import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, labelStyle, inputStyleH25 } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelQRStandardElement } from './ModelQRStandardElement';
import { loadingAction } from '../../Store/Loading';
import { Page25Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableQRStandardElement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsElement, optionsQRStandard, optionsYorN, optionsMOC, optionsCommonStatusDetailCode } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { qrStandardId, qrStandardName, elementId, elementName, qrseMoc, qrseIsRoot, rootElementId, qrseStatus, qrseDesc } = statePage.data
        const data = {
            qrseId: id,
            qrStandardId: qrStandardId,
            elementId: elementId,
            qrseStatus: qrseStatus,
            qrseDesc: qrseDesc,
            qrseMoc: qrseMoc,
            qrseIsRoot: qrseIsRoot,
            rootElementId: qrseIsRoot === false ? rootElementId : null
        };
        let a = '';
        if (qrseIsRoot === false) {
            a = CheckAllFields([qrStandardId, elementId, qrseStatus, qrseMoc, qrseIsRoot, rootElementId], ["QR Standard", "Element ID", "Status", "M/O/C", "Is Root", "Root Element"]);
        } else {
            a = CheckAllFields([qrStandardId, elementId, qrseStatus, qrseMoc, qrseIsRoot], ["QR Standard", "Element ID", "Status", "M/O/C", "Is Root"]);
            handleState('rootElementId', null);
        }
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-6'>
                        <p className={labelStyle}>QR Standard</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsQRStandard}
                            value={optionsQRStandard.filter((option: any) => option.value === statePage.data.qrStandardId)}
                            onChange={(e) => handleState('qrStandardId', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Element ID</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsElement}
                            value={optionsElement.filter((option: any) => option.value === statePage.data.elementId)}
                            onChange={(e) => handleState('elementId', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>M/O/C</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsMOC}
                            value={optionsMOC.filter((option: any) => option.value === statePage.data.qrseMoc)}
                            onChange={(e) => handleState('qrseMoc', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Is Root</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsYorN}
                            value={optionsYorN.filter((option: any) => option.value === statePage.data.qrseIsRoot)}
                            onChange={(e) => handleState('qrseIsRoot', e.value)}
                        />
                    </div>
                    {statePage.data.qrseIsRoot === false ?
                        <div className='col-6'>
                            <p className={labelStyle}>Root Element</p>
                            <Select
                                styles={inputStyleH25}
                                placeholder={'(Search)'}
                                options={optionsElement}
                                value={optionsElement.filter((option: any) => option.value === statePage.data.rootElementId)}
                                onChange={(e) => handleState('rootElementId', e.value)}
                            />
                        </div>
                        :
                        <></>
                    }
                    <div className='col-6'>
                        <p className={labelStyle}>Status</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCommonStatusDetailCode}
                            value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.data.
                                qrseStatus)} onChange={(e) => handleState('qrseStatus', `${e
                                    .value}`)} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.data.qrseDesc} onChange={(e) => handleState('qrseDesc', e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page25Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelQRStandardElement({
        id: null,
        qrseId: null,
        qrStandardId: null,
        qrStandardName: '',
        elementId: null,
        elementName: '',
        qrseMoc: 'M',
        qrseIsRoot: true,
        rootElementId: null,
        qrseStatus: '001',
        qrseDesc: '',
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelQRStandardElement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelQRStandardElement({
                    id: null,
                    qrseId: null,
                    qrStandardId: null,
                    qrStandardName: '',
                    elementId: null,
                    elementName: '',
                    qrseMoc: 'M',
                    qrseIsRoot: true,
                    rootElementId: null,
                    qrseStatus: '001',
                    qrseDesc: '',
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelQRStandardElement({
                    id: action.value.qrseId,
                    qrseId: action.value.qrseId,
                    qrStandardId: action.value.qrStandardId,
                    qrStandardName: action.value.qrStandardName,
                    elementId: action.value.elementId,
                    elementName: action.value.elementName,
                    qrseMoc: action.value.qrseMoc,
                    qrseIsRoot: action.value.qrseIsRoot,
                    rootElementId: action.value.rootElementId,
                    qrseStatus: action.value.qrseStatus,
                    qrseDesc: action.value.qrseDesc,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





import { qrManagementIcon } from "../../../Assets/Images/MenuIcon";
import { Page1QRRoute, Page2QRRoute, Page3QRRoute, Page4QRRoute, Page5QRRoute, Page6QRRoute } from "../../../Routes";
import { Page24Code, Page25Code, Page26Code, Page27Code, Page28Code, Page29Code } from "../CodeMenu";
import { Page24Title, Page25Title, Page26Title, Page27Title, Page28Title, Page29Title } from "../TitleMenu";

export const Menu9 = {
    id: 'QR_sidebar',
    label: 'QR',
    icon: qrManagementIcon,
    privilegesId: [Page24Code, Page25Code, Page26Code, Page27Code, Page28Code, Page29Code],
    itemSidebar: [
        {
            titleList: Page24Title,
            key: 'QROptions1',
            privilegeId: Page24Code,
            url: Page1QRRoute,
        },
        {
            titleList: Page25Title,
            key: 'QROptions2',
            privilegeId: Page25Code,
            url: Page2QRRoute,
        },
        {
            titleList: Page26Title,
            key: 'QROptions3',
            privilegeId: Page26Code,
            url: Page3QRRoute,
        },
        {
            titleList: Page27Title,
            key: 'QROptions4',
            privilegeId: Page27Code,
            url: Page4QRRoute,
        },
        {
            titleList: Page28Title,
            key: 'QROptions5',
            privilegeId: Page28Code,
            url: Page5QRRoute
        },
        {
            titleList: Page29Title,
            key: 'QROptions6',
            privilegeId: Page29Code,
            url: Page6QRRoute
        },
    ],
}
import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { inputStyle, labelStyle } from "../../Styles";
import { useCallback, useEffect, useReducer } from "react";

import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
import { close } from "../../Assets/Images/svg";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { initFunction, reducerOptions, stateOptions, stateSelected } from "../../State";
import { urlSelection } from "../../Services/API";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModelDetailTransactionStatus } from "../Dashboard/Models/ModelDetailTransactionStatus";
import { InterfaceOptionsDetailsMonitoringStatus } from "../Dashboard/InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { resetAllAction } from "../../Store/TransactionJourney";
import { Page2FrameworkRoute } from "../../Routes";

export const ModalQRAuthSimulatorInquiry = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);

  const { stateParent, dispatchParent, modalCondition, setModal } = props;

  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    let rrn = stateParent.rrn;
    let stan = stateParent.stan;
    if (stateParent.rrn !== '' && stateParent.stan !== '') {
      API.get({
        bodyCustom: null,
        pathCustom: `dashboard/qr-simulation-inquiry-detail?qrRrn=${rrn}&qrStan=${stan}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        handleState('data', response.data)
      })
    }

  }

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [stateParent.rrn, stateParent.stan])

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [])

  const handleClick = (codeTransType: any) => {
    dispatch({ type: "handleClick", value: codeTransType });
    setModal("modalCondition")
  };

  useEffect(() => {
    if (state.detailModal === false) {
      dispatch({ type: "selectedOriTransId", value: "" });
    }
  }, [state.detailModal]);


  const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
  const data = state.allData
  let merchant_post_code = ""
  let member_appr_code = ""

  if (state.data === null) return <></>

  if (CheckUndefined(data.merchantPosCode) == "-") {
    merchant_post_code = CheckUndefined(data.merchantPos)
  } else if (CheckUndefined(data.merchantPos) == "-") {
    merchant_post_code = CheckUndefined(data.merchantPosCode)
  } else {
    merchant_post_code = `${CheckUndefined(data.merchantPosCode)} - ${(CheckUndefined(data.merchantPos))}`
  }

  if (CheckUndefined(data.isApprCodeIntn) != "-") {
    if (data.isApprCodeIntn) member_appr_code = " (Generated by BCAP)"
  }

  const simulatorDetail = () => {
    return (
      <>
        <div className="p-0 m-0 rounded">
          <div className="grid-container-transdetail">
            <div className="grid-transdetail">
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Test Category</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.testCat)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Test Case ID</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.testCaseId)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Expected Result</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.expectedResult)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Test Result</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.testResult)}</div>
              </div>
            </div>
          </div>
        </div>

      </>
    )

  };


  const requestDetailTable = () => {
    return (
      <>
        <div className="p-0 m-0 rounded">
          <div className="grid-container-transdetail">
            <div className="grid-transdetail">
              <div className="col bg-fff" >
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Global ID</div>
                <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: "blue" }} onClick={(e) => {
                  dispatchGlobal(resetAllAction(data.globalId))
                  // navigate(`${Page2FrameworkRoute}?globalId=${data.globalId}`)
                  const url = `${Page2FrameworkRoute}?globalId=${data.globalId}`;
                  window.open(url, '_blank');
                }}>{CheckUndefined(data.globalId)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Request Date</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >
                  {CheckUndefined(data.reqDate)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">QR STAN</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrStan)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">QR Ref Number</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrRefNo)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">QR Inv Number</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrInvNo)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Product Indicator</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.prodFeatureInd)}{member_appr_code}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Product Name</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.prodFeatureName)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">QR Standard</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrStandardName)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214">QR Mode</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrMode)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Member ID</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.memberCode)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Member Name</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.memberName)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Request Reason</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.reqReason)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Reference 1</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.ref1)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Reference 2</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.ref2)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Reference 3</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.ref3)}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  };

  const mpmCpmDetails = () => {
    return (
      (data.qrMode === 'CPM') ?
      <>
        <div className="p-0 m-0 rounded">
          <div className="grid-container-transdetail">
            <div className="grid-transdetail">
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Wallet ID</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >
                  {CheckUndefined(data.walletId)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Wallet Name</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.walletName)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">CPM PAN</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.cpmPan)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">CPM PAR</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.cpmPar)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">CPM Token</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.cpmToken)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">CPM Token Request ID</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.cpmTokenReqId)}</div>
              </div>
            </div>
          </div>
        </div>
      </> :
      (data.qrMode === 'MPM') ? (<>
        <div className="p-0 m-0 rounded">
          <div className="grid-container-transdetail">
            <div className="grid-transdetail">
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Merchant Id</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >
                  {CheckUndefined(data.merchantId)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Merchant Name</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.merchantName)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Merchant Country</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.merchantCountry)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">MCC</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.mcc)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Sub Merchant Id</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.subMerchantId)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Sub Merchant Name</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.subMerchantName)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">TID</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.tid)}</div>
              </div>
              <div className="col bg-fff">
                <div className="grid-item bg-f8a Lato-700 s-1214 ">Amount</div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.amount)}</div>
              </div>
            </div>
          </div>
        </div>
      </>) : (<></>)
    )
  };


  let widthWindows_ = window.innerWidth;

  return (
    <><Modal
      open={modalCondition}
      onCancel={(e) => setModal("modalCondition")}
      maskClosable={false}
      mask={true}
      maskStyle={{
        zIndex: 15,
      }}
      style={{
        position: "fixed",
        overflow: "auto",
        // right: 'auto',
        // bottom: 'auto',
        // top: '50%',
        // left: '50%',
        // marginRight: '-30%',
        // transform: 'translate(-50%, -50%)',
        //
        right: "1%",
        bottom: "auto",
        top: "1%",
        left: "1%",
        minWidth: "min-content",
        zIndex: 90,
      }}
      bodyStyle={{
        maxHeight: '90vh',
        overflowY: 'auto',
        margin: '10px'
      }}
      footer={false}
    // title={state.titleModal}
    >
      <div className="">
        <div >
          <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
            <div className="flex-fill">
              <div className="d-flex flex-column wd-webkit">
                <div className="d-flex wrap my-1 ">
                  <div className="row my-1 w-100 p-0">
                    <div className="p-0 h-30px">
                      <p className="w-700 Lato-700 c-acr1 s-1420">Simulator Details</p>
                    </div>
                    {simulatorDetail()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
            <div className="flex-fill">
              <div className="d-flex flex-column wd-webkit">
                <div className="d-flex wrap my-1 ">
                  <div className="row my-1 w-100 p-0">
                    <div className="p-0 h-30px">
                      <p className="w-700 Lato-700 c-acr1 s-1420">Request Details</p>
                    </div>
                    {requestDetailTable()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
            <div className="flex-fill">
              <div className="d-flex flex-column wd-webkit">
                <div className="d-flex wrap my-1 ">
                  <div className="row my-1 w-100 p-0">
                    <div className="p-0 h-30px">
                      {data.qrMode === 'CPM' ?
                        <p className="w-700 Lato-700 c-acr1 s-1420">QR CPM Details</p>
                        : data.qrMode === 'MPM' ?
                          <p className="w-700 Lato-700 c-acr1 s-1420">QR MPM Details</p> : <></>}
                    </div>
                    {mpmCpmDetails()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

      {/* <ModalQRAuthInquiry2
          detailModal={state.detailModal}
          setModal={() => dispatch({ type: "detailModal", value: !state.detailModal })}
          dispatchFirstParent={dispatchParent}
          stateFirstParent={stateParent}
          stateParent={state}
          stateOptions={stateOptions} /> */}
    </>

  );
};

interface test {
  data: ModelDetailTransactionStatus | null, detailModal: false
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: test = {
  data: null,
  detailModal: false
};
function reducer(state: any, action: any) {

  switch (action.type) {
    case 'data':
      return {
        ...state,
        allData: action.value,
        data: {
          authId: action.value.authId,
          transAuthId: action.value.transId,
          transactionDateTime: action.value.transactionDateTime,
          issAmount: action.value.issAmount,
          productTypeName: action.value.productTypeName,
          localDateTime: action.value.localDateTime,
          iss: action.value.iss,
          issSwitcherAmount: action.value.issSwitcherAmount,
          productFeatureName: action.value.productFeatureName,
          acqSwitcherAmount: action.value.acqSwitcherAmount,
          mti: action.value.mti,
          issSwitcher: action.value.issSwitcher,
          acqAmount: action.value.acqAmount,
          stan: action.value.stan,
          acqSwitcher: action.value.acqSwitcher,
          convRate: action.value.convRate,
          procCode: action.value.procCode,
          acq: action.value.acq,
          convDate: action.value.convDate,
          rrn: action.value.rrn,
          mid: action.value.mid,
          issSettleDate: action.value.issSettleDate,
          invNo: action.value.invNo,
          tid: action.value.tid,
          issSwitcherSettleDate: action.value.issSwitcherSettleDate,
          apprCode: action.value.apprCode,
          merchantName: action.value.merchantName,
          acqSwitcherSettleDate: action.value.acqSwitcherSettleDate,
          merchantCountry: action.value.merchantCountry,
          acqSettleDate: action.value.acqSettleDate,
          globalId: action.value.globalId,
          mcc: action.value.mcc,
          intnRspnCode: action.value.intnRspnCode,
          isApprCodeIntn: action.value.isApprCodeIntn,
        }
      };
    case "detailModal":
      return {
        ...state,
        detailModal: action.value,
      };
    case "handleClick":
      return {
        ...state,
        selectedOriTransId: action.value,
        detailModal: !state.detailModal,
      };
    case "selectedOriTransId":
      return {
        ...state,
        selectedOriTransId: action.value,
      };
    default:
      throw new Error();
  }
}
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonDelete, ButtonEdit, InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';

import { useNavigate } from 'react-router-dom';
import { ModelInterfaceFile } from './Utils/ModelInterfaceFile';
import { InterfaceOptionsInterfaceFile } from './Utils/InterfaceOptionsInterfaceFile';
import { TableInterfaceFile } from '../FileElement/TableInterfaceFile';
import { ModalInterfaceFile } from './Components/Modal/ModalInterfaceFile';
import { ModalDraggableInterfaceFile } from './Components/Modal/ModalDraggableInterfaceFile';
import { ShowModalInterface } from '../../Interface';
import { Page35Title } from '../../State/Menu/TitleMenu';
import { CheckUndefined, ExportCSV } from '../../Utils';

const ExcelInterfaceFile = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showinterfaceFileFormat = '';
        let showinterfaceStrFormat = '';

        let showinterfaceFileStatus = '';
        let showinterfaceRecItemNameYn = '';
        if (stateOptions.optionsInterfaceFileFormatDetailCode.length > 0) {
            stateOptions.optionsInterfaceFileFormatDetailCode.forEach((element2: any) => {
                if (element2.value === item.interfaceFileFormat) {
                    showinterfaceFileFormat = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsFileStringFormatDetailCode.length > 0) {
            stateOptions.optionsFileStringFormatDetailCode.forEach((element2: any) => {
                if (element2.value === item.interfaceStrFormat) {
                    showinterfaceStrFormat = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.interfaceFileStatus) {
                    showinterfaceFileStatus = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsYorN.length > 0) {
            stateOptions.optionsYorN.forEach((element2: any) => {
                if (element2.value === item.interfaceRecItemNameYn) {
                    showinterfaceRecItemNameYn = `${element2.label}`;
                }
            });
        }


        const m = {
            no: i + 1,
            interfaceFileId: CheckUndefined(item.interfaceFileId),
            interfaceFileName: CheckUndefined(item.interfaceFileName),
            interfaceFileFormat: CheckUndefined(showinterfaceFileFormat),
            interfaceStrFormat: CheckUndefined(showinterfaceStrFormat),
            interfaceRecSize: CheckUndefined(item.interfaceRecSize),
            interfaceRecItemNameYn: CheckUndefined(showinterfaceRecItemNameYn),
            interfaceRecItemNameLength: CheckUndefined(item.interfaceRecItemNameLength),
            interfaceFileStatus: CheckUndefined(showinterfaceFileStatus),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};


export const InterfaceFile = ({ ...props }) => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let finalParameter = `${searchValue}${limit}${offset}`;
        let finalParameter2 = `${searchValue}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }


    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsInterfaceFile, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    useEffect(() => {
        initFunctionCustom(false);
    }, [pagination.pageIndex]);

    useEffect(() => {
        setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
    }, [state.view]);

    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelInterfaceFile(state, stateOptions, response.data)
        })
    }

    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelInterfaceFile) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelInterfaceFile, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                <span style={{ marginRight: '20px' }}>
                    <ButtonEdit  handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
                </span>
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
            </div>
        );
    };
    const filterComponentPage = () => {
        return (
            <></>
        )
    }
    const tableComponentPage = () => {
        return (
            <TableInterfaceFile
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                currentPage={state.currentPage}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <ModalInterfaceFile
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableInterfaceFile
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            {/* <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={true}
                needSearchFunction={true}
                needDownloadButton={true}
            /> */}
            <TableInterfaceFile
                state={state}
                stateOptions={stateOptions}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                deleteDataComponent={deleteDataComponent}
                addData={addDataComponent}
                codeAccessPrivilegeAdd={state.privilegeAccess.add}
                // handleStateGlobal={handleStateGlobal}
                // resetFilterStatus={resetFilterStatus}
                initFunctionCustom={initFunctionCustom}
                exportToCSV={exportToCSVComponent}
                codeAccessPrivilegeDownload={state.privilegeAccess.download}
                // handleNavigation={handleNavigation}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                //
                currentPage={state.currentPage}
                dispatch={dispatch}
            />
            <ModalInterfaceFile
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
            {
                state.savedShowModal.map((element1: any) => {
                    return (
                        <ModalDraggableInterfaceFile
                            key={element1.idModal}
                            id={element1.idModal}
                            index={element1.index}
                            idModal={element1.idModal}
                            state={state}
                            urlApi={state.urlApi}
                            showModal={element1.showModal}
                            reloadData={() => reloadDataComponent(false)}
                            actionShowModal={() => actionShowModal(element1.idModal, element1)}
                            actionIndexModal={() => actionIndexModal(element1.idModal)}
                            actionAfterUpdateItem={actionAfterUpdateItem}
                            itemModal={element1.itemModal}
                            stateOptions={stateOptions}
                        />
                    )
                }
                )
            }
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'interface_file',
    titlePage: Page35Title,
    privilegeAccess: { view: "191", add: "192", edit: "193", delete: "194", download: "195" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Interface File ID", width: "wd-150", val: "interfaceFileId" },
        { field: "File Name", width: "wd-150", val: "interfaceFileName" },
        { field: "File Format", width: "wd-200", val: "interfaceFileFormat" },
        { field: "File String Format", width: "wd-150", val: "interfaceStrFormat" },
        { field: "Record Size", width: "wd-150", val: "interfaceRecSize" },
        { field: "Record Item Name", width: "wd-100", val: "interfaceRecItemNameYn" },
        { field: "Record Name Length", width: "wd-100", val: "interfaceRecItemNameLength" },
        { field: "File Status", width: "wd-150", val: "interfaceFileStatus" },
        { field: 'Action', width: 'wd-150', val: 'action' },
    ],
    complete_toggle: ["no", "interfaceFileId", "interfaceFileName", "interfaceFileFormat", "interfaceStrFormat", "interfaceRecSize", "interfaceRecItemNameYn", "interfaceRecItemNameLength", , "interfaceFileStatus", "action"],
    toggle: ["no", "interfaceFileId", "interfaceFileName", "interfaceFileFormat", "interfaceStrFormat", "interfaceRecSize", "interfaceRecItemNameYn", "interfaceRecItemNameLength", , "interfaceFileStatus", "action"],
    list_column_name: ["No.", "Interface File ID", "File Name", "File Format", "File String Format", "Record Size", "Record Item Name", "Record Name Length", "File Status"],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Interface File ID, File Name',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1700,
    dataLength: 0, // zIndex
    finalAPI: '',
};


// interfaceFileId ModelInterfaceFile
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInterfaceFile) => {
                        element1.id = element1.interfaceFileId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInterfaceFile) => {
                        element1.id = element1.interfaceFileId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInterfaceFile) => {
                        showDataResult.push(
                            { idModal: element1.interfaceFileId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.interfaceFileId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}

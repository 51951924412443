import { createSlice } from "@reduxjs/toolkit";

export const successSlice = createSlice({
    name: "success",
    initialState: {
        condition: false,
        message: '',
    },
    reducers: {
        successAction: (state, action) => {
            state.condition = action.payload
        },
        successMessageAction: (state, action) => {
            state.message = action.payload
        },
        successDoubleAction: (state, action) => {
            state.message = action.payload.message
            state.condition = action.payload.condition
        },
    }
});

export const { successAction, successMessageAction, successDoubleAction } = successSlice.actions;
export const stateSuccess = (state: any) => state.success.value;
export default successSlice.reducer;

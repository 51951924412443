import {
    NavigationModal,
    NavigationTable,
    DataTableFeatures,
    SelectView,
    Sort,
} from ".";

export const InsideComponentPage = ({ ...props }) => {
    const {
        state,
        stateOptions,
        globalState,

        exportToCSVComponent,
        handleGlobalState,
        checkIndexComponent,
        handleStateComponent,

        needComponentHeaderTable,
        needComponentHeaderTableCenter = false,
        addDataComponent,
        setToggleComponent,
        needComponentFilterTable,
        filterComponentPage,
        needComponentTable,
        tableComponentPage,
        needComponentFooterTable,
        setModal,
        modalAddDeleteComponentPage,
        modalUpdateComponentPage,
        needToggleFunction = true,
        needAddButton = true,
        needSearchFunction = true,
        needDownloadButton = true,
        needComponentSort = false,
    } = props;
    return (
        <>
            <div className='d-flex flex-column w-100 my-5'>
                <h1 className='Lato-300 normal w-600px s-2024 py-2'>{state.titlePage}</h1>
                <div className='d-flex flex-column bg-ff bd-ea br-10 py-3'>
                    {
                        needComponentHeaderTable === true ?
                            <DataTableFeatures
                                stateParent={state}
                                data={state.data}
                                label={state.label}
                                titleTable={'List'}
                                privilegeAccess={state.privilegeAccess}
                                addData={addDataComponent}
                                checkIndex={checkIndexComponent}
                                exportToCSV={exportToCSVComponent}
                                handleState={handleStateComponent}
                                setToggle={setToggleComponent}
                                needToggleFunction={needToggleFunction}
                                needAddButton={needAddButton}
                                needSearchFunction={needSearchFunction}
                                needDownloadButton={needDownloadButton}
                            />
                            :
                            <></>
                    }
                    {
                        needComponentSort === true ?
                            <Sort state={state} stateOptions={stateOptions} globalState={globalState} handleGlobalState={handleGlobalState}/>
                            :
                            <></>
                    }
                    {
                        needComponentFilterTable === true ?
                            filterComponentPage !== undefined ? filterComponentPage() : <></>
                            :
                            <></>
                    }
                    {
                        needComponentHeaderTableCenter === true ?
                            <DataTableFeatures
                                stateParent={state}
                                data={state.data}
                                label={state.label}
                                titleTable={'List'}
                                privilegeAccess={state.privilegeAccess}
                                addData={addDataComponent}
                                checkIndex={checkIndexComponent}
                                exportToCSV={exportToCSVComponent}
                                handleState={handleStateComponent}
                                setToggle={setToggleComponent}
                                needToggleFunction={needToggleFunction}
                                needAddButton={needAddButton}
                                needSearchFunction={needSearchFunction}
                                needDownloadButton={needDownloadButton}
                            />
                            :
                            <></>
                    }

                    {
                        state.data.length > 0 ?
                            <>

                                {
                                    needComponentTable === true ?
                                        tableComponentPage !== undefined ? tableComponentPage() : <></>
                                        :
                                        <></>
                                }
                                {
                                    needComponentFooterTable === true ?
                                        <div className='d-flex min-h-60px p-2 justify-content-between'>
                                            <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                                            <NavigationTable
                                                data={state.data}
                                                position={state.position}
                                                view={state.view}
                                                totalPages={state.totalPages}
                                                currentPage={state.currentPage}
                                                handleState={handleStateComponent}
                                                setModal={setModal}
                                            />
                                        </div>
                                        : <></>
                                }
                            </>
                            :
                            <div className="row">
                                <div className="col-12 bg-f4 w-100 d-flex justify-content-center align-items-center h-40px">
                                    Data not found
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                state.data.length > 0 ?
                    <NavigationModal
                        data={state.data}
                        position={state.position}
                        view={state.view}
                        totalPages={state.totalPages}
                        currentPage={state.currentPage}
                        insertPage={state.insertPage}
                        handleState={handleStateComponent}
                        setModal={setModal}
                    />
                    :
                    <></>
            }
            {modalAddDeleteComponentPage !== undefined ? modalAddDeleteComponentPage() : <></>}
            {modalUpdateComponentPage !== undefined ? modalUpdateComponentPage() : <></>}
        </>
    )
}
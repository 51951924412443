import { API, urlSelection } from '../../Services/API';
import { CheckAZaz09SpecialChar, CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalCodeDetail = ({ ...props }) => {
  const dispatchGlobal = useDispatch();

  const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
  const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
  const { optionsCommonStatusDetailCode, optionsCCD, optionsGroupCode } = props.stateOptions
  //
  const { setTriggerReload } = props

  useEffect(() => {
    dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
    dispatchStatePage({ type: 'clearData', value: deleteItem })
  }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

  useEffect(() => {
    dispatchStatePage({ type: 'deleteItem', value: deleteItem })
  }, [deleteItem !== statePage.deleteItem])

  useEffect(() => {
    dispatchStatePage({ type: 'id', value: id })
    if (dataModal !== null) {
      handleState('setData', dataModal);
    }
    if (id !== '') { }
    else {
      dispatchStatePage({ type: 'clearData', value: deleteItem })
    }
  }, [id !== statePage.id])

  const handleState = (nameState: string | any, valueState: any) => {
    let formatCheckStringHaveSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?~]/;
    let formatCheckIsNum = /^\d+$/;
    if (nameState === 'ccdName') {
      if (formatCheckStringHaveSpecialCharacter.test(valueState.toString())) { }
      else if (HasWhiteSpace(valueState.toString())) { }
      else if (valueState.split('').length < 33) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else if (nameState === 'ccdSortNumber') {
      if (valueState === '') { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
      else if (formatCheckIsNum.test(valueState.toString()) === false) { }
      else if (valueState.split('').length < 4) { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
    } else if (nameState === 'ccdCode') {
      if (valueState === '') { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
      else if (formatCheckIsNum.test(valueState.toString()) === false) { }
      else if (valueState.split('').length < 4) { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
    } else if (nameState === 'ccdExt1Val') {
      if (CheckAZaz09SpecialChar.test(valueState.toString())) {
        dispatchStatePage({ type: nameState, value: valueState })
      }
      else if (HasWhiteSpace(valueState.toString())) { }
      else if (valueState.split('').length < 33) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else if (nameState === 'ccdExt2Val') {
      if (CheckAZaz09SpecialChar.test(valueState.toString())) {
        dispatchStatePage({ type: nameState, value: valueState })
      }
      else if (HasWhiteSpace(valueState.toString())) { }
      else if (valueState.split('').length < 33) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else if (nameState === 'ccdDesc') {
      if (valueState.split('').length < 121) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else { dispatchStatePage({ type: nameState, value: valueState }) }
  }

  const deleteItemAPI = () => {
    API.delete({
      bodyCustom: null,
      pathCustom: `${urlApi}/${id}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response) => {
      setModal("deleteItem")
      setTriggerReload(true)
      reloadData()
    }).catch((err) => {
      dispatchStatePage({ type: 'showModalError', value: true })
      dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
    }).finally(() => {
      dispatchStatePage({ type: 'clearData', value: deleteItem })
    })
  }

  const postDataAPI = () => {
    const { ccgCode, ccdCode, ccdName, ccdSortNumber, ccdDesc, ccdStatus, ccdExt1Val, ccdExt2Val, errorMessage } = statePage;
    const data = {
      ccgCode: `${ccgCode}`,
      ccdCode: `${ccdCode}`,
      ccdName: `${ccdName}`,
      ccdSortNumber: `${ccdSortNumber}`,
      ccdDesc: `${ccdDesc}`,
      ccdStatus: `${ccdStatus}`,
      ccdExt1Val: `${ccdExt1Val}`,
      ccdExt2Val: `${ccdExt2Val}`,
    };
    const a = CheckAllFields([ccgCode, ccdCode, ccdName, ccdSortNumber, ccdStatus], ['Group Code', 'Detail Code', 'Code Name', 'Sort No', 'Use Status']);

    if (a !== "" || errorMessage !== '') {
      handleState('errorMessage', a);
    } else {
      handleState('errorMessage', ``);
      let conditionCodeAlreadyAvailable: any[] = optionsCCD.filter((element1: any) => element1.ccdCode === ccdCode && element1.ccgCode === ccgCode);
      if (conditionCodeAlreadyAvailable.length === 0) {
        API.post({
          bodyCustom: data,
          pathCustom: `${urlApi}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response) => {
          dispatchStatePage({ type: 'clearData' })
          setModal("visibleModalAddDelete")
          setTriggerReload(true)
          reloadData()
        }).catch((err) => handleState('errorMessage', err))
      } else {
        handleState('errorMessage', 'Combination of Group Code and Detail Code already exist');
      }
    }
  }

  return (
    <>
      <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
        <div className='d-flex flex-column w-800px'>
          <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
            <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
            <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
              <div className='col-4'>
                <p className={labelStyle}>GROUP CODE <span className='text-danger'>*</span></p>
                <Select
                  styles={inputStyleH25}
                  placeholder={'(Search)'}
                  options={optionsGroupCode}
                  value={optionsGroupCode.filter((option: any) => option.value === statePage.ccgCode)}
                  onChange={(e) => handleState('ccgCode', e.value)}
                />
              </div>
              <div className='col-4'>
                <p className={labelStyle}>DETAIL CODE <span className='text-danger'>*</span></p>
                <input value={statePage.ccdCode} onChange={(e) => handleState('ccdCode', e.target.value)} className={inputStyle} />
              </div>
              <div className='col-4'>
                <p className={labelStyle}>CODE NAME <span className='text-danger'>*</span></p>
                <input value={statePage.ccdName} onChange={(e) => handleState('ccdName', e.target.value)} className={inputStyle} />
              </div>
              <div className='col-4'>
                <p className={labelStyle}>SORT NO <span className='text-danger'>*</span> <span className='text-danger'>*</span></p>
                <input value={statePage.ccdSortNumber} onChange={(e) => handleState('ccdSortNumber', e.target.value)} className={inputStyle} />
              </div>
              <div className='col-4'>
                <p className={labelStyle}>USE STATUS <span className='text-danger'>*</span></p>
                <Select
                  styles={inputStyleH25}
                  placeholder={'(Search)'}
                  options={optionsCommonStatusDetailCode}
                  value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.ccdStatus)}
                  onChange={(e) => handleState('ccdStatus', e.value)}
                />
              </div>

              <div className='col-4'>
                <p className={labelStyle}>Ext 1</p>
                <input value={statePage.ccdExt1Val} onChange={(e) => handleState('ccdExt1Val', e.target.value)} className={inputStyle} />
              </div>
              <div className='col-4'>
                <p className={labelStyle}>Ext 2</p>
                <input value={statePage.ccdExt2Val} onChange={(e) => handleState('ccdExt2Val', e.target.value)} className={inputStyle} />
              </div>
              <div className='col-12'>
                <p className={labelStyle}>DESCRIPTION</p>
                <textarea rows={4} value={statePage.ccdDesc} onChange={(e) => handleState('ccdDesc', e.target.value)} className={inputDescriptionStyle} />
              </div>
            </div>
            <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
          </div>
        </div>
      </Modal>

      <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
      <ModalCannotDelete statePage={statePage} handleState={handleState} />
    </>
  )
}


const initialState = {
  titleModal: 'New Detail Code',
  visibleModalAddDelete: false,
  deleteItem: false,
  // 
  ccdId: '',
  ccgCode: '',
  ccdCode: '',
  ccdName: '',
  ccdSortNumber: '',
  ccdDesc: '',
  ccdStatus: '001',
  ccdExt1Val: '',
  ccdExt2Val: '',
  // 
  id: "",
  errorMessage: "",
  errorMessageList: [],
  errorMessageModal: '',
  showModalError: false,
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'visibleModalAddDelete':
      return {
        ...state,
        visibleModalAddDelete: action.value,
      };
    case 'deleteItem':
      return {
        ...state,
        deleteItem: action.value,
      };
    case 'id':
      return {
        ...state,
        id: action.value,
      };
    case 'errorMessage':
      return {
        ...state,
        errorMessage: action.value,
      };
    case 'errorMessageList':
      return {
        ...state,
        errorMessageList: action.value,
      };
    case 'showModalError':
      return {
        ...state,
        showModalError: action.value,
      };
    case 'errorMessageModal':
      return {
        ...state,
        errorMessageModal: action.value,
      };
    // 
    case 'clearData':
      return {
        ...state,
        ccdId: '',
        ccgCode: '',
        ccdCode: '',
        ccdName: '',
        ccdSortNumber: '',
        ccdDesc: '',
        ccdStatus: '001',
        ccdExt1Val: '',
        ccdExt2Val: '',
        errorMessage: "",

      };
    case 'setData':
      return {
        ...state,
        ccdId: action.value.ccdId,
        ccgCode: action.value.ccgCode,
        ccdCode: action.value.ccdCode,
        ccdName: action.value.ccdName,
        ccdSortNumber: action.value.ccdSortNumber,
        ccdDesc: action.value.ccdDesc,
        ccdStatus: action.value.ccdStatus,
        ccdExt1Val: action.value.ccdExt1Val,
        ccdExt2Val: action.value.ccdExt2Val,
        errorMessage: "",
      };
    // 
    case 'ccdId':
      return {
        ...state,
        ccdId: action.value,
      };
    case 'ccgCode':
      return {
        ...state,
        ccgCode: action.value,
      };
    case 'ccdCode':
      return {
        ...state,
        ccdCode: action.value,
      };
    case 'ccdName':
      return {
        ...state,
        ccdName: action.value,
      };
    case 'ccdSortNumber':
      return {
        ...state,
        ccdSortNumber: action.value,
      };
    case 'ccdDesc':
      return {
        ...state,
        ccdDesc: action.value,
      };
    case 'ccdStatus':
      return {
        ...state,
        ccdStatus: action.value,
      };
    case 'ccdExt1Val':
      return {
        ...state,
        ccdExt1Val: action.value,
      };
    case 'ccdExt2Val':
      return {
        ...state,
        ccdExt2Val: action.value,
      };
    default:
      throw new Error();
  }
}

import { useSelector } from "react-redux";

export const ButtonDelete = ({ ...props }) => {
    const { handleClick, codeAccessPrivilege, isDisabled = false } = props;
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    if (privilegeAccess === null) {
        return <></>
    } else if (privilegeAccess.includes(codeAccessPrivilege)) {
        if (isDisabled === true) {
            return <button className="btn btn-light c-29 bg-ff p-614 s-1214 br-5  h-20px w-50px  d-flex justify-content-center align-items-center border-secondary" disabled={true}>DELETE</button>
        } else {
            return <button className="btn btn-light c-29 bg-ff p-614 s-1214 br-5  h-20px w-50px  d-flex justify-content-center align-items-center border-secondary" onClick={handleClick}>DELETE</button>
        }
    } else if (codeAccessPrivilege === "") {
        if (isDisabled === true) {
            return <button className="btn btn-light c-29 bg-ff p-614 s-1214 br-5  h-20px w-50px  d-flex justify-content-center align-items-center border-secondary" disabled={true}>DELETE</button>
        } else {
            return <button className="btn btn-light c-29 bg-ff p-614 s-1214 br-5  h-20px w-50px d-flex justify-content-center align-items-center border-secondary" onClick={handleClick}>DELETE</button>
        }
    } else return <></>;
};

import { API, urlSelection } from '../../Services/API';
import { InsideComponentPage, TableWithLimitOffset } from '../../Components';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { inputStyle, inputStyle2, labelStyle } from '../../Styles';
import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceOptionsUnmatchedList } from './InterfaceOptionsUnmatchedList';
import { ModelSettlementSummary } from '../SettlementSummary/Models/ModelSettlementSummary';
import Select from 'react-select';
import { ShowModalInterface } from '../../Interface';
import { TableUnmatchedList } from './TableUnmatchedList';
import { icon_search_white } from '../../Assets/Images/svg';
import { useNavigate } from 'react-router-dom';
import { TableWithLimitOffset2 } from '../../Components/TableWithLimitOffset2';
import { ModalUnmatchedList } from '../UnmatchedList/ModalUnmatchedList';
import { ModalUnmatchedListDetail } from '../UnmatchedList/ModalUnmatchedListDetail';
import { ModelUnmatchedList } from './ModelUnmatchedList';

export const UnmatchedList = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let selectedStartDate = stateCustomComponent.selectedStartDate !== '' ? `startDate=${stateCustomComponent.selectedStartDate}&` : '';
        let selectedEndDate = stateCustomComponent.selectedEndDate !== '' ? `endDate=${stateCustomComponent.selectedEndDate}&` : '';

        // issuer
        let selectedIssuerSwitcherInstitutionIDDetailCode = stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode !== '' ? `issuerSwitcher=${stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode}&` : '';
        // acquirer
        //source inst
        let inputInvNo = state.dispNo !== '' ? `dispNo=${state.dispNo}&` : '';
        let selectedSourceInst = stateCustomComponent.selectedSourceInst !== '' ? `srcInst=${stateCustomComponent.selectedSourceInst}&` : '';
        //
        // etc
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${search}${limit}${offset}${selectedStartDate}${selectedEndDate}${inputInvNo}${selectedSourceInst}`;

        let finalParameter2 = `${search}${limit}${offset}${selectedStartDate}${selectedEndDate}${selectedIssuerSwitcherInstitutionIDDetailCode}`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }




    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsUnmatchedList, dispatchGlobal)
    }, [])

    useEffect(() => {
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    useEffect(() => {
        dispatchCustomComponent({ type: 'selectedStartDate', value: new Date().toISOString().slice(0, 10) })
        dispatchCustomComponent({ type: 'selectedEndDate', value: new Date().toISOString().slice(0, 10) })
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '' && stateCustomComponent.selectedSourceInst !== '') initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsUnmatchedList, dispatchGlobal)
    }, [])




    const exportToCSVComponent = () => { }
    //
    const addDataComponent = () => addData(dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)



    const customButtonAction = (item: ModelSettlementSummary, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
            </div>
        );
    };

    const filterComponentPage = () => {
        return (
            <div className="container-all-select-custom">
                <div className="row gx-4 gy-4">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>Instruction Identification</p>
                        <input className='bd-d4 bg-ff br-5 h-40px px-2 w-100 s-1418 Lato-400 out-none ' 
                            placeholder='' value={state.dispNo}
                            maxLength={99}
                            // type='text'
                            onChange={(e) => handleState('dispNo', e.target.value, dispatch)} type='text'
                        />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>Source Inst</p>
                        <Select className='flex-fill' options={stateOptions.optionsMemberCode3} value={stateOptions.optionsMemberCode3.filter((option: any) => option.value === stateCustomComponent.selectedSourceInst)} onChange={(e) => handleState("selectedSourceInst", e.value, dispatchCustomComponent)} />
                    </div>
                </div>
                <div className="row gx-4 gy-2">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>Transaction Start Date</p>
                        <input value={stateCustomComponent.selectedStartDate} onChange={(e) => handleState('selectedStartDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />

                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>Transaction End Date</p>
                        <input value={stateCustomComponent.selectedEndDate} onChange={(e) => handleState('selectedEndDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />

                    </div>
                </div>
                <div className="row gx-4 mt-2">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center">
                        <button className="d-flex align-items-center px-4 py-3 br-10 bd-d4 bg-e8 bd-29 text-white" onClick={(e) => initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsUnmatchedList, dispatchGlobal)}>
                            {icon_search_white}
                            <p className="s-1418 c-ff Lato-600 mx-2">SEARCH</p>
                        </button>
                    </div>
                </div>
            </div>
        )
        // return (
        //     <div className="container-all-select-custom py-3 mt-2">
        //         <div className="row gx-5 gy-4">
        //             <div className="d-flex col-xxl-4 col-xl-7 col-lg-9 col-sm-12 col-xs-12">
        //                 <p className="c-54 s-1418 w-25">Instruction Identification</p>
        //                 <input className='bd-d4 bg-ff br-5 h-50px s-1418 Lato-400 out-none  w-300px p-2'
        //                     placeholder='' value={state.dispNo}
        //                     maxLength={99}
        //                     // type='text'
        //                     onChange={(e) => handleState('dispNo', e.target.value, dispatch)} type='text'
        //                 />
        //             </div>
        //             <div className="col-xxl-2 col-xl-4 col-lg-9 col-sm-12 col-xs-12">
        //             </div>
        //             <div className="d-flex col-xxl-3 col-xl-5 col-lg-6 col-sm-12 col-xs-12">
        //                 <p className="c-54 s-1418 w-50 pt-2">Settlement</p>
        //                 <p className="c-54 s-1418 w-50 pt-2"> Start Date</p>
        //                 <input value={stateCustomComponent.selectedStartDate} onChange={(e) => handleState('selectedStartDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle2} />
        //             </div>
        //             <div className="d-flex col-xxl-3 col-xl-5 col-lg-6 col-sm-12 col-xs-12">
        //                 <p className="c-54 s-1418 w-50 pt-2"> Settlement </p>
        //                 <p className="c-54 s-1418 w-50 pt-2">  End Date</p>
        //                 <input value={stateCustomComponent.selectedEndDate} onChange={(e) => handleState('selectedEndDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle2} />
        //             </div>
        //             <div className="d-flex col-xxl-4 col-xl-6 col-lg-7 col-sm-12 col-xs-12">
        //                 <p className="c-54 s-1418 w-25 pt-2">Source Inst,</p>
        //                 <Select className='bd-d4 bg-ff br-5 h-40px s-1418 Lato-400 out-none  w-200px' options={stateOptions.optionsMemberCode3} value={stateOptions.optionsMemberCode3.filter((option: any) => option.value === stateCustomComponent.selectedSourceInst)} onChange={(e) => handleState("selectedSourceInst", e.value, dispatchCustomComponent)} />
        //             </div>
        //         </div>
        //         <div className='row gx-5 mt-4'>
        //             <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center">
        //                 <button className="d-flex align-items-center px-4 py-3 br-20 bd-d4 bg-e8 bd-20 text-white" onClick={(e) => initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsUnmatchedList, dispatchGlobal)}>
        //                     {icon_search_white}
        //                     <p className="s-1418 c-ff Lato-600 mx-2">SEARCH</p>
        //                 </button>
        //             </div>

        //         </div>
        //     </div>
        // )
    }
    // useEffect(() => {
    //     if (state.modalCondition === false) {
    //         dispatch({ type: 'resetAllSelected' })
    //     }
    // }, [state.modalCondition])


    const tableComponentPage = () => {
        return (
            <TableUnmatchedList
                //
                stateParent={state}
                // data2={state.data2}
                //
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                editData={(item: any, idSelected: string) => {
                    //issuer recon status

                    //acquirer recon status
                    if (idSelected === 'transId') {
                        dispatch({
                            type: 'transId',
                            value: {
                                transId: item.transId,
                            }
                        });
                    }
                    dispatch({ type: 'modalCondition', value: !(state.modalCondition) });
                }}
                editData2={(item: any, idSelected: string) => {
                    if (idSelected === 'setInvNo') {
                        dispatch({
                            type: 'setInvNo',
                            value: {
                                dispNo: item.dispNo,
                            }
                        });
                    }
                    dispatch({ type: 'modalConditionDetail', value: !(state.modalCondition) });
                }}
                //
                currentPage={state.currentPage}

            />
        )
    }




    const modalAddDeleteComponentPage = () => {
        return (
            <ModalUnmatchedList
                modalCondition={state.modalCondition}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                //
                stateParent={state}
                dispatchParent={dispatch}
            />
        )
    }
    const modalAddDeleteComponentDetailPage = () => {
        return (
            <ModalUnmatchedListDetail
                modalConditionDetail={state.modalConditionDetail}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                //
                stateParent={state}
                dispatchParent={dispatch}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <TableWithLimitOffset2
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalAddDeleteComponentDetailPage={modalAddDeleteComponentDetailPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={false}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={false}
                needAddButton={false}
                needSearchFunction={false}
                needDownloadButton={false}

                needComponentHeaderTableCenter={true}

            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'settlement/unmatched',
    titlePage: 'Unmatched List',
    privilegeAccess: { view: '421', add: '', edit: '', delete: '', download: '' },
    label: [
        { field: 'No.', width: 'wd-20', val: 'no', },
        { field: 'Transaction Identifier', width: 'wd-200', label: 'dispNo', val: 'transId', },
        { field: 'Transaction Date/Time', width: 'wd-160', val: 'transDateTime', },
        { field: 'Acquirer Amount', width: 'wd-80', val: 'acqAmt', },
        { field: 'Acquirer Switcher Amount', width: 'wd-120', val: 'acqSwitcherAmt', },
        // { field: 'Status', width: 'wd-150', val: 'acquirerSwitchReconStatus', }, // acquirerSwitchCountryCurr acquirerSwitchAmount
        { field: 'Issuer Amount', width: 'wd-80', val: 'issAmt', }, //  
        { field: 'Reason Code', width: 'wd-350', val: 'rsnCd', },
        { field: 'Channel', width: 'wd-100', val: 'chnl', },
        { field: 'Merchant Biller ID', width: 'wd-150', val: 'merBlrId', },
        { field: 'Source Inst.', width: 'wd-150', val: 'srcInst', },
        { field: 'Dest Inst.', width: 'wd-150', val: 'dstInst', },
        { field: 'Source Data.', width: 'wd-150', val: 'dispNo', },
    ],
    toggle: ['no', 'dispNo', 'transDateTime', 'acqAmt', 'transId', 'acqSwitcherAmt', 'issAmt', 'rsnCd', 'chnl', 'merBlrId', 'amt', 'curr', 'issuerSwitchMemberName', 'srcInst', 'dstInst', 'sourceData'],
    complete_toggle: ['no', 'dispNo', 'transDateTime', 'transId', 'acqSwitcherAmt', 'issAmt', 'rsnCd', 'acqAmt', 'chnl', 'merBlrId', 'amt', 'curr', 'issuerSwitchMemberName', 'srcInst', 'dstInst', 'sourceData'],
    list_column_name: ['No.', 'Transaction Identifier', 'Transaction Date/Time', 'Acquirer Amount', 'Acquirer Switcher Amount', 'Issuer Amount', 'Reason Code', 'Channel', 'Issuer Amount', 'Reason Code', 'Channel', 'Merchant Biller ID', 'Source Inst.', 'Dest Inst.', 'Source Data.'],
    // 
    modalCondition: false,
    savedShowModal: [], // new
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    dispNo: '',
    transId: '',


    // 
    widthTable: 2300,
    dataLength: 0, // zIndex
    //

};


// sttlId ModelSettlementSummary
function reducer(state: any, action: any) {
    let showDataResult: any[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelUnmatchedList) => {
                        element1.id = element1.transId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSettlementSummary) => {
                        element1.id = element1.sttlId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        // case 'afterUpdate':
        //     if (action.value != null) {
        //         if (action.value.length > 0) {
        //             action.value.forEach((element1: ModelSettlementSummary) => {
        //                 showDataResult.push(
        //                     { idModal: element1.sttlId, showModal: false, itemModal: element1, index: 1 }
        //                 )
        //                 element1.id = element1.sttlId;
        //                 dataWhereChangeToIdGeneral.push(element1);
        //             })
        //         }
        //     }
        //     return {
        //         ...state,
        //         data: dataWhereChangeToIdGeneral,
        //         showModal: showDataResult,
        //         dataLength: dataWhereChangeToIdGeneral.length,
        //     };
        // 


        case 'dispNo':
            return {
                ...state,
                dispNo: action.value
            };
        case 'transId':
            return {
                ...state,
                transId: action.value.transId
            };

        case 'setInvNo':
            console.log('dispNo:?', action.value.dispNo)
            return {
                ...state,
                dispNo: action.value.dispNo
            }


        case 'resetAllSelected':
            return {
                ...state,
                selectedSourceInst: '',
                transId: '',

                dispNo: '',

                whatIsSelectedNow: '',
            };
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'modalCondition':
            return {
                ...state,
                modalCondition: action.value
            };
        case 'modalConditionDetail':
            return {
                ...state,
                modalConditionDetail: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };

        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                modalCondition: true,
            };
        case 'editData2':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                modalConditionDetail: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };

        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        //
        default:
            throw new Error();
    }
}

import React, { useEffect, useReducer } from "react";

import { Divider, Modal, Tooltip } from "antd";
import { saveAs } from "file-saver";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { Resizable, ResizableBox } from "react-resizable";
import { ButtonDownload } from "../../Components";
import { API } from "../../Services";
import { iconDownloadCustom } from "../../Assets/Images/svg";
import { urlSelection } from "../../Services/API";

export const ModalDraggableDetailLogAnalyzer = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
  const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props;

  const draggleRef2 = React.createRef<any>();

  useEffect(() => {
    dispatchStatePage({ type: "visible", value: showModal });
    dispatchStatePage({ type: "setData", value: itemModal });
    if (showModal === true) {
      if (itemModal.logName !== "") {
        console.log("test",itemModal.logName)
        API.get({
          bodyCustom: null,
          pathCustom: `dashboard/log/file/${itemModal.logName}`, // pathCustom: `dashboard/log/file/devgph230510212925298_20230510_212925_QRCPMPAYMENT_Req.log`,
          selectUrl: urlSelection.log, // use url log
          useToken: true,
          needLoading: true,
        }).then((response: any) => {
          dispatchStatePage({ type: 'data', value: response.data.file })
        })
      }
    }
  }, [statePage.visible !== showModal]);

  useEffect(() => {
    dispatchStatePage({ type: "id", value: id });
  }, [id !== statePage.id]);

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef2?.current?.getBoundingClientRect();
    dispatchStatePage({
      type: "bounds",
      value: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      },
    });
  };

  const exportFile = (fileName: any) => {
    // let element = (document.getElementById(`export-data${itemModal.logName}`) as HTMLFormElement).innerHTML.toString().replace(/<br\>/g, '\n');
    // let element = (document.getElementById(`export-data${itemModal.logName}`) as HTMLFormElement).innerHTML;
    let blob = new Blob([statePage.data], { type: "text/plain;charset=utf-8" });
    saveAs(blob, `${fileName}.txt`);
  };

  const handleState = (nameState: string | any, valueState: any) => {
    dispatchStatePage({ type: nameState, value: valueState });
  };

  const onResize = (event: any, propsSize: any) => {
    const { node, size, handle } = propsSize;
    if (size.width > 100 && size.height > 100) {
      dispatchStatePage({ type: "resize", value: { width: size.width, height: size.height } });
    }
  };


  return (
    <>
      {statePage.visible === true ? (
        <Modal
          mask={false}
          maskClosable={false}
          keyboard={false}
          width={window.innerWidth < 768 ? "85%" : ''}
          style={{
            position: "fixed",
            top: window.innerWidth < 768 ? "5%" : `2%`, // old value = calc(45% - ${statePage.height / 2}px
            left: window.innerWidth < 768 ? "5%" : `2%`, // old value = calc(50% - ${statePage.width / 2}px
            height: window.innerWidth < 768 ? "auto" : statePage.height,
            zIndex: index,
          }}
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (statePage.disabled) {
                  handleState("disabled", false);
                }
              }}
              onMouseOut={() => {
                handleState("disabled", true);
              }}
            >
              <div className="flex justify-between items-end">
                <div className="flex">
                  <div className="flex flex-col justify-between ml-4">
                    {window.innerWidth < 768 ? <h5>{statePage.titleModal}</h5> : <h5>{statePage.titleModal}</h5>}
                  </div>
                </div>
              </div>
            </div>
          }
          footer={null}
          open={statePage.visible}
          onOk={() => actionShowModal()}
          onCancel={() => actionShowModal()}
          modalRender={(modal) => (
            <Draggable disabled={statePage.disabled} bounds={statePage.bounds} onStart={(event, uiData) => onStart(event, uiData)}>
              <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                {modal}
              </div>
            </Draggable>
          )}
        >
          <ResizableBox height={statePage.height} width={statePage.width} onResize={onResize} minConstraints={[300, 300]} maxConstraints={[1850, 850]}>
            <div style={{
              width: window.innerWidth < 768 ? "75%" : "100%",
              height: window.innerWidth < 768 ? "75%" : "100%"
            }}
            >
              <Divider className="m-0" />
              <div className="my-1 fs-5 fs-sm-6 fs-md-4">
                <div className="d-flex align-items-center my-2" style={{ overflow: "hidden" }}>
                  <div
                    className="h-40px d-flex align-items-center br-10 s-1822 wrap-text flex-fill text-overflow-ellipsis"
                    style={{
                      overflow: "hidden",
                      fontSize: window.innerWidth < 768 ? "12px" : ""
                    }}>
                    {itemModal.logName}
                  </div>
                  <div className="h-40px">
                    <div className="h-40px w-40px d-flex align-items-center">
                      <Tooltip placement="bottom" title={"Download File"}>
                        <div
                          className="btn d-flex flex-column align-items-center justify-content-center bd-d4 br-50 pointer mx-auto"
                          style={{
                            width: window.innerWidth < 768 ? "75%" : "100%",
                            height: window.innerWidth < 768 ? "75%" : "100%"
                          }}
                          onClick={() => exportFile(itemModal.logName)}
                        >
                          {window.innerWidth < 768 ? iconDownloadCustom(13, 13) : iconDownloadCustom(15, 15)}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="m-0" />
              <div className="d-flex mt-2">
                <div className="w-100 m-0 text-xs">
                  {statePage.data === null ? (
                    <></>
                  ) : (
                    <pre
                      style={{
                        height: statePage.height - 60,
                        overflow: "auto",
                        fontSize: window.innerWidth < 768 ? "10px" : "12px"
                      }}
                    >
                      {`${statePage.data}`}
                    </pre>
                  )}
                </div>
              </div>
            </div>
          </ResizableBox>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

const initialState = {
  titleModal: "Log Details",
  visible: false,
  disabled: true,
  bounds: false,
  errorMessage: "",
  errorMessageList: [],
  //
  data: null,
  //
  id: "",
  //
  modalDetail: false,
  width: 1200,
  height: 600,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "titleModal":
      return {
        ...state,
        titleModal: action.value,
      };
    case "visible":
      return {
        ...state,
        visible: action.value,
      };
    case "disabled":
      return {
        ...state,
        disabled: action.value,
      };
    case "bounds":
      return {
        ...state,
        bounds: action.value,
      };
    case "errorMessage":
      return {
        ...state,
        errorMessage: action.value,
      };
    case "errorMessageList":
      return {
        ...state,
        errorMessageList: action.value,
      };

    case "id":
      return {
        ...state,
        id: action.value,
      };
    //
    case "clearData":
      return {
        ...state,
        data: null,
        errorMessage: "",
      };
    case "setData":
      return {
        ...state,
        data: action.value,
        errorMessage: "",
      };
    case "data":
      return {
        ...state,
        data: action.value,
      };

    case "modalDetail":
      return {
        ...state,
        modalDetail: action.value,
      };
    case "resize":
      return {
        ...state,
        width: action.value.width,
        height: action.value.height,
      };

    default:
      throw new Error();
  }
};

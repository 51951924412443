import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { FilterMobileTSM } from './Modal/FilterMobileTSM';
import { useMemo } from 'react';
import { CheckUndefined, NumberWithCommas } from '../../../Utils';

export const MobileViewTSM = ({ ...props }) => {
    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom,
        handleStateComponent,
        handleNavigation,
        setPagination,
        pagination,
    } = props;
    // Mobile View
    const columnsMobile : any = useMemo(
        () => [
            {
                header: 'Transaction Date/Tiime',
                accessorKey: 'fullCreateDate',
                size: 165,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.fullCreateDate)}`,
            },
            {
                header: 'Product Feature',
                accessorKey: 'productFeatureName',
                size: 110,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.productFeatureName)}`,
            },
            {
                header: 'Issuer Switcher',
                accessorKey: 'issuerSwitcherName',
                size: 80,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.issuerSwitcherName)}`,
            },
            {
                header: 'Acquier Switcher',
                accessorKey: 'acquirerSwitcherName',
                size: 80,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.acquirerSwitcherName)}`,
            },
            {
                header: 'Transaction Amount',
                accessorKey: 'transAmount',
                size: 80,
                Cell: ({ row }: any) =>
                    `${row.original.transCurrency} ${NumberWithCommas(CheckUndefined(
                        row.original.transAmount
                    ))}`,
            },
            {
                header: 'Status',
                accessorKey: 'transStatus',
                size: 80,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.transStatus)}`,
            },
            {
                header: 'MID',
                accessorKey: 'mid',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.mid)}`,
            },
            {
                header: 'Merchant Name',
                accessorKey: 'merName',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.merName)}`,
            },
            {
                header: 'Wallet User Pan',
                accessorKey: 'walletUserPan',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.walletUserPan)}`,
            },
            {
                header: 'Posting Status',
                accessorKey: 'postStatus',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.postStatus)}`,
            },
        ],
        []
    );

    const tableMobile = useMaterialReactTable({
        columns: columnsMobile,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        enableEditing: false,
        enableRowActions: false,
        enableColumnActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginateExpandedRows: false,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            showRowsPerPage: false,
            size: 'small',
            color: 'standard',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableTopToolbar: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = tableMobile.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                onClick: () => handleNavigation(row.original),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '10px',
                    fontFamily: 'system-ui, sans-serif',
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '10px',
                fontWeight: 'bold',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '10px',
                fontFamily: 'system-ui, sans-serif',
                padding: '8px 16px',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        renderTopToolbarCustomActions: ({ table }) => (
            <div className="d-flex align-items-center py-3">
                <p className="Lato-400 s-2024 c-29">List</p>
                <p className="br-5 bd-ea bg-f4 px-3 py-2 c-29 Lato-400 s-1418 mx-2">
                    {state.totalData}
                </p>
            </div>
        ),
    });

    return (
        <>  
            <h1 className="Lato-300 normal w-600 s-1418 py-2">
                Transaction Inquiry
            </h1>
            <div className="d-flex justify-content-between">
                <div className="w-50 d-flex align-items-center my-2">
                    <p className="Lato-400 s-1418 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-1 c-29 Lato-400 s-1418 mx-1">
                        {state.totalData}
                    </p>
                </div>
                <div className="w-50 d-flex justify-content-end align-items-center">
                    <FilterMobileTSM
                        state={state}
                        stateOptions={stateOptions}
                        stateCustomComponent={stateCustomComponent}
                        handleStateParent={handleStateComponent}
                        handleStateGlobal={handleStateGlobal}
                        resetFilterStatus={resetFilterStatus}
                        initFunctionCustom={initFunctionCustom}
                    />
                </div>
            </div>
            <MaterialReactTable table={tableMobile} />
        </>
    );
};

import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { errNotificationIcon, icon_search_white } from '../../Assets/Images/svg';
import { ShowModalInterface } from '../../Interface';
import { API, urlSelection } from '../../Services/API';
import { format } from 'date-fns';
import {
    handleState,
    initFunction,
    reducerOptions,
    stateOptions,
    stateSelected
} from '../../State';
import { labelStyle } from '../../Styles';
import { InterfaceOptionsUserLogHistory } from './InterfaceOptionsUserLogHistory';
import { ModelUserLogHistory } from './ModelUserLogHistory';
import { Page11Title } from '../../State/Menu/TitleMenu';
import ModalDraggableUserLogHistory from './ModalDraggableUserLogHistory';
import { CheckUndefined, ExportCSV } from '../../Utils';
import { TableUserAuditTrail } from './TableUserAuditTrail';
import { DatePickerRighIcon } from '../../Components/DatepickerRightIcon';
import { IsDateRangeWithinDays } from '../../Utils/IsDateRangeWithinDays';

const ExcelUserLogHistory = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        const m = {
            no: i + 1,
            userId: CheckUndefined(`${item.userId} - ${item.username}`),
            screenName: CheckUndefined(item.screenName),
            logFileName: CheckUndefined(item.logFileName),
            crudType: CheckUndefined(item.crudType),
            before: CheckUndefined(item.before),
            after: CheckUndefined(item.after),
            logCreateDt: CheckUndefined(item.logCreateDt),
            logSourceIp: CheckUndefined(item.logSourceIp),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};

const Page6 = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: state.view,
    });

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])


    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let selectedStartDate = `startDate=${state.startDate} ${state.startTime}&`;
        let selectedEndDate = `endDate=${state.endDate} ${state.endTime}&`;
        let finalParameter = `${searchValue}${limit}${offset}${selectedStartDate}${selectedEndDate}`;
        let finalParameter2 = `${searchValue}${selectedStartDate}${selectedEndDate}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response?.data });
                dispatch({ type: 'totalPages', value: response?.detail.totalPage });
                dispatch({ type: 'totalData', value: response?.detail.total });
                /* Refresh Page */
                setPagination({pageSize: state.view, pageIndex: 0})
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response?.data });
                dispatch({ type: 'totalPages', value: response?.detail.totalPage });
                dispatch({ type: 'totalData', value: response?.detail.total });
            })
        }
    }


    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsUserLogHistory, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        if (state.view !== pagination.pageSize) {
            initFunctionCustom(true);
            setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
        } else {
            initFunctionCustom(false);
        }
    }, [pagination.pageIndex, state.view]);

    useEffect(() => {
        if (state.startDate !== '' && state.endDate !== '') {
            let dateStart : any = new Date(state.startDate);
            let dateEnd :any = new Date(state.endDate);
    
            if (dateStart > dateEnd) {
                dispatch({
                    type: 'errorMessage',
                    value: 'Start date cannot be larger than End Date',
                });
                return;
            }
    
            if (IsDateRangeWithinDays(dateStart, dateEnd, 7)) {
                dispatch({
                    type: 'errorMessage',
                    value: 'Maximum range for start~end date is 7 days',
                });
            } else {
                let listAllDayChart = [];
                for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                    listAllDayChart.push(d.toISOString().slice(0, 10));
                }
    
                if (state.errorMessage !== '') {
                    dispatch({ type: 'errorMessage', value: '' });
                }
            }
        }
    }, [state.startDate, state.endDate]);
    
    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelUserLogHistory(state, stateOptions, response.data)
        })
    }

    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const filterComponentPage = () => {
        return (
            <>
                <div className="d-flex flex-column w-100">
                    <div className='row bg-ff br-10 bd-d4 gx-2 gy-2 px-2 pb-3 mb-2'>
                    <div className="col-xl-2 col-md-4 col-sm-6">
                        <p className={labelStyle}>START DATE TIME</p>
                        <div className="flex">
                            {
                                state.startTime === '' ?
                                    <></>
                                    :
                                    <DatePickerRighIcon 
                                    maxDate={new Date()}
                                    selected={new Date(`${state.startDate} ${state.startTime}` || '')}
                                    onChange={(date: any) => {
                                        dispatch({ type: 'startDate', value: format(date, 'yyyy-MM-dd')});
                                        dispatch({ type: 'startTime', value: format(date, 'HH:mm:ss')});
                                    }}
                                    />
                                }
                        </div>

                    </div>
                        <div className="col-xl-2 col-md-4 col-sm-6">
                            <p className={labelStyle}>END DATE TIME</p>
                            <div className="flex">
                                {
                                    state.endTime === '' ?
                                        <></>
                                        :
                                        <DatePickerRighIcon
                                            maxDate={new Date()}
                                            selected={new Date(`${state.endDate} ${state.endTime}` || '')}
                                            onChange={(date: any) => {
                                                dispatch({ type: 'endDate', value: format(date, 'yyyy-MM-dd') });
                                                dispatch({ type: 'endTime', value: format(date, 'HH:mm:ss') });
                                            }}
                                        />
                                }
                        </div>
                        </div>
                        <div className='d-flex align-items-end justtify-content-start col-xl-2 col-md-4 col-sm-6' >
                            <button 
                                className={'btn d-flex align-items-center bg-e8 h-25px'} 
                                onClick={() => reloadDataComponent(true)} 
                                disabled={state.errorMessage !== ''}
                            >
                                {icon_search_white}
                                <p className='s-1418 c-ff Lato-600 mx-2'>Search</p>
                            </button>
                        </div>
                        {state.errorMessage !== '' && (
                            <div className="col-12 pl-0">
                                <div className="d-flex bd-e8 br-10 py-1 px-1 align-items-center wd-fit mt-2">
                                    {errNotificationIcon}
                                    <p className="px-2 s-1214 Lato-600 c-e87">
                                        {state.errorMessage}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableUserLogHistory
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                User Audit Trail
            </h1>
            <TableUserAuditTrail 
                state={state}
                stateOptions={stateOptions}
                exportToCSVComponent={exportToCSVComponent}
                handleStateComponent={handleStateComponent}
                filterComponentPage={filterComponentPage}
                actionShowModal={actionShowModal}
                pagination={pagination}
                setPagination={setPagination}
            />
            {modalUpdateComponentPage()}
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'log',
    titlePage: Page11Title,
    privilegeAccess: { view: '121', add: '', edit: '', delete: '', download: '' },
    toggle: ["no", "userId", "screenName", "logFileName", "crudType", "before", "after", "logCreateDt", "logSourceIp"],
    complete_toggle: ["no", "userId", "screenName", "logFileName", "crudType", "before", "after", "logCreateDt", "logSourceIp"],
    list_column_name: ["No.", "User ID", "Screen Name", "Activity", "CRUD", "Before Snapshot", "After Snapshot", "Activity Time", "Client IP Address"],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'User Name, Activity, Client IP Address',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1900,
    dataLength: 0, // zIndex
    finalAPI: '',
    errorMessage: '',

    startDate: new Date().toISOString().slice(0, 10),
    startTime: '00:00:00',
    endDate: new Date().toISOString().slice(0, 10),
    endTime: new Date().toTimeString().slice(0, 8)
};

function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelUserLogHistory) => {
                        element1.id = element1.logId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelUserLogHistory) => {
                        element1.id = element1.logId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelUserLogHistory) => {
                        showDataResult.push(
                            { idModal: element1.logId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.logId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'startDate':
            return {
                ...state,
                startDate: action.value
            };
        case 'startTime':
            return {
                ...state,
                startTime: action.value
            };
        case 'endDate':
            return {
                ...state,
                endDate: action.value
            };
        case 'endTime':
            return {
                ...state,
                endTime: action.value
            };
        default:
            throw new Error();
    }
}

export default Page6;
import { interfaceIcon } from "../../../Assets/Images/MenuIcon";
import { Page1ProductionSimRoute, Page2ProductionSimRoute } from "../../../Routes";
import { Page57ACode, Page57Code } from "../CodeMenu";
import { Page57Title, Page57ATitle } from "../TitleMenu";

export const Menu15 = {
    id: 'prod_simulator_sidebar',
    label: 'Production Simulator',
    icon: interfaceIcon,
    privilegesId: [Page57Code],
    itemSidebar: [
        {
            titleList: Page57Title,
            key: 'ProductionSimulatorOptions1',
            privilegeId: Page57Code,
            url: Page1ProductionSimRoute,
        },
        {
            titleList: Page57ATitle,
            key: 'ProductionSimulatorOptions2',
            privilegeId: Page57ACode,
            url: Page2ProductionSimRoute,
        }
    ],
}
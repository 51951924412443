
// InterfaceHeader

interface InterfaceHeaderInterface {
    id: null | number | string;
    headerFieldId: number | null;
    headerFieldName: string | null;
    headerFieldDesc: string | null;
    headerFieldLength: number | null;
    headerFieldType: string | null;
    headerFieldMappingCode: string | null;

}

export class ModelInterfaceHeader {
    id: null | number | string;
    headerFieldId: number | null;
    headerFieldName: string | null;
    headerFieldDesc: string | null;
    headerFieldLength: number | null;
    headerFieldType: string | null;
    headerFieldMappingCode: string | null;


    constructor(value: InterfaceHeaderInterface | null) {
        this.id = value?.id ?? null;
        this.headerFieldId= value?.headerFieldId ?? null;
        this.headerFieldName= value?.headerFieldName ?? null;
        this.headerFieldDesc= value?.headerFieldDesc ?? null;
        this.headerFieldLength= value?.headerFieldLength ?? null;
        this.headerFieldType= value?.headerFieldType ?? null;
        this.headerFieldMappingCode= value?.headerFieldMappingCode ?? null;
    }
}

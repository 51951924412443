import { chartIcon } from "../../../Assets/Images/MenuIcon";
import { Page1DashboardRoute, Page2DashboardRoute, Page3DashboardRoute, Page4DashboardRoute, Page5DashboardRoute, PageDashboardQRAuthStatisticRoute, PageMainDashboardRoute } from "../../../Routes";
import { Page1Code, Page2Code, Page3Code, Page4Code, Page58Code, PageQRAuthStatisticCode, Page5Code, Page6Code, PageMainDashboardCode } from "../CodeMenu";
import { Page1Title, Page2Title, Page3Title, Page4Title, Page58Title, PageQRAuthStatisticTitle, PageMainDashboardTitle } from "../TitleMenu";

export const menu1 =
{
    id: 'authorization_sidebar',
    label: 'Authorization',
    icon: chartIcon,
    privilegesId: [Page1Code, Page2Code, Page3Code, Page4Code, Page5Code, Page6Code],
    itemSidebar: [
        {
            titleList: PageMainDashboardTitle,
            key: 'authorizationOptions1',
            privilegeId: PageMainDashboardCode,
            url: PageMainDashboardRoute,
        },
        {
            titleList: Page1Title,
            key: 'authorizationOptions2',
            privilegeId: Page1Code,
            url: Page1DashboardRoute,
        },
        {
            titleList: Page2Title,
            key: 'authorizationOptions3',
            privilegeId: Page2Code,
            url: Page2DashboardRoute,
        },
        {
            titleList: Page3Title,
            key: 'authorizationOptions4',
            privilegeId: Page3Code,
            url: Page3DashboardRoute,
        },
        {
            titleList: Page4Title,
            key: 'authorizationOptions5',
            privilegeId: Page4Code,
            url: Page4DashboardRoute,
        },
        {
            titleList: PageQRAuthStatisticTitle,
            key: 'authorizationOptions6',
            privilegeId: PageQRAuthStatisticCode,
            url: PageDashboardQRAuthStatisticRoute
        },
        {
            titleList: Page58Title,
            key: 'authorizationOptions7',
            privilegeId: Page58Code,
            url: Page5DashboardRoute,
        }
    ],
};
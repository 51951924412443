import React, { useEffect, useReducer } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker, DatePickerProps, Modal } from 'antd';
import Draggable from 'react-draggable';
import Select from 'react-select';
import { DatePickerRighIcon, FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { RangePickerProps } from 'antd/es/date-picker';
import { Page17Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableExchangeRateMember = ({ ...props }) => {
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsMember, optionsCommonStatusDetailCode, optionsRateSetting } = props.stateOptions

    const draggleRef = React.createRef<any>();


    useEffect(() => {

        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const updateDataAPI = () => {
        const { ratemId, memberId, rateId, ratemStatus, ratemStartDate, ratemEndDate } = statePage
        const a = CheckAllFields([memberId, rateId, ratemStatus, ratemStartDate, ratemEndDate], ['Member Id', 'Exchange Rate', 'Status', 'Start Date', 'End Date'])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            let dateStart = new Date(ratemStartDate);
            let dateEnd = new Date(ratemEndDate);
            let listAllDayChart: any[] = [];
            let errorMessage: string = '';
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (ratemStartDate === ratemEndDate) {
                if (statePage.errorMessage !== '') {
                    errorMessage = 'Start Date and End Date cannot be same'
                    handleState('errorMessage', errorMessage);
                }
            } else if (listAllDayChart.length === 0) {
                errorMessage = 'Start date cannot be larger than End Date'
                handleState('errorMessage', errorMessage);
            } else {
                if (statePage.errorMessage !== '') {
                    errorMessage = '';
                    handleState('errorMessage', errorMessage);
                }
            }

            const data = {
                ratemId: id,
                memberId: memberId,
                rateId: rateId,
                ratemStatus: ratemStatus,
                ratemStartDate: ratemStartDate,
                ratemEndDate: ratemEndDate,
            }
            if (errorMessage === '') {
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    actionAfterUpdateItem(id)
                    actionShowModal()
                }).catch((err) => handleState('errorMessage', err))
            }
        }
    };

    const onOkRatemStartDate = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'ratemStartDate', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
        }
    };
    const onOkRatemEndDate = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'ratemEndDate', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={600}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                top: '10vw',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                    <hr className='c-7f7'/>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column'>
                <div className='row pb-2 mx-0 mb-3'>
                    <div className={'col-6 mb-2'}>
                        <p className={labelStyle}>Member Id</p>
                        <Select styles={inputStyleH25} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e: any) => handleState("memberId", e.value)} />
                    </div>
                    <div className={'col-6 mb-2'}>
                        <p className={labelStyle} >Exchange Rate</p>
                        <Select styles={inputStyleH25} options={optionsRateSetting} value={optionsRateSetting.filter((option: any) => option.value === statePage.rateId)} onChange={(e: any) => handleState("rateId", e.value)} />
                    </div>
                    <div className="col-6 mb-2">
                        <p className={labelStyle}>Start date</p>
                        <DatePickerRighIcon
                            selected={statePage.ratemStartDate && statePage.ratemStartDate !== ''
                                ? new Date(`${statePage.ratemStartDate}` || '') 
                                : null
                            }
                            onChange={(value: any) => {
                                let date = new Date(value!.toString());
                                let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
                                let hours = date.getHours().toString().padStart(2, '0');
                                let minutes = date.getMinutes().toString().padStart(2, '0');
                                let seconds = date.getSeconds().toString().padStart(2, '0');
                                let timeNow = `${hours}:${minutes}:${seconds}`;
                                dispatchStatePage({ type: 'ratemStartDate', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
                            }}
                        />
                    </div>
                    <div className="col-6 mb-2">
                        <p className={labelStyle}>End date</p>
                        <DatePickerRighIcon
                            selected={statePage.ratemEndDate && statePage.ratemEndDate !== ''
                                ? new Date(`${statePage.ratemEndDate}` || '') 
                                : null
                            }
                            onChange={(value: any) => {
                                let date = new Date(value!.toString());
                                let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
                                let hours = date.getHours().toString().padStart(2, '0');
                                let minutes = date.getMinutes().toString().padStart(2, '0');
                                let seconds = date.getSeconds().toString().padStart(2, '0');
                                let timeNow = `${hours}:${minutes}:${seconds}`;
                                dispatchStatePage({ type: 'ratemEndDate', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })                                   }}
                        />
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>Status</p>
                        <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.ratemStatus)} onChange={(e) => handleState("ratemStatus", e.value)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />

            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page17Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    //
    prodFeatureId: '',
    productId: '',
    prodFeatureName: '',
    prodFeatureDesc: '',
    prodFeatureStatus: '001',
    prodFeatureIsDisplay: '',
    prodFeatureInd: '',
    productName: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                ratemId: '',
                memberId: '',
                rateId: '',
                ratemStatus: '001',
                ratemStartDate: new Date().toISOString().slice(0, 10),
                ratemEndDate: new Date("2099-12-31").toISOString().slice(0, 10),
                memberName: '',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                ratemId: action.value.ratemId,
                memberId: action.value.memberId,
                rateId: action.value.rateId,
                ratemStatus: action.value.ratemStatus,
                ratemStartDate: action.value.ratemStartDate === null || action.value.ratemStartDate === undefined ? '' : action.value.ratemStartDate.toString().split('T')[0],
                ratemEndDate: action.value.ratemEndDate === null || action.value.ratemEndDate === undefined ? '' : action.value.ratemEndDate.toString().split('T')[0],
                memberName: action.value.memberName,
                errorMessage: '',
            };
        // 
        case 'ratemId':
            return {
                ...state,
                ratemId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'rateId':
            return {
                ...state,
                rateId: action.value,
            };
        case 'ratemStatus':
            return {
                ...state,
                ratemStatus: action.value,
            };
        case 'ratemStartDate':
            return {
                ...state,
                ratemStartDate: action.value,
            };
        case 'ratemEndDate':
            return {
                ...state,
                ratemEndDate: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        // 
        // 
        default:
            throw new Error();
    }
}


import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAllFields, HasWhiteSpace } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page24Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableQRStandard = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsQrMpmCpm, optionsFormat, optionsCommonStatusDetailCode, optionsCountry } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'qrsName') {
            if (valueState.split('').length < 33) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'qrsCode') {
            if (valueState.split('').length < 5) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const { qrsName, qrsDesc, qrsCode, qrsStatus, qrsFormat, qrsCpmMpm, countryId, countryName, errorMessage } = statePage;
        const data = {
            qrsId: id,
            qrsName: qrsName,
            qrsDesc: qrsDesc,
            qrsCode: qrsCode,
            qrsStatus: qrsStatus,
            qrsFormat: qrsFormat,
            qrsCpmMpm: qrsCpmMpm,
            countryId: countryId
        };

        const a = CheckAllFields([qrsName, qrsCode, qrsStatus, qrsFormat, qrsCpmMpm, countryId], ["Name", "Code", "Status", "Format", "Mode", "Country"]);

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-6'>
                        <p className={labelStyle}>NAME</p>
                        <input value={statePage.qrsName} onChange={(e) => handleState('qrsName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Code</p>
                        <input value={statePage.qrsCode} onChange={(e) => handleState('qrsCode', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Country</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCountry}
                            value={optionsCountry.filter((option: any) => option.value === statePage.countryId)}
                            onChange={(e) => handleState('countryId', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Mode</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsQrMpmCpm}
                            value={optionsQrMpmCpm.filter((option: any) => option.value === statePage.qrsCpmMpm)}
                            onChange={(e) => handleState('qrsCpmMpm', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Format</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsFormat}
                            value={optionsFormat.filter((option: any) => option.value === statePage.qrsFormat)}
                            onChange={(e) => handleState('qrsFormat', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>STATUS</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCommonStatusDetailCode}
                            value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.qrsStatus)}
                            onChange={(e) => handleState('qrsStatus', `${e.value}`)}
                        />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.qrsDesc} onChange={(e) => handleState('qrsDesc', e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page24Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    qrsId: '',
    qrsName: '',
    qrsDesc: '',
    qrsCode: '',
    qrsStatus: '001', // default enable COM000
    qrsFormat: '', // QRS002
    qrsCpmMpm: '', //QRS001
    countryId: '',
    countryName: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                qrsId: '',
                qrsName: '',
                qrsDesc: '',
                qrsCode: '',
                qrsStatus: '001', // default enable COM000
                qrsFormat: '', // QRS002
                qrsCpmMpm: '', //QRS001
                countryId: '',
                countryName: '',
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                qrsId: action.value.qrsId,
                qrsName: action.value.qrsName,
                qrsDesc: action.value.qrsDesc,
                qrsCode: action.value.qrsCode,
                qrsStatus: action.value.qrsStatus,
                qrsFormat: action.value.qrsFormat,
                qrsCpmMpm: action.value.qrsCpmMpm,
                countryId: action.value.countryId,
                countryName: action.value.countryName,
                errorMessage: "",
            };
        // 
        case 'qrsId':
            return {
                ...state,
                qrsId: action.value,
            };
        case 'qrsName':
            return {
                ...state,
                qrsName: action.value,
            };
        case 'qrsDesc':
            return {
                ...state,
                qrsDesc: action.value,
            };
        case 'qrsCode':
            return {
                ...state,
                qrsCode: action.value,
            };
        case 'qrsStatus':
            return {
                ...state,
                qrsStatus: action.value,
            };
        case 'qrsFormat':
            return {
                ...state,
                qrsFormat: action.value,
            };
        case 'qrsCpmMpm':
            return {
                ...state,
                qrsCpmMpm: action.value,
            };
        case 'countryId':
            return {
                ...state,
                countryId: action.value,
            };
        case 'countryName':
            return {
                ...state,
                countryName: action.value,
            };
        default:
            throw new Error();
    }
}





import { menu1 } from "./All/Menu1";
import { Menu10 } from "./All/Menu10";
import { Menu11 } from "./All/Menu11";
import { Menu12 } from "./All/Menu12";
import { Menu13 } from "./All/Menu13";
import { Menu14 } from "./All/Menu14";
import { menu2 } from "./All/Menu2";
import { Menu3 } from "./All/Menu3";
import { Menu4 } from "./All/Menu4";
import { Menu5 } from "./All/Menu5";
import { Menu6 } from "./All/Menu6";
import { Menu7 } from "./All/Menu7";
import { Menu8 } from "./All/Menu8";
import { Menu9 } from "./All/Menu9";
import { Menu15 } from "./All/Menu15";
import { Menu16 } from "./All/Menu16";

const MenuSideBar = [
    menu1,
    menu2,
    Menu3,
    Menu4,
    Menu5,
    Menu6,
    Menu7,
    Menu8,
    Menu15,
    Menu9,
    Menu10,
    Menu11,
    Menu12,
    Menu13,
    Menu14,
    Menu16
]
const ShowMenuSideBar = [
    {
        id: 'authorization_sidebar',
        displayDropdown: true,
        activeMenu: true,
    },
    {
        id: 'settlement_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'member_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'user_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'product_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'rate_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'fee_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'framework_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'prod_simulator_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'QR_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'interface_API_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'interface_file_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'job_scheduler_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'code_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'system_network_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
    {
        id: 'alert_history_sidebar',
        displayDropdown: false,
        activeMenu: false,
    },
]

export {
    MenuSideBar,
    ShowMenuSideBar
};

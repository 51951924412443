import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { inputStyle, labelStyle } from "../../Styles";
import { useCallback, useEffect, useReducer } from "react";

import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
import ModalDetailSettlementSummaryIssuer from "./ModalDetailSettlementSummaryIssuer";
import { close } from "../../Assets/Images/svg";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { stateSelected } from "../../State";
import { urlSelection } from "../../Services/API";
import { useDispatch } from "react-redux";

const connectionBetweenRespond = [
  {
    codeTransaction: "001",
    keyCodeTransaction: "transTypeSale",
    keyCountedTransaction: "countSale",
    keyCurrName: "saleCurrName",
    keyAmountTransaction: "amountSale",

    keyFeeCurrentName: "saleSettCurrName",
    keyFee: "amountSettFeeSale",
    keySettlementCurrName: "saleSettCurrName",
    keySettlementAmount: "amountSettSale",
  },
  {
    codeTransaction: "002",
    keyCodeTransaction: "transTypeRefund",
    keyCountedTransaction: "countRefund",
    keyCurrName: "refundCurrName",
    keyAmountTransaction: "amountRefund",

    keyFeeCurrentName: "refundSettCurrName",
    keyFee: "amountSettFeeRefund",
    keySettlementCurrName: "refundSettCurrName",
    keySettlementAmount: "amountSettRefund",
  },

  {
    codeTransaction: "003",
    keyCodeTransaction: "transTypeReversal",
    keyCountedTransaction: "countReversal",
    keyCurrName: "revCurrName",
    keyAmountTransaction: "amountReversal",

    keyFeeCurrentName: "revSettCurrName",
    keyFee: "amountSettFeeReversal",
    keySettlementCurrName: "revSettCurrName",
    keySettlementAmount: "amountSettReversal",
  },

  {
    codeTransaction: "004",
    keyCodeTransaction: "transTypeChargeback",
    keyCountedTransaction: "countChargeback",
    keyCurrName: "chbkCurrName",
    keyAmountTransaction: "amountChargeback",

    keyFeeCurrentName: "chbkSettCurrName",
    keyFee: "amountSettFeeChargeback",
    keySettlementCurrName: "chbkSettCurrName",
    keySettlementAmount: "amountSettChargeback",
  },

  {
    codeTransaction: "005",
    keyCodeTransaction: "transTypeRepresentment",
    keyCountedTransaction: "countRepresentment",
    keyCurrName: "repreCurrName",
    keyAmountTransaction: "amountRepresentment",

    keyFeeCurrentName: "repreSettCurrName",
    keyFee: "amountSettFeeRepresentment",
    keySettlementCurrName: "repreSettCurrName",
    keySettlementAmount: "amountSettRepresentment",
  },

  {
    codeTransaction: "006",
    keyCodeTransaction: "transTypeChargebackReversal",
    keyCountedTransaction: "countChargebackReversal",
    keyCurrName: "chbkCurrName",
    keyAmountTransaction: "amountChargebackReversal",

    keyFeeCurrentName: "chbkRevSettCurrName",
    keyFee: "amountSettFeeChargebackReversal",
    keySettlementCurrName: "chbkRevSettCurrName",
    keySettlementAmount: "amountSettChargebackReversal",
  },

  {
    codeTransaction: "007",
    keyCodeTransaction: "transTypeRepresentmentReversal",
    keyCountedTransaction: "countRepresentmentReversal",
    keyCurrName: "repreRevCurrName",
    keyAmountTransaction: "amountRepresentmentReversal",

    keyFeeCurrentName: "repreRevSettCurrName",
    keyFee: "amountSetttFeeRepresentmenReversal",
    keySettlementCurrName: "repreRevSettCurrName",
    keySettlementAmount: "amountSettRepresentmentReversal",
  },
];

interface testData {
  memberCcdCode: string | null;
  memberCcdName: string | null;
  currencyCode: string | null;
  totalCurrName: string | null;
  saleCurrName: string | null;
  refundCurrName: string | null;
  revCurrName: string | null;
  chbkCurrName: string | null;
  repreCurrName: string | null;
  chbkRevCurrName: string | null;
  repreRevCurrName: string | null;
  countSale: number | null;
  amountSale: number | null;

  countRefund: number | null;
  amountRefund: number | null;

  countReversal: number | null;
  amountReversal: number | null;

  countChargeback: number | null;
  amountChargeback: number | null;

  countRepresentment: number | null;
  amountRepresentment: number | null;
  countChargebackReversal: number | null;
  amountChargebackReversal: number | null;
  countRepresentmentReversal: number | null;
  amountRepresentmentReversal: number | null;
  totalAmount: number | null;
  transTypeSale: string | null;
  transTypeRefund: string | null;
  transTypeReversal: string | null;
  transTypeChargeback: string | null;
  transTypeRepresentment: string | null;
  transTypeChargebackReversal: string | null;
  transTypeRepresentmentReversal: string | null;

  // new response

  saleSettCurrName: string | null;
  amountSettFeeSale: number | null;
  amountSettSale: number | null;
  refundSettCurrName: string | null;
  amountSettFeeRefund: number | null;
  amountSettRefund: number | null;
  revSettCurrName: string | null;
  amountSettFeeReversal: number | null;
  amountSettReversal: number | null;
  chbkSettCurrName: string | null;
  amountSettFeeChargeback: number | null;
  amountSettChargeback: number | null;

  repreSettCurrName: string | null;
  amountSettFeeRepresentment: number | null;
  amountSettRepresentment: number | null;

  chbkRevSettCurrName: string | null;
  amountSettFeeChargebackReversal: number | null;
  amountSettChargebackReversal: number | null;
}

class ModelTest {
  memberCcdCode: string | null;
  memberCcdName: string | null;
  currencyCode: string | null;
  totalCurrName: string | null;
  saleCurrName: string | null;
  refundCurrName: string | null;
  revCurrName: string | null;
  chbkCurrName: string | null;
  repreCurrName: string | null;
  chbkRevCurrName: string | null;
  repreRevCurrName: string | null;
  countSale: number | null;
  amountSale: number | null;
  countRefund: number | null;
  amountRefund: number | null;
  countReversal: number | null;
  amountReversal: number | null;
  countChargeback: number | null;
  amountChargeback: number | null;
  countRepresentment: number | null;
  amountRepresentment: number | null;
  countChargebackReversal: number | null;
  amountChargebackReversal: number | null;
  countRepresentmentReversal: number | null;
  amountRepresentmentReversal: number | null;
  totalAmount: number | null;
  transTypeSale: string | null;
  transTypeRefund: string | null;
  transTypeReversal: string | null;
  transTypeChargeback: string | null;
  transTypeRepresentment: string | null;
  transTypeChargebackReversal: string | null;
  transTypeRepresentmentReversal: string | null;
  // new response

  saleSettCurrName: string | null;
  amountSettFeeSale: number | null;
  amountSettSale: number | null;
  refundSettCurrName: string | null;
  amountSettFeeRefund: number | null;
  amountSettRefund: number | null;
  revSettCurrName: string | null;
  amountSettFeeReversal: number | null;
  amountSettReversal: number | null;
  chbkSettCurrName: string | null;
  amountSettFeeChargeback: number | null;
  amountSettChargeback: number | null;

  repreSettCurrName: string | null;
  amountSettFeeRepresentment: number | null;
  amountSettRepresentment: number | null;

  chbkRevSettCurrName: string | null;
  amountSettFeeChargebackReversal: number | null;
  amountSettChargebackReversal: number | null;

  constructor(value: testData | null) {
    this.memberCcdCode = value?.memberCcdCode ?? null;
    this.memberCcdName = value?.memberCcdName ?? null;
    this.currencyCode = value?.currencyCode ?? null;
    this.totalCurrName = value?.totalCurrName ?? null;
    this.saleCurrName = value?.saleCurrName ?? null;
    this.refundCurrName = value?.refundCurrName ?? null;
    this.revCurrName = value?.revCurrName ?? null;
    this.chbkCurrName = value?.chbkCurrName ?? null;
    this.repreCurrName = value?.repreCurrName ?? null;
    this.chbkRevCurrName = value?.chbkRevCurrName ?? null;
    this.repreRevCurrName = value?.repreRevCurrName ?? null;
    this.countSale = value?.countSale ?? null;
    this.amountSale = value?.amountSale ?? null;
    this.countRefund = value?.countRefund ?? null;
    this.amountRefund = value?.amountRefund ?? null;
    this.countReversal = value?.countReversal ?? null;
    this.amountReversal = value?.amountReversal ?? null;
    this.countChargeback = value?.countChargeback ?? null;
    this.amountChargeback = value?.amountChargeback ?? null;
    this.countRepresentment = value?.countRepresentment ?? null;
    this.amountRepresentment = value?.amountRepresentment ?? null;
    this.countChargebackReversal = value?.countChargebackReversal ?? null;
    this.amountChargebackReversal = value?.amountChargebackReversal ?? null;
    this.countRepresentmentReversal = value?.countRepresentmentReversal ?? null;
    this.amountRepresentmentReversal = value?.amountRepresentmentReversal ?? null;
    this.totalAmount = value?.totalAmount ?? null;
    this.transTypeSale = value?.transTypeSale ?? null;
    this.transTypeRefund = value?.transTypeRefund ?? null;
    this.transTypeReversal = value?.transTypeReversal ?? null;
    this.transTypeChargeback = value?.transTypeChargeback ?? null;
    this.transTypeRepresentment = value?.transTypeRepresentment ?? null;
    this.transTypeChargebackReversal = value?.transTypeChargebackReversal ?? null;
    this.transTypeRepresentmentReversal = value?.transTypeRepresentmentReversal ?? null;

    // new response

    this.saleSettCurrName = value?.saleSettCurrName ?? null;
    this.amountSettFeeSale = value?.amountSettFeeSale ?? null;
    this.amountSettSale = value?.amountSettSale ?? null;
    this.refundSettCurrName = value?.refundSettCurrName ?? null;
    this.amountSettFeeRefund = value?.amountSettFeeRefund ?? null;
    this.amountSettRefund = value?.amountSettRefund ?? null;
    this.revSettCurrName = value?.revSettCurrName ?? null;
    this.amountSettFeeReversal = value?.amountSettFeeReversal ?? null;
    this.amountSettReversal = value?.amountSettReversal ?? null;
    this.chbkSettCurrName = value?.chbkSettCurrName ?? null;
    this.amountSettFeeChargeback = value?.amountSettFeeChargeback ?? null;
    this.amountSettChargeback = value?.amountSettChargeback ?? null;

    this.repreSettCurrName = value?.repreSettCurrName ?? null;
    this.amountSettFeeRepresentment = value?.amountSettFeeRepresentment ?? null;
    this.amountSettRepresentment = value?.amountSettRepresentment ?? null;

    this.chbkRevSettCurrName = value?.chbkRevSettCurrName ?? null;
    this.amountSettFeeChargebackReversal = value?.amountSettFeeChargebackReversal ?? null;
    this.amountSettChargebackReversal = value?.amountSettChargebackReversal ?? null;
  }
}

export const ModalSettlementSummaryIssuer = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { stateOptions, stateParent, dispatchParent, modalCondition, setModal } = props;
  const { optionsTransactionTypeDetailCodeAndCcdDesc } = stateOptions;

  useEffect(() => {
    let dateNow = new Date().toISOString().slice(0, 10);
    if (stateParent.whatIsSelectedNow === "issuerId") {
      let newFilterLeftAndRightTable = `filterAcqId=${stateParent.filterAcqId}&filterIssId=${stateParent.filterIssId}&filterAcqSwitchId=${stateParent.filterAcqSwitchId}&filterIssSwitchId=${stateParent.filterIssSwitchId}&`;
      let selectedIssuerId = stateParent.selectedIssuerId !== "" ? `issId=${stateParent.selectedIssuerId}&` : "";
      let selectedIssuerSettleDate = stateParent.selectedIssuerSettleDate !== "" ? `settleDate=${stateParent.selectedIssuerSettleDate}&` : "";
      let finalParameter = `${selectedIssuerId}${selectedIssuerSettleDate}${newFilterLeftAndRightTable}`;
      if (selectedIssuerId !== "" && selectedIssuerSettleDate !== "") {
        dispatch({ type: "finalParameterLeftAPI", value: `${finalParameter.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/member_recon_group?${finalParameter.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataLeftAPI", value: response.data }));
      }
      let selectedAcquirerSwitchId = stateParent.selectedAcquirerSwitchId !== "" ? `acqSwitchId=${stateParent.selectedAcquirerSwitchId}&` : "";
      //
      let selectedFirstDate = stateParent.selectedAcquirerSwitchSettleDate !== "" ? `firstDate=${stateParent.selectedAcquirerSwitchSettleDate}&` : "";
      let selectedSecondDate = stateParent.selectedIssuerSettleDate !== "" ? `secondDate=${stateParent.selectedIssuerSettleDate}&` : `secondDate=${dateNow}&`;
      let selectedFirstName = `firstName=acqSwitch&`;
      let selectedSecondName = `secondName=iss&`;
      let finalParameter2 = `${selectedAcquirerSwitchId}${selectedFirstDate}${selectedSecondDate}${selectedFirstName}${selectedSecondName}${newFilterLeftAndRightTable}`;
      if (selectedAcquirerSwitchId !== "" && selectedFirstDate !== "") {
        dispatch({ type: "finalParameterRightAPI", value: `${finalParameter2.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/trans_post_group?${finalParameter2.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataRightAPI", value: response.data }));
      }
    }
  }, [stateParent.selectedIssuerId, stateParent.selectedIssuerSettleDate, stateParent.selectedAcquirerSwitchSettleDate, stateParent.whatIsSelectedNow]);

  useEffect(() => {
    let dateNow = new Date().toISOString().slice(0, 10);
    if (stateParent.whatIsSelectedNow === "acquirerId") {
      let newFilterLeftAndRightTable = `filterAcqId=${stateParent.filterAcqId}&filterIssId=${stateParent.filterIssId}&filterAcqSwitchId=${stateParent.filterAcqSwitchId}&filterIssSwitchId=${stateParent.filterIssSwitchId}&`;
      let selectedAcquirerId = stateParent.selectedAcquirerId !== "" ? `acqId=${stateParent.selectedAcquirerId}&` : "";
      let selectedAcquirerSettleDate = stateParent.selectedAcquirerSettleDate !== "" ? `settleDate=${stateParent.selectedAcquirerSettleDate}&` : "";
      let finalParameter = `${selectedAcquirerId}${selectedAcquirerSettleDate}${newFilterLeftAndRightTable}`;
      if (selectedAcquirerId !== "" && selectedAcquirerSettleDate !== "") {
        dispatch({ type: "finalParameterLeftAPI", value: `${finalParameter.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/member_recon_group?${finalParameter.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataLeftAPI", value: response.data }));
      }
      let selectedAcquirerSwitchId = stateParent.selectedAcquirerSwitchId !== "" ? `acqSwitchId=${stateParent.selectedAcquirerSwitchId}&` : "";

      //
      let selectedFirstDate = stateParent.selectedAcquirerSwitchSettleDate !== "" ? `firstDate=${stateParent.selectedAcquirerSwitchSettleDate}&` : "";
      let selectedSecondDate = stateParent.selectedAcquirerSettleDate !== "" ? `secondDate=${stateParent.selectedAcquirerSettleDate}&` : `secondDate=${dateNow}&`;
      let selectedFirstName = `firstName=acqSwitch&`;
      let selectedSecondName = `secondName=acq&`;
      let finalParameter2 = `${selectedAcquirerSwitchId}${selectedFirstDate}${selectedSecondDate}${selectedFirstName}${selectedSecondName}${newFilterLeftAndRightTable}`;
      if (selectedAcquirerSwitchId !== "" && selectedFirstDate !== "") {
        dispatch({ type: "finalParameterRightAPI", value: `${finalParameter2.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/trans_post_group?${finalParameter2.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataRightAPI", value: response.data }));
      }
    }
  }, [stateParent.selectedAcquirerId, stateParent.selectedAcquirerSettleDate, stateParent.selectedAcquirerSwitchSettleDate, stateParent.whatIsSelectedNow]);

  useEffect(() => {
    let dateNow = new Date().toISOString().slice(0, 10);
    if (stateParent.whatIsSelectedNow === "acquirerSwitchId") {
      let newFilterLeftAndRightTable = `filterAcqId=${stateParent.filterAcqId}&filterIssId=${stateParent.filterIssId}&filterAcqSwitchId=${stateParent.filterAcqSwitchId}&filterIssSwitchId=${stateParent.filterIssSwitchId}&`;
      let selectedAcquirerSwitchId = stateParent.selectedAcquirerSwitchId !== "" ? `acqSwitchId=${stateParent.selectedAcquirerSwitchId}&` : "";
      let selectedAcquirerSwitchSettleDate = stateParent.selectedAcquirerSwitchSettleDate !== "" ? `settleDate=${stateParent.selectedAcquirerSwitchSettleDate}&` : "";
      //
      let selectedFirstDate = stateParent.selectedAcquirerSwitchSettleDate !== "" ? `firstDate=${stateParent.selectedAcquirerSwitchSettleDate}&` : "";
      let selectedSecondDate = stateParent.selectedAcquirerSwitchSettleDate !== "" ? `secondDate=${stateParent.selectedAcquirerSwitchSettleDate}&` : `secondDate=${dateNow}&`;
      let selectedFirstName = `firstName=acqSwitch&`;
      let selectedSecondName = `secondName=acqSwitch&`;
      let finalParameter = `${selectedAcquirerSwitchId}${selectedFirstDate}${selectedSecondDate}${selectedFirstName}${selectedSecondName}${newFilterLeftAndRightTable}`;
      if (selectedAcquirerSwitchId !== "" && selectedAcquirerSwitchSettleDate !== "") {
        dispatch({ type: "finalParameterLeftAPI", value: `${finalParameter.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/trans_post_group?${finalParameter.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataLeftAPI", value: response.data }));
      }
      if (selectedAcquirerSwitchId !== "" && selectedFirstDate !== "") {
        let finalParameter2 = `${selectedAcquirerSwitchId}${selectedFirstDate}${selectedSecondDate}${selectedFirstName}${selectedSecondName}${newFilterLeftAndRightTable}`;
        dispatch({ type: "finalParameterRightAPI", value: `${finalParameter2.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/trans_post_group?${finalParameter2.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataRightAPI", value: response.data }));
      }
    }
  }, [stateParent.selectedAcquirerSwitchId, stateParent.selectedAcquirerSwitchSettleDate, stateParent.whatIsSelectedNow]);

  useEffect(() => {
    let dateNow = new Date().toISOString().slice(0, 10);
    if (stateParent.whatIsSelectedNow === "issuerSwitchId") {
      let newFilterLeftAndRightTable = `filterAcqId=${stateParent.filterAcqId}&filterIssId=${stateParent.filterIssId}&filterAcqSwitchId=${stateParent.filterAcqSwitchId}&filterIssSwitchId=${stateParent.filterIssSwitchId}&`;
      let selectedIssuerSwitchId = stateParent.selectedIssuerSwitchId !== "" ? `issSwitchId=${stateParent.selectedIssuerSwitchId}&` : "";
      let selectedIssuerSwitchSettleDate = stateParent.selectedIssuerSwitchSettleDate !== "" ? `settleDate=${stateParent.selectedIssuerSwitchSettleDate}&` : "";
      let finalParameter = `${selectedIssuerSwitchId}${selectedIssuerSwitchSettleDate}${newFilterLeftAndRightTable}`;
      if (selectedIssuerSwitchId !== "" && selectedIssuerSwitchSettleDate !== "") {
        dispatch({ type: "finalParameterLeftAPI", value: `${finalParameter.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/member_recon_group?${finalParameter.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataLeftAPI", value: response.data }));
      }
      let selectedAcquirerSwitchId = stateParent.selectedAcquirerSwitchId !== "" ? `acqSwitchId=${stateParent.selectedAcquirerSwitchId}&` : "";
      let selectedFirstDate = stateParent.selectedAcquirerSwitchSettleDate !== "" ? `firstDate=${stateParent.selectedAcquirerSwitchSettleDate}&` : "";
      let selectedSecondDate = stateParent.selectedIssuerSwitchSettleDate !== "" ? `secondDate=${stateParent.selectedIssuerSwitchSettleDate}&` : `secondDate=${dateNow}&`;
      let selectedFirstName = `firstName=acqSwitch&`;
      let selectedSecondName = `secondName=issSwitch&`;
      let finalParameter2 = `${selectedAcquirerSwitchId}${selectedFirstDate}${selectedSecondDate}${selectedFirstName}${selectedSecondName}${newFilterLeftAndRightTable}`;
      if (selectedAcquirerSwitchId !== "" && selectedFirstDate !== "") {
        dispatch({ type: "finalParameterRightAPI", value: `${finalParameter2.slice(0, -1)}` });
        API.get({
          bodyCustom: null,
          pathCustom: `settlement/trans_post_group?${finalParameter2.slice(0, -1)}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response: any) => dispatch({ type: "dataRightAPI", value: response.data }));
      }
    }
  }, [stateParent.selectedIssuerSwitchId, stateParent.selectedIssuerSwitchSettleDate, stateParent.selectedAcquirerSwitchSettleDate, stateParent.whatIsSelectedNow]);

  const handleClickRowTable = (codeTransType: any) => {
    dispatch({ type: "handleClickRowTable", value: codeTransType });
  };

  useEffect(() => {
    if (state.detailModal === false) {
      dispatch({ type: "selectedTransType", value: "" });
    }
  }, [state.detailModal]);

  useEffect(() => {
    if (props.modalCondition === false) {
      dispatch({ type: "resetDataLeftAPI", value: "" });
      dispatch({ type: "resetDataRightAPI", value: "" });
    }
  }, [props.modalCondition]);

  const handleState = (nameState: string | any, valueState: any) => {
    dispatch({ type: nameState, value: valueState });
  };

  /*
    * show data currency and amount 
    saleCurrName if "" show USD => amountSale    
    refundCurrName if "" show USD => amountRefund
    revCurrName if "" show USD => amountReversal
    chbkCurrName if "" show USD => amountChargeback
    repreCurrName if "" show USD => amountRepresentment
    chbkRevCurrName if "" show USD => amountChargebackReversal
    chbkRevCurrName if "" show USD => amountRepresentmentReversal
    */
  const leftTable = () => {
    /*
     * Total Amount
     * Sales 3 USD 300 => dihitung +300
     * Reversal 1 USD 50 => dihitung 0
     * Refund 1 USD 100 => dihitung -100
     * chargeback 1 USD 200 => dihitung +200
     * represent 1 USD 100 => dihitung -100
     * Total amount : USD 300
     * Chargeback reversal, Represent Reversal : hide
     */

    let amountAll: any = 0;
    amountAll = state.dataLeftAPI.amountSale - state.dataLeftAPI.amountRefund + state.dataLeftAPI.amountChargeback - state.dataLeftAPI.amountRepresentment;
    //
    let amountAllFee: any = 0;
    amountAllFee = state.dataLeftAPI.amountSettFeeSale - state.dataLeftAPI.amountSettFeeRefund + state.dataLeftAPI.amountSettFeeChargeback - state.dataLeftAPI.amountSettFeeRepresentment;
    //
    let amountAllSettlement: any = 0;
    amountAllSettlement = state.dataLeftAPI.amountSettSale - state.dataLeftAPI.amountSettRefund + state.dataLeftAPI.amountSettChargeback - state.dataLeftAPI.amountSettRepresentment;
    //
    /*
     * saleCurrName,
     * refundCurrName,
     * chbkRevCurrName,
     * repreRevCurrName
     * if all currency is same => show total amount currency, if not => show 'cannot calculate' => for table Transaction Amount, Fee, Settlement amount
     */
    let conditionShowAmount = false;
    conditionShowAmount = [state.dataLeftAPI.saleCurrName, state.dataLeftAPI.refundCurrName, state.dataLeftAPI.chbkRevCurrName, state.dataLeftAPI.repreRevCurrName].every((val, i, arr) => val === arr[0]);

    let conditionShowFeeAndSettlementAmount = false;
    conditionShowFeeAndSettlementAmount = [state.dataLeftAPI.saleSettCurrName, state.dataLeftAPI.refundSettCurrName, state.dataLeftAPI.chbkSettCurrName, state.dataLeftAPI.repreSettCurrName].every((val, i, arr) => val === arr[0]);

    return (
      <>
        <div className="p-0 m-0">
          <div className="d-flex justify-content-between px-1 py-3">
            <div className="w-200px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Trans Type</div>
            <div className="w-100px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Count</div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Transaction Amount</div>
            <div className="w-100px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Fee</div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Settlement Amount</div>
          </div>
          {optionsTransactionTypeDetailCodeAndCcdDesc.length > 0 &&
            optionsTransactionTypeDetailCodeAndCcdDesc.map((item1: any, index: number) => {
              let needColor = index % 2 === 0 ? "bg-f4" : "";
              let selectedCode = connectionBetweenRespond.filter((item2: any) => item2.codeTransaction === item1.value);
              if (item1.value === "900" || item1.value === "006" || item1.value === "007") return <></>;
              return (
                <>
                  <div
                    className={`d-flex justify-content-between align-items-center py-2 px-1 table-data-pointer  ${needColor}`}
                    onClick={() => {
                      handleClickRowTable(item1.value);
                    }}
                  >
                    <div className="w-200px word-wrap">{item1.label}</div>
                    <div className="w-100px h-20px word-wrap d-flex justify-content-between align-items-center">{CheckUndefinedStringEmpty(state.dataLeftAPI[selectedCode[0].keyCountedTransaction])}</div>
                    <div className="w-150px h-20px word-wrap d-flex justify-content-between align-items-center">
                      {CheckUndefinedStringEmpty(state.dataLeftAPI[selectedCode[0].keyCurrName])} {CheckUndefinedStringEmpty(NumberWithCommas(state.dataLeftAPI[selectedCode[0].keyAmountTransaction]))}
                    </div>

                    <div className="w-100px word-wrap d-flex justify-content-between align-items-center">
                      {CheckUndefinedStringEmpty(state.dataLeftAPI[selectedCode[0].keyFeeCurrentName])} {CheckUndefinedStringEmpty(NumberWithCommas(state.dataLeftAPI[selectedCode[0].keyFee]))}
                    </div>

                    <div className="w-150px word-wrap d-flex justify-content-between align-items-center">
                      {CheckUndefinedStringEmpty(state.dataLeftAPI[selectedCode[0].keyFeeCurrentName])} {CheckUndefinedStringEmpty(NumberWithCommas(state.dataLeftAPI[selectedCode[0].keySettlementAmount]))}
                    </div>
                  </div>
                </>
              );
            })}
          <div className="d-flex justify-content-between px-1 py-3">
            <div className="w-200px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 "></div>
            <div className="w-100px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 "></div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">
              {conditionShowAmount === true ? (
                <>
                  {CheckUndefinedStringEmpty(state.dataLeftAPI.totalCurrName)} {NumberWithCommas(amountAll)}
                </>
              ) : (
                <>Cannot calculate</>
              )}
            </div>
            <div className="w-100px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">
              {conditionShowFeeAndSettlementAmount === true ? (
                <>
                  {CheckUndefinedStringEmpty(state.dataLeftAPI.saleSettCurrName)} {NumberWithCommas(amountAllFee)}
                </>
              ) : (
                <>Cannot calculate</>
              )}
            </div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">
              {conditionShowFeeAndSettlementAmount === true ? (
                <>
                  {CheckUndefinedStringEmpty(state.dataLeftAPI.saleSettCurrName)} {NumberWithCommas(amountAllSettlement)}
                </>
              ) : (
                <>Cannot calculate</>
              )}
            </div>
          </div>
          <Divider />
        </div>
      </>
    );
  };

  /*
      * show data currency and amount 
      saleCurrName if "" show USD => amountSale    
      refundCurrName if "" show USD => amountRefund
      revCurrName if "" show USD => amountReversal
      chbkCurrName if "" show USD => amountChargeback
      repreCurrName if "" show USD => amountRepresentment
      chbkRevCurrName if "" show USD => amountChargebackReversal
      chbkRevCurrName if "" show USD => amountRepresentmentReversal
      */
  const rightTable = () => {
    /*
     * Total Amount
     * Sales 3 USD 300 => dihitung +300
     * Reversal 1 USD 50 => dihitung 0
     * Refund 1 USD 100 => dihitung -100
     * chargeback 1 USD 200 => dihitung +200
     * represent 1 USD 100 => dihitung -100
     * Total amount : USD 300
     * Chargeback reversal, Represent Reversal : hide
     */
    let amountAll: any = 0;
    amountAll = state.dataRightAPI.amountSale - state.dataRightAPI.amountRefund + state.dataRightAPI.amountChargeback - state.dataRightAPI.amountRepresentment;
    //
    let amountAllFee: any = 0;
    amountAllFee = state.dataRightAPI.amountSettFeeSale - state.dataRightAPI.amountSettFeeRefund + state.dataRightAPI.amountSettFeeChargeback - state.dataRightAPI.amountSettFeeRepresentment;
    //
    let amountAllSettlement: any = 0;
    amountAllSettlement = state.dataRightAPI.amountSettSale - state.dataRightAPI.amountSettRefund + state.dataRightAPI.amountSettChargeback - state.dataRightAPI.amountSettRepresentment;
    //
    /*
     * saleCurrName,
     * refundCurrName,
     * chbkRevCurrName,
     * repreRevCurrName
     * if all currency is same => show total amount currency, if not => show 'cannot calculate' => for table Transaction Amount, Fee, Settlement amount
     */
    let conditionShowAmount = false; // table column Transaction Amount
    conditionShowAmount = [state.dataRightAPI.saleCurrName, state.dataRightAPI.refundCurrName, state.dataRightAPI.chbkRevCurrName, state.dataRightAPI.repreRevCurrName].every((val, i, arr) => val === arr[0]);

    let conditionShowFeeAndSettlementAmount = false; // table column fee and settlemenent amount
    conditionShowFeeAndSettlementAmount = [state.dataRightAPI.saleSettCurrName, state.dataRightAPI.refundSettCurrName, state.dataRightAPI.chbkSettCurrName, state.dataRightAPI.repreSettCurrName].every((val, i, arr) => val === arr[0]);

    return (
      <>
        <div className="p-0 m-0">
          <div className="d-flex justify-content-between px-1 py-3">
            <div className="w-200px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Trans Type</div>
            <div className="w-100px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Count</div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Transaction Amount</div>
            <div className="w-100px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Fee</div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">Settlement Amount</div>
          </div>
          {optionsTransactionTypeDetailCodeAndCcdDesc.length > 0 &&
            optionsTransactionTypeDetailCodeAndCcdDesc.map((item1: any, index: number) => {
              let needColor = index % 2 === 0 ? "bg-f4" : "";
              let selectedCode = connectionBetweenRespond.filter((item2: any) => item2.codeTransaction === item1.value);
              if (item1.value === "900" || item1.value === "006" || item1.value === "007") return <></>;

              //   amountAll = parseInt(amountAll) + parseInt(CheckUndefinedStringEmpty(state.dataRightAPI[selectedCode[0].keyAmountTransaction]));

              return (
                <div
                  className={`d-flex justify-content-between align-items-center py-2 px-1 table-data-pointer  ${needColor}`}
                  onClick={() => {
                    handleClickRowTable(item1.value);
                  }}
                >
                  <div className="w-200px word-wrap ">{item1.label}</div>
                  <div className="w-100px h-20px word-wrap d-flex justify-content-between align-items-center">{CheckUndefinedStringEmpty(state.dataRightAPI[selectedCode[0].keyCountedTransaction])}</div>
                  <div className="w-150px h-20px word-wrap d-flex justify-content-between align-items-center">
                    {CheckUndefinedStringEmpty(state.dataRightAPI[selectedCode[0].keyCurrName])} {CheckUndefinedStringEmpty(NumberWithCommas(state.dataRightAPI[selectedCode[0].keyAmountTransaction]))}
                  </div>

                  <div className="w-100px word-wrap d-flex justify-content-between align-items-center">
                    {CheckUndefinedStringEmpty(state.dataRightAPI[selectedCode[0].keyFeeCurrentName])} {CheckUndefinedStringEmpty(NumberWithCommas(state.dataRightAPI[selectedCode[0].keyFee]))}
                  </div>
                  <div className="w-150px word-wrap d-flex justify-content-between align-items-center">
                    {CheckUndefinedStringEmpty(state.dataRightAPI[selectedCode[0].keyFeeCurrentName])} {CheckUndefinedStringEmpty(NumberWithCommas(state.dataRightAPI[selectedCode[0].keySettlementAmount]))}
                  </div>
                </div>
              );
            })}

          <div className="d-flex justify-content-between px-1 py-3">
            <div className="w-200px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 "></div>
            <div className="w-100px   d-flex align-items-center word-wrap border-0 Lato-700 s-1418 "></div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">
              {conditionShowAmount === true ? (
                <>
                  {CheckUndefinedStringEmpty(state.dataRightAPI.totalCurrName)} {NumberWithCommas(amountAll)}
                </>
              ) : (
                <>Cannot calculate</>
              )}
            </div>
            <div className="w-100px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">
              {conditionShowFeeAndSettlementAmount === true ? (
                <>
                  {CheckUndefinedStringEmpty(state.dataRightAPI.saleSettCurrName)} {NumberWithCommas(amountAllFee)}
                </>
              ) : (
                <>Cannot calculate</>
              )}
            </div>
            <div className="w-150px  d-flex align-items-center word-wrap border-0 Lato-700 s-1418 ">
              {conditionShowFeeAndSettlementAmount === true ? (
                <>
                  {CheckUndefinedStringEmpty(state.dataRightAPI.saleSettCurrName)} {NumberWithCommas(amountAllSettlement)}
                </>
              ) : (
                <>Cannot calculate</>
              )}
            </div>
          </div>
          {/* <Divider /> */}
          <Divider />
        </div>
      </>
    );
  };
  let widthWindows_ = window.innerWidth;
  useEffect(() => {
    dispatch({ type: "selectedDateModal", value: stateParent.selectedProcDate });
  }, [stateParent.selectedProcDate]);

  let settleDateLeft = "";
  if (stateParent.whatIsSelectedNow === "issuerId") {
    settleDateLeft = stateParent.selectedIssuerSettleDate;
  } else if (stateParent.whatIsSelectedNow === "acquirerId") {
    settleDateLeft = stateParent.selectedAcquirerSettleDate;
  } else if (stateParent.whatIsSelectedNow === "acquirerSwitchId") {
    settleDateLeft = stateParent.selectedAcquirerSwitchSettleDate;
  } else if (stateParent.whatIsSelectedNow === "issuerSwitchId") {
    settleDateLeft = stateParent.selectedIssuerSwitchSettleDate;
  }

  return (
    <>
      <Modal
        open={modalCondition}
        onCancel={(e) => setModal("modalCondition")}
        maskClosable={false}
        mask={true}
        maskStyle={{
          zIndex: 15,
        }}
        style={{
          position: "fixed",
          overflow: "unset",
          // right: 'auto',
          // bottom: 'auto',
          // top: '50%',
          // left: '50%',
          // marginRight: '-30%',
          // transform: 'translate(-50%, -50%)',
          //
          right: "2%",
          bottom: "auto",
          top: "10%",
          left: "2%",
          minWidth: "min-content",
          zIndex: 90,
        }}
        footer={false}
        title={state.titleModal}
      >
        <div className="">
          <div style={{ height: "630px", padding: "30px" }}>
            <Divider />
            <div className="d-flex " style={{ width: `${widthWindows_ - 200}px` }}>
              <div className="flex-fill">
                <div className="d-flex flex-column wd-webkit">
                  <div className="d-flex wrap my-1 ">
                    <div className="row my-1 w-100 p-0">
                      <div className="p-0 h-40px">
                        {stateParent.whatIsSelectedNow === "issuerId" && (
                          <p className="w-700 Lato-700 c-29 s-1620">
                            {CheckUndefined(stateParent.selectedIssuerMemberName)} ({stateParent.titleTableLeft})
                          </p>
                        )}
                        {stateParent.whatIsSelectedNow === "issuerSwitchId" && (
                          <p className="w-700 Lato-700 c-29 s-1620">
                            {CheckUndefined(stateParent.selectedIssuerSwitchMemberName)} ({stateParent.titleTableLeft})
                          </p>
                        )}
                        {stateParent.whatIsSelectedNow === "acquirerId" && (
                          <p className="w-700 Lato-700 c-29 s-1620">
                            {CheckUndefined(stateParent.selectedAcquirerMemberName)} ({stateParent.titleTableLeft})
                          </p>
                        )}
                        {stateParent.whatIsSelectedNow === "acquirerSwitchId" && (
                          <p className="w-700 Lato-700 c-29 s-1620">
                            {CheckUndefined(stateParent.selectedAcquirerSwitchMemberName)} ({stateParent.titleTableLeft})
                          </p>
                        )}
                        <Divider />
                      </div>
                      <div className="d-flex justify-content-end my-2">
                        <div className="w-400px">
                          {stateParent.whatIsSelectedNow === "issuerId" && <p className={labelStyle}>{stateParent.titleTableLeft} Settle Date</p>}
                          {stateParent.whatIsSelectedNow === "issuerSwitchId" && <p className={labelStyle}>{stateParent.titleTableLeft} Settle Date</p>}
                          {stateParent.whatIsSelectedNow === "acquirerId" && <p className={labelStyle}>{stateParent.titleTableLeft} Settle Date</p>}
                          {stateParent.whatIsSelectedNow === "acquirerSwitchId" && <p className={labelStyle}>{stateParent.titleTableLeft} Settle Date</p>}
                          <input
                            value={settleDateLeft}
                            onChange={(e) => {
                              if (stateParent.whatIsSelectedNow === "issuerId") {
                                dispatchParent({
                                  type: "selectedIssuerSettleDate",
                                  value: e.target.value,
                                });
                              } else if (stateParent.whatIsSelectedNow === "acquirerId") {
                                dispatchParent({
                                  type: "selectedAcquirerSettleDate",
                                  value: e.target.value,
                                });
                              } else if (stateParent.whatIsSelectedNow === "acquirerSwitchId") {
                                dispatchParent({
                                  type: "selectedAcquirerSwitchSettleDate",
                                  value: e.target.value,
                                });
                              } else if (stateParent.whatIsSelectedNow === "issuerSwitchId") {
                                dispatchParent({
                                  type: "selectedIssuerSwitchSettleDate",
                                  value: e.target.value,
                                });
                              }
                            }}
                            type="date"
                            className={inputStyle}
                          />
                        </div>
                      </div>
                      {leftTable()}
                    </div>
                  </div>
                </div>
              </div>
              <Divider type="vertical" className="m-1" />
              <div className="flex-fill">
                <div className="d-flex flex-column wd-webkit">
                  <div className="d-flex wrap my-1 ">
                    <div className="row my-1 w-100 p-0">
                      <div className="p-0 h-40px">
                        {/* <p className='w-700 Lato-700 c-29 s-1620'>{CheckUndefined(state.dataRightAPI.memberCcdName)} ({stateParent.titleTableRight})</p> */}
                        <p className="w-700 Lato-700 c-29 s-1620">
                          {CheckUndefined(stateParent.selectedAcquirerSwitchMemberName)} ({stateParent.titleTableRight})
                        </p>
                        <Divider />
                      </div>
                      <div className="d-flex justify-content-end my-2">
                        <div className="w-400px">
                          <p className={labelStyle}>{stateParent.titleTableRight} Settle Date</p>
                          <input
                            value={stateParent.selectedAcquirerSwitchSettleDate}
                            onChange={(e) => {
                              dispatchParent({
                                type: "selectedAcquirerSwitchSettleDate",
                                value: e.target.value,
                              });
                            }}
                            type="date"
                            className={inputStyle}
                          />
                        </div>
                      </div>
                      {rightTable()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <ModalDetailSettlementSummaryIssuer detailModal={state.detailModal} setModal={() => dispatch({ type: "detailModal", value: !state.detailModal })} stateFirstParent={stateParent} stateParent={state} stateOptions={stateOptions} />
    </>
  );
};

const initialState = {
  detailModal: false,
  titleModal: "Detail Settlement",
  //
  selectedIss: "",
  selectedIssSwitcher: "",
  selectedDateModal: "",
  //
  selectedTransType: "",
  //
  finalParameterLeftAPI: "",
  finalParameterRightAPI: "",
  //
  dataLeftAPI: new ModelTest({
    memberCcdCode: null,
    memberCcdName: null,
    currencyCode: null,
    totalCurrName: null,
    saleCurrName: null,
    refundCurrName: null,
    revCurrName: null,
    chbkCurrName: null,
    repreCurrName: null,
    chbkRevCurrName: null,
    repreRevCurrName: null,
    countSale: null,
    amountSale: null,
    countRefund: null,
    amountRefund: null,
    countReversal: null,
    amountReversal: null,
    countChargeback: null,
    amountChargeback: null,
    countRepresentment: null,
    amountRepresentment: null,
    countChargebackReversal: null,
    amountChargebackReversal: null,
    countRepresentmentReversal: null,
    amountRepresentmentReversal: null,
    totalAmount: null,
    transTypeSale: null,
    transTypeRefund: null,
    transTypeReversal: null,
    transTypeChargeback: null,
    transTypeRepresentment: null,
    transTypeChargebackReversal: null,
    transTypeRepresentmentReversal: null,
    // new response

    saleSettCurrName: null,
    amountSettFeeSale: null,
    amountSettSale: null,
    refundSettCurrName: null,
    amountSettFeeRefund: null,
    amountSettRefund: null,
    revSettCurrName: null,
    amountSettFeeReversal: null,
    amountSettReversal: null,
    chbkSettCurrName: null,
    amountSettFeeChargeback: null,
    amountSettChargeback: null,

    repreSettCurrName: null,
    amountSettFeeRepresentment: null,
    amountSettRepresentment: null,

    chbkRevSettCurrName: null,
    amountSettFeeChargebackReversal: null,
    amountSettChargebackReversal: null,
  }),
  dataRightAPI: new ModelTest({
    memberCcdCode: null,
    memberCcdName: null,
    currencyCode: null,
    totalCurrName: null,
    saleCurrName: null,
    refundCurrName: null,
    revCurrName: null,
    chbkCurrName: null,
    repreCurrName: null,
    chbkRevCurrName: null,
    repreRevCurrName: null,
    countSale: null,
    amountSale: null,
    countRefund: null,
    amountRefund: null,
    countReversal: null,
    amountReversal: null,
    countChargeback: null,
    amountChargeback: null,
    countRepresentment: null,
    amountRepresentment: null,
    countChargebackReversal: null,
    amountChargebackReversal: null,
    countRepresentmentReversal: null,
    amountRepresentmentReversal: null,
    totalAmount: null,
    transTypeSale: null,
    transTypeRefund: null,
    transTypeReversal: null,
    transTypeChargeback: null,
    transTypeRepresentment: null,
    transTypeChargebackReversal: null,
    transTypeRepresentmentReversal: null,
    // new response

    saleSettCurrName: null,
    amountSettFeeSale: null,
    amountSettSale: null,
    refundSettCurrName: null,
    amountSettFeeRefund: null,
    amountSettRefund: null,
    revSettCurrName: null,
    amountSettFeeReversal: null,
    amountSettReversal: null,
    chbkSettCurrName: null,
    amountSettFeeChargeback: null,
    amountSettChargeback: null,

    repreSettCurrName: null,
    amountSettFeeRepresentment: null,
    amountSettRepresentment: null,

    chbkRevSettCurrName: null,
    amountSettFeeChargebackReversal: null,
    amountSettChargebackReversal: null,
  }),
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "dataLeftAPI":
      return {
        ...state,
        dataLeftAPI: new ModelTest({
          memberCcdCode: action.value.memberCcdCode,
          memberCcdName: action.value.memberCcdName,
          currencyCode: action.value.currencyCode,
          totalCurrName: action.value.totalCurrName === "" ? "USD" : action.value.totalCurrName,
          saleCurrName: action.value.saleCurrName === "" ? "USD" : action.value.saleCurrName,
          refundCurrName: action.value.refundCurrName === "" ? "USD" : action.value.refundCurrName,
          revCurrName: action.value.revCurrName === "" ? "USD" : action.value.revCurrName,
          chbkCurrName: action.value.chbkCurrName === "" ? "USD" : action.value.chbkCurrName,
          repreCurrName: action.value.repreCurrName === "" ? "USD" : action.value.repreCurrName,
          chbkRevCurrName: action.value.chbkRevCurrName === "" ? "USD" : action.value.chbkRevCurrName,
          repreRevCurrName: action.value.repreRevCurrName === "" ? "USD" : action.value.repreRevCurrName,
          countSale: action.value.countSale,
          amountSale: action.value.amountSale,
          countRefund: action.value.countRefund,
          amountRefund: action.value.amountRefund,
          countReversal: action.value.countReversal,
          amountReversal: action.value.amountReversal,
          countChargeback: action.value.countChargeback,
          amountChargeback: action.value.amountChargeback,
          countRepresentment: action.value.countRepresentment,
          amountRepresentment: action.value.amountRepresentment,
          countChargebackReversal: action.value.countChargebackReversal,
          amountChargebackReversal: action.value.amountChargebackReversal,
          countRepresentmentReversal: action.value.countRepresentmentReversal,
          amountRepresentmentReversal: action.value.amountRepresentmentReversal,
          totalAmount: action.value.totalAmount,
          transTypeSale: action.value.transTypeSale,
          transTypeRefund: action.value.transTypeRefund,
          transTypeReversal: action.value.transTypeReversal,
          transTypeChargeback: action.value.transTypeChargeback,
          transTypeRepresentment: action.value.transTypeRepresentment,
          transTypeChargebackReversal: action.value.transTypeChargebackReversal,
          transTypeRepresentmentReversal: action.value.transTypeRepresentmentReversal,

          // new response

          saleSettCurrName: action.value.saleSettCurrName === "" ? "USD" : action.value.saleSettCurrName,
          amountSettFeeSale: action.value.amountSettFeeSale,
          amountSettSale: action.value.amountSettSale,
          refundSettCurrName: action.value.refundSettCurrName === "" ? "USD" : action.value.refundSettCurrName,
          amountSettFeeRefund: action.value.amountSettFeeRefund,
          amountSettRefund: action.value.amountSettRefund,
          revSettCurrName: action.value.revSettCurrName === "" ? "USD" : action.value.revSettCurrName,
          amountSettFeeReversal: action.value.amountSettFeeReversal,
          amountSettReversal: action.value.amountSettReversal,
          chbkSettCurrName: action.value.chbkSettCurrName === "" ? "USD" : action.value.chbkSettCurrName,
          amountSettFeeChargeback: action.value.amountSettFeeChargeback,
          amountSettChargeback: action.value.amountSettChargeback,

          repreSettCurrName: action.value.repreSettCurrName === "" ? "USD" : action.value.repreSettCurrName,
          amountSettFeeRepresentment: action.value.amountSettFeeRepresentment,
          amountSettRepresentment: action.value.amountSettRepresentment,

          chbkRevSettCurrName: action.value.chbkRevSettCurrName === "" ? "USD" : action.value.chbkRevSettCurrName,
          amountSettFeeChargebackReversal: action.value.amountSettFeeChargebackReversal,
          amountSettChargebackReversal: action.value.amountSettChargebackReversal,
        }),
      };

    case "dataRightAPI":
      return {
        ...state,
        dataRightAPI: new ModelTest({
          memberCcdCode: action.value.memberCcdCode,
          memberCcdName: action.value.memberCcdName,
          currencyCode: action.value.currencyCode,
          totalCurrName: action.value.totalCurrName === "" ? "USD" : action.value.totalCurrName,
          saleCurrName: action.value.saleCurrName === "" ? "USD" : action.value.saleCurrName,
          refundCurrName: action.value.refundCurrName === "" ? "USD" : action.value.refundCurrName,
          revCurrName: action.value.revCurrName === "" ? "USD" : action.value.revCurrName,
          chbkCurrName: action.value.chbkCurrName === "" ? "USD" : action.value.chbkCurrName,
          repreCurrName: action.value.repreCurrName === "" ? "USD" : action.value.repreCurrName,
          chbkRevCurrName: action.value.chbkRevCurrName === "" ? "USD" : action.value.chbkRevCurrName,
          repreRevCurrName: action.value.repreRevCurrName === "" ? "USD" : action.value.repreRevCurrName,
          countSale: action.value.countSale,
          amountSale: action.value.amountSale,
          countRefund: action.value.countRefund,
          amountRefund: action.value.amountRefund,
          countReversal: action.value.countReversal,
          amountReversal: action.value.amountReversal,
          countChargeback: action.value.countChargeback,
          amountChargeback: action.value.amountChargeback,
          countRepresentment: action.value.countRepresentment,
          amountRepresentment: action.value.amountRepresentment,
          countChargebackReversal: action.value.countChargebackReversal,
          amountChargebackReversal: action.value.amountChargebackReversal,
          countRepresentmentReversal: action.value.countRepresentmentReversal,
          amountRepresentmentReversal: action.value.amountRepresentmentReversal,
          totalAmount: action.value.totalAmount,
          transTypeSale: action.value.transTypeSale,
          transTypeRefund: action.value.transTypeRefund,
          transTypeReversal: action.value.transTypeReversal,
          transTypeChargeback: action.value.transTypeChargeback,
          transTypeRepresentment: action.value.transTypeRepresentment,
          transTypeChargebackReversal: action.value.transTypeChargebackReversal,
          transTypeRepresentmentReversal: action.value.transTypeRepresentmentReversal,
          // new response

          saleSettCurrName: action.value.saleSettCurrName === "" ? "USD" : action.value.saleSettCurrName,
          amountSettFeeSale: action.value.amountSettFeeSale,
          amountSettSale: action.value.amountSettSale,
          refundSettCurrName: action.value.refundSettCurrName === "" ? "USD" : action.value.refundSettCurrName,
          amountSettFeeRefund: action.value.amountSettFeeRefund,
          amountSettRefund: action.value.amountSettRefund,
          revSettCurrName: action.value.revSettCurrName === "" ? "USD" : action.value.revSettCurrName,
          amountSettFeeReversal: action.value.amountSettFeeReversal,
          amountSettReversal: action.value.amountSettReversal,
          chbkSettCurrName: action.value.chbkSettCurrName === "" ? "USD" : action.value.chbkSettCurrName,
          amountSettFeeChargeback: action.value.amountSettFeeChargeback,
          amountSettChargeback: action.value.amountSettChargeback,

          repreSettCurrName: action.value.repreSettCurrName === "" ? "USD" : action.value.repreSettCurrName,
          amountSettFeeRepresentment: action.value.amountSettFeeRepresentment,
          amountSettRepresentment: action.value.amountSettRepresentment,

          chbkRevSettCurrName: action.value.chbkRevSettCurrName === "" ? "USD" : action.value.chbkRevSettCurrName,
          amountSettFeeChargebackReversal: action.value.amountSettFeeChargebackReversal,
          amountSettChargebackReversal: action.value.amountSettChargebackReversal,
        }),
      };

    case "resetDataLeftAPI":
      return {
        ...state,
        dataLeftAPI: new ModelTest({
          memberCcdCode: null,
          memberCcdName: null,
          currencyCode: null,
          totalCurrName: null,
          saleCurrName: null,
          refundCurrName: null,
          revCurrName: null,
          chbkCurrName: null,
          repreCurrName: null,
          chbkRevCurrName: null,
          repreRevCurrName: null,
          countSale: null,
          amountSale: null,
          countRefund: null,
          amountRefund: null,
          countReversal: null,
          amountReversal: null,
          countChargeback: null,
          amountChargeback: null,
          countRepresentment: null,
          amountRepresentment: null,
          countChargebackReversal: null,
          amountChargebackReversal: null,
          countRepresentmentReversal: null,
          amountRepresentmentReversal: null,
          totalAmount: null,
          transTypeSale: null,
          transTypeRefund: null,
          transTypeReversal: null,
          transTypeChargeback: null,
          transTypeRepresentment: null,
          transTypeChargebackReversal: null,
          transTypeRepresentmentReversal: null,
          // new response

          saleSettCurrName: null,
          amountSettFeeSale: null,
          amountSettSale: null,
          refundSettCurrName: null,
          amountSettFeeRefund: null,
          amountSettRefund: null,
          revSettCurrName: null,
          amountSettFeeReversal: null,
          amountSettReversal: null,
          chbkSettCurrName: null,
          amountSettFeeChargeback: null,
          amountSettChargeback: null,

          repreSettCurrName: null,
          amountSettFeeRepresentment: null,
          amountSettRepresentment: null,

          chbkRevSettCurrName: null,
          amountSettFeeChargebackReversal: null,
          amountSettChargebackReversal: null,
        }),
      };

    case "resetDataRightAPI":
      return {
        ...state,
        dataRightAPI: new ModelTest({
          memberCcdCode: null,
          memberCcdName: null,
          currencyCode: null,
          totalCurrName: null,
          saleCurrName: null,
          refundCurrName: null,
          revCurrName: null,
          chbkCurrName: null,
          repreCurrName: null,
          chbkRevCurrName: null,
          repreRevCurrName: null,
          countSale: null,
          amountSale: null,
          countRefund: null,
          amountRefund: null,
          countReversal: null,
          amountReversal: null,
          countChargeback: null,
          amountChargeback: null,
          countRepresentment: null,
          amountRepresentment: null,
          countChargebackReversal: null,
          amountChargebackReversal: null,
          countRepresentmentReversal: null,
          amountRepresentmentReversal: null,
          totalAmount: null,
          transTypeSale: null,
          transTypeRefund: null,
          transTypeReversal: null,
          transTypeChargeback: null,
          transTypeRepresentment: null,
          transTypeChargebackReversal: null,
          transTypeRepresentmentReversal: null,
          // new response

          saleSettCurrName: null,
          amountSettFeeSale: null,
          amountSettSale: null,
          refundSettCurrName: null,
          amountSettFeeRefund: null,
          amountSettRefund: null,
          revSettCurrName: null,
          amountSettFeeReversal: null,
          amountSettReversal: null,
          chbkSettCurrName: null,
          amountSettFeeChargeback: null,
          amountSettChargeback: null,

          repreSettCurrName: null,
          amountSettFeeRepresentment: null,
          amountSettRepresentment: null,

          chbkRevSettCurrName: null,
          amountSettFeeChargebackReversal: null,
          amountSettChargebackReversal: null,
        }),
      };

    case "selectedIss":
      return {
        ...state,
        selectedIss: action.value,
      };
    case "selectedIssSwitcher":
      return {
        ...state,
        selectedIssSwitcher: action.value,
      };
    case "selectedDateModal":
      return {
        ...state,
        selectedDateModal: action.value,
      };

    case "detailModal":
      return {
        ...state,
        detailModal: action.value,
      };
    case "handleClickRowTable":
      return {
        ...state,
        selectedTransType: action.value,
        detailModal: !state.detailModal,
      };

    case "selectedTransType":
      return {
        ...state,
        selectedTransType: action.value,
      };
    case "finalParameterLeftAPI":
      return {
        ...state,
        finalParameterLeftAPI: action.value,
      };
    case "finalParameterRightAPI":
      return {
        ...state,
        finalParameterRightAPI: action.value,
      };
    default:
      throw new Error();
  }
}

// SettlementSummaryDetail

import { useSelector } from "react-redux";
import { icon_add } from "../Assets/Images/svg";
import { Tooltip } from "antd";

export const ButtonAdd = ({ ...props }) => {
    const { handleClick, codeAccessPrivilege } = props;
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    if (privilegeAccess === null) {
        return <></>
    } else if (privilegeAccess.includes(codeAccessPrivilege)) {
        return (
            <Tooltip placement="bottom" title={'Add Data'}>
                <div className={"btn d-flex flex-column justify-content-center align-items-center bd-e8 bg-fd br-5 pointer  h-100 w-100"} onClick={handleClick}>
                    <div className="pb-1">{icon_add}</div>
                </div>
            </Tooltip>
        );
    } else if (codeAccessPrivilege === '') {
        return (
            <Tooltip placement="bottom" title={'Add Data'}>
                <div className={"btn d-flex flex-column justify-content-center align-items-center bd-e8 bg-fd br-5 pointer  h-100 w-100"} onClick={handleClick}>
                    <div className="pb-1">{icon_add}</div>
                </div>
            </Tooltip>
        );
    }else return <></>;
};


import { logIcon } from "../../../Assets/Images/MenuIcon";
import { Page1JobSchedulerRoute, Page2JobSchedulerRoute, Page3JobSchedulerRoute, Page4JobSchedulerRoute, Page5JobSchedulerRoute } from "../../../Routes";
import { Page40Code, Page41Code, Page42Code, Page43Code, Page53Code } from "../CodeMenu";
import { Page40Title, Page41Title, Page42Title, Page43Title, Page53Title } from "../TitleMenu";

export const Menu12 = {
    id: 'job_scheduler_sidebar',
    label: 'Job Scheduler',
    icon: logIcon,
    privilegesId: [
        // Page40Code,
        Page41Code,
        // Page42Code,
        Page43Code,
        // Page53Code
    ],
    itemSidebar: [
        // {
        //     titleList: Page40Title,
        //     key: 'jobSchedulerOptions1',
        //     privilegeId: Page40Code,
        //     url: Page1JobSchedulerRoute,
        // },
        {
            titleList: Page41Title,
            key: 'jobSchedulerOptions2',
            privilegeId: Page41Code,
            url: Page2JobSchedulerRoute,
        },
        // {
        //     titleList: Page42Title,
        //     key: 'jobSchedulerOptions3',
        //     privilegeId: Page42Code,
        //     url: Page3JobSchedulerRoute,
        // },
        {
            titleList: Page43Title,
            key: 'jobSchedulerOptions4',
            privilegeId: Page43Code,
            url: Page4JobSchedulerRoute,
        },
        // {
        //     titleList: Page53Title,
        //     key: 'jobSchedulerOptions5',
        //     privilegeId: Page53Code,
        //     url: Page5JobSchedulerRoute,
        // },
    ],
}
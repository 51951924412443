import { SelectOptionsInterface } from "../../../Interface";

 const Page3: SelectOptionsInterface = {
    ccdOptions: true,
    spfOptions: false,
    memberOptions: false,
    ccgOptions: false,
    logLevel001Options: false,
    logLevel002Options: false,
    routingOptions: false,
    routeSequenceOptions: false,
    privilegeOptions: false,
    countryOptions: false,
    productOptions: false,
    productFeatureOptions: false,
    reasonCodeOptions: false,
    subProductOptions: false,
    exchangeRateOptions: false,
    elementOptions: false,
    qrStandardOptions: false,
    interfaceFieldOptions: false,
    headerFieldOptions: false,
    feeOptions: false,
    interfaceFileOptions: false,
    recordOptions: false,
    interfaceFileItemOptions: false,
    memberPICOptions: false,
    batchOptionsFromAPIBatch: true,
    commonCodeOptions:false,
    resultOptions:false
}
export default Page3;
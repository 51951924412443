import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../../../Components';
import { API } from '../../../../Services';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../../../Styles';
import { CheckAllFields, GeneratePassword, IsValidEmail } from '../../../../Utils';
import { ModelUserManagement } from '../../Utils/ModelUserManagement';
import { Page10Title } from '../../../../State/Menu/TitleMenu';
import { urlSelection } from '../../../../Services/API';


export const ModalDraggableUserManagement = ({ ...props }): JSX.Element => {
    const userLogin = useSelector((state: any) => state.user);

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi, handleStateComponent } = props
    const { optionsMember, optionsPrivilege, optionsUserStatusDetailCode, optionsUserLevel, optionsUserCategoryDetailCode, optionsUserPrivilegeLevel } = props.stateOptions
    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'userName') {
            if (valueState.split('').length > 16) {
                dispatchStatePage({ type: 'errorMessage', value: 'Username input maximum 16 characters' })
            } else {
                dispatchStatePage({ type: 'errorMessage', value: '' })
                dispatchStatePage({ type: nameState, value: valueState })
            }
        } else if (nameState === 'userEmail') {
            if (valueState.split('').length > 32) {
                dispatchStatePage({ type: 'errorMessage', value: 'Email input maximum 32 characters' })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: '' })
            }
        } else if (nameState === 'userStartDate') {
            let dateStart = new Date(valueState);
            let dateEnd = new Date(statePage.data.userEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: 'Start date cannot be larger than End Date' })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: '' })
            }
        } else if (nameState === 'userEndDate') {
            let dateStart = new Date(statePage.data.userStartDate);
            let dateEnd = new Date(valueState);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: 'Start date cannot be larger than End Date' })

            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: '' })

            }
        } else if (nameState === 'memberId') {
            if (valueState === 1) {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'userCategoryCode', value: '01' })
                dispatchStatePage({ type: 'privilegeId', value: optionsPrivilege[0].value })
            } else {
                let newOptionsUserPrivilegeId = optionsPrivilege.filter((option: any) => option.label.toLowerCase().includes('member'));
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'userCategoryCode', value: '002' })
                dispatchStatePage({ type: 'privilegeId', value: newOptionsUserPrivilegeId[0].value })
            }
        } else if (nameState === 'userLevel') {
            dispatchStatePage({ type: nameState, value: valueState })
            dispatchStatePage({ type: 'privilegeId', value: null })

        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    useEffect(() => {
        if (statePage.data.userLevel !== null) {
            let newOptionsPrivilege: any = []
            optionsPrivilege.forEach((item1: any) => {
                if (item1.level === statePage.data.userLevel) {
                    newOptionsPrivilege.push(item1);
                }
            })
            dispatchStatePage({ type: 'newOptionsPrivilege', value: newOptionsPrivilege })
        }
    }, [statePage.data.userLevel])

    const updateDataAPI = () => {
        const { password, confirmPassword } = statePage
        const { userId, userName, userEmail, userCategoryCode, userLevel, privilegeId, userStatus, userStartDate, userEndDate, memberId, memberName, privilegeName, userBlockedReason, } = statePage.data
        const data = {
            userId: id,
            userName: userName,
            userEmail: userEmail,
            userCategoryCode: userCategoryCode,
            userLevel: userLevel,
            privilegeId: privilegeId,
            userStatus: userStatus,
            userStartDate: userStartDate,
            userEndDate: userEndDate,
            memberId: memberId,
            memberName: memberName,
            privilegeName: privilegeName,
            userBlockedReason: userBlockedReason,
        };


        if (!IsValidEmail(userEmail)) {
            handleState('errorMessage', 'Email is invalid');
        } else {
            if (userStatus === '03') { // block user
                let a = CheckAllFields([memberId, userName, userEmail, userCategoryCode, userLevel, userStatus, userStartDate, userEndDate, userBlockedReason, privilegeId], ['MEMBER ID', 'Username', 'Email', 'Category Code', 'Level', 'Status', 'Valid Start Date', 'Valid End Date', 'Block Reason', 'Privilege Code']);
                let conditionPrivilegeIDAndUserLevelIsMatching = false;
                if (statePage.newOptionsPrivilege !== null) {
                    if (statePage.newOptionsPrivilege.length > 0) {
                        statePage.newOptionsPrivilege.forEach((item: any) => {
                            if (privilegeId === item.value) {
                                if (item.level === userLevel) conditionPrivilegeIDAndUserLevelIsMatching = true;
                            }
                        })

                    }
                }
                if (conditionPrivilegeIDAndUserLevelIsMatching === false) {
                    handleState('errorMessage', 'Privilege Level and Privilege Code doesn\'t match');
                } else {
                    if (a !== "") {
                        handleState('errorMessage', a);
                    } else {
                        let dataEdited;
                        if (password !== '') dataEdited = Object.assign(data, { userPassword: password });
                        else dataEdited = data
                        API.update({
                            bodyCustom: dataEdited,
                            pathCustom: `${urlApi}`,
                            selectUrl: urlSelection.dashboard,
                            useToken: true,
                        }).then((response) => {
                            if (privilegeId !== userLogin.privilegeId) {
                                actionAfterUpdateItem(id)
                                actionShowModal()
                                if (password !== '') {
                                    handleStateComponent('showPassword', true);
                                    handleStateComponent('currentPassword', password);
                                }
                            } else {
                                actionAfterUpdateItem(id)
                                actionShowModal()
                                if (password !== '') {
                                    handleStateComponent('showPassword', true);
                                    handleStateComponent('currentPassword', password);
                                }
                            }
                        }).catch((err) => handleState('errorMessage', err))
                    }
                }
            } else {
                let a = CheckAllFields([memberId, userName, userEmail, userCategoryCode, userLevel, userStatus, userStartDate, userEndDate, privilegeId], ['MEMBER ID', 'Username', 'Email', 'Category Code', 'Level', 'Status', 'Valid Start Date', 'Valid End Date', 'Privilege Code']);
                let conditionPrivilegeIDAndUserLevelIsMatching = false;
                if (statePage.newOptionsPrivilege !== null) {
                    if (statePage.newOptionsPrivilege.length > 0) {
                        statePage.newOptionsPrivilege.forEach((item: any) => {
                            if (privilegeId === item.value) {
                                if (item.level === userLevel) conditionPrivilegeIDAndUserLevelIsMatching = true;
                            }
                        })

                    }
                }
                if (conditionPrivilegeIDAndUserLevelIsMatching === false) {
                    handleState('errorMessage', 'Privilege Level and Privilege Code doesn\'t match');
                } else {
                    if (a !== "") {
                        handleState('errorMessage', a);
                    } else {
                        let dataEdited;
                        if (password !== '') dataEdited = Object.assign(data, { userPassword: password });
                        else dataEdited = data
                        API.update({
                            bodyCustom: dataEdited,
                            pathCustom: `${urlApi}`,
                            selectUrl: urlSelection.dashboard,
                            useToken: true,
                        }).then((response) => {
                            if (privilegeId !== userLogin.privilegeId) {
                                actionAfterUpdateItem(id)
                                actionShowModal()
                                if (password !== '') {
                                    handleStateComponent('showPassword', true);
                                    handleStateComponent('currentPassword', password);
                                }
                            } else {
                                actionAfterUpdateItem(id)
                                actionShowModal()
                                if (password !== '') {
                                    handleStateComponent('showPassword', true);
                                    handleStateComponent('currentPassword', password);
                                }
                            }
                        }).catch((err) => handleState('errorMessage', err))


                    }
                }


            }
        };
    }
    let height_ = window.innerHeight - 200;

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={700}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 600) / 2,
                height: '0px',
                width: '0px',
                top: '4vw',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                    <hr className='c-7f7'/>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column' style={{width: '100%'}}>
                <div className='row mx-0'style={{overflow: 'auto', height: '60vh'}}>
                    {
                        id
                        &&
                        (
                            <div className='col-6'>
                                <p className={labelStyle}>USER ID</p>
                                <input value={id} disabled={true} className={inputStyleReadOnly} />
                            </div>
                        )
                    }

                    {
                        userLogin.memberId === 1 ?
                            <div className='col-12'>
                                <p className={labelStyle}>member ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.data.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                            </div>
                            :
                            <div className='col-12'>
                                <p className={labelStyle}>member ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember.filter((item: any) => item.value === userLogin.memberId)} value={optionsMember.filter((option: any) => option.value === statePage.data.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                            </div>
                    }


                    <div className='col-6'>
                        <p className={labelStyle}>USERNAME <span className='text-danger'>*</span></p>
                        <input value={statePage.data.userName} onChange={(e) => handleState('userName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>email <span className='text-danger'>*</span></p>
                        <input value={statePage.data.userEmail} onChange={(e) => handleState('userEmail', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>category code <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsUserCategoryDetailCode} value={optionsUserCategoryDetailCode.filter((option: any) => option.value === statePage.data.userCategoryCode)} onChange={(e) => handleState('userCategoryCode', e.value)} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>Privilege level <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsUserPrivilegeLevel.filter((element1: any) => element1.value !== '00')} value={optionsUserPrivilegeLevel.filter((option: any) => option.value === statePage.data.userLevel)} onChange={(e) => handleState('userLevel', e.value)} />
                    </div>
                    {
                        statePage.data.userLevel === null ?
                            <div className='col-6'>
                                <p className={labelStyle}>privilege code <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} isDisabled={true} placeholder={'(Search)'} options={statePage.newOptionsPrivilege} value={statePage.newOptionsPrivilege.filter((option: any) => option.value === statePage.data.privilegeId)} onChange={(e) => handleState('privilegeId', e.value)} maxMenuHeight={195}/>
                            </div>
                            :
                            <div className='col-6'>
                                <p className={labelStyle}>privilege code <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={statePage.newOptionsPrivilege} value={statePage.newOptionsPrivilege.filter((option: any) => option.value === statePage.data.privilegeId)} onChange={(e) => handleState('privilegeId', e.value)} maxMenuHeight={195}/>
                            </div>

                    }
                    <div className='col-6'>
                        <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsUserStatusDetailCode} value={optionsUserStatusDetailCode.filter((option: any) => option.value === statePage.data.userStatus)} onChange={(e) => handleState('userStatus', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>start date <span className='text-danger'>*</span></p>
                        <input type='date' value={statePage.data.userStartDate} onChange={(e) => handleState('userStartDate', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>end date <span className='text-danger'>*</span></p>
                        <input type='date' value={statePage.data.userEndDate} onChange={(e) => handleState('userEndDate', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='row'>
                        {id ?
                            <div className='col-6'>
                                <p className={labelStyle}>Reset Password</p>
                                <button className='btn btn-sm btn-primary s-1418 c-ff Lato-600' onClick={() => {
                                    let newPassword = GeneratePassword(12);
                                    handleState('password', newPassword);
                                    handleState('confirmPassword', newPassword);
                                }}>
                                    Reset Password
                                </button>
                            </div>
                            :
                            <></>
                        }
                        {
                            id && statePage.password !== '' ?
                                <div className='col-6'>
                                    <p className={labelStyle}>Password</p>
                                    <input value={statePage.password} type='text' disabled={true} className={inputStyleReadOnly} />
                                </div>
                                :
                                <></>
                        }
                    </div>
                    {
                        (statePage.data.userStatus === '003') || (statePage.data.userStatus === '002') ?
                            <div className='col-12'>
                                <p className={labelStyle}>User block reason </p>
                                <textarea rows={4} value={statePage.data.userBlockedReason} onChange={(e) => handleState('userBlockedReason', e.target.value)} className={inputDescriptionStyle} />
                            </div>
                            :
                            <></>
                    }
                </div>
                <div className='mb-2'>
                    <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
                </div>
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page10Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelUserManagement({
        id: null,
        userId: null,
        memberId: null,
        privilegeId: null,
        userEmail: null,
        userName: null,
        userCategoryCode: null,
        userLevel: null,
        userStatus: '01',
        userStartDate: null,
        userEndDate: null,
        userBlockedReason: null,
        memberName: null,
        privilegeName: null,
    }),
    //
    id: '',
    password: '',
    confirmPassword: '',
    newOptionsPrivilege: [],
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelUserManagement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelUserManagement({
                    id: null,
                    userId: null,
                    memberId: null,
                    privilegeId: null,
                    userEmail: null,
                    userName: null,
                    userCategoryCode: null,
                    userLevel: null,
                    userStatus: '001',
                    userStartDate: null,
                    userEndDate: null,
                    userBlockedReason: null,
                    memberName: null,
                    privilegeName: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelUserManagement({
                    id: action.value.userId,
                    userId: action.value.userId,
                    memberId: action.value.memberId,
                    privilegeId: action.value.privilegeId,
                    userEmail: action.value.userEmail,
                    userName: action.value.userName,
                    userCategoryCode: action.value.userCategoryCode,
                    userLevel: action.value.userLevel,
                    userStatus: action.value.userStatus,
                    userStartDate: action.value.userStartDate,
                    userEndDate: action.value.userEndDate,
                    userBlockedReason: action.value.userBlockedReason,
                    memberName: action.value.memberName,
                    privilegeName: action.value.privilegeName,
                }),
                errorMessage: "",
            };
        case 'password':
            return {
                ...state,
                password: action.value,
            };
        case 'confirmPassword':
            return {
                ...state,
                confirmPassword: action.value,
            };
        case 'newOptionsPrivilege':
            return {
                ...state,
                newOptionsPrivilege: action.value,
            };

        default:
            throw new Error();
    }
}






// SchedulerExecution

interface SchedulerExecutionInterface {
    id: null | number | string;
    batchBusinessGroup: string | null;
    batchExecutionType: string | null;
    batchGroupPath: string | null;

}

export class ModelSchedulerExecution {
    id: null | number | string;
    batchBusinessGroup: string | null;
    batchExecutionType: string | null;
    batchGroupPath: string | null;

    constructor(value: SchedulerExecutionInterface) {
        this.id = value.id;
        this.batchBusinessGroup= value?.batchBusinessGroup ?? null;
        this.batchExecutionType= value?.batchExecutionType ?? null;
        this.batchGroupPath= value?.batchGroupPath ?? null;
    }
}

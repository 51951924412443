import { Fee } from "./Fee";
import { MemberFee } from "./MemberFee";

export const FeeRateSetting = ({...props}): JSX.Element => {
    return (
        <>
            <Fee />
            <div className='my-2'></div>
            <MemberFee />
        </>
    );
}





import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { icon_search_white, undo_icon } from '../../Assets/Images/svg';
import { InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { Page4SettlementRoute } from '../../Routes';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { inputStyle, labelStyle } from '../../Styles';
import { InterfaceOptionsDisputeResolution } from './InterfaceOptionsDisputeResolution';
import { ModelDisputeResolution } from './ModelDisputeResolution';
import { TableDisputeResolution } from './TableDisputeResolution';
import { ShowModalInterface } from '../../Interface';



export const DisputeResolution = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let selectedStartDate = stateCustomComponent.selectedStartDate !== '' ? `startDate=${stateCustomComponent.selectedStartDate}&` : '';
        let selectedEndDate = stateCustomComponent.selectedEndDate !== '' ? `endDate=${stateCustomComponent.selectedEndDate}&` : '';
        let selectedDisputeCaseStatusDetailCode = stateCustomComponent.selectedDisputeCaseStatusDetailCode !== '' ? `status=${stateCustomComponent.selectedDisputeCaseStatusDetailCode}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${search}${limit}${offset}${selectedStartDate}${selectedEndDate}${selectedDisputeCaseStatusDetailCode}`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }


    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsDisputeResolution, dispatchGlobal)

    }, [])

    useEffect(() => {
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        dispatchCustomComponent({ type: 'selectedStartDate', value: new Date().toISOString().slice(0, 10) })
        dispatchCustomComponent({ type: 'selectedEndDate', value: new Date().toISOString().slice(0, 10) })
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(true)
    }, [state.search])



    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    const exportToCSVComponent = () => { }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelDisputeResolution) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsDisputeResolution, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelDisputeResolution, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
            </div>
        );
    };
    
    const newFirstElement = { value: '', label: 'All' };
    const arrayOptionsDisputeCaseStatusDetailCode: any = []
    stateOptions.optionsDisputeCaseStatusDetailCode.forEach((element: any) => {
        arrayOptionsDisputeCaseStatusDetailCode.push({
            value: element.value,
            label: element.label
        })
    })
    const newOptionsDisputeCaseStatusDetailCode = [newFirstElement].concat(arrayOptionsDisputeCaseStatusDetailCode)

    const filterComponentPage = () => {
        return (
            <div className="gx-5 row  my-5 mx-2" style={{ background: "white", border: "none" }}>
                <div className="row my-2 p-0">
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>CASE START DATE</p>
                        <input value={stateCustomComponent.selectedStartDate} onChange={(e) => handleState('selectedStartDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>CASE END DATE</p>
                        <input value={stateCustomComponent.selectedEndDate} onChange={(e) => handleState('selectedEndDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />
                    </div>
                    <div className="col-3">
                        <p className={labelStyle}>CASE STATUS</p>
                        <div className='d-flex justify-content-between'>
                            <Select placeholder={''}
                                className="flex-fill"
                                options={newOptionsDisputeCaseStatusDetailCode}
                                value={newOptionsDisputeCaseStatusDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedDisputeCaseStatusDetailCode)}
                                onChange={(e) => handleState("selectedDisputeCaseStatusDetailCode", e?.value, dispatchCustomComponent)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => handleState("selectedDisputeCaseStatusDetailCode", '', dispatchCustomComponent)} >
                                {undo_icon}
                            </div>
                        </div>
                    </div>

                    <div className="col-3 d-flex justify-content-end align-items-end ">
                        <div className="">
                            <button className="d-flex align-items-center px-4 py-3 br-20 bd-d4 bg-e8 bd-29 text-white" onClick={(e) => initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDisputeResolution, dispatchGlobal)}>
                                {icon_search_white}
                                <p className="s-1418 c-ff Lato-600 mx-2">SEARCH</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableDisputeResolution
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                detailData={(item: any) => navigate(`${Page4SettlementRoute}/details/${item.caseId}`)}
                //
                currentPage={state.currentPage}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <></>
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={false}
                needAddButton={false}
                needSearchFunction={false}
                needDownloadButton={false}
            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'dispute',
    titlePage: 'Dispute Resolution',
    privilegeAccess: { view: "451", add: "", edit: "", delete: "", download: "" },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Case ID', width: 'wd-100', val: 'caseId' },
        { field: 'Case Date', width: 'wd-100', val: 'caseDate' },
        { field: 'Trans Type', width: 'wd-100', val: 'transType' },
        { field: 'Trans ID', width: 'wd-200', val: 'transId' },
        { field: 'Issuer Amount', width: 'wd-200', val: 'issAmount' }, // issCurr
        { field: 'Acquirer Amount', width: 'wd-200', val: 'acqAmount' }, // acqCurr
        { field: 'Requestor', width: 'wd-100', val: 'req' },
        { field: 'Issuer', width: 'wd-100', val: 'iss' },
        { field: 'Acquirer', width: 'wd-100', val: 'acq' },
        { field: 'Case Status', width: 'wd-200', val: 'caseStatus' },
    ],
    toggle: ['no', 'CaseID', 'caseDate', 'transType', 'transId', 'issAmount', 'acqAmount', 'req', 'iss', 'acq', 'caseStatus'],
    complete_toggle: ['no', 'CaseID', 'caseDate', 'transType', 'transId', 'issAmount', 'acqAmount', 'req', 'iss', 'acq', 'caseStatus'],
    list_column_name: ['No.', 'Case ID', 'Case Date', 'Trans Type', 'Trans ID', 'Issuer Amount', 'Acquirer Amount', 'Requestor', 'Issuer', 'Acquirer', 'Case Status'],
    //
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1600,
    dataLength: 0, // zIndex
};


// caseId ModelDisputeResolution
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelDisputeResolution) => {
                        element1.id = element1.caseId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelDisputeResolution) => {
                        element1.id = element1.caseId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelDisputeResolution) => {
                        showDataResult.push(
                            { idModal: element1.caseId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.caseId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        //
        case 'transId':
            return {
                ...state,
                transId: action.value
            };
        case 'rrn':
            return {
                ...state,
                rrn: action.value
            };
        case 'invoiceNumber':
            return {
                ...state,
                invoiceNumber: action.value
            };
        case 'approvalCode':
            return {
                ...state,
                approvalCode: action.value
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'modalCondition':
            return {
                ...state,
                modalCondition: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                modalCondition: !(state.modalCondition),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                modalCondition: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };

        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        default:
            throw new Error();
    }
}
import 'react-datepicker/dist/react-datepicker.css';
import { labelStyle } from '../../../Styles';
import { format } from 'date-fns';
import { DatePickerRighIcon } from '../../../Components/DatepickerRightIcon';
import { icon_search_white } from '../../../Assets/Images/svg';

export const FilterFileTransferMonitoring = ({ ...props }) => {
    const {
        state,
        initFunctionCustom,
        handleStateParent
    } = props;


    return (
        <div className="d-flex flex-column w-100">
            <div className="row bg-ff br-10 bd-d4 gx-2 gy-2 px-2 pb-3">
                <div className="col-8 col-md-6 col-lg-4 col-xl-2">
                    <p className={labelStyle}>Proc Date</p>
                    <div className="d-flex flex-column">
                        <DatePickerRighIcon
                            maxDate={new Date()}
                            needTimeInput={false}
                            selected={new Date(`${state.procDate}` || '')}
                            popperClassName="custom-class"
                            onChange={(date: any) => {
                                handleStateParent(
                                    'procDate',
                                    format(date, 'yyyy-MM-dd')
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="col-4 col-md-6 col-lg-4 col-xl-2 d-flex align-items-end">
                    <button className={'btn d-flex align-items-center bg-e8 h-25px'} onClick={() => initFunctionCustom(true)} disabled={false}>
                        {icon_search_white}
                        <p className='s-1418 c-ff Lato-600 mx-2'>Search</p>
                    </button>
                </div>
            </div>
        </div>
    )

}
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonDelete, ButtonEdit } from '../../Components';
import { ShowModalInterface } from '../../Interface';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    setModal,
    stateOptions,
} from '../../State';
import { InterfaceOptionsHmacManagement } from './Utils/InterfaceOptionsHmacManagement';
import { ModalDraggableHmacManagement } from './Components/Modal/ModalDraggableHmacManagement';
import { ModalHmacManagement } from './Components/Modal/ModalHmacManagement';
import { ModelHmacManagement } from './Utils/ModelHmacManagement';
import { Page51Title } from '../../State/Menu/TitleMenu';
import { CheckUndefined, ExportCSV } from '../../Utils';
import { TableHmacManagement } from './Components/TableHmacManagement';
const ExcelHmacManagement = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showHashType = '';
        let showEnvironmentType = '';
        let showCommonStatus = '';
        if (stateOptions.optionsHashType.length > 0) {
            stateOptions.optionsHashType.forEach((element2: any) => {
                if (element2.value === item.hmacHashType) {
                    showHashType = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsEnvironmentType.length > 0) {
            stateOptions.optionsEnvironmentType.forEach((element2: any) => {
                if (element2.value === item.hmacEnvType) {
                    showEnvironmentType = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.hmacStatus) {
                    showCommonStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            memberName: CheckUndefined(item.memberName),
            memberCode: CheckUndefined(item.memberCode),
            hmacIncKeyVal: CheckUndefined(item.hmacIncKeyVal),
            hmacOutKeyValue: CheckUndefined(item.hmacOutKeyValue),
            hmacHashType: CheckUndefined(showHashType),
            hmacEnvType: CheckUndefined(showEnvironmentType),
            hmacStatus: CheckUndefined(showCommonStatus),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};

const CheckPrivilegePage = (privilegeAccess: any, privilegeAccessViewPage: string, navigate: any) => {
    if (privilegeAccess !== null) {
        if (privilegeAccess.includes(privilegeAccessViewPage) === false) {
            navigate('/warning', { replace: true })
        }
    }
}

const Page2 = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: state.view,
    });
    useEffect(() => {
        CheckPrivilegePage(privilegeAccess, state.privilegeAccess.view, navigate)
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let finalParameter = `${searchValue}${limit}${offset}`;
        let finalParameter2 = `${searchValue}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response?.data });
                dispatch({ type: 'totalPages', value: response?.detail.totalPage });
                dispatch({ type: 'totalData', value: response?.detail.total });
                /* Refresh Page */
                setPagination({pageSize: state.view, pageIndex: 0})
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response?.data });
                dispatch({ type: 'totalPages', value: response?.detail.totalPage });
                dispatch({ type: 'totalData', value: response?.detail.total });
            })
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsHmacManagement, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        if (state.view !== pagination.pageSize) {
            initFunctionCustom(true);
            setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
        } else {
            initFunctionCustom(false);
        }
    }, [pagination.pageIndex, state.view]);

    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelHmacManagement(state, stateOptions, response.data)
        })
    }

    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelHmacManagement) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

    const customButtonAction = (item: ModelHmacManagement, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                
                <ButtonEdit handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
                
            </div>
        );
    };

    const modalAddDeleteComponentPage = () => {
        // return <></>
        return (
            <ModalHmacManagement
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableHmacManagement
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2 mt-2">
                Member HMAC
            </h1>
            <TableHmacManagement 
                state={state}
                addDataComponent={addDataComponent}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                handleStateComponent={handleStateComponent}
                checkIndexComponent={checkIndexComponent}
                setModal={setModalComponent}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                pagination={pagination}
                setPagination={setPagination}
            />
            {modalUpdateComponentPage()}
        </>
    );
}

const initialStateOptions = { ...stateOptions }

const initialState = {
    urlApi: 'hmac_key_pair',
    titlePage: `${Page51Title}`,
    privilegeAccess: { view: '81', add: '82', edit: '83', delete: '84', download: '85' },
    label: [
        { showField: true, field: 'No.', width: 'wd-50', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'no' },
        { showField: true, field: 'Member Name', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'memberName' },
        { showField: true, field: 'Member Code', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'memberCode' },
        { showField: true, field: 'Incoming Key Value', width: 'wd-250', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'hmacIncKeyVal' },
        { showField: true, field: 'Outgoing Key Value', width: 'wd-250', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'hmacOutKeyValue' },
        { showField: true, field: 'Hash Type', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'hmacHashType' }, // optionsHashType
        { showField: true, field: 'Environment Type', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'hmacEnvType' }, // optionsEnvironmentType
        { showField: true, field: 'Status', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'hmacStatus' }, // optionsCommonStatus
        { showField: true, field: 'Action', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'action' },
    ],
    toggle: ['no', 'memberName', 'memberCode', 'hmacIncKeyVal', 'hmacOutKeyValue', 'hmacHashType', 'hmacEnvType', 'hmacStatus', 'action'],
    complete_toggle: ['no', 'memberName', 'memberCode', 'hmacIncKeyVal', 'hmacOutKeyValue', 'hmacHashType', 'hmacEnvType', 'hmacStatus', 'action'],
    list_column_name: ['No.', 'Member Name', 'Member Code', 'Incoming Key Value', 'Outgoing Key Value', 'Hash Type', 'Environment Type', 'Status'],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Member Name, Member Code',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1500,
    dataLength: 0, // zIndex
    finalAPI: '',
};

// hmacId
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelHmacManagement) => {
                        element1.id = element1.hmacId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelHmacManagement) => {
                        element1.id = element1.hmacId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelHmacManagement) => {
                        showDataResult.push(
                            { idModal: element1.hmacId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.hmacId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}

export default Page2;

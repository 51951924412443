import React, { useEffect, useReducer } from 'react';

import { Divider, Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ErrorMessage2, FooterModalUpdate, UploadFileComponent } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, labelStyle } from '../../Styles';
import { CheckAllFields, CheckUndefined, NumberWithCommas } from '../../Utils';
import { ModelDetailDisputeResolutionEvent } from './ModelDetailDisputeResolutionEvent';
import { close } from '../../Assets/Images/svg';
import { saveAs } from "file-saver";
import { Buffer } from 'buffer';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import { ModelModelDisputeModal } from './ModelDisputeModal';
import { useParams } from 'react-router-dom';
import { loadingAction } from '../../Store/Loading';
import { store } from '../../Store/Store';
import { successDoubleAction } from '../../Store/Sucesss';
import { doubleAction } from '../../Store/Error';
import { ErrorMessage } from '../../Components/ErrorMessage';
import { urlSelection } from '../../Services/API';


let classNameLeft = 's-1418 w-200px d-flex align-items-center px-2'
let classNameRight = 'Lato-700 s-1214 w-100 bg-f4 h-100 d-flex align-items-center px-2'


export const ModalDetailDisputeResolution = ({ ...props }) => {
    const params = useParams();
    const dispatchGlobal = useDispatch();
    const userLogin = useSelector((state: any) => state.user);
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsChargebackReasonDetailCode, optionsRePresentmentReasonDetailCode } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
        dispatchStatePage({ 'type': 'resetForms' })
        dispatchStatePage({ type: 'showDetail', value: false })
        if (id !== null) {
            if (id !== undefined) {
                API.get({
                    bodyCustom: null,
                    pathCustom: `dispute/event/d/${id}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => handleState('setDataDispute', response.data))
                API.get({
                    bodyCustom: null,
                    pathCustom: `dispute/event/files/${id}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => handleState('images', response.data))
            }
        }
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })

    }, [id !== statePage.id])

    useEffect(() => {

    }, [statePage.data.caseId])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const ButtonAcceptAction = () => {
        const { eventId } = statePage.data
        if (eventId === null) return;
        API.update({
            bodyCustom: null,
            pathCustom: `dispute/event/${idModal}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            actionAfterUpdateItem(id)
            actionShowModal()
        }).catch((err) => {
            handleState('errorMessageButtonAccept', err)

        })
    }

    const ButtonSubmitAction = () => {
        const { selectChargebackReason, selectNotes, selectFile, selectFileArray } = statePage
        let data = {
            caseId: params.id,
            eventReason: selectChargebackReason,
            eventNote: selectNotes
        }
        let sizeMax = 0;
        if (selectChargebackReason === '' || params.id === '') {
            handleState('errorMessage', 'RE-PRESENTMENT REASON must not be null')
            return;
        }
        // let selectedFiles = selectFileArray.filter((element1: any) => {
        //     if (element1 !== '') {
        //         return element1[0];
        //     }
        // })
        let formData = new FormData()
        let typeJPG: string = 'image/jpeg';
        let typePNG: string = 'image/png';
        let typePDF: string = 'application/pdf';
        let typeDoc: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        let FilesAllowed: string[] = [typeJPG, typePNG, typePDF, typeDoc]
        let conditionFilesIsAllowed: Boolean[] = [];
        if (selectFileArray.length > 0) {
            selectFileArray.forEach((item1: any) => {
                conditionFilesIsAllowed.push(FilesAllowed.includes(item1.type))
            })
        }
        if (conditionFilesIsAllowed.includes(false)) {
            handleState('errorMessage', 'Wrong type of File! Only PDF, DOC, PNG, and JPG type is allowed');
        } else {
            handleState('errorMessage', '');
            if (selectFileArray.length > 0) {
                for (let index = 0; index < selectFileArray.length; index++) {
                    sizeMax = sizeMax + selectFileArray[index].size;
                    formData.append("files", selectFileArray[index]);
                }
            }
            formData.append("eventId", idModal);
            formData.append("eventReason", selectChargebackReason);
            formData.append("eventNote", selectNotes);
            if (selectFileArray.length === 0) {
                handleState('errorMessage', 'Documents are required');
            } else {
                if (sizeMax < (5000 * 1000)) {
                    API.putFile({
                        bodyCustom: formData,
                        pathCustom: `dispute/event/`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    }).then((response) => {
                        actionShowModal();
                        handleState('clearDataDispute', null);
                        actionAfterUpdateItem(id)
                        dispatchStatePage({ type: 'showDetail', value: false })
                    }).catch((err) => {
                        handleState('errorMessage', err)
                    })
                } else if (sizeMax > (5000 * 1000)) {
                    handleState('errorMessage', 'Maximum upload size exceeded, maximum size is 5Mb');
                }
            }
        }

    }

    const TransactionInformation = () => {
        return (
            <div className='w-100'>
                {/* <img src={imgSrcArray} alt="show image data" />  // show image */}
                <div className="row br-10 p-0 m-0" >
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12 p-0 m-0 " >
                        <Divider />
                        <h2 className="s-1822">Chargeback Information</h2>
                        <div className="" >
                            <div className="" >
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}>Case ID</div>
                                    <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.caseId)}</div>
                                </div>
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}>Chargeback Issuer Amount</div>
                                    <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.chargeIssCurrency)} {NumberWithCommas(CheckUndefined(statePage.dataDispute.chargeIssAmount))}</div>
                                </div>
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}>Chargeback Acquirer Amount</div>
                                    <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.chargeAcqCurrency)} {NumberWithCommas(CheckUndefined(statePage.dataDispute.chargeAcqAmount))}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12 p-0 m-0 " >
                        <Divider />
                        <h2 className="s-1822">Issuer Contact Information</h2>
                        <div className="">
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Name</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.issName)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Contact Number</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.issContact)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Email</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.issEmail)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12 p-0 m-0 " >
                        <Divider />
                        <h2 className="s-1822">Notes and Documents</h2>
                        <div className="">
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Reason</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.eventReason)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Notes</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.eventNote)}</div>
                            </div>
                            <div className="d-flex bd-ea min-h-60px" >
                                <div className={`${classNameLeft}`} >Documents</div>
                                <div className={`${classNameRight}`}>

                                    <div className="word-wrap border-0 Lato-700 s-1214" >
                                        <ul className='p-0' style={{ listStyle: 'none' }} >
                                            {
                                                statePage.images && statePage.images.map((element1: any) => {
                                                    return (
                                                        <li className='my-2 pointer c-1e90ff' onClick={() => downloadFile(element1.efFileDownloadS3)}>
                                                            : {element1.efFileDownloadS3}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <Divider />

                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Status</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(statePage.dataDispute.eventStatus)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const FormDocument = (index: any) => {
        return (
            <>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <div className="custom-file ">
                        <input type="file"
                            // accept="image/*"
                            className="custom-file-input" onChange={(e) => {
                                let newListFile: any[] = []
                                statePage.selectFileArray.forEach((element1: any, indexElement: any) => {
                                    if (indexElement === index) {
                                        newListFile.push(e.target.files)
                                    } else {
                                        newListFile.push(element1)
                                    }
                                })
                                handleState('selectFileArray', newListFile);

                            }} />
                        <label className="custom-file-label" >{statePage.selectFileArray[index] !== '' ? 'File Selected' : 'Choose File'}</label>
                    </div>
                    <div className='btn btn-danger ml-2' onClick={() => {
                        let newListFile: any[] = []
                        statePage.selectFileArray.forEach((element1: any) => newListFile.push(element1));
                        newListFile.splice(index, 1);
                        handleState('selectFileArray', newListFile);

                    }} >
                        <IoIosRemoveCircle size={30} />
                        <span className='ml-2'>
                            Delete File
                        </span>
                    </div>
                </div>
            </>

        )
    }
    const ListFormDocument = () => {
        return (
            <>
                {
                    statePage.selectFileArray && statePage.selectFileArray.map((element1: any, index: any) => {
                        return (FormDocument(index))
                    })
                }
            </>
        )
    }


    const ChargebackInformation = () => {
        return (
            <div className="">
                <Divider />
                <h2 className="s-1822 my-2">Re-Presentment Information</h2>
                <div className="row br-10 p-0" >
                    <div className="col-12 br-10  p-0" >
                        <div className="d-flex flex-column mb-3">
                            <p className={labelStyle}>Re-Presentment Reason</p>
                            <Select options={optionsRePresentmentReasonDetailCode} value={optionsRePresentmentReasonDetailCode.filter((option: any) => option.value === statePage.selectChargebackReason)} onChange={(e) => handleState("selectChargebackReason", e.value)} />
                        </div>
                        <div className='d-flex flex-column mt-3 mb-3'>
                            <p className={labelStyle}>NOTES</p>
                            <textarea rows={4} value={statePage.selectNotes} onChange={(e) => handleState('selectNotes', e.target.value)} className='bd-d4 bg-ff p-2 br-5 width-100 s-1418 Lato-400 w-500 out-none pl-10' />
                        </div>
                        {/* 
                        <div className='d-flex flex-column mb-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='c-54 text-up mb6 s-1214 d-flex align-items-end' style={{ height: '40px' }}>DOCUMENTS </p>

                            </div>
                            {ListFormDocument()}

                            <div className='btn btn-info' onClick={() => {
                                let newListFile: any[] = [];
                                statePage.selectFileArray.forEach((element1: any) => newListFile.push(element1));
                                newListFile.push('');
                                handleState('selectFileArray', newListFile);
                            }}>
                                <IoIosAddCircle color='white' size={30} />
                                <span className='ml-2 text-white'>
                                    Add New File
                                </span>
                            </div>
                        </div> 
                        */}

                    </div>
                    <UploadFileComponent handleStateParent={handleState} stateParent={statePage} useMultipleFile={true} />


                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => ButtonSubmitAction()} />
            </div>
        )
    }
    const downloadFile = (endpoint: any) => {
        API.getFile({
            bodyCustom: null,
            pathCustom: `dispute/event/file/${endpoint}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            try {
                let data = `data:${response.headers["content-type"]};base64,${new Buffer(response.data, "binary").toString("base64")}`;
                let fileArray = `${endpoint}`.toString().split('.');
                let fileName = '';
                let fileExtensionType = fileArray[fileArray.length - 1];
                for (let index = 0; index < fileArray.length; index++) {
                    if ((fileArray.length - 1) !== (index)) {
                        fileName = fileName + fileArray[index]
                    }
                }
                saveAs(data, `${fileName}.${fileExtensionType}`);
            } catch (error) {
            }
        }).catch((err) => { })
    }

    // check if the member code is the same as the response given by the dispute API
    let respondCode = statePage?.dataDispute?.responderMemberCode
    let checkResponder = respondCode && respondCode.some((code: any) => code === userLogin.memberCode)

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >
            <div className='d-flex flex-column'>
                <div className='d-flex flex-column ' style={{ overflow: 'auto', height: '550px' }}>

                    {TransactionInformation()}
                    <ErrorMessage errorMessage={statePage.errorMessageButtonAccept} />
                    {
                        statePage.showDetail === true ?
                            ChargebackInformation()
                            :
                            <></>
                    }

                    {
                        (statePage.dataDispute.eventStatusCode !== '000' && statePage.dataDispute.eventStatusCode !== '002') ? // if the condition is Responded(000) or Accepted(002) then there is no need to respond again or the button is removed
                            // (userLogin.memberCode === statePage.dataDispute.responderMemberCode) ? // check if the member code is the same as the response given by the dispute API
                            (checkResponder) ? // check if the member code is the same as the response given by the dispute API
                                (userLogin.memberIsRegisteredToPIC === true) ? // user login is registered member PIC
                                    statePage.showDetail === true ?
                                        <></>
                                        :
                                        // Sama ini juga mas, kemarin kan mas Imam nyuruhnya cuman sampain di negotiation, yang arbitration itu yang ngurus bcap, jadi kalau eventnya typenya 004 button accept sama responsenya di hidden atau di disable
                                        // jadi kalau eventnya typenya 004 button accept sama responsenya di hidden atau di disable
                                        <div className="d-flex align-items-center justify-content-start p-0 my-3">
                                            {statePage.dataDispute.eventTypeCode === '003' ? // check if eventTypeCode
                                                <button className="btn btn-secondary d-flex align-items-center px-4 py-2 br-10 e" onClick={(e) => ButtonAcceptAction()}>
                                                    <p className="s-1418  Lato-600">Accept</p>
                                                </button>
                                                :
                                                <div className="d-flex align-items-center justify-content-start p-0 my-3">
                                                    {
                                                        statePage.dataDispute.eventStatusCode === '004' ?
                                                            <button className="btn btn-secondary d-flex align-items-center px-4 py-2 br-10 e" disabled>
                                                                <p className="s-1418  Lato-600">Accept</p>
                                                            </button>
                                                            :
                                                            <button className="btn btn-secondary d-flex align-items-center px-4 py-2 br-10 e" onClick={(e) => ButtonAcceptAction()}>
                                                                <p className="s-1418  Lato-600">Accept</p>
                                                            </button>
                                                    }
                                                    {
                                                        statePage.dataDispute.eventStatusCode === '004' ?
                                                            <button className="btn btn-secondary d-flex align-items-center px-4 py-2 br-10 mx-2" disabled>
                                                                <p className="s-1418  Lato-600">Respond</p>
                                                            </button>
                                                            :
                                                            <button className="btn btn-secondary d-flex align-items-center px-4 py-2 br-10 mx-2" onClick={(e) => {
                                                                dispatchStatePage({ type: 'showDetail', value: true })
                                                                dispatchStatePage({ type: 'errorMessageButtonAccept', value: '' })
                                                            }}>
                                                                <p className="s-1418  Lato-600">Respond</p>
                                                            </button>
                                                    }
                                                </div>
                                            }
                                        </div>

                                    :
                                    <></>

                                :
                                <></>
                            :
                            <></>
                    }
                </div>
            </div>
        </Modal>
    );
}

const initialState = {
    titleModal: 'EVENT DETAILS',
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageButtonAccept: '',
    errorMessageList: [],
    //
    data: new ModelDetailDisputeResolutionEvent({
        id: null,
        eventId: null,
        dateTime: null,
        eventType: null,
        event: null,
        user: null,
        member: null,
        detail: null,
    }),
    //
    dataDispute: new ModelModelDisputeModal({
        id: null,
        eventId: null,
        caseId: null,
        chargeIssCurrency: null,
        chargeIssAmount: null,
        chargeAcqCurrency: null,
        chargeAcqAmount: null,
        iss: null,
        issName: null,
        issContact: null,
        issEmail: null,
        acq: null,
        acqName: null,
        acqContact: null,
        acqEmail: null,
        eventType: null,
        eventTypeCode: null,
        eventReason: null,
        eventNote: null,
        eventStatus: null,
        eventStatusCode: null,
        responderMemberCode: null,

    }),
    //
    id: '',
    showDetail: false,
    selectChargebackReason: '',
    selectNotes: '',
    selectFile: '',
    selectFileArray: [''],
    imgSrcArray: ''


}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelDetailDisputeResolutionEvent) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageButtonAccept':
            return {
                ...state,
                errorMessageButtonAccept: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelDetailDisputeResolutionEvent({
                    id: null,
                    eventId: null,
                    dateTime: null,
                    eventType: null,
                    event: null,
                    user: null,
                    member: null,
                    detail: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelDetailDisputeResolutionEvent({
                    id: action.value.eventId,
                    eventId: action.value.eventId,
                    dateTime: action.value.dateTime,
                    eventType: action.value.eventType,
                    event: action.value.event,
                    user: action.value.user,
                    member: action.value.member,
                    detail: action.value.detail,
                }),
                errorMessage: "",
            };


        case 'clearDataDispute':
            return {
                ...state,
                dataDispute: new ModelModelDisputeModal({
                    id: null,
                    eventId: null,
                    caseId: null,
                    chargeIssCurrency: null,
                    chargeIssAmount: null,
                    chargeAcqCurrency: null,
                    chargeAcqAmount: null,
                    iss: null,
                    issName: null,
                    issContact: null,
                    issEmail: null,
                    acq: null,
                    acqName: null,
                    acqContact: null,
                    acqEmail: null,
                    eventType: null,
                    eventTypeCode: null,
                    eventReason: null,
                    eventNote: null,
                    eventStatus: null,
                    eventStatusCode: null,
                    responderMemberCode: null,
                }),
                errorMessage: "",

            };
        case 'setDataDispute':
            return {
                ...state,
                dataDispute: new ModelModelDisputeModal({
                    id: action.value.eventId,
                    eventId: action.value.eventId,
                    caseId: action.value.caseId,
                    chargeIssCurrency: action.value.chargeIssCurrency,
                    chargeIssAmount: action.value.chargeIssAmount,
                    chargeAcqCurrency: action.value.chargeAcqCurrency,
                    chargeAcqAmount: action.value.chargeAcqAmount,
                    iss: action.value.iss,
                    issName: action.value.issName,
                    issContact: action.value.issContact,
                    issEmail: action.value.issEmail,
                    acq: action.value.acq,
                    acqName: action.value.acqName,
                    acqContact: action.value.acqContact,
                    acqEmail: action.value.acqEmail,
                    eventType: action.value.eventType,
                    eventTypeCode: action.value.eventTypeCode,
                    eventReason: action.value.eventReason,
                    eventNote: action.value.eventNote,
                    eventStatus: action.value.eventStatus,
                    eventStatusCode: action.value.eventStatusCode,
                    responderMemberCode: action.value.responderMemberCode,
                }),
                errorMessage: "",
            };

        case 'showDetail':
            return {
                ...state,
                showDetail: action.value,
            }
        case 'selectChargebackReason':
            return {
                ...state,
                selectChargebackReason: action.value,
            }
        case 'selectNotes':
            return {
                ...state,
                selectNotes: action.value,
            }
        case 'selectFile':
            return {
                ...state,
                selectFile: action.value,
            }
        case 'selectFileArray':
            return {
                ...state,
                selectFileArray: action.value,
            }
        case 'imgSrcArray':
            return {
                ...state,
                imgSrcArray: action.value,
            }
        case 'images':
            return {
                ...state,
                images: action.value,
            }
        case 'resetForms':
            return {
                ...state,
                selectChargebackReason: '',
                selectNotes: '',
                selectFileArray: [''],
            }

        default:
            throw new Error();
    }
}





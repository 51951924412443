import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PieChart } from 'react-minimal-pie-chart';
import { Divider } from 'antd';

import { labelStyle } from '../../../Styles';
import { applicationTwoIcon, checklistButtonGreenIcon, close_red } from '../../../Assets/Images/svg';

import { changeStateDataCopyFilterTransactionMonitoring, stateGlobalTransactionMonitoring } from '../../../Store/TransactionMonitoringSlice';


export const PieChartComponent = ({ ...props }) => {
    const dispatchRedux = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { stateOptions } = props;

    const globalState = useSelector(stateGlobalTransactionMonitoring);
    const { dataFilter, dataCopyFilter, dataByDate, dataCopyByDate, dataYesterday, dataYesterdayByDate, dataYesterdayCopyByDate, filterStartDate, filterEndDate, selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedProductId, selectedProductLabel, selectedProductSendToApi, search, optionsProductFeature, optionsProduct, listMember, listReasonCode, rangeDate } = globalState;
    const { handleStateComponent } = props;

    useEffect(() => {
        handleStateComponent('transDivided', state.transDivided)
    }, [dataFilter, dataCopyFilter, dataByDate, dataCopyByDate, dataYesterdayByDate, filterStartDate, filterEndDate, selectedProductSendToApi, selectedProductFeatureSendToApi, rangeDate, state.transDivided])

    useEffect(() => {
        countAllTransaction();
        dividingTransaction();
        convertDataNeededPieChart('issuerIdCode', 'issuerId', 'issuerName', 'optionsIssuerInstitutionIDUseUseExtAndDetail');// issuerName => Issuer
        convertDataNeededPieChart('acquirerIdCode', 'acquirerId', 'acquirerName', 'optionsAcquirerInstitutionIDUseUseExtAndDetail');// issuerName => Issuer
        convertDataNeededPieChart('issuerSwitcherIdCode', 'issuerSwitcherId', 'issuerSwitcherName', 'optionsSwitcherInstitutionIDUseExtAndDetail');// issuerName => Issuer
        convertDataNeededPieChart('acquirerSwitcherIdCode', 'acquirerSwitcherId', 'acquirerSwitcherName', 'optionsSwitcherInstitutionIDUseExtAndDetail');// issuerName => Issuer
        // convertDataNeededPieChart('acquirerIdCode');
        // convertDataNeededPieChart('issuerSwitcherIdCode');
        // convertDataNeededPieChart('acquirerSwitcherIdCode');

        // ---------------------------------------------
        // countAllTransaction();
        // dividingTransaction();
        // convertDataNeededPieChart('issuerId');// issuerName => Issuer
        // convertDataNeededPieChart('acquirerId');
        // convertDataNeededPieChart('issuerSwitcherId');
        // convertDataNeededPieChart('acquirerSwitcherId');

    }, [dataFilter, dataCopyFilter, dataByDate, dataCopyByDate, dataYesterdayByDate, filterStartDate, filterEndDate, selectedProductSendToApi, selectedProductFeatureSendToApi, props.stateOptions.optionsSAFStatusDetailCode])

    const countAllTransaction = () => {
        if (dataCopyFilter.length > 0) {
            let counted = 0;
            dataCopyFilter.forEach((element1: any) => {
                counted = counted + parseInt(element1.transCount);
            });
            dispatch({ type: `countedAllTransction`, value: counted });
        } else {
            dispatch({ type: `countedAllTransction`, value: 0 });
        }
    }
    // unused: division of transactions by type APPROVED, DECLINED, ERROR
    const dividingTransaction = () => {
        let newResultData: any[] = [];
        if (stateOptions.optionsReasonCode.length > 0) {
            let newListDataStatusTransaction: any = [];
            stateOptions.optionsReasonCode.forEach((element1: any) => {
                newListDataStatusTransaction.push(element1.rcName);
            })
            let removeSameValue: any[] = Array.from(new Set(newListDataStatusTransaction)); // remove same id
            let newListReasonCode = [...removeSameValue.map((itemOptions: any) => {
                return {

                    "status": itemOptions,
                    "rcCodeList": []
                }
            })];
            newListReasonCode.push(
                {
                    "status": "UNKNOWN",
                    "rcCodeList": []
                },
            )

            newListReasonCode.forEach((element1: any) => {
                let dataListTransactionDivided: any[] = [];

                if (dataCopyFilter.length > 0) {
                    // change transStatus => "-" to DECLINED
                    let newDataCopyFilter: any[] = []
                    dataCopyFilter.forEach((itemDataCopyFilter: any) => {
                        newDataCopyFilter.push(itemDataCopyFilter)
                    })

                    newDataCopyFilter.forEach((element2: any) => {
                        // if (element1.rcCodeList.includes(element2.transAuthIntResponseCode)) {
                        //   dataListTransactionDevided.push(element2:any);
                        // }
                        // console.log(element1.status)
                        // console.log(element2.transStatus)
                        // console.log(element1.status === element2.transStatus)
                        // console.log('=========')
                        if (element1.status === element2.transStatus) {
                            dataListTransactionDivided.push(element2);
                        }

                    })
                }
                let resultData = { status: element1.status, rcCodeList: element1.status, dataListTransactionDevided: dataListTransactionDivided };
                newResultData.push(resultData);
            });
        }
        dispatch({ type: `transDivided`, value: newResultData });

        console.log("dividingTransaction: (transDivided)");
        console.log(state.transDivided);

        console.log("dividingTransaction: (dataCopyFilter)")
        console.log(dataCopyFilter)

        console.log("dividingTransaction: (dataCopyByDate)")
        console.log(dataCopyByDate)

        console.log("dividingTransaction: (dataYesterdayByDate)")
        console.log(dataYesterdayByDate)
    }

    // percentage Transaction Member, Name Transaction Member, Counted Transaction Member  
    const convertDataNeededPieChart = (memberCommonCode: string, memberCode: string, nameMember: string, nameOptions: string) => {
        // butuh percentage name dan total
        // group data berdasarkan member memberCommonCode and nameMember
        // memberId,  memberCode, memberName, memberColor
        // masukkan dalam array terlebih dahulu
        let newResultData: any[] = [];
        let optionsSelection: any[] = [];
        if (nameOptions === 'optionsAcquirerInstitutionIDUseUseExtAndDetail') {
            optionsSelection = [...stateOptions.optionsAcquirerInstitutionIDUseUseExtAndDetail]
        } else if (nameOptions === 'optionsIssuerInstitutionIDUseUseExtAndDetail') {
            optionsSelection = [...stateOptions.optionsIssuerInstitutionIDUseUseExtAndDetail]
        } else if (nameOptions === 'optionsSwitcherInstitutionIDUseExtAndDetail') {
            optionsSelection = [...stateOptions.optionsSwitcherInstitutionIDUseExtAndDetail]
        }
        let dataGroupCommonCode: any[] = []
        let dataGroupMemberCodeAndMemberName: any[] = []

        if (optionsSelection.length > 0) {
            optionsSelection.forEach((element1: any) => {
                // memberId,  memberCode, memberName, memberColor
                // masukkan dalam array terlebih dahulu
                // element.dataTransaction
                let dataListTransaction: any[] = [];
                // console.log(dataCopyFilter)
                if (dataCopyFilter.length > 0) {
                    dataCopyFilter.forEach((element2: any) => {
                        if ((element2[memberCommonCode] === element1.ccdCode) && (element2[nameMember] === element1.label)) {
                            // console.log('masuksini')
                            dataListTransaction.push(element2);
                            if (!dataGroupCommonCode.includes(element2[nameMember])) {
                                dataGroupCommonCode.push(element2[nameMember])
                                dataGroupMemberCodeAndMemberName.push({
                                    memberName: element2[nameMember],
                                    memberCode: element2[memberCommonCode],
                                })
                            }
                        }
                    });
                }
                let resultData = { memberId: element1.value, memberCode: element1.ccdCode, memberName: element1.label, memberColor: element1.color, dataListTransaction: dataListTransaction };
                newResultData.push(resultData);
            });
        };
        dispatch({ type: `${memberCommonCode}`, value: newResultData });
        // console.log(dataGroupMemberCodeAndMemberName)
        // console.log(newResultData)
        if (memberCommonCode === 'acquirerIdCode') {
            dispatch({ type: `selectedAcquirer`, value: dataGroupMemberCodeAndMemberName });
        } else if (memberCommonCode === 'issuerIdCode') {
            dispatch({ type: `selectedIssuer`, value: dataGroupMemberCodeAndMemberName });
        } else if (memberCommonCode === 'issuerSwitcherIdCode') {
            dispatch({ type: `selectedIssuerSwitcher`, value: dataGroupMemberCodeAndMemberName });
        } else if (memberCommonCode === 'acquirerSwitcherIdCode') {
            dispatch({ type: `selectedAcquirerSwitcher`, value: dataGroupMemberCodeAndMemberName });
        }
    }
    const onClickPieChart = (titleData: any, idLabel: any) => {
        let resultDataFilter: any[] = [];
        let resultDataByDateFilter: any[] = [];
        let resultDataFilterYesterday: any[] = [];
        let resultDataFilterYesterdayByDate: any[] = [];

        var result = titleData.substring(1, titleData.length - 1); // remove ()
        result = result === '' ? null : result;
        
        dataCopyFilter.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilter.push(element1);
            }
        });
        dataCopyByDate.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataByDateFilter.push(element1);
            }
        });
        
        dataYesterday.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilterYesterday.push(element1);
            }
        });
        dataYesterdayCopyByDate.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilterYesterdayByDate.push(element1);
            }
        });
        
        dispatchRedux(changeStateDataCopyFilterTransactionMonitoring({ 
            dataCopyFilter: resultDataFilter,
            dataCopyByDate: resultDataByDateFilter,
            dataYesterday: resultDataFilterYesterday,
            dataYesterdayCopyByDate: resultDataFilterYesterdayByDate
        }));
        // dispatchRedux(changeStateTransactionMonitoring({  }))
    };

    const PieChartIssuerId = () => {
        if (state.issuerIdCode.length > 0) {
            let conditionShowChart = false;
            state.issuerIdCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true; // if data exist show Pie Chart normal
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Issuer</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'issuerName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.issuerIdCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartIssuerId`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Issuer</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }

        } else {
            return (
                <div>
                    <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Issuer</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }

    }
    const PieChartAcquirerId = () => {
        if (state.acquirerIdCode.length > 0) {
            let conditionShowChart = false;
            state.acquirerIdCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true;
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Acquirer</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'acquirerName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.acquirerIdCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartAcquirerId`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>

                )
            } else {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Acquirer</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Acquirer</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }
    }
    const PieChartIssuerSwitcherId = () => {
        if (state.issuerSwitcherIdCode.length > 0) {
            let conditionShowChart = false;
            state.issuerSwitcherIdCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true;
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Issuer Switcher</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'issuerSwitcherName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.issuerSwitcherIdCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartIssuerSwitcherId`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>

                )
            } else {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Issuer Switcher</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Issuer Switcher</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }
    }
    const PieChartAcquirerSwitcherName = () => {
        if (state.acquirerSwitcherIdCode.length > 0) {
            let conditionShowChart = false;
            state.acquirerSwitcherIdCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true;
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Acquirer Switcher</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'acquirerSwitcherName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.acquirerSwitcherIdCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartAcquirerSwitcherName`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>

                )
            } else {
                return (
                    <div>
                        <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Acquirer Switcher</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div className='fs-lato-14px-normal text-secondary text-center mx-2 my-2'>Acquirer Switcher</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }
    }
    const LegendPieChart = () => {
        let LegendAcquirer: any[] = []
        let LegendIssuer: any[] = []
        let LegendSwitcher: any[] = []
        if (stateOptions.optionsAcquirerInstitutionIDUseUseExtAndDetail.length > 0) {
            stateOptions.optionsAcquirerInstitutionIDUseUseExtAndDetail.forEach((element1: any, index: any) => {
                //         convertDataNeededPieChart('issuerIdCode', 'issuerId', 'issuerName', 'optionsIssuerInstitutionIDUseUseExtAndDetail');// issuerName => Issuer
                // convertDataNeededPieChart('acquirerIdCode', 'acquirerId', 'acquirerName', 'optionsAcquirerInstitutionIDUseUseExtAndDetail');// issuerName => Issuer
                // convertDataNeededPieChart('issuerSwitcherIdCode', 'issuerSwitcherId', 'issuerSwitcherName', 'optionsSwitcherInstitutionIDUseExtAndDetail');// issuerName => Issuer
                // convertDataNeededPieChart('acquirerSwitcherIdCode', 'acquirerSwitcherId', 'acquirerSwitcherName', 'optionsSwitcherInstitutionIDUseExtAndDetail');// issuerName => Issuer

                if (state.selectedAcquirer.length > 0) {
                    state.selectedAcquirer.forEach((itemAcquirer: any) => {
                        if ((itemAcquirer.memberName === element1.label) && (itemAcquirer.memberCode === element1.ccdCode)) {
                            if (state.issuerIdCode.length > 0) {
                                LegendAcquirer.push(
                                    (
                                        <div key={element1.label} className='mx-2 my-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10'>
                                            <div
                                                key={element1.label}
                                                style={{
                                                    backgroundColor: element1.color,
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '7px',
                                                    display: 'inline-block',
                                                }}
                                                className='mx-2'
                                            ></div>
                                            <span className='Lato-400'>{element1.label}</span>
                                        </div>
                                    )
                                )
                            }
                        } else {
                            return <></>
                        }
                    })
                }
            })
        }
        if (stateOptions.optionsIssuerInstitutionIDUseUseExtAndDetail.length > 0) {
            stateOptions.optionsIssuerInstitutionIDUseUseExtAndDetail.forEach((element1: any, index: any) => {
                if (state.selectedIssuer.length > 0) {
                    state.selectedIssuer.forEach((itemIssuer: any) => {
                        if ((itemIssuer.memberName === element1.label) && (itemIssuer.memberCode === element1.ccdCode)) {
                            if (state.issuerIdCode.length > 0) {
                                LegendIssuer.push(
                                    (
                                        <div key={element1.label} className='mx-2 my-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10'>
                                            <div
                                                key={element1.label}
                                                style={{
                                                    backgroundColor: element1.color,
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '7px',
                                                    display: 'inline-block',
                                                }}
                                                className='mx-2'
                                            ></div>
                                            <span className='Lato-400'>{element1.label}</span>
                                        </div>
                                    )
                                )
                            }
                        } else {
                            return <></>
                        }
                    })
                }
            })
        }

        if (stateOptions.optionsSwitcherInstitutionIDUseExtAndDetail.length > 0) {
            stateOptions.optionsSwitcherInstitutionIDUseExtAndDetail.forEach((element1: any, index: any) => {
                let concatAllSwitcher = [...state.selectedIssuerSwitcher].concat(state.selectedAcquirerSwitcher)
                let uniqueArray: any[] = concatAllSwitcher.filter(
                    (item, index, self) => index === self.findIndex((t) => t.memberCode === item.memberCode)
                );

                if (uniqueArray.length > 0) {
                    uniqueArray.forEach((itemIssuer: any) => {
                        if ((itemIssuer.memberName === element1.label) && (itemIssuer.memberCode === element1.ccdCode)) {
                            if (state.issuerSwitcherIdCode.length > 0 && state.acquirerSwitcherIdCode.length > 0) {
                                LegendSwitcher.push(
                                    (
                                        <div key={element1.label} className='mx-2 my-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10'>
                                            <div
                                                key={element1.label}
                                                style={{
                                                    backgroundColor: element1.color,
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '7px',
                                                    display: 'inline-block',
                                                }}
                                                className='mx-2'
                                            ></div>
                                            <span className='Lato-400'>{element1.label}</span>
                                        </div>
                                    )
                                )
                            }
                        } else {
                            return <></>
                        }
                    })
                }

            })



        }

        return (
            <>
                <Divider />
                <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
                    {
                        LegendAcquirer.length > 0 ?
                            <>
                                {LegendAcquirer.map((element1: any) => element1)}
                            </>
                            :
                            <></>
                    }
                    {
                        LegendIssuer.length > 0 ?
                            <>
                                {LegendIssuer.map((element1: any) => element1)}

                            </>
                            :
                            <></>
                    }
                    {
                        LegendSwitcher.length > 0 ?
                            <>
                                {LegendSwitcher.map((element1: any) => element1)}
                            </>
                            :
                            <></>
                    }
                </div>



            </>
        )
        // return (
        //     <>
        //         <Divider />
        //         {
        //             LegendAcquirer.length > 0 ?

        //                 <div>
        //                     <div className={labelStyle}>Acquirer</div>
        //                     <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
        //                         {LegendAcquirer.map((element1: any) => element1)}
        //                     </div>
        //                 </div>
        //                 :
        //                 <></>

        //         }
        //         {
        //             LegendIssuer.length > 0 ?
        //                 <>
        //                     <Divider />
        //                     <div>
        //                         <div className={labelStyle}>Issuer</div>
        //                         <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
        //                             {LegendIssuer.map((element1: any) => element1)}
        //                         </div>
        //                     </div>

        //                 </>
        //                 :
        //                 <></>
        //         }
        //         {
        //             LegendSwitcher.length > 0 ?
        //                 <>
        //                     <Divider />
        //                     <div>
        //                         <div className={labelStyle}>Issuer Switcher & Acquirer Switcher</div>
        //                         <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
        //                             {LegendSwitcher.map((element1: any) => element1)}
        //                         </div>
        //                     </div>
        //                 </>
        //                 :
        //                 <></>
        //         }

        //     </>
        // )
    }

    const StatusTransaction = () => {
        if (state.transDivided.length > 0) {
            let dataTransactionApproved = state.transDivided.filter((element1: any) => element1.status === 'APPROVED')[0];
            let dataTransactionDeclined = state.transDivided.filter((element1: any) => element1.status === 'DECLINED')[0];
            let dataTransactionSuspended = state.transDivided.filter((element1: any) => element1.status === 'SUSPENDED')[0];
            let dataTransactionError = state.transDivided.filter((element1: any) => element1.status === 'ERROR')[0];
            let dataTransactionInvalid = state.transDivided.filter((element1: any) => element1.status === 'INVALID')[0];
            let dataTransactionUnknown = state.transDivided.filter((element1: any) => element1.status === 'UNKNOWN')[0];
            let countedTransactionApproved: number = 0;
            let countedTransactionDeclined: number = 0;
            let countedTransactionSuspended: number = 0;
            let countedTransactionError: number = 0;
            let countedTransactionInvalid: number = 0;
            let countedTransactionUnknown: number = 0;
            let dataCurrencies: any[] = [];
            let dataTransApprovedByCurrency: any[] = [];


            if (dataTransactionApproved.dataListTransactionDevided.length > 0) {
                dataTransactionApproved.dataListTransactionDevided.forEach((element1: any) => {
                    countedTransactionApproved = countedTransactionApproved + parseInt(element1.transCount);
                    if (!dataCurrencies.includes(element1.transCurrency)) { dataCurrencies.push(element1.transCurrency); }
                })

                dataCurrencies.forEach((curr: any) => {
                    let paymentCount: number = 0;
                    let paymentAmount: any = parseFloat('0');
                    let cancelCount: number = 0;
                    let cancelAmount: any = parseFloat('0');
                    let nettAmount: any = parseFloat('0');

                    dataTransactionApproved.dataListTransactionDevided.forEach((element2: any) => {
                        if (element2.transCurrency === curr) {
                            if (element2.productFeature === "QR004" || element2.productFeature === "QR006") {
                                cancelCount = cancelCount + parseInt(element2.transCount);
                                if (!isNaN(parseFloat(element2.transAmount))) {
                                    cancelAmount = cancelAmount + element2.transAmount;
                                }
                            } else {
                                paymentCount = paymentCount + parseInt(element2.transCount);
                                if (!isNaN(parseFloat(element2.transAmount))) {
                                    paymentAmount = paymentAmount + element2.transAmount;
                                }
                            }
                        }
                    })

                    nettAmount = paymentAmount - cancelAmount;
                    nettAmount = parseFloat(nettAmount).toFixed(2);

                    dataTransApprovedByCurrency.push({ currency: curr, paymentCount: paymentCount, paymentAmount: paymentAmount, cancelCount: cancelCount, cancelAmount: cancelAmount, nettAmount: nettAmount });

                })
            }
            if (dataTransactionDeclined.dataListTransactionDevided.length > 0) {
                dataTransactionDeclined.dataListTransactionDevided.forEach((element1: any) => {
                    countedTransactionDeclined = countedTransactionDeclined + parseInt(element1.transCount);
                })
            }
            if (dataTransactionSuspended.dataListTransactionDevided.length > 0) {
                dataTransactionSuspended.dataListTransactionDevided.forEach((element1: any) => {
                    countedTransactionSuspended = countedTransactionSuspended + parseInt(element1.transCount);
                })
            }
            if (dataTransactionError.dataListTransactionDevided.length > 0) {
                dataTransactionError.dataListTransactionDevided.forEach((element1: any) => {
                    countedTransactionError = countedTransactionError + parseInt(element1.transCount);
                })
            }
            if (dataTransactionInvalid.dataListTransactionDevided.length > 0) {
                dataTransactionInvalid.dataListTransactionDevided.forEach((element1: any) => {
                    countedTransactionInvalid = countedTransactionInvalid + parseInt(element1.transCount);
                })
            }
            if (dataTransactionUnknown.dataListTransactionDevided.length > 0) {
                dataTransactionUnknown.dataListTransactionDevided.forEach((element1: any) => {
                    countedTransactionUnknown = countedTransactionUnknown + parseInt(element1.transCount);
                })
            }

            return (
                <div className='row p-0 m-0 bd-ea-bottom'>
                    <div className='col-2 d-flex justify-content-center align-items-center  p-0' style={{ minHeight: '72px', overflow: 'hidden' }}>
                        <div className='mr-4 bd-ea px-2 py-2 br-20 d-flex justify-content-center align-items-center' style={{ height: '40px', width: '40px' }}>
                            {applicationTwoIcon}
                        </div>
                        <div className='w-10px'></div>
                        <div>
                            <div className='fs-lato-14px-normal text-secondary mb-1'>Total Transactions</div>
                            <div className='s-2024 Lato-700'>{state.countedAllTransction}</div>
                        </div>
                    </div>
                    <div className='col-5 p-0 justify-content-center align-items-center'>
                        <div className='col-12 justify-content-center align-items-center d-flex'>
                            <div className='mr-4 bd-ea px-2 py-2 br-20 d-flex justify-content-center align-items-center' style={{ height: '40px', width: '40px' }}>
                                {checklistButtonGreenIcon}
                            </div>
                            <div className='w-10px'></div>
                            <div>
                                <div className='fs-lato-14px-normal text-secondary mb-1'>Approval Rate</div>
                                <div className='s-2024 Lato-700'>{isNaN(parseFloat(((countedTransactionApproved / state.countedAllTransction) * 100).toFixed(2))) ? 0 : ((countedTransactionApproved / state.countedAllTransction) * 100).toFixed(2)}% ({countedTransactionApproved})</div>
                            </div>
                        </div>
                        {countedTransactionApproved > 0 && <div className='col-12 justify-content-center align-items-center d-flex'>
                            <div className='m-2'>
                                <div className='fs-lato-14px-normal text-secondary mb-1'>Payments</div>
                                {dataTransApprovedByCurrency.map((trans: any, index: any) => {
                                    return (
                                        <div className='s-1418 Lato-700'>{trans.currency} {parseFloat(trans.paymentAmount).toFixed(2)} ({trans.paymentCount})</div>
                                    );
                                })}
                            </div>
                            <div className='m-2'>
                                <div className='fs-lato-14px-normal text-secondary mb-1'>Cancelations</div>
                                {dataTransApprovedByCurrency.map((trans: any, index: any) => {
                                    return (
                                        <div className='s-1418 Lato-700'>{trans.currency} {parseFloat(trans.cancelAmount).toFixed(2)} ({trans.cancelCount})</div>
                                    );
                                })}
                            </div>
                            <div className='m-2'>
                                <div className='fs-lato-14px-normal text-secondary mb-1'>Nett</div>
                                {dataTransApprovedByCurrency.map((trans: any, index: any) => {
                                    return (
                                        <div className='s-1418 Lato-700'>{trans.currency} {parseFloat(trans.nettAmount).toFixed(2)}</div>
                                    );
                                })}
                            </div>
                        </div>}
                    </div>
                    <div className='col-5 p-0 justify-content-center align-items-center'>
                        <div className='col-12 d-flex justify-content-center align-items-center p-0'>
                            <div className='mr-4 bd-ea px-2 py-2 br-20 d-flex justify-content-center align-items-center' style={{ height: '40px', width: '40px' }}>
                                {close_red}
                            </div>
                            <div className='w-10px'></div>
                            <div>
                                <div className='fs-lato-14px-normal text-secondary mb-1'>Reject Rate</div>
                                <div className='s-2024 Lato-700'>{isNaN(parseFloat((((state.countedAllTransction - countedTransactionApproved) / state.countedAllTransction) * 100).toFixed(2))) ? 0 : (((state.countedAllTransction - countedTransactionApproved) / state.countedAllTransction) * 100).toFixed(2)}% ({(state.countedAllTransction - countedTransactionApproved)})</div>
                            </div>
                        </div>

                        {(state.countedAllTransction - countedTransactionApproved) > 0 && <div className='col-12 justify-content-center align-items-center p-0 m-0 mt-2 d-flex'>
                            {countedTransactionDeclined ? <div className='col-2 d-flex justify-content-center align-items-center p-0 mb-2'>
                                <div>
                                    <div className='fs-lato-14px-normal text-secondary mb-1'>Decline Rate</div>
                                    <div className='Lato-700'>{isNaN(parseFloat(((countedTransactionDeclined / state.countedAllTransction) * 100).toFixed(2))) ? 0 : ((countedTransactionDeclined / state.countedAllTransction) * 100).toFixed(2)}% ({countedTransactionDeclined})</div>
                                </div>
                            </div> : <div />}
                            {countedTransactionError ? <div className='col-2 d-flex justify-content-center align-items-center p-0 mb-2'>
                                <div>
                                    <div className='fs-lato-14px-normal text-secondary mb-1'>Error Rate</div>
                                    <div className='Lato-700'>{isNaN(parseFloat(((countedTransactionError / state.countedAllTransction) * 100).toFixed(2))) ? 0 : ((countedTransactionError / state.countedAllTransction) * 100).toFixed(2)}% ({countedTransactionError})</div>
                                </div>
                            </div> : <div />}
                            {countedTransactionSuspended ? <div className='col-2 d-flex justify-content-center align-items-center p-0 mb-2'>
                                <div>
                                    <div className='fs-lato-14px-normal text-secondary mb-1'>Timeout Rate</div>
                                    <div className='Lato-700'>{isNaN(parseFloat(((countedTransactionSuspended / state.countedAllTransction) * 100).toFixed(2))) ? 0 : ((countedTransactionSuspended / state.countedAllTransction) * 100).toFixed(2)}% ({countedTransactionSuspended})</div>
                                </div>
                            </div> : <div />}
                            {countedTransactionInvalid ? <div className='col-2 d-flex justify-content-center align-items-center p-0 mb-2'>
                                <div>
                                    <div className='fs-lato-14px-normal text-secondary mb-1'>Invalid Rate</div>
                                    <div className='Lato-700'>{isNaN(parseFloat(((countedTransactionInvalid / state.countedAllTransction) * 100).toFixed(2))) ? 0 : ((countedTransactionInvalid / state.countedAllTransction) * 100).toFixed(2)}% ({countedTransactionInvalid})</div>
                                </div>
                            </div> : <div />}
                            {countedTransactionUnknown ? <div className='col-2 d-flex justify-content-center align-items-center p-0 mb-2'>
                                <div>
                                    <div className='fs-lato-14px-normal text-secondary mb-1'>Unknown Rate</div>
                                    <div className='Lato-700'>{isNaN(parseFloat(((countedTransactionUnknown / state.countedAllTransction) * 100).toFixed(2))) ? 0 : ((countedTransactionUnknown / state.countedAllTransction) * 100).toFixed(2)}% ({countedTransactionUnknown})</div>
                                </div>
                            </div> : <div />}
                        </div>}
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }
    return (
        <>
            <div className='my-2'>
                <div className='width-100 '>
                    <div className='bg-ff bd-ea br-10 py-2 px-2'>
                        {StatusTransaction()}
                        <div className='d-flex justify-content-evenly my-3' >
                            {PieChartIssuerSwitcherId()}
                            {PieChartIssuerId()}
                            {PieChartAcquirerSwitcherName()}
                            {PieChartAcquirerId()}
                        </div>
                        <LegendPieChart />
                    </div>
                </div>
            </div>
        </>
    )
}

const initialState = {
    dataMain: [
        { id: 'issuerName', label: 'Issuer', data: [] }, //transAuthSrcMemberId 
        { id: 'acquirerName', label: 'Acquirer', data: [] },
        { id: 'issuerSwitcherName', label: 'Issuer Switcher', data: [] },
        { id: 'acquirerSwitcherName', label: 'Acquirer Switchers', data: [] },
        // { id: 'issuerSwitcherName', label: 'issuerSwitcherId', data: [] }, // issuerSwitcherId
        // { id: 'acquirerSwitcherName', label: 'acquirerSwitcherId', data: [] }, //acquirerSwitcherId
        { id: 'issuerIdCode', label: 'Issuer', data: [] },
        { id: 'acquirerIdCode', label: 'Acquirer', data: [] },
        { id: 'issuerSwitcherIdCode', label: 'Issuer Switcher', data: [] },
        { id: 'acquirerSwitcherIdCode', label: 'Acquirer Switchers', data: [] },
    ],
    data: '',
    issuerId: [], // issuerName => Issuer => issuerId
    acquirerId: [], // acquirerName => Acquirer
    issuerSwitcherId: [],
    acquirerSwitcherId: [],
    //
    issuerIdCode: [],
    acquirerIdCode: [],
    issuerSwitcherIdCode: [],
    acquirerSwitcherIdCode: [],
    //
    transDivided: [],
    countedAllTransction: 0,

    selectedIssuerSwitcher: [],
    selectedAcquirerSwitcher: [],
    // listColor: ['rgb(255, 205, 86)', 'rgb(54, 162, 235)', 'rgb(255, 99, 132)', 'rgb(255, 140, 132)', 'rgb(255, 10, 132)', 'rgb(255, 55, 132)', 'rgb(255, 55, 255)', 'rgb(255, 55, 10)', 'rgb(255, 105, 20)'],
    // listColorWithId: [],
};


function reducer(state: any, action: any) {
    switch (action.type) {
        case 'selectedAcquirer':
            return {
                ...state,
                selectedAcquirer: action.value
            };
        case 'selectedIssuer':
            return {
                ...state,
                selectedIssuer: action.value
            };
        case 'selectedIssuerSwitcher':
            return {
                ...state,
                selectedIssuerSwitcher: action.value
            };
        case 'selectedAcquirerSwitcher':
            return {
                ...state,
                selectedAcquirerSwitcher: action.value
            };

        case 'countedAllTransction':
            return {
                ...state,
                countedAllTransction: action.value
            };
        case 'issuerId':
            return {
                ...state,
                issuerId: action.value
            };
        case 'acquirerId':
            return {
                ...state,
                acquirerId: action.value
            };
        case 'issuerSwitcherId':
            return {
                ...state,
                issuerSwitcherId: action.value
            };
        case 'acquirerSwitcherId':
            return {
                ...state,
                acquirerSwitcherId: action.value
            };
        case 'transDivided':
            return {
                ...state,
                transDivided: action.value
            };

        case 'issuerIdCode':
            return {
                ...state,
                issuerIdCode: action.value
            };
        case 'acquirerIdCode':
            return {
                ...state,
                acquirerIdCode: action.value
            };
        case 'issuerSwitcherIdCode':
            return {
                ...state,
                issuerSwitcherIdCode: action.value
            };
        case 'acquirerSwitcherIdCode':
            return {
                ...state,
                acquirerSwitcherIdCode: action.value
            };
        default:
            throw new Error();
    }
}
import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAZaz09SpecialChar, CheckAllFields, HasWhiteSpace } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { urlSelection } from '../../Services/API';


export const ModalDraggableCodeDetail = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
  const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
  const { optionsCommonStatusDetailCode, optionsCCD, optionsGroupCode } = props.stateOptions

  const { setTriggerReload } = props

  const draggleRef2 = React.createRef<any>();

  useEffect(() => {
    dispatchStatePage({ 'type': 'visible', value: showModal })
    dispatchStatePage({ 'type': 'setData', value: itemModal })
  }, [statePage.visible !== showModal])

  useEffect(() => {
    dispatchStatePage({ type: 'id', value: id })
  }, [id !== statePage.id])

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef2?.current?.getBoundingClientRect();
    dispatchStatePage({
      'type': 'bounds', value: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y)
      }
    })
  };

  const handleState = (nameState: string | any, valueState: any) => {
    let formatCheckStringHaveSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?~]/;
    let formatCheckIsNum = /^\d+$/;
    if (nameState === 'ccdName') {
      if (formatCheckStringHaveSpecialCharacter.test(valueState.toString())) { }
      else if (HasWhiteSpace(valueState.toString())) { }
      else if (valueState.split('').length < 33) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else if (nameState === 'ccdSortNumber') {
      if (valueState === '') { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
      else if (formatCheckIsNum.test(valueState.toString()) === false) { }
      else if (valueState.split('').length < 4) { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
    } else if (nameState === 'ccdCode') {
      if (valueState === '') { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
      else if (formatCheckIsNum.test(valueState.toString()) === false) { }
      else if (valueState.split('').length < 4) { dispatchStatePage({ type: nameState, value: valueState.toUpperCase() }) }
    } else if (nameState === 'ccdExt1Val') {
      if (CheckAZaz09SpecialChar.test(valueState.toString())) { 
        dispatchStatePage({ type: nameState, value: valueState })
      }
      else if (HasWhiteSpace(valueState.toString())) { }
      else if (valueState.split('').length < 33) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else if (nameState === 'ccdExt2Val') {
      if (CheckAZaz09SpecialChar.test(valueState.toString())) {
        dispatchStatePage({ type: nameState, value: valueState })
       }
      else if (HasWhiteSpace(valueState.toString())) { }
      else if (valueState.split('').length < 33) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else if (nameState === 'ccdDesc') {
      if (valueState.split('').length < 121) { dispatchStatePage({ type: nameState, value: valueState }) }
    } else { dispatchStatePage({ type: nameState, value: valueState }) }
  }
  const updateDataAPI = () => {
    const { ccgCode, ccdCode, ccdName, ccdSortNumber, ccdDesc, ccdStatus, ccdExt1Val, ccdExt2Val, errorMessage } = statePage;
    const data = {
      ccdId: id,
      ccgCode: ccgCode,
      ccdCode: ccdCode,
      ccdName: ccdName,
      ccdSortNumber: ccdSortNumber,
      ccdDesc: ccdDesc,
      ccdStatus: ccdStatus,
      ccdExt1Val: ccdExt1Val,
      ccdExt2Val: ccdExt2Val,
    };
    const a = CheckAllFields([ccgCode, ccdCode, ccdName, ccdSortNumber, ccdStatus], ['Group Code', 'Detail Code', 'Code Name', 'Sort No', 'Use Status']);
    if (a !== "" || errorMessage !== '') {
      handleState('errorMessage', a);
    } else {
      handleState('errorMessage', ``);
      if (itemModal.ccdCode !== ccdCode && itemModal.ccgCode !== ccgCode) {
        let conditionCodeAlreadyAvailable: any[] = optionsCCD.filter((element1: any) => element1.ccdCode === ccdCode && element1.ccgCode === ccgCode);
        if (conditionCodeAlreadyAvailable.length === 0) {
          API.update({
            bodyCustom: data,
            pathCustom: `${urlApi}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
          }).then((response) => {
            actionAfterUpdateItem(id)
            actionShowModal()
          }).catch((err) => handleState('errorMessage', err))
        } else {
          handleState('errorMessage', 'Combination of Group Code and Detail Code already exist');
        }
      } else {
        API.update({
          bodyCustom: data,
          pathCustom: `${urlApi}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,

        }).then((response) => {
          actionAfterUpdateItem(id)
          actionShowModal()
          setTriggerReload(true)
        }).catch((err) => handleState('errorMessage', err))
      }

    }
  };

  return (
    <Modal
      mask={false}
      maskClosable={false}
      keyboard={false}
      wrapClassName="aaa"
      width={800}
      style={{
        position: 'fixed',
        left: (document.body.clientWidth - 500) / 2,
        height: '0px',
        width: '0px',
        zIndex: index,
      }}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move'
          }}
          onMouseOver={() => {
            if (statePage.disabled) {
              handleState('disabled', false)
            }
          }}
          onMouseOut={() => {
            handleState('disabled', true)
          }}
        >
          <div className="flex justify-between items-end">
            <div className="flex">
              <div className="flex flex-col justify-between ml-4">
                <div>{statePage.titleModal}</div>
              </div>
            </div>
          </div>
        </div>
      }

      footer={null}
      open={statePage.visible}
      onOk={() => actionShowModal()}
      onCancel={() => actionShowModal()}
      modalRender={(modal) => (
        <Draggable
          disabled={statePage.disabled}
          bounds={statePage.bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef2} onClick={() => actionIndexModal()}>
            {modal}
          </div>
        </Draggable>
      )}
    >

      <div className='d-flex flex-column min-h-60px'>
        <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
          <div className='col-4'>
            <p className={labelStyle}>GROUP CODE <span className='text-danger'>*</span></p>
            <Select 
            styles={inputStyleH25}
            placeholder={'(Search)'} 
            options={optionsGroupCode} 
            value={optionsGroupCode.filter((option: any) => option.value === statePage.ccgCode)} 
            onChange={(e) => handleState('ccgCode', e.value)}
             />
          </div>
          <div className='col-4'>
            <p className={labelStyle}>DETAIL CODE <span className='text-danger'>*</span></p>
            <input value={statePage.ccdCode} onChange={(e) => handleState('ccdCode', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-4'>
            <p className={labelStyle}>CODE NAME <span className='text-danger'>*</span></p>
            <input value={statePage.ccdName} onChange={(e) => handleState('ccdName', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-4'>
            <p className={labelStyle}>SORT NO <span className='text-danger'>*</span></p>
            <input value={statePage.ccdSortNumber} onChange={(e) => handleState('ccdSortNumber', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-4'>
            <p className={labelStyle}>USE STATUS <span className='text-danger'>*</span></p>
            <Select 
            styles={inputStyleH25}
            placeholder={'(Search)'} 
            options={optionsCommonStatusDetailCode} 
            value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.ccdStatus)} 
            onChange={(e) => handleState('ccdStatus', e.value)}
             />
          </div>

          <div className='col-4'>
            <p className={labelStyle}>Ext 1</p>
            <input value={statePage.ccdExt1Val} onChange={(e) => handleState('ccdExt1Val', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-4'>
            <p className={labelStyle}>Ext 2</p>
            <input value={statePage.ccdExt2Val} onChange={(e) => handleState('ccdExt2Val', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-12'>
            <p className={labelStyle}>DESCRIPTION</p>
            <textarea rows={4} value={statePage.ccdDesc} onChange={(e) => handleState('ccdDesc', e.target.value)} className={inputDescriptionStyle} />
          </div>
        </div>
        <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
      </div>
    </Modal>
  );

}

const initialState = {
  titleModal: 'Edit Detail Code',
  visible: false,
  disabled: true,
  bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  errorMessage: '',
  errorMessageList: [],
  //
  ccdId: '',
  ccgCode: '',
  ccdCode: '',
  ccdName: '',
  ccdSortNumber: '',
  ccdDesc: '',
  ccdStatus: '001',
  ccdExt1Val: '',
  ccdExt2Val: '',
  //
  id: ''
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'titleModal':
      return {
        ...state,
        titleModal: action.value,
      };
    case 'visible':
      return {
        ...state,
        visible: action.value,
      };
    case 'disabled':
      return {
        ...state,
        disabled: action.value,
      };
    case 'bounds':
      return {
        ...state,
        bounds: action.value,
      };
    case 'errorMessage':
      return {
        ...state,
        errorMessage: action.value,
      };
    case 'errorMessageList':
      return {
        ...state,
        errorMessageList: action.value,
      };

    case 'id':
      return {
        ...state,
        id: action.value,
      };
    // 
    case 'clearData':
      return {
        ...state,
        ccdId: '',
        ccgCode: '',
        ccdCode: '',
        ccdName: '',
        ccdSortNumber: '',
        ccdDesc: '',
        ccdStatus: '001',
        ccdExt1Val: '',
        ccdExt2Val: '',
        errorMessage: "",

      };
    case 'setData':
      return {
        ...state,
        ccdId: action.value.ccdId,
        ccgCode: action.value.ccgCode,
        ccdCode: action.value.ccdCode,
        ccdName: action.value.ccdName,
        ccdSortNumber: action.value.ccdSortNumber,
        ccdDesc: action.value.ccdDesc,
        ccdStatus: action.value.ccdStatus,
        ccdExt1Val: action.value.ccdExt1Val,
        ccdExt2Val: action.value.ccdExt2Val,
        errorMessage: "",
      };
    // 
    case 'ccdId':
      return {
        ...state,
        ccdId: action.value,
      };
    case 'ccgCode':
      return {
        ...state,
        ccgCode: action.value,
      };
    case 'ccdCode':
      return {
        ...state,
        ccdCode: action.value,
      };
    case 'ccdName':
      return {
        ...state,
        ccdName: action.value,
      };
    case 'ccdSortNumber':
      return {
        ...state,
        ccdSortNumber: action.value,
      };
    case 'ccdDesc':
      return {
        ...state,
        ccdDesc: action.value,
      };
    case 'ccdStatus':
      return {
        ...state,
        ccdStatus: action.value,
      };
    case 'ccdExt1Val':
      return {
        ...state,
        ccdExt1Val: action.value,
      };
    case 'ccdExt2Val':
      return {
        ...state,
        ccdExt2Val: action.value,
      };
    default:
      throw new Error();
  }
}





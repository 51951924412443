import { PageMainDashboardTitle } from "../../../State/Menu/TitleMenu";

export const initialState = {
    // constant
    titlePage: PageMainDashboardTitle,
    userBCAPId: '410000',
    privilegeAccess: { view: '1001', add: '', edit: '', delete: '', download: '' },
    lineChartColors: [
        '#FF5D5D',
        '#1363DF',
        '#35C138',
        '#EA971C',
        '#39D5E0',
        '#C134E8',
        '#E04985',
        '#352777',
        '#EBE427',
        '#1F6F2B'
    ],

    // privilege
    id: '',
    privilegeId: '',
    listRoleType: [],
    isRoleBCAP: false,
    isRoleAcquirer: false,
    isRoleIssuer: false,
    isRoleAcquirerSwitcher: false,
    isRoleIssuerSwitcher: false,

    // data
    data: [],
    dataModal: [],
    totalData: 0,

    // pagination
    search: '',
    view: 15,       // page size
    position: 0,    // page index
    list_view: [15, 25, 50],
    totalPages: 0,
    currentPage: 0,

    // filter
    filterStartDate: new Date().toISOString().slice(0, 10),
    filterEndDate: new Date().toISOString().slice(0, 10),
    graphInboundDays: 7,
    graphOutboundDays: 7,
    graphAPIDays: 0,
    graphTimeoutDays: 0,
    graphInboundStatus: 'APPROVED',
    graphOutboundStatus: 'APPROVED',
    selectedCountryId: '410',
    selectedCountryLabel: 'Korea',
    selectedAcquirerSwitcherId: '',
    selectedAcquirerSwitcherLabel: '',
    selectedIssuerSwitcherId: '',
    selectedIssuerSwitcherLabel: '',
    filterErrorMessage: '',

    // others
    isRowHovered: -1
};
import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { API } from "../../../Services";
import { initFunction, reducerOptions, reducerStateSelected, stateOptions, stateSelected } from "../../../State";
import { inputStyleReadOnly, labelStyle, labelStyleW200PX, labelStyleW200PXWithoutUppercase } from "../../../Styles";
import { CheckUndefined, NumberWithCommas } from "../../../Utils";
import { InterfaceOptionsDetailsMonitoringStatus } from "../InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { ModelDetailTransactionStatus } from "../Models/ModelDetailTransactionStatus";
import { Page2FrameworkRoute, Page4DashboardRoute } from "../../../Routes";
import { Divider } from "antd";
import { resetAllAction } from "../../../Store/TransactionJourney";
import { urlSelection } from "../../../Services/API";

export const DetailsReversalMonitoringStatus = ({...props}) => {
    const { id } = useParams();
    const dispatchGlobal = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        API.get({
            bodyCustom: null,
            pathCustom: `dashboard/transaction-detail?transId=${id}`,
            // pathCustom: `dashboard/${id}/reversal-transaction`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => handleState('data', response.data))
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
    }, [])



    const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
    const data = state.allData
    let merchant_post_code = ""
    let member_appr_code = ""

    const convertLabelCountry = (countryCode: string): string => {
        let labelCountry = '';
        if (stateOptions.optionsCountryCodeAndCurrencyCode.length > 0) {
            stateOptions.optionsCountryCodeAndCurrencyCode.forEach((element: any) => {
                if (element.value === countryCode) labelCountry = element.value;
            })
        }
        return labelCountry;
    }
    if (state.data === null) return <></>

    // if (CheckUndefined(data.merchantPosCode)=="-"){
    //     merchant_post_code = CheckUndefined(data.merchantPos)
    // }else if (CheckUndefined(data.merchantPos)=="-"){
    //     merchant_post_code = CheckUndefined(data.merchantPosCode)
    // }else{
    //     merchant_post_code = `${CheckUndefined(data.merchantPosCode)} - ${(CheckUndefined(data.merchantPos))}`
    // }

    if (CheckUndefined(data.isApprCodeIntn) != "-") {
        if (data.isApprCodeIntn) member_appr_code = " (Generated by BCAP)"
    }

    return (
        <div className="_new">
            <NavLink to={Page4DashboardRoute}>
                <h1 className="Lato-600 c-29 s-2024 w-600px my-3">〱 Transaction Details</h1>
            </NavLink>
            <Divider />
            <div className="row bd-d4 br-10 bg-ff gx-4 gy-4 py-3 ">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Transaction Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>TRANSACTION ID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.transId)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Transaction Type</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.transType)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Transaction Date Time</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.transactionDateTime)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Global ID</p>
                    <NavLink
                        to={`${Page2FrameworkRoute}?globalId=${data.globalId}`}
                        className={`${labelStyleW200PXWithoutUppercase} fw-bold text-info pointer`}
                        onClick={(e) => {
                            dispatchGlobal(resetAllAction(data.globalId))
                        }}
                    >
                        {CheckUndefined(data.globalId)}
                    </NavLink>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Product Type</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.productTypeName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Product Feature</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.productFeatureName)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Approval Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.apprCode)}{member_appr_code}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Retrieval Reference Number</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.rrn)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Invoice Number</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.invNo)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>STAN</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{` ${(CheckUndefined(data.stan))}`}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>MTI</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.mti)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Processing Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{`${CheckUndefined(data.procCode)}`}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Conversion Rate</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.convRate)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Conversion Date</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.convDate)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Local Date Time</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.localDateTime)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Response Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.rspnCode)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Internal Response Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.intnRspnCode)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Destination Response Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstRspnCode)}</p>
                </div>

                {/* SOURCE */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Source Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Source Institute</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstitute)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>{data.srcLabel1}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteName1)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>{data.srcLabel2}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteName2)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Source IP</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcIp)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.srcLabel1} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteAmount1)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.srcLabel2} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteAmount2)}</p>
                </div>
                {/* DESTINATION */}

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Destination Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Destination Intitute</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstitute)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>{data.dstLabel1}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteName1)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>{data.dstLabel2}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteName2)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Destination RRN</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstRrn)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.dstLabel1} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteAmount1)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.dstLabel2} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteAmount2)}</p>
                </div>


                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Merchant Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>MID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.mid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Sub MID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.subMid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Member MID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.memberMid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Merchant Name</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.merchantName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Sub Merchant Name</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.subMerchantName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Merchant Country</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.merchantCountry)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>TID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.tid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Merchant Post Entry Mode / Channel</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{merchant_post_code} </p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>MCC</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.mcc)}</p>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Wallet Info</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Customer Pan</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.custPan)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Customer Token</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.custToken)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Customer Username</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.custUsername)}</p>
                </div>
                {
                    (CheckUndefined(data.oriTransId) === '-') ?
                        <></>
                        :
                        <>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                                <h1 className="Lato-600 c-29 s-2024 w-600px ">Origin Transaction Details (Cancellation Only)</h1>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                                <p className={labelStyleW200PX}>TRANSACTION ID</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriTransId)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                                <p className={labelStyleW200PX}>Transaction Type</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriTransType)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                <p className={labelStyleW200PX}>Transaction Date Time</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriTransactionDateTime)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                <p className={labelStyleW200PX}>STAN</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriStan)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                                <p className={labelStyleW200PX}>Retrieval Reference Number</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriRrn)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                                <p className={labelStyleW200PX}>Invoice Number</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriInvNo)}</p>
                            </div>
                        </>

                }
            </div>
        </div>
    )
}


interface test {
    data: ModelDetailTransactionStatus | null
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: test = {
    data: null
};
function reducer(state: any, action: any) {
    switch (action.type) {
        case 'data':
            return {
                ...state,
                allData: action.value,
                data: {
                    authId: action.value.authId,
                    transAuthId: action.value.transAuthId,
                    productName: action.value.productName,
                    productFeatureName: action.value.productFeatureName,
                    intnRspnCode: action.value.intnRspnCode,
                    memberRspnCode: action.value.memberRspnCode,
                    responseCode: action.value.responseCode,
                    authRrn: action.value.authRrn,
                    authStan: action.value.authStan,
                    invoiceNumber: action.value.invoiceNumber,
                    approvalCode: action.value.approvalCode,
                    localDate: action.value.localDate,
                    localTime: action.value.localTime,
                    conversionDate: action.value.conversionDate,
                    fullCreateDate: action.value.fullCreateDate,
                    settlementConvRate: action.value.settlementConvRate,
                    accHolderConvRate: action.value.accHolderConvRate,
                    transactionAmount: action.value.transactionAmount,
                    settlementAmount: action.value.settlementAmount,
                    accHolderAmount: action.value.accHolderAmount,
                    settleCurrCode: action.value.settleCurrCode,
                    accHolderCurrCode: action.value.accHolderCurrCode,
                    currencyCode: action.value.currencyCode,
                    issuerId: action.value.issuerId,
                    acquirerId: action.value.acquirerId,
                    issuerSwitcherId: action.value.issuerSwitcherId,
                    acquirerSwitcherId: action.value.acquirerSwitcherId,
                    issuerName: action.value.issuerName,
                    acquirerName: action.value.acquirerName,
                    issuerSwitcherName: action.value.issuerSwitcherName,
                    acquirerSwitcherName: action.value.acquirerSwitcherName,
                    issuerCountry: action.value.issuerCountry,
                    acquirerCountry: action.value.acquirerCountry,
                    issuerSwitcherCountry: action.value.issuerSwitcherCountry,
                    acquirerSwitcherCountry: action.value.acquirerSwitcherCountry,
                    authOriInvNo: action.value.authOriInvNo,
                    authOriRrn: action.value.authOriRrn,
                    safAuthTransId: action.value.safAuthTransId,
                    isApprCodeIntn: action.value.isApprCodeIntn,

                }
            };
        default:
            throw new Error();
    }
}
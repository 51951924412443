import { CheckUndefined, ExportCSV } from "../../Utils";


export const ExcelSchedulerExecution = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        const m = {
            no: i + 1,
            userId: CheckUndefined(`${item.userId} - ${item.username}`),
            screenName: CheckUndefined(item.screenName),
            logFileName: CheckUndefined(item.logFileName),
            crudType: CheckUndefined(item.crudType),
            before: CheckUndefined(item.before),
            after: CheckUndefined(item.after),
            logCreateDt: CheckUndefined(item.logCreateDt),
            logSourceIp: CheckUndefined(item.logSourceIp),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};
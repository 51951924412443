

import { createSlice } from "@reduxjs/toolkit";
import { ShowModalInterface } from "../Interface";

interface interfaceInitialState {
    urlApi: string;
    //
    view: number,
    position: number,
    totalData: number,
    totalPages: number,
    currentPage: number,
    //
    search: string,
    //
    id: number | string,
    dataModal: any,
    visibleModalAddDelete: boolean,
    deleteItem: boolean,
    //
    savedShowModal: any[]
}

const initialState = (): interfaceInitialState | any => {
    return {
        urlApi: 'member',
        //
        view: 10,
        position: 10,
        totalData: 0,
        totalPages: 0,
        currentPage: 1,
        //
        search: '',

        id: '',
        dataModal: null,
        visibleModalAddDelete: false,
        deleteItem: false,
        //
        SearchByPlaceHolder: 'Member Code, Member Name',
        //
        savedShowModal: []
    }
}

export const memberProfileSliceSlice = createSlice({
    name: "memberProfileSlice",
    initialState: initialState(),
    reducers: {
        handleStateActionMemberProfile: (state, action) => {
            for (const key in state as interfaceInitialState) {
                if (key === action.payload.name) {
                    let nameState: string = action.payload.name
                    state[nameState] = action.payload.value
                }
            }
        },
        handleAddDataMemberProfile: (state, action) => {
            state.id = ''
            state.dataModal = null
            state.visibleModalAddDelete = !(state.visibleModalAddDelete)
        },

        onSizeChangeMemberProfile: (state, action) => {
            state.currentPage = 1;
            state.view = action.payload;
            state.position = action.payload;

        },
        showModalMemberProfile: (state, action) => {
            let showDataResult: ShowModalInterface[] = [];
            let dataWhereChangeToIdGeneral: any[] = [];
            let newSavedShowModal: ShowModalInterface[] = [];
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.payload.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.payload.value.idModal, showModal: !(action.payload.value.showModal), itemModal: action.payload.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.payload.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.payload.value.idModal, showModal: !(element1.showModal), itemModal: action.payload.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.payload.value.idModal, showModal: !(element1.showModal), itemModal: action.payload.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.payload.value.idModal, showModal: !(action.payload.value.showModal), itemModal: action.payload.value.itemModal, index: 99 })
                    }
                }
            }
            state.savedShowModal = newSavedShowModal;
            state.dataLength = state.dataLength + 1;
        },

        changeIndexMemberProfile: (state, action) => {
            let newSavedShowModal: ShowModalInterface[] = [];
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.payload.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }

            state.savedShowModal = newSavedShowModal
            state.dataLength = state.dataLength + 1
        },
    }
});

export const { handleStateActionMemberProfile, handleAddDataMemberProfile, onSizeChangeMemberProfile, showModalMemberProfile, changeIndexMemberProfile } = memberProfileSliceSlice.actions;
export const memberProfileSliceGlobalState = (state: any) => state.memberProfileSlice;
export default memberProfileSliceSlice.reducer;
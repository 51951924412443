//Authorization
const AuthorizationRoute = '/dashboard/';
const Page1DashboardRoute = `${AuthorizationRoute}transaction-statistic`;
const Page2DashboardRoute = `${AuthorizationRoute}transaction-inquiry`;
const Page3DashboardRoute = `${AuthorizationRoute}saf-statistic`;
const Page4DashboardRoute = `${AuthorizationRoute}saf-transaction-inquiry`;
const Page5DashboardRoute = `${AuthorizationRoute}qr-auth-inquiry`;
const PageDashboardQRAuthStatisticRoute = `${AuthorizationRoute}qr-auth-statistic`;
const PageMainDashboardRoute = `${AuthorizationRoute}transaction-statistic-new`

//Settlement
const SettlementRoute = `/settlement-summary/`;
const Page1SettlementRoute = `${SettlementRoute}settlement-summary-acquirer`;
const Page2SettlementRoute = `${SettlementRoute}settlement-summary-issuer`;
const Page3SettlementRoute = `${SettlementRoute}sales-inquiry`;
const Page4SettlementRoute = `${SettlementRoute}dispute-resolution`;
const Page5SettlementRoute = `${SettlementRoute}member-payment`;
const Page6SettlementRoute = `${SettlementRoute}settlement-Summary`;
const Page7SettlementRoute = `${SettlementRoute}unmatched-List`;
const Page8SettlementRoute = `${SettlementRoute}adjustment-List`;


//Membe
const MemberRoute = `/member/`;
const Page1MemberRoute = `${MemberRoute}member-hmac`;
const Page2MemberRoute = `${MemberRoute}member-profile`;

//User
const UserRoute = `/user/`;
const Page1UserRoute = `${UserRoute}user-profile`;
const Page2UserRoute = `${UserRoute}user-audit-trail`;
const Page3UserRoute = `${UserRoute}privilege`;

//Product
const ProductRoute = '/product/';
const Page1ProductRoute = `${ProductRoute}product-definition`;
const Page2ProductRoute = `${ProductRoute}product-&-feature`;
const Page3ProductRoute = `${ProductRoute}product-feature-&-member`;
//Rate
const RateRoute = '/rate/';
const Page1RateRoute = `${RateRoute}exchange-rate-setting`;
const Page2RateRoute = `${RateRoute}exchange-rate-member`;
//Fee
const FeeRoute = '/fee/';
const Page1FeeRoute = `${FeeRoute}fee-rate-setting`;

//Framework
const FrameworkRoute = '/framework/';
const Page1FrameworkRoute = `${FrameworkRoute}saf-control`;
const Page2FrameworkRoute = `${FrameworkRoute}transaction-journey`;
const Page3FrameworkRoute = `${FrameworkRoute}service-profile`;
const Page4FrameworkRoute = `${FrameworkRoute}batch-program-profile`;

//QR
const QRRoute = '/qr/';
const Page1QRRoute = `${QRRoute}qr-standard`;
const Page2QRRoute = `${QRRoute}qr-standard-&-qr-tlv`;
const Page3QRRoute = `${QRRoute}qr-standard-&-member`;
const Page4QRRoute = `${QRRoute}qr-whitelist-and-blacklist`;
const Page5QRRoute = `${QRRoute}qr-generator`;
const Page6QRRoute = `${QRRoute}qr-tlv`;
//InterfaceAPI
const InterfaceAPIRoute = '/interface-api/';
const Page1InterfaceAPIRoute = `${InterfaceAPIRoute}body-field`;
const Page2InterfaceAPIRoute = `${InterfaceAPIRoute}header-field`;
const Page3InterfaceAPIRoute = `${InterfaceAPIRoute}api-&-body-field`;
const Page4InterfaceAPIRoute = `${InterfaceAPIRoute}api-&-header-field`;
const Page5InterfaceAPIRoute = `${InterfaceAPIRoute}api-routing-table`;
const Page6InterfaceAPIRoute = `${InterfaceAPIRoute}route-sequence`;
//InterfaceFile
const InterfaceFileRoutes = `/interface-file/`;
const Page1InterfaceFileRoutes = `${InterfaceFileRoutes}file`;
const Page2InterfaceFileRoutes = `${InterfaceFileRoutes}file-element`;
const Page3InterfaceFileRoutes = `${InterfaceFileRoutes}file-&-file-record`;
const Page4InterfaceFileRoutes = `${InterfaceFileRoutes}file-record`;
const Page5InterfaceFileRoutes = `${InterfaceFileRoutes}file-record-&-file-element`;
const Page6InterfaceFileRoutes = `${InterfaceFileRoutes}file-transfer-monitoring`;

//JobScheduler
const JobSchedulerRoute = '/job-scheduler/';
const Page1JobSchedulerRoute = `${JobSchedulerRoute}batch-program`;
const Page2JobSchedulerRoute = `${JobSchedulerRoute}job-Schedule`;
const Page3JobSchedulerRoute = `${JobSchedulerRoute}job-run`;
const Page4JobSchedulerRoute = `${JobSchedulerRoute}job-execution-history`;
const Page5JobSchedulerRoute = `${JobSchedulerRoute}batch-scheduler`;

//Code
const CodeRoute = '/code/';
const CodeManagementRoute = `/code-management/`;
const Page1CodeRoute = `${CodeRoute}common-code`;
const Page2CodeRoute = `${CodeRoute}reason-code`;
const Page3CodeRoute = `${CodeRoute}reason-code-&-reason-code`;
const Page4CodeRoute = `${CodeRoute}country-code`;

//System/Network
const SystemNetworkRoute = '/system-network/';
const Page1SystemNetworkRoute = `${SystemNetworkRoute}system-usage`;
const Page2SystemNetworkRoute = `${SystemNetworkRoute}member-connection-status`;
const Page3SystemNetworkRoute = `${SystemNetworkRoute}archive-management`;

// Gapih Production Simulator
const ProductionSimRoute = '/prod-simulator/';
const Page1ProductionSimRoute = `${ProductionSimRoute}prod-simulator-inquiry`;
const Page2ProductionSimRoute = `${ProductionSimRoute}qr-auth-simulator-inquiry`;

const AdminRoute = `/admin/`;
const Page1AdminRoute = `${AdminRoute}saf-control-management`;
const InterfaceRoute = `/interface/`;
const QRManagementRoute = `/qr-management/`;
const FeeManagementRoute = `/fee-management/`;
const LogRoute = `/log/`;
const JobRoute = `/job/`;
const ServicesRoute = `/services/`;
const Page3ServicesRoute = `${SettlementRoute}batch-scheduler`;

//Gapih Alert History
const alertRoute=`/alert`;
const PageAlertHistory=`${alertRoute}/alert-history`;
const PageAlertParameter=`${alertRoute}/alert-trans-params`;

// const Page6FrameworkRoute = `${FrameworkRoute}alert-trans-params`;


export {
    AdminRoute,
    CodeManagementRoute,
    FeeManagementRoute,
    InterfaceRoute,
    JobRoute,
    LogRoute,
    Page1AdminRoute,
    Page1CodeRoute,
    Page1DashboardRoute,
    Page1FeeRoute,
    Page1FrameworkRoute,
    Page1InterfaceAPIRoute,
    Page1InterfaceFileRoutes,
    Page1JobSchedulerRoute,
    Page1MemberRoute,
    Page1ProductRoute,
    Page1QRRoute,
    Page1RateRoute,
    Page1SettlementRoute,
    Page1SystemNetworkRoute,
    Page1UserRoute,
    Page2CodeRoute,
    Page2DashboardRoute,
    Page2FrameworkRoute,
    Page2InterfaceAPIRoute,
    Page2InterfaceFileRoutes,
    Page2JobSchedulerRoute,
    Page2MemberRoute,
    Page2ProductRoute,
    Page2QRRoute,
    Page2RateRoute,
    Page2SettlementRoute,
    Page2SystemNetworkRoute,
    Page2UserRoute,
    Page3CodeRoute,
    Page3DashboardRoute,
    Page3FrameworkRoute,
    Page3InterfaceAPIRoute,
    Page3InterfaceFileRoutes,
    Page3JobSchedulerRoute,
    Page3ProductRoute,
    Page3QRRoute,
    Page3ServicesRoute,
    Page3SettlementRoute,
    Page3SystemNetworkRoute,
    Page3UserRoute,
    Page4CodeRoute,
    Page4DashboardRoute,
    Page4FrameworkRoute,
    Page4InterfaceAPIRoute,
    Page4InterfaceFileRoutes,
    Page4JobSchedulerRoute,
    Page4QRRoute,
    Page4SettlementRoute, Page5JobSchedulerRoute, Page5InterfaceAPIRoute,
    Page5InterfaceFileRoutes,
    Page5QRRoute,
    Page5SettlementRoute,
    Page6InterfaceAPIRoute,
    Page6QRRoute,
    ProductRoute,
    QRManagementRoute,
    ServicesRoute,
    SettlementRoute,
    Page6SettlementRoute,
    Page7SettlementRoute,
    Page8SettlementRoute,
    Page6InterfaceFileRoutes,
    ProductionSimRoute,
    Page1ProductionSimRoute,
    Page5DashboardRoute,
    Page2ProductionSimRoute,
    PageAlertParameter,
    // Page6FrameworkRoute,
    PageDashboardQRAuthStatisticRoute,
    PageMainDashboardRoute,
    PageAlertHistory
};


import { interfaceIcon } from "../../../Assets/Images/MenuIcon";
import { Page1InterfaceFileRoutes, Page2InterfaceFileRoutes, Page3InterfaceFileRoutes, Page4InterfaceFileRoutes, Page5InterfaceFileRoutes, Page6InterfaceFileRoutes } from "../../../Routes";
import { Page35Code, Page36Code, Page37Code, Page38Code, Page39Code, PageFileTransferMonitoringCode } from "../CodeMenu";
import { Page35Title, Page36Title, Page37Title, Page38Title, Page39Title, PageFileTransferMonitoringTitle } from "../TitleMenu";

export const Menu11 = {
    id: 'interface_file_sidebar',
    label: 'Interface File',
    icon: interfaceIcon,
    privilegesId: [
        Page35Code,
        Page36Code,
        Page37Code,
        Page38Code,
        Page39Code,
        PageFileTransferMonitoringCode
    ],
    itemSidebar: [
        {
            titleList: Page35Title,
            key: 'interfaceFileOptions1',
            privilegeId: Page35Code,
            url: Page1InterfaceFileRoutes,
        },
        {
            titleList: Page36Title,
            key: 'interfaceFileOptions2',
            privilegeId: Page36Code,
            url: Page2InterfaceFileRoutes,
        },

        {
            titleList: Page37Title,
            key: 'interfaceFileOptions3',
            privilegeId: Page37Code,
            url: Page3InterfaceFileRoutes,
        },
        {
            titleList: Page38Title,
            key: 'interfaceFileOptions4',
            privilegeId: Page38Code,
            url: Page4InterfaceFileRoutes,
        },
        {
            titleList: Page39Title,
            key: 'interfaceFileOptions5',
            privilegeId: Page39Code,
            url: Page5InterfaceFileRoutes,
        },
        {
            titleList: PageFileTransferMonitoringTitle,
            key: 'interfaceFileOptions6',
            privilegeId: PageFileTransferMonitoringCode,
            url: Page6InterfaceFileRoutes
        },
    ],
}
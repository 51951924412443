
// LogLevelManagement

interface LogLevelManagementInterface {
    id: null | number | string;


    logLevelId: number | null;
    serviceName: string | null;
    logLevelName: string | null;
    logLevelCode: string | null;
    logLevelStatus: string | null;
    logLevelStatusCode: string | null;
    logLevelDate: string | null;
    logLevelTime: string | null;
    programId: number | null;
    programName: string | null;
    programClassCode: string | null;
    programClassName: string | null;
}

export class ModelLogLevelManagement {
    id: null | number | string;
    logLevelId: number | null;
    serviceName: string | null;
    logLevelName: string | null;
    logLevelCode: string | null;
    logLevelStatus: string | null;
    logLevelStatusCode: string | null;
    logLevelDate: string | null;
    logLevelTime: string | null;
    programId: number | null;
    programName: string | null;
    programClassCode: string | null;
    programClassName: string | null;
    constructor(value: LogLevelManagementInterface | null) {
        this.id = value?.id ?? null;
        this.logLevelId = value?.logLevelId ?? null;
        this.serviceName = value?.serviceName ?? null;
        this.logLevelName = value?.logLevelName ?? null;
        this.logLevelCode = value?.logLevelCode ?? null;
        this.logLevelStatus = value?.logLevelStatus ?? null;
        this.logLevelStatusCode = value?.logLevelStatusCode ?? null;
        this.logLevelDate = value?.logLevelDate ?? null;
        this.logLevelTime = value?.logLevelTime ?? null;
        this.programId = value?.programId ?? null;
        this.programName = value?.programName ?? null;
        this.programClassCode = value?.programClassCode ?? null;
        this.programClassName = value?.programClassName ?? null;
    }
}

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-modal';
import { ModelInterfaceRecordItem } from './ModelInterfaceRecordItem';
import { Page39Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalInterfaceRecordItem = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props

    const {
        optionsInterfaceRecord,
        optionsInterfaceFileItem,
        optionsFileStringSeparatorDetailCode,
        optionsMOC,
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])


    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["recordItemDefaultValue"]
        let limit = [64]
        let index = names.indexOf(nameState)

        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'recordItemNameLength' || nameState === 'recordItemSortNumber') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 10000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
                needLoading: true,
            }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            recordItemId,
            interfaceFileItemId,
            interfaceFileItemName,
            interfaceRecordId,
            interfaceRecordName,
            recordItemSortNumber,
            recordItemSeparator,
            recordItemNameYn,
            recordItemNameLength,
            recordItemMoc,
            recordItemDefaultValue,
            recordItemCreateBy,
            recordItemCreateDate,
            recordItemUpdateBy,
            recordItemUpdateDate,
            recordItemDeleteBy,
            recordItemDeleteDate,
            recordItemIsDelete,

        } = statePage.data
        const data = {
            interfaceRecordId: interfaceRecordId,
            interfaceFileItemId: interfaceFileItemId,
            recordItemSortNumber: recordItemSortNumber,
            recordItemSeparator: recordItemSeparator,
            recordItemNameYn: recordItemNameYn,
            recordItemNameLength: recordItemNameLength,
            recordItemMoc: recordItemMoc,
            recordItemDefaultValue: recordItemDefaultValue,
        }
        let a = '';
        if (recordItemNameYn === false) {
            a = CheckAllFields(
                [
                    interfaceRecordId,
                    interfaceFileItemId,
                    recordItemSortNumber,
                    recordItemSeparator,
                    recordItemNameYn,
                    recordItemMoc,
                ],
                [
                    "INTERFACE RECORD ID",
                    "INTERFACE FILE ITEM ID",
                    "SORT NUMBER",
                    "ITEM SEPARATOR",
                    "ITEM NAME",
                    "M/O/C"
                ]
            )
        } else {
            a = CheckAllFields(
                [
                    interfaceRecordId,
                    interfaceFileItemId,
                    recordItemSortNumber,
                    recordItemSeparator,
                    recordItemNameYn,
                    recordItemNameLength,
                    recordItemMoc,
                ],
                [
                    "INTERFACE RECORD ID",
                    "INTERFACE FILE ITEM ID",
                    "SORT NUMBER",
                    "ITEM SEPARATOR",
                    "ITEM NAME",
                    "ITEM NAME LENGTH",
                    "M/O/C"
                ]
            )
        }

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>INTERFACE RECORD ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceRecord} value={optionsInterfaceRecord.filter((option: any) => option.value === statePage.data.interfaceRecordId)} onChange={(e) => handleState('interfaceRecordId', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>INTERFACE FILE ITEM ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceFileItem} value={optionsInterfaceFileItem.filter((option: any) => option.value === statePage.data.interfaceFileItemId)} onChange={(e) => handleState('interfaceFileItemId', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>SORT NUMBER</p>
                                <input type='number' min={1} value={statePage.data.recordItemSortNumber} onChange={(e) => handleState('recordItemSortNumber', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ITEM SEPARATOR</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileStringSeparatorDetailCode} value={optionsFileStringSeparatorDetailCode.filter((option: any) => option.value === statePage.data.recordItemSeparator)} onChange={(e) => handleState('recordItemSeparator', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ITEM NAME</p>
                                <BootstrapSwitchButton checked={statePage.data.recordItemNameYn} size="xs" onChange={(e) => handleState('recordItemNameYn', e)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ITEM NAME LENGTH</p>
                                <input type='number' min={2} value={statePage.data.recordItemNameLength} onChange={(e) => handleState('recordItemNameLength', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>M/O/C</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMOC} value={optionsMOC.filter((option: any) => option.value === statePage.data.recordItemMoc)} onChange={(e) => handleState('recordItemMoc', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>ITEM DEFAULT VALUE</p>
                                <input value={statePage.data.recordItemDefaultValue} onChange={(e) => handleState('recordItemDefaultValue', e.target.value)} className={inputStyle} />
                            </div>

                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>
            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page39Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInterfaceRecordItem({
        id: null,
        recordItemId: null,
        interfaceFileItemId: null,
        interfaceFileItemName: null,
        interfaceRecordId: null,
        interfaceRecordName: null,
        recordItemSortNumber: null,
        recordItemSeparator: null,
        recordItemNameYn: false,
        recordItemNameLength: null,
        recordItemMoc: null,
        recordItemDefaultValue: null,
        recordItemCreateBy: null,
        recordItemCreateDate: null,
        recordItemUpdateBy: null,
        recordItemUpdateDate: null,
        recordItemDeleteBy: null,
        recordItemDeleteDate: null,
        recordItemIsDelete: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: "",
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceRecordItem) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceRecordItem({
                    id: null,

                    recordItemId: null,
                    interfaceFileItemId: null,
                    interfaceFileItemName: null,
                    interfaceRecordId: null,
                    interfaceRecordName: null,
                    recordItemSortNumber: null,
                    recordItemSeparator: null,
                    recordItemNameYn: false,
                    recordItemNameLength: null,
                    recordItemMoc: null,
                    recordItemDefaultValue: null,
                    recordItemCreateBy: null,
                    recordItemCreateDate: null,
                    recordItemUpdateBy: null,
                    recordItemUpdateDate: null,
                    recordItemDeleteBy: null,
                    recordItemDeleteDate: null,
                    recordItemIsDelete: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceRecordItem({
                    id: action.value.recordItemId,
                    recordItemId: action.value.recordItemId,
                    interfaceFileItemId: action.value.interfaceFileItemId,
                    interfaceFileItemName: action.value.interfaceFileItemName,
                    interfaceRecordId: action.value.interfaceRecordId,
                    interfaceRecordName: action.value.interfaceRecordName,
                    recordItemSortNumber: action.value.recordItemSortNumber,
                    recordItemSeparator: action.value.recordItemSeparator,
                    recordItemNameYn: action.value.recordItemNameYn,
                    recordItemNameLength: action.value.recordItemNameLength,
                    recordItemMoc: action.value.recordItemMoc,
                    recordItemDefaultValue: action.value.recordItemDefaultValue,
                    recordItemCreateBy: action.value.recordItemCreateBy,
                    recordItemCreateDate: action.value.recordItemCreateDate,
                    recordItemUpdateBy: action.value.recordItemUpdateBy,
                    recordItemUpdateDate: action.value.recordItemUpdateDate,
                    recordItemDeleteBy: action.value.recordItemDeleteBy,
                    recordItemDeleteDate: action.value.recordItemDeleteDate,
                    recordItemIsDelete: action.value.recordItemIsDelete,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

import { leftIcon } from '../Assets/Images/svg';

export const NavigationTable = ({ ...props }) => {
  const { position, view, totalPages, currentPage, data, handleState } = props;
  const NumberList = () => {
    if (totalPages <= 10) {
      return (
        Array.from(
          Array(totalPages), (e, i) => {
            return (
              <p key={i} className={` w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ ${parseInt(position) === (i + 1) * parseInt(view) ? 'c-e8 bg-fd' : 'c-29 bd-d4 bg-ff'}`} onClick={(e) => props.handleState('position', (i + 1) * view)}>
                {i + 1}{' '}
              </p>
            )
          }
        )
      )
    } else {
      return (
        <>
          {/* first */}
          <p key={'firstPage'} className={` w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ ${parseInt(position) === 1 * parseInt(view) ? 'c-e8 bg-fd' : 'c-29 bd-d4 bg-ff'}`} onClick={(e) => props.handleState('position', 1 * view)}>
            1
          </p>
          {[1, 2, 3, 4, totalPages].indexOf(currentPage) !== -1 && (
            <p key={'secondPage'} className={` w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ ${parseInt(position) === 2 * parseInt(view) ? 'c-e8 bg-fd' : 'c-29 bd-d4 bg-ff'}`} onClick={(e) => props.handleState('position', 2 * view)}>
              2
            </p>
          )}
          {[1, 2, 3, 4, totalPages].indexOf(currentPage) !== -1 && (
            <p key={'thirdPage'} className={` w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ ${parseInt(position) === 3 * parseInt(view) ? 'c-e8 bg-fd' : 'c-29 bd-d4 bg-ff'}`} onClick={(e) => props.handleState('position', 3 * view)}>
              3
            </p>
          )}
          {[1, 2, 3, 4, totalPages].indexOf(currentPage) === -1 && (
            <p className='w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ c-29 bd-d4 bg-ff' onClick={(e) => props.setModal('insertPage')}>
              ...
            </p>
          )}
          {/*  */}
          {[1, 2, 3, 4, totalPages].indexOf(currentPage) === -1 && (
            <p key={'beforeCurrentPage'} className={` w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ ${parseInt(position) === (currentPage - 1) * parseInt(view) ? 'c-e8 bg-fd' : 'c-29 bd-d4 bg-ff'}`} onClick={(e) => props.handleState('position', (currentPage - 1) * view)}>
              {currentPage - 1}{' '}
            </p>
          )}
          {[1, 2, 3, totalPages].indexOf(currentPage) === -1 ? (
            <p key={'currentPage'} className='w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ c-e8 bg-fd'>
              {currentPage}{' '}
            </p>
          ) : (
            <p className='w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ c-29 bd-d4 bg-ff' onClick={(e) => props.setModal('insertPage')}>
              ...
            </p>
          )}
          {[1, 2, 3, totalPages - 1, totalPages].indexOf(currentPage) === -1 && (
            <p key={'afterCurrentPage'} className={` w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ ${parseInt(position) === (currentPage + 1) * parseInt(view) ? 'c-e8 bg-fd' : 'c-29 bd-d4 bg-ff'}`} onClick={(e) => props.handleState('position', (currentPage + 1) * view)}>
              {currentPage + 1}{' '}
            </p>
          )}
          {/*  */}
          {[1, 2, 3, totalPages - 1, totalPages].indexOf(currentPage) === -1 && (
            <p className='w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ c-29 bd-d4 bg-ff' onClick={(e) => props.setModal('insertPage')}>
              ...
            </p>
          )}

          {/* last */}
          <p key={'lastPage'} className={` w-40px h-40px  br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ ${parseInt(position) === totalPages * parseInt(view) ? 'c-e8 bg-fd' : 'c-29 bd-d4 bg-ff'}`} onClick={(e) => props.handleState('position', totalPages * parseInt(view))}>
            {totalPages}{' '}
          </p>
        </>
      )
    }
  }


  if (data.length > 0) {
    return (
      <div className='d-flex'>
        <p className={`w-40px h-40px br-20 s-1420 d-flex justify-content-center align-items-center mx-1 trans_ c-29 bd-d4 bg-ff trans_  ${parseInt((position / view).toString()) === 1 ? 'd-none' : 'd-flex pointer'}`} onClick={(e) => props.handleState('position', position - view)}>
          {leftIcon}{' '}
        </p>
        {NumberList()}
        <p className={`w-40px h-40px br-20 s-1420 d-flex justify-content-center pointer align-items-center mx-1 trans_ c-29 bd-d4 bg-ff tr-180 ${parseInt((position / view).toString()) === Math.ceil(parseInt(data.length) / parseInt(view)) ? 'd-none' : 'd-flex pointer'}`} onClick={(e) => props.handleState('position', parseInt(position) + parseInt(view))}>
          {leftIcon}{' '}
        </p>
      </div>
    );
  } else return <></>

}

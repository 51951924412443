import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { changeStateTransactionMonitoring, stateGlobalTransactionMonitoring } from "../../../Store/TransactionMonitoringSlice";
import { labelStyle } from "../../../Styles";
import { errNotificationIcon, icon_search_white, undo_icon } from "../../../Assets/Images/svg";
import { initFunction } from "../../../State";

export const FilterComponent = ({ ...props }) => {
    const { initFunctionCustom, handleStateParent, stateParent } = props;
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const [getError, setError] = useState<Boolean>(false);
    const [getErrorMessage, setErrorMessage] = useState('');

    const globalState = useSelector(stateGlobalTransactionMonitoring);

    const { dataFilter, dataCopyFilter, dataYesterday, filterStartDate, filterEndDate, selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedProductId, selectedProductLabel, selectedProductSendToApi, search, optionsProductFeature, optionsProduct, rangeDate } = globalState;

    const handleState = (nameState: string, valueState: any) => dispatchGlobal(changeStateTransactionMonitoring({ [nameState]: valueState }))

    const handleError = (condition: Boolean, errorMessage: string) => {
        setError(condition);
        setErrorMessage(errorMessage);
    };

    return (
        <div className='bg-ff bd-ea br-10 p-2 py-4'>
            <div className='row'>
                <div className='col-3 Lato-400 text-secondary'>
                    <p className={labelStyle}>By Product type:</p>
                    <div className='d-flex justify-content-between'>
                        <Select
                            className="flex-fill"
                            placeholder={'(Search)'}
                            options={optionsProduct}
                            value={optionsProduct.filter((option: any) => option.value === stateParent.selectedProductId)}
                            onChange={(e) => {

                                handleStateParent('selectedProductId', e.value);
                                handleStateParent('selectedProductLabel', e.label);
                                handleStateParent('selectedProductSendToApi', e.sendToApi);
                            }}
                        />
                        <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => {
                            handleStateParent('selectedProductId', '');
                            handleStateParent('selectedProductLabel', '');
                            handleStateParent('selectedProductSendToApi', '');
                        }} >
                            {undo_icon}
                        </div>

                    </div>

                </div>
                <div className='col-3 Lato-400 text-secondary'>
                    <p className={labelStyle}>By Product feature:</p>
                    <div className='d-flex justify-content-between'>
                        <Select
                            className="flex-fill"
                            placeholder={'(Search)'}
                            options={optionsProductFeature}
                            value={optionsProductFeature.filter((option: any) => option.value === stateParent.selectedProductFeatureId)}
                            onChange={(e) => {
                                handleStateParent('selectedProductFeatureId', e.value);
                                handleStateParent('selectedProductFeatureLabel', e.label);
                                handleStateParent('selectedProductFeatureSendToApi', e.sendToApi);
                            }}
                        />
                        <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => {
                            handleStateParent('selectedProductFeatureId', '');
                            handleStateParent('selectedProductFeatureLabel', '');
                            handleStateParent('selectedProductFeatureSendToApi', '');
                        }} >
                            {undo_icon}
                        </div>
                    </div>

                </div>
                <div className='col-6 Lato-400 text-secondary '>
                    <div className='row d-flex flex-row-reverse'>
                        <div className='col-5'>
                            <p className={labelStyle}>End Date:</p>
                            <div className='d-flex justify-content-between'>
                                <input
                                    type='date'
                                    className='form-control text-secondary flex-fill'
                                    id='transaction-end-date'
                                    placeholder=''
                                    value={stateParent.filterEndDate}
                                    max={moment().format('YYYY-MM-DD')}
                                    onChange={(e) => {
                                        let dateStart = new Date(stateParent.filterStartDate);
                                        let dateEnd = new Date(e.target.value);
                                        let listAllDayChart = [];
                                        for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                                            listAllDayChart.push(d.toISOString().slice(0, 10));
                                        }
                                        if (listAllDayChart.length < 1) {
                                            handleError(true, 'Invalid date spacing');
                                        } else if (listAllDayChart.length > 365) {
                                            handleError(true, 'Maximum Date Range 1 Year');
                                        } else {
                                            handleError(false, '');
                                            handleStateParent('filterEndDate', e.target.value);
                                        }
                                    }}
                                />
                                <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => {
                                    let dateNow = new Date().toISOString().slice(0, 10);
                                    handleStateParent('filterEndDate', dateNow)
                                }} >
                                    {undo_icon}
                                </div>
                            </div>
                        </div>
                        <div className='col-5'>
                            <p className={labelStyle}>Start Date:</p>
                            <div className='d-flex justify-content-between'>
                                <input
                                    type='date'
                                    className='form-control text-secondary flex-fill'
                                    id='transaction-start-date'
                                    placeholder=''
                                    value={stateParent.filterStartDate}
                                    max={moment().format('YYYY-MM-DD')}
                                    onChange={(e) => {
                                        let dateStart = new Date(e.target.value);
                                        let dateEnd = new Date(stateParent.filterEndDate);
                                        let listAllDayChart = [];
                                        for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                                            listAllDayChart.push(d.toISOString().slice(0, 10));
                                        }
                                        if (listAllDayChart.length < 1) {
                                            handleError(true, 'Invalid date spacing');
                                        } else if (listAllDayChart.length > 365) {
                                            handleError(true, 'Maximum Date Range 1 Year');
                                        } else {
                                            handleError(false, '');
                                            handleStateParent('filterStartDate', e.target.value);
                                        }
                                    }}
                                />
                                <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => {
                                    let dateNow = new Date().toISOString().slice(0, 10);
                                    handleStateParent('filterStartDate', dateNow)
                                }} >
                                    {undo_icon}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-2">
                {
                    getError === true ?
                        <div className='col-12 pl-0'>
                            <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                {errNotificationIcon}
                                <p className='px-2 s-1418 Lato-600 c-e87'>{getErrorMessage}</p>
                            </div>
                        </div>

                        :

                        <></>
                }
                <div className="d-flex">
                    <button className="btn d-flex align-items-center justify-content-center bg-e8  "
                        onClick={() => {
                            dispatchGlobal(changeStateTransactionMonitoring({
                                'filterEndDate': stateParent.filterEndDate,
                                'filterStartDate': stateParent.filterStartDate,
                                'selectedProductFeatureId': stateParent.selectedProductFeatureId,
                                'selectedProductFeatureLabel': stateParent.selectedProductFeatureLabel,
                                'selectedProductFeatureSendToApi': stateParent.selectedProductFeatureSendToApi,
                                'selectedProductId': stateParent.selectedProductId,
                                'selectedProductLabel': stateParent.selectedProductLabel,
                                'selectedProductSendToApi': stateParent.selectedProductSendToApi,
                            }))
                        }}
                    >
                        <div className="h-100">{icon_search_white}</div>
                        <div className="h-100 mx-2 d-flex justify-content-center align-items-center s-1418 c-ff Lato-600  text-center">Search</div>
                    </button>

                </div>
            </div>

        </div>
    )
}
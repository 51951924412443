import {
    CheckUndefined,
    ExportCSV,
    NumberWithCommas
} from "../../../Utils";
export const ExcelReversalTransactionMonitoring = (state: any, stateOptions: any, stateRedux: any) => {
    let newList: any[] = []
    let listData: any[] = []
    stateRedux.dataCopyFilter.map((item: any, i: any) => {
        let showsafStatus = '';
        if (stateOptions.optionsSAFStatusDetailCode.length > 0) {
            stateOptions.optionsSAFStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.safStatus) {
                    showsafStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            issuerName: CheckUndefined(item.issuerName),
            issuerSwitcherName: CheckUndefined(item.issuerSwitcherName),
            acquirerSwitcherName: CheckUndefined(item.acquirerSwitcherName),
            acquirerName: CheckUndefined(item.acquirerName),
            transAmount: `${CheckUndefined(item.transCurrency)} ${NumberWithCommas(CheckUndefined(item.transAmount))}`,
            transCount: CheckUndefined(item.transCount),
            safStatus: CheckUndefined(showsafStatus),
            safCreateDate: CheckUndefined(item.safCreateDate),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, 'SAF Statistic')
};


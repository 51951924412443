

// SafControlManagement

interface SafControlManagementInterface {
    id: null | number | string;

    safControlId: number | null;
    memberId: number | null;
    safControlSrStatus: string | null;
    safControlProcessCount: number | null;
    safControlRetryCount: number | null;
    safControlWorkerNumber: number | null;
    safControlTimeout: number | null;
    memberName: string | null;
    memberCode: string | null;
    safSleepTime: number | null;
    safControlServer: string | null;
    safControlRsId: number | null;
}

export class ModelSafControlManagement {
    id: null | number | string;
    safControlId: number | null;
    memberId: number | null;
    safControlSrStatus: string | null;
    safControlProcessCount: number | null;
    safControlRetryCount: number | null;
    safControlWorkerNumber: number | null;
    safControlTimeout: number | null;
    memberName: string | null;
    memberCode: string | null;
    safSleepTime: number | null;
    safControlServer: string | null;
    safControlRsId: number | null;

    constructor(value: SafControlManagementInterface) {
        this.id = value.id;
        this.safControlId = value?.safControlId ?? null;
        this.memberId = value?.memberId ?? null;
        this.safControlSrStatus = value?.safControlSrStatus ?? null;
        this.safControlProcessCount = value?.safControlProcessCount ?? null;
        this.safControlRetryCount = value?.safControlRetryCount ?? null;
        this.safControlWorkerNumber = value?.safControlWorkerNumber ?? null;
        this.safControlTimeout = value?.safControlTimeout ?? null;
        this.memberName = value?.memberName ?? null;
        this.memberCode = value?.memberCode ?? null;
        this.safSleepTime = value?.safSleepTime ?? null;
        this.safControlServer = value?.safControlServer ?? null;
        this.safControlRsId = value?.safControlRsId ?? null;
    }
}

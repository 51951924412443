import { useState, useEffect } from 'react';
import { CodeDetail } from '../CodeDetail/CodeDetail';
import { GroupCodeList } from '../GroupCodeList/GroupCodeList';
import { Page44Title } from '../../State/Menu/TitleMenu';

export const CommonCodeManagement = ({...props}) => {
  const [getTriggerReload, setTriggerReload] = useState(false)
  const [getCodeDetail, setCodeDetail] = useState('')

  return (
    <>
      <h1 className='Lato-300 normal w-600 s-1822 pt-2'>{Page44Title}</h1>
      <GroupCodeList getTriggerReload={getTriggerReload} setTriggerReload={setTriggerReload} getCodeDetail={getCodeDetail} setCodeDetail={setCodeDetail} />
      <div className='my-3'></div>
      <CodeDetail getTriggerReload={getTriggerReload} setTriggerReload={setTriggerReload} getCodeDetail={getCodeDetail} setCodeDetail={setCodeDetail} />
    </>
  )
}



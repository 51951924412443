// Payment

interface MemberPics {
    memberPicId: number | null;
    memberId: number | null;
    memberPicCode: string | null;
    userId: number | null;
}

interface InterfacePayment {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberPaymentSndYn: Boolean | null;
    memberPaymentSndTime: string | number | null;
    memberPaymentSndTzone: string | number | null;
    memberPaymentRcvYn: Boolean | null;
    memberPaymentRcvTime: string | number | null;
    memberPaymentRcvTZone: string | number | null;
    memberPaymentAccNo: string | number | null;
    memberPaymentBankName: string | number | null;
    memberPics: MemberPics[] | null;
    memberPaymentConfirm: string | null;
    memberPaymentEmailNotifYn: Boolean | null;

}

export class ModalPayment {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberPaymentSndYn: Boolean | null;
    memberPaymentSndTime: string | number | null;
    memberPaymentSndTzone: string | number | null;
    memberPaymentRcvYn: Boolean | null;
    memberPaymentRcvTime: string | number | null;
    memberPaymentRcvTZone: string | number | null;
    memberPaymentAccNo: string | number | null;
    memberPaymentBankName: string | number | null;
    memberPics: MemberPics[] | null;
    memberPaymentConfirm: string | null;
    memberPaymentEmailNotifYn: Boolean | null;

    constructor(value: InterfacePayment) {
        this.id = value.id;
        this.memberParamsId = value?.memberParamsId ?? null;
        this.memberCode = value?.memberCode ?? null;
        this.memberPaymentSndYn = value?.memberPaymentSndYn ?? null;
        this.memberPaymentSndTime = value?.memberPaymentSndTime ?? null;
        this.memberPaymentSndTzone = value?.memberPaymentSndTzone ?? null;
        this.memberPaymentRcvYn = value?.memberPaymentRcvYn ?? null;
        this.memberPaymentRcvTime = value?.memberPaymentRcvTime ?? null;
        this.memberPaymentRcvTZone = value?.memberPaymentRcvTZone ?? null;
        this.memberPaymentAccNo = value?.memberPaymentAccNo ?? null;
        this.memberPaymentBankName = value?.memberPaymentBankName ?? null;
        this.memberPics = value?.memberPics ?? null;
        this.memberPaymentConfirm = value?.memberPaymentConfirm ?? null;
        this.memberPaymentEmailNotifYn = value?.memberPaymentEmailNotifYn ?? null;
    }
}

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page16Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalExchangeRateSetting = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsCommonStatusDetailCode, optionsCurrencyCcdName } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { rateFrom, rateTo, rateValue, rateStatus, rateStartDate, rateEndDate } = statePage
        const data = {
            rateFrom: `${rateFrom}`,
            rateTo: `${rateTo}`,
            rateValue: `${rateValue}`,
            rateStatus: `${rateStatus}`,
            rateStartDate: `${rateStartDate}`,
            rateEndDate: `${rateEndDate}`,
            rateSrcName: "MANUAL",
            rateSrcMethod: "DASHBOARD",
        }
        const a = CheckAllFields([rateFrom, rateTo, rateValue, rateStatus], ["From", "To", "Rate", "Status"])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-600px'>
                    <div className='d-flex min-h-40px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className={'col-6'}>
                                <p className={labelStyle}>FROM</p>
                                <Select styles={inputStyleH25} options={optionsCurrencyCcdName} value={optionsCurrencyCcdName.filter((option: any) => option.value === statePage.rateFrom)} onChange={(e: any) => handleState("rateFrom", e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>To</p>
                                <Select styles={inputStyleH25} options={optionsCurrencyCcdName} value={optionsCurrencyCcdName.filter((option: any) => option.value === statePage.rateTo)} onChange={(e: any) => handleState("rateTo", e.value)} />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>RATE</p>
                                <input type="number" min={0} value={statePage.rateValue} onChange={(e) => handleState("rateValue", e.target.value)} className={inputStyle} />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>Status</p>
                                <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.rateStatus)} onChange={(e) => handleState("rateStatus", e.value)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page16Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    rateFrom: "",
    rateTo: "",
    rateValue: "",
    rateStatus: '001',
    rateStartDate: "",
    rateEndDate: "",
    rateToCurr: "",
    rateSrcName: "",
    rateSrcMethod: "",
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                rateFrom: "",
                rateTo: "",
                rateValue: "",
                rateStatus: '001',
                rateStartDate: "",
                rateEndDate: "",
                rateToCurr: "",
                rateSrcName: "",
                rateSrcMethod: "",
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                rateFrom: action.value.rateFrom,
                rateTo: action.value.rateTo,
                rateValue: action.value.rateValue,
                rateStatus: action.value.rateStatus,
                rateStartDate: action.value.rateStartDate,
                rateEndDate: action.value.rateEndDate,
                rateToCurr: action.value.rateToCurr,
                rateSrcName: action.value.rateSrcName,
                rateSrcMethod: action.value.rateSrcMethod,
                errorMessage: '',
            };
        // 
        case 'rateFrom':
            return {
                ...state,
                rateFrom: action.value,
            };
        case 'rateTo':
            return {
                ...state,
                rateTo: action.value,
            };
        case 'rateValue':
            return {
                ...state,
                rateValue: action.value,
            };
        case 'rateStatus':
            return {
                ...state,
                rateStatus: action.value,
            };
        case 'rateStartDate':
            return {
                ...state,
                rateStartDate: action.value,
            };
        case 'rateEndDate':
            return {
                ...state,
                rateEndDate: action.value,
            };
        case 'rateToCurr':
            return {
                ...state,
                rateToCurr: action.value,
            };
        case 'rateSrcName':
            return {
                ...state,
                rateSrcName: action.value,
            };
        case 'rateSrcMethod':
            return {
                ...state,
                rateSrcMethod: action.value,
            };
        // 
        default:
            throw new Error();
    }
}

import {
    CheckUndefined,
    ExportCSV,
    NumberWithCommas
} from "../../../Utils";

export const ExcelQRAuthMonitoring = (state: any, stateOptions: any, stateRedux: any) => {
    let newList: any[] = []
    let listData: any[] = []

    stateRedux.dataCopyFilter.map((item: any, i: any) => {
        const m = {
            no: i + 1,
            prodFeatureName: CheckUndefined(item.prodFeatureName),
            qrsName: CheckUndefined(item.qrsName),
            memberName: CheckUndefined(item.memberName),
            qrReqTotalCount: CheckUndefined(item.qrReqTotalCount),
            lastReqDate: CheckUndefined(item.lastReqDate)
        }

        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })

        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if(state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })

    ExportCSV(newList, listData, 'QR Auth Statistic');
}
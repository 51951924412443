export const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'listReasonCode':
            return {
                ...state,
                listReasonCode: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'privilegeId':
            return {
                ...state,
                privilegeId: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'listRoleType':
            return {
                ...state,
                listRoleType: action.value
            };
        case 'isRoleBCAP':
            return {
                ...state,
                isRoleBCAP: action.value
            };
        case 'isRoleAcquirer':
            return {
                ...state,
                isRoleAcquirer: action.value
            };
        case 'isRoleAcquirerSwitcher':
            return {
                ...state,
                isRoleAcquirerSwitcher: action.value
            };
        case 'isRoleIssuer':
            return {
                ...state,
                isRoleIssuer: action.value
            };
        case 'isRoleIssuerSwitcher':
            return {
                ...state,
                isRoleIssuerSwitcher: action.value
            };

        // data
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'transDivided':
            return {
                ...state,
                transDivided: action.value
            };
        case 'totalData':
            return {
                ...state,
                totalData: action.value,
            };

        // pagination
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };

        // filter
        case 'filterStartDate':
            return {
                ...state,
                filterStartDate: action.value
            };
        case 'filterEndDate':
            return {
                ...state,
                filterEndDate: action.value
            };
        case 'graphInboundDays':
            return {
                ...state,
                graphInboundDays: action.value
            };
        case 'graphOutboundDays':
            return {
                ...state,
                graphOutboundDays: action.value
            };
        case 'graphAPIDays':
            return {
                ...state,
                graphAPIDays: action.value
            };
        case 'graphTimeoutDays':
            return {
                ...state,
                graphTimeoutDays: action.value
            };
        case 'graphInboundStatus':
            return {
                ...state,
                graphInboundStatus: action.value
            };
        case 'graphOutboundStatus':
            return {
                ...state,
                graphOutboundStatus: action.value
            };
        case 'selectedCountryId':
            return {
                ...state,
                selectedCountryId: action.value
            };
        case 'selectedCountryLabel':
            return {
                ...state,
                selectedCountryLabel: action.value
            };
        case 'selectedAcquirerSwitcherId':
            return {
                ...state,
                selectedAcquirerSwitcherId: action.value
            };
        case 'selectedAcquirerSwitcherLabel':
            return {
                ...state,
                selectedAcquirerSwitcherLabel: action.value
            };
        case 'selectedIssuerSwitcherId':
            return {
                ...state,
                selectedIssuerSwitcherId: action.value
            };
        case 'selectedIssuerSwitcherLabel':
            return {
                ...state,
                selectedIssuerSwitcherLabel: action.value
            };
        case 'filterErrorMessage':
            return {
                ...state,
                filterErrorMessage: action.value
            };
        case 'isRowHovered':
            return {
                ...state,
                isRowHovered: action.value
            };

        default:
            throw new Error();
    }
}

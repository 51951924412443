interface TransactionTypeDto {
    transactionType: string;
    transactionTypeId: string;
    transactionAmount: string;
    feeAmount: string;
    settlementAmount: string;
    count: string;
}

interface SettlementMemberDetailDto {
    memberCode: string;
    memberName: string;
    settDate: string;
    transactionTypeDto: TransactionTypeDto[];
    memberTableType: string;
    transactionTotalCount: string;
    transactionTotalAmount: string;
    feeTotalAmount: string;
    settlementTotalAmount: string;
}

interface SettlementSummaryInterface {
    id: null | number | string;
    typeRole: string | null; 
    type: string | null;
    settlementMemberDetailDto: SettlementMemberDetailDto | null;
}

export class ModelDetail {
    id: null | number | string;
    typeRole: string | null;  
    type: string | null;
    settlementMemberDetailDto: SettlementMemberDetailDto | null;

    constructor(value: SettlementSummaryInterface | null) {
        this.id = value?.id ?? null;
        this.typeRole = value?.typeRole ?? null;
        this.type = value?.type ?? null;
        this.settlementMemberDetailDto = value?.settlementMemberDetailDto ?? null;
    }
}

import { errNotificationIcon } from "../Assets/Images/svg";

export const ErrorMessage2 = ({ ...props }) => {
    const { errorMessage, errorMessageList } = props;
        return (
            <>
                {
                    errorMessageList.length > 0 ?
                        <div className='col-12 pl-0'>
                            <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                {errNotificationIcon}
                                <p className='px-2 s-1418 Lato-600 c-e87'>{errorMessageList.map((item: any) => item.errorMessage).toString()}</p>
                            </div>
                        </div>
                        :
                        errorMessage !== '' && (
                            <div className='col-12 pl-0'>
                                <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                    {errNotificationIcon}
                                    <p className='px-2 s-1418 Lato-600 c-e87'>{errorMessage}</p>
                                </div>
                            </div>

                        )
                }
            </>

        )
}
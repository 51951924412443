import { CheckUndefined, ExportCSV } from "../../../Utils";

const excelProductFeatureManagement = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showProdFeatureStatus = '';
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.prodFeatureStatus) {
                    showProdFeatureStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            id: CheckUndefined(`${CheckUndefined(item.prodFeatureId)} - ${CheckUndefined(item.prodFeatureName)}`),
            prodFeatureDesc: CheckUndefined(item.prodFeatureDesc),
            productId: CheckUndefined(`${CheckUndefined(item.productId)} - ${CheckUndefined(item.productName)}`),
            prodFeatureInd: CheckUndefined(item.prodFeatureInd),
            prodFeatureStatus: CheckUndefined(showProdFeatureStatus),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};
const excelProductFeatureMember = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showproductFeatureId = '';
        let showmemberId = '';
        let showpfmDestMemberId = '';
        let showpfmRsId = '';
        let showpfmStatus = '';
        if (stateOptions.optionsProductFeature.length > 0) {
            stateOptions.optionsProductFeature.forEach((element2: any) => {
                if (element2.value === item.productFeatureId) {
                    showproductFeatureId = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsMember.length > 0) {
            stateOptions.optionsMember.forEach((element2: any) => {
                if (element2.value === item.memberId) {
                    showmemberId = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsMember.length > 0) {
            stateOptions.optionsMember.forEach((element2: any) => {
                if (element2.value === item.pfmDestMemberId) {
                    showpfmDestMemberId = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsRouteSequence.length > 0) {
            stateOptions.optionsRouteSequence.forEach((element2: any) => {
                if (element2.value === item.pfmRsId) {
                    showpfmRsId = `${element2.value} - ${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (`${element2.value}` === item.pfmStatus) {
                    showpfmStatus = `${element2.label}`;
                }
            });
        }

        const m = {
            no: i + 1,
            productFeatureId: CheckUndefined(showproductFeatureId),
            memberId: CheckUndefined(showmemberId),
            pfmDestMemberId: CheckUndefined(showpfmDestMemberId),
            pfmRsId: CheckUndefined(showpfmRsId),
            pfmStatus: CheckUndefined(showpfmStatus),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};
const excelProductManagement = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showCommonStatus = '';
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.productStatus) {
                    showCommonStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            id: CheckUndefined(item.productId), // productId
            productName: CheckUndefined(item.productName),
            productDesc: CheckUndefined(item.productDesc),
            speId: CheckUndefined(showCommonStatus),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};

export {
    excelProductFeatureManagement,
    excelProductFeatureMember,
    excelProductManagement,
}
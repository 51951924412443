import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page26Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalQRStandardMember = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsQRStandard, optionsYorN, optionsCommonStatusDetailCode, optionsMember, optionsRouteSequence } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { qrStandardId, qrStandardName, qrsmSrcMemberId, qrsmSrcMemberName, qrsmDstMemberId, qrsmDstMemberName, qrsmStatus, qrsmGuid, qrsmMerId, qrsmAid, qrsmBillerId, qrsmMpan, qrsmNii, qrsmOfx, qrsmRsId, errorMessage } = statePage
        const data = {
            qrStandardId: qrStandardId, // mendatory QR Standard
            qrsmStatus: qrsmStatus, // mendatory Status
            qrsmGuid: qrsmGuid, // o GUID
            qrsmMerId: qrsmMerId, // o Merchant ID
            qrsmAid: qrsmAid, // o AID
            qrsmBillerId: qrsmBillerId, // o Biller ID
            qrsmMpan: qrsmMpan,  // o MPAN
            qrsmNii: qrsmNii, // o NII
            qrsmSrcMemberId: qrsmSrcMemberId, // mendatory Source Member
            qrsmDstMemberId: qrsmDstMemberId, // mendatory Dest Member
            qrsmOfx: qrsmOfx, // mendatory Online FX  true false
            qrsmRsId: qrsmOfx === true ? qrsmRsId : null, // o OR mendatory RSID
        };
        let a = '';
        let testDataqrsmRsId = '';
        if (qrsmRsId === null) {
            testDataqrsmRsId = ''
        } else {
            testDataqrsmRsId = qrsmRsId
        }
        if (qrsmOfx === true) {
            a = CheckAllFields([qrStandardId, qrsmStatus, qrsmSrcMemberId, qrsmDstMemberId, qrsmOfx, testDataqrsmRsId], ['QR Standard', 'Status', 'Source Member', 'Dest Member', 'Online FX', 'Online FX RSID']);
        } else {
            a = CheckAllFields([qrStandardId, qrsmStatus, qrsmSrcMemberId, qrsmDstMemberId, qrsmOfx], ['QR Standard', 'Status', 'Source Member', 'Dest Member', 'Online FX']);
        }
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>QR Standard</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsQRStandard}
                                    value={optionsQRStandard.filter((option: any) => option.value === statePage.qrStandardId)}
                                    onChange={(e) => handleState('qrStandardId', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Source Member</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsMember}
                                    value={optionsMember.filter((option: any) => option.value === statePage.qrsmSrcMemberId)}
                                    onChange={(e) => handleState('qrsmSrcMemberId', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Dst Member</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsMember}
                                    value={optionsMember.filter((option: any) => option.value === statePage.qrsmDstMemberId)}
                                    onChange={(e) => handleState('qrsmDstMemberId', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Online FX</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsYorN}
                                    value={optionsYorN.filter((option: any) => option.value === statePage.qrsmOfx)}
                                    onChange={(e) => handleState('qrsmOfx', e.value)}
                                />
                            </div>
                            {statePage.qrsmOfx === true ?
                                <div className='col-6'>
                                    <p className={labelStyle}>Online FX RSID</p>
                                    <Select
                                        styles={inputStyleH25}
                                        placeholder={'(Search)'}
                                        options={optionsRouteSequence}
                                        value={optionsRouteSequence.filter((option: any) => option.value === statePage.qrsmRsId)}
                                        onChange={(e) => handleState('qrsmRsId', e.value)}
                                    />
                                </div>
                                :
                                <></>
                            }

                            <div className='col-6'>
                                <p className={labelStyle}>Merchant ID</p>
                                <input value={statePage.qrsmMerId} onChange={(e) => handleState('qrsmMerId', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>MPAN</p>
                                <input value={statePage.qrsmMpan} onChange={(e) => handleState('qrsmMpan', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>GUID</p>
                                <input value={statePage.qrsmGuid} onChange={(e) => handleState('qrsmGuid', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>NII</p>
                                <input value={statePage.qrsmNii} onChange={(e) => handleState('qrsmNii', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>AID</p>
                                <input value={statePage.qrsmAid} onChange={(e) => handleState('qrsmAid', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Biller ID</p>
                                <input value={statePage.qrsmBillerId} onChange={(e) => handleState('qrsmBillerId', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Status</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode}
                                    value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.qrsmStatus)}
                                    onChange={(e) => handleState('qrsmStatus', `${e.value}`)}
                                />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page26Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    qrsmId: '',
    qrStandardId: '',
    qrStandardName: '',
    qrsmSrcMemberId: '',
    qrsmSrcMemberName: '',
    qrsmDstMemberId: '',
    qrsmDstMemberName: '',
    qrsmStatus: '001',
    qrsmGuid: '',
    qrsmMerId: '',
    qrsmAid: '',
    qrsmBillerId: '',
    qrsmMpan: '',
    qrsmNii: '',
    qrsmOfx: false,
    qrsmRsId: '',
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                qrsmId: '',
                qrStandardId: '',
                qrStandardName: '',
                qrsmSrcMemberId: '',
                qrsmSrcMemberName: '',
                qrsmDstMemberId: '',
                qrsmDstMemberName: '',
                qrsmStatus: '001',
                qrsmGuid: '',
                qrsmMerId: '',
                qrsmAid: '',
                qrsmBillerId: '',
                qrsmMpan: '',
                qrsmNii: '',
                qrsmOfx: false,
                qrsmRsId: '',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                qrsmId: action.value.qrsmId,
                qrStandardId: action.value.qrStandardId,
                qrStandardName: action.value.qrStandardName,
                qrsmSrcMemberId: action.value.qrsmSrcMemberId,
                qrsmSrcMemberName: action.value.qrsmSrcMemberName,
                qrsmDstMemberId: action.value.qrsmDstMemberId,
                qrsmDstMemberName: action.value.qrsmDstMemberName,
                qrsmStatus: action.value.qrsmStatus,
                qrsmGuid: action.value.qrsmGuid,
                qrsmMerId: action.value.qrsmMerId,
                qrsmAid: action.value.qrsmAid,
                qrsmBillerId: action.value.qrsmBillerId,
                qrsmMpan: action.value.qrsmMpan,
                qrsmNii: action.value.qrsmNii,
                qrsmOfx: action.value.qrsmOfx,
                qrsmRsId: action.value.qrsmRsId,
                errorMessage: "",
            };
        // 
        case 'qrsmId':
            return {
                ...state,
                qrsmId: action.value,
            };
        case 'qrStandardId':
            return {
                ...state,
                qrStandardId: action.value,
            };
        case 'qrStandardName':
            return {
                ...state,
                qrStandardName: action.value,
            };
        case 'qrsmSrcMemberId':
            return {
                ...state,
                qrsmSrcMemberId: action.value,
            };
        case 'qrsmSrcMemberName':
            return {
                ...state,
                qrsmSrcMemberName: action.value,
            };
        case 'qrsmDstMemberId':
            return {
                ...state,
                qrsmDstMemberId: action.value,
            };
        case 'qrsmDstMemberName':
            return {
                ...state,
                qrsmDstMemberName: action.value,
            };
        case 'qrsmStatus':
            return {
                ...state,
                qrsmStatus: action.value,
            };
        case 'qrsmGuid':
            return {
                ...state,
                qrsmGuid: action.value,
            };

        case 'qrsmMerId':
            return {
                ...state,
                qrsmMerId: action.value,
            };
        case 'qrsmAid':
            return {
                ...state,
                qrsmAid: action.value,
            };
        case 'qrsmBillerId':
            return {
                ...state,
                qrsmBillerId: action.value,
            };
        case 'qrsmMpan':
            return {
                ...state,
                qrsmMpan: action.value,
            };
        case 'qrsmNii':
            return {
                ...state,
                qrsmNii: action.value,
            };
        case 'qrsmOfx':
            return {
                ...state,
                qrsmOfx: action.value,
            };
        case 'qrsmRsId':
            return {
                ...state,
                qrsmRsId: action.value,
            };

        default:
            throw new Error();
    }
}

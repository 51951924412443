import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { format } from "date-fns";
import Select from 'react-select';
import Draggable from 'react-draggable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FooterModalUpdate, DatePickerCalendarIconOnly } from '../../Components';
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';
import { inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { Page46Title } from '../../State/Menu/TitleMenu';
import { logoutAction } from '../../Store/User';
import { loadingAction } from '../../Store/Loading';


export const ModalDraggableReasonCodeMapping = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsMember, optionsReasonCodeUseId } = props.stateOptions

    const draggleRef = React.createRef<any>();


    useEffect(() => {

        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        const { rcmApplyDate, rcmEndDate } = statePage;

        if (nameState === "rcmApplyDate") {
            let dateStart = new Date(valueState);
            let dateEnd = new Date(rcmEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }

            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: "Start date cannot be larger than End Date" })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else if (nameState === "rcmEndDate") {
            let dateStart = new Date(rcmApplyDate);
            let dateEnd = new Date(valueState);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: "Start date cannot be larger than End Date" })

            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const { rcmId, rcId, memberId, rcmMemberReasonCode, rcmMemberReasonName, rcmApplyDate, rcmEndDate } = statePage;
        const data = {
            rcmId: `${id}`,
            rcId: `${rcId}`,
            memberId: `${memberId}`,
            rcmMemberReasonCode: `${rcmMemberReasonCode}`,
            rcmMemberReasonName: `${rcmMemberReasonName}`,
            rcmApplyDate: `${rcmApplyDate}`,
            rcmEndDate: `${rcmEndDate}`,
        };

        const a = CheckAllFields([rcId, memberId, rcmMemberReasonCode, rcmMemberReasonName, rcmApplyDate, rcmEndDate], ["Internal Reason Code", "Member  ID", "Member Reason Code", "Member Reason Code Name", "Start Date", "End Date"]);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.update({
                bodyCustom: data,

                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className="col-6">
                        <p className={labelStyle}>member ID</p>
                        <Select
                            styles={inputStyleH25}
                            options={optionsMember}
                            value={optionsMember.filter((option: any) => option.value === statePage.memberId)}
                            onChange={(e) => handleState("memberId", e.value)}
                        />
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>internal reason code</p>
                        <Select
                            styles={inputStyleH25}
                            options={optionsReasonCodeUseId}
                            value={optionsReasonCodeUseId.filter((option: any) => option.value === `${statePage.rcId}`)}
                            onChange={(e) => handleState("rcId", e.value)}
                        />
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>member reason code</p>
                        <input value={statePage.rcmMemberReasonCode} onChange={(e) => handleState("rcmMemberReasonCode", e.target.value)} className={inputStyle} />
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>member reason code name</p>
                        <input value={statePage.rcmMemberReasonName} onChange={(e) => handleState("rcmMemberReasonName", e.target.value)} className={inputStyle} />
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>start date</p>
                        <div>
                            <div className="d-flex">
                                <DatePicker
                                    selected={statePage.rcmApplyDate}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    showPopperArrow={false}
                                    onChange={(date: any) => handleState('rcmApplyDate', format(date, 'yyyy-MM-dd'))}
                                />
                            </div>
                            <DatePickerCalendarIconOnly />
                        </div>
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>end date</p>
                        <div>
                            <div className="d-flex">
                                <DatePicker
                                    selected={statePage.rcmEndDate}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    showPopperArrow={false}
                                    onChange={(date: any) => handleState("rcmEndDate", format(date, 'yyyy-MM-dd'))}
                                />
                            </div>
                            <DatePickerCalendarIconOnly />
                        </div>
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page46Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    //
    rcmId: "",
    rcId: "",
    rcCode: '',
    memberId: "",
    rcmMemberReasonCode: "",
    rcmMemberReasonName: "",
    rcmApplyDate: new Date().toISOString().slice(0, 10),
    rcmEndDate: new Date("2099-12-31").toISOString().slice(0, 10),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                rcmId: "",
                rcId: "",
                rcCode: '',
                memberId: "",
                rcmMemberReasonCode: "",
                rcmMemberReasonName: "",
                rcmApplyDate: new Date().toISOString().slice(0, 10),
                rcmEndDate: new Date("2099-12-31").toISOString().slice(0, 10),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                rcmId: action.value.rcmId,
                rcId: action.value.rcId,
                rcCode: action.value.rcCode,
                memberId: action.value.memberId,
                rcmMemberReasonCode: action.value.rcmMemberReasonCode,
                rcmMemberReasonName: action.value.rcmMemberReasonName,
                rcmApplyDate: action.value.rcmApplyDate,
                rcmEndDate: action.value.rcmEndDate,
                errorMessage: "",
            };
        // 
        case 'rcmId':
            return {
                ...state,
                rcmId: action.value,
            };
        case 'rcId':
            return {
                ...state,
                rcId: action.value,
            };
        case 'rcCode':
            return {
                ...state,
                rcCode: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'rcmMemberReasonCode':
            return {
                ...state,
                rcmMemberReasonCode: action.value,
            };
        case 'rcmMemberReasonName':
            return {
                ...state,
                rcmMemberReasonName: action.value,
            };
        case 'rcmApplyDate':
            return {
                ...state,
                rcmApplyDate: action.value,
            };
        case 'rcmEndDate':
            return {
                ...state,
                rcmEndDate: action.value,
            };

        default:
            throw new Error();
    }
}





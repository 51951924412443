import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { SelectView } from "../../Components";
import { useMemo } from "react";
import { CheckUndefined } from "../../Utils";
import { HeaderDataTable } from "../../Components/HeaderDataTable";

export const TableUserAuditTrail = ({ ...props }) => {
    const {
        state,
        addDataComponent,
        exportToCSVComponent,
        actionShowModal,
        handleStateComponent,
        checkIndexComponent,
        setPagination,
        pagination,
        filterComponentPage,
    } = props;
    const columns: any = useMemo(
        () => [
            {
                header: 'User ID',
                accessorKey: 'userId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.userId)} - ${CheckUndefined(row.original.username)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
            {
                header: 'Screen Name',
                accessorKey: 'screenName',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.screenName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
            {
                header: 'Activity',
                accessorKey: 'logFileName',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.logFileName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
            {
                header: 'CRUD',
                accessorKey: 'crudType',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.crudType)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
            {
                header: 'Before Snapshot',
                accessorKey: 'before',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.before)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',                        
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: 250,
                    },
                }

            },
            {
                header: 'After Snapshot',
                accessorKey: 'after',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.after)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',                        
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: 250,
                    },
                }
            },
            {
                header: 'Activity Time',
                accessorKey: 'logCreateDt',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.logCreateDt)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
            {
                header: 'Client IP Address',
                accessorKey: 'logSourceIp',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.logSourceIp)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                onClick: () => (
                    actionShowModal(row.original.id, row.original)
                ),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },
        muiSearchTextFieldProps: {
            placeholder: `Search By ${state.SearchByPlaceHolder}`,
            title: `Search By ${state.SearchByPlaceHolder}`,
            
        },

        onGlobalFilterChange: (filterValue) => {
            if(filterValue === undefined) {
                handleStateComponent('search', '')
            } else {
                handleStateComponent('search', filterValue)
            }
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <>
                <div className="d-flex">
                    <HeaderDataTable
                        stateParent={state}
                        data={state.data}
                        totalData={state.totalData}
                        label={state.label}
                        titleTable={'List'}
                        privilegeAccess={state.privilegeAccess}
                        addData={addDataComponent}
                        checkIndex={checkIndexComponent}
                        exportToCSV={exportToCSVComponent}
                        handleState={handleStateComponent}
                        needToggleFunction={false}
                        needAddButton={true}
                        needSearchFunction={true}
                        needDownloadButton={true}
                        table={table}
                    />
                </div>
            </>
        ),
    });

    return (
        <>
            <div id="table" className="mt-1 mb-5 position-relative">
                {filterComponentPage()}
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    )
}
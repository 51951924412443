import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page24Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalQRStandard = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsQrMpmCpm, optionsFormat, optionsCommonStatusDetailCode, optionsCountry } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'qrsName') {
            if (valueState.split('').length < 33) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'qrsCode') {
            if (valueState.split('').length < 5) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { qrsName, qrsDesc, qrsCode, qrsStatus, qrsFormat, qrsCpmMpm, countryId, countryName, errorMessage } = statePage;
        const data = {
            qrsName: qrsName,
            qrsDesc: qrsDesc,
            qrsCode: qrsCode,
            qrsStatus: qrsStatus,
            qrsFormat: qrsFormat,
            qrsCpmMpm: qrsCpmMpm,
            countryId: countryId
        };

        const a = CheckAllFields([qrsName, qrsCode, qrsStatus, qrsFormat, qrsCpmMpm, countryId], ["Name", "Code", "Status", "Format", "Mode", "Country"]);

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>NAME</p>
                                <input value={statePage.qrsName} onChange={(e) => handleState('qrsName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Code</p>
                                <input value={statePage.qrsCode} onChange={(e) => handleState('qrsCode', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Country</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCountry}
                                    value={optionsCountry.filter((option: any) => option.value === statePage.countryId)}
                                    onChange={(e) => handleState('countryId', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Mode</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsQrMpmCpm}
                                    value={optionsQrMpmCpm.filter((option: any) => option.value === statePage.qrsCpmMpm)}
                                    onChange={(e) => handleState('qrsCpmMpm', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Format</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsFormat}
                                    value={optionsFormat.filter((option: any) => option.value === statePage.qrsFormat)}
                                    onChange={(e) => handleState('qrsFormat', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STATUS</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode}
                                    value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.qrsStatus)}
                                    onChange={(e) => handleState('qrsStatus', `${e.value}`)}
                                />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.qrsDesc} onChange={(e) => handleState('qrsDesc', e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />

        </>
    )
}


const initialState = {
    titleModal: `New ${Page24Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    qrsId: '',
    qrsName: '',
    qrsDesc: '',
    qrsCode: '',
    qrsStatus: '001', // default enable COM000
    qrsFormat: '', // QRS002
    qrsCpmMpm: '', //QRS001
    countryId: '',
    countryName: '',
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                qrsId: '',
                qrsName: '',
                qrsDesc: '',
                qrsCode: '',
                qrsStatus: '001', // default enable COM000
                qrsFormat: '', // QRS002
                qrsCpmMpm: '', //QRS001
                countryId: '',
                countryName: '',
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                qrsId: action.value.qrsId,
                qrsName: action.value.qrsName,
                qrsDesc: action.value.qrsDesc,
                qrsCode: action.value.qrsCode,
                qrsStatus: action.value.qrsStatus,
                qrsFormat: action.value.qrsFormat,
                qrsCpmMpm: action.value.qrsCpmMpm,
                countryId: action.value.countryId,
                countryName: action.value.countryName,
                errorMessage: "",
            };
        // 
        case 'qrsId':
            return {
                ...state,
                qrsId: action.value,
            };
        case 'qrsName':
            return {
                ...state,
                qrsName: action.value,
            };
        case 'qrsDesc':
            return {
                ...state,
                qrsDesc: action.value,
            };
        case 'qrsCode':
            return {
                ...state,
                qrsCode: action.value,
            };
        case 'qrsStatus':
            return {
                ...state,
                qrsStatus: action.value,
            };
        case 'qrsFormat':
            return {
                ...state,
                qrsFormat: action.value,
            };
        case 'qrsCpmMpm':
            return {
                ...state,
                qrsCpmMpm: action.value,
            };
        case 'countryId':
            return {
                ...state,
                countryId: action.value,
            };
        case 'countryName':
            return {
                ...state,
                countryName: action.value,
            };
        default:
            throw new Error();
    }
}

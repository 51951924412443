import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CapitalizeFirstLetter, CheckAllFields, CheckInputAZaz09Space } from '../../Utils';
import { ModelBatchProgramRegistration } from './ModelBatchProgramRegistration';
import { loadingAction } from '../../Store/Loading';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { FooterModalUpdate2 } from '../../Components/FooterModalUpdate2';
import { Page23Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableBatchProgramRegistration = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const {
        optionsLogLevelDetailCode,
        optionsBatchProgramStatusDetailCode,
        optionsCommonStatusDetailCode,
        optionsBatchProgramTypeDetailCode
    } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["batchProgId", "batchStatus", "batchName", "batchType", "batchProgName", "batchLogLevel", "batchDesc", "batchParameterFormat"]
        let limit = [15, 3, 15, 3, 50, 3, 200, 254]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (nameState === 'batchProgId') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === 'batchName') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === "batchProgName") {
                let errorMessage = {
                    id: '1',
                    errorMessage: 'BATCH PROGRAM NAME must contain only letters (A-Z) or (a-z), space and numbers (0-9)'
                }
                if (CheckInputAZaz09Space.test(valueState.toString())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            }
            else if (valueState.split('').length <= limit[index]) {
                dispatchStatePage({ type: nameState, value: valueState })
            }

        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const {
            batchId,
            batchProgId,
            batchStat,
            batchStatus,
            batchName,
            batchType,
            batchProgName,
            batchReprocYn,
            logLevel,
            batchParallelExecYn,
            batchDesc,
            batchParameterFormat,
            batchConstName
        } = statePage.data
        const data = {
            batchId: id,
            batchProgId: batchProgId,
            batchStatus: batchStatus,
            batchName: batchName,
            batchType: batchType,
            batchProgName: batchProgName,
            batchReprocYn: batchReprocYn,
            batchLogLevel: logLevel,
            batchParallelExecYn: batchParallelExecYn,
            batchDesc: batchDesc,
            batchParameterFormat: batchParameterFormat,
        }
        const a = CheckAllFields([
            batchProgId,
            batchStatus,
            batchName,
            batchType,
            batchProgName,
            batchReprocYn,
            logLevel,
            batchParallelExecYn,
        ], [
            "BATCH PROGRAM ID",
            "STATUS",
            'BATCH NAME',
            'TYPE',
            'BATCH PROGRAM NAME',
            "BATCH REPROCESSING",
            "BATCH LOG LEVEL",
            "BATCH EXECUTION",
        ])
        if (statePage.errorMessageList.length > 0) { }
        else {
            if (a !== "") {
                handleState('errorMessage', a);
            } else {
                handleState('errorMessage', ``);
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    actionAfterUpdateItem(id)
                    actionShowModal()
                }).catch((err) => handleState('errorMessage', err))
            }
        }

    };

    useEffect(() => {
        let resultConvertFormBatchName: String = '';
        if (statePage.data.batchProgId !== null) {
            let resultArray: string[] = statePage.data.batchProgId?.split('_')
            if (resultArray.length > 0) {
                resultArray.forEach((item: string) => {
                    resultConvertFormBatchName = resultConvertFormBatchName + CapitalizeFirstLetter(item);
                })
            }
            handleState('batchConstName', resultConvertFormBatchName)
        }

    }, [statePage.data.batchProgId])

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH PROGRAM ID <span className='text-danger'>*</span></p>
                        <input value={statePage.data.batchProgId} onChange={(e) => handleState('batchProgId', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCommonStatusDetailCode}
                            value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.batchStatus)}
                            onChange={(e) => handleState('batchStatus', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH NAME <span className='text-danger'>*</span></p>
                        <input value={statePage.data.batchName} onChange={(e) => handleState('batchName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>TYPE <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsBatchProgramTypeDetailCode}
                            value={optionsBatchProgramTypeDetailCode.filter((option: any) => option.value === statePage.data.batchType)}
                            onChange={(e) => handleState('batchType', e.value)}
                        />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>BATCH PROGRAM NAME <span className='text-danger'>*</span></p>
                        <input value={statePage.data.batchProgName} onChange={(e) => handleState('batchProgName', e.target.value)} className={inputStyle} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>BATCH REPROCESSING <span className='text-danger'>*</span></p>
                        <BootstrapSwitchButton checked={statePage.data.batchReprocYn} size="sm" onChange={(e) => handleState('batchReprocYn', e)} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>BATCH LOG LEVEL <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsLogLevelDetailCode}
                            value={optionsLogLevelDetailCode.filter((option: any) => option.value === statePage.data.logLevel)}
                            onChange={(e) => handleState('logLevel', e.value)}
                        />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>BATCH EXECUTION <span className='text-danger'>*</span></p>
                        <BootstrapSwitchButton checked={statePage.data.batchParallelExecYn} size="sm" onChange={(e) => handleState('batchParallelExecYn', e)} />
                    </div>
                    <div className='col-6 my-2'>
                        <p className={labelStyle}>PARAMETER FORMAT</p>
                        <input value={statePage.data.batchParameterFormat} onChange={(e) => handleState('batchParameterFormat', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6 my-2'>
                        <p className={labelStyle}>BATCH PARAMETER</p>
                        <input value={statePage.data.batchConstName} disabled className={inputStyleReadOnly} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.data.batchDesc} onChange={(e) => handleState('batchDesc', e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate2 errorMessage={statePage.errorMessage} errorMessageList={statePage.errorMessageList} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page23Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelBatchProgramRegistration({
        id: null,
        batchId: null,
        batchProgId: null,
        batchStat: '',
        batchStatus: '001',
        batchName: null,
        batchType: '001',
        batchProgName: null,
        batchReprocYn: false,
        logLevel: '005',
        batchParallelExecYn: false,
        batchDesc: null,
        batchParameterFormat: null,
        batchConstName: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelBatchProgramRegistration) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        case 'errorMessageList':
            if (action.value.type === "removed") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                            errorMessageList: state.errorMessageList.filter((item: any) => item.id !== action.value.data.id)
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else if (action.value.type === "added") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                        }
                    } else {

                        let data: any[] = [...state.errorMessageList]
                        data.push(action.value.data)
                        return {
                            ...state,
                            errorMessageList: data
                        }
                    }
                } else {
                    let data: any[] = [...state.errorMessageList]
                    data.push(action.value.data)
                    return {
                        ...state,
                        errorMessageList: data
                    }
                }
            } else {
                return {
                    ...state,
                }
            }

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelBatchProgramRegistration({
                    id: null,
                    batchId: null,
                    batchProgId: null,
                    batchStat: '',
                    batchStatus: '001',
                    batchName: null,
                    batchType: '001',
                    batchProgName: null,
                    batchReprocYn: false,
                    logLevel: '005',
                    batchParallelExecYn: false,
                    batchDesc: null,
                    batchParameterFormat: null,
                    batchConstName: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelBatchProgramRegistration({
                    id: action.value.batchId,
                    batchId: action.value.batchId,
                    batchProgId: action.value.batchProgId,
                    batchStat: action.value.batchStat,
                    batchStatus: action.value.batchStatus,
                    batchName: action.value.batchName,
                    batchType: action.value.batchType,
                    batchProgName: action.value.batchProgName,
                    batchReprocYn: action.value.batchReprocYn,
                    logLevel: action.value.logLevel,
                    batchParallelExecYn: action.value.batchParallelExecYn,
                    batchDesc: action.value.batchDesc,
                    batchParameterFormat: action.value.batchParameterFormat,
                    batchConstName: action.value.batchConstName,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





export const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'new_exchange_rate':
            return {
                ...state,
                new_exchange_rate: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'dataRouting':
            return {
                ...state,
                dataRouting: action.value
            };
        case 'dataSubProduct':
            return {
                ...state,
                dataSubProduct: action.value
            };
        case 'dataProductFeature':
            return {
                ...state,
                dataProductFeature: action.value
            };
        case 'routeIdOptions':
            return {
                ...state,
                routeIdOptions: action.value
            };
        case 'subProductOptions':
            return {
                ...state,
                subProductOptions: action.value
            };
        case 'productFeatureOptions':
            return {
                ...state,
                productFeatureOptions: action.value
            };
        case 'optionsSpf':
            return {
                ...state,
                optionsSpf: action.value
            };
        case 'optionsCommonStatus':
            return {
                ...state,
                optionsCommonStatus: action.value
            };
        case 'optionsMember':
            return {
                ...state,
                optionsMember: action.value
            };
        case 'optionsRouting':
            return {
                ...state,
                optionsRouting: action.value
            };
        case 'optionsQrMpmCpm':
            return {
                ...state,
                optionsQrMpmCpm: action.value
            };
        case 'optionsPrivilege':
            return {
                ...state,
                optionsPrivilege: action.value
            };
        case 'optionsUserStatus':
            return {
                ...state,
                optionsUserStatus: action.value
            };
        case 'optionsUserLevel':
            return {
                ...state,
                optionsUserLevel: action.value
            };
        case 'optionsUserCategory':
            return {
                ...state,
                optionsUserCategory: action.value
            };
        case
            'optionsCurrency':
            return {
                ...state,
                optionsCurrency: action.value
            };
        case
            'optionsReasonCode':
            return {
                ...state,
                optionsReasonCode: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'selectedMember':
            return {
                ...state,
                selectedMember: action.value
            };
        case 'selectedUserLevel':
            return {
                ...state,
                selectedUserLevel: action.value
            };
        case 'selectedUserCategory':
            return {
                ...state,
                selectedUserCategory: action.value
            };
        case 'selectedRateFrom':
            return {
                ...state,
                selectedRateFrom: action.value
            };
        case 'selectedRateTo':
            return {
                ...state,
                selectedRateTo: action.value
            };

        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
            };
        case 'editData':
            return {
                ...state,
            };
        case 'deleteData':
            return {
                ...state,
            };
        case 'searchData':
            return {
                ...state,
                data: action.value,
                position: 15,
            };
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        case 'listReasonCode':
            return {
                ...state,
                listReasonCode: action.value
            };
        
        case 'qrAuthDivided':
            return {
                ...state,
                qrAuthDivided: action.value
            };
        case 'filterStartDate':
            return {
                ...state,
                filterStartDate: action.value
            };
        case 'filterEndDate':
            return {
                ...state,
                filterEndDate: action.value
            };


        case 'selectedQRStandardId':
            return {
                ...state,
                selectedQRStandardId: action.value
            };
        case 'selectedQRStandardName':
            return {
                ...state,
                selectedQRStandardName: action.value
            };
        case 'selectedQRStandardSendToApi':
            return {
                ...state,
                selectedQRStandardSendToApi: action.value
            };
        case 'selectedProductFeatureId':
            return {
                ...state,
                selectedProductFeatureId: action.value
            };
        case 'selectedProductFeatureLabel':
            return {
                ...state,
                selectedProductFeatureLabel: action.value
            };
        case 'selectedProductFeatureSendToApi':
            return {
                ...state,
                selectedProductFeatureSendToApi: action.value
            };
        default:
            throw new Error();
    }
}
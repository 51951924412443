import { checklistButtonIcon, closeButtonIcon } from "../Assets/Images/svg";
import { ErrorMessage } from "./ErrorMessage"
import { ErrorMessage2 } from "./ErrorMessage2";

export const FooterModalCreate3 = ({ ...props }) => {
    const { errorMessage, errorMessageList, setModal, handlePostAPI, handlePostDataReRun, handleKillJob, batchStatuss } = props;
    return (
        <>
            <ErrorMessage2 errorMessage={errorMessage} errorMessageList={errorMessageList} />
            <div className='d-flex justify-content-between'>
                <button className='d-flex align-items-center br-20 bd-d4 bg-ff pointer px-3 py-3' onClick={setModal}>
                    {closeButtonIcon}
                    <p className='s-1418 c-7f Lato-600 mx-2'>CANCEL</p>
                </button>
                <div className='d-flex align-items-center'>
                    <button
                        className={`d-flex align-items-center br-20 bd-d4 pointer px-3 py-3 ${
                            props.batchStatuss !== '002' ? 'bg-c1' : 'bg-9f'
                        }`}
                        onClick={handleKillJob}
                        style={{ marginRight: '20px' }}
                        disabled={props.batchStatuss !== '002'}
                    >
                        <svg style={{ width: "10px", height: "10px" }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L5 3.82149L8.57741 0.244078C8.90285 -0.0813592 9.43049 -0.0813592 9.75592 0.244078C10.0814 0.569515 10.0814 1.09715 9.75592 1.42259L6.17851 5L9.75592 8.57741C10.0814 8.90285 10.0814 9.43049 9.75592 9.75592C9.43049 10.0814 8.90285 10.0814 8.57741 9.75592L5 6.17851L1.42259 9.75592C1.09715 10.0814 0.569515 10.0814 0.244078 9.75592C-0.0813592 9.43049 -0.0813592 8.90285 0.244078 8.57741L3.82149 5L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z"
                                fill="#FFFFFF"
                                />
                            </svg>
                        <p className='s-1418 c-ff Lato-600 mx-2'>KILL JOB</p>
                    </button>
                    <button
                        className={`d-flex align-items-center br-20 bd-d4 pointer px-3 py-3 ${
                            props.batchStatuss == '005' ? 'bg-c1' : 'bg-649'
                        }`}
                        onClick={handlePostDataReRun}  
                        style={{marginRight:'20px'}}
                        disabled={props.batchStatuss == '005'}
                    >
                        <svg style={{ width: "20px", height: "20px" }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                fill="#FFFFFF" // Set the fill color to white
                            />
                        </svg>
                        <p className='s-1418 c-ff Lato-600 mx-2'>Re-Run</p>
                    </button>
                    <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-3' onClick={handlePostAPI}>
                        {checklistButtonIcon}
                        <p className='s-1418 c-ff Lato-600 mx-2'>SAVE</p>
                    </button>
                </div>
            </div>
        </>
    )
}
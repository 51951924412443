import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelInterfaceHeader } from './ModelInterfaceHeader';
import { Page31Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalInterfaceHeader = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsElementType, optionsFieldMappingDetailCode } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { headerFieldName, headerFieldDesc, headerFieldLength, headerFieldType, headerFieldMappingCode, errorMessage } = statePage.data
        const data = {
            // headerFieldId: id,
            headerFieldName: headerFieldName,
            headerFieldDesc: headerFieldDesc,
            headerFieldLength: parseInt(headerFieldLength),
            headerFieldType: headerFieldType,
            headerFieldMappingCode: headerFieldMappingCode,
        }
        const a = CheckAllFields([headerFieldName, headerFieldDesc, headerFieldLength, headerFieldType, headerFieldMappingCode], ['Name', 'Description', 'Length', 'Type', 'Mapping Code'])

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>NAME</p>
                                <input value={statePage.data.headerFieldName} onChange={(e) => handleState('headerFieldName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Mapping Code</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsElementType} value={optionsElementType.filter((option: any) => option.value === statePage.data.headerFieldType)} onChange={(e) => handleState('headerFieldType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>length</p>
                                <input type='number' min={0} value={statePage.data.headerFieldLength} onChange={(e) => handleState('headerFieldLength', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Mapping Code</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFieldMappingDetailCode} value={optionsFieldMappingDetailCode.filter((option: any) => option.value === statePage.data.headerFieldMappingCode)} onChange={(e) => handleState('headerFieldMappingCode', e.value)} />
                            </div>
                            <div className="col-12">
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.data.headerFieldDesc} onChange={(e) => handleState("headerFieldDesc", e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page31Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInterfaceHeader({
        id: null,
        headerFieldId: null,
        headerFieldName: null,
        headerFieldDesc: null,
        headerFieldLength: null,
        headerFieldType: null,
        headerFieldMappingCode: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceHeader) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceHeader({
                    id: null,
                    headerFieldId: null,
                    headerFieldName: null,
                    headerFieldDesc: null,
                    headerFieldLength: null,
                    headerFieldType: null,
                    headerFieldMappingCode: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceHeader({
                    id: action.value.headerFieldId,
                    headerFieldId: action.value.headerFieldId,
                    headerFieldName: action.value.headerFieldName,
                    headerFieldDesc: action.value.headerFieldDesc,
                    headerFieldLength: action.value.headerFieldLength,
                    headerFieldType: action.value.headerFieldType,
                    headerFieldMappingCode: action.value.headerFieldMappingCode,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}


interface ModelConnectionDownTime {
    id: number | null;
    cdtId: number | null;
    memberConnId: number | null;
    cdtStart: string | null;
    cdtStop: string | null;
    cdtStatus: string | null;
    memberConnServer: string | null;
    memberConnPort: string | null;
    memberConnEndpoint: string | null;
}

export class ModelModelConnectionDownTime  {
    id: number | null;
    cdtId: number | null;
    memberConnId: number | null;
    cdtStart: string | null;
    cdtStop: string | null;
    cdtStatus: string | null;
    memberConnServer: string | null;
    memberConnPort: string | null;
    memberConnEndpoint: string | null;

    constructor(value: ModelConnectionDownTime) {
        this.id = value.id;
        this.cdtId= value?.cdtId ?? null;
        this.memberConnId= value?.memberConnId ?? null;
        this.cdtStart= value?.cdtStart ?? null;
        this.cdtStop= value?.cdtStop ?? null;
        this.cdtStatus= value?.cdtStatus ?? null;
        this.memberConnServer= value?.memberConnServer ?? null;
        this.memberConnPort= value?.memberConnPort ?? null;
        this.memberConnEndpoint= value?.memberConnEndpoint ?? null;
    }
}
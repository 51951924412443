import Cookies from 'universal-cookie';
import axios from "axios";
import { cookiesGlobal } from "../State/Cookies";
import { doubleAction } from "../Store/Error";
import { loadingAction } from "../Store/Loading";
import { logoutAction } from "../Store/User";
import { store } from '../Store/Store';
import { successDoubleAction } from "../Store/Sucesss";
export enum urlSelection {
    dashboard,
    log,
    batch,
}

interface optionAPI {
    useToken: Boolean | null,
    pathCustom: string,
    bodyCustom: any | null,
    selectUrl: urlSelection,
    needLoading?: Boolean,
    tableTitle?: String,
    needNotification?: Boolean,
    messageErrorInData?: Boolean
}

export class ApiComponent {
    private _urlDashboard: any = '';
    private _urlLog: any = '';
    private _urlBatch: any = '';
    private _urlSocketIO: any = '';

    constructor() {
        this._urlDashboard = process.env.REACT_APP_BASE_URL_DASHBOARD;
        this._urlLog = process.env.REACT_APP_BASE_URL_LOG;
        this._urlBatch = process.env.REACT_APP_BASE_URL_BATCH;
        this._urlSocketIO = process.env.REACT_APP_BASE_URL_SOCKET_IO;
    }

    public get urlDashboard() {
        return this._urlDashboard;
    }
    public get urlLog(): string {
        return this._urlLog;
    }
    public get urlBatch(): string {
        return this._urlBatch;
    }

    public get urlSocketIO(): string {
        return this._urlSocketIO;
    }
}


const instanceAxios = axios.create({ timeout: 20000 }); // set timeout
export class API {
    static async get(value: optionAPI) {
        // const [cookies, setCookie, removeCookie] = useCookies(['_udxtrn', '_pdxstr', '_userxxxx']);

        let componentAPI: ApiComponent = new ApiComponent()
        let selectUrl: string = '';
        if (value.selectUrl === urlSelection.dashboard) {
            selectUrl = componentAPI.urlDashboard
        } else if (value.selectUrl === urlSelection.log) {
            selectUrl = componentAPI.urlLog
        } else if (value.selectUrl === urlSelection.batch) {
            selectUrl = componentAPI.urlBatch
        }
        let headerCustom: any =
            value.useToken === true ?
                { headers: { Authorization: `Bearer ${store.getState().user.token}` } }
                : null;
        // let headerCustom: any =
        //     value.useToken === true ?
        //         { headers: { Authorization: `Bearer null` } }
        //         : null;
        if (value.useToken === true) {
            if (store.getState().user.token !== null) {
                try {
                    if (value.needLoading !== undefined) {
                        if (value.needLoading === true) {
                            store.dispatch(loadingAction(true));
                        }
                    }
                    const response = await instanceAxios.get(`${selectUrl}${value.pathCustom}`, headerCustom);
                    const { data } = response;
                    return data;
                } catch (error: any) {
                    if (axios.isCancel(error)) {
                        if (value.needNotification === undefined) {
                            store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                        } else {
                            if (value.needNotification === true) {
                                store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                            }
                        }
                        throw error.message;
                    } else if (error.code === 'ECONNABORTED') {
                        if (value.needNotification === undefined) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        } else {
                            if (value.needNotification === true) {
                                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                            }
                        }
                        throw error.message;
                    } else {
                        if (value.needNotification === undefined) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        } else {
                            if (value.needNotification === true) {
                                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                            }
                        }
                        const { status } = error.response;
                        if ([401, 403].includes(status)) {
                            try {
                                // cookiesGlobal.remove('_userxxxx', { path: '/',  });
                                // cookiesGlobal.remove('_passxxxx', { path: '/',  });
                                localStorage.removeItem('_userxxxx')
                                localStorage.removeItem('_passxxxx')
                                store.dispatch(logoutAction(null));
                            } catch (error) {
                                console.log(error)
                            }
                        }
                        throw error.message;
                    }

                } finally {
                    if (value.needLoading !== undefined) {
                        if (value.needLoading === true) {
                            store.dispatch(loadingAction(false));
                        }
                    }
                }
            }
        } else {
            try {
                if (value.needLoading !== undefined) {
                    if (value.needLoading === true) {
                        store.dispatch(loadingAction(true));
                    }
                }
                const response = await instanceAxios.get(`${selectUrl}${value.pathCustom}`, headerCustom);
                const { data } = response;
                return data;
            } catch (error: any) {
                if (axios.isCancel(error)) {
                    if (value.needNotification === undefined) {
                        store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                    } else {
                        if (value.needNotification === true) {
                            store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                        }
                    }
                    throw error.message;
                } else if (error.code === 'ECONNABORTED') {
                    if (value.needNotification === undefined) {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                    } else {
                        if (value.needNotification === true) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        }
                    }
                    throw error.message;
                } else {
                    if (value.needNotification === undefined) {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                    } else {
                        if (value.needNotification === true) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        }
                    }
                    const { status } = error.response;
                    if ([401, 403].includes(status)) {
                        try {
                            // cookiesGlobal.remove('_userxxxx', { path: '/',  });
                            // cookiesGlobal.remove('_passxxxx', { path: '/',  });
                            localStorage.removeItem('_userxxxx')
                            localStorage.removeItem('_passxxxx')
                            store.dispatch(logoutAction(null));
                        } catch (error) {
                            console.log(error)
                        }

                    }
                    throw error.message;
                }

            } finally {

                if (value.needLoading === true) {
                    store.dispatch(loadingAction(false));
                }
            }
        }
    }

    static async getFile(value: optionAPI) {
        let componentAPI: ApiComponent = new ApiComponent()
        let selectUrl: string = '';
        if (value.selectUrl === urlSelection.dashboard) {
            selectUrl = componentAPI.urlDashboard
        } else if (value.selectUrl === urlSelection.log) {
            selectUrl = componentAPI.urlLog
        } else if (value.selectUrl === urlSelection.batch) {
            selectUrl = componentAPI.urlBatch
        }
        let headerCustom: any = {
            headers: { Authorization: `Bearer ${store.getState().user.token}` },
            responseType: "arraybuffer",
        }

        if (value.useToken === true) {
            if (store.getState().user.token !== null) {
                try {
                    if (value.needLoading !== undefined) {
                        if (value.needLoading === true) {
                            store.dispatch(loadingAction(true));
                        }
                    }
                    const response = await instanceAxios.get(`${selectUrl}${value.pathCustom}`, headerCustom);
                    return response;
                } catch (error: any) {
                    if (axios.isCancel(error)) {
                        if (value.needNotification === undefined) {
                            store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                        } else {
                            if (value.needNotification === true) {
                                store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                            }
                        }
                        throw error.message;
                    } else if (error.code === 'ECONNABORTED') {
                        if (value.needNotification === undefined) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        } else {
                            if (value.needNotification === true) {
                                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                            }
                        }
                        throw error.message;
                    } else {
                        if (value.needNotification === undefined) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        } else {
                            if (value.needNotification === true) {
                                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                            }
                        }
                        const { status } = error.response;
                        if ([401, 403].includes(status)) {
                            try {
                                // cookiesGlobal.remove('_userxxxx', { path: '/',  });
                                // cookiesGlobal.remove('_passxxxx', { path: '/',  });
                                localStorage.removeItem('_userxxxx')
                                localStorage.removeItem('_passxxxx')
                                store.dispatch(logoutAction(null));
                            } catch (error) {
                                console.log(error)
                            }
                        }
                        throw error.message;
                    }

                } finally {
                    if (value.needLoading !== undefined) {
                        if (value.needLoading === true) {
                            store.dispatch(loadingAction(false));
                        }
                    }
                }
            }
        } else {
            try {
                if (value.needLoading !== undefined) {
                    if (value.needLoading === true) {
                        store.dispatch(loadingAction(true));
                    }
                }
                const response = await instanceAxios.get(`${selectUrl}${value.pathCustom}`, headerCustom);
                const { data } = response;
                return data;
            } catch (error: any) {
                if (axios.isCancel(error)) {
                    if (value.needNotification === undefined) {
                        store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                    } else {
                        if (value.needNotification === true) {
                            store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                        }
                    }
                    throw error.message;
                } else if (error.code === 'ECONNABORTED') {
                    if (value.needNotification === undefined) {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                    } else {
                        if (value.needNotification === true) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        }
                    }
                    throw error.message;
                } else {
                    if (value.needNotification === undefined) {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                    } else {
                        if (value.needNotification === true) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                        }
                    }
                    const { status } = error.response;
                    if ([401, 403].includes(status)) {
                        try {
                            // cookiesGlobal.remove('_userxxxx', { path: '/',  });
                            // cookiesGlobal.remove('_passxxxx', { path: '/',  });
                            localStorage.removeItem('_userxxxx')
                            localStorage.removeItem('_passxxxx')
                            store.dispatch(logoutAction(null));
                        } catch (error) {
                            console.log(error)
                        }

                    }
                    throw error.message;
                }

            } finally {

                if (value.needLoading === true) {
                    store.dispatch(loadingAction(false));
                }
            }
        }
    }

    static async post(value: optionAPI) {
        let componentAPI: ApiComponent = new ApiComponent()
        let selectUrl: string = '';
        if (value.selectUrl === urlSelection.dashboard) {
            selectUrl = componentAPI.urlDashboard
        } else if (value.selectUrl === urlSelection.log) {
            selectUrl = componentAPI.urlLog
        } else if (value.selectUrl === urlSelection.batch) {
            selectUrl = componentAPI.urlBatch
        }
        let headerCustom: any =
            value.useToken === true ?
                { headers: { Authorization: `Bearer ${store.getState().user.token}` } }
                : null;
        if (value.useToken === true) {
            if (store.getState().user.token !== null) {
                try {
                    if (value.needNotification === undefined) {
                        if (value.tableTitle !== undefined) {
                            store.dispatch(successDoubleAction({ condition: true, message: `Table: ${value.tableTitle}\n` })); // request cancel by user
                        } else {
                            store.dispatch(successDoubleAction({ condition: true, message: `` })); // request cancel by user
                        }
                        // store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                    } else {
                        if (value.needNotification === true) {
                            if (value.tableTitle !== undefined) {
                                store.dispatch(successDoubleAction({ condition: true, message: `Table: ${value.tableTitle}\n` })); // request cancel by user
                            } else {
                                store.dispatch(successDoubleAction({ condition: true, message: `` })); // request cancel by user
                            }
                        }
                    }
                    if (value.needLoading === undefined) {
                        store.dispatch(loadingAction(true));
                    } else {
                        if (value.needLoading === true) {
                            store.dispatch(loadingAction(true));
                        }
                    }
                    const response = await instanceAxios.post(`${selectUrl}${value.pathCustom}`, value.bodyCustom, headerCustom);
                    const { data } = response;
                    return data;
                } catch (error: any) {
                    if (axios.isCancel(error)) {
                        store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                        throw error.message;
                    } else if (error.code === 'ECONNABORTED') {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request is timeout
                        throw error.message;
                    } else {
                        if (value.messageErrorInData !== undefined) {
                            if (value.messageErrorInData === true) {
                                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.message !== undefined ? error.response.data.message : ''}` })); // error from server
                                throw error.response.data.message !== undefined ? error.response.data.message : '';
                            }
                        } else {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // error from server
                            throw error.response.data.info !== undefined ? error.response.data.info : '';
                        }
                    }
                } finally {
                    if (value.needLoading === undefined) {
                        store.dispatch(loadingAction(false));
                    } else {
                        if (value.needLoading === true) {
                            store.dispatch(loadingAction(false));
                        }
                    }
                }
            }
        } else {
            try {
                if (value.needLoading === undefined) {
                    store.dispatch(loadingAction(true));
                } else {
                    if (value.needLoading === true) {
                        store.dispatch(loadingAction(true));
                    }
                }
                const response = await instanceAxios.post(`${selectUrl}${value.pathCustom}`, value.bodyCustom, headerCustom);
                const { data } = response;
                return data;
            } catch (error: any) {
                if (axios.isCancel(error)) {
                    store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                    throw error.message;
                } else if (error.code === 'ECONNABORTED') {
                    store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request is timeout
                    throw error.message;
                } else {
                    if (value.messageErrorInData !== undefined) {
                        if (value.messageErrorInData === true) {
                            store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.message !== undefined ? error.response.data.message : ''}` })); // error from server
                            throw error.response.data.message !== undefined ? error.response.data.message : '';
                        }
                    } else {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // error from server
                        throw error.response.data.info !== undefined ? error.response.data.info : '';
                    }
                }
            } finally {
                if (value.needLoading === undefined) {
                    store.dispatch(loadingAction(false));
                } else {
                    if (value.needLoading === true) {
                        store.dispatch(loadingAction(false));
                    }
                }
            }
        }


    }

    static async postFile(value: optionAPI) {
        let componentAPI: ApiComponent = new ApiComponent()
        let selectUrl: string = '';
        if (value.selectUrl === urlSelection.dashboard) {
            selectUrl = componentAPI.urlDashboard
        } else if (value.selectUrl === urlSelection.log) {
            selectUrl = componentAPI.urlLog
        } else if (value.selectUrl === urlSelection.batch) {
            selectUrl = componentAPI.urlBatch
        }
        let headerCustom: any = {
            headers: {
                'Authorization': `Bearer ${store.getState().user.token}`,
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            if (value.needLoading === undefined) {
                store.dispatch(loadingAction(true));
            } else {
                if (value.needLoading === true) {
                    store.dispatch(loadingAction(true));
                }
            }
            const { data } = await instanceAxios.post(`${selectUrl}${value.pathCustom}`, value.bodyCustom, headerCustom);
            return data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                throw error.message;
            } else if (error.code === 'ECONNABORTED') {
                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request is timeout
                throw error.message;
            } else {
                if (value.messageErrorInData !== undefined) {
                    if (value.messageErrorInData === true) {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.message !== undefined ? error.response.data.message : ''}` })); // error from server
                    }
                } else {
                    store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // error from server
                }
                throw error.response.data.info !== undefined ? error.response.data.info : '';
            }
        } finally {
            if (value.needLoading === undefined) {
                store.dispatch(loadingAction(false));
            } else {
                if (value.needLoading === true) {
                    store.dispatch(loadingAction(false));
                }
            }
        }
    }
    static async putFile(value: optionAPI) {
        let componentAPI: ApiComponent = new ApiComponent()
        let selectUrl: string = '';
        if (value.selectUrl === urlSelection.dashboard) {
            selectUrl = componentAPI.urlDashboard
        } else if (value.selectUrl === urlSelection.log) {
            selectUrl = componentAPI.urlLog
        } else if (value.selectUrl === urlSelection.batch) {
            selectUrl = componentAPI.urlBatch
        }
        let headerCustom: any = {
            headers: {
                'Authorization': `Bearer ${store.getState().user.token}`,
                'Content-Type': 'multipart/form-data'
            }
        }
        try {
            if (value.needLoading === undefined) {
                store.dispatch(loadingAction(true));
            } else {
                if (value.needLoading === true) {
                    store.dispatch(loadingAction(true));
                }
            }
            const { data } = await instanceAxios.put(`${selectUrl}${value.pathCustom}`, value.bodyCustom, headerCustom);
            return data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                throw error.message;
            } else if (error.code === 'ECONNABORTED') {
                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request is timeout
                throw error.message;
            } else {
                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // error from server
                throw error.response.data.info !== undefined ? error.response.data.info : '';
            }
        } finally {
            if (value.needLoading === undefined) {
                store.dispatch(loadingAction(false));
            } else {
                if (value.needLoading === true) {
                    store.dispatch(loadingAction(false));
                }
            }
        }
    }

    static async update(value: optionAPI) {

        let componentAPI: ApiComponent = new ApiComponent()
        let selectUrl: string = '';
        if (value.selectUrl === urlSelection.dashboard) {
            selectUrl = componentAPI.urlDashboard
        } else if (value.selectUrl === urlSelection.log) {
            selectUrl = componentAPI.urlLog
        } else if (value.selectUrl === urlSelection.batch) {
            selectUrl = componentAPI.urlBatch
        }
        let headerCustom: any =
            value.useToken === true ?
                { headers: { Authorization: `Bearer ${store.getState().user.token}` } }
                : null;
        try {
            store.dispatch(loadingAction(true));
            const { data } = await instanceAxios.put(`${selectUrl}${value.pathCustom}`, value.bodyCustom, headerCustom);
            if (value.needNotification === undefined) {
                if (value.tableTitle !== undefined) {
                    store.dispatch(successDoubleAction({ condition: true, message: `Table: ${value.tableTitle}\n` })); // request cancel by user
                } else {
                    store.dispatch(successDoubleAction({ condition: true, message: `` })); // request cancel by user
                }
                // store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
            } else {
                if (value.needNotification === true) {
                    if (value.tableTitle !== undefined) {
                        store.dispatch(successDoubleAction({ condition: true, message: `Table: ${value.tableTitle}\n` })); // request cancel by user
                    } else {
                        store.dispatch(successDoubleAction({ condition: true, message: `` })); // request cancel by user
                    }
                }
            }
            // if (value.tableTitle !== undefined) {
            //     store.dispatch(successDoubleAction({ condition: true, message: `Table: ${value.tableTitle}\n` })); // request cancel by user
            // } else {
            //     store.dispatch(successDoubleAction({ condition: true, message: `` })); // request cancel by user
            // }
            return data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                if (value.needNotification === undefined) {
                    store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                } else {
                    if (value.needNotification === true) {
                        store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                    }
                }
                throw error.message;
            } else if (error.code === 'ECONNABORTED') {
                if (value.needNotification === undefined) {
                    store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                } else {
                    if (value.needNotification === true) {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                    }
                }
                throw error.message;
            } else {
                if (value.needNotification === undefined) {
                    store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                } else {
                    if (value.needNotification === true) {
                        store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request cancel by user
                    }
                }
                const { data } = error.response;
                // const {} = error;
                // console.log(error)
                if (data.info !== undefined) throw data.info;
                else throw error.message;
            }
        } finally {
            store.dispatch(loadingAction(false));
        }
    }

    static async delete(value: optionAPI) {
        let componentAPI: ApiComponent = new ApiComponent()
        let selectUrl: string = '';
        if (value.selectUrl === urlSelection.dashboard) {
            selectUrl = componentAPI.urlDashboard
        } else if (value.selectUrl === urlSelection.log) {
            selectUrl = componentAPI.urlLog
        } else if (value.selectUrl === urlSelection.batch) {
            selectUrl = componentAPI.urlBatch
        }
        let headerCustom: any =
            value.useToken === true ?
                { headers: { Authorization: `Bearer ${store.getState().user.token}` } }
                : null;
        try {
            if (value.needNotification === undefined) {
                if (value.tableTitle !== undefined) {
                    store.dispatch(successDoubleAction({ condition: true, message: `Table: ${value.tableTitle}\n` })); // request cancel by user
                } else {
                    store.dispatch(successDoubleAction({ condition: true, message: `` })); // request cancel by user
                }
                // store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
            } else {
                if (value.needNotification === true) {
                    if (value.tableTitle !== undefined) {
                        store.dispatch(successDoubleAction({ condition: true, message: `Table: ${value.tableTitle}\n` })); // request cancel by user
                    } else {
                        store.dispatch(successDoubleAction({ condition: true, message: `` })); // request cancel by user
                    }
                }
            }
            if (value.needLoading === undefined) {
                store.dispatch(loadingAction(true));
            } else {
                if (value.needLoading === true) {
                    store.dispatch(loadingAction(true));
                }
            }
            const { data } = await instanceAxios.delete(`${selectUrl}${value.pathCustom}`, headerCustom);
            return data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                store.dispatch(doubleAction({ condition: true, message: `` })); // request cancel by user
                throw error.message;
            } else if (error.code === 'ECONNABORTED') {
                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // request is timeout
                throw error.message;
            } else {
                store.dispatch(doubleAction({ condition: true, message: ` ${error.response.data.info !== undefined ? error.response.data.info : ''}` })); // error from server
                const { data, status } = error.response;
                // if ([401, 403].includes(status)) {
                //     store.dispatch(logoutAction(null));
                //     cookiesGlobal.remove('_userxxxx')
                //     cookiesGlobal.remove('_passxxxx')
                // }
                // if (data.info !== undefined) throw data.info;
                // else throw error.message;
                throw error.message
            }
        } finally {
            if (value.needLoading === undefined) {
                store.dispatch(loadingAction(false));
            } else {
                if (value.needLoading === true) {
                    store.dispatch(loadingAction(false));
                }
            }
        }
    }
}


import { interfaceIcon } from "../../../Assets/Images/MenuIcon";
import { Page1SystemNetworkRoute, Page2SystemNetworkRoute, Page3SystemNetworkRoute, Page5JobSchedulerRoute } from "../../../Routes";
import { Page48Code, Page49Code, Page50Code, Page53Code } from "../CodeMenu";
import { Page48Title, Page49Title, Page50Title, Page53Title } from "../TitleMenu";

export const Menu14 = {
    id: 'system_network_sidebar',
    label: 'System/Network',
    icon: interfaceIcon,
    privilegesId: [Page48Code, Page49Code, Page50Code, Page50Code],
    itemSidebar: [
        {
            titleList: Page48Title,
            key: 'SystemNetworkOptions1',
            privilegeId: Page48Code,
            url: Page1SystemNetworkRoute,
        },
        {
            titleList: Page49Title,
            key: 'SystemNetworkOptions2',
            privilegeId: Page49Code,
            url: Page2SystemNetworkRoute,
        },
        {
            titleList: Page50Title,
            key: 'SystemNetworkOptions3',
            privilegeId: Page50Code,
            url: Page3SystemNetworkRoute,
        },
        // {
        //     titleList: 'New Page',
        //     key: 'SystemNetworkOptions4',
        //     privilegeId: Page50Code,
        //     url: '/testing-page',
        // },
    ],
}
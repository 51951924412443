import { API, urlSelection } from "../Services/API";

import { SelectOptionsInterface } from "../Interface";
import { colors } from "../Utils";

export const initFunction = (dispatch: any, state: any, callback: Function, selectedProductSendToApi: any, getOptions: SelectOptionsInterface | null, dispatchGlobal: any) => {
    if (getOptions?.spfOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `spf`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => dispatch({ type: 'optionsSpf', value: response?.data }))
    }
    if (getOptions?.memberOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `member`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions = response?.data.map((element: any | {}) => {
                return { value: element.memberId, label: `${element.memberCode} - ${element.memberName}`, title: `${element.memberName}`, id: element.memberCode };
            });

            dispatch({ type: 'optionsMember', value: newOptions })

            //memberRoleType
            let newOptionsMemberRoleType: any = [];
            response?.data.map((element: any | {}) => {
                newOptionsMemberRoleType.push({
                    value: `${element.memberCode} - ALL`,
                    label: `${element.memberName} - ALL`,
                    title: `${element.memberName}`,
                    id: element.memberCode,

                })
                const roleTypeLabels: any = {
                    "001": "Acquirer",
                    "002": "Issuer",
                    "005": "Issuer Switcher",
                    "006": "Acquirer Switcher",
                    "007": "Switcher"
                };
                element?.memberRoleDtos?.map((element2: any | {}) => {
                    const roleLabel = roleTypeLabels[element2.memberRoleType] || "ALL";
                    newOptionsMemberRoleType.push({
                        value: `${element.memberCode} - ${element2.memberRoleType}`,
                        label: `${element.memberName} - ${roleLabel}`,
                        title: `${element.memberName}`,
                        id: element.memberCode,
                        roleTypeId: element2.memberRoleType,
                        roleTypeLabel: roleLabel

                    })
                })
            });
            // value: element.memberId,
            // label: `${element.memberCode} - ${element.memberName}`, 
            // title: `${element.memberName}`, 
            // id: element.memberCode, 
            // roleTypeId: element2.memberRoleType
            const newFirstElementMemberRoleType = {
                value: '',
                label: 'All'
                // value: element.memberId,
                // label: `${element.memberCode} - ${element.memberName}`, 
                // title: `${element.memberName}`, 
                // id: element.memberCode, 
                // roleTypeId: element2.memberRoleType 
            };
            const newArrayMemberRoleType = [newFirstElementMemberRoleType].concat(newOptionsMemberRoleType);
            dispatch({ type: 'OptionsMemberRoleType', value: newOptionsMemberRoleType })


            let newOptionsMemberCode = response?.data.map((element: any | {}) => {
                return { value: element.memberCode, label: `${element.memberCode} - ${element.memberName}`, title: `${element.memberName}`, id: element.memberId };
            });
            let newOptionsMemberCode2 = response?.data.map((element: any | {}) => {
                return { value: element.memberCode, label: `${element.memberName}`, title: `${element.memberName}`, id: element.memberId };
                return { value: element.memberCode, label: `${element.memberName}`, title: `${element.memberName}`, id: element.memberId };
            });
            let newFirstElementMemberCode = { value: '', label: 'All', title: '' };
            let newArrayMemberCode = [newFirstElementMemberCode].concat(newOptionsMemberCode);
            let newArrayMemberCode2 = [newFirstElementMemberCode].concat(newOptionsMemberCode2);
            dispatch({ type: 'optionsMemberCode', value: newArrayMemberCode })
            dispatch({ type: 'optionsMemberCode2', value: newOptionsMemberCode })
            dispatch({ type: 'optionsMemberCode3', value: newArrayMemberCode2 })


            let newOptionsMemberId = response?.data.map((element: any | {}) => {
                return { value: element.memberId, label: `${element.memberId} - ${element.memberName}` };
            });
            let newFirstElementMemberId = { value: '', label: 'Search', title: '' };
            let newArrayMemberId = [newFirstElementMemberId].concat(newOptionsMemberId);
            dispatch({ type: 'optionsMemberId', value: newArrayMemberId })


            let newOptionsSwitcher: any[] = [];
            let newOptionsIssuer: any[] = [];
            let newOptionsIssuersSwitcher: any[] = [];
            let newOptionsAcquirer: any[] = [];
            let newOptionsAcquirersSwitcher: any[] = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element1: any | {}) => {
                    if (element1.memberRoleType === '004') {
                        // newOptionsSwitcher.push({ value: element1.memberId, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                        newOptionsSwitcher.push({ value: element1.memberCode, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                    } else if (element1.memberRoleType === '002') {
                        // newOptionsIssuer.push({ value: element1.memberId, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                        newOptionsIssuer.push({ value: element1.memberCode, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                    } else if (element1.memberRoleType === '001') {
                        // newOptionsAcquirer.push({ value: element1.memberId, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                        newOptionsAcquirer.push({ value: element1.memberCode, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                    } else if (element1.memberRoleType === '005') {
                        // newOptionsAcquirer.push({ value: element1.memberId, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                        newOptionsIssuersSwitcher.push({ value: element1.memberCode, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                    } else if (element1.memberRoleType === '006') {
                        // newOptionsAcquirer.push({ value: element1.memberId, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                        newOptionsAcquirersSwitcher.push({ value: element1.memberCode, label: `${element1.memberCode} - ${element1.memberName}`, title: `${element1.memberName}`, id: element1.memberCode });
                    }
                })
            }
            let newFirstElement = { value: '', label: 'Search', title: '' };

            let newArrayOptionsSwitcher = [newFirstElement].concat(newOptionsSwitcher);
            let newArrayOptionsIssuer = [newFirstElement].concat(newOptionsIssuer);
            let newArrayOptionsIssuerSwitcher = [newFirstElement].concat(newOptionsIssuersSwitcher);
            let newArrayOptionsAcquirer = [newFirstElement].concat(newOptionsAcquirer);
            let newArrayOptionsAcquirerSwitcher = [newFirstElement].concat(newOptionsAcquirersSwitcher);
            // By InstitutionType
            let newOptionsSwitcherInstitutionType: any[] = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element1: any | {}) => {
                    if (element1.memberInstitutionType === '003') {
                        newOptionsSwitcherInstitutionType.push({ value: element1.memberId, label: `${element1.memberId} - ${element1.memberName}`, title: `${element1.memberName}` });
                    }
                })
            }
            let newArrayOptionsAcquirerSwitcherInstitutionType = [newFirstElement].concat(newOptionsSwitcherInstitutionType);


            dispatch({ type: 'optionsSwitcher', value: newArrayOptionsSwitcher })
            dispatch({ type: 'optionsIssuer', value: newOptionsIssuer })
            dispatch({ type: 'optionsIssuerSwitcher', value: newArrayOptionsIssuerSwitcher })
            dispatch({ type: 'optionsAcquirer', value: newOptionsAcquirer })
            dispatch({ type: 'optionsAcquirerSwitcher', value: newArrayOptionsAcquirerSwitcher })
            dispatch({ type: 'optionsSwitcherInstitutionType', value: newArrayOptionsAcquirerSwitcherInstitutionType })
        })
    }
    if (getOptions?.ccgOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `ccg`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            response?.data.forEach((element: any | {}) => {
                if (element.ccgStatus === '001') newOptions.push({ value: element.ccgCode, label: `${element.ccgCode}-${element.ccgName}` });
            });
            dispatch({ type: 'optionsGroupCode', value: newOptions })
            let newArray2: any[] = [];
            response?.data.forEach((element: any | {}) => {
                newArray2.push(element.ccgCode);
            });
            dispatch({ type: 'optionsGroupCodeOnly', value: newArray2 })
        })
    }


    if (getOptions?.commonCodeOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `ccd`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response: any) => {

            let dataOptions: any[] = [];
            let dataOptions2: any[] = [];
            let dataOptions3: any[] = [];
            let dataOptions4: any[] = [];
            let dataOptions5: any[] = [];


            response?.data.forEach((element1: any | {}) => {
                if (element1.ccgCode === 'COM000')
                    dataOptions.push({ label: `${element1.ccdDesc}`, value: `${element1.ccdCode}` })
            })
            response?.data.forEach((element2: any | {}) => {
                if (element2.ccgCode === 'COM700')
                    dataOptions2.push({ label: `${element2.ccdDesc}`, value: `${element2.ccdCode}` })
            })
            response?.data.forEach((element3: any | {}) => {
                if (element3.ccgCode === 'COM701')
                    dataOptions3.push({ label: `${element3.ccdDesc}`, value: `${element3.ccdCode}` })
            })
            response?.data.forEach((element3: any | {}) => {
                if (element3.ccgCode === 'COM702')
                    dataOptions4.push({ label: `${element3.ccdDesc}`, value: `${element3.ccdCode}` })
            })
            response?.data.forEach((element3: any | {}) => {
                if (element3.ccgCode === 'COM703')
                    dataOptions5.push({ label: `${element3.ccdDesc}`, value: `${element3.ccdCode}` })
            })
            const newFirstElement = { label: 'All', value: '' }
            const newArray = [newFirstElement].concat(dataOptions)
            const newArray2 = [newFirstElement].concat(dataOptions3)
            const newArray3 = [newFirstElement].concat(dataOptions4)
            const newArray4 = [newFirstElement].concat(dataOptions5)


            dispatch({ type: 'optionsCommonCode', value: newArray })
            dispatch({ type: 'optionsCommonCode700', value: dataOptions2 })
            dispatch({ type: 'optionsCommonCode701', value: newArray2 })
            dispatch({ type: 'optionsCommonCode702', value: newArray3 })
            dispatch({ type: 'optionsCommonCode703', value: newArray4 })
        })
    }
    if (getOptions?.ccdOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `ccd`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            // without all
            // used ccdName as value && ccdDesc as label
            let optionsCode = [
                { name: 'optionsCommonStatus', codeGroup: 'COM000' },
                { name: 'optionsElementType', codeGroup: 'COM001' },
                { name: 'optionsElementFormat', codeGroup: 'COM002' },
                { name: 'optionsHashType', codeGroup: 'COM003' },
                { name: 'optionsEnvironmentType', codeGroup: 'COM004' },
                { name: 'optionsInstitutionType', codeGroup: 'COM005' }, // used 3 digit
                { name: 'optionsRoleType', codeGroup: 'COM006' }, // used 3 digit
                { name: 'optionsQrMpmCpm', codeGroup: 'QRS001' },
                { name: 'optionsFormat', codeGroup: 'QRS002' },
                { name: 'optionsUserStatus', codeGroup: 'USR1000' },
                { name: 'optionsUserLevel', codeGroup: 'USR1001' },
                { name: 'optionsUserCategory', codeGroup: 'USR1002' },
                { name: 'optionsServerStatus', codeGroup: 'AUT2002' },
                { name: 'optionsConnectionStatus', codeGroup: 'AUT2003' },
            ]
            // DetailCode && without added option all
            // used ccdCode as value && ccdDesc as label && sortNumber as sortNumber
            let optionsCodeUseDetailCode = [
                { name: 'optionsCommonStatusDetailCode', codeGroup: 'COM000' },
                { name: 'optionsElementTypeDetailCode', codeGroup: 'COM001' },
                { name: 'optionsElementFormatDetailCode', codeGroup: 'COM002' },
                { name: 'optionsFieldMappingDetailCode', codeGroup: 'COM101' },
                { name: 'optionsSwitcherCountry', codeGroup: 'COM009' },

                { name: 'optionsFeeCalculationMethod', codeGroup: 'ADM3000' },
                { name: 'optionsRcGroup', codeGroup: 'AUT2004' },
                { name: 'optionsRcGroup', codeGroup: 'AUT2004' },
                { name: 'optionsIssuerInstitutionIDDetailCode', codeGroup: 'COM200' },
                { name: 'optionsAcquirerInstitutionIDDetailCode', codeGroup: 'COM201' },
                { name: 'optionsSwitcherInstitutionIDDetailCode', codeGroup: 'COM202' },
                { name: 'optionsPaymentStatusDetailCode', codeGroup: 'COM501' },
                { name: 'optionsFeeRateInputTypeDetailCode', codeGroup: 'ADM3001' },
                { name: 'optionsDisputeCaseStatusDetailCode', codeGroup: 'DIS001' },
                { name: 'optionsChargebackReasonDetailCode', codeGroup: 'DIS003' },
                { name: 'optionsRePresentmentReasonDetailCode', codeGroup: 'DIS004' },
                { name: 'optionsSAFStatusDetailCode', codeGroup: 'AUT2001' },
                { name: 'optionsServerStatusDetailCode', codeGroup: 'AUT2002' },
                { name: 'optionsLogLevelProgramClassificationDetailCode', codeGroup: 'COM300' },
                { name: 'optionsLogLevelDetailCode', codeGroup: 'COM301' },
                { name: 'optionsUserCategoryDetailCode', codeGroup: 'USR1002' },
                { name: 'optionsUserPrivilegeLevel', codeGroup: 'USR103' },
                //
                { name: 'optionsInterfaceFileFormatDetailCode', codeGroup: 'COM102' }, //Interface File Format
                { name: 'optionsFileStringFormatDetailCode', codeGroup: 'COM103' }, //File String Format
                { name: 'optionsFileStringSeparatorDetailCode', codeGroup: 'COM104' }, //File String Separator
                { name: 'optionsFileRecordOccurrenceDetailCode', codeGroup: 'COM108' }, //File Record Occurrence

                { name: 'optionsFileDataTypeDetailCode', codeGroup: 'COM105' }, // File Data Type
                { name: 'optionsFileDataPaddingDetailCode', codeGroup: 'COM106' }, // File Data Padding
                { name: 'optionsFileDataPaddingOptionDetailCode', codeGroup: 'COM107' }, // File Data Padding Option

                { name: 'optionsBatchProgramStatusDetailCode', codeGroup: 'BAT000' },
                { name: 'optionsBatchProgramTypeDetailCode', codeGroup: 'BAT002' },

                { name: 'optionsUserStatus', codeGroup: 'USR1000' },
                { name: 'optionsUserStatusDetailCode', codeGroup: 'USR1000' },
                { name: 'optionsPaymentConfirmationMethodDetailCode', codeGroup: 'COM502' },
                { name: 'optionsBatchRegistrationTypeDetailCode', codeGroup: 'BAT003' },
                { name: 'optionsBatchSchedulerStatusDetailCode', codeGroup: 'BAT004' },
                { name: 'optionsBatchExecutionTypeDetailCode', codeGroup: 'BAT005' },
                // { name: 'options  DetailCode', codeGroup: '' }, 
                { name: 'optionsConnectionStatusDetailCode', codeGroup: 'AUT2003' },

                { name: 'optionsTransactionTypeDetailCodeAndCcdDesc', codeGroup: 'AUT100' },
            ]
            // without added option all
            // used ccdCode as value && ccdName as label && sortNumber as sortNumber
            let optionsCodeUseDetailCodeAndCcdName = [
                { name: 'optionsBusinessGroupNameDetailCodeAndCcdName', codeGroup: 'COM605' },
                { name: 'optionsTransactionTypeDetailCodeAndCcdName', codeGroup: 'AUT100' },
                { name: 'optionsReconciliationStatusDetailCodeAndCcdName', codeGroup: 'CNS004' },
            ]

            // without added option all
            // used ccdExt1Val as value && ccdName as label && ccdCode as ccdCode && ccdSortNumber as ccdSortNumber 
            let optionsCodeUseExtAndDetail = [
                { name: 'optionsIssuerInstitutionIDUseUseExtAndDetail', codeGroup: 'COM200' },
                { name: 'optionsAcquirerInstitutionIDUseUseExtAndDetail', codeGroup: 'COM201' },
                { name: 'optionsSwitcherInstitutionIDUseExtAndDetail', codeGroup: 'COM202' },
            ]

            // with option all
            // used ccdExt1Val as value && ccdDesc as label && ccdCode as ccdCode && ccdSortNumber as ccdSortNumber 
            let optionsCodeUseExtAndDetailDesc = [
                { name: 'optionsWalletInstitutionIDUseExtAndDetail', codeGroup: 'COM203' },
            ]
            // added option all
            // used ccdCode as value && ccdName as label && sortNumber as sortNumber 
            let optionsCodeUseCCDNameAndCodeDetail = [
                { name: 'optionsIssuerInstitutionIDUseCCDNameAndCodeDetail', codeGroup: 'COM200' },
                { name: 'optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail', codeGroup: 'COM201' },
                { name: 'optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail', codeGroup: 'COM202' },
                { name: 'optionsReconciliationStatusUseCCDNameAndCodeDetail', codeGroup: 'CNS004' },
                { name: 'optionsBatchSchedulerStatusUseCCDNameAndCodeDetail', codeGroup: 'BAT004' },
                //
                { name: 'optionsBusinessGroupNameUseCCDNameAndCodeDetail', codeGroup: 'COM605' },
                { name: 'optionsBatchExecutionTypeUseCCDNameAndCodeDetail', codeGroup: 'BAT005' },
            ]
            // without added option all
            // used ccdName as value && ccdCode and ccdName  as label && ccdCode as ccdCode && sortNumber as sortNumber 
            let optionsCodeUsedCCDNameAndCCDCode = [
                { name: 'optionsInterfaceFileRecordFormatUsedCCDNameAndCCDCode', codeGroup: 'COM109' },
            ]

            let optionsCodeUseDetailCodeAndExt1 = [
                { name: 'optionsInstitutionCode200', codeGroup: 'COM200' },
                { name: 'optionsInstitutionCode201', codeGroup: 'COM201' },
                { name: 'optionsInstitutionCode202', codeGroup: 'COM202' },
                { name: 'optionsInstitutionCode203', codeGroup: 'COM203' },
            ]

            // added option all
            // used ccdName as value && ccdName as label
            let optionsCodeUseCcdName = [
                { name: 'optionsCurrencyCcdName', codeGroup: 'COM007' },
            ]

            //option settlement
            let optionsAcquireDate = [
                { name: 'Settlement Date', value: 'acquirerSettleDate' },
                { name: 'Central Processing Date', value: 'centralProcDate' }
            ]
            //
            let optionsIssuerDate = [
                { name: 'Settlement  Date', value: 'issuerSettleDate' },
                { name: 'Central Processing Date', value: 'centralProcDate' }
            ]

            optionsCode.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ccdName
                        dataNew.push({ value: element2.ccdName, label: element2.ccdDesc });
                    }
                });
                dispatch({ type: `${element1.name}`, value: dataNew })
            });
            optionsCodeUseDetailCode.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ccdCode
                        dataNew.push({ value: element2.ccdCode, label: element2.ccdDesc, sortNumber: element2.ccdSortNumber });
                    }
                });
                dispatch({ type: `${element1.name}`, value: dataNew })
            });

            optionsCodeUseDetailCodeAndCcdName.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ccdCode
                        dataNew.push({ value: element2.ccdCode, label: element2.ccdName, sortNumber: element2.ccdSortNumber });
                    }
                });
                dispatch({ type: `${element1.name}`, value: dataNew })
            });
            let number = 0;
            optionsCodeUseExtAndDetail.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any, index: number) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ext and detail
                        dataNew.push({ value: element2.ccdExt1Val, label: element2.ccdName, ccdCode: element2.ccdCode, sortNumber: element2.ccdSortNumber, color: colors[number] });
                        number = number + 5
                    }
                });
                dispatch({ type: `${element1.name}`, value: dataNew })
            });

            optionsCodeUseExtAndDetailDesc.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any, index: number) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ext and detail desc
                        dataNew.push({ value: element2.ccdExt1Val, label: element2.ccdDesc, ccdCode: element2.ccdCode, sortNumber: element2.ccdSortNumber, color: colors[number] });
                        number = number + 5
                    }
                });
                const newFirstElement = { value: '', label: 'All' };
                const newArray = [newFirstElement].concat(dataNew);
                dispatch({ type: `${element1.name}`, value: newArray })
            });

            optionsCodeUseCCDNameAndCodeDetail.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ext and detail
                        dataNew.push({ value: element2.ccdCode, label: element2.ccdName, sortNumber: element2.ccdSortNumber, value2: element2.ccdExt1Val.substring(0, 3) });
                    }
                });
                const newFirstElement = { value: '', label: 'All' };
                const newArray = [newFirstElement].concat(dataNew);
                dispatch({ type: `${element1.name}`, value: newArray })
            });
            optionsCodeUsedCCDNameAndCCDCode.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ext and detail
                        dataNew.push({ value: element2.ccdName, label: `${element2.ccdCode} - ${element2.ccdName}`, ccdCode: element2.ccdCode, sortNumber: element2.ccdSortNumber });
                    }
                });
                dispatch({ type: `${element1.name}`, value: dataNew })
            });
            dispatch({ type: 'optionsCCD', value: response?.data })

            // with desc and ext 1 val
            optionsCodeUseDetailCodeAndExt1.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ccdCode
                        dataNew.push({ value: element2.ccdCode, label: `${element2.ccdExt1Val} - ${element2.ccdName}`, id: element2.ccdExt1Val, desc: element2.ccdDesc, name: element2.ccdName });
                    }
                });
                dispatch({ type: `${element1.name}`, value: dataNew })
            });
            optionsCodeUseCcdName.forEach((element1: any) => {
                let dataNew: any[] = [];
                response?.data.forEach((element2: any) => {
                    if (element1.codeGroup === element2.ccgCode) {
                        // ext and detail
                        dataNew.push({ value: element2.ccdName, label: element2.ccdName, sortNumber: element2.ccdSortNumber });
                    }
                });
                const newFirstElement = { value: '', label: 'All' };
                const newArray = [newFirstElement].concat(dataNew);
                dispatch({ type: `${element1.name}`, value: newArray })
            });
        })
    }
    if (getOptions?.logLevel001Options) {
        API.get({
            bodyCustom: null,
            pathCustom: `log_level/pcc/001`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            response?.data.forEach((element: any) => {
                newOptions.push({ value: element.programId, label: element.programName });
            });
            const newFirstElement = { value: '', label: 'Search' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsService', value: newArray })
        })

    }
    if (getOptions?.logLevel002Options) {
        API.get({
            bodyCustom: null,
            pathCustom: `log_level/pcc/002`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            response?.data.forEach((element: any) => {
                newOptions.push({ value: element.programId, label: element.programName });
            });
            const newFirstElement = { value: '', label: 'Search' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsBatch', value: newArray })
        })
    }
    if (getOptions?.batchOptionsFromAPIBatch) {
        API.get({
            bodyCustom: null,
            pathCustom: `batch`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            let newOptions2: any[] = [];
            response?.data.forEach((element: any) => {
                newOptions.push({ value: element.batchId, label: `${element.batchId} - ${element.batchName}` });
                newOptions2.push({ value: element.batchProgId, label: `${element.batchProgId}` });
            });
            const newFirstElement = {
                value: '',
                label: `All`,
                title: `All`,
                sendToApi: ''
            };
            const newArray = [newFirstElement].concat(newOptions2);
            dispatch({ type: 'optionsBatchFromAPIBatch', value: newOptions })
            dispatch({ type: 'optionsBatchFromAPIBatch2', value: newArray })
        })
    }
    if (getOptions?.routingOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `routing`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            dispatch({ type: 'optionsRouting', value: response?.data })
        })
    }
    if (getOptions?.routeSequenceOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `route_sequence`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.rsId, label: `${element.rsName}` });
                });
            }
            dispatch({ type: 'optionsRouteSequence', value: newOptions })
        })
    }
    if (getOptions?.privilegeOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `privilege`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.privilegeId, label: element.privilegeName, level: element.ccdCode });
                });
            }
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsPrivilege', value: newArray })
        })
    }
    if (getOptions?.countryOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `country`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            let newOptionsCountry: any[] = [];
            let newOptionsCountryCodeAndCurrencyCode: any[] = [];
            let newOptionsCountryCodeCurrencyCode: any[] = [];
            if (response?.data !== undefined) {
                if (response?.data.length > 0) {
                    response?.data.forEach((element: any) => {
                        if (element.currencyCode !== null) {
                            newOptions.push({ value: element.countryId, label: element.currencyCode });
                        }
                        newOptionsCountry.push({ value: element.countryId, label: `${element.countryId} - ${element.countryName}` });
                        newOptionsCountryCodeAndCurrencyCode.push({ value: element.countryCode, label: `${element.countryCode} - ${element.countryName}`, currencyCode: element.currencyCode });
                        newOptionsCountryCodeCurrencyCode.push({ value: element.countryCode, label: `${element.countryCode} - ${element.currencyCode}`, currencyCode: element.currencyCode });
                    });
                }
            }

            // currency
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsCurrency', value: newArray })
            // country
            const newFirstElement2 = { value: '', label: 'All' };
            const newArray2 = [newFirstElement2].concat(newOptionsCountry);
            dispatch({ type: 'optionsCountry', value: newArray2 })
            // country code and currency code
            const newFirstElement3 = { value: '', label: 'All' };
            dispatch({ type: 'optionsCountryCodeAndCurrencyCode', value: newOptionsCountryCodeAndCurrencyCode })
            dispatch({ type: 'optionsCountryCodeCurrencyCode', value: newOptionsCountryCodeCurrencyCode })
        })
    }
    if (getOptions?.productOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `product`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response: any) => {
            let newOptions: any[] = [];
            let newOptions2: any[] = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element1: any) => {
                    newOptions.push(
                        {
                            value: element1.productId,
                            label: `${element1.productId} - ${element1.productName}`,
                            title: `${element1.productId} - ${element1.productName}`,
                            sendToApi: element1.productName,
                        }
                    )
                    newOptions2.push(
                        {
                            value: element1.productName,
                            label: `${element1.productId} - ${element1.productName}`,
                            title: `${element1.productId} - ${element1.productName}`,
                            sendToApi: element1.productName,
                        }
                    )
                });
            }
            const newFirstElement = {
                value: '',
                label: `All`,
                title: `All`,
                sendToApi: ''
            };
            const newArray = [newFirstElement].concat(newOptions);
            const newArray2 = [newFirstElement].concat(newOptions2);
            dispatch({ type: 'optionsProduct', value: newArray })
            dispatch({ type: 'optionsProductNameAsValue', value: newArray2 })
        })
    }
    if (getOptions?.productFeatureOptions) {
        let customParameter = selectedProductSendToApi === null ? '' : `productId=${selectedProductSendToApi}`;
        API.get({
            bodyCustom: null,
            pathCustom: `product_feature?${customParameter}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response: any) => {
            let newOptions: any[] = [];
            let newOptions2: any[] = [];
            let newOptionsOnlyProdFeatureInd: any[] = [];
            let newOptionsprodInd: any[] = [];

            if (response?.data.length > 0) {
                response?.data.forEach((element1: any) => {
                    newOptions.push(
                        {
                            value: element1.prodFeatureId,
                            label: `${element1.prodFeatureId} - ${element1.prodFeatureName}`,
                            title: `${element1.productId} - ${element1.productName}`,
                            sendToApi: element1.prodFeatureInd,
                        }
                    )
                    newOptions2.push(
                        {
                            value: element1.prodFeatureInd,
                            label: `${element1.prodFeatureInd} - ${element1.prodFeatureName}`,
                            title: `${element1.productId} - ${element1.productName}`,
                            sendToApi: element1.prodFeatureInd,
                        }
                    )
                    newOptionsOnlyProdFeatureInd.push(
                        {
                            value: element1.prodFeatureInd,
                            label: `${element1.prodFeatureInd} - ${element1.prodFeatureName}`,
                        }
                    )
                    if (element1.prodFeatureInd === 'QR000' || element1.prodFeatureInd === 'QR001' || element1.prodFeatureInd === 'QR009')
                        newOptionsprodInd.push(
                            {
                                label: `${element1.prodFeatureInd}-${element1.prodFeatureName}`,
                                value: `${element1.prodFeatureInd}`,
                                title: `${element1.productId} - ${element1.productName}`,
                                sendToApi: element1.prodFeatureInd,
                            }
                        )
                });
            }

            const newFirstElement = {
                value: '',
                label: `All`,
                title: `All`,
                sendToApi: ''
            };
            const newArray = [newFirstElement].concat([...newOptions]);
            const newArray2 = [newFirstElement].concat([...newOptionsOnlyProdFeatureInd]);
            const newArray3 = [newFirstElement].concat([...newOptions2]);
            const newArray4 = [newFirstElement].concat([...newOptionsprodInd])
            dispatch({ type: 'optionsProductFeature', value: newOptions })
            dispatch({ type: 'optionsProductFeatureWithAll', value: newArray })
            dispatch({ type: 'optionsProductFeatureWithAllOnlyProdFeatureInd', value: newArray2 })
            dispatch({ type: 'optionsProductFeatureProdFeatureIndAsValue', value: newArray3 })
            dispatch({ type: 'optionsProductFeatureProdFeatureIndAsValue2', value: newOptions2 })
            dispatch({ type: 'optionsProductInd', value: newArray4 })
        })
    }

    if (getOptions?.reasonCodeOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `reason_code`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response: any) => {
            let dataOptions: any[] = [];
            response?.data.forEach((element1: any) => {
                dataOptions.push({ label: `${element1.rcCode} - ${element1.rcName} - ${element1.rcDesc}`, label2: `${element1.rcDesc}`, title: `${element1.rcName}`, value: `${element1.rcCode}`, sendToApi: `${element1.rcCode}`, sendToApi2: `${element1.rcId}` });
            });

            let dataOptions2: any[] = [];
            response?.data.forEach((element1: any) => {
                dataOptions2.push({ label: `${element1.rcCode} - ${element1.rcName} - ${element1.rcDesc}`, label2: `${element1.rcDesc}`, title: `${element1.rcName}`, value: `${element1.rcId}`, sendToApi: `${element1.rcId}` });
            });

            const newFirstElement = { label: `All`, title: `All`, value: ``, sendToApi: `` };
            const newArray = [newFirstElement].concat(dataOptions);
            dispatch({ type: 'optionsReasonCode', value: newArray })

            const newFirstElement2 = { label: `All`, title: `All`, value: ``, sendToApi: `` };
            const newArray2 = [newFirstElement2].concat(dataOptions2);
            dispatch({ type: 'optionsReasonCodeUseId', value: newArray2 })
        })
    }

    if (getOptions?.subProductOptions) {
        API.get({
            bodyCustom: null,

            pathCustom: `sub_product`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response: any) => {
            let newOptions: any[] = [];
            response?.data.forEach((element: any) => {
                if (element.currencyCode !== null) {
                    newOptions.push({ value: element.subProdId, label: `${element.subProdId} - ${element.subProdName}` });
                }
            });
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsSubProduct', value: newArray })

        })
    }
    if (getOptions?.exchangeRateOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `exchange_rate`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response) => {
            let newOptions: any[] = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.rateId, label: `${element.rateId} - ${element.rateSrcName}` });
                });
            }
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsRateSetting', value: newArray })
        })
    }
    if (getOptions?.elementOptions) {
        API.get({
            bodyCustom: null,

            pathCustom: `element`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response) => {
            let newOptions: any[] = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.elementId, label: `${element.elementName} - ${element.elementDesc}` });
                });
            }
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsElement', value: newArray })
        })

    }
    if (getOptions?.qrStandardOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `qr_standard`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response) => {
            let newOptions: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.qrsId, label: `${element.qrsId} - ${element.qrsName}` });
                });
            }
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsQRStandard', value: newArray })
        })

    }

    if (getOptions?.interfaceFieldOptions) {
        API.get({
            bodyCustom: null,

            pathCustom: `interface_field`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response) => {
            let newOptions: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.interfaceFieldId, label: `${element.interfaceFieldId} - ${element.interfaceFieldName}` });
                });
            }
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsInterfaceField', value: newArray })
        })
    }
    if (getOptions?.headerFieldOptions) {

        API.get({
            bodyCustom: null,

            pathCustom: `header_field`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,

        }).then((response) => {
            let newOptions: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.headerFieldId, label: `${element.headerFieldId} - ${element.headerFieldName}` });
                });
            }
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsHeaderField', value: newArray })
        })
    }
    if (getOptions?.resultOptions) {
        let dataOptions: any = [{ value: 'true', label: 'Valid' }, { value: 'false', label: 'Not Valid' }];
        const newFirstElement = { label: 'All', value: '' };
        const newArray = [newFirstElement].concat(dataOptions);
        dispatch({ type: 'optionsResult', value: newArray })
    }
    if (getOptions?.feeOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `fee`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response) => {
            let newOptions: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.feeId, label: `${element.feeId} - ${element.feeType}` });
                });
            }
            const newFirstElement = { value: '', label: 'All' };
            const newArray = [newFirstElement].concat(newOptions);
            dispatch({ type: 'optionsFee', value: newArray })
        })
    }
    if (getOptions?.interfaceFileOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `interface_file`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response) => {
            let newOptions: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.interfaceFileId, label: `${element.interfaceFileId} - ${element.interfaceFileName}` });
                });
            }
            dispatch({ type: 'optionsInterfaceFile', value: newOptions })
        })
    }
    if (getOptions?.recordOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `interface_record`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response) => {
            let newOptions: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.recordId, label: `${element.recordId} - ${element.recordName}` });
                });
            }
            dispatch({ type: 'optionsInterfaceRecord', value: newOptions })
        })
    }
    if (getOptions?.interfaceFileItemOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `interface_file_item`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response) => {
            let newOptions: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    newOptions.push({ value: element.interfaceFileItemId, label: `${element.interfaceFileItemId} - ${element.interfileItemName}` });
                });
            }
            dispatch({ type: 'optionsInterfaceFileItem', value: newOptions })
        })
    }
    if (getOptions?.memberPICOptions) {
        API.get({
            bodyCustom: null,
            pathCustom: `member_pic`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false,
            needLoading: false,
        }).then((response) => {
            let newOptionsPayment: any = [];
            let newOptionsDispute: any = [];
            if (response?.data.length > 0) {
                response?.data.forEach((element: any) => {
                    if (element.memberPicCode === "001") {
                        newOptionsPayment.push({ value: element.userId, label: `${element.userId} - ${element.memberId}` });
                    }
                    else if (element.memberPicCode === "002") {
                        newOptionsDispute.push({ value: element.userId, label: `${element.userId} - ${element.memberId}` });

                    }
                });
            }
            dispatch({ type: 'optionsMemberPICPayment', value: newOptionsPayment })
            dispatch({ type: 'optionsMemberPICDispute', value: newOptionsDispute })
        })
    }
    callback();
}
export const addData = (dispatch: any) => dispatch({ type: 'addData' });
export const editData = (e: any, y: any, dispatch: any) => dispatch({ type: 'editData', value: { dataModal: y, id: e } });
export const deleteData = (e: any, y: any, dispatch: any) => dispatch({ type: 'deleteData', value: { dataModal: y, id: e } });
export const deleteData2 = (e: any, f:any,y: any, dispatch: any) => dispatch({ type: 'deleteData', value: { dataModal: y, svcId: e ,prodInd:f} });
export const setModal = (e: any, dispatch: any, state: any) => dispatch({ type: `${e}`, value: !state[`${e}`], id: '' })
export const checkIndex = (e: any, state: any) => {
    const n = state.toggle.indexOf(e)
    let a = false
    if (n !== -1) {
        a = true
    } else {
        a = false
    }
    return a
}
export const handleState = (nameState: any, valueState: any, dispatch: any) => {
    dispatch({ type: `${nameState}`, value: valueState })
    if (nameState === 'view') {
        dispatch({ type: 'position', value: valueState })
    }
}
export const setToggle = (e: any, dispatch: any, state: any) => {
    const cek = [...state.toggle];
    let selectedToggle = state.label.find((element1: any) => element1.val === e);
    let getWidthColumn = selectedToggle.width.split('-')[1];
    const index = cek.indexOf(e);
    if (index === -1) {
        cek.push(e);
        if (state.widthTable !== undefined) {
            let widthTable = state.widthTable + parseInt(getWidthColumn)
            dispatch({ type: 'widthTable', value: widthTable })
        }
    } else {
        cek.splice(index, 1);
        if (state.widthTable !== undefined) {
            let widthTable = state.widthTable - parseInt(getWidthColumn)
            dispatch({ type: 'widthTable', value: widthTable })
        }
    }
    dispatch({ type: 'toggle', value: cek })
};

export const sortData = (e: any, dispatch: any, state: any) => {
    if (state.data.length > 0) {
        let resultSort = [...state.data.sort((a: any, b: any) => {
            let fa = ''
            if (a[`${e}`] !== null) {
                fa = a[`${e}`].toLowerCase();
            }
            let fb = '';
            if (b[`${e}`] !== null) {
                fb = b[`${e}`].toLowerCase();
            }
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        })];
        if (state.columnActiveAsc === `${e}`) {
            dispatch({ type: 'dataCopy', value: resultSort.reverse() })
            dispatch({ type: 'columnActiveAsc', value: '' })
            dispatch({ type: 'columnActiveDsc', value: `${e}` })
        } else {
            dispatch({ type: 'dataCopy', value: resultSort })
            dispatch({ type: 'columnActiveAsc', value: `${e}` })
            dispatch({ type: 'columnActiveDsc', value: `` })
        }
    }
}

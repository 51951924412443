import {
  Page10Title,
  Page11Title,
  Page12Title,
  Page13Title,
  Page14Title,
  Page20Title,
  Page21Title,
  Page23Title,
  Page25Title,
  Page26Title,
  Page27Title,
  Page29Title,
  Page30Title,
  Page32Title,
  Page33Title,
  Page34Title,
  Page35Title,
  Page36Title,
  Page37Title,
  Page38Title,
  Page39Title,
  Page40Title,
  Page41Title,
  Page42Title,
  Page43Title,
  Page44Title,
  Page45Title,
  Page46Title,
  Page47Title,
  Page48Title,
  Page49Title,
  Page51Title,
  Page52Title,
  Page53Title,
  Page54Title,
  Page57Title,
  Page58Title,
  Page59Title,
  PageQRAuthStatisticTitle,
  Page57ATitle,
  PageMainDashboardTitle,
  PageFileTransferMonitoringTitle,
} from "./Menu/TitleMenu";

export const listPrivilegePageSelected = JSON.stringify([
  {
    id: 50,
    label: "SAF Control Parameter",
    val: "selectedSafControlParameter",
    selectedListPrivilege: [],
  },
  {
    id: 60,
    label: Page10Title,
    val: "selectedUserManagement",
    selectedListPrivilege: [],
  },
  {
    id: 70,
    label: `${Page52Title}`,
    val: "selectedMemberManagement",
    selectedListPrivilege: [],
  },
  {
    id: 80,
    label: `${Page51Title}`,
    val: "selectedMemberHMACManagement",
    selectedListPrivilege: [],
  },
  {
    id: 90,
    label: Page49Title,
    val: "selectedInstitutionMonitoringStatus",
    selectedListPrivilege: [],
  },
  {
    id: 100,
    label: Page21Title,
    val: "selectedLogLevelManagement",
    selectedListPrivilege: [],
  },
  {
    id: 110,
    label: "Archive Management",
    val: "selectedArchiveManagement",
    selectedListPrivilege: [],
  },
  {
    id: 120,
    label: Page11Title,
    val: "selectedUserLogHistory",
    selectedListPrivilege: [],
  },
  {
    id: 130,
    label: Page13Title,
    val: "selectedProductManagement",
    selectedListPrivilege: [],
  },
  {
    id: 140,
    label: Page14Title,
    val: "selectedProductFeatureManagement",
    selectedListPrivilege: [],
  },
  // {
  //     id: 150,
  //     label: "Sub Product Management",
  //     val: "selectedSubProductManagement",
  //     selectedListPrivilege: [],
  // },
  // {
  //     id: 160,
  //     label: "Sub Product Feature Mapping",
  //     val: "selectedSubProductFeatureMapping",
  //     selectedListPrivilege: [],
  // },
  {
    id: 170,
    label: Page29Title,
    val: "selectedDataElementManagement",
    selectedListPrivilege: [],
  },
  // {
  //     id: 180,
  //     label: "Sub Product Element Mapping",
  //     val: "selectedSubProductElementMapping",
  //     selectedListPrivilege: [],
  // },
  {
    id: 190,
    label: Page30Title,
    val: "selectedInterfaceField",
    selectedListPrivilege: [],
  },
  {
    id: 200,
    label: "Interface Header",
    val: "selectedInterfaceHeader",
    selectedListPrivilege: [],
  },
  // {
  //     id: 210,
  //     label: "Interface Management",
  //     val: "selectedInterfaceManagement",
  //     selectedListPrivilege: [],
  // },
  // {
  //     id: 220,
  //     label: "Interface Header Management",
  //     val: "selectedInterfaceHeaderManagement",
  //     selectedListPrivilege: [],
  // },
  // {
  //     id: 230,
  //     label: "Routing Table Management",
  //     val: "selectedRoutingTableManagement",
  //     selectedListPrivilege: [],
  // },
  {
    id: 240,
    label: Page44Title,
    val: "selectedCommonCodeManagement",
    selectedListPrivilege: [],
  },
  {
    id: 250,
    label: Page45Title,
    val: "selectedReasonCodeManagement",
    selectedListPrivilege: [],
  },
  {
    id: 260,
    label: Page46Title,
    val: "selectedReasonCodeMapping",
    selectedListPrivilege: [],
  },
  {
    id: 270,
    label: Page47Title,
    val: "selectedCountryCodeManagement",
    selectedListPrivilege: [],
  },
  {
    id: 280,
    label: Page12Title,
    val: "selectedPrivilegeCode",
    selectedListPrivilege: [],
  },
  {
    id: 290,
    label: Page27Title,
    val: "selectedQRWhitelist&Blacklist",
    selectedListPrivilege: [],
  },
  {
    id: 300,
    label: "QR Generation",
    val: "selectedQRGeneration",
    selectedListPrivilege: [],
  },
  {
    id: 310,
    label: "Fee rate Setting",
    val: "selectedFeerateSetting",
    selectedListPrivilege: [],
  },
  {
    id: 320,
    label: "Exchange Rate Setting",
    val: "selectedExchangeRateSetting",
    selectedListPrivilege: [],
  },
  {
    id: 330,
    label: Page34Title,
    val: "selectedRoutingManagement",
    selectedListPrivilege: [],
  },
  {
    id: 340,
    label: Page32Title,
    val: "selectedRoutingFieldMapping",
    selectedListPrivilege: [],
  },
  {
    id: 350,
    label: Page33Title,
    val: "selectedRoutingHeaderMapping",
    selectedListPrivilege: [],
  },
  // {
  //     id: 360,
  //     label: "Sub Product Feature Member",
  //     val: "selectedSubProductFeatureMember",
  //     selectedListPrivilege: [],
  // },
  {
    id: 370,
    label: "QR Standard",
    val: "selectedQRStandard",
    selectedListPrivilege: [],
  },
  {
    id: 380,
    label: Page25Title,
    val: "selectedQRStandardElement",
    selectedListPrivilege: [],
  },
  {
    id: 390,
    label: Page26Title,
    val: "selectedQRStandardMember",
    selectedListPrivilege: [],
  },
  {
    id: 400,
    label: Page54Title,
    val: "selectedRouteSequence",
    selectedListPrivilege: [],
  },
  {
    id: 410,
    label: Page14Title,
    val: "selectedProductFeatureMember",
    selectedListPrivilege: [],
  },
  {
    id: 680,
    label: "Settlement Summary",
    val: "selectedSettlementSummary",
    selectedListPrivilege: [],
  },
  {
    id: 420,
    label: "Settlement Summary (Acquirer)",
    val: "selectedSettlementSummary(Acquirer)",
    selectedListPrivilege: [],
  },
  {
    id: 430,
    label: "Settlement Summary (Issuer)",
    val: "selectedSettlementSummary(Issuer)",
    selectedListPrivilege: [],
  },
  {
    id: 440,
    label: "Sales Inquiry",
    val: "selectedSalesInquiry",
    selectedListPrivilege: [],
  },
  {
    id: 450,
    label: "Dispute Resolution",
    val: "selectedDisputeResolution",
    selectedListPrivilege: [],
  },
  {
    id: 460,
    label: "Member Payment",
    val: "selectedMemberPayment",
    selectedListPrivilege: [],
  },
  {
    id: 470,
    label: "Transaction Statistic",
    val: "selectedTransactionMonitoring",
    selectedListPrivilege: [],
  },
  {
    id: 480,
    label: "Transaction Inquiry",
    val: "selectedTransactionMonitoringStatus",
    selectedListPrivilege: [],
  },
  {
    id: 490,
    label: "SAF Statistic",
    val: "selectedReversalTransactionMonitoring",
    selectedListPrivilege: [],
  },
  {
    id: 500,
    label: "SAF Transaction Inquiry",
    val: "selectedReversalMonitoringStatus",
    selectedListPrivilege: [],
  },
  {
    id: 510,
    label: Page48Title,
    val: "selectedCloudWatchMonitoring",
    selectedListPrivilege: [],
  },
  {
    id: 520,
    label: Page20Title,
    val: "selectedLogAnalyzer",
    selectedListPrivilege: [],
  },
  {
    id: 540,
    label: Page40Title,
    val: "selectedBatchProgram",
    selectedListPrivilege: [],
  },
  {
    id: 550,
    label: Page41Title,
    val: "selectedSchedulerManagement",
    selectedListPrivilege: [],
  },
  // {
  //     id: 560,
  //     label: Page42Title,
  //     val: "selectedRunScheduler",
  //     selectedListPrivilege: [],
  // },
  {
    id: 570,
    label: Page43Title,
    val: "selectedSchedulerExecution",
    selectedListPrivilege: [],
  },
  {
    id: 590,
    label: "Exchange Rate Member",
    val: "selectedExchangeRateMember",
    selectedListPrivilege: [],
  },
  {
    id: 600,
    label: Page35Title,
    val: "selectedInterfaceFile",
    selectedListPrivilege: [],
  },
  {
    id: 610,
    label: Page38Title,
    val: "selectedInterfaceRecord",
    selectedListPrivilege: [],
  },
  {
    id: 620,
    label: Page37Title,
    val: "selectedInterfaceFileRecord",
    selectedListPrivilege: [],
  },
  {
    id: 630,
    label: Page36Title,
    val: "selectedInterfaceFileItem",
    selectedListPrivilege: [],
  },
  {
    id: 640,
    label: Page39Title,
    val: "selectedInterfaceRecordItem",
    selectedListPrivilege: [],
  },
  {
    id: 650,
    label: "ServiceRegistration",
    val: "selectedServiceRegistration",
    selectedListPrivilege: [],
  },
  {
    id: 660,
    label: Page23Title,
    val: "selectedBatchProgramRegistration",
    selectedListPrivilege: [],
  },
  {
    id: 700,
    label: Page57Title,
    val: "selectedProductionSimulatorInquiry",
    selectedListPrivilege: [],
  },
  {
    id: 710,
    label: Page58Title,
    val: "selectedQRAuthInquiry",
    selectedListPrivilege: [],
  },
  {
    id: 720,
    label: Page57ATitle,
    val: "selectedQrAuthSimulatorInquiry",
    selectedListPrivilege: [],
  },
  {
    id: 900,
    label: Page59Title,
    val: "selectedAlertParameters",
    selectedListPrivilege: [],
  },
  {
    id: 901,
    label: Page59Title,
    val: "selectedBatchStatusMonitoring",
    selectedListPrivilege: [],
  },
  // {
  //     id: 670,
  //     label: Page53Title,
  //     val: "selectedBatchScheduler",
  //     selectedListPrivilege: [],
  // },
  {
    id: 720,
    label: PageQRAuthStatisticTitle,
    val: "selectedQRAuthStatistic",
    selectedListPrivilege: [],
  },
  {
    id: 980,
    label: "Unmatched List",
    val: "selectedUnmatchedList",
    selectedListPrivilege: [],
  },
  {
    id: 990,
    label: "Adjustment List",
    val: "selectedAdjustmentList",
    selectedListPrivilege: [],
  },
  {
    id: 1000,
    label: PageMainDashboardTitle,
    val: "selectedTransactionMonitoringNew",
    selectedListPrivilege: [],
  },
  {
    id: 1010,
    label: "Alert History",
    val: "selectedAlertHistory",
    selectedListPrivilege: [],
  },
  {
    id: 1020,
    label: PageFileTransferMonitoringTitle,
    val: "selectedFileTransferMonitoring",
    selectedListPrivilege: [],
  },
]);


// import { useSelector } from "react-redux";
// import { CheckUndefined } from "../../Utils";
// import { Divider } from "antd";

// const HeaderComponent = ({ ...props }) => {
//     const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
//     return (
//         <div className='d-flex justify-content-between px-4 '>
//             {
//                 props.label.map((item: any, i: any) => {
//                     if (item.val === 'action') {
//                         if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
//                             return (
//                                 props.checkIndex(`${item.val}`) && (
//                                     <p key={i} className={` s-1418 Lato-700 ${item.width} text-center `}>
//                                         {item.field}
//                                     </p>
//                                 )
//                             );
//                         } else {
//                             return <></>;
//                         }
//                     } else {
//                         return (
//                             props.checkIndex(`${item.val}`) && (
//                                 <p key={i} className={` s-1418 Lato-700 ${item.width} `}>
//                                     {item.field}
//                                 </p>
//                             )
//                         );
//                     }
//                 })
//             }
//         </div>
//     )
// }
// export const TableInterfaceRecordItem = ({ ...props }) => {
//     const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

//     return (
//         <div className='d-flex flex-column w-100 overflow mb-2'>
//             <div className={`d-flex flex-column ${props.widthTable}`}>
//                 <HeaderComponent
//                     codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
//                     codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
//                     label={props.label}
//                     data={props.data}
//                     position={props.position}
//                     view={props.view}
//                     customButtonAction={props.customButtonAction}
//                     checkIndex={props.checkIndex}
//                     widthTable={props.widthTable}
//                     stateOptions={props.stateOptions}
//                 />
//                     <Divider className="my-2"/>

//                 {props.data.map((item: any, index: any) => {
//                     let number = index + ((props.currentPage - 1) * props.view)
//                     return (
//                         <div key={number} className={`d-flex justify-content-between align-items-center min-h-50px px-4  ${number % 2 === 0 ? 'bg-f4' : ''} `}>
//                             {props.label.map((element1: any) => {
//                                 if (element1.val === 'no') {
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{number + 1}</p>;
//                                 } else if (element1.val === 'action') {
//                                     if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
//                                         return props.checkIndex(`${element1.val}`) && <div className={`d-flex  s-1418 Lato-400 word-wrap ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
//                                     } else {
//                                         return <></>;
//                                     }
//                                 }
//                                 else if (element1.val === "interfaceRecordId") {
//                                     let dataReturn = "";
//                                     if (props.stateOptions.optionsInterfaceRecord.length > 0) {
//                                         props.stateOptions.optionsInterfaceRecord.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;

//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 }
//                                 else if (element1.val === "interfaceFileItemId") {
//                                     let dataReturn = "";
//                                     if (props.stateOptions.optionsInterfaceFileItem.length > 0) {
//                                         props.stateOptions.optionsInterfaceFileItem.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;

//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 }
//                                 else if (element1.val === "recordItemSeparator") {
//                                     let dataReturn = "";
//                                     if (props.stateOptions.optionsFileStringSeparatorDetailCode.length > 0) {
//                                         props.stateOptions.optionsFileStringSeparatorDetailCode.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;

//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 }
//                                 else if (element1.val === "recordItemNameYn") {
//                                     let dataReturn = "";
//                                     if (props.stateOptions.optionsYorN.length > 0) {
//                                         props.stateOptions.optionsYorN.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;

//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 }
//                                 else {
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
//                                 }
//                             })}
//                         </div>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// }


import {
    MaterialReactTable,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from 'antd';
import { CheckUndefined } from '../../Utils';
import { ButtonAdd, ButtonDownload, SelectView } from '../../Components';



export const TableInterfaceRecordItem = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const interfaceRecordIdOptions = [
        {
            "value": 2,
            "label": "2 - HDRS0001"
        },
        {
            "value": 5,
            "label": "5 - RECS0001"
        },
        {
            "value": 6,
            "label": "6 - RECS0015"
        },
        {
            "value": 7,
            "label": "7 - BTLS0001"
        },
        {
            "value": 10,
            "label": "10 - TRLS0001"
        },
        {
            "value": 12,
            "label": "12 - YOSdummy"
        },
        {
            "value": 13,
            "label": "13 - YOS00003"
        },
        {
            "value": 14,
            "label": "14 - REC9999"
        },
        {
            "value": 17,
            "label": "17 - testing"
        },
        {
            "value": 20,
            "label": "20 - name"
        },
        {
            "value": 21,
            "label": "21 - DUMY"
        },
        {
            "value": 22,
            "label": "22 - Rname"
        },
        {
            "value": 23,
            "label": "23 - RECS0002"
        },
        {
            "value": 24,
            "label": "24 - MERHDR00"
        },
        {
            "value": 25,
            "label": "25 - MERREC00"
        },
        {
            "value": 26,
            "label": "26 - MERTRL00"
        },
        {
            "value": 27,
            "label": "27 - MERREC03"
        },
        {
            "value": 28,
            "label": "28 - RECM0015"
        },
        {
            "value": 29,
            "label": "29 - MERREC01"
        },
        {
            "value": 31,
            "label": "31 - STTL0D15"
        },
        {
            "value": 32,
            "label": "32 - PMT000H1"
        },
        {
            "value": 33,
            "label": "33 - PMT000D1"
        },
        {
            "value": 34,
            "label": "34 - PMT000T1"
        },
        {
            "value": 35,
            "label": "35 - STTL0101"
        },
        {
            "value": 36,
            "label": "36 - STTL0102"
        },
        {
            "value": 37,
            "label": "37 - STTL0103"
        },
        {
            "value": 38,
            "label": "38 - STTL0104"
        },
        {
            "value": 39,
            "label": "39 - tes"
        },
        {
            "value": 44,
            "label": "44 - STTL1001"
        },
        {
            "value": 45,
            "label": "45 - MERC1001"
        },
        {
            "value": 46,
            "label": "46 - MERC2001"
        },
        {
            "value": 47,
            "label": "47 - STTL2001"
        },
        {
            "value": 48,
            "label": "48 - STTL2002"
        },
        {
            "value": 49,
            "label": "49 - STTL2003"
        },
        {
            "value": 50,
            "label": "50 - STTL2004"
        },
        {
            "value": 51,
            "label": "51 - STTL3001"
        },
        {
            "value": 52,
            "label": "52 - STTL3002"
        },
        {
            "value": 53,
            "label": "53 - STTL3003"
        },
        {
            "value": 54,
            "label": "54 - MERC3001"
        },
        {
            "value": 55,
            "label": "55 - MERC3002"
        },
        {
            "value": 56,
            "label": "56 - MERC3003"
        },
        {
            "value": 57,
            "label": "57 - STTL4001"
        },
        {
            "value": 58,
            "label": "58 - STTL4002"
        },
        {
            "value": 59,
            "label": "59 - STTL4003"
        },
        {
            "value": 60,
            "label": "60 - RCON4001"
        },
        {
            "value": 61,
            "label": "61 - RCON4002"
        },
        {
            "value": 62,
            "label": "62 - RCON4003"
        },
        {
            "value": 63,
            "label": "63 - ADJS4001"
        },
        {
            "value": 64,
            "label": "64 - ADJS4002"
        },
        {
            "value": 65,
            "label": "65 - ADJS4003"
        }
    ]
    const recordItemSeparatorOptions = [
        {
            "value": "000",
            "label": "No separator",
            "sortNumber": 1
        },
        {
            "value": "001",
            "label": "Space",
            "sortNumber": 2
        },
        {
            "value": "002",
            "label": "New line",
            "sortNumber": 2
        },
        {
            "value": "003",
            "label": "Tab Separator",
            "sortNumber": 4
        },
        {
            "value": "004",
            "label": "Pipeline",
            "sortNumber": 4
        }
    ]

    const optionsYorN = [
        { value: true, label: 'Y' },
        { value: false, label: 'N' },
    ];

    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateParent,
        resetFilterStatus,
        initFunctionCustom,
        handleNavigation,
        actionShowModal, deleteDataComponent, isProcTime, dispatch, pagination, setPagination
    } = props;
    const { optionsFieldMappingDetailCode } = stateOptions;
    const columns: any = useMemo(
        () => [
            {
                header: 'Interface Record ID',
                accessorKey: 'interfaceRecordId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    // // console.log("test", props.stateOptions.optionsInterfaceRecord)
                    // let dataReturn = row.original.interfaceRecordId;
                    // // console.log("test", props.stateOptions.optionsFileRecordOccurrenceDetailCode)
                    // if ( interfaceRecordIdOptions.length > 0) {
                    //      interfaceRecordIdOptions.forEach((element2: any) => {
                    //         if (`${element2.value}` === `${row.original.interfaceRecordId}`) {
                    //             dataReturn = `${element2.label}`;
                    //         }
                    //     });
                    // }
                    return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(row.original.interfaceRecordId)} - {CheckUndefined(row.original.interfaceRecordName)}</p>;
                },
            },
            {
                header: 'Interface File Item ID',
                accessorKey: 'interfaceFileItemId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    // // console.log("tesst", props.stateOptions.optionsInterfaceFileItem)
                    // let dataReturn = row.original.interfaceFileItemId;
                    // if ( props.stateOptions.optionsInterfaceFileItem.length > 0) {
                    //      props.stateOptions.optionsInterfaceFileItem.forEach((element2: any) => {
                    //         if (`${element2.value}` === `${row.original.interfaceFileItemId}`) {
                    //             dataReturn = `${element2.label}`;
                    //         }
                    //     });
                    // }
                    return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(row.original.interfaceFileItemId)} - {CheckUndefined(row.original.interfaceFileItemName)}</p>;
                },
                // `${CheckUndefined(row.original.srcMemberName)}`,
            },
            {
                header: 'Sort Number',
                accessorKey: 'recordItemSortNumber',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.recordItemSortNumber)}`,
            },
            {
                header: 'Item Separator',
                accessorKey: 'recordItemSeparator',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    let dataReturn = row.original.recordItemSeparator;
                    if (recordItemSeparatorOptions.length > 0) {
                        recordItemSeparatorOptions.forEach((element2: any) => {
                            if (`${element2.value}` === `${row.original.recordItemSeparator}`) {
                                dataReturn = `${element2.label}`;
                            }
                        });
                    }
                    return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(dataReturn)}</p>;
                },
                // `${CheckUndefined(row.original.srcMemberName)}`,
            },
            {
                header: 'Item Name',
                accessorKey: 'recordItemNameYn',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    let dataReturn = row.original.recordItemNameYn;
                    // console.log("test", props.stateOptions.optionsFileStringSeparatorDetailCode)
                    if (optionsYorN.length > 0) {
                        optionsYorN.forEach((element2: any) => {
                            if (`${element2.value}` === `${row.original.recordItemNameYn}`) {
                                dataReturn = `${element2.label}`;
                            }
                        });
                    }
                    return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(dataReturn)}</p>;
                },
            },
            {
                header: 'Item Name Length',
                accessorKey: 'recordItemNameLength',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.recordItemNameLength)}`,
            },
            {
                header: 'M/O/C',
                accessorKey: 'recordItemMoc',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.recordItemMoc)}`,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                size: 'auto',
                Cell: ({ row }: any) => {
                    const item = row.original;
                    // console.log("item",item)
                    if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                        return props.checkIndex('action') && <div className={`d-flex  s-1418 Lato-400 word-wrap`}>{props.customButtonAction(item)} </div>;
                    } else {
                        return <></>;
                    }
                },

            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        onGlobalFilterChange: (filterValue) =>
            handleStateParent('search', filterValue),
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="d-flex justify-content-between align-items-center pt-1">
                <div className="d-flex align-items-center">
                    <p className="Lato-400 s-1822 ">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1  Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
                <Divider type='vertical' className='m-1' />
                <div className="">
                    <ButtonDownload handleClick={props.exportToCSV} codeAccessPrivilege={props.codeAccessPrivilegeDownload} />
                </div>

            </div>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleGlobalFilterButton table={table} />
                <div className="h-30px w-30px">
                    <div className="h-100">
                        <ButtonAdd handleClick={props.addData} codeAccessPrivilege={props.codeAccessPrivilegeAdd} />
                    </div>

                </div>
            </>
        ),
    });
    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 pt-2">
                File Record & File Element
            </h1>
            <div>{props.filterComponentPage()}</div>
            <div id="table" className="mt-2 mb-4 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    );
};

import { dbIcon } from "../../../Assets/Images/svg";
import { Page1UserRoute, Page2UserRoute, Page3UserRoute } from "../../../Routes";
import { Page10Code, Page11Code, Page12Code } from "../CodeMenu";
import { Page10Title, Page11Title, Page12Title } from "../TitleMenu";

export const Menu4 = {
    id: 'user_sidebar',
    label: 'User',
    icon: dbIcon,
    privilegesId: [ Page10Code, Page11Code, Page12Code],
    itemSidebar: [
        {
            titleList: Page10Title,
            key: 'userOptions1',
            privilegeId: Page10Code,
            url: Page1UserRoute,
        },
        {
            titleList: Page11Title,
            key: 'userOptions2',
            privilegeId: Page11Code,
            url: Page2UserRoute,
        },
        {
            titleList: Page12Title,
            key: 'userOptions3',
            privilegeId: Page12Code,
            url: Page3UserRoute,
        },

    ],
}
export const IsDateRangeWithinDays   = (startDate: string | Date, endDate: string | Date, maxRangeDays: number = 7): boolean => {
    const environment = process.env.REACT_APP_ENVIRONMENT;

    if (environment !== 'production') {
        return false;
    }

    const dateStart = new Date(startDate);
    const dateEnd = new Date(endDate);

    const timeDifference = Math.abs(dateEnd.getTime() - dateStart.getTime());
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference > maxRangeDays;
};

import { useEffect, useReducer } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { ButtonDownload, FooterModalCreate, ModalDelete } from '../../Components';
import { API } from '../../Services/API';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, labelStyle } from '../../Styles';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { saveAs } from 'file-saver';
import { Page42Title } from '../../State/Menu/TitleMenu';


export const ModalRunScheduler = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }


    const exportFile = (fileName: any) => {
        let element = (document.getElementById('export-data') as HTMLFormElement).innerHTML.toString().replace(/<br\>/g, '\n');
        let blob = new Blob([element], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${fileName}.txt`);
    }

    return (
        <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
            <div className='d-flex flex-column wd-1000' >
                <div className='shd-ea'>
                    <div className='d-flex p-20 justify-content-between align-items-center '>
                        <div className="my-3 w-700 Lato-700 c-29 s-1822">
                            {statePage.titleModal}
                        </div>
                        <div className='pointer' onClick={(e) => setModal('visibleModalAddDelete')}>{close} </div>
                    </div>
                    <div className='d-flex justify-content-end align-items-end my-2'>
                        {/* <div className='bg-f4 h-40px d-flex justify-content-between align-items-between p-2 br-10 s-1822'> {props.dataLogNameSelected}</div> */}
                        <ButtonDownload handleClick={() => exportFile(props.dataLogNameSelected)} codeAccessPrivilege={""} />
                    </div>
                </div>

                <div className='d-flex flex-column p-20'>
                    <div className='d-flex wrap mb-10 '>
                        <div className='row mb-10 w-100'>

                            {
                                // state.data === null
                                //     ?
                                //     <></>
                                //     :
                                //     <div id="export-data"
                                //         dangerouslySetInnerHTML={{ __html: state.data }}
                                //         style={{ height: '600px', overflow: 'auto' }}
                                //     />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}


const initialState = {
    titleModal: `New ${Page42Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    data: {},
    //
    // data: new any({
    //     id: null,
    //     qrseId: null,
    //     qrStandardId: null,
    //     qrStandardName: '',
    //     elementId: null,
    //     elementName: '',
    //     qrseMoc: 'M',
    //     qrseIsRoot: true,
    //     rootElementId: null,
    //     qrseStatus: '001',
    //     qrseDesc: '',
    // }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as any) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }


    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                // data: new any({
                //     id: null,
                //     qrseId: null,
                //     qrStandardId: null,
                //     qrStandardName: '',
                //     elementId: null,
                //     elementName: '',
                //     qrseMoc: 'M',
                //     qrseIsRoot: true,
                //     rootElementId: null,
                //     qrseStatus: '001',
                //     qrseDesc: '',
                // }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                // data: new any({
                //     id: action.value.qrseId,
                //     qrseId: action.value.qrseId,
                //     qrStandardId: action.value.qrStandardId,
                //     qrStandardName: action.value.qrStandardName,
                //     elementId: action.value.elementId,
                //     elementName: action.value.elementName,
                //     qrseMoc: action.value.qrseMoc,
                //     qrseIsRoot: action.value.qrseIsRoot,
                //     rootElementId: action.value.rootElementId,
                //     qrseStatus: action.value.qrseStatus,
                //     qrseDesc: action.value.qrseDesc,
                // }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

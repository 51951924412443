import { dbIcon } from "../../../Assets/Images/svg";
import { Page1MemberRoute, Page2MemberRoute } from "../../../Routes";
import { Page51Code, Page52Code } from "../CodeMenu";
import { Page51Title, Page52Title } from "../TitleMenu";

export const Menu3 = {
    id: 'member_sidebar',
    label: 'Member',
    icon: dbIcon,
    privilegesId: [Page51Code, Page52Code],
    itemSidebar: [
        {
            titleList: Page51Title,
            key: 'memberOptions1',
            privilegeId: Page51Code,
            url: Page1MemberRoute,
        },
        {
            titleList: Page52Title,
            key: 'memberOptions2',
            privilegeId: Page52Code,
            url: Page2MemberRoute,
        },
    ],
}
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import { format } from "date-fns";
import Modal from 'react-modal';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete, DatePickerCalendarIconOnly } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { Page27Title } from '../../State/Menu/TitleMenu';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalQRWhitelistAndBlacklist = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsQRStandard, optionsCommonStatusDetailCode, optionsWhitelistBlacklist } = props.stateOptions


    const criteriaOptions = [
        { value: 'MPAN', label: 'MPAN' },
        { value: 'Issuer ID', label: 'Issuer ID' }
    ];


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { qrStandardId, subProductCode, subProductName, qrListType, qrListCriteria, qrListCriteriaValue, qrListStartDate, qrListEndDate, qrListStatus, qrListReason, } = statePage
        const data = {
            qrStandardId: qrStandardId,
            qrListType: qrListType,
            qrListCriteria: qrListCriteria,
            qrListCriteriaValue: qrListCriteriaValue,
            // qrListStartDate: qrListStartDate !== '' ? `${qrListStartDate.split('T')[0]}${qrListStartDate.split('T')[1] !== undefined ? ` ${qrListStartDate.split('T')[1]}` : ''}` : '',
            qrListStartDate: qrListStartDate !== '' ? `${qrListStartDate} 00:00:00` : '',
            // qrListEndDate: qrListEndDate !== '' ? `${qrListEndDate.split('T')[0]}${qrListEndDate.split('T')[1] !== undefined ? ` ${qrListEndDate.split('T')[1]}` : ''}` : '',
            qrListEndDate: qrListEndDate !== '' ? `${qrListEndDate} 00:00:00` : '',
            qrListStatus: qrListStatus,
            qrListReason: qrListReason,
        }

        const a = CheckAllFields([qrListType, qrListCriteria, qrListCriteriaValue, qrListStatus, qrListStartDate, qrListEndDate, qrStandardId, qrListReason], ['Type', 'Criteria', 'Value', 'Status', 'Start Date', 'End Date', 'QR STANDARD ID', 'Reason'])

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            let dateStart = new Date(qrListStartDate);
            let dateEnd = new Date(qrListEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length < 1) {
                handleState('errorMessage', 'Start Date can\'t be larger than end date');
            } else {
                handleState('errorMessage', ``);
                API.post({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    dispatchStatePage({ type: 'clearData' })
                    setModal("visibleModalAddDelete")
                    reloadData()
                }).catch((err) => handleState('errorMessage', err))
            }

        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>TYPE</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsWhitelistBlacklist}
                                    value={optionsWhitelistBlacklist.filter((option: any) => option.value === statePage.qrListType)}
                                    onChange={(e) => handleState('qrListType', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>CRITERIA</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={criteriaOptions}
                                    value={criteriaOptions.filter((option: any) => option.value === statePage.qrListCriteria)}
                                    onChange={(e) => handleState('qrListCriteria', e?.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>VALUE</p>
                                <input value={statePage.qrListCriteriaValue} onChange={(e) => handleState('qrListCriteriaValue', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Status</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode}
                                    value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.qrListStatus)}
                                    onChange={(e) => handleState('qrListStatus', `${e.value}`)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>START DATE</p>
                                <div>
                                    <div className="d-flex">
                                        <DatePicker
                                            selected={statePage.qrListStartDate}
                                            className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                            wrapperClassName="w-100 h-100"
                                            popperClassName="react-datepicker-popper-transform-up-right"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown
                                            showPopperArrow={false}
                                            onChange={(date: any) => handleState('qrListStartDate', format(date, 'yyyy-MM-dd'))}
                                        />
                                    </div>
                                    <DatePickerCalendarIconOnly />
                                </div>
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>END DATE</p>
                                <div>
                                    <div className="d-flex">
                                        <DatePicker
                                            selected={statePage.qrListEndDate}
                                            className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                            wrapperClassName="w-100 h-100"
                                            popperClassName="react-datepicker-popper-transform-up-right"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown
                                            showPopperArrow={false}
                                            onChange={(date: any) => handleState("qrListEndDate", format(date, 'yyyy-MM-dd'))}
                                        />
                                    </div>
                                    <DatePickerCalendarIconOnly />
                                </div>
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>QR STANDARD ID</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsQRStandard}
                                    value={optionsQRStandard.filter((option: any) => option.value === statePage.qrStandardId)}
                                    onChange={(e) => handleState('qrStandardId', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>REASON</p>
                                <input value={statePage.qrListReason} onChange={(e) => handleState('qrListReason', e.target.value)} className={inputStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page27Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    qrListId: '',
    qrStandardId: '',
    subProductCode: '',
    subProductName: '',
    qrListType: '',
    qrListCriteria: '',
    qrListCriteriaValue: '',
    qrListStartDate: '',
    qrListEndDate: '',
    qrListStatus: '001',
    qrListReason: '',
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                qrListId: '',
                qrStandardId: '',
                subProductCode: '',
                subProductName: '',
                qrListType: '',
                qrListCriteria: '',
                qrListCriteriaValue: '',
                qrListStartDate: '',
                qrListEndDate: '',
                qrListStatus: '001',
                qrListReason: '',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                qrListId: action.value.qrListId,
                qrStandardId: action.value.qrStandardId,
                qrListType: action.value.qrListType,
                qrListCriteria: action.value.qrListCriteria,
                qrListCriteriaValue: action.value.qrListCriteriaValue,
                qrListStartDate: action.value.qrListStartDate === null || action.value.qrListStartDate === undefined ? '' : action.value.qrListStartDate.toString().split(' ')[0],
                // qrListStartDate: action.value.qrListStartDate,
                qrListEndDate: action.value.qrListEndDate === null || action.value.qrListEndDate === undefined ? '' : action.value.qrListEndDate.toString().split(' ')[0],
                // qrListEndDate: action.value.qrListEndDate,
                qrListStatus: action.value.qrListStatus,
                qrListReason: action.value.qrListReason,
                errorMessage: "",
            };
        // 
        case 'qrListId':
            return {
                ...state,
                qrListId: action.value,
            };
        case 'qrStandardId':
            return {
                ...state,
                qrStandardId: action.value,
            };
        case 'qrListType':
            return {
                ...state,
                qrListType: action.value,
            };
        case 'qrListCriteria':
            return {
                ...state,
                qrListCriteria: action.value,
            };
        case 'qrListCriteriaValue':
            return {
                ...state,
                qrListCriteriaValue: action.value,
            };
        case 'qrListStartDate':
            return {
                ...state,
                qrListStartDate: action.value,
            };
        case 'qrListEndDate':
            return {
                ...state,
                qrListEndDate: action.value,
            };
        case 'qrListStatus':
            return {
                ...state,
                qrListStatus: action.value,
            };
        case 'qrListReason':
            return {
                ...state,
                qrListReason: action.value,
            };
        default:
            throw new Error();
    }
}

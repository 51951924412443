import { useReducer } from 'react';
import { Card, FormLabel } from "react-bootstrap";
import ReactModal from "react-modal";
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import bgLogin from "../../Assets/Images/bg_login.png";
import logo2 from "../../Assets/Images/logo2.png";
import { close, errNotificationIcon, padlockIcon } from "../../Assets/Images/svg";
import { history } from '../../History';
import { Page1SystemNetworkRoute, Page3DashboardRoute, Page4DashboardRoute, Page1DashboardRoute, Page2DashboardRoute, PageMainDashboardRoute } from '../../Routes';
import { API } from '../../Services';
import { labelStyle } from '../../Styles';
import { CheckAllFields, CheckPasswordMinim, ModalStyle } from '../../Utils';
import { listPrivilegePage } from '../../State';
import { urlSelection } from '../../Services/API';



export const ForgotPassword = () => {
    let height_ = window.innerHeight;
    const { token } = useParams();
    //
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const navigate = useNavigate();
    //
    const userLogin = useSelector((store: any) => store.user);

    const handleState = (nameState: string | any, valueState: any) => dispatchStatePage({ type: nameState, value: valueState })


    const confirmPassword = () => {
        const data =
        {
            userPassword: statePage.userPassword,
            confirmPassword: statePage.confirmPassword,
            token: token,
        };
        const a = CheckAllFields([statePage.userPassword, statePage.confirmPassword], ["Password", "Confirm password"]);

        if (CheckPasswordMinim.test(statePage.userPassword)) {
            if (a !== "") handleState('errorMessage', a)
            else if (statePage.userPassword !== statePage.confirmPassword) handleState('errorMessage', "Your password and confirmation password must match")
            else {
                API.post({
                    pathCustom: `userapp/confirmpassword`,
                    bodyCustom: data,
                    selectUrl: urlSelection.dashboard,
                    useToken: null,
                }).then((res: any) => {
                    handleState('modalShow', true)
                    handleState('modalMessage', 'Password Updated Successfully')
                }).catch((err: any) => {
                    handleState('modalShow', true)
                    handleState('modalMessage', err)
                    //
                    handleState('errorMessage', err)
                })
            }
        }
        else handleState('errorMessage', 'Password min. 8 char and must include lowercase, uppercase, special character & number')
    }
    if (userLogin.user) {
        if (userLogin.privilegeAccess !== null) {
            if (userLogin.privilegeAccess.length > 0) {
                let data = [...userLogin.privilegeAccess] // copy data privilege code form state global,.. because immutable cannot change directly
                if (data.includes('471')) {
                    return <Navigate to={PageMainDashboardRoute} state={{ from: history.location }} />; // 2024-11-06 update first page to new main dashboard page
                } else if (data.includes('481')) {
                    return <Navigate to={Page2DashboardRoute} state={{ from: history.location }} />;
                } else if (data.includes('491')) {
                    return <Navigate to={Page3DashboardRoute} state={{ from: history.location }} />;
                } else if (data.includes('501')) {
                    return <Navigate to={Page4DashboardRoute} state={{ from: history.location }} />;
                } else if (data.includes('511')) {
                    return <Navigate to={Page1SystemNetworkRoute} state={{ from: history.location }} />;
                } else {
                    data.sort((a: string, b: string) => parseInt(a) - parseInt(b))
                    let pageCode = data[0]; // 51 / 52 / 53
                    let pageRouteAndCode: any[] = [] // 51
                    JSON.parse(listPrivilegePage).forEach((element: any) => {
                        pageRouteAndCode.push({ route: element.route, code: (parseInt(element.id) + 1) }) // get only code and route
                    })
                    let selectedRouteAndRoute = pageRouteAndCode.filter((element: any) => `${element.code}` === `${pageCode}`)[0]
                    return <Navigate to={selectedRouteAndRoute.route} state={{ from: history.location }} />;
                }
            } else {
                navigate('/warning')
            }
        } else {
            return <></>
        }

    }

    return (
        <>
            <div className="w-100 bg-ac" style={{ height: `${height_}px` }}>
                <div className="row d-flex justify-content-center h-100persen" style={{ backgroundImage: `url(${bgLogin})` }}>
                    <div className="col-md-4 bg-ff bd-ea hg-fit w-400px h-600px" style={{ minHeight: "550px" }}>
                        <div className="text-center my-3">
                            <Card.Img className="w-250px" src={logo2} />
                        </div>
                        <div className="text-center mb-4 fs-lato-18px-normal">RESET PASSWORD</div>
                        <div >
                            <div className="form-group font-11">
                                <FormLabel className={labelStyle} htmlFor="password">PASSWORD</FormLabel>
                                <FormLabel className="container-custom-input" htmlFor="password">
                                    {padlockIcon}
                                    <input value={statePage.userPassword} id="password" type="password" className="mx-2" placeholder="Enter new password" onChange={(event) => handleState('userPassword', event.target.value)} />
                                </FormLabel>
                            </div>
                            <div className="form-group font-11">
                                <FormLabel className={labelStyle} htmlFor="confirm-password">CONFIRM PASSWORD</FormLabel>
                                <FormLabel className="container-custom-input" htmlFor="confirm-password">
                                    {padlockIcon}
                                    <input value={statePage.confirmPassword} id="confirm-password" type="password" className="mx-2" placeholder="Confirm new password" onChange={(event) => handleState('confirmPassword', event.target.value)} />
                                </FormLabel>
                            </div>
                            {
                                statePage.errorMessage !== "" &&
                                <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                    {errNotificationIcon}
                                    <p className='px-2 s-1418 Lato-600 c-e87'>{statePage.errorMessage}</p>
                                </div>
                            }
                            <div className="w-100 d-flex justify-content-center align-items-center py-2">
                                <button onClick={confirmPassword} className="w-100 d-flex align-items-center justify-content-center py-3 br-20 bd-d4 bg-e8 text-center s-1418 c-ff Lato-600 ">
                                    RESET PASSWORD
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactModal isOpen={statePage.modalShow} style={ModalStyle}>
                <div className='d-flex flex-column w-500px'>
                    <div className='d-flex p-20 h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{'Message'}</p>
                        <div className='pointer' onClick={() => handleState('modalShow', false)}>{close}</div>
                    </div>
                </div>
                <div className='d-flex flex-column p-20'>
                    <div className='d-flex wrap my-2'>
                        {statePage.modalMessage}
                    </div>
                    <div className='d-flex wrap my-2 justify-content-end align-items-center'>
                        <button onClick={(e) => handleState('modalShow', false)} className="d-flex align-items-center px-4 py-3 br-20 bd-d4 h-40px mx-2">
                            <span className="s-1418 Lato-600">CLOSE</span>
                        </button>
                        <button onClick={(e) => navigate('/login')} className="d-flex align-items-center px-4 py-3 br-20 bd-d4 my-2 bg-e8 h-40px">
                            <span className="s-1418 Lato-600 text-white">LOGIN</span>
                        </button>
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

const initialState = {
    userPassword: '',
    confirmPassword: '',
    errorMessage: '',
    modalShow: false,
    modalMessage: ''
}
const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'userPassword':
            return {
                ...state,
                userPassword: action.value,
            };
        case 'confirmPassword':
            return {
                ...state,
                confirmPassword: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'modalShow':
            return {
                ...state,
                modalShow: action.value,
            };
        case 'modalMessage':
            return {
                ...state,
                modalMessage: action.value,
            };
        default:
            throw new Error();

    }
}
import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, inputStyleReadOnlyH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page47Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalCountryCodeManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsCommonStatusDetailCode } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'countryCode') {
            if (valueState.split('').length < 4) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'currencyCode') {
            if (valueState.split('').length < 4) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'countryIso2') {
            if (valueState.split('').length < 3) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'countryIso3') {
            if (valueState.split('').length < 4) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            let names = ["countryName", "countryCurrencyName"]
            let limit = [50, 50]
            let index = names.indexOf(nameState)
            if (index !== -1) {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { countryCode, countryName, countryIso2, countryIso3, currencyCode, countryCurrencyName, countryStatus } = statePage;
        const data = {
            countryCode: countryCode,
            countryName: countryName,
            countryIso2: countryIso2,
            countryIso3: countryIso3,
            currencyCode: currencyCode,
            countryCurrencyName: countryCurrencyName,
            countryStatus: countryStatus,
        };
        const a = CheckAllFields([countryCode, countryName, countryIso2, countryIso3, currencyCode, countryCurrencyName, countryStatus], ['Country Code', 'Country Name', 'ISO2', 'ISO3', 'Currency Code', 'Currency Name', 'Status']);
        if (a !== '') {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', '');
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>Country Code <span className='text-danger'>*</span></p>
                                {statePage.countryId ? (
                                    <input value={statePage.countryCode} disabled className={inputStyleReadOnly} />
                                ) : (
                                    <input value={statePage.countryCode} onChange={(e) => handleState('countryCode', e.target.value)} className={inputStyle} />
                                )}
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Country Name <span className='text-danger'>*</span></p>
                                <input value={statePage.countryName} onChange={(e) => handleState('countryName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ISO2 <span className='text-danger'>*</span></p>
                                <input value={statePage.countryIso2} onChange={(e) => handleState('countryIso2', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ISO3 <span className='text-danger'>*</span></p>
                                <input value={statePage.countryIso3} onChange={(e) => handleState('countryIso3', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Currency Code <span className='text-danger'>*</span></p>
                                <input value={statePage.currencyCode} onChange={(e) => handleState('currencyCode', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Currency Name <span className='text-danger'>*</span></p>
                                <input value={statePage.countryCurrencyName} onChange={(e) => handleState('countryCurrencyName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.countryStatus)} onChange={(e) => handleState('countryStatus', e.value)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />

                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page47Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    countryId: '',
    countryCode: '',
    countryName: '',
    countryIso2: '',
    countryIso3: '',
    currencyCode: '',
    countryCurrencyName: '',
    countryStatus: '001',
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                countryId: '',
                countryCode: '',
                countryName: '',
                countryIso2: '',
                countryIso3: '',
                currencyCode: '',
                countryCurrencyName: '',
                countryStatus: '001',
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                countryId: action.value.countryId,
                countryCode: action.value.countryCode,
                countryName: action.value.countryName,
                countryIso2: action.value.countryIso2,
                countryIso3: action.value.countryIso3,
                currencyCode: action.value.currencyCode,
                countryCurrencyName: action.value.countryCurrencyName,
                countryStatus: action.value.countryStatus,
                errorMessage: "",
            };
        // 
        case 'countryId':
            return {
                ...state,
                countryId: action.value,
            };
        case 'countryCode':
            return {
                ...state,
                countryCode: action.value,
            };
        case 'countryName':
            return {
                ...state,
                countryName: action.value,
            };
        case 'countryIso2':
            return {
                ...state,
                countryIso2: action.value,
            };
        case 'countryIso3':
            return {
                ...state,
                countryIso3: action.value,
            };
        case 'currencyCode':
            return {
                ...state,
                currencyCode: action.value,
            };
        case 'countryCurrencyName':
            return {
                ...state,
                countryCurrencyName: action.value,
            };
        case 'countryStatus':
            return {
                ...state,
                countryStatus: action.value,
            };
        default:
            throw new Error();
    }
}

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelRoutingFieldMapping } from './ModelRoutingFieldMapping';
import { Page32Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalRoutingFieldMapping = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMOClowercase, optionsCommonStatusDetailCode, optionsRouting, optionsInterfaceField } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
                needLoading: true,
            }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { interfaceFieldId, rifMoc, rifStatus, routeId, rifValue } = statePage.data
        const data = {
            // rifId: id,
            routeId: routeId,
            interfaceFieldId: parseInt(interfaceFieldId),
            rifMoc: rifMoc,
            rifStatus: rifStatus,
            rifValue: rifValue
        }
        const a = CheckAllFields([interfaceFieldId, rifMoc, routeId, rifStatus], ["INTERFACE FIELD ID", "M/O/C", "ROUTE ID", "STATUS"])

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,

                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,

            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    let newOptionsRouting: any = [];
    if (optionsRouting.length > 0) {
        optionsRouting.forEach((element1: any) => {
            newOptionsRouting.push({ value: element1.routeId, label: element1.routeName === null ? 'Route Name is unknown' : element1.routeName });
        })
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>INTERFACE FIELD ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceField} value={optionsInterfaceField.filter((option: any) => option.value === statePage.data.interfaceFieldId)} onChange={(e) => handleState('interfaceFieldId', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>M/O/C</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMOClowercase} value={optionsMOClowercase.filter((option: any) => option.value === statePage.data.rifMoc)} onChange={(e) => handleState('rifMoc', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Route ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={newOptionsRouting} value={newOptionsRouting.filter((option: any) => option.value === statePage.data.routeId)} onChange={(e) => handleState('routeId', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Status</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.rifStatus)} onChange={(e) => handleState('rifStatus', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Default Value</p>
                                <input value={statePage.data.rifValue} onChange={(e) => handleState('rifValue', e.target.value)} className={inputStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page32Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelRoutingFieldMapping({
        id: null,
        rifId: null,
        routeId: null,
        interfaceFieldId: null,
        rifMoc: "m",
        rifStatus: "001",
        rifValue: null,
        interfaceFieldName: null,
        interfaceFieldValue: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelRoutingFieldMapping) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelRoutingFieldMapping({
                    id: null,
                    rifId: null,
                    routeId: null,
                    interfaceFieldId: null,
                    rifMoc: "m",
                    rifStatus: "001",
                    rifValue: null,
                    interfaceFieldName: null,
                    interfaceFieldValue: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelRoutingFieldMapping({
                    id: action.value.rifId,
                    rifId: action.value.rifId,
                    routeId: action.value.routeId,
                    interfaceFieldId: action.value.interfaceFieldId,
                    rifMoc: action.value.rifMoc,
                    rifStatus: action.value.rifStatus,
                    rifValue: action.value.rifValue,
                    interfaceFieldName: action.value.interfaceFieldName,
                    interfaceFieldValue: action.value.interfaceFieldValue,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

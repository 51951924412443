import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page49Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalInstitutionMonitoringStatus = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMember, optionsServerStatus, optionsConnectionStatus, optionsServerStatusDetailCode, optionsConnectionStatusDetailCode } = props.stateOptions



    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'memberConnIntervalTime') {
            if (valueState === '') dispatchStatePage({ type: nameState, value: valueState })
            else if (parseInt(valueState) > 0 && parseInt(valueState) < 100) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            let names = ["memberConnServer", "memberConnPort", "memberConnEndpoint"]
            let limit = [50, 10, 255]
            let index = names.indexOf(nameState)
            if (index !== -1) {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { memberId, memberConnServer, memberConnPort, memberConnServerStatus, memberConnStatus, memberConnEndpoint, memberConnIntervalTime } = statePage
        const data = {
            memberId: memberId,
            memberConnServer: memberConnServer,
            memberConnPort: memberConnPort,
            memberConnServerStatus: memberConnServerStatus,
            memberConnStatus: memberConnStatus,
            memberConnEndpoint: memberConnEndpoint,
            memberConnIntervalTime: memberConnIntervalTime,
        };

        const a = CheckAllFields([memberId, memberConnServer, memberConnPort, memberConnServerStatus, memberConnStatus, memberConnEndpoint, memberConnIntervalTime], ['MEMBER ID', 'IP ADDRESS/HOST', 'PORT', 'SERVER STATUS', 'CONNECTION STATUS', 'END POINT', 'INTERVAL TIME']);


        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-600px'>
                    <div className='d-flex min-h-40px justify-content-between align-items-center shd-ea'>
                        <p className='w-600 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>member ID <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>ip address/host <span className='text-danger'>*</span></p>
                                <input value={statePage.memberConnServer} onChange={(e) => handleState('memberConnServer', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>port <span className='text-danger'>*</span></p>
                                <input value={statePage.memberConnPort} onChange={(e) => handleState('memberConnPort', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-12'>
                                <p className={labelStyle}>End point <span className='text-danger'>*</span></p>
                                <input value={statePage.memberConnEndpoint} onChange={(e) => handleState('memberConnEndpoint', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Server STATUS <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsServerStatusDetailCode} value={optionsServerStatusDetailCode.filter((option: any) => option.value === statePage.memberConnServerStatus)} onChange={(e) => handleState('memberConnServerStatus', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Connection STATUS <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsConnectionStatusDetailCode} value={optionsConnectionStatusDetailCode.filter((option: any) => option.value === statePage.memberConnStatus)} onChange={(e) => handleState('memberConnStatus', e.value)} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>INTERVAL TIME <span className='text-danger'>*</span></p>
                                <input type='number' value={statePage.memberConnIntervalTime} onChange={(e) => handleState('memberConnIntervalTime', e.target.value)} className={inputStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />

        </>
    )
}


const initialState = {
    titleModal: `New ${Page49Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    memberConnId: '',
    memberId: '',
    memberConnServer: '',
    memberConnPort: '',
    memberConnEndpoint: '',
    memberConnServerStatus: '000',
    memberConnIntervalTime: '',
    memberConnStatus: '001',
    memberConnLastEchoDt: '',
    memberConnLastTransDt: '',
    memberName: '',
    memberCode: '',
    cdtStart: '',
    cdtStop: '',
    // 
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: '',
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                memberConnId: '',
                memberId: '',
                memberConnServer: '',
                memberConnPort: '',
                memberConnEndpoint: '',
                memberConnServerStatus: '000',
                memberConnIntervalTime: '',
                memberConnStatus: '001',
                memberConnLastEchoDt: '',
                memberConnLastTransDt: '',
                memberName: '',
                memberCode: '',
                cdtStart: '',
                cdtStop: '',
                //
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                memberConnId: action.value.memberConnId,
                memberId: action.value.memberId,
                memberConnServer: action.value.memberConnServer,
                memberConnPort: action.value.memberConnPort,
                memberConnEndpoint: action.value.memberConnEndpoint,
                memberConnServerStatus: action.value.memberConnServerStatus,
                memberConnIntervalTime: action.value.memberConnIntervalTime,
                memberConnStatus: action.value.memberConnStatus,
                memberConnLastEchoDt: action.value.memberConnLastEchoDt,
                memberConnLastTransDt: action.value.memberConnLastTransDt,
                memberName: action.value.memberName,
                memberCode: action.value.memberCode,
                cdtStart: action.value.cdtStart,
                cdtStop: action.value.cdtStop,
                //
                errorMessage: "",
            };
        // 
        case 'memberConnId':
            return {
                ...state,
                memberConnId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'memberConnServer':
            return {
                ...state,
                memberConnServer: action.value,
            };
        case 'memberConnPort':
            return {
                ...state,
                memberConnPort: action.value,
            };
        case 'memberConnEndpoint':
            return {
                ...state,
                memberConnEndpoint: action.value,
            };
        case 'memberConnServerStatus':
            return {
                ...state,
                memberConnServerStatus: action.value,
            };
        case 'memberConnIntervalTime':
            return {
                ...state,
                memberConnIntervalTime: action.value,
            };
        case 'memberConnStatus':
            return {
                ...state,
                memberConnStatus: action.value,
            };
        case 'memberConnLastEchoDt':
            return {
                ...state,
                memberConnLastEchoDt: action.value,
            };
        case 'memberConnLastTransDt':
            return {
                ...state,
                memberConnLastTransDt: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        case 'memberCode':
            return {
                ...state,
                memberCode: action.value,
            };
        case 'cdtStart':
            return {
                ...state,
                cdtStart: action.value,
            };
        case 'cdtStop':
            return {
                ...state,
                cdtStop: action.value,
            };


        default:
            throw new Error();
    }
}

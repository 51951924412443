import { API, urlSelection } from '../../../Services/API';
import { CheckAllFields, CheckInputAZaz09Space, ModalStyle } from '../../../Utils';
import { DatePicker, DatePickerProps, Divider, TimePicker } from 'antd';
import { FooterModalCreate2, ModalCannotDelete, ModalDelete } from '../../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleReadOnly, labelStyle } from '../../../Styles';
import { useCallback, useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelBatchScheduler } from '../Models';
import { Page53Title } from '../../../State/Menu/TitleMenu';
import { RangePickerProps } from 'antd/es/date-picker';
import Select from 'react-select';
import { close } from '../../../Assets/Images/svg';
import dayjs from 'dayjs';
import moment from 'moment';
import { useDispatch } from 'react-redux';

const Page3 = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const {
        optionsLogLevelDetailCode,
        optionsBatchProgramStatusDetailCode,
        optionsCommonStatusDetailCode,
        optionsBatchProgramTypeDetailCode,
        optionsBatchFromAPIBatch,
        optionsBatchRegistrationTypeDetailCode,
        optionsBatchSchedulerStatusDetailCode,
        optionsBatchExecutionTypeDetailCode,
        optionsBusinessGroupNameDetailCodeAndCcdName
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["batchProgId", "batchStatus", "batchName", "batchType", "batchProgName", "batchLogLevel", "batchDesc"]
        let limit = [15, 3, 15, 3, 50, 3, 200]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (nameState === 'batchProgId') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === 'batchName') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === "batchProgName") {
                let errorMessage = {
                    id: '1',
                    errorMessage: 'BATCH PROGRAM NAME must contain only letters (A-Z) or (a-z), space and numbers (0-9)'
                }
                if (CheckInputAZaz09Space.test(valueState.toString())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            }
            else if (valueState.split('').length <= limit[index]) {
                dispatchStatePage({ type: nameState, value: valueState })
            }

        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `batch/run-batch-v2/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            batchId,
            batchPreRequisite,
            batchStatus,
            batchRegType,
            batchExecutionType,
            batchRerunManuallyDttm,
            batchRunTime,
            batchParameterValue,
            batchGroupPath,
            batchPlannedDttm,
            batchInterval,
            batchBusinessGroup,
            batchDescription
        } = statePage.data

        const data = {
            // batchId: batchId,
            batchId: batchId,
            // batchPreRequisite: (batchPreRequisite !== null && batchPreRequisite.length > 0) ? batchPreRequisite.toString() : '',
            batchStatus: batchStatus,
            batchRegType: batchRegType,
            batchExecutionType: batchExecutionType,
            // batchRerunManuallyDttm: batchRerunManuallyDttm,
            batchRunTime: batchRunTime,
            batchParameterValue: batchParameterValue,
            batchGroupPath: batchGroupPath,
            batchPlannedDttm: batchPlannedDttm,
            batchInterval: batchInterval,
            batchBusinessGroup: batchBusinessGroup,
            batchDescription: batchDescription,
        }
        let checkFields: any[] = []
        let checkNameFields: any[] = []
        if (batchExecutionType === '005' || batchExecutionType === '006' || batchExecutionType === '007') {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
                batchInterval
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
                "BATCH INTERVAL",
            ]
        } else {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
            ]
        }

        const a = CheckAllFields(checkFields, checkNameFields)
        if (statePage.errorMessageList.length > 0) { }
        else {
            if (a !== "") {
                handleState('errorMessage', a);
            } else {
                handleState('errorMessage', ``);
                API.post({
                    bodyCustom: data,
                    pathCustom: `batch/run-batch-v2`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    dispatchStatePage({ type: 'clearData' })
                    setModal("visibleModalAddDelete")
                    reloadData()
                }).catch((err) => handleState('errorMessage', err))
            }
        }
    }


    useEffect(() => {
        let datNow = new Date();
        let hoursNow = datNow.getHours().toString().padStart(2, '0');
        let minutesNow = datNow.getMinutes().toString().padStart(2, '0');
        let secondsNow = datNow.getSeconds().toString().padStart(2, '0');
        let timeNowNow = `${hoursNow}:${minutesNow}:${secondsNow}`;
        let dateStart = new Date();
        let dateStartUsed = dateStart.toISOString().slice(0, 10);
        dispatchStatePage({ type: 'batchPlannedDttm', value: `${dateStartUsed} ${timeNowNow}` })

        // dispatchStatePage({ type: 'selectedDateBatchRunTime', value: new Date().toISOString().slice(0, 10) })
        dispatchStatePage({ type: 'selectedTimeBatchRunTime', value: '00:00:01' })

        dispatchStatePage({ type: 'selectedDateBatchRerunManuallyDttm', value: new Date().toISOString().slice(0, 10) })
        dispatchStatePage({ type: 'selectedTimeBatchRerunManuallyDttm', value: '00:00:01' })
    }, [])

    useEffect(() => {
        // handleState('batchRunTime', `${statePage.selectedDateBatchRunTime} ${statePage.selectedTimeBatchRunTime}`)
        handleState('batchRunTime', `${statePage.selectedTimeBatchRunTime}`)
    }, [statePage.selectedDateBatchRunTime, statePage.selectedTimeBatchRunTime])



    useEffect(() => {
        handleState('batchRerunManuallyDttm', `${statePage.selectedDateBatchRerunManuallyDttm} ${statePage.selectedTimeBatchRerunManuallyDttm}`)
    }, [statePage.selectedDateBatchRerunManuallyDttm, statePage.selectedTimeBatchRerunManuallyDttm])


    useEffect(() => {
        let selectedBatchPreRequisite: any[] = []
        if (optionsBatchFromAPIBatch.length > 0) {
            optionsBatchFromAPIBatch.forEach((option: any) => {
                if (statePage.data.batchPreRequisite !== null) {
                    if (statePage.data.batchPreRequisite.length > 0) {
                        if (statePage.data.batchPreRequisite.includes(option.value)) {
                            selectedBatchPreRequisite.push(option)
                        }
                    }
                }
            })
            handleState('selectedBatchPreRequisite', selectedBatchPreRequisite)
        }
    }, [statePage.data.batchPreRequisite, optionsBatchFromAPIBatch])


    const onOkBatchRunTime = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            // dispatchStatePage({ type: 'selectedDateBatchRunTime', value: localDate.toString().split(' ')[0] })
            let datNow = new Date();
            let hoursNow = datNow.getHours().toString().padStart(2, '0');
            let minutesNow = datNow.getMinutes().toString().padStart(2, '0');
            let secondsNow = datNow.getSeconds().toString().padStart(2, '0');
            let timeNowNow = `${hoursNow}:${minutesNow}:${secondsNow}`;
            if (timeNow > timeNowNow) {
                let dateStart = new Date();
                dateStart.setDate(dateStart.getDate() - 1)
                let dateStartUsed = dateStart.toISOString().slice(0, 10);
                dispatchStatePage({ type: 'selectedTimeBatchRunTime', value: timeNow })
                dispatchStatePage({ type: 'batchPlannedDttm', value: `${dateStartUsed} ${timeNow}` })
            } else {
                let dateStart = new Date();
                let dateStartUsed = dateStart.toISOString().slice(0, 10);
                dispatchStatePage({ type: 'selectedTimeBatchRunTime', value: timeNow })
                dispatchStatePage({ type: 'batchPlannedDttm', value: `${dateStartUsed} ${timeNowNow}` })
            }
        }
    };
    const onOkBatchPlannedDttm = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'batchPlannedDttm', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
        }
    };
    const onOkBatchRerunManuallyDttm = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'selectedDateBatchRerunManuallyDttm', value: localDate.toString().split(' ')[0] })
            dispatchStatePage({ type: 'selectedTimeBatchRerunManuallyDttm', value: timeNow })
        }
    };

    const ComponentBatchPlannedDttm = useCallback(({ ...props }) => {
        const { statePage } = props;
        return (
            <>
                {
                    dayjs(statePage.data.batchPlannedDttm, ['YYYY-MM-DD HH:mm:ss']).isValid() === true ?
                        <DatePicker
                            defaultValue={dayjs(`${statePage.data.batchPlannedDttm}`, ['YYYY-MM-DD HH:mm:ss'])}
                            className='flex-fill w-100 h-40px'
                            showTime
                            disabled={true}
                        />
                        :
                        <DatePicker
                            className='flex-fill w-100 h-40px'
                            showTime
                            disabled={true}
                        />
                }
            </>
        )
    }, [statePage.data.batchPlannedDttm])


    useEffect(() => {
        let labelSelectedBatchBusinessGroup: string = ''
        let labelSelectedBatchExecutionType: string = ''

        if (optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup).length > 0) {
            labelSelectedBatchBusinessGroup = optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup)[0].label
        }
        if (optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType).length > 0) {
            labelSelectedBatchExecutionType = optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType)[0].label
        }
        handleState('batchGroupPath', (`${labelSelectedBatchBusinessGroup}/${labelSelectedBatchExecutionType}`).toUpperCase())

    }, [statePage.data.batchBusinessGroup, statePage.data.batchExecutionType])


    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px' style={{ overflow: 'auto', height: '650px' }}>
                    <div>
                        <div className='d-flex min-h-60px justify-content-between align-items-center' >
                            <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                            <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                        </div>
                        <Divider className='m-0' />
                    </div>
                    <div className=''>
                        <div className='row m-0 p-0 g-1 mb-5'>
                            {/* <div className='col-12'>
                                <p className={labelStyle}>BATCH PRE REQUISITE</p>
                                <Select
                                    className='flex-fill'
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={statePage.selectedBatchPreRequisite}
                                    options={optionsBatchFromAPIBatch}
                                    onChange={(items) => {
                                        let dataSelected: any[] = [];
                                        items.forEach((item: any) => {
                                            dataSelected.push(item.value);

                                        })
                                        handleState('batchPreRequisite', dataSelected)
                                    }}
                                />
                            </div> */}
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH ID <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} options={optionsBatchFromAPIBatch} value={optionsBatchFromAPIBatch.filter((option: any) => option.value === statePage.data.batchId)} onChange={(e) => handleState('batchId', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH STATUS <span className='text-danger'>*</span></p>
                                <Select isDisabled={true} placeholder={'(Search)'} options={optionsBatchSchedulerStatusDetailCode} value={optionsBatchSchedulerStatusDetailCode.filter((option: any) => option.value === statePage.data.batchStatus)} onChange={(e) => handleState('batchStatus', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH BUSINESS GROUP <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} options={optionsBusinessGroupNameDetailCodeAndCcdName} value={optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup)} onChange={(e) => handleState('batchBusinessGroup', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH EXECUTION TYPE <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} options={optionsBatchExecutionTypeDetailCode} value={optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType)} onChange={(e) => handleState('batchExecutionType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH REG TYPE <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} options={optionsBatchRegistrationTypeDetailCode} value={optionsBatchRegistrationTypeDetailCode.filter((option: any) => option.value === statePage.data.batchRegType)} onChange={(e) => handleState('batchRegType', e.value)} />
                            </div>

                            {/* <div className='col-6'>
                                <p className={labelStyle}>BATCH RERUN MANUALLY DTTM</p>
                                {
                                    statePage.selectedTimeBatchRerunManuallyDttm === '' ?
                                        <></>
                                        :
                                        <DatePicker defaultValue={dayjs(`${statePage.selectedTimeBatchRerunManuallyDttm}`, 'HH:mm:ss')} className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRerunManuallyDttm} />
                                }
                            </div> */}


                            {/* <div className='col-6'>
                                <p className={labelStyle}>BATCH RUN TIME <span className='text-danger'>*</span></p>
                                {
                                    statePage.selectedTimeBatchRunTime === '' ?
                                        <></>
                                        :
                                        <DatePicker defaultValue={dayjs(`${statePage.selectedTimeBatchRunTime}`, 'HH:mm:ss')} className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRunTime} />
                                }
                            </div> */}
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH RUN TIME <span className='text-danger'>*</span></p>
                                <TimePicker className='h-40px' defaultValue={dayjs('00:00:01', ['HH:mm:ss'])} onOk={onOkBatchRunTime} onChange={onOkBatchRunTime}/>
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH PARAMETER VALUE </p>
                                <input value={statePage.data.batchParameterValue} onChange={(e) => handleState('batchParameterValue', e.target.value)} className={inputStyle} />
                            </div>



                            <div className='col-6'>
                                <p className={labelStyle}>BATCH GROUP PATH </p>
                                <input disabled={true} value={statePage.data.batchGroupPath} className={inputStyleReadOnly} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH PLANNED DTTM </p>
                                <ComponentBatchPlannedDttm statePage={statePage} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH INTERVAL
                                    {
                                        statePage.data.batchExecutionType === '005' || statePage.data.batchExecutionType === '006' || statePage.data.batchExecutionType === '007' ?
                                            <span className='text-danger'>*</span>
                                            :
                                            <></>
                                    }
                                </p>
                                <input type='number' value={statePage.data.batchInterval} onChange={(e) => handleState('batchInterval', e.target.value)} className={inputStyle} />
                            </div>


                            <div className='col-12'>
                                    <p className={labelStyle}>DESCRIPTION</p>
                                    <textarea rows={4} value={statePage.data.batchDescription} onChange={(e) => handleState('batchDescription', e.target.value)} className={inputDescriptionStyle} />
                                </div>
                        </div>
                        <FooterModalCreate2 errorMessage={statePage.errorMessage} errorMessageList={statePage.errorMessageList} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}

const initialState = {
    titleModal: `New ${Page53Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelBatchScheduler({
        id: null,
        batchId: null,
        batchPreRequisite: null,
        batchStatus: `000`,
        batchLastExec: null,
        batchGroupPath: null,
        createProgId: null,
        updateProgId: null,
        createDttm: null,
        updateDttm: null,
        batchRegType: null,
        batchRerunManuallyDttm: null,
        batchPlannedDttm: null,
        batchRunTime: null,
        batchParameterValue: null,
        batchExecutionType: null,
        batchInterval: null,
        batchBusinessGroup: null,
        flag: null,
        batchDescription: null,
        batchProgId: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
    selectedBatchPreRequisite: [],
    selectedDateBatchRunTime: '',
    selectedTimeBatchRunTime: '',

    selectedDateBatchPlannedDttm: '',
    selectedTimeBatchPlannedDttm: '',


    selectedDateBatchRerunManuallyDttm: '',
    selectedTimeBatchRerunManuallyDttm: '',


}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelBatchScheduler) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        case 'errorMessageList':
            if (action.value.type === "removed") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                            errorMessageList: state.errorMessageList.filter((item: any) => item.id !== action.value.data.id)
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else if (action.value.type === "added") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                        }
                    } else {

                        let data: any[] = [...state.errorMessageList]
                        data.push(action.value.data)
                        return {
                            ...state,
                            errorMessageList: data
                        }
                    }
                } else {
                    let data: any[] = [...state.errorMessageList]
                    data.push(action.value.data)
                    return {
                        ...state,
                        errorMessageList: data
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        //
        case 'selectedBatchPreRequisite':
            return {
                ...state,
                selectedBatchPreRequisite: action.value,
            };
        case 'selectedDateBatchRunTime':
            return {
                ...state,
                selectedDateBatchRunTime: action.value,
            };
        case 'selectedTimeBatchRunTime':
            return {
                ...state,
                selectedTimeBatchRunTime: action.value,
            };
        case 'selectedDateBatchPlannedDttm':
            return {
                ...state,
                selectedDateBatchPlannedDttm: action.value,
            };
        case 'selectedTimeBatchPlannedDttm':
            return {
                ...state,
                selectedTimeBatchPlannedDttm: action.value,
            };
        case 'selectedDateBatchParameterValue':
            return {
                ...state,
                selectedDateBatchParameterValue: action.value,
            };
        case 'selectedTimeBatchParameterValue':
            return {
                ...state,
                selectedTimeBatchParameterValue: action.value,
            };

        case 'selectedDateBatchRerunManuallyDttm':
            return {
                ...state,
                selectedDateBatchRerunManuallyDttm: action.value,
            };

        case 'selectedTimeBatchRerunManuallyDttm':
            return {
                ...state,
                selectedTimeBatchRerunManuallyDttm: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelBatchScheduler({
                    id: null,
                    batchId: null,
                    batchPreRequisite: null,
                    batchStatus: `000`,
                    batchLastExec: null,
                    batchGroupPath: null,
                    createProgId: null,
                    updateProgId: null,
                    createDttm: null,
                    updateDttm: null,
                    batchRegType: null,
                    batchRerunManuallyDttm: null,
                    batchPlannedDttm: null,
                    batchRunTime: null,
                    batchParameterValue: null,
                    batchExecutionType: null,
                    batchInterval: null,
                    batchBusinessGroup: null,
                    flag: null,
                    batchDescription: null,
                    batchProgId: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelBatchScheduler({
                    id: action.value.id,
                    batchId: action.value.batchId,
                    batchPreRequisite: action.value.batchPreRequisite,
                    batchStatus: action.value.batchStatus,
                    batchLastExec: action.value.batchLastExec,
                    batchGroupPath: action.value.batchGroupPath,
                    createProgId: action.value.createProgId,
                    updateProgId: action.value.updateProgId,
                    createDttm: action.value.createDttm,
                    updateDttm: action.value.updateDttm,
                    batchRegType: action.value.batchRegType,
                    batchRerunManuallyDttm: action.value.batchRerunManuallyDttm,
                    batchPlannedDttm: action.value.batchPlannedDttm,
                    batchRunTime: action.value.batchRunTime,
                    batchParameterValue: action.value.batchParameterValue,
                    batchExecutionType: action.value.batchExecutionType,
                    batchInterval: action.value.batchInterval,
                    batchBusinessGroup: action.value.batchBusinessGroup,
                    flag: action.value.flag,
                    batchDescription: action.value.batchDescription,
                    batchProgId: action.value.batchProgId,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

export default Page3;

import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { history } from "../History";
import { API, urlSelection } from "../Services/API";
import { AllPath } from "../Services/Path";
import { loginAction, logoutAction, setMemberIsRegisteredToPIC, setRoleByMemberIdAction, tokenAction } from "../Store/User";
import { ModalStyle, ModalStyle2 } from "../Utils";
import { replace } from "formik";
import { Page1DashboardRoute } from "../Routes";
import Cookies from 'universal-cookie';
import { cookiesGlobal } from "../State/Cookies";
import { loadavg } from "os";

export const PrivilegePage = ({ ...props }) => {
    const user = useSelector((state: any) => state.user.user);
    const navigate = useNavigate()
    const dispatchGlobal = useDispatch();
    const [getLoading, setLoading] = useState(false);

    const getDataUser = useCallback(async () => {
        const { userId, token } = user;
        dispatchGlobal(tokenAction({ token }))
        if (token === null || token === undefined) { }
        else {
            setLoading(true);
            try {
                const bodyGet = await API.get({
                    pathCustom: `${AllPath.userApp}/${userId}`,
                    bodyCustom: null,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: false,
                    needNotification: false,
                })
                const { privilegeId, userLevel, memberId } = bodyGet.data;
                const bodyGet2 = await API.get({
                    pathCustom: `${AllPath.privilegeDetail}/${privilegeId}`,
                    bodyCustom: null,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: false,
                    needNotification: false,
                })
                const bodyGet3 = await API.get({
                    pathCustom: `${AllPath.member}`,
                    bodyCustom: null,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: false,
                    needNotification: false,
                })
                dispatchGlobal(setRoleByMemberIdAction({ memberId: memberId, data: bodyGet3.data }));
                let saveMemberCodeUser = '';
                if (bodyGet3.data.length > 0) {
                    bodyGet3.data.forEach((element1: any) => {
                        if (element1.memberId === memberId) {
                            if (element1.memberRoleDtos !== null) {
                                saveMemberCodeUser = element1.memberCode
                            }
                        }
                    })
                }
                // check user is member PIC
                const bodyGet4 = await API.get({
                    pathCustom: `member_pic?memberPicCode=002&userId=${userId}&memberCode=${saveMemberCodeUser}`,
                    bodyCustom: null,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: false,
                    needNotification: false,
                })
                dispatchGlobal(setMemberIsRegisteredToPIC(bodyGet4.data.length > 0 ? true : false));
                dispatchGlobal(loginAction({ user: user, privilegeId: privilegeId, dataPrivilegeAccess: bodyGet2.data, userLevel: userLevel, memberId: memberId }))
                let encValUserDetail = CryptoJS.AES.encrypt(user, "bcap-gapih.dashboard.com").toString();
                let encValRandomData = CryptoJS.AES.encrypt('random data', "bcap-gapih.dashboard.com").toString();
                // cookiesGlobal.set('_passxxxx', encValRandomData, { path: '/',  });
                // cookiesGlobal.set('_userxxxx', encValUserDetail, { path: '/',  });
                navigate(Page1DashboardRoute, { replace: true })

            } catch (error) {
            } finally {
                setLoading(false);
            }
        }
    }, [])

    useEffect(() => {
        getDataUser()
    }, []);
    if (getLoading) {
        return (
            <Modal isOpen={getLoading} style={ModalStyle2} >
                <div className="h-40px w-500px d-flex  justify-content-center align-items-center h-100 w-100">
                    <div className="d-flex flex-column justify-content-center bd-ea py-5 br-10 bg-ff" style={{ width: '350px' }}>
                        <Loading />
                    </div>
                </div>
            </Modal>
        )
    } else {
        return props.children;
    }
}


import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonDelete, ButtonNavigation, TableWithLimitOffset } from '../../Components';
import { ShowModalInterface } from '../../Interface';
import { Page2MemberRoute } from '../../Routes';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { InterfaceOptionsMemberManagement } from './InterfaceOptionsMemberManagement';
import { ModalDraggableMemberManagement } from './ModalDraggableMemberManagement';
import { ModalMemberManagement } from './ModalMemberManagement';
import { ModelMemberManagement } from './ModelMemberManagement';
import { Page52Title } from '../../State/Menu/TitleMenu';
import { CheckUndefined, ExportCSV } from '../../Utils';
import { TableMemberManagement } from './Components/TableMemberManagement';


const ExcelMemberManagement = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showmemberInstitutionType = '';

        if (stateOptions.optionsInstitutionType.length > 0) {
            stateOptions.optionsInstitutionType.forEach((element2: any) => {
                if (element2.value === item.memberInstitutionType) {
                    showmemberInstitutionType = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            memberCode: CheckUndefined(item.memberCode),
            memberName: CheckUndefined(item.memberName),
            memberInstitutionType: CheckUndefined(showmemberInstitutionType),
            countryCode: CheckUndefined(`${item.countryCode} - ${item.countryName}`),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};
const Page5 = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: state.view,
    });

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let finalParameter = `${searchValue}${limit}${offset}`;
        let finalParameter2 = `${searchValue}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)

        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
                /* Refresh Page */
                setPagination({pageSize: state.view, pageIndex: 0})
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsMemberManagement, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        if (state.view !== pagination.pageSize) {
            initFunctionCustom(true);
            setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
        } else {
            initFunctionCustom(false);
        }
    }, [pagination.pageIndex, state.view]);



    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelMemberManagement(state, stateOptions, response.data)
        })
    }

    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelMemberManagement) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

    const customButtonAction = (item: ModelMemberManagement, width: string | any) => {
        return (
            <div className={`d-flex gap-2 align-items-center ${width}`}>
                
                <ButtonNavigation navTo={`${Page2MemberRoute}/details/${item.memberCode}`} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
                
            </div>
        );
    };

    const modalAddDeleteComponentPage = () => {
        // return <></>
        return (
            <ModalMemberManagement
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />

        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableMemberManagement
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2 mt-2">
                Member Profile
            </h1>
            <TableMemberManagement 
                state={state}
                addDataComponent={addDataComponent}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                handleStateComponent={handleStateComponent}
                checkIndexComponent={checkIndexComponent}
                setModal={setModalComponent}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                pagination={pagination}
                setPagination={setPagination}
            />
            {modalUpdateComponentPage()}
        </>
    );

}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'member',
    titlePage: `${Page52Title}`,
    privilegeAccess: { view: "71", add: "72", edit: "73", delete: "74", download: "75" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Member Code", width: "wd-150", val: "memberCode" },
        { field: "Member Name", width: "wd-200", val: "memberName" },
        { field: "Institution Type", width: "wd-200", val: "memberInstitutionType" },
        { field: "Country Code", width: "wd-200", val: "countryCode" },
        { field: "Action", width: "wd-150", val: "action" },
    ],
    toggle: ["no", "memberCode", "memberName", "memberInstitutionType", "countryCode", "action"],
    complete_toggle: ["no", "memberCode", "memberName", "memberInstitutionType", "countryCode", "action"],
    list_column_name: ["No.", "Member Code", "Member Name", "Institution Type", "Role Type", "Country Code"],
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Member Code, Member Name',

    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1300,
    dataLength: 0, // zIndex
    finalAPI: '',
    //
};


// memberId ModelMemberManagement
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelMemberManagement) => {
                        element1.id = element1.memberId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelMemberManagement) => {
                        element1.id = element1.memberId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelMemberManagement) => {
                        showDataResult.push(
                            { idModal: element1.memberId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.memberId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}


export default Page5;

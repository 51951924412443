



// ModelDisputeModal

interface ModelDisputeModalInterface {
    id: null | number | string;

    eventId: number | null;
    caseId: number | null;
    chargeIssCurrency: string | null;
    chargeIssAmount: number | null;
    chargeAcqCurrency: string | null;
    chargeAcqAmount: number | null;
    iss: string | null;
    issName: string | null;
    issContact: string | null;
    issEmail: string | null;
    acq: string | null;
    acqName: string | null;
    acqContact: string | null;
    acqEmail: string | null;
    eventType: string | null;
    eventTypeCode: string | null;
    eventReason: string | null;
    eventNote: string | null;
    eventStatus: string | null;
    eventStatusCode: string | null;
    responderMemberCode: string | null;
}

export class ModelModelDisputeModal {
    id: null | number | string;

    eventId: number | null;
    caseId: number | null;
    chargeIssCurrency: string | null;
    chargeIssAmount: number | null;
    chargeAcqCurrency: string | null;
    chargeAcqAmount: number | null;
    iss: string | null;
    issName: string | null;
    issContact: string | null;
    issEmail: string | null;
    acq: string | null;
    acqName: string | null;
    acqContact: string | null;
    acqEmail: string | null;
    eventType: string | null;
    eventTypeCode: string | null;
    eventReason: string | null;
    eventNote: string | null;
    eventStatus: string | null;
    eventStatusCode: string | null;
    responderMemberCode: string | null;


    constructor(value: ModelDisputeModalInterface | null) {
        this.id = value?.id ?? null;
        this.eventId = value?.eventId ?? null;
        this.caseId = value?.caseId ?? null;
        this.chargeIssCurrency = value?.chargeIssCurrency ?? null;
        this.chargeIssAmount = value?.chargeIssAmount ?? null;
        this.chargeAcqCurrency = value?.chargeAcqCurrency ?? null;
        this.chargeAcqAmount = value?.chargeAcqAmount ?? null;
        this.iss = value?.iss ?? null;
        this.issName = value?.issName ?? null;
        this.issContact = value?.issContact ?? null;
        this.issEmail = value?.issEmail ?? null;
        this.acq = value?.acq ?? null;
        this.acqName = value?.acqName ?? null;
        this.acqContact = value?.acqContact ?? null;
        this.acqEmail = value?.acqEmail ?? null;
        this.eventType = value?.eventType ?? null;
        this.eventTypeCode = value?.eventTypeCode ?? null;
        this.eventReason = value?.eventReason ?? null;
        this.eventNote = value?.eventNote ?? null;
        this.eventStatus = value?.eventStatus ?? null;
        this.eventStatusCode = value?.eventStatusCode ?? null;
        this.responderMemberCode = value?.responderMemberCode ?? null;
    }
}

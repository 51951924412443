import { feeManagementIcon } from "../../../Assets/Images/MenuIcon";
import { Page1FeeRoute } from "../../../Routes";
import { Page18Code } from "../CodeMenu";
import { Page18Title } from "../TitleMenu";

export const Menu7 = {
    id: 'fee_sidebar',
    label: 'Fee',
    icon: feeManagementIcon,
    privilegesId: [Page18Code],
    itemSidebar: [
        {
            titleList: Page18Title,
            key: 'feeOptions1',
            privilegeId: Page18Code,
            url: Page1FeeRoute,
        },
    ],
}
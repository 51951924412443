import { createSlice } from "@reduxjs/toolkit";


export const loadingSlice = createSlice({
    name: "loading",
    initialState: {
        condition: false 
    },
    reducers: {
        loadingAction: (state, action) => {
            state.condition = action.payload
        },
    }
});

export const { loadingAction } = loadingSlice.actions;
export const stateUser = (state: any) => state.loading.value;
export default loadingSlice.reducer;


// DetailSalesInquiry

interface DetailSalesInquiryInterface {
    id: null | number | string;
    postId: null | number;
    transDate: null | string;
    transTime: null | string;
    localTransDate: null | string;
    localTransTime: null | string;
    cpd: null | string;
    transId: null | string;
    transTypeCode: null | string;
    transType: null | string;
    productFeatureName: null | string;
    productFeatureInd: null | string;
    rrn: null | string;
    stan: null | string;
    invoiceNumber: null | string;
    approvalCode: null | string;
    responseCode: null | string;
    acqSwitcherId: null | string;
    acqSwitcherMemberId: null | string;
    acqSwitcherMemberName: null | string;
    acqSwitcherAmount: null | number;
    acqSwitcherCurrency: null | string;
    acqSwitcherCurrencyCode: null | string;
    acqSwitcherCountry: null | string;
    acqId: null | string;
    acqMemberId: null | string;
    acqMemberName: null | string;
    acqAmount: null | number;
    acqCurrency: null | string;
    acqCurrencyCode: null | string;
    acqCountry: null | string;
    merId: null | string;
    merName: null | string;
    terId: null | string;
    issSwitcherId: null | string;
    issSwitcherMemberId: null | string;
    issSwitcherMemberName: null | string;
    acqFeeAmount: null | number;
    issSwitcherAmount: null | number;
    issSwitcherCurrency: null | string;
    issSwitcherCurrencyCode: null | string;
    issSwitcherCountry: null | string;
    issId: null | string;
    issMemberId: null | string;
    issMemberName: null | string;
    issAmount: null | number;
    issCurrency: null | string;
    issCurrencyCode: null | string;
    issCountry: null | string;
    isHaveDisputeCase: Boolean | null;
    caseId: null | number;
}

export class ModelDetailSalesInquiry {
    id: null | number | string;
    postId: null | number;
    transDate: null | string;
    transTime: null | string;
    localTransDate: null | string;
    localTransTime: null | string;
    cpd: null | string;
    transId: null | string;
    transTypeCode: null | string;
    transType: null | string;
    productFeatureName: null | string;
    productFeatureInd: null | string;
    rrn: null | string;
    stan: null | string;
    invoiceNumber: null | string;
    approvalCode: null | string;
    responseCode: null | string;
    acqSwitcherId: null | string;
    acqSwitcherMemberId: null | string;
    acqSwitcherMemberName: null | string;
    acqSwitcherAmount: null | number;
    acqSwitcherCurrency: null | string;
    acqSwitcherCurrencyCode: null | string;
    acqSwitcherCountry: null | string;
    acqId: null | string;
    acqMemberId: null | string;
    acqMemberName: null | string;
    acqAmount: null | number;
    acqCurrency: null | string;
    acqCurrencyCode: null | string;
    acqCountry: null | string;
    merId: null | string;
    merName: null | string;
    terId: null | string;
    issSwitcherId: null | string;
    issSwitcherMemberId: null | string;
    issSwitcherMemberName: null | string;
    acqFeeAmount: null | number;
    issSwitcherAmount: null | number;
    issSwitcherCurrency: null | string;
    issSwitcherCurrencyCode: null | string;
    issSwitcherCountry: null | string;
    issId: null | string;
    issMemberId: null | string;
    issMemberName: null | string;
    issAmount: null | number;
    issCurrency: null | string;
    issCurrencyCode: null | string;
    issCountry: null | string;
    isHaveDisputeCase: Boolean | null;
    caseId: null | number;



    constructor(value: DetailSalesInquiryInterface | null) {
        this.id = value?.id ?? null;
        this.postId = value?.postId ?? null;
        this.transDate = value?.transDate ?? null;
        this.transTime = value?.transTime ?? null;
        this.localTransDate = value?.localTransDate ?? null;
        this.localTransTime = value?.localTransTime ?? null;
        this.cpd = value?.cpd ?? null;
        this.transId = value?.transId ?? null;
        this.transTypeCode = value?.transTypeCode ?? null;
        this.transType = value?.transType ?? null;
        this.productFeatureName = value?.productFeatureName ?? null;
        this.productFeatureInd = value?.productFeatureInd ?? null;
        this.rrn = value?.rrn ?? null;
        this.stan = value?.stan ?? null;
        this.invoiceNumber = value?.invoiceNumber ?? null;
        this.approvalCode = value?.approvalCode ?? null;
        this.responseCode = value?.responseCode ?? null;
        this.acqSwitcherId = value?.acqSwitcherId ?? null;
        this.acqSwitcherMemberId = value?.acqSwitcherMemberId ?? null;
        this.acqSwitcherMemberName = value?.acqSwitcherMemberName ?? null;
        this.acqSwitcherAmount = value?.acqSwitcherAmount ?? null;
        this.acqSwitcherCurrency = value?.acqSwitcherCurrency ?? null;
        this.acqSwitcherCurrencyCode = value?.acqSwitcherCurrencyCode ?? null;
        this.acqSwitcherCountry = value?.acqSwitcherCountry ?? null;
        this.acqId = value?.acqId ?? null;
        this.acqMemberId = value?.acqMemberId ?? null;
        this.acqMemberName = value?.acqMemberName ?? null;
        this.acqAmount = value?.acqAmount ?? null;
        this.acqCurrency = value?.acqCurrency ?? null;
        this.acqCurrencyCode = value?.acqCurrencyCode ?? null;
        this.acqCountry = value?.acqCountry ?? null;
        this.merId = value?.merId ?? null;
        this.merName = value?.merName ?? null;
        this.terId = value?.terId ?? null;
        this.issSwitcherId = value?.issSwitcherId ?? null;
        this.issSwitcherMemberId = value?.issSwitcherMemberId ?? null;
        this.issSwitcherMemberName = value?.issSwitcherMemberName ?? null;
        this.acqFeeAmount = value?.acqFeeAmount ?? null;
        this.issSwitcherAmount = value?.issSwitcherAmount ?? null;
        this.issSwitcherCurrency = value?.issSwitcherCurrency ?? null;
        this.issSwitcherCurrencyCode = value?.issSwitcherCurrencyCode ?? null;
        this.issSwitcherCountry = value?.issSwitcherCountry ?? null;
        this.issId = value?.issId ?? null;
        this.issMemberId = value?.issMemberId ?? null;
        this.issMemberName = value?.issMemberName ?? null;
        this.issAmount = value?.issAmount ?? null;
        this.issCurrency = value?.issCurrency ?? null;
        this.issCurrencyCode = value?.issCurrencyCode ?? null;
        this.issCountry = value?.issCountry ?? null;
        this.isHaveDisputeCase = value?.isHaveDisputeCase ?? null;
        this.caseId = value?.caseId ?? null;
    }
}



export const stateOptions = {
    optionsSpf: [],
    optionsMember: [],
    optionsMemberCode: [],
    optionsMemberCode2: [],
    optionsMemberCode3: [],
    optionsMemberId: [],
    OptionsMemberRoleType: [],
    optionsCommonStatus: [],
    optionsElementType: [],
    optionsElementTypeDetailCode: [],
    optionsElementFormat: [],
    optionsElementFormatDetailCode: [],
    optionsHashType: [],
    optionsEnvironmentType: [],
    optionsInstitutionType: [],
    optionsRoleType: [],
    optionsFieldMappingDetailCode: [],
    optionsBusinessGroupNameDetailCodeAndCcdName: [],
    optionsIssuerInstitutionIDDetailCode: [],
    optionsAcquirerInstitutionIDDetailCode: [],
    optionsSwitcherInstitutionIDDetailCode: [],
    optionsPaymentStatusDetailCode: [],
    optionsFeeRateInputTypeDetailCode: [],
    optionsDisputeCaseStatusDetailCode: [],
    optionsChargebackReasonDetailCode: [],
    optionsRePresentmentReasonDetailCode: [],
    optionsSAFStatusDetailCode: [],
    optionsServerStatusDetailCode: [],
    optionsLogLevelProgramClassificationDetailCode: [],
    optionsLogLevelDetailCode: [],
    optionsInterfaceFileFormatDetailCode: [],
    optionsFileStringFormatDetailCode: [],
    optionsFileStringSeparatorDetailCode: [],
    optionsCommonStatusDetailCode: [],
    optionsFileRecordOccurrenceDetailCode: [],
    optionsFileDataTypeDetailCode: [],
    optionsFileDataPaddingDetailCode: [],
    optionsFileDataPaddingOptionDetailCode: [],
    optionsBatchProgramTypeDetailCode: [],
    optionsUserCategoryDetailCode: [],
    optionsUserStatusDetailCode: [],
    optionsPaymentConfirmationMethodDetailCode: [],
    optionsBatchProgramStatusDetailCode: [],
    optionsReconciliationStatusDetailCodeAndCcdName: [],
    optionsBatchRegistrationTypeDetailCode: [],
    optionsBatchSchedulerStatusDetailCode: [],
    optionsBatchExecutionTypeDetailCode: [],
    optionsConnectionStatusDetailCode: [],
    optionsInterfaceFileRecordFormatUsedCCDNameAndCCDCode: [],
    // used ext and detail
    optionsIssuerInstitutionIDUseUseExtAndDetail: [],
    optionsAcquirerInstitutionIDUseUseExtAndDetail: [],
    optionsSwitcherInstitutionIDUseExtAndDetail: [],
    // used ext and detail desc
    optionsWalletInstitutionIDUseExtAndDetail: [],
    // ccdName and Code Detail
    optionsIssuerInstitutionIDUseCCDNameAndCodeDetail: [],
    optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail: [],
    optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail: [],
    optionsTransactionTypeDetailCodeAndCcdDesc: [],
    optionsBusinessGroupNameUseCCDNameAndCodeDetail: [],
    optionsBatchExecutionTypeUseCCDNameAndCodeDetail: [],
    optionsCommonCode:[],
    optionsCommonCode700:[],
    optionsCommonCode701:[],
    optionsCommonCode702:[],
    optionsCommonCode703:[],

    
    //
    optionsUserPrivilegeLevel: [],
    optionsFeeCalculationMethod: [],
    optionsFormat: [],
    optionsRouting: [],
    optionsRouteSequence: [],
    optionsQrMpmCpm: [],
    optionsPrivilege: [],
    optionsUserStatus: [],
    optionsUserLevel: [],
    optionsUserCategory: [],
    optionsCountry: [],
    optionsCountryCodeAndCurrencyCode: [],
    optionsCurrency: [],
    optionsCurrencyCcdName: [],
    optionsServerStatus: [],
    optionsConnectionStatus: [],
    optionsReasonCode: [],
    optionsReasonCodeUseId: [],
    optionsProduct: [],
    optionsProductNameAsValue: [],
    optionsQRStandardNameAsValue:[],
    optionsProductFeature: [],
    optionsProductFeatureWithAll: [],
    optionsProductFeatureWithAllOnlyProdFeatureInd: [],
    optionsProductFeatureProdFeatureIndAsValue: [],
    optionsProductFeatureProdFeatureIndAsValue2: [],
    optionsProductInd:[],
    optionsTransactionTypeDetailCodeAndCcdName: [],

    optionsIssuer: [],
    optionsIssuerSwitcher: [],
    optionsSwitcher: [],
    optionsAcquirer: [],
    optionsAcquirerSwitcher: [],
    optionsSwitcherCountry: [],
    optionsSubProduct: [],
    optionsRcGroup: [],
    optionsRateSetting: [],
    optionsElement: [],
    optionsQRStandard: [],
    optionsInterfaceField: [],
    optionsHeaderField: [],
    optionsResult:[],
    optionsFee: [],
    optionsGroupCode: [],
    optionsGroupCodeOnly: [],
    optionsService: [],
    optionsBatch: [],
    optionsCCD: [],
    optionsInterfaceFile: [],
    optionsInterfaceRecord: [],
    optionsInterfaceFileItem: [],
    optionsCountryCodeCurrencyCode: [],
    optionsMemberPICPayment: [],
    optionsMemberPICDispute: [],
    optionsBatchFromAPIBatch: [],
    optionsBatchFromAPIBatch2: [],
    optionsBatchSchedulerStatusUseCCDNameAndCodeDetail: [],
    // member instution type
    optionsInstitutionCode200: [],
    optionsInstitutionCode201: [],
    optionsInstitutionCode202: [],
    optionsInstitutionCode203: [],
    //
    optionsAcquireDate: [],
    optionsIssuerDate: [],
    optionsSwitcherInstitutionType: [],
    optionsTresholdPercent:[
        {value:5, label: '5% of Trans Daily'},
        {value:10, label: '10% of Trans Daily'},
        {value:25, label: '25% of Trans Daily'},
        {value:50, label: '50% of Trans Daily'},
        {value:75, label: '75% of Trans Daily'},
        {value:90, label: '90% of Trans Daily'},
    ],
    optionsSourceInstUnmatchedList:[
        {value:'all', label:'All'},
        {value:'sourceInst', label:'NITMX'}
    ],
    optionsOnOff:[
        { value: true, label: 'ON' },
        { value: false, label: 'OFF' },
    ],
    optionsYorN: [
        { value: true, label: 'Y' },
        { value: false, label: 'N' },
    ],
    optionsMOC: [
        { value: 'M', label: 'M' },
        { value: 'O', label: 'O' },
        { value: 'C', label: 'C' },
    ],
    optionsMOClowercase: [
        { value: 'm', label: 'm' },
        { value: 'o', label: 'o' },
        { value: 'c', label: 'c' },
    ],
    optionsWhitelistBlacklist: [
        { value: '01', label: 'Whitelist' },
        { value: '02', label: 'Blacklist' }
    ],
    optionsSendReceive: [
        { value: '000', label: 'N' },
        { value: '001', label: 'Y' },
    ],
    optionsLogLevel: [
        { value: 'INFO', label: 'INFO' },
        { value: 'ERROR', label: 'ERROR' },
        { value: 'DEBUG', label: 'DEBUG' },
        { value: 'WARN', label: 'WARN' },
    ],
    optionsSort: [
        { label: 'ASC', value: 'asc' },
        { label: 'DESC', value: 'desc' },
    ],
    optionsTestValid: [
        { value: '', label: 'All' },
        { value: 'true', label: 'Valid' },
        { value: 'false', label: 'Not Valid' },
    ],
}
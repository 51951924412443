import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelRoutingManagement } from './ModelRoutingManagement';
import { Page34Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalRoutingManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMemberId, optionsSwitcherInstitutionType } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])


    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { routeSourceMemberId, routeDestMemberId, routeForwardingId, routeDesc, routeName, routeTimeoutThreshold } = statePage.data
        const data = {
            // routeId: id,
            routeSourceMemberId: routeSourceMemberId,
            routeDestMemberId: routeDestMemberId,
            routeForwardingId: routeForwardingId,
            routeTimeoutThreshold: routeTimeoutThreshold,
            routeDesc: routeDesc,
            routeName: routeName,
        };
        const a = CheckAllFields([routeTimeoutThreshold, routeName], ['TIMEOUT THRESHOLD', 'NAME']);

        if (a !== "") {
            handleState('errorMessage', a);
        }
        else if (routeSourceMemberId === '' || routeDestMemberId === '') {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
        else if (routeSourceMemberId === '' || routeDestMemberId === '') {
            if (routeSourceMemberId === routeDestMemberId) {
                handleState('errorMessage', 'SOURCE MEMBER and DESTINATION MEMBER should be different');
            } else {
                handleState('errorMessage', ``);
                API.post({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    dispatchStatePage({ type: 'clearData' })
                    setModal("visibleModalAddDelete")
                    reloadData()
                }).catch((err) => handleState('errorMessage', err))
            }
        }else {
            if (routeSourceMemberId === routeDestMemberId) {
                handleState('errorMessage', 'SOURCE MEMBER and DESTINATION MEMBER should be different');
            } else {
                handleState('errorMessage', ``);
                API.post({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    dispatchStatePage({ type: 'clearData' })
                    setModal("visibleModalAddDelete")
                    reloadData()
                }).catch((err) => handleState('errorMessage', err))
            }
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>SOURCE MEMBER</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMemberId} value={optionsMemberId.filter((option: any) => option.value === statePage.data.routeSourceMemberId)} onChange={(e) => handleState('routeSourceMemberId', e?.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>DESTINATION MEMBER</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMemberId} value={optionsMemberId.filter((option: any) => option.value === statePage.data.routeDestMemberId)} onChange={(e) => handleState('routeDestMemberId', e?.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>FORWARDING ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsSwitcherInstitutionType} value={optionsSwitcherInstitutionType.filter((option: any) => option.value === statePage.data.routeForwardingId)} onChange={(e) => handleState('routeForwardingId', e?.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>timeout threshold</p>
                                <input type='number' min={0} value={statePage.data.routeTimeoutThreshold} onChange={(e) => handleState('routeTimeoutThreshold', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>NAME</p>
                                <input value={statePage.data.routeName} onChange={(e) => handleState('routeName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className="col-12">
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.data.routeDesc} onChange={(e) => handleState("routeDesc", e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page34Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelRoutingManagement({
        id: null,
        routeId: null,
        routeSourceMemberId: null,
        routeDestMemberId: null,
        routeForwardingId: null,
        routeTimeoutThreshold: 20,
        routeName: null,
        routeDesc: null,
        sourceMemberName: null,
        destMemberName: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelRoutingManagement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelRoutingManagement({
                    id: null,
                    routeId: null,
                    routeSourceMemberId: null,
                    routeDestMemberId: null,
                    routeForwardingId: null,
                    routeTimeoutThreshold: 20,
                    routeName: null,
                    routeDesc: null,
                    sourceMemberName: null,
                    destMemberName: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelRoutingManagement({
                    id: action.value.routeId,
                    routeId: action.value.routeId,
                    routeSourceMemberId: action.value.routeSourceMemberId,
                    routeDestMemberId: action.value.routeDestMemberId,
                    routeForwardingId: action.value.routeForwardingId,
                    routeTimeoutThreshold: action.value.routeTimeoutThreshold,
                    routeName: action.value.routeName,
                    routeDesc: action.value.routeDesc,
                    sourceMemberName: action.value.sourceMemberName,
                    destMemberName: action.value.destMemberName,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

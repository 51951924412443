import { Divider } from "antd";
import { ButtonAdd } from "./ButtonAdd";
import { ButtonDownload } from "./ButtonDownload";
import { SearchTable } from "./SearchTable";
import { ToggleTable } from "./ToggleTable";

export const DataTableFeaturesLimitOffset2 = ({ ...props }) => {
  const {
    stateParent,
    titleTable,
    data,
    label,
    privilegeAccess,
    checkIndex,
    setToggle,
    handleState,
    addData,
    exportToCSV,
    needAddButton = true,
    needDownloadButton = true,
    needSearchFunction = true,
    needToggleFunction = true,
    totalData
  } = props;

  return (
    <div className="row p-0 mt-4 mb-2 mx-2">
      <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12 m-0 d-flex align-items-center">
        <p className="Lato-400 s-2024 c-29">{titleTable}</p>
        <p className="br-5 bd-ea bg-f4 px-3 py-2 c-29 Lato-400 s-1418 mx-2">{totalData}</p>

        {
          needDownloadButton ?
            <div className="h-40px w-40px">
              <ButtonDownload handleClick={exportToCSV} codeAccessPrivilege={privilegeAccess.download} />
            </div>
            :
            <></>
        }
      </div>
      <div className="col-xl-8 col-lg-8 col-sm-12 col-xs-12 ">
        <div className="d-flex justify-content-end align-items-end w-100">
          <div className="h-50px w-100px">
            <ToggleTable needToggleFunction={needToggleFunction} label={label} checkIndex={checkIndex} setToggle={setToggle} />
          </div>
          <div className="h-50px w-200px mx-2">
            <SearchTable needSearchFunction={needSearchFunction} handleState={handleState} stateParent={stateParent}/>
          </div>
          {
            needAddButton ?
              <div className="h-50px w-50px">
                <div className="h-100">
                  <ButtonAdd handleClick={addData} codeAccessPrivilege={privilegeAccess.add} />
                </div>

              </div>
              :
              <></>
          }
        </div>
      </div>
    </div>
  );
};


import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { icon_search_white } from '../../Assets/Images/svg';
import { InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { inputStyle, labelStyle } from '../../Styles';
import { ExcelSalesInquiry } from './ExcelSalesInquiry';
import { InterfaceOptionsSalesInquiry } from './InterfaceOptionsSalesInquiry';
import { ModelSalesInquiry } from './ModelSalesInquiry';
import { TableSalesInquiry } from './TableSalesInquiry';
import Select from 'react-select';
import { ShowModalInterface } from '../../Interface';
import { ModalSalesInquiry } from './ModalSalesInquiry';



export const SalesInquiry = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const userLogin = useSelector((state: any) => state.user);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])


    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let selectedStartDate = stateCustomComponent.selectedStartDate !== '' ? `startDate=${stateCustomComponent.selectedStartDate}&` : '';
        let selectedEndDate = stateCustomComponent.selectedEndDate !== '' ? `endDate=${stateCustomComponent.selectedEndDate}&` : '';
        // issuer
        let selectedIssuerInstitutionIDDetailCode = stateCustomComponent.selectedIssuerInstitutionIDDetailCode !== '' ? `issuer=${stateCustomComponent.selectedIssuerInstitutionIDDetailCode}&` : '';
        let selectedIssuerSwitcherInstitutionIDDetailCode = stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode !== '' ? `issuerSwitcher=${stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode}&` : '';
        // acquirer
        let selectedAcquirerInstitutionIDDetailCode = stateCustomComponent.selectedAcquirerInstitutionIDDetailCode !== '' ? `acquirer=${stateCustomComponent.selectedAcquirerInstitutionIDDetailCode}&` : '';
        let selectedAcquirerSwitcherInstitutionIDDetailCode = stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode !== '' ? `acquirerSwitcher=${stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode}&` : '';
        // etc
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let inputTransId = state.transId !== '' ? `transId=${state.transId}&` : '';
        let inputRrn = state.rrn !== '' ? `rrn=${state.rrn}&` : '';
        let inputInvoiceNumber = state.invoiceNumber !== '' ? `invoiceNumber=${state.invoiceNumber}&` : '';
        let inputApprovalCode = state.approvalCode !== '' ? `approvalCode=${state.approvalCode}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${search}${limit}${offset}${selectedStartDate}${selectedEndDate}${selectedIssuerInstitutionIDDetailCode}${selectedIssuerSwitcherInstitutionIDDetailCode}${selectedAcquirerInstitutionIDDetailCode}${selectedAcquirerSwitcherInstitutionIDDetailCode}${inputTransId}${inputRrn}${inputInvoiceNumber}${inputApprovalCode}`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsSalesInquiry, dispatchGlobal)
    }, [])

    useEffect(() => {
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(true)
    }, [state.search, stateCustomComponent.selectedMember])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    useEffect(() => {
        dispatchCustomComponent({ type: 'selectedStartDate', value: new Date().toISOString().slice(0, 10) })
        dispatchCustomComponent({ type: 'selectedEndDate', value: new Date().toISOString().slice(0, 10) })
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsSalesInquiry, dispatchGlobal)
    }, [])

    const exportToCSVComponent = () => ExcelSalesInquiry(state, stateOptions)
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelSalesInquiry) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelSalesInquiry, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
            </div>
        );
    };

    const filterComponentPage = () => {
        return (
            <div className="row gx-5 gy-2 container-all-select-custom px-2 py-4 my-4">
                <div className="col-12 row gy-2">
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>Start Date</p>
                        <input value={stateCustomComponent.selectedStartDate} onChange={(e) => handleState('selectedStartDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />

                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>End Date</p>
                        <input value={stateCustomComponent.selectedEndDate} onChange={(e) => handleState('selectedEndDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />
                    </div>
                </div>
                {
                    `${userLogin.transId}` === "1" ?
                        <div className="col-12 row gy-2">
                            <div className="col-md-3 col-sm-6">
                                <p className={labelStyle}>ISSUER</p>
                                <Select options={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail} value={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedIssuerInstitutionIDDetailCode)} onChange={(e) => handleState("selectedIssuerInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <p className={labelStyle}>ISSUER SWITCHER</p>
                                <Select options={stateOptions.optionsSwitcherInstitutionIDDetailCode} value={stateOptions.optionsSwitcherInstitutionIDDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode)} onChange={(e) => handleState("selectedIssuerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <p className={labelStyle}>ACQUIRER SWITCHER</p>
                                <Select
                                    options={stateOptions.optionsSwitcherInstitutionIDDetailCode.map((element: any) => {
                                        if (element.value !== "00") {
                                        }
                                        return element;
                                    })}
                                    value={stateOptions.optionsSwitcherInstitutionIDDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode)}
                                    onChange={(e) => handleState("selectedAcquirerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <p className={labelStyle}>ACQUIRER</p>
                                <Select options={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail} value={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerInstitutionIDDetailCode)} onChange={(e) => handleState("selectedAcquirerInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
                            </div>
                        </div>
                        :
                        <></>
                }
                <div className="col-12 row my-2">
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>TRANS ID</p>
                        <input value={state.transId} onChange={(e) => handleState('transId', e.target.value, dispatch)} type='text' className={inputStyle} />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>RRN</p>
                        <input value={state.rrn} onChange={(e) => handleState('rrn', e.target.value, dispatch)} type='text' className={inputStyle} />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>INVOICE NUMBER</p>
                        <input value={state.invoiceNumber} onChange={(e) => handleState('invoiceNumber', e.target.value, dispatch)} type='text' className={inputStyle} />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <p className={labelStyle}>APPROVAL CODE</p>
                        <input value={state.approvalCode} onChange={(e) => handleState('approvalCode', e.target.value, dispatch)} type='text' className={inputStyle} />
                    </div>
                </div>
                <div className="col-12 row my-2">
                    <div className='d-flex'>
                        <button className="btn d-flex align-items-center px-4 py-3 bg-e8 h-50px " onClick={() => initFunctionCustom(true)}>
                            {icon_search_white}
                            <p className="s-1418 c-ff Lato-600 mx-2">Search</p>
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableSalesInquiry
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                editData={(item: ModelSalesInquiry) => actionShowModal(item.id, item)}
                //handleClick={() => actionShowModal(item.id, item)} 
                currentPage={state.currentPage}
            />
        )
    }

    const modalAddDeleteComponentPage = () => {
        return <></>
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalSalesInquiry
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={false}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={true}
                needSearchFunction={true}
                needDownloadButton={true}
                //
                needComponentHeaderTableCenter={true}
            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'sales_inquiry',
    titlePage: 'Sales Inquiry',
    privilegeAccess: { view: '441', add: '442', edit: '443', delete: '444', download: '445' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Trans Date', width: 'wd-100', val: 'transDate' },
        { field: 'Central Processing Date', width: 'wd-100', val: 'cpdDate' },
        { field: 'Trans Type', width: 'wd-100', val: 'transType' },
        { field: 'Issuer Amount', width: 'wd-150', val: 'issuerAmount' }, // issuerCurrency  issuerAmount
        { field: 'Issuer Switcher Amount', width: 'wd-200', val: 'issSwitcherAmount' }, // issuerSwitcherCurrency issSwitcherAmount
        { field: 'Acquirer Switcher Amount', width: 'wd-200', val: 'acquirerSwitcherAmount' }, //  acquirerSwitcherCurrency acquirerSwitcherAmount
        { field: 'Acquirer Amount', width: 'wd-150', val: 'acquirerAmount' }, // acquirerCurrency acquirerAmount
        { field: 'Issuer', width: 'wd-150', val: 'issuerMemberName' },
        { field: 'Issuer Switcher', width: 'wd-150', val: 'issuerSwitcherMemberName' },
        { field: 'Acquirer Switcher', width: 'wd-150', val: 'acquirerSwitcherMemberName' },
        { field: 'Acquirer', width: 'wd-150', val: 'acquirerMemberName' },
        { field: 'Trans ID', width: 'wd-200', val: 'transId' },
        { field: 'RRN', width: 'wd-150', val: 'rrn' },
        { field: 'Approval Code', width: 'wd-100', val: 'approvalCode' },
        { field: 'Invoice Number', width: 'wd-200', val: 'invoiceNumber' },
        { field: 'Edit Code', width: 'wd-100', val: 'editCode' },
    ],
    toggle: ['no', 'transDate', 'cpdDate', 'transType', 'issuerAmount', 'issSwitcherAmount', 'acquirerSwitcherAmount', 'acquirerAmount', 'issuerMemberName', 'issuerSwitcherMemberName', 'acquirerSwitcherMemberName', 'acquirerMemberName', 'transId', 'rrn', 'approvalCode', 'invoiceNumber', 'editCode'],
    complete_toggle: ['no', 'transDate', 'cpdDate', 'transType', 'issuerAmount', 'issSwitcherAmount', 'acquirerSwitcherAmount', 'acquirerAmount', 'issuerMemberName', 'issuerSwitcherMemberName', 'acquirerSwitcherMemberName', 'acquirerMemberName', 'transId', 'rrn', 'approvalCode', 'invoiceNumber', 'editCode'],
    list_column_name: ['No.', 'Trans Date', 'Central Processing Date', 'Trans Type', 'Issuer Amount', 'Issuer Switcher Amount', 'Acquirer Switcher Amount', 'Acquirer Amount', 'Issuer', 'Issuer Switcher', 'Acquirer Switcher', 'Acquirer', 'Trans ID', 'RRN', 'Approval Code', 'Invoice Number', 'Edit Code'],
    // 
    modalCondition: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Trans ID, RRN, Invoice Number',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 2600,
    dataLength: 0, // zIndex
    //
    transId: '',
    rrn: '',
    invoiceNumber: '',
    approvalCode: '',
};


// postId ModelSalesInquiry
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSalesInquiry) => {
                        element1.id = element1.transId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSalesInquiry) => {
                        element1.id = element1.transId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSalesInquiry) => {
                        showDataResult.push(
                            { idModal: element1.transId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.transId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        //
        case 'transId':
            return {
                ...state,
                transId: action.value
            };
        case 'rrn':
            return {
                ...state,
                rrn: action.value
            };
        case 'invoiceNumber':
            return {
                ...state,
                invoiceNumber: action.value
            };
        case 'approvalCode':
            return {
                ...state,
                approvalCode: action.value
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'modalCondition':
            return {
                ...state,
                modalCondition: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                modalCondition: !(state.modalCondition),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                modalCondition: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };

        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        default:
            throw new Error();
    }
}

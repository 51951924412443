import { createSlice } from "@reduxjs/toolkit";



const initialState = () => {
  let dateNow = new Date().toISOString().slice(0, 10);
  return {
    transId: "",
    transAuthIntResponseCode: "", // search for status => reason code
    statusSendToApi: "", // search for status => reason code
    transStatusName: "", // reason code name
    transCurrencyCode: "", // currency code
    acqSwitchCurrencyCode: "", // currency code
    issSwitchCurrencyCode: "", // currency code
    issuerId: "", // search for issuer
    acquirerId: "", // search for acquirer
    issuerSwitcherId: "", // search for issuerSwitcherId
    acquirerSwitcherId: "", // search for acquirerSwitcherId
    //
    issuerIdCode: "", // search for issuerCode
    acquirerIdCode: "", // search for acquirerCode
    issuerSwitcherIdCode: "", // search for issuerSwitcherIdCode
    acquirerSwitcherIdCode: "", // search for acquirerSwitcherIdCode
    // Product
    selectedProductId: "",
    selectedProductLabel: "",
    selectedProductSendToApi: "",
    // Product Feature
    selectedProductFeatureId: "",
    selectedProductFeatureLabel: "",
    selectedProductFeatureSendToApi: "",
    // 
    filterStartDate: dateNow, // search for by start date
    filterEndDate: dateNow, // // search for by end date
    search: "",

    product: null,
    productFeature: null,

    // 05/05/2023
    approvalCode: '',
    STAN : '',
    RRN: '',
    InvoiceNumber: '',
    GlobalID: '',
    MID: '',
    TID: '',
    transactionAmount: '',

    // test transactions
    testCaseId: '',
    testCat: '',
    selectedTestValid: '',
  }
}

export const transactionTestInquirySlice = createSlice({
  name: "transactionTestInquiry",
  initialState: initialState(),
  reducers: {
    changeStateTransactionTestInquiry: (state, action) => {
      state.transAuthIntResponseCode = action.payload.transAuthIntResponseCode === undefined || action.payload.transAuthIntResponseCode === null ? state.transAuthIntResponseCode : action.payload.transAuthIntResponseCode;
      state.statusSendToApi = action.payload.statusSendToApi === undefined || action.payload.statusSendToApi === null ? state.statusSendToApi : action.payload.statusSendToApi;
      state.transStatusName = action.payload.transStatusName === undefined || action.payload.transStatusName === null ? state.transStatusName : action.payload.transStatusName;
      state.transCurrencyCode = action.payload.transCurrencyCode === undefined || action.payload.transCurrencyCode === null ? state.transCurrencyCode : action.payload.transCurrencyCode;
      state.issSwitchCurrencyCode = action.payload.issSwitchCurrencyCode === undefined || action.payload.issSwitchCurrencyCode === null ? state.issSwitchCurrencyCode : action.payload.issSwitchCurrencyCode;
      state.acqSwitchCurrencyCode = action.payload.acqSwitchCurrencyCode === undefined || action.payload.acqSwitchCurrencyCode === null ? state.acqSwitchCurrencyCode : action.payload.acqSwitchCurrencyCode;
      state.issuerId = action.payload.issuerId === undefined || action.payload.issuerId === null ? state.issuerId : action.payload.issuerId;
      state.acquirerId = action.payload.acquirerId === undefined || action.payload.acquirerId === null ? state.acquirerId : action.payload.acquirerId;
      state.issuerSwitcherId = action.payload.issuerSwitcherId === undefined || action.payload.issuerSwitcherId === null ? state.issuerSwitcherId : action.payload.issuerSwitcherId;
      state.acquirerSwitcherId = action.payload.acquirerSwitcherId === undefined || action.payload.acquirerSwitcherId === null ? state.acquirerSwitcherId : action.payload.acquirerSwitcherId;

      state.issuerIdCode = action.payload.issuerIdCode === undefined || action.payload.issuerIdCode === null ? state.issuerIdCode : action.payload.issuerIdCode;
      state.acquirerIdCode = action.payload.acquirerIdCode === undefined || action.payload.acquirerIdCode === null ? state.acquirerIdCode : action.payload.acquirerIdCode;
      state.issuerSwitcherIdCode = action.payload.issuerSwitcherIdCode === undefined || action.payload.issuerSwitcherIdCode === null ? state.issuerSwitcherIdCode : action.payload.issuerSwitcherIdCode;
      state.acquirerSwitcherIdCode = action.payload.acquirerSwitcherIdCode === undefined || action.payload.acquirerSwitcherIdCode === null ? state.acquirerSwitcherIdCode : action.payload.acquirerSwitcherIdCode;

      state.selectedProductId = action.payload.selectedProductId === undefined || action.payload.selectedProductId === null ? state.selectedProductId : action.payload.selectedProductId;
      state.selectedProductLabel = action.payload.selectedProductLabel === undefined || action.payload.selectedProductLabel === null ? state.selectedProductLabel : action.payload.selectedProductLabel;
      state.selectedProductSendToApi = action.payload.selectedProductSendToApi === undefined || action.payload.selectedProductSendToApi === null ? state.selectedProductSendToApi : action.payload.selectedProductSendToApi;
      state.selectedProductFeatureId = action.payload.selectedProductFeatureId === undefined || action.payload.selectedProductFeatureId === null ? state.selectedProductFeatureId : action.payload.selectedProductFeatureId;
      state.selectedProductFeatureLabel = action.payload.selectedProductFeatureLabel === undefined || action.payload.selectedProductFeatureLabel === null ? state.selectedProductFeatureLabel : action.payload.selectedProductFeatureLabel;
      state.selectedProductFeatureSendToApi = action.payload.selectedProductFeatureSendToApi === undefined || action.payload.selectedProductFeatureSendToApi === null ? state.selectedProductFeatureSendToApi : action.payload.selectedProductFeatureSendToApi;

      state.transId = action.payload.transId === undefined || action.payload.transId === null ? state.transId : action.payload.transId;
      state.product = action.payload.product === undefined || action.payload.product === null ? state.product : action.payload.product;
      state.productFeature = action.payload.productFeature === undefined || action.payload.productFeature === null ? state.productFeature : action.payload.productFeature;
      state.filterStartDate = action.payload.filterStartDate === undefined || action.payload.filterStartDate === null ? state.filterStartDate : action.payload.filterStartDate;
      state.filterEndDate = action.payload.filterEndDate === undefined || action.payload.filterEndDate === null ? state.filterEndDate : action.payload.filterEndDate;
      state.search = action.payload.search === undefined || action.payload.search === null ? state.search : action.payload.search;
      // 05/05/2023
      state.approvalCode = action.payload.approvalCode === undefined || action.payload.approvalCode === null ? state.approvalCode : action.payload.approvalCode;
      state.STAN = action.payload.STAN === undefined || action.payload.STAN === null ? state.STAN : action.payload.STAN;
      state.RRN = action.payload.RRN === undefined || action.payload.RRN === null ? state.RRN : action.payload.RRN;
      state.InvoiceNumber = action.payload.InvoiceNumber === undefined || action.payload.InvoiceNumber === null ? state.InvoiceNumber : action.payload.InvoiceNumber;
      state.GlobalID = action.payload.GlobalID === undefined || action.payload.GlobalID === null ? state.GlobalID : action.payload.GlobalID;
      state.MID = action.payload.MID === undefined || action.payload.MID === null ? state.MID : action.payload.MID;
      state.TID = action.payload.TID === undefined || action.payload.TID === null ? state.TID : action.payload.TID;
      state.transactionAmount = action.payload.transactionAmount === undefined || action.payload.transactionAmount === null ? state.transactionAmount : action.payload.transactionAmount;
      // test data
      state.testCaseId = action.payload.testCaseId === undefined || action.payload.testCaseId === null ? state.testCaseId : action.payload.testCaseId;
      state.testCat = action.payload.testCat === undefined || action.payload.testCat === null ? state.testCat : action.payload.testCat;
      state.selectedTestValid = action.payload.selectedTestValid === undefined || action.payload.selectedTestValid === null ? state.selectedTestValid : action.payload.selectedTestValid;

    },
    resetAllActionPage2: (state, action) => {
      state.transAuthIntResponseCode = '';
      state.statusSendToApi = '';
      state.transStatusName = '';
      state.transCurrencyCode = '';
      state.acqSwitchCurrencyCode = '';
      state.issSwitchCurrencyCode = '';
      state.issuerId = '';
      state.acquirerId = '';
      state.issuerSwitcherId = '';
      state.acquirerSwitcherId = '';

      state.issuerIdCode = '';
      state.acquirerIdCode = '';
      state.issuerSwitcherIdCode = '';
      state.acquirerSwitcherIdCode = '';

      state.selectedProductId = '';
      state.selectedProductLabel = '';
      state.selectedProductSendToApi = '';
      state.selectedProductFeatureId = '';
      state.selectedProductFeatureLabel = '';
      state.selectedProductFeatureSendToApi = '';

      state.transId = action.payload;
      state.product = null;
      state.productFeature = null;
      state.filterStartDate = '';
      state.filterEndDate = '';
      state.search = '';
      // 05/05/2023
      state.approvalCode = '';
      state.STAN = '';
      state.RRN = '';
      state.InvoiceNumber = '';
      state.GlobalID = '';
      state.MID = '';
      state.TID = '';
      state.transactionAmount = '';
      // test field
      state.testCaseId = '';
      state.testCat = '';
      state.selectedTestValid = '';
    }
  },
});

// Action creators are generated for each case reducer function
export const { changeStateTransactionTestInquiry, resetAllActionPage2 } = transactionTestInquirySlice.actions;
export const stateGlobalTransactionTestInquiry = (state: any) => state.transactionTestInquiry;
export default transactionTestInquirySlice.reducer;

export const CheckAllFields = (allFields: any[], messageAllFields: any[]): string => {
    let desc = "";
    let fields: any[] = [];
    allFields.map((item, i) => {
        if (item === "" || item === undefined || item === null) {
            fields.push(messageAllFields[i]);
        }
    });
    if (fields.length === 1) desc = fields + " is required";
    else if (fields.length > 1) desc = fields + " are required";
    else desc = "";
    return desc;
};
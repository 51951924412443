import { useCallback, useEffect, useReducer, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { Buffer } from "buffer";

// date util libraries
import { format, isSameMinute, differenceInDays } from "date-fns";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { ShowModalInterface } from "../../Interface";
import { API, urlSelection } from "../../Services/API";
import {
  addData, checkIndex, deleteData,
  handleState, initFunction, reducerOptions,
  reducerStateSelected, setModal, setToggle,
  sortData, stateOptions, stateSelected
} from "../../State";
import { Page21Title } from "../../State/Menu/TitleMenu";
import { CheckUndefined, ExportCSV } from "../../Utils";
import { store } from '../../Store/Store';
import { transactionJourneyGlobalState, handleStateAction, resetAllAction } from "../../Store/TransactionJourney";
import { DesktopViewTransactionJourney } from "./DesktopViewTransactionJourney";
import { InterfaceOptionsLogAnalyzer } from "./InterfaceOptionsLogAnalyzer";
import { ModelLogAnalyzer } from "./ModelLogAnalyzer";
import { MobileViewTransactionJourney } from "./MobileViewTransactionJourney";
import { IsDateRangeWithinDays } from "../../Utils/IsDateRangeWithinDays";

dayjs.extend(customParseFormat);

const ExcelLogAnalyzer = (state: any, stateOptions: any, dataFromAPI: any) => {
  let newList: any[] = [];
  let listData: any[] = [];
  dataFromAPI.map((item: any, i: any) => {
    const m = {
      no: i + 1,
      logDate: CheckUndefined(item.logDate),
      logDateTime: CheckUndefined(item.logDateTime),
      logChannel: CheckUndefined(item.logChannel),
      memberSrcName: CheckUndefined(item.memberSrcName),
      memberDstName: CheckUndefined(item.memberDstName),
      nodeSrc: CheckUndefined(item.nodeSrc),
      nodeProc: CheckUndefined(item.nodeProc),
      nodeDst: CheckUndefined(item.nodeDst),
      productInd: CheckUndefined(item.productInd),
      authGwStatus: CheckUndefined(item.authGwStatus),
      authRspnCode: CheckUndefined(item.authRspnCode),
      globalId: CheckUndefined(item.globalId),
      logName: CheckUndefined(item.logName),
    };
    state.complete_toggle.map((item: any) => {
      if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m];
    });
    listData.push(m);
  });

  state.complete_toggle.map((item: any) => {
    if (state.toggle.includes(item)) {
      newList.push(state.list_column_name[state.complete_toggle.indexOf(item)]);
    }
  });
  ExportCSV(newList, listData, state.titlePage);
};

export const LogAnalyzer = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const globalId = searchParams.get('globalId');
  const walletUserPan = searchParams.get('walletUserPan');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');

  const stateGlobal = useSelector(transactionJourneyGlobalState);
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const [errorMessage, setErrorMessage] = useState({
    globalId: '',
    rrn: '',
    stan: '',
    invNo: '',
    date: '',
    cpan: '',
    midMpan: ''
  });

  useEffect(() => {
    if (privilegeAccess !== null) {
      if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
        navigate("/warning", { replace: true });
      }
    }
  }, [privilegeAccess]);

  const initFunctionCustom = (needRefreshPage: Boolean | null, needTime: boolean) => {
    let selectedMemberSrcCode = stateGlobal.selectedMemberSrcCode !== "" ? `memberSrcCode=${stateGlobal.selectedMemberSrcCode}&` : "";
    let selectedMemberDstCode = stateGlobal.selectedMemberDstCode !== "" ? `memberDstCode=${stateGlobal.selectedMemberDstCode}&` : "";
    let selectedProductInd = stateGlobal.selectedProductInd !== "" ? `productInd=${stateGlobal.selectedProductInd}&` : "";
    let selectedGlobalId = stateGlobal.selectedGlobalId !== "" ? `globalId=${stateGlobal.selectedGlobalId}&` : "";
    let selectedAuthRrn = stateGlobal.selectedAuthRrn !== "" ? `authRrn=${stateGlobal.selectedAuthRrn}&` : "";
    let selectedAuthStan = stateGlobal.selectedAuthStan !== "" ? `authStan=${stateGlobal.selectedAuthStan}&` : "";
    let selectedInvNo = stateGlobal.selectedInvNo !== "" ? `invNo=${stateGlobal.selectedInvNo}&` : "";
    let selectedAuthRspnCode = stateGlobal.selectedAuthRspnCode !== "" ? `authRspnCode=${stateGlobal.selectedAuthRspnCode}&` : "";
    let selectedServerId = stateGlobal.selectedServerId !== "" ? `serverId=${stateGlobal.selectedServerId}&` : "";
    let selectedStartDate = stateGlobal.selectedStartDate !== "" ? `startDate=${stateGlobal.selectedStartDate}&` : "";
    let selectedEndDate = stateGlobal.selectedEndDate !== "" ? `endDate=${stateGlobal.selectedEndDate}&` : "";
    let selectedStartTime = stateGlobal.selectedStartTime !== "" ? `startTime=${stateGlobal.selectedStartTime}&` : "";
    let selectedEndTime = stateGlobal.selectedEndTime !== "" ? `endTime=${stateGlobal.selectedEndTime}&` : "";
    let selectedFxRate = stateGlobal.selectedFxRate !== "" ? `fxRate=${stateGlobal.selectedFxRate}&` : "";
    let selectedSim = stateGlobal.selectedSim !== "" ? `sim=${stateGlobal.selectedSim}&` : "";
    let selectedCpan = stateGlobal.selectedCpan !== "" ? `cpan=${stateGlobal.selectedCpan}&` : "";
    let selectedMidMpan = stateGlobal.selectedMidMpan !== "" ? `midMpan=${stateGlobal.selectedMidMpan}&` : "";
    let limit = `limit=${pagination.pageSize}&`;
    let offset = `offset=${pagination.pageIndex * pagination.pageSize}&`;
    let finalParameter = needTime === true ? `${limit}${offset}${selectedMemberSrcCode}${selectedMemberDstCode}${selectedProductInd}${selectedInvNo}${selectedAuthRrn}${selectedAuthRspnCode}${selectedServerId}${selectedGlobalId}${selectedAuthStan}${selectedFxRate}${selectedSim}${selectedCpan}${selectedMidMpan}${selectedStartDate}${selectedEndDate}${selectedStartTime}${selectedEndTime}` : `${limit}${offset}${selectedMemberSrcCode}${selectedMemberDstCode}${selectedProductInd}${selectedInvNo}${selectedAuthRrn}${selectedAuthRspnCode}${selectedServerId}${selectedGlobalId}${selectedAuthStan}${selectedFxRate}${selectedSim}${selectedCpan}${selectedMidMpan}`;
    let finalParameter2 = needTime === true ? `${selectedMemberSrcCode}${selectedMemberDstCode}${selectedProductInd}${selectedInvNo}${selectedAuthRrn}${selectedAuthRspnCode}${selectedServerId}${selectedGlobalId}${selectedAuthStan}${selectedFxRate}${selectedSim}${selectedCpan}${selectedMidMpan}${selectedStartDate}${selectedEndDate}${selectedStartTime}${selectedEndTime}` : `${limit}${offset}${selectedMemberSrcCode}${selectedMemberDstCode}${selectedProductInd}${selectedInvNo}${selectedAuthRrn}${selectedAuthRspnCode}${selectedServerId}${selectedGlobalId}${selectedAuthStan}${selectedFxRate}${selectedSim}${selectedCpan}${selectedMidMpan}`;

    handleStateComponent("finalAPI", `${finalParameter2.slice(0, -1)}`);

    if (needRefreshPage === true) {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.log,
        useToken: true,
        needLoading: true,
        needNotification: true,
      }).then((response: any) => {
        dispatch({ type: "searchData", value: response?.data });
        dispatch({ type: "totalPages", value: response?.detail.totalPage });
        dispatch({ type: "totalData", value: response?.detail.total });
      });
    } else {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.log,
        useToken: true,
        needLoading: true,
        needNotification: true,
      }).then((response: any) => {
        dispatch({ type: "data", value: response?.data });
        dispatch({ type: "totalPages", value: response?.detail.totalPage });
        dispatch({ type: "totalData", value: response?.detail.total });
      });
    }
  };

  useEffect(() => {
    initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsLogAnalyzer, dispatchGlobal);
  }, []);

  useEffect(() => {
    if (window.opener && window.opener.data) {
      handleGlobalState('selectedGlobalId', window.opener.data.globalId);
      handleGlobalState('selectedStartDate', window.opener.data.startDate);
      handleGlobalState('selectedEndDate', window.opener.data.endDate);
      handleGlobalState('selectedStartTime', window.opener.data.startTime);
      handleGlobalState('selectedEndTime', window.opener.data.endTime);
      window.opener.data = undefined;
    }
  }, []);

  useEffect(() => {
    if (location.search) {
      handleGlobalState('locationSearch', location.search);
    }

    if (location.search && (globalId || walletUserPan) && startDate && endDate) {
      if (globalId) handleGlobalState('selectedGlobalId', globalId);
      if (walletUserPan) handleGlobalState('selectedCpan', walletUserPan);
      handleGlobalState('selectedStartDate', startDate);
      handleGlobalState('selectedEndDate', endDate);
      handleGlobalState('selectedEndTime', '23:59:59');
    }
  }, [location.search, globalId, walletUserPan, startDate, endDate]);

  useEffect(() => {
    initFunctionCustom(false, true);
  }, [pagination.pageIndex, pagination.pageSize, state.search]);

  useEffect(() => {
    if (state.data.length > 0) {
      // grouping data by globalId using reduce
      const groupedData = state.data.reduce((acc: any[], item: any) => {
        const group = acc.find((group) => group.globalId === item.globalId);
        if (group) {
          // if a group exists for the globalId, push the item into the group's data array
          group.data.push(item);
        } else {
          // otherwise, create a new group
          acc.push({ globalId: item.globalId, data: [item] });
        }
        return acc;
      }, []);

      // flattening grouped data using flatMap
      const flattenedData = groupedData.flatMap((group: any) =>
        group.data.map((dataItem: any) => ({
          ...dataItem,
          globalId: group.globalId,
        }))
      );

      handleGlobalState("groupedData", flattenedData);
    } else {
      handleGlobalState("groupedData", []);
    }
  }, [state.data]);

  useEffect(() => {
    if (errorMessage.globalId !== '' || errorMessage.rrn !== '' || errorMessage.stan !== '' || errorMessage.invNo !== ''
      || errorMessage.date !== '' || errorMessage.cpan !== '' || errorMessage.midMpan !== '') {
      dispatch({ type: "errorMessageFilter", value: "Please resolve invalid input" })
    } else {
      dispatch({ type: "errorMessageFilter", value: "" })
    }
  }, [errorMessage]);

  useEffect(() => {
    let currentPage: number = parseInt((state.position / state.view).toString());
    dispatch({ type: "currentPage", value: currentPage });
  }, [state.totalData, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
  }, [state.view]);

  useEffect(() => {
    const maxRangeDays = 7;
    if (stateGlobal.selectedStartDate) {
      const isCurrentTime = isSameMinute(stateGlobal.selectedStartDate, new Date());

      if (isCurrentTime) {
        handleGlobalState('selectedStartTime', '00:00:01');
      }

      if (IsDateRangeWithinDays(stateGlobal.selectedEndDate, stateGlobal.selectedStartDate, maxRangeDays)) {
        setErrorMessage((prevState: any) => ({
          ...prevState,
          "date": "Maximum range for start~end date is 7 days"
        }))
      } else {
        setErrorMessage((prevState: any) => ({
          ...prevState,
          "date": ""
        }))
      }
    }
  }, [stateGlobal.selectedStartDate])

  useEffect(() => {
    const maxRangeDays = 7;
    if (stateGlobal.selectedEndDate) {
      if (IsDateRangeWithinDays(stateGlobal.selectedEndDate, stateGlobal.selectedStartDate, maxRangeDays)) {
        setErrorMessage((prevState: any) => ({
          ...prevState,
          "date": "Maximum range for start~end date is 7 days"
        }))
      } else {
        setErrorMessage((prevState: any) => ({
          ...prevState,
          "date": ""
        }))
      }
    }
  }, [stateGlobal.selectedEndDate])

  const handleGlobalState = (nameState: string, valueState: any) => {
    // if (stateGlobal[nameState] !== valueState) {
    dispatchGlobal(handleStateAction({ name: nameState, value: valueState }));
    // }
  };

  const handleRowClick = (rowData: any) => {
    dispatch({ type: "id", value: rowData.globalId });
    dispatch({ type: "dataModal", value: rowData });
    dispatch({ type: "visibleModalAddDelete", value: !state.visibleModalAddDelete });
  };

  const handleResetFilter = () => {
    handleGlobalState("selectedGlobalId", '');
    handleGlobalState("selectedAuthRrn", '');
    handleGlobalState("selectedAuthStan", '');
    handleGlobalState("selectedInvNo", '');
    handleGlobalState("selectedAuthRspnCode", '');
    handleGlobalState("selectedServerId", '');
    handleGlobalState("selectedMemberSrcName", '');
    handleGlobalState("selectedMemberSrcCode", '');
    handleGlobalState("selectedMemberDstName", '');
    handleGlobalState("selectedMemberDstCode", '');
    handleGlobalState("selectedProductInd", '');
    handleGlobalState("selectedFxRate", '');
    handleGlobalState("selectedSim", '');
    handleGlobalState("selectedCpan", '');
    handleGlobalState("selectedMidMpan", '');

    let dateNow = new Date().toISOString().slice(0, 10);
    handleGlobalState("selectedStartDate", dateNow);
    handleGlobalState("selectedEndDate", dateNow);

    handleGlobalState("selectedStartTime", '00:00:01');
    handleGlobalState("selectedEndTime", format(new Date(), 'HH:mm:ss'));

    setErrorMessage((prevState: any) => ({
      ...prevState,
      "date": ""
    }))
  }

  const exportToCSVComponent = () => {
    API.getFile({
      bodyCustom: null,
      pathCustom: `dashboard/log/xlsx?${state.finalAPI}`,
      selectUrl: urlSelection.log,
      useToken: true,
      needLoading: true,
    }).then((response: any) => {
      try {
        (window as any).Buffer = Buffer;
        const data = `data:${response.headers["content-type"]};base64,${new Buffer(response.data, "binary").toString("base64")}`;
        saveAs(data, `${state.titlePage}.xlsx`);
      } catch (error) {
        console.log("something wrong");
      }
    });
  };

  const addDataComponent = () => addData(dispatch);
  const deleteDataComponent = (item: ModelLogAnalyzer) => deleteData(item.id, item, dispatch);
  const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state);
  const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch);
  const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state);
  const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state);
  const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage, true), null, InterfaceOptionsLogAnalyzer, dispatchGlobal)
  const actionShowModal = (idModal: any, item: any) => dispatch({ type: "showModal", value: { idModal, showModal: item.showModal, itemModal: item } });
  const actionIndexModal = (idModal: any) => dispatch({ type: "changeIndex", value: idModal });
  const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false, true);

  const customButtonAction = (item: ModelLogAnalyzer, width: string | any) => {
    return <div className={`d-flex justify-content-around align-items-center ${width}`}></div>;
  };

  // 1. Response Code
  // - All (default) -> not sent
  // - Approved (Response code 00 / 0000) -> 00
  // - Not Approved (Response code other than 00 or null) -> 01
  // 2.Server
  // - All (default) -> not sent
  // - API Server -> SV
  // - API Gateway -> GW

  const optionsResponseCode: any = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Approved",
      value: "00",
    },
    {
      label: "Not Approved",
      value: "01",
    },
  ];

  const optionsServer: any = [
    {
      label: "All",
      value: "",
    },
    {
      label: "API Server",
      value: "SW",
    },
    {
      label: "API Gateway",
      value: "GW",
    },
  ];

  return (
    <>
      <div id="dekstop-view" className="d-none d-sm-block">
        <DesktopViewTransactionJourney
          state={state}
          stateOptions={stateOptions}
          optionsResponseCode={optionsResponseCode}
          optionsServer={optionsServer}
          pagination={pagination}
          errorMessage={errorMessage}
          setPagination={setPagination}
          setErrorMessage={setErrorMessage}
          initFunctionCustom={initFunctionCustom}
          handleGlobalState={handleGlobalState}
          handleResetFilter={handleResetFilter}
          handleStateComponent={handleStateComponent}
          handleRowClick={handleRowClick}
          exportToCSVComponent={exportToCSVComponent}
          reloadDataComponent={reloadDataComponent}
          dispatch={dispatch}
        />
      </div>

      <div id="mobile-view" className="mt-2 d-block d-sm-none">
        <MobileViewTransactionJourney
          state={state}
          stateOptions={stateOptions}
          optionsResponseCode={optionsResponseCode}
          optionsServer={optionsServer}
          pagination={pagination}
          errorMessage={errorMessage}
          setPagination={setPagination}
          setErrorMessage={setErrorMessage}
          initFunctionCustom={initFunctionCustom}
          handleGlobalState={handleGlobalState}
          handleResetFilter={handleResetFilter}
          handleStateComponent={handleStateComponent}
          handleRowClick={handleRowClick}
          exportToCSVComponent={exportToCSVComponent}
          reloadDataComponent={reloadDataComponent}
          dispatch={dispatch}
        />
      </div>
    </>
  );
};

const initialStateOptions = { ...stateOptions };

const initialState = {
  urlApi: "dashboard/log",
  titlePage: Page21Title,
  privilegeAccess: { view: "511", add: "", edit: "", delete: "", download: "" },
  visibleModalAddDelete: false,
  savedShowModal: [], // new
  //
  id: "",
  data: [],
  dataCopy: [],
  dataModal: null,
  search: "",
  view: 25,
  position: 25,
  list_view: [25, 50, 75, 100, 500],
  totalData: 0, // new
  totalPages: 0,
  currentPage: 0,
  insertPage: false,
  deleteItem: false,
  //
  dataLength: 0, // zIndex
  finalAPI: "",
  //
  errorMessageFilter: "",
  columnActiveAsc: "",
  columnActiveDsc: "",
};

// logId ModelLogAnalyzer
function reducer(state: any, action: any) {
  let showDataResult: ShowModalInterface[] = [];
  let dataWhereChangeToIdGeneral: any[] = [];
  let newSavedShowModal: ShowModalInterface[] = [];
  switch (action.type) {
    case "data":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelLogAnalyzer) => {
            element1.id = element1.logId;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case "searchData":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelLogAnalyzer) => {
            element1.id = element1.logId;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        position: state.view,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case "showModal":
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            let conditionShowModalFind = false;
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value.idModal) {
                conditionShowModalFind = true;
              }
            });
            if (conditionShowModalFind === false) {
              newSavedShowModal.push({ idModal: action.value.idModal, showModal: !action.value.showModal, itemModal: action.value.itemModal, index: state.dataLength + 99 });
              newSavedShowModal.push(...state.savedShowModal);
            } else {
              state.savedShowModal.forEach((element1: ShowModalInterface) => {
                if (element1.idModal === action.value.idModal) {
                  if (element1.showModal === false) {
                    // will to show
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !element1.showModal, itemModal: action.value.itemModal, index: state.dataLength + 99 });
                  } else {
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !element1.showModal, itemModal: action.value.itemModal, index: 99 });
                  }
                } else {
                  newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 });
                }
              });
            }
          } else {
            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !action.value.showModal, itemModal: action.value.itemModal, index: 99 });
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case "changeIndex":
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value) {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 });
              } else {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 });
              }
            });
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case "afterUpdate":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelLogAnalyzer) => {
            showDataResult.push({ idModal: element1.logId!, showModal: false, itemModal: element1, index: 1 });
            element1.id = element1.logId;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        showModal: showDataResult,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    //

    case "errorMessageFilter":
      return {
        ...state,
        errorMessageFilter: action.value,
      };
    case "columnActiveAsc":
      return {
        ...state,
        columnActiveAsc: action.value,
      };
    case "columnActiveDsc":
      return {
        ...state,
        columnActiveDsc: action.value,
      };
    case "dataCopy":
      return {
        ...state,
        dataCopy: action.value,
      };
    //
    case "visibleModalAddDelete":
      return {
        ...state,
        visibleModalAddDelete: action.value,
      };
    case "id":
      return {
        ...state,
        id: action.value,
      };
    case "privilegeAccess":
      return {
        ...state,
        privilegeAccess: action.value,
      };
    case "deleteItem":
      return {
        ...state,
        deleteItem: action.value,
      };
    case "list_view":
      return {
        ...state,
        list_view: action.value,
      };
    case "view":
      return {
        ...state,
        view: action.value,
      };
    case "position":
      return {
        ...state,
        position: action.value,
      };
    case "search":
      return {
        ...state,
        search: action.value,
      };
    case "dataModal":
      return {
        ...state,
        dataModal: action.value,
      };
    case "insertPage":
      return {
        ...state,
        insertPage: action.value,
      };
    case "addData":
      return {
        ...state,
        id: "",
        dataModal: null,
        visibleModalAddDelete: !state.visibleModalAddDelete,
      };
    case "editData":
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        visibleModalAddDelete: true,
      };
    case "deleteData":
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        deleteItem: true,
      };
    case "changeData":
      return {
        ...state,
        totalPages: action.value.totalPages,
        currentPage: action.value.currentPage,
      };

    // new
    case "totalData":
      return {
        ...state,
        totalData: action.value,
      };
    case "totalPages":
      return {
        ...state,
        totalPages: action.value,
      };
    case "currentPage":
      return {
        ...state,
        currentPage: action.value,
      };
    case "finalAPI":
      return {
        ...state,
        finalAPI: action.value,
      };
    default:
      throw new Error();
  }
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const changeStartDateAction = createAsyncThunk(
  "transactionMonitoring/changeStartDateAction",
  async (filterStartDate: string, thunkAPI) => {
    try {
      // const response = await fetch("https://api.example.com/data");
      // const data = await response.json();
      // return data;
      return filterStartDate;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  });

interface transactionMonitoringInterface {
  dataFilter: any[],
  dataCopyFilter: any[],
  dataByDate: any[],            // new placeholder data to hold original value
  dataCopyByDate: any[],
  dataYesterday: any[],
  dataYesterdayByDate: any[],   // new placeholder data to hold original value
  dataYesterdayCopyByDate: any[],
  filterStartDate: string,
  filterEndDate: string,
  // ProductFeature
  selectedProductFeatureId: number | string,
  selectedProductFeatureLabel: string,
  selectedProductFeatureSendToApi: string,
  // Product
  selectedProductId: number | string,
  selectedProductLabel: string,
  selectedProductSendToApi: string,
  //
  search: string,
  optionsProductFeature: any[],
  optionsProduct: any[],
  listMember: any[], // for color static pie chart when click data color not change
  listReasonCode: any[], // for show status table transaction => 'APPROVED', 'DECLINED', 'TIMEOUT', 'PRE-DECLINED', 'FORMAT ERROR'
  rangeDate: number | string,
}


function initialState(): transactionMonitoringInterface {
  let dateStart = new Date();
  dateStart.setDate(dateStart.getDate() - 30);
  let dateStartUsed = dateStart.toISOString().slice(0, 10);
  return {
    dataFilter: [],
    dataCopyFilter: [],
    dataByDate: [],
    dataCopyByDate: [],
    dataYesterday: [],
    dataYesterdayByDate: [],
    dataYesterdayCopyByDate: [],
    filterStartDate: new Date().toISOString().slice(0, 10),// dateStartUsed,
    filterEndDate: new Date().toISOString().slice(0, 10),
    // ProductFeature
    selectedProductFeatureId: "", // 1
    selectedProductFeatureLabel: "",
    selectedProductFeatureSendToApi: "",
    // Product
    selectedProductId: "", // 1
    selectedProductLabel: "",
    selectedProductSendToApi: "",
    //
    search: "",
    optionsProductFeature: [],
    optionsProduct: [],
    listMember: [], // for color static pie chart when click data color not change
    listReasonCode: [], // for show status table transaction => 'APPROVED', 'DECLINED', 'TIMEOUT', 'PRE-DECLINED'
    rangeDate: 1,

  }
}

export const transactionMonitoringSlice = createSlice({
  name: "transactionMonitoring",
  initialState: initialState(),
  reducers: {
    changeStateTransactionMonitoring: (state, action) => {
      state.dataFilter = action.payload.dataFilter === undefined                || null ? state.dataFilter : action.payload.dataFilter;
      state.dataCopyFilter = action.payload.dataFilter === undefined            || null ? state.dataFilter : action.payload.dataFilter;
      state.dataByDate = action.payload.dataByDate === undefined                || null ? state.dataByDate : action.payload.dataByDate;
      state.dataCopyByDate = action.payload.dataByDate === undefined            || null ? state.dataByDate : action.payload.dataByDate;
      state.dataYesterday = action.payload.dataYesterday === undefined          || null ? state.dataYesterday : action.payload.dataYesterday;
      state.dataYesterdayByDate =
        action.payload.dataYesterdayByDate === undefined                        || null ? state.dataYesterdayByDate : action.payload.dataYesterdayByDate;
      state.dataYesterdayCopyByDate =
        action.payload.dataYesterdayCopyByDate === undefined                    || null ? state.dataYesterdayByDate : action.payload.dataYesterdayByDate;
      state.filterStartDate = action.payload.filterStartDate === undefined      || null ? state.filterStartDate : action.payload.filterStartDate;
      state.filterEndDate = action.payload.filterEndDate === undefined          || null ? state.filterEndDate : action.payload.filterEndDate;
      state.selectedProductFeatureId =
        action.payload.selectedProductFeatureId === undefined                   || null ? state.selectedProductFeatureId : action.payload.selectedProductFeatureId;
      state.selectedProductFeatureLabel =
        action.payload.selectedProductFeatureLabel === undefined                || null ? state.selectedProductFeatureLabel : action.payload.selectedProductFeatureLabel;
      state.selectedProductFeatureSendToApi =
        action.payload.selectedProductFeatureSendToApi === undefined            || null ? state.selectedProductFeatureSendToApi : action.payload.selectedProductFeatureSendToApi;
      state.selectedProductId = action.payload.selectedProductId === undefined  || null ? state.selectedProductId : action.payload.selectedProductId;
      state.selectedProductLabel = 
        action.payload.selectedProductLabel === undefined                       || null ? state.selectedProductLabel : action.payload.selectedProductLabel;
      state.selectedProductSendToApi = 
        action.payload.selectedProductSendToApi === undefined                   || null ? state.selectedProductSendToApi : action.payload.selectedProductSendToApi;
      state.optionsProductFeature = 
        action.payload.optionsProductFeature === undefined                      || null ? state.optionsProductFeature : action.payload.optionsProductFeature;
      state.optionsProduct = action.payload.optionsProduct === undefined        || null ? state.optionsProduct : action.payload.optionsProduct;
      state.search = action.payload.search === undefined                        || null ? state.search : action.payload.search;
      state.listMember = action.payload.listMember === undefined                || null ? state.listMember : action.payload.listMember;
      state.listReasonCode = action.payload.listReasonCode === undefined        || null ? state.listReasonCode : action.payload.listReasonCode;
      state.rangeDate = action.payload.rangeDate === undefined                  || null ? state.rangeDate : action.payload.rangeDate;
    },
    changeStateDataCopyFilterTransactionMonitoring: (state, action) => {
      // used for filtering data when clicking on Pie Chart 
      state.dataCopyFilter = action.payload.dataCopyFilter === undefined  || null ? state.dataCopyFilter : action.payload.dataCopyFilter;
      state.dataCopyByDate = action.payload.dataCopyByDate === undefined  || null ? state.dataCopyByDate : action.payload.dataCopyByDate;
      state.dataYesterday = action.payload.dataYesterday === undefined    || null ? state.dataYesterday : action.payload.dataYesterday;
      state.dataYesterdayCopyByDate =
        action.payload.dataYesterdayCopyByDate === undefined              || null ? state.dataYesterdayCopyByDate : action.payload.dataYesterdayCopyByDate;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(changeStartDateAction.pending, (state) => { })
      .addCase(changeStartDateAction.fulfilled, (state, action) => {

        state.filterStartDate = action.payload;
      })
      .addCase(changeStartDateAction.rejected, (state, action) => { });
  },
});

export const { changeStateTransactionMonitoring, changeStateDataCopyFilterTransactionMonitoring } = transactionMonitoringSlice.actions;
export const stateGlobalTransactionMonitoring = (state: any) => state.transactionMonitoring;
export default transactionMonitoringSlice.reducer;

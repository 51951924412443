
// import { useSelector } from "react-redux";
// import { CheckUndefined } from "../../Utils";
// import { Divider } from "antd";
// import { HeaderComponent } from "../../Components";
// import { Page2JobSchedulerRoute } from "../../Routes";


// export const TableSchedulerManagement = ({ ...props }) => {
//     const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

//     return (
//         <div className='d-flex flex-column w-100 overflow mb-2'>
//             <div className={`d-flex flex-column ${props.widthTable}`}>
//                 <HeaderComponent
//                     codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
//                     codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
//                     label={props.label}
//                     data={props.data}
//                     position={props.position}
//                     view={props.view}
//                     customButtonAction={props.customButtonAction}
//                     checkIndex={props.checkIndex}
//                     widthTable={props.widthTable}
//                     stateOptions={props.stateOptions}
//                 />
//                 <Divider className="my-2" />


//                 {props.data.map((item: any, index: any) => {
//                     let number = index + ((props.currentPage - 1) * props.view)

//                     return (
//                         <a
//                             key={number}
//                             className={`d-flex justify-content-between align-items-center min-h-50px px-4 table-data-pointer  ${number % 2 === 0 ? 'bg-f4' : ''} `}
//                             // onClick={() => props.handleShowModal(item)}

// href={`${Page2JobSchedulerRoute}/details?batchExecutionType=${item.batchExecutionType === '' ? '' : item.batchExecutionType}&batchBusinessGroup=${item.batchBusinessGroup === ''? '' : item.batchBusinessGroup}`}
// target="_blank"

//                         >
//                             {props.label.map((element1: any) => {
//                                 if (element1.val === 'no') {
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{number + 1}</p>;
//                                 } else if (element1.val === 'action') {
//                                     if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
//                                         return props.checkIndex(`${element1.val}`) && <div className={`d-flex  s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
//                                     } else {
//                                         return <></>;
//                                     }
//                                 } 
//                                  else if (element1.val === "batchBusinessGroup") {
//                                     let dataReturn = `${item[`${element1.val}`]}`;
//                                     if (props.stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.length > 0) {
//                                         props.stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;
//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 } else if (element1.val === "batchExecutionType") {
//                                     let dataReturn = `${item[`${element1.val}`]}`;
//                                     if (props.stateOptions.optionsBatchExecutionTypeDetailCode.length > 0) {
//                                         props.stateOptions.optionsBatchExecutionTypeDetailCode.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;
//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 }
//                                 else {
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
//                                 }
//                             })}
//                         </a>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// }

// export default TableSchedulerManagement;
import {
    MaterialReactTable,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { CheckUndefined } from '../../Utils';
import { ButtonAdd, SelectView } from '../../Components';
import { Divider } from 'antd';


export const TableSchedulerManagement = ({ ...props }) => {
    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom, reloadDataComponent,
        handleStateParent,
        setPagination,
        pagination,
    } = props;
    const columns: any = useMemo(
        () => [
            {
                header: 'Batch Business Group',
                accessorKey: 'batchBusinessGroup',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    let dataReturn = `${CheckUndefined(row.original.batchBusinessGroup)}`
                    if (props.stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.length > 0) {
                        props.stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.forEach((element2: any) => {
                            if (element2.value === row.original.batchBusinessGroup) {
                                dataReturn = `${element2.label}`;
                            }
                        });
                    }
                    return dataReturn
                },
                // `${CheckUndefined(row.original.batchBusinessGroup)}`,
            },
            {
                header: 'Batch Execution Type',
                accessorKey: 'batchExecutionType',
                size: 'auto',
                // Cell: ({ row }: any) =>
                //     `${CheckUndefined(row.original.batchExecutionType)}`,
                Cell: ({ row }: any) => {
                    let dataReturn = `${CheckUndefined(row.original.batchExecutionType)}`
                    if (props.stateOptions.optionsBatchExecutionTypeDetailCode.length > 0) {
                        props.stateOptions.optionsBatchExecutionTypeDetailCode.forEach((element2: any) => {
                            if (element2.value === row.original.batchExecutionType) {
                                dataReturn = `${element2.label}`;
                            }
                        });
                    }
                    return dataReturn
                },
            },
            {
                header: 'Batch Group Path',
                accessorKey: 'batchGroupPath',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchGroupPath)}`,
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: {
            pagination,
            // globalFilter: state.search || ''
        },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableGlobalFilter: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                onClick: () => props.handleShowModal(row.original),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        muiSearchTextFieldProps: {
            placeholder: `Search By ${state.SearchByPlaceHolder}`,
            size: 'small',
            title: `Search By ${state.SearchByPlaceHolder}`,
        },

        onGlobalFilterChange: (filterValue) => {
            if (filterValue === undefined) {
                handleStateGlobal('search', '')
            }
            handleStateGlobal('search', filterValue)
        },

        renderTopToolbarCustomActions: ({ table }) => (
            <div className="justify-content-between pt-2">
                <div className="col-6 d-flex align-items-center">
                    <p className="Lato-400 s-1822 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
            </div>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleGlobalFilterButton table={table} />
                <div className="h-30px w-30px">
                    <div className="h-100">
                        <ButtonAdd handleClick={props.addData} codeAccessPrivilege={state.privilegeAccess.add} />
                    </div>

                </div>
            </>
        ),

    });
    return (
        <>
            <div id="table" className="mt-2 mb-5 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    );
};

import { useSelector } from "react-redux"
import { CheckUndefinedStringEmpty } from "../../../Utils";
import { inputStyleH25, labelStyle } from "../../../Styles";
import { errNotificationIcon, undo_icon } from "../../../Assets/Images/svg";
import Select from "react-select";
import moment from "moment";
import { ButtonSearch } from "../../../Components/ButtonSearch";
import { stateGlobalQRAuthSimulatorInquiry } from "../../../Store/QRAuthSimulatorInquirySlice";
import { ButtonSearchBlue } from "../../../Components/ButtonSearchBlue";
import { DatePickerRighIcon } from "../../../Components/DatepickerRightIcon";
import { format } from 'date-fns';
import { DatePickerBlueRightIcon } from "../../../Components";


export const FilterComponentQRAuthSimulatorInquiry = ({ ...props }) => {
    const globalState = useSelector(stateGlobalQRAuthSimulatorInquiry);
    const { state, stateOptions, stateCustomComponent, handleStateGlobal, resetFilterStatus, initFunctionCustom } = props;
    //  console.log(stateOptions.optionsCommonCode)
    const {
        testCat,
        testCaseId,
        isTestValid,
        qrRefNo,
        QRAuthInqResponseCode,
        QRStatusSendToApi,
        QRStatus,
        stan,
        invNo,
        globalId,
        memberId,
        qrCpmPan,
        qrMpmMerId,
        filterStartDate,
        filterEndDate,
        search,
        selectedQRStandardId,
        selectedQRStandardName,
        selectedQRStandardSendToApi,
        selectedProductIndId,
        selectedProductIndLabel,
        selectedProductIndSendToApi,
        productInd,
        qrsId,
    } = globalState;

    const userLogin = useSelector((state: any) => state.user)

    return (<div className='d-flex flex-column w-100'>
        <div className='row bg-ff br-10 bd-d4 gx-2 gy-2 px-2'>
            <div className='col-2'>
                <p className={labelStyle}>TEST CATEGORY</p>

                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={testCat}
                        maxLength={64}
                        onChange={(e) => {
                            const newValue = e.target.value.toUpperCase();
                            handleStateGlobal('testCat', newValue);
                            resetFilterStatus();
                            // handleStateGlobal('testCat', e.target.value);
                            // resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleStateGlobal('testCat', ''); resetFilterStatus() }} >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>TEST CASE ID</p>

                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={testCaseId}
                        maxLength={64}
                        onChange={(e) => {
                            const newValue = e.target.value.toUpperCase();
                            handleStateGlobal('testCaseId', newValue);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleStateGlobal('testCaseId', ''); resetFilterStatus() }} >
                        {undo_icon}
                    </div>
                </div>
            </div>

            <div className='col-2'>
                <p className={labelStyle}>TEST RESULT</p>
                <div className='d-flex justify-content-between'>
                    <Select
                        placeholder={''}
                        className="flex-fill"
                        options={stateOptions.optionsResult}
                        value={stateOptions.optionsResult.filter((option: any) => option.value === isTestValid)}
                        styles={inputStyleH25}
                        onChange={(e) => {
                            handleStateGlobal('isTestValid', e.value);
                            // handleStateGlobal('selectedQRStandardSendToApi', e.sendToApi);
                            resetFilterStatus()
                        }}

                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => {
                            handleStateGlobal('isTestValid', '');
                            resetFilterStatus()
                        }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>

            <div className='col-2'>
                <p className={labelStyle}>QR REF NO</p>

                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={qrRefNo}
                        maxLength={12}
                        onChange={(e) => {
                            handleStateGlobal('qrRefNo', e.target.value);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleStateGlobal('qrRefNo', ''); resetFilterStatus() }} >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>QR STAN</p>
                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={stan}
                        maxLength={6}
                        onChange={(e) => {
                            handleStateGlobal('stan', e.target.value);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleStateGlobal('stan', ''); resetFilterStatus() }} >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>QR INV NO</p>
                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={invNo}
                        maxLength={64}
                        onChange={(e) => {
                            handleStateGlobal('invNo', e.target.value);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => { handleStateGlobal('invNo', ''); resetFilterStatus() }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>GLOBAL ID</p>
                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={globalId}
                        maxLength={24}
                        onChange={(e) => {
                            handleStateGlobal('globalId', e.target.value);
                            resetFilterStatus()

                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => { handleStateGlobal('globalId', ''); resetFilterStatus() }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>QR STANDARD</p>
                <div className='d-flex justify-content-between'>
                    <Select
                        placeholder={''}
                        className="flex-fill"
                        options={stateOptions.optionsQRStandard}
                        value={stateOptions.optionsQRStandard.filter((option: any) => option.value === selectedQRStandardId)}
                        styles={inputStyleH25}
                        onChange={(e) => {
                            handleStateGlobal('selectedQRStandardId', e.value);
                            // handleStateGlobal('selectedQRStandardSendToApi', e.sendToApi);
                            resetFilterStatus()
                        }}

                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => {
                            handleStateGlobal('selectedQRStandardId', '');
                            handleStateGlobal('selectedQRStandardSendToApi', '');
                            resetFilterStatus()
                        }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>PRODUCT INDICATOR</p>
                <div className='d-flex justify-content-between'>
                    <Select
                        placeholder={''}
                        className="flex-fill"
                        options={stateOptions.optionsProductInd}
                        value={stateOptions.optionsProductInd.filter((option: any) => option.value === selectedProductIndId)}
                        styles={inputStyleH25}
                        onChange={(e) => {
                            handleStateGlobal('selectedProductIndId', e.value);
                            handleStateGlobal('selectedProductIndSendToApi', e.sendToApi);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => {
                            handleStateGlobal('selectedProductIndId', '');
                            handleStateGlobal('selectedProductIndSendToApi', '');
                            resetFilterStatus()

                        }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>QR STATUS</p>
                <div className='d-flex justify-content-between'>
                    <Select
                        placeholder={''}
                        className="flex-fill"
                        options={stateOptions.optionsCommonCode}
                        value={stateOptions.optionsCommonCode.filter((option: any) => option.value === QRStatusSendToApi)}
                        styles={inputStyleH25}
                        onChange={(e) => {
                            handleStateGlobal('QRStatusSendToApi', e.value);
                            handleStateGlobal('QRStatus', e.label);
                            resetFilterStatus()


                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => {
                            handleStateGlobal('QRStatusSendToApi', '');
                            handleStateGlobal('QRStatus', '');
                            resetFilterStatus()
                        }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>MEMBER ID</p>
                <div className='d-flex justify-content-between'>
                    <Select placeholder={''}
                        className="flex-fill"
                        options={stateOptions.optionsMemberCode}
                        value={stateOptions.optionsMemberCode.filter((option: any) => option.value === memberId)}
                        styles={inputStyleH25}
                        onChange={(e) => {
                            handleStateGlobal('memberId', e.value);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => {
                            handleStateGlobal('memberId', '');
                            resetFilterStatus()
                        }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>

            <div className='col-2'>
                <p className={labelStyle}>CPAN</p>
                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={qrCpmPan}
                        maxLength={64}
                        onChange={(e) => {
                            handleStateGlobal('qrCpmPan', e.target.value);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => { handleStateGlobal('qrCpmPan', ''); resetFilterStatus() }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>MPAN</p>
                <div className='d-flex justify-content-between'>
                    <input
                        className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                        placeholder=''
                        value={qrMpmMerId}
                        maxLength={128}
                        onChange={(e) => {
                            handleStateGlobal('qrMpmMerId', e.target.value);
                            resetFilterStatus()
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => { handleStateGlobal('qrMpmMerId', ''); resetFilterStatus() }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>Start transaction date</p>
                <div className='d-flex justify-content-between'>
                    <DatePickerBlueRightIcon
                        needTimeInput={false}
                        todayButton="today"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        selected={new Date(`${filterStartDate}` || '')}
                        onChange={(date: any) => {
                            handleStateGlobal(
                                'filterStartDate',
                                format(date, 'yyyy-MM-dd')
                            );
                            if (filterEndDate === '') {
                                let dateNow = new Date()
                                    .toISOString()
                                    .slice(0, 10);
                                handleStateGlobal('filterEndDate', dateNow);
                            }
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => { handleStateGlobal('filterStartDate', ''); resetFilterStatus() }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>
            <div className='col-2'>
                <p className={labelStyle}>End transaction date</p>
                <div className='d-flex'>
                    <DatePickerBlueRightIcon
                        needTimeInput={false}
                        todayButton="today"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        selected={new Date(`${filterEndDate}` || '')}
                        onChange={(date: any) => {
                            handleStateGlobal(
                                'filterEndDate',
                                format(date, 'yyyy-MM-dd')
                            );
                            if (filterStartDate === '') {
                                let dateNow = new Date()
                                    .toISOString()
                                    .slice(0, 10);
                                handleStateGlobal('filterStartDate', dateNow);
                            }
                        }}
                    />
                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px'
                        onClick={(e) => { handleStateGlobal('filterEndDate', ''); resetFilterStatus() }}
                    >
                        {undo_icon}
                    </div>
                </div>
            </div>

            {state.errorMessage !== '' && (
                <div className='col-12 pl-0'>
                    <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                        {errNotificationIcon}
                        <p className='px-2 s-1418 Lato-600 c-e87'>{state.errorMessage}</p>
                    </div>
                </div>

            )}
            <div className='d-flex justify-content-end'>
                <div className='d-flex p-2 justify-content-end'>
                    <button className='btn d-flex bg-d5 h-30px mx-1 mr-100px'
                        onClick={(e) => {
                            handleStateGlobal('testCat', '');
                            handleStateGlobal('testCaseId', '');
                            handleStateGlobal('isTestValid', '');
                            handleStateGlobal('qrRefNo', '');
                            handleStateGlobal('stan', '');
                            handleStateGlobal('invNo', '');
                            handleStateGlobal('globalId', '');
                            handleStateGlobal('QRStatusSendToApi', '');
                            handleStateGlobal('memberId', '');
                            handleStateGlobal('selectedQRStandardId', '');
                            handleStateGlobal('selectedQRStandardSendToApi', '');
                            handleStateGlobal('qrCpmPan', '');
                            handleStateGlobal('qrMpmMerId', '');
                            handleStateGlobal('selectedProductIndId', '');
                            handleStateGlobal('selectedProductIndSendToApi', '');
                            let dateNow = new Date().toISOString().slice(0, 10);
                            handleStateGlobal('filterStartDate', dateNow)
                            handleStateGlobal('filterEndDate', dateNow)

                        }}
                    >

                        {/* {undo_icon} */}
                        <svg style={{ width: "20px", height: "20px" }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                fill="#FFFFFF" // Set the fill color to white
                            />
                        </svg>
                        <p className="s-1418 c-ff Lato-600 mx-2">Reset</p>
                    </button>
                </div>
                <div className='d-flex'> 
                    <ButtonSearchBlue filterStartDate={filterStartDate} initFunctionCustom={initFunctionCustom} />
                </div>
            </div>

        </div>
    </div>
    )
}




import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleReadOnly, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelRouteSequence } from './ModelRouteSequence';
import { Page54Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalRouteSequence = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsCommonStatusDetailCode, optionsRouting, optionsRouteSequence } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'rsName') {
            if (valueState.split('').length < 33) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'rsReqSeqVal') {
            if (parseInt(valueState) > 0) {
                if (valueState.split('').length < 3) dispatchStatePage({ type: nameState, value: valueState })
            }
        } else if (nameState === 'rsResSeqVal') {
            if (parseInt(valueState) > 0) {
                if (valueState.split('').length < 3) dispatchStatePage({ type: nameState, value: valueState })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
                needLoading: true,
            }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { rsReqId, rsResId, rsReqSeqVal, rsResSeqVal, rsName, rsDesc, rsStatus, rsNextRsId } = statePage.data

        const data = {
            // rsId: id,
            rsReqId: rsReqId, // o
            rsReqSeqVal: rsReqId === '' || rsReqId === null ? null : rsReqSeqVal, // o or m
            rsResId: rsResId, // o
            rsResSeqVal: rsResId === '' || rsResId === null ? null : rsResSeqVal, // o or m
            rsName: rsName, // m
            rsDesc: rsDesc, // o
            rsStatus: rsStatus, // mendatory
            rsNextRsId: rsNextRsId, // o
        };

        let a = '';
        let condition = [rsName, rsStatus];
        let conditionString = ['Name', 'Status'];
        if (rsReqId !== null) {
            condition.push(rsReqId);
            conditionString.push('Req RID')
            condition.push(rsReqSeqVal);
            conditionString.push('Req RID Sequence')
        }
        if (rsResId !== null) {
            condition.push(rsResId);
            conditionString.push('Res RID')
            condition.push(rsResSeqVal);
            conditionString.push('Res RID Sequence')
        }
        a = CheckAllFields(condition, conditionString);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,

                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,

            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    let newOptionsRouting = [];
    newOptionsRouting.push({ value: null, label: 'null' })
    if (optionsRouting.length > 0) {
        optionsRouting.forEach((element1: any) => {
            newOptionsRouting.push({ value: element1.routeId, label: element1.routeName === null ? 'Route Name is unknown' : element1.routeName });
        })
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>

                            {id !== '' ?
                                <div className='col-6'>
                                    <p className={labelStyle}>Route Sequence ID</p>
                                    <input value={id} disabled className={inputStyleReadOnly} />
                                </div>
                                :
                                <></>
                            }

                            <div className='col-6'>
                                <p className={labelStyle}>NAME</p>
                                <input value={statePage.data.rsName} onChange={(e) => handleState('rsName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Req RID</p>
                                <Select placeholder={'(Search)'} options={newOptionsRouting} value={newOptionsRouting.filter((option: any) => option.value === statePage.data.rsReqId)} onChange={(e) => handleState('rsReqId', e?.value)} />
                            </div>

                            {statePage.data.rsReqId === null || statePage.data.rsReqId === '' ?
                                <div className='col-6'>
                                    <p className={labelStyle}>Req RID Sequence</p>
                                    <input type={'number'} min={0} disabled value={statePage.data.rsReqSeqVal} className={inputStyleReadOnly} />
                                </div>
                                :
                                <div className='col-6'>
                                    <p className={labelStyle}>Req RID Sequence</p>
                                    <input type={'number'} min={0} value={statePage.data.rsReqSeqVal} onChange={(e) => handleState('rsReqSeqVal', e.target.value)} className={inputStyle} />
                                </div>
                            }

                            <div className='col-6'>
                                <p className={labelStyle}>Res RID</p>
                                <Select placeholder={'(Search)'} options={newOptionsRouting} value={newOptionsRouting.filter((option: any) => option.value === statePage.data.rsResId)} onChange={(e) => handleState('rsResId', e?.value)} />
                            </div>

                            {statePage.data.rsResId === null || statePage.data.rsResId === '' ?
                                <div className='col-6'>
                                    <p className={labelStyle}>Res RID Sequence</p>
                                    <input type={'number'} min={0} disabled value={statePage.data.rsResSeqVal} className={inputStyleReadOnly} />
                                </div>
                                :
                                <div className='col-6'>
                                    <p className={labelStyle}>Res RID Sequence</p>
                                    <input type={'number'} min={0} value={statePage.data.rsResSeqVal} onChange={(e) => handleState('rsResSeqVal', e.target.value)} className={inputStyle} />
                                </div>
                            }

                            <div className='col-6'>
                                <p className={labelStyle}>Next Sequence</p>
                                <Select placeholder={'(Search)'} options={optionsRouteSequence} value={optionsRouteSequence.filter((option: any) => option.value === statePage.data.rsNextRsId)} onChange={(e) => handleState('rsNextRsId', e?.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>Status</p>
                                <Select placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.data.rsStatus)} onChange={(e) => handleState('rsStatus', `${e.value}`)} />
                            </div>
                            <div className="col-12">
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.data.rsDesc} onChange={(e) => handleState("rsDesc", e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />

        </>
    )
}


const initialState = {
    titleModal: `New ${Page54Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelRouteSequence({
        id: null,
        rsId: null,
        rsReqId: null,
        rsResId: null,
        rsReqSeqVal: null,
        rsResSeqVal: null,
        rsName: null,
        rsDesc: null,
        rsStatus: '001',
        rsNextRsId: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelRouteSequence) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelRouteSequence({
                    id: null,
                    rsId: null,
                    rsReqId: null,
                    rsResId: null,
                    rsReqSeqVal: null,
                    rsResSeqVal: null,
                    rsName: null,
                    rsDesc: null,
                    rsStatus: '001',
                    rsNextRsId: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelRouteSequence({
                    id: action.value.rsId,
                    rsId: action.value.rsId,
                    rsReqId: action.value.rsReqId,
                    rsResId: action.value.rsResId,
                    rsReqSeqVal: action.value.rsReqSeqVal,
                    rsResSeqVal: action.value.rsResSeqVal,
                    rsName: action.value.rsName,
                    rsDesc: action.value.rsDesc,
                    rsStatus: action.value.rsStatus,
                    rsNextRsId: action.value.rsNextRsId,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

import { useEffect, useReducer } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useDispatch, useSelector } from "react-redux";
import { applicationTwoIcon, checklistButtonGreenIcon, close_red } from '../../../Assets/Images/svg';
import { changeStateReversalTransactionMonitoring, stateGlobalReversalTransactionMonitoring } from "../../../Store/ReversalTransactionMonitoringSlice";
import { labelStyle } from '../../../Styles';
import { Divider } from 'antd';


export const PieChartComponentRTM = ({ ...props }) => {
    const dispatchRedux = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);

    const { stateOptions } = props;

    const globalState = useSelector(stateGlobalReversalTransactionMonitoring);
    const { dataFilter, dataCopyFilter, dataYesterday, filterStartDate, filterEndDate, selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedProductId, selectedProductLabel, selectedProductSendToApi, search, optionsProductFeature, optionsProduct, listMember, listReasonCode, rangeDate } = globalState;
    useEffect(() => {
        countAllTransaction();
        dividingTransaction();
        convertDataNeededPieChart('issuerCode', 'issuerId', 'issuerName', 'optionsIssuerInstitutionIDUseUseExtAndDetail');// issuerName => Issuer
        convertDataNeededPieChart('acquirerCode', 'acquirerId', 'acquirerName', 'optionsAcquirerInstitutionIDUseUseExtAndDetail');// issuerName => Issuer
        convertDataNeededPieChart('issuerSwitcherCode', 'issuerSwitcherId', 'issuerSwitcherName', 'optionsSwitcherInstitutionIDUseExtAndDetail');// issuerName => Issuer
        convertDataNeededPieChart('acquirerSwitcherCode', 'acquirerSwitcherId', 'acquirerSwitcherName', 'optionsSwitcherInstitutionIDUseExtAndDetail');// issuerName => Issuer
        // convertDataNeededPieChart('acquirerCode');
        // convertDataNeededPieChart('issuerSwitcherCode');
        // convertDataNeededPieChart('acquirerSwitcherCode');

        // ---------------------------------------------
        // countAllTransaction();
        // dividingTransaction();
        // convertDataNeededPieChart('issuerId');// issuerName => Issuer
        // convertDataNeededPieChart('acquirerId');
        // convertDataNeededPieChart('issuerSwitcherId');
        // convertDataNeededPieChart('acquirerSwitcherId');

    }, [dataFilter, dataCopyFilter, filterStartDate, filterEndDate, selectedProductSendToApi, selectedProductFeatureSendToApi, props.stateOptions.optionsSAFStatusDetailCode,])

    const countAllTransaction = () => {
        if (dataCopyFilter.length > 0) {
            let counted = 0;
            dataCopyFilter.forEach((element1: any) => {
                counted = counted + parseInt(element1.transCount);
            });
            dispatch({ type: `countedAllTransction`, value: counted });
        } else {
            dispatch({ type: `countedAllTransction`, value: 0 });
        }
    }
    // division of transactions by type APPROVED, DECLINED, ERROR
    const dividingTransaction = () => {
        let newResultData: any[] = [];
        if (stateOptions.optionsSAFStatusDetailCode.length > 0) {
            stateOptions.optionsSAFStatusDetailCode.forEach((element1: any) => {
                let dataListTransactionDevided: any[] = [];
                if (dataCopyFilter.length > 0) {
                    dataCopyFilter.forEach((element2: any) => {
                        // if (element1.rcCodeList.includes(element2.transAuthIntResponseCode)) {
                        //   dataListTransactionDevided.push(element2:any);
                        // }
                        if (element1.value === element2.safStatusCode) {
                            dataListTransactionDevided.push(element2);
                        }

                    })
                }
                let resultData = { status: element1.label, rcCodeList: element1.value, dataListTransactionDevided: dataListTransactionDevided };
                newResultData.push(resultData);
            });
        }
        dispatch({ type: `transDevided`, value: newResultData });
    }

    // percentage Transaction Member, Name Transaction Member, Counted Transaction Member  
    const convertDataNeededPieChart = (memberCommonCode: string, memberCode: string, nameMember: string, nameOptions: string) => { // butuh percentage name dan total
        // group data berdasarkan member memberCommonCode and nameMember
        // memberId,  memberCode, memberName, memberColor
        // masukkan dalam array terlebih dahulu
        let newResultData: any[] = [];
        let optionsSelection: any[] = [];
        if (nameOptions === 'optionsAcquirerInstitutionIDUseUseExtAndDetail') {
            optionsSelection = [...stateOptions.optionsAcquirerInstitutionIDUseUseExtAndDetail]
        } else if (nameOptions === 'optionsIssuerInstitutionIDUseUseExtAndDetail') {
            optionsSelection = [...stateOptions.optionsIssuerInstitutionIDUseUseExtAndDetail]
        } else if (nameOptions === 'optionsSwitcherInstitutionIDUseExtAndDetail') {
            optionsSelection = [...stateOptions.optionsSwitcherInstitutionIDUseExtAndDetail]
        }
        let dataGroupCommonCode: any[] = []
        let dataGroupMemberCodeAndMemberName: any[] = []

        if (optionsSelection.length > 0) {
            optionsSelection.forEach((element1: any) => {
                // memberId,  memberCode, memberName, memberColor
                // masukkan dalam array terlebih dahulu
                // element.dataTransaction
                let dataListTransaction: any[] = [];
                if (dataCopyFilter.length > 0) {
                    dataCopyFilter.forEach((element2: any) => {
                        if ((element2[memberCommonCode] === element1.ccdCode) && (element2[nameMember] === element1.label)) {
                            dataListTransaction.push(element2);
                            if (!dataGroupCommonCode.includes(element2[nameMember])) {
                                dataGroupCommonCode.push(element2[nameMember])
                                dataGroupMemberCodeAndMemberName.push({
                                    memberName: element2[nameMember],
                                    memberCode: element2[memberCommonCode],
                                })
                            }
                        }
                    });
                }
                let resultData = { memberId: element1.value, memberCode: element1.ccdCode, memberName: element1.label, memberColor: element1.color, dataListTransaction: dataListTransaction };
                newResultData.push(resultData);
            });
        };
        dispatch({ type: `${memberCommonCode}`, value: newResultData });
        if (memberCommonCode === 'acquirerCode') {
            dispatch({ type: `selectedAcquirer`, value: dataGroupMemberCodeAndMemberName });
        } else if (memberCommonCode === 'issuerCode') {
            dispatch({ type: `selectedIssuer`, value: dataGroupMemberCodeAndMemberName });
        } else if (memberCommonCode === 'issuerSwitcherCode') {
            dispatch({ type: `selectedIssuerSwitcher`, value: dataGroupMemberCodeAndMemberName });
        } else if (memberCommonCode === 'acquirerSwitcherCode') {
            dispatch({ type: `selectedAcquirerSwitcher`, value: dataGroupMemberCodeAndMemberName });
        }
    }
    const onClickPieChart = (titleData: any, idLabel: any) => {
        let resultDataFilter: any[] = [];
        let resultDataFilterYesterday: any[] = [];
        var result = titleData.substring(1, titleData.length - 1); // remove ()
        result = result === '' ? null : result;
        dataCopyFilter.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilter.push(element1);
            }
        });
        dataYesterday.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilterYesterday.push(element1);
            }
        });
        dispatchRedux(changeStateReversalTransactionMonitoring({ dataCopyFilter: resultDataFilter, dataYesterday: resultDataFilterYesterday }));
        // dispatchRedux(changeStateTransactionMonitoring({  }))
    };

    const PieChartIssuerId = () => {
        if (state.issuerCode.length > 0) {
            let conditionShowChart = false;
            state.issuerCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true; // if data exist show Pie Chart normal
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Issuer</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'issuerName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.issuerCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartIssuerId`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Issuer</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }

        } else {
            return (
                <div>
                    <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Issuer</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }

    }
    const PieChartAcquirerId = () => {
        if (state.acquirerCode.length > 0) {
            let conditionShowChart = false;
            state.acquirerCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true;
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Acquirer</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'acquirerName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.acquirerCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartAcquirerId`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>

                )
            } else {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Acquirer</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Acquirer</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }
    }
    const PieChartIssuerSwitcherId = () => {
        if (state.issuerSwitcherCode.length > 0) {
            let conditionShowChart = false;
            state.issuerSwitcherCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true;
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Issuer Switcher</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'issuerSwitcherName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.issuerSwitcherCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartIssuerSwitcherId`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>

                )
            } else {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Issuer Switcher</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Issuer Switcher</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }
    }
    const PieChartAcquirerSwitcherName = () => {
        if (state.acquirerSwitcherCode.length > 0) {
            let conditionShowChart = false;
            state.acquirerSwitcherCode.forEach((element1: any) => {
                if (element1.dataListTransaction.length > 0) {
                    conditionShowChart = true;
                }
            })
            if (conditionShowChart) {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Acquirer Switcher</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'acquirerSwitcherName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[
                                ...state.acquirerSwitcherCode.map((element1: any, index: any) => {
                                    let resultCountedAllTransaction = 0;
                                    if (element1.dataListTransaction.length > 0) {
                                        resultCountedAllTransaction = resultCountedAllTransaction + parseInt(element1.dataListTransaction.length)
                                    }
                                    return {
                                        title: `(${((resultCountedAllTransaction / dataCopyFilter.length) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedAllTransaction})`,
                                        key: `${index}PieChartAcquirerSwitcherName`,
                                        value: resultCountedAllTransaction,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>

                )
            } else {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Acquirer Switcher</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>Acquirer Switcher</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }
    }

    const LegendPieChart = () => {
        let LegendAcquirer: any[] = []
        let LegendIssuer: any[] = []
        let LegendSwitcher: any[] = []
        if (stateOptions.optionsAcquirerInstitutionIDUseUseExtAndDetail.length > 0) {
            stateOptions.optionsAcquirerInstitutionIDUseUseExtAndDetail.forEach((element1: any, index: any) => {
                if (state.selectedAcquirer.length > 0) {
                    state.selectedAcquirer.forEach((itemAcquirer: any) => {
                        if ((itemAcquirer.memberName === element1.label) && (itemAcquirer.memberCode === element1.ccdCode)) {
                            if (state.acquirerCode.length > 0) {
                                LegendAcquirer.push(
                                    (
                                        <div className='mx-2 my-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10'>
                                            <div
                                                key={element1.label}
                                                style={{
                                                    backgroundColor: element1.color,
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '7px',
                                                    display: 'inline-block',
                                                }}
                                                className='mx-2'
                                            ></div>
                                            <span className='Lato-400'>{element1.label} {element1.value}</span>
                                        </div>
                                    )
                                )
                            }
                        } else {
                            return <></>
                        }
                    })
                }
            })
        }
        if (stateOptions.optionsIssuerInstitutionIDUseUseExtAndDetail.length > 0) {
            stateOptions.optionsIssuerInstitutionIDUseUseExtAndDetail.forEach((element1: any, index: any) => {
                if (state.selectedIssuer.length > 0) {
                    state.selectedIssuer.forEach((itemIssuer: any) => {
                        if ((itemIssuer.memberName === element1.label) && (itemIssuer.memberCode === element1.ccdCode)) {
                            if (state.issuerCode.length > 0) {
                                LegendIssuer.push(
                                    (
                                        <div className='mx-2 my-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10'>
                                            <div
                                                key={element1.label}
                                                style={{
                                                    backgroundColor: element1.color,
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '7px',
                                                    display: 'inline-block',
                                                }}
                                                className='mx-2'
                                            ></div>
                                            <span className='Lato-400'>{element1.label} {element1.value}</span>
                                        </div>
                                    )
                                )
                            }
                        } else {
                            return <></>
                        }
                    })
                }
            })
        }

        if (stateOptions.optionsSwitcherInstitutionIDUseExtAndDetail.length > 0) {
            stateOptions.optionsSwitcherInstitutionIDUseExtAndDetail.forEach((element1: any, index: any) => {
                let concatAllSwitcher = [...state.selectedIssuerSwitcher].concat(state.selectedAcquirerSwitcher)
                let uniqueArray: any[] = concatAllSwitcher.filter(
                    (item, index, self) => index === self.findIndex((t) => t.memberCode === item.memberCode)
                );

                if (uniqueArray.length > 0) {
                    uniqueArray.forEach((itemIssuer: any) => {
                        if ((itemIssuer.memberName === element1.label) && (itemIssuer.memberCode === element1.ccdCode)) {
                            if (state.issuerSwitcherCode.length > 0 && state.acquirerSwitcherCode.length > 0) {
                                LegendSwitcher.push(
                                    (
                                        <div className='mx-2 my-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10'>
                                            <div
                                                key={element1.label}
                                                style={{
                                                    backgroundColor: element1.color,
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '7px',
                                                    display: 'inline-block',
                                                }}
                                                className='mx-2'
                                            ></div>
                                            <span className='Lato-400'>{element1.label} {element1.value}</span>
                                        </div>
                                    )
                                )
                            }
                        } else {
                            return <></>
                        }
                    })
                }

            })



        }

        return (
            <>
                <Divider />
                <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
                    {
                        LegendAcquirer.length > 0 ?
                            <>
                                {LegendAcquirer.map((element1: any) => element1)}
                            </>
                            :
                            <></>
                    }
                     {
                    LegendIssuer.length > 0 ?
                        <>
                            {LegendIssuer.map((element1: any) => element1)}

                        </>
                        :
                        <></>
                }
                {
                    LegendSwitcher.length > 0 ?
                        <>
                            {LegendSwitcher.map((element1: any) => element1)}
                        </>
                        :
                        <></>
                }
                </div>
               
                

            </>
        )
        return (
            <>
                <Divider />
                {
                    LegendAcquirer.length > 0 ?

                        <div>
                            <div className={labelStyle}>Acquirer</div>
                            <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
                                {LegendAcquirer.map((element1: any) => element1)}
                            </div>
                        </div>
                        :
                        <></>

                }
                {
                    LegendIssuer.length > 0 ?
                        <>
                            <Divider />
                            <div>
                                <div className={labelStyle}>Issuer</div>
                                <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
                                    {LegendIssuer.map((element1: any) => element1)}
                                </div>
                            </div>

                        </>
                        :
                        <></>
                }
                {
                    LegendSwitcher.length > 0 ?
                        <>
                            <Divider />
                            <div>
                                <div className={labelStyle}>Issuer Switcher & Acquirer Switcher</div>
                                <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
                                    {LegendSwitcher.map((element1: any) => element1)}
                                </div>
                            </div>
                        </>
                        :
                        <></>
                }

            </>
        )
    }

    return (
        <>
            <div className='my-5'>
                <div className='width-100 '>
                    <div className='bg-ff bd-ea br-10 py-2 px-2'>
                        <div className='d-flex justify-content-evenly my-3' >
                            {PieChartIssuerSwitcherId()}
                            {PieChartIssuerId()}
                            {PieChartAcquirerSwitcherName()}
                            {PieChartAcquirerId()}
                        </div>
                        <LegendPieChart />
                    </div>
                </div>
            </div>
        </>
    )
}

const initialState = {
    dataMain: [
        { id: 'issuerName', label: 'Issuer', data: [] }, //transAuthSrcMemberId
        { id: 'acquirerName', label: 'Acquirer', data: [] },
        { id: 'issuerSwitcherName', label: 'Issuer Switcher', data: [] },
        { id: 'acquirerSwitcherName', label: 'Acquirer Switchers', data: [] },
        // {id: 'issuerSwitcherName', label: 'issuerSwitcherId', data: [] }, // issuerSwitcherId
        // {id: 'acquirerSwitcherName', label: 'acquirerSwitcherId', data: [] }, //acquirerSwitcherId
        { id: 'issuerCode', label: 'Issuer', data: [] },
        { id: 'acquirerCode', label: 'Acquirer', data: [] },
        { id: 'issuerSwitcherCode', label: 'Issuer Switcher', data: [] },
        { id: 'acquirerSwitcherCode', label: 'Acquirer Switchers', data: [] },
    ],
    data: '',
    issuerId: [], // issuerName => Issuer => issuerId
    acquirerId: [], // acquirerName => Acquirer
    issuerSwitcherId: [],
    acquirerSwitcherId: [],
    //
    issuerCode: [],
    acquirerCode: [],
    issuerSwitcherCode: [],
    acquirerSwitcherCode: [],
    //
    transDevided: [],
    countedAllTransction: 0,
    //
    selectedAcquirer: [], // member code acquirer for show in pie chart is not 0, if zero don`t show legend
    selectedIssuer: [],
    selectedIssuerSwitcher: [],
    selectedAcquirerSwitcher: [],
    // listColor: ['rgb(255, 205, 86)', 'rgb(54, 162, 235)', 'rgb(255, 99, 132)', 'rgb(255, 140, 132)', 'rgb(255, 10, 132)', 'rgb(255, 55, 132)', 'rgb(255, 55, 255)', 'rgb(255, 55, 10)', 'rgb(255, 105, 20)'],
    // listColorWithId: [],
};
function reducer(state: any, action: any) {
    switch (action.type) {
        case 'selectedAcquirer':
            return {
                ...state,
                selectedAcquirer: action.value
            };
        case 'selectedIssuer':
            return {
                ...state,
                selectedIssuer: action.value
            };
        case 'selectedIssuerSwitcher':
            return {
                ...state,
                selectedIssuerSwitcher: action.value
            };
        case 'selectedAcquirerSwitcher':
            return {
                ...state,
                selectedAcquirerSwitcher: action.value
            };

        case 'countedAllTransction':
            return {
                ...state,
                countedAllTransction: action.value
            };
        case 'issuerId':
            return {
                ...state,
                issuerId: action.value
            };
        case 'acquirerId':
            return {
                ...state,
                acquirerId: action.value
            };
        case 'issuerSwitcherId':
            return {
                ...state,
                issuerSwitcherId: action.value
            };
        case 'acquirerSwitcherId':
            return {
                ...state,
                acquirerSwitcherId: action.value
            };
        case 'transDevided':
            return {
                ...state,
                transDevided: action.value
            };


        case 'issuerCode':
            return {
                ...state,
                issuerCode: action.value
            };
        case 'acquirerCode':
            return {
                ...state,
                acquirerCode: action.value
            };
        case 'issuerSwitcherCode':
            return {
                ...state,
                issuerSwitcherCode: action.value
            };
        case 'acquirerSwitcherCode':
            return {
                ...state,
                acquirerSwitcherCode: action.value
            };
        default:
            throw new Error();
    }
}
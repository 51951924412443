

// DetailDisputeResolution

interface DetailDisputeResolutionInterface {
    id: null | number | string;

    caseId: number | null;
    caseNo: string | null;
    caseDate: string | null;
    caseType: string | null;
    disIssAmount: number | null;
    disIssCurrency: string | null;
    disAcqAmount: number | null;
    disAcqCurrency: string | null;
    iss: string | null;
    acq: string | null;
    caseStatus: string | null;
    merId: string | null;
    merName: string | null;
    terId: string | null;
    daysToAct: string | null;
    dateDeadline: string | null;
}

export class ModelDetailDisputeResolution {
    id: null | number | string;
    caseId: number | null;
    caseNo: string | null;
    caseDate: string | null;
    caseType: string | null;
    disIssAmount: number | null;
    disIssCurrency: string | null;
    disAcqAmount: number | null;
    disAcqCurrency: string | null;
    iss: string | null;
    acq: string | null;
    caseStatus: string | null;
    merId: string | null;
    merName: string | null;
    terId: string | null;
    daysToAct: string | null;
    dateDeadline: string | null;


    constructor(value: DetailDisputeResolutionInterface | null) {
        this.id = value?.id ?? null;
        this.caseId = value?.caseId ?? null;
        this.caseNo = value?.caseNo ?? null;
        this.caseDate = value?.caseDate ?? null;
        this.caseType = value?.caseType ?? null;
        this.disIssAmount = value?.disIssAmount ?? null;
        this.disIssCurrency = value?.disIssCurrency ?? null;
        this.disAcqAmount = value?.disAcqAmount ?? null;
        this.disAcqCurrency = value?.disAcqCurrency ?? null;
        this.iss = value?.iss ?? null;
        this.acq = value?.acq ?? null;
        this.caseStatus = value?.caseStatus ?? null;
        this.merId = value?.merId ?? null;
        this.merName = value?.merName ?? null;
        this.terId = value?.terId ?? null;
        this.daysToAct = value?.daysToAct ?? null;
        this.dateDeadline = value?.dateDeadline ?? null;
    }
}

import { Alert, Modal } from 'antd';
import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PopupMenu } from "react-simple-widgets";
import { checklistButtonIcon, closeButtonIcon, errNotificationIcon, iconPeople } from "../Assets/Images/svg";
import { logoutAction } from "../Store/User";
import { CheckAllFields, CheckUndefined, IsValidEmail } from "../Utils";
import { loadingAction } from '../Store/Loading';
import { API } from '../Services';
import { urlSelection } from '../Services/API';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { cookiesGlobal } from '../State/Cookies';

const labelStyle = 'c-54 text-uppercase s-1214 w-100 my-2';
const inputStyle = 'bd-d4 bg-ff br-5 h-40px px-2 w-100 s-1418 Lato-400 out-none';
const modalStylePrivate: any = {
    position: 'fixed',
    left: '0px',
    right: '0px',
    bottom: '0px',
    zIndex: 10000,
    width: '700px',
    top: '30%',
}


export const Profile = () => {
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const userLogin = useSelector((state: any) => state.user.user);
    const navigate = useNavigate()

    const handleState = (nameState: any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    useEffect(() => {
        dispatchStatePage({ type: 'currentPassword', value: '' })
        dispatchStatePage({ type: 'password', value: '' })
        dispatchStatePage({ type: 'confirmPassword', value: '' })
        dispatchStatePage({ type: 'errorMessage', value: '' })
        API.get({
            bodyCustom: null,
            pathCustom: `userapp/${userLogin?.userId}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            dispatchStatePage({ type: 'setData', value: response.data })
        }).catch((err: any) => {
        })

    }, [statePage.modalChangeEmail, statePage.modalProfilePassword]);


    const postDataEmail = () => {
        const { userId, userName, userEmail, userCategoryCode, userLevel, privilegeId, userStatus, userStartDate, userEndDate, memberId, memberName, privilegeName, userBlockedReason } = statePage
        const data = {
            userId: userId,
            userName: userName,
            userEmail: userEmail,
            userCategoryCode: userCategoryCode,
            userLevel: userLevel,
            privilegeId: privilegeId,
            userStatus: userStatus,
            userStartDate: userStartDate,
            userEndDate: userEndDate,
            memberId: memberId,
            memberName: memberName,
            privilegeName: privilegeName,
            userBlockedReason: userBlockedReason,
        };
        if (!IsValidEmail(userEmail)) {
            dispatchStatePage({ type: 'errorMessage', value: 'Email is invalid' })
        } else {
            API.update({
                bodyCustom: data,
                pathCustom: `userapp`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response: any) => {
                dispatchStatePage({ type: 'setData', value: response.data })
                dispatchStatePage({ type: 'modalSuccess', value: true })
                dispatchStatePage({ type: 'errorMessage', value: '' })
                setTimeout(function () {
                    dispatchStatePage({ type: 'modalSuccess', value: false })
                    dispatchStatePage({ type: 'modalChangeEmail', value: false })
                    dispatchStatePage({ type: 'errorMessage', value: '' })
                    dispatchStatePage({ type: 'currentPassword', value: '' })
                    dispatchStatePage({ type: 'password', value: '' })
                    dispatchStatePage({ type: 'confirmPassword', value: '' })
                }, 1500);
            }).catch((err: any) => {
                dispatchStatePage({ type: 'errorMessage', value: '' })
                dispatchStatePage({ type: 'modalFailed', value: true })
                setTimeout(function () {
                    dispatchStatePage({ type: 'errorMessage', value: '' })
                    dispatchStatePage({ type: 'modalChangeEmail', value: false })
                    dispatchStatePage({ type: 'modalFailed', value: false })

                }, 1500);
            })
        }
    }

    const postDataPassword = () => {
        const { currentPassword, password, confirmPassword, } = statePage
        const data = {
            currentPassword: currentPassword,
            password: password,
            confirmPassword: confirmPassword,
        };
        let check = new RegExp(/(?=^.{8,32}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%*()_+^&]*$/);
        const a = CheckAllFields([currentPassword, password, confirmPassword], ["Current Password", "Password", "Confirm password"]);
        if (check.test(password)) {
            if (a !== "") {
                dispatchStatePage({ type: 'errorMessage', value: a })
            } else if (password !== confirmPassword) {
                dispatchStatePage({ type: 'errorMessage', value: 'Your password and confirmation password must match' })

            } else {
                API.post({
                    bodyCustom: data,
                    pathCustom: `userapp/changePassword`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                }).then((response: any) => {
                    dispatchStatePage({ type: 'modalSuccess', value: true })
                    dispatchStatePage({ type: 'errorMessage', value: '' })
                    setTimeout(function () {
                        dispatchStatePage({ type: 'modalSuccess', value: false })
                        dispatchStatePage({ type: 'modalProfilePassword', value: false })
                        dispatchStatePage({ type: 'errorMessage', value: '' })
                    }, 1500);
                }).catch((err: any) => {
                    dispatchStatePage({ type: 'errorMessage', value: '' })
                    dispatchStatePage({ type: 'modalFailed', value: true })
                    setTimeout(function () {
                        dispatchStatePage({ type: 'errorMessage', value: '' })
                        dispatchStatePage({ type: 'modalProfilePassword', value: false })
                        dispatchStatePage({ type: 'modalFailed', value: false })
                    }, 1500);
                })
            }
        } else {
            dispatchStatePage({ type: 'errorMessage', value: 'Password min. 8 char and must include lowercase, uppercase, special character & number ' })
        }
    }
    return (
        <>
            <div id="app">
                <div className="text-end">
                    <PopupMenu>
                        <button style={{ border: '0px', height: '40px', width: "40px", borderRadius: '25px' }}>
                            {iconPeople}
                        </button>
                        <div className="card text-start">
                            <div className="card-body px-4 py-4">
                                <div id="circle-avatar" className="text-center mx-auto mb-4" style={{ border: '0px', height: '100px', width: "100px", borderRadius: '50px' }}>
                                </div>
                                <>
                                    <h5 className="text-center mb-0">{CheckUndefined(userLogin?.userName)}</h5>
                                    <p className="text-center mb-2">{CheckUndefined(userLogin?.userEmail)}</p>
                                    <hr className="mb-0" style={{ margin: "0 -24px 0" }} />
                                </>
                                <div className="list-group list-group-flush" style={{ margin: "0 -24px 0" }}>
                                    <button className="list-group-item list-group-item-action px-4" onClick={() => handleState('modalChangeEmail', true)}>
                                        <small>Change Email</small>
                                    </button>
                                    <button className="list-group-item list-group-item-action px-4" onClick={() => handleState('modalProfilePassword', true)}>
                                        <small>Change Password</small>
                                    </button>
                                </div>

                                <hr style={{ margin: "0 -24px 24px" }} />

                                <div className="d-grid">
                                    <button className="btn btn-secondary" onClick={() => {
                                        try {
                                            // cookiesGlobal.remove('_userxxxx', { path: '/',  });
                                            // cookiesGlobal.remove('_passxxxx', { path: '/',  });

                                            localStorage.removeItem('_userxxxx')
                                            localStorage.removeItem('_passxxxx')
                                            dispatchGlobal(logoutAction(null))
                                        } catch (error) {
                                            console.log(error)
                                        }
                                    }}>
                                        <small>Logout</small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </PopupMenu>
                </div>
            </div>
            <Modal
                title="Change Email"
                centered
                open={statePage.modalChangeEmail}
                onCancel={() => handleState('modalChangeEmail', false)}
                style={modalStylePrivate}
                footer={false}
            >
                <div className='d-flex flex-column'>
                    <hr />
                    <div className='row  gx-3 mx-0 '>
                        <div className={'col-12'}>
                            <p className={labelStyle}>Member Email</p>
                            <input type="text" value={statePage.userEmail} onChange={(e) => handleState('userEmail', e.target.value)} className={inputStyle} />
                        </div>
                    </div>
                    {statePage.errorMessage !== '' &&
                        <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-3'>
                            {errNotificationIcon}
                            <p className='ml-10 s-1418 Lato-600 c-e87'>{statePage.errorMessage} </p>
                        </div>
                    }
                    <div className='d-flex justify-content-between mt-4'>
                        <button className='d-flex align-items-center px-3 py-2 br-20 bd-d4 bg-ff pointer' onClick={(e) => handleState('modalChangeEmail', false)}>
                            {closeButtonIcon}
                            <p className='s-1418 c-7f Lato-600 mx-2'>CANCEL</p>
                        </button>
                        <button className='d-flex align-items-center px-3 py-2 br-20 bd-d4 bg-e8 bd-29' onClick={(e) => postDataEmail()}>
                            {checklistButtonIcon}
                            <p className='s-1418 c-ff Lato-600 mx-2'>SAVE</p>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                title="Change Password"
                centered
                open={statePage.modalProfilePassword}
                onCancel={() => handleState('modalProfilePassword', false)}
                style={modalStylePrivate}
                footer={false}
            >
                <div className='d-flex flex-column'>
                    <hr />
                    <div className='row  gx-3 mx-0 '>
                        <div className='col-12'>
                            <p className={labelStyle}>Current Password</p>
                            <input type="password" value={statePage.currentPassword} onChange={(e) => handleState('currentPassword', e.target.value)} className={inputStyle} />
                        </div>
                        <div className={'col-12'}>
                            <p className={labelStyle}>New Password</p>
                            <input type="password" value={statePage.password} onChange={(e) => handleState('password', e.target.value)} className={inputStyle} />
                        </div>
                        <div className={'col-12'}>
                            <p className={labelStyle}>Password Confirm</p>
                            <input type="password" value={statePage.confirmPassword} onChange={(e) => handleState('confirmPassword', e.target.value)} className={inputStyle} />
                        </div>
                    </div>
                    {statePage.errorMessage !== '' &&
                        <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-3'>
                            {errNotificationIcon}
                            <p className='ml-10 s-1418 Lato-600 c-e87'>{statePage.errorMessage} </p>
                        </div>}
                    <div className='d-flex justify-content-between mt-4' >
                        <button className='d-flex align-items-center px-3 py-2 br-20 bd-d4 bg-ff pointer' onClick={(e) => handleState('modalProfilePassword', false)}>
                            {closeButtonIcon}
                            <p className='s-1418 c-7f Lato-600 mx-2'>CANCEL</p>
                        </button>
                        <button className='d-flex align-items-center px-3 py-2 br-20 bd-d4 bg-e8 bd-29' onClick={(e) => postDataPassword()}>
                            {checklistButtonIcon}
                            <p className='s-1418 c-ff Lato-600 mx-2'>SAVE</p>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                title="STATUS"
                centered
                open={statePage.modalSuccess}
                onCancel={() => handleState('modalSuccess', false)}
                style={modalStylePrivate}
                footer={false}
            >
                <hr />
                <Alert message="Success update" type="success" showIcon />
            </Modal>
            <Modal
                title="STATUS"
                centered
                open={statePage.modalFailed}
                onCancel={() => handleState('modalFailed', false)}
                style={modalStylePrivate}
                footer={false}
            >
                <hr />
                <Alert message="Failed to update data" type="error" showIcon />
            </Modal>
        </>
    );
}


const initialState = {
    modalChangeEmail: false,
    modalProfilePassword: false,
    modalSuccess: false,
    modalFailed: false,
    errorMessage: '',
    //
    memberContactName: '',
    memberContactPhone: '',
    //
    userId: '',
    memberId: '',
    privilegeId: '',
    userEmail: '',
    userName: '',
    userCategoryCode: '',
    userLevel: '',
    userStatus: '',
    userStartDate: '',
    userEndDate: '',
    userBlockedReason: '',
    memberName: '',
    privilegeName: '',
    //
    currentPassword: '',
    password: '',
    confirmPassword: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {

        case 'setData':
            return {
                ...state,
                userId: action.value.userId,
                memberId: action.value.memberId,
                privilegeId: action.value.privilegeId,
                userEmail: action.value.userEmail,
                userName: action.value.userName,
                userCategoryCode: action.value.userCategoryCode,
                userLevel: action.value.userLevel,
                userStatus: action.value.userStatus,
                userStartDate: action.value.userStartDate,
                userEndDate: action.value.userEndDate,
                userBlockedReason: action.value.userBlockedReason,
                memberName: action.value.memberName,
                privilegeName: action.value.privilegeName,
            }
        case 'modalChangeEmail':
            return {
                ...state,
                modalChangeEmail: action.value
            }

        case 'modalProfilePassword':
            return {
                ...state,
                modalProfilePassword: action.value
            }
        case 'modalSuccess':
            return {
                ...state,
                modalSuccess: action.value
            }
        case 'modalFailed':
            return {
                ...state,
                modalFailed: action.value
            }
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value
            }
        case 'memberContactName':
            return {
                ...state,
                memberContactName: action.value
            }
        case 'memberContactPhone':
            return {
                ...state,
                memberContactPhone: action.value
            }
        //
        case 'userId':
            return {
                ...state,
                userId: action.value
            }


        case 'memberId':
            return {
                ...state,
                memberId: action.value
            }

        case 'privilegeId':
            return {
                ...state,
                privilegeId: action.value
            }

        case 'userEmail':
            return {
                ...state,
                userEmail: action.value
            }

        case 'userName':
            return {
                ...state,
                userName: action.value
            }

        case 'userCategoryCode':
            return {
                ...state,
                userCategoryCode: action.value
            }

        case 'userLevel':
            return {
                ...state,
                userLevel: action.value
            }
        case 'userStatus':
            return {
                ...state,
                userStatus: action.value
            }
        case 'userStartDate':
            return {
                ...state,
                userStartDate: action.value
            }
        case 'userEndDate':
            return {
                ...state,
                userEndDate: action.value
            }
        case 'userBlockedReason':
            return {
                ...state,
                userBlockedReason: action.value
            }

        case 'memberName':
            return {
                ...state,
                memberName: action.value
            }
        case 'privilegeName':
            return {
                ...state,
                privilegeName: action.value
            }
        case 'currentPassword':
            return {
                ...state,
                currentPassword: action.value
            }
        case 'password':
            return {
                ...state,
                password: action.value
            }
        case 'confirmPassword':
            return {
                ...state,
                confirmPassword: action.value
            }
        default:
            throw new Error();

    }
}
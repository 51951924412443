import { logIcon } from "../../../Assets/Images/MenuIcon";
import { Page1FrameworkRoute, Page2FrameworkRoute, Page3FrameworkRoute, Page4FrameworkRoute } from "../../../Routes";
import { Page20Code, Page21Code, Page22Code, Page23Code, Page59Code } from "../CodeMenu";
import { Page20Title, Page21Title, Page22Title, Page23Title, Page59Title } from "../TitleMenu";

export const Menu8 = {
    id: 'framework_sidebar',
    label: 'Framework',
    icon: logIcon,
    privilegesId: [Page20Code, Page21Code, Page22Code, Page23Code, Page59Code],
    itemSidebar: [
        {
            titleList: Page20Title,
            key: 'frameworkOptions1',
            privilegeId: Page20Code,
            url: Page1FrameworkRoute,
        },

        {
            titleList: Page21Title,
            key: 'frameworkOptions2',
            privilegeId: Page21Code,
            url: Page2FrameworkRoute,
        },
        {
            titleList: Page22Title,
            key: 'frameworkOptions3',
            privilegeId: Page22Code,
            url: Page3FrameworkRoute,
        },
        {
            titleList: Page23Title,
            key: 'frameworkOptions4',
            privilegeId: Page23Code,
            url: Page4FrameworkRoute,
        }
    ],
}
// import { Divider, Pagination, Popover } from "antd";
// import type { PaginationProps } from "antd";
// import { useEffect, useState } from "react";
// import { useEdges } from "reactflow";
// import { CheckUndefined, CheckUndefinedStringEmpty, NumberWithCommas } from "../../Utils";

// const TableSettlementSummary = ({ ...props }) => {
//   const { stateParent } = props;
//   const { data, label, widthTable, data2 } = stateParent;

//   const [pageActive, setPageActive] = useState(1);
//   const [dataPerPage, setDataPerPage] = useState(10);

//   // useEffect(() => {

//   //     console.log(data)
//   //     console.log(pageActive)
//   //     console.log(dataPerPage)
//   // }, [data, pageActive, dataPerPage])

//   const onChange: PaginationProps["onChange"] = (pageNumber) => {
//     // console.log('Page: ', pageNumber);
//     setPageActive(pageNumber);
//   };
//   const onShowSizeChange: PaginationProps["onShowSizeChange"] = (current, pageSize) => {
//     // console.log(current, pageSize);
//     setDataPerPage(pageSize);
//   };

//   const showTotal: PaginationProps["showTotal"] = (total) => `Total ${total} items`;
//   return (
//     <>
//       <div className="">
//         <div className={`d-flex  my-3  flex-column `} style={{ overflowX: "auto", gap: 5 }}>
//           <div className={`d-flex align-items-center justify-content-between flex-row px-3`} style={{ gap: 20, minHeight: 50, flex: "none", width: widthTable }}>
//             {label?.map((itemLabel: any) => {
//               if (itemLabel.val === "no") {
//                 return (
//                   <>
//                     <div className={`word-wrap fw-bold  ${itemLabel.width}`} style={{}}>
//                       {itemLabel.field}
//                     </div>
//                   </>
//                 );
//               } else {
//                 return (
//                   <>
//                     <div className={`word-wrap fw-bold  ${itemLabel.width}`} style={{}}>
//                       {itemLabel.field}
//                     </div>
//                   </>
//                 );
//               }
//             })}
//           </div>
//           <Divider className="my-1" />
//           {data?.map((itemData: any, index: number) => {
//             //let number = index + (props.currentPage - 1) * props.view;
//             // if (index + 1 <= pageActive * dataPerPage && index >= (pageActive - 1) * dataPerPage) {
//               return (
//                 <>
//                   <div className={`d-flex align-items-center justify-content-between flex-row px-3 ${(index + 1) % 2 === 0 ? "" : "bg-f4"}`} style={{ gap: 20, minHeight: 50, flex: "none", width: widthTable }}>
//                     {label?.map((itemLabel: any) => {
//                       if (itemLabel.val === "no") {
//                         return (
//                           <>
//                             <div className={`word-wrap ${itemLabel.width}`} style={{}}>
//                               {index + 1}
//                             </div>
//                           </>
//                         );
//                       } else if (itemLabel.val === "reconStatus") {
//                         let selectedBgColor = "";
//                         let selectedTextColor = "";
//                         if (itemData[`${itemLabel.val}`] === "Unmatched") {
//                           selectedBgColor = "#dc3545";
//                           selectedTextColor = "white";
//                         } else if (itemData[`${itemLabel.val}`] === "Reconciled") {
//                           selectedBgColor = "#198754";
//                           selectedTextColor = "white";
//                         } else if (itemData[`${itemLabel.val}`] === "Unknown") {
//                           selectedBgColor = "#0dcaf0";
//                           selectedTextColor = "black";
//                         } else if (itemData[`${itemLabel.val}`] === "Unreconciled") {
//                           selectedBgColor = "#0000ff";
//                           selectedTextColor = "white";
//                         }
//                         if (CheckUndefined(itemData[`${itemLabel.val}`]) === "Unknown") {
//                           return (
//                             <Popover content="Member's not sent the settlement file">
//                               <p className={` Lato-400 word-wrap row-data-pointer  ${itemLabel.width}`} onClick={() => props.editData(itemData, "reconStatus")}>
//                                 <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
//                                   {CheckUndefined(itemData[`${itemLabel.val}`])}
//                                 </div>
//                               </p>
//                             </Popover>
//                           );
//                         } else {
//                           return (
//                             <p className={` Lato-400 word-wrap row-data-pointer  ${itemLabel.width}`} onClick={() => props.editData(itemData, "reconStatus")}>
//                               <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
//                                 {CheckUndefined(itemData[`${itemLabel.val}`])}
//                               </div>
//                             </p>
//                           );
//                         }
//                       } else {
//                         return (
//                           <>
//                             <div className={`word-wrap   ${itemLabel.width}`} style={{}}>
//                               {itemData[itemLabel.val]}
//                             </div>
//                           </>
//                         );
//                       }
//                     })}
//                   </div>
//                 </>
//               );
//             //}
//           })}
//           {/* <Divider className='my-1' /> */}
//           <div className={`d-flex align-items-start justify-content-between px-3 min-h-50px py-2`} style={{ gap: 20, flex: "none", width: widthTable, height: "auto", borderTop: "1px solid #d4d4d4", borderBottom: "1px solid #d4d4d4" }}>
//             {label?.map((itemLabel: any) => {
//               if (itemLabel.val === "trxCount") {
//                 return (
//                   <div>
//                     {props.data2 &&
//                       props.data2?.map((transData: any, index: any) => (
//                         <p className={`c-29 s-1620 Lato-700 word-wrap py-1 ${itemLabel.width}`} style={{ borderBottom: "1px solid #d4d4d4" }} key={index}>
//                           {transData.trxCount !== "" ? transData.trxCount : "0"}
//                         </p>
//                       ))}
//                   </div>
//                 );
//               } else if (itemLabel.val === "acqSwitchAmount") {
//                 return (
//                   <div>
//                     {props.data2 &&
//                       props.data2?.map((transData: any, index: any) => (
//                         <p className={`c-29 s-1620 Lato-700 word-wrap py-1 ${itemLabel.width}`} style={{ borderBottom: "1px solid #d4d4d4" }} key={index}>
//                           {transData.acqSwitchAmount !== "" ? transData.acqSwitchAmount : ""}
//                         </p>
//                       ))}
//                   </div>
//                 );
//               } else if (itemLabel.val === "issSwitchAmount") {
//                 return (
//                   <div>
//                     {props.data2 &&
//                       props.data2?.map((reconData: any, index: any) => (
//                         <p className={`c-29 s-1620 Lato-700 word-wrap py-1 ${itemLabel.width}`} style={{ borderBottom: "1px solid #d4d4d4" }} key={index}>
//                           {reconData.issSwitchAmount !== "" ? reconData.issSwitchAmount : "0"}
//                         </p>
//                       ))}
//                   </div>
//                 );
//               } else if (itemLabel.val === "serviceFee") {
//                 return (
//                   <div>
//                     {props.data2 &&
//                       props.data2?.map((reconData: any, index: any) => (
//                         <p className={`c-29 s-1620 Lato-700 word-wrap py-1 ${itemLabel.width}`} style={{ borderBottom: "1px solid #d4d4d4" }} key={index}>
//                           {reconData.serviceFee !== "" ? reconData.serviceFee : "0"}
//                         </p>
//                       ))}
//                   </div>
//                 );
//               } else if (itemLabel.val === "settAmount") {
//                 return (
//                   <div>
//                     {props.data2 &&
//                       props.data2?.map((reconData: any, index: any) => (
//                         <p className={`c-29 s-1620 Lato-700 word-wrap py-1 ${itemLabel.width}`} style={{ borderBottom: "1px solid #d4d4d4" }} key={index}>
//                           {reconData.settAmount !== "" ? reconData.settAmount : "0"}
//                         </p>
//                       ))}
//                   </div>
//                 );
//               } else {
//                 return <div className={`word-wrap fw-bold  ${itemLabel.width}`} style={{}}></div>;
//               }
//             })}
//           </div>
//         </div>
//         {/* <div className="d-flex justify-content-end my-5 px-3">
//           <Pagination size="default" showQuickJumper showSizeChanger defaultCurrent={pageActive} pageSize={dataPerPage} current={pageActive} total={data?.length} onChange={onChange} showTotal={showTotal} onShowSizeChange={onShowSizeChange} />
//         </div> */}
//       </div>
//     </>
//   );
// };

// export default TableSettlementSummary;


import { useMemo } from "react";
import { MaterialReactTable, MRT_ColumnDef, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from "material-react-table";
import { CheckUndefined } from "../../Utils";
import { HeaderDataTable } from "../../Components/HeaderDataTable";
import { ButtonAdd, SelectView } from "../../Components";
import { Divider, Popover } from "antd";
import { TableCell, TableFooter, TableRow } from "@mui/material";
import { ModelSettlementSummary } from "./Models/ModelSettlementSummary";


export const TableSettlementSummary = ({ ...props }) => {
  const {
    state,
    addDataComponent,
    exportToCSVComponent,
    handleStateComponent,
    checkIndexComponent,
    setPagination,
    pagination,
    customButtonAction,
    modalAddDeleteComponentPage, data2
  } = props;
  const calculateTotalTrxCount = () => {
    return state.data.reduce((total: number, row: any) => {
      return total + (Number(row.trxCount) || 0);
    }, 0);
  };

  const totalTrxCount = calculateTotalTrxCount();
  const columns = useMemo<MRT_ColumnDef<ModelSettlementSummary>[]>(
    () => [
      {
        header: 'Acquirer Switcher Settle Date',
        accessorKey: 'acqSwitchSettDate',
        size: 150,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.acqSwitchSettDate)}`,
      },
      {
        header: 'Acquirer Switcher',
        accessorKey: 'acqSwitchMemberId',
        size: 100,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.acqSwitchMemberId)}`,
      },
      {
        header: 'Issuer Switcher Settle Date',
        accessorKey: 'issSwitchSettDate',
        size: 150,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.issSwitchSettDate)}`,
        enableSorting: true,
      },
      {
        header: 'Issuer Switcher',
        accessorKey: 'issSwitchMemberId',
        size: 100,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.issSwitchMemberId)}`,
        enableSorting: true,
      },
      {
        header: 'BCAP Transaction Count',
        size: 100,
        accessorKey: 'trxCount',
        Cell: ({ row }: any) => { return `${CheckUndefined(row.original.trxCount)}` }
        ,
        enableSorting: true,
        Footer: () => { 
          return <div>{state.data2 &&
            state.data2?.map((transData: any, index: any) => (
              <p className={`c-29 Lato-700 word-wrap`} key={index}>
                {transData.trxCount !== "" ? transData.trxCount : ""}
              </p>
            ))}</div>
        }
      },
      {
        header: 'Acquirer Switcher Amount',
        accessorKey: 'acqSwitchAmount',
        size: 150,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.acqSwitchAmount)}`,
        enableSorting: true,
        Footer: () => {
          return <div>{state.data2 &&
            state.data2?.map((transData: any, index: any) => (
              <p className={`c-29 Lato-700 word-wrap`} key={index}>
                {transData.acqSwitchAmount !== "" ? transData.acqSwitchAmount : ""}
              </p>
            ))}</div>
        }
      },
      {
        header: 'Issuer Switcher Amount',
        accessorKey: 'issSwitchAmount',
        size: 150,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.issSwitchAmount)}`,
        enableSorting: true,
        Footer: () => {
          return <div>{state.data2 &&
            state.data2?.map((transData: any, index: any) => (
              <p className={`c-29 Lato-700 word-wrap`} key={index}>
                {transData.issSwitchAmount !== "" ? transData.issSwitchAmount : ""}
              </p>
            ))}</div>
        }
      },
      {
        header: 'Recon Status',
        accessorKey: 'reconStatus',
        size: 100,
        Cell: ({ row }: any) => {
          let selectedBgColor = "";
          let selectedTextColor = "";
          if (row.original.reconStatus === "Unmatched") {
            selectedBgColor = "#dc3545";
            selectedTextColor = "white";
          } else if (row.original.reconStatus === "Reconciled") {
            selectedBgColor = "#198754";
            selectedTextColor = "white";
          } else if (row.original.reconStatus === "Unknown") {
            selectedBgColor = "#0dcaf0";
            selectedTextColor = "black";
          } else if (row.original.reconStatus === "Unreconciled") {
            selectedBgColor = "#0000ff";
            selectedTextColor = "white";
          }
          if (CheckUndefined(row.original.reconStatus) === "Unknown") {
            return (
              <Popover content="Member's not sent the settlement file">
                <p className={` Lato-400 word-wrap row-data-pointer`} onClick={() => props.editData(row.original, "reconStatus")}>
                  <div className="p-2 rounded d-flex justify-content-center" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                    {CheckUndefined(row.original.reconStatus)}
                  </div>
                </p>
              </Popover>
            );
          } else {
            return (
              <p className={` Lato-400 word-wrap row-data-pointer`} onClick={() => props.editData(row.original, "reconStatus")}>
                <div className="p-2 rounded d-flex justify-content-center" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                  {CheckUndefined(row.original.reconStatus)}
                </div>
              </p>
            );
          }
        },
        // `${CheckUndefined(row.original.reconStatus)}`,
        enableSorting: false,
      },
      {
        header: 'Service Fee',
        accessorKey: 'serviceFee',
        size: 100,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.serviceFee)}`,
        enableSorting: true,
        Footer: () => {
          return <div>{state.data2 &&
            state.data2?.map((transData: any, index: any) => (
              <p className={`c-29 Lato-700 word-wrap`}  key={index}>
                {transData.serviceFee !== "" ? transData.serviceFee : ""}
              </p>
            ))}</div>
        }
      },
      {
        header: 'Settlement Amount',
        accessorKey: 'settAmount',
        size: 100,
        Cell: ({ row }: any) =>
          `${CheckUndefined(row.original.settAmount)}`,
        enableSorting: true,
        Footer: () => {
          return <div>{state.data2 &&
            state.data2?.map((transData: any, index: any) => (
              <p className={`c-29 Lato-700 word-wrap`}  key={index}>
                {transData.settAmount !== "" ? transData.settAmount : ""}
              </p>
            ))}</div>
        }
      },
    ],
    [state],
  );


  // const columns: any = useMemo(
  //   () => [

  //   ],
  //   []
  // );
  const table = useMaterialReactTable({
    columns,
    data: state.data,
    state: { pagination, globalFilter: state.search || '' },
    initialState: {
      density: 'compact',
    },
    enableColumnActions: false,
    enableClickToCopy: false,
    enableEditing: false,
    enableHiding: false,
    enableRowActions: false,
    manualFiltering: true,
    manualPagination: true,
    rowCount: state.totalData,
    onPaginationChange: setPagination,
    enableRowNumbers: true,
    enableTableFooter: true,
    paginationDisplayMode: 'pages',
    enablePagination: true,
    muiPaginationProps: {
      color: 'standard',
      showRowsPerPage: false,
      size: 'small',
    },
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    positionActionsColumn: "last",
    muiTableBodyRowProps: ({ row }) => {
      const sortedRows: any = table.getRowModel().rows;
      const rowIndex = sortedRows.indexOf(row);
      return {
        hover: false,
        sx: {
          backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
          fontSize: '12px',
          fontFamily: 'system-ui, sans-serif',
          zIndex: 0,
        },
      };
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '12px',
        fontFamily: 'system-ui, sans-serif',
        borderRight: '1px solid rgba(218, 217, 219, .7)',
        borderTop: '1px solid rgba(218, 217, 219, .7)',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '12px',
        fontFamily: 'system-ui, sans-serif',
        borderRight: '1px solid rgba(218, 217, 219, .7)',
        zIndex: 0,
      },
    },
    muiTopToolbarProps: {
      sx: {
        zIndex: 0,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        zIndex: 0,
        fontSize: '12px',
      },
    },
    muiTableFooterCellProps: {
      sx: {
        borderTop: '1px solid rgba(218, 217, 219, .7)',
        fontSize: '12px', // Set font size for header cells
        fontWeight: 'bold', // Optional: make header font bold
        fontFamily: 'system-ui, sans-serif', // Set font family for header cells
        color: '#292929',
      },
    },
    muiSearchTextFieldProps: {
      placeholder: `Search By ${state.SearchByPlaceHolder}`,
      title: `Search By ${state.SearchByPlaceHolder}`,

    },
    onGlobalFilterChange: (filterValue) => {
      if (filterValue === undefined) {
        handleStateComponent('search', '')
      } else {
        handleStateComponent('search', filterValue)
      }
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <div className="d-flex">
          <HeaderDataTable
            stateParent={state}
            data={state.data}
            totalData={state.totalData}
            label={state.label}
            titleTable={'List'}
            privilegeAccess={state.privilegeAccess}
            addData={addDataComponent}
            checkIndex={checkIndexComponent}
            exportToCSV={exportToCSVComponent}
            handleState={handleStateComponent}
            needToggleFunction={false}
            needAddButton={true}
            needSearchFunction={true}
            needDownloadButton={true}
            table={table}
            isButtonDisabled={props.isButtonDisabled}
          />
        </div>
      </>
    ),
    renderRowActions: (table: any) => (
      customButtonAction(table.row.original)
    ),
    // renderToolbarInternalActions: ({ table }) => (
    //     <>
    //         <MRT_ToggleGlobalFilterButton table={table} />
    //         <div className="h-30px w-30px">
    //             <div className="h-100">
    //                 <ButtonAdd handleClick={addDataComponent} codeAccessPrivilege={state.privilegeAccess.add} />
    //             </div>
    //         </div>
    //     </>
    // ),
  });
  return (
    <>
      <div id="table" className="mt-1 mb-5 position-relative">
        <MaterialReactTable table={table} />
        <div className='position-absolute' style={{ bottom: '12px' }}>
          <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
        </div>
      </div>
      {modalAddDeleteComponentPage()}
    </>
  )
}



import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { inputStyle, labelStyle } from "../../Styles";
import { useCallback, useEffect, useReducer } from "react";

import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
// import ModalDetailUnmatchedList from "./ModalDetailUnmatchedList";
import { close } from "../../Assets/Images/svg";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { initFunction, reducerOptions, stateOptions, stateSelected } from "../../State";
import { urlSelection } from "../../Services/API";
import { useDispatch } from "react-redux";
import { InterfaceOptionsDetailsMonitoringStatus } from "../Dashboard/InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { ModelDetailUnmatchedList } from "./ModelDetailUnmatchedList";

export const ModalUnmatchedListDetail = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
  const { stateParent, dispatchParent, modalConditionDetail, setModal } = props;

  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    if (stateParent.dispNo !== '') {
      API.get({
        bodyCustom: null,
        pathCustom: `settlement/unmatched/d?dispNo=${stateParent.dispNo}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        handleState('data', response.data)
      })
    }

  }

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [modalConditionDetail])

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [])

  const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
  const data = state.allData
  if (state.data === null) return <></>
  // const rightTable2 = () => {
  //   return (
  //     <>
  //       <div className="px-2 m-0 rounded py-2 ">
  //         <div className="d-flex flex-column">
  //           <div className="d-flex bg-f4  border " >
  //             <div className=" w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700 ">Instruction Identification</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.invNo)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">Unmatched Type</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.adjType)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700 ">Settlement Date</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.sttlDate)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700 ">Reply Date Time</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.replyDateTime)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700 ">Transaction Date Time</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.transDateTime)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">Transaction Type</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.transType)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700 ">Direction</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.direction)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">From Bank ID</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.srcInst)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">To Bank ID</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.dstInst)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">Refund Currency</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.curr)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">Refund Amount</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.amt)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">From Acc No</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.frmAccNo)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">To Acc No</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.toAccNo)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">Originatior ID</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.originatorId)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">Receipient ID</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.receipientId)}</div>
  //           </div>
  //           <div className="d-flex bg-f4  border mt-1 " >
  //             <div className="w-300px h-50px d-flex align-items-center word-wrap border-0  s-1418 p-2 bg-fff Lato-700">Settlement Bank</div>
  //             <div className=" w-300px word-wrap d-flex  align-items-center p-2">{CheckUndefined(data.sttlBank)}</div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const rightTable = () => {
    return (
      <>
        <div className="px-2 m-0 rounded py-2 w-auto">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column" style={{marginRight:'4px'}}>
              {data.slice(0, Math.ceil(data.length / 2)).map((item: any) => (Object.entries(item).map(([key, value]) => {
                // let count= data.length
                console.log()
                return (
                  <div className="d-flex bg-f4  border mb-1" >
                    <div className=" w-200px d-flex align-items-center border-0  s-1214 p-2 bg-fff Lato-700 " style={{height:'45px'}}>{CheckUndefined(key)}</div>
                    <div className=" w-500px d-flex  align-items-center p-2 s-1214 ">{CheckUndefined(value)}</div>
                  </div>
                )
              })))}
            </div>
            <div className="d-flex flex-column">
            {data.slice(Math.floor(data.length / 2)).map((item: any) => (Object.entries(item).map(([key, value]) => {
              // let count= data.length
              console.log()
              return(
                <div className="d-flex bg-f4  border mb-1" >
                  <div className=" w-200px  d-flex align-items-center border-0  s-1214  p-2 bg-fff Lato-700 " style={{height:'45px'}}>{CheckUndefined(key)}</div>
                  <div className=" w-500px d-flex  align-items-center p-2 s-1214 ">{CheckUndefined(value)}</div>
                </div>
              )
            })))}
          </div>
          </div>
        </div>
      </>
    );
  };
  let widthWindows_ = window.innerWidth;


  return (
    <>
      <Modal
        open={modalConditionDetail}
        onCancel={(e) => setModal("modalConditionDetail")}
        maskClosable={false}
        mask={true}
        maskStyle={{
          zIndex: 15,
        }}
        style={{
          position: "fixed",
          overflow: "hidden",
          // right: 'auto',
          // bottom: 'auto',
          // top: '50%',
          // left: '50%',
          // marginRight: '-30%',
          // transform: 'translate(-50%, -50%)',
          //
          right: "1%",
          bottom: "auto",
          top: "2%",
          left: "1%",
          minWidth: "min-content",
          zIndex: 90,
        }}
        bodyStyle={{
          maxHeight: '80vh',
          maxWidth: '100vw',
          
        }}
        footer={false}
        title={state.titleModal}
      >
        <div className="">
          <div style={{  paddingRight: "10px" }}>
            <div className="d-flex align-items-center m-2 ">
              <p className="w-700 Lato-700 c-29 s-1620">Source Detail</p>
            </div>
            <div className="d-flex " >
              <div className="flex-fill">
                <div className="d-flex flex-column wd-webkit">
                  <div className="d-flex wrap  ">
                    <div className="row  w-100 p-0">

                      {/* <div className="d-flex justify-content-end my-2">
                    
                      </div> */}
                      {rightTable()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal>

      {/* <ModalDetailUnmatchedList detailModal={state.detailModal} setModal={() => dispatch({ type: "detailModal", value: !state.detailModal })} dispatchFirstParent={dispatchParent} stateFirstParent={stateParent} stateParent={state} stateOptions={stateOptions} /> */}
    </>
  );
};
interface test {
  data: ModelDetailUnmatchedList | null
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: test = {
  data: null
}

function reducer(state: any, action: any) {

  switch (action.type) {
    case 'data':
      return {
        ...state,
        allData: action.value,
        data: {
          dispNo: action.value.dispNo,
          adjType: action.value.adjType,
          sttlDate: action.value.sttlDate,
          replyDateTime: action.value.replyDateTime,
          transDateTime: action.value.transDateTime,
          transType: action.value.transType,
          direction: action.value.direction,
          srcInst: action.value.srcInst,
          dstInst: action.value.dstInst,
          curr: action.value.curr,
          amt: action.value.amt,
          frmAccNo: action.value.frmAccNo,
          toAccNo: action.value.toAccNo,
          originatorId: action.value.originatorId,
          receipientId: action.value.receipientId,
          sttlBank: action.value.sttlBank,
        }
      };

    default:
      throw new Error();
  }
}

import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { history } from "../../History";
import { Page1DashboardRoute, Page1SystemNetworkRoute, Page2DashboardRoute, Page3DashboardRoute, Page4DashboardRoute, PageMainDashboardRoute, Page2FrameworkRoute } from "../../Routes";
import { listPrivilegePage } from "../../State";
import { ForgotPassword } from "./LoginComponents/ForgotPassword";
import { FormLogin } from "./LoginComponents/FormLogin";
import { loginAction, setMemberIsRegisteredToPIC, setRoleByMemberIdAction, tokenAction } from "../../Store/User";
import { AllPath } from "../../Services";
import { API, urlSelection } from "../../Services/API";
import { transactionJourneyGlobalState } from "../../Store/TransactionJourney";
import Cookies from 'universal-cookie';
import { cookiesGlobal } from "../../State/Cookies";

export const Login = ({ ...props }) => {
  const navigate = useNavigate();
  const userLogin = useSelector((store: any) => store.user);
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const dispatchGlobal = useDispatch();
  const user = useSelector((state: any) => state.user.user);

  const stateGlobalTransactionJourney = useSelector(transactionJourneyGlobalState);

  const getDataUser = useCallback(async () => {
    if (!user) {
      return;
    }
    const { userId, token } = user;
    dispatchGlobal(tokenAction({ token }))
    if (token === null || token === undefined) { }
    else {
      try {
        // get data user
        const bodyGet = await API.get({
          pathCustom: `${AllPath.userApp}/${userId}`,
          bodyCustom: null,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: false,
          needNotification: false,
        })
        const { privilegeId, userLevel, memberId } = bodyGet.data;
        // get data privileges code user page
        const bodyGet2 = await API.get({
          pathCustom: `${AllPath.privilegeDetail}/${privilegeId}`,
          bodyCustom: null,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: false,
          needNotification: false,
        })
        const bodyGet3 = await API.get({
          pathCustom: `${AllPath.member}`,
          bodyCustom: null,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: false,
          needNotification: false,
        })
        // save roles user 
        dispatchGlobal(setRoleByMemberIdAction({ memberId: memberId, data: bodyGet3.data }));
        let saveMemberCodeUser = '';
        if (bodyGet3.data.length > 0) {
          bodyGet3.data.forEach((element1: any) => {
            if (element1.memberId === memberId) {
              if (element1.memberRoleDtos !== null) {
                saveMemberCodeUser = element1.memberCode
              }
            }
          })
        }
        // check user is member PIC
        const bodyGet4 = await API.get({
          pathCustom: `member_pic?memberPicCode=002&userId=${userId}&memberCode=${saveMemberCodeUser}`,
          bodyCustom: null,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: false,
          needNotification: false,
        })
        dispatchGlobal(setMemberIsRegisteredToPIC(bodyGet4.data.length > 0 ? true : false));
        dispatchGlobal(loginAction({ user: user, privilegeId: privilegeId, dataPrivilegeAccess: bodyGet2.data, userLevel: userLevel, memberId: memberId }))
        if (stateGlobalTransactionJourney.locationSearch === '') {
          navigate(PageMainDashboardRoute, { replace: true })
        } else {
          navigate(`${Page2FrameworkRoute}${stateGlobalTransactionJourney.locationSearch}`, { replace: true })
        }
      } catch (error) {
      } finally {
      }
    }
  }, [])

  if (userLogin.user) {
    getDataUser();
    if (userLogin.privilegeAccess !== null) {
      if (userLogin.privilegeAccess.length > 0) {
        let data = [...userLogin.privilegeAccess] // copy data privilege code form state global,.. because immutable cannot change directly
        if (data.includes('101') && stateGlobalTransactionJourney.locationSearch !== '') {
          return <Navigate to={`${Page2FrameworkRoute}${stateGlobalTransactionJourney.locationSearch}`} state={{ from: history.location }} />;
        } else if (data.includes('471')) {
          return <Navigate to={PageMainDashboardRoute} state={{ from: history.location }} />; // 2024-11-06 update first page to new main dashboard page 
        } else if (data.includes('481')) {
          return <Navigate to={Page2DashboardRoute} state={{ from: history.location }} />;
        } else if (data.includes('491')) {
          return <Navigate to={Page3DashboardRoute} state={{ from: history.location }} />;
        } else if (data.includes('501')) {
          return <Navigate to={Page4DashboardRoute} state={{ from: history.location }} />;
        } else if (data.includes('511')) {
          return <Navigate to={Page1SystemNetworkRoute} state={{ from: history.location }} />;
        } else {
          data.sort((a: string, b: string) => parseInt(a) - parseInt(b))
          let pageCode = data[0]; // 51 / 52 / 53
          let pageRouteAndCode: any[] = [] // 51
          JSON.parse(listPrivilegePage).forEach((element: any) => {
            pageRouteAndCode.push({ route: element.route, code: (parseInt(element.id) + 1) }) // get only code and route
          })
          let selectedRouteAndRoute = pageRouteAndCode.filter((element: any) => `${element.code}` === `${pageCode}`)[0]
          return <Navigate to={selectedRouteAndRoute.route} state={{ from: history.location }} />;
        }
      } else {
        navigate('/warning')
      }
    }
  }

  return (
    <>
      {!showForgotPassword && <FormLogin setShowForgotPassword={setShowForgotPassword} />}
      {showForgotPassword && <ForgotPassword setShowForgotPassword={setShowForgotPassword} />}

    </>
  );
}



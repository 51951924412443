import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { Tooltip, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';

const { Dragger } = Upload;

export const UploadFileComponent = ({ ...props }) => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { handleStateParent, statePage, useMultipleFile, useMaxCount } = props;

    useEffect(() => {
        handleStateParent('selectFileArray', fileList)
    }, [fileList])

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);

    const propsUpload: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            if (useMultipleFile === false) {
                setFileList((state) => [file]);
            } else {
                setFileList([...fileList, file]);
            }
            return false;
        },
        multiple: useMultipleFile,
        maxCount: useMaxCount
    };

    useEffect(() => {
        if (props.resetUpload) {
          setFileList([]);
        }
    }, [props.resetUpload]);

    return (
        <>
            <Dragger className='d-flex flex-column p-0 mb-3' {...propsUpload}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                </p>
            </Dragger>
            {fileList.length > 0 ?
                <Upload
                    className='my-4'
                    listType="picture-circle"
                    fileList={fileList}
                    onChange={handleChange}
                    onDownload={(file: UploadFile<any>) => {
                        // console.log(file)
                    }}
                    beforeUpload={(file) => {
                        setFileList([...fileList, file]);
                        return false;
                    }}
                    onRemove={(file) => {
                        try {
                            let fileResultConvert: any = file
                            readFileAsArrayBuffer(fileResultConvert)
                                .then((arrayBuffer) => {
                                    const blob = new Blob([arrayBuffer]);
                                    const url = URL.createObjectURL(blob);
                                    const link = document.createElement("a");
                                    link.href = url;
                                    link.download = file.name;
                                    link.click();
                                    URL.revokeObjectURL(url);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });

                        } catch (error) {
                        }
                        return false;
                    }}
                    multiple={useMultipleFile}
                    showUploadList={
                        {
                            showDownloadIcon: true,
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            downloadIcon: 'Download',
                            removeIcon: <Tooltip style={{ zIndex: 99999999 }} placement="bottomLeft" title={'download'}><DownloadOutlined > </DownloadOutlined>,</Tooltip>
                        }
                    }
                >
                </Upload >
                :
                <></>
            }
        </>
    );
};
function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result instanceof ArrayBuffer) {
                resolve(reader.result);
            } else {
                reject(new Error("Failed to read file as ArrayBuffer"));
            }
        };

        reader.onerror = () => {
            reject(new Error("Failed to read file"));
        };

        reader.readAsArrayBuffer(file);
    });
}

import { API, urlSelection } from '../../Services/API';
import { InsideComponentPage, TableWithLimitOffset } from '../../Components';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { inputStyle, labelStyle } from '../../Styles';
import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InterfaceOptionsSettlementSummaryAcquirer } from './InterfaceOptionsSettlementSummaryAcquirer';
import { ModalSettlementSummaryAcquirer } from './ModalSettlementSummaryAcquirer';
import { ModelSettlementSummary } from '../SettlementSummary/Models/ModelSettlementSummary';
import Select from 'react-select';
import { ShowModalInterface } from '../../Interface';
import { TableSettlementSummaryAcquirer } from './TableSettlementSummaryAcquirer';
import { icon_search_white } from '../../Assets/Images/svg';
import { useNavigate } from 'react-router-dom';

export const SettlementSummaryAcquirer = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let selectedStartDate = stateCustomComponent.selectedStartDate !== '' ? `fromSettlementDate=${stateCustomComponent.selectedStartDate}&` : '';
        let selectedEndDate = stateCustomComponent.selectedEndDate !== '' ? `toSettlementDate=${stateCustomComponent.selectedEndDate}&` : '';
        let selectedCountryIDAcq = stateCustomComponent.selectedCountryID !== '' ? `acqCountry=${stateCustomComponent.selectedCountryID}&` : '';

        // issuer
        let selectedIssuerInstitutionIDDetailCode = stateCustomComponent.selectedIssuerInstitutionIDDetailCode !== '' ? `issuer=${stateCustomComponent.selectedIssuerInstitutionIDDetailCode}&` : '';
        let selectedIssuerSwitcherInstitutionIDDetailCode = stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode !== '' ? `issuerSwitcher=${stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode}&` : '';
        // acquirer
        let selectedAcquirerInstitutionIDDetailCode = stateCustomComponent.selectedAcquirerInstitutionIDDetailCode !== '' ? `acquirer=${stateCustomComponent.selectedAcquirerInstitutionIDDetailCode}&` : '';
        let selectedAcquirerSwitcherInstitutionIDDetailCode = stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode !== '' ? `acquirerSwitcher=${stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode}&` : '';
        //
        let selectedAcquirerDate = stateCustomComponent.selectedAcquirerDate !== '' ? `dateFilter=${stateCustomComponent.selectedAcquirerDate}&` : '';
        // etc
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${search}${limit}${offset}${selectedStartDate}${selectedEndDate}${selectedCountryIDAcq}${selectedIssuerInstitutionIDDetailCode}${selectedIssuerSwitcherInstitutionIDDetailCode}${selectedAcquirerInstitutionIDDetailCode}${selectedAcquirerSwitcherInstitutionIDDetailCode}${selectedAcquirerDate}`;
        let finalParameter2 = `${search}${selectedStartDate}${selectedEndDate}${selectedCountryIDAcq}${selectedIssuerInstitutionIDDetailCode}${selectedIssuerSwitcherInstitutionIDDetailCode}${selectedAcquirerInstitutionIDDetailCode}${selectedAcquirerSwitcherInstitutionIDDetailCode}${selectedAcquirerDate}`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
            //
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}/total_summary?${finalParameter2.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data2', value: response.data });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
                //
            })
            //
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}/total_summary?${finalParameter2.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data2', value: response.data });
            })
        }
    }

    const settlementDateOptions = [
        { label: 'Acquirer Settlement Date', value: 'acquirerSettleDate' },
        { label: 'Issuer Switcher Settlement Date', value: 'issuerSwitcherSettleDate' },
        { label: 'Central Processing Date', value: 'centralProcDate' },
    ];



    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsSettlementSummaryAcquirer, dispatchGlobal)
    }, [])

    useEffect(() => {
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    useEffect(() => {
        dispatchCustomComponent({ type: 'selectedStartDate', value: new Date().toISOString().slice(0, 10) })
        dispatchCustomComponent({ type: 'selectedEndDate', value: new Date().toISOString().slice(0, 10) })
        if (stateCustomComponent.selectedStartDate !== '' && stateCustomComponent.selectedEndDate !== '') initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsSettlementSummaryAcquirer, dispatchGlobal)
    }, [])

    //
    useEffect(() => {
        handleState("selectedAcquirerDate", 'acquirerSettleDate', dispatchCustomComponent);
    }, []);


    const exportToCSVComponent = () => { }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelSettlementSummary) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelSettlementSummary, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
            </div>
        );
    };

    const filterComponentPage = () => {
        return (
            <div className="container-all-select-custom py-3 mt-2">
                <div className="row gx-5 gy-4">
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>Date</p>
                        {/* <Select options={settlementDateOptions} value={settlementDateOptions.filter((option: any) => option.value === state.selectedAcquirerDate)} onChange={(e) => handleState("selectedAcquirerDate", e.value, dispatchCustomComponent)}/> */}
                        <Select options={settlementDateOptions} value={settlementDateOptions.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerDate)} onChange={(e) => handleState("selectedAcquirerDate", e?.value, dispatchCustomComponent)} />
                        {/* <Select options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode)} onChange={(e) => handleState("selectedAcquirerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)} /> */}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>Start Date</p>
                        <input value={stateCustomComponent.selectedStartDate} onChange={(e) => handleState('selectedStartDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>End Date</p>
                        <input value={stateCustomComponent.selectedEndDate} onChange={(e) => handleState('selectedEndDate', e.target.value, dispatchCustomComponent)} type='date' className={inputStyle} />
                    </div>
                    {/* <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>ACQUIRER COUNTRY</p>
                        <Select options={stateOptions.optionsCountry} value={stateOptions.optionsCountry.filter((option: any) => option.value === stateCustomComponent.selectedCountryID)} onChange={(e) => handleState("selectedCountryID", e.value, dispatchCustomComponent)} />
                    </div> */}
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                    </div>
                    {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-sm-12 col-xs-12">
                    </div> */}
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>ACQUIRER</p>
                        <Select options={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail} value={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerInstitutionIDDetailCode)} onChange={(e) => handleState("selectedAcquirerInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>ACQUIRER SWITCHER</p>
                        <Select options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode)} onChange={(e) => handleState("selectedAcquirerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>ISSUER SWITCHER</p>
                        <Select
                            options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail}
                            value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode)}
                            onChange={(e) => handleState("selectedIssuerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)}
                        />
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
                        <p className={labelStyle}>ISSUER</p>
                        <Select options={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail} value={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedIssuerInstitutionIDDetailCode)} onChange={(e) => handleState("selectedIssuerInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
                    </div>


                </div>
                <div className='row gx-5 mt-4'>
                    <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center">
                        <button className="d-flex align-items-center px-4 py-3 br-20 bd-d4 bg-e8 bd-29 text-white" onClick={(e) => initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsSettlementSummaryAcquirer, dispatchGlobal)}>
                            {icon_search_white}
                            <p className="s-1418 c-ff Lato-600 mx-2">SEARCH</p>
                        </button>
                    </div>

                </div>
            </div>
        )
    }
    useEffect(() => {
        if (state.modalCondition === false) {
            dispatch({ type: 'resetAllSelected' })
        }
    }, [state.modalCondition])
    // console.log('total acq1:',state.data2.totalAcq)
    // console.log('total acq:',state.totalAcq)

    const tableComponentPage = () => {
        return (
            <TableSettlementSummaryAcquirer
                //
                stateParent={state}
                data2={state.data2}
                //
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                editData={(item: any, idSelected: string) => {
                    if (idSelected === 'issuer') {
                        dispatch({
                            type: 'setIssuer',
                            value: {
                                issuerId: item.issuerId,
                                issuerSettleDate: item.issuerSettleDate,
                                //
                                acquirerSwitchId: item.acquirerSwitchId,
                                acquirerSwitchSettleDate: item.acquirerSwitchSettleDate,
                                //
                                procDate: item.procDate,
                                //
                                titleTableLeft: 'Issuer',
                                titleTableRight: 'Acquirer Switcher',

                                issuerMemberName: item.issuerMemberName,
                                acquirerSwitchMemberName: item.acquirerSwitchMemberName,

                                filterAcqId: item.acquirerId,
                                filterIssId: item.issuerId,
                                filterAcqSwitchId: item.acquirerSwitchId,
                                filterIssSwitchId: item.issuerSwitchId,

                            }
                        });
                    }
                    else if (idSelected === 'acquirer') {
                        dispatch({
                            type: 'setAcquirer',
                            value: {
                                acquirerId: item.acquirerId,
                                acquirerSettleDate: item.acquirerSettleDate,
                                //
                                acquirerSwitchId: item.acquirerSwitchId,
                                acquirerSwitchSettleDate: item.acquirerSwitchSettleDate,
                                //
                                procDate: item.procDate,
                                //
                                titleTableLeft: 'Acquirer',
                                titleTableRight: 'Acquirer Switcher',

                                acquirerSwitchMemberName: item.acquirerSwitchMemberName,
                                acquirerMemberName: item.acquirerMemberName,

                                filterAcqId: item.acquirerId,
                                filterIssId: item.issuerId,
                                filterAcqSwitchId: item.acquirerSwitchId,
                                filterIssSwitchId: item.issuerSwitchId,
                            }
                        });
                    }
                    else if (idSelected === 'acquirerSwitch') {
                        dispatch({
                            type: 'setAcquirerSwitch',
                            value: {
                                acquirerSwitchId: item.acquirerSwitchId,
                                acquirerSwitchSettleDate: item.acquirerSwitchSettleDate,
                                //
                                procDate: item.procDate,
                                //
                                titleTableLeft: 'Acquirer Switcher',
                                titleTableRight: 'Acquirer Switcher',

                                acquirerSwitchMemberName: item.acquirerSwitchMemberName,

                                filterAcqId: item.acquirerId,
                                filterIssId: item.issuerId,
                                filterAcqSwitchId: item.acquirerSwitchId,
                                filterIssSwitchId: item.issuerSwitchId,
                            }
                        });
                    }
                    else if (idSelected === 'issuerSwitch') {
                        dispatch({
                            type: 'setIssuerSwitch',
                            value: {
                                issuerSwitchId: item.issuerSwitchId,
                                issuerSwitchSettleDate: item.issuerSwitchSettleDate,
                                //
                                acquirerSwitchId: item.acquirerSwitchId,
                                acquirerSwitchSettleDate: item.acquirerSwitchSettleDate,
                                //
                                procDate: item.procDate,
                                //
                                titleTableLeft: 'Issuer Switcher',
                                titleTableRight: 'Acquirer Switcher',

                                acquirerSwitchMemberName: item.acquirerSwitchMemberName,
                                issuerSwitchMemberName: item.issuerSwitchMemberName,

                                filterAcqId: item.acquirerId,
                                filterIssId: item.issuerId,
                                filterAcqSwitchId: item.acquirerSwitchId,
                                filterIssSwitchId: item.issuerSwitchId,
                            }
                        });
                    }
                    dispatch({ type: 'modalCondition', value: !(state.modalCondition) });
                }}
                //
                currentPage={state.currentPage}

            />
        )
    }

    const modalAddDeleteComponentPage = () => {
        return (
            <ModalSettlementSummaryAcquirer
                modalCondition={state.modalCondition}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                //
                stateParent={state}
                dispatchParent={dispatch}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={false}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={false}
                needAddButton={false}
                needSearchFunction={false}
                needDownloadButton={false}

                needComponentHeaderTableCenter={true}

            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'settlement',
    titlePage: 'Settlement Summary ( Acquirer )',
    privilegeAccess: { view: '421', add: '', edit: '', delete: '', download: '' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no', },
        { field: 'Central Processing Date', width: 'wd-150', val: 'centralProcDate', },
        { field: 'Acquirer Settle Date', width: 'wd-150', val: 'acquirerSettleDate', },
        { field: 'Issuer Switcher Settle Date', width: 'wd-150', val: 'issuerSwitchSettleDate', }, // issuer switch settle date
        { field: 'Acquirer', width: 'wd-150', val: 'acquirerMemberName', },
        { field: 'Acquirer Count', width: 'wd-150', val: 'acquirerCount', },
        { field: 'Acquirer Amount', width: 'wd-150', val: 'acquirerAmount', }, // acquirerCountryCurr
        { field: 'Acquirer Recon Amount', width: 'wd-150', val: 'acquirerReconAmount', }, // acquirerReconCountryCurr acquirerReconAmount
        { field: 'Acquirer Recon Count', width: 'wd-150', val: 'acquirerReconCount', },
        { field: 'Acquirer Recon Status', width: 'wd-200', val: 'acquirerReconStatus', },
        { field: 'Acquirer Switcher', width: 'wd-150', val: 'acquirerSwitchMemberName', },
        { field: 'Acquirer Switcher Count', width: 'wd-150', val: 'acquirerSwitchCount', },
        { field: 'Acquirer Switcher Amount', width: 'wd-150', val: 'acquirerSwitchAmount', }, // acquirerSwitchCountryCurr acquirerSwitchAmount
        { field: 'Acquirer Switcher Recon Amount', width: 'wd-150', val: 'acquirerSwitchReconAmount', }, // acquirerSwitchReconCountryCurr 
        { field: 'Acquirer Switcher Recon Count', width: 'wd-150', val: 'acquirerSwitchReconCount', }, //  
        { field: 'Acquirer Switcher Recon Status', width: 'wd-150', val: 'acquirerSwitchReconStatus', }, //==============
        { field: 'Issuer Switcher', width: 'wd-150', val: 'issuerSwitchMemberName', },
        { field: 'Issuer Switcher Count', width: 'wd-150', val: 'issuerSwitchCount', },
        { field: 'Issuer Switcher Amount', width: 'wd-150', val: 'issuerSwitchAmount', }, //  issuerSwitchCountryCurr issuerSwitchAmount
        { field: 'Issuer Switcher Recon Amount', width: 'wd-150', val: 'issuerSwitchReconAmount', }, // issuerSwitchReconCountryCurr issuerSwitchReconAmount
        { field: 'Issuer Switcher Recon Count', width: 'wd-150', val: 'issuerSwitchReconCount', },
        { field: 'Issuer Switcher Recon Status', width: 'wd-150', val: 'issuerSwitchReconStatus', },
        { field: 'Issuer Switcher Settlement Amount', width: 'wd-150', val: 'issuerSwitchSettAmount', }, // issuerSwitchSettCountryCurr issuerSwitchSettAmount
        { field: 'Issuer', width: 'wd-150', val: 'issuerMemberName', },
        { field: 'Issuer Count', width: 'wd-150', val: 'issuerCount', },
        { field: 'Issuer Amount', width: 'wd-200', val: 'issuerAmount', }, // issuerCountryCurr
        { field: 'Issuer Recon Amount', width: 'wd-150', val: 'issuerReconAmount', }, // issuerReconCountryCurr
        { field: 'Issuer Recon Count', width: 'wd-150', val: 'issuerReconCount', },
        { field: 'Issuer Recon Status', width: 'wd-150', val: 'issuerReconStatus', },
    ],
    toggle: ['no', 'centralProcDate', 'acquirerSettleDate', 'issuerSwitchSettleDate', 'acquirerMemberName', 'acquirerCount', 'acquirerAmount', 'acquirerReconAmount', 'acquirerReconCount', 'acquirerReconStatus', 'acquirerSwitchMemberName', 'acquirerSwitchCount', 'acquirerSwitchReconCount', 'acquirerSwitchAmount', 'acquirerSwitchReconAmount', 'acquirerSwitchReconStatus', 'issuerSwitchMemberName', 'issuerSwitchCount', 'issuerSwitchAmount', 'issuerSwitchReconAmount', 'issuerSwitchReconCount', 'issuerSwitchReconStatus','issuerSwitchSettAmount', 'issuerMemberName', 'issuerCount', 'issuerAmount', 'issuerReconAmount', 'issuerReconCount', 'issuerReconStatus'],
    complete_toggle: ['no', 'centralProcDate', 'acquirerSettleDate', 'issuerSwitchSettleDate', 'acquirerMemberName', 'acquirerCount', 'acquirerAmount', 'acquirerReconAmount', 'acquirerReconCount', 'acquirerReconStatus', 'acquirerSwitchMemberName', 'acquirerSwitchCount', 'acquirerSwitchReconCount', 'acquirerSwitchAmount', 'acquirerSwitchReconAmount', 'acquirerSwitchReconStatus', 'issuerSwitchMemberName', 'issuerSwitchCount', 'issuerSwitchAmount', 'issuerSwitchReconAmount', 'issuerSwitchReconCount', 'issuerSwitchReconStatus','issuerSwitchSettAmount', 'issuerMemberName', 'issuerCount', 'issuerAmount', 'issuerReconAmount', 'issuerReconCount', 'issuerReconStatus'],
    list_column_name: ['No.', 'Central Processing Date', 'Acquirer Settle Date', 'Issuer Switcher Settle Date', 'Acquirer', 'Acquirer Count', 'Acquirer Amount', 'Acquirer Recon Amount', 'Acquirer Recon Count', 'Acquirer Recon Status', 'Acquirer Switcher', 'Acquirer Switcher Count', 'Acquirer Switcher Recon Count', 'Acquirer Switcher Amount', 'Acquirer Switcher Recon Amount', 'Acquirer Switcher Recon Status', 'Issuer Switcher', 'Issuer Switcher Count', 'Issuer Switcher Amount', 'Issuer Switcher Recon Amount', 'Issuer Switcher Recon Count', 'Issuer Switcher Recon Status', 'Issuer Switcher Settlement Amount', 'Issuer', 'Issuer Count', 'Issuer Amount', 'Issuer Recon Amount', 'Issuer Recon Count', 'Issuer Recon Status'],
    // 
    modalCondition: false,
    savedShowModal: [], // new
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 4500,
    dataLength: 0, // zIndex
    //
    selectedAcq: '',
    selectedAcqSwitcher: '',
    selectedAcqDate: '',

    //
    selectedIssuerId: '',
    selectedIssuerSettleDate: '',

    selectedAcquirerId: '',
    selectedAcquirerSettleDate: '',

    selectedAcquirerSwitchId: '',
    selectedAcquirerSwitchSettleDate: '',

    selectedIssuerSwitchId: '',
    selectedIssuerSwitchSettleDate: '',
    //
    selectedProcDate: '',

    whatIsSelectedNow: '',

    titleTableLeft: '',
    titleTableRight: '',

    //
    selectedIssuerMemberName: '',
    selectedIssuerSwitchMemberName: '',
    selectedAcquirerMemberName: '',
    selectedAcquirerSwitchMemberName: '',

    //
    selectedAcquirerDate: 'acquirerSettleDate',
    //
    data2: [],

    filterAcqId: "",
    filterIssId: "",
    filterAcqSwitchId: "",
    filterIssSwitchId: "",
};


// sttlId ModelSettlementSummary
function reducer(state: any, action: any) {
    let showDataResult: any[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSettlementSummary) => {
                        element1.id = element1.sttlId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSettlementSummary) => {
                        element1.id = element1.sttlId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSettlementSummary) => {
                        showDataResult.push(
                            { idModal: element1.sttlId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.sttlId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'setIssuer':
            return {
                ...state,
                selectedIssuerId: action.value.issuerId,
                selectedIssuerSettleDate: action.value.issuerSettleDate,

                selectedAcquirerId: '',
                selectedAcquirerSettleDate: '',

                selectedAcquirerSwitchId: action.value.acquirerSwitchId,
                selectedAcquirerSwitchSettleDate: action.value.acquirerSwitchSettleDate,

                selectedIssuerSwitchId: '',
                selectedIssuerSwitchSettleDate: '',

                selectedProcDate: action.value.procDate,

                whatIsSelectedNow: 'issuerId',

                titleTableLeft: action.value.titleTableLeft,
                titleTableRight: action.value.titleTableRight,

                selectedIssuerMemberName: action.value.issuerMemberName,
                selectedAcquirerSwitchMemberName: action.value.acquirerSwitchMemberName,



                filterAcqId: action.value.filterAcqId,
                filterIssId: action.value.filterIssId,
                filterAcqSwitchId: action.value.filterAcqSwitchId,
                filterIssSwitchId: action.value.filterIssSwitchId,
            };
        case 'setAcquirer':
            return {
                ...state,
                selectedIssuerId: '',
                selectedIssuerSettleDate: '',

                selectedAcquirerId: action.value.acquirerId,
                selectedAcquirerSettleDate: action.value.acquirerSettleDate,

                selectedAcquirerSwitchId: action.value.acquirerSwitchId,
                selectedAcquirerSwitchSettleDate: action.value.acquirerSwitchSettleDate,

                selectedIssuerSwitchId: '',
                selectedIssuerSwitchSettleDate: '',

                selectedProcDate: action.value.procDate,

                whatIsSelectedNow: 'acquirerId',

                titleTableLeft: action.value.titleTableLeft,
                titleTableRight: action.value.titleTableRight,

                selectedAcquirerMemberName: action.value.acquirerMemberName,
                selectedAcquirerSwitchMemberName: action.value.acquirerSwitchMemberName,

                filterAcqId: action.value.filterAcqId,
                filterIssId: action.value.filterIssId,
                filterAcqSwitchId: action.value.filterAcqSwitchId,
                filterIssSwitchId: action.value.filterIssSwitchId,
            };
        case 'setAcquirerSwitch':
            return {
                ...state,
                selectedIssuerId: '',
                selectedIssuerSettleDate: '',

                selectedAcquirerId: '',
                selectedAcquirerSettleDate: '',

                selectedAcquirerSwitchId: action.value.acquirerSwitchId,
                selectedAcquirerSwitchSettleDate: action.value.acquirerSwitchSettleDate,

                selectedIssuerSwitchId: '',
                selectedIssuerSwitchSettleDate: '',

                selectedProcDate: action.value.procDate,

                whatIsSelectedNow: 'acquirerSwitchId',

                titleTableLeft: action.value.titleTableLeft,
                titleTableRight: action.value.titleTableRight,

                selectedAcquirerSwitchMemberName: action.value.acquirerSwitchMemberName,

                filterAcqId: action.value.filterAcqId,
                filterIssId: action.value.filterIssId,
                filterAcqSwitchId: action.value.filterAcqSwitchId,
                filterIssSwitchId: action.value.filterIssSwitchId,
            };
            
        case 'setIssuerSwitch':
            return {
                ...state,
                selectedIssuerId: '',
                selectedIssuerSettleDate: '',

                selectedAcquirerId: '',
                selectedAcquirerSettleDate: '',

                selectedAcquirerSwitchId: action.value.acquirerSwitchId,
                selectedAcquirerSwitchSettleDate: action.value.acquirerSwitchSettleDate,

                selectedIssuerSwitchId: action.value.issuerSwitchId,
                selectedIssuerSwitchSettleDate: action.value.issuerSwitchSettleDate,

                selectedProcDate: action.value.procDate,

                whatIsSelectedNow: 'issuerSwitchId',

                titleTableLeft: action.value.titleTableLeft,
                titleTableRight: action.value.titleTableRight,

                selectedIssuerSwitchMemberName: action.value.issuerSwitchMemberName,
                selectedAcquirerSwitchMemberName: action.value.acquirerSwitchMemberName,

                filterAcqId: action.value.filterAcqId,
                filterIssId: action.value.filterIssId,
                filterAcqSwitchId: action.value.filterAcqSwitchId,
                filterIssSwitchId: action.value.filterIssSwitchId,
            };
        case 'resetAllSelected':
            return {
                ...state,
                selectedIssuerId: '',
                selectedIssuerSettleDate: '',

                selectedAcquirerId: '',
                selectedAcquirerSettleDate: '',

                selectedAcquirerSwitchId: '',
                selectedAcquirerSwitchSettleDate: '',

                selectedIssuerSwitchId: '',
                selectedIssuerSwitchSettleDate: '',

                selectedProcDate: '',

                whatIsSelectedNow: '',

                titleTableLeft: '',
                titleTableRight: '',

                filterAcqId: '',
                filterIssId: '',
                filterAcqSwitchId: '',
                filterIssSwitchId: '',
            };
        case 'selectedAcq':
            return {
                ...state,
                selectedAcq: action.value
            };
        case 'selectedAcqSwitcher':
            return {
                ...state,
                selectedAcqSwitcher: action.value
            };
        case 'selectedAcqDate':
            return {
                ...state,
                selectedAcqDate: action.value
            };

        case 'selectedIssuerSettleDate':
            return {
                ...state,
                selectedIssuerSettleDate: action.value
            };
        case 'selectedAcquirerSettleDate':
            return {
                ...state,
                selectedAcquirerSettleDate: action.value
            };
        case 'selectedAcquirerSwitchSettleDate':
            return {
                ...state,
                selectedAcquirerSwitchSettleDate: action.value
            };
        case 'selectedIssuerSwitchSettleDate':
            return {
                ...state,
                selectedIssuerSwitchSettleDate: action.value
            };
        case 'selectedAcqDate':
            return {
                ...state,
                selectedAcqDate: action.value
            };
        //
        case 'selectedAcquirerDate':
            return {
                ...state,
                selectedAcquirerDate: action.value
            };

        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'modalCondition':
            return {
                ...state,
                modalCondition: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                modalCondition: !(state.modalCondition),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                modalCondition: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // case 'changeData':
        //     return {
        //         ...state,
        //         totalPages: action.value.totalPages,
        //         currentPage: action.value.currentPage,
        //         //
        //         // totalAcqCounts: action.value.totalAcqCount,
        //         // totalAcqAmounts: action.value.totalAcqAmounts,
        //         // totalAcqReconAmounts: action.value.totalAcqReconAmounts,
        //         // totalAcqReconCounts: action.value.totalAcqReconCounts,
        //         // totalAcqSwitchCounts: action.value.totalAcqSwitchCounts,
        //         // totalAcqSwitchAmounts: action.value.totalAcqSwitchAmounts,
        //         // totalAcqSwitchReconAmounts: action.value.totalAcqSwitchReconAmounts,
        //         // totalAcqSwitchReconCounts: action.value.totalAcqSwitchReconCounts,
        //         // totalIssCounts: action.value.totalIssCounts,
        //         // totalIssAmounts: action.value.totalIssAmounts,
        //         // totalIssReconAmounts: action.value.totalIssReconAmounts,
        //         // totalIssReconCounts: action.value.totalIssReconCounts,
        //         // totalIssSwitchCounts: action.value.totalIssSwitchCounts,
        //         // totalIssSwitchAmounts: action.value.totalIssSwitchAmounts,
        //         // totalIssSwitchReconAmounts: action.value.totalIssSwitchReconAmounts,
        //         // totalIssSwitchReconCounts: action.value.totalIssSwitchReconCounts,
        //         //
        //         totalAcqCount: action.value.totalAcqCount,
        //         totalAcqAmount: action.value.totalAcqAmount,
        //         totalAcqReconAmount: action.value.totalAcqReconAmount,
        //         totalAcqReconCount: action.value.totalAcqReconCount,
        //         totalAcqSwitchCount: action.value.totalAcqSwitchCount,
        //         totalAcqSwitchAmount: action.value.totalAcqSwitchAmount,
        //         totalAcqSwitchReconAmount: action.value.totalAcqSwitchReconAmount,
        //         totalAcqSwitchReconCount: action.value.totalAcqSwitchReconCount,
        //         totalIssCount: action.value.totalIssCount,
        //         totalIssAmount: action.value.totalIssAmounts,
        //         totalIssReconAmount: action.value.totalIssReconAmount,
        //         totalIssReconCount: action.value.totalIssReconCount,
        //         totalIssSwitchCount: action.value.totalIssSwitchCount,
        //         totalIssSwitchAmount: action.value.totalIssSwitchAmount,
        //         totalIssSwitchReconAmount: action.value.totalIssSwitchReconAmount,
        //         totalIssSwitchReconCount: action.value.totalIssSwitchReconCount,
        //     };
        case 'data2':
            return {
                ...state,
                data2: action.value
                //
            };
        // case 'totalAcqRecon':
        //     return {
        //         ...state,
        //         totalAcqRecon: action.value
        //     };
        // case 'totalAcqSwitch':
        //     return {
        //         ...state,
        //         totalAcqSwitch: action.value
        //     };
        // case 'totalAcqSwitchRecon':
        //     return {
        //         ...state,
        //         totalAcqSwitchRecon: action.value
        //     };
        // case 'totalIss':
        //     return {
        //         ...state,
        //         totalIss: action.value
        //     };
        // case 'totalIssRecon':
        //     return {
        //         ...state,
        //         totalIssRecon: action.value
        //     };
        // case 'totalIssSwitch':
        //     return {
        //         ...state,
        //         totalIssSwitch: action.value
        //     };
        // case 'totalIssSwitchRecon':
        //     return {
        //         ...state,
        //         totalIssSwitchRecon: action.value
        //     };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        //
        case "totalAcqCount":
            return {
                ...state,
                totalAcqCount: action.value,
            };
        case "totalAcqAmount":
            return {
                ...state,
                totalAcqAmount: action.value,
            };
        case "totalAcqReconAmount":
            return {
                ...state,
                totalAcqReconAmount: action.value,
            };
        case "totalAcqReconCount":
            return {
                ...state,
                totalAcqReconCount: action.value,
            };
        case "totalAcqSwitchCount":
            return {
                ...state,
                totalAcqSwitchCount: action.value,
            };
        case "totalAcqSwitchAmount":
            return {
                ...state,
                totalAcqSwitchAmount: action.value,
            };
        case "totalAcqSwitchReconAmount":
            return {
                ...state,
                totalAcqSwitchReconAmount: action.value,
            };
        case "totalAcqSwitchReconCount":
            return {
                ...state,
                totalAcqSwitchReconCount: action.value,
            };
        case "totalIssSwitchCount":
            return {
                ...state,
                totalIssSwitchCount: action.value,
            };
        case "totalIssSwitchAmount":
            return {
                ...state,
                totalIssSwitchAmount: action.value,
            };
        case "totalIssSwitchReconAmount":
            return {
                ...state,
                totalIssSwitchReconAmount: action.value,
            };
        case "totalIssSwitchReconCount":
            return {
                ...state,
                totalIssSwitchReconCount: action.value,
            };
        case "totalIssCount":
            return {
                ...state,
                totalIssCount: action.value,
            };
        case "totalIssAmount":
            return {
                ...state,
                totalIssAmount: action.value,
            };
        case "totalIssReconAmount":
            return {
                ...state,
                totalIssReconAmount: action.value,
            };
        case "totalIssReconCount":
            return {
                ...state,
                totalIssReconCount: action.value,
            };
        case "total":
            return {
                ...state,
                total: action.value,
            };
        default:
            throw new Error();
    }
}

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer, useState } from 'react';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-modal';
import { ModelInterfaceFileItem } from './ModelInterfaceFileItem';
import { Page36Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalInterfaceFileItem = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props

    const {
        optionsFileDataTypeDetailCode,
        optionsFileDataPaddingDetailCode,
        optionsFileDataPaddingOptionDetailCode,
        optionsFieldMappingDetailCode,
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])


    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["interfileItemName", "interfileItemType", "interfileItemPad", "interfileItemPadOpt", "interfileItemMappingCode"]
        let limit = [32, 3, 3, 3, 3]
        let index = names.indexOf(nameState)

        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'interfileItemSize') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 1000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
                needLoading: true,
            }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            interfaceFileItemId,
            interfileItemName,
            interfileItemSize,
            interfileItemType,
            interfileItemPad,
            interfileItemPadOpt,
            interfileItemMappingCode,
            interfileItemCreateBy,
            interfileItemCreateDate,
            interfileItemUpdateBy,
            interfileItemUpdateDate,
            interfileItemDeleteBy,
            interfileItemDeleteDate,
            interfileItemIsDelete,

        } = statePage.data
        const data = {
            interfileItemName: interfileItemName,
            interfileItemSize: interfileItemSize,
            interfileItemType: interfileItemType,
            interfileItemPad: interfileItemPad,
            interfileItemPadOpt: interfileItemPadOpt,
            interfileItemMappingCode: interfileItemMappingCode,
        }
        const a = CheckAllFields(
            [
                interfileItemName,
                interfileItemSize,
                interfileItemType,
                interfileItemPad,
                interfileItemPadOpt,
                interfileItemMappingCode,
            ],
            [
                'ITEM NAME',
                'ITEM SIZE',
                'ITEM TYPE',
                'ITEM PADDING',
                'ITEM PADDING OPTION',
                'ITEM MAPPING CODE',
            ]

        )
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>ITEM NAME</p>
                                <input value={statePage.data.interfileItemName} onChange={(e) => handleState('interfileItemName', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>ITEM SIZE</p>
                                <input type='number' min={1} value={statePage.data.interfileItemSize} onChange={(e) => handleState('interfileItemSize', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>ITEM TYPE</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileDataTypeDetailCode} value={optionsFileDataTypeDetailCode.filter((option: any) => option.value === statePage.data.interfileItemType)} onChange={(e) => handleState('interfileItemType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ITEM PADDING</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileDataPaddingDetailCode} value={optionsFileDataPaddingDetailCode.filter((option: any) => option.value === statePage.data.interfileItemPad)} onChange={(e) => handleState('interfileItemPad', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ITEM PADDING OPTION</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileDataPaddingOptionDetailCode} value={optionsFileDataPaddingOptionDetailCode.filter((option: any) => option.value === statePage.data.interfileItemPadOpt)} onChange={(e) => handleState('interfileItemPadOpt', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ITEM MAPPING CODE</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFieldMappingDetailCode} value={optionsFieldMappingDetailCode.filter((option: any) => option.value === statePage.data.interfileItemMappingCode)} onChange={(e) => handleState('interfileItemMappingCode', e.value)} />
                            </div>

                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page36Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInterfaceFileItem({
        id: null,
        interfaceFileItemId: null,
        interfileItemName: null,
        interfileItemSize: 1,
        interfileItemType: null,
        interfileItemPad: null,
        interfileItemPadOpt: null,
        interfileItemMappingCode: null,
        interfileItemCreateBy: null,
        interfileItemCreateDate: null,
        interfileItemUpdateBy: null,
        interfileItemUpdateDate: null,
        interfileItemDeleteBy: null,
        interfileItemDeleteDate: null,
        interfileItemIsDelete: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: "",
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceFileItem) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceFileItem({
                    id: null,
                    interfaceFileItemId: null,
                    interfileItemName: null,
                    interfileItemSize: 1,
                    interfileItemType: null,
                    interfileItemPad: null,
                    interfileItemPadOpt: null,
                    interfileItemMappingCode: null,
                    interfileItemCreateBy: null,
                    interfileItemCreateDate: null,
                    interfileItemUpdateBy: null,
                    interfileItemUpdateDate: null,
                    interfileItemDeleteBy: null,
                    interfileItemDeleteDate: null,
                    interfileItemIsDelete: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceFileItem({
                    id: action.value.interfaceFileItemId,
                    interfaceFileItemId: action.value.interfaceFileItemId,
                    interfileItemName: action.value.interfileItemName,
                    interfileItemSize: action.value.interfileItemSize,
                    interfileItemType: action.value.interfileItemType,
                    interfileItemPad: action.value.interfileItemPad,
                    interfileItemPadOpt: action.value.interfileItemPadOpt,
                    interfileItemMappingCode: action.value.interfileItemMappingCode,
                    interfileItemCreateBy: action.value.interfileItemCreateBy,
                    interfileItemCreateDate: action.value.interfileItemCreateDate,
                    interfileItemUpdateBy: action.value.interfileItemUpdateBy,
                    interfileItemUpdateDate: action.value.interfileItemUpdateDate,
                    interfileItemDeleteBy: action.value.interfileItemDeleteBy,
                    interfileItemDeleteDate: action.value.interfileItemDeleteDate,
                    interfileItemIsDelete: action.value.interfileItemIsDelete,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

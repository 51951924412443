interface InterfaceInstitutionCode{
    id: null | number | string;
    memberInstCodeId: string | null;
    memberId: string | null;
    instCcgCode: string| null;
    instCcdCode: string | null;
    instCcdCodeName: string | null;
    memberBcapCode: string | null;
    memberInstDesc: string | null;
    memberInstCode: string | string | null;
}


export class ModelInstitutionCode {
    id: null | number | string;
    memberInstCodeId: string | null;
    memberId: string | null;
    instCcgCode: string| null;
    instCcdCode: string | null;
    instCcdCodeName: string | null;
    memberBcapCode: string | null;
    memberInstDesc: string | null;
    memberInstCode: string | string | null;

    constructor(value: InterfaceInstitutionCode) {
        this.id = value.id;
        this.memberInstCodeId = value.memberInstCodeId;
        this.memberId = value.memberId;
        this.instCcgCode = value.instCcgCode;
        this.instCcdCode= value.instCcdCode;
        this.instCcdCodeName = value.instCcdCodeName;
        this.memberBcapCode = value.memberBcapCode;
        this.memberInstDesc = value.memberInstDesc;
        this.memberInstCode = value.memberInstCode;
    }
}



const Page1Title = 'Transaction Statistic';
const Page2Title = 'Transaction Inquiry';
const Page3Title = 'SAF Statistic';
const Page4Title = 'SAF Transaction Inquiry';
const Page58Title = 'QR Auth Inquiry';
const PageQRAuthStatisticTitle = 'QR Auth Statistic';
const PageMainDashboardTitle = 'Transaction Statistic New';

const Page56Title = 'Settlement Summary'; //new
const Page5Title = 'Settlement Summary (Acquirer)';
const Page6Title = 'Settlement Summary (Issuer)';
const Page7Title = 'Sales Inquiry';
const Page8Title = 'Dispute Resolution';
const Page9Title = 'Member Payment';
const PageUnmatchedList = 'Unmatched List';
const PageAdjustmentList = 'Adjustment List';
const PageAlertHistoryTitle='Alert History';


const Page10Title = 'User Profile';
const Page11Title = 'User Audit Trail';
const Page12Title = 'Privilege';

const Page13Title = 'Product Definition';
const Page14Title = 'Product & Feature';
const Page15Title = 'Product Feature & Member';

const Page16Title = 'Exchange Rate Setting';
const Page17Title = 'Exchange Rate Member';

const Page18Title = 'Fee Rate Setting';

const Page19Title = 'Log Level';
const Page20Title = 'SAF Control';
const Page21Title = 'Transaction Journey';
const Page22Title = 'Service Profile';
const Page23Title = 'Batch Program Profile';
const Page59Title = 'Alert Parameters';

const Page24Title = 'QR Standard';
const Page25Title = 'QR Standard & QR TLV';
const Page26Title = 'QR Standard & Member';
const Page27Title = 'QR Whitelist & Blacklist';

const Page28Title = 'QR Generator';
const Page29Title = 'QR TLV';

const Page30Title = 'Body Field';
const Page31Title = 'Header Field';
const Page32Title = 'API & Body Field';
const Page33Title = 'API & Header Field';
const Page34Title = 'API Routing Table';
const Page54Title = 'API Route Sequence';

const Page35Title = 'File';
const Page36Title = 'File Element';
const Page37Title = 'File & File Record';
const Page38Title = 'File Record';
const Page39Title = 'File Record & File Element';
const PageFileTransferMonitoringTitle = 'File Transfer Monitoring';

const Page40Title = 'Batch Program Profile'; // fake
const Page41Title = 'Job Schedule';
const Page42Title = 'Job Run';
const Page43Title = 'Job Execution History';

const Page44Title = 'Common Code';
const Page45Title = 'Reason Code';
const Page46Title = 'Reason Code Mapping';
const Page47Title = 'Country Code';

const Page48Title = 'System Usage';
const Page49Title = 'Member Connection Status';
const Page50Title = 'Archive Management';


const Page51Title = 'Member HMAC';
const Page52Title = 'Member Profile';
const Page53Title = 'Batch Scheduler';

const Page57Title = 'Production Simulator Inquiry'
const Page57ATitle = 'QR Auth Simulator Inquiry'


export {
    Page1Title,
    Page2Title,
    Page3Title,
    Page4Title,
    Page5Title,
    Page6Title,
    Page7Title,
    Page8Title,
    Page9Title,
    Page10Title,
    Page11Title,
    Page12Title,
    Page13Title,
    Page14Title,
    Page15Title,
    Page16Title,
    Page17Title,
    Page18Title,
    Page19Title,
    Page20Title,
    Page21Title,
    Page22Title,
    Page23Title,
    Page24Title,
    Page25Title,
    Page26Title,
    Page27Title,
    Page28Title,
    Page29Title,
    Page30Title,
    Page31Title,
    Page32Title,
    Page33Title,
    Page34Title,
    Page35Title,
    Page36Title,
    Page37Title,
    Page38Title,
    Page39Title,
    Page40Title,
    Page41Title,
    Page42Title,
    Page43Title,
    Page44Title,
    Page45Title,
    Page46Title,
    Page47Title,
    Page48Title,
    Page49Title,
    Page50Title,
    Page51Title,
    Page52Title,
    Page53Title,
    Page54Title,
    Page56Title,
    Page57Title,
    Page58Title,
    Page57ATitle,
    Page59Title,
    PageQRAuthStatisticTitle,
    PageUnmatchedList,
    PageAdjustmentList,
    PageMainDashboardTitle,
    PageAlertHistoryTitle,
    PageFileTransferMonitoringTitle
}
import { useEffect, useReducer, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { format, differenceInDays, parse } from "date-fns";
import {
    MRT_ColumnDef,
} from 'material-react-table';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as echarts from 'echarts';

import { changeStateMainDashboard, stateGlobalMainDashboard, handleStateAction } from '../../../Store/MainDashboardSlice';
import { NumberWithCommas, CheckUndefined } from '../../../Utils';
import { inputStyleH25, labelStyle } from "../../../Styles";
import { arrowHeadDownIcon, arrowHeadUpIcon, icon_search_white, undo_icon, errNotificationIcon } from "../../../Assets/Images/svg";
import { DatePickerCalendarIconOnly } from '../../../Components';
import { ModelTransactionMonitoring } from '../../TransactionStatic/Utils/ModelTransactionMonitoring';
import { ModelMainDashboardSummary } from '../Utils/ModelMainDashboardSummary';
import { ModelMainDashboardSummaryDeclined } from '../Utils/ModelMainDashboardSummaryDeclined';
import { MainPageTransactionReactTable } from '../Components/MainPageTransactionReactTable';
import { MainPageSummaryReactTable } from '../Components/MainPageSummaryReactTable';

// unused
import { MainPageSummaryAcquirerReactTable } from '../Components/MainPageSummaryAcquirerReactTable';
import { MainPageSummaryIssuerReactTable } from '../Components/MainPageSummaryIssuerReactTable';
import { MainPageSummaryAcquirerDeclinedReactTable } from '../Components/MainPageSummaryAcquirerDeclinedReactTable';
import { MainPageSummaryIssuerDeclinedReactTable } from '../Components/MainPageSummaryIssuerDeclinedReactTable';

export const DesktopViewMainPage = ({ ...props }) => {

    const stateGlobal = useSelector(stateGlobalMainDashboard);
    const {
        dataPeriodNowTransactionList,
        dataPeriodNowSummary,
        dataPeriodNowSummaryDeclined,
        dataPeriodNowAcquirer,
        dataPeriodNowAcquirerDeclined,
        dataPeriodNowIssuer,
        dataPeriodNowIssuerDeclined,
        dataPeriodNowAcquirerIssuer,
        dataPeriodNowAcquirerIssuerDeclined,
        dataPeriodNowTotalCount,
        dataPeriodNowTotalAcquirer,
        dataPeriodNowTotalIssuer,
        dataPeriodNowTotal,
        dataPeriodNowApprovedCount,
        dataPeriodNowPreDeclinedCount,
        dataPeriodNowDeclinedCount,
        dataPeriodNowTimeoutCount,
        dataPeriodPrevSummary,
        dataPeriodPrevSummaryDeclined,
        dataPeriodPrevTotalCount,
        dataPeriodPrevApprovedCount,
        dataPeriodPrevPreDeclinedCount,
        dataPeriodPrevDeclinedCount,
        dataPeriodPrevTimeoutCount,
        dataInbound,
        dataOutbound,
        dataApi,
        dataTimeout,
        filterStartDate,
        filterEndDate,
        selectedCountryId,
        selectedCountryLabel,
        search,
        rangeDate,
        dataAcquirerSwitcherOptions,
        dataIssuerSwitcherOptions,
        selectedAcquirerSwitcherId,
        selectedAcquirerSwitcherLabel,
        selectedIssuerSwitcherId,
        selectedIssuerSwitcherLabel,
    } = stateGlobal;

    const {
        state,
        stateOptions,
        pagination,
        optionsDayGraph,
        optionsDayGraphWithHour,
        optionsTransactionStatus,
        setPagination,
        initFunctionCustom,
        handleStateComponent,
        handleStateGlobal,
        handleRowClick,
        handleScrollToTransaction,
        reloadDataComponent,
        countDifferencePercentage,
        dispatch
    } = props

    // graph inbound useEffect
    useEffect(() => {
        // Initialize the chart
        const chartDom = document.getElementById('line-chart-inbound');
        const myChart = echarts.init(chartDom);

        // Initialize chart data
        const today = new Date();
        let rangeTime: any = [];
        let dailyTotals: { [key: string]: number } = {};

        let legendCountries: any = [];
        let seriesData: any = [];
        let seriesDataPerCountry: any = [];

        // Populate x-axis time
        const graphInboundDays = differenceInDays(filterEndDate, filterStartDate);

        if (graphInboundDays > 0) {    // case when more than one day
            const startDate = new Date();
            const endDate = parse(filterEndDate, 'yyyy-MM-dd', new Date());

            startDate.setTime(endDate.getTime() - (graphInboundDays - 1) * 24 * 60 * 60 * 1000);

            for (let i = 0; i <= graphInboundDays; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + (i));
                rangeTime.push(currentDate.toISOString().slice(0, 10));
            }
        } else {    // case when only one day
            const currentHour = filterEndDate !== format(today, 'yyyy-MM-dd') ? 24 : today.getHours() + 1;

            for (let i = 0; i < currentHour; i++) {
                const currentDate = new Date();
                currentDate.setHours(i, 0, 0, 0);

                const hourString = currentDate.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                }).replace('.', ':');

                rangeTime.push(hourString);
            }
        }

        // Populate value
        if (graphInboundDays > 0) {     // case when more than one day
            dataInbound?.forEach((issuer: any, index: number) => {
                dailyTotals = {};
                seriesDataPerCountry = [];

                issuer?.graphData?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);
                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: string) => {
                    if (dailyTotals[date]) {
                        seriesDataPerCountry.push(dailyTotals[date]);
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: issuer?.name
                })

                seriesData.push({
                    name: issuer?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        } else {    // case when only one day
            dataInbound?.forEach((country: any, index: number) => {
                seriesDataPerCountry = [];

                rangeTime.forEach((time: any) => {
                    const matchingDataInbound = country?.graphData?.find((item: any) => {
                        const inboundTimePerCountry = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return inboundTimePerCountry === time;
                    });

                    if (matchingDataInbound) {
                        seriesDataPerCountry.push(Number(matchingDataInbound.count));
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: country?.name
                });

                seriesData.push({
                    name: country?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        }

        // Define the chart options
        const option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: legendCountries
            },
            grid: {
                left: '6%',
                right: '6%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: rangeTime
            },
            yAxis: {
                type: 'value'
            },
            series: seriesData
        };

        // Set the chart options
        myChart.setOption(option);

        // Resize the chart on window resize
        window.addEventListener('resize', () => {
            myChart.resize();
        });

        // Cleanup function to destroy chart when component unmounts
        return () => {
            window.removeEventListener('resize', () => myChart.resize());
            myChart.dispose();
        };
    }, [dataInbound]);

    // graph outbound useEffect
    useEffect(() => {
        // Initialize the chart
        const chartDom = document.getElementById('line-chart-outbound');
        const myChart = echarts.init(chartDom);

        // Initialize chart data
        const today = new Date();
        let rangeTime: any = [];
        let dailyTotals: { [key: string]: number } = {};

        let legendCountries: any = [];
        let seriesData: any = [];
        let seriesDataPerCountry: any = [];

        // Populate x-axis time
        const graphOutboundDays = differenceInDays(filterEndDate, filterStartDate);

        if (graphOutboundDays > 0) {    // case when more than one day
            const startDate = new Date();
            const endDate = parse(filterEndDate, 'yyyy-MM-dd', new Date())

            startDate.setTime(endDate.getTime() - (graphOutboundDays - 1) * 24 * 60 * 60 * 1000);

            for (let i = 0; i <= graphOutboundDays; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + i);
                rangeTime.push(currentDate.toISOString().slice(0, 10));
            }
        } else {    // case when only one day
            const currentHour = filterEndDate !== format(today, 'yyyy-MM-dd') ? 24 : today.getHours() + 1;

            for (let i = 0; i < currentHour; i++) {
                const currentDate = new Date();
                currentDate.setHours(i, 0, 0, 0);

                const hourString = currentDate.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                }).replace('.', ':');

                rangeTime.push(hourString);
            }
        }

        // Populate value
        if (graphOutboundDays > 0) {     // case when more than one day
            dataOutbound?.forEach((country: any, index: number) => {
                dailyTotals = {};
                seriesDataPerCountry = [];

                country?.graphData?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);
                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: string) => {
                    if (dailyTotals[date]) {
                        seriesDataPerCountry.push(dailyTotals[date]);
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: country?.name
                });

                seriesData.push({
                    name: country?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        } else {
            dataOutbound?.forEach((country: any, index: number) => {
                seriesDataPerCountry = [];

                rangeTime.forEach((time: any) => {
                    const matchingDataOutbound = country?.graphData?.find((item: any) => {
                        const outboundTimePerCountry = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return outboundTimePerCountry === time;
                    });

                    if (matchingDataOutbound) {
                        seriesDataPerCountry.push(Number(matchingDataOutbound.count));
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: country?.name
                });

                seriesData.push({
                    name: country?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        }

        // Define the chart options
        const option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: legendCountries
            },
            grid: {
                left: '3%',
                right: '6%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: rangeTime
            },
            yAxis: {
                type: 'value'
            },
            series: seriesData
        };

        // Set the chart options
        myChart.setOption(option);

        // Resize the chart on window resize
        window.addEventListener('resize', () => {
            myChart.resize();
        });

        // Cleanup function to destroy chart when component unmounts
        return () => {
            window.removeEventListener('resize', () => myChart.resize());
            myChart.dispose();
        };
    }, [dataOutbound]);

    // graph API useEffect
    useEffect(() => {
        if (selectedCountryId === '410') {
            // Initialize the chart
            const chartDom = document.getElementById('line-chart-api');
            const myChart = echarts.init(chartDom);

            // Initialize chart data
            const today = new Date();
            let rangeTime: any = [];
            let dataCountApiTotal: any = [];
            let dataCountApiFinancial: any = [];
            let dailyTotals: { [key: string]: number } = {}

            // Populate x-axis time
            if (state.graphAPIDays > 1) {    // case when 7, 14, 30 days (daily)
                const startDate = new Date();
                startDate.setDate(today.getDate() - (state.graphAPIDays));

                for (let i = 0; i <= state.graphAPIDays; i++) {
                    const currentDate = new Date(startDate);
                    currentDate.setDate(startDate.getDate() + i);
                    rangeTime.push(currentDate.toISOString().slice(0, 10));
                }
            } else {    // case when today, yesterday (hourly)
                const currentHour = state.graphAPIDays == 1 ? 24 : today.getHours() + 1;

                for (let i = 0; i < currentHour; i++) {
                    const currentDate = new Date();
                    currentDate.setHours(i, 0, 0, 0);

                    const hourString = currentDate.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    }).replace('.', ':');

                    rangeTime.push(hourString);
                }
            }

            // Populate value
            if (state.graphAPIDays > 1) {    // case when 7, 14, 30 days (daily)
                dataApi.total?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);

                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: any) => {
                    if (dailyTotals[date]) {
                        dataCountApiTotal.push(dailyTotals[date]);
                    } else {
                        dataCountApiTotal.push(0);
                    }
                });

                // reset var to calculate financial
                dailyTotals = {}

                dataApi.financial?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);

                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: any) => {
                    if (dailyTotals[date]) {
                        dataCountApiFinancial.push(dailyTotals[date]);
                    } else {
                        dataCountApiFinancial.push(0);
                    }
                });

            } else {    // case when today, yesterday (hourly)
                rangeTime.forEach((time: any) => {
                    const matchingDataTotal = dataApi.total?.find((item: any) => {
                        const apiTime = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return apiTime === time;
                    });

                    const matchingDataFinancial = dataApi.financial?.find((item: any) => {
                        const apiTime = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return apiTime === time;
                    });

                    if (matchingDataTotal) {
                        dataCountApiTotal.push(Number(matchingDataTotal.count));
                    } else {
                        dataCountApiTotal.push(0);
                    }

                    if (matchingDataFinancial) {
                        dataCountApiFinancial.push(Number(matchingDataFinancial.count));
                    } else {
                        dataCountApiFinancial.push(0);
                    }
                });
            }

            // Define the chart options
            const option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['Total API Call', 'Financial']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: rangeTime
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Total API Call',
                        type: 'line',
                        data: dataCountApiTotal,
                        color: '#FF5D5D'
                    },
                    {
                        name: 'Financial',
                        type: 'line',
                        data: dataCountApiFinancial,
                        color: '#1363DF'
                    },
                ]
            };

            // Set the chart options
            myChart.setOption(option);

            // Resize the chart on window resize
            window.addEventListener('resize', () => {
                myChart.resize();
            });

            // Cleanup function to destroy chart when component unmounts
            return () => {
                window.removeEventListener('resize', () => myChart.resize());
                myChart.dispose();
            };
        }
    }, [dataApi]);

    // graph timeout useEffect
    useEffect(() => {
        if (selectedCountryId === '410') {
            // Initialize the chart
            const chartDom = document.getElementById('line-chart-timeout');
            const myChart = echarts.init(chartDom);

            // Initialize chart data
            const today = new Date();
            let rangeTime: any = [];
            let dataCount: any = [];
            let dailyTotals: { [key: string]: number } = {}

            // Populate x-axis time
            if (state.graphTimeoutDays > 1) {    // case when 7, 14, 30 days
                const startDate = new Date();
                startDate.setDate(today.getDate() - (state.graphTimeoutDays));

                for (let i = 0; i <= state.graphTimeoutDays; i++) {
                    const currentDate = new Date(startDate);
                    currentDate.setDate(startDate.getDate() + i);
                    rangeTime.push(currentDate.toISOString().slice(0, 10));
                }
            } else {    // case when today, yesterday (hourly)
                const currentHour = state.graphTimeoutDays == 1 ? 24 : today.getHours() + 1;

                for (let i = 0; i < currentHour; i++) {
                    const currentDate = new Date();
                    currentDate.setHours(i, 0, 0, 0);

                    const hourString = currentDate.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    }).replace('.', ':');

                    rangeTime.push(hourString);
                }
            }

            // Populate value
            if (state.graphTimeoutDays > 1) {    // case when 7, 14, 30 days
                dataTimeout.forEach((item: any) => {
                    const date = item.date.slice(0, 10);

                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: any) => {
                    if (dailyTotals[date]) {
                        dataCount.push(dailyTotals[date]);
                    } else {
                        dataCount.push(0);
                    }
                });
            } else {    // case when today, yesterday (hourly)
                rangeTime.forEach((time: any) => {
                    const matchingDataTimeout = dataTimeout?.find((item: any) => {
                        const apiTime = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return apiTime === time;
                    });

                    if (matchingDataTimeout) {
                        dataCount.push(Number(matchingDataTimeout.count));
                    } else {
                        dataCount.push(0);
                    }
                });
            }

            // Define the chart options
            const option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['Timeout']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: rangeTime
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Timeout',
                        type: 'line',
                        stack: 'Total',
                        data: dataCount,
                        color: '#FF5D5D'
                    },
                ]
            };

            // Set the chart options
            myChart.setOption(option);

            // Resize the chart on window resize
            window.addEventListener('resize', () => {
                myChart.resize();
            });

            // Cleanup function to destroy chart when component unmounts
            return () => {
                window.removeEventListener('resize', () => myChart.resize());
                myChart.dispose();
            };
        }
    }, [dataTimeout]);

    const columnsTransactionList = useMemo<MRT_ColumnDef<ModelTransactionMonitoring>[]>(
        () => [
            {
                accessorKey: 'productType', // product type
                header: 'Product Type',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.productType}`
            },
            {
                accessorKey: 'acquirerSwitcherIdCode', // acquirer switcher
                header: 'Acquirer Switcher',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.length > 0) {
                        stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.acquirerSwitcherIdCode) {
                                data = optionsItem.label;
                            }
                        });
                    }
                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'issuerSwitcherIdCode', // issuer switcher
                header: 'Issuer Switcher',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.length > 0) {
                        stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.issuerSwitcherIdCode) {
                                data = optionsItem.label;
                            }
                        });
                    }
                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'walletId', // wallet name
                header: 'Wallet Name',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsWalletInstitutionIDUseExtAndDetail.length > 0) {
                        stateOptions.optionsWalletInstitutionIDUseExtAndDetail.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.walletId) {
                                data = optionsItem.label;
                            }
                        });
                    }
                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'productFeatureName', // product feature
                header: 'Product Feature',
                maxSize: 150,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.productFeatureName}`
            },
            {
                accessorKey: 'transAmount', // total amount
                header: 'Total Amount',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    if ((row.original.transAmount !== "") && (row.original.transCurrency !== "")) { // if trans amount or currency is empty, return "-"
                        return `${row.original.transCurrency} ${NumberWithCommas(row.original.transAmount)}`
                    } else {
                        return `-`
                    }
                }
            },
            {
                accessorKey: 'transCount', // transaction count
                header: 'Transaction Count',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.transCount}`
            },
            {
                accessorKey: 'transStatus', // status
                header: 'Status',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    return (
                        <p className="fw-bold"
                            style={{
                                color: state.isRowHovered !== row.index
                                    ? row.original.transStatus === "APPROVED" ? '#50ae33' : '#e83d44'
                                    : '#ffffff'
                            }}
                        >
                            {row.original.transStatus}
                        </p>
                    )
                }
            },
            {
                accessorKey: 'fullCreateDate', // last transaction time
                header: 'Last Transaction Time',
                maxSize: 200,
                Cell: ({ row }: any) => `${row.original.fullCreateDate}`
            },
        ],
        [state.isRowHovered, stateOptions],
    );

    const columnsTransactionSummaryAcquirer = useMemo<MRT_ColumnDef<ModelMainDashboardSummary>[]>(
        () => [
            {
                accessorKey: 'acqSwitchCountry', // acquirer country
                header: 'Acquirer     Country',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.acqSwitchCountry}`,
                Footer: ''
            },
            {
                accessorKey: 'issSwitchMemberName', // issuer switcher
                header: 'Issuer       Switcher',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.issSwitchMemberName}`,
                Footer: ''
            },
            {
                accessorKey: 'prodFeatureName', // product feature
                header: 'Product      Feature',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.prodFeatureName}`,
                Footer: ''
            },
            {
                accessorKey: 'approvedTrans', // approved transactions
                header: 'Approved Transactions',
                size: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.approvedTrans}`,
                Footer: (dataPeriodNowTotal?.totalAcqTrans > 0) ? dataPeriodNowTotal?.totalAcqApprovedTrans : ''
            },
            {
                accessorKey: 'acqSwitchAmountTrans', // transaction amount
                header: 'Transaction     Amount',
                size: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.currencyName} ${NumberWithCommas(row.original.amountTrans)}`,
                muiTableBodyCellProps: ({ row }: any) => ({
                    sx: {
                        fontSize: '12px',
                        fontFamily: 'system-ui, sans-serif',
                        borderRight: '1px solid rgba(218, 217, 219, .7)',
                        color: row.original.prodFeatureName !== "QR Refund" ? 'inherit' : '#e83d44'
                    }
                }),
                Footer: (dataPeriodNowTotal?.totalAcqTrans > 0) ?
                    () => (
                        <>
                            {dataPeriodNowTotal?.totalAcqAmount?.map((totalAmountByCurrency: any, index: number) => (
                                <p key={index}>
                                    {`${totalAmountByCurrency.currency} ${NumberWithCommas(totalAmountByCurrency.amount)}`}
                                </p>
                            ))}
                        </>
                    ) : ''
            },
            {
                accessorKey: 'approvalTransRate', // approval rate
                header: 'Approval        Rate',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.approvalTransRate}%`,
                Footer: (dataPeriodNowTotal?.totalAcqTrans > 0) ? `${dataPeriodNowTotal?.totalAcqApprovalTransRate}%` : ''
            },
            {
                accessorKey: 'declinedTrans', // declined transactions
                header: 'Declined Transactions',
                size: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.declinedTrans}`,
                Footer: (dataPeriodNowTotal?.totalAcqTrans > 0) ? `${dataPeriodNowTotal?.totalAcqDeclinedTrans + dataPeriodNowTotal?.totalAcqPreDeclinedTrans}` : ''
            },
            {
                accessorKey: 'timeoutTrans', // timeout
                header: 'Timeout',
                size: 50,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.timeoutTrans}`,
                Footer: (dataPeriodNowTotal?.totalAcqTrans > 0) ? `${dataPeriodNowTotal?.totalAcqTimeoutTrans}` : ''
            },
        ],
        [stateGlobal]
    );

    const columnsTransactionSummaryIssuer = useMemo<MRT_ColumnDef<ModelMainDashboardSummary>[]>(
        () => [
            {
                accessorKey: 'acqSwitchCountry', // acquirer country
                header: 'Acquirer     Country',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.acqSwitchCountry}`,
                Footer: ''
            },
            {
                accessorKey: 'issSwitchMemberName', // issuer switcher
                header: 'Issuer       Switcher',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.issSwitchMemberName}`,
                Footer: ''
            },
            {
                accessorKey: 'prodFeatureName', // product feature
                header: 'Product      Feature',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.prodFeatureName}`,
                Footer: ''
            },
            {
                accessorKey: 'approvedTrans', // approved transactions
                header: 'Approved Transactions',
                size: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.approvedTrans}`,
                Footer: (dataPeriodNowTotal?.totalIssTrans > 0) ? dataPeriodNowTotal?.totalIssApprovedTrans : ''
            },
            {
                accessorKey: 'issSwitchAmountTrans', // transaction amount
                header: 'Transaction     Amount',
                size: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.currencyName} ${NumberWithCommas(row.original.amountTrans)}`,
                muiTableBodyCellProps: ({ row }: any) => ({
                    sx: {
                        fontSize: '12px',
                        fontFamily: 'system-ui, sans-serif',
                        borderRight: '1px solid rgba(218, 217, 219, .7)',
                        color: row.original.prodFeatureName !== "QR Refund" ? 'inherit' : '#e83d44'
                    }
                }),
                Footer: (dataPeriodNowTotal?.totalIssTrans > 0) ?
                    () => (
                        <>
                            {dataPeriodNowTotal?.totalIssAmount?.map((totalAmountByCurrency: any, index: number) => (
                                <p key={index}>
                                    {`${totalAmountByCurrency.currency} ${NumberWithCommas(totalAmountByCurrency.amount)}`}
                                </p>
                            ))}
                        </>
                    ) : ''
            },
            {
                accessorKey: 'approvalTransRate', // approval rate
                header: 'Approval        Rate',
                size: 60,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.approvalTransRate}%`,
                Footer: (dataPeriodNowTotal?.totalIssTrans > 0) ? `${dataPeriodNowTotal?.totalIssApprovalTransRate}%` : ''
            },
            {
                accessorKey: 'declinedTrans', // declined transactions
                header: 'Declined Transactions',
                size: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.declinedTrans}`,
                Footer: (dataPeriodNowTotal?.totalIssTrans > 0) ? `${dataPeriodNowTotal?.totalIssDeclinedTrans + dataPeriodNowTotal?.totalIssPreDeclinedTrans}` : ''
            },
            {
                accessorKey: 'timeoutTrans', // timeout
                header: 'Timeout',
                size: 50,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.timeoutTrans}`,
                Footer: (dataPeriodNowTotal?.totalIssTrans > 0) ? `${dataPeriodNowTotal?.totalIssTimeoutTrans}` : ''
            },
        ],
        [stateGlobal],
    );

    const columnsTransactionSummaryAcquirerDeclined = useMemo<MRT_ColumnDef<ModelMainDashboardSummaryDeclined>[]>(
        () => [
            {
                accessorKey: 'acqSwitchCountry', // acquirer country
                header: 'Acquirer     Country',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.acqSwitchCountry}`,
                Footer: ''
            },
            {
                accessorKey: 'issSwitchMemberName', // issuer switcher
                header: 'Issuer       Switcher',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.issSwitchMemberName}`,
                Footer: ''
            },
            {
                accessorKey: 'prodFeatureName', // product feature
                header: 'Product      Feature',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.prodFeatureName}`,
                Footer: ''
            },
            {
                accessorKey: 'transCount', // declined transactions
                header: 'Declined Transactions',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.transCount}`,
                Footer: (dataPeriodNowTotal?.totalAcqDeclinedTrans > 0 || dataPeriodNowTotal?.totalAcqTimeoutTrans > 0 || dataPeriodNowTotal?.totalAcqPreDeclinedTrans > 0)
                    ? `${dataPeriodNowTotal?.totalAcqDeclinedTrans + dataPeriodNowTotal?.totalAcqTimeoutTrans + dataPeriodNowTotal?.totalAcqPreDeclinedTrans}`
                    : ''
            },
            {
                accessorKey: 'acqSwitchAmountTrans', // transaction amount
                header: 'Transaction   Amount',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.currencyName} ${NumberWithCommas(row.original.amountTrans)}`,
                muiTableBodyCellProps: ({ row }: any) => ({
                    sx: {
                        fontSize: '12px',
                        fontFamily: 'system-ui, sans-serif',
                        borderRight: '1px solid rgba(218, 217, 219, .7)',
                        color: row.original.prodFeatureName !== "QR Refund" ? 'inherit' : '#e83d44'
                    }
                }),
                Footer: (dataPeriodNowTotal?.totalAcqDeclinedTrans > 0 || dataPeriodNowTotal?.totalAcqTimeoutTrans > 0 || dataPeriodNowTotal?.totalAcqPreDeclinedTrans > 0) ?
                    () => (
                        <>
                            {dataPeriodNowTotal?.totalAcqDecAmount?.map((totalAmountByCurrency: any, index: number) => (
                                <p key={index}>
                                    {`${totalAmountByCurrency.currency} ${NumberWithCommas(totalAmountByCurrency.amount)}`}
                                </p>
                            ))}
                        </>
                    ) : ''
            },
            {
                accessorKey: 'responseCodeName', // transaction status
                header: 'Transaction   Status',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.responseCodeName}`,
                Footer: ''
            },
        ],
        [stateGlobal],
    );

    const columnsTransactionSummaryIssuerDeclined = useMemo<MRT_ColumnDef<ModelMainDashboardSummaryDeclined>[]>(
        () => [
            {
                accessorKey: 'acqSwitchCountry', // acquirer country
                header: 'Acquirer     Country',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.acqSwitchCountry}`,
                Footer: ''
            },
            {
                accessorKey: 'issSwitchMemberName', // issuer switcher
                header: 'Issuer       Switcher',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.issSwitchMemberName}`,
                Footer: ''
            },
            {
                accessorKey: 'prodFeatureName', // product feature
                header: 'Product      Feature',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.prodFeatureName}`,
                Footer: ''
            },
            {
                accessorKey: 'transCount', // declined transactions
                header: 'Declined Transactions',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.transCount}`,
                Footer: (dataPeriodNowTotal?.totalIssDeclinedTrans > 0 || dataPeriodNowTotal?.totalIssTimeoutTrans > 0 || dataPeriodNowTotal?.totalIssPreDeclinedTrans > 0)
                    ? `${dataPeriodNowTotal?.totalIssDeclinedTrans + dataPeriodNowTotal?.totalIssTimeoutTrans + dataPeriodNowTotal?.totalIssPreDeclinedTrans}`
                    : ''
            },
            {
                accessorKey: 'acqSwitchAmountTrans', // transaction amount
                header: 'Transaction   Amount',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.currencyName} ${NumberWithCommas(row.original.amountTrans)}`,
                muiTableBodyCellProps: ({ row }: any) => ({
                    sx: {
                        fontSize: '12px',
                        fontFamily: 'system-ui, sans-serif',
                        borderRight: '1px solid rgba(218, 217, 219, .7)',
                        color: row.original.prodFeatureName !== "QR Refund" ? 'inherit' : '#e83d44'
                    }
                }),
                Footer: (dataPeriodNowTotal?.totalIssDeclinedTrans > 0 || dataPeriodNowTotal?.totalIssTimeoutTrans > 0 || dataPeriodNowTotal?.totalIssPreDeclinedTrans > 0) ?
                    () => (
                        <>
                            {dataPeriodNowTotal?.totalIssDecAmount?.map((totalAmountByCurrency: any, index: number) => (
                                <p key={index}>
                                    {`${totalAmountByCurrency.currency} ${NumberWithCommas(totalAmountByCurrency.amount)}`}
                                </p>
                            ))}
                        </>
                    ) : ''
            },
            {
                accessorKey: 'responseCodeName', // transaction status
                header: 'Transaction   Status',
                size: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.responseCodeName}`,
                Footer: ''
            },
        ],
        [stateGlobal],
    );

    return (
        <>
            <h1 className='Lato-300 normal w-600px s-2024 mx-2 py-2'>{state.titlePage}</h1>
            {/* header filter */}
            <div id='header-filter' className='row d-flex bd-d4 br-10 mx-2 p-2'>
                <div id='date-picker' className='align-items-center d-flex col-4 justify-content-start px-0 gap-3'>
                    <label className='c-54 text-uppercase s-1214'>Date</label>
                    <div>
                        <div className='d-flex ml-2 gap-2'>
                            <div>
                                <DatePicker
                                    selected={state.filterStartDate}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    dateFormat="yyyy-MM-dd"
                                    timeFormat="HH:mm"
                                    showYearDropdown
                                    maxDate={new Date()}
                                    onChange={(date: any) => handleStateComponent('filterStartDate', format(date, 'yyyy-MM-dd'))}
                                />
                                <DatePickerCalendarIconOnly />
                            </div>

                            <div>
                                -
                            </div>

                            <div>
                                <DatePicker
                                    selected={state.filterEndDate}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    dateFormat="yyyy-MM-dd"
                                    timeFormat="HH:mm"
                                    showYearDropdown
                                    minDate={state.filterStartDate}
                                    maxDate={new Date()}
                                    onChange={(date: any) => handleStateComponent("filterEndDate", format(date, 'yyyy-MM-dd'))}
                                />
                                <DatePickerCalendarIconOnly />
                            </div>
                        </div>

                    </div>

                </div>

                <div id='switcher-country-option' className='d-flex col-5'>
                    <div className='d-flex col-6 align-items-center gap-3'>
                        <label className='c-54 text-uppercase s-1214'>Country</label>
                        <Select
                            className="flex-fill"
                            styles={inputStyleH25}
                            isDisabled={!state.isRoleBCAP}
                            options={stateOptions.optionsSwitcherCountry}
                            value={stateOptions.optionsSwitcherCountry.filter((option: any) => option.value === state.selectedCountryId)}
                            onChange={(e) => {
                                handleStateComponent("selectedCountryId", e?.value)
                                handleStateComponent("selectedCountryLabel", e?.label)
                            }}
                        />
                    </div>
                </div>

                <div className='d-flex col-3 align-items-center justify-content-end'>
                    <button
                        className="ml-4 btn d-flex bg-e8 h-30px mx-0 mr-100px border-0"
                        disabled={state.filterErrorMessage !== ''}
                        onClick={() => {
                            handleStateGlobal("filterStartDate", state.filterStartDate)
                            handleStateGlobal("filterEndDate", state.filterEndDate)
                            handleStateGlobal("selectedCountryId", state.selectedCountryId)
                            handleStateGlobal("selectedCountryLabel", state.selectedCountryLabel)
                            // handleStateGlobal("selectedAcquirerSwitcherId", state.selectedAcquirerSwitcherId)
                            // handleStateGlobal("selectedIssuerSwitcherId", state.selectedIssuerSwitcherId)
                            // handleStateGlobal("selectedAcquirerSwitcherLabel", state.selectedAcquirerSwitcherLabel)
                            // handleStateGlobal("selectedIssuerSwitcherLabel", state.selectedIssuerSwitcherLabel)
                        }}
                    >
                        {icon_search_white}
                        <p className="s-1418 c-ff Lato-600 mx-2">Search</p>
                    </button>
                </div>

                {state.filterErrorMessage !== '' && (
                    <div className="d-flex mt-2 p-2 gap-2 bd-e8 br-10 wd-fit align-items-center">
                        {errNotificationIcon}
                        <p className="ml-10 s-1420 Lato-600 c-e87">{state.filterErrorMessage}</p>
                    </div>
                )}
            </div>

            {/* summary highlight */}
            <div id='summary-highlight' className='d-flex mt-3 mx-2 gap-3'>
                <div className='px-0'>
                    <div className='bd-d4 px-2 py-1' style={{ borderRadius: '8px' }}>
                        <p className='s-2024 me-1 fw-bold'>{dataPeriodNowTotalCount}</p>
                        <p className='pt-1 s-1620 c-545'>Total Transactions</p>
                    </div>
                </div>

                <div className='px-0 w-10persen'>
                    <div className='bd-d4 px-2 py-1' style={{ borderRadius: '8px' }}>
                        <div className='d-flex justify-content-between align-items-baseline'>
                            <p className='s-2024 me-1 fw-bold'>{dataPeriodNowApprovedCount}</p>
                            <div className='d-flex px-2 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowApprovedCount > dataPeriodPrevApprovedCount
                                    ? '#55b937'
                                    : dataPeriodNowApprovedCount < dataPeriodPrevApprovedCount
                                        ? '#e83d44'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowApprovedCount !== dataPeriodPrevApprovedCount)
                                    ? ((dataPeriodNowApprovedCount > dataPeriodPrevApprovedCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1418 c-ff'>{countDifferencePercentage(dataPeriodNowApprovedCount, dataPeriodPrevApprovedCount)} %</p>
                            </div>
                        </div>
                        <p className='pt-1 s-1620 c-545'>Approved</p>
                    </div>
                </div>

                <div className='px-0 w-10persen'>
                    <div className='bd-d4 px-2 py-1' style={{ borderRadius: '8px' }}>
                        <div className='d-flex justify-content-between align-items-baseline'>
                            <p className='s-2024 me-1 fw-bold'>{dataPeriodNowPreDeclinedCount}</p>
                            <div className='d-flex px-2 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowPreDeclinedCount > dataPeriodPrevPreDeclinedCount
                                    ? '#e83d44'
                                    : dataPeriodNowPreDeclinedCount < dataPeriodPrevPreDeclinedCount
                                        ? '#55b937'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowPreDeclinedCount !== dataPeriodPrevPreDeclinedCount)
                                    ? ((dataPeriodNowPreDeclinedCount > dataPeriodPrevPreDeclinedCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1418 c-ff'>{countDifferencePercentage(dataPeriodNowPreDeclinedCount, dataPeriodPrevPreDeclinedCount)} %</p>
                            </div>
                        </div>
                        <p className='pt-1 s-1620 c-545'>Pre-Declined</p>
                    </div>
                </div>

                <div className='px-0 w-10persen'>
                    <div className='bd-d4 px-2 py-1' style={{ borderRadius: '8px' }}>
                        <div className='d-flex justify-content-between align-items-baseline'>
                            <p className='s-2024 me-1 fw-bold'>{dataPeriodNowDeclinedCount}</p>
                            <div className='d-flex px-2 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowDeclinedCount > dataPeriodPrevDeclinedCount
                                    ? '#e83d44'
                                    : dataPeriodNowDeclinedCount < dataPeriodPrevDeclinedCount
                                        ? '#55b937'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowDeclinedCount !== dataPeriodPrevDeclinedCount)
                                    ? ((dataPeriodNowDeclinedCount > dataPeriodPrevDeclinedCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1418 c-ff'>{countDifferencePercentage(dataPeriodNowDeclinedCount, dataPeriodPrevDeclinedCount)} %</p>
                            </div>
                        </div>
                        <p className='pt-1 s-1620 c-545'>Declined</p>
                    </div>
                </div>

                <div className='px-0 w-10persen'>
                    <div className='bd-d4 px-2 py-1' style={{ borderRadius: '8px' }}>
                        <div className='d-flex justify-content-between align-items-baseline'>
                            <p className='s-2024 me-1 fw-bold'>{dataPeriodNowTimeoutCount}</p>
                            <div className='d-flex px-2 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowTimeoutCount > dataPeriodPrevTimeoutCount
                                    ? '#e83d44'
                                    : dataPeriodNowTimeoutCount < dataPeriodPrevTimeoutCount
                                        ? '#55b937'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowTimeoutCount !== dataPeriodPrevTimeoutCount)
                                    ? ((dataPeriodNowTimeoutCount > dataPeriodPrevTimeoutCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1418 c-ff'>{countDifferencePercentage(dataPeriodNowTimeoutCount, dataPeriodPrevTimeoutCount)} %</p>
                            </div>
                        </div>
                        <p className='pt-1 s-1620 c-545'>Timeout</p>
                    </div>
                </div>
            </div>

            {/* table summary */}
            <div className='row d-flex'>
                <div className='mt-2 col-6 px-2' onClick={handleScrollToTransaction}>
                    <h1 className='Lato-300 normal w-600px s-1822 py-2 mb-0'>
                        Transaction Summary ({selectedCountryLabel} Inbound)
                    </h1>
                    <MainPageSummaryReactTable
                        data={dataPeriodNowAcquirer}
                        columns={columnsTransactionSummaryAcquirer}
                        state={state}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleStateComponent={handleStateComponent}
                    />
                </div>
                <div className='mt-2 col-6 px-2' onClick={handleScrollToTransaction}>
                    <h1 className='Lato-300 normal w-600px s-1822 py-2 mb-0'>
                        Transaction Summary ({selectedCountryLabel} Outbound)
                    </h1>
                    <MainPageSummaryReactTable
                        data={dataPeriodNowIssuer}
                        columns={columnsTransactionSummaryIssuer}
                        state={state}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleStateComponent={handleStateComponent}
                    />
                </div>
                <div className='mt-2 col-6 px-2' onClick={handleScrollToTransaction}>
                    <h1 className='Lato-300 normal w-600px s-1822 py-2 mb-0'>
                        Declined Transaction Summary ({selectedCountryLabel} Inbound)
                    </h1>
                    <MainPageSummaryReactTable
                        data={dataPeriodNowAcquirerDeclined}
                        columns={columnsTransactionSummaryAcquirerDeclined}
                        state={state}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleStateComponent={handleStateComponent}
                    />
                </div>
                <div className='mt-2 col-6 px-2' onClick={handleScrollToTransaction}>
                    <h1 className='Lato-300 normal w-600px s-1822 py-2 mb-0'>
                        Declined Transaction Summary ({selectedCountryLabel} Outbound)
                    </h1>
                    <MainPageSummaryReactTable
                        data={dataPeriodNowIssuerDeclined}
                        columns={columnsTransactionSummaryIssuerDeclined}
                        state={state}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleStateComponent={handleStateComponent}
                    />
                </div>
            </div>

            {/* transaction graphs */}
            <div className='row d-flex'>
                <div className='col-6 px-2'>
                    <div className='row d-flex justify-content-start align-items-center mt-3'>
                        <h1 className='col-3 Lato-300 normal s-1822 mb-0 px-0'>{selectedCountryLabel} Inbound</h1>
                        <Select
                            className="col-4"
                            styles={inputStyleH25}
                            options={optionsTransactionStatus}
                            value={optionsTransactionStatus.filter((option: any) => option.value === state.graphInboundStatus)}
                            onChange={(e) => {
                                handleStateComponent("graphInboundStatus", e?.value)
                            }}
                        />
                    </div>
                    <div id="line-chart-inbound" style={{ width: '100%', height: '250px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
                </div>

                <div className='col-6 px-2'>
                    <div className='row d-flex justify-content-start align-items-center mt-3'>
                        <h1 className='col-3 Lato-300 normal s-1822 mb-0 px-0'>{selectedCountryLabel} Outbound</h1>
                        <Select
                            className="col-4"
                            styles={inputStyleH25}
                            options={optionsTransactionStatus}
                            value={optionsTransactionStatus.filter((option: any) => option.value === state.graphOutboundStatus)}
                            onChange={(e) => {
                                handleStateComponent("graphOutboundStatus", e?.value)
                            }}
                        />
                    </div>
                    <div id="line-chart-outbound" style={{ width: '100%', height: '250px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
                </div>
            </div>

            {/* table list */}
            <div id='transaction-list-table' className='m-2'>
                <h1 className='Lato-300 normal w-600px s-1822 py-2 mb-0'>Transaction List</h1>
                <MainPageTransactionReactTable
                    data={dataPeriodNowTransactionList}
                    columns={columnsTransactionList}
                    state={state}
                    pagination={pagination}
                    setPagination={setPagination}
                    handleStateComponent={handleStateComponent}
                    handleRowClick={handleRowClick}
                />
            </div>

            {/* api & timeout graphs */}
            {selectedCountryId === '410' && (
                <>
                    <h1 className='Lato-300 normal w-600px s-2024 mx-2 mb-0 pt-3'>API/Timeout Status</h1>
                    <div className='row d-flex mb-4'>
                        <div className='col-6 px-2'>
                            <div className='row d-flex justify-content-start align-items-center mt-3'>
                                <h1 className='col-3 Lato-300 normal s-1822 mb-0 px-0'>API Count</h1>
                                <Select
                                    className="col-4"
                                    styles={inputStyleH25}
                                    options={optionsDayGraphWithHour}
                                    value={optionsDayGraphWithHour.filter((option: any) => option.value === state.graphAPIDays)}
                                    onChange={(e) => {
                                        handleStateComponent("graphAPIDays", e?.value)
                                    }}
                                />
                            </div>
                            <div id="line-chart-api" style={{ width: '100%', height: '250px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
                        </div>

                        <div className='col-6 px-2'>
                            <div className='row d-flex justify-content-start align-items-center mt-3'>
                                <h1 className='col-3 Lato-300 normal s-1822 mb-0 px-0'>Total Timeout Count</h1>
                                <Select
                                    className="col-4"
                                    styles={inputStyleH25}
                                    options={optionsDayGraphWithHour}
                                    value={optionsDayGraphWithHour.filter((option: any) => option.value === state.graphTimeoutDays)}
                                    onChange={(e) => {
                                        handleStateComponent("graphTimeoutDays", e?.value)
                                    }}
                                />
                            </div>
                            <div id="line-chart-timeout" style={{ width: '100%', height: '250px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}
import { CheckUndefined } from "../../Utils";
import React, { useEffect, useReducer, useState } from "react";

import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
import { initFunction, reducerOptions, stateOptions } from "../../State";
import { urlSelection } from "../../Services/API";
import { useDispatch } from "react-redux";
import { ModelDetailTransactionStatus } from "../Dashboard/Models/ModelDetailTransactionStatus";
import { InterfaceOptionsDetailsMonitoringStatus } from "../Dashboard/InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { resetAllAction } from "../../Store/TransactionJourney";
import { Page2FrameworkRoute } from "../../Routes";
import { ResizableBox } from "react-resizable";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ModalTransactionTestInquiry2 } from "./ModalTransactionTestInquiry2";


export const ModalTransactionTestInquiry = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 2 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [statePage, dispatchStatePage] = useReducer(reducerStatePage, initialStatePage);

  const { stateParent, dispatchParent, modalCondition, setModal } = props;

  const handleMouseDown = (e: any) => {
    setDragging(true);
    setOffset({
      x: e.clientX - modalPosition.x,
      y: e.clientY - modalPosition.y,
    });
  };

  const handleMouseMove = (e: any) => {
    if (dragging) {
      setModalPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  React.useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);


  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    let transId = stateParent.transId;
    if (stateParent.transId !== '') {
      API.get({
        bodyCustom: null,
        pathCustom: `dashboard/transaction-test-detail${transId ? `?transId=${transId}` : ''}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        handleState('data', response.data)
      }).catch((error: any) => {
        console.error("Transactions Data Not Found:", error);
      });
    }

  }

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [stateParent.transId])

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [])

  const handleClick = (codeTransType: any) => {
    dispatch({ type: "handleClick", value: codeTransType });
  };
  const onResize = (event: any, propsSize: any) => {
    const { node, size, handle } = propsSize;
    if (size.width > 100 && size.height > 100) {
      dispatchStatePage({ type: "resize", value: { width: size.width, height: size.height } });
    }
  };
  useEffect(() => {
    if (state.detailModal === false) {
      dispatch({ type: "selectedOriTransId", value: "" });
    }
  }, [state.detailModal]);


  const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
  const data = state.allData
  let merchant_post_code = ""
  let member_appr_code = ""

  if (state.data === null) return <></>

  if (CheckUndefined(data.merchantPosCode) === "-") {
    merchant_post_code = CheckUndefined(data.merchantPos)
  } else if (CheckUndefined(data.merchantPos) === "-") {
    merchant_post_code = CheckUndefined(data.merchantPosCode)
  } else {
    merchant_post_code = `${CheckUndefined(data.merchantPosCode)} - ${(CheckUndefined(data.merchantPos))}`
  }

  if (CheckUndefined(data.isApprCodeIntn) !== "-") {
    if (data.isApprCodeIntn) member_appr_code = " (Generated by BCAP)"
  }

  const simulatorDetail = () => {
    return (
      <>
        <div className="p-0 m-0 rounded ">
          <div className="grid-container-transdetailss">
            <div className="grid-transdetail">
              <div className="col bg-fff" >
                <div className="bg-f4f">
                  <div className="grid-item bg-f4f Lato-700 s-1214 ">Test Category</div>
                </div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.testCat)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item bg-f4f Lato-700 s-1214 ">Test Case ID</div>
                </div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.testCaseId)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item bg-f4f Lato-700 s-1214 ">Expected Result</div>
                </div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.testExpectedResult)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item bg-f4f Lato-700 s-1214 ">Test Result</div>
                </div>
                <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.isTestValid)}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };

  const transactionsDetailTable = () => {
    return (
      <>
        {data.rspnCode === '00 - Success' ? <>
          <div className="p-0 m-0 rounded ">
            <div className="grid-container-transdetail">
              <div className="grid-transdetail">
                <div className="col bg-fff" >
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Transactions ID</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.transId)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Global ID</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214 row-data-pointer" style={{ color: "blue" }} onClick={(e) => {
                    dispatchGlobal(resetAllAction(data.globalId));
                    const url = `${Page2FrameworkRoute}?globalId=${data.globalId}`;
                    window.open(url, '_blank');
                  }} >{CheckUndefined(data.globalId)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Transaction Type</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.transType)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Transaction Date/Time</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.transactionDateTime)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Local Date Time</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.localDateTime)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Approval Code</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.apprCode)}{member_appr_code}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">RRN</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.rrn)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Invoice Number</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.invNo)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214">STAN</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.stan)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.srcLabel1)} Amount</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteAmount1)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.srcLabel2)} Amount</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteAmount2)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.dstLabel1)} Amount</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.dstInstituteAmount1)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.dstLabel2)} Amount</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.dstInstituteAmount2)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="col bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Product Type</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.productTypeName)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Product Feature</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: "red" }}>{CheckUndefined(data.productFeatureName)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">MTI</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.mti)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Settlement Amount</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.stlAmt)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Fee</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.acqFeeAmount)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Settlement Date</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.stlDate)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Conversion Rate</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.convRate)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Conversion Date</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.convDate)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">QR Ref No</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: 'magenta' }}>{CheckUndefined(data.qrAuthDetails?.qrRefNo)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Internal Response Code</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.intnRspnCode)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Destination Response Code</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214"  >{CheckUndefined(data.dstRspnCode)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Processing Code</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.procCode)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 " >Response Code</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: "green", fontWeight: 'bold' }} >{CheckUndefined(data.rspnCode)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-f4f">
                    <div className="grid-item bg-f4f Lato-700 s-1214 ">Transaction Status</div>
                  </div>
                  <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.status)}</div>
                </div>
              </div>
            </div>
          </div>
        </> :
          <>
            <>
              <div className="p-0 m-0 rounded ">
                <div className="grid-container-transdetail">
                  <div className="grid-transdetail">
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Transactions ID</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.transId)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Global ID</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214 row-data-pointer" style={{ color: "blue" }} onClick={(e) => {
                        dispatchGlobal(resetAllAction(data.globalId));
                        const url = `${Page2FrameworkRoute}?globalId=${data.globalId}`;
                        window.open(url, '_blank');
                        // navigate(`${Page2FrameworkRoute}?globalId=${data.globalId}`);
                      }} >{CheckUndefined(data.globalId)}</div>
                    </div>
                    <div className="col bg-fff ">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Transaction Type</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.transType)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Transaction Date/Time</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.transactionDateTime)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Local Date Time</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.localDateTime)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Approval Code</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.apprCode)}{member_appr_code}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">RRN</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.rrn)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Invoice Number</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.invNo)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214">STAN</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.stan)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.srcLabel1)} Amount</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteAmount1)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.srcLabel2)} Amount</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteAmount2)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.dstLabel1)} Amount</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.dstInstituteAmount1)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">{CheckUndefined(data.dstLabel2)} Amount</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.dstInstituteAmount2)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Product Type</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.productTypeName)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Product Feature</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: "red" }}>{CheckUndefined(data.productFeatureName)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">MTI</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.mti)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Settlement Amount</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.stlAmt)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Fee</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.acqFeeAmount)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Settlement Date</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.stlDate)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Conversion Rate</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.convRate)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Conversion Date</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.convDate)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">QR Ref No</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: 'magenta' }} >{CheckUndefined(data.qrAuthDetails?.qrRefNo)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 " >Internal Response Code</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: 'red', fontWeight: 'bold' }} >{CheckUndefined(data.intnRspnCode)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 " >Destination Response Code</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: 'red', fontWeight: 'bold' }}>{CheckUndefined(data.dstRspnCode)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 " >Processing Code</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.procCode)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Response Code</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" style={{ color: 'red', fontWeight: 'bold' }}>{CheckUndefined(data.rspnCode)}</div>
                    </div>
                    <div className="col bg-fff">
                      <div className="bg-f4f">
                        <div className="grid-item bg-f4f Lato-700 s-1214 ">Transaction Status</div>
                      </div>
                      <div className="grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.status)}</div>
                    </div>
                  </div>
                </div>
              </div>

            </>
          </>}
      </>
    );
  };

  const qrAuthDetailTable = () => {
    return (
      <>{data.productTypeName === 'QR' ?
        <div className="p-0 m-0 rounded ">
          <div>
            <p className="c-acr1 pb-2" style={{ fontSize: '13px' }}>Linked #1 : {CheckUndefined(data.qrAuthDetails?.qrProductFeatureInd)} {CheckUndefined(data.qrAuthDetails?.qrProductFeatureName)} </p>
          </div>
          <div className="grid-container-transdetails">
            <div className="grid-transdetail">
              <div className="col bg-fff">
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214" >Transaction ID</div>
                </div>
                <div className="grid-item- bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.qrTransId)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 tr-id-140 ">Global ID</div>
                </div>
                <div className="grid-item- bg-fff c-acr1 s-1214 row-data-pointer"
                  style={{ color: "blue" }} onClick={(e) => {
                    dispatchGlobal(resetAllAction(data.qrAuthDetails?.qrGlobalId));
                    const url = `${Page2FrameworkRoute}?globalId=${data.qrAuthDetails?.qrGlobalId}`;
                    window.open(url, '_blank');
                  }}
                >{CheckUndefined(data.qrAuthDetails?.qrGlobalId)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 ">Product Feature</div>
                </div>
                <div className="grid-item- bg-fff c-acr1 s-1214" style={{ color: 'red' }} >{CheckUndefined(data.qrAuthDetails?.qrProductFeatureName)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item- bg-t1f Lato-700 s-1214 " style={{ minWidth: '150px' }} >Request Date/Time</div>
                </div>
                <div className="grid-item- bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.qrReqDate)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 tr-id-140">QR STAN</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.qrReqStan)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 ">QR Ref No</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'magenta' }}>{CheckUndefined(data.qrAuthDetails?.qrRefNo)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 " >QR Inv No</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214 " style={{ maxWidth: '150px' }} >{CheckUndefined(data.qrAuthDetails?.qrInvNo)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 ">QR Standard</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.qrStandard)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 ">QR Mode</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.qrMode)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 tr-id-140">Member Name</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.qrMemberName)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 " style={{ minWidth: '120px' }}>Merchant Name</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.qrMerchantName)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 " >QR Ref 1</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ maxWidth: '150px' }} >{CheckUndefined(data.qrAuthDetails?.ref1)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 ">QR Ref 2</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214 tr-id-140" style={{ maxWidth: '150px' }} >{CheckUndefined(data.qrAuthDetails?.ref2)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-t1f">
                  <div className="grid-item-mc bg-t1f Lato-700 s-1214 ">QR Ref 3</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.qrAuthDetails?.ref3)}</div>
              </div>
              {CheckUndefined(data.qrAuthDetails?.rspnCode) === '00 - Success' ?
                <div className="col bg-fff" >
                  <div className="bg-t1f">
                    <div className="grid-item-mc bg-t1f Lato-700 s-1214 tr-id-140">Response Code</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'green', fontWeight: 'bold' }}>{CheckUndefined(data.qrAuthDetails?.qrRspnCode)}</div>
                </div> :
                <div className="col bg-fff" >
                  <div className="bg-t1f">

                    <div className="grid-item-mc bg-t1f Lato-700 s-1214 tr-id-140">Response Code</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'red', fontWeight: 'bold' }} >{CheckUndefined(data.qrAuthDetails?.qrRspnCode)}</div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <></>
      }
      </>
    )

  };

  const settlementDetailsTable = () => {
    return (
      <>
        <div className="p-0 m-0 rounded ">
          <div className="grid-container-transdetailss">
            <div className="grid-transdetail">
              <div className="col bg-fff" style={{ minWidth: '180px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214" >Wallet Id</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.walletId)}</div>
              </div>
              <div className="col bg-fff" style={{ minWidth: '170px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">Customer PAN</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214">{CheckUndefined(data.custPan)}</div>
              </div>
              <div className="col bg-fff " style={{ minWidth: '170px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">Customer Token</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.custToken)}</div>
              </div>
              <div className="col bg-fff" style={{ minWidth: '170px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">Customer Username</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.custUsername)}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  };

  const sourceDetailTable = () => {
    return (
      <>
        <div className="p-0 m-0 rounded ">
          <div className="grid-container-transdetailss">
            <div className="grid-transdetail">
              <div className="col" style={{ minWidth: '180px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">Source Institute</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstitute)}</div>
              </div>
              <div className="col" style={{ minWidth: '180px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">{data.srcLabel1}</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214 row-data-pointer" style={{ color: '#3b0607' }}
                >{CheckUndefined(data.srcInstituteName1)}</div>
              </div>
              <div className="col " style={{ minWidth: '180px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">{data.srcLabel2}</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteName2)}</div>
              </div>
              <div className="col" style={{ minWidth: '150px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">Source IP</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcIp)}</div>
              </div>
              <div className="col">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">Source Ref No</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcRefNo)}</div>
              </div>
              <div className="col">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">{data.srcLabel1} Amount</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteAmount1)}</div>
              </div>
              <div className="col">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">{data.srcLabel2} Amount</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteAmount2)}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  };

  const destinationDetailTable = () => {
    return (
      <>
        <div className="p-0 m-0 rounded ">
          <div className="grid-container-transdetailss">
            <div className="grid-transdetail">
              <div className="col bg-fff" >
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 tr-id-140" style={{ minWidth: '178px' }}>Destination Intitute</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214 tr-id-140" >{CheckUndefined(data.dstInstitute)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 " style={{ minWidth: '170px' }} >{data.dstLabel1}</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214 row-data-pointer" style={{ color: '#3b0607' }}
                >{CheckUndefined(data.dstInstituteName1)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 tr-id-170">{data.dstLabel2}</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.dstInstituteName2)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc- bg-f4f Lato-700 s-1214 ">Destination Ref No</div>
                </div>
                <div className="grid-item-mc- bg-fff c-acr1 s-1214" style={{ maxWidth: '170px' }} >{CheckUndefined(data.dstRefNo)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">{data.dstLabel1} Amount</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.dstInstituteAmount1)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">{data.dstLabel2} Amount</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.dstInstituteAmount2)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc bg-f4f Lato-700 s-1214 ">Conversion Rate</div>
                </div>
                <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.convRate)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc- bg-f4f Lato-700 s-1214 ">Conversion Date</div>
                </div>
                <div className="grid-item-mc- bg-fff c-acr1 s-1214" >{CheckUndefined(data.convDate)}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  };

  const subsequentLinkedTable = () => {
    return (
      (CheckUndefined(data.oriTransId) !== '-') ?
        <>
          <div className="p-0 m-0 rounded ">
            <div>
              <p className="c-acr1 pb-2" style={{ fontSize: '13px' }}>Linked #2 : {CheckUndefined(data.oriProductFeatureInd)} {CheckUndefined(data.oriProductFeatureName)} </p>
            </div>
            <div className="grid-container-transdetails">
              <div className="grid-transdetail">
                <div className="col bg-fff" >
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 tr-id-140">Transaction ID</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214 tr-id-140 row-data-pointer" style={{ color: "darkblue", maxWidth: '120px' }}
                    onClick={() => {
                      handleClick(data.oriTransId);
                    }}>{CheckUndefined(data.oriTransId)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 " >Global ID</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214 row-data-pointer"
                    style={{ color: "blue", maxWidth: '120px' }} onClick={(e) => {
                      dispatchGlobal(resetAllAction(data.oriGlobalId));
                      const url = `${Page2FrameworkRoute}?globalId=${data.oriGlobalId}`;
                      window.open(url, '_blank');
                    }}
                  >{CheckUndefined(data.oriGlobalId)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 tr-id-100" >Product Feature</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'red' }} >{CheckUndefined(data.oriProductFeatureName)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 tr-id-170 ">Transaction Date/Time</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214 tr-id-170" >{CheckUndefined(data.oriTransactionDateTime)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 ">STAN</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.oriStan)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 ">QR Ref No</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'magenta' }}>{CheckUndefined(data.qrAuthDetails?.qrRefNo)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 ">RRN</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.oriRrn)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 ">Invoice Number</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.oriInvNo)}</div>
                </div>
                <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 ">Transaction Amount</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(data.srcInstituteAmount)}</div>
                </div>
                {CheckUndefined(data.oriRspnCode) === '00 - Success' ? <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 tr-id-140">Response Code</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'green', fontWeight: 'bold' }} >{CheckUndefined(data.oriRspnCode)}</div>
                </div> : <div className="col bg-fff">
                  <div className="bg-bl4">
                    <div className="grid-item-mc bg-bl4 Lato-700 s-1214 tr-id-140">Response Code</div>
                  </div>
                  <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'red', fontWeight: 'bold' }} >{CheckUndefined(data.oriRspnCode)}</div>
                </div>}
              </div>
            </div>
          </div>
        </> : data.cancellationDetailList && data.cancellationDetailList.length > 0 ? (
          data.cancellationDetailList.map((item: any, index: number) => (
            <div className="p-0 m-0 rounded ">
              <div>
                <p className="c-acr1 pb-2" style={{ fontSize: '13px' }}>Linked #{index + 1} : {CheckUndefined(item.prodFeatureInd)} {CheckUndefined(item.prodFeatureName)} </p>
              </div>
              <div className="grid-container-transdetails">
                <div className="grid-transdetail">
                  <div className="col bg-fff" >
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 tr-id-140">Transaction ID</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214 tr-id-140 row-data-pointer" style={{ color: "darkblue", maxWidth: '120px' }}
                      onClick={() => {
                        handleClick(item.transId);
                      }}>{CheckUndefined(item.transId)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 " >Global ID</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214 row-data-pointer"
                      style={{ color: "blue", maxWidth: '120px' }} onClick={(e) => {
                        dispatchGlobal(resetAllAction(item.globalId));
                        const url = `${Page2FrameworkRoute}?globalId=${item.globalId}`;
                        window.open(url, '_blank');
                      }}
                    >{CheckUndefined(item.globalId)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 tr-id-100" >Product Feature</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'red' }} >{CheckUndefined(item.prodFeatureName)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 tr-id-170 " style={{ minWidth: '170px' }}>Transaction Date/Time</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214 tr-id-170" >{CheckUndefined(item.transDateTime)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 ">STAN</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(item.stan)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 ">QR Ref No</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'magenta' }}>{CheckUndefined(data.qrAuthDetails?.qrRefNo)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 ">RRN</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(item.refNo)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 ">Invoice Number</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(item.invNo)}</div>
                  </div>
                  <div className="col bg-fff">
                    <div className="bg-bl5">
                      <div className="grid-item-mc bg-bl5 Lato-700 s-1214 ">Transaction Amount</div>
                    </div>
                    <div className="grid-item-mc bg-fff c-acr1 s-1214" >{CheckUndefined(item.srcInstituteAmount)}</div>
                  </div>
                  {CheckUndefined(item.rspnCode) === '00 - Success' ?
                    <div className="col bg-fff">
                      <div className="bg-bl5">
                        <div className="grid-item-mc bg-bl5 Lato-700 s-1214 tr-id-140">Response Code</div>
                      </div>
                      <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'green', fontWeight: 'bold' }}  >{CheckUndefined(item.rspnCode)}</div>
                    </div> :
                    <div className="col bg-fff">
                      <div className="bg-bl5">
                        <div className="grid-item-mc bg-bl5 Lato-700 s-1214 tr-id-140">Response Code</div>
                      </div>
                      <div className="grid-item-mc bg-fff c-acr1 s-1214" style={{ color: 'red', fontWeight: 'bold' }}  >{CheckUndefined(item.rspnCode)}</div>
                    </div>
                  }
                </div>
              </div>
            </div>))) : (<></>)
    )
  };

  const merchantDetailTable = () => {
    return (
      <>
        <div className="p-0 m-0 rounded  ">
          <div className="grid-container-transdetailss">
            <div className="grid-transdetail">
              <div className="col bg-fff" style={{ minWidth: '200px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 font-size-mobile ">Merchant Name</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.merchantName)}</div>
              </div>
              <div className="col bg-fff" style={{ minWidth: '170px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 ">MID</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214 row-data-pointer" style={{ color: '#3b0607' }}
                >{CheckUndefined(data.mid)}</div>
              </div>
              <div className="col bg-fff " style={{ minWidth: '160px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 ">Sub MID</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.subMid)}</div>
              </div>
              <div className="col bg-fff" style={{ minWidth: '160px' }}>
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 ">Member MID</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.memberMid)}</div>
              </div>
              <div className="col bg-fff" >
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 ">Sub Merchant Name</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.subMerchantName)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 ">Merchant Country</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.merchantCountry)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 ">TID</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.tid)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214 ">MCC</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.mcc)}</div>
              </div>
              <div className="col bg-fff">
                <div className="bg-f4f">
                  <div className="grid-item-mc lg:grid-item bg-f4f Lato-700 s-1214">Merchant Post Entry Mode / Channel</div>
                </div>
                <div className="grid-item-mc lg:grid-item bg-fff c-acr1 s-1214" >{CheckUndefined(data.merchant_post_code)}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )

  };

  let widthWindows_ = window.innerWidth;
  const isMobile = window.innerWidth <= 768;

  return (
    <>{props.modalDetail === true ?
      <><div style={{ position: 'relative' }}>
        <Modal
          open={props.modalDetail}
          onCancel={(e) => setModal("modalCondition")}
          closable={false}
          maskClosable={false}
          mask={true}
          maskStyle={{ zIndex: 15 }}
          style={{
            position: 'fixed',
            top: modalPosition.y,
            left: modalPosition.x,
            right: '0%',
            minWidth: isMobile ? "" : "min-content",
            maxWidth: widthWindows_,
            zIndex: 90,
          }}
          bodyStyle={{
            maxHeight: '90vh',
            overflowY: 'auto',
            marginLeft: isMobile ? "0px" : "2px",
            marginRight: "0px",
            marginBottom: "0px"
          }}
          footer={false}
        >
          <ResizableBox height={statePage.height} width={statePage.width} onResize={onResize} minConstraints={[300, 300]}>
            <div className="">
              <div style={{ position: 'absolute', top: '5px', right: isMobile ? '15px' : '16px', zIndex: 100 }}>
                <IconButton aria-label="close" onClick={() => setModal("modalCondition")}>
                  <CloseIcon style={{ fontSize: '16px', padding: '0px' }} />
                </IconButton>
              </div>
              <div className="modal-header c-acr1" onMouseDown={handleMouseDown} style={{ cursor: 'move' }}></div>
              <div>
              <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
                  <div className="flex-fill">
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1 ">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Simulator Details</p>
                          </div>
                          {simulatorDetail()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
                  <div className="flex-fill">
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1 ">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Transactions Details</p>
                          </div>
                          {transactionsDetailTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
                  <div className="flex-fill mb-3 mb-lg-0 " style={{ width: `${widthWindows_ - 100}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Merchant Details</p>
                          </div>
                          {merchantDetailTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider type="vertical" className="m-1 d-none d-lg-block" />
                  <div className="flex-fill" style={{ width: `${widthWindows_ - 170}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Wallet Info</p>
                          </div>
                          {settlementDetailsTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
                  <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 100}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Source Details</p>
                          </div>
                          {sourceDetailTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider type="vertical" className="m-1 d-none d-lg-block" />
                  <div className="flex-fill" style={{ width: `${widthWindows_ - 170}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Destination Details</p>
                          </div>
                          {destinationDetailTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
                  <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 150}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Previous Linked</p>
                          </div>
                          {qrAuthDetailTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider type="vertical" className="m-1 d-none d-lg-block" />
                  <div className="flex-fill" style={{ width: `${widthWindows_ - 200}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap my-1">
                        {data.cancellationDetailList && data.cancellationDetailList.length > 0 ?
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Subsequent Linked</p>
                            </div>
                            {subsequentLinkedTable()}
                          </div> : <div></div>}
                      </div>
                    </div>
                  </div>
                </div>
                {(CheckUndefined(data.oriTransId) !== '-') ?
                  <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
                    <div className="flex-fill" style={{ width: `${widthWindows_ - 140}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap ">
                          <div className="row  w-100 p-0">

                            {subsequentLinkedTable()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider type="vertical" className="m-1" />
                    <div className="flex-fill" style={{ width: `${widthWindows_ - 200}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap  ">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : <></>}
              </div>
            </div>
          </ResizableBox>
        </Modal>
      </div>
        <ModalTransactionTestInquiry2
          detailModal={state.detailModal}
          setModal={() => dispatch({ type: "detailModal", value: !state.detailModal })}
          dispatchFirstParent={dispatchParent}
          stateFirstParent={stateParent}
          stateParent={state}
          stateOptions={stateOptions} />
      </>
      : <>
        <div style={{ position: 'relative' }}>
          <Modal
            open={modalCondition}
            onCancel={() => setModal("modalCondition")}
            closable={false}
            maskClosable={false}
            mask={true}
            maskStyle={{ zIndex: 15 }}
            style={{
              position: 'fixed',
              top: modalPosition.y,
              left: modalPosition.x,
              right: "0%",
              minWidth: isMobile ? "" : "min-content",
              maxWidth: widthWindows_,
              zIndex: 90,
            }}
            bodyStyle={{
              maxHeight: '90vh',
              overflowY: 'auto',
              marginLeft: isMobile ? "0px" : "2px",
              marginRight: "0px",
              marginBottom: "0px"

            }}
            footer={false}
          >
            <ResizableBox height={statePage.height} width={statePage.width} onResize={onResize} minConstraints={[300, 300]}>
              <div >
                <div style={{ position: 'absolute', top: '5px', right: isMobile ? '15px' : '16px', zIndex: 100 }}>
                  <IconButton aria-label="close" onClick={() => setModal("modalCondition")}>
                    <CloseIcon style={{ fontSize: '16px', padding: '0px' }} />
                  </IconButton>
                </div>
                <div className="modal-header c-acr1" onMouseDown={handleMouseDown} style={{ cursor: 'move' }}></div>
                <div>
                  <div className="d-flex" style={{ width: `${widthWindows_- 120}px` }}>
                    <div className="flex-fill">
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1 ">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-100 Lato-700 c-acr1 s-1420 border-bottom pb-1">Simulator Details</p>
                            </div>
                            {simulatorDetail()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex" style={{ width: `${widthWindows_ - 120}px` }}>
                    <div className="flex-fill">
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1 ">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Transactions Details</p>
                            </div>
                            {transactionsDetailTable()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
                    <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 100}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-700 Lato-700 c-acr1 s-1420  border-bottom pb-1">Merchant Details</p>
                            </div>
                            {merchantDetailTable()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider type="vertical" className="m-1 d-none d-lg-block" />
                    <div className="flex-fill" style={{ width: `${widthWindows_ - 170}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Wallet Info</p>
                            </div>
                            {settlementDetailsTable()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
                    <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 100}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Source Details</p>
                            </div>
                            {sourceDetailTable()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider type="vertical" className="m-1 d-none d-lg-block" />
                    <div className="flex-fill" style={{ width: `${widthWindows_ - 170}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Destination Details</p>
                            </div>
                            {destinationDetailTable()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
                    <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 150}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1">
                          <div className="row my-1 w-100 p-0">
                            <div className="p-0 h-30px">
                              <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Previous Linked</p>
                            </div>
                            {qrAuthDetailTable()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Divider type="vertical" className="m-1 d-none d-lg-block" />
                    <div className="flex-fill" style={{ width: `${widthWindows_ - 200}px` }}>
                      <div className="d-flex flex-column wd-webkit">
                        <div className="d-flex wrap my-1">
                          {data.cancellationDetailList && data.cancellationDetailList.length > 0 ?
                            <div className="row my-1 w-100 p-0">
                              <div className="p-0 h-30px">
                                <p className="w-700 Lato-700 c-acr1 s-1420 border-bottom pb-1">Subsequent Linked</p>
                              </div>
                              {subsequentLinkedTable()}
                            </div> : <div></div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(CheckUndefined(data.oriTransId) !== '-') ?
                    <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
                      <div className="flex-fill" style={{ width: `${widthWindows_ - 120}px` }}>
                        <div className="d-flex flex-column wd-webkit">
                          <div className="d-flex wrap ">
                            <div className="row  w-100 p-0">

                              {subsequentLinkedTable()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider type="vertical" className="m-1" />
                      <div className="flex-fill" style={{ width: `${widthWindows_ - 200}px` }}>
                        <div className="d-flex flex-column wd-webkit">
                          <div className="d-flex wrap  ">
                            <div className="row my-1 w-100 p-0">
                              <div className="p-0 h-30px">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : <></>}
                </div>
              </div>
            </ResizableBox>
          </Modal>
        </div>

        <ModalTransactionTestInquiry2
          detailModal={state.detailModal}
          setModal={() => dispatch({ type: "detailModal", value: !state.detailModal })}
          dispatchFirstParent={dispatchParent}
          stateFirstParent={stateParent}
          stateParent={state}
          stateOptions={stateOptions} />
      </>}
    </>
  );
};

interface test {
  data: ModelDetailTransactionStatus | null, detailModal: false
}
const initialStateOptions = { ...stateOptions }
const initialState: test = {
  data: null,
  detailModal: false
};
function reducer(state: any, action: any) {

  switch (action.type) {
    case 'data':
      return {
        ...state,
        allData: action.value,
        data: {
          authId: action.value.authId,
          transAuthId: action.value.transId,
          transactionDateTime: action.value.transactionDateTime,
          issAmount: action.value.issAmount,
          productTypeName: action.value.productTypeName,
          localDateTime: action.value.localDateTime,
          iss: action.value.iss,
          issSwitcherAmount: action.value.issSwitcherAmount,
          productFeatureName: action.value.productFeatureName,
          acqSwitcherAmount: action.value.acqSwitcherAmount,
          mti: action.value.mti,
          issSwitcher: action.value.issSwitcher,
          acqAmount: action.value.acqAmount,
          stan: action.value.stan,
          acqSwitcher: action.value.acqSwitcher,
          convRate: action.value.convRate,
          procCode: action.value.procCode,
          acq: action.value.acq,
          convDate: action.value.convDate,
          rrn: action.value.rrn,
          mid: action.value.mid,
          issSettleDate: action.value.issSettleDate,
          invNo: action.value.invNo,
          tid: action.value.tid,
          issSwitcherSettleDate: action.value.issSwitcherSettleDate,
          apprCode: action.value.apprCode,
          merchantName: action.value.merchantName,
          acqSwitcherSettleDate: action.value.acqSwitcherSettleDate,
          merchantCountry: action.value.merchantCountry,
          acqSettleDate: action.value.acqSettleDate,
          globalId: action.value.globalId,
          mcc: action.value.mcc,
          intnRspnCode: action.value.intnRspnCode,
          isApprCodeIntn: action.value.isApprCodeIntn,
        }
      };
    case "detailModal":
      return {
        ...state,
        detailModal: action.value,
      };
    case "handleClick":
      return {
        ...state,
        selectedOriTransId: action.value,
        detailModal: !state.detailModal,
      };
    case "selectedOriTransId":
      return {
        ...state,
        selectedOriTransId: action.value,
      };
    default:
      throw new Error();
  }
}

const initialStatePage = {
  bounds: false,
  data: null,
  id: "",
  width: 1420,
  height: 700,
};

const reducerStatePage = (state: any, action: any) => {
  switch (action.type) {
    case "resize":
      return {
        ...state,
        width: action.value.width,
        height: action.value.height,
      };

    default:
      throw new Error();
  }
};


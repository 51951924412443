import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Sidebar } from "./Components";
import { PrivateRoute } from './Pages/PrivateRoute';

// Pages Product Management
import { ProductFeatureManagement, ProductFeatureMember, ProductManagement } from "./Pages/ProductManagement";

// Pages Fee Management
import { ExchangeRateMember, ExchangeRateSetting, FeeRateSetting } from "./Pages/FeeManagement";

// Pages Code Management
import { CommonCodeManagement, CountryCodeManagement, PrivilegeCode, PrivilegeCodeDetail, ReasonCodeManagement, ReasonCodeMapping } from "./Pages/CodeManagement";

// QR Management
import { DataElementManagement, QRGenerator, QRStandard, QRStandardElement, QRStandardMember, QRWhitelistAndBlacklist } from "./Pages/QRManagement";

// Admin
import { ArchiveManagement, DetailInstitutionMonitoringStatus, DetailMember, HmacManagement, InstitutionMonitoringStatus, MemberManagement, SafControlManagement, UserLogHistory, UserManagement } from "./Pages";

// Dashboard
import { CloudWatchMonitoring, DetailsMonitoringStatus, DetailsMonitoringStatusFromLog, DetailsReversalMonitoringStatus, ReversalMonitoringStatus, ReversalTransactionMonitoring, TransactionMonitoring, TransactionMonitoringStatus, DetailsTransactionTestInquiry, QRAuthInquiry, DetailsQRAuthInquiry, QRAuthMonitoring, MainDashboard } from "./Pages/Dashboard";

//interface
import { InterfaceField, InterfaceHeader, RouteSequence, RoutingFieldMapping, RoutingHeaderMapping, RoutingManagement } from "./Pages/Interface";

// log
import { LogAnalyzer, LogLevelManagement } from "./Pages/Log";

// settlement
import { InterfaceFile, InterfaceFileItem, InterfaceFileRecord, InterfaceRecord, InterfaceRecordItem, } from "./Pages/InterfaceFile";

//
import { DetailDisputeResolution, DisputeResolution, MemberPayment, SalesInquiry, SettlementSummaryAcquirer, SettlementSummaryIssuer } from "./Pages/SettlementSummary";

import { SettlementSummary } from "./Pages/SettlementSummary/SettlementSummary";

//unmatched List
import { UnmatchedList } from "./Pages/UnmatchedList/UnmatchedList";

// Prod simulator
import { TransactionTestInquiry } from "./Pages/TransactionTestInquiry/TransactionTestInquiry";

// Routes
import {
  Page1AdminRoute,
  Page1CodeRoute,
  Page1DashboardRoute,
  Page1FeeRoute,
  Page1FrameworkRoute,
  Page1InterfaceAPIRoute,
  Page1InterfaceFileRoutes,
  Page1JobSchedulerRoute,
  Page1MemberRoute,
  Page1ProductRoute,
  Page1QRRoute,
  Page1RateRoute,
  Page1SettlementRoute,
  Page1SystemNetworkRoute,
  Page1UserRoute,
  Page1ProductionSimRoute,
  Page2CodeRoute,
  Page2DashboardRoute,
  Page2FrameworkRoute,
  Page2InterfaceAPIRoute,
  Page2InterfaceFileRoutes,
  Page2JobSchedulerRoute,
  Page2MemberRoute,
  Page2ProductRoute,
  Page2QRRoute,
  Page2RateRoute,
  Page2SettlementRoute,
  Page2SystemNetworkRoute,
  Page2UserRoute,
  Page3CodeRoute,
  Page3DashboardRoute,
  Page3FrameworkRoute,
  Page3InterfaceAPIRoute,
  Page3InterfaceFileRoutes,
  Page3JobSchedulerRoute,
  Page3ProductRoute,
  Page3QRRoute,
  Page3ServicesRoute,
  Page3SettlementRoute,
  Page3SystemNetworkRoute,
  Page3UserRoute,
  Page4CodeRoute,
  Page4DashboardRoute,
  Page4FrameworkRoute,
  Page4InterfaceAPIRoute,
  Page4InterfaceFileRoutes,
  Page4JobSchedulerRoute,
  Page4QRRoute,
  Page4SettlementRoute,
  Page5JobSchedulerRoute,
  Page5InterfaceAPIRoute,
  Page5DashboardRoute,
  Page5InterfaceFileRoutes,
  Page5QRRoute,
  Page5SettlementRoute,
  PageDashboardQRAuthStatisticRoute,
  Page6SettlementRoute, 
  Page7SettlementRoute,
  Page6QRRoute,
  Page6InterfaceAPIRoute,
  // Page6FrameworkRoute,
  Page8SettlementRoute,
  Page2ProductionSimRoute,
  PageMainDashboardRoute,
  PageAlertHistory,
  PageAlertParameter,
  Page6InterfaceFileRoutes

} from "./Routes";


import { useEffect, useCallback, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Components/Loading";
import { ForgotPassword, Login } from "./Pages/Auth";
import { BatchProgram, RunScheduler, SchedulerExecution, SchedulerManagement } from "./Pages/Job";
import { BatchProgramRegistration, BatchScheduler, ServiceRegistration } from "./Pages/Service";
import { Warning } from "./Pages/Warning";
import { loginAction, logoutAction, setMemberIsRegisteredToPIC, setRoleByMemberIdAction, tokenAction } from "./Store/User";
import './Styles/css/main.css';
import { ModalStyle2, SecondsToHHMMSS } from "./Utils";

import { Divider, notification } from 'antd';
import { useIdleTimer } from 'react-idle-timer';
import { doubleAction } from "./Store/Error";
import { successDoubleAction } from "./Store/Sucesss";
import { countAction, remainingAction, stateAction } from "./Store/Timeout";
import { transactionJourneyGlobalState } from "./Store/TransactionJourney";
import TestPage from "./Pages/TestPage";
import TestingComponent from "./Pages/TestingPage";
import { API, urlSelection } from "./Services/API";
import { AllPath } from "./Services";
import * as CryptoJS from 'crypto-js';
import { loadingAction } from "./Store/Loading";
import { SchedulerManagementDetail } from "./Pages/JobSchedule/SchedulerManagementDetail";
import Cookies from 'universal-cookie';
import { PrivilegePage } from "./Pages/PrivilegePage";
import { cookiesGlobal } from "./State/Cookies";
import AlertParameters from "./Pages/AlertParameters/AlertParameters";
import { QRAuthSimulatorInquiry } from "./Pages/QR Auth Simulator Inquiry/QRAuthSimulatorInquiry";
import { DetailsQRAuthSimulatorInquiry } from "./Pages/Dashboard/Details/DetailsQRAuthSimulatorInquiry";
import { AdjustmentList } from "./Pages/AdjustmentList/AdjustmentList";
import { AlertHistory } from "./Pages/Alert History Page/AlertHistory";
import { FileTransferMonitoring } from "./Pages/FileTransferMonitoring/FileTransferMonitoring";
type NotificationType = 'success' | 'info' | 'warning' | 'error';

function App() {
  const dispatchGlobal = useDispatch();
  const loadingCondition = useSelector((state: any) => state.loading.condition);
  // push Notification
  const error = useSelector((state: any) => state.error);
  const success = useSelector((state: any) => state.success);
  const userData = useSelector((state: any) => state.user);
  const [api, contextHolder] = notification.useNotification(undefined);




  const openNotificationWithIcon = (type: NotificationType, label: string, message: string) => {
    api[type]({ message: label, description: (<>{message}</>) });
    dispatchGlobal(doubleAction({ condition: false, message: '' }));
    dispatchGlobal(successDoubleAction({ condition: false, message: '' }));
  };

  useEffect(() => {
    if (error.condition === true) openNotificationWithIcon('error', 'Something Wrong', error.message)
    if (success.condition === true) openNotificationWithIcon('success', 'Success', success.message)
  }, [error.condition, success.condition])




  // timeout idle
  const timeout = useSelector((state: any) => state.timeout);
  const onIdle = () => dispatchGlobal(stateAction('Idle'))
  const onActive = () => dispatchGlobal(stateAction('Active'))
  const onAction = () => dispatchGlobal(countAction(timeout.count + 1))

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 60 * 8,
    throttle: 2000
  })

  useEffect(() => {
    const interval = setInterval(() => {
      dispatchGlobal(remainingAction(Math.ceil(getRemainingTime() / 1000)))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    if (timeout.state === 'Idle') {
      try {
        // cookiesGlobal.remove('_userxxxx', { path: '/',  });
        // cookiesGlobal.remove('_passxxxx', { path: '/',  });
        localStorage.removeItem('_userxxxx')
        localStorage.removeItem('_passxxxx')
        dispatchGlobal(logoutAction(null))
      } catch (error) {
        console.log(error)
      }
    }
  }, [timeout.state])


  return (
    <>
      {contextHolder}
      <Modal isOpen={loadingCondition} style={{ ...ModalStyle2, overlay: { zIndex: 10000 } }}>
        <div className="h-40px w-500px d-flex  justify-content-center align-items-center h-100 w-100">
          <div className="d-flex flex-column justify-content-center bd-ea py-5 br-10 bg-ff" style={{ width: '350px' }}>
            <Loading />
          </div>
        </div>
      </Modal>
      <Routes>
        <Route path={Page1DashboardRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<TransactionMonitoring />}></Sidebar>} />} />} key={"TransactionMonitoring"} />
        <Route path={Page2DashboardRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<TransactionMonitoringStatus />}></Sidebar>} />} />} key={"TransactionMonitoringStatus"} />
        <Route path={`${Page2DashboardRoute}/details/:id`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailsMonitoringStatus />}></Sidebar>} />} />} key={"DetailsMonitoringStatus"} />
        <Route path={`${Page2DashboardRoute}/details`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailsMonitoringStatusFromLog />}></Sidebar>} />} />} key={"DetailsMonitoringStatus"} />
        <Route path={Page3DashboardRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ReversalTransactionMonitoring />}></Sidebar>} />} />} key={"ReversalTransactionMonitoring"} />
        <Route path={Page4DashboardRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ReversalMonitoringStatus />}></Sidebar>} />} />} key={"ReversalMonitoringStatus"} />
        <Route path={`${Page4DashboardRoute}/details/:id`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailsReversalMonitoringStatus />}></Sidebar>} />} />} key={"DetailsReversalMonitoringStatus"} />
        <Route path={PageMainDashboardRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<MainDashboard />}></Sidebar>} />} />} key={"TransactionStatistic"} />

        <Route path={Page5DashboardRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRAuthInquiry />}></Sidebar>} />} />} key={"QRAuthInquiry"} />
        <Route path={`${Page5DashboardRoute}/details/:rrn/:stan`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailsQRAuthInquiry />}></Sidebar>} />} />} key={"DetailsQRAuthInquiry"} />
        <Route path={PageDashboardQRAuthStatisticRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRAuthMonitoring />}></Sidebar>} />} />} key={"QRAuthMonitoring"} />

        <Route path={Page1ProductRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ProductManagement />}></Sidebar>} />} />} key={"ProductManagement"} />
        <Route path={Page3ProductRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ProductFeatureMember />}></Sidebar>} />} />} key={"ProductFeatureMember"} />
        <Route path={Page2ProductRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ProductFeatureManagement />}></Sidebar>} />} />} key={"ProductFeatureManagement"} />

        <Route path={Page2RateRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ExchangeRateMember />}></Sidebar>} />} />} key={"ExchangeRateMember"} />
        <Route path={Page1RateRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ExchangeRateSetting />}></Sidebar>} />} />} key={"ExchangeRateSetting"} />
        <Route path={Page1FeeRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<FeeRateSetting />}></Sidebar>} />} />} key={"FeeRateSetting"} />

        <Route path={Page1CodeRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<CommonCodeManagement />}></Sidebar>} />} />} key={"CommonCodeManagement"} />
        <Route path={Page4CodeRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<CountryCodeManagement />}></Sidebar>} />} />} key={"CountryCodeManagement"} />
        <Route path={Page3CodeRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ReasonCodeMapping />}></Sidebar>} />} />} key={"ReasonCodeMapping"} />
        <Route path={Page2CodeRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ReasonCodeManagement />}></Sidebar>} />} />} key={"ReasonCodeManagement"} />
        <Route path={Page3UserRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<PrivilegeCode />}></Sidebar>} />} />} key={"PrivilegeCode"} />
        <Route path={`${Page3UserRoute}/:id`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<PrivilegeCodeDetail />}></Sidebar>} />} />} key={"PrivilegeCodeDetail"} />

        <Route path={Page6QRRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DataElementManagement />}></Sidebar>} />} />} key={"DataElementManagement"} />
        <Route path={Page1QRRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRStandard />}></Sidebar>} />} />} key={"QRStandard"} />
        <Route path={Page2QRRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRStandardElement />}></Sidebar>} />} />} key={"QRStandardElement"} />
        <Route path={Page3QRRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRStandardMember />}></Sidebar>} />} />} key={"QRStandardMember"} />
        <Route path={Page4QRRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRWhitelistAndBlacklist />}></Sidebar>} />} />} key={"QRWhitelistAndBlacklist"} />
        <Route path={Page5QRRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRGenerator />}></Sidebar>} />} />} key={"QRGenerator"} />

        <Route path={Page1MemberRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<HmacManagement />}></Sidebar>} />} />} key={"HmacManagement"} />
        <Route path={Page2MemberRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<MemberManagement />}></Sidebar>} />} />} key={"MemberManagement"} />
        <Route path={`${Page2MemberRoute}/details/:memberCode`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailMember />}></Sidebar>} />} />} key={"DetailMember"} />
        <Route path={Page2UserRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<UserLogHistory />}></Sidebar>} />} />} key={"UserLogHistory"} />
        <Route path={Page1UserRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<UserManagement />}></Sidebar>} />} />} key={"UserManagement"} />


        <Route path={Page1InterfaceAPIRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InterfaceField />}></Sidebar>} />} />} key={"InterfaceField"} />
        <Route path={Page2InterfaceAPIRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InterfaceHeader />}></Sidebar>} />} />} key={"InterfaceHeader"} />
        <Route path={Page6InterfaceAPIRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<RouteSequence />}></Sidebar>} />} />} key={"RouteSequence"} />
        <Route path={Page5InterfaceAPIRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<RoutingManagement />}></Sidebar>} />} />} key={"RoutingManagement"} />
        <Route path={Page4InterfaceAPIRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<RoutingHeaderMapping />}></Sidebar>} />} />} key={"RoutingHeaderMapping"} />
        <Route path={Page3InterfaceAPIRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<RoutingFieldMapping />}></Sidebar>} />} />} key={"RoutingFieldMapping"} />


        <Route path={Page1InterfaceFileRoutes} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InterfaceFile />}></Sidebar>} />} />} key={"InterfaceFile"} />
        <Route path={Page2InterfaceFileRoutes} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InterfaceFileItem />}></Sidebar>} />} />} key={"InterfaceFileItem"} />
        <Route path={Page3InterfaceFileRoutes} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InterfaceFileRecord />}></Sidebar>} />} />} key={"InterfaceFileRecord"} />
        <Route path={Page4InterfaceFileRoutes} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InterfaceRecord />}></Sidebar>} />} />} key={"InterfaceRecord"} />
        <Route path={Page5InterfaceFileRoutes} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InterfaceRecordItem />}></Sidebar>} />} />} key={"InterfaceRecordItem"} />
        <Route path={Page6InterfaceFileRoutes} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<FileTransferMonitoring />}></Sidebar>} />} />} key={"FilteTransferMonitoring"} />    

        <Route path={Page5SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<MemberPayment />}></Sidebar>} />} />} key={"MemberPayment"} />
        <Route path={Page2SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SettlementSummaryIssuer />}></Sidebar>} />} />} key={"SettlementSummaryIssuer"} />
        <Route path={Page1SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SettlementSummaryAcquirer />}></Sidebar>} />} />} key={"SettlementSummaryAcquirer"} />
        <Route path={Page4SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DisputeResolution />}></Sidebar>} />} />} key={"DisputeResolution"} />
        <Route path={`${Page4SettlementRoute}/details/:id`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailDisputeResolution />}></Sidebar>} />} />} key={"DetailDisputeResolution"} />
        <Route path={Page3SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SalesInquiry />}></Sidebar>} />} />} key={"SalesInquiry"} />


        {/* <Route path={Page2FrameworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<LogLevelManagement  />}></Sidebar>}/> } />} key={"LogLevelManagement"}/> */}
        <Route path={Page1FrameworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SafControlManagement />}></Sidebar>} />} />} key={"SafControlManagement"} />
        <Route path={Page2FrameworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<LogAnalyzer />}></Sidebar>} />} />} key={"BatchStatusMonitoring"} />

        <Route path={Page1JobSchedulerRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<BatchProgram />}></Sidebar>} />} />} key={"BatchProgram"} />
        <Route path={Page3JobSchedulerRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<RunScheduler />}></Sidebar>} />} />} key={"RunScheduler"} />
        <Route path={Page2JobSchedulerRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SchedulerManagement />}></Sidebar>} />} />} key={"SchedulerManagement"} />

        <Route path={`${Page2JobSchedulerRoute}/details/`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SchedulerManagementDetail />}></Sidebar>} />} />} key={"SchedulerManagement"} />

        <Route path={Page4JobSchedulerRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SchedulerExecution />}></Sidebar>} />} />} key={"SchedulerExecution"} />

        <Route path={Page3FrameworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ServiceRegistration />}></Sidebar>} />} />} key={"ServiceRegistration"} />
        <Route path={Page4FrameworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<BatchProgramRegistration />}></Sidebar>} />} />} key={"BatchProgramRegistration"} />
        <Route path={PageAlertParameter} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<AlertParameters />}></Sidebar>} />} />} key={"BatchProgramRegistration"} />

        <Route path={Page1SystemNetworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<CloudWatchMonitoring />}></Sidebar>} />} />} key={"CloudWatchMonitoring"} />
        <Route path={Page2SystemNetworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<InstitutionMonitoringStatus />}></Sidebar>} />} />} key={"InstitutionMonitoringStatus"} />
        <Route path={`${Page2SystemNetworkRoute}/details/:id`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailInstitutionMonitoringStatus />}></Sidebar>} />} />} key={"InstitutionMonitoringStatus"} />
        <Route path={Page3SystemNetworkRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<ArchiveManagement />}></Sidebar>} />} />} key={"ArchiveManagement"} />
        <Route path={Page5JobSchedulerRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<BatchScheduler />}></Sidebar>} />} />} key={"BatchProgramRegistration"} />


        <Route path={Page6SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<SettlementSummary />}></Sidebar>} />} />} key={"SettlementSummary"} />
        <Route path={Page7SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<UnmatchedList />}></Sidebar>} />} />} key={"UnmatchedList"} />       
        <Route path={Page8SettlementRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<AdjustmentList />}></Sidebar>} />} />} key={"AdjustmentList"} />      

        <Route path={Page1ProductionSimRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<TransactionTestInquiry />}></Sidebar>} />} />} key={"TransactionTestInquiry"} />
        <Route path={Page2ProductionSimRoute} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<QRAuthSimulatorInquiry />}></Sidebar>} />} />} key={"QRAuthSimulatorInquiry"} />
        <Route path={`${Page2ProductionSimRoute}/details/:rrn/:stan`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailsQRAuthSimulatorInquiry />}></Sidebar>} />} />} key={"DetailsQRAuthSimulatorInquiry"} />
        <Route path={`${Page1ProductionSimRoute}/details/:id`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<DetailsTransactionTestInquiry />}></Sidebar>} />} />} key={"DetailsTransactionTestInquiry"} />

        <Route path={PageAlertHistory} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<AlertHistory />}></Sidebar>} />} />} key={"AlertHistory"} />

        <Route path={`testing`} element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<TestingComponent />}></Sidebar>} />} />} key={"testing page data"} />

        <Route path='/confirm-password/:token' element={<ForgotPassword />} key={"ForgotPassword"} />
        <Route path="/warning" element={<PrivateRoute children={<PrivilegePage children={<Sidebar child={<Warning />}></Sidebar>} />} />} key={"Warning"} />

        <Route path='/login' element={<Login />} key={"Login"} />
        <Route path="*" element={<Navigate to={PageMainDashboardRoute} />} />

      </Routes>

    </>
  );
}

export default App;

import { API } from "../../Services";
import { urlSelection } from "../../Services/API";
import {
    CheckUndefined,
    CheckUndefinedStringEmpty,
    ExportCSV,
    NumberWithCommas
} from "../../Utils";
export const ExcelSettlementSummary = (state: any, stateOptions: any) => {
    API.get({
        bodyCustom: null,
        pathCustom: `settlement/settlement-summary-v3-download?${state.finalAPI}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
    }).then((response: any) => {
        // dispatch({ type: "dataCsv", value: response.data });
        const dataCsv = response.data;
        let newList: string[] = [
            "No",
            "Transaction Time",
            "Transaction Type",
            "Acquirer Switcher Settle Date",
            "Issuer Switcher Settle Date",
            "Acquirer Switcher",
            "Acquirer",
            "Issuer Switcher",
            "Issuer",
            "Auth STAN",
            "Auth RRN",
            "Invoice No",
            "Transaction Amount",
            "Fee",
            "Settlement Amount",
            "Recon Status",
            "Approval Code",
            "Merchant Name",
            "Merchant ID",
            "Customer PAN"
        ]
        let listData: any[] = []
        dataCsv.map((item: any, i: any) => {
            let transType;
            switch (item.transactionType) {
                case "001":
                    transType = "Sales";
                    break;
                case "002":
                    transType = "Refund";
                    break;
                default:
                    transType = "";
                    break;
            }

            const m = {
                no: i + 1,
                transactionTime: CheckUndefined(item.transactionTime),
                transactionType: transType,
                acqSwitchSettDate: CheckUndefined(item.acqSwitchSettDate),
                issSwitchSettDate: CheckUndefined(item.issSwitchSettDate),
                acqSwitchName: CheckUndefined(item.acqSwitchName),
                acqName: CheckUndefined(item.acqName),
                issSwitchName: CheckUndefined(item.issSwitchName),
                issName: CheckUndefined(item.issName),
                authStan: CheckUndefined(item.authStan),
                authRrn: CheckUndefined(item.authRrn),
                invoiceNo: CheckUndefined(item.invoiceNo),
                transactionAmount: CheckUndefined(item.transactionAmount),
                transactionfee: CheckUndefined(item.transactionfee),
                settlementAmount: CheckUndefined(item.settlementAmount),
                reconStatus: CheckUndefined(item.reconStatus),
                approvalCode: CheckUndefined(item.approvalCode),
                merchantName: CheckUndefined(item.merchantName),
                merchantId: CheckUndefined(item.merchantId),
                custPan: CheckUndefined(item.custPan),
            }
            listData.push(m)
        })

        ExportCSV(newList, listData, 'Settlement Summary')
        // exportToCSVComponent()
    })
};

import { useSelector } from "react-redux";
import { CheckUndefined } from "../../../Utils";
import { Divider } from "antd";
import { HeaderComponent } from "../../../Components";


export const Page3 = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    return (
        <div className='d-flex flex-column w-100 overflow mb-2'>
            <div className={`d-flex flex-column ${props.widthTable}`}>
                <HeaderComponent
                    codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
                    codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
                    label={props.label}
                    data={props.data}
                    position={props.position}
                    view={props.view}
                    customButtonAction={props.customButtonAction}
                    checkIndex={props.checkIndex}
                    widthTable={props.widthTable}
                    stateOptions={props.stateOptions}
                />
                <Divider className="my-2" />


                {props.data.map((item: any, index: any) => {
                    let number = index + ((props.currentPage - 1) * props.view)

                    return (
                        <div key={number}
                            className={`d-flex justify-content-between align-items-center min-h-50px px-4   ${number % 2 === 0 ? 'bg-f4' : ''} `}>
                            {props.label.map((element1: any) => {
                                if (element1.val === 'no') {
                                    return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{number + 1}</p>;
                                } else if (element1.val === 'action') {
                                    if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                                        return props.checkIndex(`${element1.val}`) && <div className={`d-flex  s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
                                    } else {
                                        return <></>;
                                    }
                                } else if (element1.val === "batchId") {
                                    let dataReturn = `${item[`${element1.val}`]}`;
                                    if (props.stateOptions.optionsBatchFromAPIBatch.length > 0) {
                                        props.stateOptions.optionsBatchFromAPIBatch.forEach((element2: any) => {
                                            if (element2.value === item[`${element1.val}`]) {
                                                dataReturn = `${element2.label}`;
                                            }
                                        });
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                } else if (element1.val === "batchRegType") {
                                    let dataReturn = `${item[`${element1.val}`]}`;
                                    if (props.stateOptions.optionsBatchRegistrationTypeDetailCode.length > 0) {
                                        props.stateOptions.optionsBatchRegistrationTypeDetailCode.forEach((element2: any) => {
                                            if (element2.value === item[`${element1.val}`]) {
                                                dataReturn = `${element2.label}`;
                                            }
                                        });
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                } else if (element1.val === "batchExecutionType") {
                                    let dataReturn = `${item[`${element1.val}`]}`;
                                    if (props.stateOptions.optionsBatchExecutionTypeDetailCode.length > 0) {
                                        props.stateOptions.optionsBatchExecutionTypeDetailCode.forEach((element2: any) => {
                                            if (element2.value === item[`${element1.val}`]) {
                                                dataReturn = `${element2.label}`;
                                            }
                                        });
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                } 
                                else if (element1.val === "batchPreRequisite") {
                                    let dataReturn = `${item[`${element1.val}`]}`;
                                    let result = ''
                                    if (props.stateOptions.optionsBatchFromAPIBatch.length > 0) {
                                        if(dataReturn.toString().length > 0){
                                            let splitString = [...dataReturn.toString().split(',')];
                                            if(splitString.length > 0){
                                                splitString.forEach((item2: any ) => {
                                                    props.stateOptions.optionsBatchFromAPIBatch.forEach((element2: any) => {
                                                        if (`${element2.value}` === item2) {
                                                            result = result + element2.label + ', ';
                                                        }
                                                    });
                                                })
                                            }
                                        }
                                    }
                                    if(result === ''){
                                        result = dataReturn;
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(result)}</p>;
                                } 
                                else {
                                    return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                                }
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Page3;
import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import { format } from "date-fns";
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FooterModalUpdate, DatePickerCalendarIconOnly } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelServiceRegistration } from './ModelServiceRegistration';
import { loadingAction } from '../../Store/Loading';
import { Page22Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableServiceRegistration = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const {
        optionsLogLevelDetailCode,
        optionsCommonStatusDetailCode
    } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["serviceCode", "serviceStat", "serviceName", "ServiceLogLevel", "serviceProgName", "serviceDesc"]
        let limit = [16, 3, 100, 3, 32, 255]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const {
            serviceId,
            serviceCode,
            serviceStat,
            serviceName,
            serviceLogLevel,
            serviceProgName,
            serviceStartDateTime,
            serviceStartDate,
            serviceStartTime,
            serviceStopDateTime,
            serviceStopDate,
            serviceStopTime,
            serviceDesc,
        } = statePage.data
        let splitStartDate = serviceStartDateTime.toString().split('T');
        let splitEndDate = serviceStopDateTime.toString().split('T');

        const data = {
            serviceId: id,
            serviceCode: serviceCode,
            serviceStat: serviceStat,
            serviceName: serviceName,
            serviceLogLevel: serviceLogLevel,
            serviceProgName: serviceProgName,
            serviceStartDateTime: `${splitStartDate[0]} ${splitStartDate[1]}`,
            serviceStopDateTime: `${splitEndDate[0]} ${splitEndDate[1]}`,
            serviceDesc: serviceDesc,
        }

        const a = CheckAllFields([
            serviceCode,
            serviceStat,
            serviceName,
            serviceLogLevel,
            serviceProgName,
            serviceStartDateTime,
            serviceStopDateTime,
        ], [
            'SERVICE CODE',
            'STATUS',
            'SERVICE NAME',
            'SERVICE LOG LEVEL',
            'SERVICE PROGRAM NAME',
            'START DATETIME',
            'STOP DATETIME',
        ])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-6'>
                        <p className={labelStyle}>SERVICE CODE <span className='text-danger'>*</span></p>
                        <input value={statePage.data.serviceCode} onChange={(e) => handleState('serviceCode', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCommonStatusDetailCode}
                            value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.serviceStat)}
                            onChange={(e) => handleState('serviceStat', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>SERVICE NAME <span className='text-danger'>*</span></p>
                        <input value={statePage.data.serviceName} onChange={(e) => handleState('serviceName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>SERVICE LOG LEVEL <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsLogLevelDetailCode}
                            value={optionsLogLevelDetailCode.filter((option: any) => option.value === statePage.data.serviceLogLevel)}
                            onChange={(e) => handleState('serviceLogLevel', e.value)}
                        />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>START DATETIME <span className='text-danger'>*</span></p>
                        <div>
                            <div className="d-flex">
                                <DatePicker
                                    selected={new Date(statePage.data.serviceStartDateTime || '')}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    popperClassName="react-datepicker-popper-transform-up-right"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeFormat="HH:mm"
                                    showYearDropdown
                                    showTimeSelect
                                    showPopperArrow={false}
                                    timeIntervals={5}
                                    onChange={(date: any) => { handleState('serviceStartDateTime', `${format(date, 'yyyy-MM-dd HH:mm:ss')}`) }}
                                />
                            </div>
                            <DatePickerCalendarIconOnly />
                        </div>
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>STOP DATETIME <span className='text-danger'>*</span></p>
                        <div>
                            <div className="d-flex">
                                <DatePicker
                                    selected={new Date(statePage.data.serviceStopDateTime || '')}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    popperClassName="react-datepicker-popper-transform-up-right"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeFormat="HH:mm"
                                    showYearDropdown
                                    showTimeSelect
                                    showPopperArrow={false}
                                    timeIntervals={5}
                                    onChange={(date: any) => { handleState('serviceStopDateTime', `${format(date, 'yyyy-MM-dd HH:mm:ss')}`) }}
                                />
                            </div>
                            <DatePickerCalendarIconOnly />
                        </div>
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>SERVICE PROGRAM NAME <span className='text-danger'>*</span></p>
                        <input value={statePage.data.serviceProgName} onChange={(e) => handleState('serviceProgName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.data.serviceDesc} onChange={(e) => handleState('serviceDesc', e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page22Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelServiceRegistration({
        id: null,
        serviceId: null,
        serviceCode: null,
        serviceStat: '001',
        serviceName: null,
        serviceLogLevel: '005',
        serviceProgName: null,
        serviceStartDateTime: null,
        serviceStartDate: null,
        serviceStartTime: null,
        serviceStopDateTime: null,
        serviceStopDate: null,
        serviceStopTime: null,
        serviceDesc: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    let now = new Date();
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    let timeNow = `${new Date().toISOString().slice(0, 10)}T${hours}:${minutes}:${seconds}`;
    for (const key in state.data as ModelServiceRegistration) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelServiceRegistration({
                    id: null,
                    serviceId: null,
                    serviceCode: null,
                    serviceStat: '001',
                    serviceName: null,
                    serviceLogLevel: '005',
                    serviceProgName: null,
                    serviceStartDateTime: timeNow,
                    serviceStartDate: null,
                    serviceStartTime: null,
                    serviceStopDateTime: timeNow,
                    serviceStopDate: null,
                    serviceStopTime: null,
                    serviceDesc: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelServiceRegistration({
                    id: action.value.serviceId,
                    serviceId: action.value.serviceId,
                    serviceCode: action.value.serviceCode,
                    serviceStat: action.value.serviceStat,
                    serviceName: action.value.serviceName,
                    serviceLogLevel: action.value.serviceLogLevel,
                    serviceProgName: action.value.serviceProgName,
                    serviceStartDateTime: action.value.serviceStartDateTime ?? timeNow,
                    serviceStartDate: action.value.serviceStartDate,
                    serviceStartTime: action.value.serviceStartTime,
                    serviceStopDateTime: action.value.serviceStopDateTime ?? timeNow,
                    serviceStopDate: action.value.serviceStopDate,
                    serviceStopTime: action.value.serviceStopTime,
                    serviceDesc: action.value.serviceDesc,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





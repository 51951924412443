import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { format, differenceInDays } from "date-fns";

import { initialState } from './State/state';
import { reducer } from './State/reducer';
import { InterfaceOptionsMainDashboard } from './Utils/InterfaceOptionsMainDashboard';
import { DesktopViewMainPage } from './View/DesktopViewMainPage';
import { MobileViewMainPage } from './View/MobileViewMainPage';

import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    stateOptions,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle
} from '../../State';
import { changeStateMainDashboard, stateGlobalMainDashboard, handleStateAction } from '../../Store/MainDashboardSlice';
import { changeStateTransactionMonitoringStatus } from '../../Store/TransactionMonitoringStatusSlice';
import { loadingAction } from '../../Store/Loading';
import { store } from '../../Store/Store';
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';
import { Page2DashboardRoute } from '../../Routes';
import { CheckUndefined, CheckUndefinedStringEmpty } from '../../Utils';
import { IsDateRangeWithinDays } from '../../Utils/IsDateRangeWithinDays';

export const MainDashboard = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const location = useLocation();

    const user = useSelector((state: any) => state.user);

    /* state */
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(
        reducerOptions,
        initialStateOptions
    );

    const stateGlobal = useSelector(stateGlobalMainDashboard);
    const {
        dataPeriodNowTransactionList,
        dataPeriodNowSummary,
        dataPeriodNowSummaryDeclined,
        dataPeriodNowAcquirer,
        dataPeriodNowAcquirerDeclined,
        dataPeriodNowIssuer,
        dataPeriodNowIssuerDeclined,
        dataPeriodNowAcquirerIssuer,
        dataPeriodNowAcquirerIssuerDeclined,
        dataPeriodNowTotalCount,
        dataPeriodNowTotalAcquirer,
        dataPeriodNowTotalIssuer,
        dataPeriodNowTotal,
        dataPeriodNowApprovedCount,
        dataPeriodNowPreDeclinedCount,
        dataPeriodNowDeclinedCount,
        dataPeriodNowTimeoutCount,
        dataPeriodPrevSummary,
        dataPeriodPrevSummaryDeclined,
        dataPeriodPrevTotalCount,
        dataPeriodPrevApprovedCount,
        dataPeriodPrevPreDeclinedCount,
        dataPeriodPrevDeclinedCount,
        dataPeriodPrevTimeoutCount,
        dataInbound,
        dataOutbound,
        dataApi,
        dataTimeout,
        filterStartDate,
        filterEndDate,
        selectedCountryId,
        selectedCountryLabel,
        search,
        rangeDate,
        dataAcquirerSwitcherOptions,
        dataIssuerSwitcherOptions,
        selectedAcquirerSwitcherId,
        selectedAcquirerSwitcherLabel,
        selectedIssuerSwitcherId,
        selectedIssuerSwitcherLabel,
    } = stateGlobal;

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    /* function */
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsMainDashboard, dispatchGlobal);
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch);
    const handleStateGlobal = (nameState: string, valueState: any) => {
        dispatchGlobal(changeStateMainDashboard({ [nameState]: valueState }));
    };

    const initFunctionCustom = async (needRefreshPage: Boolean | null) => {
        try {
            store.dispatch(loadingAction(true));

            let dateStartPeriodNow = new Date(filterStartDate); let dateEndPeriodNow = new Date(filterEndDate); let listAllDayChart = [];
            for (let d = dateStartPeriodNow; d <= dateEndPeriodNow; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10))
            }

            let dateStartPeriodPrev = new Date(filterStartDate); let dateEndPeriodPrev = new Date(filterEndDate);
            dateStartPeriodPrev.setDate(dateStartPeriodPrev.getDate() - listAllDayChart.length);
            dateEndPeriodPrev.setDate(dateEndPeriodPrev.getDate() - listAllDayChart.length);

            let dateStartPeriodPrevStr = dateStartPeriodPrev.toISOString().slice(0, 10); let dateEndPeriodPrevStr = dateEndPeriodPrev.toISOString().slice(0, 10);

            let urlTransaction = 'dashboard/transaction-list-main?';
            let urlSummary = 'dashboard/transaction-summary?';
            let urlTotalPeriodNow = 'dashboard/transaction-summary-total?';
            let urlTotalPeriodPrev = 'dashboard/transaction-summary-total?';

            if (CheckUndefinedStringEmpty(selectedCountryId) !== '') {
                urlTransaction = urlTransaction + `countryCode=${CheckUndefinedStringEmpty(selectedCountryId)}&`;
                urlSummary = urlSummary + `countryCode=${CheckUndefinedStringEmpty(selectedCountryId)}&`;
                urlTotalPeriodNow = urlTotalPeriodNow + `countryCode=${CheckUndefinedStringEmpty(selectedCountryId)}&`;
                urlTotalPeriodPrev = urlTotalPeriodPrev + `countryCode=${CheckUndefinedStringEmpty(selectedCountryId)}&`;
            }

            if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
                urlTransaction = urlTransaction + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
                urlSummary = urlSummary + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
                urlTotalPeriodNow = urlTotalPeriodNow + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
                urlTotalPeriodPrev = urlTotalPeriodPrev + `startDate=${CheckUndefinedStringEmpty(dateStartPeriodPrevStr)}&`;
            }

            if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
                urlTransaction = urlTransaction + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
                urlSummary = urlSummary + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
                urlTotalPeriodNow = urlTotalPeriodNow + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
                urlTotalPeriodPrev = urlTotalPeriodPrev + `endDate=${CheckUndefinedStringEmpty(dateEndPeriodPrevStr)}&`;
            }

            urlTransaction = urlTransaction + `limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}&`;

            let responseTransactionList = await API.get({ bodyCustom: null, pathCustom: urlTransaction.slice(0, -1), selectUrl: urlSelection.dashboard, useToken: true, needNotification: false });
            let responseSummary = await API.get({ bodyCustom: null, pathCustom: urlSummary.slice(0, -1), selectUrl: urlSelection.dashboard, useToken: true, needNotification: false });
            let responseTotalPeriodNow = await API.get({ bodyCustom: null, pathCustom: urlTotalPeriodNow.slice(0, -1), selectUrl: urlSelection.dashboard, useToken: true, needNotification: false });
            let responseTotalPeriodPrev = await API.get({ bodyCustom: null, pathCustom: urlTotalPeriodPrev.slice(0, -1), selectUrl: urlSelection.dashboard, useToken: true, needNotification: false });

            handleStateGlobal("dataPeriodNowTransactionList", responseTransactionList?.data);
            handleStateComponent("totalData", responseTransactionList?.data.length);

            handleStateGlobal("dataPeriodNowAcquirer", responseSummary?.data.acqSwitchTransSummary);
            handleStateGlobal("dataPeriodNowAcquirerDeclined", responseSummary?.data.acqSwitchDecSummary);
            handleStateGlobal("dataPeriodNowIssuer", responseSummary?.data.issSwitchTransSummary);
            handleStateGlobal("dataPeriodNowIssuerDeclined", responseSummary?.data.issSwitchDecSummary);

            handleStateGlobal("dataPeriodNowTotal", responseTotalPeriodNow?.data);

            handleStateGlobal("dataPeriodNowTotalCount", responseTotalPeriodNow?.data.totalTrans);
            handleStateGlobal("dataPeriodNowApprovedCount", responseTotalPeriodNow?.data.totalApprovedTrans);
            handleStateGlobal("dataPeriodNowPreDeclinedCount", responseTotalPeriodNow?.data.totalPreDeclinedTrans);
            handleStateGlobal("dataPeriodNowDeclinedCount", responseTotalPeriodNow?.data.totalDeclinedTrans);
            handleStateGlobal("dataPeriodNowTimeoutCount", responseTotalPeriodNow?.data.totalTimeoutTrans);

            handleStateGlobal("dataPeriodPrevTotalCount", responseTotalPeriodPrev?.data.totalTrans);
            handleStateGlobal("dataPeriodPrevApprovedCount", responseTotalPeriodPrev?.data.totalApprovedTrans);
            handleStateGlobal("dataPeriodPrevPreDeclinedCount", responseTotalPeriodPrev?.data.totalPreDeclinedTrans);
            handleStateGlobal("dataPeriodPrevDeclinedCount", responseTotalPeriodPrev?.data.totalDeclinedTrans);
            handleStateGlobal("dataPeriodPrevTimeoutCount", responseTotalPeriodPrev?.data.totalTimeoutTrans);

            const dataAcquirerIssuer = [
                ...(responseSummary?.data.acqSwitchTransSummary || []),
                ...(responseSummary?.data.issSwitchTransSummary || [])
            ];
            
            const dataAcquirerIssuerDec = [
                ...(responseSummary?.data.acqSwitchDecSummary || []),
                ...(responseSummary?.data.issSwitchDecSummary || [])
            ];

            handleStateGlobal("dataPeriodNowAcquirerIssuer", dataAcquirerIssuer);
            handleStateGlobal("dataPeriodNowAcquirerIssuerDeclined", dataAcquirerIssuerDec);

            store.dispatch(loadingAction(false));
        } catch (error) {
            console.error(error);
            store.dispatch(loadingAction(false));
        }
    };

    const initGraphInbound = async (needRefreshPage: Boolean | null) => {
        let urlInboundCount = 'graph/transaction-inbound?';
        urlInboundCount = urlInboundCount + `status=${state.graphInboundStatus}&`;
        urlInboundCount = urlInboundCount + `countryCode=${selectedCountryId}&`;
        urlInboundCount = urlInboundCount + `startDate=${filterStartDate}&`;
        urlInboundCount = urlInboundCount + `endDate=${filterEndDate}`;
        let responseGraphInboundCount = await API.get({
            bodyCustom: null,
            pathCustom: urlInboundCount,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false
        })
        handleStateGlobal("dataInbound", responseGraphInboundCount?.data);
    }

    const initGraphOutbound = async (needRefreshPage: Boolean | null) => {
        let urlOutboundCount = 'graph/transaction-outbound?';
        urlOutboundCount = urlOutboundCount + `status=${state.graphOutboundStatus}&`;
        urlOutboundCount = urlOutboundCount + `countryCode=${selectedCountryId}&`;
        urlOutboundCount = urlOutboundCount + `startDate=${filterStartDate}&`;
        urlOutboundCount = urlOutboundCount + `endDate=${filterEndDate}`;
        let responseGraphOutboundCount = await API.get({
            bodyCustom: null,
            pathCustom: urlOutboundCount,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false
        })
        handleStateGlobal("dataOutbound", responseGraphOutboundCount?.data);
    }

    const initGraphAPI = async (needRefreshPage: Boolean | null) => {
        let urlAPICount = 'graph/transaction-count-per-hour';
        urlAPICount = urlAPICount + `?dayInterval=${state.graphAPIDays}`;
        const responseGraphAPICount = await API.get({
            bodyCustom: null,
            pathCustom: urlAPICount,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false
        })
        handleStateGlobal("dataApi", responseGraphAPICount?.data);
    }

    const initGraphTimeout = async (needRefreshPage: Boolean | null) => {
        let urlTimeoutCount = 'graph/transaction-timeout-count';
        urlTimeoutCount = urlTimeoutCount + `?dayInterval=${state.graphTimeoutDays}`;
        let responseGraphTimeoutCount = await API.get({
            bodyCustom: null,
            pathCustom: urlTimeoutCount,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needNotification: false
        })
        handleStateGlobal("dataTimeout", responseGraphTimeoutCount?.data);
    }

    const countDifferencePercentage = (countNow: number, countPrev: number) => {
        if (countNow == 0 && countPrev == 0) {
            return 0;
        } else if (countNow != 0 && countPrev == 0) {
            countNow *= 100;
            return countNow;
        } else if (countNow == 0 && countPrev != 0) {
            countPrev *= 100;
            return countPrev;
        }

        let percentage: string | number = countNow / countPrev;

        if (countNow > countPrev) {
            percentage = (percentage - 1) * 100;
            percentage = Number.isInteger(percentage) ? percentage : percentage.toFixed(2);
            return percentage;
        } else if (countNow < countPrev) {
            percentage = (1 - percentage) * 100;
            percentage = Number.isInteger(percentage) ? percentage : percentage.toFixed(2);
            return percentage;
        } else {
            return 0;
        }
    }

    const handleScrollToTransaction = (event: any) => {
        const target = event.target;
        if (target.closest('.MuiBox-root') || target.closest('.MuiFilter-root')) {
            return;
        }
        document.getElementById('transaction-list-table')?.scrollIntoView();
    }

    // handle navigation to inquiry
    const handleRowClick = (item: any) => {
        if (item.fullCreateDate !== null) {
            let dateArray = item.fullCreateDate.toString().split(' ');
            if (dateArray.length > 1) {
                let date = dateArray[0];
                let changeTransStatusName = item.transStatus;
                if (changeTransStatusName === 'UNKNOWN') {
                    changeTransStatusName = '-';
                }

                dispatchGlobal(
                    changeStateTransactionMonitoringStatus({
                        transId: '',
                        transAuthIntResponseCode: '', // search for status => reason code
                        statusSendToApi: '', // search for status => reason code
                        transStatusName: changeTransStatusName === null || changeTransStatusName === undefined ? '' : changeTransStatusName, // search for status => reason code
                        transCurrencyCode: item.acqTransCurrency === null || item.acqTransCurrency === undefined ? '' : item.acqTransCurrency, // search for status => reason code
                        acqSwitchCurrencyCode: item.acqSwitchTansCurrency === null || item.acqSwitchTansCurrency === undefined ? '' : item.acqSwitchTansCurrency, // search for status => reason code
                        issSwitchCurrencyCode: item.issSwitchTansCurrency === null || item.issSwitchTansCurrency === undefined ? '' : item.issSwitchTansCurrency, // search for status => reason code
                        // Product
                        selectedProductId: item.productType,
                        selectedProductLabel: null,
                        selectedProductSendToApi: item.productType,
                        // Product Feature
                        selectedProductFeatureId: item.productFeature,
                        selectedProductFeatureLabel: null,
                        selectedProductFeatureSendToApi: item.productFeature,
                        // 
                        search: '',
                        filterStartDate: filterStartDate,
                        filterEndDate: filterEndDate,
                        startTime: '00:00:01',
                        endTime: '23:59:59',
                        // 
                        issuerId: item.issuerId === null || item.issuerId === undefined ? '' : item.issuerId, // issuer
                        acquirerId: item.acquirerId === null || item.acquirerId === undefined ? '' : item.acquirerId, // acquirer
                        issuerSwitcherId: item.issuerSwitcherId === null || item.issuerSwitcherId === undefined ? '' : item.issuerSwitcherId, // issuerSwitcherId
                        acquirerSwitcherId: item.acquirerSwitcherId === null || item.acquirerSwitcherId === undefined ? '' : item.acquirerSwitcherId, // acquirerSwitcherId
                        // 
                        issuerIdCode: item.issuerIdCode === null || item.issuerIdCode === undefined ? '' : item.issuerIdCode, // issuerIdCode
                        acquirerIdCode: item.acquirerIdCode === null || item.acquirerIdCode === undefined ? '' : item.acquirerIdCode, // acquirerIdCode
                        issuerSwitcherIdCode: item.issuerSwitcherIdCode === null || item.issuerSwitcherIdCode === undefined ? '' : item.issuerSwitcherIdCode, // issuerSwitcherIdCode
                        acquirerSwitcherIdCode: item.acquirerSwitcherIdCode === null || item.acquirerSwitcherIdCode === undefined ? '' : item.acquirerSwitcherIdCode, // acquirerSwitcherIdCode
                        walletId: item.walletId === null || item.walletId === undefined ? '' : item.walletId, // walletId
                        navigateFrom: "transaction-statistic",
                    })
                );
                navigate(`${Page2DashboardRoute}`)
            }
        }
    };

    /* use effects */

    // init state options
    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsMainDashboard, dispatchGlobal);
    }, []);

    // init data
    useEffect(() => {
        if (state.id !== '') {
            initFunctionCustom(true);
        }
    }, [filterStartDate, filterEndDate, selectedCountryId, pagination.pageIndex, pagination.pageSize]);

    // init graphs
    useEffect(() => {
        initGraphInbound(false);
        initGraphOutbound(false);
        initGraphAPI(false);
        initGraphTimeout(false);
    }, []);

    useEffect(() => {
        initGraphInbound(false);
    }, [state.graphInboundStatus, filterStartDate, filterEndDate, selectedCountryId]);

    useEffect(() => {
        initGraphOutbound(false);
    }, [state.graphOutboundStatus, filterStartDate, filterEndDate, selectedCountryId]);

    useEffect(() => {
        if (selectedCountryId === '410') {
            initGraphAPI(false);
        }
    }, [state.graphAPIDays, selectedCountryId]);

    useEffect(() => {
        if (selectedCountryId === '410') {
            initGraphTimeout(false);
        }
    }, [state.graphTimeoutDays, selectedCountryId]);

    // refresh when page size is changed
    useEffect(() => {
        setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
    }, [state.view]);

    // check if user has privilege and get role type
    useEffect(() => {
        if (user.privilegeAccess !== null) {
            if (user.privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true });
            } else {
                handleStateComponent('id', user.memberCode);
                handleStateComponent('privilegeId', user.idPrivilegeAccess);
                handleStateComponent('listRoleType', user.memberRoleType);
            }
        }
    }, [user]);

    // check if user role is BCAP
    useEffect(() => {
        if (state.id === state.userBCAPId) {
            handleStateComponent('isRoleBCAP', true);
            initFunctionCustom(true);
        } else {
            handleStateComponent('isRoleBCAP', false);
            if(state.id.slice(0, 3) === '410') initFunctionCustom(true);
        }
    }, [state.id]);

    // change selected country filter based on role if user role not BCAP
    useEffect(() => {
        if (state.id !== state.userBCAPId) {
            if (stateOptions.optionsSwitcherCountry.length > 0) {
                for (const country of stateOptions.optionsSwitcherCountry) {
                    if (country.value === state.id.slice(0, 3)) {
                        handleStateComponent('selectedCountryId', country.value);
                        handleStateComponent('selectedCountryLabel', country.label);
                        handleStateGlobal('selectedCountryId', country.value);
                        handleStateGlobal('selectedCountryLabel', country.label);
                        break;
                    }
                }
            }
        }
    }, [stateOptions.optionsSwitcherCountry]);

    // check if date range is more than 7 days
    useEffect(() => {
        const maxRangeDays = 7;

        if(IsDateRangeWithinDays(state.filterStartDate, state.filterEndDate, maxRangeDays)) {
            handleStateComponent('filterErrorMessage', 'Maximum range for start~end date is 7 days');
        } else {
            handleStateComponent('filterErrorMessage', '');
        }
    }, [state.filterStartDate, state.filterEndDate])

    const optionsTransactionStatus: any = [
        {
            label: "Approved",
            value: "APPROVED"
        },
        {
            label: "Pre-Declined",
            value: "PRE-DECLINED"
        },
        {
            label: "Declined",
            value: "DECLINED"
        },
        {
            label: "Timeout",
            value: "TIMEOUT"
        }
    ];

    const optionsDayGraph: any = [
        {
            label: "7 days",
            value: 7
        },
        {
            label: "14 days",
            value: 14
        },
        {
            label: "30 days",
            value: 30
        }
    ];

    const optionsDayGraphWithHour: any = [
        {
            label: "Today",
            value: 0
        },
        {
            label: "Yesterday",
            value: 1
        },
        {
            label: "7 days",
            value: 7
        },
        {
            label: "14 days",
            value: 14
        },
        {
            label: "30 days",
            value: 30
        }
    ];

    return (
        <>
            <div id='desktop-view' className='d-none d-sm-block'>
                <DesktopViewMainPage
                    state={state}
                    stateOptions={stateOptions}
                    pagination={pagination}
                    optionsDayGraph={optionsDayGraph}
                    optionsDayGraphWithHour={optionsDayGraphWithHour}
                    optionsTransactionStatus={optionsTransactionStatus}
                    setPagination={setPagination}
                    initFunctionCustom={initFunctionCustom}
                    handleStateComponent={handleStateComponent}
                    handleStateGlobal={handleStateGlobal}
                    handleRowClick={handleRowClick}
                    handleScrollToTransaction={handleScrollToTransaction}
                    reloadDataComponent={reloadDataComponent}
                    countDifferencePercentage={countDifferencePercentage}
                    dispatch={dispatch}
                />
            </div>

            <div id='mobile-view' className='mt-2 d-block d-sm-none'>
                <MobileViewMainPage
                    state={state}
                    stateOptions={stateOptions}
                    pagination={pagination}
                    optionsDayGraph={optionsDayGraph}
                    optionsDayGraphWithHour={optionsDayGraphWithHour}
                    optionsTransactionStatus={optionsTransactionStatus}
                    setPagination={setPagination}
                    initFunctionCustom={initFunctionCustom}
                    handleStateComponent={handleStateComponent}
                    handleStateGlobal={handleStateGlobal}
                    handleRowClick={handleRowClick}
                    handleScrollToTransaction={handleScrollToTransaction}
                    reloadDataComponent={reloadDataComponent}
                    countDifferencePercentage={countDifferencePercentage}
                    dispatch={dispatch}
                />
            </div>
        </>
    );
};

const initialStateOptions = { ...stateOptions };
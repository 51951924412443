
// Dispute

interface MemberPicDtos {

    memberPicId: number | null;
    memberId: number | null;
    memberPicCode: string | null;
    userId: number | null;
}

interface InterfaceDispute {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberDisplayFileYn: Boolean | null;
    memberDisplayPortalYn: Boolean | null;
    memberPicDtos: MemberPicDtos[] | any[] | null;
    memberDisplayEmailNotifYn: Boolean | null;

}

export class ModalDispute {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberDisplayFileYn: Boolean | null;
    memberDisplayPortalYn: Boolean | null;
    memberPicDtos: MemberPicDtos[] | null;
    memberDisplayEmailNotifYn: Boolean | null;

    constructor(value: InterfaceDispute) {
        this.id = value.id;
        this.memberParamsId = value?.memberParamsId ?? null;
        this.memberCode = value?.memberCode ?? null;
        this.memberDisplayFileYn = value?.memberDisplayFileYn ?? null;
        this.memberDisplayPortalYn = value?.memberDisplayPortalYn ?? null;
        this.memberPicDtos = value?.memberPicDtos ?? null;
        this.memberDisplayEmailNotifYn = value?.memberDisplayEmailNotifYn ?? null;
    }
}

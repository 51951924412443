import { CheckUndefined, CheckUndefinedStringEmpty, NumberWithCommas } from '../../Utils';
import { Divider, Modal, Tooltip } from 'antd';
import { FooterModalUpdate, UploadFileComponent } from '../../Components';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useReducer } from 'react';
import { Resizable, ResizableBox } from "react-resizable";
import { useDispatch, useSelector } from 'react-redux';

import { API } from '../../Services';
import { Buffer } from 'buffer';
import Draggable from 'react-draggable';
import { FaCopy } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ModelDetailDisputeResolutionEvent } from '../DisputeResolution/ModelDetailDisputeResolutionEvent';
import { ModelDetailSalesInquiry } from '../SalesInquiry/ModelDetailSalesInquiry';
import { Page2DashboardRoute } from '../../Routes';
import Select from 'react-select';
import { icon_search_white } from '../../Assets/Images/svg';
import { labelStyle } from '../../Styles';
import { message } from 'antd';
import { resetAllActionPage2 } from '../../Store/TransactionMonitoringStatusSlice';
import { saveAs } from "file-saver";
import { urlSelection } from '../../Services/API';

export const ModalSalesInquirySettlementSummaryAcquirer = ({ ...props }) => {
    const [messageApi, contextHolder] = message.useMessage();

    const params = useParams();
    const dispatchGlobal = useDispatch();
    const navigate = useNavigate();
    const userLogin = useSelector((state: any) => state.user);
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsChargebackReasonDetailCode } = props.stateOptions


    const draggleRef2 = React.createRef<any>();
    // {{url}}/settlement/member-sales?authStan=773584&authInvNo=INV1234632356547
    useEffect(() => {
        dispatchStatePage({ type: 'resetForms' });
        // dispatchStatePage({ 'type': 'setData', value: itemModal })
        dispatchStatePage({ type: 'showDetail', value: false });

        const fetchData = async () => {
            if (showModal && itemModal.authStan !== null && itemModal.authStan !== undefined && itemModal.authStan !== '' && itemModal.authInvNo !== null && itemModal.authInvNo !== undefined && itemModal.authInvNo !== '') {
                try {
                    const response = await API.get({
                        bodyCustom: null,
                        pathCustom: `settlement/member-sales?authStan=${itemModal.authStan}&authInvNo=${itemModal.authInvNo}`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    });
                    dispatchStatePage({ type: 'visible', value: showModal });
                    handleState('setDataSalesInquiry', response.data);
                } catch (error) {
                    actionShowModal()
                }
            }
        };

        fetchData();
    }, [showModal, id]);

    useEffect(() => {
        if(showModal === false){
            dispatchStatePage({ type: 'visible', value: false });
        }
    }, [showModal]);


    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    useEffect(() => {

    }, [statePage.dataSalesInquiry.caseId])

    let height_ = window.innerHeight - 100;
    let width_ = window.innerWidth - 100;


    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const ButtonSubmitAction = () => {
        const { selectChargebackReason, selectNotes, selectFile, selectFileArray } = statePage
        let sizeMax = 0;
        if (selectChargebackReason === '' || params.id === '') {
            handleState('errorMessage', 'RE-PRESENTMENT REASON must not be null')
            return;
        }
        let formData = new FormData()
        let typeJPG: string = 'image/jpeg';
        let typePNG: string = 'image/png';
        let typePDF: string = 'application/pdf';
        let typeDoc: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        let FilesAllowed: string[] = [typeJPG, typePNG, typePDF, typeDoc]

        let conditionFilesIsAllowed: Boolean[] = [];
        if (selectFileArray.length > 0) {
            selectFileArray.forEach((item1: any) => {
                conditionFilesIsAllowed.push(FilesAllowed.includes(item1.type))
            })
        }
        if (conditionFilesIsAllowed.includes(false)) {
            handleState('errorMessage', 'Wrong type of File! Only PDF, DOC, PNG, and JPG type is allowed');
        } else {
            handleState('errorMessage', '');
            if (selectFileArray.length > 0) {
                for (let index = 0; index < selectFileArray.length; index++) {
                    sizeMax = sizeMax + selectFileArray[index].size;
                    formData.append("files", selectFileArray[index]);
                }
            }
            formData.append("authTransId", statePage.dataSalesInquiry.transId);
            formData.append("eventReason", selectChargebackReason);
            formData.append("eventNote", selectNotes);
            if (selectFileArray.length === 0) {
                handleState('errorMessage', 'Documents are required');
            } else if (sizeMax < (5000 * 1000)) {
                API.postFile({
                    bodyCustom: formData,
                    pathCustom: `dispute`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                    needNotification: true,
                }).then((response) => {
                    actionShowModal();
                    handleState('clearDataSalesInquiry', null);
                    actionAfterUpdateItem(id)
                    dispatchStatePage({ type: 'showDetail', value: false })
                }).catch((err) => {
                    handleState('errorMessage', err)
                })
            } else if (sizeMax > (5000 * 1000)) {
                handleState('errorMessage', 'Maximum upload size exceeded, maximum size is 5Mb');
            }
        }
    }

    const onResize = (event: any, propsSize: any) => {
        const { node, size, handle } = propsSize
        if (size.width > 100 && size.height > 100) {
            dispatchStatePage({ type: 'resize', value: { width: size.width, height: size.height } })
        }
    };

    const TransactionInformation = () => {
        let classNameLeft = 's-1418 w-200px d-flex align-items-center p-2 '
        let classNameRight = 'Lato-700 s-1214 w-100 bg-f4f4f4 h-100 d-flex align-items-center p-2'
        let styleBorderLeft = { borderRight: '1px solid black', borderBottom: '1px solid black' }
        let styleBorderRight = { borderRight: '1px solid black', borderBottom: '1px solid black', background: '#F4F4F4' }
        return (
            <div className='w-100'>
                {
                    statePage.showDetail === true ?
                        <div className="mb-3">
                            <p className="w-400 Lato-700 c-29 s-1822">Transaction Information</p>
                        </div>
                        :
                        <></>

                }
                <div className="row p-0 m-0" style={{ overflow: 'auto', border: '1px solid black', flexWrap: 'nowrap' }} >
                    <div className="col-xl-4 col-lg-6 col-sm-12 col-xs-12 p-0 m-0" >
                        <div className="" >
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Trans Date/Time</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.transDate)} {CheckUndefined(statePage.dataSalesInquiry.transTime)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Local Trans Date/Time</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.localTransDate)} {CheckUndefined(statePage.dataSalesInquiry.localTransTime)}</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Central Processing Date</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.cpd)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Trans ID</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>

                                    <a
                                        // to={``}
                                        // onClick={(e) => {
                                        //     dispatchGlobal(resetAllActionPage2(CheckUndefined(statePage.dataSalesInquiry.transId)))
                                        //     // navigate(`${Page2DashboardRoute}`)
                                        //     window.open(`${Page2DashboardRoute}?transId=${CheckUndefined(statePage.dataSalesInquiry.transId)}`);

                                        // }}
                                        href={`${Page2DashboardRoute}?transId=${CheckUndefined(statePage.dataSalesInquiry.transId)}`}
                                        target="_blank"
                                    >
                                        {CheckUndefined(statePage.dataSalesInquiry.transId)}
                                    </a>
                                    <div
                                        className='pointer text-info ms-2'
                                        onClick={
                                            (event: React.MouseEvent<HTMLDivElement>) => {
                                                event.stopPropagation();
                                                navigator.clipboard.writeText(`${CheckUndefined(statePage.dataSalesInquiry.transId)}`)
                                                messageApi.success('Copied to clipboard')
                                            }
                                        }>
                                        <Tooltip placement="bottom" title={'Copy to clipboard'}>
                                            <FaCopy color='black' />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Trans Type</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.transType)}</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Product ID</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.productFeatureName)} ({CheckUndefined(statePage.dataSalesInquiry.productFeatureInd)})</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Retrieval Ref Number</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.rrn)}</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>System Trace Audit Number</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.stan)}</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Invoice Number</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.invoiceNumber)}</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Approval Code</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.approvalCode)}</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={{ borderRight: '1px solid black' }}>Response Code</div>
                                <div className={`${classNameRight}`} style={{ borderRight: '1px solid black', background: '#F4F4F4' }}>{CheckUndefined(statePage.dataSalesInquiry.responseCode)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-12 col-xs-12 p-0 m-0">
                        <div className="">
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Acquirer Switcher</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.acqSwitcherMemberName)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Acquirer Switcher Amount</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefinedStringEmpty(statePage.dataSalesInquiry.acqSwitcherCurrency)} {NumberWithCommas(CheckUndefined(CheckUndefined(statePage.dataSalesInquiry.acqSwitcherAmount)))}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Acquirer Switcher Country</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.acqSwitcherCountry)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Acquirer</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.acqMemberName)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Acquirer Amount</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefinedStringEmpty(statePage.dataSalesInquiry.acqCurrency)} {NumberWithCommas(CheckUndefined(CheckUndefined(statePage.dataSalesInquiry.acqAmount)))}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Acquirer Country</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.acqCountry)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Merchant ID</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.merId)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Merchant Name</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.merName)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Terminal ID</div>
                                <div className={`${classNameRight}`} style={styleBorderRight}>{CheckUndefined(statePage.dataSalesInquiry.terId)}</div>
                            </div>
                            <div>
                                <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                    <div className={`${classNameLeft}`} style={styleBorderLeft}></div>
                                    <div className={`${classNameRight}`} style={styleBorderRight}></div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                    <div className={`${classNameLeft}`} style={{ borderRight: '1px solid black' }}></div>
                                    <div className={`${classNameRight}`} style={{ borderRight: '1px solid black', background: '#F4F4F4' }}></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-12 col-xs-12 p-0 m-0">
                        <div className="">

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Issuer Switcher</div>
                                <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}>{CheckUndefined(statePage.dataSalesInquiry.issSwitcherMemberName)}</div>
                            </div>


                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Issuer Switcher Amount</div>
                                <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}>{CheckUndefinedStringEmpty(statePage.dataSalesInquiry.issSwitcherCurrency)} {NumberWithCommas(CheckUndefined(statePage.dataSalesInquiry.issSwitcherAmount))}</div>
                            </div>


                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Issuer Switcher Country</div>
                                <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}>{CheckUndefined(statePage.dataSalesInquiry.issSwitcherCountry)}</div>
                            </div>


                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Issuer</div>
                                <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}>{CheckUndefined(statePage.dataSalesInquiry.issMemberName)}</div>
                            </div>


                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Issuer Amount</div>
                                <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}>{CheckUndefinedStringEmpty(statePage.dataSalesInquiry.issCurrency)} {NumberWithCommas(CheckUndefined(statePage.dataSalesInquiry.issAmount))}</div>
                            </div>


                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Issuer Country</div>
                                <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}>{CheckUndefined(statePage.dataSalesInquiry.issCountry)}</div>
                            </div>

                            <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                <div className={`${classNameLeft}`} style={styleBorderLeft}>Fee</div>
                                <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}>{CheckUndefinedStringEmpty(statePage.dataSalesInquiry.acqCurrency)} {statePage.dataSalesInquiry.acqFeeAmount === null ? "0" :NumberWithCommas(CheckUndefined(CheckUndefined(statePage.dataSalesInquiry.acqFeeAmount)))}</div>
                            </div>
                            <div>
                                <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                    <div className={`${classNameLeft}`} style={styleBorderLeft}></div>
                                    <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}></div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                    <div className={`${classNameLeft}`} style={styleBorderLeft}></div>
                                    <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}></div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                    <div className={`${classNameLeft}`} style={styleBorderLeft}></div>
                                    <div className={`${classNameRight}`} style={{ borderBottom: '1px solid black', background: '#F4F4F4' }}></div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex bd-ea h-60px" style={{ border: '0px solid black' }}>
                                    <div className={`${classNameLeft}`} style={{ borderRight: '1px solid black' }}></div>
                                    <div className={`${classNameRight}`} style={{ background: '#F4F4F4' }}></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const ChargebackInformation = () => {
        return (
            <div className="">
                <Divider />
                <h2 className="s-1822 my-2">Chargeback Information</h2>
                <div className="row br-10 p-0 m-0 mb-3" >
                    <div className="col-12 br-10 p-0 m-0" >
                        <div className="d-flex flex-column mb-3">
                            <p className={labelStyle}>ChargeBacK REASON</p>
                            <Select options={optionsChargebackReasonDetailCode} value={optionsChargebackReasonDetailCode.filter((option: any) => option.value === statePage.selectChargebackReason)} onChange={(e) => handleState("selectChargebackReason", e.value)} />
                        </div>
                        <div className='d-flex flex-column mt-3 mb-3'>
                            <p className={labelStyle}>NOTES</p>
                            <textarea rows={4} value={statePage.selectNotes} onChange={(e) => handleState('selectNotes', e.target.value)} className='bd-d4 bg-ff br-5 width-100 s-1418 Lato-400 w-500 out-none pl-10' />
                        </div>
                        <UploadFileComponent handleStateParent={handleState} stateParent={statePage} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => ButtonSubmitAction()} />
            </div>
        )
    }

    return (
        <Modal
            mask={false}
            maskClosable={false}
            wrapClassName="aaa"
            width="max-content"
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 1450) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()} style={{ overflow: 'hidden',boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >
            {id === null ?
                <div className='' style={{ width: '500px' }}>
                    <Divider />
                    This data doesn't have Auth Trans ID or Trans ID
                    <Divider />
                </div>
                :
                <ResizableBox height={statePage.height} width={statePage.width} onResize={onResize} maxConstraints={[width_, height_]} style={{ overflowY: 'auto' }} minConstraints={[920, 700]}>
                    <div style={{ width: '100%', height: '440px' }}>
                        <div className='d-flex flex-column'>
                            {contextHolder}
                            <div className='d-flex flex-column '>
                                {TransactionInformation()}
                                {
                                    statePage.showDetail === true ?
                                        ChargebackInformation()
                                        :
                                        <></>
                                }
                                {
                                    (statePage.dataSalesInquiry.isHaveDisputeCase === false) ? // already responded ?
                                        (userLogin.memberCode === statePage.dataSalesInquiry.issSwitcherMemberId || userLogin.memberCode === statePage.dataSalesInquiry.issMemberId) ? // check if the member code is the same as the response given by the sales inquiry
                                            (userLogin.memberIsRegisteredToPIC === true) ? // user login is registered member PIC
                                                statePage.showDetail === true ?
                                                    <></>
                                                    :
                                                    userLogin.memberRoleType.length > 0 ?
                                                        (userLogin.memberRoleType as any[]).includes('002') || (userLogin.memberRoleType as any[]).includes('005') ?

                                                            <div className='d-flex my-2'>
                                                                <button className="btn d-flex align-items-center px-2 bg-e8 min-h-40px " onClick={() => dispatchStatePage({ type: 'showDetail', value: true })}>
                                                                    <p className="s-1418 c-ff Lato-600 mx-2">Request Chargeback</p>
                                                                </button>
                                                            </div>
                                                            :
                                                            <></>
                                                        :
                                                        <></>
                                                :
                                                <></>
                                            :
                                            <></>
                                        :
                                        <></>

                                }
                            </div>
                        </div>
                    </div>
                </ResizableBox>

            }

        </Modal>
    );

}

const initialState = {
    titleModal: 'MEMBER SALES DETAILS',
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelDetailDisputeResolutionEvent({
        id: null,
        eventId: null,
        dateTime: null,
        eventType: null,
        event: null,
        user: null,
        member: null,
        detail: null,
    }),
    //
    dataSalesInquiry: new ModelDetailSalesInquiry({
        id: null,
        postId: null,
        transDate: null,
        transTime: null,
        localTransDate: null,
        localTransTime: null,
        cpd: null,
        transId: null,
        transTypeCode: null,
        transType: null,
        productFeatureName: null,
        productFeatureInd: null,
        rrn: null,
        stan: null,
        invoiceNumber: null,
        approvalCode: null,
        responseCode: null,
        acqSwitcherId: null,
        acqSwitcherMemberId: null,
        acqSwitcherMemberName: null,
        acqSwitcherAmount: null,
        acqSwitcherCurrency: null,
        acqSwitcherCurrencyCode: null,
        acqSwitcherCountry: null,
        acqId: null,
        acqMemberId: null,
        acqMemberName: null,
        acqAmount: null,
        acqCurrency: null,
        acqCurrencyCode: null,
        acqCountry: null,
        merId: null,
        merName: null,
        terId: null,
        issSwitcherId: null,
        issSwitcherMemberId: null,
        issSwitcherMemberName: null,
        acqFeeAmount: null,
        issSwitcherAmount: null,
        issSwitcherCurrency: null,
        issSwitcherCurrencyCode: null,
        issSwitcherCountry: null,
        issId: null,
        issMemberId: null,
        issMemberName: null,
        issAmount: null,
        issCurrency: null,
        issCurrencyCode: null,
        issCountry: null,
        caseId: null,
        isHaveDisputeCase: null,


    }),
    //
    id: '',
    showDetail: false,
    selectChargebackReason: '',
    selectNotes: '',
    selectFile: '',
    selectFileArray: [''],
    imgSrcArray: '',
    //
    width: 1400,
    height: 700
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelDetailDisputeResolutionEvent) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelDetailDisputeResolutionEvent({
                    id: null,
                    eventId: null,
                    dateTime: null,
                    eventType: null,
                    event: null,
                    user: null,
                    member: null,
                    detail: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelDetailDisputeResolutionEvent({
                    id: action.value.eventId,
                    eventId: action.value.eventId,
                    dateTime: action.value.dateTime,
                    eventType: action.value.eventType,
                    event: action.value.event,
                    user: action.value.user,
                    member: action.value.member,
                    detail: action.value.detail,
                }),
                errorMessage: "",
            };


        case 'clearDataSalesInquiry':
            return {
                ...state,
                dataSalesInquiry: new ModelDetailSalesInquiry({
                    id: null,

                    postId: null,
                    transDate: null,
                    transTime: null,
                    localTransDate: null,
                    localTransTime: null,
                    cpd: null,
                    transId: null,
                    transTypeCode: null,
                    transType: null,
                    productFeatureName: null,
                    productFeatureInd: null,
                    rrn: null,
                    stan: null,
                    invoiceNumber: null,
                    approvalCode: null,
                    responseCode: null,
                    acqSwitcherId: null,
                    acqSwitcherMemberId: null,
                    acqSwitcherMemberName: null,
                    acqSwitcherAmount: null,
                    acqSwitcherCurrency: null,
                    acqSwitcherCurrencyCode: null,
                    acqSwitcherCountry: null,
                    acqId: null,
                    acqMemberId: null,
                    acqMemberName: null,
                    acqAmount: null,
                    acqCurrency: null,
                    acqCurrencyCode: null,
                    acqCountry: null,
                    merId: null,
                    merName: null,
                    terId: null,
                    issSwitcherId: null,
                    issSwitcherMemberId: null,
                    issSwitcherMemberName: null,
                    acqFeeAmount: null,
                    issSwitcherAmount: null,
                    issSwitcherCurrency: null,
                    issSwitcherCurrencyCode: null,
                    issSwitcherCountry: null,
                    issId: null,
                    issMemberId: null,
                    issMemberName: null,
                    issAmount: null,
                    issCurrency: null,
                    issCurrencyCode: null,
                    issCountry: null,
                    caseId: null,
                    isHaveDisputeCase: null,
                }),
                errorMessage: "",

            };
        case 'setDataSalesInquiry':
            return {
                ...state,
                dataSalesInquiry: new ModelDetailSalesInquiry({
                    id: action.value.postId,
                    postId: action.value.postId,
                    transDate: action.value.transDate,
                    transTime: action.value.transTime,
                    localTransDate: action.value.localTransDate,
                    localTransTime: action.value.localTransTime,
                    cpd: action.value.cpd,
                    transId: action.value.transId,
                    transTypeCode: action.value.transTypeCode,
                    transType: action.value.transType,
                    productFeatureName: action.value.productFeatureName,
                    productFeatureInd: action.value.productFeatureInd,
                    rrn: action.value.rrn,
                    stan: action.value.stan,
                    invoiceNumber: action.value.invoiceNumber,
                    approvalCode: action.value.approvalCode,
                    responseCode: action.value.responseCode,
                    acqSwitcherId: action.value.acqSwitcherId,
                    acqSwitcherMemberId: action.value.acqSwitcherMemberId,
                    acqSwitcherMemberName: action.value.acqSwitcherMemberName,
                    acqSwitcherAmount: action.value.acqSwitcherAmount,
                    acqSwitcherCurrency: action.value.acqSwitcherCurrency,
                    acqSwitcherCurrencyCode: action.value.acqSwitcherCurrencyCode,
                    acqSwitcherCountry: action.value.acqSwitcherCountry,
                    acqId: action.value.acqId,
                    acqMemberId: action.value.acqMemberId,
                    acqMemberName: action.value.acqMemberName,
                    acqAmount: action.value.acqAmount,
                    acqCurrency: action.value.acqCurrency,
                    acqCurrencyCode: action.value.acqCurrencyCode,
                    acqCountry: action.value.acqCountry,
                    merId: action.value.merId,
                    merName: action.value.merName,
                    terId: action.value.terId,
                    issSwitcherId: action.value.issSwitcherId,
                    issSwitcherMemberId: action.value.issSwitcherMemberId,
                    issSwitcherMemberName: action.value.issSwitcherMemberName,
                    acqFeeAmount: action.value.acqFeeAmount,
                    issSwitcherAmount: action.value.issSwitcherAmount,
                    issSwitcherCurrency: action.value.issSwitcherCurrency,
                    issSwitcherCurrencyCode: action.value.issSwitcherCurrencyCode,
                    issSwitcherCountry: action.value.issSwitcherCountry,
                    issId: action.value.issId,
                    issMemberId: action.value.issMemberId,
                    issMemberName: action.value.issMemberName,
                    issAmount: action.value.issAmount,
                    issCurrency: action.value.issCurrency,
                    issCurrencyCode: action.value.issCurrencyCode,
                    issCountry: action.value.issCountry,
                    caseId: action.value.caseId,
                    isHaveDisputeCase: action.value.isHaveDisputeCase,
                }),
                errorMessage: "",
            };

        case 'showDetail':
            return {
                ...state,
                showDetail: action.value,
            }
        case 'selectChargebackReason':
            return {
                ...state,
                selectChargebackReason: action.value,
            }
        case 'selectNotes':
            return {
                ...state,
                selectNotes: action.value,
            }
        case 'selectFile':
            return {
                ...state,
                selectFile: action.value,
            }
        case 'selectFileArray':
            return {
                ...state,
                selectFileArray: action.value,
            }
        case 'imgSrcArray':
            return {
                ...state,
                imgSrcArray: action.value,
            }
        case 'resetForms':
            return {
                ...state,
                selectChargebackReason: '',
                selectNotes: '',
                selectFileArray: [''],
            }
        case 'resize':
            return {
                ...state,
                width: action.value.width,
                height: action.value.height,
            };

        default:
            throw new Error();
    }
}





import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ButtonDelete, ButtonEdit  } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    stateOptions,
    stateSelected
} from '../../State';
import { InterfaceOptionsExchangeRateSetting } from './InterfaceOptionsExchangeRateSetting';
import { ModalDraggableExchangeRateSetting } from './ModalDraggableExchangeRateSetting';
import { ModalExchangeRateSetting } from './ModalExchangeRateSetting';
import { ModelExchangeRateSetting } from './ModelExchangeRateSetting';
import { ShowModalInterface } from '../../Interface';
import { ExcelExchangeRateSetting } from '../FeeManagement/Excel';
import { Page16Title } from '../../State/Menu/TitleMenu';
//
import { undo_icon } from '../../Assets/Images/svg';
import { TableExchangeRateSetting } from './Components/TableExchangeRateSetting';
import { inputStyleH25 } from '../../Styles';

export const ExchangeRateSetting = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });
    
    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search !== '' ? `search=${state.search}&` : '';
        let selectedRateFrom = stateCustomComponent.selectedRateFrom !== '' ? `rateFrom=${stateCustomComponent.selectedRateFrom}&` : '';
        let selectedRateTo = stateCustomComponent.selectedRateTo !== '' ? `rateTo=${stateCustomComponent.selectedRateTo}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let finalParameter = `${searchValue}${limit}${offset}${selectedRateFrom}${selectedRateTo}`;
        let finalParameter2 = `${searchValue}${selectedRateFrom}${selectedRateTo}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });

                /* Refresh Page */
                setPagination({pageSize: state.view, pageIndex: 0})
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsExchangeRateSetting, dispatchGlobal)
    }, [])


    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search, stateCustomComponent.selectedRateFrom, stateCustomComponent.selectedRateTo])

    useEffect(() => {
        if (state.view !== pagination.pageSize) {
            initFunctionCustom(true);
            setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
        } else {
            initFunctionCustom(false);
        }
    }, [pagination.pageIndex, state.view]);


    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelExchangeRateSetting(state, stateOptions, response.data)
        })
    }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelExchangeRateSetting) => deleteData(item.id, item, dispatch)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelExchangeRateSetting, width: string | any) => {
        return (
            <div className={`d-flex gap-2 align-items-center ${width}`}>
                
                <ButtonEdit handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
                
            </div>
        );
    };
    const filterComponentPage = () => {
        return (
            <div className="g-5 row bg-ff mb-10 d-flex justify-content-center align-items-center p-0 m-0 py-3 mb-2 br-10 bd-d4">
                <div className="row my-1">
                    <div className="col-3 d-flex justify-content-between">
                        <Select
                            styles={inputStyleH25}
                            className='flex-fill'
                            placeholder='From: '
                            options={stateOptions.optionsCurrencyCcdName}
                            onChange={(e) => handleState('selectedRateFrom', e.value, dispatchCustomComponent)}
                            value={stateCustomComponent.selectedRateFrom === "" ? "" : stateOptions.optionsCurrencyCcdName.filter((option: any) => option.value === stateCustomComponent.selectedRateFrom)}
                        />
                        {/* // */}
                        <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedRateFrom", '', dispatchCustomComponent) }}>
                            {undo_icon}
                        </div>
                    </div>
                    <div className="col-3 d-flex justify-content-between">
                        <Select
                            styles={inputStyleH25}
                            className='flex-fill'
                            placeholder='To: '
                            options={stateOptions.optionsCurrencyCcdName}
                            onChange={(e) => handleState('selectedRateTo', e.value, dispatchCustomComponent)}
                            value={stateCustomComponent.selectedRateTo === "" ? "" : stateOptions.optionsCurrencyCcdName.filter((option: any) => option.value === stateCustomComponent.selectedRateTo)}
                        />
                        {/* // */}
                        <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedRateTo", '', dispatchCustomComponent) }}>
                            {undo_icon}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <ModalExchangeRateSetting
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableExchangeRateSetting
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                Exchange Rate Setting
            </h1>
            <TableExchangeRateSetting
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                filterComponentPage={filterComponentPage}
                addDataComponent={addDataComponent}
                handleStateComponent={handleStateComponent}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
            />

            {modalAddDeleteComponentPage()}
            {modalUpdateComponentPage()}
        </>
    );

}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    titlePage: Page16Title,
    urlApi: 'exchange_rate',
    privilegeAccess: { view: '321', add: '322', edit: '323', delete: '324', download: '325' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Exchange Rate ID', width: 'wd-200', val: 'rateId' },
        { field: 'From', width: 'wd-100', val: 'rateFrom' },
        { field: 'To', width: 'wd-100', val: 'rateTo' },
        { field: 'Rate', width: 'wd-100', val: 'rateValue' },
        { field: 'Status', width: 'wd-100', val: 'rateStatus' },
        { field: 'Source Method', width: 'wd-150', val: 'rateSrcMethod' },
        { field: 'Source Name', width: 'wd-150', val: 'rateSrcName' },
        { field: 'Action', width: 'wd-150', val: 'action' }
    ],
    toggle: ['no', 'rateId', 'rateFrom', 'rateTo', 'rateValue', 'rateStatus', 'rateSrcMethod', 'rateSrcName', 'action'],
    complete_toggle: ['no', 'rateId', 'rateFrom', 'rateTo', 'rateValue', 'rateStatus', 'rateSrcMethod', 'rateSrcName', 'action'],
    list_column_name: ['No.', 'Exchange Rate', 'From', 'To', 'Rate', 'Status', 'Source Method', 'Source Name'],
    // 
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Exchange Rate ID, From, To, Rate, Source Method, Source Name',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1200,
    finalAPI: '',
};


// rateId ModelExchangeRateSetting
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelExchangeRateSetting) => {
                        element1.id = element1.rateId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelExchangeRateSetting) => {
                        element1.id = element1.rateId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelExchangeRateSetting) => {
                        showDataResult.push(
                            { idModal: element1.rateId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.rateId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}


import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelInterfaceRecordItem } from './ModelInterfaceRecordItem';
import { loadingAction } from '../../Store/Loading';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Page39Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';

export const ModalDraggableInterfaceRecordItem = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props

    const {
        optionsInterfaceRecord,
        optionsInterfaceFileItem,
        optionsFileStringSeparatorDetailCode,
        optionsMOC,
    } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["recordItemDefaultValue"]
        let limit = [64]
        let index = names.indexOf(nameState)

        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'recordItemNameLength' || nameState === 'recordItemSortNumber') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 10000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            }else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }
    const updateDataAPI = () => {
        const {
            recordItemId,
            interfaceFileItemId,
            interfaceFileItemName,
            interfaceRecordId,
            interfaceRecordName,
            recordItemSortNumber,
            recordItemSeparator,
            recordItemNameYn,
            recordItemNameLength,
            recordItemMoc,
            recordItemDefaultValue,
            recordItemCreateBy,
            recordItemCreateDate,
            recordItemUpdateBy,
            recordItemUpdateDate,
            recordItemDeleteBy,
            recordItemDeleteDate,
            recordItemIsDelete,

        } = statePage.data
        const data = {
            recordItemId: id,
            interfaceRecordId: interfaceRecordId,
            interfaceFileItemId: interfaceFileItemId,
            recordItemSortNumber: recordItemSortNumber,
            recordItemSeparator: recordItemSeparator,
            recordItemNameYn: recordItemNameYn,
            recordItemNameLength: recordItemNameLength,
            recordItemMoc: recordItemMoc,
            recordItemDefaultValue: recordItemDefaultValue,
        }
        let a = '';
        if (recordItemNameYn === false) {
            a = CheckAllFields(
                [
                    interfaceRecordId,
                    interfaceFileItemId,
                    recordItemSortNumber,
                    recordItemSeparator,
                    recordItemNameYn,
                    recordItemMoc,
                ],
                [
                    "INTERFACE RECORD ID",
                    "INTERFACE FILE ITEM ID",
                    "SORT NUMBER",
                    "ITEM SEPARATOR",
                    "ITEM NAME",
                    "M/O/C"
                ]
            )
        } else {
            a = CheckAllFields(
                [
                    interfaceRecordId,
                    interfaceFileItemId,
                    recordItemSortNumber,
                    recordItemSeparator,
                    recordItemNameYn,
                    recordItemNameLength,
                    recordItemMoc,
                ],
                [
                    "INTERFACE RECORD ID",
                    "INTERFACE FILE ITEM ID",
                    "SORT NUMBER",
                    "ITEM SEPARATOR",
                    "ITEM NAME",
                    "ITEM NAME LENGTH",
                    "M/O/C"
                ]
            )
        }
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,

            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>INTERFACE RECORD ID</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceRecord} value={optionsInterfaceRecord.filter((option: any) => option.value === statePage.data.interfaceRecordId)} onChange={(e) => handleState('interfaceRecordId', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>INTERFACE FILE ITEM ID</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceFileItem} value={optionsInterfaceFileItem.filter((option: any) => option.value === statePage.data.interfaceFileItemId)} onChange={(e) => handleState('interfaceFileItemId', e.value)} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>SORT NUMBER</p>
                        <input type='number' min={1} value={statePage.data.recordItemSortNumber} onChange={(e) => handleState('recordItemSortNumber', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>ITEM SEPARATOR</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileStringSeparatorDetailCode} value={optionsFileStringSeparatorDetailCode.filter((option: any) => option.value === statePage.data.recordItemSeparator)} onChange={(e) => handleState('recordItemSeparator', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>ITEM NAME</p>
                        <BootstrapSwitchButton checked={statePage.data.recordItemNameYn} size="xs" onChange={(e) => handleState('recordItemNameYn', e)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>ITEM NAME LENGTH</p>
                        <input type='number' min={1} value={statePage.data.recordItemNameLength} onChange={(e) => handleState('recordItemNameLength', e.target.value)} className={inputStyle} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>M/O/C</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMOC} value={optionsMOC.filter((option: any) => option.value === statePage.data.recordItemMoc)} onChange={(e) => handleState('recordItemMoc', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>ITEM DEFAULT VALUE</p>
                        <input value={statePage.data.recordItemDefaultValue} onChange={(e) => handleState('recordItemDefaultValue', e.target.value)} className={inputStyle} />
                    </div>

                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page39Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelInterfaceRecordItem({
        id: null,
        recordItemId: null,
        interfaceFileItemId: null,
        interfaceFileItemName: null,
        interfaceRecordId: null,
        interfaceRecordName: null,
        recordItemSortNumber: null,
        recordItemSeparator: null,
        recordItemNameYn: false,
        recordItemNameLength: null,
        recordItemMoc: null,
        recordItemDefaultValue: null,
        recordItemCreateBy: null,
        recordItemCreateDate: null,
        recordItemUpdateBy: null,
        recordItemUpdateDate: null,
        recordItemDeleteBy: null,
        recordItemDeleteDate: null,
        recordItemIsDelete: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceRecordItem) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceRecordItem({
                    id: null,

                    recordItemId: null,
                    interfaceFileItemId: null,
                    interfaceFileItemName: null,
                    interfaceRecordId: null,
                    interfaceRecordName: null,
                    recordItemSortNumber: null,
                    recordItemSeparator: null,
                    recordItemNameYn: false,
                    recordItemNameLength: null,
                    recordItemMoc: null,
                    recordItemDefaultValue: null,
                    recordItemCreateBy: null,
                    recordItemCreateDate: null,
                    recordItemUpdateBy: null,
                    recordItemUpdateDate: null,
                    recordItemDeleteBy: null,
                    recordItemDeleteDate: null,
                    recordItemIsDelete: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceRecordItem({
                    id: action.value.recordItemId,
                    recordItemId: action.value.recordItemId,
                    interfaceFileItemId: action.value.interfaceFileItemId,
                    interfaceFileItemName: action.value.interfaceFileItemName,
                    interfaceRecordId: action.value.interfaceRecordId,
                    interfaceRecordName: action.value.interfaceRecordName,
                    recordItemSortNumber: action.value.recordItemSortNumber,
                    recordItemSeparator: action.value.recordItemSeparator,
                    recordItemNameYn: action.value.recordItemNameYn,
                    recordItemNameLength: action.value.recordItemNameLength,
                    recordItemMoc: action.value.recordItemMoc,
                    recordItemDefaultValue: action.value.recordItemDefaultValue,
                    recordItemCreateBy: action.value.recordItemCreateBy,
                    recordItemCreateDate: action.value.recordItemCreateDate,
                    recordItemUpdateBy: action.value.recordItemUpdateBy,
                    recordItemUpdateDate: action.value.recordItemUpdateDate,
                    recordItemDeleteBy: action.value.recordItemDeleteBy,
                    recordItemDeleteDate: action.value.recordItemDeleteDate,
                    recordItemIsDelete: action.value.recordItemIsDelete,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}







// Page3

interface Page3 {
    id: null | number | string;
    batchId: number | null;
    batchPreRequisite: string | null | any [];
    batchStatus: string | null;
    batchLastExec: string | null;
    batchGroupPath: string | null;
    createProgId: string | null;
    updateProgId: number | null;
    createDttm: string | null;
    updateDttm: string | null;
    batchRegType: string | null;
    batchRerunManuallyDttm: string | null;
    batchPlannedDttm: string | null;
    batchRunTime: string | null;
    batchParameterValue: string | null;
    batchExecutionType: string | null;
    batchInterval: number | null;
    batchBusinessGroup: string | null;
    flag: string | null;
    batchDescription: string | null;
    batchProgId: string | null;
}


class ModelPage3 {
    id: null | number | string;
    batchId: number | null;
    batchPreRequisite: string | null | any [];
    batchStatus: string | null;
    batchLastExec: string | null;
    batchGroupPath: string | null;
    createProgId: string | null;
    updateProgId: number | null;
    createDttm: string | null;
    updateDttm: string | null;
    batchRegType: string | null;
    batchRerunManuallyDttm: string | null;
    batchPlannedDttm: string | null;
    batchRunTime: string | null;
    batchParameterValue: string | null;
    batchExecutionType: string | null;
    batchInterval: number | null;
    batchBusinessGroup: string | null;
    flag: string | null;
    batchDescription: string | null;
    batchProgId: string | null;

    constructor(value: Page3 | null) {
        this.id = value?.id ?? null;
        this.batchId= value?.batchId ?? null;
        this.batchPreRequisite= value?.batchPreRequisite ?? null;
        this.batchStatus= value?.batchStatus ?? null;
        this.batchLastExec= value?.batchLastExec ?? null;
        this.batchGroupPath= value?.batchGroupPath ?? null;
        this.createProgId= value?.createProgId ?? null;
        this.updateProgId= value?.updateProgId ?? null;
        this.createDttm= value?.createDttm ?? null;
        this.updateDttm= value?.updateDttm ?? null;
        this.batchRegType= value?.batchRegType ?? null;
        this.batchRerunManuallyDttm= value?.batchRerunManuallyDttm ?? null;
        this.batchPlannedDttm= value?.batchPlannedDttm ?? null;
        this.batchRunTime= value?.batchRunTime ?? null;
        this.batchParameterValue= value?.batchParameterValue ?? null;
        this.batchExecutionType= value?.batchExecutionType ?? null;
        this.batchInterval= value?.batchInterval ?? null;
        this.batchBusinessGroup= value?.batchBusinessGroup ?? null;
        this.flag= value?.flag ?? null;
        this.batchDescription= value?.batchDescription ?? null;
        this.batchProgId= value?.batchProgId ?? null;
    }
}


export default ModelPage3
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonNavigation, InsideComponentPage } from '../../Components';
import { Page4DashboardRoute } from '../../Routes';
import { API } from '../../Services';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { changeStateReversalTransactionMonitoring, handleStateActionSAFStatistic, stateGlobalReversalTransactionMonitoring } from '../../Store/ReversalTransactionMonitoringSlice';
import { changeStateReversalTransactionMonitoringStatus } from '../../Store/ReversalTransactionMonitoringStatusSlice';
import { CheckUndefinedStringEmpty, colors } from '../../Utils';
import { DetailStatusTransactionRTM } from './Components/DetailStatusTransactionRTM';
import { FilterComponentRTM } from './Components/FilterComponentRTM';
import { LineChartComponentRTM } from './Components/LineChartComponentRTM';
import { PieChartComponentRTM } from './Components/PieChartComponentRTM';
import { ExcelTransactionMonitoring } from '../TransactionStatic/Utils/ExcelTransactionMonitoring';
import { InterfaceOptionsReversalTransactionMonitoring } from './Utils/InterfaceOptionsReversalTransactionMonitoring';
import { ModelTransactionMonitoring } from '../TransactionStatic/Utils/ModelTransactionMonitoring';
import { store } from '../../Store/Store';
import { loadingAction } from '../../Store/Loading';
import { ExcelReversalTransactionMonitoring } from './Utils';
import { TableReversalTransactionMonitoring } from './Components/TableReversalTransactionMonitoring';
import { Page3Title } from '../../State/Menu/TitleMenu';
import { Divider } from 'antd';
import { labelStyle } from '../../Styles';
import { urlSelection } from '../../Services/API';

export const ReversalTransactionMonitoring = ({...props}) => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const globalState = useSelector(stateGlobalReversalTransactionMonitoring);

    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const {
        dataFilter,
        dataCopyFilter,
        dataYesterday,
        filterStartDate,
        filterEndDate,
        selectedProductFeatureId,
        selectedProductFeatureLabel,
        selectedProductFeatureSendToApi,
        selectedProductId,
        selectedProductLabel,
        selectedProductSendToApi,
        search,
        optionsProductFeature,
        optionsProduct,
        rangeDate,
        sortBy,
        sortType
    } = globalState;

    const handleGlobalState = (nameState: string, valueState: any) => {
        dispatchGlobal(handleStateActionSAFStatistic({ name: nameState, value: valueState }))
    }

    const preparationFilter = () => {
        const getMembers = () => {
            return new Promise((resolve, reject) => {
                API.get({
                    bodyCustom: null,
                    pathCustom: `member`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needNotification: false,
                }).then((response: any) => {
                    let newList = [];
                    if (response.data.length > 0) {
                        response.data.forEach((element1: any, index: number) => {
                            let randomColor = colors[index];
                            newList.push({ memberId: element1.memberId, memberCode: element1.memberCode, memberName: element1.memberName, memberColor: randomColor, dataTransaction: [] })
                        });
                    }
                    newList.push({ memberId: '', memberCode: '', memberName: 'Undefined', memberColor: 'rgb(0,0,0)', dataTransaction: [] })
                    dispatchGlobal(changeStateReversalTransactionMonitoring({ listMember: newList })); // automatically filter data also to the contents
                    resolve(response.data);
                }).catch((err: any) => reject(err));
            })
        }
        // for percentage UI Top PIE CHART
        const getReasonCode = () => {
            return new Promise((resolve, reject) => {
                API.get({
                    bodyCustom: null,
                    pathCustom: `reason_code`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needNotification: false,
                }).then((response: any) => {
                    let newListDataStatusTransaction: any = [];
                    if (response.data.length > 0) {
                        response.data.forEach((element1: any) => {
                            newListDataStatusTransaction.push(element1.rcName);
                        })
                    }
                    let removeSameValue: any[] = Array.from(new Set(newListDataStatusTransaction)); // remove same id

                    let newList: any[] = [];
                    removeSameValue.forEach((element1) => {
                        newList.push({ status: element1, rcCodeList: [] });
                    })
                    newList.map((element1) => {
                        if (response.data.length > 0) {
                            response.data.forEach((element2: any) => {
                                if (element1.status === element2.rcName) {
                                    element1.rcCodeList.push(element2.rcCode);
                                }
                            })
                        }
                    })
                    dispatchGlobal(changeStateReversalTransactionMonitoring({ listReasonCode: newList })); // automatically filter data also to the contents
                    dispatchOptions({ type: 'optionsReasonCode', value: response.data });
                    resolve(response.data);
                }).catch((err: any) => reject(err));
            })
        }
        Promise.all([getMembers(), getReasonCode()]).then(() => {
        }).finally(() => {
            store.dispatch(loadingAction(false));
        })
    }
    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let dateStart = new Date(filterStartDate); let dateEnd = new Date(filterEndDate); let listAllDayChart = [];
        for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) { listAllDayChart.push(d.toISOString().slice(0, 10)) }
        let dateStartBefore = new Date(filterStartDate); let dateEndBefore = new Date(filterEndDate);
        dateStartBefore.setDate(dateStartBefore.getDate() - listAllDayChart.length); dateEndBefore.setDate(dateEndBefore.getDate() - listAllDayChart.length);
        let dayUsedStartBefore = dateStartBefore.toISOString().slice(0, 10); let dayUsedEndBefore = dateEndBefore.toISOString().slice(0, 10);
        let urlReqNow = 'dashboard/saf-monitoring?';
        let urlReqBefore = 'dashboard/saf-monitoring?';
        if (CheckUndefinedStringEmpty(search) !== '') {
            urlReqNow = urlReqNow + `search=${CheckUndefinedStringEmpty(search)}&`;
            urlReqBefore = urlReqBefore + `search=${CheckUndefinedStringEmpty(search)}&`;
        }
        if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
            urlReqNow = urlReqNow + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
            urlReqBefore = urlReqBefore + `startDate=${CheckUndefinedStringEmpty(dayUsedStartBefore)}&`;
        }
        if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
            urlReqNow = urlReqNow + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
            urlReqBefore = urlReqBefore + `endDate=${CheckUndefinedStringEmpty(dayUsedEndBefore)}&`;
        }
        if (CheckUndefinedStringEmpty(sortBy) !== '' && CheckUndefinedStringEmpty(sortType) !== '') {
            urlReqNow = urlReqNow + `sort=${CheckUndefinedStringEmpty(sortBy)}:${CheckUndefinedStringEmpty(sortType)}&`;
            urlReqBefore = urlReqBefore + `sort=${CheckUndefinedStringEmpty(sortBy)}:${CheckUndefinedStringEmpty(sortType)}&`;
        }
        const getDataBefore = () => {
            return new Promise((resolve, reject) => {
                API.get({
                    bodyCustom: null,
                    pathCustom: urlReqBefore.slice(0, -1),
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needNotification: false,
                }).then((response: any) => {
                    dispatchGlobal(changeStateReversalTransactionMonitoring({ dataYesterday: response.data }));
                    resolve(response.data);
                }).catch((err: any) => reject(err));
            })
        }
        const getDataNow = () => {
            return new Promise((resolve, reject) => {
                API.get({
                    bodyCustom: null,
                    pathCustom: urlReqNow.slice(0, -1),
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needNotification: false,
                }).then((response: any) => {
                    dispatchGlobal(changeStateReversalTransactionMonitoring({ dataFilter: response.data })); // automatically filter data also to the contents
                    resolve(response.data);
                }).catch((err: any) => reject(err));
            })
        }
        store.dispatch(loadingAction(true));
        Promise.all([getDataBefore(), getDataNow()]).then(() => {
            dispatchGlobal(changeStateReversalTransactionMonitoring({ rangeDate: listAllDayChart.length }));
        }).finally(() => {
            store.dispatch(loadingAction(false));

        })
    }


    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    useEffect(() => {
        preparationFilter()
    }, [selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedProductId, selectedProductLabel, selectedProductSendToApi])

    useEffect(() => {
        if (filterStartDate !== '' && filterEndDate !== '') initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsReversalTransactionMonitoring, dispatchGlobal)
    }, [])

    useEffect(() => {
        if (filterStartDate !== '' && filterEndDate !== '') initFunctionCustom(true)
    }, [search, filterStartDate, filterEndDate, rangeDate, sortBy, sortType])

    useEffect(() => {
        handleStateComponent('filterStartDate', filterStartDate)
        handleStateComponent('filterEndDate', filterEndDate)
    }, [])

    useEffect(() => {
        dispatchGlobal(changeStateReversalTransactionMonitoring({ 'search': state.search }))
    }, [state.search])


    useEffect(() => {
        let totalPages: number = Math.ceil(dataCopyFilter.length / state.view);
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'changeData', value: { totalPages, currentPage } });
        dispatch({ type: 'data', value: dataCopyFilter });
    }, [dataCopyFilter])

    const exportToCSVComponent = () => ExcelReversalTransactionMonitoring(state, stateOptions, globalState)
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelTransactionMonitoring) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsReversalTransactionMonitoring, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => { }

    const handleNavigation = (item: any) => {
        if (item.fullCreateDate !== null) {
            let dateArray = item.fullCreateDate.toString().split(' ');
            if (dateArray.length > 1) {
                let date = dateArray[0];
                dispatchGlobal(
                    changeStateReversalTransactionMonitoringStatus({
                        safId: item.safId === null || item.safId === undefined ? '' : item.safId, // safId used to search 
                        transId: '',
                        transAuthIntResponseCode: '', // search for status => reason code
                        statusSendToApi: '', // search for status => reason code
                        transStatusName: '', // search for status => reason code
                        transCurrencyCode: '', // search for status => reason code
                        // Product
                        selectedProductId: '',
                        selectedProductLabel: ``,
                        selectedProductSendToApi: ``,
                        // Product Feature

                        selectedProductFeatureId: ``,
                        selectedProductFeatureLabel: ``,
                        selectedProductFeatureSendToApi: ``,
                        // 
                        search: '',
                        filterStartDate: filterStartDate,
                        filterEndDate: filterEndDate,
                        // new
                        safStatus: ``,
                        //

                        issuerId: ``, // issuer
                        acquirerId: ``, // acquirer
                        issuerSwitcherId: ``, // issuerSwitcherId
                        acquirerSwitcherId: ``, // acquirerSwitcherId

                        issuerIdCode: ``, // issuerIdCode
                        acquirerIdCode: ``, // acquirerIdCode
                        issuerSwitcherIdCode: ``, // issuerSwitcherIdCode
                        acquirerSwitcherIdCode: ``, // acquirerSwitcherIdCode
                    })
                );
                navigate(Page4DashboardRoute)
            }
        }

    };

    const customButtonAction = (item: ModelTransactionMonitoring, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                <ButtonNavigation handleClick={() => handleNavigation(item)} codeAccessPrivilege='' />
            </div>
        );
    };

    const filterComponentPage = () => {
        return (
            <></>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableReversalTransactionMonitoring
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                handleGlobalState={handleGlobalState}
                stateGlobal={globalState}
                handleNavigation={handleNavigation}
            />
        )
    }

    const modalAddDeleteComponentPage = () => {
        return (
            <></>
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
            </>
        )
    }


    return (
        <>
            <h1 className='Lato-300 normal w-600px s-2024 py-2'>{state.titlePage}</h1>
            <FilterComponentRTM initFunctionCustom={initFunctionCustom} handleStateParent={handleStateComponent} stateParent={state} />
            <DetailStatusTransactionRTM stateOptions={stateOptions} data={dataCopyFilter} />
            <PieChartComponentRTM stateOptions={stateOptions} />
            <LineChartComponentRTM stateParent={state} initFunctionCustom={initFunctionCustom} handleStateParent={handleStateComponent} stateOptions={stateOptions} />

            <InsideComponentPage
                state={state}
                stateOptions={stateOptions}
                globalState={globalState}

                handleGlobalState={handleGlobalState}

                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={false}
                needSearchFunction={true}
                needDownloadButton={true}
                //
                needComponentSort={false}

            />
        </>
    )
}


const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState = {
    titlePage: Page3Title,
    listReasonCode: [], // status transaction
    data: [],
    privilegeAccess: { view: '471', add: '', edit: '', delete: '', download: '' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Issuer', width: 'wd-150', val: 'issuerName' },
        { field: 'Issuer Switcher', width: 'wd-150', val: 'issuerSwitcherName' },
        { field: 'Acquirer Switcher', width: 'wd-150', val: 'acquirerSwitcherName' },
        { field: 'Acquirer', width: 'wd-150', val: 'acquirerName' },
        { field: 'Total Amount', width: 'wd-150', val: 'transAmount' },
        { field: 'Retry Count', width: 'wd-150', val: 'transCount' },
        { field: 'SAF Status', width: 'wd-150', val: 'safStatus' },
        { field: 'Last Transaction Time', width: 'wd-200', val: 'safCreateDate' },
        // { field: 'Action', width: 'wd-100', val: 'action' },
    ],
    toggle: ['no', 'issuerName', 'issuerSwitcherName', 'acquirerSwitcherName', 'acquirerName', 'transAmount', 'transCount', 'safStatus', 'safCreateDate'],
    complete_toggle: ['no', 'issuerName', 'issuerSwitcherName', 'acquirerSwitcherName', 'acquirerName', 'transAmount', 'transCount', 'safStatus', 'safCreateDate'],
    list_column_name: ['No.', 'Issuer', 'Issuer Switcher', 'Acquirer Switcher', 'Acquirer', 'Total Amount', 'Retry Count', 'SAF Status', 'Last Transaction Time'],
    //
    deleteItem: false,
    dataModal: [],
    SearchByPlaceHolder: 'Issuer, Issuer Switcher, Acquirer Switcher, Acquirer, Currency Code',
    //
    id: '',
    search: '',
    view: 15,
    position: 15,
    list_view: [15, 20, 25, 50, 75, 100, 500],
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    // 
    widthTable: 1500,
    //
    transDivided: [],
    filterStartDate: '',
    filterEndDate: '',
    //

};

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'new_exchange_rate':
            return {
                ...state,
                new_exchange_rate: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'dataRouting':
            return {
                ...state,
                dataRouting: action.value
            };
        case 'dataSubProduct':
            return {
                ...state,
                dataSubProduct: action.value
            };
        case 'dataProductFeature':
            return {
                ...state,
                dataProductFeature: action.value
            };
        case 'routeIdOptions':
            return {
                ...state,
                routeIdOptions: action.value
            };
        case 'subProductOptions':
            return {
                ...state,
                subProductOptions: action.value
            };
        case 'productFeatureOptions':
            return {
                ...state,
                productFeatureOptions: action.value
            };
        case 'optionsSpf':
            return {
                ...state,
                optionsSpf: action.value
            };
        case 'optionsCommonStatus':
            return {
                ...state,
                optionsCommonStatus: action.value
            };
        case 'optionsMember':
            return {
                ...state,
                optionsMember: action.value
            };
        case 'optionsRouting':
            return {
                ...state,
                optionsRouting: action.value
            };
        case 'optionsQrMpmCpm':
            return {
                ...state,
                optionsQrMpmCpm: action.value
            };
        case 'optionsPrivilege':
            return {
                ...state,
                optionsPrivilege: action.value
            };
        case 'optionsUserStatus':
            return {
                ...state,
                optionsUserStatus: action.value
            };
        case 'optionsUserLevel':
            return {
                ...state,
                optionsUserLevel: action.value
            };
        case 'optionsUserCategory':
            return {
                ...state,
                optionsUserCategory: action.value
            };
        case
            'optionsCurrency':
            return {
                ...state,
                optionsCurrency: action.value
            };
        case
            'optionsReasonCode':
            return {
                ...state,
                optionsReasonCode: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'selectedMember':
            return {
                ...state,
                selectedMember: action.value
            };
        case 'selectedUserLevel':
            return {
                ...state,
                selectedUserLevel: action.value
            };
        case 'selectedUserCategory':
            return {
                ...state,
                selectedUserCategory: action.value
            };
        case 'selectedRateFrom':
            return {
                ...state,
                selectedRateFrom: action.value
            };
        case 'selectedRateTo':
            return {
                ...state,
                selectedRateTo: action.value
            };

        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
            };
        case 'editData':
            return {
                ...state,
            };
        case 'deleteData':
            return {
                ...state,
            };
        case 'searchData':
            return {
                ...state,
                data: action.value,
                position: 15,
            };
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        case 'listReasonCode':
            return {
                ...state,
                listReasonCode: action.value
            };
        //
        case 'transDivided':
            return {
                ...state,
                transDivided: action.value
            };
        case 'filterStartDate':
            return {
                ...state,
                filterStartDate: action.value
            };
        case 'filterEndDate':
            return {
                ...state,
                filterEndDate: action.value
            };
        default:
            throw new Error();
    }
}

import { createSlice } from "@reduxjs/toolkit";


export const errorSlice = createSlice({
    name: "error",
    initialState: {
        condition: false,
        message : '' ,
    },
    reducers: {
        errorAction: (state, action) => {
            state.condition = action.payload
        },
        messageAction :(state, action) => {
            state.message = action.payload
        },
        doubleAction: (state, action) => {
            state.message = action.payload.message
            state.condition = action.payload.condition
        },
    }
});

export const { errorAction, messageAction,  doubleAction} = errorSlice.actions;
export const stateUser = (state: any) => state.error.value;
export default errorSlice.reducer;

import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelRouteSequence } from './ModelRouteSequence';

import { loadingAction } from '../../Store/Loading';
import { Page54Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';

export const ModalDraggableRouteSequence = ({ ...props }) => {
      const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsCommonStatusDetailCode, optionsRouting, optionsRouteSequence } = props.stateOptions

    

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'rsName') {
            if (valueState.split('').length < 33) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'rsReqSeqVal') {
            if (parseInt(valueState) > 0) {
                if (valueState.split('').length < 3) dispatchStatePage({ type: nameState, value: valueState })
            }
        } else if (nameState === 'rsResSeqVal') {
            if (parseInt(valueState) > 0) {
                if (valueState.split('').length < 3) dispatchStatePage({ type: nameState, value: valueState })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const { rsReqId, rsResId, rsReqSeqVal, rsResSeqVal, rsName, rsDesc, rsStatus, rsNextRsId } = statePage.data

        const data = {
            rsId: id,
            rsReqId: rsReqId, // o
            rsReqSeqVal: rsReqId === '' || rsReqId === null ? null : rsReqSeqVal, // o or m
            rsResId: rsResId, // o
            rsResSeqVal: rsResId === '' || rsResId === null ? null : rsResSeqVal, // o or m
            rsName: rsName, // m
            rsDesc: rsDesc, // o
            rsStatus: rsStatus, // mendatory
            rsNextRsId: rsNextRsId, // o
        };

        let a = '';
        let condition = [rsName, rsStatus];
        let conditionString = ['Name', 'Status'];
        if (rsReqId !== null) {
            condition.push(rsReqId);
            conditionString.push('Req RID')
            condition.push(rsReqSeqVal);
            conditionString.push('Req RID Sequence')
        }
        if (rsResId !== null) {
            condition.push(rsResId);
            conditionString.push('Res RID')
            condition.push(rsResSeqVal);
            conditionString.push('Res RID Sequence')
        }
        a = CheckAllFields(condition, conditionString);

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                 
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                   
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };
    let newOptionsRouting = [];
    newOptionsRouting.push({ value: null, label: 'null' })
    if (optionsRouting.length > 0) {
        optionsRouting.forEach((element1: any) => {
            newOptionsRouting.push({ value: element1.routeId, label: element1.routeName === null ? 'Route Name is unknown' : element1.routeName });
        })
    }
    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>

                    {id !== '' ?
                        <div className='col-6'>
                            <p className={labelStyle}>Route Sequence ID</p>
                            <input value={id} disabled className={inputStyleReadOnly} />
                        </div>
                        :
                        <></>
                    }

                    <div className='col-6'>
                        <p className={labelStyle}>NAME</p>
                        <input value={statePage.data.rsName} onChange={(e) => handleState('rsName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Req RID</p>
                        <Select placeholder={'(Search)'} options={newOptionsRouting} value={newOptionsRouting.filter((option: any) => option.value === statePage.data.rsReqId)} onChange={(e) => handleState('rsReqId', e?.value)} />
                    </div>

                    {statePage.data.rsReqId === null || statePage.data.rsReqId === '' ?
                        <div className='col-6'>
                            <p className={labelStyle}>Req RID Sequence</p>
                            <input type={'number'} min={0} disabled value={statePage.data.rsReqSeqVal} className={inputStyleReadOnly} />
                        </div>
                        :
                        <div className='col-6'>
                            <p className={labelStyle}>Req RID Sequence</p>
                            <input type={'number'} min={0} value={statePage.data.rsReqSeqVal} onChange={(e) => handleState('rsReqSeqVal', e.target.value)} className={inputStyle} />
                        </div>
                    }

                    <div className='col-6'>
                        <p className={labelStyle}>Res RID</p>
                        <Select placeholder={'(Search)'} options={newOptionsRouting} value={newOptionsRouting.filter((option: any) => option.value === statePage.data.rsResId)} onChange={(e) => handleState('rsResId', e?.value)} />
                    </div>

                    {statePage.data.rsResId === null || statePage.data.rsResId === '' ?
                        <div className='col-6'>
                            <p className={labelStyle}>Res RID Sequence</p>
                            <input type={'number'} min={0} disabled value={statePage.data.rsResSeqVal} className={inputStyleReadOnly} />
                        </div>
                        :
                        <div className='col-6'>
                            <p className={labelStyle}>Res RID Sequence</p>
                            <input type={'number'} min={0} value={statePage.data.rsResSeqVal} onChange={(e) => handleState('rsResSeqVal', e.target.value)} className={inputStyle} />
                        </div>
                    }

                    <div className='col-6'>
                        <p className={labelStyle}>Next Sequence</p>
                        <Select placeholder={'(Search)'} options={optionsRouteSequence} value={optionsRouteSequence.filter((option: any) => option.value === statePage.data.rsNextRsId)} onChange={(e) => handleState('rsNextRsId', e?.value)} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>Status</p>
                        <Select placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.data.rsStatus)} onChange={(e) => handleState('rsStatus', `${e.value}`)} />
                    </div>
                    <div className="col-12">
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.data.rsDesc} onChange={(e) => handleState("rsDesc", e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page54Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelRouteSequence({
        id: null,
        rsId: null,
        rsReqId: null,
        rsResId: null,
        rsReqSeqVal: null,
        rsResSeqVal: null,
        rsName: null,
        rsDesc: null,
        rsStatus: '001',
        rsNextRsId: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelRouteSequence) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelRouteSequence({
                    id: null,
                    rsId: null,
                    rsReqId: null,
                    rsResId: null,
                    rsReqSeqVal: null,
                    rsResSeqVal: null,
                    rsName: null,
                    rsDesc: null,
                    rsStatus: '001',
                    rsNextRsId: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelRouteSequence({
                    id: action.value.rsId,
                    rsId: action.value.rsId,
                    rsReqId: action.value.rsReqId,
                    rsResId: action.value.rsResId,
                    rsReqSeqVal: action.value.rsReqSeqVal,
                    rsResSeqVal: action.value.rsResSeqVal,
                    rsName: action.value.rsName,
                    rsDesc: action.value.rsDesc,
                    rsStatus: action.value.rsStatus,
                    rsNextRsId: action.value.rsNextRsId,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





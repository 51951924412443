import { useSelector } from "react-redux";
import { iconDownloadCustom } from "../Assets/Images/svg";
import { Tooltip } from "antd";
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const ButtonDownload = ({ ...props }) => {
    const { handleClick, codeAccessPrivilege,isButtonDisabled } = props;
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    if (codeAccessPrivilege === "") {
        return (
            <Button
                onClick={handleClick}
                startIcon={<FileDownloadIcon style={{ width: '18px', height: '18px', marginRight: '-6px' }} />}
                className="h-25px hover-button-red"
                sx={{
                    color: '#f15e5e'
                }}
                disabled={isButtonDisabled}
            >
                <p className="s-1418">Download</p>
            </Button>
        );
    } else if (privilegeAccess !== null) {
        if (privilegeAccess.includes(codeAccessPrivilege)) {
            return (
                <Button
                    onClick={handleClick}
                    startIcon={<FileDownloadIcon style={{ width: '18px', height: '18px', marginRight: '-6px' }} />}
                    className="h-25px hover-button-red"
                    sx={{
                        color: '#f15e5e'
                    }}
                    disabled={isButtonDisabled}
                >
                    <p className="s-1418">Download</p>
                </Button>
            );
        } else {
            return <></>
        }
    } else {
        return <></>;
    }
};


import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import { format } from "date-fns";
import Select from 'react-select';
import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete, DatePickerCalendarIconOnly } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { Page46Title } from '../../State/Menu/TitleMenu';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalReasonCodeMapping = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMember, optionsReasonCodeUseId } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        const { rcmApplyDate, rcmEndDate } = statePage;

        if (nameState === "rcmApplyDate") {
            let dateStart = new Date(valueState);
            let dateEnd = new Date(rcmEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }

            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: "Start date cannot be larger than End Date" })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else if (nameState === "rcmEndDate") {
            let dateStart = new Date(rcmApplyDate);
            let dateEnd = new Date(valueState);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: "Start date cannot be larger than End Date" })

            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { rcmId, rcId, memberId, rcmMemberReasonCode, rcmMemberReasonName, rcmApplyDate, rcmEndDate } = statePage;
        const data = {
            rcId: `${rcId}`,
            memberId: `${memberId}`,
            rcmMemberReasonCode: `${rcmMemberReasonCode}`,
            rcmMemberReasonName: `${rcmMemberReasonName}`,
            rcmApplyDate: `${rcmApplyDate}`,
            rcmEndDate: `${rcmEndDate}`,
        };

        const a = CheckAllFields([rcId, memberId, rcmMemberReasonCode, rcmMemberReasonName, rcmApplyDate, rcmEndDate], ["Internal Reason Code", "Member  ID", "Member Reason Code", "Member Reason Code Name", "Start Date", "End Date"]);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className="col-6">
                                <p className={labelStyle}>member ID</p>
                                <Select
                                    styles={inputStyleH25}
                                    options={optionsMember}
                                    value={optionsMember.filter((option: any) => option.value === statePage.memberId)}
                                    onChange={(e) => handleState("memberId", e.value)}
                                />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>internal reason code</p>
                                <Select
                                    styles={inputStyleH25}
                                    options={optionsReasonCodeUseId}
                                    value={optionsReasonCodeUseId.filter((option: any) => option.value === `${statePage.rcId}`)}
                                    onChange={(e) => handleState("rcId", e.value)}
                                />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>member reason code</p>
                                <input value={statePage.rcmMemberReasonCode} onChange={(e) => handleState("rcmMemberReasonCode", e.target.value)} className={inputStyle} />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>member reason code name</p>
                                <input value={statePage.rcmMemberReasonName} onChange={(e) => handleState("rcmMemberReasonName", e.target.value)} className={inputStyle} />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>start date</p>
                                <div>
                                    <div className="d-flex">
                                        <DatePicker
                                            selected={statePage.rcmApplyDate}
                                            className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                            wrapperClassName="w-100 h-100"
                                            popperClassName="react-datepicker-popper-transform-up-right"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown
                                            showPopperArrow={false}
                                            onChange={(date: any) => handleState('rcmApplyDate', format(date, 'yyyy-MM-dd'))}
                                        />
                                    </div>
                                    <DatePickerCalendarIconOnly />
                                </div>
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>end date</p>
                                <div>
                                    <div className="d-flex">
                                        <DatePicker
                                            selected={statePage.rcmEndDate}
                                            className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                            wrapperClassName="w-100 h-100"
                                            popperClassName="react-datepicker-popper-transform-up-right"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown
                                            showPopperArrow={false}
                                            onChange={(date: any) => handleState("rcmEndDate", format(date, 'yyyy-MM-dd'))}
                                        />
                                    </div>
                                    <DatePickerCalendarIconOnly />
                                </div>
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />

                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page46Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    rcmId: "",
    rcId: "",
    rcCode: '',
    memberId: "",
    rcmMemberReasonCode: "",
    rcmMemberReasonName: "",
    rcmApplyDate: new Date().toISOString().slice(0, 10),
    rcmEndDate: new Date("2099-12-31").toISOString().slice(0, 10),
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                rcmId: "",
                rcId: "",
                rcCode: '',
                memberId: "",
                rcmMemberReasonCode: "",
                rcmMemberReasonName: "",
                rcmApplyDate: new Date().toISOString().slice(0, 10),
                rcmEndDate: new Date("2099-12-31").toISOString().slice(0, 10),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                rcmId: action.value.rcmId,
                rcId: action.value.rcId,
                rcCode: action.value.rcCode,
                memberId: action.value.memberId,
                rcmMemberReasonCode: action.value.rcmMemberReasonCode,
                rcmMemberReasonName: action.value.rcmMemberReasonName,
                rcmApplyDate: action.value.rcmApplyDate,
                rcmEndDate: action.value.rcmEndDate,
                errorMessage: "",
            };
        // 
        case 'rcmId':
            return {
                ...state,
                rcmId: action.value,
            };
        case 'rcId':
            return {
                ...state,
                rcId: action.value,
            };
        case 'rcCode':
            return {
                ...state,
                rcCode: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'rcmMemberReasonCode':
            return {
                ...state,
                rcmMemberReasonCode: action.value,
            };
        case 'rcmMemberReasonName':
            return {
                ...state,
                rcmMemberReasonName: action.value,
            };
        case 'rcmApplyDate':
            return {
                ...state,
                rcmApplyDate: action.value,
            };
        case 'rcmEndDate':
            return {
                ...state,
                rcmEndDate: action.value,
            };
        default:
            throw new Error();
    }
}

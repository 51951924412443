import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';
import { Page16Title } from '../../State/Menu/TitleMenu';
import { inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';


export const ModalDraggableExchangeRateSetting = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsCommonStatusDetailCode, optionsCurrencyCcdName } = props.stateOptions

    const draggleRef = React.createRef<any>();


    useEffect(() => {

        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const updateDataAPI = () => {
        const { rateFrom, rateTo, rateValue, rateStatus, rateStartDate, rateEndDate } = statePage
        const data = {
            rateId: id,
            rateFrom: `${rateFrom}`,
            rateTo: `${rateTo}`,
            rateValue: `${rateValue}`,
            rateStatus: `${rateStatus}`,
            rateStartDate: `${rateStartDate}`,
            rateEndDate: `${rateEndDate}`,
            rateSrcName: "MANUAL",
            rateSrcMethod: "DASHBOARD",
        }
        const a = CheckAllFields([rateFrom, rateTo, rateValue, rateStatus], ["From", "To", "Rate", "Status"])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {

            API.update({
                bodyCustom: data,

                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={600}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                top: '10vw',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                    <hr className='c-7f7'/>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column'>
                <div className='row pb-2 mx-0 mb-3'>
                    <div className={'col-6 mb-2'}>
                        <p className={labelStyle}>FROM</p>
                        <Select styles={inputStyleH25} options={optionsCurrencyCcdName} value={optionsCurrencyCcdName.filter((option: any) => option.value === statePage.rateFrom)} onChange={(e: any) => handleState("rateFrom", e.value)} />
                    </div>
                    <div className={'col-6 mb-2'}>
                        <p className={labelStyle}>To</p>
                        <Select styles={inputStyleH25} options={optionsCurrencyCcdName} value={optionsCurrencyCcdName.filter((option: any) => option.value === statePage.rateTo)} onChange={(e: any) => handleState("rateTo", e.value)} />
                    </div>
                    <div className="col-6 mb-2">
                        <p className={labelStyle}>RATE</p>
                        <input type="number" min={0} value={statePage.rateValue} onChange={(e) => handleState("rateValue", e.target.value)} className={inputStyle} />
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>Status</p>
                        <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.rateStatus)} onChange={(e) => handleState("rateStatus", e.value)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page16Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    //
    rateFrom: "",
    rateTo: "",
    rateValue: "",
    rateStatus: "",
    rateStartDate: "",
    rateEndDate: "",
    rateToCurr: "",
    rateSrcName: "",
    rateSrcMethod: "",
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                rateFrom: "",
                rateTo: "",
                rateValue: "",
                rateStatus: "",
                rateStartDate: "",
                rateEndDate: "",
                rateToCurr: "",
                rateSrcName: "",
                rateSrcMethod: "",
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                rateFrom: action.value.rateFrom,
                rateTo: action.value.rateTo,
                rateValue: action.value.rateValue,
                rateStatus: action.value.rateStatus,
                rateStartDate: action.value.rateStartDate,
                rateEndDate: action.value.rateEndDate,
                rateToCurr: action.value.rateToCurr,
                rateSrcName: action.value.rateSrcName,
                rateSrcMethod: action.value.rateSrcMethod,
                errorMessage: '',
            };
        // 
        case 'rateFrom':
            return {
                ...state,
                rateFrom: action.value,
            };
        case 'rateTo':
            return {
                ...state,
                rateTo: action.value,
            };
        case 'rateValue':
            return {
                ...state,
                rateValue: action.value,
            };
        case 'rateStatus':
            return {
                ...state,
                rateStatus: action.value,
            };
        case 'rateStartDate':
            return {
                ...state,
                rateStartDate: action.value,
            };
        case 'rateEndDate':
            return {
                ...state,
                rateEndDate: action.value,
            };
        case 'rateToCurr':
            return {
                ...state,
                rateToCurr: action.value,
            };
        case 'rateSrcName':
            return {
                ...state,
                rateSrcName: action.value,
            };
        case 'rateSrcMethod':
            return {
                ...state,
                rateSrcMethod: action.value,
            };
        // 
        default:
            throw new Error();
    }
}


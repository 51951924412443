import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// export const changeStartDateAction = createAsyncThunk(
//   "transactionMonitoring/changeStartDateAction",
//   async (filterStartDate: string, thunkAPI) => {
//     try {
//       // const response = await fetch("https://api.example.com/data");
//       // const data = await response.json();
//       // return data;
//       return filterStartDate;
//     } catch (error) {
//       throw new Error("Failed to fetch data");
//     }
//   });

interface mainDashboardInterface {
    // period now
    dataPeriodNowTransactionList: any[],
    dataPeriodNowSummary: any[],
    dataPeriodNowAcquirer: any[],
    dataPeriodNowAcquirerDeclined: any[],
    dataPeriodNowIssuer: any[],
    dataPeriodNowIssuerDeclined: any[],
    dataPeriodNowAcquirerIssuer: any[],
    dataPeriodNowAcquirerIssuerDeclined: any[],
    dataPeriodNowTotalAcquirer: any[],
    dataPeriodNowTotalIssuer: any[],
    dataPeriodNowTotal: any[],
    dataPeriodNowTotalCount: number | string,
    dataPeriodNowApprovedCount: number | string,
    dataPeriodNowPreDeclinedCount: number | string,
    dataPeriodNowDeclinedCount: number | string,
    dataPeriodNowTimeoutCount: number | string,

    // period previous
    dataPeriodPrevSummary: any[],
    dataPeriodPrevTotalCount: number | string,
    dataPeriodPrevApprovedCount: number | string,
    dataPeriodPrevPreDeclinedCount: number | string,
    dataPeriodPrevDeclinedCount: number | string,
    dataPeriodPrevTimeoutCount: number | string,

    // chart
    dataInbound: any[],
    dataOutbound: any[],
    dataApi: any[],
    dataTimeout: any[],
    
    // filter
    filterStartDate: string,
    filterEndDate: string,
    selectedCountryId: number | string,
    selectedCountryLabel: string,
    
    search: string,
    rangeDate: number | string,
    dataAcquirerSwitcherOptions: any[],
    dataIssuerSwitcherOptions: any[],
    selectedAcquirerSwitcherId: number | string,
    selectedAcquirerSwitcherLabel: string,
    selectedIssuerSwitcherId: number | string,
    selectedIssuerSwitcherLabel: string,
}


function initialState(): mainDashboardInterface | any {
    let dateStart = new Date();
    dateStart.setDate(dateStart.getDate() - 30);
    let dateStartUsed = dateStart.toISOString().slice(0, 10);
    return {
        // period now
        dataPeriodNowTransactionList: [],
        dataPeriodNowSummary: [],
        dataPeriodNowSummaryDeclined: [],
        dataPeriodNowAcquirer: [],
        dataPeriodNowAcquirerDeclined: [],
        dataPeriodNowIssuer: [],
        dataPeriodNowIssuerDeclined: [],
        dataPeriodNowAcquirerIssuer: [],
        dataPeriodNowAcquirerIssuerDeclined: [],
        dataPeriodNowTotalAcquirer: [],
        dataPeriodNowTotalIssuer: [],
        dataPeriodNowTotal: [],
        dataPeriodNowTotalCount: 0,
        dataPeriodNowApprovedCount: 0,
        dataPeriodNowPreDeclinedCount: 0,
        dataPeriodNowDeclinedCount: 0,
        dataPeriodNowTimeoutCount: 0,

        // period previous
        dataPeriodPrevSummary: [],
        dataPeriodPrevSummaryDeclined: [],
        dataPeriodPrevTotalCount: 0,
        dataPeriodPrevApprovedCount: 0,
        dataPeriodPrevPreDeclinedCount: 0,
        dataPeriodPrevDeclinedCount: 0,
        dataPeriodPrevTimeoutCount: 0,

        // chart
        dataInbound: [],
        dataOutbound: [],
        dataApi: [],
        dataTimeout: [],
        
        // filter
        filterStartDate: new Date().toISOString().slice(0, 10),
        filterEndDate: new Date().toISOString().slice(0, 10),
        selectedCountryId: "410",
        selectedCountryLabel: "Korea",
        
        search: "",
        rangeDate: 1,
        dataAcquirerSwitcherOptions: [],
        dataIssuerSwitcherOptions: [],
        selectedAcquirerSwitcherId: "",
        selectedAcquirerSwitcherLabel: "",
        selectedIssuerSwitcherId: "",
        selectedIssuerSwitcherLabel: "",
    }
}

export const mainDashboardSlice = createSlice({
    name: "mainDashboard",
    initialState: initialState(),
    reducers: {
        handleStateAction: (state, action) => {
            for (const key in state as mainDashboardInterface) {
                if (key === action.payload.name) {
                    let nameState: string = action.payload.name
                    state[nameState] = action.payload.value
                }
            }
        },
        changeStateMainDashboard: (state, action) => {
            state.dataPeriodNowTransactionList = action.payload.dataPeriodNowTransactionList === undefined      || null ? state.dataPeriodNowTransactionList : action.payload.dataPeriodNowTransactionList;
            state.dataPeriodNowSummary = action.payload.dataPeriodNowSummary === undefined                      || null ? state.dataPeriodNowSummary : action.payload.dataPeriodNowSummary;
            state.dataPeriodNowSummaryDeclined = action.payload.dataPeriodNowSummaryDeclined === undefined      || null ? state.dataPeriodNowSummaryDeclined : action.payload.dataPeriodNowSummaryDeclined;
            state.dataPeriodNowAcquirer = action.payload.dataPeriodNowAcquirer === undefined                    || null ? state.dataPeriodNowAcquirer : action.payload.dataPeriodNowAcquirer;
            state.dataPeriodNowAcquirerDeclined = action.payload.dataPeriodNowAcquirerDeclined === undefined    || null ? state.dataPeriodNowAcquirerDeclined : action.payload.dataPeriodNowAcquirerDeclined;
            state.dataPeriodNowIssuer = action.payload.dataPeriodNowIssuer === undefined                        || null ? state.dataPeriodNowIssuer : action.payload.dataPeriodNowIssuer;
            state.dataPeriodNowIssuerDeclined = action.payload.dataPeriodNowIssuerDeclined === undefined        || null ? state.dataPeriodNowIssuerDeclined : action.payload.dataPeriodNowIssuerDeclined;
            state.dataPeriodNowAcquirerIssuer = action.payload.dataPeriodNowAcquirerIssuer === undefined        || null ? state.dataPeriodNowAcquirerIssuer : action.payload.dataPeriodNowAcquirerIssuer;
            state.dataPeriodNowAcquirerIssuerDeclined = action.payload.dataPeriodNowAcquirerIssuerDeclined === undefined    || null ? state.dataPeriodNowAcquirerIssuerDeclined : action.payload.dataPeriodNowAcquirerIssuerDeclined;
            state.dataPeriodNowTotalAcquirer = action.payload.dataPeriodNowTotalAcquirer === undefined          || null ? state.dataPeriodNowTotalAcquirer : action.payload.dataPeriodNowTotalAcquirer;
            state.dataPeriodNowTotalIssuer = action.payload.dataPeriodNowTotalIssuer === undefined              || null ? state.dataPeriodNowTotalIssuer : action.payload.dataPeriodNowTotalIssuer;
            state.dataPeriodNowTotal = action.payload.dataPeriodNowTotal === undefined                          || null ? state.dataPeriodNowTotal : action.payload.dataPeriodNowTotal;
            state.dataPeriodNowTotalCount = action.payload.dataPeriodNowTotalCount === undefined                || null ? state.dataPeriodNowTotalCount : action.payload.dataPeriodNowTotalCount;
            state.dataPeriodNowApprovedCount = action.payload.dataPeriodNowApprovedCount === undefined          || null ? state.dataPeriodNowApprovedCount : action.payload.dataPeriodNowApprovedCount;
            state.dataPeriodNowPreDeclinedCount = action.payload.dataPeriodNowPreDeclinedCount === undefined    || null ? state.dataPeriodNowPreDeclinedCount : action.payload.dataPeriodNowPreDeclinedCount;
            state.dataPeriodNowDeclinedCount = action.payload.dataPeriodNowDeclinedCount === undefined          || null ? state.dataPeriodNowDeclinedCount : action.payload.dataPeriodNowDeclinedCount;
            state.dataPeriodNowTimeoutCount = action.payload.dataPeriodNowTimeoutCount === undefined            || null ? state.dataPeriodNowTimeoutCount : action.payload.dataPeriodNowTimeoutCount;

            state.dataPeriodPrevSummary = action.payload.dataPeriodPrevSummary === undefined                    || null ? state.dataPeriodPrevSummary : action.payload.dataPeriodPrevSummary;
            state.dataPeriodPrevSummaryDeclined = action.payload.dataPeriodPrevSummaryDeclined === undefined    || null ? state.dataPeriodPrevSummaryDeclined : action.payload.dataPeriodPrevSummaryDeclined;
            state.dataPeriodPrevTotalCount = action.payload.dataPeriodPrevTotalCount === undefined              || null ? state.dataPeriodPrevTotalCount : action.payload.dataPeriodPrevTotalCount;
            state.dataPeriodPrevApprovedCount = action.payload.dataPeriodPrevApprovedCount === undefined        || null ? state.dataPeriodPrevApprovedCount : action.payload.dataPeriodPrevApprovedCount;
            state.dataPeriodPrevPreDeclinedCount = action.payload.dataPeriodPrevPreDeclinedCount === undefined  || null ? state.dataPeriodPrevPreDeclinedCount : action.payload.dataPeriodPrevPreDeclinedCount;
            state.dataPeriodPrevDeclinedCount = action.payload.dataPeriodPrevDeclinedCount === undefined        || null ? state.dataPeriodPrevDeclinedCount : action.payload.dataPeriodPrevDeclinedCount;
            state.dataPeriodPrevTimeoutCount = action.payload.dataPeriodPrevTimeoutCount === undefined          || null ? state.dataPeriodPrevTimeoutCount : action.payload.dataPeriodPrevTimeoutCount;
            
            state.dataInbound = action.payload.dataInbound === undefined                                        || null ? state.dataInbound : action.payload.dataInbound;
            state.dataOutbound = action.payload.dataOutbound === undefined                                      || null ? state.dataOutbound : action.payload.dataOutbound;
            state.dataApi = action.payload.dataApi === undefined                                                || null ? state.dataApi : action.payload.dataApi;
            state.dataTimeout = action.payload.dataTimeout === undefined                                        || null ? state.dataTimeout : action.payload.dataTimeout;
            
            state.filterStartDate = action.payload.filterStartDate === undefined                                || null ? state.filterStartDate : action.payload.filterStartDate;
            state.filterEndDate = action.payload.filterEndDate === undefined                                    || null ? state.filterEndDate : action.payload.filterEndDate;
            state.selectedCountryId = action.payload.selectedCountryId === undefined                            || null ? state.selectedCountryId : action.payload.selectedCountryId;
            state.selectedCountryLabel = action.payload.selectedCountryLabel === undefined                      || null ? state.selectedCountryLabel : action.payload.selectedCountryLabel;
            
            state.search = action.payload.search === undefined                                                  || null ? state.search : action.payload.search;
            state.rangeDate = action.payload.rangeDate === undefined                                            || null ? state.rangeDate : action.payload.rangeDate;
            state.dataAcquirerSwitcherOptions = action.payload.dataAcquirerSwitcherOptions === undefined        || null ? state.dataAcquirerSwitcherOptions : action.payload.dataAcquirerSwitcherOptions;
            state.dataIssuerSwitcherOptions = action.payload.dataIssuerSwitcherOptions === undefined            || null ? state.dataIssuerSwitcherOptions : action.payload.dataIssuerSwitcherOptions;
            state.selectedAcquirerSwitcherId = action.payload.selectedAcquirerSwitcherId === undefined          || null ? state.selectedAcquirerSwitcherId : action.payload.selectedAcquirerSwitcherId;
            state.selectedAcquirerSwitcherLabel = action.payload.selectedAcquirerSwitcherLabel === undefined    || null ? state.selectedAcquirerSwitcherLabel : action.payload.selectedAcquirerSwitcherLabel;
            state.selectedIssuerSwitcherId = action.payload.selectedIssuerSwitcherId === undefined              || null ? state.selectedIssuerSwitcherId : action.payload.selectedIssuerSwitcherId;
            state.selectedIssuerSwitcherLabel = action.payload.selectedIssuerSwitcherLabel === undefined        || null ? state.selectedIssuerSwitcherLabel : action.payload.selectedIssuerSwitcherLabel;
        }
    },
});

export const { changeStateMainDashboard, handleStateAction } = mainDashboardSlice.actions;
export const stateGlobalMainDashboard = (state: any) => state.mainDashboard;
export default mainDashboardSlice.reducer;

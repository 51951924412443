import { codeManagementIcon } from "../../../Assets/Images/MenuIcon";
import { Page1CodeRoute, Page2CodeRoute, Page3CodeRoute, Page4CodeRoute } from "../../../Routes";
import { Page44Code, Page45Code, Page46Code, Page47Code } from "../CodeMenu";
import { Page44Title, Page45Title, Page46Title, Page47Title } from "../TitleMenu";

export const Menu13 = {
    id: 'code_sidebar',
    label: 'Code',
    icon: codeManagementIcon,
    privilegesId: [
        Page44Code,
        Page45Code,
        Page46Code,
        Page47Code,
    ],
    itemSidebar: [
        {
            titleList: Page44Title,
            key: 'codeOptions1',
            privilegeId: Page44Code,
            url: Page1CodeRoute,
        },
        {
            titleList: Page45Title,
            key: 'codeOptions2',
            privilegeId: Page45Code,
            url: Page2CodeRoute,
        },
        {
            titleList: Page46Title,
            key: 'codeOptions3',
            privilegeId: Page46Code,
            url: Page3CodeRoute,
        },
        {
            titleList: Page47Title,
            key: 'codeOptions4',
            privilegeId: Page47Code,
            url: Page4CodeRoute,
        },
    ],
}
import { ButtonNavigation, InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { CheckUndefined, CheckUndefinedStringEmpty } from '../../Utils';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { changeStateQRAuthSimulatorInquiry, resetAllActionPage6, stateGlobalQRAuthSimulatorInquiry } from '../../Store/QRAuthSimulatorInquirySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API } from '../../Services';
import { Page2DashboardRoute } from '../../Routes';
import { urlSelection } from '../../Services/API';
import { InterfaceOptionsQRAuthSimulatorInquiry } from './Utils/InterfaceOptionsQRAuthSimulatorInquiry';
import { ModelQRAuthSimulatorInquiry } from './Utils/ModelQRAuthSimulatorInquiry';
import { TableQRAuthSimulatorInquiry } from './Components/TableQRAuthSimulatorInquiry';
import { FilterComponentQRAuthSimulatorInquiry } from './Components/FilterComponentQRAuthSimulatorInquiry';
import { ModalQRAuthSimulatorInquiry } from './ModalQRAuthSimulatorInquiry';
import { ShowModalInterface } from '../../Interface';
import { DesktopViewQRAuthSimulatorInquiry } from './Components/DesktopViewQRAuthSimulatorInquiry';
import { MobileViewQRAuthSimulatorInquiry } from './Components/MobileViewQRAuthSimulatorInquiry';

export const QRAuthSimulatorInquiry = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const globalState = useSelector(stateGlobalQRAuthSimulatorInquiry);

    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });


    //     const location = useLocation();
    //   const searchParams = new URLSearchParams(location.search);
    //   const qrRrnValue = searchParams.get('qrRrn');

    //   useEffect(() => {
    //     if (qrRrnValue !== undefined) {
    //       if (qrRrnValue !== null) {
    //         dispatchGlobal(resetAllActionPage5(CheckUndefined(qrRrnValue)))
    //       }
    //     }
    //   }, [qrRrnValue])

    const {
        testCat,
        testCaseId,
        isTestValid,
        qrRefNo,
        QRStatusSendToApi,
        QRStatus,
        stan,
        invNo,
        globalId,
        memberId,
        qrCpmPan,
        qrMpmMerId,
        filterStartDate,
        filterEndDate,
        search,
        selectedQRStandardId,
        selectedQRStandardName,
        selectedQRStandardSendToApi,
        selectedProductIndId,
        selectedProductIndLabel,
        selectedProductIndSendToApi,
        productInd,
        qrsId,
    } = globalState;



    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])


    const initFunctionCustom = (needRefreshPage: Boolean | null) => {

        let apiUrl = 'dashboard/qr-log-test-list?';
        if (CheckUndefinedStringEmpty(testCat) !== '') {
            apiUrl = apiUrl + `testCat=${CheckUndefinedStringEmpty(testCat)}&`;
        }
        if (CheckUndefinedStringEmpty(testCaseId) !== '') {
            apiUrl = apiUrl + `testCaseId=${CheckUndefinedStringEmpty(testCaseId)}&`;
        }
        if (CheckUndefinedStringEmpty(isTestValid) !== '') {
            apiUrl = apiUrl + `isTestValid=${CheckUndefinedStringEmpty(isTestValid)}&`;
        }
        if (CheckUndefinedStringEmpty(selectedQRStandardId) !== '') {
            apiUrl = apiUrl + `qrsId=${CheckUndefinedStringEmpty(selectedQRStandardId)}&`;
        }
        //NOTES
        if (CheckUndefinedStringEmpty(selectedProductIndSendToApi) !== '') {
            apiUrl = apiUrl + `productInd=${CheckUndefinedStringEmpty(selectedProductIndSendToApi)}&`;
        }
        if (CheckUndefinedStringEmpty(search) !== '') {
            apiUrl = apiUrl + `search=${CheckUndefinedStringEmpty(search)}&`;
        }

        //-------------------------------------NOTED

        if (CheckUndefinedStringEmpty(QRStatusSendToApi) !== '') {
            apiUrl = apiUrl + `qrStatus=${CheckUndefinedStringEmpty(QRStatusSendToApi)}&`;
        }

        if (CheckUndefinedStringEmpty(invNo) !== '') {
            apiUrl = apiUrl + `invNo=${CheckUndefinedStringEmpty(invNo)}&`;
        }
        if (CheckUndefinedStringEmpty(qrRefNo) !== '') {
            apiUrl = apiUrl + `refNo=${CheckUndefinedStringEmpty(qrRefNo)}&`;
        }
        if (CheckUndefinedStringEmpty(stan) !== '') {
            apiUrl = apiUrl + `stan=${CheckUndefinedStringEmpty(stan)}&`;
        }
        if (CheckUndefinedStringEmpty(globalId) !== '') {
            apiUrl = apiUrl + `globalId=${CheckUndefinedStringEmpty(globalId)}&`;
        }
        if (CheckUndefinedStringEmpty(memberId) !== '') {
            apiUrl = apiUrl + `memberId=${CheckUndefinedStringEmpty(memberId)}&`;
        }
        if (CheckUndefinedStringEmpty(qrCpmPan) !== '') {
            apiUrl = apiUrl + `qrCpmPan=${CheckUndefinedStringEmpty(qrCpmPan)}&`;
        }
        // 
        if (CheckUndefinedStringEmpty(qrMpmMerId) !== '') {
            apiUrl = apiUrl + `qrMpmMerId=${CheckUndefinedStringEmpty(qrMpmMerId)}&`;
        }
        if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
            apiUrl = apiUrl + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
            apiUrl = apiUrl + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        apiUrl = apiUrl + `limit=${state.view}&`;
        apiUrl = apiUrl + `offset=${pagination.pageIndex * state.view}&`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    const handleStateGlobal = (nameState: any, valueState: any) => {
        dispatchGlobal(changeStateQRAuthSimulatorInquiry({ [nameState]: valueState }))
    }

    useEffect(() => { initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsQRAuthSimulatorInquiry, dispatchGlobal) }, [search])
    useEffect(() => { initFunctionCustom(true) }, [search])
    useEffect(() => { initFunctionCustom(false) }, [state.currentPage])

    useEffect(() => {
        handleStateGlobal('search', state.search);
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    useEffect(() => {
        if (filterStartDate !== '' && filterEndDate !== '') {
            let dateStart = new Date(filterStartDate);
            let dateEnd = new Date(filterEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }

            if (listAllDayChart.length === 0) {
                dispatch({ type: 'errorMessage', value: 'Start date cannot be larger than End Date' });
            } else {
                if (state.errorMessage !== '') {
                    dispatch({ type: 'errorMessage', value: '' });
                }
            }
        }

    }, [filterStartDate, filterEndDate])


    const exportToCSVComponent = () => { }

    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelQRAuthSimulatorInquiry) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsQRAuthSimulatorInquiry, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => { }


    const resetFilterStatus = () => {
        handleStateGlobal('qrStatusName', '');
    }

    // const handleNavigation = (item: any) => navigate(`${Page2DashboardRoute}/details/${item.transAuthId}`)
    const handleNavigation = (item: any) => {
        console.log("item", item)
        dispatch({
            type: 'rrn',
            value: {
                rrn: item.qrRrn,
            }
        });
        dispatch({
            type: 'stan',
            value: {
                stan: item.qrReqStan,
            }
        });
        dispatch({ type: 'modalCondition', value: !(state.modalCondition) });
    }


    const customButtonAction = (item: ModelQRAuthSimulatorInquiry, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                {/* <ButtonNavigation handleClick={() => handleNavigation(item)} /> */}
            </div>
        );
    };
    useEffect(() => {
        initFunctionCustom(false);
    }, [pagination.pageIndex]);

    useEffect(() => {
        setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
    }, [state.view]);

    const filterComponentPage = () => {
        return (
            <></>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableQRAuthSimulatorInquiry
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                onClick={(item: any) => handleNavigation(item)}
                //
                currentPage={state.currentPage}
            />
        )
    }

    const modalAddDeleteComponentPage = () => {
        return (
            <ModalQRAuthSimulatorInquiry
                modalCondition={state.modalCondition}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                //
                stateParent={state}
                dispatchParent={dispatch}
            />
        )
    }


    const modalUpdateComponentPage = () => {
        return (
            <>
            </>
        )
    }



    return (<>
        {/* <FilterComponentQRAuthSimulatorInquiry state={state} stateOptions={stateOptions} stateCustomComponent={stateCustomComponent} handleStateParent={handleStateComponent} handleStateGlobal={handleStateGlobal} initFunctionCustom={initFunctionCustom} resetFilterStatus={resetFilterStatus} />
        <TableWithLimitOffset
            state={state}
            stateOptions={stateOptions}
            customButtonAction={customButtonAction}
            exportToCSVComponent={exportToCSVComponent}
            addDataComponent={addDataComponent}
            checkIndexComponent={checkIndexComponent}
            handleStateComponent={handleStateComponent}
            setToggleComponent={setToggleComponent}
            setModal={setModalComponent}
            // component
            tableComponentPage={tableComponentPage}
            filterComponentPage={filterComponentPage}
            modalAddDeleteComponentPage={modalAddDeleteComponentPage}
            modalUpdateComponentPage={modalUpdateComponentPage}
            // 
            needComponentHeaderTable={true}
            needComponentFilterTable={true}
            needComponentTable={true}
            needComponentFooterTable={true}
            //
            needToggleFunction={true}
            needAddButton={false}
            needSearchFunction={true}
            needDownloadButton={false}
        /> */}
        <div id="dekstop-view" className="d-none d-sm-block">
            <DesktopViewQRAuthSimulatorInquiry
                state={state}
                stateOptions={stateOptions}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                handleStateGlobal={handleStateGlobal}
                resetFilterStatus={resetFilterStatus}
                initFunctionCustom={initFunctionCustom}
                handleNavigation={handleNavigation}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
            />
        </div>
        <div id="mobile-view" className="mt-2 d-block d-sm-none">
            <MobileViewQRAuthSimulatorInquiry
                state={state}
                stateOptions={stateOptions}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                handleStateGlobal={handleStateGlobal}
                resetFilterStatus={resetFilterStatus}
                initFunctionCustom={initFunctionCustom}
                handleNavigation={handleNavigation}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
            />
        </div>
        <ModalQRAuthSimulatorInquiry
            modalCondition={state.modalCondition}
            setModal={setModalComponent}
            deleteItem={state.deleteItem}
            id={state.id}
            dataModal={state.dataModal}
            urlApi={state.urlApi}
            reloadData={() => reloadDataComponent(false)}
            stateOptions={stateOptions}
            //
            stateParent={state}
            dispatchParent={dispatch}
        />
    </>)
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    data: [],
    privilegeAccess: { view: '481', add: '', edit: '', delete: '', download: '' },
    label: [
        { field: 'No.', width: 'wd-20', val: 'no' },
        { field: 'Test Category', width: 'wd-100', val: 'testCat' },
        { field: 'Test Case Id', width: 'wd-100', val: 'testCaseId' },
        { field: 'Test Result', width: 'wd-90', val: 'isTestValid' },
        { field: 'QR Date/Time', width: 'wd-130', val: 'createDate' },
        { field: 'Product Indicator', width: 'wd-120', val: 'prodFeatureName' },
        { field: 'Member Id', width: 'wd-80', val: 'qrReqMemberId' },
        { field: 'QR Ref No', width: 'wd-100', val: 'qrRrn' },
        { field: 'QR Stan', width: 'wd-80', val: 'qrReqStan' },
        { field: 'QR Inv No', width: 'wd-240', val: 'qrMpmInvNo' },
        { field: 'QR Status', width: 'wd-90', val: 'qrStatus' },
    ],

    toggle: ['no', 'testCat', 'testCaseId', 'isTestValid', 'createDate', 'prodFeatureName', 'qrReqMemberId', 'qrRrn', 'qrReqStan', 'qrMpmInvNo', 'qrStatus'],
    complete_toggle: ['no', 'testCat', 'testCaseId', 'isTestValid', 'createDate', 'prodFeatureName', 'qrReqMemberId', 'qrRrn', 'qrReqStan', 'qrMpmInvNo', 'qrStatus'],
    list_column_name: ['No.', 'testCat', 'testCaseId', 'isTestValid', 'QR Date/Time', 'Product Indicator', 'Product Member Id', 'QR Ref No', 'QR Stan ', 'QR Inv No ', 'QR Status',],

    deleteItem: false,
    dataModal: null,
    SearchByPlaceHolder: 'Product Indicator,  Product Member Id,  QR Ref No, QR Stan,  QR Inv No, QR Status',

    id: '',
    search: '',
    view: 15,
    position: 15,
    list_view: [15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    errorMessage: '',

    // 
    widthTable: 1600,
    dataLength: 0, // zIndex
    //
    acquirerSwitcherId: '',
    issuerSwitcherId: '',
    acquirerId: '',
    issuerId: '',
    //
    conditionHitFirst: false,
    rrn: '',
    stan: '',
};

const reducer = (state: any, action: any) => {
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'dataRouting':
            return {
                ...state,
                dataRouting: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };

        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
            };
        case 'editData':
            return {
                ...state,
            };
        case 'deleteData':
            return {
                ...state,
            };
        case 'searchData':
            return {
                ...state,
                data: action.value,
                position: 15,
            };


        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value
            };
        // new
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        //
        case 'acquirerSwitcherId':
            return {
                ...state,
                acquirerSwitcherId: action.value
            };
        case 'issuerSwitcherId':
            return {
                ...state,
                issuerSwitcherId: action.value
            };
        case 'acquirerId':
            return {
                ...state,
                acquirerId: action.value
            };
        case 'issuerId':
            return {
                ...state,
                issuerId: action.value
            };
        case 'conditionHitFirst':
            return {
                ...state,
                conditionHitFirst: action.value
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'rrn':
            return {
                ...state,
                rrn: action.value.rrn
            };
        case 'stan':
            return {
                ...state,
                stan: action.value.stan
            };
        case 'modalCondition':
            return {
                ...state,
                modalCondition: action.value
            };

        default:
            throw new Error();
    }
}
// settlement

interface InterfaceSettlement {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberSettleCurrCode: string | null;
    memberSettleFxYn: Boolean | null;
    memberSettleFxId: number | null;
    memberCutOverYn: Boolean | null;
    memberCutOverTime: string | null;
    memberCutOverTzone: string | null;
}

export class ModalSettlement {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberSettleCurrCode: string | null;
    memberSettleFxYn: Boolean | null;
    memberSettleFxId: number | null;
    memberCutOverYn: Boolean | null;
    memberCutOverTime: string | null;
    memberCutOverTzone: string | null;

    constructor(value: InterfaceSettlement) {
        this.id = value.id;
        this.memberParamsId = value?.memberParamsId ?? null;
        this.memberCode = value?.memberCode ?? null;
        this.memberSettleCurrCode = value?.memberSettleCurrCode ?? null;
        this.memberSettleFxYn = value?.memberSettleFxYn ?? null;
        this.memberSettleFxId = value?.memberSettleFxId ?? null;
        this.memberCutOverYn = value?.memberCutOverYn ?? null;
        this.memberCutOverTime = value?.memberCutOverTime ?? null;
        this.memberCutOverTzone = value?.memberCutOverTzone ?? null;
    }
}

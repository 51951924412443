import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelRoutingHeaderMapping } from './ModelRoutingHeaderMapping';
import { loadingAction } from '../../Store/Loading';
import { Page33Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableRoutingHeaderMapping = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi, state } = props
    const { optionsMOClowercase, optionsCommonStatusDetailCode, optionsRouting, optionsHeaderField } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { headerFieldId, rhfMoc, rhfStatus, routeId, rhfValue } = statePage.data
        const data = {
            rhfId: id,
            routeId: routeId,
            headerFieldId: headerFieldId,
            rhfMoc: rhfMoc,
            rhfStatus: rhfStatus,
            rhfValue: rhfValue,
        }
        const a = CheckAllFields([headerFieldId, rhfMoc, routeId, rhfStatus], ['INTERFACE FIELD ID', 'M/O/C', 'ROUTE ID', 'STATUS'])

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                tableTitle: state.titlePage
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };
    let newOptionsRouting: any = [];
    if (optionsRouting.length > 0) {
        optionsRouting.forEach((element1: any) => {
            newOptionsRouting.push({ value: element1.routeId, label: element1.routeName === null ? 'Route Name is unknown' : element1.routeName });
        })
    }
    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>HEADER FIELD ID</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsHeaderField} value={optionsHeaderField.filter((option: any) => option.value === statePage.data.headerFieldId)} onChange={(e) => handleState('headerFieldId', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>M/O/C</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMOClowercase} value={optionsMOClowercase.filter((option: any) => option.value === statePage.data.rhfMoc)} onChange={(e) => handleState('rhfMoc', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Route ID</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={newOptionsRouting} value={newOptionsRouting.filter((option: any) => option.value === statePage.data.routeId)} onChange={(e) => handleState('routeId', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Status</p>
                       <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.rhfStatus)} onChange={(e) => handleState('rhfStatus', e.value)} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>Default Value</p>
                        <input value={statePage.data.rhfValue} onChange={(e) => handleState('rhfValue', e.target.value)} className={inputStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page33Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelRoutingHeaderMapping({
        id: null,
        rhfId: null,
        routeId: null,
        headerFieldId: null,
        rhfMoc: 'm',
        rhfStatus: '001',
        rhfValue: null,
        description: null,
        headerFieldName: null,
        headerFieldValue: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelRoutingHeaderMapping) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelRoutingHeaderMapping({
                    id: null,
                    rhfId: null,
                    routeId: null,
                    headerFieldId: null,
                    rhfMoc: 'm',
                    rhfStatus: '001',
                    rhfValue: null,
                    description: null,
                    headerFieldName: null,
                    headerFieldValue: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelRoutingHeaderMapping({
                    id: action.value.rhfId,
                    rhfId: action.value.rhfId,
                    routeId: action.value.routeId,
                    headerFieldId: action.value.headerFieldId,
                    rhfMoc: action.value.rhfMoc,
                    rhfStatus: action.value.rhfStatus,
                    rhfValue: action.value.rhfValue,
                    description: action.value.description,
                    headerFieldName: action.value.headerFieldName,
                    headerFieldValue: action.value.headerFieldValue,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, inputStyleReadOnlyH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { urlSelection } from '../../Services/API';


export const ModalDraggableGroupCodeList = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsCommonStatusDetailCode, optionsGroupCodeOnly } = props.stateOptions

    const { setTriggerReload } = props

    const draggleRef = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        const { errorMessageList } = statePage;
        let customErrorMessage1 = 'GROUP CODE input maximum 8 characters';
        let customErrorMessage2 = 'GROUP CODE already exist';
        let customErrorMessage3 = 'GROUP CODE NAME input maximum 32 characters';
        let customErrorMessage4 = 'DESCRIPTION input maximum 120 characters';
        if (nameState === 'ccgCode') {
            if (valueState.split('').length > 8) {
                if (errorMessageList.includes(customErrorMessage1)) {
                } else {
                    errorMessageList.push(customErrorMessage1);
                    let stringErrorMessage = '';
                    errorMessageList.forEach((element1: any) => {
                        stringErrorMessage = stringErrorMessage + element1;
                    });

                    dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
                    dispatchStatePage({ type: nameState, value: valueState.toUpperCase() })
                }
            } else if (optionsGroupCodeOnly.includes(`${valueState.toUpperCase()}`)) {
                if (errorMessageList.includes(customErrorMessage2)) {
                } else {
                    errorMessageList.push(customErrorMessage2);
                    let stringErrorMessage = '';
                    errorMessageList.forEach((element1: any) => {
                        stringErrorMessage = stringErrorMessage + ', ' + element1;
                    });

                    dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
                    dispatchStatePage({ type: nameState, value: valueState.toUpperCase() })
                }
            } else {
                let dataListErrorMessage = errorMessageList.filter((element1: any) => element1 !== customErrorMessage1);
                let dataListErrorMessage2 = dataListErrorMessage.filter((element1: any) => element1 !== customErrorMessage2);
                dispatchStatePage({ type: 'errorMessage', value: dataListErrorMessage2.join(', ') })
                dispatchStatePage({ type: nameState, value: valueState.toUpperCase() })
                dispatchStatePage({ type: 'errorMessageList', value: dataListErrorMessage2 })
            }
        } else if (nameState === 'ccgName') {
            if (valueState.split('').length > 32) {
                if (errorMessageList.includes(customErrorMessage3)) {
                } else {
                    errorMessageList.push(customErrorMessage3);
                    let stringErrorMessage = '';
                    errorMessageList.forEach((element1: any) => {
                        stringErrorMessage = stringErrorMessage + ', ' + element1;
                    });

                    dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                let dataListErrorMessage = errorMessageList.filter((element1: any) => element1 !== customErrorMessage3);
                dispatchStatePage({ type: 'errorMessage', value: dataListErrorMessage.join(', ') })
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessageList', value: dataListErrorMessage })
            }
        } else if (nameState === 'ccgDesc') {
            if (valueState.split('').length > 120) {
                if (errorMessageList.includes(customErrorMessage4)) {
                } else {
                    errorMessageList.push(customErrorMessage4);
                    let stringErrorMessage = '';
                    errorMessageList.forEach((element1: any) => {
                        stringErrorMessage = stringErrorMessage + ', ' + element1;
                    });
                    dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                let dataListErrorMessage = errorMessageList.filter((element1: any) => element1 !== customErrorMessage4);
                dispatchStatePage({ type: 'errorMessage', value: dataListErrorMessage.join(', ') })
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessageList', value: dataListErrorMessage })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const { ccgCode, ccgName, ccgDesc, ccgStatus, errorMessage } = statePage;
        const data = {
            ccgId: `${id}`,
            ccgCode: `${ccgCode}`,
            ccgName: `${ccgName}`,
            ccgDesc: `${ccgDesc}`,
            ccgStatus: `${ccgStatus}`,
        };
        const a = CheckAllFields([ccgCode, ccgName, ccgStatus], ['Group Code', 'Group Code Name', 'Use Status']);
        if (a !== "" || errorMessage !== '') {
            let errorMessageString = errorMessage === '' ? '' : `${errorMessage}, `;
            if (errorMessageString.toString().includes(a)) {
            } else {
                handleState('errorMessage', `${errorMessageString}${a}, `);
            }
        } else {
            if (errorMessage === '') {
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                }).then((response) => {
                    actionAfterUpdateItem(id)
                    actionShowModal()
                    setTriggerReload(true)
                }).catch((err) => handleState('errorMessage', err))
            }
        }

    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-4'>
                        <p className={labelStyle}>GROUP CODE <span className='text-danger'>*</span></p>
                        {statePage.id ? <input value={statePage.ccgCode} disabled className={inputStyleReadOnlyH25} /> : <input value={statePage.id} onChange={(e) => handleState('id', e.target.value)} className={inputStyle} />}
                    </div>
                    <div className='col-4'>
                        <p className={labelStyle}>GROUP CODE NAME <span className='text-danger'>*</span></p>
                        <input value={statePage.ccgName} onChange={(e) => handleState('ccgName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-4'>
                        <p className={labelStyle}>USE STATUS <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCommonStatusDetailCode}
                            value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.ccgStatus)}
                            onChange={(e) => handleState('ccgStatus', e.value)}
                        />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.ccgDesc} onChange={(e) => handleState('ccgDesc', e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: 'Edit Group Code',
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    ccgId: '',
    ccgCode: '',
    ccgName: '',
    ccgDesc: '',
    ccgStatus: '001',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                ccgId: '',
                ccgCode: '',
                ccgName: '',
                ccgDesc: '',
                ccgStatus: '001',
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                ccgId: action.value.ccgId,
                ccgCode: action.value.ccgCode,
                ccgName: action.value.ccgName,
                ccgDesc: action.value.ccgDesc,
                ccgStatus: action.value.ccgStatus,
                errorMessage: "",
            };
        // 
        case 'ccgId':
            return {
                ...state,
                ccgId: action.value,
            };
        case 'ccgCode':
            return {
                ...state,
                ccgCode: action.value,
            };
        case 'ccgName':
            return {
                ...state,
                ccgName: action.value,
            };
        case 'ccgDesc':
            return {
                ...state,
                ccgDesc: action.value,
            };
        case 'ccgStatus':
            return {
                ...state,
                ccgStatus: action.value,
            };
        default:
            throw new Error();
    }
}





export const ModalStyle =
{
    content: {
        overflow: 'unset',
        right: 'auto',
        bottom: 'auto',
        top: '50%',
        left: '50%',
        marginRight: '-30%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: 90,
    }
}
export const ModalStyle2 =
{
    content: {
        overflow: 'unset',
        right: 'auto',
        bottom: 'auto',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
    },
    overlay: {
        zIndex: 9999999999,
    }
}
export const ModalStyle3 =
{
    content: {
        overflow: 'unset',
        right: 'auto',
        bottom: 'auto',
        top: '50%',
        left: '50%',
        marginRight: '-30%',
        transform: 'translate(-50%, -50%)',
        minWidth: 'min-content'
    },
    overlay: {
        zIndex: 90,
    }
}
export const ModalStyle4 =
{
    content: {
        overflow: 'unset',
        right: 'auto',
        bottom: 'auto',
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '98%',
        width: '98%',
        borderRadius: '10px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 10,
    }
}

export const ModalStyle5 =
{
    content: {
        overflow: 'unset',
        right: 'auto',
        bottom: 'auto',
        top: '50%',
        left: '50%',
        width: '40%',
        marginRight: '-30%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: 90,
    }
}
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { icon_search_white } from '../../Assets/Images/svg';
import { ButtonDelete, ButtonEdit, ButtonNavigation } from '../../Components';
import { ShowModalInterface } from '../../Interface';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { inputStyleH25, labelStyle } from '../../Styles';
import { InterfaceOptionsInstitutionMonitoringStatus } from './InterfaceOptionsInstitutionMonitoringStatus';
import { ModalDraggableInstitutionMonitoringStatus } from './ModalDraggableInstitutionMonitoringStatus';
import { ModalInstitutionMonitoringStatus } from './ModalInstitutionMonitoringStatus';
import { ModelInstitutionMonitoringStatus } from './ModelInstitutionMonitoringStatus';
import { Page2SystemNetworkRoute } from '../../Routes';
import { Page49Title } from '../../State/Menu/TitleMenu';
import { TableMemberConnectionStatus } from './Components/TableMemberConnectionStatus';


const Page3 = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let memberSelected = stateCustomComponent.selectedMember === '' ? '' : `memberId=${stateCustomComponent.selectedMember}&`;
        let selectedServerStatus = stateCustomComponent.selectedServerStatus === '' ? '' : `serverStatus=${stateCustomComponent.selectedServerStatus}&`;
        let selectedConnectionStatus = stateCustomComponent.selectedConnectionStatus === '' ? '' : `status=${stateCustomComponent.selectedConnectionStatus}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let finalParameter = `${searchValue}${limit}${offset}${memberSelected}${selectedServerStatus}${selectedConnectionStatus}`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });

                /* Refresh Page */
                setPagination({pageSize: state.view, pageIndex: 0});
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }


    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsInstitutionMonitoringStatus, dispatchGlobal)
    }, [])

    useEffect(() => {
        if (state.view !== pagination.pageSize) {
            initFunctionCustom(true);
            setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
        } else {
            initFunctionCustom(false);
        }
    }, [pagination.pageIndex, state.view]);

    const exportToCSVComponent = () => { }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelInstitutionMonitoringStatus) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelInstitutionMonitoringStatus, width: string | any) => {
        return (
            <div className={`d-flex gap-2 align-items-center ${width}`}>

                <ButtonNavigation navTo={`${Page2SystemNetworkRoute}/details/${item.id}`} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonEdit handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />

            </div>
        );
    };

    const filterComponentPage = () => {
        return (
            <div className="g-5 row bg-ff mb-10 d-flex justify-content-center align-items-center p-0 m-0 py-2 mb-2 br-10 bd-d4">
                <div className="row mb-1">
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12">
                        <p className={labelStyle}>member</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsMember} value={stateOptions.optionsMember.filter((option: any) => option.value === stateCustomComponent.selectedMember)} onChange={(e) => handleState("selectedMember", e.value, dispatchCustomComponent)} />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12">
                        <p className={labelStyle}>server status</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsServerStatusDetailCode} value={stateOptions.optionsServerStatusDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedServerStatus)} onChange={(e) => handleState("selectedServerStatus", e.value, dispatchCustomComponent)} />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12">
                        <p className={labelStyle}>connection status</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsConnectionStatusDetailCode} value={stateOptions.optionsConnectionStatusDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedConnectionStatus)} onChange={(e) => handleState("selectedConnectionStatus", e.value, dispatchCustomComponent)} />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-12 col-xs-12 d-flex  align-items-end justify-content-end ">
                        <div className=' d-flex align-items-end justify-content-end' >
                            <button 
                                className="btn d-flex bg-e8 h-30px mx-1 mr-100px "
                                onClick={(e) => { 
                                    handleState("selectedConnectionStatus", '', dispatchCustomComponent) 
                                    handleState("selectedServerStatus", '', dispatchCustomComponent)
                                    handleState("selectedMember", '', dispatchCustomComponent)
                                }}
                            >
                            <svg
                                style={{ width: '18px', height: '18px' }}
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                    fill="#FFFFFF"
                                />
                            </svg>
                            <p className="s-1418 c-ff Lato-600 mx-2">Reset</p>
                            </button>
                            <button className="btn d-flex bg-e8 h-30px mx-1 mr-100px " onClick={() => reloadDataComponent(true)}>
                                {icon_search_white}
                                <p className="s-1418 c-ff Lato-600 mx-2">Search</p>
                            </button>
                        </div>
                        <div className='w-20px'></div>
                    </div>
                </div>
            </div>
        )
    }

    const modalAddDeleteComponentPage = () => {
        // return <></>
        return (
            <ModalInstitutionMonitoringStatus
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />

        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableInstitutionMonitoringStatus
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                Member Connection Status
            </h1>
            <TableMemberConnectionStatus
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                filterComponentPage={filterComponentPage}
                addDataComponent={addDataComponent}
                handleStateComponent={handleStateComponent}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
            />

            {modalAddDeleteComponentPage()}
            {modalUpdateComponentPage()}
        </>
    );

}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'member_conn',
    titlePage: Page49Title,
    privilegeAccess: { view: '91', add: '92', edit: '93', delete: '94', download: '95' },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Member", width: "wd-200", val: "memberId" },
        { field: "IP Address", width: "wd-200", val: "memberConnServer" },
        { field: "Port", width: "wd-100", val: "memberConnPort" },
        { field: "Server Status", width: "wd-200", val: "memberConnServerStatus" },
        { field: "Last Echo", width: "wd-200", val: "memberConnLastEchoDt" },
        { field: "Last Transaction", width: "wd-200", val: "memberConnLastTransDt" },
        { field: "Connection Status", width: "wd-150", val: "memberConnStatus" },
        { field: "Action", width: "wd-200", val: "action" },
    ],
    toggle: ["no", "memberId", "memberConnServer", "memberConnPort", "memberConnServerStatus", "memberConnLastEchoDt", "memberConnLastTransDt", "memberConnStatus", "action"],
    complete_toggle: ["no", "memberId", "memberConnServer", "memberConnPort", "memberConnServerStatus", "memberConnLastEchoDt", "memberConnLastTransDt", "memberConnStatus", "action"],
    list_column_name: ["No.", "Member", "IP Address", "Port", "Server Status", "Last Echo", "Last Transaction", "Connection Status"],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1550,
};

// memberConnId ModelInstitutionMonitoringStatus
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInstitutionMonitoringStatus) => {
                        element1.id = element1.memberConnId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInstitutionMonitoringStatus) => {
                        element1.id = element1.memberConnId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInstitutionMonitoringStatus) => {
                        showDataResult.push(
                            { idModal: element1.memberConnId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.memberConnId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        default:
            throw new Error();
    }
}

export default Page3;

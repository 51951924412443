import { MaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from "material-react-table";
import { ButtonAdd } from "./ButtonAdd";
import { ButtonDownload } from "./ButtonDownload";
import { SearchTable } from "./SearchTable";
import { ToggleTable } from "./ToggleTable";

export const HeaderDataTable = ({ ...props }) => {
    const {
        stateParent,
        titleTable,
        data,
        table,
        label,
        privilegeAccess,
        checkIndex,
        setToggle,
        handleState,
        addData,
        exportToCSV,
        needAddButton = true,
        needDownloadButton = true,
        needSearchFunction = true,
        needToggleFunction = true,
        totalData, isButtonDisabled

    } = props;

    return (
        <div className="d-flex p-0 mt-1 mb-1">
            <div className="d-flex justify-content-start align-items-center w-100">
                <div className="">
                    <div className="col-6 d-flex align-items-center">
                        <p className="Lato-400 s-1822 c-29">List</p>
                        <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                            {totalData}
                        </p>
                    </div>
                </div>

                {
                    needDownloadButton ?
                        <div className="h-30px w-30px" style={{ opacity: isButtonDisabled ? '0.7' : '1' }}>
                            <ButtonDownload isButtonDisabled={isButtonDisabled} handleClick={exportToCSV} codeAccessPrivilege={privilegeAccess.download} />
                        </div>
                        :
                        <></>
                }

            </div>
        </div>
    );
}
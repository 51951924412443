

// InterfaceField

interface InterfaceFieldInterface {
    id: null | number | string;
    interfaceFieldId: number | null;
    interfaceFieldName: string | null;
    interfaceFieldDesc: string | null;
    interfaceFieldLength: number | null;
    interfaceFieldType: string | null;
    interfaceFieldMappingCode: string | null;

}

export class ModelInterfaceField {
    id: null | number | string;
    interfaceFieldId: number | null;
    interfaceFieldName: string | null;
    interfaceFieldDesc: string | null;
    interfaceFieldLength: number | null;
    interfaceFieldType: string | null;
    interfaceFieldMappingCode: string | null;


    constructor(value: InterfaceFieldInterface | null) {
        this.id = value?.id ?? null;
        this.interfaceFieldId = value?.interfaceFieldId ?? null;
        this.interfaceFieldName = value?.interfaceFieldName ?? null;
        this.interfaceFieldDesc = value?.interfaceFieldDesc ?? null;
        this.interfaceFieldLength = value?.interfaceFieldLength ?? null;
        this.interfaceFieldType = value?.interfaceFieldType ?? null;
        this.interfaceFieldMappingCode = value?.interfaceFieldMappingCode ?? null;
    }
}

import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { format } from "date-fns";
import Draggable from 'react-draggable';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FooterModalUpdate, DatePickerCalendarIconOnly } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAllFields, HasWhiteSpace } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page27Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableQRWhitelistAndBlacklist = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsQRStandard, optionsCommonStatusDetailCode, optionsWhitelistBlacklist } = props.stateOptions
    
    const criteriaOptions = [
        { value: 'MPAN', label: 'MPAN' },
        { value: 'Issuer ID', label: 'Issuer ID' }
    ];

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { qrStandardId, subProductCode, subProductName, qrListType, qrListCriteria, qrListCriteriaValue, qrListStartDate, qrListEndDate, qrListStatus, qrListReason, } = statePage
        const data = {
            qrListId: id,
            qrStandardId: qrStandardId,
            qrListType: qrListType,
            qrListCriteria: qrListCriteria,
            qrListCriteriaValue: qrListCriteriaValue,
            // qrListStartDate: qrListStartDate !== '' ? `${qrListStartDate.split('T')[0]}${qrListStartDate.split('T')[1] !== undefined ? ` ${qrListStartDate.split('T')[1]}` : ''}` : '',
            qrListStartDate: qrListStartDate !== '' ? `${qrListStartDate} 00:00:00` : '',
            // qrListEndDate: qrListEndDate !== '' ? `${qrListEndDate.split('T')[0]}${qrListEndDate.split('T')[1] !== undefined ? ` ${qrListEndDate.split('T')[1]}` : ''}` : '',
            qrListEndDate: qrListEndDate !== '' ? `${qrListEndDate} 00:00:00` : '',
            qrListStatus: qrListStatus,
            qrListReason: qrListReason,
        }

        const a = CheckAllFields([qrListType, qrListCriteria, qrListCriteriaValue, qrListStatus, qrListStartDate, qrListEndDate, qrStandardId, qrListReason], ['Type', 'Criteria', 'Value', 'Status', 'Start Date', 'End Date', 'QR STANDARD ID', 'Reason'])

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            let dateStart = new Date(qrListStartDate);
            let dateEnd = new Date(qrListEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length < 1) {
                handleState('errorMessage', 'Start Date can\'t be larger than end date');
            } else {
                handleState('errorMessage', ``);
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    actionAfterUpdateItem(id)
                    actionShowModal()
                }).catch((err) => handleState('errorMessage', err))
            }

        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-6'>
                        <p className={labelStyle}>TYPE</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsWhitelistBlacklist}
                            value={optionsWhitelistBlacklist.filter((option: any) => option.value === statePage.qrListType)}
                            onChange={(e) => handleState('qrListType', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>CRITERIA</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={criteriaOptions}
                            value={criteriaOptions.filter((option: any) => option.value === statePage.qrListCriteria)}
                            onChange={(e) => handleState('qrListCriteria', e?.value)}
                        />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>VALUE</p>
                        <input value={statePage.qrListCriteriaValue} onChange={(e) => handleState('qrListCriteriaValue', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Status</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCommonStatusDetailCode}
                            value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.qrListStatus)}
                            onChange={(e) => handleState('qrListStatus', `${e.value}`)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>START DATE</p>
                        <div>
                            <div className="d-flex">
                                <DatePicker
                                    selected={statePage.qrListStartDate}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    popperClassName="react-datepicker-popper-transform-up-right"
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    showPopperArrow={false}
                                    onChange={(date: any) => handleState('qrListStartDate', format(date, 'yyyy-MM-dd'))}
                                />
                            </div>
                            <DatePickerCalendarIconOnly />
                        </div>
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>END DATE</p>
                        <div>
                            <div className="d-flex">
                                <DatePicker
                                    selected={statePage.qrListEndDate}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    popperClassName="react-datepicker-popper-transform-up-right"
                                    dateFormat="yyyy-MM-dd"
                                    showYearDropdown
                                    showPopperArrow={false}
                                    onChange={(date: any) => handleState("qrListEndDate", format(date, 'yyyy-MM-dd'))}
                                />
                            </div>
                            <DatePickerCalendarIconOnly />
                        </div>
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>QR STANDARD ID</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsQRStandard}
                            value={optionsQRStandard.filter((option: any) => option.value === statePage.qrStandardId)}
                            onChange={(e) => handleState('qrStandardId', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>REASON</p>
                        <input value={statePage.qrListReason} onChange={(e) => handleState('qrListReason', e.target.value)} className={inputStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page27Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    qrListId: '',
    qrStandardId: '',
    subProductCode: '',
    subProductName: '',
    qrListType: '',
    qrListCriteria: '',
    qrListCriteriaValue: '',
    qrListStartDate: '',
    qrListEndDate: '',
    qrListStatus: '001',
    qrListReason: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                qrListId: '',
                qrStandardId: '',
                subProductCode: '',
                subProductName: '',
                qrListType: '',
                qrListCriteria: '',
                qrListCriteriaValue: '',
                qrListStartDate: '',
                qrListEndDate: '',
                qrListStatus: '001',
                qrListReason: '',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                qrListId: action.value.qrListId,
                qrStandardId: action.value.qrStandardId,
                qrListType: action.value.qrListType,
                qrListCriteria: action.value.qrListCriteria,
                qrListCriteriaValue: action.value.qrListCriteriaValue,
                qrListStartDate: action.value.qrListStartDate === null || action.value.qrListStartDate === undefined ? '' : action.value.qrListStartDate.toString().split(' ')[0],
                // qrListStartDate: action.value.qrListStartDate,
                qrListEndDate: action.value.qrListEndDate === null || action.value.qrListEndDate === undefined ? '' : action.value.qrListEndDate.toString().split(' ')[0],
                // qrListEndDate: action.value.qrListEndDate,
                qrListStatus: action.value.qrListStatus,
                qrListReason: action.value.qrListReason,
                errorMessage: "",
            };
        // 
        case 'qrListId':
            return {
                ...state,
                qrListId: action.value,
            };
        case 'qrStandardId':
            return {
                ...state,
                qrStandardId: action.value,
            };
        case 'qrListType':
            return {
                ...state,
                qrListType: action.value,
            };
        case 'qrListCriteria':
            return {
                ...state,
                qrListCriteria: action.value,
            };
        case 'qrListCriteriaValue':
            return {
                ...state,
                qrListCriteriaValue: action.value,
            };
        case 'qrListStartDate':
            return {
                ...state,
                qrListStartDate: action.value,
            };
        case 'qrListEndDate':
            return {
                ...state,
                qrListEndDate: action.value,
            };
        case 'qrListStatus':
            return {
                ...state,
                qrListStatus: action.value,
            };
        case 'qrListReason':
            return {
                ...state,
                qrListReason: action.value,
            };

        default:
            throw new Error();
    }
}





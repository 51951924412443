import { useEffect, useReducer, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { ButtonDelete, ButtonEdit, InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
  addData,
  checkIndex,
  deleteData,
  handleState,
  initFunction,
  reducerOptions,
  reducerStateSelected,
  setModal,
  setToggle,
  stateOptions,
  stateSelected
} from '../../State';
import { InterfaceOptionsReasonCodeManagement } from './InterfaceOptionsReasonCodeManagement';
import { ModalDraggableReasonCodeManagement } from './ModalDraggableReasonCodeManagement';
import { ModalReasonCodeManagement } from './ModalReasonCodeManagement';
import { ModelReasonCodeManagement } from './ModelReasonCodeManagement';
import { DesktopViewReasonCodeManagement } from './View/DesktopViewReasonCodeManagement';
import { ShowModalInterface } from '../../Interface';
import { Page45Title } from '../../State/Menu/TitleMenu';

export const ReasonCodeManagement = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
  const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

  const debouncedSearch = useCallback(
    debounce((value) => {
      dispatch({ type: 'search', value });
    }, 2000),
    []
  );

  useEffect(() => {
    if (privilegeAccess !== null) {
      if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
        navigate('/warning', { replace: true })
      }
    }
  }, [privilegeAccess])

  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    let searchValue = state.search === '' ? '' : `search=${state.search}&`;
    let limit = `limit=${state.view}&`;
    let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
    let finalParameter = `${searchValue}${limit}${offset}`;
    let finalParameter2 = `${searchValue}`;
    handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)

    if (needRefreshPage === true) {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: 'searchData', value: response.data });
        dispatch({ type: 'totalPages', value: response.detail.totalPage });
        dispatch({ type: 'totalData', value: response.detail.total });
      })
    } else {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: 'data', value: response.data });
        dispatch({ type: 'totalPages', value: response.detail.totalPage });
        dispatch({ type: 'totalData', value: response.detail.total });
      })
    }
  }

  useEffect(() => {
    initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsReasonCodeManagement, dispatchGlobal)
  }, [])

  useEffect(() => {
    initFunctionCustom(false)
  }, [state.position, state.view])

  useEffect(() => {
    initFunctionCustom(true)
  }, [state.search])

  useEffect(() => {
    let currentPage: number = parseInt((state.position / state.view).toString());
    dispatch({ type: 'currentPage', value: currentPage });
  }, [state.totalData, state.position, state.view])

  const addDataComponent = () => addData(dispatch)
  const deleteDataComponent = (item: ModelReasonCodeManagement) => deleteData(item.id, item, dispatch)
  const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
  const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
  const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
  const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
  const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
  const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
  const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
  const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

  const customButtonAction = (item: ModelReasonCodeManagement, width: string | any) => {
    return (
      <div className={`d-flex justify-content-around align-items-center ${width}`}>
        <ButtonEdit handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
        <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
      </div>
    );
  };

  const modalAddDeleteComponentPage = () => {
    return (
      <ModalReasonCodeManagement
        visibleModalAddDelete={state.visibleModalAddDelete}
        setModal={setModalComponent}
        deleteItem={state.deleteItem}
        id={state.id}
        urlApi={state.urlApi}
        dataModal={state.dataModal}
        reloadData={() => reloadDataComponent(false)}
        stateOptions={stateOptions}
      />
    )
  }

  const modalUpdateComponentPage = () => {
    return (
      <>
        {
          state.savedShowModal.map((element1: any) => {
            return (
              <ModalDraggableReasonCodeManagement
                key={element1.idModal}
                id={element1.idModal}
                index={element1.index}
                idModal={element1.idModal}
                state={state}
                urlApi={state.urlApi}
                showModal={element1.showModal}
                reloadData={() => reloadDataComponent(false)}
                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                actionIndexModal={() => actionIndexModal(element1.idModal)}
                actionAfterUpdateItem={actionAfterUpdateItem}
                itemModal={element1.itemModal}
                stateOptions={stateOptions}
              />
            )})
        }
      </>
    )
  }

  return (
    <>
      <DesktopViewReasonCodeManagement
        state={state}
        stateOptions={stateOptions}
        codeAccessPrivilegeEdit={state.privilegeAccess.edit}
        codeAccessPrivilegeDelete={state.privilegeAccess.delete}
        debouncedSearch={debouncedSearch}
        customButtonAction={customButtonAction}
        addDataComponent={addDataComponent}
        handleStateComponent={handleStateComponent}
        modalAddDeleteComponentPage={modalAddDeleteComponentPage}
        modalUpdateComponentPage={modalUpdateComponentPage}
        dispatch={dispatch}
      />
    </>
  );
}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
  urlApi: 'reason_code',
  titlePage: Page45Title,
  privilegeAccess: { view: "251", add: "252", edit: "253", delete: "254", download: "255" },
  // 
  visibleModalAddDelete: false,
  savedShowModal: [], // new
  SearchByPlaceHolder: 'Reason Code, Reason Group Code, Reason Name',
  // 
  id: '',
  data: [],
  dataModal: null,
  search: '',
  view: 10,
  position: 10,
  list_view: [10, 15, 20],
  totalData: 0, // new
  totalPages: 0,
  currentPage: 0,
  insertPage: false,
  deleteItem: false,
  // 
  widthTable: 900,
  dataLength: 0, // zIndex
  finalAPI: '',
};


// rcId ModelReasonCodeManagement
function reducer(state: any, action: any) {
  let showDataResult: ShowModalInterface[] = [];
  let dataWhereChangeToIdGeneral: any[] = [];
  let newSavedShowModal: ShowModalInterface[] = [];
  switch (action.type) {
    case 'data':
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelReasonCodeManagement) => {
            element1.id = element1.rcId;
            dataWhereChangeToIdGeneral.push(element1);
          })
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case 'searchData':
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelReasonCodeManagement) => {
            element1.id = element1.rcId;
            dataWhereChangeToIdGeneral.push(element1);
          })
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        position: state.view,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case 'showModal':
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            let conditionShowModalFind = false;
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value.idModal) {
                conditionShowModalFind = true;
              }
            })
            if (conditionShowModalFind === false) {
              newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
              newSavedShowModal.push(...state.savedShowModal);
            } else {
              state.savedShowModal.forEach((element1: ShowModalInterface) => {
                if (element1.idModal === action.value.idModal) {
                  if (element1.showModal === false) { // will to show
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                  } else {
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                  }
                } else {
                  newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                }
              })
            }

          } else {
            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case 'changeIndex':
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value) {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
              } else {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
              }
            })
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case 'afterUpdate':
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelReasonCodeManagement) => {
            showDataResult.push(
              { idModal: element1.rcId, showModal: false, itemModal: element1, index: 1 }
            )
            element1.id = element1.rcId;
            dataWhereChangeToIdGeneral.push(element1);
          })
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        showModal: showDataResult,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    // 
    case 'widthTable':
      return {
        ...state,
        widthTable: action.value
      };
    case 'visibleModalAddDelete':
      return {
        ...state,
        visibleModalAddDelete: action.value
      };
    case 'id':
      return {
        ...state,
        id: action.value
      };
    case 'label':
      return {
        ...state,
        label: action.value
      };
    case 'toggle':
      return {
        ...state,
        toggle: action.value
      };
    case 'complete_toggle':
      return {
        ...state,
        complete_toggle: action.value
      };
    case 'list_column_name':
      return {
        ...state,
        list_column_name: action.value
      };
    case 'privilegeAccess':
      return {
        ...state,
        privilegeAccess: action.value
      };
    case 'deleteItem':
      return {
        ...state,
        deleteItem: action.value
      };
    case 'list_view':
      return {
        ...state,
        list_view: action.value
      };
    case 'view':
      return {
        ...state,
        view: action.value
      };
    case 'position':
      return {
        ...state,
        position: action.value
      };
    case 'search':
      return {
        ...state,
        search: action.value
      };
    case 'dataModal':
      return {
        ...state,
        dataModal: action.value
      };
    case 'insertPage':
      return {
        ...state,
        insertPage: action.value
      };
    case 'addData':
      return {
        ...state,
        id: '',
        dataModal: null,
        visibleModalAddDelete: !(state.visibleModalAddDelete),
      };
    case 'editData':
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        visibleModalAddDelete: true,
      };
    case 'deleteData':
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        deleteItem: true,
      };
    // new
    case 'totalData':
      return {
        ...state,
        totalData: action.value
      };
    case 'totalPages':
      return {
        ...state,
        totalPages: action.value
      };
    case 'currentPage':
      return {
        ...state,
        currentPage: action.value
      };
    case 'finalAPI':
      return {
        ...state,
        finalAPI: action.value
      };
    default:
      throw new Error();
  }
}


import React, { useEffect, useReducer } from 'react';
import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { FooterModalUpdate } from '../../Components';
import { inputStyleReadOnly, labelStyle } from '../../Styles';
import { ModelUserLogHistory } from './ModelUserLogHistory';
import { ResizableBox } from 'react-resizable';
import TextArea from 'antd/es/input/TextArea';
import { Page11Title } from '../../State/Menu/TitleMenu';

const ModalDraggableUserLogHistory = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props

    const {
        optionsInterfaceFileFormatDetailCode,
        optionsFileStringFormatDetailCode,
        optionsFileStringSeparatorDetailCode,
        optionsCommonStatusDetailCode,
    } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["interfaceFileId", "interfaceFileName", "interfaceFileFormat", "interfaceFileDesc"]
        let limit = [8, 32, 3, 64]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'interfaceRecSize') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 1000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else if (nameState === 'interfaceRecItemNameLength') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 10000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                    <hr className='c-7f7'/>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row pb-2 gy-2 gx-3 mx-0'>

                    <div className='col-6'>
                        <p className={labelStyle}>USER ID</p>
                        <input value={`${statePage.data.userId} - ${statePage.data.username}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>SCREEN NAME</p>
                        <input value={statePage.data.screenName} readOnly className={inputStyleReadOnly} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>ACTIVITY</p>
                        <input value={statePage.data.logFileName} readOnly className={inputStyleReadOnly} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>ACTIVITY TIME</p>
                        <input value={statePage.data.logCreateDt} readOnly className={inputStyleReadOnly} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>CLIENT IP ADDRESS</p>
                        <input value={statePage.data.logSourceIp} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>CRUD</p>
                        <input value={statePage.data.crudType} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>BEFORE SNAPSHOT</p>
                        <TextArea rows={3} value={statePage.data.before} readOnly={true}  ></TextArea>
                    </div>

                    <div className='col-12'>
                        <p className={labelStyle}>AFTER SNAPSHOT</p>
                        <TextArea rows={3} value={statePage.data.after} readOnly={true}  ></TextArea>
                    </div>

                </div>
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `DETAIL ${Page11Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelUserLogHistory({
        id: null,
        logId: null,
        traceId: null,
        logSourceIp: null,
        logCreateDt: null,
        logFileName: null,
        userId: null,
        username: null,
        before: null,
        after: null,
        screenName: null,
        crudType: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelUserLogHistory) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelUserLogHistory({
                    id: null,
                    logId: null,
                    traceId: null,
                    logSourceIp: null,
                    logCreateDt: null,
                    logFileName: null,
                    userId: null,
                    username: null,
                    before: null,
                    after: null,
                    screenName: null,
                    crudType: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelUserLogHistory({
                    id: action.value.logId,
                    logId: action.value.logId,
                    traceId: action.value.traceId,
                    logSourceIp: action.value.logSourceIp,
                    logCreateDt: action.value.logCreateDt,
                    logFileName: action.value.logFileName,
                    userId: action.value.userId,
                    username: action.value.username,
                    before: action.value.before,
                    after: action.value.after,
                    screenName: action.value.screenName,
                    crudType: action.value.crudType,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

export default ModalDraggableUserLogHistory;






import { useEffect, useReducer, useState } from "react";
import { Card, FormLabel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import bgLogin from "../../../Assets/Images/bg_login.png";
import logo2 from "../../../Assets/Images/logo2.png";
import { errNotificationIcon, padlockIcon, userIcon } from "../../../Assets/Images/svg";
import { ButtonLogin } from "../../../Components";
import { API, AllPath } from "../../../Services";
import { loginAction, setRoleByMemberIdAction, tokenAction } from "../../../Store/User";
import { CheckAllFields, ValidationPassword } from "../../../Utils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { urlSelection } from "../../../Services/API";
import * as CryptoJS from 'crypto-js';
import Cookies from 'universal-cookie';
import { cookiesGlobal } from "../../../State/Cookies";


export const FormLogin = ({ ...props }): JSX.Element => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => setShowPassword(!showPassword)

    useEffect(() => {
        try {
            // let decryptedUN = CryptoJS.AES.decrypt(cookiesGlobal.get('_udxtrn'), 'bcap-gapih.dashboard.com', { path: '/',  });
            // let decryptedPW = CryptoJS.AES.decrypt(cookiesGlobal.get('_pdxstr'), 'bcap-gapih.dashboard.com', { path: '/',  });
            let decryptedUN = CryptoJS.AES.decrypt(localStorage.getItem('_udxtrn')!, 'bcap-gapih.dashboard.com');
            let decryptedPW = CryptoJS.AES.decrypt(localStorage.getItem('_pdxstr')!, 'bcap-gapih.dashboard.com');
            if (decryptedUN !== null && decryptedPW !== null) {
                dispatchStatePage({ type: 'user', value: { email: decryptedUN.toString(CryptoJS.enc.Utf8), password: decryptedPW.toString(CryptoJS.enc.Utf8) } });
                dispatchStatePage({ type: 'rememberMe', value: true })
            }
        } catch (error) {
            // console.log(error)
        }
    }, [])

    const handleState = (nameType: string, value: any) => {
        dispatchStatePage({ type: nameType, value: value })
    }

    const handleValidation = (): Boolean => {
        let formIsValid = true;
        if (statePage.email === '' || statePage.email === null) {
            formIsValid = false;
            handleState('errorMessage', 'form must filled');
            return false;
        } else {
            handleState('errorMessage', '');
            formIsValid = true;
        }

        if (ValidationPassword(statePage.password)) {
            formIsValid = false;
            handleState('errorMessage', 'Only Letters and length must best min 8 Characters and Max 22 Characters');
            return false;
        } else {
            handleState('errorMessage', '');
            formIsValid = true;
        }

        return formIsValid;
    };

    const handleLogin = async () => {
        if (handleValidation() === false) return
        const a = CheckAllFields([statePage.email, statePage.password], ["Email", "Password"]);
        if (a !== "") handleState('errorMessage', a);
        else {
            try {
                let body = { email: statePage.email, password: statePage.password };
                if (statePage.rememberMe === true) {
                    let encValUsername = CryptoJS.AES.encrypt(statePage.email, "bcap-gapih.dashboard.com").toString();
                    let encValPassword = CryptoJS.AES.encrypt(statePage.password, "bcap-gapih.dashboard.com").toString();
                    // cookiesGlobal.set('_udxtrn', encValUsername, { path: '/',  });
                    // cookiesGlobal.set('_pdxstr', encValPassword, { path: '/',  });
                    localStorage.setItem('_udxtrn', encValUsername)
                    localStorage.setItem('_pdxstr', encValPassword)
                } else {
                    // cookiesGlobal.remove('_udxtrn', { path: '/',  });
                    // cookiesGlobal.remove('_pdxstr', { path: '/',  });

                    localStorage.removeItem('_udxtrn')
                    localStorage.removeItem('_pdxstr')
                }
                let resData = await API.post({
                    pathCustom: `${AllPath.userApp}/login`,
                    bodyCustom: body,
                    selectUrl: urlSelection.dashboard,
                    useToken: null,
                    needLoading: false,
                });
                const { userId, token } = resData.data;
                dispatchGlobal(tokenAction({ token }));
                let bodyGet = await API.get({
                    pathCustom: `${AllPath.userApp}/${userId}`,
                    bodyCustom: null,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: false,
                })
                const { privilegeId, userLevel, memberId } = bodyGet.data;
                let bodyGet2 = await API.get({
                    pathCustom: `${AllPath.privilegeDetail}/${privilegeId}`,
                    bodyCustom: null,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: false,
                })
                let bodyGet3 = await API.get({
                    pathCustom: `${AllPath.member}`,
                    bodyCustom: null,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: false,
                })
                let encValUserDetail = CryptoJS.AES.encrypt(JSON.stringify(resData.data), "bcap-gapih.dashboard.com").toString();
                // cookiesGlobal.set('_userxxxx', encValUserDetail, { path: '/', });
                localStorage.setItem('_userxxxx', encValUserDetail)
                let encValRandomData = CryptoJS.AES.encrypt('random data', "bcap-gapih.dashboard.com").toString();
                // cookiesGlobal.set('_passxxxx', encValRandomData, { path: '/', });
                localStorage.setItem('_passxxxx', encValRandomData,)
                dispatchGlobal(setRoleByMemberIdAction({ memberId: memberId, data: bodyGet3.data }));
                dispatchGlobal(loginAction({ user: resData.data, privilegeId: privilegeId, dataPrivilegeAccess: bodyGet2.data, userLevel: userLevel, memberId: memberId }))

            } catch (error) {
                handleState('errorMessage', error)
            }
        }
    };

    const height_ = window.innerHeight;

    return (
        <div className="w-100 bg-ac" style={{ height: `${height_}px` }}>

            <div className="row d-flex justify-content-center h-100persen" style={{ backgroundImage: `url(${bgLogin})` }}>
                <div className="col-md-4 bg-ff bd-ea w-400px h-600px" >
                    <div className="text-center my-3">
                        <Card.Img className="w-250px" src={logo2} />
                    </div>
                    <div className="text-center fs-lato-18px-normal my-2">LOGIN</div>
                    <div>
                        <div className="form-group font-11">
                            <FormLabel className="c-54 text-uppercase  s-1214 w-100" htmlFor="email-input">USERNAME/EMAIL/ID</FormLabel>
                            <FormLabel className="container-custom-input" htmlFor="email-input">
                                {userIcon}
                                <input value={statePage.email} type="email" className="custom-input mx-2" id="email-input" placeholder="Enter email" onChange={(event) => handleState('email', event.target.value)} />
                            </FormLabel>
                        </div>
                        <div className="form-group">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="col-6"><FormLabel className="c-54 text-uppercase  s-1214 w-100" htmlFor="password-input">PASSWORD</FormLabel></div>
                                <div className="col-6 text-end btn btn-link font-11 text-danger text-decoration-none s-1214" onClick={() => props.setShowForgotPassword(true)}>FORGOT PASSWORD</div>
                            </div>
                            <FormLabel className="container-custom-input" htmlFor="password-input">
                                {padlockIcon}
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={statePage.password}
                                    className="custom-input mx-2" id="password-input" placeholder="Password" onChange={(event) => handleState('password', event.target.value)} />

                                <button
                                    className="btn"
                                    type="button"
                                    onClick={handlePasswordToggle}
                                >
                                    {
                                        showPassword ?
                                            <FaEyeSlash />
                                            :
                                            <FaEye />
                                    }
                                </button>
                            </FormLabel>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="form-group col-6 mx-20 d-flex align-items-center">
                                <input type="checkbox" id="rem_" className="form-check-input"
                                    checked={statePage.rememberMe}
                                    onChange={(e) => handleState('rememberMe', !(statePage.rememberMe))} />
                                <label htmlFor="rem_" className="form-check-label ms-2" >
                                    Remember me
                                </label>
                            </div>
                            <ButtonLogin handleLogin={handleLogin} />
                        </div>
                    </div>

                    {
                        statePage.errorMessage !== "" &&
                        <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                            {errNotificationIcon}
                            <p className='px-1 s-1418 Lato-600 c-e87'>{statePage.errorMessage}</p>
                        </div>
                    }
                    <div className="text-center mt-5">
                        <select className="custom-select font-12 mb-20 wd-120">
                            <option>English</option>
                            <option>Indonesia</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

const initialState: any = {
    email: '',
    password: '',
    errorMessage: '',
    rememberMe: false,
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'user':
            return {
                ...state,
                email: action.value.email,
                password: action.value.password,
            };
        case 'email':
            return {
                ...state,
                email: action.value,
            };
        case 'password':
            return {
                ...state,
                password: action.value,
            };

        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'rememberMe':
            return {
                ...state,
                rememberMe: action.value,
            };
        default:
            throw new Error();
    }
}
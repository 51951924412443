import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonDelete, ButtonEdit, InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { InterfaceOptionsBatchProgram } from '../File/InterfaceOptionsBatchProgram';
import { ModalDraggableIeBatchProgram } from '../File/ModalDraggableIeBatchProgram';
import { TableRunScheduler } from './TableRunScheduler';
import { ModalRunScheduler } from './ModalRunScheduler';
import Select from 'react-select';
import { inputStyle, inputStyleReadOnly, labelStyle } from '../../Styles';
import { errNotificationIcon, icon_search_white } from '../../Assets/Images/svg';
import { Page42Title } from '../../State/Menu/TitleMenu';
import { ShowModalInterface } from '../../Interface';
import { InterfaceOptionsRunScheduler } from './InterfaceOptionsRunScheduler';
import { CheckAllFields } from '../../Utils';

interface interfaceBatchParams {
    name: string | null;
    type: string | null;
    value: string | null;
}
interface interfaceBatchRun {
    id: null | number | string;
    batchProgId: string | null;
    batchName: string | null;
    batchDesc: string | null;
    status: string | null;
    startExecution: string | null;
    stopExecution: string | null;
    duration: number | null;
    exitCode: string | null;
    lastParametersExecution: string | null;
    batchStatus: string | null;
    batchParam: interfaceBatchParams[]
}
export class ModelBatchRun {
    id: null | number | string;
    batchProgId: string | null;
    batchName: string | null;
    batchDesc: string | null;
    status: string | null;
    startExecution: string | null;
    stopExecution: string | null;
    duration: number | null;
    exitCode: string | null;
    lastParametersExecution: string | null;
    batchStatus: string | null;
    batchParam: interfaceBatchParams[]

    constructor(value: interfaceBatchRun) {
        this.id = value.id;
        this.batchProgId = value?.batchProgId ?? null;
        this.batchName = value?.batchName ?? null;
        this.batchDesc = value?.batchDesc ?? null;
        this.status = value?.status ?? null;
        this.startExecution = value?.startExecution ?? null;
        this.stopExecution = value?.stopExecution ?? null;
        this.duration = value?.duration ?? null;
        this.batchProgId = value?.batchProgId ?? null;
        this.exitCode = value?.exitCode ?? null;
        this.lastParametersExecution = value?.lastParametersExecution ?? null;
        this.batchStatus = value?.batchStatus ?? null;
        this.batchParam = value?.batchParam ?? null;
    }
}


interface interfaceJobRun {
    id: number | null;
    batchId: number | null;
    batchProgramId: string | null;
    batchPreRequisite: string | null;
    batchStatus: string | null;
    batchStatusCode: string | null;
    batchLastExec: string | null;
    createProgId: string | null;
    updateProgId: number | null;
    createDttm: string | null;
    updateDttm: string | null;
    batchParams: string | null;
    batchRegType: string | null;
    batchRegTypeCode: string | null;
    batchRerunManuallyDttm: string | null;
    batchPlannedDttm: string | null;
    batchRunTime: string | null;
    batchParameterValue: string | null;
    batchExecutionType: string | null;
    batchExecutionCode: string | null;
    batchBusinessGroup: string | null;
    batchBusinessGroupCode: string | null;
    batchGroupPath: string | null;
    batchKillYn: string | null;
    batchInterval: number | null;
    batchFlag: string | null;
}

export class ModelJobRun {
    id: number | null;
    batchId: number | null;
    batchProgramId: string | null;
    batchPreRequisite: string | null;
    batchStatus: string | null;
    batchStatusCode: string | null;
    batchLastExec: string | null;
    createProgId: string | null;
    updateProgId: number | null;
    createDttm: string | null;
    updateDttm: string | null;
    batchParams: string | null;
    batchRegType: string | null;
    batchRegTypeCode: string | null;
    batchRerunManuallyDttm: string | null;
    batchPlannedDttm: string | null;
    batchRunTime: string | null;
    batchParameterValue: string | null;
    batchExecutionType: string | null;
    batchExecutionCode: string | null;
    batchBusinessGroup: string | null;
    batchBusinessGroupCode: string | null;
    batchGroupPath: string | null;
    batchKillYn: string | null;
    batchInterval: number | null;
    batchFlag: string | null;

    constructor(value: interfaceJobRun) {
        this.id = value.id;
        this.batchId = value?.batchId ?? null;
        this.batchProgramId = value?.batchProgramId ?? null;
        this.batchPreRequisite = value?.batchPreRequisite ?? null;
        this.batchStatus = value?.batchStatus ?? null;
        this.batchStatusCode = value?.batchStatusCode ?? null;
        this.batchLastExec = value?.batchLastExec ?? null;
        this.createProgId = value?.createProgId ?? null;
        this.updateProgId = value?.updateProgId ?? null;
        this.createDttm = value?.createDttm ?? null;
        this.updateDttm = value?.updateDttm ?? null;
        this.batchParams = value?.batchParams ?? null;
        this.batchRegType = value?.batchRegType ?? null;
        this.batchRegTypeCode = value?.batchRegTypeCode ?? null;
        this.batchRerunManuallyDttm = value?.batchRerunManuallyDttm ?? null;
        this.batchPlannedDttm = value?.batchPlannedDttm ?? null;
        this.batchRunTime = value?.batchRunTime ?? null;
        this.batchParameterValue = value?.batchParameterValue ?? null;
        this.batchExecutionType = value?.batchExecutionType ?? null;
        this.batchExecutionCode = value?.batchExecutionCode ?? null;
        this.batchBusinessGroup = value?.batchBusinessGroup ?? null;
        this.batchBusinessGroupCode = value?.batchBusinessGroupCode ?? null;
        this.batchGroupPath = value?.batchGroupPath ?? null;
        this.batchKillYn = value?.batchKillYn ?? null;
        this.batchInterval = value?.batchInterval ?? null;
        this.batchFlag = value?.batchFlag ?? null;
    }
}



export const RunScheduler = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${search}${limit}${offset}`;
        API.get({
            bodyCustom: null,
            pathCustom: `api/batch/all`,
            selectUrl: urlSelection.batch,
            useToken: true,
        }).then((response: any) => {
            dispatch({ type: 'dataOptionsBatchList', value: response.data });
        })
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsRunScheduler, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    const exportToCSVComponent = () => { }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelJobRun) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const submitRun = () => {
        if (state.selectedBatchData?.batchParam.length > 0) {
            let dataLeft: any[] = []
            let dataRight: any[] = []
            let dataBody: any = {}
            dataBody.batchName = state.selectedBatchName
            state.selectedBatchData?.batchParam.forEach((item: interfaceBatchParams) => {
                if (item.type === 'm') {
                    dataLeft.push(item.value)
                    dataRight.push(item.name?.toString().toUpperCase())
                }
                dataBody[item.name!] = item.value;
            })
            const a = CheckAllFields(dataLeft, dataRight);
            if (a !== '') {
                handleStateComponent('errorMessage', a);
            } else {
                handleStateComponent('errorMessage', ``);
                API.post({
                    bodyCustom: dataBody,
                    pathCustom: `api/batch/run`,
                    selectUrl: urlSelection.batch,
                    useToken: true,
                    needLoading: true,
                    messageErrorInData: true,
                }).then((response) => {
                    initFunctionCustom(true);
                }).catch((err) => handleStateComponent('errorMessage', err))
            }
        } else {
            let dataBody = {
                "batchName": state.selectedBatchName,
            }
            handleStateComponent('errorMessage', ``);
            API.post({
                bodyCustom: dataBody,
                pathCustom: `api/batch/run`,
                selectUrl: urlSelection.batch,
                useToken: true,
                needLoading: true,
                messageErrorInData: true,
            }).then((response) => {
                initFunctionCustom(true);
            }).catch((err) => handleStateComponent('errorMessage', err))
        }
    }


    const customButtonAction = (item: any, width: string | any) => {
        return (
            <></>
        );
    };
    const filterComponentPage = () => {
        let dataFilter = [...state.dataOptionsBatchList].map((item: any) => {
            return {
                value: `${item.batchProgId}-${item.jobId}`,
                label: `${item.batchProgId}-${item.jobId}`,
                batchName: item.batchName,
                all: item
            }
        })

        return (
            <>
                <div className='d-flex flex-column  my-2'>
                    <div className="row px-2">
                        <div className="col-4 py-4 ">
                            <p className={labelStyle}>Batch List</p>
                            <Select options={dataFilter} value={dataFilter.filter((option: any) => option.value === state.selectedBatchId)} onChange={(e) => {
                                handleState("selectedBatchId", e!.value, dispatch)
                                handleState("selectedBatchName", e!.batchName, dispatch)
                                handleState("selectedBatchData", e!.all, dispatch)
                                handleState("errorMessage", '', dispatch)
                            }} />
                            <p className={labelStyle}>Status</p>
                            <input value={state.selectedBatchData?.status} className={inputStyleReadOnly} />
                            <p className={labelStyle}>Batch Status</p>
                            <input value={state.selectedBatchData?.batchStatus} className={inputStyleReadOnly} />
                            <p className={labelStyle}>Exit Code</p>
                            <input value={state.selectedBatchData?.exitCode} className={inputStyleReadOnly} />
                            <p className={labelStyle}>Start Execution</p>
                            <input value={state.selectedBatchData?.startExecution} className={inputStyleReadOnly} />
                            <p className={labelStyle}>Stop Execution</p>
                            <input value={state.selectedBatchData?.stopExecution} className={inputStyleReadOnly} />
                            {
                                state.errorMessage !== '' &&
                                <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit mt-4'>
                                    {errNotificationIcon}
                                    <p className='px-2 s-1418 Lato-600 c-e87'>{state.errorMessage}</p>
                                </div>
                            }
                            <div className='row p-0 my-3'>
                                <div className='d-flex align-items-center p-0'>
                                    <button className="d-flex align-items-center px-3 py-2 br-10 bd-d4 bg-e8 bd-29" onClick={submitRun}>
                                        <p className="s-1418 c-ff Lato-600 mx-2" >Launch</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 py-4 bd-ea-left-right">
                            {
                                state.selectedBatchData !== null ?
                                    state.selectedBatchData.batchParam.length > 0 ?
                                        state.selectedBatchData.batchParam.map((item: interfaceBatchParams) => {
                                            return (
                                                <>
                                                    <p className={labelStyle}>{item.name} {item.type === 'm' ? <span className='text-danger'>*</span> : <></>}</p>
                                                    <input value={item.value ?? ''} className={inputStyle} onChange={(e) => dispatch({ type: 'changeSelectedBatchData', value: { name: item.name, value: e.target.value } })} />
                                                    {/* <input value={item.value ?? ''} className={inputStyleReadOnly} onChange={(e) => dispatch({ type: 'changeSelectedBatchData', value: 'test' })} /> */}
                                                    {/* <input value={item.value ?? ''} className={inputStyleReadOnly} onChange={(e) => console.log('test')} /> */}
                                                </>
                                            )
                                        })
                                        :
                                        <></>
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                </div>
            </>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableRunScheduler
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}  
                
                //
                currentPage={state.currentPage}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <></>
        );
        return (
            <ModalRunScheduler
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {/* {
                    state.showModal.map((element1: any) => {
                        return (
                            <ModalDraggableQRStandardElement
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                } */}
            </>
        )
    }

    return (
        <>
            <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={false}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={false}
                needAddButton={false}
                needSearchFunction={true}
                needDownloadButton={false}
                //
                needComponentHeaderTableCenter={true}
            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'batch/run-batch-job-detail',
    titlePage: Page42Title,
    privilegeAccess: { view: "191", add: "192", edit: "193", delete: "194", download: "195" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Batch Program Id", width: "wd-150", val: "batchProgramId" },
        { field: "Batch Pre Requisite", width: "wd-150", val: "batchPreRequisite" },
        { field: "Batch Status", width: "wd-150", val: "batchStatus" },
        { field: "Batch Last Exec", width: "wd-150", val: "batchLastExec" },
        { field: "Create Prog Id", width: "wd-150", val: "createProgId" },
        { field: "Update Prog Id", width: "wd-150", val: "updateProgId" },
        { field: "Create Dttm", width: "wd-150", val: "createDttm" },
        { field: "Update Dttm", width: "wd-150", val: "updateDttm" },
        { field: "Batch Params", width: "wd-150", val: "batchParams" },
        { field: "Batch Reg Type", width: "wd-150", val: "batchRegType" },
        { field: "Batch Rerun Manually Dttm", width: "wd-150", val: "batchRerunManuallyDttm" },
        { field: "Batch Planned Dttm", width: "wd-150", val: "batchPlannedDttm" },
        { field: "Batch Run Time", width: "wd-150", val: "batchRunTime" },
        { field: "Batch Parameter Value", width: "wd-150", val: "batchParameterValue" },
        { field: "Batch Execution Type", width: "wd-150", val: "batchExecutionType" },
        { field: "Batch Execution Code", width: "wd-150", val: "batchExecutionCode" },
        { field: "Batch Business Group", width: "wd-150", val: "batchBusinessGroup" },
        { field: "Batch Group Path", width: "wd-150", val: "batchGroupPath" },
        { field: "Batch Kill Yn", width: "wd-150", val: "batchKillYn" },
        { field: "Batch Interval", width: "wd-150", val: "batchInterval" },
        { field: "Batch Flag", width: "wd-150", val: "batchFlag" },
    ],
    complete_toggle: ["no",  "batchProgramId", "batchPreRequisite", "batchStatus", "batchLastExec", "createProgId", "updateProgId", "createDttm", "updateDttm", "batchParams", "batchRegType", "batchRerunManuallyDttm", "batchPlannedDttm", "batchRunTime", "batchParameterValue", "batchExecutionType", "batchExecutionCode", "batchBusinessGroup", "batchGroupPath", "batchKillYn", "batchInterval", "batchFlag"],
    toggle: ["no",  "batchProgramId", "batchPreRequisite", "batchStatus", "batchLastExec", "createProgId", "updateProgId", "createDttm", "updateDttm", "batchParams", "batchRegType", "batchRerunManuallyDttm", "batchPlannedDttm", "batchRunTime", "batchParameterValue", "batchExecutionType", "batchExecutionCode", "batchBusinessGroup", "batchGroupPath", "batchKillYn", "batchInterval", "batchFlag"],
    list_column_name: ["No.", "Batch Program Id", "Batch Pre Requisite", "Batch Status", "Batch Last Exec", "Create Prog Id", "Update Prog Id", "Create Dttm", "Update Dttm", "Batch Params", "Batch Reg Type", "Batch Rerun Manually Dttm", "Batch Planned Dttm", "Batch Run Time", "Batch Parameter Value", "Batch Execution Type", "Batch Execution Code", "Batch Business Group", "Batch Group Path", "Batch Group Path", "Batch Kill Yn", "Batch Interval", "Batch Flag"],
    //
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Batch ID, Batch Parameter Value',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 4000,
    //
    selectedBatchId: null,
    selectedBatchName: null,
    selectedBatchData: null,
    //
    errorMessage: "",
    dataOptionsBatchList: []

};


// memberConnId ModelBatchRun
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelJobRun) => {
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelJobRun) => {
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelJobRun) => {
                        showDataResult.push(
                            { idModal: element1.id!!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'dataOptionsBatchList':
            return {
                ...state,
                dataOptionsBatchList: action.value
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        //
        case 'selectedBatchId':
            return {
                ...state,
                selectedBatchId: action.value
            };
        //
        case 'selectedBatchName':
            return {
                ...state,
                selectedBatchName: action.value
            };

        case 'selectedBatchData':
            return {
                ...state,
                selectedBatchData: action.value
            };

        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        //

        case 'changeSelectedBatchData':
            let updatedDataBatchData: any[] = [];
            if (state.selectedBatchData.batchParam.length) {
                let newData = state.selectedBatchData.batchParam.map((item: interfaceBatchParams) => {
                    if (item.name === action.value.name) {
                        // Assign the new value to the 'value' property
                        return {
                            ...item,
                            value: action.value.value // Update with your desired new value
                        };
                    } else {
                        return item;
                    }
                })
                updatedDataBatchData = [...newData]
            }
            return {
                ...state,
                selectedBatchData: {
                    ...state.selectedBatchData,
                    batchParam: updatedDataBatchData
                }
            };
        default:
            throw new Error();
    }
}

export default RunScheduler



import { Page1Title } from "../../../State/Menu/TitleMenu";

export const initialState = {
    titlePage: Page1Title,
    listReasonCode: [], // status transaction
    data: [],
    privilegeAccess: { view: '471', add: '', edit: '', delete: '', download: '' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Product Type', width: 'wd-150', val: 'productType' },
        { field: 'Issuer', width: 'wd-150', val: 'issuerName' },
        { field: 'Issuer Switcher', width: 'wd-150', val: 'issuerSwitcherName' },
        { field: 'Acquirer Switcher', width: 'wd-150', val: 'acquirerSwitcherName' },
        { field: 'Acquirer', width: 'wd-150', val: 'acquirerName' },
        { field: 'Product Feature', width: 'wd-150', val: 'productFeatureName' },
        { field: 'Total Amount', width: 'wd-150', val: 'transAmount' },
        { field: 'Transaction Count', width: 'wd-150', val: 'transCount' },
        { field: 'Status', width: 'wd-150', val: 'transStatus' },
        { field: 'Last Transaction Time', width: 'wd-150', val: 'fullCreateDate' },
        // { field: 'Action', width: 'wd-100', val: 'action' },
    ],
    toggle: ['no', 'productType', 'issuerName', 'issuerSwitcherName', 'acquirerSwitcherName', 'acquirerName', 'productFeatureName', 'transAmount', 'transCount', 'transStatus', 'fullCreateDate'],
    complete_toggle: ['no', 'productType', 'issuerName', 'issuerSwitcherName', 'acquirerSwitcherName', 'acquirerName', 'productFeatureName', 'transAmount', 'transCount', 'transStatus', 'fullCreateDate'],
    list_column_name: ['No.', 'Product Type', 'Issuer', 'Issuer Switcher', 'Acquirer Switcher', 'Acquirer', 'Product Feature', 'Total Amount', 'Transaction Count', 'Status', 'Last Transaction Time'],
    deleteItem: false,
    dataModal: [],
    SearchByPlaceHolder: 'Issuer, Issuer Switcher, Acquirer Switcher, Acquirer, Currency Code',


    id: '',
    search: '',
    view: 15,
    position: 15,
    list_view: [15, 20],
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    // 
    widthTable: 1700,
    //
    transDivided: [],
    filterStartDate: '',
    filterEndDate: '',
    //
    selectedProductId: '',
    selectedProductLabel: '',
    selectedProductSendToApi: '',
    //
    selectedProductFeatureId: '',
    selectedProductFeatureLabel: '',
    selectedProductFeatureSendToApi: '',
};


import { useDispatch, useSelector } from "react-redux";
import { ButtonDelete, ButtonEdit, TableWithLimitOffset } from "../../Components";
import { useNavigate } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import { API, urlSelection } from '../../Services/API';
import { ShowModalInterface } from '../../Interface';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { ModelAlertParameters } from "./Utils/ModelAlertParameters";
import { CheckUndefined, ExportCSV } from '../../Utils';
import { Page59Title } from "../../State/Menu/TitleMenu";
import { TableAlertParameters } from "./Components/Table/TableAlertParameters";
import { ModalDraggableAlertParameters } from "./Components/Modal/ModalDraggableAlertParameters";
import { InterfaceOptionsAlertParameters } from "./Utils/InterfaceOptionsAlertParamters";
import { ModalAlertParameters } from "./Components/Modal/ModalAlertParameters";
import { TableAlertParameterNew } from "./Components/Table/TableAlertParameterNew";
import { TableAlertParameterNew2 } from "./Components/Table/TableAlertParameterNew2";
import { Divider } from "antd";
import { deleteData2 } from "../../State/All";

const ExcelHmacManagement = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
   
    dataFromAPI.map((item: any, i: any) => {
        let showHashType = '';
        let showEnvironmentType = '';
        let showCommonStatus = '';
        if (stateOptions.optionsHashType.length > 0) {
            stateOptions.optionsHashType.forEach((element2: any) => {
                if (element2.value === item.hmacHashType) {
                    showHashType = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsEnvironmentType.length > 0) {
            stateOptions.optionsEnvironmentType.forEach((element2: any) => {
                if (element2.value === item.hmacEnvType) {
                    showEnvironmentType = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.hmacStatus) {
                    showCommonStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            memberName: CheckUndefined(item.memberName),
            memberCode: CheckUndefined(item.memberCode),
            hmacIncKeyVal: CheckUndefined(item.hmacIncKeyVal),
            hmacOutKeyValue: CheckUndefined(item.hmacOutKeyValue),
            hmacHashType: CheckUndefined(showHashType),
            hmacEnvType: CheckUndefined(showEnvironmentType),
            hmacStatus: CheckUndefined(showCommonStatus),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};

const HitAPI = (urlAPI: string, condition: Boolean | null, finalParameterAPI: string, dispatchPage: any) => {
    if (condition === true) {
        API.get({
            bodyCustom: null,
            pathCustom: `${urlAPI}?${finalParameterAPI.slice(0, -1)}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            dispatchPage({ type: 'searchData', value: response.data });
            dispatchPage({ type: 'totalPages', value: response.detail.totalPage });
            dispatchPage({ type: 'totalData', value: response.detail.total });
        })
    } else {
        API.get({
            bodyCustom: null,
            pathCustom: `${urlAPI}?${finalParameterAPI.slice(0, -1)}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            dispatchPage({ type: 'data', value: response.data });
            dispatchPage({ type: 'totalPages', value: response.detail.totalPage });
            dispatchPage({ type: 'totalData', value: response.detail.total });
        })
    }
}

const CheckPrivilegePage = (privilegeAccess: any, privilegeAccessViewPage: string, navigate: any) => {
    if (privilegeAccess !== null) {
        if (privilegeAccess.includes(privilegeAccessViewPage) === false) {
            navigate('/warning', { replace: true })
        }
    }
}
const AlertParameters = ({ ...props }) => {
     const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [state2, dispatch2] = useReducer(reducer2, initialState2);

    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        CheckPrivilegePage(privilegeAccess, state.privilegeAccess.view, navigate)
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let finalParameter = `${searchValue}${limit}${offset}`;
        let finalParameter2 = `${searchValue}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        HitAPI(state.urlApi, needRefreshPage, finalParameter, dispatch);
    }
    const initFunctionCustom2 = (needRefreshPage: Boolean | null) => {
        let limit = `limit=${state2.view}&`;
        let offset =`offset=${pagination.pageIndex * state.view}&`;
        let finalParameter = `${limit}${offset}`;
        HitAPI(state2.urlApi, needRefreshPage, finalParameter, dispatch2);
    }

    useEffect(() => {
        initFunctionCustom(false);
    }, [pagination.pageIndex]);

    useEffect(() => {
        setPagination({pageIndex: pagination.pageIndex, pageSize:state.view});
    }, [state.view]);


    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsAlertParameters, dispatchGlobal);
    }, [])

    useEffect(() => {
        initFunctionCustom(false)
        initFunctionCustom2(false)
    }, [state.position, state.view])

    useEffect(() => {
        initFunctionCustom(true)
        initFunctionCustom2(true)
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelHmacManagement(state, stateOptions, response.data)
        })
    }
    //
    const addDataComponent = () => addData(dispatch)

    const deleteDataComponent = (item: ModelAlertParameters) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const reloadDataComponent2 = (needRefreshPage: any) => initFunctionCustom2(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)
    const actionAfterUpdateItem2 = (updateItemID: any) => initFunctionCustom2(false)


    const customButtonAction = (item: ModelAlertParameters) => {
        return (
            <div className={`d-flex justify-content-around align-items-center`}>
                <span style={{ marginRight: '20px' }}><ButtonEdit handleClick={() => { actionShowModal(item.id, item)}} codeAccessPrivilege={state.privilegeAccess.edit} /> </span>
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
            </div>
        );
    };

    const filterComponentPage = () => <></>

    const tableComponentPage = () => {
        return (
            <TableAlertParameters
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                actionShowModal={actionShowModal}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                currentPage={state.currentPage}
            />
        )
    }

    const modalAddDeleteComponentPage = () => {
        // return <></>
        return (
            <ModalAlertParameters
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                svcId={state2.svcId}
                prodInd={state2.prodInd}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />

        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableAlertParameters
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                actionAfterUpdateItem2={actionAfterUpdateItem2}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            {/* <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={true}
                needSearchFunction={true}
                needDownloadButton={true}
            /> */}
            <TableAlertParameterNew
                state={state}
                stateOptions={stateOptions}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                deleteDataComponent={deleteDataComponent}
                addData={addDataComponent}
                codeAccessPrivilegeAdd={state.privilegeAccess.add}
                // handleStateGlobal={handleStateGlobal}
                // resetFilterStatus={resetFilterStatus}
                initFunctionCustom={initFunctionCustom}
                // handleNavigation={handleNavigation}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                //
                currentPage={state.currentPage}
                dispatch={dispatch2}
            />
            <TableAlertParameterNew2
                state={state2}
                stateOptions={stateOptions}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                deleteDataComponent={deleteDataComponent}
                addData={addDataComponent}
                // handleStateGlobal={handleStateGlobal}
                // resetFilterStatus={resetFilterStatus}
                initFunctionCustom={initFunctionCustom2}
                // handleNavigation={handleNavigation}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                codeAccessPrivilegeAdd={state.privilegeAccess.add}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                //
                currentPage={state.currentPage}
                dispatch={dispatch2}
            />

            <ModalAlertParameters
                visibleModalAddDelete={state.visibleModalAddDelete}
                isProcTime={state2.isProcTime}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                deleteItem2={state2.deleteItem}
                id={state.id}
                svcId={state2.svcId}
                prodInd={state2.prodInd}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                urlApi2={state2.urlApi}
                reloadData={() => reloadDataComponent(false)}
                reloadData2={() => reloadDataComponent2(false)}
                stateOptions={stateOptions}
            />
            {
                state.savedShowModal.map((element1: any) => {
                    return (
                        <ModalDraggableAlertParameters
                            key={element1.idModal}
                            id={element1.idModal}
                            index={element1.index}
                            idModal={element1.idModal}
                            state={state}
                            urlApi={state.urlApi}
                            showModal={element1.showModal}
                            reloadData={() => reloadDataComponent(false)}
                            reloadData2={() => reloadDataComponent(false)}
                            actionShowModal={() => actionShowModal(element1.idModal, element1)}
                            actionIndexModal={() => actionIndexModal(element1.idModal)}
                            actionAfterUpdateItem={actionAfterUpdateItem}
                            actionAfterUpdateItem2={actionAfterUpdateItem2}
                            itemModal={element1.itemModal}
                            stateOptions={stateOptions}
                        />
                    )
                }
                )
            }
            {/* <CustomTable state={state} dispatch={dispatch} /> */}
        </>
    );
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState = {
    urlApi: 'alert-trans-params',
    titlePage: `${Page59Title}`,
    privilegeAccess: { view: '901', add: '902', edit: '903', delete: '904', download: '905' },
    label: [
        { showField: true, field: 'No.', width: 'wd-50', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'no' },
        { showField: true, field: 'Source Member', width: 'wd-210', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'srcMemberName' },
        { showField: true, field: 'Dest Member', width: 'wd-210', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'dstMemberName' },
        { showField: true, field: 'Threshold Count', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'alertThreshCount' },
        { showField: true, field: 'Threshold Percent', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'alertThreshPercent' },
        { showField: true, field: 'Type', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'alertType' },
        { showField: true, field: 'On/Off', width: 'wd-50', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'alertOnOff' },
        { showField: true, field: 'Action', width: 'wd-150', widthColumn: 150, maxWidth: 150, minWidth: 150, val: 'action' },
    ],
    toggle: ['no', 'srcMemberName', 'dstMemberName', 'alertThreshCount', 'alertThreshPercent', 'alertType', 'alertOnOff', 'action'],
    complete_toggle: ['no', 'srcMemberName', 'dstMemberName', 'alertThreshCount', 'alertThreshPercent', 'alertType', 'alertOnOff', 'action'],
    list_column_name: ['No.', 'Source Member', 'Dest Member', 'Threshold Count', 'Threshold Percent', 'Type', 'On/Of'],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Source Member, Dest Member',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1500,
    dataLength: 0, // zIndex
    finalAPI: '',
};

// hmacId
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelAlertParameters) => {
                        element1.id = element1.alertTransParamsId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelAlertParameters) => {
                        element1.id = element1.alertTransParamsId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelAlertParameters) => {
                        showDataResult.push(
                            { idModal: element1.alertTransParamsId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.alertTransParamsId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}
const initialState2 = {
    urlApi: 'alert-proc-time-params',
    titlePage: `${Page59Title}`,
    privilegeAccess: { view: '901', add: '902', edit: '903', delete: '904', download: '905' },

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Source Member, Dest Member',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1500,
    dataLength: 0, // zIndex
    finalAPI: '',
};

function reducer2(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            return {
                ...state,
                data: action.value,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            // if (action.value != null) {
            //     if (action.value.length > 0) {
            //         action.value.forEach((element1: ModelAlertParameters) => {
            //             element1.id = element1.alertTransParamsId;
            //             dataWhereChangeToIdGeneral.push(element1);
            //         })
            //     }
            // }
            return {
                ...state,
                data: action.value,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelAlertParameters) => {
                        showDataResult.push(
                            { idModal: element1.alertTransParamsId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.alertTransParamsId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'svcId':
            return {
                ...state,
                svcId: action.value
            };
        case 'prodInd':
            return {
                ...state,
                prodInd: action.value
            };
        case 'updateSvcProd':
            return {
                ...state,
                svcId: action.value.svcId,
                prodInd:action.value.prodInd
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'isProcTime':
            return {
                ...state,
                isProcTime: action.value
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                svcId: action.value.svcId,
                prodInd: action.value.prodInd,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}


export default AlertParameters;
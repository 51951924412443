import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { DatePicker, DatePickerProps } from 'antd';
import { DatePickerRighIcon, FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page17Title } from '../../State/Menu/TitleMenu';
import { RangePickerProps } from 'antd/es/date-picker';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import dayjs from 'dayjs';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';
import moment from 'moment';

export const ModalExchangeRateMember = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMember, optionsCommonStatusDetailCode, optionsRateSetting } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { ratemId, memberId, rateId, ratemStatus, ratemStartDate, ratemEndDate } = statePage
        const data = {
            memberId: `${memberId}`,
            rateId: `${rateId}`,
            ratemStatus: `${ratemStatus}`,
            ratemStartDate: `${ratemStartDate}`,
            ratemEndDate: `${ratemEndDate}`,
        }
        const a = CheckAllFields([memberId, rateId, ratemStatus, ratemStartDate, ratemEndDate], ['Member Id', 'Exchange Rate', 'Status', 'Start Date', 'End Date'])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            let dateStart = new Date(ratemStartDate);
            let dateEnd = new Date(ratemEndDate);
            let listAllDayChart: any[] = [];
            let errorMessage: string = '';
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (ratemStartDate === ratemEndDate) {
                if (statePage.errorMessage !== '') {
                    errorMessage = 'Start Date and End Date cannot be same'
                    handleState('errorMessage', errorMessage);
                }
            } else if (listAllDayChart.length === 0) {
                errorMessage = 'Start date cannot be larger than End Date'
                handleState('errorMessage', errorMessage);
            } else {
                if (statePage.errorMessage !== '') {
                    errorMessage = '';
                    handleState('errorMessage', errorMessage);
                }
            }
            if (errorMessage === '') {
                API.post({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    dispatchStatePage({ type: 'clearData' })
                    setModal("visibleModalAddDelete")
                    reloadData()
                }).catch((err) => handleState('errorMessage', err))
            }
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-600px'>
                    <div className='d-flex min-h-40px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Member Id</p>
                                <Select styles={inputStyleH25} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e: any) => handleState("memberId", e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Exchange Rate</p>
                                <Select styles={inputStyleH25} options={optionsRateSetting} value={optionsRateSetting.filter((option: any) => option.value === statePage.rateId)} onChange={(e: any) => handleState("rateId", e.value)} />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>Start date</p>
                                <DatePickerRighIcon
                                    selected={statePage.ratemStartDate && statePage.ratemStartDate !== ''
                                        ? new Date(`${statePage.ratemStartDate}` || '') 
                                        : null
                                    }
                                    onChange={(value: any) => {
                                        let date = new Date(value!.toString());
                                        let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
                                        let hours = date.getHours().toString().padStart(2, '0');
                                        let minutes = date.getMinutes().toString().padStart(2, '0');
                                        let seconds = date.getSeconds().toString().padStart(2, '0');
                                        let timeNow = `${hours}:${minutes}:${seconds}`;
                                        dispatchStatePage({ type: 'ratemStartDate', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>End date</p>
                                <DatePickerRighIcon
                                    selected={statePage.ratemEndDate && statePage.ratemEndDate !== ''
                                        ? new Date(`${statePage.ratemEndDate}` || '') 
                                        : null
                                    }
                                    onChange={(value: any) => {
                                        let date = new Date(value!.toString());
                                        let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
                                        let hours = date.getHours().toString().padStart(2, '0');
                                        let minutes = date.getMinutes().toString().padStart(2, '0');
                                        let seconds = date.getSeconds().toString().padStart(2, '0');
                                        let timeNow = `${hours}:${minutes}:${seconds}`;
                                        dispatchStatePage({ type: 'ratemEndDate', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })                                   }}
                                />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>Status</p>
                                <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.ratemStatus)} onChange={(e) => handleState("ratemStatus", e.value)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page17Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    ratemId: '',
    memberId: '',
    rateId: '',
    ratemStatus: '001',
    ratemStartDate: `${new Date().toISOString().slice(0, 10)} 00:00:01`,
    ratemEndDate: '2099-12-31 00:00:01',
    memberName: '',
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {


    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                ratemId: '',
                memberId: '',
                rateId: '',
                ratemStatus: '001',
                ratemStartDate: `${new Date().toISOString().slice(0, 10)} 00:00:01`,
                ratemEndDate: '2099-12-31 00:00:01',
                memberName: '',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                ratemId: action.value.ratemId,
                memberId: action.value.memberId,
                rateId: action.value.rateId,
                ratemStatus: action.value.ratemStatus,
                ratemStartDate: action.value.ratemStartDate === null || action.value.ratemStartDate === undefined ? '' : action.value.ratemStartDate.toString().split('T')[0],
                ratemEndDate: action.value.ratemEndDate === null || action.value.ratemEndDate === undefined ? '' : action.value.ratemEndDate.toString().split('T')[0],
                memberName: action.value.memberName,
                errorMessage: '',
            };
        // 
        case 'ratemId':
            return {
                ...state,
                ratemId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'rateId':
            return {
                ...state,
                rateId: action.value,
            };
        case 'ratemStatus':
            return {
                ...state,
                ratemStatus: action.value,
            };
        case 'ratemStartDate':
            return {
                ...state,
                ratemStartDate: action.value,
            };
        case 'ratemEndDate':
            return {
                ...state,
                ratemEndDate: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        // 

        default:
            throw new Error();
    }
}

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-modal';
import { ModelInterfaceFileRecord } from './ModelInterfaceFileRecord';
import { Page37Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalInterfaceFileRecord = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props

    const {
        optionsInterfaceFile,
        optionsInterfaceRecord,
        optionsFileRecordOccurrenceDetailCode,
        optionsMOC,
        optionsInterfaceFileRecordFormatUsedCCDNameAndCCDCode,
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])


    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["interfileRecordName"]
        let limit = [8]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'interfileRecordNameLength' || nameState === 'interfileRecordSortNumber') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 10000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }
    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            interfileRecordId,
            interfaceFileId,
            interfaceFileName,
            recordId,
            recordName,
            interfileRecordSortNumber,
            interfileRecordOccur,
            interfileRecordName,
            interfileRecordNameLength,
            interfileRecordMoc,
            interfaceFileRecordCreateBy,
            interfaceFileRecordCreateDate,
            interfaceFileRecordUpdateBy,
            interfaceFileRecordUpdateDate,
            interfaceFileRecordDeleteBy,
            interfaceFileRecordDeleteDate,
            interfaceFileRecordIsDelete,

        } = statePage.data
        const data = {
            interfileId: interfaceFileId,
            recordId: recordId,
            interfileRecordSortNumber: interfileRecordSortNumber,
            interfileRecordOccur: interfileRecordOccur,
            interfileRecordName: interfileRecordName,
            interfileRecordNameLength: interfileRecordNameLength,
            interfileRecordMoc: interfileRecordMoc,
        }
        const a = CheckAllFields(
            [
                interfaceFileId,
                recordId,
                interfileRecordSortNumber,
                interfileRecordOccur,
                interfileRecordNameLength,
                interfileRecordMoc,
            ],
            [
                'INTERFACE FILE ID',
                'RECORD ID',
                'SORT NUMBER',
                'FILE RECORD OCCURRENCE',
                'INTERFACE FILE RECORD LENGTH',
                'M/O/C',
            ]
        )
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>INTERFACE FILE ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceFile} value={optionsInterfaceFile.filter((option: any) => option.value === statePage.data.interfaceFileId)} onChange={(e) => handleState('interfaceFileId', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>RECORD ID</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceRecord} value={optionsInterfaceRecord.filter((option: any) => option.value === statePage.data.recordId)} onChange={(e) => handleState('recordId', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>SORT NUMBER</p>
                                <input type='number' min={1} value={statePage.data.interfileRecordSortNumber} onChange={(e) => handleState('interfileRecordSortNumber', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>FILE RECORD OCCURRENCE</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileRecordOccurrenceDetailCode} value={optionsFileRecordOccurrenceDetailCode.filter((option: any) => option.value === statePage.data.interfileRecordOccur)} onChange={(e) => handleState('interfileRecordOccur', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>INTERFACE FILE RECORD NAME</p>
                                {/*  <Select styles={inputStyleH25}  placeholder={'(Search)'} options={optionsInterfaceFileRecordFormatUsedCCDNameAndCCDCode} value={optionsInterfaceFileRecordFormatUsedCCDNameAndCCDCode.filter((option: any) => option.value === statePage.data.interfileRecordName)} onChange={(e) => handleState('interfileRecordName', e.value)} /> */}
                                <input value={statePage.data.interfileRecordName} onChange={(e) => handleState('interfileRecordName', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>INTERFACE FILE RECORD LENGTH</p>
                                <input type='number' min={1} value={statePage.data.interfileRecordNameLength} onChange={(e) => handleState('interfileRecordNameLength', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>M/O/C</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMOC} value={optionsMOC.filter((option: any) => option.value === statePage.data.interfileRecordMoc)} onChange={(e) => handleState('interfileRecordMoc', e.value)} />
                            </div>


                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page37Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInterfaceFileRecord({
        id: null,
        interfileRecordId: null,
        interfaceFileId: null,
        interfaceFileName: null,
        recordId: null,
        recordName: null,
        interfileRecordSortNumber: 1,
        interfileRecordOccur: null,
        interfileRecordName: null,
        interfileRecordNameLength: '1',
        interfileRecordMoc: null,
        interfaceFileRecordCreateBy: null,
        interfaceFileRecordCreateDate: null,
        interfaceFileRecordUpdateBy: null,
        interfaceFileRecordUpdateDate: null,
        interfaceFileRecordDeleteBy: null,
        interfaceFileRecordDeleteDate: null,
        interfaceFileRecordIsDelete: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: "",
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceFileRecord) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceFileRecord({
                    id: null,
                    interfileRecordId: null,
                    interfaceFileId: null,
                    interfaceFileName: null,
                    recordId: null,
                    recordName: null,
                    interfileRecordSortNumber: 1,
                    interfileRecordOccur: null,
                    interfileRecordName: null,
                    interfileRecordNameLength: '1',
                    interfileRecordMoc: null,
                    interfaceFileRecordCreateBy: null,
                    interfaceFileRecordCreateDate: null,
                    interfaceFileRecordUpdateBy: null,
                    interfaceFileRecordUpdateDate: null,
                    interfaceFileRecordDeleteBy: null,
                    interfaceFileRecordDeleteDate: null,
                    interfaceFileRecordIsDelete: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceFileRecord({
                    id: action.value.interfileRecordId,
                    interfileRecordId: action.value.interfileRecordId,
                    interfaceFileId: action.value.interfaceFileId,
                    interfaceFileName: action.value.interfaceFileName,
                    recordId: action.value.recordId,
                    recordName: action.value.recordName,
                    interfileRecordSortNumber: action.value.interfileRecordSortNumber,
                    interfileRecordOccur: action.value.interfileRecordOccur,
                    interfileRecordName: action.value.interfileRecordName,
                    interfileRecordNameLength: action.value.interfileRecordNameLength,
                    interfileRecordMoc: action.value.interfileRecordMoc,
                    interfaceFileRecordCreateBy: action.value.interfaceFileRecordCreateBy,
                    interfaceFileRecordCreateDate: action.value.interfaceFileRecordCreateDate,
                    interfaceFileRecordUpdateBy: action.value.interfaceFileRecordUpdateBy,
                    interfaceFileRecordUpdateDate: action.value.interfaceFileRecordUpdateDate,
                    interfaceFileRecordDeleteBy: action.value.interfaceFileRecordDeleteBy,
                    interfaceFileRecordDeleteDate: action.value.interfaceFileRecordDeleteDate,
                    interfaceFileRecordIsDelete: action.value.interfaceFileRecordIsDelete,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

import { productManagementIcon } from "../../../Assets/Images/MenuIcon";
import { Page1ProductRoute, Page2ProductRoute, Page3ProductRoute } from "../../../Routes";
import { Page13Code, Page14Code, Page15Code } from "../CodeMenu";
import { Page13Title, Page14Title, Page15Title } from "../TitleMenu";

export const Menu5 = {
    id: 'product_sidebar',
    label: 'Product',
    icon: productManagementIcon,
    privilegesId: [Page13Code, Page14Code, Page15Code],
    itemSidebar: [
        {
            titleList: Page13Title,
            key: 'productOptions1',
            privilegeId: Page13Code,
            url: Page1ProductRoute,
        },
        {
            titleList: Page14Title,
            key: 'productOptions2',
            privilegeId: Page14Code,
            url: Page2ProductRoute,
        },
        {
            titleList: Page15Title,
            key: 'productOptions3',
            privilegeId: Page15Code,
            url: Page3ProductRoute,
        },
    ],
}
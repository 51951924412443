import { SelectOptionsInterface, ShowModalInterface } from "../../Interface"
import { Button, Divider, Tooltip } from 'antd';
import { useEffect, useReducer, useRef } from 'react';
import { Page41Title, Page53Title } from "../../State/Menu/TitleMenu"
import { ModelBatchScheduler } from "../Job/Models"
import { ModelSchedulerExecution } from "./ModelSchedulerManagement"
import Modal from 'react-modal';
import { COLORS_NODE_JOB, ModalStyle } from "../../Utils";
import { API } from "../../Services";
import { urlSelection } from "../../Services/API";
import Select from 'react-select';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { useDispatch } from "react-redux";
import ModalJobSchedule from "./ModalJobSchedule";
import ModalJobScheduleUpdate from "./ModalJobScheduleUpdate";
import { FooterModalCreate, TableWithLimitOffset } from "../../Components";
import { checklistButtonIcon, close, icon_add } from "../../Assets/Images/svg";
import { inputStyleReadOnly, labelStyle } from "../../Styles";
import EdgeWithButtonFlow from "./TestReactFlow";
import { useLocation, useParams } from "react-router-dom";
import TableSchedulerManagementDetail from "./TableSchedulerManagementDetail";
export const InterfaceOptionsSchedulerManagementDetail: SelectOptionsInterface = {
    ccdOptions: true,
    spfOptions: false,
    memberOptions: false,
    ccgOptions: false,
    logLevel001Options: false,
    logLevel002Options: false,
    routingOptions: false,
    routeSequenceOptions: false,
    privilegeOptions: false,
    countryOptions: false,
    productOptions: false,
    productFeatureOptions: false,
    reasonCodeOptions: false,
    subProductOptions: false,
    exchangeRateOptions: false,
    elementOptions: false,
    qrStandardOptions: false,
    interfaceFieldOptions: false,
    headerFieldOptions: false,
    feeOptions: false,
    interfaceFileOptions: false,
    recordOptions: false,
    interfaceFileItemOptions: false,
    batchOptionsFromAPIBatch: true,
    commonCodeOptions:false,
    resultOptions:false

}


export const SchedulerManagementDetail = () => {
    const childRef = useRef<any>(null);
    const childRef2 = useRef<any>(null);
    const childRef3 = useRef<any>(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const batchExecutionType = searchParams.get('batchExecutionType');
    const batchBusinessGroup = searchParams.get('batchBusinessGroup');

    const [stateTable, dispatchTable] = useReducer(reducerTable, initialStateTable);
    const [stateReactFlow, dispatchStateReactFlow] = useReducer(reducerReactFlow, initialStateReactFlow);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);


    useEffect(() => {
        dispatchStateReactFlow({ type: 'selectedBatchBusinessGroup', value: batchBusinessGroup })
        dispatchStateReactFlow({ type: 'selectedBatchExecutionType', value: batchExecutionType })
    }, [])

    const dispatchGlobal = useDispatch();

    const addDataComponent = () => addData(dispatchTable)
    const deleteDataComponent = (item: ModelBatchScheduler) => deleteData(item.id, item, dispatchTable)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, stateTable)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatchTable)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatchTable, stateTable)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatchTable, stateTable)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatchTable({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatchTable({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = stateTable.search !== '' ? `search=${stateTable.search}&` : '';
        let limit = `limit=${stateTable.view}&`;
        let offset = `offset=${parseInt((stateTable.position - stateTable.view).toString())}&`;
        let selectedBatchExecutionType = `batchExecutionType=${batchExecutionType}&`;
        let selectedBatchBusinessGroup = `batchBusinessGroup=${batchBusinessGroup}&`;
        let finalParameter = `${search}${limit}${offset}${selectedBatchExecutionType}${selectedBatchBusinessGroup}`;
        let finalParameter2 = `${search}`;
        // handleStateComponent('finalAPI', `${stateTable.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${stateTable.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatchTable({ type: 'searchData', value: response?.data });
                dispatchTable({ type: 'totalPages', value: response?.detail?.totalPage });
                dispatchTable({ type: 'totalData', value: response?.detail?.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${stateTable.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatchTable({ type: 'data', value: response?.data });
                dispatchTable({ type: 'totalPages', value: response?.detail?.totalPage });
                dispatchTable({ type: 'totalData', value: response?.detail?.total });
            })
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, stateTable, () => { }, null, InterfaceOptionsSchedulerManagementDetail, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(false)
    }, [stateTable.position, stateTable.view, batchExecutionType, batchBusinessGroup])


    useEffect(() => {
        let currentPage: number = parseInt((stateTable.position / stateTable.view).toString());
        dispatchTable({ type: 'currentPage', value: currentPage });
    }, [stateTable.totalData, stateTable.position, stateTable.view])


    const postDataAPI = () => {
        const { batchList, edgeList } = stateReactFlow;
        let dataBatch: any[] = []
        let dataRelation: any[] = []
        if (batchList.length > 0) {
            batchList.forEach((item: any) => {
                let dataCopyItemBatch = {
                    jobId: `${item.id}`,
                    position: {
                        "x": item.position.x,
                        "y": item.position.y,
                    }
                }
                dataBatch.push(dataCopyItemBatch)
            })
        }
        if (edgeList.length > 0) {
            edgeList.forEach((item: any) => {
                let dataCopyItemRelation = {
                    source: `${item.source}`,
                    target: `${item.target}`,
                }
                dataRelation.push(dataCopyItemRelation)
            })
        }

        const data = { batch: dataBatch, relation: dataRelation };
        if (batchList.length === 0) {
            handleState('errorMessage', 'Batch Job Detail Must Have 1 Data', dispatchStateReactFlow);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `batch/relation`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStateReactFlow({ type: 'visibleModalSaveRelationData', value: false })
                initFunctionCustom(false)
                getDataAllJobDetail(null, 'open');
            }).catch((err) => {
                handleState('errorMessage', err, dispatchStateReactFlow)
            })
        }
    }
    const onConnect = (item: any) => { // need {source : .. , target : ''}
        childRef.current.onConnect(item);
    }

    const removeNode = (id: any) => childRef.current.removeNode(id);

    useEffect(() => {
        let labelSelectedBatchBusinessGroup: string = ''
        let labelSelectedBatchExecutionType: string = ''

        if (stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === stateReactFlow.selectedBatchBusinessGroup).length > 0) {
            labelSelectedBatchBusinessGroup = stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === stateReactFlow.selectedBatchBusinessGroup)[0].label
        }
        if (stateOptions.optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === stateReactFlow.selectedBatchExecutionType).length > 0) {
            labelSelectedBatchExecutionType = stateOptions.optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === stateReactFlow.selectedBatchExecutionType)[0].label
        }
        handleState('selectedBatchGroupPath', (`${labelSelectedBatchBusinessGroup}/${labelSelectedBatchExecutionType}`).toUpperCase(), dispatchStateReactFlow)

    }, [stateReactFlow.selectedBatchBusinessGroup, stateReactFlow.selectedBatchExecutionType])

    const getDataAllJobDetail = (id: any, condition: any) => {
        // condition => add , update, and delete

        let data = {
            batchBusinessGroup: stateReactFlow.selectedBatchBusinessGroup,
            batchExecType: stateReactFlow.selectedBatchExecutionType
        }
        // childRef.current.onAdd(stateReactFlow.selectedBatch);
        API.post({
            bodyCustom: data,
            pathCustom: `${stateReactFlow.urlApiPostBatch}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {

            if (condition === 'delete') {
                // console.log('delete')
                childRef.current.functionRemoveNode(`${id}`);
                initFunctionCustom(false)
            } else if (condition === 'add') {
                // console.log('add')
                if (response?.data.batch !== undefined) {
                    let newNode = response?.data.batch.filter((item: any) => `${item.jobId}` === `${id}`)[0]
                    childRef.current.onAdd(newNode);
                }
                initFunctionCustom(false)
            } else if (condition === 'update') {
                // console.log('update')
                if (response !== undefined) {
                    response?.data.batch.forEach((item: any) => {
                        if (`${id}` === `${item.jobId}`) {
                            let currentValue: any = {}
                            currentValue.value = `${item.jobId}`;
                            currentValue.label = `${item.jobId} - ${item.progId}`;
                            currentValue.status = `${item.status}`;
                            item.value = `${item.jobId}`;
                            item.label = `${item.jobId} - ${item.progId}`;
                            item.status = `${item.status}`;
                            childRef.current.updateNode(currentValue, item);
                        }
                    })
                }
                initFunctionCustom(false)
            } if (condition === 'open') {
                // console.log('open')
                // childRef.current.resetNodesAndEdges();
                if (response?.data !== undefined) {
                    if (response?.data.batch.length > 0) {
                        response?.data.batch.forEach((item: any) => {
                            childRef.current.onAdd(item);
                        })
                    }
                    if (response?.data.relation.length > 0) {
                        response?.data.relation.forEach((item: any) => {
                            item.source = `${item.source}`;
                            item.target = `${item.target}`;
                            item.sourceHandle = null;
                            item.targetHandle = null;
                            onConnect(item);
                        })
                    }
                }
                initFunctionCustom(false)
            }
            // if (id === null) {
            //     if (childRef?.current?.nodes !== undefined) {
            //         if (response !== undefined) {
            //             response?.data.batch.forEach((item: any) => {
            //                 if (`${id}` === `${item.jobId}`) {

            //                 }
            //                 childRef.current.onAdd(item);
            //             })
            //         }
            //     } else {
            //         childRef.current.resetNodesAndEdges();
            //         if (response !== undefined) {
            //             if (response?.data.batch.length > 0) {
            //                 response?.data.batch.forEach((item: any) => {
            //                     childRef.current.onAdd(item);
            //                 })
            //             }
            //             if (response?.data.relation.length > 0) {
            //                 response?.data.relation.forEach((item: any) => {
            //                     item.source = `${item.source}`;
            //                     item.target = `${item.target}`;
            //                     item.sourceHandle = null;
            //                     item.targetHandle = null;
            //                     onConnect(item);
            //                 })
            //             }
            //         }
            //     }

            // } else {

            // }
        }).catch((err) => {
            // handleState('errorMessage', err)
        })
    }

    useEffect(() => {
        getDataAllJobDetail(null, 'open');
    }, [stateReactFlow.selectedBatchBusinessGroup, stateReactFlow.selectedBatchExecutionType])

    useEffect(() => {
        if (stateReactFlow.selectedBatchJobIdUpdate !== null && stateReactFlow.selectedBatchJobIdUpdate !== '') {
            API.get({
                bodyCustom: null,
                pathCustom: `batch/run-batch-v2/${stateReactFlow.selectedBatchJobIdUpdate}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatchStateReactFlow({ type: 'setUpdateData', value: response?.data })
                dispatchStateReactFlow({ type: 'getData', value: response?.data })
            })
        }
    }, [stateReactFlow.selectedBatchJobIdUpdate, stateReactFlow.modalUpdateBatch])


    const customButtonAction = (item: ModelBatchScheduler, width: string | any) => {
        return <></>

    };

    const tableComponentPage = () => {
        return (
            <TableSchedulerManagementDetail
                codeAccessPrivilegeDelete={stateTable.privilegeAccess.delete}
                codeAccessPrivilegeEdit={stateTable.privilegeAccess.edit}
                label={stateTable.label}
                data={stateTable.data}
                position={stateTable.position}
                view={stateTable.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${stateTable.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                currentPage={stateTable.currentPage}
                //
                selectedJobId={stateReactFlow.selectedJobId}
            />
        )
    }
    const filterComponentPage = () => {
        return <></>

    }
    const modalAddDeleteComponentPage = () => {
        return <></>
    }
    const modalUpdateComponentPage = () => {
        return <></>
    }

    

    return (
        <>
            <div className='d-flex flex-column w-100 mt-3'>
                <h1 className='Lato-300 normal s-2024 mb-4'>Details Job Schedule</h1>
            </div>

            <div className='d-flex flex-column justify-content-between' style={{ overflow: 'auto' }}>
                <div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH BUSINESS GROUP <span className='text-danger'>*</span></p>
                                <Select
                                    placeholder={'(Search)'}
                                    options={stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName}
                                    value={stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === stateReactFlow.selectedBatchBusinessGroup)}
                                    // onChange={(e) => handleState('selectedBatchBusinessGroup', e.value)} 
                                    isDisabled={true}
                                />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH EXECUTION TYPE <span className='text-danger'>*</span></p>
                                <Select
                                    placeholder={'(Search)'}
                                    options={stateOptions.optionsBatchExecutionTypeDetailCode}
                                    value={stateOptions.optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === stateReactFlow.selectedBatchExecutionType)}
                                    // onChange={(e) => handleState('selectedBatchExecutionType', e.value)} 
                                    isDisabled={true}
                                />
                            </div>

                            {
                                stateReactFlow.selectedBatchBusinessGroup !== '' && stateReactFlow.selectedBatchExecutionType !== '' ?
                                    <>
                                        <div className={'col-12  d-flex justify-content-between align-items-center'}>
                                            <div className="bd-ea p-2 br-10">
                                                Note : Double-click to update the batch or drag to move the batch location
                                            </div>
                                          
                                            {/* <Button className='br-10 d-flex justify-content-center align-items-center' onClick={() => onConnect()}> Add Batch</Button> */}
                                        </div>
                                        <div className="bd-ea br-5 p-2" style={{ height: '600px', position: 'relative' }}>
                                        <div style={{position:'absolute', right: '7px', top: '5px', zIndex: 1000}}>
                                                <Tooltip placement="bottom" title={'Add Data'}>
                                                    <div className={"btn d-flex justify-content-center align-items-center bd-e8 bg-fd pointer  h-50px w-60px"} onClick={() => handleState('modalAddBatch', true, dispatchStateReactFlow)}>
                                                        {icon_add}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <EdgeWithButtonFlow
                                                ref={childRef}
                                                handleStateParent={(nameState: any, valueState: any) => handleState(nameState, valueState, dispatchStateReactFlow)}
                                                stateParent={stateReactFlow}
                                                setModal={() => handleState('visibleModalRemoveDataJob', !(stateReactFlow.visibleModalRemoveDataJob), dispatchStateReactFlow)}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center pt-3'>
                                            <div className="d-flex justify-content-between align-items-center">
                                                {
                                                    stateOptions.optionsBatchSchedulerStatusDetailCode.length > 0 &&
                                                    COLORS_NODE_JOB.map((itemColorJob: any) => {
                                                        let nameLabel: any = ''
                                                        stateOptions.optionsBatchSchedulerStatusDetailCode.forEach((itemOption: any) => {
                                                            if (itemColorJob.code === itemOption.value) nameLabel = itemOption.label
                                                        })
                                                        if (itemColorJob.code === '005') {
                                                            return <></>
                                                        }
                                                        return (
                                                            <div className='mx-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10 flex-wrap'>
                                                                <div
                                                                    key={itemColorJob.code}
                                                                    style={{
                                                                        backgroundColor: itemColorJob.colorUsed,
                                                                        width: '15px',
                                                                        height: '15px',
                                                                        borderRadius: '7px',
                                                                        display: 'inline-block',
                                                                    }}
                                                                    className='mx-2'
                                                                ></div>
                                                                <span className='Lato-400 mx-2'>{nameLabel}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-3 ' onClick={() => {
                                                dispatchStateReactFlow({ type: 'visibleModalSaveRelationData', value: true })
                                            }}>
                                                {checklistButtonIcon}
                                                <p className='s-1418 c-ff Lato-600 mx-2'>SAVE</p>
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                <>
                    <TableWithLimitOffset
                        state={stateTable}
                        stateOptions={stateOptions}
                        customButtonAction={customButtonAction}
                        exportToCSVComponent={() => { }}
                        addDataComponent={addDataComponent}
                        checkIndexComponent={checkIndexComponent}
                        handleStateComponent={handleStateComponent}
                        setToggleComponent={setToggleComponent}
                        setModal={setModalComponent}
                        // component
                        tableComponentPage={tableComponentPage}
                        filterComponentPage={filterComponentPage}
                        modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                        modalUpdateComponentPage={modalUpdateComponentPage}
                        // 
                        needComponentHeaderTable={true}
                        needComponentFilterTable={true}
                        needComponentTable={true}
                        needComponentFooterTable={true}
                        //
                        needToggleFunction={false}
                        needAddButton={false}
                        needSearchFunction={false}
                        needDownloadButton={false}


                        needComponentSort={false}
                    />
                </>
            </div>

            <Modal isOpen={stateReactFlow.showModalError} style={ModalStyle}>
                <div className='d-flex flex-column wd-300'>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className=''></p>
                        <p className='w-700 Lato-700 text-danger s-1822'>Alert</p>
                        <div className='pointer' onClick={(e) => handleState('showModalError', false, dispatchStateReactFlow)}>{close} </div>
                    </div>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='s-1620 mb-30'>This Data Can't be delete </p>
                    </div>
                </div>
            </Modal>


            <ModalJobSchedule
                visibleModalAddDelete={stateReactFlow.modalAddBatch}
                stateOptions={stateOptions}
                urlApi={stateReactFlow.urlApiModalAddBatch}
                setModal={() => handleState('modalAddBatch', !(stateReactFlow.modalAddBatch), dispatchStateReactFlow)}
                stateParent={stateReactFlow}
                getDataAllJobDetail={getDataAllJobDetail}
                ref={childRef2}
                functionSetModal={(conditionModal: any) => dispatchStateReactFlow({ type: 'visibleModalAddDataJob', value: conditionModal })}
                handleStateParent={(nameState: any, valueState: any) => handleState(nameState, valueState, dispatchStateReactFlow)}
            />
            <ModalJobScheduleUpdate
                visibleModalAddDelete={stateReactFlow.modalUpdateBatch}
                stateOptions={stateOptions}
                urlApi={stateReactFlow.urlApiModalAddBatch}
                setModal={() => handleState('modalUpdateBatch', !(stateReactFlow.modalUpdateBatch), dispatchStateReactFlow)}
                stateParent={stateReactFlow}
                getDataAllJobDetail={getDataAllJobDetail} // refresh react flow
                ref={childRef3}
                functionSetModal={(conditionModal: any) => dispatchStateReactFlow({ type: 'visibleModalUpdateDataJob', value: conditionModal })} // confirmation update data
                functionSetModal2={(conditionModal: any) => dispatchStateReactFlow({ type: 'visibleModalKillJob', value: conditionModal })} // confirmation update data
                handleStateParent={(nameState: any, valueState: any) => handleState(nameState, valueState, dispatchStateReactFlow)}
            />


            <Modal isOpen={stateReactFlow.visibleModalRemoveDataJob} style={ModalStyle}>
                <div className='bg-ff p-20 flex flex-column center br-20 w-400px'>
                    <h2 className='s-24 Lato-600 text-center'>Confirmation</h2>
                    <p className='s-1620 text-center'>Are you sure you want to remove Job Detail?</p>
                    <hr />
                    <div className='d-flex w-100 justify-content-between'>
                        <button className='btn_ bg-c1 bd-c1' onClick={() => dispatchStateReactFlow({ type: 'visibleModalRemoveDataJob', value: false })}  >Close</button>
                        <button className='btn_ mx-1 bg-f1 bd-f1' onClick={() => {
                            API.delete({
                                bodyCustom: null,
                                pathCustom: `${stateReactFlow.urlApiModalAddBatch}/${stateReactFlow.selectedBatchJobIdUpdate}`,
                                selectUrl: urlSelection.dashboard,
                                useToken: true,
                                needLoading: true,
                                needNotification: true,
                            }).then((response) => {
                                getDataAllJobDetail(response?.data.id, 'delete');
                                reloadDataComponent(false)
                                handleState('visibleModalRemoveDataJob', !(stateReactFlow.visibleModalRemoveDataJob), dispatchStateReactFlow)
                            }).catch((err) => {
                                handleState('visibleModalRemoveDataJob', !(stateReactFlow.visibleModalRemoveDataJob), dispatchStateReactFlow)
                            })
                        }}>Yes</button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={stateReactFlow.visibleModalAddDataJob} style={ModalStyle}>
                <div className='bg-ff p-20 flex flex-column center br-20 w-400px'>
                    <h2 className='s-24 Lato-600 text-center'>Confirmation</h2>
                    <p className='s-1620 text-center'>Are you sure to Add Data Job Detail?</p>
                    <hr />
                    <div className='d-flex w-100 justify-content-between'>
                        <button className='btn_ bg-c1 bd-c1' onClick={() => dispatchStateReactFlow({ type: 'visibleModalAddDataJob', value: false })}  >Close</button>
                        <button className='btn_ mx-1 bg-f1 bd-f1' onClick={() => {
                            childRef2.current.postDataAPI();
                        }}>Yes</button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={stateReactFlow.visibleModalUpdateDataJob} style={ModalStyle}>
                <div className='bg-ff p-20 flex flex-column center br-20 w-400px'>
                    <h2 className='s-24 Lato-600 text-center'>Confirmation</h2>
                    <p className='s-1620 text-center'>Are you sure you want to update Job Detail?</p>
                    <hr />
                    <div className='d-flex w-100 justify-content-between'>
                        <button className='btn_ bg-c1 bd-c1' onClick={() => dispatchStateReactFlow({ type: 'visibleModalUpdateDataJob', value: false })} >Close</button>
                        <button className='btn_ mx-1 bg-f1 bd-f1' onClick={() => {
                            childRef3.current.postDataAPI();
                        }}>Yes</button>
                    </div>
                </div>
            </Modal>
            {/* // */}
            <Modal isOpen={stateReactFlow.visibleModalKillJob} style={ModalStyle}>
                
                <div className='bg-ff p-20 flex flex-column center br-20 w-400px'>
                    
                    <h2 className='s-24 Lato-600 text-center'>Confirmation</h2>
                    <p className='s-1620 text-center'>Are you sure to kill this job  ({stateReactFlow.batchId} - {stateReactFlow.batchProgramId} - {stateReactFlow.batchDescription})?</p>
                    <hr />
                    <div className='d-flex w-100 justify-content-between'>
                        <button className='btn_ bg-c1 bd-c1' onClick={() => dispatchStateReactFlow({ type: 'visibleModalKillJob', value: false })}  >Close</button>
                        <button className='btn_ mx-1 bg-f1 bd-f1' onClick={() => {
                            childRef3.current.postDataKillJob();
                        }}>Yes</button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={stateReactFlow.visibleModalSaveRelationData} style={ModalStyle} >
                <div className='bg-ff p-20 flex flex-column center br-20 w-400px'>
                    <h2 className='s-24 Lato-600 text-center'>Confirmation</h2>
                    <p className='s-1620 text-center'>Are you sure you want to save the Data Relation of Job Detail?</p>
                    <hr />
                    <div className='d-flex w-100 justify-content-between'>
                        <button className='btn_ bg-c1 bd-c1' onClick={() => dispatchStateReactFlow({ type: 'visibleModalSaveRelationData', value: false })}  >Close</button>
                        <button className='btn_ mx-1 bg-f1 bd-f1' onClick={() => {
                            postDataAPI()
                        }}>Yes</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialStateTable = {
    urlApi: 'batch/run-batch-v2',
    titlePage: 'Job Status',
    privilegeAccess: { view: "671", add: "672", edit: "673", delete: "674", download: "675" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "", width: "wd-50", val: "batchStatus" },
        { field: "Batch ID", width: "wd-150", val: "batchId" },
        { field: "Batch Job ID", width: "wd-150", val: "id" },
        { field: "Batch Pre Requisite", width: "wd-200", val: "batchPreRequisite" },
        { field: "Batch Description", width: "wd-200", val: "batchDescription" },
        { field: "Batch Last Execution", width: "wd-200", val: "batchLastExec" },
        { field: "Batch Group Path", width: "wd-200", val: "batchGroupPath" },
        { field: "Batch Reg Type", width: "wd-200", val: "batchRegType" },
        { field: "Batch Rerun Manually Dttm", width: "wd-200", val: "batchRerunManuallyDttm" },
        { field: "Batch Planned Dttm", width: "wd-200", val: "batchPlannedDttm" },
        { field: "Batch Run Time", width: "wd-200", val: "batchRunTime" },
    ],
    toggle: ["no", "batchStatus", "batchId", "id", "batchPreRequisite", "batchLastExec", "batchGroupPath", "batchRegType", "batchRerunManuallyDttm", "batchPlannedDttm", "batchRunTime", "batchDescription"],
    complete_toggle: ["no", "batchStatus", "batchId", "id", "batchPreRequisite", "batchLastExec", "batchGroupPath", "batchRegType", "batchRerunManuallyDttm", "batchPlannedDttm", "batchRunTime", "batchDescription"],
    list_column_name: ["No.", "", "Batch ID", "Batch Job ID", "Batch Pre Requisite", "Batch Last Execution", "Batch Group Path", "Batch Reg Type", "Batch Rerun Manually Dttm", "Batch Planned Dttm", "Batch Run Time", "Batch Description"],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Batch ID, Batch Parameter Value',

    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 20,
    position: 20,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 2300,
    dataLength: 0, // zIndex
    finalAPI: '',
    //
};

// batchId ModelBatchScheduler
const reducerTable = (state: any, action: any) => {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelBatchScheduler) => {
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelBatchScheduler) => {
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelBatchScheduler) => {
                        showDataResult.push(
                            { idModal: element1.id!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };

        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}


const initialStateReactFlow = {
    urlApiPostBatch: 'batch/relation-detail',
    urlApiModalAddBatch: 'batch/run-batch-v2',
    titleModal: `Edit ${Page41Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    modalAddBatch: false,
    modalUpdateBatch: false,
    selectedBatch: null,
    selectedBatchJobIdUpdate: null,
    // 
    batchList: [],
    edgeList: [],
    // 
    id: "",
    errorMessage: "",
    showModalError: false,

    selectedBatchBusinessGroup: '',
    selectedBatchExecutionType: '',
    selectedBatchGroupPath: '',

    visibleModalRemoveDataJob: false,
    visibleModalAddDataJob: false,
    visibleModalUpdateDataJob: false,
    //
    visibleModalKillJob: false,
    //
    visibleModalSaveRelationData: false,

    data: new ModelSchedulerExecution({
        id: null,
        batchBusinessGroup: null,
        batchExecutionType: null,
        batchGroupPath: null,
    }),
    dataUpdate: new ModelBatchScheduler({
        id: null,
        batchId: null,
        batchPreRequisite: null,
        batchStatus: null,
        batchLastExec: null,
        batchGroupPath: null,
        createProgId: null,
        updateProgId: null,
        createDttm: null,
        updateDttm: null,
        batchRegType: null,
        batchRerunManuallyDttm: null,
        batchPlannedDttm: null,
        batchRunTime: null,
        batchParameterValue: null,
        batchExecutionType: null,
        batchInterval: null,
        batchBusinessGroup: null,
        flag: null,
        batchDescription: null,
        batchProgId: null,
    }),

    selectedJobId : '', /// highlight job id table when react flow clicked
}

const reducerReactFlow = (state: any, action: any) => {
    for (const key in state.data as ModelBatchScheduler) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }
    switch (action.type) {
        case 'selectedJobId':
            return {
                ...state,
                selectedJobId: action.value,
            };

        case 'visibleModalRemoveDataJob':
            return {
                ...state,
                visibleModalRemoveDataJob: action.value,
            };
        case 'visibleModalAddDataJob':
            return {
                ...state,
                visibleModalAddDataJob: action.value,
            };
        case 'visibleModalUpdateDataJob':
            return {
                ...state,
                visibleModalUpdateDataJob: action.value,
            };

        case 'visibleModalKillJob':
            return {
                ...state,
                visibleModalKillJob: action.value,
            };

        case 'visibleModalSaveRelationData':
            return {
                ...state,
                visibleModalSaveRelationData: action.value,
            };

        case 'selectedBatchBusinessGroup':
            return {
                ...state,
                selectedBatchBusinessGroup: action.value,
            };
        case 'selectedBatchExecutionType':
            return {
                ...state,
                selectedBatchExecutionType: action.value,
            };
        case 'selectedBatchGroupPath':
            return {
                ...state,
                selectedBatchGroupPath: action.value,
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'modalAddBatch':
            return {
                ...state,
                modalAddBatch: action.value,
            };
        case 'modalUpdateBatch':
            return {
                ...state,
                modalUpdateBatch: action.value,
            };
        case 'selectedBatch':
            return {
                ...state,
                selectedBatch: action.value,
            };
        case 'selectedBatchJobIdUpdate':
            return {
                ...state,
                selectedBatchJobIdUpdate: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                SchedulerName: '',
                batchList: [],
                edgeList: [],
                errorMessage: "",
                //
                selectedBatchBusinessGroup: '',
                selectedBatchExecutionType: '',
                selectedBatchGroupPath: '',
                visibleModalAddDataJob: false,
                visibleModalSaveRelationData: false,

                data: new ModelSchedulerExecution({
                    id: null,
                    batchBusinessGroup: null,
                    batchExecutionType: null,
                    batchGroupPath: null,
                }),
                dataUpdate: new ModelBatchScheduler({
                    id: null,
                    batchId: null,
                    batchPreRequisite: null,
                    batchStatus: null,
                    batchLastExec: null,
                    batchGroupPath: null,
                    createProgId: null,
                    updateProgId: null,
                    createDttm: null,
                    updateDttm: null,
                    batchRegType: null,
                    batchRerunManuallyDttm: null,
                    batchPlannedDttm: null,
                    batchRunTime: null,
                    batchParameterValue: null,
                    batchExecutionType: null,
                    batchInterval: null,
                    batchBusinessGroup: null,
                    flag: null,
                    batchDescription: null,
                    batchProgId: null,
                }),
            };
        case 'setData':
            return {
                ...state,

                selectedBatchBusinessGroup: action.value.batchBusinessGroup,
                selectedBatchExecutionType: action.value.batchExecutionType,
                // errorMessage: "",
                // data: new ModelSchedulerExecution({
                //     id: action.value.id,
                //     batchBusinessGroup: action.value.batchBusinessGroup,
                //     batchExecutionType: action.value.batchExecutionType,
                //     batchGroupPath: action.value.batchGroupPath,
                // }),
            };
        // 
        // 
        case 'clearUpdateData':
            return {
                ...state,
                dataUpdate: new ModelBatchScheduler({
                    id: null,
                    batchId: null,
                    batchPreRequisite: null,
                    batchStatus: null,
                    batchLastExec: null,
                    batchGroupPath: null,
                    createProgId: null,
                    updateProgId: null,
                    createDttm: null,
                    updateDttm: null,
                    batchRegType: null,
                    batchRerunManuallyDttm: null,
                    batchPlannedDttm: null,
                    batchRunTime: null,
                    batchParameterValue: null,
                    batchExecutionType: null,
                    batchInterval: null,
                    batchBusinessGroup: null,
                    flag: null,
                    batchDescription: null,
                    batchProgId: null,
                }),

            };
        case 'setUpdateData':
            return {
                ...state,
                dataUpdate: new ModelBatchScheduler({
                    id: action.value.id,
                    batchId: action.value.batchId,
                    batchPreRequisite: action.value.batchPreRequisite,
                    batchStatus: action.value.batchStatus,
                    batchLastExec: action.value.batchLastExec,
                    batchGroupPath: action.value.batchGroupPath,
                    createProgId: action.value.createProgId,
                    updateProgId: action.value.updateProgId,
                    createDttm: action.value.createDttm,
                    updateDttm: action.value.updateDttm,
                    batchRegType: action.value.batchRegType,
                    batchRerunManuallyDttm: action.value.batchRerunManuallyDttm,
                    batchPlannedDttm: action.value.batchPlannedDttm,
                    batchRunTime: action.value.batchRunTime,
                    batchParameterValue: action.value.batchParameterValue,
                    batchExecutionType: action.value.batchExecutionType,
                    batchInterval: action.value.batchInterval,
                    batchBusinessGroup: action.value.batchBusinessGroup,
                    flag: action.value.flag,
                    batchDescription: action.value.batchDescription,
                    batchProgId: action.value.batchProgId,
                }),
                selectedBatchBusinessGroup: action.value.batchBusinessGroup,
                selectedBatchExecutionType: action.value.batchExecutionType,
                errorMessage: "",
            };
        
        case 'getData':
            return {
                ...state,
                batchId: action.value.batchId,
                batchProgramId: action.value.batchProgramId,
                batchDescription: action.value.batchDescription,
            };

        // 
        case 'batchList':
            return {
                ...state,
                batchList: action.value,
            };
        case 'edgeList':
            return {
                ...state,
                edgeList: action.value,
            };
        default:
            throw new Error();
    }
}
import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Page1DashboardRoute, Page2FrameworkRoute, Page2ProductionSimRoute, Page5DashboardRoute } from "../../../Routes";
import { API } from "../../../Services";
import { initFunction, reducerOptions, reducerStateSelected, stateOptions, stateSelected } from "../../../State";
import { labelStyleW200PX, labelStyleW200PXWithoutUppercase } from "../../../Styles";
import { CheckUndefined, NumberWithCommas } from "../../../Utils";
import { InterfaceOptionsDetailsMonitoringStatus } from "../InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { ModelDetailTransactionStatus } from "../Models/ModelDetailTransactionStatus";
import { Divider } from "antd";
import { resetAllAction } from "../../../Store/TransactionJourney";
import { urlSelection } from "../../../Services/API";
export const DetailsQRAuthSimulatorInquiry = ({ ...props }) => {
    const { rrn, stan } = useParams();
    const dispatchGlobal = useDispatch();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        API.get({
            bodyCustom: null,
            pathCustom: `dashboard/qr-simulation-inquiry-detail?qrRrn=${rrn}&qrStan=${stan}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            handleState('data', response.data)
        })
    }
    useEffect(() => {
        initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
    }, [])
    const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
    const data = state.allData
    let merchant_post_code = ""
    let member_appr_code = ""
    if (state.data === null) return <></>
    if (CheckUndefined(data.merchantPosCode) == "-") {
        merchant_post_code = CheckUndefined(data.merchantPos)
    } else if (CheckUndefined(data.merchantPos) == "-") {
        merchant_post_code = CheckUndefined(data.merchantPosCode)
    } else {
        merchant_post_code = `${CheckUndefined(data.merchantPosCode)} - ${(CheckUndefined(data.merchantPos))}`
    }
    if (CheckUndefined(data.isApprCodeIntn) != "-") {
        if (data.isApprCodeIntn) member_appr_code = " (Generated by BCAP)"
    }
    return (
        <div className="_new rev">
            <NavLink to={Page2ProductionSimRoute}>
                <h1 className="Lato-600 c-29 s-2024 w-600px mb-40px my-3">〱 QR Auth Simulator Inquiry Details</h1>
            </NavLink>
            <Divider />
            {/* Request Details */}
            <div className="row bd-d4 br-10 bg-ff gx-4 gy-4 py-3 ">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-f8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Simulator Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}> Test Category</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.testCat)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}>Test Case Id</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.testCaseId)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a b">
                    <p className={labelStyleW200PX}>Expected Result</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.expectedResult)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                    <p className={labelStyleW200PX}>Test Result</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.testResult)}</p>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-f8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Request Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f">
                    <p className={labelStyleW200PX}>Global ID</p>
                    <div
                        className={`${labelStyleW200PXWithoutUppercase} fw-bold text-info pointer`}
                        onClick={(e) => {
                            dispatchGlobal(resetAllAction(data.globalId))
                            navigate(`${Page2FrameworkRoute}?globalId=${data.globalId}`)
                        }}
                    >
                        {CheckUndefined(data.globalId)}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}>Request Date</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.reqDate)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}>QR STAN</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrStan)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a b">
                    <p className={labelStyleW200PX}>QR Ref Number</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrRefNo)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                    <p className={labelStyleW200PX}>QR Inv Number</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrInvNo)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                    <p className={labelStyleW200PX}>Product Indicator</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.prodFeatureInd)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                    <p className={labelStyleW200PX}>Product Name</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.prodFeatureName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}>QR Standard</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrStandardName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a b">
                    <p className={labelStyleW200PX}>QR Mode</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrMode)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}>Member ID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.memberCode)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}>Member Name</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.memberName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f">
                    <p className={labelStyleW200PX}>Request Reason</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{` ${(CheckUndefined(data.reqReason))}`}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f">
                    <p className={labelStyleW200PX}>Reference 1</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.ref1)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f">
                    <p className={labelStyleW200PX}>Reference 2</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{`${CheckUndefined(data.ref2)}`}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                    <p className={labelStyleW200PX}>Reference 3</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.ref3)}</p>
                </div>
                {data.qrMode === 'CPM' ? 
                    <>
                        {/* CPM DETAILS */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-f8">
                            <h1 className="Lato-600 c-29 s-2024 w-600px ">QR CPM Details</h1>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f8a">
                            <p className={labelStyleW200PX}>Wallet ID</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.walletId)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                            <p className={labelStyleW200PX}>{data.srcLabel2} Wallet Name</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.walletName)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                            <p className={labelStyleW200PX}>CPM PAN</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.cpmPan)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a b">
                            <p className={labelStyleW200PX}>CPM PAR</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.cpmPar)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f">
                            <p className={labelStyleW200PX}>CPM Token</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.cpmToken)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                            <p className={labelStyleW200PX}>CPM Token Request ID</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.cpmTokenReqId)}</p>
                        </div>
                    </>
                    : 
                    <>
                        {/* MPM DETAILS */}
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-f8">
                            <h1 className="Lato-600 c-29 s-2024 w-600px ">QR MPM Details</h1>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f8a">
                            <p className={labelStyleW200PX}>Merchant Id</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.merchantId)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a">
                            <p className={labelStyleW200PX}>Merchant Name</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.merchantName)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a b">
                            <p className={labelStyleW200PX}>Merchant Country</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.merchantCountry)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f">
                            <p className={labelStyleW200PX}>MCC</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.mcc)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                            <p className={labelStyleW200PX}>Sub Merchant Id</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.subMerchantId)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                            <p className={labelStyleW200PX}>Sub Merchant Name</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.subMerchantName)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                            <p className={labelStyleW200PX}>Tid</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.tid)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f8a f b">
                            <p className={labelStyleW200PX}>Amount</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.amount)}</p>
                        </div>
                    </> 
                }
            </div>
        </div>
    )
}
interface test {
    data: ModelDetailTransactionStatus | null
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: test = {
    data: null
};
function reducer(state: any, action: any) {
    switch (action.type) {
        case 'data':
            return {
                ...state,
                allData: action.value,
                data: {
                    globalId: action.value.globalId,
                    reqDate: action.value.reqDate,
                    qrStan: action.value.qrStan,
                    qrRefNo: action.value.qrRefNo,
                    qrInvNo: action.value.qrInvNo,
                    prodFeatureInd: action.value.prodFeatureInd,
                    prodFeatureName: action.value.prodFeatureName,
                    qrStandardCode: action.value.qrStandardCode,
                    qrStandardName: action.value.qrStandardName,
                    qrMode: action.value.qrMode,
                    memberCode: action.value.memberCode,
                    memberName: action.value.memberName,
                    reqReason: action.value.reqReason,
                    ref1: action.value.ref1,
                    ref2: action.value.ref2,
                    ref3: action.value.ref3,
                    walletId: action.value.walletId,
                    walletName: action.value.walletName,
                    cpmPan: action.value.cpmPan,
                    cpmPar: action.value.cpmPar,
                    cpmToken: action.value.cpmToken,
                    cpmTokenReqId: action.value.cpmTokenReqId,
                    merchantId: action.value.merchatId,
                    merchantName: action.value.merchantName,
                    merchantCountry: action.value.merchantCountry,
                    mcc: action.value.mcc,
                    subMerchantId: action.value.subMerchantId,
                    subMerchantName: action.value.subMerchantName,
                    tid: action.value.tid,
                    amount: action.value.amount,
                }
            };
        default:
            throw new Error();
    }
}




// InterfaceFile

interface InterfaceFileInterface {
    id: null | number | string;
    interfaceFileId: null | number | string
    interfaceFileName: string | null;
    interfaceFileFormat: string | null;
    interfaceStrFormat: string | null;
    interfaceRecSize: number | null;
    interfaceRecItemNameYn: Boolean | null,
    interfaceRecItemNameLength: number | null;
    interfaceFileDesc: string | null;
    interfaceFileStatus: string | null;
    interfaceFileCreateBy: number | null;
    interfaceFileCreateDate: string | null;
    interfaceFileUpdateBy: string | null;
    interfaceFileUpdateDate: string | null;
    interfaceFileDeleteBy: string | null;
    interfaceFileDeleteDate: string | null;
    filename_format:string|null;

}

export class ModelInterfaceFile {
    id: null | number | string;
    interfaceFileId: null | number | string
    interfaceFileName: string | null;
    interfaceFileFormat: string | null;
    interfaceStrFormat: string | null;
    interfaceRecSize: number | null;
    interfaceRecItemNameYn: Boolean | null;
    interfaceRecItemNameLength: number | null;
    interfaceFileDesc: string | null;
    interfaceFileStatus: string | null;
    interfaceFileCreateBy: number | null;
    interfaceFileCreateDate: string | null;
    interfaceFileUpdateBy: string | null;
    interfaceFileUpdateDate: string | null;
    interfaceFileDeleteBy: string | null;
    interfaceFileDeleteDate: string | null;
    filename_format:string|null;
    constructor(value: InterfaceFileInterface | null) {
        this.id = value?.id ?? null;
        this.interfaceFileId = value?.interfaceFileId ?? null;
        this.interfaceFileName = value?.interfaceFileName ?? null;
        this.interfaceFileFormat = value?.interfaceFileFormat ?? null;
        this.interfaceStrFormat = value?.interfaceStrFormat ?? null;
        this.interfaceRecSize = value?.interfaceRecSize ?? null;
        this.interfaceRecItemNameYn = value?.interfaceRecItemNameYn ?? null;
        this.interfaceRecItemNameLength = value?.interfaceRecItemNameLength ?? null;
        this.interfaceFileDesc = value?.interfaceFileDesc ?? null;
        this.interfaceFileStatus = value?.interfaceFileStatus ?? null;
        this.interfaceFileCreateBy = value?.interfaceFileCreateBy ?? null;
        this.interfaceFileCreateDate = value?.interfaceFileCreateDate ?? null;
        this.interfaceFileUpdateBy = value?.interfaceFileUpdateBy ?? null;
        this.interfaceFileUpdateDate = value?.interfaceFileUpdateDate ?? null;
        this.interfaceFileDeleteBy = value?.interfaceFileDeleteBy ?? null;
        this.interfaceFileDeleteDate = value?.interfaceFileDeleteDate ?? null;
        this.filename_format = value?.filename_format ?? null;

    }
}

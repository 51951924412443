import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAllFields, HasWhiteSpace } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page26Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableQRStandardMember = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsQRStandard, optionsYorN, optionsCommonStatusDetailCode, optionsMember, optionsRouteSequence } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { qrStandardId, qrStandardName, qrsmSrcMemberId, qrsmSrcMemberName, qrsmDstMemberId, qrsmDstMemberName, qrsmStatus, qrsmGuid, qrsmMerId, qrsmAid, qrsmBillerId, qrsmMpan, qrsmNii, qrsmOfx, qrsmRsId, errorMessage } = statePage
        const data = {
            qrsmId: id,
            qrStandardId: qrStandardId, // mendatory QR Standard
            qrsmStatus: qrsmStatus, // mendatory Status
            qrsmGuid: qrsmGuid, // o GUID
            qrsmMerId: qrsmMerId, // o Merchant ID
            qrsmAid: qrsmAid, // o AID
            qrsmBillerId: qrsmBillerId, // o Biller ID
            qrsmMpan: qrsmMpan,  // o MPAN
            qrsmNii: qrsmNii, // o NII
            qrsmSrcMemberId: qrsmSrcMemberId, // mendatory Source Member
            qrsmDstMemberId: qrsmDstMemberId, // mendatory Dest Member
            qrsmOfx: qrsmOfx, // mendatory Online FX  true false
            qrsmRsId: qrsmOfx === true ? qrsmRsId : null, // o OR mendatory RSID
        };
        let a = '';
        let testDataqrsmRsId = '';
        if (qrsmRsId === null) {
            testDataqrsmRsId = ''
        } else {
            testDataqrsmRsId = qrsmRsId
        }
        if (qrsmOfx === true) {
            a = CheckAllFields([qrStandardId, qrsmStatus, qrsmSrcMemberId, qrsmDstMemberId, qrsmOfx, testDataqrsmRsId], ['QR Standard', 'Status', 'Source Member', 'Dest Member', 'Online FX', 'Online FX RSID']);
        } else {
            a = CheckAllFields([qrStandardId, qrsmStatus, qrsmSrcMemberId, qrsmDstMemberId, qrsmOfx], ['QR Standard', 'Status', 'Source Member', 'Dest Member', 'Online FX']);
        }
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-6'>
                        <p className={labelStyle}>QR Standard</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsQRStandard}
                            value={optionsQRStandard.filter((option: any) => option.value === statePage.qrStandardId)}
                            onChange={(e) => handleState('qrStandardId', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Source Member</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsMember}
                            value={optionsMember.filter((option: any) => option.value === statePage.qrsmSrcMemberId)}
                            onChange={(e) => handleState('qrsmSrcMemberId', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Dst Member</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsMember}
                            value={optionsMember.filter((option: any) => option.value === statePage.qrsmDstMemberId)}
                            onChange={(e) => handleState('qrsmDstMemberId', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Online FX</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsYorN}
                            value={optionsYorN.filter((option: any) => option.value === statePage.qrsmOfx)}
                            onChange={(e) => handleState('qrsmOfx', e.value)}
                        />
                    </div>
                    {statePage.qrsmOfx === true ?
                        <div className='col-6'>
                            <p className={labelStyle}>Online FX RSID</p>
                            <Select
                                styles={inputStyleH25}
                                placeholder={'(Search)'}
                                options={optionsRouteSequence}
                                value={optionsRouteSequence.filter((option: any) => option.value === statePage.qrsmRsId)}
                                onChange={(e) => handleState('qrsmRsId', e.value)}
                            />
                        </div>
                        :
                        <></>
                    }

                    <div className='col-6'>
                        <p className={labelStyle}>Merchant ID</p>
                        <input value={statePage.qrsmMerId} onChange={(e) => handleState('qrsmMerId', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>MPAN</p>
                        <input value={statePage.qrsmMpan} onChange={(e) => handleState('qrsmMpan', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>GUID</p>
                        <input value={statePage.qrsmGuid} onChange={(e) => handleState('qrsmGuid', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>NII</p>
                        <input value={statePage.qrsmNii} onChange={(e) => handleState('qrsmNii', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>AID</p>
                        <input value={statePage.qrsmAid} onChange={(e) => handleState('qrsmAid', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Biller ID</p>
                        <input value={statePage.qrsmBillerId} onChange={(e) => handleState('qrsmBillerId', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Status</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsCommonStatusDetailCode}
                            value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.qrsmStatus)}
                            onChange={(e) => handleState('qrsmStatus', `${e.value}`)}
                        />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page26Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    qrsmId: '',
    qrStandardId: '',
    qrStandardName: '',
    qrsmSrcMemberId: '',
    qrsmSrcMemberName: '',
    qrsmDstMemberId: '',
    qrsmDstMemberName: '',
    qrsmStatus: '001',
    qrsmGuid: '',
    qrsmMerId: '',
    qrsmAid: '',
    qrsmBillerId: '',
    qrsmMpan: '',
    qrsmNii: '',
    qrsmOfx: false,
    qrsmRsId: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                qrsmId: '',
                qrStandardId: '',
                qrStandardName: '',
                qrsmSrcMemberId: '',
                qrsmSrcMemberName: '',
                qrsmDstMemberId: '',
                qrsmDstMemberName: '',
                qrsmStatus: '001',
                qrsmGuid: '',
                qrsmMerId: '',
                qrsmAid: '',
                qrsmBillerId: '',
                qrsmMpan: '',
                qrsmNii: '',
                qrsmOfx: false,
                qrsmRsId: '',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                qrsmId: action.value.qrsmId,
                qrStandardId: action.value.qrStandardId,
                qrStandardName: action.value.qrStandardName,
                qrsmSrcMemberId: action.value.qrsmSrcMemberId,
                qrsmSrcMemberName: action.value.qrsmSrcMemberName,
                qrsmDstMemberId: action.value.qrsmDstMemberId,
                qrsmDstMemberName: action.value.qrsmDstMemberName,
                qrsmStatus: action.value.qrsmStatus,
                qrsmGuid: action.value.qrsmGuid,
                qrsmMerId: action.value.qrsmMerId,
                qrsmAid: action.value.qrsmAid,
                qrsmBillerId: action.value.qrsmBillerId,
                qrsmMpan: action.value.qrsmMpan,
                qrsmNii: action.value.qrsmNii,
                qrsmOfx: action.value.qrsmOfx,
                qrsmRsId: action.value.qrsmRsId,
                errorMessage: "",
            };
        // 
        case 'qrsmId':
            return {
                ...state,
                qrsmId: action.value,
            };
        case 'qrStandardId':
            return {
                ...state,
                qrStandardId: action.value,
            };
        case 'qrStandardName':
            return {
                ...state,
                qrStandardName: action.value,
            };
        case 'qrsmSrcMemberId':
            return {
                ...state,
                qrsmSrcMemberId: action.value,
            };
        case 'qrsmSrcMemberName':
            return {
                ...state,
                qrsmSrcMemberName: action.value,
            };
        case 'qrsmDstMemberId':
            return {
                ...state,
                qrsmDstMemberId: action.value,
            };
        case 'qrsmDstMemberName':
            return {
                ...state,
                qrsmDstMemberName: action.value,
            };
        case 'qrsmStatus':
            return {
                ...state,
                qrsmStatus: action.value,
            };
        case 'qrsmGuid':
            return {
                ...state,
                qrsmGuid: action.value,
            };

        case 'qrsmMerId':
            return {
                ...state,
                qrsmMerId: action.value,
            };
        case 'qrsmAid':
            return {
                ...state,
                qrsmAid: action.value,
            };
        case 'qrsmBillerId':
            return {
                ...state,
                qrsmBillerId: action.value,
            };
        case 'qrsmMpan':
            return {
                ...state,
                qrsmMpan: action.value,
            };
        case 'qrsmNii':
            return {
                ...state,
                qrsmNii: action.value,
            };
        case 'qrsmOfx':
            return {
                ...state,
                qrsmOfx: action.value,
            };
        case 'qrsmRsId':
            return {
                ...state,
                qrsmRsId: action.value,
            };

        default:
            throw new Error();
    }
}





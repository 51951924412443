

// RoutingManagement

interface RoutingManagementInterface {
    id: null | number | string;
    routeId: number | null;
    routeSourceMemberId: number | null;
    routeDestMemberId: number | null;
    routeForwardingId: number | null;
    routeTimeoutThreshold: number | null;
    routeName: string | null;
    routeDesc: string | null;
    sourceMemberName: string | null;
    destMemberName: string | null;
}

export class ModelRoutingManagement {
    id: null | number | string;
    routeId: number | null;
    routeSourceMemberId: number | null;
    routeDestMemberId: number | null;
    routeForwardingId: number | null;
    routeTimeoutThreshold: number | null;
    routeName: string | null;
    routeDesc: string | null;
    sourceMemberName: string | null;
    destMemberName: string | null;


    constructor(value: RoutingManagementInterface | null) {
        this.id = value?.id ?? null;
        this.routeId= value?.routeId ?? null;
        this.routeSourceMemberId= value?.routeSourceMemberId ?? null;
        this.routeDestMemberId= value?.routeDestMemberId ?? null;
        this.routeForwardingId= value?.routeForwardingId ?? null;
        this.routeTimeoutThreshold= value?.routeTimeoutThreshold ?? null;
        this.routeName= value?.routeName ?? null;
        this.routeDesc= value?.routeDesc ?? null;
        this.sourceMemberName= value?.sourceMemberName ?? null;
        this.destMemberName= value?.destMemberName ?? null;
    }
}
// export const colors = [
//     'rgb(255, 205, 86)',
//     'rgb(54, 162, 235)',
//     'rgb(255, 99, 132)',
//     'rgb(255, 140, 132)',
//     'rgb(255, 10, 132)',
//     'rgb(255, 55, 132)',
//     'rgb(255, 55, 255)',
//     'rgb(255, 55, 10)',
//     'rgb(255, 105, 20)',
//     'rgb(152, 223, 214)',
//     'rgb(255, 221, 131)',
//     'rgb(226, 24, 24)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
//     'rgb(0, 35, 91)',
// ];

export const colors: any = [];

for (let i = 0; i < 500; i++) {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const rgb = `rgb(${r}, ${g}, ${b})`;
    colors.push(rgb);
}

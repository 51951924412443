import { createSlice } from "@reduxjs/toolkit";

interface interfaceInitialState {
    groupedData: [],
    selectedMemberSrcName: string | null,
    selectedMemberSrcCode: string | null,
    selectedMemberDstName: string | null,
    selectedMemberDstCode: string | null,
    selectedProductInd: string | null,
    selectedGlobalId: string | null,
    selectedAuthRrn: string | null,
    selectedAuthStan: string | null,
    selectedInvNo: string | null,
    selectedAuthRspnCode: string | null,
    selectedServerId: string | null,
    selectedFxRate: string | null,
    selectedSim: string | null,
    selectedCpan: string | null,
    selectedMidMpan: string | null,
    selectedStartDate: string | null,
    selectedEndDate: string | null,
    selectedStartTime: string | null,
    selectedEndTime: string | null,
    search: string | null,
    sortBy: string | null,
    sortType: string | null,
    locationSearch: string | null
}

const initialState = (): interfaceInitialState | any => {
    let dateNow = new Date().toISOString().slice(0, 10);
    let now = new Date();
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    let startTime = '00:00:01';
    let timeNow = `${hours}:${minutes}:${seconds}`;
    return {
        groupedData: [],
        selectedMemberSrcName: '',
        selectedMemberSrcCode: '',
        selectedMemberDstName: '',
        selectedMemberDstCode: '',
        selectedProductInd: '',
        selectedGlobalId: '',
        selectedAuthRrn: '',
        selectedAuthStan: '',
        selectedInvNo: '',
        selectedAuthRspnCode: '',
        selectedServerId: '',
        selectedFxRate: '',
        selectedSim: '',
        selectedCpan: '',
        selectedMidMpan: '',
        selectedStartDate: dateNow,
        selectedEndDate: dateNow,
        selectedStartTime: startTime,
        selectedEndTime: timeNow,
        search: '',
        sortBy: '',
        sortType: '',
        locationSearch: ''
    }
}



export const transactionJourneySlice = createSlice({
    name: "transactionJourney",
    initialState: initialState(),
    reducers: {
        handleStateAction: (state, action) => {
            for (const key in state as interfaceInitialState) {
                if (key === action.payload.name) {
                    let nameState: string = action.payload.name
                    state[nameState] = action.payload.value
                }
            }
        },
        resetAllAction: (state, action) => {
            state.selectedMemberSrcName = ''
            state.selectedMemberSrcCode = ''
            state.selectedMemberDstName = ''
            state.selectedMemberDstCode = ''
            state.selectedProductInd = ''
            state.selectedGlobalId = action.payload
            state.selectedAuthRrn = ''
            state.selectedAuthStan = ''
            state.selectedInvNo = ''
            state.selectedAuthRspnCode = ''
            state.selectedServerId = ''
            state.selectedFxRate = ''
            state.selectedSim = ''
            state.selectedCpan = ''
            state.selectedMidMpan = ''
            state.selectedStartDate = ''
            state.selectedEndDate = ''
            state.selectedStartTime = ''
            state.search = ''
            state.selectedEndTime = ''
            state.sortBy = ''
            state.sortType = ''
        }
    }
});

export const { handleStateAction, resetAllAction } = transactionJourneySlice.actions;
export const transactionJourneyGlobalState = (state: any) => state.transactionJourney;
export default transactionJourneySlice.reducer;
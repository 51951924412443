
import Select from 'react-select';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { stateGlobalAlertHistory } from './AlertHistorySlice';
import { inputStyleH25, labelStyle } from '../../Styles';
import { DatePickerRighIcon } from '../../Components/DatepickerRightIcon';
import { errNotificationIcon } from '../../Assets/Images/svg';
import { ButtonSearch } from '../../Components/ButtonSearch';

export const FilterComponentALertHistory = ({ ...props }) => {
    const globalState = useSelector(stateGlobalAlertHistory);
    const {
        state,
        handleStateGlobal,
        initFunctionCustom, response, stateOptions
    } = props;

    const {search,
        filterStartDate,
        filterEndDate,
        startTime,
        endTime,svrId,alertCat,alertEventType, alertSeverity
    } = globalState;

    const userLogin = useSelector((state: any) => state.user);

    const optionsAlertHistory:any =[
        {value :'', label: 'All'},
        {value :'API', label:'API'},
        {value :'API Gateway', label:' API GW'},
        {value :'BATCH', label:'BATCH'},
        {value :'DB', label:'DB'}
    ]

    return (
        <div className="d-flex flex-column w-100">
            <div className="row bg-ff br-10 bd-d4 gx-2 gy-2 px-2">
                <div className="col-4  col-lg-2 col-md-3">
                    <p className={labelStyle}>Start Date</p>
                    <div className="d-flex flex-column">
                        <DatePickerRighIcon
                              todayButton="today"
                              dateFormat="dd/MM/yyyy"
                              maxDate={new Date()}
                              selected={new Date(`${filterStartDate} ${startTime}` || '')}
                              onChange={(date: any) => {
                                  handleStateGlobal(
                                      'filterStartDate',
                                      format(date, 'yyyy-MM-dd')
                                  );
                                  handleStateGlobal(
                                      'startTime',
                                      format(date, 'HH:mm:ss')
                                  );
                                  if (filterEndDate === '') {
                                      let dateNow = new Date()
                                          .toISOString()
                                          .slice(0, 10);
                                      handleStateGlobal('filterEndDate', dateNow);
                                  }
                              }}
                        />
                    </div>
                </div>
                <div className="col-4  col-lg-2 col-md-3">
                    <p className={labelStyle}>End Date</p>
                    <div className="d-flex flex-column">
                        <DatePickerRighIcon
                            maxDate={new Date()}
                            selected={new Date(`${filterEndDate} ${endTime} ` || '')}
                            onChange={(date: any) => {
                                handleStateGlobal(
                                    'filterEndDate',
                                    format(date, 'yyyy-MM-dd')
                                );
                                handleStateGlobal(
                                    'endTime',
                                    format(date, 'HH:mm:ss')
                                );
                                if (filterStartDate === '') {
                                    let dateNow = new Date()
                                        .toISOString()
                                        .slice(0, 10);
                                    handleStateGlobal(
                                        'filterStartDate',
                                        dateNow
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="col-4  col-lg-2 col-md-3">
                    <p className={labelStyle}>Server</p>
                    <div className="d-flex justify-content-between">
                        <Select
                            placeholder={'All'}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={optionsAlertHistory}
                            value={optionsAlertHistory.filter(
                                (option: any) =>
                                    option.value === svrId
                            )}
                            onChange={(e) => {
                                handleStateGlobal(
                                    'svrId',
                                    e.value
                                );
                                // resetFilterStatus();
                            }}
                        />
                    </div>
                </div>
                <div className="col-4  col-lg-2 col-md-3">
                    <p className={labelStyle}>Category</p>
                    <div className="d-flex justify-content-between">
                        <Select
                            placeholder={'All'}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsCommonCode701}
                            value={stateOptions.optionsCommonCode701.filter(
                                (option: any) =>
                                    option.value === alertCat
                            )}
                            onChange={(e) => {
                                handleStateGlobal(
                                    'alertCat',
                                    e.value
                                );
                            //     resetFilterStatus();
                            }}
                        />
                    </div>
                </div>
                <div className="col-4  col-lg-2 col-md-3">
                    <p className={labelStyle}>Event</p>
                    <div className="d-flex justify-content-between">
                        <Select
                            placeholder={'All'}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={
                                stateOptions.optionsCommonCode702
                            }
                            value={stateOptions.optionsCommonCode702.filter(
                                (option: any) =>
                                    option.value === alertEventType
                            )}
                            onChange={(e) => {
                                handleStateGlobal(
                                    'alertEventType',
                                    e.value
                                );
                                // resetFilterStatus();
                            }}
                        />
                    </div>
                </div>
                <div className="col-4  col-lg-2 col-md-3">
                    <p className={labelStyle}>Severity</p>
                    <div className="d-flex justify-content-between">
                        <Select
                            placeholder={'All'}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsCommonCode703}
                            value={stateOptions.optionsCommonCode703.filter(
                                (option: any) =>
                                    option.value === alertSeverity
                            )}
                            onChange={(e) => {
                                handleStateGlobal(
                                    'alertSeverity',
                                    e.value
                                );
                                // resetFilterStatus();
                            }}
                        />
                    </div>
                </div>
                {state.errorMessage !== '' && (
                    <div className="col-12 pl-0">
                        <div className="d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2">
                            {errNotificationIcon}
                            <p className="px-2 s-1418 Lato-600 c-e87">
                                {state.errorMessage}
                            </p>
                        </div>
                    </div>
                )}
                <div className="d-flex justify-content-end">
                    <div className="d-flex p-2 justify-content-end">
                        <button
                            className="btn d-flex bg-e8 h-30px mx-1 mr-100px"
                            onClick={(e) => {
                                let dateNow = new Date()
                                    .toISOString()
                                    .slice(0, 10);
                                handleStateGlobal('filterStartDate', dateNow);
                                handleStateGlobal('filterEndDate', dateNow);
                                let now = new Date();
                                let hours = now.getHours().toString().padStart(2, '0');
                                let minutes = now.getMinutes().toString().padStart(2, '0');
                                let seconds = now.getSeconds().toString().padStart(2, '0');
                                let startTime = '00:00:00';
                                let timeNow = `${hours}:${minutes}:${seconds}`;
                                handleStateGlobal('startTime', startTime);
                                handleStateGlobal('endTime', timeNow);
                                handleStateGlobal('svrId','');
                                handleStateGlobal('alertCat','');
                                handleStateGlobal('alertEventType','');
                                handleStateGlobal('alertSeverity','');
                            }}
                        >
                            <svg
                                style={{ width: '18px', height: '18px' }}
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                    fill="#FFFFFF"
                                />
                            </svg>
                            <p className="s-1418 c-ff Lato-600 mx-2">Reset</p>
                        </button>
                    </div>
                    <div className="d-flex">
                        {' '}
                        <ButtonSearch
                            filterStartDate={filterStartDate}
                            initFunctionCustom={initFunctionCustom}
                            disabled={state.errorMessage !== ''}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};

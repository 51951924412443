import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonDelete, ButtonEdit, InsideComponentPage } from '../../Components';
import { API } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { InterfaceOptionsHmacManagement } from '../MemberHMAC/Utils/InterfaceOptionsHmacManagement';
import { ModalDraggableHmacManagement } from '../MemberHMAC/Components/Modal/ModalDraggableHmacManagement';
import { ModalHmacManagement } from '../MemberHMAC/Components/Modal/ModalHmacManagement';
import { ModelHmacManagement } from '../MemberHMAC/Utils/ModelHmacManagement';
import { TableHmacManagement } from './Tables/TableHmacManagement';


export const Page1 = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);


    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        // let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        // let finalParameter = `${searchValue}`;
        // if (needRefreshPage === true) {
        //     API.get({
        //         bodyCustom: null,

        //         pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        //         selectUrl: urlSelection.dashboard,
        //         useToken: true,

        //     }).then((response: any) => dispatch({ type: 'searchData', value: response.data }))
        // } else {
        //     API.get({
        //         bodyCustom: null,

        //         pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        //         selectUrl: urlSelection.dashboard,
        //         useToken: true,

        //     }).then((response: any) => dispatch({ type: 'data', value: response.data }))
        // }
    }

    const afterUpdate = (updateItemID: any) => {
        // let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        // let finalParameter = `${searchValue}`;
        // API.get({
        //     bodyCustom: null,

        //     pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        //     selectUrl: urlSelection.dashboard,
        //     useToken: true,

        // }).then((response: any) => dispatch({ type: 'afterUpdate', value: { data: response.data, updateItemID: updateItemID } }))
    }

    const afterUpdateItem = (updateItemID: any) => {
        initFunction(dispatchOptions, state, () => afterUpdate(updateItemID), null, InterfaceOptionsHmacManagement, dispatchGlobal)
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsHmacManagement, dispatchGlobal)
    }, [state.search])

    useEffect(() => {
        let totalPages: number = Math.ceil(state.data.length / state.view);
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'changeData', value: { totalPages, currentPage } });
    }, [state.data.length, state.position, state.view])

    const exportToCSVComponent = () => {}
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelHmacManagement) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsHmacManagement, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => afterUpdateItem(updateItemID)


    const customButtonAction = (item: ModelHmacManagement, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                <ButtonEdit handleClick={() => actionShowModal(item.id)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
            </div>
        );
    };
    const filterComponentPage = () => <></>
    const tableComponentPage = () => {
        return (
            <TableHmacManagement
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        // return <></>
        return (
            <ModalHmacManagement
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />

        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.showModal.map((element1: any) => {
                        return (
                            <ModalDraggableHmacManagement
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <div className='d-flex flex-column w-100 my-5'>
                <h1 className='Lato-300 normal w-600px s-2024 py-2'>{state.titlePage}</h1>
            </div>
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'hmac_key_pair',
    titlePage: 'Archive Management',
    privilegeAccess: { view: '111', add: '112', edit: '113', delete: '114', download: '115' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Member Name', width: 'wd-150', val: 'memberName' },
        { field: 'Member Code', width: 'wd-150', val: 'memberCode' },
        { field: 'Incoming Key Value', width: 'wd-250', val: 'hmacIncKeyVal' },
        { field: 'Outgoing Key Value', width: 'wd-250', val: 'hmacOutKeyValue' },
        { field: 'Hash Type', width: 'wd-150', val: 'hmacHashType' }, // optionsHashType
        { field: 'Environment Type', width: 'wd-150', val: 'hmacEnvType' }, // optionsEnvironmentType
        { field: 'Status', width: 'wd-150', val: 'hmacStatus' }, // optionsCommonStatus
        { field: 'Action', width: 'wd-150', val: 'action' },
    ],
    toggle: ['no', 'memberName', 'memberCode', 'hmacIncKeyVal', 'hmacOutKeyValue', 'hmacHashType', 'hmacEnvType', 'hmacStatus', 'action'],
    complete_toggle: ['no', 'memberName', 'memberCode', 'hmacIncKeyVal', 'hmacOutKeyValue', 'hmacHashType', 'hmacEnvType', 'hmacStatus', 'action'],
    list_column_name: ['No.', 'Member Name', 'Member Code', 'Incoming Key Value', 'Outgoing Key Value', 'Hash Type', 'Environment Type', 'Status'],

    visibleModalAddDelete: false,
    showModal: [],
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1500,
};


// hmacId
function reducer(state: any, action: any) {
    let showDataResult: any[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let showModal = state.showModal;
    let newShowModal: any[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelHmacManagement) => {
                        showDataResult.push(
                            { idModal: element1.hmacId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.hmacId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelHmacManagement) => {
                        let i = 1;
                        showDataResult.push(
                            { idModal: element1.hmacId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.hmacId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (showModal !== undefined) {
                if (showModal !== null) {
                    if (showModal.length > 0) {
                        showModal.forEach((element1: any | {}) => {
                            if (element1.idModal === action.value) {
                                if (element1.showModal === false) { // will to show
                                    newShowModal.push({ idModal: element1.idModal, showModal: !(element1.showModal), itemModal: element1.itemModal, index: state.dataLength + 99 })
                                } else {
                                    newShowModal.push({ idModal: element1.idModal, showModal: !(element1.showModal), itemModal: element1.itemModal, index: 99 })
                                }
                            } else {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                showModal: newShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (showModal !== undefined) {
                if (showModal !== null) {
                    if (showModal.length > 0) {
                        showModal.forEach((element1: any | {}) => {
                            if (element1.idModal === action.value) {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                showModal: newShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            let dataFilter: any[] = [];
            dataFilter = action.value.data.filter((element1: ModelHmacManagement) => element1.hmacId === action.value.updateItemID)
            let resultIdFilter = dataFilter[0].hmacId;
            dataFilter[0].id = resultIdFilter;
            return {
                ...state,
                showModal: state.showModal.map((element1: any | {}) => {
                    if (element1.idModal === action.value.updateItemID) {
                        return { idModal: resultIdFilter, showModal: !(element1.showModal), itemModal: dataFilter[0], index: 1 }
                    } else {
                        return element1;
                    }
                }),
                data: state.data.map((element1: any | {}) => {
                    if (element1.id === action.value.updateItemID) {
                        return dataFilter[0];
                    } else {
                        return element1;
                    }
                })
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        default:
            throw new Error();
    }
}


export default Page1;
import { useEffect, useReducer, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SelectOptionsInterface } from '../../../Interface';
import { API } from '../../../Services';
import { initFunction, reducerOptions, stateOptions } from '../../../State';
import { GetDispute } from './Components/GetDispute';
import { GetGeneralInformation } from './Components/GetGeneralInformation';
import { GetInterfaceFile } from './Components/GetInterfaceFile';
import { GetPayment } from './Components/GetPayment';
import { GetSettlement } from './Components/GetSettlement';
import { GetInstitutionCode } from './Components/GetInstituionCode';
import { urlSelection } from '../../../Services/API';




const Detail1 = ({...props}) => {
    const { memberCode } = useParams()
    const dispatchGlobal = useDispatch();
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);

    const [getOptionsPIC, setOptionsPIC] = useState<any>([]);

    useEffect(() => {
        initFunction(dispatchOptions, null, () => { }, null, InterfaceOptionsDetailMember, dispatchGlobal)
    }, [])

    useEffect(() => {
        API.get({
            bodyCustom: null,
            pathCustom: `userapp?memberCode=${memberCode}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            let dataOptionsPIC: any[] = [];
            if (response.data.length > 0) {
                response.data.forEach((item: any) => {
                    if (item.userStatus === '001') dataOptionsPIC.push({ value: item.userId, label: `${item.userId} - ${item.memberName} - ${item.userName}` })
                })
            }
            setOptionsPIC(dataOptionsPIC)
        })
    }, [memberCode])


    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2 mt-2">
                Member Parameters
            </h1>
            <div className='tabs'>
                <Tabs defaultActiveKey="GeneralInformation" id="fill-tab-example" className='mt-2 bd-ea br-10' >
                    <Tab className='' eventKey="GeneralInformation" title="General Information">
                        <GetGeneralInformation codeMember={memberCode} stateOptions={stateOptions} />
                    </Tab>
                    <Tab className='' eventKey="Settlement" title="Settlement">
                        <GetSettlement codeMember={memberCode} stateOptions={stateOptions} />
                    </Tab>
                    <Tab className='' eventKey="InterfaceFiles" title="Interface Files">
                        <GetInterfaceFile codeMember={memberCode} stateOptions={stateOptions} />
                    </Tab>
                    <Tab className='' eventKey="Payment" title="Payment" >
                        <GetPayment codeMember={memberCode} stateOptions={stateOptions} getOptionsPIC={getOptionsPIC} />
                    </Tab>
                    <Tab className='' eventKey="Disputes" title="Disputes" >
                        <GetDispute codeMember={memberCode} stateOptions={stateOptions} getOptionsPIC={getOptionsPIC} />
                    </Tab>
                    <Tab className='' eventKey="InstitutionCode" title="Institution Code" >
                        <GetInstitutionCode codeMember={memberCode} stateOptions={stateOptions} getOptionsPIC={getOptionsPIC} />
                    </Tab>
                </Tabs>
            </div>

        </>

    )
}
const initialStateOptions = { ...stateOptions }

const InterfaceOptionsDetailMember: SelectOptionsInterface = {
    ccdOptions: true,
    spfOptions: false,
    memberOptions: true,
    ccgOptions: true,
    logLevel001Options: false,
    logLevel002Options: false,
    routingOptions: false,
    routeSequenceOptions: false,
    privilegeOptions: false,
    countryOptions: true,
    productOptions: false,
    productFeatureOptions: false,
    reasonCodeOptions: false,
    subProductOptions: false,
    exchangeRateOptions: true,
    elementOptions: false,
    qrStandardOptions: false,
    interfaceFieldOptions: false,
    headerFieldOptions: false,
    feeOptions: false,
    interfaceFileOptions: true,
    recordOptions: false,
    interfaceFileItemOptions: false,
    memberPICOptions: true,
    commonCodeOptions:false,
    resultOptions:false
}

export default Detail1;











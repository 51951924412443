import { createSlice } from "@reduxjs/toolkit";


export const timeoutSlice = createSlice({
    name: "timeout",
    initialState: {
        state: 'Active',
        count: 0,
        remaining: 0,
    },
    reducers: {
        stateAction: (state, action) => {
            state.state = action.payload
        },
        countAction: (state, action) => {
            state.count = action.payload

        },
        remainingAction: (state, action) => {
            state.remaining = action.payload
        }
    }
});

export const { stateAction, countAction, remainingAction } = timeoutSlice.actions;
export const stateUser = (state: any) => state.timeout.value;
export default timeoutSlice.reducer;

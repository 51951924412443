export function reducerStateSelected(state: any, action: any) {
    switch (action.type) {
        case 'selectedMember':
            return {
                ...state,
                selectedMember: action.value
            };
        case 'selectedOptionsFilter':
            return {
                ...state,
                selectedOptionsFilter: action.value
            };
        case 'selectedMemberName':
            return {
                ...state,
                selectedMemberName: action.value
            };
        case 'selectedMemberIssuer':
            return {
                ...state,
                selectedMemberIssuer: action.value
            };
        case 'selectedMemberIssuerSwitcher':
            return {
                ...state,
                selectedMemberIssuerSwitcher: action.value
            };
        case 'selectedMemberSwitcher':
            return {
                ...state,
                selectedMemberSwitcher: action.value
            };
        case 'selectedMemberAcquirer':
            return {
                ...state,
                selectedMemberAcquirer: action.value
            };
        case 'selectedMemberAcquirerSwitcher':
            return {
                ...state,
                selectedMemberAcquirerSwitcher: action.value
            };
        case 'selectedSourceMember':
            return {
                ...state,
                selectedSourceMember: action.value
            };
        case 'selectedReceivingMember':
            return {
                ...state,
                selectedReceivingMember: action.value
            };
        case 'selectedUserLevel':
            return {
                ...state,
                selectedUserLevel: action.value
            };
        case 'selectedUserCategory':
            return {
                ...state,
                selectedUserCategory: action.value
            };
        case 'selectedRateFrom':
            return {
                ...state,
                selectedRateFrom: action.value
            };
        case 'selectedRateTo':
            return {
                ...state,
                selectedRateTo: action.value
            };
        case 'selectedServerStatus':
            return {
                ...state,
                selectedServerStatus: action.value
            };
        case 'selectedConnectionStatus':
            return {
                ...state,
                selectedConnectionStatus: action.value
            };
        case 'selectedStartDate':
            return {
                ...state,
                selectedStartDate: action.value
            };
        case 'selectedEndDate':
            return {
                ...state,
                selectedEndDate: action.value
            };
        case 'selectedReasonCode':
            return {
                ...state,
                selectedReasonCode: action.value
            };
        case 'selectedMemberSrcId':
            return {
                ...state,
                selectedMemberSrcId: action.value
            };
        case 'selectedMemberDstId':
            return {
                ...state,
                selectedMemberDstId: action.value
            };
        case 'selectedMemberSrcName':
            return {
                ...state,
                selectedMemberSrcName: action.value
            };
        case 'selectedMemberDstName':
            return {
                ...state,
                selectedMemberDstName: action.value
            };
        case 'selectedProductInd':
            return {
                ...state,
                selectedProductInd: action.value
            };
        case 'selectedProcCode':
            return {
                ...state,
                selectedProcCode: action.value
            };
        case 'selectedCpan':
            return {
                ...state,
                selectedCpan: action.value
            };
        case 'selectedMidMpan':
            return {
                ...state,
                selectedMidMpan: action.value
            };
        case 'selectedCurrencyCode':
            return {
                ...state,
                selectedCurrencyCode: action.value
            };
        case 'selectedAuthAmount':
            return {
                ...state,
                selectedAuthAmount: action.value
            };
        case 'selectedAuthRrn':
            return {
                ...state,
                selectedAuthRrn: action.value
            };
        case 'selectedAuthRspnCode':
            return {
                ...state,
                selectedAuthRspnCode: action.value
            };
        case 'selectedServerId':
            return {
                ...state,
                selectedServerId: action.value
            };
        case 'selectedGlobalId':
            return {
                ...state,
                selectedGlobalId: action.value
            };
        case 'selectedAuthStan':
            return {
                ...state,
                selectedAuthStan: action.value
            };
        case 'selectedStartTime':
            return {
                ...state,
                selectedStartTime: action.value
            };
        case 'selectedEndTime':
            return {
                ...state,
                selectedEndTime: action.value
            };
        case 'selectedStartDateISOString':
            return {
                ...state,
                selectedStartDateISOString: action.value
            };
        case 'selectedEndDateISOString':
            return {
                ...state,
                selectedEndDateISOString: action.value
            };
        case 'selectedRouting':
            return {
                ...state,
                selectedRouting: action.value
            };
        case 'selectedSubProduct':
            return {
                ...state,
                selectedSubProduct: action.value
            };
        case 'selectedIssuerInstitutionIDDetailCode':
            return {
                ...state,
                selectedIssuerInstitutionIDDetailCode: action.value
            };
        case 'selectedAcquirerInstitutionIDDetailCode':
            return {
                ...state,
                selectedAcquirerInstitutionIDDetailCode: action.value
            };
        case 'selectedIssuerSwitcherInstitutionIDDetailCode':
            return {
                ...state,
                selectedIssuerSwitcherInstitutionIDDetailCode: action.value
            };
        case 'selectedDisputeCaseStatusDetailCode':
            return {
                ...state,
                selectedDisputeCaseStatusDetailCode: action.value
            };
        case 'selectedAcquirerSwitcherInstitutionIDDetailCode':
            return {
                ...state,
                selectedAcquirerSwitcherInstitutionIDDetailCode: action.value
            };
        case 'selectedPaymentStatusDetailCode':
            return {
                ...state,
                selectedPaymentStatusDetailCode: action.value
            };
        case 'optionsUserCategoryDetailCode':
            return {
                ...state,
                optionsUserCategoryDetailCode: action.value
            };
        case 'selectedReceivedDate':
            return {
                ...state,
                selectedReceivedDate: action.value
            };
        case 'selectedTransferDate':
            return {
                ...state,
                selectedTransferDate: action.value
            };
        case 'selectedCountryID':
            return {
                ...state,
                selectedCountryID: action.value
            };
        case 'selectedQRStandard':
            return {
                ...state,
                selectedQRStandard: action.value
            };
        case 'selectedQRStandardID':
            return {
                ...state,
                selectedQRStandardID: action.value
            };
        case 'selectedInterfaceRecordID':
            return {
                ...state,
                selectedInterfaceRecordID: action.value
            };
        case 'inputProgramID':
            return {
                ...state,
                inputProgramID: action.value
            };
        case 'selectedBatchStatus':
            return {
                ...state,
                selectedBatchStatus: action.value
            };
        case 'selectedBatchType':
            return {
                ...state,
                selectedBatchType: action.value
            };
        case 'inputServiceName':
            return {
                ...state,
                inputServiceName: action.value
            };
        case 'selectedServiceStatus':
            return {
                ...state,
                selectedServiceStatus: action.value
            };

        case 'selectedStatusBatch':
            return {
                ...state,
                selectedStatusBatch: action.value
            };

        case 'selectedBatchProgramId':
            return {
                ...state,
                selectedBatchProgramId: action.value
            };

        case 'selectedBatchExecutionType':
            return {
                ...state,
                selectedBatchExecutionType: action.value
            };

        case 'selectedBatchBusinessGroup':
            return {
                ...state,
                selectedBatchBusinessGroup: action.value
            };
        case 'selectedBatchId':
            return {
                ...state,
                selectedBatchId: action.value
            };
        //
        case 'selectedAcquirerDate':
            return {
                ...state,
                selectedAcquirerDate: action.value
            };
        case 'selectedIssuerDate':
            return {
                ...state,
                selectedIssuerDate: action.value
            };
        case 'acqSwitchSettleStartDate':
            return {
                ...state,
                acqSwitchSettleStartDate: action.value
            };
        case 'acqSwitchSettleEndDate':
            return {
                ...state,
                acqSwitchSettleEndDate: action.value
            };
        case 'issSwitchSettleStartDate':
            return {
                ...state,
                issSwitchSettleStartDate: action.value
            };
        case 'issSwitchSettleEndDate':
            return {
                ...state,
                issSwitchSettleEndDate: action.value
            };
        case 'selectedSourceInst':
            return {
                ...state,
                selectedSourceInst: action.value
            };

        default:
            throw new Error();
    }
}
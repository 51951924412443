import { FiAlertTriangle } from "react-icons/fi";
export const chartIcon = (
  <svg style={{ width: "16px", height: "16px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8582 1.74762C18.2738 1.94549 18.4502 2.44275 18.2523 2.85828L14.0857 11.6083C13.9726 11.8457 13.7541 12.0158 13.4962 12.0673C13.2383 12.1187 12.9713 12.0453 12.7758 11.8694L9.42258 8.85149L6.57865 14.5393C6.37282 14.951 5.87226 15.1178 5.46061 14.912C5.04897 14.7062 4.88211 14.2056 5.08794 13.794L8.42127 7.12732C8.53761 6.89463 8.75595 6.72963 9.01156 6.68122C9.26717 6.63281 9.53073 6.70655 9.7241 6.88059L13.0609 9.88372L16.7476 2.14172C16.9454 1.72619 17.4427 1.54975 17.8582 1.74762ZM2.49996 1.66667C2.9602 1.66667 3.33329 2.03976 3.33329 2.5V16.6667H17.5C17.9602 16.6667 18.3333 17.0398 18.3333 17.5C18.3333 17.9602 17.9602 18.3333 17.5 18.3333H2.49996C2.03972 18.3333 1.66663 17.9602 1.66663 17.5V2.5C1.66663 2.03976 2.03972 1.66667 2.49996 1.66667Z"
      fill="#292929"
    />
  </svg>
);

export const productManagementIcon = (
  <svg style={{ width: "18px", height: "18px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.929083 5.4454C1.01438 5.28304 1.14615 5.16016 1.29978 5.08498L9.62733 0.92123C9.86191 0.803926 10.1381 0.803926 10.3727 0.92123L18.6997 5.08471C18.8535 5.15987 18.9855 5.28285 19.0709 5.4454C19.0776 5.45805 19.0839 5.47087 19.0899 5.48386C19.1446 5.60223 19.1692 5.72724 19.1667 5.85V14.1666C19.1667 14.4822 18.9883 14.7708 18.706 14.912L10.3872 19.0713C10.2782 19.1287 10.1548 19.1626 10.0239 19.1663C10.016 19.1665 10.008 19.1666 9.99999 19.1666C9.98478 19.1666 9.96957 19.1662 9.95437 19.1653C9.83154 19.1587 9.71578 19.1255 9.61274 19.0713L1.29398 14.912C1.01166 14.7708 0.833328 14.4822 0.833328 14.1666V5.85001C0.830754 5.72725 0.855355 5.60223 0.9101 5.48386C0.9161 5.47087 0.92243 5.45805 0.929083 5.4454ZM14.1668 4.68171L16.4699 5.83326L9.99999 9.06822L7.6969 7.91668L14.1668 4.68171ZM12.3034 3.75001L9.99999 2.59827L3.53006 5.83326L5.83349 6.98497L12.3034 3.75001ZM5.44295 8.65309L2.49999 7.18161V13.6515L9.16666 16.9849V10.5149L5.4773 8.67027C5.46573 8.66481 5.45428 8.65908 5.44295 8.65309ZM10.8333 16.9849V10.5149L17.5 7.18161V13.6515L10.8333 16.9849Z"
      fill="#292929"
    />
  </svg>
);

export const interfaceIcon = (
  <svg style={{ width: "17px", height: "17px" }} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.666672 2.75008C0.666672 1.59949 1.59941 0.666748 2.75001 0.666748H15.25C16.4006 0.666748 17.3333 1.5995 17.3333 2.75008V15.2501C17.3333 16.4007 16.4006 17.3334 15.25 17.3334H2.75001C1.59942 17.3334 0.666672 16.4007 0.666672 15.2501V2.75008ZM2.75001 2.33341C2.51989 2.33341 2.33334 2.51996 2.33334 2.75008V15.2501C2.33334 15.4802 2.51988 15.6667 2.75001 15.6667H15.25C15.4801 15.6667 15.6667 15.4802 15.6667 15.2501V2.75008C15.6667 2.51996 15.4801 2.33341 15.25 2.33341H2.75001Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

export const codeManagementIcon = (
  <svg style={{ width: "18px", height: "16px" }} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9353 1.18721C11.9926 0.730552 11.6689 0.313895 11.2122 0.256576C10.7556 0.199258 10.3389 0.522983 10.2816 0.979637L8.29424 16.813C8.23692 17.2696 8.56065 17.6863 9.0173 17.7436C9.47396 17.8009 9.89061 17.4772 9.94793 17.0205L11.9353 1.18721Z" fill="black" fillOpacity="0.85" />
    <path
      d="M7.2454 3.81717C7.57654 4.1368 7.58588 4.66435 7.26625 4.9955L2.84536 9.5756L7.24501 13.8168C7.57636 14.1362 7.58604 14.6638 7.26662 14.9951C6.94721 15.3265 6.41966 15.3361 6.08831 15.0167L1.08831 10.1968C0.929122 10.0433 0.837446 9.83292 0.833464 9.61185C0.829481 9.39078 0.913519 9.17718 1.06708 9.0181L6.06708 3.83801C6.38671 3.50687 6.91426 3.49754 7.2454 3.81717Z"
      fill="black"
      fillOpacity="0.85"
    />
    <path
      d="M12.7546 3.81717C13.0857 3.49754 13.6133 3.50687 13.9329 3.83801L18.9329 9.0181C19.0865 9.17718 19.1705 9.39078 19.1665 9.61185C19.1625 9.83292 19.0709 10.0433 18.9117 10.1968L13.9117 15.0167C13.5803 15.3361 13.0528 15.3265 12.7334 14.9951C12.414 14.6638 12.4236 14.1362 12.755 13.8168L17.1546 9.5756L12.7337 4.9955C12.4141 4.66435 12.4234 4.1368 12.7546 3.81717Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

export const qrManagementIcon = (
  <svg style={{ width: "17px", height: "17px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833336 1.66659C0.833336 1.20635 1.20643 0.833252 1.66667 0.833252H6.66667C7.12691 0.833252 7.5 1.20635 7.5 1.66659V6.66659C7.5 7.12682 7.12691 7.49992 6.66667 7.49992H1.66667C1.20643 7.49992 0.833336 7.12682 0.833336 6.66659V1.66659ZM2.5 2.49992V5.83325H5.83334V2.49992H2.5ZM10 0.833252C10.4602 0.833252 10.8333 1.20635 10.8333 1.66659V10.8333H1.66667C1.20643 10.8333 0.833336 10.4602 0.833336 9.99992C0.833336 9.53968 1.20643 9.16659 1.66667 9.16659H9.16667V1.66659C9.16667 1.20635 9.53977 0.833252 10 0.833252ZM12.5 1.66659C12.5 1.20635 12.8731 0.833252 13.3333 0.833252H18.3333C18.7936 0.833252 19.1667 1.20635 19.1667 1.66659V6.66659C19.1667 7.12682 18.7936 7.49992 18.3333 7.49992H13.3333C12.8731 7.49992 12.5 7.12682 12.5 6.66659V1.66659ZM14.1667 2.49992V5.83325H17.5V2.49992H14.1667ZM12.5 9.99992C12.5 9.53968 12.8731 9.16659 13.3333 9.16659H18.3333C18.7936 9.16659 19.1667 9.53968 19.1667 9.99992C19.1667 10.4602 18.7936 10.8333 18.3333 10.8333H13.3333C12.8731 10.8333 12.5 10.4602 12.5 9.99992ZM0.833336 13.3333C0.833336 12.873 1.20643 12.4999 1.66667 12.4999H6.66667C7.12691 12.4999 7.5 12.873 7.5 13.3333V18.3333C7.5 18.7935 7.12691 19.1666 6.66667 19.1666H1.66667C1.20643 19.1666 0.833336 18.7935 0.833336 18.3333V13.3333ZM2.5 14.1666V17.4999H5.83334V14.1666H2.5ZM10 12.4999C10.4602 12.4999 10.8333 12.873 10.8333 13.3333V18.3333C10.8333 18.7935 10.4602 19.1666 10 19.1666C9.53977 19.1666 9.16667 18.7935 9.16667 18.3333V13.3333C9.16667 12.873 9.53977 12.4999 10 12.4999ZM12.5 13.3333C12.5 12.873 12.8731 12.4999 13.3333 12.4999H18.3333C18.7936 12.4999 19.1667 12.873 19.1667 13.3333V18.3333C19.1667 18.7935 18.7936 19.1666 18.3333 19.1666H13.3333C12.8731 19.1666 12.5 18.7935 12.5 18.3333V13.3333ZM14.1667 14.1666V17.4999H17.5V14.1666H14.1667Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);
export const feeManagementIcon = (
  <svg style={{ width: "18px", height: "12px" }} viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833336 1.41659C0.833336 0.956348 1.20643 0.583252 1.66667 0.583252H18.3333C18.7936 0.583252 19.1667 0.956348 19.1667 1.41659V11.4166C19.1667 11.8768 18.7936 12.2499 18.3333 12.2499H1.66667C1.20643 12.2499 0.833336 11.8768 0.833336 11.4166V1.41659ZM2.5 5.4999V7.33327C4.13261 7.66467 5.41859 8.95064 5.74999 10.5833H14.25C14.5814 8.95064 15.8674 7.66467 17.5 7.33327V5.4999C15.8674 5.1685 14.5814 3.88253 14.25 2.24992H5.74999C5.41859 3.88253 4.13261 5.1685 2.5 5.4999ZM4.02442 2.24992H2.5V3.77433C3.21035 3.52326 3.77335 2.96027 4.02442 2.24992ZM15.9756 2.24992C16.2267 2.96027 16.7897 3.52326 17.5 3.77433V2.24992H15.9756ZM17.5 9.05884C16.7897 9.30991 16.2267 9.8729 15.9756 10.5833H17.5V9.05884ZM4.02442 10.5833C3.77335 9.8729 3.21035 9.30991 2.5 9.05884V10.5833H4.02442ZM7.08334 6.41659C7.08334 4.72435 8.25337 3.08325 10 3.08325C11.7466 3.08325 12.9167 4.72435 12.9167 6.41659C12.9167 8.10882 11.7466 9.74992 10 9.74992C8.25337 9.74992 7.08334 8.10882 7.08334 6.41659ZM10 4.74992C9.44547 4.74992 8.75 5.3474 8.75 6.41659C8.75 7.48577 9.44547 8.08325 10 8.08325C10.5545 8.08325 11.25 7.48577 11.25 6.41659C11.25 5.3474 10.5545 4.74992 10 4.74992Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

export const logIcon = (
  <svg style={{ width: "18px", height: "18px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.20004 1C4.6713 1 1 4.6713 1 9.20004C1 13.7288 4.6713 17.4001 9.20004 17.4001C11.1535 17.4001 12.9474 16.717 14.356 15.5767L17.5265 18.7472C17.8636 19.0843 18.4101 19.0843 18.7472 18.7472C19.0843 18.4101 19.0843 17.8636 18.7472 17.5265L15.5767 14.356C16.717 12.9474 17.4001 11.1535 17.4001 9.20004C17.4001 4.6713 13.7288 1 9.20004 1ZM2.72632 9.20004C2.72632 5.62473 5.62473 2.72632 9.20004 2.72632C12.7754 2.72632 15.6738 5.62473 15.6738 9.20004C15.6738 12.7754 12.7754 15.6738 9.20004 15.6738C5.62473 15.6738 2.72632 12.7754 2.72632 9.20004ZM11.7895 5.14319C11.4558 5.14319 11.1853 5.4137 11.1853 5.7474V11.1853H9.97689V6.95583C9.97689 6.62213 9.70638 6.35162 9.37268 6.35162C9.03898 6.35162 8.76847 6.62213 8.76847 6.95583V11.1853H7.56003V8.16425C7.56003 7.83055 7.28951 7.56003 6.95582 7.56003C6.62212 7.56003 6.3516 7.83055 6.3516 8.16425V11.1853H5.74739C5.41369 11.1853 5.14318 11.4558 5.14318 11.7895C5.14318 12.1232 5.41369 12.3937 5.74739 12.3937H12.998C13.3317 12.3937 13.6022 12.1232 13.6022 11.7895C13.6022 11.4558 13.3317 11.1853 12.998 11.1853H12.3937V5.7474C12.3937 5.4137 12.1232 5.14319 11.7895 5.14319Z" fill="#292929" />
  </svg>
);

export const settlementIcon = (
  <svg style={{ width: "17px", height: "17px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.91667 5.83325C7.91667 5.37301 8.28976 4.99992 8.75 4.99992H13.75C14.2102 4.99992 14.5833 5.37301 14.5833 5.83325C14.5833 6.29349 14.2102 6.66658 13.75 6.66658H8.75C8.28976 6.66658 7.91667 6.29349 7.91667 5.83325Z" fill="#292929" />
    <path d="M8.75 9.16658C8.28976 9.16658 7.91667 9.53968 7.91667 9.99992C7.91667 10.4602 8.28976 10.8333 8.75 10.8333H13.75C14.2102 10.8333 14.5833 10.4602 14.5833 9.99992C14.5833 9.53968 14.2102 9.16658 13.75 9.16658H8.75Z" fill="#292929" />
    <path d="M7.91667 14.1666C7.91667 13.7063 8.28976 13.3333 8.75 13.3333H13.75C14.2102 13.3333 14.5833 13.7063 14.5833 14.1666C14.5833 14.6268 14.2102 14.9999 13.75 14.9999H8.75C8.28976 14.9999 7.91667 14.6268 7.91667 14.1666Z" fill="#292929" />
    <path d="M7.08333 5.83325C7.08333 6.2935 6.71025 6.66658 6.25 6.66658C5.78975 6.66658 5.41667 6.2935 5.41667 5.83325C5.41667 5.373 5.78975 4.99992 6.25 4.99992C6.71025 4.99992 7.08333 5.373 7.08333 5.83325Z" fill="#292929" />
    <path d="M6.25 10.8333C6.71025 10.8333 7.08333 10.4602 7.08333 9.99992C7.08333 9.53967 6.71025 9.16658 6.25 9.16658C5.78975 9.16658 5.41667 9.53967 5.41667 9.99992C5.41667 10.4602 5.78975 10.8333 6.25 10.8333Z" fill="#292929" />
    <path d="M7.08333 14.1666C7.08333 14.6268 6.71025 14.9999 6.25 14.9999C5.78975 14.9999 5.41667 14.6268 5.41667 14.1666C5.41667 13.7063 5.78975 13.3333 6.25 13.3333C6.71025 13.3333 7.08333 13.7063 7.08333 14.1666Z" fill="#292929" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 2.49992C2.5 1.57944 3.24619 0.833252 4.16667 0.833252H15.8333C16.7538 0.833252 17.5 1.57944 17.5 2.49992V17.4999C17.5 18.4204 16.7538 19.1666 15.8333 19.1666H4.16667C3.24619 19.1666 2.5 18.4204 2.5 17.4999V2.49992ZM15.8333 2.49992H4.16667V17.4999H15.8333V2.49992Z" fill="#292929" />
  </svg>
);

export const taxDemoIcon = (
  <svg style={{ width: "20px", height: "20px" }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2H22C23.1046 2 24 2.89543 24 4V20C24 21.1046 23.1046 22 22 22H2C0.89543 22 0 21.1046 0 20V4C0 2.89543 0.89543 2 2 2Z" fill="#292929" />
    <path d="M4 6H20V8H4V6Z" fill="#fff" />
    <path d="M4 10H20V12H4V10Z" fill="#fff" />
    <path d="M4 14H20V16H4V14Z" fill="#fff" />
    <path d="M4 18H20V20H4V18Z" fill="#fff" />
    <path d="M7 8V14H11V8H7Z" fill="#6b6b6b" />
    <path d="M13 10V14H17V10H13Z" fill="#6b6b6b" />
  </svg>

);

export const alertHistoryIcon = (
  <FiAlertTriangle />
/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24">
  <rect x="0" y="4" width="16" height="16" fill="#ffffff" stroke="#000" stroke-width="1" rx="2"/>
  
  <line x1="0" y1="10" x2="16" y2="10" stroke="#ccc" stroke-width="0.5"/>
  <line x1="0" y1="14" x2="16" y2="14" stroke="#ccc" stroke-width="0.5"/>
  
  <circle cx="8" cy="2" r="1.5" fill="#ff4d4d"/>
  <rect x="7.5" y="4" width="1" height="6" fill="#fff"/>
  <rect x="7.5" y="12" width="1" height="2" fill="#fff"/>
</svg> */
)
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { undo_icon } from '../../Assets/Images/svg';
import { ButtonDelete, ButtonEdit, TableWithLimitOffset } from '../../Components';
import { ButtonSearch1 } from '../../Components/ButtonSearch';
import { ShowModalInterface } from '../../Interface';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { inputStyle, labelStyle } from '../../Styles';
import { InterfaceOptionsBatchScheduler } from './InterfaceOptions';
import { ModalDraggableBatchProgramRegistration } from '../BatchProgramProfile/ModalDraggableBatchProgramRegistration';
import { ModalBatchProgramRegistration } from '../BatchProgramProfile/ModalBatchProgramRegistration';
import { ModelBatchScheduler } from './Models';
import { TableBatchProgramRegistration } from '../BatchProgramProfile/TableBatchProgramRegistration';
import {  ExcelBatchScheduler } from './Excel';
import { ModalBatchScheduler } from './Modals';
import { ModalDraggableBatchScheduler } from './ModalDraggable';
import { TableBatchScheduler } from './Tables';
import { Page53Title } from '../../State/Menu/TitleMenu';

const Page3 = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${search}${limit}${offset}`;
        let finalParameter2 = `${search}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsBatchScheduler, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])


    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelBatchScheduler(state, stateOptions, response.data)
        })
    }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelBatchScheduler) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelBatchScheduler, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                <ButtonEdit handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} isDisabled={(item.batchStatus === '002') ? true : false} />
            </div>
        );
    };
    const filterComponentPage = () => {
        return <></>
        return (
            <div className="px-2 container-all-select-custom h-100px">
                <div className="row  gx-2">
                    <div className="col-3 ">
                        <p className={labelStyle}>PROGRAM ID</p>
                        <input value={stateCustomComponent.inputProgramID} onChange={(e) => handleState('inputProgramID', e.target.value, dispatchCustomComponent)} className={inputStyle} />
                    </div>
                    <div className="col-3">
                        <p className={labelStyle}>BATCH STATUS</p>
                        <div className='d-flex justify-content-between'>
                            <Select className='flex-fill' options={stateOptions.optionsCommonStatusDetailCode} value={stateOptions.optionsCommonStatusDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedBatchStatus)} onChange={(e) => handleState("selectedBatchStatus", e.value, dispatchCustomComponent)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedBatchStatus", '', dispatchCustomComponent) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <p className={labelStyle}>BATCH TYPE</p>
                        <div className='d-flex justify-content-between'>
                            <Select className='flex-fill' options={stateOptions.optionsBatchProgramTypeDetailCode} value={stateOptions.optionsBatchProgramTypeDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedBatchType)} onChange={(e) => handleState("selectedBatchType", e.value, dispatchCustomComponent)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedBatchType", '', dispatchCustomComponent) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-2 d-flex justify-content-end  align-items-end">
                        <ButtonSearch1 initFunctionCustom={reloadDataComponent} />
                    </div>
                </div>
            </div>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableBatchScheduler
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                currentPage={state.currentPage}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <ModalBatchScheduler
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableBatchScheduler
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={true}
                needSearchFunction={true}
                needDownloadButton={true}
            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'batch/run-batch-v2',
    titlePage: Page53Title,

    privilegeAccess: { view: "551", add: "552", edit: "553", delete: "554", download: "555" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Batch ID", width: "wd-150", val: "batchId" },
        { field: "Batch Job ID", width: "wd-150", val: "id" },
        { field: "Batch Pre Requisite", width: "wd-200", val: "batchPreRequisite" },
        { field: "Batch Last Execution", width: "wd-200", val: "batchLastExec" },
        { field: "Batch Group Path", width: "wd-200", val: "batchGroupPath" },
        { field: "Batch Reg Type", width: "wd-200", val: "batchRegType" },
        { field: "Batch Rerun Manually Dttm", width: "wd-200", val: "batchRerunManuallyDttm" },
        { field: "Batch Planned Dttm", width: "wd-200", val: "batchPlannedDttm" },
        { field: "Batch Run Time", width: "wd-200", val: "batchRunTime" },
        { field: "Batch Parameter Value", width: "wd-200", val: "batchParameterValue" },
        { field: "Batch Execution Type", width: "wd-150", val: "batchExecutionType" },
        { field: "Batch Interval", width: "wd-150", val: "batchInterval" },
        { field: 'Action', width: 'wd-150', val: 'action' }
    ],
    toggle: ["no", "batchId", "id", "batchPreRequisite", "batchLastExec", "batchGroupPath", "batchRegType", "batchRerunManuallyDttm", "batchPlannedDttm", "batchRunTime", "batchParameterValue", "batchExecutionType", "batchInterval", 'action'],
    complete_toggle: ["no", "batchId", "id", "batchPreRequisite", "batchLastExec", "batchGroupPath", "batchRegType", "batchRerunManuallyDttm", "batchPlannedDttm", "batchRunTime", "batchParameterValue", "batchExecutionType", "batchInterval", 'action'],
    list_column_name: ["No.", "Batch ID", "Batch Job ID", "Batch Pre Requisite", "Batch Last Execution", "Batch Group Path", "Batch Reg Type", "Batch Rerun Manually Dttm", "Batch Planned Dttm", "Batch Run Time", "Batch Parameter Value", "Batch Execution Type", "Batch Interval"],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Batch ID, Batch Parameter Value',

    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 3000,
    dataLength: 0, // zIndex
    finalAPI: '',
};


// batchId ModelBatchScheduler
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelBatchScheduler) => {
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelBatchScheduler) => {
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelBatchScheduler) => {
                        showDataResult.push(
                            { idModal: element1.id!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.id!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };

        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}

export default Page3;
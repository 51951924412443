import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { inputStyle, labelStyle } from "../../Styles";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { IoSearch } from "react-icons/io5";


import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
import ModalDetailSettlementSummaryIssuer from "../SettlementSummaryIssuer/ModalDetailSettlementSummaryIssuer";
import { close } from "../../Assets/Images/svg";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { stateSelected } from "../../State";
import { urlSelection } from "../../Services/API";
import { useDispatch } from "react-redux";
import ModalDetailSettlementSummaryAcquirer from "./ModalDetailSettlementSummary";
import { ModelDetail } from "./Models/ModelDetail";
import { MRT_ColumnDef } from "material-react-table";
import { TableLeftAPI } from "./Tables/TableLeftAPI";
import { ResizableBox } from "react-resizable";
import { CheckUndefinedNumberEmpty } from "../../Utils/CheckUndefinedNumberEmpty";

export const ModalSettlementSummary = ({ ...props }) => {
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 10 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const dispatchGlobal = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [statePage, dispatchStatePage] = useReducer(reducerStatePage, initialStatePage);


  const { stateOptions, stateParent, dispatchParent, modalCondition, setModal } = props;
  const { optionsTransactionTypeDetailCodeAndCcdDesc } = stateOptions;

  const handleMouseDown = (e: any) => {
    setDragging(true);
    setOffset({
      x: e.clientX - modalPosition.x,
      y: e.clientY - modalPosition.y,
    });
  };
  const onResize = (event: any, propsSize: any) => {
    const { node, size, handle } = propsSize;
    if (size.width > 100 && size.height > 100) {
      dispatchStatePage({ type: "resize", value: { width: size.width, height: size.height } });
    }
  };

  const handleMouseMove = (e: any) => {
    if (dragging) {
      setModalPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  React.useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);


  useEffect(() => {
    let selectedacqSwitchMemberCode = stateParent.selectedacqSwitchMemberCode !== "" ? `acqSwitchMemberCode=${stateParent.selectedacqSwitchMemberCode}&` : "";
    let selectedacqSwitchSettDate = stateParent.selectedacqSwitchSettDate !== "" ? `acqSwitchSettDate=${stateParent.selectedacqSwitchSettDate}&` : "";
    let selectedissSwitchMemberCode = stateParent.selectedissSwitchMemberCode !== "" ? `issSwitchMemberCode=${stateParent.selectedissSwitchMemberCode}&` : "";
    let selectedissSwitchSettDate = stateParent.selectedissSwitchSettDate !== "" ? `issSwitchSettDate=${stateParent.selectedissSwitchSettDate}&` : "";
    let finalParameter = `${selectedacqSwitchMemberCode}${selectedacqSwitchSettDate}${selectedissSwitchMemberCode}${selectedissSwitchSettDate}`;
    if (selectedacqSwitchSettDate !== '' || selectedissSwitchSettDate !== '')
      dispatch({ type: "finalParameterLeftAPI", value: `${finalParameter.slice(0, -1)}` });
    API.get({
      bodyCustom: null,
      pathCustom: `settlement/settlement-summary-pop-up?${finalParameter.slice(0, -1)}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response: any) => { dispatch({ type: "dataLeftAPI", value: response.data[0] }); dispatch({ type: "dataRightAPI", value: response.data[1] }) });
  }, [stateParent.selectedacqSwitchMemberCode, stateParent.selectedacqSwitchSettDate, stateParent.selectedissSwitchMemberCode, stateParent.selectedissSwitchSettDate]);
  const handleClickRowTable = (item: any) => {
    console.log("item,", item)
    dispatch({
      type: "handleClickRowTableRight", value: {
        selectedTransTypeRight: item.secondTransTypeId,
        selectedMemberSettleDateRight: item.secondSettleDate,
        selectedMemberTableTypeRight: item.secondMemberTableType,
        selectedMemberCodeRight: item.secondMemberCode,
        selectedMemberRoleRight: item.secondTypeRole,
        selectedSecondSettleDateRight: item.settDate,
        selectedSecondMemberCodeRight: item.memberCode,
        selectedItemRight: item.selectedItem
      }
    });
    dispatch({
      type: "handleClickRowTable", value: {
        selectedTransType: item.transTypeId,
        selectedMemberSettleDate: item.settDate,
        selectedMemberTableType: item.memberTableType,
        selectedMemberCode: item.memberCode,
        selectedMemberRole: item.typeRole,
        selectedSecondSettleDate: item.secondSettleDate,
        selectedSecondMemberCode: item.secondMemberCode,
        selectedItem: item.selectedItem
      }
    });
  };
  const handleClickRowTable2 = (item: any) => {
    dispatch({
      type: "handleClickRowTableRight", value: {
        selectedTransTypeRight: item.transTypeId,
        selectedMemberSettleDateRight: item.settDate,
        selectedMemberTableTypeRight: item.memberTableType,
        selectedMemberCodeRight: item.memberCode,
        selectedMemberRoleRight: item.typeRole,
        selectedSecondSettleDateRight: item.secondSettleDate,
        selectedSecondMemberCodeRight: item.secondMemberCode,
        selectedItemRight: item.selectedItem
      }
    });
    dispatch({
      type: "handleClickRowTable", value: {
        selectedTransType: item.secondTransTypeId,
        selectedMemberSettleDate: item.secondSettleDate,
        selectedMemberTableType: item.secondMemberTableType,
        selectedMemberCode: item.secondMemberCode,
        selectedMemberRole: item.secondTypeRole,
        selectedSecondSettleDate: item.settDate,
        selectedSecondMemberCode: item.memberCode,
        selectedItem: item.selectedItem
      }
    });
  };
  useEffect(() => {
    if (state.detailModal === false) {
      dispatch({ type: "selectedTransType", value: "" });
    }
  }, [state.detailModal]);

  useEffect(() => {
    if (props.modalCondition === false) {
      dispatch({ type: "resetDataLeftAPI", value: "" });
      dispatch({ type: "resetDataRightAPI", value: "" });
    }
  }, [props.modalCondition]);

  const handleState = (nameState: string | any, valueState: any) => {
    dispatch({ type: nameState, value: valueState });
  };
  type Data = {
    transType: string,
    count: number,
    transAmount: string,
    fee: string,
    settAmount: string,
    typeRole: string,
    type: string,
    memberTableType: string,
    transactionTotalCount: string,
    transactionTotalAmount: string,
    feeTotalAmount: string,
    settlementTotalAmount: string,
    memberCode: string,
    memberName: string,
    settDate: string,
    secondSettleDate: string,
    secondMemberCode: string
  };
  const dataLeft: Data[] = state.dataLeftAPI?.settlementMemberDetailDto?.transactionTypeDto.map((item: any) => ({
    transType: item.transactionType,
    transTypeId: item.transactionTypeId,
    count: parseInt(item.count, 10),
    transAmount: item.transactionAmount,
    transactionCurrCode:item.transactionCurrCode,
    feeCurrCode: item.feeCurrCode,
    settlementCurrCode:item.settlementCurrCode,
    fee: item.feeAmount,
    settAmount: item.settlementAmount,
    typeRole: state.dataLeftAPI?.typeRole,
    type: state.dataLeftAPI?.type,
    memberTableType: state.dataLeftAPI?.settlementMemberDetailDto?.memberTableType,
    transactionTotalCount: state.dataLeftAPI?.settlementMemberDetailDto?.transactionTotalCount,
    transactionCurrCodeTotal: state.dataLeftAPI?.settlementMemberDetailDto?.transactionCurrCode,
    feeCurrCodeTotal: state.dataLeftAPI?.settlementMemberDetailDto?.feeCurrCode,
    settlementCurrCodeTotal: state.dataLeftAPI?.settlementMemberDetailDto?.settlementCurrCode,
    transactionTotalAmount: state.dataLeftAPI?.settlementMemberDetailDto?.transactionTotalAmount,
    feeTotalAmount: state.dataLeftAPI?.settlementMemberDetailDto?.transactionTotalAmount,
    settlementTotalAmount: state.dataLeftAPI?.settlementMemberDetailDto?.transactionTotalAmount,
    memberCode: state.dataLeftAPI?.settlementMemberDetailDto?.memberCode,
    memberName: state.dataLeftAPI?.settlementMemberDetailDto?.memberName,
    settDate: state.dataLeftAPI?.settlementMemberDetailDto?.settDate,
    secondSettleDate: state.dataRightAPI?.settlementMemberDetailDto?.settDate,
    secondMemberCode: state.dataRightAPI?.settlementMemberDetailDto?.memberCode,
    secondMemberTableType: state.dataRightAPI?.settlementMemberDetailDto?.memberTableType,
    secondTypeRole: state.dataRightAPI?.typeRole,
    secondTransTypeId: item.transactionTypeId,
    selectedItem: 'left'
  })) || [];
  const dataRight: Data[] = state.dataRightAPI?.settlementMemberDetailDto?.transactionTypeDto.map((item: any) => ({
    transType: item.transactionType,
    transTypeId: item.transactionTypeId,
    count: parseInt(item.count, 10),
    transAmount: item.transactionAmount,
    transactionCurrCode:item.transactionCurrCode,
    feeCurrCode: item.feeCurrCode,
    settlementCurrCode:item.settlementCurrCode,
    fee: item.feeAmount,
    settAmount: item.settlementAmount,
    typeRole: state.dataRightAPI?.typeRole,
    type: state.dataRightAPI?.type,
    memberTableType: state.dataRightAPI?.settlementMemberDetailDto?.memberTableType,
    transactionTotalCount: state.dataRightAPI?.settlementMemberDetailDto?.transactionTotalCount,
    transactionTotalAmount: state.dataRightAPI?.settlementMemberDetailDto?.transactionTotalAmount,
    feeTotalAmount: state.dataRightAPI?.settlementMemberDetailDto?.transactionTotalAmount,
    settlementTotalAmount: state.dataRightAPI?.settlementMemberDetailDto?.transactionTotalAmount,
    transactionCurrCodeTotal: state.dataRightAPI?.settlementMemberDetailDto?.transactionCurrCode,
    feeCurrCodeTotal: state.dataRightAPI?.settlementMemberDetailDto?.feeCurrCode,
    settlementCurrCodeTotal: state.dataRightAPI?.settlementMemberDetailDto?.settlementCurrCode,
    memberCode: state.dataRightAPI?.settlementMemberDetailDto?.memberCode,
    memberName: state.dataRightAPI?.settlementMemberDetailDto?.memberName,
    settDate: state.dataRightAPI?.settlementMemberDetailDto?.settDate,
    secondSettleDate: state.dataLeftAPI?.settlementMemberDetailDto?.settDate,
    secondMemberCode: state.dataLeftAPI?.settlementMemberDetailDto?.memberCode,
    secondMemberTableType: state.dataLeftAPI?.settlementMemberDetailDto?.memberTableType,
    secondTypeRole: state.dataLeftAPI?.typeRole,
    secondTransTypeId: item.transactionTypeId,
    selectedItem: 'right'
  })) || [];


  const totalCountLeft = dataLeft.reduce((sum, item) => sum + item.count, 0);
  const totalTransAmountLeft = dataLeft.reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
  const totalFeeLeft = dataLeft.reduce((sum, item) => sum + parseFloat(item.fee), 0);
  const totalSettAmountLeft = dataLeft.reduce((sum, item) => sum + parseFloat(item.settAmount), 0);

  const totalCountRight = dataRight.reduce((sum, item) => sum + item.count, 0);
  const totalTransAmountRight = dataRight.reduce((sum, item) => sum + parseFloat(item.transAmount), 0);
  const totalFeeRight = dataRight.reduce((sum, item) => sum + parseFloat(item.fee), 0);
  const totalSettAmountRight = dataRight.reduce((sum, item) => sum + parseFloat(item.settAmount), 0);


  const columnsLeftDataAPI = useMemo<MRT_ColumnDef<Data>[]>(() => [
    {
      accessorKey: 'transType',
      header: 'Trans Type',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.transType}`,
      footer: 'Total',
    },
    {
      accessorKey: 'count',
      header: 'Count',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.count}`,
      footer: `${totalCountLeft}`,
    },
    {
      accessorKey: 'transAmount',
      header: 'Transaction Amount',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.transactionCurrCode} ${CheckUndefinedNumberEmpty(row.original.transAmount)}`,
      footer: `${state.dataLeftAPI?.settlementMemberDetailDto?.transactionCurrCode} ${totalTransAmountLeft.toFixed(2)}`,
    },
    {
      accessorKey: 'fee',
      header: 'Fee',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => ` ${row.original.feeCurrCode} ${CheckUndefinedNumberEmpty(row.original.fee)}`,
      footer: ` ${state.dataLeftAPI?.settlementMemberDetailDto?.feeCurrCode} ${state.dataLeftAPI?.settlementMemberDetailDto?.feeTotalAmount}`,
    },
    {
      accessorKey: 'settAmount',
      header: 'Settlement Amount',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.settlementCurrCode} ${CheckUndefinedNumberEmpty(row.original.settAmount)}`,
      footer: ` ${state.dataLeftAPI?.settlementMemberDetailDto?.settlementCurrCode} ${state.dataLeftAPI?.settlementMemberDetailDto?.settlementTotalAmount}`,
    },
  ], [dataLeft]);

  const columnsRightDataAPI = useMemo<MRT_ColumnDef<Data>[]>(() => [
    {
      accessorKey: 'transType',
      header: 'Trans Type',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.transType}`,
      footer: 'Total',
    },
    {
      accessorKey: 'count',
      header: 'Count',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.count}`,
      footer: `${totalCountRight}`,
    },
    {
      accessorKey: 'transAmount',
      header: 'Transaction Amount',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.transactionCurrCode} ${CheckUndefinedNumberEmpty(row.original.transAmount)}`,
      footer: `${state.dataRightAPI?.settlementMemberDetailDto?.transactionCurrCode} ${totalTransAmountRight.toFixed(2)}`,
    },
    {
      accessorKey: 'fee',
      header: 'Fee',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => ` ${row.original.feeCurrCode} ${CheckUndefinedNumberEmpty(row.original.fee)}`,
      footer: ` ${state.dataRightAPI?.settlementMemberDetailDto?.feeCurrCode} ${state.dataRightAPI?.settlementMemberDetailDto?.feeTotalAmount}`,
    },
    {
      accessorKey: 'settAmount',
      header: 'Settlement Amount',
      maxSize: 100,
      enableSorting: false,
      Cell: ({ row }: any) => `${row.original.settlementCurrCode} ${CheckUndefinedNumberEmpty(row.original.settAmount)}`,
      footer: ` ${state.dataRightAPI?.settlementMemberDetailDto?.settlementCurrCode} ${state.dataRightAPI?.settlementMemberDetailDto?.settlementTotalAmount}`,
    },
  ], [dataRight]);

  let widthWindows_ = window.innerWidth;

  useEffect(() => {
    dispatch({ type: "selectedDateModal", value: stateParent.selectedProcDate });
  }, [stateParent.selectedProcDate]);

  let settleDateLeft = "";
  if (stateParent.whatIsSelectedNow === "issuerId") {
    settleDateLeft = stateParent.selectedIssuerSettleDate;
  } else if (stateParent.whatIsSelectedNow === "acquirerId") {
    settleDateLeft = stateParent.selectedAcquirerSettleDate;
  } else if (stateParent.whatIsSelectedNow === "acquirerSwitchId") {
    settleDateLeft = stateParent.selectedAcquirerSwitchSettleDate;
  } else if (stateParent.whatIsSelectedNow === "issuerSwitchId") {
    settleDateLeft = stateParent.selectedIssuerSwitchSettleDate;
  }

  // const componentModal = useCallback(() => {
  //     return (

  //     )
  // }, [])
  return (
    <>
      <Modal
        open={modalCondition}
        onCancel={(e) => setModal("modalCondition")}
        maskClosable={false}
        mask={true}
        maskStyle={{
          zIndex: 15,
        }}
        style={{
          position: "fixed",
          overflow: "unset",
          // right: 'auto',
          // bottom: 'auto',
          // top: '50%',
          // left: '50%',
          // marginRight: '-30%',
          // transform: 'translate(-50%, -50%)',
          //
          right: "2%",
          bottom: "auto",
          // top: "2%",
          // left: "2%",
          top: modalPosition.y,
          left: modalPosition.x,
          minWidth: "min-content",
          zIndex: 90,
        }}
        bodyStyle={{
          maxHeight: '70vh',
          overflowX: 'unset',
          overflowY: 'unset',
          marginRight: "0px",
          marginBottom: "0px",
        }}
        footer={false}
        title={state.titleModal}
      >
        <div onMouseDown={handleMouseDown} style={{ cursor: 'move', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px' }}>
          {/* <ResizableBox height={statePage.height} width={statePage.width} onResize={onResize} minConstraints={[300, 300]}> */}

            <div style={{ height: "700px", padding: "0px" }}>
              <div className="d-flex " style={{ maxWidth: '50%' }}>
                <div className="flex-fill">
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap ">
                      <div className="row w-100 p-0">
                        <div className="m-0 p-0">
                          <p className="w-700 Lato-700 c-29 s-1620">
                            {CheckUndefined(state.dataLeftAPI?.settlementMemberDetailDto?.memberName)} ({state.dataLeftAPI.type})
                          </p>
                        </div>

                        {/* <div className="d-flex justify-content-end" style={{ paddingBottom: '10px' }}>
                          <div className="w-250px">
                            {<p className={labelStyle} onClick={() => console.log("test data,", state.dataLeftAPI?.settlementMemberDetailDto)}>{stateParent.titleTableLeft} Settle Date</p>}
                            <div className="d-flex">
                              <input
                                value={settleDateLeft}
                                onChange={(e) => {
                                  dispatchParent({
                                    type: "selectedIssuerSettleDate",
                                    value: e.target.value,
                                  });
                                }}
                                type="date"
                                className={inputStyle}
                              />
                              <IoSearch style={{ fontSize: '20px', marginLeft: '5px', color: 'GrayText', marginTop: '3px' }} />
                            </div>
                          </div>
                        </div> */}
                        <TableLeftAPI
                          data={dataLeft}
                          columns={columnsLeftDataAPI}
                          state={state}
                          pagination={props.pagination}
                          setPagination={props.setPagination}
                          handleStateComponent={props.handleStateComponent}
                          stateOptions={stateOptions}
                          handleClickRowTable={handleClickRowTable}
                        // handleRowClick={handleRowClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Divider type="vertical" className="m-1" />
                <div className="flex-fill">
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap ">
                      <div className="row w-100 p-0">
                        <div className="m-0 p-0">
                          <p className="w-700 Lato-700 c-29 s-1620">
                            {CheckUndefined(state.dataRightAPI?.settlementMemberDetailDto?.memberName)} ({state.dataRightAPI.type})
                          </p>
                        </div>
                        {/* <div className="d-flex justify-content-end" style={{ paddingBottom: '10px' }}>
                          <div className="w-250px">
                            <p className={labelStyle}>{stateParent.titleTableRight} Settle Date</p>
                            <div className="d-flex">
                              <input
                                value={stateParent.selectedAcquirerSwitchSettleDate}
                                onChange={(e) => {
                                  dispatchParent({
                                    type: "selectedAcquirerSwitchSettleDate",
                                    value: e.target.value,
                                  });
                                }}
                                type="date"
                                className={inputStyle}
                              />
                              <IoSearch style={{ fontSize: '20px', marginLeft: '5px', color: 'GrayText', marginTop: '3px' }} />
                            </div>
                          </div>
                        </div> */}
                        <TableLeftAPI
                          data={dataRight}
                          columns={columnsRightDataAPI}
                          state={state}
                          pagination={props.pagination}
                          setPagination={props.setPagination}
                          handleStateComponent={props.handleStateComponent}
                          stateOptions={stateOptions}
                          handleClickRowTable={handleClickRowTable2}
                        // handleRowClick={handleRowClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </ResizableBox> */}
        </div>
      </Modal>

      <ModalDetailSettlementSummaryAcquirer detailModal={state.detailModal} setModal={() => dispatch({ type: "detailModal", value: !state.detailModal })} dispatchFirstParent={dispatchParent} stateFirstParent={stateParent} stateParent={state} stateOptions={stateOptions} pagination={props.pagination} setPagination={props.setPagination} />
    </>
  );
};

const initialState = {
  firstObject: null,
  detailModal: false,
  titleModal: "Detail Settlement",
  //
  selectedIss: "",
  selectedIssSwitcher: "",
  selectedDateModal: "",
  //
  selectedTransType: "",
  //
  finalParameterLeftAPI: "",
  finalParameterRightAPI: "",
  //
  dataLeftAPI: [],
  dataRightAPI: [],
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case "dataLeftAPI":
      return {
        ...state,
        dataLeftAPI: new ModelDetail(
          action.value
        ),
      };

    case "dataRightAPI":
      return {
        ...state,
        dataRightAPI: new ModelDetail(
          action.value
        ),
      };

    case "resetDataLeftAPI":
      return {
        ...state,
        // dataLeftAPI: new ModelTest({

        // }),
      };

    case "resetDataRightAPI":
      return {
        ...state,
        // dataRightAPI: new ModelTest({
        // }),
      };

    case "selectedIss":
      return {
        ...state,
        selectedIss: action.value,
      };
    case "selectedIssSwitcher":
      return {
        ...state,
        selectedIssSwitcher: action.value,
      };
    case "selectedDateModal":
      return {
        ...state,
        selectedDateModal: action.value,
      };

    case "detailModal":
      return {
        ...state,
        detailModal: action.value,
      };
    case "handleClickRowTable":
      return {
        ...state,
        selectedTransType: action.value.selectedTransType,
        selectedMemberSettleDate: action.value.selectedMemberSettleDate,
        selectedMemberTableType: action.value.selectedMemberTableType,
        selectedMemberCode: action.value.selectedMemberCode,
        selectedMemberRole: action.value.selectedMemberRole,
        selectedSecondSettleDate: action.value.selectedSecondSettleDate,
        selectedSecondMemberCode: action.value.selectedSecondMemberCode,
        selectedItem: action.value.selectedItem,
        // detailModal: !state.detailModal,
      };
    case "handleClickRowTableRight":
      return {
        ...state,
        selectedTransTypeRight: action.value.selectedTransTypeRight,
        selectedMemberSettleDateRight: action.value.selectedMemberSettleDateRight,
        selectedMemberTableTypeRight: action.value.selectedMemberTableTypeRight,
        selectedMemberCodeRight: action.value.selectedMemberCodeRight,
        selectedMemberRoleRight: action.value.selectedMemberRoleRight,
        selectedSecondSettleDateRight: action.value.selectedSecondSettleDateRight,
        selectedSecondMemberCodeRight: action.value.selectedSecondMemberCodeRight,
        selectedItemRight: action.value.selectedItemRight,
        detailModal: !state.detailModal,
      };
    case "selectedMemberSettleDate":
      return {
        ...state,
        selectedMemberSettleDate: action.value,
      };
    case "selectedMemberSettleDateRight":
      return {
        ...state,
        selectedMemberSettleDateRight: action.value,
      };
    case "selectedItem":
      return {
        ...state,
        selectedItem: action.value,
      };
    case "selectedItemRight":
      return {
        ...state,
        selectedItemRight: action.value,
      };
    case "selectedMemberTableType":
      return {
        ...state,
        selectedMemberTableType: action.value,
      };
    case "selectedMemberTableTypeRight":
      return {
        ...state,
        selectedMemberTableTypeRight: action.value,
      };
    case "selectedMemberCode":
      return {
        ...state,
        selectedMemberCode: action.value,
      };
    case "selectedMemberCodeRight":
      return {
        ...state,
        selectedMemberCodeRight: action.value,
      };
    case "selectedMemberRole":
      return {
        ...state,
        selectedMemberRole: action.value,
      };
    case "selectedMemberRoleRight":
      return {
        ...state,
        selectedMemberRoleRight: action.value,
      };

    case "selectedTransType":
      return {
        ...state,
        selectedTransType: action.value,
      };
    case "selectedTransTypeRight":
      return {
        ...state,
        selectedTransTypeRight: action.value,
      };
    case "selectedSecondSettleDate":
      return {
        ...state,
        selectedSecondSettleDate: action.value,
      };
    case "selectedSecondSettleDateRight":
      return {
        ...state,
        selectedSecondSettleDateRight: action.value,
      };
    case "selectedSecondMemberCode":
      return {
        ...state,
        selectedSecondMemberCode: action.value,
      };
    case "selectedSecondMemberCodeRight":
      return {
        ...state,
        selectedSecondMemberCodeRight: action.value,
      };
    case "finalParameterLeftAPI":
      return {
        ...state,
        finalParameterLeftAPI: action.value,
      };
    case "finalParameterRightAPI":
      return {
        ...state,
        finalParameterRightAPI: action.value,
      };
    default:
      throw new Error();
  }
}


const initialStatePage = {
  bounds: false,
  //
  data: null,
  //
  id: "",
  //
  width: window.innerWidth*0.85,
  height: window.innerHeight*0.70,
};

const reducerStatePage = (state: any, action: any) => {
  switch (action.type) {
    case "resize":
      return {
        ...state,
        width: action.value.width,
        height: action.value.height,
      };

    default:
      throw new Error();
  }
};



// SettlementSummaryDetail

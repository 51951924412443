

import { createSlice } from "@reduxjs/toolkit";

interface interfaceInitialState {
    selectedStartDate: string | null,
    selectedEndDate: string | null,
    selectedStartTime: string | null,
    selectedEndTime: string | null,
    selectedBatchProgramId: string | null,
    selectedBatchStatus: string | null,
    selectedBatchGroupPath: string | null,
    SelectedBatchExecutionType: string | null,
    selectedBatchBusinessGroup: string | null,
}

const initialState = (): interfaceInitialState | any => {
    let dateNow = new Date().toISOString().slice(0, 10);
    let now = new Date();
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    let startTime = '00:00:01';
    let timeNow = `${hours}:${minutes}:${seconds}`;
    return {
        selectedStartDate: dateNow,
        selectedEndDate: dateNow,
        selectedStartTime: startTime,
        selectedEndTime: timeNow,
        selectedBatchProgramId: '',
        selectedBatchStatus: '',
        selectedBatchGroupPath: '',
        SelectedBatchExecutionType: '',
        selectedBatchBusinessGroup: '',
    }
}



export const jobExecutionHistorySlice = createSlice({
    name: "jobExecutionHistory",
    initialState: initialState(),
    reducers: {
        handleStateAction: (state, action) => {
            for (const key in state as interfaceInitialState) {
                if (key === action.payload.name) {
                    state[action.payload.name] = action.payload.value
                }
            }
        },
        resetAllAction: (state, action) => {
            state.selectedStartDate = ''
            state.selectedStartTime = ''
            state.selectedEndTime = ''
            state.selectedEndDate = ''
            state.selectedBatchProgramId = ''
            state.selectedBatchStatus = ''
            state.selectedBatchGroupPath = ''
            state.SelectedBatchExecutionType = ''
            state.selectedBatchBusinessGroup = ''
        }
    }
});

export const { handleStateAction, resetAllAction } = jobExecutionHistorySlice.actions;
export const jobExecutionHistoryGlobalState = (state: any) => state.jobExecutionHistory;
export default jobExecutionHistorySlice.reducer;
import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, inputStyleReadOnlyH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalGroupCodeList = ({ ...props }) => {
  const dispatchGlobal = useDispatch();

  const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
  const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
  const { optionsCommonStatusDetailCode, optionsGroupCodeOnly } = props.stateOptions

  const { setTriggerReload } = props

  useEffect(() => {
    dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
    dispatchStatePage({ type: 'clearData', value: deleteItem })
  }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

  useEffect(() => {
    dispatchStatePage({ type: 'deleteItem', value: deleteItem })
  }, [deleteItem !== statePage.deleteItem])

  useEffect(() => {
    dispatchStatePage({ type: 'id', value: id })
    if (dataModal !== null) {
      handleState('setData', dataModal);
    }
    if (id !== '') { }
    else {
      dispatchStatePage({ type: 'clearData', value: deleteItem })
    }
  }, [id !== statePage.id])

  const handleState = (nameState: string | any, valueState: any) => {
    const { errorMessageList } = statePage;
    let customErrorMessage1 = 'GROUP CODE input maximum 8 characters';
    let customErrorMessage2 = 'GROUP CODE already exist';
    let customErrorMessage3 = 'GROUP CODE NAME input maximum 32 characters';
    let customErrorMessage4 = 'DESCRIPTION input maximum 120 characters';
    if (nameState === 'ccgCode') {
      if (valueState.split('').length > 8) {
        if (errorMessageList.includes(customErrorMessage1)) {
        } else {
          errorMessageList.push(customErrorMessage1);
          let stringErrorMessage = '';
          errorMessageList.forEach((element1: any) => {
            stringErrorMessage = stringErrorMessage + element1;
          });

          dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
          dispatchStatePage({ type: nameState, value: valueState.toUpperCase() })
        }
      } else if (optionsGroupCodeOnly.includes(`${valueState.toUpperCase()}`)) {
        if (errorMessageList.includes(customErrorMessage2)) {
        } else {
          errorMessageList.push(customErrorMessage2);
          let stringErrorMessage = '';
          errorMessageList.forEach((element1: any) => {
            stringErrorMessage = stringErrorMessage + ', ' + element1;
          });

          dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
          dispatchStatePage({ type: nameState, value: valueState.toUpperCase() })
        }
      } else {
        let dataListErrorMessage = errorMessageList.filter((element1: any) => element1 !== customErrorMessage1);
        let dataListErrorMessage2 = dataListErrorMessage.filter((element1: any) => element1 !== customErrorMessage2);
        dispatchStatePage({ type: 'errorMessage', value: dataListErrorMessage2.join(', ') })
        dispatchStatePage({ type: nameState, value: valueState.toUpperCase() })
        dispatchStatePage({ type: 'errorMessageList', value: dataListErrorMessage2 })
      }
    } else if (nameState === 'ccgName') {
      if (valueState.split('').length > 32) {
        if (errorMessageList.includes(customErrorMessage3)) {
        } else {
          errorMessageList.push(customErrorMessage3);
          let stringErrorMessage = '';
          errorMessageList.forEach((element1: any) => {
            stringErrorMessage = stringErrorMessage + ', ' + element1;
          });

          dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
          dispatchStatePage({ type: nameState, value: valueState })
        }
      } else {
        let dataListErrorMessage = errorMessageList.filter((element1: any) => element1 !== customErrorMessage3);
        dispatchStatePage({ type: 'errorMessage', value: dataListErrorMessage.join(', ') })
        dispatchStatePage({ type: nameState, value: valueState })
        dispatchStatePage({ type: 'errorMessageList', value: dataListErrorMessage })
      }
    } else if (nameState === 'ccgDesc') {
      if (valueState.split('').length > 120) {
        if (errorMessageList.includes(customErrorMessage4)) {
        } else {
          errorMessageList.push(customErrorMessage4);
          let stringErrorMessage = '';
          errorMessageList.forEach((element1: any) => {
            stringErrorMessage = stringErrorMessage + ', ' + element1;
          });

          dispatchStatePage({ type: 'errorMessage', value: stringErrorMessage })
          dispatchStatePage({ type: nameState, value: valueState })
        }
      } else {
        let dataListErrorMessage = errorMessageList.filter((element1: any) => element1 !== customErrorMessage4);
        dispatchStatePage({ type: 'errorMessage', value: dataListErrorMessage.join(', ') })
        dispatchStatePage({ type: nameState, value: valueState })
        dispatchStatePage({ type: 'errorMessageList', value: dataListErrorMessage })
      }
    } else {
      dispatchStatePage({ type: nameState, value: valueState })
    }

  }

  const deleteItemAPI = () => {
    API.delete({
      bodyCustom: null,
      pathCustom: `${urlApi}/${statePage.ccgCode}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response) => {
      setModal("deleteItem")
      setTriggerReload(true)
      reloadData()
    }).catch((err) => {
      dispatchStatePage({ type: 'showModalError', value: true })
      dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
    }).finally(() => {
      dispatchStatePage({ type: 'clearData', value: deleteItem })
    })
  }

  const postDataAPI = () => {
    const { ccgCode, ccgName, ccgDesc, ccgStatus, errorMessage } = statePage;
    const data = {
      ccgCode: `${ccgCode}`,
      ccgName: `${ccgName}`,
      ccgDesc: `${ccgDesc}`,
      ccgStatus: `${ccgStatus}`,
    };
    const a = CheckAllFields([ccgCode, ccgName, ccgStatus], ['Group Code', 'Group Code Name', 'Use Status']);
    if (a !== "" || errorMessage !== '') {
      let errorMessageString = errorMessage === '' ? '' : `${errorMessage}, `;
      if (errorMessageString.toString().includes(a)) {
      } else {
        handleState('errorMessage', `${errorMessageString}${a}, `);
      }
    } else {
      if (errorMessage === '') {
        handleState('errorMessage', ``);
        API.post({
          bodyCustom: data,
          pathCustom: `${urlApi}`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needLoading: true,
        }).then((response) => {
          dispatchStatePage({ type: 'clearData' })
          setModal("visibleModalAddDelete")
          setTriggerReload(true)
          reloadData()
        }).catch((err) => handleState('errorMessage', err))
      }
    }
  }

  return (
    <>
      <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
        <div className='d-flex flex-column w-800px'>
          <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
            <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
            <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
              <div className='col-4'>
                <p className={labelStyle}>GROUP CODE <span className='text-danger'>*</span></p>
                {statePage.id ? <input value={statePage.ccgCode} disabled className={inputStyleReadOnly} /> : <input value={statePage.ccgCode} onChange={(e) => handleState('ccgCode', e.target.value)} className={inputStyle} />}
              </div>
              <div className='col-4'>
                <p className={labelStyle}>GROUP CODE NAME <span className='text-danger'>*</span></p>
                <input value={statePage.ccgName} onChange={(e) => handleState('ccgName', e.target.value)} className={inputStyle} />
              </div>
              <div className='col-4'>
                <p className={labelStyle}>USE STATUS <span className='text-danger'>*</span></p>
                <Select
                  styles={inputStyleH25}
                  placeholder={'(Search)'}
                  options={optionsCommonStatusDetailCode}
                  value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.ccgStatus)}
                  onChange={(e) => handleState('ccgStatus', e.value)}
                />
              </div>
              <div className='col-12'>
                <p className={labelStyle}>DESCRIPTION</p>
                <textarea rows={4} value={statePage.ccgDesc} onChange={(e) => handleState('ccgDesc', e.target.value)} className={inputDescriptionStyle} />
              </div>
            </div>
            <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
          </div>
        </div>
      </Modal>

      <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
      <ModalCannotDelete statePage={statePage} handleState={handleState} />
    </>
  )
}


const initialState = {
  titleModal: 'New Group Code',
  visibleModalAddDelete: false,
  deleteItem: false,
  // 
  ccgId: '',
  ccgCode: '',
  ccgName: '',
  ccgDesc: '',
  ccgStatus: '001',
  // 
  id: "",
  errorMessage: "",
  errorMessageList: [],
  errorMessageModal: '',
  showModalError: false,
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'visibleModalAddDelete':
      return {
        ...state,
        visibleModalAddDelete: action.value,
      };
    case 'deleteItem':
      return {
        ...state,
        deleteItem: action.value,
      };
    case 'id':
      return {
        ...state,
        id: action.value,
      };
    case 'errorMessage':
      return {
        ...state,
        errorMessage: action.value,
      };
    case 'errorMessageList':
      return {
        ...state,
        errorMessageList: action.value,
      };
    case 'showModalError':
      return {
        ...state,
        showModalError: action.value,
      };
    case 'errorMessageModal':
      return {
        ...state,
        errorMessageModal: action.value,
      };
    // 
    case 'clearData':
      return {
        ...state,
        ccgId: '',
        ccgCode: '',
        ccgName: '',
        ccgDesc: '',
        ccgStatus: '001',
        errorMessage: "",

      };
    case 'setData':
      return {
        ...state,
        ccgId: action.value.ccgId,
        ccgCode: action.value.ccgCode,
        ccgName: action.value.ccgName,
        ccgDesc: action.value.ccgDesc,
        ccgStatus: action.value.ccgStatus,
        errorMessage: "",
      };
    // 
    case 'ccgId':
      return {
        ...state,
        ccgId: action.value,
      };
    case 'ccgCode':
      return {
        ...state,
        ccgCode: action.value,
      };
    case 'ccgName':
      return {
        ...state,
        ccgName: action.value,
      };
    case 'ccgDesc':
      return {
        ...state,
        ccgDesc: action.value,
      };
    case 'ccgStatus':
      return {
        ...state,
        ccgStatus: action.value,
      };
    default:
      throw new Error();
  }
}

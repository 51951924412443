
// InterfaceRecordItem

interface InterfaceRecordItemInterface {
    id: null | number | string;
    recordItemId: number | null;
    interfaceFileItemId: number | null;
    interfaceFileItemName: string | null;
    interfaceRecordId: number | null;
    interfaceRecordName: string | null;
    recordItemSortNumber: number | null;
    recordItemSeparator: string | null;
    recordItemNameYn: Boolean | null;
    recordItemNameLength: number | null;
    recordItemMoc: string | null;
    recordItemDefaultValue: string | null;
    recordItemCreateBy: number | null;
    recordItemCreateDate: string | null;
    recordItemUpdateBy: number | null;
    recordItemUpdateDate: string | null;
    recordItemDeleteBy: number | null;
    recordItemDeleteDate: string | null;
    recordItemIsDelete: Boolean | null;

}

export class ModelInterfaceRecordItem {
    id: null | number | string;
    recordItemId: number | null;
    interfaceFileItemId: number | null;
    interfaceFileItemName: string | null;
    interfaceRecordId: number | null;
    interfaceRecordName: string | null;
    recordItemSortNumber: number | null;
    recordItemSeparator: string | null;
    recordItemNameYn: Boolean | null;
    recordItemNameLength: number | null;
    recordItemMoc: string | null;
    recordItemDefaultValue: string | null;
    recordItemCreateBy: number | null;
    recordItemCreateDate: string | null;
    recordItemUpdateBy: number | null;
    recordItemUpdateDate: string | null;
    recordItemDeleteBy: number | null;
    recordItemDeleteDate: string | null;
    recordItemIsDelete: Boolean | null;


    constructor(value: InterfaceRecordItemInterface | null) {
        this.id = value?.id ?? null;
        this.recordItemId = value?.recordItemId ?? null;
        this.interfaceFileItemId = value?.interfaceFileItemId ?? null;
        this.interfaceFileItemName = value?.interfaceFileItemName ?? null;
        this.interfaceRecordId = value?.interfaceRecordId ?? null;
        this.interfaceRecordName = value?.interfaceRecordName ?? null;
        this.recordItemSortNumber = value?.recordItemSortNumber ?? null;
        this.recordItemSeparator = value?.recordItemSeparator ?? null;
        this.recordItemNameYn = value?.recordItemNameYn ?? null;
        this.recordItemNameLength = value?.recordItemNameLength ?? null;
        this.recordItemMoc = value?.recordItemMoc ?? null;
        this.recordItemDefaultValue = value?.recordItemDefaultValue ?? null;
        this.recordItemCreateBy = value?.recordItemCreateBy ?? null;
        this.recordItemCreateDate = value?.recordItemCreateDate ?? null;
        this.recordItemUpdateBy = value?.recordItemUpdateBy ?? null;
        this.recordItemUpdateDate = value?.recordItemUpdateDate ?? null;
        this.recordItemDeleteBy = value?.recordItemDeleteBy ?? null;
        this.recordItemDeleteDate = value?.recordItemDeleteDate ?? null;
        this.recordItemIsDelete = value?.recordItemIsDelete ?? null;
    }
}

import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelSafControlManagement } from './ModelSafControlManagement';
import { loadingAction } from '../../Store/Loading';
import { Page20Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableSafControlManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsMember, optionsRouteSequence } = props.stateOptions


    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'safControlProcessCount' || nameState === 'safControlRetryCount' || nameState === 'safControlTimeout' || nameState === 'safControlWorkerNumber' || nameState === 'safSleepTime') {
            if (valueState === '') {
                dispatchStatePage({ type: nameState, value: valueState })
            } else if (parseInt(valueState) > 0 && parseInt(valueState) < 100) {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        } else {
            let names = ["safControlServer"]
            let limit = [50]
            let index = names.indexOf(nameState)
            if (index !== -1) {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }
    const updateDataAPI = () => {
        const { safControlId, memberId, safControlSrStatus, safControlProcessCount, safControlRetryCount, safControlWorkerNumber, safControlTimeout, safControlServer, safSleepTime, safControlRsId } = statePage.data
        const data = {
            safControlId: id,
            memberId: memberId,
            safControlSrStatus: safControlSrStatus,
            safControlProcessCount: safControlProcessCount,
            safControlRetryCount: safControlRetryCount,
            safControlWorkerNumber: safControlWorkerNumber,
            safControlTimeout: safControlTimeout,
            safControlServer: safControlServer,
            safControlSleepTime: safSleepTime,
            safControlRsId: safControlRsId,
        };
        const a = CheckAllFields([memberId, safControlSrStatus, safControlProcessCount, safControlRetryCount, safControlWorkerNumber, safControlTimeout, safControlServer, safSleepTime,], ['Member ID', 'SAF Control Status', 'Process Count', 'Retry Transmit Count', 'Worker Number', 'Timeout', 'Server Name', 'Sleep Timeout',]);

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>

                    <div className='col-12'>
                        <p className={labelStyle}>member <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsMember}
                            value={optionsMember.filter((option: any) => option.value === statePage.data.memberId)}
                            onChange={(e) => handleState('memberId', e.value)}
                        />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>send/receive <span className='text-danger'>*</span></p>
                        <div className='d-flex justify-content-between align-items-center hg-40px' >
                            {
                                statePage.data.safControlId ?
                                    <>
                                        <span className=''>
                                            <input readOnly type='radio' value={'001'} checked={statePage.data.safControlSrStatus === '001'} name='safControlSrStatus' className='mx-2' style={{ display: 'inline-block' }} disabled />
                                            {'YES'}
                                        </span>
                                        <span className=''>
                                            <input readOnly type='radio' value={'000'} checked={statePage.data.safControlSrStatus === '000'} name='safControlSrStatus' className='mx-2' style={{ display: 'inline-block' }} disabled />
                                            NO
                                        </span>
                                        <span></span>
                                    </>
                                    :
                                    <>
                                        <span className=''>
                                            <input type='radio' value={'001'} checked={statePage.data.safControlSrStatus === '001'} onChange={(e) => handleState('safControlSrStatus', e.target.value)} className='mx-2' style={{ display: 'inline-block' }} />
                                            {'YES'}
                                        </span>
                                        <span className=''>
                                            <input type='radio' value={'000'} checked={statePage.data.safControlSrStatus === '000'} onChange={(e) => handleState('safControlSrStatus', e.target.value)} className='mx-2' style={{ display: 'inline-block' }} />
                                            NO
                                        </span>
                                        <span></span>
                                    </>
                            }
                        </div>
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>SERVER NAME <span className='text-danger'>*</span></p>
                        <input value={statePage.data.safControlServer} onChange={(e) => handleState('safControlServer', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}> PROCESS COUNT <span className='text-danger'>*</span></p>
                        <input type={'number'} min={0} value={statePage.data.safControlProcessCount} onChange={(e) => handleState('safControlProcessCount', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>RETRY COUNT <span className='text-danger'>*</span></p>
                        <input type={'number'} min={0} value={statePage.data.safControlRetryCount} onChange={(e) => handleState('safControlRetryCount', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>TIMEOUT <span className='text-danger'>*</span></p>
                        <input type={'number'} min={0} value={statePage.data.safControlTimeout} onChange={(e) => handleState('safControlTimeout', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>worker number <span className='text-danger'>*</span></p>
                        <input type={'number'} min={0} value={statePage.data.safControlWorkerNumber} onChange={(e) => handleState('safControlWorkerNumber', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>sleep time out <span className='text-danger'>*</span></p>
                        <input type={'number'} min={0} value={statePage.data.safSleepTime} onChange={(e) => handleState('safSleepTime', e.target.value)} className={inputStyle} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>ROUTE SEQUENCE ID <span className='text-danger'>*</span></p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={'(Search)'}
                            options={optionsRouteSequence}
                            value={optionsRouteSequence.filter((option: any) => option.value === statePage.data.safControlRsId)}
                            onChange={(e) => handleState('safControlRsId', e.value)}
                        />
                    </div>

                    <div className='col-12 mt-3'>
                        {
                            statePage.data.safControlId ?
                                <>
                                    <span className={'text-up me-2 ' + (statePage.data.safControlSrStatus !== '000' ? 'button-select table-data-pointer' : 'button-unselect')} onClick={(e) => handleState('safControlSrStatus', '000')}>
                                        SAF STOP
                                    </span>
                                    <span className={'text-up me-2 ' + (statePage.data.safControlSrStatus !== '001' ? 'button-select table-data-pointer' : 'button-unselect')} onClick={(e) => handleState('safControlSrStatus', '001')}>
                                        SAF START
                                    </span>
                                </>
                                :
                                <>
                                    <span className={'text-up me-2 ' + (statePage.data.safControlSrStatus !== '000' ? 'button-select table-data-pointer' : 'button-unselect')}>
                                        SAF STOP
                                    </span>
                                    <span className={'text-up me-2 ' + (statePage.data.safControlSrStatus !== '001' ? 'button-select table-data-pointer' : 'button-unselect')}>
                                        SAF START
                                    </span>
                                </>
                        }
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page20Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelSafControlManagement({
        id: null,
        safControlId: null,
        memberId: null,
        safControlSrStatus: null,
        safControlProcessCount: null,
        safControlRetryCount: null,
        safControlWorkerNumber: null,
        safControlTimeout: null,
        memberName: null,
        memberCode: null,
        safSleepTime: null,
        safControlServer: null,
        safControlRsId: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelSafControlManagement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelSafControlManagement({
                    id: null,
                    safControlId: null,
                    memberId: null,
                    safControlSrStatus: null,
                    safControlProcessCount: null,
                    safControlRetryCount: null,
                    safControlWorkerNumber: null,
                    safControlTimeout: null,
                    memberName: null,
                    memberCode: null,
                    safSleepTime: null,
                    safControlServer: null,
                    safControlRsId: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelSafControlManagement({
                    id: action.value.safControlId,
                    safControlId: action.value.safControlId,
                    memberId: action.value.memberId,
                    safControlSrStatus: action.value.safControlSrStatus,
                    safControlProcessCount: action.value.safControlProcessCount,
                    safControlRetryCount: action.value.safControlRetryCount,
                    safControlWorkerNumber: action.value.safControlWorkerNumber,
                    safControlTimeout: action.value.safControlTimeout,
                    memberName: action.value.memberName,
                    memberCode: action.value.memberCode,
                    safSleepTime: action.value.safSleepTime,
                    safControlServer: action.value.safControlServer,
                    safControlRsId: action.value.safControlRsId,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





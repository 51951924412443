import { CheckUndefined, ExportCSV } from "../../../Utils";



const ExcelBatchScheduler = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showbatchId = '';
        let showbatchRegType = '';
        let showbatchExecutionType = '';
        let showbatchPreRequisite = '';
        let result = ''

        if (stateOptions.optionsBatchFromAPIBatch.length > 0) {
            stateOptions.optionsBatchFromAPIBatch.forEach((element2: any) => {
                if (element2.value === item.batchId) {
                    showbatchId = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsBatchRegistrationTypeDetailCode.length > 0) {
            stateOptions.optionsBatchRegistrationTypeDetailCode.forEach((element2: any) => {
                if (element2.value === item.batchRegType) {
                    showbatchRegType = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsBatchExecutionTypeDetailCode.length > 0) {
            stateOptions.optionsBatchExecutionTypeDetailCode.forEach((element2: any) => {
                if (element2.value === item.batchExecutionType) {
                    showbatchExecutionType = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsBatchFromAPIBatch.length > 0) {
            if(item.batchPreRequisite.toString().length > 0){
                let splitString = [...item.batchPreRequisite.toString().split(',')];
                if(splitString.length > 0){
                    splitString.forEach((item2: any ) => {
                        stateOptions.optionsBatchFromAPIBatch.forEach((element2: any) => {
                            if (`${element2.value}` === item2) {
                                result = result + element2.label + ', ';
                            }
                        });
                    })
                }
            }
        }
        
        if(result === ''){
            result = item.batchPreRequisite;
        }
        const m = {
            no: i + 1,
            batchId: CheckUndefined(showbatchId),
            id: CheckUndefined(item.id),
            batchPreRequisite: CheckUndefined(result),
            batchGroupPath: CheckUndefined(item.batchGroupPath),
            batchRegType: CheckUndefined(showbatchRegType),
            batchRerunManuallyDttm: CheckUndefined(item.batchRerunManuallyDttm),
            batchPlannedDttm: CheckUndefined(item.batchPlannedDttm),
            batchRunTime: CheckUndefined(item.batchRunTime),
            batchParameterValue: CheckUndefined(item.batchParameterValue),
            batchExecutionType: CheckUndefined(showbatchExecutionType),
            batchInterval: CheckUndefined(item.batchInterval),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};



export {
    ExcelBatchScheduler
}



// InterfaceFileRecord

interface InterfaceFileRecordInterface {
    id: null | number | string;
    interfileRecordId: number | null;
    interfaceFileId: number | null;
    interfaceFileName: string | null;
    recordId: number | null;
    recordName: string | null;
    interfileRecordSortNumber: number | null;
    interfileRecordOccur: string | null;
    interfileRecordName: string | null;
    interfileRecordNameLength: string | null;
    interfileRecordMoc: string | null;
    interfaceFileRecordCreateBy: number | null;
    interfaceFileRecordCreateDate: string | null;
    interfaceFileRecordUpdateBy: number | null;
    interfaceFileRecordUpdateDate: string | null;
    interfaceFileRecordDeleteBy: string | null;
    interfaceFileRecordDeleteDate: string | null;
    interfaceFileRecordIsDelete: Boolean | null;
}

export class ModelInterfaceFileRecord {
    id: null | number | string;
    interfileRecordId: number | null;
    interfaceFileId: number | null;
    interfaceFileName: string | null;
    recordId: number | null;
    recordName: string | null;
    interfileRecordSortNumber: number | null;
    interfileRecordOccur: string | null;
    interfileRecordName: string | null;
    interfileRecordNameLength: string | null;
    interfileRecordMoc: string | null;
    interfaceFileRecordCreateBy: number | null;
    interfaceFileRecordCreateDate: string | null;
    interfaceFileRecordUpdateBy: number | null;
    interfaceFileRecordUpdateDate: string | null;
    interfaceFileRecordDeleteBy: string | null;
    interfaceFileRecordDeleteDate: string | null;
    interfaceFileRecordIsDelete: Boolean | null;

    constructor(value: InterfaceFileRecordInterface | null) {
        this.id = value?.id ?? null;
        this.interfileRecordId = value?.interfileRecordId ?? null;
        this.interfaceFileId = value?.interfaceFileId ?? null;
        this.interfaceFileName = value?.interfaceFileName ?? null;
        this.recordId = value?.recordId ?? null;
        this.recordName = value?.recordName ?? null;
        this.interfileRecordSortNumber = value?.interfileRecordSortNumber ?? null;
        this.interfileRecordOccur = value?.interfileRecordOccur ?? null;
        this.interfileRecordName = value?.interfileRecordName ?? null;
        this.interfileRecordNameLength = value?.interfileRecordNameLength ?? null;
        this.interfileRecordMoc = value?.interfileRecordMoc ?? null;
        this.interfaceFileRecordCreateBy = value?.interfaceFileRecordCreateBy ?? null;
        this.interfaceFileRecordCreateDate = value?.interfaceFileRecordCreateDate ?? null;
        this.interfaceFileRecordUpdateBy = value?.interfaceFileRecordUpdateBy ?? null;
        this.interfaceFileRecordUpdateDate = value?.interfaceFileRecordUpdateDate ?? null;
        this.interfaceFileRecordDeleteBy = value?.interfaceFileRecordDeleteBy ?? null;
        this.interfaceFileRecordDeleteDate = value?.interfaceFileRecordDeleteDate ?? null;
        this.interfaceFileRecordIsDelete = value?.interfaceFileRecordIsDelete ?? null;
    }
}

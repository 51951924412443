import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page14Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableProductFeatureManagement = ({ ...props }) => {
      const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsProduct, optionsCommonStatusDetailCode } = props.stateOptions
    const draggleRef = React.createRef<any>();


    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'prodFeatureInd') {
            if (valueState.split('').length < 9) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const { prodFeatureId, productId, prodFeatureName, prodFeatureDesc, prodFeatureStatus, prodFeatureIsDisplay, prodFeatureInd, productName, errorMessage } = statePage
        const data = {
            prodFeatureId: id,
            productId: productId,
            prodFeatureName: prodFeatureName,
            prodFeatureDesc: prodFeatureDesc,
            prodFeatureStatus: prodFeatureStatus,
            prodFeatureInd: prodFeatureInd,
        }
        const a = CheckAllFields([productId, prodFeatureName, prodFeatureStatus, prodFeatureInd], ['Product Feature', 'Product Feature Name', 'Status', 'Product Indicator'])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className={'col-6'}>
                        <p className={labelStyle}>Product Feature</p>
                        <Select styles={inputStyleH25} className='h-40px' options={optionsProduct} value={optionsProduct.filter((option: any) => option.value === statePage.productId)} onChange={(e) => handleState('productId', e.value)} />
                    </div>
                    <div className={'col-6'}>
                        <p className={labelStyle}>PRODUCT FEATURE NAME</p>
                        <input value={statePage.prodFeatureName} onChange={(e) => handleState('prodFeatureName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className={'col-6'}>
                        <p className={labelStyle}>PRODUCT INDICATOR</p>
                        <input value={statePage.prodFeatureInd} onChange={(e) => handleState('prodFeatureInd', e.target.value)} className={inputStyle} />
                    </div>
                    <div className={'col-6'}>
                        <p className={labelStyle}>Status</p>
                        <Select styles={inputStyleH25} className='h-40px' options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.prodFeatureStatus)} onChange={(e) => handleState('prodFeatureStatus', e.value)} />
                    </div>
                    <div className={'col-12'}>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.prodFeatureDesc} onChange={(e) => handleState('prodFeatureDesc', e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}
const initialState = {
    titleModal: `Edit ${Page14Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    //
    prodFeatureId: '',
    productId: '',
    prodFeatureName: '',
    prodFeatureDesc: '',
    prodFeatureStatus: '001',
    prodFeatureIsDisplay: '',
    prodFeatureInd: '',
    productName: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                prodFeatureId: '',
                productId: '',
                prodFeatureName: '',
                prodFeatureDesc: '',
                prodFeatureStatus: '001',
                prodFeatureIsDisplay: '',
                prodFeatureInd: '',
                productName: '',
            };
        case 'setData':
            return {
                ...state,
                prodFeatureId: action.value.prodFeatureId,
                productId: action.value.productId,
                prodFeatureName: action.value.prodFeatureName,
                prodFeatureDesc: action.value.prodFeatureDesc,
                prodFeatureStatus: action.value.prodFeatureStatus,
                prodFeatureIsDisplay: action.value.prodFeatureIsDisplay,
                prodFeatureInd: action.value.prodFeatureInd,
                productName: action.value.productName,
            };
        // 
        case 'prodFeatureId':
            return {
                ...state,
                prodFeatureId: action.value,
            };
        case 'productId':
            return {
                ...state,
                productId: action.value,
            };
        case 'prodFeatureName':
            return {
                ...state,
                prodFeatureName: action.value,
            };
        case 'prodFeatureDesc':
            return {
                ...state,
                prodFeatureDesc: action.value,
            };
        case 'prodFeatureStatus':
            return {
                ...state,
                prodFeatureStatus: action.value,
            };
        case 'prodFeatureIsDisplay':
            return {
                ...state,
                prodFeatureIsDisplay: action.value,
            };
        case 'prodFeatureInd':
            return {
                ...state,
                prodFeatureInd: action.value,
            };
        case 'productName':
            return {
                ...state,
                productName: action.value,
            };
        // 
        default:
            throw new Error();
    }
}


import { useEffect, useState } from 'react';
import { Modal, Button, IconButton, Zoom } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Select from 'react-select';
import { inputStyleH25, labelStyleWwithoutUppercase } from '../../../Styles';
import { stateGlobalTransactionTestInquiry } from "../../../Store/TransactionTestInquirySlice";
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { errNotificationIcon } from '../../../Assets/Images/svg';
import { DatePickerBlueRightIcon } from '../../../Components/DatepickerBlueRightIcon';
import moment from 'moment';
import useIsMobile from '../../../Utils/useIsMobile';

export const MobileFilterTSMTest = ({ ...props }) => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const globalState = useSelector(stateGlobalTransactionTestInquiry);
    const [activeFilter, setActiveFilter] = useState<'approved' | 'declined' | 'all' | '' > ('all');
    const {
        state,
        stateOptions,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom,
    } = props;
    const {
        statusSendToApi,
        selectedProductFeatureId,
        filterStartDate,
        filterEndDate,
        STAN,
        RRN,
        InvoiceNumber,
        GlobalID,
        MID,
        TID,
        transactionAmount,
        issuerIdCode,
        acquirerIdCode,
        issuerSwitcherIdCode,
        acquirerSwitcherIdCode,
        testCaseId,
        testCat,
        selectedTestValid
    } = globalState;

    const handleFilterClick = (filter: 'approved' | 'declined' | 'all') => {
        setActiveFilter(filter);
        const status =
            filter === 'approved'
                ? '0000'
                : filter === 'declined'
                ? '0001'
                : '';
        handleStateGlobal('statusSendToApi', status);
        handleStateGlobal('transAuthIntResponseCode', status);
        resetFilterStatus();
    };

    useEffect(() => {
        if (isMobile) {
            initFunctionCustom(true);
        }
    }, [statusSendToApi]);

    return (
        <>
                    <div className="d-flex h-20px gap-1">
                <Button
                    variant="contained"
                    onClick={() => handleFilterClick('approved')}
                    sx={{
                        borderRadius: '20px',
                        fontSize: '8px',
                        padding: '3px 4px',
                        backgroundColor:
                            activeFilter === 'approved' ? 'green' : 'gray',
                        color: 'white',
                        textTransform: 'none',
                        marginY: '2px',
                    }}
                >
                    Approved
                </Button>

                <Button
                    variant="contained"
                    onClick={() => handleFilterClick('declined')}
                    sx={{
                        borderRadius: '20px',
                        fontSize: '8px',
                        padding: '3px 4px',
                        backgroundColor:
                            activeFilter === 'declined' ? 'red' : 'gray',
                        color: 'white',
                        textTransform: 'none',
                        marginY: '2px',
                    }}
                >
                    Declined
                </Button>

                <Button
                    variant="contained"
                    onClick={() => handleFilterClick('all')}
                    sx={{
                        borderRadius: '20px',
                        fontSize: '8px',
                        padding: '3px 4px',
                        backgroundColor:
                            activeFilter === 'all' ? 'lightgray' : 'gray', 
                        color: activeFilter === 'all' ? 'black' : 'white', 
                        textTransform: 'none',
                        marginY: '2px',
                    }}
                >
                    All
                </Button>
            </div>
            <div className='d-flex justify-content-end'>
                <IconButton onClick={handleOpen}>
                    <FilterListIcon />
                </IconButton>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="filter-modal-title"
                    aria-describedby="filter-modal-description"
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Zoom in={open}>
                        <div
                            className="modal-content p-3 bg-white"
                            style={modalStyle}
                        >
                            <div className="filter-container">
                                <div className="filter-header d-flex justify-content-between align-items-center border-bottom pb-2">
                                    <div>
                                        <span
                                            className="close-button"
                                            onClick={handleClose}
                                        >
                                            <svg
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                }}
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M6.225 4.811a1 1 0 011.414 0L12 9.172l4.361-4.361a1 1 0 111.414 1.414L13.414 10.586l4.361 4.361a1 1 0 01-1.414 1.414L12 12l-4.361 4.361a1 1 0 01-1.414-1.414l4.361-4.361-4.361-4.361a1 1 0 010-1.414z"
                                                    fill="#292929"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    <h5 className="m-0">Filters</h5>
                                    <div>
                                        <button
                                            className="btn d-flex h-30px mx-1 mr-100px"
                                            onClick={(e) => {
                                                handleStateGlobal('testCat', '');
                                                handleStateGlobal('testCaseId', '');
                                                handleStateGlobal('selectedTestValid', '');
                                                handleStateGlobal('STAN', '');
                                                handleStateGlobal('RRN', '');
                                                handleStateGlobal('InvoiceNumber', '');
                                                handleStateGlobal('GlobalID', '');
                                                let dateNow = new Date().toISOString().slice(0, 10);
                                                handleStateGlobal('filterStartDate', dateNow);
                                                handleStateGlobal('filterEndDate', dateNow);
                                                handleStateGlobal('MID', '');
                                                handleStateGlobal('TID', '');
                                                handleStateGlobal('transactionAmount', '');
                                                handleStateGlobal('transAuthIntResponseCode', '');
                                                handleStateGlobal('statusSendToApi', '');
                                                handleStateGlobal('transStatusName', '');
                                                handleStateGlobal('selectedProductId', '');
                                                handleStateGlobal('selectedProductSendToApi', '');
                                                handleStateGlobal('issuerIdCode', '');
                                                handleStateGlobal('issuerSwitcherIdCode', '');
                                                handleStateGlobal('acquirerSwitcherIdCode', '');
                                                handleStateGlobal('acquirerIdCode', '');
                                                handleStateGlobal('selectedProductFeatureId', '');
                                                handleStateGlobal('selectedProductFeatureSendToApi', '');
                                                resetFilterStatus();
                                            }}
                                        >
                                            <svg
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                }}
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                                    fill="#292929" 
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="filter-section mt-2">
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Test Category</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={testCat}
                                                onChange={(e) => {
                                                    handleStateGlobal('testCat', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={64}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Test Case ID</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={testCaseId}
                                                onChange={(e) => {
                                                    handleStateGlobal('testCaseId', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={64}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Test Result</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <Select
                                                placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={stateOptions.optionsTestValid}
                                                value={stateOptions.optionsTestValid.filter((option: any) => option.value === selectedTestValid)}
                                                onChange={(e) => {
                                                    handleStateGlobal('selectedTestValid', e.value);
                                                    resetFilterStatus();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>STAN</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={STAN}
                                                onChange={(e) => {
                                                    handleStateGlobal('STAN', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={6}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>RRN</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={RRN}
                                                onChange={(e) => {
                                                    handleStateGlobal('RRN', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={64}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Invoice No</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={InvoiceNumber}
                                                onChange={(e) => {
                                                    handleStateGlobal('InvoiceNumber', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={128}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Global ID</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={GlobalID}
                                                onChange={(e) => {
                                                    handleStateGlobal('GlobalID', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={255}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Start Date</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <DatePickerBlueRightIcon
                                                maxDate={moment().format('YYYY-MM-DD')}
                                                selected={new Date(`${filterStartDate}`)}
                                                needTimeInput={false}
                                                onChange={(date: any) => {
                                                    handleStateGlobal(
                                                        'filterStartDate',
                                                        format(date, 'yyyy-MM-dd')
                                                    );
                                                    if (filterEndDate === '') {
                                                        let dateNow = new Date()
                                                            .toISOString()
                                                            .slice(0, 10);
                                                        handleStateGlobal(
                                                            'filterEndDate',
                                                            dateNow
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>End Date</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                        <DatePickerBlueRightIcon
                                            maxDate={moment().format('YYYY-MM-DD')}
                                            selected={new Date(`${filterEndDate}`)}
                                            needTimeInput={false}
                                            onChange={(date: any) => {
                                                handleStateGlobal(
                                                    'filterEndDate',
                                                    format(date, 'yyyy-MM-dd')
                                                );
                                                if (filterStartDate === '') {
                                                    let dateNow = new Date()
                                                        .toISOString()
                                                        .slice(0, 10);
                                                    handleStateGlobal(
                                                        'filterStartDate',
                                                        dateNow
                                                    );
                                                }
                                            }}
                                        />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Issuer</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <Select placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail}
                                                value={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === issuerIdCode)}
                                                onChange={(e) => {
                                                    handleStateGlobal('issuerIdCode', e.value);
                                                    resetFilterStatus();
                                                }} />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Issuer Switcher</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <Select placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail}
                                                value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === issuerSwitcherIdCode)}
                                                onChange={(e) => {
                                                    handleStateGlobal('issuerSwitcherIdCode', e.value);
                                                    resetFilterStatus();

                                                }} />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Acquirer Switcher</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <Select placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail}
                                                value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === acquirerSwitcherIdCode)}
                                                onChange={(e) => {
                                                    handleStateGlobal('acquirerSwitcherIdCode', e.value);
                                                    resetFilterStatus();
                                                }} />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Acquirer</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <Select placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail}
                                                value={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === acquirerIdCode)}
                                                onChange={(e) => {
                                                    handleStateGlobal('acquirerIdCode', e.value)
                                                    resetFilterStatus('dst', e.value);
                                                }} />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Product Indicator</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <Select
                                                placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={stateOptions.optionsProductFeatureProdFeatureIndAsValue}
                                                value={stateOptions.optionsProductFeatureProdFeatureIndAsValue.filter((option: any) => option.value === selectedProductFeatureId)}
                                                onChange={(e) => {
                                                    handleStateGlobal('selectedProductFeatureId', e.value);
                                                    handleStateGlobal('selectedProductFeatureSendToApi', e.sendToApi);
                                                    resetFilterStatus();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>MID</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={MID}
                                                onChange={(e) => {
                                                    handleStateGlobal('MID', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={32}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>TID</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={TID}
                                                onChange={(e) => {
                                                    handleStateGlobal('TID', e.target.value);
                                                    resetFilterStatus();
                                                }}
                                                maxLength={32}
                                            />
                                        </div>
                                    </div>
                                    <div className='row d-flex'>
                                        <div className="col-4">
                                            <p className={labelStyleWwithoutUppercase}>Amount</p>
                                        </div>
                                        <div className='col-8 d-flex justify-content-between'>
                                            <input
                                                type="text"
                                                className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                                                placeholder=''
                                                value={transactionAmount}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const validDecimal = /^\d*\.?\d*$/; 
                                                    if (validDecimal.test(value) && value.length <= 18) {
                                                        handleStateGlobal('transactionAmount', value);
                                                        resetFilterStatus();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {state.errorMessage !== '' && (
                                    <div className="col-12 pl-0">
                                        <div className="d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit mt-2">
                                            {errNotificationIcon}
                                            <p className="px-2 s-1418 Lato-600 c-e87">
                                                {state.errorMessage}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <button
                                    className="btn bg-d5 w-100 py-2 mt-3"
                                    onClick={(e) => {
                                        handleClose();
                                        initFunctionCustom(true);
                                    }}
                                    disabled={state.errorMessage !== ''}
                                >
                                    <p className="s-1620 tex-uppercase c-ff Lato-600 mx-2">
                                        APPLY
                                    </p>
                                </button>
                            </div>
                        </div>
                    </Zoom>
                </Modal>
            </div>
        </>
    );
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    backgroundColor: 'white',
    boxShadow: '24',
    padding: '20px',
    borderRadius: '5px'
};

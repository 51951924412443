import {
  Page1DashboardRoute,
  Page2DashboardRoute,
  Page3DashboardRoute,
  Page4DashboardRoute,
  Page5DashboardRoute,
  PageDashboardQRAuthStatisticRoute,
  PageMainDashboardRoute,
  Page1SystemNetworkRoute,
  Page1ProductRoute,
  Page2ProductRoute,
  Page3ProductRoute,
  Page1FeeRoute,
  Page1RateRoute,
  Page2RateRoute,
  Page1CodeRoute,
  Page4CodeRoute,
  Page3CodeRoute,
  Page2CodeRoute,
  Page3UserRoute,
  Page4QRRoute,
  Page5QRRoute,
  Page1QRRoute,
  Page2QRRoute,
  Page3QRRoute,
  Page6QRRoute,
  Page1AdminRoute,
  Page1UserRoute,
  Page1MemberRoute,
  Page2MemberRoute,
  Page2SystemNetworkRoute,
  Page3SystemNetworkRoute,
  Page2UserRoute,
  Page1InterfaceAPIRoute,
  Page2InterfaceAPIRoute,
  Page3InterfaceAPIRoute,
  Page4InterfaceAPIRoute,
  Page5InterfaceAPIRoute,
  Page6InterfaceAPIRoute,
  Page1InterfaceFileRoutes,
  Page2InterfaceFileRoutes,
  Page3InterfaceFileRoutes,
  Page4InterfaceFileRoutes,
  Page5InterfaceFileRoutes,
  Page1SettlementRoute,
  Page2SettlementRoute,
  Page3SettlementRoute,
  Page4SettlementRoute,
  Page5SettlementRoute,
  Page6SettlementRoute,
  Page7SettlementRoute,
  Page8SettlementRoute,

  Page1FrameworkRoute,
  Page2FrameworkRoute,
  Page1JobSchedulerRoute,
  Page2JobSchedulerRoute,
  Page3JobSchedulerRoute,
  Page4JobSchedulerRoute,
  Page5JobSchedulerRoute,
  Page3FrameworkRoute,
  Page4FrameworkRoute,
  // Page6FrameworkRoute,
  Page1ProductionSimRoute,
  Page2ProductionSimRoute,
  PageAlertHistory,
  PageAlertParameter,
  Page6InterfaceFileRoutes
} from "../Routes";
import { Page10Title, Page11Title, Page12Title, Page13Title, Page14Title, Page15Title, Page20Title, Page21Title, Page23Title, Page25Title, Page26Title, Page27Title, Page29Title, Page30Title, Page31Title, Page32Title, Page33Title, Page34Title, Page35Title, Page36Title, Page37Title, Page38Title, Page39Title, Page40Title, Page41Title, Page42Title, Page43Title, Page44Title, Page45Title, Page46Title, Page47Title, Page48Title, Page49Title, Page51Title, Page52Title, Page53Title, Page54Title, Page56Title, Page57Title, PageAdjustmentList, PageUnmatchedList,Page57ATitle, Page59Title, PageMainDashboardTitle, PageAlertHistoryTitle, PageFileTransferMonitoringTitle } from "./Menu/TitleMenu";

export const listPrivilegePage = JSON.stringify([
  {
    id: 50,
    label: Page20Title,
    val: "selectedSafControlParameter",
    valChecked: "optionSafControlParameter",
    checked: false,
    route: Page1AdminRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "51", label: "view" },
      { id: "52", label: "add" },
      { id: "53", label: "edit" },
      { id: "54", label: "delete" },
      { id: "55", label: "download" },
    ],
  },
  {
    id: 60,
    label: `${Page10Title}`,
    val: "selectedUserManagement",
    valChecked: "optionUserManagement",
    checked: false,
    route: Page1UserRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "61", label: "view" },
      { id: "62", label: "add" },
      { id: "63", label: "edit" },
      { id: "64", label: "delete" },
      { id: "65", label: "download" },
    ],
  },
  {
    id: 70,
    label: `${Page52Title}`,
    val: "selectedMemberManagement",
    valChecked: "optionMemberManagement",
    checked: false,
    route: Page2MemberRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "71", label: "view" },
      { id: "72", label: "add" },
      { id: "73", label: "edit" },
      { id: "74", label: "delete" },
      { id: "75", label: "download" },
    ],
  },
  {
    id: 80,
    label: `${Page51Title}`,
    val: "selectedMemberHMACManagement",
    valChecked: "optionMemberHMACManagement",
    checked: false,
    route: Page1MemberRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "81", label: "view" },
      { id: "82", label: "add" },
      { id: "83", label: "edit" },
      { id: "84", label: "delete" },
      { id: "85", label: "download" },
    ],
  },
  {
    id: 90,
    label: Page49Title,
    val: "selectedInstitutionMonitoringStatus",
    valChecked: "optionInstitutionMonitoringStatus",
    checked: false,
    route: Page2SystemNetworkRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "91", label: "view" },
      { id: "92", label: "add" },
      { id: "93", label: "edit" },
      { id: "94", label: "delete" },
      { id: "95", label: "download" },
    ],
  },
  {
    id: 100,
    label: Page21Title,
    val: "selectedLogLevelManagement",
    valChecked: "optionLogLevelManagement",
    checked: false,
    route: Page2FrameworkRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "101", label: "view" },
      // { id: "102", label: "add" },
      // { id: "103", label: "edit" },
      // { id: "104", label: "delete" },
      { id: "105", label: "download" },
    ],
  },
  {
    id: 110,
    label: "Archive Management",
    val: "selectedArchiveManagement",
    valChecked: "optionArchiveManagement",
    checked: false,
    route: Page3SystemNetworkRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "111", label: "view" },
      { id: "113", label: "edit" },
      { id: "114", label: "delete" },
      { id: "115", label: "download" },
    ],
  },
  {
    id: 120,
    label: Page11Title,
    val: "selectedUserLogHistory",
    valChecked: "optionUserLogHistory",
    checked: false,
    route: Page2UserRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "121", label: "view" },
      { id: "125", label: "download" },
    ],
  },
  {
    id: 130,
    label: Page13Title,
    val: "selectedProductManagement",
    valChecked: "optionProductManagement",
    checked: false,
    route: Page1ProductRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "131", label: "view" },
      { id: "132", label: "add" },
      { id: "133", label: "edit" },
      { id: "134", label: "delete" },
      { id: "135", label: "download" },
    ],
  },
  {
    id: 140,
    label: Page14Title,
    val: "selectedProductFeatureManagement",
    valChecked: "optionProductFeatureManagement",
    checked: false,
    route: Page2ProductRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "141", label: "view" },
      { id: "142", label: "add" },
      { id: "143", label: "edit" },
      { id: "144", label: "delete" },
      { id: "145", label: "download" },
    ],
  },
  // {
  //   id: 150,
  //   label: "Sub Product Management",
  //   val: "selectedSubProductManagement",
  //   valChecked: "optionSubProductManagement",
  //   checked: false,
  // route: ,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "151", label: "view" },
  //     { id: "152", label: "add" },
  //     { id: "153", label: "edit" },
  //     { id: "154", label: "delete" },
  //     { id: "155", label: "download" },
  //   ],
  // },
  // {
  //   id: 160,
  //   label: "Sub Product Feature Mapping",
  //   val: "selectedSubProductFeatureMapping",
  //   valChecked: "optionSubProductFeatureMapping",
  //   checked: false,
  // route: ,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "161", label: "view" },
  //     { id: "162", label: "add" },
  //     { id: "163", label: "edit" },
  //     { id: "164", label: "delete" },
  //     { id: "165", label: "download" },
  //   ],
  // },
  {
    id: 170,
    label: Page29Title,
    val: "selectedDataElementManagement",
    valChecked: "optionDataElementManagement",
    checked: false,
    route: Page6QRRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "171", label: "view" },
      { id: "172", label: "add" },
      { id: "173", label: "edit" },
      { id: "174", label: "delete" },
      { id: "175", label: "download" },
    ],
  },
  // {
  //   id: 180,
  //   label: "Sub Product Element Mapping",
  //   val: "selectedSubProductElementMapping",
  //   valChecked: "optionSubProductElementMapping",
  //   checked: false,
  // route: ,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "181", label: "view" },
  //     { id: "182", label: "add" },
  //     { id: "183", label: "edit" },
  //     { id: "184", label: "delete" },
  //     { id: "185", label: "download" },
  //   ],
  // },
  {
    id: 190,
    label: Page30Title,
    val: "selectedInterfaceField",
    valChecked: "optionInterfaceField",
    checked: false,
    route: Page1InterfaceAPIRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "191", label: "view" },
      { id: "192", label: "add" },
      { id: "193", label: "edit" },
      { id: "194", label: "delete" },
      { id: "195", label: "download" },
    ],
  },
  {
    id: 200,
    label: Page31Title,
    val: "selectedInterfaceHeader",
    valChecked: "optionInterfaceHeader",
    checked: false,
    route: Page2InterfaceAPIRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "201", label: "view" },
      { id: "202", label: "add" },
      { id: "203", label: "edit" },
      { id: "204", label: "delete" },
      { id: "205", label: "download" },
    ],
  },
  // {
  //   id: 210,
  //   label: "Interface Management",
  //   val: "selectedInterfaceManagement",
  //   valChecked: "optionInterfaceManagement",
  //   checked: false,
  // route: ,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "211", label: "view" },
  //     { id: "212", label: "add" },
  //     { id: "213", label: "edit" },
  //     { id: "214", label: "delete" },
  //     { id: "215", label: "download" },
  //   ],
  // },
  // {
  //   id: 220,
  //   label: "Interface Header Management",
  //   val: "selectedInterfaceHeaderManagement",
  //   valChecked: "optionInterfaceHeaderManagement",
  //   checked: false,
  // route: ,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "221", label: "view" },
  //     { id: "222", label: "add" },
  //     { id: "223", label: "edit" },
  //     { id: "224", label: "delete" },
  //     { id: "225", label: "download" },
  //   ],
  // },
  // {
  //   id: 230,
  //   label: "Routing Table Management",
  //   val: "selectedRoutingTableManagement",
  //   valChecked: "optionRoutingTableManagement",
  //   checked: false,
  // route: ,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "231", label: "view" },
  //     { id: "232", label: "add" },
  //     { id: "233", label: "edit" },
  //     { id: "234", label: "delete" },
  //     { id: "235", label: "download" },
  //   ],
  // },
  {
    id: 240,
    label: Page44Title,
    val: "selectedCommonCodeManagement",
    valChecked: "optionCommonCodeManagement",
    checked: false,
    route: Page1CodeRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "241", label: "view" },
      { id: "242", label: "add" },
      { id: "243", label: "edit" },
      { id: "244", label: "delete" },
      { id: "245", label: "download" },
    ],
  },
  {
    id: 250,
    label: Page45Title,
    val: "selectedReasonCodeManagement",
    valChecked: "optionReasonCodeManagement",
    checked: false,
    route: Page2CodeRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "251", label: "view" },
      { id: "252", label: "add" },
      { id: "253", label: "edit" },
      { id: "254", label: "delete" },
      { id: "255", label: "download" },
    ],
  },
  {
    id: 260,
    label: Page46Title,
    val: "selectedReasonCodeMapping",
    valChecked: "optionReasonCodeMapping",
    checked: false,
    route: Page3CodeRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "261", label: "view" },
      { id: "262", label: "add" },
      { id: "263", label: "edit" },
      { id: "264", label: "delete" },
      { id: "265", label: "download" },
    ],
  },
  {
    id: 270,
    label: Page47Title,
    val: "selectedCountryCodeManagement",
    valChecked: "optionCountryCodeManagement",
    checked: false,
    route: Page4CodeRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "271", label: "view" },
      { id: "272", label: "add" },
      { id: "273", label: "edit" },
      { id: "274", label: "delete" },
      { id: "275", label: "download" },
    ],
  },
  {
    id: 280,
    label: Page12Title,
    val: "selectedPrivilegeCode",
    valChecked: "optionPrivilegeCode",
    checked: false,
    route: Page3UserRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "281", label: "view" },
      { id: "282", label: "add" },
      { id: "283", label: "edit" },
      { id: "284", label: "delete" },
      { id: "285", label: "download" },
    ],
  },
  {
    id: 290,
    label: Page27Title,
    val: "selectedQRWhitelist&Blacklist",
    valChecked: "optionQRWhitelist&Blacklist",
    checked: false,
    route: Page4QRRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "291", label: "view" },
      { id: "292", label: "add" },
      { id: "293", label: "edit" },
      { id: "294", label: "delete" },
      { id: "295", label: "download" },
    ],
  },
  {
    id: 300,
    label: "QR Generator",
    val: "selectedQRGeneration",
    valChecked: "optionQRGeneration",
    checked: false,
    route: Page5QRRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "301", label: "view" },
      { id: "302", label: "add" },
      { id: "306", label: "print" },
    ],
  },
  {
    id: 310,
    label: "Fee Rate Setting",
    val: "selectedFeerateSetting",
    valChecked: "optionFeerateSetting",
    checked: false,
    route: Page1FeeRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "311", label: "view" },
      { id: "312", label: "add" },
      { id: "313", label: "edit" },
      { id: "314", label: "delete" },
      { id: "315", label: "download" },
    ],
  },
  {
    id: 320,
    label: "Exchange Rate Setting",
    val: "selectedExchangeRateSetting",
    valChecked: "optionExchangeRateSetting",
    checked: false,
    route: Page1RateRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "321", label: "view" },
      { id: "322", label: "add" },
      { id: "323", label: "edit" },
      { id: "324", label: "delete" },
      { id: "325", label: "download" },
    ],
  },
  {
    id: 330,
    label: Page34Title,
    val: "selectedRoutingManagement",
    valChecked: "optionRoutingManagement",
    checked: false,
    route: Page5InterfaceAPIRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "331", label: "view" },
      { id: "332", label: "add" },
      { id: "333", label: "edit" },
      { id: "334", label: "delete" },
      { id: "335", label: "download" },
    ],
  },
  {
    id: 340,
    label: Page32Title,
    val: "selectedRoutingFieldMapping",
    valChecked: "optionRoutingFieldMapping",
    checked: false,
    route: Page3InterfaceAPIRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "341", label: "view" },
      { id: "342", label: "add" },
      { id: "343", label: "edit" },
      { id: "344", label: "delete" },
      { id: "345", label: "download" },
    ],
  },
  {
    id: 350,
    label: Page33Title,
    val: "selectedRoutingHeaderMapping",
    valChecked: "optionRoutingHeaderMapping",
    checked: false,
    route: Page4InterfaceAPIRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "351", label: "view" },
      { id: "352", label: "add" },
      { id: "353", label: "edit" },
      { id: "354", label: "delete" },
      { id: "355", label: "download" },
    ],
  },
  // {
  //   id: 360,
  //   label: "Sub Product Feature Member",
  //   val: "selectedSubProductFeatureMember",
  //   valChecked: "optionSubProductFeatureMember",
  //   checked: false,
  // route: ,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "361", label: "view" },
  //     { id: "362", label: "add" },
  //     { id: "363", label: "edit" },
  //     { id: "364", label: "delete" },
  //     { id: "365", label: "download" },
  //   ],
  // },
  {
    id: 370,
    label: "QR Standard",
    val: "selectedQRStandard",
    valChecked: "optionQRStandard",
    checked: false,
    route: Page1QRRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "371", label: "view" },
      { id: "372", label: "add" },
      { id: "373", label: "edit" },
      { id: "374", label: "delete" },
      { id: "375", label: "download" },
    ],
  },
  {
    id: 380,
    label: Page25Title,
    val: "selectedQRStandardElement",
    valChecked: "optionQRStandardElement",
    checked: false,
    route: Page2QRRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "381", label: "view" },
      { id: "382", label: "add" },
      { id: "383", label: "edit" },
      { id: "384", label: "delete" },
      { id: "385", label: "download" },
    ],
  },
  {
    id: 390,
    label: Page26Title,
    val: "selectedQRStandardMember",
    valChecked: "optionQRStandardMember",
    checked: false,
    route: Page3QRRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "391", label: "view" },
      { id: "392", label: "add" },
      { id: "393", label: "edit" },
      { id: "394", label: "delete" },
      { id: "395", label: "download" },
    ],
  },
  {
    id: 400,
    label: Page54Title,
    val: "selectedRouteSequence",
    valChecked: "optionRouteSequence",
    checked: false,
    route: Page6InterfaceAPIRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "401", label: "view" },
      { id: "402", label: "add" },
      { id: "403", label: "edit" },
      { id: "404", label: "delete" },
      { id: "405", label: "download" },
    ],
  },
  {
    id: 410,
    label: Page15Title,
    val: "selectedProductFeatureMember",
    valChecked: "optionProductFeatureMember",
    checked: false,
    route: Page3ProductRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "411", label: "view" },
      { id: "412", label: "add" },
      { id: "413", label: "edit" },
      { id: "414", label: "delete" },
      { id: "415", label: "download" },
    ],
  },

  {
    id: 680,
    label: "Settlement Summary",
    val: "selectedSettlementSummary",
    valChecked: "optionSettlementSummary",
    checked: false,
    route: Page6SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "681", label: "view" },
    ],
  },
  {
    id: 420,
    label: "Settlement Summary (Acquirer)",
    val: "selectedSettlementSummary(Acquirer)",
    valChecked: "optionSettlementSummary(Acquirer)",
    checked: false,
    route: Page1SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "421", label: "view" },
    ],
  },
  {
    id: 430,
    label: "Settlement Summary (Issuer)",
    val: "selectedSettlementSummary(Issuer)",
    valChecked: "optionSettlementSummary(Issuer)",
    checked: false,
    route: Page2SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "431", label: "view" },
    ],
  },
  {
    id: 440,
    label: "Sales Inquiry",
    val: "selectedSalesInquiry",
    valChecked: "optionSalesInquiry",
    checked: false,
    route: Page3SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "441", label: "view" },
    ],
  },
  {
    id: 450,
    label: "Dispute Resolution",
    val: "selectedDisputeResolution",
    valChecked: "optionDisputeResolution",
    checked: false,
    route: Page4SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "451", label: "view" },
    ],
  },
  {
    id: 460,
    label: "Member Payment",
    val: "selectedMemberPayment",
    valChecked: "optionMemberPayment",
    checked: false,
    route: Page5SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "461", label: "view" },
    ],
  },
  {
    id: 470,
    label: "Transaction Statistic",
    val: "selectedTransactionMonitoring",
    valChecked: "optionProductFeatureMember",
    checked: false,
    route: Page1DashboardRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "471", label: "view" },
    ],
  },
  {
    id: 480,
    label: "Transaction Inquiry",
    val: "selectedTransactionMonitoringStatus",
    valChecked: "optionTransactionMonitoringStatus",
    checked: false,
    route: Page2DashboardRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "481", label: "view" },
    ],
  },
  {
    id: 490,
    label: "SAF Statistic",
    val: "selectedReversalTransactionMonitoring",
    valChecked: "optionReversalTransactionMonitoring",
    checked: false,
    route: Page3DashboardRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "491", label: "view" },
    ],
  },
  {
    id: 500,
    label: "SAF Transaction Inquiry",
    val: "selectedReversalMonitoringStatus",
    valChecked: "optionReversalMonitoringStatus",
    checked: false,
    route: Page4DashboardRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "501", label: "view" },
    ],
  },

  {
    id: 510,
    label: Page48Title,
    val: "selectedCloudWatchMonitoring",
    valChecked: "optionCloudWatchMonitoring",
    checked: false,
    route: Page1SystemNetworkRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "511", label: "view" },
    ],
  },
  // {                                      //SAF Control
  //   id: 520,
  //   label: Page20Title,
  //   val: "selectedLogAnalyzer",
  //   valChecked: "optionLogAnalyzer",
  //   checked: false,
  //   route: Page1FrameworkRoute,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "521", label: "view" },
  //   ],
  // },

  // {
  //   id: 540,
  //   label: Page40Title,
  //   val: "selectedBatchProgram",
  //   valChecked: "optionBatchProgram",
  //   checked: false,
  //   route: Page1JobSchedulerRoute,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "541", label: "view" },
  //   ],
  // },
  {
    id: 550,
    label: Page41Title,
    val: "selectedSchedulerManagement",
    valChecked: "optionSchedulerManagement",
    checked: false,
    route: Page2JobSchedulerRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "551", label: "view" },
      { id: "552", label: "add" },
    ],
  },
  // {
  //   id: 560,
  //   label: Page42Title,
  //   val: "selectedRunScheduler",
  //   valChecked: "optionRunScheduler",
  //   checked: false,
  //   route: Page3JobSchedulerRoute,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "561", label: "view" },
  //   ],
  // },
  {
    id: 570,
    label: Page43Title,
    val: "selectedSchedulerExecution",
    valChecked: "optionSchedulerExecution",
    checked: false,
    route: Page4JobSchedulerRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "571", label: "view" },
    ],
  },
  {
    id: 590,
    label: "Exchange Rate Member",
    val: "selectedExchangeRateMember",
    valChecked: "optionExchangeRateMember",
    checked: false,
    route: Page2RateRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "591", label: "view" },
      { id: "592", label: "add" },
      { id: "593", label: "edit" },
      { id: "594", label: "delete" },
      { id: "595", label: "download" },
    ],
  },
  {
    id: 600,
    label: Page35Title,
    val: "selectedInterfaceFile",
    valChecked: "optionInterfaceFile",
    checked: false,
    route: Page1InterfaceFileRoutes,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "601", label: "view" },
      { id: "602", label: "add" },
      { id: "603", label: "edit" },
      { id: "604", label: "delete" },
      { id: "605", label: "download" },
    ],
  },
  {
    id: 610,
    label: Page38Title,
    val: "selectedInterfaceRecord",
    valChecked: "optionInterfaceRecord",
    checked: false,
    route: Page4InterfaceFileRoutes,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "611", label: "view" },
      { id: "612", label: "add" },
      { id: "613", label: "edit" },
      { id: "614", label: "delete" },
      { id: "615", label: "download" },
    ],
  },
  {
    id: 620,
    label: Page37Title,
    val: "selectedInterfaceFileRecord",
    valChecked: "optionInterfaceFileRecord",
    checked: false,
    route: Page3InterfaceFileRoutes,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "621", label: "view" },
      { id: "622", label: "add" },
      { id: "623", label: "edit" },
      { id: "624", label: "delete" },
      { id: "625", label: "download" },
    ],
  },
  {
    id: 630,
    label: Page36Title,
    val: "selectedInterfaceFileItem",
    valChecked: "optionInterfaceFileItem",
    checked: false,
    route: Page2InterfaceFileRoutes,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "631", label: "view" },
      { id: "632", label: "add" },
      { id: "633", label: "edit" },
      { id: "634", label: "delete" },
      { id: "635", label: "download" },
    ],
  },
  {
    id: 640,
    label: Page39Title,
    val: "selectedInterfaceRecordItem",
    valChecked: "optionInterfaceRecordItem",
    checked: false,
    route: Page5InterfaceFileRoutes,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "641", label: "view" },
      { id: "642", label: "add" },
      { id: "643", label: "edit" },
      { id: "644", label: "delete" },
      { id: "645", label: "download" },
    ],
  },
  {
    id: 650,
    label: "Service Profile",
    val: "selectedServiceRegistration",
    valChecked: "optionServiceRegistration",
    checked: false,
    route: Page3FrameworkRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "651", label: "view" },
      { id: "652", label: "add" },
      { id: "653", label: "edit" },
      { id: "654", label: "delete" },
      { id: "655", label: "download" },
    ],
  },
  {
    id: 660,
    label: Page23Title,
    val: "selectedBatchProgramRegistration",
    valChecked: "optionBatchProgramRegistration",
    checked: false,
    route: Page4FrameworkRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "661", label: "view" },
      { id: "662", label: "add" },
      { id: "663", label: "edit" },
      { id: "664", label: "delete" },
      // { id: "665", label: "download" },
    ],
  },
  {
    id: 700,
    label: Page57Title,
    val: "selectedProductionSimulatorInquiry",
    valChecked: "optionProductionSimulatorInquiry",
    checked: false,
    route: Page1ProductionSimRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "701", label: "view" },
      { id: "702", label: "download" },
    ],
  },
  {
    id: 720,
    label: Page57ATitle,
    val: "selectedQrAuthSimulatorInquiry",
    valChecked: "optionQrAuthSimulatorInquiry",
    checked: false,
    route: Page2ProductionSimRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "721", label: "view" },
    ],
  },

  {
    id: 800,
    label: "QR Auth Inquiry",
    val: "selectedQRAuthInquiry",
    valChecked: "optionQRAuthInquiry",
    checked: false,
    route: Page5DashboardRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "801", label: "view" },
    ],
  },

  {
    id: 900,
    label: "Alert Parameters",
    val: "selectedAlertParameters",
    valChecked: "optionAlertParameters",
    checked: false,
    route: PageAlertParameter,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "901", label: "view" },
      { id: "902", label: "add" },
      { id: "903", label: "edit" },
      { id: "904", label: "delete" },
      { id: "905", label: "download" },
    ],
  },
  {
    id: 710,
    label: "QR Auth Statistic",
    val: "selectedQRAuthStatistic",
    valChecked: "optionQRAuthMonitoring",
    checked: false,
    route: PageDashboardQRAuthStatisticRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "711", label: "view" },
    ],
  },
  {
    id: 980,
    label: PageUnmatchedList,
    val: "selectedUnmatchedList",
    valChecked: "OptionUnmatchedList",
    checked: false,
    route: Page7SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "981", label: "view" },
    ],
  },
  {
    id: 990,
    label: PageAdjustmentList,
    val: "selectedAdjustmentList",
    valChecked: "OptionAdjustmentList",
    checked: false,
    route: Page8SettlementRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "991", label: "view" },
    ],
  },
  { // main page dashboard
    id: 1000,
    label: PageMainDashboardTitle,
    val: "selectedTransactionMonitoringNew",
    valChecked: "optionTransactionMonitoringNew",
    checked: false,
    route: PageMainDashboardRoute,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "1001", label: "view" },
    ],
  },

  {
    id: 1010,
    label: PageAlertHistoryTitle,
    val :"selectedAlertHistory",
    valChecked: "OptionAlertHistory",
    checked: false,
    route : PageAlertHistory,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "1011", label: "view" },
    ],
  },
  {
    id: 1020,
    label: PageFileTransferMonitoringTitle,
    val: "selectedFileTransferMonitoring",
    valChecked: "OptionFileTransferMonitoring",
    checked: false,
    route: Page6InterfaceFileRoutes,
    listPrivilege: [
      { id: "all", label: "all" },
      { id: "1021", label: "view" },
    ],
  },
  // {
  //   id: 670,
  //   label: Page53Title,
  //   val: "selectedBatchScheduler",
  //   valChecked: "optionBatchScheduler",
  //   checked: false,
  //   route: Page5JobSchedulerRoute,
  //   listPrivilege: [
  //     { id: "all", label: "all" },
  //     { id: "671", label: "view" },
  //     { id: "672", label: "add" },
  //     { id: "673", label: "edit" },
  //     { id: "674", label: "delete" },
  //     { id: "675", label: "download" },
  //   ],
  // },
]);

// {
//   "privfId": 331,
//   "privfName": "Routing Management - view",
//   "privfDesc": "Routing Management - view"
// }
// {
//   "privfId": 332,
//   "privfName": "Routing Management - add",
//   "privfDesc": "Routing Management - add"
// }
// {
//   "privfId": 333,
//   "privfName": "Routing Management - edit",
//   "privfDesc": "Routing Management - edit"
// }
// {
//   "privfId": 334,
//   "privfName": "Routing Management - delete",
//   "privfDesc": "Routing Management - delete"
// }
// {
//   "privfId": 335,
//   "privfName": "Routing Management - download",
//   "privfDesc": "Routing Management - download"
// }

// {
//   "privfId": 341,
//   "privfName": "Routing Field Mapping - view",
//   "privfDesc": "Routing Field Mapping - view"
// }
// {
//   "privfId": 342,
//   "privfName": "Routing Field Mapping - add",
//   "privfDesc": "Routing Field Mapping - add"
// }
// {
//   "privfId": 343,
//   "privfName": "Routing Field Mapping - edit",
//   "privfDesc": "Routing Field Mapping - edit"
// }
// {
//   "privfId": 344,
//   "privfName": "Routing Field Mapping - delete",
//   "privfDesc": "Routing Field Mapping - delete"
// }
// {
//   "privfId": 345,
//   "privfName": "Routing Field Mapping - download",
//   "privfDesc": "Routing Field Mapping - download"
// }

// {
//   "privfId": 351,
//   "privfName": "Routing Header Mapping - view",
//   "privfDesc": "Routing Header Mapping - view"
// }
// {
//   "privfId": 352,
//   "privfName": "Routing Header Mapping - add",
//   "privfDesc": "Routing Header Mapping - add"
// }
// {
//   "privfId": 353,
//   "privfName": "Routing Header Mapping - edit",
//   "privfDesc": "Routing Header Mapping - edit"
// }
// {
//   "privfId": 354,
//   "privfName": "Routing Header Mapping - delete",
//   "privfDesc": "Routing Header Mapping - delete"
// }
// {
//   "privfId": 355,
//   "privfName": "Routing Header Mapping - download",
//   "privfDesc": "Routing Header Mapping - download"
// }

// {
//   "privfId": 361,
//   "privfName": "Sub Product Feature Member - view",
//   "privfDesc": "Sub Product Feature Member - view"
// }
// {
//   "privfId": 362,
//   "privfName": "Sub Product Feature Member - add",
//   "privfDesc": "Sub Product Feature Member - add"
// }
// {
//   "privfId": 363,
//   "privfName": "Sub Product Feature Member - edit",
//   "privfDesc": "Sub Product Feature Member - edit"
// }
// {
//   "privfId": 364,
//   "privfName": "Sub Product Feature Member - delete",
//   "privfDesc": "Sub Product Feature Member - delete"
// }
// {
//   "privfId": 365,
//   "privfName": "Sub Product Feature Member - download",
//   "privfDesc": "Sub Product Feature Member - download"
// }



// DetailDisputeResolutionEvent

interface DetailDisputeResolutionEventInterface {
    id: null | number | string;
    eventId: number | null;
    dateTime: string | null;
    eventType: string | null;
    event: string | null;
    user: string | null;
    member: string | null;
    detail: string | null;
}

export class ModelDetailDisputeResolutionEvent {
    id: null | number | string;
    eventId: number | null;
    dateTime: string | null;
    eventType: string | null;
    event: string | null;
    user: string | null;
    member: string | null;
    detail: string | null;

    constructor(value: DetailDisputeResolutionEventInterface | null) {
        this.id = value?.id ?? null;
        this.eventId = value?.eventId ?? null;
        this.dateTime = value?.dateTime ?? null;
        this.eventType = value?.eventType ?? null;
        this.event = value?.event ?? null;
        this.user = value?.user ?? null;
        this.member = value?.member ?? null;
        this.detail = value?.detail ?? null;
    }
}

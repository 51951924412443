

// BatchProgramRegistration

interface BatchProgramRegistrationInterface {
    id: null | number | string;
    batchId: number | null;
    batchProgId: string | null;
    batchStat: string | null;
    batchStatus: string | null;
    batchName: string | null;
    batchType: string | null;
    batchProgName: string | null;
    batchReprocYn: Boolean | null;
    logLevel: string | null;
    batchParallelExecYn: Boolean | null;
    batchDesc: string | null;
    batchParameterFormat: string | null;
    batchConstName: string | null;
}

export class ModelBatchProgramRegistration {
    id: null | number | string;
    batchId: number | null;
    batchProgId: string | null;
    batchStat: string | null;
    batchStatus: string | null;
    batchName: string | null;
    batchType: string | null;
    batchProgName: string | null;
    batchReprocYn: Boolean | null;
    logLevel: string | null;
    batchParallelExecYn: Boolean | null;
    batchDesc: string | null;
    batchParameterFormat: string | null;
    batchConstName: string | null;

    constructor(value: BatchProgramRegistrationInterface | null) {
        this.id = value?.id ?? null;
        this.batchId = value?.batchId ?? null;
        this.batchProgId = value?.batchProgId ?? null;
        this.batchStat = value?.batchStat ?? null;
        this.batchStatus = value?.batchStatus ?? null;
        this.batchName = value?.batchName ?? null;
        this.batchType = value?.batchType ?? null;
        this.batchProgName = value?.batchProgName ?? null;
        this.batchReprocYn = value?.batchReprocYn ?? null;
        this.logLevel = value?.logLevel ?? null;
        this.batchParallelExecYn = value?.batchParallelExecYn ?? null;
        this.batchDesc = value?.batchDesc ?? null;
        this.batchParameterFormat = value?.batchParameterFormat ?? null;
        this.batchConstName = value?.batchConstName ?? null;
    }
}

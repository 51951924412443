import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalFee = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMember, optionsCommonStatusDetailCode, optionsFeeRateInputTypeDetailCode } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { errorMessage, feeId, feeType, feeDesc, feeStatus } = statePage
        const data = {
            feeType: feeType,
            feeDesc: feeDesc,
            feeStatus: feeStatus,
        }
        const a = CheckAllFields([feeType, feeStatus], ['Fee Type', 'Status', 'Member'])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-600px'>
                    <div className='d-flex min-h-40px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className={'col-6'}>
                                <p className={labelStyle}>FEE TYPE</p>
                                <Select styles={inputStyleH25} options={optionsFeeRateInputTypeDetailCode} value={optionsFeeRateInputTypeDetailCode.filter((option: any) => option.value === statePage.feeType)} onChange={(e) => handleState('feeType', e.value)} />
                            </div>

                            <div className={'col-6'}>
                                <p className={labelStyle}>STATUS</p>
                                <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.feeStatus)} onChange={(e) => handleState('feeStatus', e.value)} />
                            </div>
                            <div className={'col-12'}>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={(statePage.feeDesc !== 'null') && (statePage.feeDesc !== null) ? statePage.feeDesc : ''} onChange={(e) => handleState('feeDesc', e.target.value)} className={inputDescriptionStyle}></textarea>
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />

                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: 'New Fee',
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    feeId: '',
    feeType: '',
    feeDesc: '',
    feeStatus: '001',
    //
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                feeId: '',
                feeType: '',
                feeDesc: '',
                feeStatus: '001',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                feeId: action.value.feeId,
                feeType: action.value.feeType,
                feeDesc: action.value.feeDesc,
                feeStatus: action.value.feeStatus,
                errorMessage: '',
            };
        // 
        case 'feeId':
            return {
                ...state,
                feeId: action.value,
            };
        case 'feeType':
            return {
                ...state,
                feeType: action.value,
            };
        case 'feeDesc':
            return {
                ...state,
                feeDesc: action.value,
            };
        case 'feeStatus':
            return {
                ...state,
                feeStatus: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        // 
        default:
            throw new Error();
    }
}

import { useSelector } from "react-redux";
import { CheckUndefined, CheckUndefinedStringEmpty, NumberWithCommas, NumberWithCommas2 } from "../../Utils";
import { Divider, Popover } from "antd";

const HeaderComponent = ({ ...props }) => {
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  return (
    <div className="d-flex justify-content-between px-4">
      {props.label.map((item: any, i: any) => {
        if (item.val === "action") {
          if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
            return (
              props.checkIndex(`${item.val}`) && (
                <p key={i} className={`c-29 s-1418 Lato-700 ${item.width} text-center  `}>
                  {item.field}
                </p>
              )
            );
          } else {
            return <></>;
          }
        } else {
          return (
            props.checkIndex(`${item.val}`) && (
              <p key={i} className={`c-29 s-1418 Lato-700 ${item.width}   `}>
                {item.field}
              </p>
            )
          );
        }
      })}
    </div>
  );
};

const FooterComponent = ({ ...props }) => {
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  return (
    <div className="d-flex justify-content-between align-items-start min-h-50px px-4 py-2" style={{ height: "auto", borderTop: "1px solid #d4d4d4", borderBottom: "1px solid #d4d4d4" }}>
      {props.label.map((item: any, i: any) => {
        if (item.val === "no") {
          return props.checkIndex(`${item.val}`) && <p className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`}></p>;
        } else if (item.val === "acquirerCount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcq?.map((acqData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqData.count !== "" ? acqData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "acquirerAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcq?.map((acqData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqData.amount !== "" ? acqData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "acquirerReconAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcqRecon?.map((acqReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqReconData.amount !== "" ? acqReconData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "acquirerReconCount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcqRecon?.map((acqReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqReconData.count !== "" ? acqReconData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "acquirerSwitchCount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcqSwitch?.map((acqSwitchData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqSwitchData.count !== "" ? acqSwitchData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "acquirerSwitchAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcqSwitch?.map((acqSwitchData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqSwitchData.amount !== "" ? acqSwitchData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "acquirerSwitchReconAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcqSwitchRecon?.map((acqSwitchReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqSwitchReconData.amount !== "" ? acqSwitchReconData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "acquirerSwitchReconCount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalAcqSwitchRecon?.map((acqSwitchReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {acqSwitchReconData.count !== "" ? acqSwitchReconData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerSwitchCount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIssSwitch?.map((issSwitchData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issSwitchData.count !== "" ? issSwitchData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerSwitchAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIssSwitch?.map((issSwitchData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issSwitchData.amount !== "" ? issSwitchData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerSwitchReconAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIssSwitchRecon?.map((issSwitchReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issSwitchReconData.amount !== "" ? issSwitchReconData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerSwitchReconCount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIssSwitchRecon?.map((issSwitchReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issSwitchReconData.count !== "" ? issSwitchReconData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerCount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIss?.map((issData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issData.count !== "" ? issData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIss?.map((issData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issData.amount !== "" ? issData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerReconAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIssRecon?.map((issReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issReconData.amount !== "" ? issReconData.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerReconCount") {
          return (
            // props.checkIndex(`${item.val}`) && (
            //   <p className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`}>
            //     {(props.data2.totalIssRecon.count)}
            //   </p>
            // )
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIssRecon?.map((issReconData: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {issReconData.count !== "" ? issReconData.count : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else if (item.val === "issuerSwitchSettAmount") {
          return (
            props.checkIndex(`${item.val}`) && (
              <div>
                {props.data2.totalIssSwitchSett?.map((data: any, index: any) => (
                  <p key={index} className={`c-29 s-1418 Lato-700 word-wrap py-1 ${item.width}`} style={{ borderBottom: "1px solid #d4d4d4" }}>
                    {data.amount !== "" ? data.amount : "0"}
                  </p>
                ))}
              </div>
            )
          );
        } else {
          return props.checkIndex(`${item.val}`) && <p key={i} className={`c-29 s-1418 Lato-700 ${item.width}   `}></p>;
        }
      })}
    </div>
  );
};

export const TableSettlementSummaryAcquirer = ({ ...props }) => {
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

  return (
    <div className="d-flex flex-column w-100 overflow mb-2">
      <div className={`d-flex flex-column ${props.widthTable}`}>
        <HeaderComponent codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete} codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit} label={props.label} data={props.data} position={props.position} view={props.view} customButtonAction={props.customButtonAction} checkIndex={props.checkIndex} widthTable={props.widthTable} stateOptions={props.stateOptions} />
        <Divider className="my-2" />

        {props.data.map((item: any, index: any) => {
          let number = index + (props.currentPage - 1) * props.view;
          return (
            <div key={number} className={`d-flex justify-content-between align-items-center min-h-50px px-4  ${number % 2 === 0 ? "bg-f4" : ""} `}>
              {props.label.map((element1: any) => {
                if (element1.val === "no") {
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{number + 1}</p>;
                } else if (element1.val === "action") {
                  if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                    return props.checkIndex(`${element1.val}`) && <div className={`d-flex c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
                  } else {
                    return <></>;
                  }
                } else if (element1.val === "acquirerAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.acquirerCountryCurr)} ${NumberWithCommas(CheckUndefined(item.acquirerAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "acquirerReconAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.acquirerReconCountryCurr)} ${NumberWithCommas(CheckUndefined(item.acquirerReconAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "acquirerSwitchAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.acquirerSwitchCountryCurr)} ${NumberWithCommas(CheckUndefined(item.acquirerSwitchAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "acquirerSwitchReconAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.acquirerSwitchReconCountryCurr)} ${NumberWithCommas(CheckUndefined(item.acquirerSwitchReconAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "issuerReconStatus") {
                  let selectedBgColor = "";
                  let selectedTextColor = "";
                  if (item[`${element1.val}`] === "Unmatched") {
                    selectedBgColor = "#dc3545";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Reconciled") {
                    selectedBgColor = "#198754";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Unknown") {
                    selectedBgColor = "#0dcaf0";
                    selectedTextColor = "black";
                  } else if (item[`${element1.val}`] === "Unreconciled") {
                    selectedBgColor = "#0000ff";
                    selectedTextColor = "white";
                  }
                  // if (CheckUndefined(item[`${element1.val}`]) === 'UNMATCHED') {
                  if (CheckUndefined(item[`${element1.val}`]) === "Unknown") {
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <Popover content="Member's not sent the settlement file">
                          <p className={` s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "issuer")}>
                            <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                              {CheckUndefined(item[`${element1.val}`])}
                            </div>
                          </p>
                        </Popover>
                      )
                    );
                  } else {
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <p className={` s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "issuer")}>
                          <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                            {CheckUndefined(item[`${element1.val}`])}
                          </div>
                        </p>
                      )
                    );
                  }
                  // }
                  // return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap  ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                } else if (element1.val === "issuerSwitchReconStatus") {
                  let selectedBgColor = "";
                  let selectedTextColor = "";
                  if (item[`${element1.val}`] === "Unmatched") {
                    selectedBgColor = "#dc3545";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Reconciled") {
                    selectedBgColor = "#198754";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Unknown") {
                    selectedBgColor = "#0dcaf0";
                    selectedTextColor = "black";
                  } else if (item[`${element1.val}`] === "Unreconciled") {
                    selectedBgColor = "#0000ff";
                    selectedTextColor = "white";
                  }
                  // if (CheckUndefined(item[`${element1.val}`]) === 'UNMATCHED') {
                  if (CheckUndefined(item[`${element1.val}`]) === "Unknown") {
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <Popover content="Member's not sent the settlement file">
                          <p className={` s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "issuerSwitch")}>
                            <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                              {CheckUndefined(item[`${element1.val}`])}
                            </div>
                          </p>
                        </Popover>
                      )
                    );
                  } else {
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <p className={` s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "issuerSwitch")}>
                          <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                            {CheckUndefined(item[`${element1.val}`])}
                          </div>
                        </p>
                      )
                    );
                  }
                  // }
                  // return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap  ${element1.width}`} >{CheckUndefined(item[`${element1.val}`])}</p>;
                } else if (element1.val === "acquirerSwitchReconStatus") {
                  let selectedBgColor = "";
                  let selectedTextColor = "";
                  if (item[`${element1.val}`] === "Unmatched") {
                    selectedBgColor = "#dc3545";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Reconciled") {
                    selectedBgColor = "#198754";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Unknown") {
                    selectedBgColor = "#0dcaf0";
                    selectedTextColor = "black";
                  } else if (item[`${element1.val}`] === "Unreconciled") {
                    selectedBgColor = "#0000ff";
                    selectedTextColor = "white";
                  }
                  if (CheckUndefined(item[`${element1.val}`]) === "Unknown") {
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <Popover content="User’s not sent the settlement file">
                          <p className={`s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "acquirerSwitch")}>
                            <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                              {CheckUndefined(item[`${element1.val}`])}
                            </div>
                          </p>
                        </Popover>
                      )
                    );
                  } else
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <p className={`s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "acquirerSwitch")}>
                          <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                            {CheckUndefined(item[`${element1.val}`])}
                          </div>
                        </p>
                      )
                    );
                  // if (CheckUndefined(item[`${element1.val}`]) === 'UNMATCHED') {
                  // }
                  // return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap  ${element1.width}`} >{CheckUndefined(item[`${element1.val}`])}</p>;
                } else if (element1.val === "acquirerReconStatus") {
                  let selectedBgColor = "";
                  let selectedTextColor = "";
                  if (item[`${element1.val}`] === "Unmatched") {
                    selectedBgColor = "#dc3545";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Reconciled") {
                    selectedBgColor = "#198754";
                    selectedTextColor = "white";
                  } else if (item[`${element1.val}`] === "Unknown") {
                    selectedBgColor = "#0dcaf0";
                    selectedTextColor = "black";
                  } else if (item[`${element1.val}`] === "Unreconciled") {
                    selectedBgColor = "#0000ff";
                    selectedTextColor = "white";
                  }
                  // if (CheckUndefined(item[`${element1.val}`]) === 'UNMATCHED') {

                  if (CheckUndefined(item[`${element1.val}`]) === "Unknown") {
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <Popover content="Member's not sent the settlement file">
                          <p className={`s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "acquirer")}>
                            <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                              {CheckUndefined(item[`${element1.val}`])}
                            </div>
                          </p>
                        </Popover>
                      )
                    );
                  } else {
                    return (
                      props.checkIndex(`${element1.val}`) && (
                        <p className={`s-1418 Lato-400 word-wrap row-data-pointer  ${element1.width}`} onClick={() => props.editData(item, "acquirer")}>
                          <div className="btn" style={{ backgroundColor: selectedBgColor, color: selectedTextColor }}>
                            {CheckUndefined(item[`${element1.val}`])}
                          </div>
                        </p>
                      )
                    );
                  }
                  // }
                  // return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap  ${element1.width}`} >{CheckUndefined(item[`${element1.val}`])}</p>;
                } else if (element1.val === "issuerSwitchAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.issuerSwitchCountryCurr)} ${NumberWithCommas(CheckUndefined(item.issuerSwitchAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "issuerSwitchReconAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.issuerSwitchReconCountryCurr)} ${NumberWithCommas(CheckUndefined(item.issuerSwitchReconAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "issuerAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.issuerCountryCurr)} ${NumberWithCommas2(CheckUndefined(item.issuerAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "issuerReconAmount") {
                  let show = `${CheckUndefinedStringEmpty(item.issuerReconCountryCurr)} ${NumberWithCommas(CheckUndefined(item.issuerReconAmount))}`;
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else if (element1.val === "issuerSwitchSettAmount") {
                  let show = "";
                  if (item.issuerSwitchSettAmount === 0.0) {
                    show = `USD 0`;
                  } else {
                    show = `${CheckUndefinedStringEmpty(item.issuerSwitchSettCountryCurr)} ${NumberWithCommas(CheckUndefined(item.issuerSwitchSettAmount))}`;
                  }
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                } else {
                  return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                }
              })}
            </div>
          );
        })}
        <Divider className="my-0" />
      </div>
      <div className={`d-flex flex-column ${props.widthTable}`}>
        <FooterComponent
          label={props.label}
          data={props.data}
          position={props.position}
          view={props.view}
          customButtonAction={props.customButtonAction}
          checkIndex={props.checkIndex}
          widthTable={props.widthTable}
          stateOptions={props.stateOptions}
          //
          data2={props.data2}
        />
      </div>
    </div>
  );
};

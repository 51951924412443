
import { useSelector } from "react-redux";
import { COLORS_NODE_JOB, CheckUndefined } from "../../Utils";
import { Divider, Tooltip } from "antd";
import { HeaderComponent } from "../../Components";
import { Page4JobSchedulerRoute } from "../../Routes";


export const TableSchedulerManagementDetail = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    return (
        <div className='d-flex flex-column w-100 overflow mb-2'>
            <div className={`d-flex flex-column ${props.widthTable}`}>
                <HeaderComponent
                    codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
                    codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
                    label={props.label}
                    data={props.data}
                    position={props.position}
                    view={props.view}
                    customButtonAction={props.customButtonAction}
                    checkIndex={props.checkIndex}
                    widthTable={props.widthTable}
                    stateOptions={props.stateOptions}
                />
                <Divider className="my-2" />


                {props.data.map((item: any, index: any) => {
                    let number = index + ((props.currentPage - 1) * props.view)
                    let backgroundColor: any = '';
                    if (number % 2) {
                    } else {
                        backgroundColor = 'bg-f4';

                    }
                    if (`${props.selectedJobId}` === `${item.id}`) {
                        backgroundColor = 'bg-e8 text-white';
                    }
                    return (
                        <a
                            key={number}
                            className={`d-flex justify-content-between align-items-center min-h-50px px-4 table-data-pointer ${backgroundColor} `}
                            href={`${Page4JobSchedulerRoute}?batchProgramId=${item.batchProgramId === '' ? '' : item.batchProgramId}&batchLastExec=${item.batchLastExec === '' ? '' : item.batchLastExec?.toString().split(' ')[0]}&batchExecutionType=${item.batchExecutionType}&batchBusinessGroup=${item.batchBusinessGroup}&batchJobId=${item.id}`}
                            target="_blank"
                        >
                            {props.label.map((element1: any) => {
                                if (element1.val === 'no') {
                                    return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{number + 1}</p>;
                                } else if (element1.val === 'action') {
                                    if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                                        return props.checkIndex(`${element1.val}`) && <div className={`d-flex  s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
                                    } else {
                                        return <></>;
                                    }
                                } else if (element1.val === "batchStatus") {
                                    let selectedBackgroundColor: any = ''
                                    let labelTooltip = `${item[`${element1.val}`]}`;
                                    if (props.stateOptions.optionsBatchSchedulerStatusDetailCode.length > 0) {
                                        props.stateOptions.optionsBatchSchedulerStatusDetailCode.forEach((element2: any) => {
                                            if (element2.value === item[`${element1.val}`]) {
                                                labelTooltip = `${element2.label}`;
                                            }
                                        });
                                    }
                                    COLORS_NODE_JOB.forEach((itemColor: any) => {
                                        if (itemColor.code === `${item[`${element1.val}`]}`) {
                                            selectedBackgroundColor = itemColor.colorUsed
                                        }
                                    })

                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>
                                        <Tooltip placement="left" title={`${labelTooltip}`}>

                                            <div style={{ backgroundColor: `${selectedBackgroundColor}`, height: '40px', width: '40px' }}>

                                            </div>
                                        </Tooltip>


                                    </p>;
                                } else if (element1.val === "batchId") {
                                    let dataReturn = `${item[`${element1.val}`]}`;
                                    if (props.stateOptions.optionsBatchFromAPIBatch.length > 0) {
                                        props.stateOptions.optionsBatchFromAPIBatch.forEach((element2: any) => {
                                            if (element2.value === item[`${element1.val}`]) {
                                                dataReturn = `${element2.label}`;
                                            }
                                        });
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                } else if (element1.val === "batchRegType") {
                                    let dataReturn = `${item[`${element1.val}`]}`;
                                    if (props.stateOptions.optionsBatchRegistrationTypeDetailCode.length > 0) {
                                        props.stateOptions.optionsBatchRegistrationTypeDetailCode.forEach((element2: any) => {
                                            if (element2.value === item[`${element1.val}`]) {
                                                dataReturn = `${element2.label}`;
                                            }
                                        });
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                } else if (element1.val === "batchExecutionType") {
                                    let dataReturn = `${item[`${element1.val}`]}`;
                                    if (props.stateOptions.optionsBatchExecutionTypeDetailCode.length > 0) {
                                        props.stateOptions.optionsBatchExecutionTypeDetailCode.forEach((element2: any) => {
                                            if (element2.value === item[`${element1.val}`]) {
                                                dataReturn = `${element2.label}`;
                                            }
                                        });
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                }
                                // else if (element1.val === "batchPreRequisite") {
                                //     let dataReturn = `${item[`${element1.val}`]}`;
                                //     let result = ''
                                //     if (props.stateOptions.optionsBatchFromAPIBatch.length > 0) {
                                //         if (dataReturn.toString().length > 0) {
                                //             let splitString = [...dataReturn.toString().split(',')];
                                //             if (splitString.length > 0) {
                                //                 splitString.forEach((item2: any) => {
                                //                     props.stateOptions.optionsBatchFromAPIBatch.forEach((element2: any) => {
                                //                         if (`${element2.value}` === item2) {
                                //                             result = result + element2.label + ', ';
                                //                         }
                                //                     });
                                //                 })
                                //             }
                                //         }
                                //     }
                                //     if (result === '') {
                                //         result = dataReturn;
                                //     }
                                //     return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(result)}</p>;
                                // }
                                else {
                                    return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                                }
                            })}
                        </a>
                    );
                })}
            </div>
        </div>
    );
}

export default TableSchedulerManagementDetail;
import { API, urlSelection } from '../../Services/API';
import { ButtonDelete, ButtonEdit, InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { DatePicker, DatePickerProps, Divider } from 'antd';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { handleStateAction, jobExecutionHistoryGlobalState } from '../../Store/JobExecutionHistory';
import { icon_search_white, undo_icon } from '../../Assets/Images/svg';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ExcelSchedulerExecution } from './ExcelSchedulerExecution';
import { InterfaceOptionsBatchProgram } from '../File/InterfaceOptionsBatchProgram';
import { InterfaceOptionsSchedulerExecution } from './InterfaceOptionsSchedulerExecution';
import { ModalBatchProgram } from '../File/ModalBatchProgram';
import ModalDraggableIeSchedulerExecution from './ModalDraggableIeSchedulerExecution';
import ModelSchedulerExecution from './ModelSchedulerExecution';
import { Page43Title } from '../../State/Menu/TitleMenu';
import { Page4JobSchedulerRoute } from '../../Routes';
import { RangePickerProps } from 'antd/es/date-picker';
import Select from 'react-select';
import { ShowModalInterface } from '../../Interface';
import { TableBatchProgram } from '../File/TableBatchProgram';
import { TableSchedulerExecution } from './TableSchedulerExecution';
import dayjs from 'dayjs';
import { inputStyleH25, labelStyle } from '../../Styles';
import moment from 'moment';

export const SchedulerExecution = ({ ...props }) => {
    const [firstHitApi, setFirstHitApi] = useState(true);
    // navigation and route
    const navigate = useNavigate();
    // global state
    const dispatchGlobal = useDispatch();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    const stateGlobal = useSelector(jobExecutionHistoryGlobalState);
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    // state
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    // params
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const batchProgId = searchParams.get('batchProgramId');
    const batchLastExec = searchParams.get('batchLastExec');
    const batchExecutionType = searchParams.get('batchExecutionType');
    const batchBusinessGroup = searchParams.get('batchBusinessGroup');
    const batchJobId = searchParams.get('batchJobId');

    const initFunctionCustom = async (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let selectedStartDate = stateGlobal.selectedStartDate !== '' ? `startDate=${stateGlobal.selectedStartDate}&` : '';
        let selectedEndDate = stateGlobal.selectedEndDate !== '' ? `endDate=${stateGlobal.selectedEndDate}&` : '';
        let selectedBatchStatus = stateGlobal.selectedBatchStatus !== '' ? `status=${stateGlobal.selectedBatchStatus}&` : '';
        let selectedBatchProgramId = stateGlobal.selectedBatchProgramId !== '' ? `batchProgramId=${stateGlobal.selectedBatchProgramId}&` : '';

        // let selectedBatchGroupPath = stateGlobal.selectedBatchGroupPath !== '' ? `batchGroupPath=${stateGlobal.selectedBatchGroupPath}&` : '';
        let SelectedBatchExecutionType = stateGlobal.SelectedBatchExecutionType !== '' ? `batchExecutionType=${stateGlobal.SelectedBatchExecutionType}&` : '';
        let selectedBatchBusinessGroup = stateGlobal.selectedBatchBusinessGroup !== '' ? `batchBusinessGroup=${stateGlobal.selectedBatchBusinessGroup}&` : '';
        let finalParameter = `${searchValue}${limit}${offset}${selectedStartDate}${selectedEndDate}${selectedBatchStatus}${selectedBatchProgramId}${selectedBatchBusinessGroup}${SelectedBatchExecutionType}`;
        let finalParameter2 = `${searchValue}${selectedStartDate}${selectedEndDate}${selectedBatchStatus}${selectedBatchProgramId}${selectedBatchBusinessGroup}${SelectedBatchExecutionType}`;
        if (firstHitApi) {
            finalParameter = finalParameter + `${batchJobId !== '' ? `batchJobId=${batchJobId}&` : ''}`;
            finalParameter2 = finalParameter2 + `${batchJobId !== '' ? `batchJobId=${batchJobId}&` : ''}`;
        }
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            })
                .then((response: any) => {
                    dispatch({ type: 'searchData', value: response.data });
                    dispatch({ type: 'totalPages', value: response.detail.totalPage });
                    dispatch({ type: 'totalData', value: response.detail.total });
                    setFirstHitApi(false)
                })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            })
                .then((response: any) => {
                    dispatch({ type: 'data', value: response.data });
                    dispatch({ type: 'totalPages', value: response.detail.totalPage });
                    dispatch({ type: 'totalData', value: response.detail.total });
                    setFirstHitApi(false)
                })
        }
    }

    // privilege code page access
    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    useEffect(() => {
        initFunctionCustom(false);
    }, [pagination.pageIndex]);

    useEffect(() => {
        setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
    }, [state.view]);


    useEffect(() => {
        if (batchLastExec !== null) {
            dispatchGlobal(handleStateAction({ name: 'selectedStartDate', value: batchLastExec !== null ? batchLastExec : '' }))
        }
    }, [batchLastExec, dispatchGlobal])

    useEffect(() => {
        if (batchProgId !== null) {
            dispatchGlobal(handleStateAction({ name: 'selectedBatchProgramId', value: batchProgId !== null ? batchProgId : '' }))
        }
    }, [batchProgId, dispatchGlobal])

    useEffect(() => {
        if (batchExecutionType !== null) {
            dispatchGlobal(handleStateAction({ name: 'SelectedBatchExecutionType', value: batchExecutionType !== null ? batchExecutionType : '' }))
        }
    }, [batchExecutionType, dispatchGlobal])

    useEffect(() => {
        if (batchBusinessGroup !== null) {
            dispatchGlobal(handleStateAction({ name: 'selectedBatchBusinessGroup', value: batchBusinessGroup !== null ? batchBusinessGroup : '' }))
        }
    }, [batchBusinessGroup, dispatchGlobal])

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsSchedulerExecution, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        initFunctionCustom(true);
    }, [state.search, stateGlobal.selectedStartDate, stateGlobal.selectedEndDate, stateGlobal.selectedBatchProgramId, stateGlobal.selectedBatchStatus, stateGlobal.selectedBatchGroupPath, stateGlobal.SelectedBatchExecutionType, stateGlobal.selectedBatchBusinessGroup, batchLastExec, batchProgId, dispatchGlobal]);

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelSchedulerExecution(state, stateOptions, response.data)
        })
    }

    //

    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelSchedulerExecution) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

    const customButtonAction = (item: any, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
            </div>
        );
    };


    const onOkStartDateTime = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;

            dispatchGlobal(handleStateAction({ name: 'selectedStartDate', value: localDate.toString().split(' ')[0] }))
            dispatchGlobal(handleStateAction({ name: 'selectedStartTime', value: timeNow }))

        }
    };
    const onOkEndDateTime = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchGlobal(handleStateAction({ name: 'selectedEndDate', value: localDate.toString().split(' ')[0] }))
            dispatchGlobal(handleStateAction({ name: 'selectedEndTime', value: timeNow }))
        }
    };

    const ComponentStartDate = useCallback(({ ...props }) => {
        const { stateGlobal, onOkStartDateTime } = props;
        return (
            <>
                {
                    dayjs(stateGlobal.selectedStartDate, ['YYYY-MM-DD']).isValid() === true ?
                        <DatePicker
                            defaultValue={dayjs(`${stateGlobal.selectedStartDate}`, ['YYYY-MM-DD'])}
                            className='flex-fill h-25px'
                            format="YYYY-MM-DD"
                            onOk={onOkStartDateTime}
                            onChange={onOkStartDateTime}
                        />
                        :
                        <DatePicker
                            className='flex-fill h-25px'
                            format="YYYY-MM-DD"
                            onOk={onOkStartDateTime}
                            onChange={onOkStartDateTime}
                        />

                }
            </>
        )
    }, [stateGlobal, dispatchGlobal])

    const ComponentEndDate = useCallback(({ ...props }) => {
        const { stateGlobal, onOkEndDateTime } = props;
        return (
            <>
                {
                    dayjs(stateGlobal.selectedEndDate, ['YYYY-MM-DD']).isValid() === true ?
                        <DatePicker
                            defaultValue={dayjs(`${stateGlobal.selectedEndDate}`, ['YYYY-MM-DD'])}
                            className='flex-fill h-25px'
                            format="YYYY-MM-DD"
                            onOk={onOkEndDateTime}
                            onChange={onOkEndDateTime}
                        />
                        :
                        <DatePicker
                            className='flex-fill h-25px'
                            format="YYYY-MM-DD"
                            onOk={onOkEndDateTime}
                            onChange={onOkEndDateTime}
                        />

                }
            </>
        )
    }, [stateGlobal, dispatchGlobal])

    const filterComponentPage = () => {
        let newOptionsBatchGroupPatch: any[] = []
        let dateNow = new Date().toISOString().slice(0, 10);
        stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName?.forEach((item1: any) => {
            stateOptions.optionsBatchExecutionTypeDetailCode?.forEach((item2: any) => {
                newOptionsBatchGroupPatch.push({
                    label: `${item1.label.toString().toLocaleUpperCase()}/${item2.label.toString().toLocaleUpperCase()}`,
                    value: `${item1.label.toString().toLocaleUpperCase()}/${item2.label.toString().toLocaleUpperCase()}`,
                })
            })
        })
        return (
            <>
                <div className="row container-all-select-custom pb-4 pt-1 mx-0 px-0">
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>START DATE</p>
                        <div className='d-flex justify-content-between'>
                            <ComponentStartDate stateGlobal={stateGlobal} onOkStartDateTime={onOkStartDateTime} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => dispatchGlobal(handleStateAction({ name: 'selectedStartDate', value: dateNow }))}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>END DATE</p>
                        <div className='d-flex justify-content-between'>
                            <ComponentEndDate stateGlobal={stateGlobal} onOkEndDateTime={onOkEndDateTime} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => dispatchGlobal(handleStateAction({ name: 'selectedEndDate', value: dateNow }))}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH PROGRAM ID</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsBatchFromAPIBatch2} value={stateOptions.optionsBatchFromAPIBatch2.filter((option: any) => option.value === stateGlobal.selectedBatchProgramId)} onChange={(e) => dispatchGlobal(handleStateAction({ name: 'selectedBatchProgramId', value: e.value }))} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => dispatchGlobal(handleStateAction({ name: 'selectedBatchProgramId', value: '' }))}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH STATUS</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsBatchSchedulerStatusUseCCDNameAndCodeDetail} value={stateOptions.optionsBatchSchedulerStatusUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateGlobal.selectedBatchStatus)} onChange={(e) => dispatchGlobal(handleStateAction({ name: 'selectedBatchStatus', value: e.value }))} />

                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => dispatchGlobal(handleStateAction({ name: 'selectedBatchStatus', value: '' }))}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH BUSINESS GROUP</p>
                        <div className='d-flex justify-content-between'>

                            <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsBusinessGroupNameUseCCDNameAndCodeDetail} value={stateOptions.optionsBusinessGroupNameUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateGlobal.selectedBatchBusinessGroup)} onChange={(e) => dispatchGlobal(handleStateAction({ name: 'selectedBatchBusinessGroup', value: e.value }))} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { dispatchGlobal(handleStateAction({ name: 'selectedBatchBusinessGroup', value: '' })) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH EXECUTION TYPE</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsBatchExecutionTypeUseCCDNameAndCodeDetail} value={stateOptions.optionsBatchExecutionTypeUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateGlobal.SelectedBatchExecutionType)} onChange={(e) => dispatchGlobal(handleStateAction({ name: 'SelectedBatchExecutionType', value: e.value }))} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { dispatchGlobal(handleStateAction({ name: 'SelectedBatchExecutionType', value: '' })) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH GROUP PATH</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill' options={newOptionsBatchGroupPatch} value={newOptionsBatchGroupPatch.filter((option: any) => option.value === stateGlobal.selectedBatchGroupPath)} onChange={(e) => dispatchGlobal(handleStateAction({ name: 'selectedBatchGroupPath', value: e.value }))} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => dispatchGlobal(handleStateAction({ name: 'selectedBatchGroupPath', value: '' }))}>
                                {undo_icon}
                            </div>
                        </div>
                    </div> */}

                    {/* <Divider className='my-2' />
                    <div className="col d-flex  align-items-end justify-content-end ">
                        <div className=' d-flex align-items-end justify-content-end' >
                            <button className="btn d-flex align-items-center px-4 py-3 bg-e8 h-50px " onClick={() => reloadDataComponent(true)}>
                                {icon_search_white}
                                <p className="s-1418 c-ff Lato-600 mx-2">Search</p>
                            </button>
                        </div>
                    </div> */}
                </div>
            </>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableSchedulerExecution
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                //
                editData={(item: any) => actionShowModal(item.eventId, item)}
                //
                currentPage={state.currentPage}
                //
                handleShowModal={(item: any) => {
                    navigate(`${Page4JobSchedulerRoute}?`)
                    // dispatch({ type: 'id', value: item.id })
                    // dispatch({ type: 'dataModal', value: item })
                    // dispatch({ type: 'visibleModalAddDelete', value: true })
                }}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <ModalBatchProgram
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableIeSchedulerExecution
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }


    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                Job Execution History
            </h1>
            {filterComponentPage()}
            <TableSchedulerExecution
                editData={(item: any) => actionShowModal(item.eventId, item)}
                state={state}
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                stateOptions={stateOptions}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                initFunctionCustom={initFunctionCustom}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
                dataModal={state.dataModal}
                currentPage={state.currentPage}
                addData={addDataComponent}
                handleShowModal={(item: any) => {
                    const url = `${Page4JobSchedulerRoute}?`;
                    window.open(url, '_blank');
                    // navigation(`${Page2JobSchedulerRoute}/details?batchExecutionType=${item.batchExecutionType === '' ? '' : item.batchExecutionType}&batchBusinessGroup=${item.batchBusinessGroup === '' ? '' : item.batchBusinessGroup}`)
                    // dispatch({ type: 'id', value: item.id })
                    // dispatch({ type: 'dataModal', value: item })
                    // dispatch({ type: 'visibleModalAddDelete', value: true })
                }}
            />
            <ModalBatchProgram
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
            {
                state.savedShowModal.map((element1: any) => {
                    return (
                        <ModalDraggableIeSchedulerExecution
                            key={element1.idModal}
                            id={element1.idModal}
                            index={element1.index}
                            idModal={element1.idModal}
                            state={state}
                            urlApi={state.urlApi}
                            showModal={element1.showModal}
                            reloadData={() => reloadDataComponent(false)}
                            actionShowModal={() => actionShowModal(element1.idModal, element1)}
                            actionIndexModal={() => actionIndexModal(element1.idModal)}
                            actionAfterUpdateItem={actionAfterUpdateItem}
                            itemModal={element1.itemModal}
                            stateOptions={stateOptions}
                        />
                    )
                }
                )
            }
        </>
    );

}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'batch/history',
    titlePage: Page43Title,
    privilegeAccess: { view: "191", add: "192", edit: "193", delete: "194", download: "195" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Batch TX Date", width: "wd-200", val: "batchTxDate" },
        { field: "Batch Execution Type", width: "wd-200", val: "batchExecType" },
        { field: "Batch Group Path", width: "wd-200", val: "batchGroupPath" },
        { field: "Batch Job Detail ID", width: "wd-200", val: "batchJobDetailId" },
        { field: "Batch Last Execution", width: "wd-200", val: "batchLastExec" },
        { field: "Batch Messagge", width: "wd-200", val: "batchMessagge" },
        { field: "Batch Program Id", width: "wd-200", val: "batchProgId" },
        { field: "Batch Status", width: "wd-200", val: "batchStatus" },
        { field: "Change Dttm", width: "wd-200", val: "chngDttm" },
        { field: "Change Program Id", width: "wd-200", val: "chngProgId" },
        { field: "Change User Id", width: "wd-200", val: "chngUserId" },
        { field: "Input Dttm", width: "wd-200", val: "inpDttm" },
        { field: "Input Progrram Id", width: "wd-200", val: "inpProgId" },
        { field: "Input User Id", width: "wd-200", val: "inpUserId" },
        { field: "Batch Log Id", width: "wd-200", val: "batchLogId" },
        { field: "Batch Log Name", width: "wd-200", val: "batchLogName" },
        { field: "Count", width: "wd-200", val: "count" },
    ],
    //
    complete_toggle: ["no", "batchTxDate", "batchExecType", "batchGroupPath", "batchJobDetailId", "batchLastExec", "batchMessagge", "batchProgId", "batchStatus", "chngDttm", "chngProgId", "chngUserId", "inpDttm", "inpProgId", "inpUserId", "batchLogId", "batchLogName", "count",],
    toggle: ["no", "batchTxDate", "batchExecType", "batchGroupPath", "batchJobDetailId", "batchLastExec", "batchMessagge", "batchProgId", "batchStatus", "chngDttm", "chngProgId", "chngUserId", "inpDttm", "inpProgId", "inpUserId", "batchLogId", "batchLogName", "count",],
    list_column_name: ["No.", "Batch TX Date", "Batch Execution Type", "Batch Group Path", "Batch Job Detail ID", "Batch Last Execution", "Batch Messagge", "Batch Program Id", "Batch Status", "Change Dttm", "Change Program Id", "Change User Id", "Input Dttm", "Input Program Id", "Input User Id", "Batch Log Id", "Batch Log Name", "Count"],
    //
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Batch Program Id',

    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 3900,
    dataLength: 0, // zIndex
    finalAPI: '',

    startTimeSelectedValue: '',
    endTimeSelectedValue: '',
};


// memberConnId ModelSchedulerExecution
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSchedulerExecution) => {
                        element1.id = element1.id;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSchedulerExecution) => {
                        element1.id = element1.id;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSchedulerExecution) => {
                        showDataResult.push(
                            { idModal: element1.id!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.id;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'startTimeSelectedValue':
            return {
                ...state,
                startTimeSelectedValue: action.value,
            };
        case 'endTimeSelectedValue':
            return {
                ...state,
                endTimeSelectedValue: action.value,
            };
        //
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}



import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelInterfaceField } from './ModelInterfaceField';
import { Page30Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalInterfaceField = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsElementTypeDetailCode, optionsFieldMappingDetailCode } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { interfaceFieldName, interfaceFieldDesc, interfaceFieldMappingCode, interfaceFieldType, interfaceFieldLength } = statePage.data
        const data = {
            interfaceFieldName: interfaceFieldName,
            interfaceFieldDesc: interfaceFieldDesc,
            interfaceFieldLength: parseInt(interfaceFieldLength),
            interfaceFieldType: interfaceFieldType,
            interfaceFieldMappingCode: interfaceFieldMappingCode,
        }
        const a = CheckAllFields([interfaceFieldName, interfaceFieldLength, interfaceFieldType, interfaceFieldMappingCode], ['Name', 'Length', 'Type', 'Mapping Code'])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>NAME</p>
                                <input value={statePage.data.interfaceFieldName} onChange={(e) => handleState('interfaceFieldName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <input value={statePage.data.interfaceFieldDesc} onChange={(e) => handleState('interfaceFieldDesc', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>type</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsElementTypeDetailCode} value={optionsElementTypeDetailCode.filter((option: any) => option.value === statePage.data.interfaceFieldType)} onChange={(e) => handleState('interfaceFieldType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>length</p>
                                <input type='number' min={0} value={statePage.data.interfaceFieldLength} onChange={(e) => handleState('interfaceFieldLength', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Mapping Code</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFieldMappingDetailCode} value={optionsFieldMappingDetailCode.filter((option: any) => option.value === statePage.data.interfaceFieldMappingCode)} onChange={(e) => handleState('interfaceFieldMappingCode', e.value)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page30Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInterfaceField({
        id: null,
        interfaceFieldId: null,
        interfaceFieldName: null,
        interfaceFieldDesc: null,
        interfaceFieldLength: null,
        interfaceFieldType: null,
        interfaceFieldMappingCode: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceField) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceField({
                    id: null,
                    interfaceFieldId: null,
                    interfaceFieldName: null,
                    interfaceFieldDesc: null,
                    interfaceFieldLength: null,
                    interfaceFieldType: null,
                    interfaceFieldMappingCode: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceField({
                    id: action.value.interfaceFieldId,
                    interfaceFieldId: action.value.interfaceFieldId,
                    interfaceFieldName: action.value.interfaceFieldName,
                    interfaceFieldDesc: action.value.interfaceFieldDesc,
                    interfaceFieldLength: action.value.interfaceFieldLength,
                    interfaceFieldType: action.value.interfaceFieldType,
                    interfaceFieldMappingCode: action.value.interfaceFieldMappingCode,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

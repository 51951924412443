

// RoutingFieldMapping

interface RoutingFieldMappingInterface {
    id: null | number | string;
    rifId: number | null;
    routeId: number | null;
    interfaceFieldId: number | null;
    rifMoc: string | null;
    rifStatus: string | null;
    rifValue: string | null;
    interfaceFieldName: string | null;
    interfaceFieldValue: string | null;


}

export class ModelRoutingFieldMapping {
    id: null | number | string;
    rifId: number | null;
    routeId: number | null;
    interfaceFieldId: number | null;
    rifMoc: string | null;
    rifStatus: string | null;
    rifValue: string | null;
    interfaceFieldName: string | null;
    interfaceFieldValue: string | null;


    constructor(value: RoutingFieldMappingInterface | null) {
        this.id = value?.id ?? null;
        this.rifId = value?.rifId ?? null;
        this.routeId = value?.routeId ?? null;
        this.interfaceFieldId = value?.interfaceFieldId ?? null;
        this.rifMoc = value?.rifMoc ?? null;
        this.rifStatus = value?.rifStatus ?? null;
        this.rifValue = value?.rifValue ?? null;
        this.interfaceFieldName = value?.interfaceFieldName ?? null;
        this.interfaceFieldValue = value?.interfaceFieldValue ?? null;
    }
}

import { useEffect, useReducer, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, differenceInDays, parse } from "date-fns";
import {
    MRT_ColumnDef,
} from 'material-react-table';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as echarts from 'echarts';
import { Button, IconButton, Modal, Zoom } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { changeStateMainDashboard, stateGlobalMainDashboard, handleStateAction } from '../../../Store/MainDashboardSlice';
import { NumberWithCommas, CheckUndefined } from '../../../Utils';
import { inputStyleH25, inputStyleH20, labelStyle, labelStyleWwithoutUppercase } from "../../../Styles";
import { arrowHeadDownIcon, arrowHeadUpIcon, errNotificationIcon, undo_icon } from "../../../Assets/Images/svg";
import { DatePickerCalendarIconOnly } from '../../../Components';
import { ModelTransactionMonitoring } from '../../TransactionStatic/Utils/ModelTransactionMonitoring';
import { ModelMainDashboardSummary } from '../Utils/ModelMainDashboardSummary';
import { ModelMainDashboardSummaryDeclined } from '../Utils/ModelMainDashboardSummaryDeclined';
import { MainPageTransactionMobileReactTable } from '../Components/MainPageTransactionMobileReactTable';
import { MainPageSummaryMobileReactTable } from '../Components/MainPageSummaryMobileReactTable';

export const MobileViewMainPage = ({ ...props }) => {

    const stateGlobal = useSelector(stateGlobalMainDashboard);
    const {
        dataPeriodNowTransactionList,
        dataPeriodNowSummary,
        dataPeriodNowSummaryDeclined,
        dataPeriodNowAcquirer,
        dataPeriodNowAcquirerDeclined,
        dataPeriodNowIssuer,
        dataPeriodNowIssuerDeclined,
        dataPeriodNowAcquirerIssuer,
        dataPeriodNowAcquirerIssuerDeclined,
        dataPeriodNowTotalCount,
        dataPeriodNowTotalAcquirer,
        dataPeriodNowTotalIssuer,
        dataPeriodNowTotal,
        dataPeriodNowApprovedCount,
        dataPeriodNowPreDeclinedCount,
        dataPeriodNowDeclinedCount,
        dataPeriodNowTimeoutCount,
        dataPeriodPrevSummary,
        dataPeriodPrevSummaryDeclined,
        dataPeriodPrevTotalCount,
        dataPeriodPrevApprovedCount,
        dataPeriodPrevPreDeclinedCount,
        dataPeriodPrevDeclinedCount,
        dataPeriodPrevTimeoutCount,
        dataInbound,
        dataOutbound,
        dataApi,
        dataTimeout,
        filterStartDate,
        filterEndDate,
        selectedCountryId,
        selectedCountryLabel,
        search,
        rangeDate,
        dataAcquirerSwitcherOptions,
        dataIssuerSwitcherOptions,
        selectedAcquirerSwitcherId,
        selectedAcquirerSwitcherLabel,
        selectedIssuerSwitcherId,
        selectedIssuerSwitcherLabel,
    } = stateGlobal;

    const {
        state,
        stateOptions,
        pagination,
        optionsDayGraph,
        optionsDayGraphWithHour,
        optionsSwitcherCountry,
        optionsTransactionStatus,
        setPagination,
        initFunctionCustom,
        handleStateComponent,
        handleStateGlobal,
        handleRowClick,
        handleScrollToTransaction,
        reloadDataComponent,
        countDifferencePercentage,
        dispatch
    } = props

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const isMobile = () => window.innerWidth <= 768;

    // graph inbound useEffect
    useEffect(() => {
        // Initialize the chart
        const chartDom = document.getElementById('line-chart-inbound-mobile');
        const myChart = echarts.init(chartDom);

        // Initialize chart data
        const today = new Date();
        let rangeTime: any = [];
        let dailyTotals: { [key: string]: number } = {};

        let legendCountries: any = [];
        let seriesData: any = [];
        let seriesDataPerCountry: any = [];

        // Populate x-axis time
        const graphInboundDays = differenceInDays(filterEndDate, filterStartDate);

        if (graphInboundDays > 0) {    // case when more than one day
            const startDate = new Date();
            const endDate = parse(filterEndDate, 'yyyy-MM-dd', new Date());

            startDate.setTime(endDate.getTime() - (graphInboundDays - 1) * 24 * 60 * 60 * 1000);

            for (let i = 0; i <= graphInboundDays; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + (i));
                rangeTime.push(currentDate.toISOString().slice(0, 10));
            }
        } else {    // case when only one day
            const currentHour = filterEndDate !== format(today, 'yyyy-MM-dd') ? 24 : today.getHours() + 1;

            for (let i = 0; i < currentHour; i++) {
                const currentDate = new Date();
                currentDate.setHours(i, 0, 0, 0);

                const hourString = currentDate.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                }).replace('.', ':');

                rangeTime.push(hourString);
            }
        }

        // Populate value
        if (graphInboundDays > 0) {     // case when more than one day
            dataInbound?.forEach((issuer: any, index: number) => {
                dailyTotals = {};
                seriesDataPerCountry = [];

                issuer?.graphData?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);
                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: string) => {
                    if (dailyTotals[date]) {
                        seriesDataPerCountry.push(dailyTotals[date]);
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: issuer?.name
                })

                seriesData.push({
                    name: issuer?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        } else {    // case when only one day
            dataInbound?.forEach((country: any, index: number) => {
                seriesDataPerCountry = [];

                rangeTime.forEach((time: any) => {
                    const matchingDataInbound = country?.graphData?.find((item: any) => {
                        const inboundTimePerCountry = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return inboundTimePerCountry === time;
                    });

                    if (matchingDataInbound) {
                        seriesDataPerCountry.push(Number(matchingDataInbound.count));
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: country?.name
                });

                seriesData.push({
                    name: country?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        }

        // Define the chart options
        const option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: legendCountries
            },
            grid: {
                left: '3%',
                right: '6%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: rangeTime
            },
            yAxis: {
                type: 'value'
            },
            series: seriesData
        };

        // Set the chart options
        myChart.setOption(option);

        // Resize the chart on window resize
        window.addEventListener('resize', () => {
            myChart.resize();
        });

        // Cleanup function to destroy chart when component unmounts
        return () => {
            window.removeEventListener('resize', () => myChart.resize());
            myChart.dispose();
        };
    }, [dataInbound]);

    // graph outbound useEffect
    useEffect(() => {
        // Initialize the chart
        const chartDom = document.getElementById('line-chart-outbound-mobile');
        const myChart = echarts.init(chartDom);

        // Initialize chart data
        const today = new Date();
        let rangeTime: any = [];
        let dailyTotals: { [key: string]: number } = {};

        let legendCountries: any = [];
        let seriesData: any = [];
        let seriesDataPerCountry: any = [];

        // Populate x-axis time
        const graphOutboundDays = differenceInDays(filterEndDate, filterStartDate);

        if (graphOutboundDays > 0) {    // case when more than one day
            const startDate = new Date();
            const endDate = parse(filterEndDate, 'yyyy-MM-dd', new Date())

            startDate.setTime(endDate.getTime() - (graphOutboundDays - 1) * 24 * 60 * 60 * 1000);

            for (let i = 0; i <= graphOutboundDays; i++) {
                const currentDate = new Date(startDate);
                currentDate.setDate(startDate.getDate() + i);
                rangeTime.push(currentDate.toISOString().slice(0, 10));
            }
        } else {    // case when only one day
            const currentHour = filterEndDate !== format(today, 'yyyy-MM-dd') ? 24 : today.getHours() + 1;

            for (let i = 0; i < currentHour; i++) {
                const currentDate = new Date();
                currentDate.setHours(i, 0, 0, 0);

                const hourString = currentDate.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                }).replace('.', ':');

                rangeTime.push(hourString);
            }
        }

        // Populate value
        if (graphOutboundDays > 0) {     // case when more than one day
            dataOutbound?.forEach((country: any, index: number) => {
                dailyTotals = {};
                seriesDataPerCountry = [];

                country?.graphData?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);
                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: string) => {
                    if (dailyTotals[date]) {
                        seriesDataPerCountry.push(dailyTotals[date]);
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: country?.name
                });

                seriesData.push({
                    name: country?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        } else {
            dataOutbound?.forEach((country: any, index: number) => {
                seriesDataPerCountry = [];

                rangeTime.forEach((time: any) => {
                    const matchingDataOutbound = country?.graphData?.find((item: any) => {
                        const outboundTimePerCountry = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return outboundTimePerCountry === time;
                    });

                    if (matchingDataOutbound) {
                        seriesDataPerCountry.push(Number(matchingDataOutbound.count));
                    } else {
                        seriesDataPerCountry.push(0);
                    }
                });

                legendCountries.push({
                    name: country?.name
                });

                seriesData.push({
                    name: country?.name,
                    type: 'line',
                    data: seriesDataPerCountry,
                    color: index < 10 ? state.lineChartColors[index] : ''
                });
            })
        }

        // Define the chart options
        const option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: legendCountries
            },
            grid: {
                left: '3%',
                right: '6%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: rangeTime
            },
            yAxis: {
                type: 'value'
            },
            series: seriesData
        };

        // Set the chart options
        myChart.setOption(option);

        // Resize the chart on window resize
        window.addEventListener('resize', () => {
            myChart.resize();
        });

        // Cleanup function to destroy chart when component unmounts
        return () => {
            window.removeEventListener('resize', () => myChart.resize());
            myChart.dispose();
        };
    }, [dataOutbound]);

    // graph API useEffect
    useEffect(() => {
        if (selectedCountryId === '410') {
            // Initialize the chart
            const chartDom = document.getElementById('line-chart-api-mobile');
            const myChart = echarts.init(chartDom);

            // Initialize chart data
            const today = new Date();
            let rangeTime: any = [];
            let dataCountApiTotal: any = [];
            let dataCountApiFinancial: any = [];
            let dailyTotals: { [key: string]: number } = {}

            // Populate x-axis time
            if (state.graphAPIDays > 1) {    // case when 7, 14, 30 days (daily)
                const startDate = new Date();
                startDate.setDate(today.getDate() - (state.graphAPIDays));

                for (let i = 0; i <= state.graphAPIDays; i++) {
                    const currentDate = new Date(startDate);
                    currentDate.setDate(startDate.getDate() + i);
                    rangeTime.push(currentDate.toISOString().slice(0, 10));
                }
            } else {    // case when today, yesterday (hourly)
                const currentHour = state.graphAPIDays == 1 ? 24 : today.getHours() + 1;

                for (let i = 0; i < currentHour; i++) {
                    const currentDate = new Date();
                    currentDate.setHours(i, 0, 0, 0);

                    const hourString = currentDate.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    }).replace('.', ':');

                    rangeTime.push(hourString);
                }
            }

            // Populate value
            if (state.graphAPIDays > 1) {    // case when 7, 14, 30 days (daily)
                dataApi.total?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);

                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: any) => {
                    if (dailyTotals[date]) {
                        dataCountApiTotal.push(dailyTotals[date]);
                    } else {
                        dataCountApiTotal.push(0);
                    }
                });

                // reset var to calculate financial
                dailyTotals = {}

                dataApi.financial?.forEach((item: any) => {
                    const date = item.date.slice(0, 10);

                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: any) => {
                    if (dailyTotals[date]) {
                        dataCountApiFinancial.push(dailyTotals[date]);
                    } else {
                        dataCountApiFinancial.push(0);
                    }
                });

            } else {    // case when today, yesterday (hourly)
                rangeTime.forEach((time: any) => {
                    const matchingDataTotal = dataApi.total?.find((item: any) => {
                        const apiTime = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return apiTime === time;
                    });

                    const matchingDataFinancial = dataApi.financial?.find((item: any) => {
                        const apiTime = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return apiTime === time;
                    });

                    if (matchingDataTotal) {
                        dataCountApiTotal.push(Number(matchingDataTotal.count));
                    } else {
                        dataCountApiTotal.push(0);
                    }

                    if (matchingDataFinancial) {
                        dataCountApiFinancial.push(Number(matchingDataFinancial.count));
                    } else {
                        dataCountApiFinancial.push(0);
                    }
                });
            }

            // Define the chart options
            const option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['Total API Call', 'Financial']
                },
                grid: {
                    left: '3%',
                    right: '6%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: rangeTime
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Total API Call',
                        type: 'line',
                        data: dataCountApiTotal,
                        color: '#FF5D5D'
                    },
                    {
                        name: 'Financial',
                        type: 'line',
                        data: dataCountApiFinancial,
                        color: '#1363DF'
                    },
                ]
            };

            // Set the chart options
            myChart.setOption(option);

            // Resize the chart on window resize
            window.addEventListener('resize', () => {
                myChart.resize();
            });

            // Cleanup function to destroy chart when component unmounts
            return () => {
                window.removeEventListener('resize', () => myChart.resize());
                myChart.dispose();
            };
        }
    }, [dataApi]);

    // graph timeout useEffect
    useEffect(() => {
        if (selectedCountryId === '410') {
            // Initialize the chart
            const chartDom = document.getElementById('line-chart-timeout-mobile');
            const myChart = echarts.init(chartDom);

            // Initialize chart data
            const today = new Date();
            let rangeTime: any = [];
            let dataCount: any = [];
            let dailyTotals: { [key: string]: number } = {}

            // Populate x-axis time
            if (state.graphTimeoutDays > 1) {    // case when 7, 14, 30 days
                const startDate = new Date();
                startDate.setDate(today.getDate() - (state.graphTimeoutDays));

                for (let i = 0; i <= state.graphTimeoutDays; i++) {
                    const currentDate = new Date(startDate);
                    currentDate.setDate(startDate.getDate() + i);
                    rangeTime.push(currentDate.toISOString().slice(0, 10));
                }
            } else {    // case when today, yesterday (hourly)
                const currentHour = state.graphTimeoutDays == 1 ? 24 : today.getHours() + 1;

                for (let i = 0; i < currentHour; i++) {
                    const currentDate = new Date();
                    currentDate.setHours(i, 0, 0, 0);

                    const hourString = currentDate.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                    }).replace('.', ':');

                    rangeTime.push(hourString);
                }
            }

            // Populate value
            if (state.graphTimeoutDays > 1) {    // case when 7, 14, 30 days
                dataTimeout.forEach((item: any) => {
                    const date = item.date.slice(0, 10);

                    if (dailyTotals[date]) {
                        dailyTotals[date] += Number(item.count);
                    } else {
                        dailyTotals[date] = Number(item.count);
                    }
                });

                rangeTime.forEach((date: any) => {
                    if (dailyTotals[date]) {
                        dataCount.push(dailyTotals[date]);
                    } else {
                        dataCount.push(0);
                    }
                });
            } else {    // case when today, yesterday (hourly)
                rangeTime.forEach((time: any) => {
                    const matchingDataTimeout = dataTimeout?.find((item: any) => {
                        const apiTime = new Date(item.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                        }).replace('.', ':');

                        return apiTime === time;
                    });

                    if (matchingDataTimeout) {
                        dataCount.push(Number(matchingDataTimeout.count));
                    } else {
                        dataCount.push(0);
                    }
                });
            }

            // Define the chart options
            const option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['Timeout']
                },
                grid: {
                    left: '3%',
                    right: '6%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: rangeTime
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Timeout',
                        type: 'line',
                        stack: 'Total',
                        data: dataCount,
                        color: '#FF5D5D'
                    },
                ]
            };

            // Set the chart options
            myChart.setOption(option);

            // Resize the chart on window resize
            window.addEventListener('resize', () => {
                myChart.resize();
            });

            // Cleanup function to destroy chart when component unmounts
            return () => {
                window.removeEventListener('resize', () => myChart.resize());
                myChart.dispose();
            };
        }
    }, [dataTimeout]);

    const columnsTransactionList = useMemo<MRT_ColumnDef<ModelTransactionMonitoring>[]>(
        () => [
            {
                accessorKey: 'productType', // product type
                header: 'Product Type',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.productType}`
            },
            {
                accessorKey: 'acquirerSwitcherIdCode', // acquirer switcher
                header: 'Acquirer Switcher',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.length > 0) {
                        stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.acquirerSwitcherIdCode) {
                                data = optionsItem.label;
                            }
                        });
                    }
                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'issuerSwitcherIdCode', // issuer switcher
                header: 'Issuer Switcher',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.length > 0) {
                        stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.issuerSwitcherIdCode) {
                                data = optionsItem.label;
                            }
                        });
                    }
                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'walletId', // wallet name
                header: 'Wallet Name',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsWalletInstitutionIDUseExtAndDetail.length > 0) {
                        stateOptions.optionsWalletInstitutionIDUseExtAndDetail.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.walletId) {
                                data = optionsItem.label;
                            }
                        });
                    }
                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'productFeatureName', // product feature
                header: 'Product Feature',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.productFeatureName}`
            },
            {
                accessorKey: 'transAmount', // total amount
                header: 'Total Amount',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    if ((row.original.transAmount !== "") && (row.original.transCurrency !== "")) { // if trans amount or currency is empty, return "-"
                        return `${row.original.transCurrency} ${NumberWithCommas(row.original.transAmount)}`
                    } else {
                        return `-`
                    }
                }
            },
            {
                accessorKey: 'transCount', // transaction count
                header: 'Transaction Count',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.transCount}`
            },
            {
                accessorKey: 'transStatus', // status
                header: 'Status',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    return (
                        <p className="fw-bold"
                            style={{
                                color: state.isRowHovered !== row.index
                                    ? row.original.transStatus === "APPROVED" ? '#50ae33' : '#e83d44'
                                    : '#ffffff'
                            }}
                        >
                            {row.original.transStatus}
                        </p>
                    )
                }
            },
            {
                accessorKey: 'fullCreateDate', // last transaction time
                header: 'Last Transaction Time',
                maxSize: 150,
                Cell: ({ row }: any) => `${row.original.fullCreateDate}`
            },
        ],
        [state.isRowHovered, stateOptions],
    );

    const columnsTransactionSummary = useMemo<MRT_ColumnDef<ModelMainDashboardSummary>[]>(
        () => [
            {
                accessorKey: 'acqSwitchCountry', // country
                header: 'Country',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.acqSwitchCountry}`,
                Footer: ''
            },
            {
                accessorKey: 'approvedTrans', // transactions
                header: 'Transactions',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.approvedTrans + row.original.declinedTrans + row.original.timeoutTrans}`,
                Footer: (dataPeriodNowTotalCount > 0) ? dataPeriodNowTotalCount : ''
            },
            {
                accessorKey: 'acqSwitchAmountTrans', // transaction amount
                header: 'Transaction Amount',
                maxSize: 150,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.currencyName} ${NumberWithCommas(row.original.amountTrans)}`,
                muiTableBodyCellProps: ({ row }: any) => ({
                    sx: {
                        fontSize: '12px',
                        fontFamily: 'system-ui, sans-serif',
                        borderRight: '1px solid rgba(218, 217, 219, .7)',
                        color: row.original.prodFeatureName !== "QR Refund" ? 'inherit' : '#e83d44'
                    }
                }),
                Footer: () => (
                    <>
                        {[...(dataPeriodNowTotal?.totalIssAmount || []), ...(dataPeriodNowTotal?.totalAcqAmount || [])].map((totalAmountByCurrency: any, index: number) => (
                            <p key={index}>
                                {`${totalAmountByCurrency.currency} ${NumberWithCommas(totalAmountByCurrency.amount)}`}
                            </p>
                        ))}
                    </>
                )

            },
            {
                accessorKey: 'approvalTransRate', // approval rate
                header: 'Approval Rate',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.approvalTransRate}%`,
                Footer: (dataPeriodNowTotalCount > 0) ? `${dataPeriodNowTotal.totalApprovalTransRate}%` : ''
            },
        ],
        [stateGlobal]
    );

    const columnsTransactionSummaryDeclined = useMemo<MRT_ColumnDef<ModelMainDashboardSummaryDeclined>[]>(
        () => [
            {
                accessorKey: 'acqSwitchCountry', // country
                header: 'Country',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.acqSwitchCountry}`,
                Footer: ''
            },
            {
                accessorKey: 'transCount', // transactions
                header: 'Transactions',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.transCount}`,
                Footer: (dataPeriodNowDeclinedCount > 0 || dataPeriodNowTimeoutCount > 0) ? `${dataPeriodNowDeclinedCount + dataPeriodNowTimeoutCount}` : ''
            },
            {
                accessorKey: 'acqSwitchAmountTrans', // transaction amount
                header: 'Transaction Amount',
                maxSize: 150,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.currencyName} ${NumberWithCommas(row.original.amountTrans)}`,
                muiTableBodyCellProps: ({ row }: any) => ({
                    sx: {
                        fontSize: '12px',
                        fontFamily: 'system-ui, sans-serif',
                        borderRight: '1px solid rgba(218, 217, 219, .7)',
                        color: row.original.prodFeatureName !== "QR Refund" ? 'inherit' : '#e83d44'
                    }
                }),
                Footer: (
                    dataPeriodNowTotal?.totalAcqDeclinedTrans > 0 || dataPeriodNowTotal?.totalAcqTimeoutTrans > 0 || dataPeriodNowTotal?.totalAcqPreDeclinedTrans > 0
                    || dataPeriodNowTotal?.totalIssDeclinedTrans > 0 || dataPeriodNowTotal?.totalIssTimeoutTrans > 0 || dataPeriodNowTotal?.totalIssPreDeclinedTrans > 0
                ) ?
                    () => (
                        <>
                            {[...(dataPeriodNowTotal?.totalIssDecAmount || []), ...(dataPeriodNowTotal?.totalAcqDecAmount || [])].map((totalAmountByCurrency: any, index: number) => (
                                <p key={index}>
                                    {`${totalAmountByCurrency.currency} ${NumberWithCommas(totalAmountByCurrency.amount)}`}
                                </p>
                            ))}
                        </>
                    ) : ''
            },
            {
                accessorKey: 'responseCodeName', // reason
                header: 'Reason',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.responseCodeName}`,
                Footer: ''
            },
        ],
        [stateGlobal],
    );

    return (
        <>
            <h1 className="Lato-300 normal s-1620 py-2">Transaction Statistic New</h1>

            {/* header */}
            <div className='d-flex justify-content-between align-items-center'>
                <div id="header-total-data" className='d-flex align-items-center my-2'>
                    <p className='Lato-400 s-1418 mb-0 me-2'>Date</p>
                    <p className='Lato-400 s-1418 mb-0 me-1 fw-bold'>{`${filterStartDate}`}</p>
                    <p className='Lato-400 s-1418 mb-0 me-1 '>-</p>
                    <p className='Lato-400 s-1418 mb-0 fw-bold'>{`${filterEndDate}`}</p>
                </div>
                <div id="header-filter-mobile" className='w-50 d-flex justify-content-end align-items-center'>
                    {/* filter window */}
                    <div>
                        <IconButton onClick={handleOpen}>
                            <FilterListIcon />
                        </IconButton>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="filter-modal-title"
                            aria-describedby="filter-modal-description"
                            closeAfterTransition
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Zoom in={open}>
                                <div
                                    className="modal-content p-3 bg-white"
                                    style={modalStyle}
                                >
                                    <div className='filter-container'>
                                        <div id="filter-header" className='filter-header d-flex justify-content-between align-items-center border-bottom pb-2'>
                                            <div id="close-button">
                                                <button
                                                    className='btn d-flex h-30px mx-1 mr-100px'
                                                    onClick={handleClose}
                                                >
                                                    <svg
                                                        style={{
                                                            width: '18px',
                                                            height: '18px',
                                                        }}
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6.225 4.811a1 1 0 011.414 0L12 9.172l4.361-4.361a1 1 0 111.414 1.414L13.414 10.586l4.361 4.361a1 1 0 01-1.414 1.414L12 12l-4.361 4.361a1 1 0 01-1.414-1.414l4.361-4.361-4.361-4.361a1 1 0 010-1.414z"
                                                            fill="#292929"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>

                                            <h5 className='m-0'>Filters</h5>

                                            <div id='reset-filter-button' style={{ width: '40px' }}>
                                                {/* <button className='btn d-flex h-30px mx-1 mr-100px' onClick={handleResetFilter}>
                                        <svg style={{ width: '18px', height: '18px',}} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z" fill="#292929"/>
                                        </svg>
                                    </button> */}
                                            </div>
                                        </div>

                                        <div id='filter-section' className='filter-section mt-3'>
                                            <div className='row d-flex align-items-center'>
                                                <div className="col-4">
                                                    <p className={labelStyleWwithoutUppercase}>
                                                        Start Date
                                                    </p>
                                                </div>

                                                <div className='col-8 d-flex flex-column'>
                                                    <DatePicker
                                                        selected={state.filterStartDate}
                                                        className="form-control flex-fill h-25px w-100 s-1418"
                                                        wrapperClassName="w-100 h-100"
                                                        dateFormat="yyyy-MM-dd"
                                                        timeFormat="HH:mm"
                                                        showYearDropdown
                                                        maxDate={new Date()}
                                                        onChange={(date: any) => handleStateComponent('filterStartDate', format(date, 'yyyy-MM-dd'))}
                                                    />
                                                    <DatePickerCalendarIconOnly />
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className="col-4">
                                                    <p className={labelStyleWwithoutUppercase}>
                                                        End Date
                                                    </p>
                                                </div>
                                                <div className='col-8 d-flex flex-column'>
                                                    <DatePicker
                                                        selected={state.filterEndDate}
                                                        className="form-control flex-fill w-100 h-25px s-1418"
                                                        wrapperClassName="w-100 h-100"
                                                        dateFormat="yyyy-MM-dd"
                                                        timeFormat="HH:mm"
                                                        showYearDropdown
                                                        maxDate={new Date()}
                                                        onChange={(date: any) => handleStateComponent("filterEndDate", format(date, 'yyyy-MM-dd'))}
                                                    />
                                                    <DatePickerCalendarIconOnly />

                                                    {/* {state.filterErrorMessage !== '' && (
                                                        <div className="">
                                                            <p className='text-danger mt-1 s-1418'>
                                                                {state.filterErrorMessage}
                                                            </p>
                                                        </div>
                                                    )} */}
                                                </div>
                                            </div>
                                            <div className='row d-flex' style={{ marginTop: '2px' }}>
                                                <div className="col-4">
                                                    <p className={labelStyleWwithoutUppercase}>
                                                        Country
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <Select
                                                        className="flex-fill"
                                                        styles={inputStyleH25}
                                                        isDisabled={!state.isRoleBCAP}
                                                        options={stateOptions.optionsSwitcherCountry}
                                                        value={stateOptions.optionsSwitcherCountry.filter((option: any) => option.value === state.selectedCountryId)}
                                                        onChange={(e) => {
                                                            handleStateComponent("selectedCountryId", e?.value)
                                                            handleStateComponent("selectedCountryLabel", e?.label)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {state.filterErrorMessage !== "" ? (
                                            <div className="col-12 pl-0">
                                                <div className="d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit mt-2">
                                                    {errNotificationIcon}
                                                    <p className="px-2 s-1418 Lato-600 c-e87">{state.filterErrorMessage}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <button
                                            className="btn btn-danger w-100 py-2 mt-3"
                                            disabled={state.filterErrorMessage !== ''}
                                            onClick={(e) => {
                                                handleClose();
                                                handleStateGlobal("filterStartDate", state.filterStartDate)
                                                handleStateGlobal("filterEndDate", state.filterEndDate)
                                                handleStateGlobal("selectedCountryId", state.selectedCountryId)
                                                handleStateGlobal("selectedCountryLabel", state.selectedCountryLabel)
                                                // handleStateGlobal("selectedAcquirerSwitcherId", state.selectedAcquirerSwitcherId)
                                                // handleStateGlobal("selectedIssuerSwitcherId", state.selectedIssuerSwitcherId)
                                                // handleStateGlobal("selectedAcquirerSwitcherLabel", state.selectedAcquirerSwitcherLabel)
                                                // handleStateGlobal("selectedIssuerSwitcherLabel", state.selectedIssuerSwitcherLabel)
                                            }}
                                        >
                                            <p className="s-1620 tex-uppercase c-ff Lato-600 mx-2">
                                                SEARCH
                                            </p>
                                        </button>

                                    </div>
                                </div>
                            </Zoom>
                        </Modal>
                    </div>
                </div>
            </div>

            {/* summary highlight */}
            <div id='summary-highlight' className='row d-flex justify-content-between'>
                <div className='col-3 px-0'>
                    <div className='p-1 bd-d4 br-10 mb-2'>
                        <p className='py-1 s-1418 fw-bold'>{dataPeriodNowTotalCount}</p>
                        <p className='s-1418'>Total Transactions</p>
                    </div>
                </div>
                <div className='col-2 px-0'>
                    <div className='p-1 bd-d4 br-10 mb-2'>
                        <div className='d-flex py-1 align-items-end'>
                            <p className='me-1 s-1418 fw-bold'>{dataPeriodNowApprovedCount}</p>
                            <div className='d-flex px-1 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowApprovedCount > dataPeriodPrevApprovedCount
                                    ? '#55b937'
                                    : dataPeriodNowApprovedCount < dataPeriodPrevApprovedCount
                                        ? '#e83d44'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowApprovedCount !== dataPeriodPrevApprovedCount)
                                    ? ((dataPeriodNowApprovedCount > dataPeriodPrevApprovedCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1214 c-ff'>{countDifferencePercentage(dataPeriodNowApprovedCount, dataPeriodPrevApprovedCount)}%</p>
                            </div>
                        </div>
                        <p className='s-1418'>Approved</p>
                    </div>
                </div>
                <div className='col-2 px-0'>
                    <div className='p-1 bd-d4 br-10'>
                        <div className='d-flex py-1 align-items-end'>
                            <p className='me-1 s-1418 fw-bold'>{dataPeriodNowPreDeclinedCount}</p>
                            <div className='d-flex px-1 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowPreDeclinedCount > dataPeriodPrevPreDeclinedCount
                                    ? '#e83d44'
                                    : dataPeriodNowPreDeclinedCount < dataPeriodPrevPreDeclinedCount
                                        ? '#55b937'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowPreDeclinedCount !== dataPeriodPrevPreDeclinedCount)
                                    ? ((dataPeriodNowPreDeclinedCount > dataPeriodPrevPreDeclinedCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1214 c-ff'>{countDifferencePercentage(dataPeriodNowPreDeclinedCount, dataPeriodPrevPreDeclinedCount)}%</p>
                            </div>
                        </div>
                        <p className='s-1418'>Pre-Declined</p>
                    </div>
                </div>
                <div className='col-2 px-0'>
                    <div className='p-1 bd-d4 br-10'>
                        <div className='d-flex py-1 align-items-end'>
                            <p className='me-1 s-1418 fw-bold'>{dataPeriodNowDeclinedCount}</p>
                            <div className='d-flex px-1 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowDeclinedCount > dataPeriodPrevDeclinedCount
                                    ? '#e83d44'
                                    : dataPeriodNowDeclinedCount < dataPeriodPrevDeclinedCount
                                        ? '#55b937'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowDeclinedCount !== dataPeriodPrevDeclinedCount)
                                    ? ((dataPeriodNowDeclinedCount > dataPeriodPrevDeclinedCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1214 c-ff'>{countDifferencePercentage(dataPeriodNowDeclinedCount, dataPeriodPrevDeclinedCount)}%</p>
                            </div>
                        </div>
                        <p className='s-1418'>Declined</p>
                    </div>
                </div>
                <div className='col-2 px-0'>
                    <div className='p-1 bd-d4 br-10'>
                        <div className='d-flex py-1 align-items-end'>
                            <p className='me-1 s-1418 fw-bold'>{dataPeriodNowTimeoutCount}</p>
                            <div className='d-flex px-1 br-50 align-items-center gap-1' style={{
                                backgroundColor: dataPeriodNowTimeoutCount > dataPeriodPrevTimeoutCount
                                    ? '#e83d44'
                                    : dataPeriodNowTimeoutCount < dataPeriodPrevTimeoutCount
                                        ? '#55b937'
                                        : '#7f7f7f'
                            }}>
                                {(dataPeriodNowTimeoutCount !== dataPeriodPrevTimeoutCount)
                                    ? ((dataPeriodNowTimeoutCount > dataPeriodPrevTimeoutCount) ? arrowHeadUpIcon : arrowHeadDownIcon)
                                    : ''}
                                <p className='s-1214 c-ff'>{countDifferencePercentage(dataPeriodNowTimeoutCount, dataPeriodPrevTimeoutCount)}%</p>
                            </div>
                        </div>
                        <p className='s-1418'>Timeout</p>
                    </div>
                </div>
            </div>

            {/* table summary */}
            <div>
                <div className='mt-2' onClick={handleScrollToTransaction}>
                    <h1 className='Lato-300 normal s-1420 pb-2 mb-0'>
                        Transaction Summary ({selectedCountryLabel} Inbound & Outbound)
                    </h1>
                    <MainPageSummaryMobileReactTable
                        data={dataPeriodNowAcquirerIssuer}
                        columns={columnsTransactionSummary}
                        state={state}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleStateComponent={handleStateComponent}
                    />
                </div>
            </div>

            <div className='mt-2' onClick={handleScrollToTransaction}>
                <h1 className='Lato-300 normal s-1420 pb-2 mb-0'>
                    Declined Transaction Summary ({selectedCountryLabel} Inbound & Outbound)
                </h1>
                <MainPageSummaryMobileReactTable
                    data={dataPeriodNowAcquirerIssuerDeclined}
                    columns={columnsTransactionSummaryDeclined}
                    state={state}
                    pagination={pagination}
                    setPagination={setPagination}
                    handleStateComponent={handleStateComponent}
                />
            </div>

            {/* transaction graphs */}
            <div className='w-100'>
                <div className='row d-flex justify-content-start align-items-center mt-3'>
                    <h1 className='col-3 Lato-300 normal s-1420 mb-0 px-0'>{selectedCountryLabel} Inbound</h1>
                    <Select
                        className="col-4"
                        styles={inputStyleH20}
                        options={optionsTransactionStatus}
                        value={optionsTransactionStatus.filter((option: any) => option.value === state.graphInboundStatus)}
                        onChange={(e) => {
                            handleStateComponent("graphInboundStatus", e?.value)
                        }}
                    />
                </div>
                <div id="line-chart-inbound-mobile" style={{ width: '100%', height: '200px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
            </div>

            <div className='w-100'>
                <div className='row d-flex justify-content-start align-items-center mt-3'>
                    <h1 className='col-3 Lato-300 normal s-1420 mb-0 px-0'>{selectedCountryLabel} Outbound</h1>
                    <Select
                        className="col-4"
                        styles={inputStyleH20}
                        options={optionsTransactionStatus}
                        value={optionsTransactionStatus.filter((option: any) => option.value === state.graphOutboundStatus)}
                        onChange={(e) => {
                            handleStateComponent("graphOutboundStatus", e?.value)
                        }}
                    />
                </div>
                <div id="line-chart-outbound-mobile" style={{ width: '100%', height: '200px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
            </div>

            {/* table list */}
            <div id='transaction-list-table' className='my-2'>
                <h1 className='Lato-300 normal s-1420 py-2 mb-0'>Transaction List</h1>
                <MainPageTransactionMobileReactTable
                    data={dataPeriodNowTransactionList}
                    columns={columnsTransactionList}
                    state={state}
                    pagination={pagination}
                    setPagination={setPagination}
                    handleStateComponent={handleStateComponent}
                    handleRowClick={handleRowClick}
                />
            </div>

            {/* api & timeout graphs */}
            {selectedCountryId === '410' && (
                <>
                    <h1 className="Lato-300 normal s-1620 mb-0 pt-2">API/Timeout Status</h1>
                    <div className='w-100'>
                        <div className='row d-flex justify-content-start align-items-center mt-3'>
                            <h1 className='col-3 Lato-300 normal s-1420 mb-0 px-0'>API Count</h1>
                            <Select
                                className="col-4"
                                styles={inputStyleH20}
                                options={optionsDayGraphWithHour}
                                value={optionsDayGraphWithHour.filter((option: any) => option.value === state.graphAPIDays)}
                                onChange={(e) => {
                                    handleStateComponent("graphAPIDays", e?.value)
                                }}
                            />
                        </div>
                        <div id="line-chart-api-mobile" style={{ width: '100%', height: '200px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
                    </div>

                    <div className='w-100 mb-4'>
                        <div className='row d-flex justify-content-start align-items-center mt-3'>
                            <h1 className='col-3 Lato-300 normal s-1420 mb-0 px-0'>Total Timeout Count</h1>
                            <Select
                                className="col-4"
                                styles={inputStyleH20}
                                options={optionsDayGraphWithHour}
                                value={optionsDayGraphWithHour.filter((option: any) => option.value === state.graphTimeoutDays)}
                                onChange={(e) => {
                                    handleStateComponent("graphTimeoutDays", e?.value)
                                }}
                            />
                        </div>
                        <div id="line-chart-timeout-mobile" style={{ width: '100%', height: '200px', marginTop: '8px', paddingLeft: '0px', paddingRight: '0px' }}></div>
                    </div>
                </>
            )}
        </>
    )
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    backgroundColor: 'white',
    boxShadow: '24',
    padding: '20px',
};
import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelRoutingFieldMapping } from './ModelRoutingFieldMapping';
import { loadingAction } from '../../Store/Loading';
import { Page32Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableRoutingFieldMapping = ({ ...props }) => {
      const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsMOClowercase, optionsCommonStatusDetailCode, optionsRouting, optionsInterfaceField } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { interfaceFieldId, rifMoc, rifStatus, routeId, rifValue } = statePage.data
        const data = {
            rifId: id,
            routeId: routeId,
            interfaceFieldId: parseInt(interfaceFieldId),
            rifMoc: rifMoc,
            rifStatus: rifStatus,
            rifValue: rifValue
        }
        const a = CheckAllFields([interfaceFieldId, rifMoc, routeId, rifStatus], ["INTERFACE FIELD ID", "M/O/C", "ROUTE ID", "STATUS"])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };
    let newOptionsRouting: any = [];
    if (optionsRouting.length > 0) {
        optionsRouting.forEach((element1: any) => {
            newOptionsRouting.push({ value: element1.routeId, label: element1.routeName === null ? 'Route Name is unknown' : element1.routeName });
        })
    }
    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>INTERFACE FIELD ID</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceField} value={optionsInterfaceField.filter((option: any) => option.value === statePage.data.interfaceFieldId)} onChange={(e) => handleState('interfaceFieldId', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>M/O/C</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMOClowercase} value={optionsMOClowercase.filter((option: any) => option.value === statePage.data.rifMoc)} onChange={(e) => handleState('rifMoc', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Route ID</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={newOptionsRouting} value={newOptionsRouting.filter((option: any) => option.value === statePage.data.routeId)} onChange={(e) => handleState('routeId', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Status</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.rifStatus)} onChange={(e) => handleState('rifStatus', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Default Value</p>
                        <input value={statePage.data.rifValue} onChange={(e) => handleState('rifValue', e.target.value)} className={inputStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page32Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelRoutingFieldMapping({
        id: null,
        rifId: null,
        routeId: null,
        interfaceFieldId: null,
        rifMoc: "m",
        rifStatus: "001",
        rifValue: null,
        interfaceFieldName: null,
        interfaceFieldValue: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelRoutingFieldMapping) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelRoutingFieldMapping({
                    id: null,
                    rifId: null,
                    routeId: null,
                    interfaceFieldId: null,
                    rifMoc: "m",
                    rifStatus: "001",
                    rifValue: null,
                    interfaceFieldName: null,
                    interfaceFieldValue: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelRoutingFieldMapping({
                    id: action.value.rifId,
                    rifId: action.value.rifId,
                    routeId: action.value.routeId,
                    interfaceFieldId: action.value.interfaceFieldId,
                    rifMoc: action.value.rifMoc,
                    rifStatus: action.value.rifStatus,
                    rifValue: action.value.rifValue,
                    interfaceFieldName: action.value.interfaceFieldName,
                    interfaceFieldValue: action.value.interfaceFieldValue,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Page2FrameworkRoute, Page2DashboardRoute } from "../../../Routes";
import { API } from "../../../Services";
import { initFunction, reducerOptions, reducerStateSelected, stateOptions, stateSelected } from "../../../State";
import { labelStyle, labelStyleW200PX, labelStyleW200PXWithoutUppercase } from "../../../Styles";
import { CheckUndefined, NumberWithCommas } from "../../../Utils";
import { InterfaceOptionsDetailsMonitoringStatus } from "../InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { ModelDetailTransactionStatus } from "../Models/ModelDetailTransactionStatus";
import { Divider } from "antd";
import { resetAllAction } from "../../../Store/TransactionJourney";
import { urlSelection } from "../../../Services/API";
const inputStyleReadOnly = 'hg-40px  s-1418 Lato-400  out-none px-2 flex-fill bg-none';

export const DetailsMonitoringStatus = ({...props}) => {
    const { id } = useParams();
    const dispatchGlobal = useDispatch();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        API.get({
            bodyCustom: null,
            pathCustom: `dashboard/transaction-detail?transId=${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            handleState('data', response.data)
        })
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
    }, [])


    const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
    const data = state.allData
    let merchant_post_code = ""
    let member_appr_code = ""

    const convertLabelCountry = (countryCode: string): string => {
        let labelCountry = '';
        if (stateOptions.optionsCountryCodeAndCurrencyCode.length > 0) {
            stateOptions.optionsCountryCodeAndCurrencyCode.forEach((element: any) => {
                if (element.value === countryCode) labelCountry = element.value;
            })
        }
        return labelCountry;
    }
    if (state.data === null) return <></>

    if (CheckUndefined(data.merchantPosCode) == "-") {
        merchant_post_code = CheckUndefined(data.merchantPos)
    } else if (CheckUndefined(data.merchantPos) == "-") {
        merchant_post_code = CheckUndefined(data.merchantPosCode)
    } else {
        merchant_post_code = `${CheckUndefined(data.merchantPosCode)} - ${(CheckUndefined(data.merchantPos))}`
    }

    if (CheckUndefined(data.isApprCodeIntn) != "-") {
        if (data.isApprCodeIntn) member_appr_code = " (Generated by BCAP)"
    }

    return (
        <div className="_new rev">
            <NavLink to={Page2DashboardRoute}>
                <h1 className="Lato-600 c-29 s-2024 w-600px mb-40px my-3">〱 Transaction Details</h1>
            </NavLink>
            <Divider />
            <div className="row bd-d4 br-10 bg-ff gx-4 gy-4 py-3 ">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Transaction Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>TRANSACTION ID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.transId)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Transaction Type</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.transType)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Transaction Date Time</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.transactionDateTime)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Global ID</p>
                    <div
                        className={`${labelStyleW200PXWithoutUppercase} fw-bold text-info pointer`}
                        onClick={(e) => {
                            dispatchGlobal(resetAllAction(data.globalId))
                            navigate(`${Page2FrameworkRoute}?globalId=${data.globalId}`)
                        }}
                    >
                        {CheckUndefined(data.globalId)}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Product Type</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.productTypeName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Product Feature</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.productFeatureName)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Approval Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.apprCode)}{member_appr_code}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Retrieval Reference Number</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.rrn)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Invoice Number</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.invNo)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>STAN</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{` ${(CheckUndefined(data.stan))}`}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>MTI</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.mti)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Processing Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{`${CheckUndefined(data.procCode)}`}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Conversion Rate</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.convRate)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Conversion Date</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.convDate)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Local Date Time</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.localDateTime)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Response Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.rspnCode)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Internal Response Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.intnRspnCode)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Destination Response Code</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstRspnCode)}</p>
                </div>

                {/* Cancellation Details */}
                { data.cancellationDetailList != null ?
                    <>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex bd-d4 bg-e8">
                            <h1 className="Lato-600 c-29 s-2024 w-600px ">Cancellation Details</h1>
                        </div>
                        {(data.cancellationDetailList.length === 1) ? 
                            (data.cancellationDetailList.map((cancellation : any, index:number) => (
                                <>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4 ">
                                        <p className={labelStyleW200PX}>TRANSACTION ID</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.transId)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                        <p className={labelStyleW200PX}>Transaction Type</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.transType)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                                        <p className={labelStyleW200PX}>Transaction Date Time</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.transDateTime)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Global ID</p>
                                        <div
                                            className={`${labelStyleW200PXWithoutUppercase} fw-bold text-info pointer`}
                                            onClick={(e) => {
                                                dispatchGlobal(resetAllAction(cancellation.globalId))
                                                navigate(`${Page2FrameworkRoute}?globalId=${cancellation.globalId}`)
                                            }}
                                        >
                                            {CheckUndefined(cancellation.globalId)}
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                                        <p className={labelStyleW200PX}>Product Feature</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.prodFeature)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                                        <p className={labelStyleW200PX}>Reference Number</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.refNo)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                        <p className={labelStyleW200PX}>Invoice Number</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.invNo)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>STAN</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{` ${(CheckUndefined(cancellation.stan))}`}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                        <p className={labelStyleW200PX}>Local Date Time</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.localDateTime)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Response Code</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.rspnCode)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Internal Response Code</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.intnRspnCode)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Destination Response Code</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.destRspnCode)}</p>
                                    </div>
                                </>
                            ))) 
                            :
                            (data.cancellationDetailList.map((cancellation : any, index:number) => (
                                <>
                                    <div className="">
                                        <p className={`${labelStyleW200PX} fw-bold`}>Cancellation {index + 1}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4 ">
                                        <p className={labelStyleW200PX}>TRANSACTION ID</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.transId)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                        <p className={labelStyleW200PX}>Transaction Type</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.transType)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                                        <p className={labelStyleW200PX}>Transaction Date Time</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.transDateTime)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Global ID</p>
                                        <div
                                            className={`${labelStyleW200PXWithoutUppercase} fw-bold text-info pointer`}
                                            onClick={(e) => {
                                                dispatchGlobal(resetAllAction(cancellation.globalId))
                                                navigate(`${Page2FrameworkRoute}?globalId=${cancellation.globalId}`)
                                            }}
                                        >
                                            {CheckUndefined(cancellation.globalId)}
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                                        <p className={labelStyleW200PX}>Product Feature</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.prodFeature)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                                        <p className={labelStyleW200PX}>Reference Number</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.refNo)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                        <p className={labelStyleW200PX}>Invoice Number</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.invNo)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>STAN</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{` ${(CheckUndefined(cancellation.stan))}`}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                        <p className={labelStyleW200PX}>Local Date Time</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.localDateTime)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Response Code</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.rspnCode)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Internal Response Code</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.intnRspnCode)}</p>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                        <p className={labelStyleW200PX}>Destination Response Code</p>
                                        <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(cancellation.destRspnCode)}</p>
                                    </div>
                                </>
                            ))) 
                        }
                    </>
                    :
                    <></>
                }
                
                {/* QR AUTH DETAILS */}
                {data.productTypeName === 'QR' ?
                    <>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                            <h1 className="Lato-600 c-29 s-2024 w-600px ">QR Auth Details</h1>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                            <p className={labelStyleW200PX}>Global ID</p>
                            <div
                                className={`${labelStyleW200PXWithoutUppercase} fw-bold text-info pointer`}
                                onClick={(e) => {
                                    dispatchGlobal(resetAllAction(data.qrAuthDetails?.qrGlobalId))
                                    navigate(`${Page2FrameworkRoute}?globalId=${data.qrAuthDetails?.qrGlobalId}`)
                                }}
                            >
                                {CheckUndefined(data.qrAuthDetails?.qrGlobalId)}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                            <p className={labelStyleW200PX}>STAN</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.qrReqStan)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                            <p className={labelStyleW200PX}>QR Ref No</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.qrRefNo)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                            <p className={labelStyleW200PX}>Request Date</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.qrReqDate)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                            <p className={labelStyleW200PX}>QR Standard</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.qrStandard)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                            <p className={labelStyleW200PX}>QR Mode</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.qrMode)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                            <p className={labelStyleW200PX}>QR Inv No</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.qrInvNo)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                            <p className={labelStyleW200PX}>QR Ref 1</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.ref1)}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                            <p className={labelStyleW200PX}>QR Ref 2</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{`${CheckUndefined(data.qrAuthDetails?.ref2)}`}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                            <p className={labelStyleW200PX}>QR Ref 3</p>
                            <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.qrAuthDetails?.ref3)}</p>
                        </div>
                    </>
                    :
                    <></>
                }

                {/* SETTLEMENT */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Settlement Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Settlement Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.stlAmt)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Settlement Date</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.stlDate)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Conversion Rate</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.stlConvRate)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Conversion Date</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.stlConvDate)}</p>
                </div>

                {/* SOURCE */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Source Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Source Institute</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstitute)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>{data.srcLabel1}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteName1)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>{data.srcLabel2}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteName2)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Source IP</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcIp)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.srcLabel1} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteAmount1)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.srcLabel2} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcInstituteAmount2)}</p>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Source Ref No</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.srcRefNo)}</p>
                </div>

                {/* DESTINATION */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Destination Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Destination Intitute</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstitute)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>{data.dstLabel1}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteName1)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>{data.dstLabel2}</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteName2)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Destination Ref No</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstRefNo)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.dstLabel1} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteAmount1)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>{data.dstLabel2} Amount</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.dstInstituteAmount2)}</p>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Merchant Details</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>MID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.mid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Sub MID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.subMid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Member MID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.memberMid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                    <p className={labelStyleW200PX}>Merchant Name</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.merchantName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Sub Merchant Name</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.subMerchantName)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                    <p className={labelStyleW200PX}>Merchant Country</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.merchantCountry)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>TID</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.tid)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Merchant Post Entry Mode / Channel</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{merchant_post_code} </p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>MCC</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.mcc)}</p>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                    <h1 className="Lato-600 c-29 s-2024 w-600px ">Wallet Info</h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Wallet Id</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.walletId)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Customer Pan</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.custPan)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                    <p className={labelStyleW200PX}>Customer Token</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.custToken)}</p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                    <p className={labelStyleW200PX}>Customer Username</p>
                    <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.custUsername)}</p>
                </div>


                {
                    (CheckUndefined(data.oriTransId) === '-') ?
                        <></>
                        :
                        <>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  d-flex bd-d4 bg-e8">
                                <h1 className="Lato-600 c-29 s-2024 w-600px ">Origin Transaction Details (Cancellation Only)</h1>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12  d-flex bd-d4 bg-f4">
                                <p className={labelStyleW200PX}>TRANSACTION ID</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriTransId)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 b">
                                <p className={labelStyleW200PX}>Transaction Type</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriTransType)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4">
                                <p className={labelStyleW200PX}>Transaction Date Time</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriTransactionDateTime)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                <p className={labelStyleW200PX}>Global ID</p>
                                <div
                                    className={`${labelStyleW200PXWithoutUppercase} fw-bold text-info pointer`}
                                    onClick={(e) => {
                                        dispatchGlobal(resetAllAction(data.oriGlobalId))
                                        navigate(`${Page2FrameworkRoute}?globalId=${data.oriGlobalId}`)
                                    }}
                                >
                                    {CheckUndefined(data.oriGlobalId)}
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f">
                                <p className={labelStyleW200PX}>STAN</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriStan)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                                <p className={labelStyleW200PX}>Retrieval Reference Number</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriRrn)}</p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex bd-d4 bg-f4 f b">
                                <p className={labelStyleW200PX}>Invoice Number</p>
                                <p className={`${labelStyleW200PXWithoutUppercase} fw-bold`}>{CheckUndefined(data.oriInvNo)}</p>
                            </div>
                        </>

                }

            </div>
        </div>
    )
}


interface test {
    data: ModelDetailTransactionStatus | null
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: test = {
    data: null
};
function reducer(state: any, action: any) {

    switch (action.type) {
        case 'data':
            return {
                ...state,
                allData: action.value,
                data: {
                    authId: action.value.authId,
                    transAuthId: action.value.transId,
                    transactionDateTime: action.value.transactionDateTime,
                    issAmount: action.value.issAmount,
                    productTypeName: action.value.productTypeName,
                    localDateTime: action.value.localDateTime,
                    iss: action.value.iss,
                    issSwitcherAmount: action.value.issSwitcherAmount,
                    productFeatureName: action.value.productFeatureName,
                    acqSwitcherAmount: action.value.acqSwitcherAmount,
                    mti: action.value.mti,
                    issSwitcher: action.value.issSwitcher,
                    acqAmount: action.value.acqAmount,
                    stan: action.value.stan,
                    acqSwitcher: action.value.acqSwitcher,
                    convRate: action.value.convRate,
                    procCode: action.value.procCode,
                    acq: action.value.acq,
                    convDate: action.value.convDate,
                    rrn: action.value.rrn,
                    mid: action.value.mid,
                    issSettleDate: action.value.issSettleDate,
                    invNo: action.value.invNo,
                    tid: action.value.tid,
                    issSwitcherSettleDate: action.value.issSwitcherSettleDate,
                    apprCode: action.value.apprCode,
                    merchantName: action.value.merchantName,
                    acqSwitcherSettleDate: action.value.acqSwitcherSettleDate,
                    merchantCountry: action.value.merchantCountry,
                    acqSettleDate: action.value.acqSettleDate,
                    globalId: action.value.globalId,
                    mcc: action.value.mcc,
                    intnRspnCode: action.value.intnRspnCode,
                    isApprCodeIntn: action.value.isApprCodeIntn,

                }
            };
        default:
            throw new Error();
    }
}
import { SelectOptionsInterface } from "../../Interface";

export const InterfaceOptionsQRWhitelistAndBlacklist: SelectOptionsInterface = {
    ccdOptions: true,
    spfOptions: false,
    memberOptions: false,
    ccgOptions: false,
    logLevel001Options: false,
    logLevel002Options: false,
    routingOptions: false,
    routeSequenceOptions: false,
    privilegeOptions: false,
    countryOptions: false,
    productOptions: false,
    productFeatureOptions: false,
    reasonCodeOptions: false,
    subProductOptions: false,
    exchangeRateOptions: false,
    elementOptions: false,
    qrStandardOptions: true,
    interfaceFieldOptions: false,
    headerFieldOptions: false,
        feeOptions: false,
    interfaceFileOptions:false,
            recordOptions: false,
    interfaceFileItemOptions:false,
    commonCodeOptions:false,
    resultOptions:false
}

export function reducerOptions(state: any, action: any) {
    switch (action.type) {
        case 'optionsSpf':
            return {
                ...state,
                optionsSpf: action.value
            };
        case 'optionsMember':
            return {
                ...state,
                optionsMember: action.value
            };
        case 'OptionsMemberRoleType':
            return {
                ...state,
                OptionsMemberRoleType: action.value
            };
        case 'optionsMemberCode':
            return {
                ...state,
                optionsMemberCode: action.value
            };
        case 'optionsMemberCode2':
            return {
                ...state,
                optionsMemberCode2: action.value
            };
        case 'optionsMemberCode3':
            return {
                ...state,
                optionsMemberCode3: action.value
            };
        case 'optionsMemberId':
            return {
                ...state,
                optionsMemberId: action.value
            };
        case 'optionsFormat':
            return {
                ...state,
                optionsFormat: action.value
            };
        case 'optionsCommonStatus':
            return {
                ...state,
                optionsCommonStatus: action.value
            };

        case 'optionsElementType':
            return {
                ...state,
                optionsElementType: action.value
            };
        case 'optionsElementTypeDetailCode':
            return {
                ...state,
                optionsElementTypeDetailCode: action.value
            };

        case 'optionsElementFormat':
            return {
                ...state,
                optionsElementFormat: action.value
            };
        case 'optionsElementFormatDetailCode':
            return {
                ...state,
                optionsElementFormatDetailCode: action.value
            };
        case 'optionsEnvironmentType':
            return {
                ...state,
                optionsEnvironmentType: action.value
            };
        case 'optionsHashType':
            return {
                ...state,
                optionsHashType: action.value
            };
        case 'optionsInstitutionType':
            return {
                ...state,
                optionsInstitutionType: action.value
            };
        case 'optionsRoleType':
            return {
                ...state,
                optionsRoleType: action.value
            };
        case 'optionsFieldMappingDetailCode':
            return {
                ...state,
                optionsFieldMappingDetailCode: action.value
            };
        case 'optionsIssuerInstitutionIDDetailCode':
            return {
                ...state,
                optionsIssuerInstitutionIDDetailCode: action.value
            };
        case 'optionsAcquirerInstitutionIDDetailCode':
            return {
                ...state,
                optionsAcquirerInstitutionIDDetailCode: action.value
            };
        case 'optionsPaymentStatusDetailCode':
            return {
                ...state,
                optionsPaymentStatusDetailCode: action.value
            };

        case 'optionsSwitcherInstitutionIDDetailCode':
            return {
                ...state,
                optionsSwitcherInstitutionIDDetailCode: action.value
            };

        case 'optionsBusinessGroupNameDetailCodeAndCcdName':
            return {
                ...state,
                optionsBusinessGroupNameDetailCodeAndCcdName: action.value
            };
        case 'optionsFeeRateInputTypeDetailCode':
            return {
                ...state,
                optionsFeeRateInputTypeDetailCode: action.value
            };
        case 'optionsDisputeCaseStatusDetailCode':
            return {
                ...state,
                optionsDisputeCaseStatusDetailCode: action.value
            };
        case 'optionsChargebackReasonDetailCode':
            return {
                ...state,
                optionsChargebackReasonDetailCode: action.value
            };
        case 'optionsRePresentmentReasonDetailCode':
            return {
                ...state,
                optionsRePresentmentReasonDetailCode: action.value
            };
        case 'optionsSAFStatusDetailCode':
            return {
                ...state,
                optionsSAFStatusDetailCode: action.value
            };

        case 'optionsServerStatusDetailCode':
            return {
                ...state,
                optionsServerStatusDetailCode: action.value
            };

        case 'optionsLogLevelProgramClassificationDetailCode':
            return {
                ...state,
                optionsLogLevelProgramClassificationDetailCode: action.value
            };
        case 'optionsLogLevelDetailCode':
            return {
                ...state,
                optionsLogLevelDetailCode: action.value
            };
        case 'optionsInterfaceFileFormatDetailCode':
            return {
                ...state,
                optionsInterfaceFileFormatDetailCode: action.value
            };
        case 'optionsFileStringFormatDetailCode':
            return {
                ...state,
                optionsFileStringFormatDetailCode: action.value
            };
        case 'optionsFileStringSeparatorDetailCode':
            return {
                ...state,
                optionsFileStringSeparatorDetailCode: action.value
            };
        case 'optionsCommonStatusDetailCode':
            return {
                ...state,
                optionsCommonStatusDetailCode: action.value
            };
        case 'optionsFileRecordOccurrenceDetailCode':
            return {
                ...state,
                optionsFileRecordOccurrenceDetailCode: action.value
            };
        case 'optionsFileDataTypeDetailCode':
            return {
                ...state,
                optionsFileDataTypeDetailCode: action.value
            };
        case 'optionsFileDataPaddingDetailCode':
            return {
                ...state,
                optionsFileDataPaddingDetailCode: action.value
            };
        case 'optionsFileDataPaddingOptionDetailCode':
            return {
                ...state,
                optionsFileDataPaddingOptionDetailCode: action.value
            };
        case 'optionsUserCategoryDetailCode':
            return {
                ...state,
                optionsUserCategoryDetailCode: action.value
            };
        case 'optionsBatchProgramTypeDetailCode':
            return {
                ...state,
                optionsBatchProgramTypeDetailCode: action.value
            };
        case 'optionsUserStatusDetailCode':
            return {
                ...state,
                optionsUserStatusDetailCode: action.value
            };
        case 'optionsPaymentConfirmationMethodDetailCode':
            return {
                ...state,
                optionsPaymentConfirmationMethodDetailCode: action.value
            };
        case 'optionsBatchProgramStatusDetailCode':
            return {
                ...state,
                optionsBatchProgramStatusDetailCode: action.value
            };
        case 'optionsReconciliationStatusDetailCodeAndCcdName':
            return {
                ...state,
                optionsReconciliationStatusDetailCodeAndCcdName: action.value
            };
        case 'optionsIssuerInstitutionIDUseUseExtAndDetail':
            return {
                ...state,
                optionsIssuerInstitutionIDUseUseExtAndDetail: action.value
            };
        case 'optionsAcquirerInstitutionIDUseUseExtAndDetail':
            return {
                ...state,
                optionsAcquirerInstitutionIDUseUseExtAndDetail: action.value
            };
        case 'optionsSwitcherInstitutionIDUseExtAndDetail':
            return {
                ...state,
                optionsSwitcherInstitutionIDUseExtAndDetail: action.value
            };
        case 'optionsWalletInstitutionIDUseExtAndDetail':
            return {
                ...state,
                optionsWalletInstitutionIDUseExtAndDetail: action.value
            };
        case 'optionsBatchRegistrationTypeDetailCode':
            return {
                ...state,
                optionsBatchRegistrationTypeDetailCode: action.value
            };
        case 'optionsBatchSchedulerStatusDetailCode':
            return {
                ...state,
                optionsBatchSchedulerStatusDetailCode: action.value
            };
        case 'optionsBatchExecutionTypeDetailCode':
            return {
                ...state,
                optionsBatchExecutionTypeDetailCode: action.value
            };
        case 'optionsIssuerInstitutionIDUseCCDNameAndCodeDetail':
            return {
                ...state,
                optionsIssuerInstitutionIDUseCCDNameAndCodeDetail: action.value
            };
        case 'optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail':
            return {
                ...state,
                optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail: action.value
            };
        case 'optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail':
            return {
                ...state,
                optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail: action.value
            };

        case 'optionsConnectionStatusDetailCode':
            return {
                ...state,
                optionsConnectionStatusDetailCode: action.value
            };
        case 'optionsInterfaceFileRecordFormatUsedCCDNameAndCCDCode':
            return {
                ...state,
                optionsInterfaceFileRecordFormatUsedCCDNameAndCCDCode: action.value
            };
        case 'optionsReconciliationStatusUseCCDNameAndCodeDetail':
            return {
                ...state,
                optionsReconciliationStatusUseCCDNameAndCodeDetail: action.value
            };

        case 'optionsUserPrivilegeLevel':
            return {
                ...state,
                optionsUserPrivilegeLevel: action.value
            };
        case 'optionsFeeCalculationMethod':
            return {
                ...state,
                optionsFeeCalculationMethod: action.value
            };
        case 'optionsRouting':
            return {
                ...state,
                optionsRouting: action.value
            };
        case 'optionsRouteSequence':
            return {
                ...state,
                optionsRouteSequence: action.value
            };
        case 'optionsQrMpmCpm':
            return {
                ...state,
                optionsQrMpmCpm: action.value
            };
        case 'optionsPrivilege':
            return {
                ...state,
                optionsPrivilege: action.value
            };
        case 'optionsUserStatus':
            return {
                ...state,
                optionsUserStatus: action.value
            };
        case 'optionsUserLevel':
            return {
                ...state,
                optionsUserLevel: action.value
            };
        case 'optionsUserCategory':
            return {
                ...state,
                optionsUserCategory: action.value
            };
        case 'optionsCountry':
            return {
                ...state,
                optionsCountry: action.value
            };
        case 'optionsCountryCodeAndCurrencyCode':
            return {
                ...state,
                optionsCountryCodeAndCurrencyCode: action.value
            };
        case 'optionsCurrency':
            return {
                ...state,
                optionsCurrency: action.value
            };
        case 'optionsCurrencyCcdName':
            return {
                ...state,
                optionsCurrencyCcdName: action.value
            };
        case 'optionsServerStatus':
            return {
                ...state,
                optionsServerStatus: action.value
            };
        case 'optionsConnectionStatus':
            return {
                ...state,
                optionsConnectionStatus: action.value
            };
        case 'optionsReasonCode':
            return {
                ...state,
                optionsReasonCode: action.value
            };
        case 'optionsReasonCodeUseId':
            return {
                ...state,
                optionsReasonCodeUseId: action.value
            };

        case 'optionsProduct':
            return {
                ...state,
                optionsProduct: action.value
            };

        case 'optionsProductNameAsValue':
            return {
                ...state,
                optionsProductNameAsValue: action.value
            };

        case 'optionsProductFeature':
            return {
                ...state,
                optionsProductFeature: action.value
            };

        case 'optionsProductFeatureProdFeatureIndAsValue':
            return {
                ...state,
                optionsProductFeatureProdFeatureIndAsValue: action.value
            };
        case 'optionsProductFeatureProdFeatureIndAsValue2':
            return {
                ...state,
                optionsProductFeatureProdFeatureIndAsValue2: action.value
            };
        case 'optionsQRStandardNameAsValue':
            return {
                ...state,
                optionsQRStandardNameAsValue: action.value
            };
        case 'optionsProductInd':
            return {
                ...state,
                optionsProductInd: action.value
            }

        case 'optionsProductFeatureWithAll':
            return {
                ...state,
                optionsProductFeatureWithAll: action.value
            };

        case 'optionsProductFeatureWithAllOnlyProdFeatureInd':
            return {
                ...state,
                optionsProductFeatureWithAllOnlyProdFeatureInd: action.value
            };

        case 'optionsTransactionTypeDetailCodeAndCcdName':
            return {
                ...state,
                optionsTransactionTypeDetailCodeAndCcdName: action.value
            };
        case 'optionsTransactionTypeDetailCodeAndCcdDesc':
            return {
                ...state,
                optionsTransactionTypeDetailCodeAndCcdDesc: action.value
            };

        case 'optionsIssuer':
            return {
                ...state,
                optionsIssuer: action.value
            };
        case 'optionsIssuerSwitcher':
            return {
                ...state,
                optionsIssuerSwitcher: action.value
            };
        case 'optionsSwitcher':
            return {
                ...state,
                optionsSwitcher: action.value
            };
        case 'optionsAcquirer':
            return {
                ...state,
                optionsAcquirer: action.value
            };
        case 'optionsAcquirerSwitcher':
            return {
                ...state,
                optionsAcquirerSwitcher: action.value
            };
        case 'optionsSwitcherCountry':
            return {
                ...state,
                optionsSwitcherCountry: action.value
            };

        case 'optionsSubProduct':
            return {
                ...state,
                optionsSubProduct: action.value
            };
        case 'optionsRcGroup':
            return {
                ...state,
                optionsRcGroup: action.value
            };
        case 'optionsRateSetting':
            return {
                ...state,
                optionsRateSetting: action.value
            };
        case 'optionsElement':
            return {
                ...state,
                optionsElement: action.value
            };
        case 'optionsQRStandard':
            return {
                ...state,
                optionsQRStandard: action.value
            };
        case 'optionsInterfaceField':
            return {
                ...state,
                optionsInterfaceField: action.value
            };
        case 'optionsHeaderField':
            return {
                ...state,
                optionsHeaderField: action.value
            };
        case 'optionsResult':
            return {
                ...state,
                optionsResult: action.value
            };
        case 'optionsFee':
            return {
                ...state,
                optionsFee: action.value
            };
        case 'optionsGroupCode':
            return {
                ...state,
                optionsGroupCode: action.value
            };
        case 'optionsGroupCodeOnly':
            return {
                ...state,
                optionsGroupCodeOnly: action.value
            };

        case 'optionsService':
            return {
                ...state,
                optionsService: action.value
            };
        case 'optionsSwitcherInstitutionType':
            return {
                ...state,
                optionsSwitcherInstitutionType: action.value
            };
        case 'optionsBatch':
            return {
                ...state,
                optionsBatch: action.value
            };
        case 'optionsCCD':
            return {
                ...state,
                optionsCCD: action.value
            };
        case 'optionsYorN':
            return {
                ...state,
                optionsYorN: action.value
            };
        case 'optionsMOC':
            return {
                ...state,
                optionsMOC: action.value
            };
        case 'optionsMOClowercase':
            return {
                ...state,
                optionsMOClowercase: action.value
            };
        case 'optionsWhitelistBlacklist':
            return {
                ...state,
                optionsWhitelistBlacklist: action.value
            };
        case 'optionsSendReceive':
            return {
                ...state,
                optionsSendReceive: action.value
            };
        case 'optionsLogLevel':
            return {
                ...state,
                optionsLogLevel: action.value
            };
        case 'optionsInterfaceFile':
            return {
                ...state,
                optionsInterfaceFile: action.value
            };
        case 'optionsInterfaceRecord':
            return {
                ...state,
                optionsInterfaceRecord: action.value
            };
        case 'optionsInterfaceFileItem':
            return {
                ...state,
                optionsInterfaceFileItem: action.value
            };
        case 'optionsCountryCodeCurrencyCode':
            return {
                ...state,
                optionsCountryCodeCurrencyCode: action.value
            };
        case 'optionsMemberPICPayment':
            return {
                ...state,
                optionsMemberPICPayment: action.value
            };
        case 'optionsMemberPICDispute':
            return {
                ...state,
                optionsMemberPICDispute: action.value
            };
        case 'optionsBatchFromAPIBatch':
            return {
                ...state,
                optionsBatchFromAPIBatch: action.value
            };
        case 'optionsBatchFromAPIBatch2':
            return {
                ...state,
                optionsBatchFromAPIBatch2: action.value
            };
        case 'optionsBatchSchedulerStatusUseCCDNameAndCodeDetail':
            return {
                ...state,
                optionsBatchSchedulerStatusUseCCDNameAndCodeDetail: action.value
            };
        case 'optionsInstitutionCode200':
            return {
                ...state,
                optionsInstitutionCode200: action.value
            };
        case 'optionsInstitutionCode201':
            return {
                ...state,
                optionsInstitutionCode201: action.value
            };
        case 'optionsInstitutionCode202':
            return {
                ...state,
                optionsInstitutionCode202: action.value
            };
        case 'optionsInstitutionCode203':
            return {
                ...state,
                optionsInstitutionCode203: action.value
            };

        case 'optionsBusinessGroupNameUseCCDNameAndCodeDetail':
            return {
                ...state,
                optionsBusinessGroupNameUseCCDNameAndCodeDetail: action.value
            };
        case 'optionsBatchExecutionTypeUseCCDNameAndCodeDetail':
            return {
                ...state,
                optionsBatchExecutionTypeUseCCDNameAndCodeDetail: action.value
            };

        //
        case 'optionsAcquireDate':
            return {
                ...state,
                optionsAcquireDate: action.value
            };
        case 'optionsIssuerDate':
            return {
                ...state,
                optionsIssuerDate: action.value
            };
        case 'optionsTresholdPercent':
            return {
                ...state,
                optionsTresholdPercent: action.value
            };
        case 'optionsTestValid':
            return {
                ...state,
                optionsTestValid: action.value
            };
        case 'optionsCommonCode700':
            return {
                ...state,
                optionsCommonCode700: action.value
            };
        case 'optionsCommonCode701':
            return {
                ...state,
                optionsCommonCode701: action.value
            };
        case 'optionsCommonCode702':
            return {
                ...state,
                optionsCommonCode702: action.value
            };
        case 'optionsCommonCode703':
            return {
                ...state,
                optionsCommonCode703: action.value
            };
        case 'optionsOnOff':
            return {
                ...state,
                optionsOnOff: action.value
            };
        case 'optionsCommonCode':
            return {
                ...state,
                optionsCommonCode: action.value
            };
        case 'optionsSourceInstUnmatchedList':
            return {
                ...state,
                optionsSourceInstUnmatchedList: action.value
            };

        default:
            throw new Error();
    }
}
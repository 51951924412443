import { API, urlSelection } from '../../../../Services/API';
import { CheckAllFields, GeneratePassword, HasWhiteSpace, IsValidEmail, ModalStyle } from '../../../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import { Button } from 'react-bootstrap';
import Modal from 'react-modal';
import { ModelUserManagement } from '../../Utils/ModelUserManagement';
import { Page10Title } from '../../../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../../../Assets/Images/svg';
import { ModalStyle5 } from '../../../../Utils/ModalStyle';
import { DatePickerRighIcon } from '../../../../Components/DatepickerRightIcon';
import { format } from 'date-fns';

export const ModalUserManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const userLogin = useSelector((state: any) => state.user);

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal, handleStateComponent } = props
    const { optionsMember, optionsPrivilege, optionsUserStatusDetailCode, optionsUserLevel, optionsUserCategoryDetailCode, optionsUserPrivilegeLevel } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
        dispatchStatePage({ type: 'resetData', value: { userLogin: userLogin, stateOptions: props.stateOptions } })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])



    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'userName') {
            if (valueState.split('').length > 16) {
                dispatchStatePage({ type: 'errorMessage', value: 'Username input maximum 16 characters' })
            } else {
                dispatchStatePage({ type: 'errorMessage', value: '' })
                dispatchStatePage({ type: nameState, value: valueState })
            }
        } else if (nameState === 'userEmail') {
            if (valueState.split('').length > 32) {
                dispatchStatePage({ type: 'errorMessage', value: 'Email input maximum 32 characters' })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: '' })
            }
        } else if (nameState === 'userStartDate') {
            let dateStart = new Date(valueState);
            let dateEnd = new Date(statePage.data.userEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: 'Start date cannot be larger than End Date' })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: '' })
            }
        } else if (nameState === 'userEndDate') {
            let dateStart = new Date(statePage.data.userStartDate);
            let dateEnd = new Date(valueState);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: 'Start date cannot be larger than End Date' })

            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: '' })

            }
        } else if (nameState === 'memberId') {
            if (valueState === 1) {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'userCategoryCode', value: '01' })
                dispatchStatePage({ type: 'privilegeId', value: optionsPrivilege[0].value })
            } else {
                let newOptionsUserPrivilegeId = optionsPrivilege.filter((option: any) => option.label.toLowerCase().includes('member'));
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'userCategoryCode', value: '002' })
                dispatchStatePage({ type: 'privilegeId', value: newOptionsUserPrivilegeId[0].value })
            }
        } else if (nameState === 'userLevel') {
            dispatchStatePage({ type: nameState, value: valueState })
            dispatchStatePage({ type: 'privilegeId', value: null })

        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    useEffect(() => {
        if (statePage.data.userLevel !== null) {
            let newOptionsPrivilege: any = []
            optionsPrivilege.forEach((item1: any) => {
                if (item1.level === statePage.data.userLevel) {
                    newOptionsPrivilege.push(item1);
                }
            })
            dispatchStatePage({ type: 'newOptionsPrivilege', value: newOptionsPrivilege })
        }
    }, [statePage.data.userLevel])

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { password, confirmPassword } = statePage
        const { userName, userEmail, userCategoryCode, userLevel, privilegeId, userStatus, userStartDate, userEndDate, memberId, memberName, privilegeName, userBlockedReason, } = statePage.data
        const data = {
            // userId: id,
            userName: userName,
            userEmail: userEmail,
            userCategoryCode: userCategoryCode,
            userLevel: userLevel,
            privilegeId: privilegeId,
            userStatus: userStatus,
            userStartDate: userStartDate,
            userEndDate: userEndDate,
            memberId: memberId,
            memberName: memberName,
            privilegeName: privilegeName,
            userBlockedReason: userBlockedReason,
        };
        if (!IsValidEmail(userEmail)) {
            handleState('errorMessage', 'Email is invalid');
        } else {
            if (userStatus === '03') { // block user
                let a = CheckAllFields([memberId, userName, userEmail, userCategoryCode, userLevel, userStatus, userStartDate, userEndDate, userBlockedReason, privilegeId], ['MEMBER ID', 'Username', 'Email', 'Category Code', 'Level', 'Status', 'Valid Start Date', 'Valid End Date', 'Block Reason', 'Privilege Code']);
                let conditionPrivilegeIDAndUserLevelIsMatching = false;
                if (statePage.newOptionsPrivilege !== null) {
                    if (statePage.newOptionsPrivilege.length > 0) {
                        statePage.newOptionsPrivilege.forEach((item: any) => {
                            if (privilegeId === item.value) {
                                if (item.level === userLevel) conditionPrivilegeIDAndUserLevelIsMatching = true;
                            }
                        })

                    }
                }
                if (conditionPrivilegeIDAndUserLevelIsMatching === false) {
                    handleState('errorMessage', 'Privilege Level and Privilege Code doesn\'t match');
                } else {
                    if (a !== "") {
                        handleState('errorMessage', a);
                    } else {
                        handleState('errorMessage', ``);
                        let newPassword = GeneratePassword(12);
                        var dataEdited = Object.assign(data, { userPassword: newPassword });
                        API.post({
                            bodyCustom: dataEdited,
                            pathCustom: `userapp/register`,
                            selectUrl: urlSelection.dashboard,
                            useToken: true,
                            needLoading: true,
                        }).then((response) => {
                            dispatchStatePage({ type: 'clearData' })
                            setModal("visibleModalAddDelete")
                            handleStateComponent('showPassword', true);
                            handleStateComponent('currentPassword', newPassword);
                            reloadData()
                        }).catch((err) => handleState('errorMessage', err))
                    }
                }
            } else {
                let a = CheckAllFields([memberId, userName, userEmail, userCategoryCode, userLevel, userStatus, userStartDate, userEndDate, privilegeId], ['MEMBER ID', 'Username', 'Email', 'Category Code', 'Level', 'Status', 'Valid Start Date', 'Valid End Date', 'Privilege Code']);
                let conditionPrivilegeIDAndUserLevelIsMatching = false;
                if (statePage.newOptionsPrivilege !== null) {
                    if (statePage.newOptionsPrivilege.length > 0) {
                        statePage.newOptionsPrivilege.forEach((item: any) => {
                            if (privilegeId === item.value) {
                                if (item.level === userLevel) conditionPrivilegeIDAndUserLevelIsMatching = true;
                            }
                        })

                    }
                }
                if (conditionPrivilegeIDAndUserLevelIsMatching === false) {
                    handleState('errorMessage', 'Privilege Level and Privilege Code doesn\'t match');
                } else {
                    if (a !== "") {
                        handleState('errorMessage', a);
                    } else {
                        handleState('errorMessage', '');
                        let newPassword = GeneratePassword(12);
                        var dataEdited = Object.assign(data, { userPassword: newPassword });
                        API.post({
                            bodyCustom: dataEdited,
                            pathCustom: `userapp/register`,
                            selectUrl: urlSelection.dashboard,
                            useToken: true,
                            needLoading: true,
                        }).then((response) => {
                            dispatchStatePage({ type: 'clearData' })
                            setModal("visibleModalAddDelete")
                            handleStateComponent('showPassword', true);
                            handleStateComponent('currentPassword', newPassword);
                            reloadData()
                        }).catch((err) => handleState('errorMessage', err))
                    }
                }
            }
        }
    }

    return (
        <>

            <Modal isOpen={visibleModalAddDelete} style={ModalStyle5} >
                <div className='d-flex flex-column min-h-60px' >
                    <div className='d-flex min-h-30px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            {id
                                &&
                                (
                                    <div className='col-6'>
                                        <p className={labelStyle}>USER ID</p>
                                        <input value={id} disabled={true} className={inputStyleReadOnly} />
                                    </div>
                                )
                            }

                            {
                                userLogin.memberId === 1 ?
                                    <div className='col-12'>
                                        <p className={labelStyle}>member ID</p>
                                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.data.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                                    </div>
                                    :
                                    <div className='col-12'>
                                        <p className={labelStyle}>member ID</p>
                                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember.filter((item: any) => item.value === userLogin.memberId)} value={optionsMember.filter((option: any) => option.value === statePage.data.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                                    </div>
                            }


                            <div className='col-6'>
                                <p className={labelStyle}>USERNAME <span className='text-danger'>*</span></p>
                                <input value={statePage.data.userName} onChange={(e) => handleState('userName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>EMAIL <span className='text-danger'>*</span></p>
                                <input value={statePage.data.userEmail} onChange={(e) => handleState('userEmail', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>category code <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsUserCategoryDetailCode} value={optionsUserCategoryDetailCode.filter((option: any) => option.value === statePage.data.userCategoryCode)} onChange={(e) => handleState('userCategoryCode', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Privilege level <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsUserPrivilegeLevel.filter((element1: any) => element1.value !== '00')} value={optionsUserPrivilegeLevel.filter((option: any) => option.value === statePage.data.userLevel)} onChange={(e) => handleState('userLevel', e.value)} />
                            </div>
                            {
                                statePage.data.userLevel === null ?
                                    <div className='col-6'>
                                        <p className={labelStyle}>privilege code <span className='text-danger'>*</span></p>
                                        <Select styles={inputStyleH25} isDisabled={true} placeholder={'(Search)'} options={statePage.newOptionsPrivilege} value={statePage.newOptionsPrivilege.filter((option: any) => option.value === statePage.data.privilegeId)} onChange={(e) => handleState('privilegeId', e.value)} />
                                    </div>
                                    :
                                    <div className='col-6'>
                                        <p className={labelStyle}>privilege code <span className='text-danger'>*</span></p>
                                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={statePage.newOptionsPrivilege} value={statePage.newOptionsPrivilege.filter((option: any) => option.value === statePage.data.privilegeId)} onChange={(e) => handleState('privilegeId', e.value)} />
                                    </div>
                            }

                            <div className='col-6'>
                                <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsUserStatusDetailCode} value={optionsUserStatusDetailCode.filter((option: any) => option.value === statePage.data.userStatus)} onChange={(e) => handleState('userStatus', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>start date <span className='text-danger'>*</span></p>
                                <DatePickerRighIcon 
                                    needTimeInput={false}
                                    selected={statePage.data.userStartDate}
                                    onChange={(date: any) => {
                                        handleState('userStartDate', format(date, 'yyyy-MM-dd'))
                                    }}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>end date <span className='text-danger'>*</span></p>
                                <DatePickerRighIcon 
                                    needTimeInput={false}
                                    selected={statePage.data.userEndDate}
                                    onChange={(date: any) => {
                                        handleState('userEndDate', format(date, 'yyyy-MM-dd'))
                                    }}
                                />
                            </div>
                            <div className='row'>
                                {id ?
                                    <div className='col-6'>
                                        <p className={labelStyle}>Reset Password</p>
                                        <Button variant='primary' onClick={() => {
                                            let newPassword = GeneratePassword(12);
                                            handleState('password', newPassword);
                                            handleState('confirmPassword', newPassword);
                                        }}>
                                            Reset Password
                                        </Button>
                                    </div>
                                    :
                                    <></>
                                }
                                {
                                    id && statePage.password !== '' ?
                                        <div className='col-6'>
                                            <p className={labelStyle}>Password</p>
                                            <input value={statePage.password} type='text' disabled={true} className={inputStyleReadOnly} />
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                            {
                                (statePage.data.userStatus === '003') || (statePage.data.userStatus === '002') ?
                                    <div className='col-12'>
                                        <p className={labelStyle}>User block reason </p>
                                        <textarea rows={4} value={statePage.data.userBlockedReason} onChange={(e) => handleState('userBlockedReason', e.target.value)} className={inputDescriptionStyle} />
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page10Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelUserManagement({
        id: null,
        userId: null,
        memberId: null,
        privilegeId: null,
        userEmail: null,
        userName: null,
        userCategoryCode: null,
        userLevel: null,
        userStatus: '001',
        userStartDate: new Date().toISOString().slice(0, 10),
        userEndDate: new Date().toISOString().slice(0, 10),
        userBlockedReason: null,
        memberName: null,
        privilegeName: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
    password: '',
    confirmPassword: '',
    newOptionsPrivilege: [],

}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelUserManagement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }


    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelUserManagement({
                    id: null,
                    userId: null,
                    memberId: null,
                    privilegeId: null,
                    userEmail: null,
                    userName: null,
                    userCategoryCode: null,
                    userLevel: null,
                    userStatus: '01',
                    userStartDate: null,
                    userEndDate: null,
                    userBlockedReason: null,
                    memberName: null,
                    privilegeName: null,
                }),
                errorMessage: "",

            };
        // 
        case 'resetData':
            let newOptionsUserPrivilegeId = null;
            if (action.value.stateOptions.optionsPrivilege.length > 0) {
                newOptionsUserPrivilegeId = action.value.stateOptions.optionsPrivilege.filter((option: any) => option.label.toLowerCase().includes('member'));
            }
            return {
                ...state,
                data: new ModelUserManagement({
                    id: null,
                    userId: null,
                    memberId: action.value.userLogin.memberId,
                    privilegeId: newOptionsUserPrivilegeId !== null ? newOptionsUserPrivilegeId[0].value : null,
                    userEmail: null,
                    userName: null,
                    userCategoryCode: action.value.userLogin.memberId === 1 ? '001' : '002',
                    userLevel: null,
                    userStatus: '001',
                    userStartDate: new Date().toISOString().slice(0, 10),
                    userEndDate: new Date('2099-12-31').toISOString().slice(0, 10),
                    userBlockedReason: null,
                    memberName: null,
                    privilegeName: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelUserManagement({
                    id: null,
                    userId: null,
                    memberId: null,
                    privilegeId: null,
                    userEmail: null,
                    userName: null,
                    userCategoryCode: null,
                    userLevel: null,
                    userStatus: null,
                    userStartDate: null,
                    userEndDate: null,
                    userBlockedReason: null,
                    memberName: null,
                    privilegeName: null,
                }),
                errorMessage: "",
            };
        case 'password':
            return {
                ...state,
                password: action.value,
            };
        case 'confirmPassword':
            return {
                ...state,
                confirmPassword: action.value,
            };
        case 'newOptionsPrivilege':
            return {
                ...state,
                newOptionsPrivilege: action.value,
            };

        default:
            throw new Error();
    }
}

import { useSelector } from "react-redux";
import { CheckUndefined, NumberWithCommas } from "../../../Utils";
import { Divider } from "antd";

const HeaderComponent = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    return (
        <div className='d-flex justify-content-between px-4 '>
            {
                props.label.map((item: any, i: any) => {
                    if (item.val === 'action') {
                        if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={`headerKey${i}`} className={`s-1418 Lato-700 ${item.width} text-center `}>
                                        {item.field}
                                    </p>
                                )
                            );
                        } else if (props.codeAccessPrivilegeDelete === '') {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={`headerKey${i}`} className={`s-1418 Lato-700 ${item.width} text-center `}>
                                        {item.field}
                                    </p>
                                )
                            );
                        } else if (props.codeAccessPrivilegeEdit === '') {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={`headerKey${i}`} className={`s-1418 Lato-700 ${item.width} text-center `}>
                                        {item.field}
                                    </p>
                                )
                            );
                        } else {
                            return <></>;
                        }
                    } else {
                        return (
                            props.checkIndex(`${item.val}`) && (
                                <p key={`headerKey${i}`} className={`s-1418 Lato-700 ${item.width} `}>
                                    {item.field}
                                </p>
                            )
                        );
                    }
                })
            }
        </div>
    )
}

export const TableQRAuthMonitoring = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    return (
        <div className='d-flex flex-column w-100 overflow mb-2'>
            <div className={`d-flex flex-column ${props.widthTable}`}>
                <HeaderComponent
                    codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
                    codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
                    label={props.label}
                    data={props.data}
                    position={props.position}
                    view={props.view}
                    customButtonAction={props.customButtonAction}
                    checkIndex={props.checkIndex}
                    widthTable={props.widthTable}
                    stateOptions={props.stateOptions}
                    handleNavigation={props.handleNavigation}
                />

                <Divider className="my-2" />
                {props.data.map((item: any, i: any) => {
                    if (i <= props.position - 1 && i >= props.position - props.view) {
                        return (
                            <div key={`dataKey${i}`} className={`d-flex justify-content-between align-items-center min-h-50px px-4 table-data-pointer ${i % 2 === 0 ? 'bg-f4' : ''}`} onClick={() => props.handleNavigation(item)}>
                                {props.label.map((element1: any) => {
                                    if (element1.val === 'no') {
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{i + 1}</p>;
                                    } else if (element1.val === 'prodFeatureName') {
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`prodFeatureInd`]) + ` - ` + CheckUndefined(item[`${element1.val}`])}</p>;
                                    } else if (element1.val === 'memberName') {
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`memberId`]) + ` - ` + CheckUndefined(item[`${element1.val}`])}</p>;
                                    } else {
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                                    }
                                })}
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
} 
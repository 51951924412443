import { interfaceIcon } from "../../../Assets/Images/MenuIcon";
import { Page1InterfaceAPIRoute, Page2InterfaceAPIRoute, Page3InterfaceAPIRoute, Page4InterfaceAPIRoute, Page5InterfaceAPIRoute, Page6InterfaceAPIRoute } from "../../../Routes";
import { Page30Code, Page31Code, Page32Code, Page33Code, Page34Code, Page54Code } from "../CodeMenu";
import { Page30Title, Page31Title, Page32Title, Page33Title, Page34Title, Page54Title } from "../TitleMenu";

export const Menu10 = {
    id: 'interface_API_sidebar',
    label: 'Interface API',
    icon: interfaceIcon,
    privilegesId: [
        Page30Code,
        Page31Code,
        Page32Code,
        Page33Code,
        Page34Code,
        Page54Code,
    ],
    itemSidebar: [
        {
            titleList: Page30Title,
            key: 'interfaceAPIOptions1',
            privilegeId: Page30Code,
            url: Page1InterfaceAPIRoute,
        },

        {
            titleList: Page31Title,
            key: 'interfaceAPIOptions2',
            privilegeId: Page31Code,
            url: Page2InterfaceAPIRoute,
        },
        {
            titleList: Page32Title,
            key: 'interfaceAPIOptions3',
            privilegeId: Page32Code,
            url: Page3InterfaceAPIRoute,
        },
        {
            titleList: Page33Title,
            key: 'interfaceAPIOptions4',
            privilegeId: Page33Code,
            url: Page4InterfaceAPIRoute,
        },
        {
            titleList: Page34Title,
            key: 'interfaceAPIOptions5',
            privilegeId: Page34Code,
            url: Page5InterfaceAPIRoute,
        },

        {
            titleList: Page54Title,
            key: 'interfaceAPIOptions6',
            privilegeId: Page54Code,
            url: Page6InterfaceAPIRoute,
        },
    ],
}
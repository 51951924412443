import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, CheckInputAZ09, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputStyle, inputStyleH25, inputStyleH25Multi, inputStyleReadOnly, labelStyle } from '../../Styles';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page52Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';

export const ModalMemberManagement = ({ ...props }) => {

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsCountryCodeAndCurrencyCode, optionsCommonStatusDetailCode, optionsInstitutionType, optionsRoleType } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
        dispatchStatePage({ type: 'memberRoleDtos', value: [] })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["countryCode", "memberName", "memberCode"]
        let limit = [3, 50, 6]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (nameState === "memberCode") {
                if (CheckInputAZ09.test(valueState.toString().toUpperCase())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        handleState('errorMessageList', [])
                        handleState('errorMessage', '');
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        let errorMessageList: any[] = [];
                        errorMessageList.push('MEMBER CODE must contain only uppercase letters (A-Z) and numbers (0-9)')
                        handleState('errorMessageList', errorMessageList)
                        handleState('errorMessage', 'MEMBER CODE must contain only uppercase letters (A-Z) and numbers (0-9)');
                    }
                }
            } else {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { memberName, memberCode, memberInstitutionType, countryCode, memberStatus, memberContactName, memberContactPhone, memberRoleDtos } = statePage
        const data = {
            memberName: memberName,
            memberCode: memberCode,
            memberInstitutionType: memberInstitutionType,
            countryCode: countryCode,
            memberStatus: memberStatus,
            memberRoleTypes: memberRoleDtos,
        };

        if (memberRoleDtos.length > 0) {
            const a = CheckAllFields([memberName, memberCode, memberInstitutionType, countryCode, memberStatus], ['Member Name', 'Member Code', 'Institution Type', 'Country Code', 'Status']);
            if (statePage.errorMessageList.length > 0) { }
            else {
                if (a !== '') {
                    handleState('errorMessage', a);
                } else {
                    handleState('errorMessage', ``);
                    API.post({
                        bodyCustom: data,
                        pathCustom: `${urlApi}`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    }).then((response) => {
                        dispatchStatePage({ type: 'clearData' })
                        setModal("visibleModalAddDelete")
                        reloadData()
                        API.post({
                            bodyCustom: { memberCode: memberCode },
                            pathCustom: `member_parameter`,
                            selectUrl: urlSelection.dashboard,
                            useToken: true,
                        })
                    }).catch((err) => handleState('errorMessage', err))
                }
            }
        } else {
            handleState('errorMessage', 'MEMBER ROLES TYPE is required');
        }
    }

    useEffect(() => {
        let selectedMemberRoles: any[] = []
        optionsRoleType.forEach((option: any) => {
            if (statePage.memberRoleDtos !== null) {
                if (statePage.memberRoleDtos.length > 0) {
                    if (statePage.memberRoleDtos.includes(option.value)) {
                        selectedMemberRoles.push(option)
                    }
                }
            }
        })
        handleState('selectedMemberRoles', selectedMemberRoles)
    }, [statePage.memberRoleDtos, optionsRoleType])

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-600px'>
                    <div className='d-flex min-h-30px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>member code <span className='text-danger'>*</span></p>
                                <input value={statePage.memberCode} onChange={(e) => handleState('memberCode', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>member name <span className='text-danger'>*</span></p>
                                <input value={statePage.memberName} onChange={(e) => handleState('memberName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>institution type <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInstitutionType} value={optionsInstitutionType.filter((option: any) => option.value === statePage.memberInstitutionType)} onChange={(e) => handleState('memberInstitutionType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>MEMBER ROLES TYPE <span className='text-danger'>*</span></p>
                                <Select
                                    className='flex-fill'
                                    closeMenuOnSelect={false}
                                    value={statePage.selectedMemberRoles}
                                    isMulti
                                    styles={inputStyleH25Multi}
                                    options={optionsRoleType}
                                    onChange={(items) => {
                                        let dataSelected: any[] = [];
                                        items.forEach((item: any) => {
                                            dataSelected.push(item.value);

                                        })
                                        handleState('memberRoleDtos', dataSelected)
                                    }}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>country CODE <span className='text-danger'>*</span></p>
                                <Select 
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'} 
                                    options={optionsCountryCodeAndCurrencyCode} 
                                    value={optionsCountryCodeAndCurrencyCode.filter((option: any) => option.value === statePage.countryCode)} 
                                    onChange={(e) => {
                                        handleState('countryCode', e.value)
                                        handleState('currencyCode', e.currencyCode)
                                    }} 
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>currency code</p>
                                <input value={statePage.currencyCode} readOnly className={inputStyleReadOnly} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                                <Select 
                                    styles={inputStyleH25} 
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode} 
                                    value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.memberStatus)} 
                                    onChange={(e) => handleState('memberStatus', e.value)} 
                                />
                            </div>

                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page52Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    memberId: '',
    memberName: '',
    memberCode: '',
    memberInstitutionType: '',
    countryCode: '',
    currencyCode: '',
    memberStatus: '001',
    memberContactName: '',
    memberContactPhone: '',
    memberRoleDtos: [],
    // 
    id: "",
    selectedMemberRoles: [],
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'selectedMemberRoles':
            return {
                ...state,
                selectedMemberRoles: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                memberId: '',
                memberName: '',
                memberCode: '',
                memberInstitutionType: '',
                countryCode: '',
                currencyCode: '',
                memberStatus: '001',
                memberContactName: '',
                memberContactPhone: '',
                //
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                memberId: action.value.memberId,
                memberName: action.value.memberName,
                memberCode: action.value.memberCode,
                memberInstitutionType: action.value.memberInstitutionType,
                countryCode: action.value.countryCode,
                currencyCode: action.value.currencyCode,
                memberStatus: action.value.memberStatus,
                memberContactName: action.value.memberContactName,
                memberContactPhone: action.value.memberContactPhone,
                //
                errorMessage: "",
            };
        // 
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        case 'memberCode':
            return {
                ...state,
                memberCode: action.value,
            };
        case 'memberInstitutionType':
            return {
                ...state,
                memberInstitutionType: action.value,
            };
        case 'countryCode':
            return {
                ...state,
                countryCode: action.value,
            };
        case 'currencyCode':
            return {
                ...state,
                currencyCode: action.value,
            };
        case 'memberStatus':
            return {
                ...state,
                memberStatus: action.value,
            };
        case 'memberContactName':
            return {
                ...state,
                memberContactName: action.value,
            };
        case 'memberContactPhone':
            return {
                ...state,
                memberContactPhone: action.value,
            };

        case 'memberRoleDtos':
            return {
                ...state,
                memberRoleDtos: action.value,
            };
        default:
            throw new Error();
    }
}

import { useEffect, useMemo, useReducer, useState } from "react";
import Modal from "react-modal";
import { NavLink, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { ResizableBox } from "react-resizable";
import { saveAs } from "file-saver";
import { format } from "date-fns";

import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

import { ModelLogAnalyzer } from "./ModelLogAnalyzer";
import { ModalDraggableDetailLogAnalyzer } from "./ModalDraggableDetailLogAnalyzer";
import { TableModalLogAnalyzer } from "../Log/Tables/TableModalLogAnalyzer";
import { API } from "../../Services";
import { urlSelection } from "../../Services/API";
import { logoutAction } from "../../Store/User";
import { loadingAction } from "../../Store/Loading";
import { SelectView } from '../../Components';
import { ModalStyle4, CheckUndefined } from "../../Utils";
import { close, iconDownloadCustom } from "../../Assets/Images/svg";
import { addData, checkIndex, deleteData, handleState, initFunction, setToggle } from "../../State";
import { ShowModalInterface } from "../../Interface";
import { changeStateQRAuthInquiry } from "../../Store/QRAuthInquirySlice";
import { Page2DashboardRoute, Page5DashboardRoute } from "../../Routes";
import { changeStateTransactionMonitoringStatus } from "../../Store/TransactionMonitoringStatusSlice";
import { transactionJourneyGlobalState } from "../../Store/TransactionJourney";

const ModalLogAnalyzer = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateGlobal = useSelector(transactionJourneyGlobalState);
  const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal, stateOptions } = props;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  let height_ = window.innerHeight - 60;
  let width_ = window.innerWidth - 80;
  let height__ = window.innerHeight - 20;
  let width__ = window.innerWidth - 20;

  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    let limit = `limit=${state.view}&`;
    let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
    let finalParameter = `${limit}${offset}`;
    if (needRefreshPage === true) {
      API.get({
        bodyCustom: null,
        pathCustom: `dashboard/log/${id}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.log, // use url log
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: "searchData", value: response.data });
        dispatch({ type: "totalPages", value: response.detail.totalPage });
        dispatch({ type: "totalData", value: response.detail.total });
      });
    } else {
      API.get({
        bodyCustom: null,
        pathCustom: `dashboard/log/${id}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.log, // use url log
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: "data", value: response.data });
        dispatch({ type: "totalPages", value: response.detail.totalPage });
        dispatch({ type: "totalData", value: response.detail.total });
      });
    }
  };

  const handleRowClick = (row: any) => {
    dispatch({
      type: 'showModal',
      value: {
        idModal: row.original.id,
        showModal: false,
        itemModal: row.original,
        index: row.index
      }
    })
  };

  const handleNavigateByGlobalId = (isQR: boolean, globalId: string, startDate: string, endDate: string, startTime: string, endTime: string) => {
    let newWindow: any;
    if(!isQR) {
      newWindow = window.open(`${Page2DashboardRoute}`, '_blank');
    } else {
      newWindow = window.open(`${Page5DashboardRoute}`, '_blank');
    }

    newWindow.opener.data = {
      globalId: globalId,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime
    };
  };

  // useEffect(() => {
  //   if (id !== "") {
  //     initFunctionCustom(false);
  //   }
  // }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (props.visibleModalAddDelete) { // Disable scrolling on the background page
      document.body.style.overflow = "hidden";
    } else { // Restore scrolling when the modal is closed
      document.body.style.overflow = "unset";
    }

    return () => { // Cleanup function to reset overflow when component unmounts
      document.body.style.overflow = "unset";
    };
  }, [props.visibleModalAddDelete]);

  useEffect(() => {
    if (!props.visibleModalAddDelete) {
      dispatch({ type: "hideAllModals" });
    }
  }, [props.visibleModalAddDelete]);

  useEffect(() => {
    dispatch({ type: "visibleModalAddDelete", value: visibleModalAddDelete });
    dispatch({ type: "resize", value: { width: width_, height: height_ } }); // set to original size.
  }, [visibleModalAddDelete !== state.visibleModalAddDelete]);

  useEffect(() => {
    dispatch({ type: "id", value: id });
    if (id !== "") {
      initFunctionCustom(true);
    }
  }, [id]);

  useEffect(() => {
    dispatch({ type: "resize", value: { width: width_, height: height_ } });
  }, [height_, width_]);

  useEffect(() => {
    let currentPage: number = parseInt((pagination.pageIndex / pagination.pageSize).toString());
    dispatch({ type: "currentPage", value: currentPage });
  }, [state.totalData, pagination.pageIndex, pagination.pageSize]);

  const exportToCSVComponent = () => { };
  const addDataComponent = () => addData(dispatch);
  const deleteDataComponent = (item: ModelLogAnalyzer) => deleteData(item.id, item, dispatch);
  const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state);
  const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch);
  const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state);
  const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state);
  const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage);
  const actionShowModal = (idModal: any, item: any) => dispatch({ type: "showModal", value: { idModal, showModal: item.showModal, itemModal: item } });
  const actionIndexModal = (idModal: any) => dispatch({ type: "changeIndex", value: idModal });
  const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false);

  const customButtonAction = (item: ModelLogAnalyzer, width: string | any) => {
    return <div className={`d-flex justify-content-around align-items-center ${width}`}></div>;
  };

  const modalUpdateComponentPage = () => {
    return (
      <>
        {state.savedShowModal.map((element1: any) => {
          return <ModalDraggableDetailLogAnalyzer
            key={element1.idModal}
            id={element1.idModal}
            index={element1.index}
            idModal={element1.idModal}
            state={state}
            urlApi={state.urlApi}
            showModal={element1.showModal}
            reloadData={() => reloadDataComponent(false)}
            actionShowModal={() => actionShowModal(element1.idModal, element1)}
            actionIndexModal={() => actionIndexModal(element1.idModal)}
            itemModal={element1.itemModal}
            stateOptions={stateOptions}
          />;
        })}
      </>
    );
  };

  const downloadFile = () => {
    API.getFile({
      bodyCustom: null,
      pathCustom: `dashboard/log/download/${dataModal.globalId}`,
      selectUrl: urlSelection.log, // use url log
      useToken: true,
    })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/zip" });
        saveAs(file, `${dataModal.globalId}.zip`);
      })
      .catch((err) => { });
  };

  const onResize = (event: any, propsSize: any) => {
    const { node, size, handle } = propsSize;
    if (size.width > 100 && size.height > 100) {
      dispatch({ type: "resize", value: { width: size.width, height: size.height } });
    }
  };

  const columns = useMemo<MRT_ColumnDef<ModelLogAnalyzer>[]>(
    () => [
      {
        accessorKey: 'logDateTime', // date time
        header: 'Date Time',
        maxSize: 100,
        Cell: ({ row }: any) => `${row.original.logDateTime}`
      },
      {
        accessorKey: 'productInd', // product indicator
        header: 'P. Indicator',
        maxSize: 150,
        enableSorting: false,
        Cell: ({ row }: any) => {
          if (row.original.productInd !== '') {
            return `${row.original.productInd} - ${row.original.productFeatureName}`
          } else {
            return `-`
          }
        }
      },
      {
        accessorKey: 'globalId', // global id
        header: 'Global ID',
        minSize: 200,
        maxSize: 200,
        enableSorting: false,
        Cell: ({ row }: any) => {
          return (
            <div
            className={`s-1418 Lato-400 word-wrap text-info`}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();

                if (CheckUndefined(row.original.globalId) !== '') {
                  if (!['QR000', 'QR001', 'QR009'].includes(row.original.productInd)) {
                    handleNavigateByGlobalId(
                      false,
                      row.original.globalId,
                      format(new Date(row.original.logDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                      format(new Date(row.original.logDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                      '00:00:01',
                      '23:59:59'
                    );
                  } else {
                    handleNavigateByGlobalId(
                      true,
                      row.original.globalId,
                      format(new Date(row.original.logDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                      format(new Date(row.original.logDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                      '00:00:01',
                      '23:59:59'
                    );
                  }
                }
              }}
            >
              <NavLink to=''>
                {CheckUndefined(row.original.globalId)}
              </NavLink>
            </div>
          );
        }
      },
      {
        accessorKey: 'authGwStatus', // api gateway status
        header: 'API G.W.',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.authGwStatus}` == '' ? data = '-' : data = `${row.original.authGwStatus}`
          return data;
        }
      },
      {
        accessorKey: 'reasonCode', // reason code
        header: 'R. Code',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.reasonCode}` == '' ? data = '-' : data = `${row.original.reasonCode}`
          return data;
        }
      },
      {
        accessorKey: 'reasonMsg', // reason message
        header: 'R. Message',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.reasonMsg}` == '' ? data = '-' : data = `${row.original.reasonMsg}`
          return data;
        }
      },
      {
        accessorKey: 'logChannel', // channel
        header: 'Channel',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.logChannel}` == '' ? data = '-' : data = `${row.original.logChannel}`
          return data;
        }
      },
      {
        accessorKey: 'nodeSrc', // from
        header: 'From',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => `${row.original.nodeSrc}`
      },
      {
        accessorKey: 'nodeProc', // proc
        header: 'Proc',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => `${row.original.nodeProc}`
      },
      {
        accessorKey: 'nodeDst', // to
        header: 'To',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => `${row.original.nodeDst}`
      },
      {
        accessorKey: 'authAmount', // amount
        header: 'Amount',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          if ((row.original.currencyName !== '') && (row.original.authAmount !== '')) { // if currency name or auth amount is empty, return "-"
            const amount = row.original.authAmount;
            let formattedAmount = '';

            if (amount.includes('.')) {
              formattedAmount = parseFloat(amount).toFixed(2);
            } else {
              const length = amount.length;
              const integerPart = amount.slice(0, length - 2);
              const decimalPart = amount.slice(length - 2);
              formattedAmount = `${parseInt(integerPart || '0')}.${decimalPart}`;
            }

            return `${row.original.currencyName} ${formattedAmount}`
          } else {
            return `-`
          }
        }
      },
      {
        accessorKey: 'cpan', // wallet user pan
        header: 'Wallet User Pan',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
            let data;
            `${row.original.cpan}` == '' ? data = '-' : data = `${row.original.cpan}`
            return data;
        }
    },
    {
        accessorKey: 'midMpan', // mid
        header: 'MID',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
            let data;
            `${row.original.midMpan}` == '' ? data = '-' : data = `${row.original.midMpan}`
            return data;
        }
    },
      {
        accessorKey: 'memberSrcName', // source inst
        header: 'Source Inst.',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.memberSrcName}` == '' ? data = '-' : data = `${row.original.memberSrcName}`
          return data;
        }
      },
      {
        accessorKey: 'memberDstName', // destination inst
        header: 'Dest Inst.',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.memberDstName}` == '' ? data = '-' : data = `${row.original.memberDstName}`
          return data;
        }
      },
      {
        accessorKey: 'logSvcRcvDateTime', // log receive time
        header: 'Rcv. Time',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.logSvcRcvDateTime}` == '' ? data = '-' : data = `${row.original.logSvcRcvDateTime}`
          return data;
        }
      },
      {
        accessorKey: 'logSvcSndDateTime', // log send time
        header: 'Snd. Time',
        maxSize: 100,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.logSvcSndDateTime}` == '' ? data = '-' : data = `${row.original.logSvcSndDateTime}`
          return data;
        }
      },
      {
        accessorKey: 'logName', // log file name
        header: 'Log File Name',
        minSize: 150,
        maxSize: 150,
        enableSorting: false,
        Cell: ({ row }: any) => {
          let data;
          `${row.original.logName}` == '' ? data = '-' : data = `${row.original.logName}`
          return data;
        }
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    // 1. Column Definitions
    columns,

    // 2. Data & State
    data: state.data,
    state: { pagination },
    initialState: {
      density: 'compact',
    },

    // 3. Editing & Row Actions
    enableColumnActions: false,
    enableClickToCopy: false,
    enableEditing: false,
    enableHiding: false,
    enableRowActions: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableGlobalFilterRankedResults: false,

    // 4. Pagination & Row Numbers
    manualPagination: true,
    rowCount: state.totalData,
    onPaginationChange: setPagination,
    enableRowNumbers: true,
    paginationDisplayMode: 'pages',
    muiPaginationProps: {
      color: 'standard',
      showRowsPerPage: false,
      size: 'small',
    },

    // 5. Positioning & Layout
    enableFullScreenToggle: false,
    enableColumnFilterModes: false,
    enableColumnFilters: false,

    muiTableBodyRowProps: ({ row, table }) => {

      const sortedRows: any = table.getRowModel().rows;
      const rowIndex = sortedRows.indexOf(row);

      return {
        onClick: () => dispatch({
          type: 'showModal',
          value: {
            idModal: row.original.id,
            showModal: false,
            itemModal: row.original,
            index: row.index
          }
        }),
        sx: {
          cursor: 'pointer',
          backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
          '&:hover': {
            backgroundColor: 'rgba(255, 0, 0, 0.9)',
            '& .MuiTableCell-root': {
              color: 'white', // Change text color to white on hover
            },
          },
          fontSize: '12px',
          fontFamily: 'system-ui, sans-serif',
          zIndex: 0,
        },
      };
    },

    muiTableHeadCellProps: {
      sx: {
        fontSize: '12px', // Set font size for header cells
        fontWeight: 'bold', // Optional: make header font bold
        fontFamily: 'system-ui, sans-serif', // Set font family for header cells
        borderRight: '1px solid rgba(218, 217, 219, .7)',
        borderTop: '1px solid rgba(218, 217, 219, .7)',
      },
    },

    muiTableBodyCellProps: {
      sx: {
        fontSize: '12px', // Set font size for table cells
        fontFamily: 'system-ui, sans-serif', // Set font family for table cells
        borderRight: '1px solid rgba(218, 217, 219, .7)',
        zIndex: 0,
      },
    },

    muiSearchTextFieldProps: {
      placeholder: 'Search all column',
      size: 'small',
      sx: {
        fontSize: '12px',
        fontFamily: 'system-ui, sans-serif'
      }
    },

    muiTopToolbarProps: {
      sx: {
        zIndex: 0,
      }
    },

    muiBottomToolbarProps: {
      sx: {
        zIndex: 0,
        fontSize: '12px',
      },
    },

    // 6. Event Handler
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="flex flex-wrap align-items-center">
        <div className="row justify-content-between py-1">
          <div className="col-6 d-flex align-items-center">
            <p className="Lato-400 s-2024 c-29">List</p>
            <p className="br-5 bd-ea bg-f4 px-3 py-2 c-29 Lato-400 s-1418 mx-2">{state.totalData}</p>
          </div>
        </div>
        <Button
          disabled={state.totalData <= 0}
          onClick={downloadFile}
          startIcon={<FileDownloadIcon />}
          className="h-30px hover-button-red"
          sx={{
            color: '#f15e5e'
          }}
        >
          Download
        </Button>
      </div>
    ),
  });

  return (
    <>
      {props.visibleModalAddDelete === true && (
        <Modal
          isOpen={visibleModalAddDelete}
          style={ModalStyle4}
        >
          {/* header: title & close button */}
          <div className="d-flex min-h-20px justify-content-between align-items-center mt-1 mb-3">
            <p className="w-700 Lato-700 c-29 s-2024">{id}</p>
            <div className="pointer" onClick={(e) => props.setModal("visibleModalAddDelete")}>
              {close}{" "}
            </div>
          </div>

          {/* table */}
          <div className="d-flex flex-column align-items-center h-90persen" style={{ overflow: "hidden" }}>
            <div className="position-relative" style={{ overflow: "auto", width: "100%" }}>
              <MaterialReactTable table={table} />
              {/* <div className='position-absolute' style={{ bottom: '12px' }}>
                  <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                </div> */}
            </div>
            {state.savedShowModal.length > 0 && (
              modalUpdateComponentPage()
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

const initialState = {
  titlePage: "",
  privilegeAccess: { view: "101", add: "102", edit: "103", delete: "104", download: "105" },
  //
  visibleModalAddDelete: false,
  savedShowModal: [], // new
  //
  id: "",
  data: [],
  dataModal: null,
  search: "",
  view: 20,
  position: 20,
  list_view: [5, 10, 20],
  totalData: 0, // new
  totalPages: 0,
  currentPage: 0,
  insertPage: false,
  deleteItem: false,
  //
  widthTable: 2500,
  dataLength: 0, // zIndex
  //
  width: 1200,
  height: 600,
};

// logId ModelLogAnalyzer
function reducer(state: any, action: any) {
  let showDataResult: ShowModalInterface[] = [];
  let dataWhereChangeToIdGeneral: any[] = [];
  let newSavedShowModal: ShowModalInterface[] = [];
  switch (action.type) {
    case "data":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelLogAnalyzer) => {
            element1.id = element1.logId!;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case "searchData":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelLogAnalyzer) => {
            element1.id = element1.logId!;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        position: state.view,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case "showModal":
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            let conditionShowModalFind = false;
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value.idModal) {
                conditionShowModalFind = true;
              }
            });
            if (conditionShowModalFind === false) {
              newSavedShowModal.push({ idModal: action.value.idModal, showModal: !action.value.showModal, itemModal: action.value.itemModal, index: state.dataLength + 99 });
              newSavedShowModal.push(...state.savedShowModal);
            } else {
              state.savedShowModal.forEach((element1: ShowModalInterface) => {
                if (element1.idModal === action.value.idModal) {
                  if (element1.showModal === false) {
                    // will to show
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !element1.showModal, itemModal: action.value.itemModal, index: state.dataLength + 99 });
                  } else {
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !element1.showModal, itemModal: action.value.itemModal, index: 99 });
                  }
                } else {
                  newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 });
                }
              });
            }
          } else {
            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !action.value.showModal, itemModal: action.value.itemModal, index: 99 });
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case "changeIndex":
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value) {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 });
              } else {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 });
              }
            });
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case "afterUpdate":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelLogAnalyzer) => {
            showDataResult.push({ idModal: element1.logId!, showModal: false, itemModal: element1, index: 1 });
            element1.id = element1.logId!;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        showModal: showDataResult,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    //
    case "hideAllModals":
      return {
        ...state,
        savedShowModal: state.savedShowModal.map((element1: ShowModalInterface) => ({
          ...element1,
          showModal: false,
        })),
      };
    //
    case "widthTable":
      return {
        ...state,
        widthTable: action.value,
      };
    case "visibleModalAddDelete":
      return {
        ...state,
        visibleModalAddDelete: action.value,
      };
    case "id":
      return {
        ...state,
        id: action.value,
      };
    case "label":
      return {
        ...state,
        label: action.value,
      };
    case "toggle":
      return {
        ...state,
        toggle: action.value,
      };
    case "complete_toggle":
      return {
        ...state,
        complete_toggle: action.value,
      };
    case "list_column_name":
      return {
        ...state,
        list_column_name: action.value,
      };
    case "privilegeAccess":
      return {
        ...state,
        privilegeAccess: action.value,
      };
    case "deleteItem":
      return {
        ...state,
        deleteItem: action.value,
      };
    case "list_view":
      return {
        ...state,
        list_view: action.value,
      };
    case "view":
      return {
        ...state,
        view: action.value,
      };
    case "position":
      return {
        ...state,
        position: action.value,
      };
    case "search":
      return {
        ...state,
        search: action.value,
      };
    case "dataModal":
      return {
        ...state,
        dataModal: action.value,
      };
    case "insertPage":
      return {
        ...state,
        insertPage: action.value,
      };
    case "addData":
      return {
        ...state,
        id: "",
        dataModal: null,
        visibleModalAddDelete: !state.visibleModalAddDelete,
      };
    case "editData":
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        visibleModalAddDelete: true,
      };
    case "deleteData":
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        deleteItem: true,
      };
    // new
    case "totalData":
      return {
        ...state,
        totalData: action.value,
      };
    case "totalPages":
      return {
        ...state,
        totalPages: action.value,
      };
    case "currentPage":
      return {
        ...state,
        currentPage: action.value,
      };
    case "finalAPI":
      return {
        ...state,
        finalAPI: action.value,
      };
    case "resize":
      return {
        ...state,
        width: action.value.width,
        height: action.value.height,
      };
    default:
      throw new Error();
  }
}

export default ModalLogAnalyzer;

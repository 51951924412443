import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerRighIcon = ({ ...props }) => {
    const {
        maxDate,
        selected,
        onChange,
        needTimeInput = true,
        popperClassName = "custom-datepicker-popper"
    } = props;
    return (
        <div className="w-100" style={{ position: "relative", display: "inline-block"}}>
            {needTimeInput ? 
            <> 
                <DatePicker
                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                    wrapperClassName="w-100 h-100"
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeFormat="HH:mm"
                    showTimeSelect
                    showYearDropdown
                    timeIntervals={5}
                    todayButton="Today"
                    maxDate={maxDate}
                    selected={selected}
                    onChange={onChange}
                    popperClassName={popperClassName}
                    placeholderText="yyyy-mm-dd hh:mm:ss"
                />
                <span
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "46%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    fontSize: "14px",
                }}
                >
                    <svg 
                        viewBox="64 64 896 896" 
                        focusable="false" 
                        data-icon="calendar" 
                        width="1em" 
                        height="1em" 
                        fill="#0000003f" 
                        aria-hidden="true">
                            <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                            </path>
                    </svg>
                </span>
            </> 
            
            : 
            
            <> 
                <DatePicker
                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                    wrapperClassName="w-100 h-100"
                    dateFormat="yyyy-MM-dd"
                    showYearDropdown
                    todayButton="Today"
                    maxDate={maxDate}
                    selected={selected}
                    onChange={onChange}
                    popperClassName={popperClassName}
                    placeholderText="yyyy-mm-dd"
                />
                <span
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "46%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    fontSize: "14px",
                }}
                >
                    <svg 
                        viewBox="64 64 896 896" 
                        focusable="false" 
                        data-icon="calendar" 
                        width="1em" 
                        height="1em" 
                        fill="#0000003f" 
                        aria-hidden="true">
                            <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                            </path>
                    </svg>
                </span>
            </> 
            }
        </div>
    );
}
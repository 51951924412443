import { useEffect, useReducer, useState } from "react";
import { API } from "../../Services";
import { urlSelection } from "../../Services/API";
import { useDispatch, useSelector } from "react-redux";
import { stateGlobalTransactionMonitoringStatus } from "../../Store/TransactionMonitoringStatusSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { changeStateAlertHistory, stateGlobalAlertHistory } from "./AlertHistorySlice";
import { CheckUndefinedStringEmpty, NumberWithCommas } from "../../Utils";
import { FilterComponentALertHistory } from "./FilterComponentAlertHistory";
import { initFunction, reducerOptions, stateOptions } from "../../State";
import { InterfaceOptionsAlertHistory } from "./InterfaceOptionsAlertHistory";
import { Select } from "antd";
import { inputStyleH25 } from "../../Styles";
import { AlertHistoryTable } from "./AlertHistoryTable";
import { red } from "@mui/material/colors";
import { HiArrowLongDown, HiArrowLongUp } from "react-icons/hi2";
import { arrowHeadDownIcon, arrowHeadUpIcon } from "../../Assets/Images/svg";


export const AlertHistory = () => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });
    const globalState = useSelector(stateGlobalAlertHistory);
    const privilegeAccess = useSelector(
        (state: any) => state.user.privilegeAccess
    );
    const [stateOptions, dispatchOptions] = useReducer(
        reducerOptions,
        initialStateOptions
    );


    const { search, filterStartDate, filterEndDate, svrId, alertCat, alertEventType, alertSeverity, startTime, endTime } = globalState;

    const handleStateGlobal = (nameState: any, valueState: any) => {
        dispatchGlobal(
            changeStateAlertHistory({ [nameState]: valueState })
        );
    };

    const resetAllFilter = () => {
        let dateNow = new Date().toISOString().slice(0, 10);
        handleStateGlobal('filterStartDate', dateNow);
        handleStateGlobal('filterEndDate', dateNow);
        handleStateGlobal('svrId', '');
        handleStateGlobal('alertCat', '');
        handleStateGlobal('alertEventType', '');
        handleStateGlobal('alertSeverity', '');
    };


    useEffect(() => {
        initFunctionCustom(true);
    }, [search, state.view]);

    useEffect(() => {
        initFunctionCustom(false);
    }, [pagination.pageIndex]);

    useEffect(() => {
        setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
    }, [state.view]);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (
                privilegeAccess.includes(state.privilegeAccess.view) === false
            ) {
                navigate('/warning', { replace: true });
            }
        }
    }, [privilegeAccess]);

    useEffect(() => {
        initFunction(
            dispatchOptions,
            state,
            () => { },
            null,
            InterfaceOptionsAlertHistory,
            dispatchGlobal
        );
    }, [search]);

    const reloadDataComponent = (needRefreshPage: any) => {
        initFunction(
            dispatchOptions,
            state,
            () => initFunctionCustom(needRefreshPage),
            null,
            InterfaceOptionsAlertHistory,
            dispatchGlobal
        );
    }



    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let apiUrl = 'alert-history?';

        if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
            apiUrl =
                apiUrl +
                `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
            apiUrl =
                apiUrl + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(startTime) !== '') {
            apiUrl =
                apiUrl +
                `startTime=${CheckUndefinedStringEmpty(startTime)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(endTime) !== '') {
            apiUrl =
                apiUrl + `endTime=${CheckUndefinedStringEmpty(endTime)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(svrId) !== '') {
            apiUrl =
                apiUrl +
                `svrId=${CheckUndefinedStringEmpty(
                    svrId
                )}&`;
        }
        if (CheckUndefinedStringEmpty(alertCat) !== '') {
            apiUrl =
                apiUrl +
                `alertCat=${CheckUndefinedStringEmpty(
                    alertCat
                )}&`;
        }
        if (CheckUndefinedStringEmpty(alertEventType) !== '') {
            apiUrl =
                apiUrl +
                `alertEventType=${CheckUndefinedStringEmpty(
                    alertEventType
                )}&`;
        }
        if (CheckUndefinedStringEmpty(alertSeverity) !== '') {
            apiUrl =
                apiUrl +
                `alertSeverity=${CheckUndefinedStringEmpty(
                    alertSeverity
                )}&`;
        }
        apiUrl = apiUrl + `limit=${state.view}&`;
        apiUrl =
            apiUrl + `offset=${pagination.pageIndex * state.view}&`;

        // console.log("url", apiUrl)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                setPagination({ pageSize: state.view, pageIndex: 0 })
                dispatch({ type: 'searchData', value: response?.data });
                dispatch({ type: 'summary', value: response?.summary });
                dispatch({
                    type: 'totalPages',
                    value: response?.detail.totalPage,
                });
                dispatch({ type: 'totalData', value: response?.detail.total });
            });
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response?.data });
                dispatch({
                    type: 'totalPages',
                    value: response?.detail.totalPage,
                });
                dispatch({ type: 'totalData', value: response?.detail.total });
            });
        }
    };

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                Alert History
            </h1>
            {/* <div className="row d-flex justify-content-between"> */}
            <div style={{ marginRight: '100px', marginLeft: '100px' }}>
                <div className='d-flex my-2 justify-content-around'>
                    {state.dataSummary?.map((item: any, index: number) => {
                        if (item.svrId === 'GW') {
                            return (
                                <div className='px-0' style={{width:'15%'}}>
                                    <div className='bd-d4 px-2 py-1' style={{ borderRadius: '8px' }}>
                                        <div className='d-flex justify-content-between align-items-baseline'>
                                            <p className='s-2024 me-1 fw-bold'> {CheckUndefinedStringEmpty(item.rangeCount)}</p>
                                            <div className='d-flex px-2 br-50 align-items-center gap-1' style={{
                                                backgroundColor: item.percentageChange > 0
                                                    ? '#55b937'
                                                    : item.percentageChange < 0
                                                        ? '#e83d44'
                                                        : '#7f7f7f'
                                            }}>
                                                {item.percentageChange > 0
                                                    ? (item.percentageChange < 0 ? arrowHeadUpIcon : arrowHeadDownIcon)
                                                    : ''}
                                                <p className='s-1418 c-ff'>{CheckUndefinedStringEmpty(item.percentageChange)} %</p>
                                            </div>
                                        </div>
                                        <p className='pt-1 s-1620 c-545'>API {CheckUndefinedStringEmpty(item.svrId)}</p>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className='px-0' style={{width:'15%'}}>
                                    <div className='bd-d4 px-2 py-1' style={{ borderRadius: '8px' }}>
                                        <div className='d-flex justify-content-between align-items-baseline'>
                                            <p className='s-2024 me-1 fw-bold'> {CheckUndefinedStringEmpty(item.rangeCount)}</p>
                                            <div className='d-flex px-2 br-50 align-items-center gap-1' style={{
                                                backgroundColor: item.percentageChange > 0
                                                    ? '#55b937'
                                                    : item.percentageChange < 0
                                                        ? '#e83d44'
                                                        : '#7f7f7f'
                                            }}>
                                                {item.percentageChange > 0
                                                    ? (item.percentageChange < 0 ? arrowHeadUpIcon : arrowHeadDownIcon)
                                                    : ''}
                                                <p className='s-1418 c-ff'>{CheckUndefinedStringEmpty(item.percentageChange)} %</p>
                                            </div>
                                        </div>
                                        <p className='pt-1 s-1620 c-545'>{CheckUndefinedStringEmpty(item.svrId)}</p>
                                    </div>
                                </div>)
                        }
                        {/* <div className="row">
                           <div className="col border p-4 border-1" style={{ borderRadius: '0px' }}>
                               Column
                           </div>
                           <div className="col border p-4 border-1" style={{ borderRadius: '0px' }}>
                               Column
                           </div>
                       </div>
                       <div className="row">
                           <div className="col border p-4 border-1" style={{ borderRadius: '0px' }}>
                               Column
                           </div>
                           <div className="col border p-4 border-1" style={{ borderRadius: '0px' }}>
                               Column
                           </div>
                       </div> */}
                    })}
                </div>
            </div>

            <FilterComponentALertHistory state={state}
                handleStateGlobal={handleStateGlobal}
                initFunctionCustom={initFunctionCustom}
                stateOptions={stateOptions}
                reloadData={() => reloadDataComponent(false)}
            />


            <AlertHistoryTable
                state={state}
                stateOptions={stateOptions}
                // stateCustomComponent={stateCustomComponent}
                // handleStateParent={handleStateComponent}
                handleStateGlobal={handleStateGlobal}
                // resetFilterStatus={resetFilterStatus}
                initFunctionCustom={initFunctionCustom}
                // handleNavigation={handleNavigation}
                // modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
            />

        </>
    )
}
const initialStateOptions = { ...stateOptions };

const initialState = {
    data: [],
    privilegeAccess: {
        view: '1011',
        add: '',
        edit: '',
        delete: '',
        download: '',
    },
    deleteItem: false,
    dataModal: null,
    // SearchByPlaceHolder:
    //     'Issuer, Issuer Switcher, Acquirer Switcher, Acquirer, Currency Code, Transaction ID',

    id: '',
    search: '',
    view: 15,
    position: 15,
    list_view: [10, 15, 20, 30, 40, 50, 100],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    errorMessage: '',

    //
    dataLength: 0, // zIndex

};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value,
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value,
            };

        case 'list_view':
            return {
                ...state,
                list_view: action.value,
            };
        case 'view':
            return {
                ...state,
                view: action.value,
            };
        case 'position':
            return {
                ...state,
                position: action.value,
            };
        case 'search':
            return {
                ...state,
                search: action.value,
            };
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'dataSummary':
            return {
                ...state,
                dataSummary: action.value,
            }
        case 'summary':
            return {
                ...state,
                dataSummary: action.value,
            }
        case 'id':
            return {
                ...state,
                id: action.value,
            };

        case 'searchData':
            return {
                ...state,
                data: action.value,
                position: 15,
            };

        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        // new

        case 'totalData':
            return {
                ...state,
                totalData: action.value,
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value,
            };

        default:
            throw new Error();
    }
};

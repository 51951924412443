import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { history } from "../History";
import Cookies from 'universal-cookie';
import { useEffect, useState } from "react";
import * as CryptoJS from 'crypto-js';
import { cookiesGlobal } from "../State/Cookies";
import { Page2FrameworkRoute } from "../Routes";
import { transactionJourneyGlobalState, handleStateAction } from "../Store/TransactionJourney";

export const PrivateRoute = ({ ...props }) => {
    const user = useSelector((state: any) => state.user.user);
    const location = useLocation();
    const dispatchGlobal = useDispatch();

    if (location.pathname === Page2FrameworkRoute) {
        dispatchGlobal(handleStateAction({ name: 'locationSearch', value: location.search }));
    }

    if (!user) return <Navigate to="/login" state={{ from: history.location }} />;
    return props.children;

}


import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputStyle, inputStyleH25, inputStyleReadOnlyH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page47Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableCountryCodeManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsCommonStatusDetailCode } = props.stateOptions


    const draggleRef = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'countryCode') {
            if (valueState.split('').length < 4) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'currencyCode') {
            if (valueState.split('').length < 4) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'countryIso2') {
            if (valueState.split('').length < 3) dispatchStatePage({ type: nameState, value: valueState })
        } else if (nameState === 'countryIso3') {
            if (valueState.split('').length < 4) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            let names = ["countryName", "countryCurrencyName"]
            let limit = [50, 50]
            let index = names.indexOf(nameState)
            if (index !== -1) {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }
    const updateDataAPI = () => {
        const { countryCode, countryName, countryIso2, countryIso3, currencyCode, countryCurrencyName, countryStatus } = statePage;
        const data = {
            countryCode: countryCode,
            countryName: countryName,
            countryIso2: countryIso2,
            countryIso3: countryIso3,
            currencyCode: currencyCode,
            countryCurrencyName: countryCurrencyName,
            countryStatus: countryStatus,
        };
        const a = CheckAllFields([countryCode, countryName, countryIso2, countryIso3, currencyCode, countryCurrencyName, countryStatus], ['Country Code', 'Country Name', 'ISO2', 'ISO3', 'Currency Code', 'Currency Name', 'Status']);
        if (a !== '') {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', '');
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }

    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className='col-6'>
                        <p className={labelStyle}>Country Code <span className='text-danger'>*</span></p>
                        {statePage.countryId ? (
                            <input value={statePage.countryCode} disabled className={inputStyleReadOnlyH25} />
                        ) : (
                            <input value={statePage.countryCode} onChange={(e) => handleState('countryCode', e.target.value)} className={inputStyle} />
                        )}
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Country Name <span className='text-danger'>*</span></p>
                        <input value={statePage.countryName} onChange={(e) => handleState('countryName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>ISO2 <span className='text-danger'>*</span></p>
                        <input value={statePage.countryIso2} onChange={(e) => handleState('countryIso2', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>ISO3 <span className='text-danger'>*</span></p>
                        <input value={statePage.countryIso3} onChange={(e) => handleState('countryIso3', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Currency Code <span className='text-danger'>*</span></p>
                        <input value={statePage.currencyCode} onChange={(e) => handleState('currencyCode', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Currency Name <span className='text-danger'>*</span></p>
                        <input value={statePage.countryCurrencyName} onChange={(e) => handleState('countryCurrencyName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.countryStatus)} onChange={(e) => handleState('countryStatus', e.value)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page47Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    countryId: '',
    countryCode: '',
    countryName: '',
    countryIso2: '',
    countryIso3: '',
    currencyCode: '',
    countryCurrencyName: '',
    countryStatus: '001',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        // 
        case 'clearData':
            return {
                ...state,
                countryId: '',
                countryCode: '',
                countryName: '',
                countryIso2: '',
                countryIso3: '',
                currencyCode: '',
                countryCurrencyName: '',
                countryStatus: '001',
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                countryId: action.value.countryId,
                countryCode: action.value.countryCode,
                countryName: action.value.countryName,
                countryIso2: action.value.countryIso2,
                countryIso3: action.value.countryIso3,
                currencyCode: action.value.currencyCode,
                countryCurrencyName: action.value.countryCurrencyName,
                countryStatus: action.value.countryStatus,
                errorMessage: "",
            };
        // 
        case 'countryId':
            return {
                ...state,
                countryId: action.value,
            };
        case 'countryCode':
            return {
                ...state,
                countryCode: action.value,
            };
        case 'countryName':
            return {
                ...state,
                countryName: action.value,
            };
        case 'countryIso2':
            return {
                ...state,
                countryIso2: action.value,
            };
        case 'countryIso3':
            return {
                ...state,
                countryIso3: action.value,
            };
        case 'currencyCode':
            return {
                ...state,
                currencyCode: action.value,
            };
        case 'countryCurrencyName':
            return {
                ...state,
                countryCurrencyName: action.value,
            };
        case 'countryStatus':
            return {
                ...state,
                countryStatus: action.value,
            };
        default:
            throw new Error();
    }
}





import { DatePicker, DatePickerProps, Divider, TimePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import moment from 'moment';
import { useCallback, useReducer, forwardRef, useEffect, useImperativeHandle } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { checklistButtonIcon, close, undo_icon } from '../../Assets/Images/svg';
import { FooterModalCreate3, ModalCannotDelete, ModalDelete } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import { inputDescriptionStyle, inputStyle, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAllFields, CheckInputAZaz09Space, ModalStyle } from '../../Utils';
import { ModelBatchScheduler } from '../Job/Models';
import { Page53Title } from '../../State/Menu/TitleMenu';
import { updateEdge } from 'reactflow';

const ModalJobScheduleUpdate = forwardRef((props: any, ref: any) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, setModal, urlApi, stateParent, getDataAllJobDetail, functionSetModal, functionSetModal2, handleStateParent } = props
    const { dataUpdate } = stateParent

    const {
        optionsLogLevelDetailCode,
        optionsBatchProgramStatusDetailCode,
        optionsCommonStatusDetailCode,
        optionsBatchProgramTypeDetailCode,
        optionsBatchFromAPIBatch,
        optionsBatchRegistrationTypeDetailCode,
        optionsBatchSchedulerStatusDetailCode,
        optionsBatchExecutionTypeDetailCode,
        optionsBusinessGroupNameDetailCodeAndCcdName
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'setData', value: dataUpdate })
        dispatchStatePage({ type: 'batchBusinessGroup', value: stateParent.selectedBatchBusinessGroup })
        dispatchStatePage({ type: 'batchExecutionType', value: stateParent.selectedBatchExecutionType })
        // if(visibleModalAddDelete === false){
        //     if(stateParent.selectedBatchJobIdUpdate !== null ){
        //         handleStateParent('selectedBatchJobIdUpdate', null)
        //         handleStateParent('clearUpdateData')
        //     }
        // }
    }, [visibleModalAddDelete, dataUpdate])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["batchProgId", "batchStatus", "batchName", "batchType", "batchProgName", "batchLogLevel", "batchDescription", "batchInterval"]
        let limit = [15, 3, 15, 3, 50, 3, 200, 4]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (nameState === 'batchProgId') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === 'batchName') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === "batchProgName") {
                let errorMessage = {
                    id: '1',
                    errorMessage: 'BATCH PROGRAM NAME must contain only letters (A-Z) or (a-z), space and numbers (0-9)'
                }
                if (CheckInputAZaz09Space.test(valueState.toString())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            }
            else if (valueState.toString().split('').length <= limit[index]) {
                dispatchStatePage({ type: nameState, value: valueState })
            }

        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const postDataAPI = () => {
        const {
            id,
            batchId,
            batchPreRequisite,
            batchStatus,
            batchRegType,
            batchExecutionType,
            batchRerunManuallyDttm,
            batchRunTime,
            batchParameterValue,
            batchGroupPath,
            batchPlannedDttm,
            batchInterval,
            batchBusinessGroup,
            batchDescription
        } = statePage.data

        const data = {
            batchDetailId: id,
            batchId: batchId,
            batchPreRequisite: batchPreRequisite,
            batchStatus: batchStatus,
            batchRegType: batchRegType,
            batchExecutionType: batchExecutionType,
            // batchRerunManuallyDttm: batchRerunManuallyDttm,
            batchRunTime: batchRunTime,
            batchParameterValue: batchParameterValue,
            batchGroupPath: batchGroupPath,
            batchPlannedDttm: batchPlannedDttm,
            batchInterval: batchInterval,
            batchBusinessGroup: batchBusinessGroup,
            batchDescription: batchDescription,
        }
        let checkFields: any[] = []
        let checkNameFields: any[] = []
        if (batchExecutionType === '005' || batchExecutionType === '006' || batchExecutionType === '007') {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
                batchInterval
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
                "BATCH INTERVAL",
            ]
        } else {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
            ]
        }
        const a = CheckAllFields(checkFields, checkNameFields)
        if (statePage.errorMessageList.length > 0) { }
        else {
            if (a !== "") {
                handleState('errorMessage', a);
                functionSetModal(false)
            } else {
                handleState('errorMessage', ``);
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    getDataAllJobDetail(id, 'update');
                    dispatchStatePage({ type: 'clearData' })
                    setModal()
                    functionSetModal(false)
                }).catch((err) => handleState('errorMessage', err))
            }
        }
    }
    const postDataAPIReRun = () => {
        const {
            id,
            batchId,
            batchPreRequisite,
            batchStatus,
            batchRegType,
            batchExecutionType,
            batchRerunManuallyDttm,
            batchRunTime,
            batchParameterValue,
            batchGroupPath,
            batchPlannedDttm,
            batchInterval,
            batchBusinessGroup,
            batchDescription
        } = statePage.data
        let dataSplitBatchPlannedDttmDate: any = '';
        let dataSplitBatchPlannedDttmTime: any = '';
        if (batchPlannedDttm.toString().split().length > 0) {
            let currentDate = new Date(batchPlannedDttm.toString().split(' ')[0]);
            currentDate.setDate(currentDate.getDate() - 1);
            dataSplitBatchPlannedDttmDate = currentDate.toISOString().split('T')[0];
            dataSplitBatchPlannedDttmTime = batchPlannedDttm.toString().split(' ')[1];
        }

        const data = {
            batchDetailId: id,
            batchId: batchId,
            batchPreRequisite: batchPreRequisite,
            batchStatus: '001',
            batchRegType: batchRegType,
            batchExecutionType: batchExecutionType,
            // batchRerunManuallyDttm: batchRerunManuallyDttm,
            batchRunTime: batchRunTime,
            batchParameterValue: batchParameterValue,
            batchGroupPath: batchGroupPath,
            batchPlannedDttm: `${dataSplitBatchPlannedDttmDate} ${dataSplitBatchPlannedDttmTime}`,
            batchInterval: batchInterval,
            batchBusinessGroup: batchBusinessGroup,
            batchDescription: batchDescription,
        }
        let checkFields: any[] = []
        let checkNameFields: any[] = []
        if (batchExecutionType === '005' || batchExecutionType === '006' || batchExecutionType === '007') {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
                batchInterval
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
                "BATCH INTERVAL",
            ]
        } else {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
            ]
        }
        const a = CheckAllFields(checkFields, checkNameFields)
        if (statePage.errorMessageList.length > 0) { }
        else {
            if (a !== "") {
                handleState('errorMessage', a);
                functionSetModal(false)
            } else {
                handleState('errorMessage', ``);
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    getDataAllJobDetail(id, 'update');
                    dispatchStatePage({ type: 'clearData' })
                    setModal()
                    functionSetModal(false)
                }).catch((err) => handleState('errorMessage', err))
            }
        }
    }
    //
    const postDataKillJob= () => {
        const {
            id,
            batchId,
            batchPreRequisite,
            batchStatus,
            batchRegType,
            batchExecutionType,
            batchRerunManuallyDttm,
            batchRunTime,
            batchParameterValue,
            batchGroupPath,
            batchPlannedDttm,
            batchInterval,
            batchBusinessGroup,
            batchDescription
        } = statePage.data
        let dataSplitBatchPlannedDttmDate: any = '';
        let dataSplitBatchPlannedDttmTime: any = '';
        if (batchPlannedDttm.toString().split().length > 0) {
            let currentDate = new Date(batchPlannedDttm.toString().split(' ')[0]);
            currentDate.setDate(currentDate.getDate() - 1);
            dataSplitBatchPlannedDttmDate = currentDate.toISOString().split('T')[0];
            dataSplitBatchPlannedDttmTime = batchPlannedDttm.toString().split(' ')[1];
        }

        const data = {
            batchDetailId: id,
            batchId: batchId,
            batchPreRequisite: batchPreRequisite,
            batchStatus: '005',
            batchRegType: batchRegType,
            batchExecutionType: batchExecutionType,
            // batchRerunManuallyDttm: batchRerunManuallyDttm,
            batchRunTime: batchRunTime,
            batchParameterValue: batchParameterValue,
            batchGroupPath: batchGroupPath,
            batchPlannedDttm: batchPlannedDttm,
            batchInterval: batchInterval,
            batchBusinessGroup: batchBusinessGroup,
            batchDescription: batchDescription,
        }
        let checkFields: any[] = []
        let checkNameFields: any[] = []
        if (batchExecutionType === '005' || batchExecutionType === '006' || batchExecutionType === '007') {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
                batchInterval
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
                "BATCH INTERVAL",
            ]
        } else {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
            ]
        }
        const a = CheckAllFields(checkFields, checkNameFields)
        if (statePage.errorMessageList.length > 0) { }
        else {
            if (a !== "") {
                handleState('errorMessage', a);
                functionSetModal2(false)
            } else {
                handleState('errorMessage', ``);
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    getDataAllJobDetail(id, 'update');
                    dispatchStatePage({ type: 'clearData' })
                    setModal()
                    functionSetModal2(false)
                }).catch((err) => handleState('errorMessage', err))
            }
        }
    }

    
    useEffect(() => {
        let selectedBatchPreRequisite: any[] = []
        if (optionsBatchFromAPIBatch.length > 0) {
            optionsBatchFromAPIBatch.forEach((option: any) => {
                if (statePage.data.batchPreRequisite !== null) {
                    if (typeof statePage.data.batchPreRequisite === 'string') {
                        if (statePage.data.batchPreRequisite.length > 0) {
                            let splitBatchPreRequisite: any[] = []
                            splitBatchPreRequisite = [...statePage.data.batchPreRequisite.toString().split(',')]
                            if (splitBatchPreRequisite.length > 0) {
                                splitBatchPreRequisite.forEach((item: any) => {
                                    if (item === `${option.value}`) {
                                        selectedBatchPreRequisite.push(option)
                                    }
                                })

                            }
                        }
                    } else if (statePage.data.batchPreRequisite instanceof Array) {
                        if (statePage.data.batchPreRequisite.length > 0) {
                            if (statePage.data.batchPreRequisite.includes(option.value)) {
                                selectedBatchPreRequisite.push(option)
                            }
                        }
                    }

                }
            })
            handleState('selectedBatchPreRequisite', selectedBatchPreRequisite)
        }
    }, [statePage.data.batchPreRequisite, optionsBatchFromAPIBatch])



    const onOkBatchRunTime = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            let datNow = new Date();
            let hoursNow = datNow.getHours().toString().padStart(2, '0');
            let minutesNow = datNow.getMinutes().toString().padStart(2, '0');
            let secondsNow = datNow.getSeconds().toString().padStart(2, '0');
            let timeNowNow = `${hoursNow}:${minutesNow}:${secondsNow}`;
            if (timeNow > timeNowNow) {
                let dateStart = new Date();
                dateStart.setDate(dateStart.getDate() - 1)
                let dateStartUsed = dateStart.toISOString().slice(0, 10);
                dispatchStatePage({ type: 'batchRunTime', value: timeNow })
                dispatchStatePage({ type: 'batchPlannedDttm', value: `${dateStartUsed} ${timeNow}` })
            } else {
                let dateStart = new Date();
                let dateStartUsed = dateStart.toISOString().slice(0, 10);
                dispatchStatePage({ type: 'batchRunTime', value: timeNow })
                dispatchStatePage({ type: 'batchPlannedDttm', value: `${dateStartUsed} ${timeNowNow}` })
            }
        }
    };
    const onOkBatchPlannedDttm = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'batchPlannedDttm', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
        }
    };
    const onOkBatchRerunManuallyDttm = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'selectedDateBatchRerunManuallyDttm', value: localDate.toString().split(' ')[0] })
            dispatchStatePage({ type: 'selectedTimeBatchRerunManuallyDttm', value: timeNow })
        }
    };

    const ComponentBatchPlannedDttm = useCallback(({ ...props }) => {
        const { statePage } = props;
        return (
            <>
                {
                    dayjs(statePage.data.batchPlannedDttm, ['YYYY-MM-DD HH:mm:ss']).isValid() === true ?
                        <DatePicker
                            defaultValue={dayjs(`${statePage.data.batchPlannedDttm}`, ['YYYY-MM-DD HH:mm:ss'])}
                            className='flex-fill w-100 h-40px'
                            showTime
                            disabled={true}
                        />
                        :
                        <DatePicker
                            className='flex-fill w-100 h-40px'
                            showTime
                            disabled={true}
                        />
                }
            </>
        )
    }, [statePage.data.batchPlannedDttm])


    const ComponentBatchRunTime = useCallback(({ ...props }) => {
        const { statePage } = props;
        return (
            <>
                {
                    dayjs(statePage.data.batchRunTime, ['HH:mm:ss']).isValid() === true ?
                        <TimePicker
                            defaultValue={dayjs(`${statePage.data.batchRunTime}`, ['HH:mm:ss'])}
                            className='flex-fill w-100 h-40px'

                            onOk={onOkBatchRunTime}
                            onChange={onOkBatchRunTime}
                        />
                        :
                        <TimePicker
                            className='flex-fill w-100 h-40px'

                            onOk={onOkBatchRunTime}
                            onChange={onOkBatchRunTime}
                        />
                }
            </>
        )
    }, [statePage.data.batchRunTime])


    useEffect(() => {
        let labelSelectedBatchBusinessGroup: string = ''
        let labelSelectedBatchExecutionType: string = ''

        if (optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup).length > 0) {
            labelSelectedBatchBusinessGroup = optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup)[0].label
        }
        if (optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType).length > 0) {
            labelSelectedBatchExecutionType = optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType)[0].label
        }
        handleState('batchGroupPath', (`${labelSelectedBatchBusinessGroup}/${labelSelectedBatchExecutionType}`).toUpperCase())

    }, [statePage.data.batchBusinessGroup, statePage.data.batchExecutionType])

    useImperativeHandle(ref, () => ({
        postDataAPI,
        postDataKillJob,
    }));

    //
    const newBatchStatus = stateParent.dataUpdate.batchStatus

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column justify-content-between w-800px' style={{ overflow: 'auto', height: '650px' }}>
                    <div>
                        <div className='d-flex min-h-60px justify-content-between align-items-center' >
                            <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                            <div className='pointer' onClick={(e) => props.setModal()}>{close} </div>
                        </div>
                        <Divider className='m-0' />

                        <div className=''>
                            <div className='row m-0 p-0 g-1 mb-5'>
                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH ID <span className='text-danger'>*</span></p>
                                    <Select placeholder={'(Search)'} options={optionsBatchFromAPIBatch} value={optionsBatchFromAPIBatch.filter((option: any) => option.value === statePage.data.batchId)}
                                        // onChange={(e) => handleState('batchId', e.value)} 
                                        isDisabled={true}
                                    />
                                </div>
                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH STATUS <span className='text-danger'>*</span></p>
                                    <Select placeholder={'(Search)'} options={optionsBatchSchedulerStatusDetailCode} value={optionsBatchSchedulerStatusDetailCode.filter((option: any) => option.value === statePage.data.batchStatus)}
                                        onChange={(e) => handleState('batchStatus', e.value)} 
                                        // isDisabled={true}
                                    />
                                </div>

                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH BUSINESS GROUP <span className='text-danger'>*</span></p>
                                    <Select placeholder={'(Search)'} options={optionsBusinessGroupNameDetailCodeAndCcdName} value={optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup)} onChange={(e) => handleState('batchBusinessGroup', e.value)} isDisabled={true} />
                                </div>

                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH EXECUTION TYPE <span className='text-danger'>*</span></p>
                                    <Select placeholder={'(Search)'} options={optionsBatchExecutionTypeDetailCode} value={optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType)} onChange={(e) => handleState('batchExecutionType', e.value)} isDisabled={true} />
                                </div>
                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH REG TYPE <span className='text-danger'>*</span></p>
                                    <Select placeholder={'(Search)'} options={optionsBatchRegistrationTypeDetailCode} value={optionsBatchRegistrationTypeDetailCode.filter((option: any) => option.value === statePage.data.batchRegType)} onChange={(e) => handleState('batchRegType', e.value)} />
                                </div>

                                {/* <div className='col-6'>
                                <p className={labelStyle}>BATCH RERUN MANUALLY DTTM</p>
                                {
                                    statePage.selectedTimeBatchRerunManuallyDttm === '' ?
                                        <></>
                                        :
                                        <DatePicker defaultValue={dayjs(`${statePage.selectedTimeBatchRerunManuallyDttm}`, 'HH:mm:ss')} className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRerunManuallyDttm} />
                                }
                            </div> */}


                                {/* <div className='col-6'>
                                <p className={labelStyle}>BATCH RUN TIME <span className='text-danger'>*</span></p>
                                {
                                    statePage.selectedTimeBatchRunTime === '' ?
                                        <></>
                                        :
                                        <DatePicker defaultValue={dayjs(`${statePage.selectedTimeBatchRunTime}`, 'HH:mm:ss')} className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRunTime} />
                                }
                            </div> */}
                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH RUN TIME <span className='text-danger'>*</span></p>
                                    <ComponentBatchRunTime statePage={statePage} />
                                    {/* {
                                    dayjs(statePage.data.batchRunTime, ['HH:mm:ss']).isValid() === true ?
                                        <TimePicker className='h-40px'
                                            defaultValue={dayjs(`${statePage.data.batchRunTime}`, ['HH:mm:ss'])}
                                            onOk={onOkBatchRunTime}
                                            onChange={onOkBatchRunTime}
                                        />
                                        :
                                        <TimePicker className='h-40px'
                                            onOk={onOkBatchRunTime}
                                            onChange={onOkBatchRunTime}
                                        />
                                } */}
                                </div>


                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH PARAMETER VALUE </p>
                                    <input value={statePage.data.batchParameterValue} onChange={(e) => handleState('batchParameterValue', e.target.value)} className={inputStyle} />
                                </div>



                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH GROUP PATH </p>
                                    <input disabled={true} value={statePage.data.batchGroupPath} className={inputStyleReadOnly} />
                                </div>
                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH PLANNED DTTM </p>
                                    <ComponentBatchPlannedDttm statePage={statePage} />
                                </div>

                                <div className='col-6'>
                                    <p className={labelStyle}>BATCH INTERVAL
                                        {
                                            statePage.data.batchExecutionType === '005' || statePage.data.batchExecutionType === '006' || statePage.data.batchExecutionType === '007' ?
                                                <span className='text-danger'>*</span>
                                                :
                                                <></>
                                        }
                                    </p>
                                    <input type='number' value={statePage.data.batchInterval} onChange={(e) => handleState('batchInterval', e.target.value)} className={inputStyle} />
                                </div>
                                <div className='col-12'>
                                    <p className={labelStyle}>DESCRIPTION</p>
                                    <textarea rows={4} value={statePage.data.batchDescription || ''} onChange={(e) => handleState('batchDescription', e.target.value)} className={inputDescriptionStyle} />
                                </div>
                            </div>
                        </div>
                        {/* <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-3 ' onClick={() => postDataAPIReRun()}> */}
                            {/* {undo_icon} */}
                            {/* <svg style={{ width: "20px", height: "20px" }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                    fill="#FFFFFF" // Set the fill color to white
                                />
                            </svg>
                            <p className='s-1418 c-ff Lato-600 mx-2'>RE-RUN</p>
                        </button> */}
                        <Divider />
                    </div>
                    <FooterModalCreate3 errorMessage={statePage.errorMessage} errorMessageList={statePage.errorMessageList} setModal={() => setModal()} handlePostAPI={() => functionSetModal(true)} handlePostDataReRun ={() => postDataAPIReRun()} handleKillJob ={() => functionSetModal2(true)} batchStatuss={newBatchStatus}/>
                </div>
            </Modal>
        </>
    )
})

const initialState = {
    titleModal: `Update ${Page53Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelBatchScheduler({
        id: null,
        batchId: null,
        batchPreRequisite: null,
        batchStatus: null,
        batchLastExec: null,
        batchGroupPath: null,
        createProgId: null,
        updateProgId: null,
        createDttm: null,
        updateDttm: null,
        batchRegType: null,
        batchRerunManuallyDttm: null,
        batchPlannedDttm: null,
        batchRunTime: null,
        batchParameterValue: null,
        batchExecutionType: null,
        batchInterval: null,
        batchBusinessGroup: null,
        flag: null,
        batchDescription: null,
        batchProgId: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
    selectedBatchPreRequisite: [],
    selectedTimeBatchRunTime: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelBatchScheduler) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        case 'errorMessageList':
            if (action.value.type === "removed") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                            errorMessageList: state.errorMessageList.filter((item: any) => item.id !== action.value.data.id)
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else if (action.value.type === "added") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                        }
                    } else {

                        let data: any[] = [...state.errorMessageList]
                        data.push(action.value.data)
                        return {
                            ...state,
                            errorMessageList: data
                        }
                    }
                } else {
                    let data: any[] = [...state.errorMessageList]
                    data.push(action.value.data)
                    return {
                        ...state,
                        errorMessageList: data
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        //
        case 'selectedBatchPreRequisite':
            return {
                ...state,
                selectedBatchPreRequisite: action.value,
            };
        case 'selectedTimeBatchRunTime':
            return {
                ...state,
                selectedTimeBatchRunTime: action.value,
            };
        case 'selectedDateBatchParameterValue':
            return {
                ...state,
                selectedDateBatchParameterValue: action.value,
            };
        case 'selectedTimeBatchParameterValue':
            return {
                ...state,
                selectedTimeBatchParameterValue: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelBatchScheduler({
                    id: null,
                    batchId: null,
                    batchPreRequisite: null,
                    batchStatus: null,
                    batchLastExec: null,
                    batchGroupPath: null,
                    createProgId: null,
                    updateProgId: null,
                    createDttm: null,
                    updateDttm: null,
                    batchRegType: null,
                    batchRerunManuallyDttm: null,
                    batchPlannedDttm: null,
                    batchRunTime: null,
                    batchParameterValue: null,
                    batchExecutionType: null,
                    batchInterval: null,
                    batchBusinessGroup: null,
                    flag: null,
                    batchDescription: null,
                    batchProgId: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelBatchScheduler({
                    id: action.value.id,
                    batchId: action.value.batchId,
                    batchPreRequisite: action.value.batchPreRequisite,
                    batchStatus: action.value.batchStatus,
                    batchLastExec: action.value.batchLastExec,
                    batchGroupPath: action.value.batchGroupPath,
                    createProgId: action.value.createProgId,
                    updateProgId: action.value.updateProgId,
                    createDttm: action.value.createDttm,
                    updateDttm: action.value.updateDttm,
                    batchRegType: action.value.batchRegType,
                    batchRerunManuallyDttm: action.value.batchRerunManuallyDttm,
                    batchPlannedDttm: action.value.batchPlannedDttm,
                    batchRunTime: action.value.batchRunTime,
                    batchParameterValue: action.value.batchParameterValue,
                    batchExecutionType: action.value.batchExecutionType,
                    batchInterval: action.value.batchInterval,
                    batchBusinessGroup: action.value.batchBusinessGroup,
                    flag: action.value.flag,
                    batchDescription: action.value.batchDescription,
                    batchProgId: action.value.batchProgId,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

export default ModalJobScheduleUpdate;

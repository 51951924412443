import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import { fileURLToPath } from 'url';                // originally unused

import { ButtonNavigation, InsideComponentPage } from '../../Components';
import { CheckUndefinedStringEmpty, colors } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';    // originally unused
import { store } from '../../Store/Store';
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';
import { history } from '../../History';            // originally unused
import { Page5DashboardRoute } from '../../Routes';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    stateSelected as initialStateCustomComponent,
    stateOptions as initialStateOptions,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle
} from '../../State';
// change according to page
import { changeStateQrAuthMonitoring, stateGlobalQrAuthMonitoring } from '../../Store/QRAuthMonitoringSlice';
import { changeStateQRAuthInquiry } from '../../Store/QRAuthInquirySlice';

import { FilterComponentQRAuthMonitoring } from './Components/FilterComponentQRAuthMonitoring';
import { InterfaceOptionsQRAuthMonitoring } from './Utils/InterfaceOptionsQRAuthMonitoring';
import { ModelQRAuthMonitoring } from './Utils/ModelQRAuthMonitoring';
import { TableQRAuthMonitoring } from './Components/TableQRAuthMonitoring';
import { ExcelQRAuthMonitoring } from './Utils/ExcelQRAuthMonitoring';
import { LineChartComponent } from './Components/LineChartComponent';
import { PieChartComponent } from './Components/PieChartComponent';

import { initialState } from './State/state';
import { reducer } from './State/reducer';


export const QRAuthMonitoring = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const globalState = useSelector(stateGlobalQrAuthMonitoring);

    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, { ...initialStateOptions });
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, { ...initialStateCustomComponent });
    const { dataFilter, dataCopyFilter, dataByDate, dataCopyByDate, dataYesterday, dataYesterdayByDate, dataYesterdayCopyByDate, filterStartDate, filterEndDate, selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedQRStandardId, selectedQRStandardName, selectedQRStandardSendToApi, search, optionsProductFeature, optionsQRStandard, rangeDate } = globalState;

    const getProductFeature = () => {
        let newFirstElement = { value: '', label: 'All', title: 'All', sendToApi: '' };
        //TODO new Jira: get QR auth product feature from DB
        let qrAuthProductFeatureInd = ['QR000', 'QR001', 'QR009'];  // variable to hold product feature indicator that's used for QR auth

        return new Promise((resolve, reject) => {
            let checkSelectedProductFeatureId = state.selectedProductFeatureId === '' ? '' : `productFeatureId=${state.selectedProductFeatureId}&`;
            let finalParameter = `${checkSelectedProductFeatureId}`;
            API.get({
                bodyCustom: null,
                // pathCustom: `product_feature?productId=${selectedProductId}`,
                pathCustom: `product_feature?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needNotification: false,
            }).then((response: any) => {
                let newOptionsProductFeature: any = [];
                if (response?.data.length > 0) {
                    response.data.forEach((element1: any) => {
                        qrAuthProductFeatureInd.forEach((productFeatureInd: any) => {
                            if (productFeatureInd === element1.prodFeatureInd) {
                                newOptionsProductFeature.push({
                                    value: element1.prodFeatureId,
                                    label: `${element1.prodFeatureName}`,
                                    title: `${element1.productId} - ${element1.productName}`,
                                    sendToApi: element1.prodFeatureInd,
                                });
                            }
                        })
                    });
                }
                
                let selectedProductFeature = { value: '', label: 'All', title: 'All', sendToApi: '' };
                newOptionsProductFeature.forEach((element1: any) => {
                    if (element1.value === selectedProductFeatureId) selectedProductFeature = element1;
                });

                dispatchGlobal(
                    changeStateQrAuthMonitoring({
                        selectedProductFeatureId: selectedProductFeature.value,
                        selectedProductFeatureLabel: selectedProductFeature.label,
                        selectedProductFeatureSendToApi: selectedProductFeature.sendToApi,
                        optionsProductFeature: [newFirstElement].concat(newOptionsProductFeature),
                    })
                );
                resolve(response?.data);
            }).catch((err: any) => reject(err));
        })
    }

    const preparationFilter = () => {
        let newFirstElement = { value: '', label: 'All', title: 'All', sendToApi: '' };
        const getQRStandard = () => {
            return new Promise((resolve, reject) => {
                API.get({
                    bodyCustom: null,
                    pathCustom: `qr_standard`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needNotification: false,
                }).then((response: any) => {
                    let newOptionsQRStandard: any[] = [];
                    if (response?.data !== undefined) {
                        if (response.data.length > 0) {
                            response.data.forEach((element1: any) => {
                                newOptionsQRStandard.push({
                                    value: element1.qrsId,
                                    label: `${element1.qrsName}`,
                                    title: `${element1.qrsId} - ${element1.qrsName}`,
                                    sendToApi: element1.qrsId,    // change this value to set which value to send to API
                                });
                            });
                        }
                    }
                    let selectedQRStandard = { value: '', label: 'All', title: 'All', sendToApi: '' };
                    newOptionsQRStandard.forEach((element1) => {
                        if (element1.value === selectedQRStandardId) {
                            selectedQRStandard = element1;
                        }
                    });
                    dispatchGlobal(
                        changeStateQrAuthMonitoring({
                            selectedQRStandardId: selectedQRStandard.value,
                            selectedQRStandardName: selectedQRStandard.label,
                            selectedQRStandardSendToApi: selectedQRStandard.sendToApi,
                            optionsQRStandard: [newFirstElement].concat(newOptionsQRStandard),
                        })
                    );
                    resolve(response?.data);
                }).catch((err: any) => reject(err));
            })
        }

        // ~~~~~~~~~~~~~~~~~~~~~~~ FIRST FUN ~~~~~~~~~~~~~~~~~~~~~~~ //
        const getMember = () => {
            return new Promise((resolve, reject) => {
                API.get({
                    bodyCustom: null,
                    pathCustom: `member`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needNotification: false,
                }).then((response: any) => {
                    let newListDataMemberQRAuth: any = [];
                    if (response?.data.length > 0) {
                        response.data.forEach((element1: any) => {
                            newListDataMemberQRAuth.push(element1.memberName);
                        })
                    }
                    let removeSameValue: any[] = Array.from(new Set(newListDataMemberQRAuth)); // remove same id

                    let newList: any[] = [];
                    removeSameValue.forEach((element1) => {
                        newList.push({ status: element1, memberCodeList: [] });
                    })

                    newList.map((element1) => {
                        if (response.data.length > 0) {
                            response.data.forEach((element2: any) => {
                                if (element1.status === element2.memberName) {
                                    element1.memberCodeList.push(element2.memberCode);
                                }
                            })
                        }
                    })

                    console.log("getMember: (optionsMember)")
                    console.log(response.data)

                    console.log("getMember: (listMember)")
                    console.log(newList)

                    dispatchGlobal(changeStateQrAuthMonitoring({ listMember: newList })); // automatically filter data also to the contents
                    dispatchOptions({ type: 'optionsMember', value: response?.data });
                    resolve(response?.data);
                }).catch((err: any) => reject(err));
            })
        }
        Promise.all([getQRStandard(), getProductFeature(), getMember()]).then(() => {
        }).finally(() => {
            store.dispatch(loadingAction(false));
        })
    }

    const initFunctionCustom = async (needRefreshPage: Boolean | null) => {
        try {
            store.dispatch(loadingAction(true));

            let dateStart = new Date(filterStartDate); let dateEnd = new Date(filterEndDate); let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) { listAllDayChart.push(d.toISOString().slice(0, 10)) }

            let dateStartBefore = new Date(filterStartDate); let dateEndBefore = new Date(filterEndDate);
            dateStartBefore.setDate(dateStartBefore.getDate() - listAllDayChart.length); dateEndBefore.setDate(dateEndBefore.getDate() - listAllDayChart.length);

            let dayUsedStartBefore = dateStartBefore.toISOString().slice(0, 10); let dayUsedEndBefore = dateEndBefore.toISOString().slice(0, 10);

            let urlReqNow = 'dashboard/qr-req-monitoring?'; let urlReqBefore = 'dashboard/qr-req-monitoring?';

            if (CheckUndefinedStringEmpty(selectedQRStandardSendToApi) !== '') {
                urlReqNow = urlReqNow + `qrsId=${CheckUndefinedStringEmpty(selectedQRStandardSendToApi)}&`;
                urlReqBefore = urlReqBefore + `qrsId=${CheckUndefinedStringEmpty(selectedQRStandardSendToApi)}&`;
            }
            if (CheckUndefinedStringEmpty(selectedProductFeatureSendToApi) !== '') {
                urlReqNow = urlReqNow + `productFeature=${CheckUndefinedStringEmpty(selectedProductFeatureSendToApi)}&`;
                urlReqBefore = urlReqBefore + `productFeature=${CheckUndefinedStringEmpty(selectedProductFeatureSendToApi)}&`;
            }
            if (CheckUndefinedStringEmpty(search) !== '') {
                urlReqNow = urlReqNow + `search=${CheckUndefinedStringEmpty(search)}&`;
                urlReqBefore = urlReqBefore + `search=${CheckUndefinedStringEmpty(search)}&`;
            }
            if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
                urlReqNow = urlReqNow + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
                urlReqBefore = urlReqBefore + `startDate=${CheckUndefinedStringEmpty(dayUsedStartBefore)}&`;
            } else {
                urlReqNow = urlReqNow + ``;
                urlReqBefore = urlReqBefore + ``;
            }
            if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
                urlReqNow = urlReqNow + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
                urlReqBefore = urlReqBefore + `endDate=${CheckUndefinedStringEmpty(dayUsedEndBefore)}&`;
            } else {
                urlReqNow = urlReqNow + ``;
                urlReqBefore = urlReqBefore + ``;
            }

            const responseYesterday = await API.get({
                bodyCustom: null,
                pathCustom: urlReqBefore.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needNotification: false
            })

            const responseToday = await API.get({
                bodyCustom: null,
                pathCustom: urlReqNow.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needNotification: false
            })

            dispatchGlobal(changeStateQrAuthMonitoring({
                dataYesterday: responseYesterday?.data,
                dataYesterdayByDate: responseYesterday?.dataByDate,
                dataFilter: responseToday?.data,
                dataByDate: responseToday?.dataByDate,
                rangeDate: listAllDayChart.length
            }))

            store.dispatch(loadingAction(false));
        } catch (error) {
            console.error(error);
            store.dispatch(loadingAction(false));
        }
    }

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    useEffect(() => {
        preparationFilter()
    }, [selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedQRStandardId, selectedQRStandardName, selectedQRStandardSendToApi])

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsQRAuthMonitoring, dispatchGlobal)
    }, [])

    useEffect(() => {
        if (filterStartDate !== '' && filterEndDate !== '') initFunctionCustom(true)
    }, [search, filterStartDate, filterEndDate, /*rangeDate,*/ selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedQRStandardId, selectedQRStandardName, selectedQRStandardSendToApi])

    useEffect(() => {
        handleStateComponent('filterStartDate', filterStartDate)
        handleStateComponent('filterEndDate', filterEndDate)

        handleStateComponent('selectedQRStandardId', selectedQRStandardId)
        handleStateComponent('selectedQRStandardName', selectedQRStandardName)
        handleStateComponent('selectedQRStandardSendToApi', selectedQRStandardSendToApi)

        handleStateComponent('selectedProductFeatureId', selectedProductFeatureId)
        handleStateComponent('selectedProductFeatureLabel', selectedProductFeatureLabel)
        handleStateComponent('selectedProductFeatureSendToApi', selectedProductFeatureSendToApi)
    }, [])


    useEffect(() => {
        getProductFeature()
    }, [state.selectedQRStandardId])

    useEffect(() => {
        dispatchGlobal(changeStateQrAuthMonitoring({ 'search': state.search }))
    }, [state.search])

    useEffect(() => {
        let totalPages: number = Math.ceil(dataCopyFilter.length / state.view);
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'changeData', value: { totalPages, currentPage } });
        dispatch({ type: 'data', value: dataCopyFilter });
    }, [dataCopyFilter, dataCopyByDate])

    const exportToCSVComponent = () => ExcelQRAuthMonitoring(state, stateOptions, globalState)
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelQRAuthMonitoring) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsQRAuthMonitoring, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => { }

    const handleNavigation = (item: any) => {
        if (item.fullCreateDate !== null) {
            let dateArray = item.lastReqDate.toString().split(' ');
            if (dateArray.length > 1) {
                let date = dateArray[0];

                dispatchGlobal(
                    changeStateQRAuthInquiry({
                        qrRefNo: '',
                        QRAuthInqResponseCode: '',
                        QRStatusSendToApi: '',
                        QRStatus: '',
                        stan: '',
                        invNo: '',
                        globalId: '',
                        memberId: item.memberId,
                        qrCpmPan: '',
                        qrMpmMerId: '',
                        // QR Standard
                        selectedQRStandardId: parseInt(item.qrsId, 10),
                        selectedQRStandardName: item.qrsName,
                        selectedQRStandardSendToApi: item.qrsCode,
                        // Product Feature
                        selectedProductIndId: item.prodFeatureInd,
                        selectedProductIndLabel: item.prodFeatureName,
                        selectedProductIndSendToApi: item.prodFeatureInd,
                        // 
                        search: '',
                        filterStartDate: filterStartDate,
                        filterEndDate: filterEndDate,
                        // 
                        productInd: item.prodFeatureInd,
                        qrsId: item.qrsId
                    })
                );
                navigate(`${Page5DashboardRoute}`);
            }
        }
    }

    const customButtonAction = (item: ModelQRAuthMonitoring, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                <ButtonNavigation handleClick={() => handleNavigation(item)} codeAccessPrivilege='' />
            </div>
        );
    }

    const filterComponentPage = () => {
        return (
            <></>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableQRAuthMonitoring
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                handleNavigation={handleNavigation}
            //
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <></>
        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <h1 className='Lato-300 normal w-600px s-2024 py-2'>{state.titlePage}</h1>
            <FilterComponentQRAuthMonitoring initFunctionCustom={initFunctionCustom} handleStateParent={handleStateComponent} stateParent={state} />
            <PieChartComponent handleStateComponent={handleStateComponent} stateOptions={stateOptions} />
            <LineChartComponent stateParent={state} initFunctionCustom={initFunctionCustom} handleStateParent={handleStateComponent} stateOptions={stateOptions} />
            <InsideComponentPage
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={false}
                needSearchFunction={true}
                needDownloadButton={true}
            />
        </>
    )
} 
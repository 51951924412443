
// import { useSelector } from "react-redux";
// import { CheckUndefined } from "../../Utils";
// import { Divider } from "antd";

// const HeaderComponent = ({ ...props }) => {
//     const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
//     return (
//         <div className='d-flex justify-content-between px-4 '>
//             {
//                 props.label.map((item: any, i: any) => {
//                     if (item.val === 'action') {
//                         if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
//                             return (
//                                 props.checkIndex(`${item.val}`) && (
//                                     <p key={i} className={` s-1418 Lato-700 ${item.width} text-center `}>
//                                         {item.field}
//                                     </p>
//                                 )
//                             );
//                         } else {
//                             return <></>;
//                         }
//                     } else {
//                         return (
//                             props.checkIndex(`${item.val}`) && (
//                                 <p key={i} className={` s-1418 Lato-700 ${item.width} `}>
//                                     {item.field}
//                                 </p>
//                             )
//                         );
//                     }
//                 })
//             }
//         </div>
//     )
// }
// export const TableInterfaceRecord = ({ ...props }) => {
//     const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

//     return (
//         <div className='d-flex flex-column w-100 overflow mb-2'>
//             <div className={`d-flex flex-column ${props.widthTable}`}>
//                 <HeaderComponent
//                     codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
//                     codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
//                     label={props.label}
//                     data={props.data}
//                     position={props.position}
//                     view={props.view}
//                     customButtonAction={props.customButtonAction}
//                     checkIndex={props.checkIndex}
//                     widthTable={props.widthTable}
//                     stateOptions={props.stateOptions}
//                 />
//                     <Divider className="my-2"/>


//                 {props.data.map((item: any, index: any) => {
//                     let number = index + ((props.currentPage - 1) * props.view)

//                     return (
//                         <div key={number} className={`d-flex justify-content-between align-items-center min-h-50px px-4  ${number % 2 === 0 ? 'bg-f4' : ''} `}>
//                             {props.label.map((element1: any) => {
//                                 if (element1.val === 'no') {
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{number + 1}</p>;
//                                 } else if (element1.val === 'action') {
//                                     if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
//                                         return props.checkIndex(`${element1.val}`) && <div className={`d-flex  s-1418 Lato-400 word-wrap ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
//                                     } else {
//                                         return <></>;
//                                     }
//                                 }
//                                 else if (element1.val === "recordMappingCode") {
//                                     let dataReturn = `${item[`${element1.val}`]}`;
//                                     if (props.stateOptions.optionsFieldMappingDetailCode.length > 0) {
//                                         props.stateOptions.optionsFieldMappingDetailCode.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;
//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 }
//                                 else if (element1.val === "recordSeparator") {
//                                     let dataReturn = `${item[`${element1.val}`]}`;
//                                     if (props.stateOptions.optionsFileStringSeparatorDetailCode.length > 0) {
//                                         props.stateOptions.optionsFileStringSeparatorDetailCode.forEach((element2: any) => {
//                                             if (element2.value === item[`${element1.val}`]) {
//                                                 dataReturn = `${element2.label}`;
//                                             }
//                                         });
//                                     }
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
//                                 }
//                                 else {
//                                     return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
//                                 }
//                             })}
//                         </div>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// }


import {
    MaterialReactTable,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from 'antd';
import { CheckUndefined } from '../../Utils';
import { ButtonAdd, ButtonDownload, SelectView } from '../../Components';



export const TableInterfaceRecord = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
   
    const recordSeparatorOptions =[
        {
            "value": "000",
            "label": "No separator",
            "sortNumber": 1
        },
        {
            "value": "001",
            "label": "Space",
            "sortNumber": 2
        },
        {
            "value": "002",
            "label": "New line",
            "sortNumber": 2
        },
        {
            "value": "003",
            "label": "Tab Separator",
            "sortNumber": 4
        },
        {
            "value": "004",
            "label": "Pipeline",
            "sortNumber": 4
        }
    ]
    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateParent,
        resetFilterStatus,
        initFunctionCustom,
        handleNavigation,
        actionShowModal, deleteDataComponent, isProcTime, dispatch, pagination, setPagination
    } = props;
    const { optionsFieldMappingDetailCode } = stateOptions;
    const columns: any = useMemo(
        () => [
            {
                header: 'Record Name',
                accessorKey: 'recordName',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(row.original.recordName)}</p>;
                },
            },
            {
                header: 'Record Size',
                accessorKey: 'recordSize',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(row.original.recordSize)}</p>;
                },
                // `${CheckUndefined(row.original.srcMemberName)}`,
            },
            {
                header: 'Record Mapping Code',
                accessorKey: 'recordMappingCode',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                //  {   let dataReturn = row.original.recordMappingCode;
                //     if (props.stateOptions.optionsFieldMappingDetailCode.length > 0) {
                //         props.stateOptions.optionsFieldMappingDetailCode.forEach((element2: any) => {
                //             if (`${element2.value}` === row.original.recordMappingCode) {
                //                 dataReturn = `${element2.label}`;
                //             }
                //         });
                //     }
                //     return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(dataReturn)}</p>;
                // },
                `${CheckUndefined(row.original.recordName)}`,
            },
            {
                header: 'Record Separator',
                accessorKey: 'recordSeparator',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    let dataReturn = row.original.recordSeparator;
                    if (recordSeparatorOptions.length > 0) {
                        recordSeparatorOptions.forEach((element2: any) => {
                            if (`${element2.value}` === row.original.recordSeparator) {
                                dataReturn = `${element2.label}`;
                            }
                        });
                    }
                    return <p className={` s-1418 Lato-400 word-wrap`}>{CheckUndefined(dataReturn)}</p>;
                },
                // `${CheckUndefined(row.original.recordSeparator)}`,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                size: 'auto',
                Cell: ({ row }:any) => {
                    const item = row.original;
                    // console.log("item",item)
                    if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                        return props.checkIndex('action') && <div className={`d-flex  s-1418 Lato-400 word-wrap`}>{props.customButtonAction(item)} </div>;
                    } else {
                        return <></>;
                    }
                },

            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        onGlobalFilterChange: (filterValue) =>
            handleStateParent('search', filterValue),
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="d-flex justify-content-between align-items-center pt-1">
                <div className="d-flex align-items-center">
                    <p className="Lato-400 s-1822 ">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1  Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
                <Divider type='vertical' className='m-1' />
                <div className="">
                    <ButtonDownload handleClick={props.exportToCSV} codeAccessPrivilege={props.codeAccessPrivilegeDownload} />
                </div>

            </div>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleGlobalFilterButton table={table} />
                <div className="h-30px w-30px">
                    <div className="h-100">
                        <ButtonAdd handleClick={props.addData} codeAccessPrivilege={props.codeAccessPrivilegeAdd} />
                    </div>

                </div>
            </>
        ),
    });
    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                File Record
            </h1>
            <div id="table" className="mt-2 mb-4 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    );
};

import { useEffect, useReducer, useState } from 'react';
import Select from 'react-select';
import TimezoneSelect, {
    allTimezones,
    useTimezoneSelect
} from 'react-timezone-select';
import { checklistButtonIcon, errNotificationIcon } from '../../../../Assets/Images/svg';
import { API } from '../../../../Services';
import { inputStyleH25, inputStyleWithoutW100, inputStyleWithoutW100ReadOnly, labelStyleW250PX } from '../../../../Styles';
import { ModalSettlement } from '../Models/ModelSettlement';
import { urlSelection } from '../../../../Services/API';

const timezones = { ...allTimezones }
const labelStyle = 'original'


export const GetSettlement = ({ ...props }) => {
    const { codeMember, stateOptions } = props;
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    useEffect(() => {
        API.get({
            bodyCustom: null,
            pathCustom: `member_parameter/settlement/${codeMember}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            dispatchStatePage({ type: 'setData', value: response.data })
        })
    }, [])
    const updateData = () => {
        const {
            memberParamsId,
            memberCode,
            memberSettleCurrCode,
            memberSettleFxYn,
            memberSettleFxId,
            memberCutOverYn,
            memberCutOverTime,
            memberCutOverTzone,
        } = statePage.data

        let dataRelation: any = [
            {
                title: 'EXCHANGE RATE',
                condition: memberSettleFxYn,
                value: memberSettleFxId
            },
            {
                title: 'CUT OVERTIME TIME',
                condition: memberCutOverYn,
                value: memberCutOverTime
            },
            {
                title: 'CUT OVERTIME ZONE',
                condition: memberCutOverYn,
                value: memberCutOverTzone
            },
        ]

        let errorMessage: any[] = [];

        dataRelation.forEach((item: any) => {
            if (item.condition) {
                if (item.value === null) errorMessage.push(item.title)
                else if (item.value === '') errorMessage.push(item.title)
            }
        });

        if (errorMessage.length > 0) {
            if (errorMessage.length === 1) {
                handleState('errorMessage', `${errorMessage.join(', ').toString()} IS REQUIRED`)
            } else {
                handleState('errorMessage', `${errorMessage.join(', ').toString()} ARE REQUIRED`)
            }
        } else {
            if (statePage.errorMessageList && statePage.errorMessageList.length > 0) { }
            else {
                const data = {
                    memberCode: memberCode,
                    memberSettleCurrCode: memberSettleCurrCode,
                    memberSettleFxYn: memberSettleFxYn,
                    memberSettleFxId: memberSettleFxYn === true ? memberSettleFxId : null,
                    memberCutOverYn: memberCutOverYn,
                    memberCutOverTime: memberCutOverYn === true ? memberCutOverTime : null,
                    memberCutOverTzone: memberCutOverYn === true ? memberCutOverTzone : null
                }
                API.update({
                    bodyCustom: data,
                    pathCustom: `member_parameter/settlement`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                }).then((response: any) => {
                    if (response.data !== undefined) {
                        dispatchStatePage({ type: 'setData', value: response.data })
                    }
                })

            }
        }

    }
    return (
        <div className='d-flex flex-column justify-content-between bd-ea br-10 px-2 pt-2 pb-5' >
            <div className='row py-2 gy-1 gx-3 mx-0 mb-3'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex align-items-center my-2'}>
                        <p className={labelStyleW250PX}>SETTLEMENT CURRENCY CODE</p>
                        <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsCountryCodeCurrencyCode} value={stateOptions.optionsCountryCodeCurrencyCode.filter((option: any) => option.value === statePage.data.memberSettleCurrCode)} onChange={(e) => handleState('memberSettleCurrCode', e.value)} />
                    </div>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex align-items-center my-2'}>
                        <p className={labelStyleW250PX}>USING GAPIH EXCHANGE RATE</p>
                        <div className='d-flex justify-content-between align-items-center hg-40px' >
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberSettleFxYn', true)}
                                    checked={statePage.data.memberSettleFxYn === true}
                                    name='memberSettleFxYn'
                                />
                                <span className='mx-2 s-1214'>YES</span>
                            </span>
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberSettleFxYn', false)}
                                    checked={statePage.data.memberSettleFxYn === false}
                                    name='memberSettleFxYn'
                                />
                                <span className='mx-2 s-1214'>NO</span>
                            </span>
                            <span></span>
                        </div>
                        <div className='w-40px'></div>
                        <div className='flex-fill'></div>
                    </div>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex align-items-center my-2'}>
                        <p className={labelStyleW250PX}>EXCHANGE RATE</p>
                        <Select styles={inputStyleH25} isDisabled={statePage.data.memberSettleFxYn === true ? false : true} className='flex-fill' options={stateOptions.optionsRateSetting} value={stateOptions.optionsRateSetting.filter((option: any) => option.value === statePage.data.memberSettleFxId)} onChange={(e) => handleState('memberSettleFxId', e.value)} />
                    </div>
                </div>

                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex align-items-center my-2'}>
                        <p className={labelStyleW250PX}>DEFINE CUT OVER TIME</p>
                        <div className='d-flex justify-content-between align-items-center hg-40px' >
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberCutOverYn', true)}
                                    checked={statePage.data.memberCutOverYn === true}
                                    name='memberCutOverYn'
                                />
                                <span className='mx-2 s-1214'>YES</span>
                            </span>
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberCutOverYn', false)}
                                    checked={statePage.data.memberCutOverYn === false}
                                    name='memberCutOverYn'
                                />
                                <span className='mx-2 s-1214'>NO</span>
                            </span>
                            <span></span>
                        </div>
                        <div className='w-40px'></div>
                        <div className='flex-fill'></div>
                    </div>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <p className={labelStyleW250PX}>CUT OVERTIME</p>
                        {
                            statePage.data.memberCutOverYn === true ?
                                <input disabled={statePage.data.memberCutOverYn === true ? false : true} value={statePage.data.memberCutOverTime} type='time' onChange={(e) => handleState('memberCutOverTime', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                :
                                <input disabled={statePage.data.memberCutOverYn === false ? false : true} value={statePage.data.memberCutOverYn === true ? statePage.data.memberCutOverTime : "" } type='time' onChange={(e) => handleState('memberCutOverTime', e.target.value)} className={inputStyleWithoutW100ReadOnly + ' flex-fill'} />
                        }
                        <div className='w-10px'></div>
                        <TimezoneSelect
                            styles={inputStyleH25}
                            isDisabled={statePage.data.memberCutOverYn === true ? false : true}
                            options={options}
                            value={statePage.data.memberCutOverYn === true && statePage.data.memberCutOverTzone !== null ? statePage.data.memberCutOverTzone : ""}
                            className='flex-fill'
                            onChange={(e) => handleState('memberCutOverTzone', e.value)}
                        />
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column'>
                {statePage.errorMessage !== '' && (
                    <div className='col-12 pl-0'>
                        <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                            {errNotificationIcon}
                            <p className='px-2 s-1418 Lato-600 c-e87'>{statePage.errorMessage}</p>
                        </div>
                    </div>

                )}
                <div className='d-flex'>
                    <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-2 ' onClick={updateData}>
                        {checklistButtonIcon}
                        <p className='s-1214 c-ff Lato-600 mx-2'>SAVE</p>
                    </button>
                </div>
            </div>
        </div>
    )

}
const initialState = {
    data: new ModalSettlement({
        id: null,
        memberParamsId: null,
        memberCode: null,
        memberSettleCurrCode: null,
        memberSettleFxYn: null,
        memberSettleFxId: null,
        memberCutOverYn: null,
        memberCutOverTime: null,
        memberCutOverTzone: null,
    }),
    errorMessage: "",
}
const reducer = (state: any, action: any) => {
    for (const key in state.data as ModalSettlement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {

        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModalSettlement({
                    id: null,
                    memberParamsId: null,
                    memberCode: null,
                    memberSettleCurrCode: null,
                    memberSettleFxYn: null,
                    memberSettleFxId: null,
                    memberCutOverYn: null,
                    memberCutOverTime: null,
                    memberCutOverTzone: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModalSettlement({
                    id: action.value?.memberParamsId,
                    memberParamsId: action.value?.memberParamsId,
                    memberCode: action.value?.memberCode,
                    memberSettleCurrCode: action.value?.memberSettleCurrCode,
                    memberSettleFxYn: action.value?.memberSettleFxYn,
                    memberSettleFxId: action.value?.memberSettleFxId,
                    memberCutOverYn: action.value?.memberCutOverYn,
                    memberCutOverTime: action.value?.memberCutOverTime,
                    memberCutOverTzone: action.value?.memberCutOverTzone,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}
import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelQRStandardElement } from './ModelQRStandardElement';
import { Page25Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalQRStandardElement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsElement, optionsQRStandard, optionsYorN, optionsMOC, optionsCommonStatusDetailCode } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { qrStandardId, qrStandardName, elementId, elementName, qrseMoc, qrseIsRoot, rootElementId, qrseStatus, qrseDesc } = statePage.data
        const data = {
            qrStandardId: qrStandardId,
            elementId: elementId,
            qrseStatus: qrseStatus,
            qrseDesc: qrseDesc,
            qrseMoc: qrseMoc,
            qrseIsRoot: qrseIsRoot,
            rootElementId: qrseIsRoot === false ? rootElementId : null
        };
        let a = '';
        if (qrseIsRoot === false) {
            a = CheckAllFields([qrStandardId, elementId, qrseStatus, qrseMoc, qrseIsRoot, rootElementId], ["QR Standard", "Element ID", "Status", "M/O/C", "Is Root", "Root Element"]);
        } else {
            a = CheckAllFields([qrStandardId, elementId, qrseStatus, qrseMoc, qrseIsRoot], ["QR Standard", "Element ID", "Status", "M/O/C", "Is Root"]);
            handleState('rootElementId', null);
        }
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>QR Standard</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsQRStandard}
                                    value={optionsQRStandard.filter((option: any) => option.value === statePage.data.qrStandardId)}
                                    onChange={(e) => handleState('qrStandardId', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Element ID</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsElement}
                                    value={optionsElement.filter((option: any) => option.value === statePage.data.elementId)}
                                    onChange={(e) => handleState('elementId', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>M/O/C</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsMOC}
                                    value={optionsMOC.filter((option: any) => option.value === statePage.data.qrseMoc)}
                                    onChange={(e) => handleState('qrseMoc', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Is Root</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsYorN}
                                    value={optionsYorN.filter((option: any) => option.value === statePage.data.qrseIsRoot)}
                                    onChange={(e) => handleState('qrseIsRoot', e.value)}
                                />
                            </div>
                            {statePage.data.qrseIsRoot === false ?
                                <div className='col-6'>
                                    <p className={labelStyle}>Root Element</p>
                                    <Select
                                        styles={inputStyleH25}
                                        placeholder={'(Search)'}
                                        options={optionsElement}
                                        value={optionsElement.filter((option: any) => option.value === statePage.data.rootElementId)}
                                        onChange={(e) => handleState('rootElementId', e.value)}
                                    />
                                </div>
                                :
                                <></>
                            }
                            <div className='col-6'>
                                <p className={labelStyle}>Status</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode}
                                    value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.data.
                                        qrseStatus)} onChange={(e) => handleState('qrseStatus', `${e
                                            .value}`)} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.data.qrseDesc} onChange={(e) => handleState('qrseDesc', e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page25Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelQRStandardElement({
        id: null,
        qrseId: null,
        qrStandardId: null,
        qrStandardName: '',
        elementId: null,
        elementName: '',
        qrseMoc: 'M',
        qrseIsRoot: true,
        rootElementId: null,
        qrseStatus: '001',
        qrseDesc: '',
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelQRStandardElement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }


    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelQRStandardElement({
                    id: null,
                    qrseId: null,
                    qrStandardId: null,
                    qrStandardName: '',
                    elementId: null,
                    elementName: '',
                    qrseMoc: 'M',
                    qrseIsRoot: true,
                    rootElementId: null,
                    qrseStatus: '001',
                    qrseDesc: '',
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelQRStandardElement({
                    id: action.value.qrseId,
                    qrseId: action.value.qrseId,
                    qrStandardId: action.value.qrStandardId,
                    qrStandardName: action.value.qrStandardName,
                    elementId: action.value.elementId,
                    elementName: action.value.elementName,
                    qrseMoc: action.value.qrseMoc,
                    qrseIsRoot: action.value.qrseIsRoot,
                    rootElementId: action.value.rootElementId,
                    qrseStatus: action.value.qrseStatus,
                    qrseDesc: action.value.qrseDesc,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

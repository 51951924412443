import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page13Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

const ModalProductManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsCommonStatusDetailCode } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { productId, productName, productDesc, productStatus, id } = statePage
        const data = {
            productName: `${productName}`,
            productDesc: `${productDesc}`,
            productStatus: `${productStatus}`
        }

        const a = CheckAllFields([productName, productStatus], ["Product Name", "Status"])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <div>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px' >
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column min-h-60px'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            {
                                id !== "" &&
                                <div className='col-6'>
                                    <p className={labelStyle}>PRODUCT ID</p>
                                    <input value={id} disabled className='bd-d4 bg-f4 br-5 h-40px w-100 s-1418 Lato-400 px-2 out-none ' />
                                </div>
                            }
                            <div className={`col-6`}>
                                <p className={labelStyle}>PRODUCT NAME</p>
                                <input value={statePage.productName} onChange={(e) => handleState("productName", e.target.value)} className={inputStyle} />
                            </div>
                            <div className={`col-6`}>
                                <p className={labelStyle}>STATUS</p>
                                <Select styles={inputStyleH25} className='h-40px' value={statePage.productStatus ? optionsCommonStatusDetailCode.find((item: any) => item.value === statePage.productStatus) : "001"} options={optionsCommonStatusDetailCode} onChange={(e) => handleState("productStatus", e.value)} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={(statePage.productDesc !== "null") && (statePage.productDesc !== null) ? statePage.productDesc : ""} onChange={(e) => handleState("productDesc", e.target.value)} className='bd-d4 bg-ff br-5 w-100 s-1418 Lato-400 px-2 out-none '></textarea>
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>
            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </div>
    )
}


const initialState = {
    titleModal: `New ${Page13Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    productId: "",
    productName: "",
    productDesc: "",
    productStatus: "001",
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'clearData':
            return {
                ...state,
                productId: '',
                productName: '',
                productDesc: '',
                productStatus: '001',
            };
        case 'setData':
            return {
                ...state,
                productId: action.value.productId,
                productName: action.value.productName,
                productDesc: action.value.productDesc,
                productStatus: action.value.productStatus,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'productId':
            return {
                ...state,
                productId: action.value,
            };
        case 'productName':
            return {
                ...state,
                productName: action.value,
            };
        case 'productDesc':
            return {
                ...state,
                productDesc: action.value,
            };
        case 'productStatus':
            return {
                ...state,
                productStatus: action.value,
            };
        default:
            throw new Error();
    }
}


export default ModalProductManagement;

import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer, useState } from 'react';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-modal';
import { ModelInterfaceRecord } from './ModelInterfaceRecord';
import { Page38Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalInterfaceRecord = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props

    const {
        optionsFileStringSeparatorDetailCode,
        optionsFieldMappingDetailCode
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])


    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["recordName"]
        let limit = [8]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'recordSize') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 1000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
                needLoading: true,
            }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            recordId,
            recordName,
            recordSize,
            recordMappingCode,
            recordCreateBy,
            recordCreateDate,
            recordUpdateBy,
            recordUpdateDate,
            recordDeleteBy,
            recordDeleteDate,
            recordIsDelete,
            recordSeparator,

        } = statePage.data
        const data = {
            recordName: recordName,
            recordSize: recordSize,
            recordMappingCode: recordMappingCode,
            recordSeparator: recordSeparator,
        }
        const a = CheckAllFields(
            [
                recordName,
                recordSize,
                recordMappingCode,
                recordSeparator,
            ],
            [
                'RECORD NAME',
                'RECORD SIZE',
                'FIELD MAPPING',
                'RECORD SEPARATOR',
            ]
        )
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>RECORD NAME</p>
                                <input value={statePage.data.recordName} onChange={(e) => handleState('recordName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>RECORD SIZE</p>
                                <input type='number' min={1} value={statePage.data.recordSize} onChange={(e) => handleState('recordSize', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>FIELD MAPPING</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFieldMappingDetailCode} value={optionsFieldMappingDetailCode.filter((option: any) => option.value === statePage.data.recordMappingCode)} onChange={(e) => handleState('recordMappingCode', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>RECORD SEPARATOR </p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileStringSeparatorDetailCode} value={optionsFileStringSeparatorDetailCode.filter((option: any) => option.value === statePage.data.recordSeparator)} onChange={(e) => handleState('recordSeparator', e.value)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page38Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInterfaceRecord({
        id: null,
        recordId: null,
        recordName: null,
        recordSize: null,
        recordMappingCode: null,
        recordCreateBy: null,
        recordCreateDate: null,
        recordUpdateBy: null,
        recordUpdateDate: null,
        recordDeleteBy: null,
        recordDeleteDate: null,
        recordIsDelete: null,
        recordSeparator: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: "",
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceRecord) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceRecord({
                    id: null,
                    recordId: null,
                    recordName: null,
                    recordSize: null,
                    recordMappingCode: null,
                    recordCreateBy: null,
                    recordCreateDate: null,
                    recordUpdateBy: null,
                    recordUpdateDate: null,
                    recordDeleteBy: null,
                    recordDeleteDate: null,
                    recordIsDelete: null,
                    recordSeparator: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceRecord({
                    id: action.value.recordId,
                    recordId: action.value.recordId,
                    recordName: action.value.recordName,
                    recordSize: action.value.recordSize,
                    recordMappingCode: action.value.recordMappingCode,
                    recordCreateBy: action.value.recordCreateBy,
                    recordCreateDate: action.value.recordCreateDate,
                    recordUpdateBy: action.value.recordUpdateBy,
                    recordUpdateDate: action.value.recordUpdateDate,
                    recordDeleteBy: action.value.recordDeleteBy,
                    recordDeleteDate: action.value.recordDeleteDate,
                    recordIsDelete: action.value.recordIsDelete,
                    recordSeparator: action.value.recordSeparator,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

import { useCallback, useEffect, useState } from "react";
import { icon_search } from "../Assets/Images/svg";
import debounce from "lodash/debounce";
import { Tooltip } from "antd";

export const SearchTable = ({ ...props }): JSX.Element | null => {
    const { needSearchFunction, handleState, stateParent } = props;

    const [search, setSearch] = useState('');
    const [withoutSymbolSearch, setWithoutSymbolSearch] = useState('');

    useEffect(() => {
        let replacedSearch = search.replace(/\[|\]/g, ""); // remove character  [] and |
        handleSearch(replacedSearch)
    }, [withoutSymbolSearch])

    useEffect(() => {
        setSearch(stateParent.search)
    }, [stateParent.search])

    const handleSearch = useCallback(
        debounce((value) => {
            handleState("search", value)
        }, 2000),
        []
    );


// 
    const handleChange = (e: any) => {
        setSearch(e.target.value)
        setWithoutSymbolSearch(e.target.value)
    };


    if (needSearchFunction === true) {
        return (
            <div className="d-flex bd-d4 br-30 search-field h-100 ">
                {icon_search}
                <Tooltip placement="bottom" title={`${stateParent.SearchByPlaceHolder !== undefined ? `Search By ${stateParent.SearchByPlaceHolder}` : 'Search'}`}>
                    <input value={withoutSymbolSearch} type="text" placeholder={`${stateParent.SearchByPlaceHolder !== undefined ? `Search By ${stateParent.SearchByPlaceHolder}` : 'Search'}`} className="s-1620 out-none bd-unset h-100 w-100 br-30" style={{ paddingRight: '20px' }} onChange={handleChange} />
                </Tooltip>
            </div>
        )
    } else return null


}
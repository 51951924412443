import { CheckUndefined, ExportCSV } from "../../../Utils";

export const ExcelUserManagement = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showUserCategoryCode = '';
        let showUserLevel = '';
        let showUserStatus = '';
        if (stateOptions.optionsUserCategoryDetailCode.length > 0) {
            stateOptions.optionsUserCategoryDetailCode.forEach((element2: any) => {
                if (element2.value === item.userCategoryCode) {
                    showUserCategoryCode = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsUserPrivilegeLevel.length > 0) {
            stateOptions.optionsUserPrivilegeLevel.forEach((element2: any) => {
                if (element2.value === item.userLevel) {
                    showUserLevel = `${element2.label}`;
                }
            });
        }

        if (stateOptions.optionsUserStatusDetailCode.length > 0) {
            stateOptions.optionsUserStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.userStatus) {
                    showUserStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            id: CheckUndefined(item.userId), //userId
            userName: CheckUndefined(item.userName),
            userEmail: CheckUndefined(item.userEmail),
            userCategoryCode: CheckUndefined(showUserCategoryCode),
            userLevel: CheckUndefined(showUserLevel),
            userStatus: CheckUndefined(showUserStatus),
            userStartDate: CheckUndefined(item.userStartDate),
            userEndDate: CheckUndefined(item.userEndDate),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, state.titlePage)
};
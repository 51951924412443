import { createSlice } from "@reduxjs/toolkit";

export const reversalTransactionMonitoringStatusSlice = createSlice({
  name: "reversalTransactionMonitoringStatus",
  initialState: {
    safId: "", //new
    transId: "",
    transAuthIntResponseCode: "", // search for status => reason code
    statusSendToApi: "", // search for status => reason code
    transStatusName: "", // reason code name
    transCurrencyCode: "", // currency code
    issuerId: "", // search for issuer
    acquirerId: "", // search for acquirer
    issuerSwitcherId: "", // search for issuerSwitcherId
    acquirerSwitcherId: "", // search for acquirerSwitcherId
    //
    issuerIdCode: "", // search for issuerCode
    acquirerIdCode: "", // search for acquirerCode
    issuerSwitcherIdCode: "", // search for issuerSwitcherIdCode
    acquirerSwitcherIdCode: "", // search for acquirerSwitcherIdCode
    // Product
    selectedProductId: "",
    selectedProductLabel: "",
    selectedProductSendToApi: "",
    // Product Feature
    selectedProductFeatureId: "",
    selectedProductFeatureLabel: "",
    selectedProductFeatureSendToApi: "",
    // 
    filterStartDate: new Date().toISOString().slice(0, 10), // search for by start date
    filterEndDate: new Date().toISOString().slice(0, 10), // // search for by end date
    search: "",

    product: null,
    productFeature: null,
    safStatus: '',
    oriTransId: '',
  },
  reducers: {
    changeStateReversalTransactionMonitoringStatus: (state, action) => {
      state.safId = action.payload.safId === undefined || action.payload.safId === null ? state.safId : action.payload.safId;
      state.transAuthIntResponseCode = action.payload.transAuthIntResponseCode === undefined || action.payload.transAuthIntResponseCode === null ? state.transAuthIntResponseCode : action.payload.transAuthIntResponseCode;
      state.statusSendToApi = action.payload.statusSendToApi === undefined || action.payload.statusSendToApi === null ? state.statusSendToApi : action.payload.statusSendToApi;
      state.transStatusName = action.payload.transStatusName === undefined || action.payload.transStatusName === null ? state.transStatusName : action.payload.transStatusName;
      state.transCurrencyCode = action.payload.transCurrencyCode === undefined || action.payload.transCurrencyCode === null ? state.transCurrencyCode : action.payload.transCurrencyCode;
      state.issuerId = action.payload.issuerId === undefined || action.payload.issuerId === null ? state.issuerId : action.payload.issuerId;
      state.acquirerId = action.payload.acquirerId === undefined || action.payload.acquirerId === null ? state.acquirerId : action.payload.acquirerId;
      state.issuerSwitcherId = action.payload.issuerSwitcherId === undefined || action.payload.issuerSwitcherId === null ? state.issuerSwitcherId : action.payload.issuerSwitcherId;
      state.acquirerSwitcherId = action.payload.acquirerSwitcherId === undefined || action.payload.acquirerSwitcherId === null ? state.acquirerSwitcherId : action.payload.acquirerSwitcherId;

      state.issuerIdCode = action.payload.issuerIdCode === undefined || action.payload.issuerIdCode === null ? state.issuerIdCode : action.payload.issuerIdCode;
      state.acquirerIdCode = action.payload.acquirerIdCode === undefined || action.payload.acquirerIdCode === null ? state.acquirerIdCode : action.payload.acquirerIdCode;
      state.issuerSwitcherIdCode = action.payload.issuerSwitcherIdCode === undefined || action.payload.issuerSwitcherIdCode === null ? state.issuerSwitcherIdCode : action.payload.issuerSwitcherIdCode;
      state.acquirerSwitcherIdCode = action.payload.acquirerSwitcherIdCode === undefined || action.payload.acquirerSwitcherIdCode === null ? state.acquirerSwitcherIdCode : action.payload.acquirerSwitcherIdCode;

      state.selectedProductId = action.payload.selectedProductId === undefined || action.payload.selectedProductId === null ? state.selectedProductId : action.payload.selectedProductId;
      state.selectedProductLabel = action.payload.selectedProductLabel === undefined || action.payload.selectedProductLabel === null ? state.selectedProductLabel : action.payload.selectedProductLabel;
      state.selectedProductSendToApi = action.payload.selectedProductSendToApi === undefined || action.payload.selectedProductSendToApi === null ? state.selectedProductSendToApi : action.payload.selectedProductSendToApi;
      state.selectedProductFeatureId = action.payload.selectedProductFeatureId === undefined || action.payload.selectedProductFeatureId === null ? state.selectedProductFeatureId : action.payload.selectedProductFeatureId;
      state.selectedProductFeatureLabel = action.payload.selectedProductFeatureLabel === undefined || action.payload.selectedProductFeatureLabel === null ? state.selectedProductFeatureLabel : action.payload.selectedProductFeatureLabel;
      state.selectedProductFeatureSendToApi = action.payload.selectedProductFeatureSendToApi === undefined || action.payload.selectedProductFeatureSendToApi === null ? state.selectedProductFeatureSendToApi : action.payload.selectedProductFeatureSendToApi;


      state.transId = action.payload.transId === undefined || action.payload.transId === null ? state.transId : action.payload.transId;
      state.product = action.payload.product === undefined || action.payload.product === null ? state.product : action.payload.product;
      state.productFeature = action.payload.productFeature === undefined || action.payload.productFeature === null ? state.productFeature : action.payload.productFeature;
      state.filterStartDate = action.payload.filterStartDate === undefined || action.payload.filterStartDate === null ? state.filterStartDate : action.payload.filterStartDate;
      state.filterEndDate = action.payload.filterEndDate === undefined || action.payload.filterEndDate === null ? state.filterEndDate : action.payload.filterEndDate;
      state.search = action.payload.search === undefined || action.payload.search === null ? state.search : action.payload.search;
      state.safStatus = action.payload.safStatus === undefined || action.payload.safStatus === null ? state.safStatus : action.payload.safStatus;
      state.oriTransId = action.payload.oriTransId === undefined || action.payload.oriTransId === null ? state.oriTransId : action.payload.oriTransId;
      
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeStateReversalTransactionMonitoringStatus } = reversalTransactionMonitoringStatusSlice.actions;
export const stateGlobalReversalTransactionMonitoringStatus = (state:any) => state.reversalTransactionMonitoringStatus;
export default reversalTransactionMonitoringStatusSlice.reducer;

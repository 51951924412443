import { errNotificationIcon, icon_search_white, undo_icon } from "../../../Assets/Images/svg";

import { ButtonSearchBlue } from "../../../Components/ButtonSearchBlue";
import { CheckUndefinedStringEmpty } from "../../../Utils";
import Select from "react-select";
import { inputStyleH25, labelStyle } from "../../../Styles";
import moment from "moment";
import { stateGlobalTransactionTestInquiry } from "../../../Store/TransactionTestInquirySlice";
import { useSelector } from "react-redux";
import { format } from 'date-fns';
import { DatePickerBlueRightIcon } from "../../../Components/DatepickerBlueRightIcon";

export const FilterComponentTSM = ({ ...props }) => {
    const globalState = useSelector(stateGlobalTransactionTestInquiry);
    const { state, stateOptions, stateCustomComponent, handleStateGlobal, resetFilterStatus, initFunctionCustom } = props;
    const {

        transId,
        transStatusName,
        statusSendToApi,
        issuerId,
        issuerSwitcherId,
        acquirerSwitcherId,
        acquirerId,
        selectedProductId,
        selectedProductLabel,
        selectedProductSendToApi,
        selectedProductFeatureId,
        selectedProductFeatureLabel,
        selectedProductFeatureSendToApi,
        filterStartDate,
        filterEndDate,
        search,
        transCurrencyCode,
        approvalCode,
        STAN,
        RRN,
        InvoiceNumber,
        GlobalID,
        MID,
        TID,
        transactionAmount,
        issuerIdCode,
        acquirerIdCode,
        issuerSwitcherIdCode,
        acquirerSwitcherIdCode,
        testCaseId,
        testCat,
        selectedTestValid

    } = globalState;

    const userLogin = useSelector((state: any) => state.user)
    let statusReasonCode = stateOptions.optionsReasonCode;
    
    if (CheckUndefinedStringEmpty(transStatusName) !== '') {
        statusReasonCode = stateOptions.optionsReasonCode.filter((option: any) => option.title === transStatusName || option.title === 'All');
    }
    
    // 06/09/2024 change response code options
    const optionsResponseCode : any = [
        {
            label: "All",
            value: "",
        },
        {
            label: "Approved",
            value: "0000",
        },
        {
            label: "Not Approved",
            value: "0001",
        },
    ];

    return (
        <div className='d-flex flex-column w-100'>
            <div className='row bg-ff br-10 bd-d4 gx-2 gy-2 px-2'>
                {/* STAN */}
                <div className='col-2'>
                    <p className={labelStyle}>TEST CATEGORY</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={testCat}
                            onChange={(e) => {
                                handleStateGlobal('testCat', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={64}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>TEST CASE ID</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={testCaseId}
                            onChange={(e) => {
                                handleStateGlobal('testCaseId', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={64}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>TEST RESULT</p>
                    <div className='d-flex justify-content-between'>
                        <Select
                            placeholder={''}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsTestValid}
                            value={stateOptions.optionsTestValid.filter((option: any) => option.value === selectedTestValid)}
                            onChange={(e) => {
                                handleStateGlobal('selectedTestValid', e.value);
                                resetFilterStatus();
                            }}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>STAN</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={STAN}
                            onChange={(e) => {
                                handleStateGlobal('STAN', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={6}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>RRN</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={RRN}
                            onChange={(e) => {
                                handleStateGlobal('RRN', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={64}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>INV NO</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={InvoiceNumber}
                            onChange={(e) => {
                                handleStateGlobal('InvoiceNumber', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={128}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>GLOBAL ID</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={GlobalID}
                            onChange={(e) => {
                                handleStateGlobal('GlobalID', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={255}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>START DATE</p>
                    <div className='d-flex justify-content-between'>
                        <DatePickerBlueRightIcon
                            maxDate={moment().format('YYYY-MM-DD')}
                            selected={new Date(`${filterStartDate}`)}
                            needTimeInput={false}
                            onChange={(date: any) => {
                                handleStateGlobal(
                                    'filterStartDate',
                                    format(date, 'yyyy-MM-dd')
                                );
                                if (filterEndDate === '') {
                                    let dateNow = new Date()
                                        .toISOString()
                                        .slice(0, 10);
                                    handleStateGlobal(
                                        'filterEndDate',
                                        dateNow
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>END DATE</p>
                    <div className='d-flex'>
                    <DatePickerBlueRightIcon
                        maxDate={moment().format('YYYY-MM-DD')}
                        selected={new Date(`${filterEndDate}`)}
                        needTimeInput={false}
                        onChange={(date: any) => {
                            handleStateGlobal(
                                'filterEndDate',
                                format(date, 'yyyy-MM-dd')
                            );
                            if (filterStartDate === '') {
                                let dateNow = new Date()
                                    .toISOString()
                                    .slice(0, 10);
                                handleStateGlobal(
                                    'filterStartDate',
                                    dateNow
                                );
                            }
                        }}
                    />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>issuer</p>
                    <div className='d-flex justify-content-between'>
                        <Select placeholder={''}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail}
                            value={stateOptions.optionsIssuerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === issuerIdCode)}
                            onChange={(e) => {
                                handleStateGlobal('issuerIdCode', e.value);
                                resetFilterStatus();
                            }} />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>issuer switcher</p>
                    <div className='d-flex justify-content-between'>
                        <Select placeholder={''}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail}
                            value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === issuerSwitcherIdCode)}
                            onChange={(e) => {
                                handleStateGlobal('issuerSwitcherIdCode', e.value);
                                resetFilterStatus();

                            }} />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>Acquirer switcher</p>
                    <div className='d-flex justify-content-between'>
                        <Select placeholder={''}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail}
                            value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === acquirerSwitcherIdCode)}
                            onChange={(e) => {
                                handleStateGlobal('acquirerSwitcherIdCode', e.value);
                                resetFilterStatus();
                            }} />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>Acquirer</p>
                    <div className='d-flex justify-content-between'>
                        <Select placeholder={''}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail}
                            value={stateOptions.optionsAcquirerInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === acquirerIdCode)}
                            onChange={(e) => {
                                handleStateGlobal('acquirerIdCode', e.value)
                                resetFilterStatus('dst', e.value);
                            }} />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>product indicator</p>
                    <div className='d-flex justify-content-between'>
                        <Select
                            placeholder={''}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsProductFeatureProdFeatureIndAsValue}
                            value={stateOptions.optionsProductFeatureProdFeatureIndAsValue.filter((option: any) => option.value === selectedProductFeatureId)}
                            onChange={(e) => {
                                handleStateGlobal('selectedProductFeatureId', e.value);
                                handleStateGlobal('selectedProductFeatureSendToApi', e.sendToApi);
                                resetFilterStatus();
                            }}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>response code</p>
                    <div className='d-flex justify-content-between'>
                        <Select
                            placeholder={''}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={optionsResponseCode}
                            value={optionsResponseCode.filter((option: any) => option.value === statusSendToApi)}
                            onChange={(e) => {
                                handleStateGlobal('transAuthIntResponseCode', e?.value);
                                handleStateGlobal('statusSendToApi', e?.value);
                                handleStateGlobal('transStatusName', e?.label);
                                resetFilterStatus();
                            }}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>MID</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={MID}
                            onChange={(e) => {
                                handleStateGlobal('MID', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={32}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>TID</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={TID}
                            onChange={(e) => {
                                handleStateGlobal('TID', e.target.value);
                                resetFilterStatus();
                            }}
                            maxLength={32}
                        />
                    </div>
                </div>
                <div className='col-2'>
                    <p className={labelStyle}>AMOUNT</p>
                    <div className='d-flex justify-content-between'>
                        <input
                            type="text"
                            className='bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100'
                            placeholder=''
                            value={transactionAmount}
                            onChange={(e) => {
                                const value = e.target.value;
                                const validDecimal = /^\d*\.?\d*$/; 
                                if (validDecimal.test(value) && value.length <= 18) {
                                    handleStateGlobal('transactionAmount', value);
                                    resetFilterStatus();
                                }
                            }}
                        />
                    </div>
                </div>

                {state.errorMessage !== '' && (
                    <div className="col-12 pl-0">
                        <div className="d-flex bd-e8 br-10 py-1 px-1 align-items-center wd-fit mt-2">
                            {errNotificationIcon}
                            <p className="px-2 s-1214 Lato-600 c-e87">
                                {state.errorMessage}
                            </p>
                        </div>
                    </div>
                )}
                <div className='d-flex justify-content-end'>
                    <div className='d-flex p-2 justify-content-end'>
                        <button className='btn d-flex bg-d5 h-30px mx-1 mr-100px'
                            onClick={(e) => {
                                handleStateGlobal('testCat', '');
                                handleStateGlobal('testCaseId', '');
                                handleStateGlobal('selectedTestValid', '');
                                handleStateGlobal('STAN', '');
                                handleStateGlobal('RRN', '');
                                handleStateGlobal('InvoiceNumber', '');
                                handleStateGlobal('GlobalID', '');
                                let dateNow = new Date().toISOString().slice(0, 10);
                                handleStateGlobal('filterStartDate', dateNow);
                                handleStateGlobal('filterEndDate', dateNow);
                                handleStateGlobal('MID', '');
                                handleStateGlobal('TID', '');
                                handleStateGlobal('transactionAmount', '');
                                handleStateGlobal('transAuthIntResponseCode', '');
                                handleStateGlobal('statusSendToApi', '');
                                handleStateGlobal('transStatusName', '');
                                handleStateGlobal('selectedProductId', '');
                                handleStateGlobal('selectedProductSendToApi', '');
                                handleStateGlobal('issuerIdCode', '');
                                handleStateGlobal('issuerSwitcherIdCode', '');
                                handleStateGlobal('acquirerSwitcherIdCode', '');
                                handleStateGlobal('acquirerIdCode', '');
                                handleStateGlobal('selectedProductFeatureId', '');
                                handleStateGlobal('selectedProductFeatureSendToApi', '');
                                resetFilterStatus();
                            }}
                        >

                            <svg style={{ width: "18px", height: "18px" }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                    fill="#FFFFFF"
                                />
                            </svg>
                            <p className="s-1418 c-ff Lato-600 mx-2">Reset</p>
                        </button>
                    </div>
                    <div className='d-flex'>
                        <ButtonSearchBlue 
                            filterStartDate={filterStartDate} 
                            initFunctionCustom={initFunctionCustom} 
                            disabled={state.errorMessage !== ''}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}
import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { NavigationModal, NavigationTable, SelectView, TableWithLimitOffset } from "../../Components";
import { addData, checkIndex, handleState, setModal, setToggle } from "../../State";
import { close, undo_icon } from "../../Assets/Images/svg";
import { inputStyle, inputStyleH25, labelStyle } from "../../Styles";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
import { ModalSalesInquiry } from "../SalesInquiry/ModalSalesInquiry";
import { ModalSalesInquiry_rrn_stan } from "./ModalSalesInquiry_rrn_stan";
import Select from 'react-select';
import { ShowModalInterface } from "../../Interface";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { urlSelection } from "../../Services/API";
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { ResizableBox } from "react-resizable";
import { ButtonSearch } from "../../Components/ButtonSearch";

interface TestingInterface {
    id: null | number | string;
    settleDate: string | null;
    authDate: string | null;
    authRrn: string | null;
    invNo: string | null;
    authTransId: string | null;
    authCurrCode: string | null;
    authAmount: number | null;
    prodFeatureName: string | null;
    editCode: string | null;
    acqName: string | null;
    issName: string | null;
    acqSwitchName: string | null;
    issSwitchName: string | null;
    transType: string | null;

}

export class ModelTesting {
    id: null | number | string;
    settleDate: string | null;
    authDate: string | null;
    authRrn: string | null;
    invNo: string | null;
    authTransId: string | null;
    authCurrCode: string | null;
    authAmount: number | null;
    prodFeatureName: string | null;
    editCode: string | null;
    acqName: string | null;
    issName: string | null;
    acqSwitchName: string | null;
    issSwitchName: string | null;
    transType: string | null;


    constructor(value: TestingInterface) {
        this.id = value.id;
        this.settleDate = value?.settleDate ?? null;
        this.authDate = value?.authDate ?? null;
        this.authRrn = value?.authRrn ?? null;
        this.invNo = value?.invNo ?? null;
        this.authTransId = value?.authTransId ?? null;
        this.authCurrCode = value?.authCurrCode ?? null;
        this.authAmount = value?.authAmount ?? null;
        this.prodFeatureName = value?.prodFeatureName ?? null;
        this.editCode = value?.editCode ?? null;
        this.acqName = value?.acqName ?? null;
        this.issName = value?.issName ?? null;
        this.acqSwitchName = value?.acqSwitchName ?? null;
        this.issSwitchName = value?.issSwitchName ?? null;
        this.transType = value?.transType ?? null;
    }
}
interface Transaction {
    no: string;
    settleDate: string;
    authDate: string;
    stan: string;
    rrn: string;
    invNo: string;
    fee: string;
    reconStatus: string;
    amount: string;
    id: string;
}

const TableCustomLeft = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const {
        data,
        columns,
        state,
        pagination,
        setPagination,
        initFunctionCustom,
        handleStateComponent,
        handleStateGlobal,
        handleRowClick,
        reloadDataComponent,
        dispatch, handleClickRowTable
    } = props

    const table = useMaterialReactTable({
        // 1. Column Definitions
        columns,

        // 2. Data & State
        data,
        state: { pagination },
        initialState: {
            density: 'compact',
        },

        // 3. Editing & Row Actions

        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        enableGlobalFilterRankedResults: false,

        // 4. Pagination & Row Numbers
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        enablePagination: true,
        enableGlobalFilter: false,
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },

        // onGlobalFilterChange: (filterValue) =>
        //     handleStateGlobal('search', filterValue),

        // 5. Positioning & Layout
        enableFullScreenToggle: false,
        enableColumnFilterModes: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row, table }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);

            return {
                onDoubleClick: () => { props.editData(row.original) },
                sx: {
                    cursor: 'pointer',
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white', // Change text color to white on hover
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px', // Set font size for table cells
                fontFamily: 'system-ui, sans-serif', // Set font family for table cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiSearchTextFieldProps: {
            placeholder: 'Search By Invoice No, Auth STAN',
            size: 'small',
            sx: {
                fontSize: '5px',
                fontFamily: 'system-ui, sans-serif'
            }
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            }
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        // 6. Event Handler
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="">
                <div className="row justify-content-between py-1">
                    <div className="col-6 d-flex align-items-center">
                        <p className="Lato-400 s-1822 c-29">List</p>
                        <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">{state.totalData}</p>
                    </div>
                </div>
                {/* <Button
                    disabled={state.totalData <= 0}
                    onClick={exportToCSVComponent}
                    startIcon={<FileDownloadIcon />}
                    className="h-30px hover-button-red"
                    sx={{
                        color: '#f15e5e'
                    }}
                >
                    Export to CSV
                </Button> */}
            </div>
        ),
    })

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
}

const TableCustomRight = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const {
        data,
        columns,
        state,
        pagination,
        setPagination,
        initFunctionCustom,
        handleStateComponent,
        handleStateGlobal,
        handleRowClick,
        reloadDataComponent,
        dispatch, handleClickRowTable
    } = props

    const table = useMaterialReactTable({
        // 1. Column Definitions
        columns,

        // 2. Data & State
        data,
        state: { pagination },
        initialState: {
            density: 'compact',
        },

        // 3. Editing & Row Actions
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        enableGlobalFilterRankedResults: false,

        // 4. Pagination & Row Numbers
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        enablePagination: true,
        enableGlobalFilter: false,
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },

        // onGlobalFilterChange: (filterValue) =>
        //     handleStateGlobal('search', filterValue),

        // 5. Positioning & Layout
        enableFullScreenToggle: false,
        enableColumnFilterModes: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row, table }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);

            return {
                onDoubleClick: () => props.editData(row.original),
                sx: {
                    cursor: 'pointer',
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white', // Change text color to white on hover
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px', // Set font size for table cells
                fontFamily: 'system-ui, sans-serif', // Set font family for table cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiSearchTextFieldProps: {
            placeholder: 'Search By Invoice No, Auth STAN',
            size: 'small',
            sx: {
                fontSize: '5px',
                fontFamily: 'system-ui, sans-serif'
            }
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            }
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        // 6. Event Handler
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="">
                <div className="row justify-content-between py-1">
                    <div className="col-6 d-flex align-items-center">
                        <p className="Lato-400 s-1822 c-29">List</p>
                        <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">{state.totalData}</p>
                    </div>
                </div>
                {/* <Button
                    disabled={state.totalData <= 0}
                    onClick={exportToCSVComponent}
                    startIcon={<FileDownloadIcon />}
                    className="h-30px hover-button-red"
                    sx={{
                        color: '#f15e5e'
                    }}
                >
                    Export to CSV
                </Button> */}
            </div>
        ),
    })

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
}

const ModalDetailSettlementSummaryAcquirer = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    //
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateTableLeft, dispatchTableLeft] = useReducer(reducerTableLeft, initialStateTableLeft);
    const [stateTableRight, dispatchTableRight] = useReducer(reducerTableRight, initialStateTableRight);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 10 });
    const [dragging, setDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [paginationLeft, setPaginationLeft] = useState({
        pageIndex: 0,
        pageSize: stateTableLeft.view,
    });
    const [paginationRight, setPaginationRight] = useState({
        pageIndex: 0,
        pageSize: stateTableLeft.view,
    });
    const [statePage, dispatchStatePage] = useReducer(reducerStatePage, initialStatePage);
    const handleMouseDown = (e: any) => {
        setDragging(true);
        setOffset({
            x: e.clientX - modalPosition.x,
            y: e.clientY - modalPosition.y,
        });
    };
    const onResize = (event: any, propsSize: any) => {
        const { node, size, handle } = propsSize;
        if (size.width > 100 && size.height > 100) {
            dispatchStatePage({ type: "resize", value: { width: size.width, height: size.height } });
        }
    };

    const handleMouseMove = (e: any) => {
        if (dragging) {
            setModalPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    React.useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);




    const { stateFirstParent, stateParent, stateOptions, dispatchFirstParent, dataLeft } = props;
    const { optionsTransactionTypeDetailCodeAndCcdName } = stateOptions



    const initFunctionCustomTableLeft = (needRefreshPage: Boolean | null) => {
        let selectedTransType = stateParent.selectedTransType !== '' ? `transactionTypeId=${stateParent.selectedTransType}&` : '';
        let selectedMemberSettleDate = stateParent.selectedMemberSettleDate !== '' ? `settleDate=${stateParent.selectedMemberSettleDate}&` : '';
        let selectedMemberTableType = stateParent.selectedMemberTableType !== '' ? `tableType=${stateParent.selectedMemberTableType}&` : '';
        let selectedMemberCode = stateParent.selectedMemberCode !== '' ? `memberCode=${stateParent.selectedMemberCode}&` : '';
        let selectedMemberRole = stateParent.selectedMemberRole !== '' ? `memberRole=${stateParent.selectedMemberRole}&` : '';
        let selectedSecondSettleDate = stateParent.selectedSecondSettleDate !== '' ? `destMemberSettleDate=${stateParent.selectedSecondSettleDate}&` : '';
        let selectedAuthDate = stateParent.selectedSecondSettleDate !== '' ? `authDate=${stateParent.selectedSecondSettleDate}&` : '';

        let selectedSecondMemberCode = stateParent.selectedSecondMemberCode !== '' ? `destMemberCode=${stateParent.selectedSecondMemberCode}&` : '';

        // -----------------
        let limitTableLeft = `limit=${stateTableLeft.view}&`;
        let offsetTableLeft = `offset=${paginationLeft.pageIndex * stateTableLeft.view}&`;
        // -----------------
        let search = stateTableLeft.search !== '' ? `search=${stateTableLeft.search}&` : '';
        let selectedReconStatus = stateTableLeft.selectedReconStatus !== '' ? `reconStatus=${stateTableLeft.selectedReconStatus}&` : '';
        let invNoValue = stateTableLeft.invNoValue !== '' ? `invNo=${stateTableLeft.invNoValue}&` : '';
        let stanValue = stateTableLeft.stanValue !== '' ? `stan=${stateTableLeft.stanValue}&` : '';
        let rrnValue = stateTableLeft.rrnValue !== '' ? `rrn=${stateTableLeft.rrnValue}&` : '';


        let finalParameter1 = `${search}${selectedTransType}${selectedReconStatus}${selectedMemberSettleDate}${selectedMemberTableType}${selectedMemberCode}${selectedMemberRole}${selectedSecondSettleDate}${selectedSecondMemberCode}${limitTableLeft}${offsetTableLeft}${selectedAuthDate}${invNoValue}${stanValue}${rrnValue}`;

        if (needRefreshPage === true) {
            if (finalParameter1 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `settlement/settlement-summary-pop-up-detail?${finalParameter1.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableLeft({ type: 'searchData', value: response.data });
                        dispatchTableLeft({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableLeft({ type: 'totalData', value: response.detail.total });
                        const total = response.data.reduce((sum: number, transaction: Transaction) => {
                            const amount = parseFloat(transaction.amount.replace(/^[A-Za-z\s]+/, '').trim());
                            return sum + amount;
                        }, 0);
                        dispatchTableLeft({ type: 'amountSummary', value: total.toFixed(2) });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }
        } else {
            if (finalParameter1 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `settlement/settlement-summary-pop-up-detail?${finalParameter1.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableLeft({ type: 'data', value: response.data });
                        dispatchTableLeft({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableLeft({ type: 'totalData', value: response.detail.total });
                        const total = response.data.reduce((sum: number, transaction: Transaction) => {
                            const amount = parseFloat(transaction.amount.replace(/^[A-Za-z\s]+/, '').trim());
                            return sum + amount;
                        }, 0);
                        dispatchTableLeft({ type: 'amountSummary', value: total.toFixed(2) });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }

        }
    }

    const initFunctionCustomTableRight = (needRefreshPage: Boolean | null) => {
        let selectedTransType = stateParent.selectedTransTypeRight !== '' ? `transactionTypeId=${stateParent.selectedTransTypeRight}&` : '';
        let selectedMemberSettleDate = stateParent.selectedMemberSettleDateRight !== '' ? `settleDate=${stateParent.selectedMemberSettleDateRight}&` : '';
        let selectedMemberTableType = stateParent.selectedMemberTableTypeRight !== '' ? `tableType=${stateParent.selectedMemberTableTypeRight}&` : '';
        let selectedMemberCode = stateParent.selectedMemberCodeRight !== '' ? `memberCode=${stateParent.selectedMemberCodeRight}&` : '';
        let selectedMemberRole = stateParent.selectedMemberRoleRight !== '' ? `memberRole=${stateParent.selectedMemberRoleRight}&` : '';
        let selectedSecondSettleDate = stateParent.selectedSecondSettleDateRight !== '' ? `destMemberSettleDate=${stateParent.selectedSecondSettleDateRight}&` : '';
        let selectedAuthDate = stateParent.selectedSecondSettleDateRight !== '' ? `authDate=${stateParent.selectedSecondSettleDateRight}&` : '';
        let selectedSecondMemberCode = stateParent.selectedSecondMemberCodeRight !== '' ? `destMemberCode=${stateParent.selectedSecondMemberCodeRight}&` : '';
        let invNoValue = stateTableRight.invNoValue !== '' ? `invNo=${stateTableRight.invNoValue}&` : '';
        let stanValue = stateTableRight.stanValue !== '' ? `stan=${stateTableRight.stanValue}&` : '';
        let rrnValue = stateTableRight.rrnValue !== '' ? `rrn=${stateTableRight.rrnValue}&` : '';
        let limitTableRight = `limit=${stateTableRight.view}&`;
        let selectedReconStatus = stateTableRight.selectedReconStatus !== '' ? `reconStatus=${stateTableRight.selectedReconStatus}&` : '';

        let offsetTableRight = `offset=${paginationRight.pageIndex * stateTableRight.view}&`;
        let search = stateTableRight.search !== '' ? `search=${stateTableRight.search}&` : '';
        // -----------------
        let finalParameter2 = `${search}${selectedTransType}${selectedReconStatus}${selectedMemberSettleDate}${selectedMemberTableType}${selectedMemberCode}${selectedMemberRole}${selectedSecondSettleDate}${selectedSecondMemberCode}${limitTableRight}${offsetTableRight}${selectedAuthDate}${invNoValue}${stanValue}${rrnValue}`;
        if (needRefreshPage === true) {
            if (finalParameter2 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `settlement/settlement-summary-pop-up-detail?${finalParameter2.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableRight({ type: 'searchData', value: response.data });
                        dispatchTableRight({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableRight({ type: 'totalData', value: response.detail.total });
                        const total = response.data.reduce((sum: number, transaction: Transaction) => {
                            const amount = parseFloat(transaction.amount.replace(/^[A-Za-z\s]+/, '').trim());
                            return sum + amount;
                        }, 0);
                        dispatchTableRight({ type: 'amountSummary', value: total.toFixed(2) });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }
        } else {
            if (finalParameter2 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `settlement/settlement-summary-pop-up-detail?${finalParameter2.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableRight({ type: 'data', value: response.data });
                        dispatchTableRight({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableRight({ type: 'totalData', value: response.detail.total });
                        // dispatchTableRight({ type: 'amountSummary', value: response.detail.amountSummary });
                        const total = response.data.reduce((sum: number, transaction: Transaction) => {
                            const amount = parseFloat(transaction.amount.replace(/^[A-Za-z\s]+/, '').trim());
                            return sum + amount;
                        }, 0);
                        dispatchTableRight({ type: 'amountSummary', value: total.toFixed(2) });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }


        }
    }

    useEffect(() => {
        // reset page if modal show is false
        if (props.detailModal === false) {
            dispatchTableLeft({ type: 'resetPosition' })
            dispatchTableRight({ type: 'resetPosition' })
        }
    }, [props.detailModal])


    useEffect(() => {
        initFunctionCustomTableLeft(false);
    }, [paginationLeft.pageIndex]);

    useEffect(() => {
        setPaginationLeft({ pageIndex: paginationLeft.pageIndex, pageSize: stateTableRight.view });
    }, [stateTableLeft.view]);

    useEffect(() => {
        initFunctionCustomTableRight(false);
    }, [paginationRight.pageIndex]);

    useEffect(() => {
        setPaginationRight({ pageIndex: paginationRight.pageIndex, pageSize: stateTableRight.view });
    }, [stateTableRight.view]);


    useEffect(() => {
        initFunctionCustomTableLeft(true)
    }, [stateTableLeft.search])

    useEffect(() => {
        initFunctionCustomTableRight(true)
    }, [stateTableRight.search])

    useEffect(() => {
        initFunctionCustomTableLeft(false)
    }, [stateParent.finalParameterLeftAPI, stateParent.selectedTransType, stateTableLeft.position, stateTableLeft.view]);

    useEffect(() => {
        initFunctionCustomTableRight(false);
    }, [stateParent.finalParameterRightAPI, stateParent.selectedTransType, stateTableRight.position, stateTableRight.view]);

    useEffect(() => {
        let currentPage: number = parseInt((stateTableLeft.position / stateTableLeft.view).toString());
        dispatchTableLeft({ type: 'currentPage', value: currentPage });
    }, [stateTableLeft.totalData, stateTableLeft.position, stateTableLeft.view])

    useEffect(() => {
        let currentPage: number = parseInt((stateTableRight.position / stateTableRight.view).toString());
        dispatchTableRight({ type: 'currentPage', value: currentPage });
    }, [stateTableRight.totalData, stateTableRight.position, stateTableRight.view])


    // TableLeft
    // TableRight
    const addDataComponentTableLeft = () => addData(dispatchTableLeft)
    const addDataComponentTableRight = () => addData(dispatchTableRight)
    const checkIndexComponentTableLeft = (nameAction: any) => checkIndex(nameAction, stateTableLeft)
    const checkIndexComponentTableRight = (nameAction: any) => checkIndex(nameAction, stateTableRight)
    const handleStateComponentTableLeft = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatchTableLeft)
    const handleStateComponentTableRight = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatchTableRight)
    const setToggleComponentTableLeft = (nameAction: any) => setToggle(nameAction, dispatchTableLeft, stateTableLeft)
    const setToggleComponentTableRight = (nameAction: any) => setToggle(nameAction, dispatchTableRight, stateTableRight)
    const setModalComponentTableLeft = (nameAction: any) => setModal(nameAction, dispatchTableLeft, stateTableLeft)
    const setModalComponentTableRight = (nameAction: any) => setModal(nameAction, dispatchTableRight, stateTableRight)

    const actionShowModalTableLeft = (idModal: any, item: any) => dispatchTableLeft({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionShowModalTableRight = (idModal: any, item: any) => dispatchTableRight({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModalTableLeft = (idModal: any) => dispatchTableLeft({ type: 'changeIndex', value: idModal })
    const actionIndexModalTableRight = (idModal: any) => dispatchTableRight({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItemTableLeft = (updateItemID: any) => initFunctionCustomTableLeft(false)
    const actionAfterUpdateItemTableRight = (updateItemID: any) => initFunctionCustomTableRight(false)

    type Data = {
        settleDate: string,
        authDate: string,
        stan: string,
        rrn: string,
        invNo: string,
        fee: string,
        reconStatus: string,
        amount: string,
    };
    const columnsLeftDataAPI = useMemo<MRT_ColumnDef<Data>[]>(() => [
        {
            accessorKey: 'settleDate',
            header: 'Settle Date',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => `${row.original.settleDate}`,
            // footer: 'Amount',
        },
        {
            accessorKey: 'authDate',
            header: 'Auth Date',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => `${row.original.authDate}`,
        },
        {
            accessorKey: 'fee',
            header: 'Fee',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.fee}`,
            //   footer: ` ${totalSettAmountLeft}`,
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.amount}`,
            //   footer: ` ${totalSettAmountLeft}`,
        },
        {
            accessorKey: 'reconStatus',
            header: 'Recon Status',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.reconStatus}`,
            //   footer: ` ${totalSettAmountLeft}`,
        },
        {
            accessorKey: 'stan',
            header: 'Auth Stan',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.stan}`,
        },
        {
            accessorKey: 'rrn',
            header: 'Auth RRN',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.rrn}`,
        },
        {
            accessorKey: 'invNo',
            header: 'Invoice No',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.invNo}`,
        },
    ], [stateTableLeft]);
    const columnsRightDataAPI = useMemo<MRT_ColumnDef<Data>[]>(() => [
        {
            accessorKey: 'settleDate',
            header: 'Settle Date',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => `${row.original.settleDate}`,
            // footer: 'Amount',
        },
        {
            accessorKey: 'authDate',
            header: 'Auth Date',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => `${row.original.authDate}`,
        },
        {
            accessorKey: 'fee',
            header: 'Fee',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.fee}`,
            //   footer: ` ${totalSettAmountLeft}`,
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.amount}`,
            //   footer: ` ${totalSettAmountLeft}`,
        },
        {
            accessorKey: 'reconStatus',
            header: 'Recon Status',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.reconStatus}`,
            //   footer: ` ${totalSettAmountLeft}`,
        },
        {
            accessorKey: 'stan',
            header: 'Auth Stan',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.stan}`,
        },
        {
            accessorKey: 'rrn',
            header: 'Auth RRN',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.rrn}`,
        },
        {
            accessorKey: 'invNo',
            header: 'Invoice No',
            maxSize: 100,
            enableSorting: false,
            Cell: ({ row }: any) => ` ${row.original.invNo}`,
        },
    ], [stateTableRight]);

    const tableComponentPageTableLeft = () => {
        return (
            <TableCustomLeft
                data={stateTableLeft.data}
                columns={columnsLeftDataAPI}
                state={stateTableLeft}
                pagination={paginationLeft}
                setPagination={setPaginationLeft}
                handleStateComponent={props.handleStateComponent}
                stateOptions={stateOptions}
                position={stateTableLeft.position}
                view={stateTableLeft.view}
                checkIndex={checkIndexComponentTableLeft}
                stateFirstParent={props.stateFirstParent}
                editData={(item: ModelTesting) => actionShowModalTableLeft(item.id, item)}
                dataLeft={dataLeft}
            // handleRowClick={handleRowClick}
            />
            // </div >
            // <TableCustomLeft
            //     codeAccessPrivilegeDelete={''}
            //     codeAccessPrivilegeEdit={''}
            //     label={stateTableLeft.label}
            //     data={stateTableLeft.data}
            // position={stateTableLeft.position}
            // view={stateTableLeft.view}
            //     customButtonAction={() => <></>}
            //     checkIndex={checkIndexComponentTableLeft}
            //     widthTable={`wd-${stateTableLeft.widthTable}-responsive`}
            //     stateOptions={stateOptions}
            //     //
            //     currentPage={stateTableLeft.currentPage}
            //     //
            //     stateFirstParent={props.stateFirstParent}
            //     stateParent={props.stateParent}
            //     //
            // editData={(item: ModelTesting) => actionShowModalTableLeft(item.id, item)}
            // />
        )
    }
    const tableComponentPageTableRight = () => {
        return (
            <TableCustomRight
                data={stateTableRight.data}
                columns={columnsRightDataAPI}
                state={stateTableRight}
                pagination={paginationRight}
                setPagination={setPaginationRight}
                handleStateComponent={props.handleStateComponent}
                stateOptions={stateOptions}
                position={stateTableRight.position}
                view={stateTableRight.view}
                checkIndex={checkIndexComponentTableRight}
                stateFirstParent={props.stateFirstParent}
                editData={(item: ModelTesting) => actionShowModalTableRight(item.id, item)}
            />
            // <TableCustomRight
            //     codeAccessPrivilegeDelete={''}
            //     codeAccessPrivilegeEdit={''}
            //     label={stateTableRight.label}
            //     data={stateTableRight.data}
            //     position={stateTableRight.position}
            //     view={stateTableRight.view}
            //     customButtonAction={() => <></>}
            //     checkIndex={checkIndexComponentTableRight}
            //     widthTable={`wd-${stateTableRight.widthTable}-responsive`}
            //     stateOptions={stateOptions}
            //     //
            //     currentPage={stateTableRight.currentPage}
            //     //
            //     stateFirstParent={props.stateFirstParent}
            //     stateParent={props.stateParent}
            //     //
            //     editData={(item: ModelTesting) => actionShowModalTableRight(item.id, item)}
            // />
        )
    }
    const filterComponentPageTableLeft = () => {
        return (

            // <div className="row container-all-select-custom d-flex" style={{ marginBottom: '10px' }}>
            //     <div className="row flex-end">
            <div className="d-flex flex-column w-100 my-2">
                <div className="row bg-ff br-10 bd-d4 gx-2 gy-2 px-2 flex-end">
                    <div className="col-3 my-2">
                        <p className={labelStyle}>RRN</p>
                        <div className="d-flex justify-content-between">
                            <input
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                placeholder=""
                                value={stateTableLeft.rrnValue}
                                onChange={(e) => {
                                    handleState("rrnValue", e.target.value, dispatchTableLeft)
                                }}
                                maxLength={12}
                            />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("rrnValue", '', dispatchTableLeft) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 my-2">
                        <p className={labelStyle}>Stan</p>
                        <div className="d-flex justify-content-between">
                            <input
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                placeholder=""
                                value={stateTableLeft.stanValue}
                                onChange={(e) => {
                                    handleState("stanValue", e.target.value, dispatchTableLeft)
                                }}
                                maxLength={6}
                            />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("stanValue", '', dispatchTableLeft) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 my-2">
                        <p className={labelStyle}>Invoice No</p>
                        <div className="d-flex justify-content-between">
                            <input
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                placeholder=""
                                value={stateTableLeft.invNoValue}
                                onChange={(e) => {
                                    handleState("invNoValue", e.target.value, dispatchTableLeft)
                                }}
                                maxLength={64}
                            />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("invNoValue", '', dispatchTableLeft) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 my-2">
                        <p className={labelStyle}>RECON STATUS</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill w-100' options={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail} value={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateTableLeft.selectedReconStatus)} onChange={(e) => handleState("selectedReconStatus", e.value, dispatchTableLeft)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedReconStatus", '', dispatchTableLeft) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div>
                        <ButtonSearch
                            // filterStartDate={filterStartDate}
                            initFunctionCustom={initFunctionCustomTableLeft}
                        // disabled={stateTableLeft.errorMessage !== ''}
                        />
                    </div>

                </div>
            </div>
        )
    }
    const filterComponentPageTableRight = () => {
        return (
            <div className="d-flex flex-column w-100 my-2">
                <div className="row bg-ff br-10 bd-d4 gx-2 gy-2 px-2 flex-end">
                    <div className="col-3 my-2">
                        <p className={labelStyle}>RRN</p>
                        <div className="d-flex justify-content-between">
                            <input
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                placeholder=""
                                value={stateTableRight.rrnValue}
                                onChange={(e) => {
                                    handleState("rrnValue", e.target.value, dispatchTableRight)
                                }}
                                maxLength={12}
                            />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("rrnValue", '', dispatchTableRight) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 my-2">
                        <p className={labelStyle}>Stan</p>
                        <div className="d-flex justify-content-between">
                            <input
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                placeholder=""
                                value={stateTableRight.stanValue}
                                onChange={(e) => {
                                    handleState("stanValue", e.target.value, dispatchTableRight)
                                }}
                                maxLength={6}
                            />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("stanValue", '', dispatchTableRight) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 my-2">
                        <p className={labelStyle}>Invoice No</p>
                        <div className="d-flex justify-content-between">
                            <input
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                placeholder=""
                                value={stateTableRight.invNoValue}
                                onChange={(e) => {
                                    handleState("invNoValue", e.target.value, dispatchTableRight)
                                }}
                                maxLength={64}
                            />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("invNoValue", '', dispatchTableRight) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 my-2">
                        <p className={labelStyle}>RECON STATUS</p>
                        <div className='d-flex justify-content-between'>
                            <Select styles={inputStyleH25} className='flex-fill w-100' options={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail} value={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateTableRight.selectedReconStatus)} onChange={(e) => handleState("selectedReconStatus", e.value, dispatchTableRight)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedReconStatus", '', dispatchTableRight) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div>
                        <ButtonSearch
                            // filterStartDate={filterStartDate}
                            initFunctionCustom={initFunctionCustomTableRight}
                        // disabled={stateTableLeft.errorMessage !== ''}
                        />
                    </div>

                </div>
            </div>
        )
    }


    const modalUpdateComponentPageTableLeft = () => {
        return (
            <>
                {
                    stateTableLeft.savedShowModal.map((element1: any) => {
                        return (
                            <ModalSalesInquiry_rrn_stan
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={stateTableLeft}
                                urlApi={stateTableLeft.urlApi}
                                showModal={element1.showModal}
                                actionShowModal={() => actionShowModalTableLeft(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModalTableLeft(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItemTableLeft}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }
    const modalUpdateComponentPageTableRight = () => {
        return (
            <>
                {
                    stateTableRight.savedShowModal.map((element1: any) => {
                        return (
                            <ModalSalesInquiry
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={stateTableRight}
                                urlApi={stateTableRight.urlApi}
                                showModal={element1.showModal}
                                actionShowModal={() => actionShowModalTableRight(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModalTableRight(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItemTableRight}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }
    let widthWindows_ = window.innerWidth;
    let heightWindows_ = window.innerHeight;

    return (
        <>
            {
                props.detailModal === true &&
                <Modal open={props.detailModal} onCancel={props.setModal}

                    maskClosable={false}
                    mask={true}
                    maskStyle={{
                        zIndex: 15,
                    }}
                    bodyStyle={{ overflowX: 'hidden' }}
                    style={{

                        position: 'fixed',
                        overflow: 'unset',
                        // right: 'auto',
                        // bottom: 'auto',
                        // top: '50%',
                        // left: '50%',
                        // marginRight: '-30%',
                        // transform: 'translate(-50%, -50%)',
                        //
                        right: '2%',
                        bottom: '3%',
                        top: modalPosition.y,
                        left: modalPosition.x,
                        minWidth: 'min-content',
                        zIndex: 100,
                    }}
                    footer={false}
                    title={state.titleModal}

                >
                    <div
                        //onMouseDown={handleMouseDown}
                        className='d-flex flex-column' style={{
                            height: `${heightWindows_ - 150}px `,
                            //  cursor: 'move', 
                            display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px'
                        }}>
                        <ResizableBox
                            height={statePage.height}
                            width={statePage.width}
                            onResize={onResize}
                            minConstraints={[300, 300]}
                        >
                            <div
                                className="h-150 d-flex"
                                style={{
                                    width: '100%',
                                }}
                            >
                                <div style={{ overflow: 'auto', flex: '1' }}>
                                    <p className="w-700 Lato-700 s-1620">
                                        {CheckUndefined(stateParent.dataLeftAPI?.settlementMemberDetailDto?.memberName)} ({stateParent.dataLeftAPI.type})
                                    </p>
                                    {filterComponentPageTableLeft()}
                                    {tableComponentPageTableLeft()}
                                    {modalUpdateComponentPageTableLeft()}
                                </div>

                                <Divider type="vertical" className="m-1" />
                                <div style={{ overflow: 'auto', flex: '1' }}>
                                    <p className="w-700 Lato-700 s-1620">
                                        {CheckUndefined(stateParent.dataRightAPI?.settlementMemberDetailDto?.memberName)} ({stateParent.dataRightAPI.type})
                                    </p>
                                    {filterComponentPageTableRight()}
                                    {tableComponentPageTableRight()}
                                    {modalUpdateComponentPageTableRight()}
                                </div>
                            </div>
                        </ResizableBox>
                    </div>
                </Modal>
            }
        </>


    );
}

const initialState = {
    titleModal: 'Transactions List',
}

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'selectedIss':
            return {
                ...state,
                selectedIss: action.value,
            };

        default:
            throw new Error();
    }
}


const initialStateTableLeft = {
    titlePage: ``,
    privilegeAccess: { view: "", add: "", edit: "", delete: "", download: "" },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Settle Date', width: 'wd-150', val: 'settleDate' },
        { field: 'Auth Date', width: 'wd-100', val: 'authDate' },
        { field: 'Auth STAN', width: 'wd-150', val: 'authStan' },
        { field: 'Invoice No', width: 'wd-200', val: 'invNo' },
        { field: 'Fee', width: 'wd-100', val: 'authFeeAmount' },
        { field: 'Amount', width: 'wd-150', val: 'authAmount' }, // acqSwitchCurrCode
        { field: 'dynamicColumn', width: 'wd-100', val: 'dynamicColumn' },
        { field: 'Recon Status', width: 'wd-100', val: 'reconStatus' },
    ],

    toggle: ["no", "settleDate", "authDate", "authStan", "invNo", "authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    complete_toggle: ["no", "settleDate", "authDate", "authStan", "invNo", "authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    list_column_name: ["No.", "Settle Date", "Auth Date", "Auth STAN", "Invoice No", "Fee", "Amount", "dynamicColumn", "Recon Status"],

    //
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Invoice No, Auth STAN',
    // memberData
    memberName: '',
    memberCurrencyCode: '',
    totalAmount: '',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 25, 50, 75, 100],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1200,
    dataLength: 0, // zIndex
    //
    selectedReconStatus: '',
    amountSummary: '',
    rrnValue: '',
    stanValue: '',
    invNoValue: ''
};
// acquire recon
const initialStateTableRight = {
    titlePage: ``,
    privilegeAccess: { view: "", add: "", edit: "", delete: "", download: "" },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Settle Date', width: 'wd-150', val: 'settleDate' },
        { field: 'Auth Date', width: 'wd-100', val: 'authDate' },
        { field: 'Auth STAN', width: 'wd-150', val: 'authStan' },
        { field: 'Auth RRN', width: 'wd-150', val: 'authRrn' },
        { field: 'Invoice No', width: 'wd-200', val: 'invNo' },
        { field: 'Fee', width: 'wd-100', val: 'authFeeAmount' },
        { field: 'Amount', width: 'wd-150', val: 'authAmount' }, // acqSwitchCurrCode
        { field: 'dynamicColumn', width: 'wd-100', val: 'dynamicColumn' },
        { field: 'Recon Status', width: 'wd-100', val: 'reconStatus' },
    ],

    toggle: ["no", "settleDate", "authDate", "authRrn", "authStan", "invNo", "authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    complete_toggle: ["no", "settleDate", "authDate", "authRrn", "authStan", "invNo", "authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    list_column_name: ["No.", "Settle Date", "Auth Date", "Auth RRN", "Auth STAN", "Invoice No", "Fee", "Amount", "dynamicColumn", "Recon Status"],
    //
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Invoice No, Auth RRN, Auth STAN',
    // memberData
    memberName: '',
    memberCurrencyCode: '',
    totalAmount: '',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 25, 50, 75, 100],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1300,
    dataLength: 0, // zIndex
    selectedReconStatus: '',
    amountSummary: '',
    rrnValue: '',
    stanValue: '',
    invNoValue: ''
};

function reducerTableLeft(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'amountSummary': {
            return {
                ...state,
                amountSummary: action.value,
            }
        }

        case 'resetPosition':
            return {
                ...state,
                view: 10,
                position: 10,
                totalData: 0, // new
                totalPages: 0,
                currentPage: 0,
                selectedReconStatus: '',
                search: '',
                amountSummary: '',
            };
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.invNo!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.invNo!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 120 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 120 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 120 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        showDataResult.push(
                            { idModal: element1.invNo!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.invNo!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };

        case 'selectedReconStatus':
            return {
                ...state,
                selectedReconStatus: action.value
            };
        case 'rrnValue':
            return {
                ...state,
                rrnValue: action.value
            };
        case 'stanValue':
            return {
                ...state,
                stanValue: action.value
            };
        case 'invNoValue':
            return {
                ...state,
                invNoValue: action.value
            };
        default:
            throw new Error();
    }
}

function reducerTableRight(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'amountSummary': {
            return {

                ...state,
                amountSummary: action.value,
            }
        }
        case 'resetPosition':
            return {
                ...state,
                view: 10,
                position: 10,
                totalData: 0, // new
                totalPages: 0,
                currentPage: 0,
                selectedReconStatus: '',
                search: '',
                amountSummary: '',
            };
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.authTransId!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.authTransId!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 120 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 120 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 120 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        showDataResult.push(
                            { idModal: element1.authTransId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.authTransId!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };

        case 'selectedReconStatus':
            return {
                ...state,
                selectedReconStatus: action.value
            };
        case 'rrnValue':
            return {
                ...state,
                rrnValue: action.value
            };
        case 'stanValue':
            return {
                ...state,
                stanValue: action.value
            };
        case 'invNoValue':
            return {
                ...state,
                invNoValue: action.value
            };
        default:
            throw new Error();
    }

}
const initialStatePage = {
    bounds: false,
    //
    data: null,
    //
    id: "",
    //
    width: window.innerWidth*0.85,
    height: window.innerHeight*0.70,
};

const reducerStatePage = (state: any, action: any) => {
    switch (action.type) {
        case "resize":
            return {
                ...state,
                width: action.value.width,
                height: action.value.height,
            };

        default:
            throw new Error();
    }
};



// SettlementSummaryDetail


export default ModalDetailSettlementSummaryAcquirer;
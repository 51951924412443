import { ButtonAdd } from "./ButtonAdd";
import { ButtonDownload } from "./ButtonDownload";
import { SearchTable } from "./SearchTable";
import { ToggleTable } from "./ToggleTable";

export const DataTableFeatures = ({ ...props }) => {
  const {
    stateParent,
    titleTable,
    data,
    label,
    privilegeAccess,
    checkIndex,
    setToggle,
    handleState,
    addData,
    exportToCSV,
    needAddButton = true,
    needDownloadButton = true,
    needSearchFunction = true,
    needToggleFunction = true
  } = props;




  return (
    <div className="row justify-content-between mb-3 py-3">
      <div className="col-6 d-flex align-items-center">
        <p className="Lato-400 s-2024 c-29">{titleTable}</p>
        <p className="br-5 bd-ea bg-f4 px-3 py-2 c-29 Lato-400 s-1418 mx-2">{data.length} </p>
      </div>
      <div className="col-6 d-flex flex-end">
        <ToggleTable needToggleFunction={needToggleFunction} label={label} checkIndex={checkIndex} setToggle={setToggle} />
        <SearchTable needSearchFunction={needSearchFunction} handleState={handleState} stateParent={stateParent} />
        {needDownloadButton ? <ButtonDownload handleClick={exportToCSV} codeAccessPrivilege={privilegeAccess.download} /> : null}
        {needAddButton ? <ButtonAdd handleClick={addData} codeAccessPrivilege={privilegeAccess.add} /> : null}
      </div>
    </div>
  );
};


import Modal from 'react-modal';
import { errNotificationIconCustom } from '../Assets/Images/svg';
import { ModalStyle } from '../Utils';

export const ModalCannotDelete = ({ ...props }) => {
    const { handleState, statePage } = props;
    return (
        <Modal isOpen={statePage.showModalError} style={ModalStyle} onRequestClose={() => handleState('showModalError', false)}>
            <div className='d-flex flex-column w-500px m-4'>
                <div className='d-flex justify-content-center align-items-center c-e87' style={{ fontSize: '30px' }}>
                    <div>
                        {errNotificationIconCustom(50, 50)}
                    </div>
                    <div className='mx-1'>
                        ERROR!
                    </div>
                </div>
                <div className='h-20px'></div>
                <div className='d-flex align-items-center justify-content-center p-2 min-h-60px bd-ea br-10 c-e87 text-center'>
                    {statePage.errorMessageModal}
                </div>
                <div className='h-20px'></div>
                <div className='d-flex align-items-center justify-content-center'>
                    <button className="btn  px-4 bg-e8  s-1418 Lato-600 " onClick={() => handleState('showModalError', false)}>
                       <span className='c-ff'>Close</span> 
                    </button>
                </div>
            </div>
        </Modal>
    )
}
import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
    let dateNow = new Date().toISOString().slice(0, 10);
    let now = new Date();
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    let startTime = '00:00:00';
    let timeNow = `${hours}:${minutes}:${seconds}`;
    return {
    
        selectedServer: "",
        alertEventType: "",
        alertSeverity: "",
        svrId: "",
        alertCat: "",
       
        filterStartDate: dateNow, // search for by start date
        filterEndDate: dateNow, // // search for by end date
        search: "",

        startTime : startTime,
        endTime: timeNow,
    };
};

export const alertHistorySlice = createSlice({
    name: "alertHistory",
    initialState: initialState(),
    reducers: {
        changeStateAlertHistory: (state, action) => {
            state.selectedServer =
                action.payload.selectedServer === undefined ||
                action.payload.selectedServer === null
                    ? state.selectedServer
                    : action.payload.selectedServer;
            state.alertEventType =
                action.payload.alertEventType === undefined ||
                action.payload.alertEventType === null
                    ? state.alertEventType
                    : action.payload.alertEventType;
            state.alertSeverity =
                action.payload.alertSeverity === undefined ||
                action.payload.alertSeverity === null
                    ? state.alertSeverity
                    : action.payload.alertSeverity;
            state.filterStartDate =
                action.payload.filterStartDate === undefined ||
                action.payload.filterStartDate === null
                    ? state.filterStartDate
                    : action.payload.filterStartDate;
            state.filterEndDate =
                action.payload.filterEndDate === undefined ||
                action.payload.filterEndDate === null
                    ? state.filterEndDate
                    : action.payload.filterEndDate;
            state.search =
                action.payload.search === undefined ||
                action.payload.search === null
                    ? state.search
                    : action.payload.search;
            // 05/05/2023
            state.svrId =
                action.payload.svrId === undefined ||
                action.payload.svrId === null
                    ? state.svrId
                    : action.payload.svrId;
            state.alertCat =
                action.payload.alertCat === undefined || action.payload.alertCat === null
                    ? state.alertCat
                    : action.payload.alertCat;
            state.startTime =
                action.payload.startTime === undefined ||
                action.payload.startTime === null
                    ? state.startTime
                    : action.payload.startTime;
            state.endTime =
                action.payload.endTime === undefined ||
                action.payload.endTime === null
                    ? state.endTime
                    : action.payload.endTime;
        },
        resetAllActionPage2: (state, action) => {

            state.selectedServer = "";
            state.alertEventType = "";
            state.alertSeverity = "";
        
            state.filterStartDate = "";
            state.filterEndDate = "";
            state.search = "";
            // 05/05/2023
            state.svrId = "";
            state.alertCat = "";
          
            state.startTime = "";
            state.endTime = "";
        },
    },
});

// Action creators are generated for each case reducer function
export const { changeStateAlertHistory, resetAllActionPage2 } =
    alertHistorySlice.actions;
export const stateGlobalAlertHistory = (state: any) =>
    state.alertHistory;
export default alertHistorySlice.reducer;

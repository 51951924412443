import { useReducer, useState } from "react";
import { Card, FormLabel } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import bgLogin from "../../../Assets/Images/bg_login.png";
import logo2 from "../../../Assets/Images/logo2.png";
import { errNotificationIcon, userIcon } from "../../../Assets/Images/svg";
import { ModalCustom } from "./ModalCustom";
import { IsValidEmail } from "../../../Utils";
import { API } from "../../../Services";
import { urlSelection } from "../../../Services/API";

export const ForgotPassword = ({ ...props }): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const [getConfirmCaptcha, setConfirmCaptcha] = useState(false);

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }


    const handleCaptcha = (value: any) => {
        if (value !== null || value !== undefined) {
            setConfirmCaptcha(!getConfirmCaptcha);
        }
    }

    const submitForgotPassword = async () => {
        if (!IsValidEmail(statePage.email)) {
            dispatchStatePage({ type: 'errorMessage', value: 'Email is invalid' })
        } else {
            const data = { userEmail: statePage.email };
            await API.post({
                pathCustom: `userapp/forgotpassword`,
                bodyCustom: data,
                selectUrl: urlSelection.dashboard,
                useToken: false,
            })
                .then((res: any) => {
                    dispatchStatePage({ type: 'showModal', value: true })
                    dispatchStatePage({ type: 'messageModal', value: 'Mail sent successfully, please check your email' })
                    dispatchStatePage({ type: 'email', value: '' })
                    dispatchStatePage({ type: 'errorMessage', value: '' })
                }).catch((err: any) => {
                    dispatchStatePage({ type: 'showModal', value: true })
                    dispatchStatePage({ type: 'messageModal', value: 'Something Wrong, Please check your email is correct' })
                    dispatchStatePage({ type: 'errorMessage', value: '' })
                })
        }
    };



    const height_ = window.innerHeight;


    return (
        <>

            <div className="w-100 bg-ac" style={{ height: `${height_}px` }}>
                <div className="row d-flex justify-content-center h-100persen" style={{ backgroundImage: `url(${bgLogin})` }}>
                    <div className="col-md-4 bg-ff bd-ea hg-fit w-400px h-600px" style={{ minHeight: "550px" }}>
                        <div className="text-center my-3">
                            <Card.Img className="w-250px" src={logo2} />
                        </div>
                        <div className="text-center fs-lato-18px-normal my-2">FORGOT PASSWORD</div>
                        <div className="mb-3" >
                            <div className="form-group">
                                <FormLabel className="c-54 text-uppercase mb6 s-1214 w-100" htmlFor="email-user">
                                    EMAIL
                                </FormLabel>
                                <FormLabel className="container-custom-input" htmlFor="email-user">
                                    {userIcon}
                                    <input type="email" className="custom-input mx-2" id="email-user" placeholder="Enter email" value={statePage.email} onChange={(event) => handleState('email', event.target.value)} />
                                </FormLabel>
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-2">
                                <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={handleCaptcha} />
                            </div>
                            {
                                statePage.errorMessage !== "" &&
                                <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                    {errNotificationIcon}
                                    <p className='px-1 s-1418 Lato-600 c-e87'>{statePage.errorMessage}</p>
                                </div>
                            }
                            <div className="d-flex align-items-center justify-content-between mt-3">
                                <button className="d-flex align-items-center px-2 py-2 br-20 bd-d4" onClick={() => props.setShowForgotPassword(false)}>
                                    <IoIosArrowBack />
                                    <span className="s-1418 Lato-600 mx-2">LOGIN</span>
                                </button>
                                <button onClick={getConfirmCaptcha === true ? submitForgotPassword : () => { }} className={(getConfirmCaptcha === true ? "bg-e8" : "") + " flex align-items-center px-2 py-2 br-20 bd-d4 "}>
                                    <p className={(getConfirmCaptcha === true ? "c-ff" : "") + " s-1418  Lato-600 mx-2"}>RECOVER PASSWORD</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalCustom statePage={statePage} handleState={handleState} />

        </>
    )
}

const initialState: any = {
    email: '',
    errorMessage: '',
    showModal: false,
    messageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {

        case 'email':
            return {
                ...state,
                email: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModal':
            return {
                ...state,
                showModal: action.value,
            };
        case 'messageModal':
            return {
                ...state,
                messageModal: action.value,
            };
        default:
            throw new Error();
    }
}

import Select from 'react-select';
import { labelStyle } from '../Styles';
import { useDispatch } from 'react-redux';



const Sort = ({ ...props }) => {
    const { state, stateOptions, globalState, handleGlobalState } = props;
    let newOptionsForSorting = [...state.label.filter((item1: any) => {
        return (item1.val !== 'no' && item1.val !== 'action');
    }).map((item2: any) => {
        return {
            label: item2.field,
            value: item2.val
        };
    })]

    return (
        <div className='row mb-5 justify-content-end'>
            <div className='col-xxl-2 col-xl-3 col-lg-6 col-sm-12 col-xs-12'>
                <p className={labelStyle}>SORT BY</p>
                <Select placeholder={'Select'} options={newOptionsForSorting} value={newOptionsForSorting.filter((item : any) => item.value === globalState.sortBy)} onChange={(e) => { handleGlobalState('sortBy', e.value)}} />
            </div>
            <div className='col-xxl-2 col-xl-3 col-lg-6 col-sm-12 col-xs-12'>
                <p className={labelStyle}>SORT TYPE</p>
                <Select placeholder={'Select'} options={stateOptions.optionsSort} value={stateOptions.optionsSort.filter((item : any) => item.value === globalState.sortType)} onChange={(e) => { handleGlobalState('sortType', e.value)}} />
            </div>
        </div>
    )
}

export default Sort;
import {
    MaterialReactTable,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { ButtonAdd, ButtonDelete, ButtonEdit, SelectView } from '../../../../Components';
import { CheckUndefined } from '../../../../Utils';
import { useSelector } from 'react-redux';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from 'antd';



export const TableAlertParameterNew2 = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const severity = [
        {
            "label": "INFO",
            "value": "000"
        },
        {
            "label": "LOW",
            "value": "001"
        },
        {
            "label": "MEDIUM",
            "value": "002"
        },
        {
            "label": "HIGH",
            "value": "003"
        },
        {
            "label": "CRITICAL",
            "value": "004"
        }
    ]

    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom,
        handleStateParent,
        handleNavigation,
        actionShowModal, deleteDataComponent, addData, dispatch, pagination, setPagination
    } = props;
    const columns: any = useMemo(
        () => [
            {
                header: 'Service ID',
                accessorKey: 'svcId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    return <div>{CheckUndefined(row.original.svcId)}</div>
                },
                // `${CheckUndefined(row.original.srcMemberName)}`,
            },
            {
                header: 'Prod. Ind',
                accessorKey: 'prodInd',
                size: 'auto',
                Cell: ({ row }: any) => {
                    return <div> {CheckUndefined(row.original.prodInd)}</div>
                },
                // Cell: ({ row }: any) =>
                //     `${CheckUndefined(row.original.dstMemberName)}`,
            },
            {
                header: 'Threshold ',
                accessorKey: 'procTimeThres',
                size: 'auto',
                Cell: ({ row }: any) => { return <div>{CheckUndefined(row.original.procTimeThres)} ms</div> },
            },
            {
                header: 'Severity',
                accessorKey: 'alertSeverity',
                size: 'auto',
                Cell: ({ row }: any) => {
                    let dataReturn
                    severity.forEach((element2: any) => {
                        if (element2.value === row.original.alertSeverity) {
                            dataReturn = `${element2.label}`;
                        }
                    })
                    return dataReturn
                },
                // `${CheckUndefined(row.original.alertSeverity)}`,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                size: 'auto',
                Cell: ({ row }) => {
                    const item = row.original;
                    if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                        return props.checkIndex('action') && <div className={`d-flex c-29 s-1418 Lato-400 word-wrap`} onClick={() => { dispatch({ type: 'isProcTime', value: 'alert-proc-time' }); dispatch({ type: 'updateSvcProd', value: { svcId: item.svcId, prodInd: item.prodInd } }); }}>{props.customButtonAction(item)} </div>;
                    } else {
                        return <></>;
                    }
                },

            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableGlobalFilter: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                // onClick: () => handleNavigation(row.original),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },
        renderTopToolbarCustomActions: ({ table }) => (

            <div className="d-flex justify-content-between align-items-center pt-1">
                <div className="d-flex align-items-center">
                    <p className="Lato-400 s-1822 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
            </div>
        ),

        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleGlobalFilterButton table={table} />
                <div className="h-30px w-30px" onClick={() => dispatch({ type: 'isProcTime', value: 'alert-proc-time' })}>
                    <div className="h-100">
                        <ButtonAdd handleClick={addData} codeAccessPrivilege={props.codeAccessPrivilegeAdd} />
                    </div>
                </div>
            </>
        ),


    });
    return (
        <>
            <div className='d-flex'><p style={{ fontSize: '14px' }}>Service Processing Time Alert</p></div>
            <div id="table" className="mt-2 mb-4 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    );
};

import { PageQRAuthStatisticTitle } from "../../../State/Menu/TitleMenu";

export const initialState = {
    titlePage: PageQRAuthStatisticTitle,
    listReasonCode: [],
    data: [],
    privilegeAccess: { view: '711', add: '', edit: '', delete: '', download: '' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Product Feature', width: 'wd-200', val: 'prodFeatureName' },
        { field: 'QR Standard', width: 'wd-150', val: 'qrsName'},
        { field: 'Member', width: 'wd-150', val: 'memberName' },
        { field: 'Usage Count', width: 'wd-150', val: 'qrReqUsageCount' },
        { field: 'Request Count', width: 'wd-150', val: 'qrReqTotalCount' },
        { field: 'Last Request Time', width: 'wd-150', val: 'lastReqDate' },
        // { field: 'Action', width: 'wd-100', val: 'action' },
    ],
    toggle: ['no', 'prodFeatureName', 'qrsName', 'memberName', 'qrReqUsageCount', 'qrReqTotalCount', 'lastReqDate'],
    complete_toggle: ['no', 'prodFeatureName', 'qrsName', 'memberName', 'qrReqUsageCount', 'qrReqTotalCount', 'lastReqDate'],
    list_column_name: ['No.', 'Product Feature', 'QR Standard', 'Member', 'Usage Count', 'Request Count', 'Last Request Time'],
    deleteItem: false,
    dataModal: [],
    SearchByPlaceHolder: 'Member, QR Standard',


    id: '',
    search: '',
    view: 15,
    position: 15,
    list_view: [15, 20],
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    // 
    widthTable: 1200,
    //
    qrAuthDivided: [],
    filterStartDate: '',
    filterEndDate: '',
    //
    selectedProductFeatureId: '',
    selectedProductFeatureLabel: '',
    selectedProductFeatureSendToApi: '',
    //
    selectedQRStandardId: '',
    selectedQRStandardName: '',
    selectedQRStandardSendToApi: '',
}
import React, { useEffect, useReducer } from 'react';
import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { FooterModalUpdate } from '../../Components';
import { inputStyleReadOnly, labelStyle } from '../../Styles';
import { ResizableBox } from 'react-resizable';
import TextArea from 'antd/es/input/TextArea';
import { Page11Title, Page43Title } from '../../State/Menu/TitleMenu';
import { CheckUndefined } from '../../Utils';
import { ModelBatchScheduler } from '../Job/Models';
import ModelSchedulerExecution from './ModelSchedulerExecution';

const ModalDraggableIeSchedulerExecution = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props

    const {
        optionsInterfaceFileFormatDetailCode,
        optionsFileStringFormatDetailCode,
        optionsFileStringSeparatorDetailCode,
        optionsCommonStatusDetailCode,
    } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>

                    <div className='col-6'>
                        <p className={labelStyle}>BATCH EXECUTION TYPE</p>
                        <input value={`${CheckUndefined(statePage.data.batchExecType)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH GROUP PATH</p>
                        <input value={`${CheckUndefined(statePage.data.batchGroupPath)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH JOB DETAIL ID</p>
                        <input value={`${CheckUndefined(statePage.data.batchJobDetailId)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH LAST EXECUTION</p>
                        <input value={`${CheckUndefined(statePage.data.batchLastExec)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH MESSAGGE</p>
                        <input value={`${CheckUndefined(statePage.data.batchMessagge)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH PROGRAM ID</p>
                        <input value={`${CheckUndefined(statePage.data.batchProgId)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH STATUS</p>
                        <input value={`${CheckUndefined(statePage.data.batchStatus)}`} readOnly className={inputStyleReadOnly} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>CHANGE DTTM</p>
                        <input value={`${CheckUndefined(statePage.data.chngDttm)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>CHANGE PROGRAM ID</p>
                        <input value={`${CheckUndefined(statePage.data.chngProgId)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>CHANGE USER ID</p>
                        <input value={`${CheckUndefined(statePage.data.chngUserId)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>INPUT DTTM</p>
                        <input value={`${CheckUndefined(statePage.data.inpDttm)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>INPUT PROGRAM ID</p>
                        <input value={`${CheckUndefined(statePage.data.inpProgId)}`} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>BATCH LOG NAME</p>
                        <TextArea rows={4} value={CheckUndefined(statePage.data.batchLogName)} readOnly={true}  ></TextArea>
                    </div>
                </div>
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Detail ${Page43Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelSchedulerExecution({
        id: null,
        batchExecTypeCode: null,
        batchExecType: null,
        batchJobDetailId: null,
        batchMessagge: null,
        batchProgId: null,
        batchStatus: null,
        batchLastExec: null,
        batchGroupPath: null,
        batchRegTypeCode: null,
        batchStatusCode: null,
        chngDttm: null,
        chngProgId: null,
        batchRegType: null,
        chngUserId: null,
        inpDttm: null,
        inpProgId: null,
        batchParameterValue: null,
        inpUserId: null,
        batchLogId: null,
        batchLogName: null,
        count: null,
        batchTxDate: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelSchedulerExecution) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelSchedulerExecution({
                    id: null,
                    batchExecTypeCode: null,
                    batchExecType: null,
                    batchJobDetailId: null,
                    batchMessagge: null,
                    batchProgId: null,
                    batchStatus: null,
                    batchLastExec: null,
                    batchGroupPath: null,
                    batchRegTypeCode: null,
                    batchStatusCode: null,
                    chngDttm: null,
                    chngProgId: null,
                    batchRegType: null,
                    chngUserId: null,
                    inpDttm: null,
                    inpProgId: null,
                    batchParameterValue: null,
                    inpUserId: null,
                    batchLogId: null,
                    batchLogName: null,
                    count: null,
                    batchTxDate: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelSchedulerExecution({
                    id: action.value.id,
                    batchExecTypeCode: action.value.batchExecTypeCode,
                    batchExecType: action.value.batchExecType,
                    batchJobDetailId: action.value.batchJobDetailId,
                    batchMessagge: action.value.batchMessagge,
                    batchProgId: action.value.batchProgId,
                    batchStatus: action.value.batchStatus,
                    batchLastExec: action.value.batchLastExec,
                    batchGroupPath: action.value.batchGroupPath,
                    batchRegTypeCode: action.value.batchRegTypeCode,
                    batchStatusCode: action.value.batchStatusCode,
                    chngDttm: action.value.chngDttm,
                    chngProgId: action.value.chngProgId,
                    batchRegType: action.value.batchRegType,
                    chngUserId: action.value.chngUserId,
                    inpDttm: action.value.inpDttm,
                    inpProgId: action.value.inpProgId,
                    batchParameterValue: action.value.batchParameterValue,
                    inpUserId: action.value.inpUserId,
                    batchLogId: action.value.batchLogId,
                    batchLogName: action.value.batchLogName,
                    count: action.value.count,
                    batchTxDate: action.value.batchTxDate,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

export default ModalDraggableIeSchedulerExecution;






import { useMemo } from "react";
import { CheckUndefined, NumberWithCommas } from "../../../Utils";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MobileFilterTSMTest } from "./MobileFilterTSMTest";

export const MobileViewTSMTest = ({ ...props }) => {
    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom,
        handleNavigation,
        setPagination,
        pagination,
    } = props;
    const columns : any= useMemo(
        () => [
            {
                header: 'Transaction Datetime',
                accessorKey: 'fullCreateDate',
                size: '160',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.fullCreateDate)}`,

            },
            {
                header: 'Test Cat',
                accessorKey: 'testCat',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.testCat)}`,
            },
            {
                header: 'Test Case ID',
                accessorKey: 'testCaseId',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.testCaseId)}`,
            },
            {
                header: 'Test Valid',
                accessorKey: 'isTestValid',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.isTestValid)}`,
            },
            {
                header: 'Product Indicator',
                accessorKey: 'productFeatureName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.productFeatureName)}`,
            },
            {
                header: 'Issuer',
                accessorKey: 'issuerName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.issuerName)}`,
            },
            {
                header: 'Issuer Switcher',
                accessorKey: 'issuerSwitcherName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.issuerSwitcherName)}`,
            },
            {
                header: 'Acquirer Switcher',
                accessorKey: 'acquirerSwitcherName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.acquirerSwitcherName)}`,
            },
            {
                header: 'Acquirer',
                accessorKey: 'acquirerName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.acquirerName)}`,
            },
            {
                header: 'Invoice Number',
                accessorKey: 'authInvNo',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.authInvNo)}`,
            },
            {
                header: 'Transaction Amount',
                accessorKey: 'transAmount',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.transCurrency)} ${NumberWithCommas(CheckUndefined(row.original.transAmount))}`,
            },
            {
                header: 'Status',
                accessorKey: 'transStatus',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.transStatus)}`,
            },
            {
                header: 'Global ID',
                accessorKey: 'authGlobalId',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.authGlobalId)}`,
            },
        ],
        []
    );
    
    const table = useMaterialReactTable({
        columns,
        data: state.data,
        initialState: {
            density: 'compact',
        },
        state: { pagination, globalFilter: state.search || '' },
        enableEditing: false,
        enableRowActions: false,
        enableColumnActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginateExpandedRows: false,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100],
            showFirstButton: false,
            showLastButton: false,
            showRowsPerPage: false,
            size: 'small',
            color: 'standard',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableTopToolbar: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                onClick: () => handleNavigation(row.original),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#d8f7fd' : '#ffffff',
                    '&:hover': {
                        backgroundColor: '#0bb4d5',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '10px',
                    fontFamily: 'system-ui, sans-serif',
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '10px',
                fontWeight: 'bold',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '10px',
                fontFamily: 'system-ui, sans-serif',
                padding: '8px 16px',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },
    });
    return (
        <>  
            <h1 className="Lato-300 normal w-600 s-1418">
                Production Simulator Inquiry
            </h1>
            <div className="d-flex justify-content-between">
                <div className="w-50 d-flex align-items-center">
                    <p className="Lato-400 s-1418 c-29">List</p>
                    <p className="br-5 bd-ea bg-f8a px-1 c-29 Lato-400 s-1418 mx-1">
                        {state.totalData}
                    </p>
                </div>
                <div className="w-50 d-flex justify-content-end align-items-center">
                    <MobileFilterTSMTest
                        state={state}
                        stateOptions={stateOptions}
                        stateCustomComponent={stateCustomComponent}
                        handleStateGlobal={handleStateGlobal}
                        resetFilterStatus={resetFilterStatus}
                        initFunctionCustom={initFunctionCustom}
                    />
                </div>
            </div>
            <MaterialReactTable table={table} />
        </>
    );
}
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PieChart } from 'react-minimal-pie-chart';
import { Divider } from 'antd';

import { labelStyle } from '../../../Styles';
import { applicationTwoIcon, checklistButtonGreenIcon, close_red } from '../../../Assets/Images/svg';
import { colors } from '../../../Utils';

import { changeStateDataCopyFilterQrAuthMonitoring, stateGlobalQrAuthMonitoring } from '../../../Store/QRAuthMonitoringSlice';

export const PieChartComponent = ({ ...props }) => {
    const dispatchRedux = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);

    const globalState = useSelector(stateGlobalQrAuthMonitoring);
    const { dataFilter, dataCopyFilter, dataCopyByDate, dataYesterday, dataYesterdayByDate, dataYesterdayCopyByDate, filterStartDate, filterEndDate, selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedQRStandardId, selectedQRStandardName, selectedQRStandardSendToApi, search, optionsProductFeature, optionsProduct, listMember, listReasonCode, rangeDate } = globalState;

    const { stateOptions } = props;
    const { handleStateComponent } = props;


    useEffect(() => {
        handleStateComponent('qrAuthDivided', state.qrAuthDivided);
    }, [dataFilter, dataCopyFilter, filterStartDate, filterEndDate, selectedQRStandardSendToApi, selectedProductFeatureSendToApi, rangeDate, state.qrAuthDivided])

    // ~~~~~~~~~~~~~~~~~~~~~~~ FIRST FUN ~~~~~~~~~~~~~~~~~~~~~~~ //
    useEffect(() => {
        countAllQRAuth();
        dividingQRAuth();
        convertDataNeededPieChart('memberId', 'memberName');
    }, [dataFilter, dataCopyFilter, filterStartDate, filterEndDate, selectedQRStandardSendToApi, selectedProductFeatureSendToApi])

    // ~~~~~~~~~~~~~~~~~~~~~~~ SECOND FUN ~~~~~~~~~~~~~~~~~~~~~~~ //
    const countAllQRAuth = () => {
        if (dataCopyFilter.length > 0) {
            let counted = 0;
            dataCopyFilter.forEach((dataQRAuth: any) => {
                counted = counted + parseInt(dataQRAuth.qrReqTotalCount);
            });
            dispatch({ type: `countedAllQRAuth`, value: counted });
        } else {
            dispatch({ type: `countedAllQRAuth`, value: 0 });
        }
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~ THIRD FUN ~~~~~~~~~~~~~~~~~~~~~~~ //
    // membagi qr auth berdasarkan member
    const dividingQRAuth = () => {
        let newResultData: any[] = [];
        if (stateOptions.optionsMember.length > 0) {
            let newListQRAuthMember: any = [];
            stateOptions.optionsMember.forEach((element1: any) => {
                newListQRAuthMember.push({
                    memberName: element1.memberName
                });
            })

            let removeSameValue: any[] = Array.from(new Set(newListQRAuthMember)); // remove same id
            let newListMember = [...removeSameValue.map((itemMember: any) => {
                return {
                    "memberName": itemMember.memberName
                }
            })];

            newListMember.forEach((element1: any) => {
                let dataListQRAuthDivided: any[] = [];

                if (dataCopyFilter.length > 0) {
                    let newDataCopyFilter: any[] = []
                    dataCopyFilter.forEach((itemDataCopyFilter: any) => {
                        newDataCopyFilter.push(itemDataCopyFilter);
                    })

                    newDataCopyFilter.forEach((element2: any) => {
                        if (element1.memberName === element2.memberName) {
                            dataListQRAuthDivided.push(element2);
                        }

                    })
                }

                let resultData = { memberName: element1.memberName, dataListQRAuthDevided: dataListQRAuthDivided };
                newResultData.push(resultData);

            });
        }

        dispatch({ type: `qrAuthDivided`, value: newResultData });
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~ FORTH FUN ~~~~~~~~~~~~~~~~~~~~~~~ //
    // untuk mengisi state memberCode dan selectedMember  
    const convertDataNeededPieChart = (memberCode: string, memberName: string) => {

        let newResultData: any[] = [];
        let optionsMember: any[] = [...stateOptions.optionsMember];
        
        let dataGroupMemberCode: any[] = []
        let dataGroupMemberCodeAndMemberName: any[] = []

        if (optionsMember.length > 0) {
            optionsMember.forEach((element1: any, index: number) => {
                let dataListQRAuth: any[] = [];
                if (dataCopyFilter.length > 0) {
                    dataCopyFilter.forEach((element2: any) => {
                        if ((element2[memberCode] === element1.memberCode) && (element2[memberName] === element1.memberName)) {
                            dataListQRAuth.push(element2);
                            if (!dataGroupMemberCode.includes(element2[memberName])) {
                                dataGroupMemberCode.push(element2[memberName]);

                                dataGroupMemberCodeAndMemberName.push({
                                    memberName: element2[memberName],
                                    memberCode: element2[memberCode],
                                    memberColor: colors[index]
                                })
                            }
                        }
                    });
                }

                let resultData = { memberCode: element1.memberCode, memberName: element1.memberName, memberColor: colors[index], dataListQRAuth: dataListQRAuth };
                newResultData.push(resultData);
            });
        };
        dispatch({ type: `memberCode`, value: newResultData });
        dispatch({ type: `selectedMember`, value: dataGroupMemberCodeAndMemberName })
    }

    const onClickPieChart = (titleData: any, idLabel: any) => {
        let resultDataFilter: any[] = [];
        let resultDataFilterByDate: any[] = [];
        let resultDataFilterYesterday: any[] = [];
        let resultDataFilterYesterdayByDate: any[] = [];
        
        var result = titleData.substring(1, titleData.length - 1); // remove ()
        result = result === '' ? null : result;

        dataCopyFilter.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilter.push(element1);
            }
        });
        dataCopyByDate.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilterByDate.push(element1);
            }
        });

        dataYesterday.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilterYesterday.push(element1);
            }
        });
        dataYesterdayCopyByDate.forEach((element1: any) => {
            if (element1[idLabel] === result) {
                resultDataFilterYesterdayByDate.push(element1);
            }
        });

        dispatchRedux(changeStateDataCopyFilterQrAuthMonitoring({ 
            dataCopyFilter: resultDataFilter,
            dataCopyByDate: resultDataFilterByDate,
            dataYesterday: resultDataFilterYesterday,
            dataYesterdayCopyByDate: resultDataFilterYesterdayByDate
        }));
    }

    const PieChartMemberCode = () => {
        if (state.memberCode.length > 0) {
            let conditionShowChart = false;
            state.memberCode.forEach((element1: any) => {
                if (element1.dataListQRAuth.length > 0) {
                    conditionShowChart = true; // if data exist, show Pie Chart normal
                }
            })

            if (conditionShowChart) {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>QR Auth by Member</div>
                        <PieChart
                            onClick={(e: any, segmentIndex: any) => onClickPieChart(e.target.textContent.split(' | ')[1], 'memberName')}
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={
                                [
                                ...state.memberCode.map((element1: any, index: any) => {                                  
                                    let resultCountedQRRequestByMember = 0;
                                    if (element1.dataListQRAuth.length > 0) {
                                        element1.dataListQRAuth.map((dataListQR: any) => {
                                            resultCountedQRRequestByMember = resultCountedQRRequestByMember + parseInt(dataListQR.qrReqTotalCount);
                                        })
                                    }
                                    return {
                                        title: `(${((resultCountedQRRequestByMember / state.countedAllQRAuth) * 100).toFixed(1)}%) | (${element1.memberName === null || undefined ? '' : element1.memberName}) | (${resultCountedQRRequestByMember})`,
                                        key: `${index}PieChartMemberCode`,
                                        value: resultCountedQRRequestByMember,
                                        color: element1.memberColor,
                                    };
                                }),
                            ]}
                        />
                    </div>
                )
            } else {
                return (
                    <div>
                        <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>QR Auth by Member</div>
                        <PieChart
                            style={{ maxHeight: '200px', width: '200px' }}
                            startAngle={270}
                            data={[{ title: '0', value: 100, color: '#666666' },]}
                        />
                    </div>
                )
            }

        } else {
            return (
                <div>
                    <div className='text-secondary Lato-400 text-secondary text-center mx-2 my-2'>QR Auth by Member</div>
                    <PieChart
                        style={{ maxHeight: '200px', width: '200px' }}
                        startAngle={270}
                        data={[{ title: '0', value: 100, color: '#666666' },]}
                    />
                </div>
            )
        }

    }

    // ~~~~~~~~~~~~~~~~~~~~~~~ FIFTH FUN (on pie click) ~~~~~~~~~~~~~~~~~~~~~~~ //
    const LegendPieChart = () => {
        let LegendMember: any[] = []

        if (stateOptions.optionsMember.length > 0) {
            stateOptions.optionsMember.forEach((element1: any, index: any) => {

                if (state.selectedMember.length > 0) {
                    state.selectedMember.forEach((itemMember: any) => {
                        if ((itemMember.memberName === element1.memberName) && (itemMember.memberCode === element1.memberCode)) {
                            if (state.memberCode.length > 0) {
                                LegendMember.push(
                                    (
                                        <div className='mx-2 my-2 d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10'>
                                            <div
                                                key={itemMember.memberName}
                                                style={{
                                                    backgroundColor: itemMember.memberColor,
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '7px',
                                                    display: 'inline-block',
                                                }}
                                                className='mx-2'
                                            ></div>
                                            <span className='Lato-400'>{itemMember.memberName} {itemMember.memberCode}</span>
                                        </div>
                                    )
                                )
                            }
                        } else {
                            return <></>
                        }
                    })
                }
            })
        }

        return (
            <>
                <Divider />
                <div className='d-flex flex-wrap justify-content-center align-items-center  pt-3'>
                    {
                        LegendMember.length > 0 ?
                            <>
                                {LegendMember.map((element1: any) => element1)}
                            </>
                            :
                            <></>
                    }
                </div>
            </>
        )
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~ SIXTH FUN ~~~~~~~~~~~~~~~~~~~~~~~ //
    const QRAuthByAvailableMember = () => {
        if (state.qrAuthDivided.length > 0) {
            let dataQRAuthByAvailableMemberRequest: any[] = [];
            state.qrAuthDivided.forEach((dataQRDivided: any) => {
                if (dataQRDivided.dataListQRAuthDevided.length > 0) {
                    dataQRAuthByAvailableMemberRequest.push(dataQRDivided);
                }
            })

            let countedQRAuthUsage: number = 0;
            if (dataQRAuthByAvailableMemberRequest.length > 0) {
                dataQRAuthByAvailableMemberRequest.forEach((dataQREachMember: any) => {
                    dataQREachMember.dataListQRAuthDevided.forEach((dataQRAuth: any) => {
                        countedQRAuthUsage = countedQRAuthUsage + parseInt(dataQRAuth.qrReqUsageCount);
                    })
                })
            }

            return (
                <div className='row p-0 m-0 bd-ea-bottom'>
                    <div className='col-6 d-flex justify-content-center align-items-center  p-0' style={{ minHeight: '72px', overflow: 'hidden' }}>
                        <div className='mr-4 bd-ea px-2 py-2 br-20 d-flex justify-content-center align-items-center' style={{ height: '40px', width: '40px' }}>
                            {applicationTwoIcon}
                        </div>
                        <div className='w-10px'></div>
                        <div>
                            <div className='fs-lato-14px-normal text-secondary mb-1'>Total QR Auth</div>
                            <div className='s-2024 Lato-700'>{state.countedAllQRAuth}</div>
                        </div>
                    </div>

                    <div className='col-6 d-flex justify-content-center align-items-center bd-ea-left-right p-0' style={{ minHeight: '72px' }}>
                        <div className='mr-4 bd-ea px-2 py-2 br-20 d-flex justify-content-center align-items-center' style={{ height: '40px', width: '40px' }}>
                            {checklistButtonGreenIcon}
                        </div>
                        <div className='w-10px'></div>
                        <div>
                            <div className='fs-lato-14px-normal text-secondary mb-1'>Usage Rate</div>
                            <div className='s-2024 Lato-700'>{isNaN(parseFloat(((countedQRAuthUsage / state.countedAllQRAuth) * 100).toFixed(2))) ? 0 : ((countedQRAuthUsage / state.countedAllQRAuth) * 100).toFixed(2)}%</div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }
    return (
        <>
            <div className='my-5'>
                <div className='width-100 '>
                    <div className='bg-ff bd-ea br-10 py-2 px-2'>
                        {QRAuthByAvailableMember()}
                        <div className='d-flex justify-content-evenly my-3' >
                            {PieChartMemberCode()}
                        </div>
                        <LegendPieChart />
                    </div>
                </div>
            </div>
        </>
    )
}

const initialState = {
    dataMain: [ //unused
        { id: 'memberName', label: 'MemberName', data: [] },
        { id: 'memberCode', label: 'MemberCode', data: [] },
    ],
    data: '',   //unused

    countedAllQRAuth: 0,
    memberCode: [],
    qrAuthDivided: [],
    selectedMember: [],
};


function reducer(state: any, action: any) {
    switch (action.type) {
        case 'selectedMember':
            return {
                ...state,
                selectedMember: action.value
            };
        case 'countedAllQRAuth':
            return {
                ...state,
                countedAllQRAuth: action.value
            };
        case 'memberCode':
            return {
                ...state,
                memberCode: action.value
            };
        case 'qrAuthDivided':
            return {
                ...state,
                qrAuthDivided: action.value
            };

        default:
            throw new Error();
    }
}
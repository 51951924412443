import { icon_search, icon_search_white } from "../Assets/Images/svg";

export const ButtonSearchBlue = ({ ...props }) => {
    const { disabled = false, initFunctionCustom } = props;
    return (
        <div className='d-flex w-100 flex-end my-2'>
            <button className={'btn d-flex bg-d5 h-30px mx-1 mr-100px'} onClick={ () => initFunctionCustom(true)} disabled={disabled}>
                {icon_search_white}
                <p className='s-1418 c-ff Lato-600 mx-2'>Search</p>
            </button>
        </div>
    )
}

export const ButtonSearchBlue1 = ({ ...props }) => {
    const { initFunctionCustom, disabled = false } = props;
    return (
        <button className={'btn d-flex bg-d5 h-30px mx-1 mr-100px'} onClick={() => initFunctionCustom(true)} disabled={disabled}>
            {icon_search}
            <p className='s-1418 c-ff Lato-600 mx-2'>Search</p>
        </button>
    )
}
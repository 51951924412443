import { useDispatch, useSelector } from 'react-redux';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { changeStateMainDashboard, stateGlobalMainDashboard, handleStateAction } from '../../../Store/MainDashboardSlice';

export const MainPageSummaryReactTable = ({ ...props }) => {

    const stateGlobal = useSelector(stateGlobalMainDashboard);

    const {
        data,
        columns,
        state,
        pagination,
        setPagination,
        initFunctionCustom,
        handleStateComponent,
        handleStateGlobal,
        handleRowClick,
        reloadDataComponent,
        dispatch
    } = props

    const table = useMaterialReactTable({
        // 1. Column Definitions
        columns,

        // 2. Data & State
        data,
        initialState: {
            density: 'compact',
        },

        // 3. Row & Column Utils
        enableRowActions: false,
        enableColumnActions: false,
        
        // 4. Toolbar Utils
        enableBottomToolbar: false,
        enableHiding: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enablePagination: false,

        muiTableBodyRowProps: ({ row, table }) => {

            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);

            return {
                onClick: () => ({}),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white', // Change text color to white on hover
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px', // Set font size for table cells
                fontFamily: 'system-ui, sans-serif', // Set font family for table cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                zIndex: 0,
            },
        },

        muiTableFooterCellProps: {
            sx: {
                fontSize: '12px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
                color: '#292929'
            },
        },

        muiSearchTextFieldProps: {
            placeholder: 'Search all column',
            size: 'small',
            sx: {
                fontSize: '12px',
                fontFamily: '"Lato-400", system-ui, sans-serif'
            }
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            }
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
                paddingX: '0px'
            },
        },
    })

    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
}
import { errNotificationIcon } from "../Assets/Images/svg";

export const ErrorMessage = ({ ...props }) => {
    const { errorMessage } = props;
    if (errorMessage !== '') {
        return (
            <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                {errNotificationIcon}
                <p className='px-2 s-1418 Lato-600 c-e87'>{errorMessage}</p>
            </div>
        )
    }
    return null;
}
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckUndefined, CheckUndefinedStringEmpty } from '../../Utils';
import {
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    stateOptions,
    stateSelected,
} from '../../State';
import {
    changeStateTransactionMonitoringStatus,
    resetAllActionPage2,
    stateGlobalTransactionMonitoringStatus,
} from '../../Store/TransactionMonitoringStatusSlice';
import { API } from '../../Services';
import { InterfaceOptionsTransactionMonitoringStatus } from './Utils/InterfaceOptionsTransactionMonitoringStatus';
import { urlSelection } from '../../Services/API';
import { ShowModalInterface } from '../../Interface';
import { ModalUnmatchedList } from '../UnmatchedList/ModalUnmatchedList';
import { MobileViewTSM } from './Components/MobileViewTSM';
import { DesktopViewTSM } from './Components/DesktopViewTSM';
import { IsDateRangeWithinDays } from '../../Utils/IsDateRangeWithinDays';

export const TransactionMonitoringStatus = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const location = useLocation();
    const globalState = useSelector(stateGlobalTransactionMonitoringStatus);
    const privilegeAccess = useSelector(
        (state: any) => state.user.privilegeAccess
    );
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(
        reducerOptions,
        initialStateOptions
    );
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(
        reducerStateSelected,
        initialStateCustomComponent
    );
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });
    const searchParams = new URLSearchParams(location.search);
    const transIDValue = searchParams.get('transId');

    useEffect(() => {
        if (transIDValue !== undefined) {
            if (transIDValue !== null) {
                dispatchGlobal(
                    resetAllActionPage2(CheckUndefined(transIDValue))
                );
            }
        }
    }, [transIDValue]);

    const {
        transId,
        transStatusName,
        statusSendToApi,
        issuerId,
        issuerSwitcherId,
        acquirerSwitcherId,
        acquirerId,
        selectedProductId,
        selectedProductLabel,
        selectedProductSendToApi,
        selectedProductFeatureSendToApi,
        filterStartDate,
        startTime,
        filterEndDate,
        endTime,
        search,
        transCurrencyCode,
        acqSwitchCurrencyCode,
        issSwitchCurrencyCode,
        approvalCode,
        STAN,
        RRN,
        InvoiceNumber,
        GlobalID,
        MID,
        TID,
        transactionAmount,
        issuerIdCode,
        acquirerIdCode,
        issuerSwitcherIdCode,
        acquirerSwitcherIdCode,
        walletUserPan,
        postStatus,
        navigateFrom,
        walletId
    } = globalState;

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let apiUrl = 'dashboard/transaction-list?';
        if (CheckUndefinedStringEmpty(selectedProductSendToApi) !== '') {
            apiUrl =
                apiUrl +
                `prodType=${CheckUndefinedStringEmpty(
                    selectedProductSendToApi
                )}&`;
        }
        if (CheckUndefinedStringEmpty(selectedProductFeatureSendToApi) !== '') {
            apiUrl =
                apiUrl +
                `transType=${CheckUndefinedStringEmpty(
                    selectedProductFeatureSendToApi
                )}&`;
        }
        if (CheckUndefinedStringEmpty(search) !== '') {
            apiUrl = apiUrl + `search=${CheckUndefinedStringEmpty(search)}&`;
        }
        // statusName 'APPROVED' status = '0000'

        if (CheckUndefinedStringEmpty(statusSendToApi) !== '') {
            apiUrl =
                apiUrl +
                `status=${CheckUndefinedStringEmpty(statusSendToApi)}&`;
        }
        if (CheckUndefinedStringEmpty(transStatusName) !== '') {
            apiUrl =
                apiUrl +
                `statusName=${CheckUndefinedStringEmpty(transStatusName)}&`;
        }
        if (CheckUndefinedStringEmpty(transCurrencyCode) !== '') {
            apiUrl =
                apiUrl +
                `currencyCode=${CheckUndefinedStringEmpty(transCurrencyCode)}&`;
        }
        if (CheckUndefinedStringEmpty(acqSwitchCurrencyCode) !== '') {
            apiUrl =
                apiUrl +
                `acqSwitchCurrencyCode=${CheckUndefinedStringEmpty(
                    acqSwitchCurrencyCode
                )}&`;
        }
        if (CheckUndefinedStringEmpty(issSwitchCurrencyCode) !== '') {
            apiUrl =
                apiUrl +
                `issSwitchCurrencyCode=${CheckUndefinedStringEmpty(
                    issSwitchCurrencyCode
                )}&`;
        }
        if (CheckUndefinedStringEmpty(issuerIdCode) !== '') {
            apiUrl =
                apiUrl + `issuerId=${CheckUndefinedStringEmpty(issuerIdCode)}&`;
        }
        if (CheckUndefinedStringEmpty(issuerSwitcherIdCode) !== '') {
            apiUrl =
                apiUrl +
                `issuerSwitcherId=${CheckUndefinedStringEmpty(
                    issuerSwitcherIdCode
                )}&`;
        }
        if (CheckUndefinedStringEmpty(acquirerSwitcherIdCode) !== '') {
            apiUrl =
                apiUrl +
                `acquirerSwitcherId=${CheckUndefinedStringEmpty(
                    acquirerSwitcherIdCode
                )}&`;
        }
        //
        if (CheckUndefinedStringEmpty(acquirerIdCode) !== '') {
            apiUrl =
                apiUrl +
                `acquirerId=${CheckUndefinedStringEmpty(acquirerIdCode)}&`;
        }
        if (CheckUndefinedStringEmpty(transId) !== '') {
            apiUrl = apiUrl + `transId=${CheckUndefinedStringEmpty(transId)}&`;
        }
        if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
            apiUrl =
                apiUrl +
                `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
            apiUrl =
                apiUrl + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(startTime) !== '') {
            apiUrl =
                apiUrl +
                `startTime=${CheckUndefinedStringEmpty(startTime)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(endTime) !== '') {
            apiUrl =
                apiUrl + `endTime=${CheckUndefinedStringEmpty(endTime)}&`;
        } else {
            apiUrl = apiUrl + ``;
        }
        if (CheckUndefinedStringEmpty(approvalCode) !== '') {
            apiUrl =
                apiUrl +
                `approvalCode=${CheckUndefinedStringEmpty(approvalCode)}&`;
        }
        if (CheckUndefinedStringEmpty(STAN) !== '') {
            apiUrl = apiUrl + `stan=${CheckUndefinedStringEmpty(STAN)}&`;
        }
        if (CheckUndefinedStringEmpty(RRN) !== '') {
            apiUrl = apiUrl + `rrn=${CheckUndefinedStringEmpty(RRN)}&`;
        }
        if (CheckUndefinedStringEmpty(InvoiceNumber) !== '') {
            apiUrl =
                apiUrl +
                `invoiceNumber=${CheckUndefinedStringEmpty(InvoiceNumber)}&`;
        }
        if (CheckUndefinedStringEmpty(GlobalID) !== '') {
            apiUrl =
                apiUrl + `globalId=${CheckUndefinedStringEmpty(GlobalID)}&`;
        }
        if (CheckUndefinedStringEmpty(MID) !== '') {
            apiUrl = apiUrl + `mid=${CheckUndefinedStringEmpty(MID)}&`;
        }
        if (CheckUndefinedStringEmpty(TID) !== '') {
            apiUrl = apiUrl + `tid=${CheckUndefinedStringEmpty(TID)}&`;
        }
        if (CheckUndefinedStringEmpty(transactionAmount) !== '') {
            apiUrl =
                apiUrl +
                `transactionAmount=${CheckUndefinedStringEmpty(
                    transactionAmount
                )}&`;
        }
        if (CheckUndefinedStringEmpty(walletUserPan) !== '') {
            apiUrl =
                apiUrl +
                `walletUserPan=${CheckUndefinedStringEmpty(walletUserPan)}&`;
        }
        if (CheckUndefinedStringEmpty(postStatus) !== '') {
            apiUrl =
                apiUrl + `postStatus=${CheckUndefinedStringEmpty(postStatus)}&`;
        }
        if (CheckUndefinedStringEmpty(walletId) !== '') {
            apiUrl =
                apiUrl + `walletId=${CheckUndefinedStringEmpty(walletId)}&`;
        }
        
        apiUrl = apiUrl + `limit=${state.view}&`;
        apiUrl =
            apiUrl + `offset=${pagination.pageIndex * state.view}&`;

        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                setPagination({pageSize: state.view, pageIndex: 0})
                dispatch({ type: 'searchData', value: response?.data });
                dispatch({
                    type: 'totalPages',
                    value: response?.detail.totalPage,
                });
                dispatch({ type: 'totalData', value: response?.detail.total });
            });
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response?.data });
                dispatch({
                    type: 'totalPages',
                    value: response?.detail.totalPage,
                });
                dispatch({ type: 'totalData', value: response?.detail.total });
            });
        }
    };
    const handleStateGlobal = (nameState: any, valueState: any) => {
        dispatchGlobal(
            changeStateTransactionMonitoringStatus({ [nameState]: valueState })
        );
    };

    useEffect(() => {
        return () => {
            if(navigateFrom !== '') {
                resetAllFilter();
                handleStateGlobal('navigateFrom', '');
            }
        };
    }, []);

    useEffect(() => {
        if (window.opener && window.opener.data) {
            handleStateGlobal('GlobalID', window.opener.data.globalId);
            handleStateGlobal('filterStartDate', window.opener.data.startDate);
            handleStateGlobal('filterEndDate', window.opener.data.endDate);
            handleStateGlobal('startTime', window.opener.data.startTime);
            handleStateGlobal('endTime', window.opener.data.endTime);
            handleStateGlobal('walletUserPan', window.opener.data.walletUserPan)
            handleStateGlobal('navigateFrom', window.opener.data.navigateFrom)
            window.opener.data = undefined;
        }
    }, []);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (
                privilegeAccess.includes(state.privilegeAccess.view) === false
            ) {
                navigate('/warning', { replace: true });
            }
        }
    }, [privilegeAccess]);

    useEffect(() => {
        initFunction(
            dispatchOptions,
            state,
            () => {},
            null,
            InterfaceOptionsTransactionMonitoringStatus,
            dispatchGlobal
        );
    }, [search]);

    useEffect(() => {
        initFunctionCustom(true);
    }, [search]);

    useEffect(() => {
        if (state.view !== pagination.pageSize) {
            initFunctionCustom(true);
            setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
        } else {
            initFunctionCustom(false);
        }
    }, [pagination.pageIndex, state.view]);


    useEffect(() => {
        if (filterStartDate !== '' && filterEndDate !== '') {
            let dateStart : any = new Date(filterStartDate);
            let dateEnd :any = new Date(filterEndDate);
    
            if (dateStart > dateEnd) {
                dispatch({
                    type: 'errorMessage',
                    value: 'Start date cannot be larger than End Date',
                });
                return;
            }
    
            if (IsDateRangeWithinDays(dateStart, dateEnd, 7)) {
                dispatch({
                    type: 'errorMessage',
                    value: 'Maximum range for start~end date is 7 days',
                });
            } else {
                let listAllDayChart = [];
                for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                    listAllDayChart.push(d.toISOString().slice(0, 10));
                }
    
                if (state.errorMessage !== '') {
                    dispatch({ type: 'errorMessage', value: '' });
                }
            }
        }
    }, [filterStartDate, filterEndDate]);
    

    const handleStateComponent = (nameAction: any, valueAction: any) =>
        handleState(nameAction, valueAction, dispatch);
    const setModalComponent = (nameAction: any) =>
        setModal(nameAction, dispatch, state);
    const reloadDataComponent =  (needRefreshPage: any) => {
        initFunction(
            dispatchOptions,
            state,
            () => initFunctionCustom(needRefreshPage),
            null,
            InterfaceOptionsTransactionMonitoringStatus,
            dispatchGlobal
        );
    }

    const resetFilterStatus = () => {
        handleStateGlobal('transStatusName', '');
        handleStateGlobal('transCurrencyCode', '');
        handleStateGlobal('acqSwitchCurrencyCode', '');
        handleStateGlobal('issSwitchCurrencyCode', '');
    };

    const resetAllFilter = () => {
        handleStateGlobal('transId', '');
        handleStateGlobal('approvalCode', '');
        handleStateGlobal('STAN', '');
        handleStateGlobal('RRN', '');
        handleStateGlobal('InvoiceNumber', '');
        handleStateGlobal('GlobalID', '');
        handleStateGlobal('MID', '');
        handleStateGlobal('TID', '');
        handleStateGlobal('transactionAmount', '');
        handleStateGlobal('transAuthIntResponseCode', '');
        handleStateGlobal('statusSendToApi', '');
        handleStateGlobal('transStatusName', '');
        handleStateGlobal('selectedProductId', '');
        handleStateGlobal('selectedProductSendToApi', '');
        handleStateGlobal('issuerIdCode', '');
        handleStateGlobal('issuerSwitcherIdCode', '');
        handleStateGlobal('acquirerSwitcherIdCode', '');
        handleStateGlobal('acquirerIdCode', '');
        handleStateGlobal('selectedProductFeatureId', '');
        handleStateGlobal('selectedProductFeatureSendToApi', '');
        handleStateGlobal('walletId', '');
        
        let dateNow = new Date().toISOString().slice(0, 10);
        handleStateGlobal('filterStartDate', dateNow);
        handleStateGlobal('filterEndDate', dateNow);

        let now = new Date();
        let hours = now.getHours().toString().padStart(2, '0');
        let minutes = now.getMinutes().toString().padStart(2, '0');
        let seconds = now.getSeconds().toString().padStart(2, '0');
        let startTime = '00:00:00';
        let timeNow = `${hours}:${minutes}:${seconds}`;
        handleStateGlobal('startTime', startTime);
        handleStateGlobal('endTime', timeNow);
        
        handleStateGlobal('walletUserPan', '');
        handleStateGlobal('postStatus', '');
        resetFilterStatus();
    };

    const handleNavigation = (item: any) => {
        dispatch({
            type: 'transId',
            value: {
                transId: item.transAuthId,
            },
        });
        dispatch({ type: 'modalCondition', value: !state.modalCondition });
    };

    const modalAddDeleteComponentPage = () => {
        return (
            <ModalUnmatchedList
                modalCondition={state.modalCondition}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                handleStateGlobal={handleStateGlobal}
                dispatchGlobal={dispatchGlobal}
                changeStateTransactionMonitoringStatus={changeStateTransactionMonitoringStatus}
                //
                stateParent={state}
                dispatchParent={dispatch}
                
            />
        );
    };
    return (
        <>
            <div id="dekstop-view" className="d-none d-sm-block">
                <DesktopViewTSM
                    state={state}
                    stateOptions={stateOptions}
                    stateCustomComponent={stateCustomComponent}
                    handleStateParent={handleStateComponent}
                    handleStateGlobal={handleStateGlobal}
                    resetFilterStatus={resetFilterStatus}
                    initFunctionCustom={initFunctionCustom}
                    handleNavigation={handleNavigation}
                    modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                    pagination={pagination}
                    setPagination={setPagination}
                />
            </div>
            <div id="mobile-view" className="mt-2 d-block d-sm-none">
                <MobileViewTSM
                    state={state}
                    stateOptions={stateOptions}
                    stateCustomComponent={stateCustomComponent}
                    handleStateParent={handleStateComponent}
                    handleStateGlobal={handleStateGlobal}
                    resetFilterStatus={resetFilterStatus}
                    initFunctionCustom={initFunctionCustom}
                    handleNavigation={handleNavigation}
                    modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                    pagination={pagination}
                    setPagination={setPagination}
                />
            </div>
            {modalAddDeleteComponentPage()}
        </>
    );
};

const initialStateOptions = { ...stateOptions };
const initialStateCustomComponent = { ...stateSelected };

const initialState = {
    data: [],
    privilegeAccess: {
        view: '481',
        add: '',
        edit: '',
        delete: '',
        download: '',
    },
    deleteItem: false,
    dataModal: null,
    SearchByPlaceHolder:
        'Issuer, Issuer Switcher, Acquirer Switcher, Acquirer, Currency Code, Transaction ID',

    id: '',
    search: '',
    view: 15,
    position: 15,
    list_view: [10, 15, 20, 30, 40, 50, 100],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    errorMessage: '',

    //
    widthTable: 1700,
    dataLength: 0, // zIndex
    //
    acquirerSwitcherId: '',
    issuerSwitcherId: '',
    acquirerId: '',
    issuerId: '',
    //
    conditionHitFirst: false,
    transId: '',
};

const reducer = (state: any, action: any) => {
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value,
            };
        case 'label':
            return {
                ...state,
                label: action.value,
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value,
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value,
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value,
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value,
            };
        case 'view':
            return {
                ...state,
                view: action.value,
            };
        case 'position':
            return {
                ...state,
                position: action.value,
            };
        case 'search':
            return {
                ...state,
                search: action.value,
            };
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value,
            };
        case 'dataRouting':
            return {
                ...state,
                dataRouting: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };

        case 'insertPage':
            return {
                ...state,
                insertPage: action.value,
            };
        case 'addData':
            return {
                ...state,
            };
        case 'editData':
            return {
                ...state,
            };
        case 'deleteData':
            return {
                ...state,
            };
        case 'searchData':
            return {
                ...state,
                data: action.value,
                position: 15,
            };

        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        // new
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        case 'totalData':
            return {
                ...state,
                totalData: action.value,
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value,
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value,
            };
        //
        case 'acquirerSwitcherId':
            return {
                ...state,
                acquirerSwitcherId: action.value,
            };
        case 'issuerSwitcherId':
            return {
                ...state,
                issuerSwitcherId: action.value,
            };
        case 'acquirerId':
            return {
                ...state,
                acquirerId: action.value,
            };
        case 'issuerId':
            return {
                ...state,
                issuerId: action.value,
            };
        case 'conditionHitFirst':
            return {
                ...state,
                conditionHitFirst: action.value,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach(
                            (element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    conditionShowModalFind = true;
                                }
                            }
                        );
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({
                                idModal: action.value.idModal,
                                showModal: !action.value.showModal,
                                itemModal: action.value.itemModal,
                                index: state.dataLength + 99,
                            });
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach(
                                (element1: ShowModalInterface) => {
                                    if (
                                        element1.idModal ===
                                        action.value.idModal
                                    ) {
                                        if (element1.showModal === false) {
                                            // will to show
                                            newSavedShowModal.push({
                                                idModal: action.value.idModal,
                                                showModal: !element1.showModal,
                                                itemModal:
                                                    action.value.itemModal,
                                                index: state.dataLength + 99,
                                            });
                                        } else {
                                            newSavedShowModal.push({
                                                idModal: action.value.idModal,
                                                showModal: !element1.showModal,
                                                itemModal:
                                                    action.value.itemModal,
                                                index: 99,
                                            });
                                        }
                                    } else {
                                        newSavedShowModal.push({
                                            idModal: element1.idModal,
                                            showModal: element1.showModal,
                                            itemModal: element1.itemModal,
                                            index: 99,
                                        });
                                    }
                                }
                            );
                        }
                    } else {
                        newSavedShowModal.push({
                            idModal: action.value.idModal,
                            showModal: !action.value.showModal,
                            itemModal: action.value.itemModal,
                            index: 99,
                        });
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach(
                            (element1: ShowModalInterface) => {
                                if (element1.idModal === action.value) {
                                    newSavedShowModal.push({
                                        idModal: element1.idModal,
                                        showModal: element1.showModal,
                                        itemModal: element1.itemModal,
                                        index: state.dataLength + 99,
                                    });
                                } else {
                                    newSavedShowModal.push({
                                        idModal: element1.idModal,
                                        showModal: element1.showModal,
                                        itemModal: element1.itemModal,
                                        index: 99,
                                    });
                                }
                            }
                        );
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'transId':
            return {
                ...state,
                transId: action.value.transId,
            };
        case 'modalCondition':
            return {
                ...state,
                modalCondition: action.value,
            };
        default:
            throw new Error();
    }
};

import { API, urlSelection } from "../../Services/API";
import { InsideComponentPage, TableWithLimitOffset } from "../../Components";
import { addData, checkIndex, deleteData, handleState, initFunction, reducerOptions, reducerStateSelected, setModal, setToggle, stateOptions, stateSelected } from "../../State";
import { inputStyle, inputStyleH25, labelStyle } from "../../Styles";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CheckUndefined } from "../../Utils";
import { InterfaceOptionsSettlementSummary } from "./InterfaceOptionsSettlementSummary";
import { ModelSettlementSummary } from "./Models/ModelSettlementSummary";
import Select from "react-select";
import { ShowModalInterface } from "../../Interface";
import TableComponent from "../../Components/TableComponent";
import { ExcelSettlementSummary } from "./ExcelSettlementSummary";
import { icon_search_white } from "../../Assets/Images/svg";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "../../Components/ErrorMessage";
import { TableSettlementSummary } from "./TableSettlementSummary";
import { ModalSettlementSummaryAcquirer } from "../SettlementSummaryAcquirer/ModalSettlementSummaryAcquirer";
import { ModalSettlementSummary } from "./ModalSettlementSummary";

export const SettlementSummary = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  const userLogin = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);

  const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: state.view,
  });

  useEffect(() => {
    if (privilegeAccess !== null) {
      if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
        navigate("/warning", { replace: true });
      }
    }
  }, [privilegeAccess]);

  const initFunctionCustom = (needRefreshPage: Boolean | null) => {

    let acqSwitchSettleStartDate = stateCustomComponent.acqSwitchSettleStartDate != "" ? `acqSwitchSettleStartDate=${stateCustomComponent.acqSwitchSettleStartDate}&` : "";
    let acqSwitchSettleEndDate = stateCustomComponent.acqSwitchSettleEndDate != "" ? `acqSwitchSettleEndDate=${stateCustomComponent.acqSwitchSettleEndDate}&` : "";
    let issSwitchSettleStartDate = stateCustomComponent.issSwitchSettleStartDate != "" ? `issSwitchSettleStartDate=${stateCustomComponent.issSwitchSettleStartDate}&` : "";
    let issSwitchSettleEndDate = stateCustomComponent.issSwitchSettleEndDate != "" ? `issSwitchSettleEndDate=${stateCustomComponent.issSwitchSettleEndDate}&` : "";

    // issuer
    let selectedIssuerSwitcherInstitutionIDDetailCode = stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode !== "" ? `issSwitchId=${stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode}&` : "";
    // acquirer
    let selectedAcquirerSwitcherInstitutionIDDetailCode = stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode !== "" ? `acqSwitchId=${stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode}&` : "";
    // memberid
    let selectedMember = stateCustomComponent.selectedMember;
    const selectedMemberSplit = selectedMember.split(" - ");
    // etc
    let search = state.search !== "" ? `search=${state.search}&` : "";
    let limit = `limit=${state.view}&`;
    let offset = `offset=${pagination.pageIndex * state.view}&`;

    let finalParameter3 = `${search}${limit}${offset}${selectedAcquirerSwitcherInstitutionIDDetailCode}${selectedIssuerSwitcherInstitutionIDDetailCode}${acqSwitchSettleStartDate}${acqSwitchSettleEndDate}${issSwitchSettleStartDate}${issSwitchSettleEndDate}`;
    handleStateComponent("finalAPI", `${state.urlApi}?${finalParameter3.slice(0, -1)}`);
    if (needRefreshPage === true) {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter3.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: "searchData", value: response.data });
        dispatch({ type: "totalPages", value: response.detail.totalPage });
        dispatch({ type: "totalData", value: response.detail.total });
        setPagination({ pageSize: state.view, pageIndex: 0 })

        // dispatch({ type: "transCounts", value: response.detail.transCount });
        // dispatch({ type: "transAmounts", value: response.detail.transAmount });
        // dispatch({ type: "reconCounts", value: response.detail.reconCount });
        // dispatch({ type: "reconAmounts", value: response.detail.reconAmount });
      });
      //
      API.get({
        bodyCustom: null,
        pathCustom: `settlement/settlement-summary-v3-total?${finalParameter3.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: "data2", value: response.data });
        setPagination({ pageSize: state.view, pageIndex: 0 })
      });
      // excel data
      // API.get({
      //   bodyCustom: null,
      //   pathCustom: `settlement/settlement-summary-v3-download?${finalParameter3.slice(0, -1)}`,
      //   selectUrl: urlSelection.dashboard,
      //   useToken: true,
      //   needLoading: true,
      // }).then((response: any) => {
      //   dispatch({ type: "dataCsv", value: response.data });
      // });
    } else {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter3.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: "data", value: response.data });
        dispatch({ type: "totalPages", value: response.detail.totalPage });
        dispatch({ type: "totalData", value: response.detail.total });
      });

      //
      API.get({
        bodyCustom: null,
        pathCustom: `settlement/settlement-summary-v3-total?${finalParameter3.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: "data2", value: response.data });
      });
    }
  };

  useEffect(() => {
    API.get({
      bodyCustom: null,
      pathCustom: `userapp/${userLogin.user.userId}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response: any) => {
      dispatch({ type: "userData", value: response?.data });
    });
  }, []);

  useEffect(() => {
    initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsSettlementSummary, dispatchGlobal);
  }, []);

  useEffect(() => {
    if (stateCustomComponent.acqSwitchSettleStartDate !== "" || stateCustomComponent.issSwitchSettleStartDate !== "") initFunctionCustom(false);
  }, [state.position, state.view]);

  useEffect(() => {
    if (stateCustomComponent.acqSwitchSettleStartDate !== "" || stateCustomComponent.issSwitchSettleStartDate !== "") initFunctionCustom(true);
  }, [state.search]);

  // useEffect(() => {
  //   initFunctionCustom(false);
  // }, [pagination.pageIndex]);

  // useEffect(() => {
  //   setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
  // }, [state.view]);


  useEffect(() => {
    let currentPage: number = parseInt((state.position / state.view).toString());
    dispatch({ type: "currentPage", value: currentPage });
  }, [state.totalData, state.position, state.view]);
  //   useEffect(() => {
  //     if (state.view !== pagination.pageSize) {
  //         initFunctionCustom(true);
  //         setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
  //     } else {
  //         initFunctionCustom(false);
  //     }
  // }, [pagination.pageIndex, state.view]);


  useEffect(() => {
    if (userLogin.memberCode !== '410000' && userLogin.memberCode !== '410001') {
      const acquirerSwitcherCode = userLogin.memberRoleType?.includes('006') ? state.userData.userInstitutionCode : stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail[0]?.value;
      const issuerSwitcherCode = userLogin.memberRoleType?.includes('005') ? state.userData.userInstitutionCode : stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail[0]?.value;

      handleState("selectedAcquirerSwitcherInstitutionIDDetailCode", acquirerSwitcherCode, dispatchCustomComponent);
      handleState("selectedIssuerSwitcherInstitutionIDDetailCode", issuerSwitcherCode, dispatchCustomComponent);
    }
  }, [stateOptions]);

  const calculateDateDifference = (startDate: string, endDate: string): number => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };


  useEffect(() => {
    if (stateCustomComponent.acqSwitchSettleStartDate !== '' && stateCustomComponent.acqSwitchSettleEndDate !== '') {
      let dateStart = new Date(stateCustomComponent.acqSwitchSettleStartDate);
      let dateEnd = new Date(stateCustomComponent.acqSwitchSettleEndDate);

      if (dateStart > dateEnd) {
        dispatch({
          type: 'errorMessage',
          value: 'Start date cannot be larger than End Date',
        });
        return;
      }

      let diffInMillis: number = +dateEnd - +dateStart;
      let diffDays: number = Math.floor(diffInMillis / (24 * 60 * 60 * 1000));
      if (diffDays > 6) {
        dispatch({
          type: 'errorMessage',
          value: 'Maximum range for start~end date is 7 days',
        });
      } else {
        let listAllDayChart = [];
        for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
          listAllDayChart.push(d.toISOString().slice(0, 10));
        }

        if (state.errorMessage !== '') {
          dispatch({ type: 'errorMessage', value: '' });
        }
      }
    }
  }, [stateCustomComponent.acqSwitchSettleStartDate, stateCustomComponent.acqSwitchSettleEndDate]);

  useEffect(() => {
    if (stateCustomComponent.issSwitchSettleStartDate !== '' && stateCustomComponent.issSwitchSettleEndDate !== '') {
      let dateStart = new Date(stateCustomComponent.issSwitchSettleStartDate);
      let dateEnd = new Date(stateCustomComponent.issSwitchSettleEndDate);

      if (dateStart > dateEnd) {
        dispatch({
          type: 'errorMessage',
          value: 'Start date cannot be larger than End Date',
        });
        return;
      }

      let diffInMillis: number = +dateEnd - +dateStart;
      let diffDays: number = Math.floor(diffInMillis / (24 * 60 * 60 * 1000));
      if (diffDays > 6) {
        dispatch({
          type: 'errorMessage',
          value: 'Maximum range for start~end date is 7 days',
        });
      } else {
        let listAllDayChart = [];
        for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
          listAllDayChart.push(d.toISOString().slice(0, 10));
        }

        if (state.errorMessage !== '') {
          dispatch({ type: 'errorMessage', value: '' });
        }
      }
    }
  }, [stateCustomComponent.issSwitchSettleStartDate, stateCustomComponent.issSwitchSettleEndDate]);


  const exportToCSVComponent = () => ExcelSettlementSummary(state, stateOptions);
  //
  const addDataComponent = () => addData(dispatch);
  const deleteDataComponent = (item: ModelSettlementSummary) => deleteData(item.id, item, dispatch);
  const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state);
  const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch);
  const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state);
  const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state);
  const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage);
  const actionShowModal = (idModal: any, item: any) =>
    dispatch({
      type: "showModal",
      value: { idModal, showModal: item.showModal, itemModal: item },
    });
  const actionIndexModal = (idModal: any) => dispatch({ type: "changeIndex", value: idModal });
  const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false);

  const customButtonAction = (item: ModelSettlementSummary, width: string | any) => {
    return <div className={`d-flex justify-content-around align-items-center ${width}`}></div>;
  };
  const isButtonDisabled = calculateDateDifference(stateCustomComponent.acqSwitchSettleStartDate, stateCustomComponent.acqSwitchSettleEndDate) > 6 || calculateDateDifference(stateCustomComponent.issSwitchSettleStartDate, stateCustomComponent.issSwitchSettleEndDate) > 6;
  const isButtonDisabled2 = state.data2 === undefined || calculateDateDifference(stateCustomComponent.acqSwitchSettleStartDate, stateCustomComponent.acqSwitchSettleEndDate) > 6 || calculateDateDifference(stateCustomComponent.issSwitchSettleStartDate, stateCustomComponent.issSwitchSettleEndDate) > 6;

  const filterComponentPage = () => {
    //member
    let memberList: any = [];
    if (stateOptions.OptionsMemberRoleType.length > 0) {
      stateOptions.OptionsMemberRoleType.forEach((data: any) => {
        memberList.push({
          value: data.value,
          label: data.label,
        });
      });
    }


    const customStyles = {
      placeholder: (base: any) => ({
        ...base,
        color: "#313131", // Change placeholder color to black
      }),
    };

    return (
      // <div className="container-all-select-custom">
      <div className="mb-2">
        <div className="d-flex flex-column w-100">
          <div className="row bg-ff br-10 bd-d4 gx-2 gy-2 px-2">
            <div className="col-2 mb-1">
              <p className={labelStyle}>ACQ SWITCH</p>
              {(userLogin.memberCode === '410000' || userLogin.memberCode === '410001') ? (
                <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode)} onChange={(e) => handleState("selectedAcquirerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
              ) : (userLogin.memberRoleType?.includes('006')) ? (
                <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === state.userData.userInstitutionCode)} isDisabled={true} />
              ) : (
                <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail[0]} isDisabled={true} />
              )}
            </div>
            <div className="col-2">
              <p className={labelStyle}>ISS SWITCH</p>
              {(userLogin.memberCode === '410000' || userLogin.memberCode === '410001') ? (
                <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode)} onChange={(e) => handleState("selectedIssuerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
              ) : (userLogin.memberRoleType?.includes('005')) ? (
                <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === state.userData.userInstitutionCode)} isDisabled={true} />
              ) : (
                <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail[0]} isDisabled={true} />
              )}
            </div>
            <div className="col-2"
              onClick={
                () => {
                  if (stateCustomComponent.issSwitchSettleStartDate !== "" || stateCustomComponent.issSwitchSettleEndDate !== "") {
                    dispatch({ type: "errorMessage", value: "You can only search Issuer switcher date. If you want to search with Acquirer Switcher date, please refresh the browser than select Acquirer Switcher date" });
                    <ErrorMessage errorMessage={state.errorMessage} />
                  }
                }
              }>
              <p className={labelStyle}>ACQ SWITCH SETTLE START DATE</p>
              <div className="d-flex justify-content-between">
                <input value={stateCustomComponent.acqSwitchSettleStartDate}
                  onChange={(e) => {
                    const startDate = e.target.value;
                    handleState("acqSwitchSettleStartDate", startDate, dispatchCustomComponent);
                    if (startDate) {
                      let dateNow = new Date();
                      // let dateNow = new Date().toISOString().slice(0, 10);
                      const endDate = new Date(startDate);
                      endDate.setDate(endDate.getDate() + 6);
                      if (endDate >= dateNow) {
                        handleState("acqSwitchSettleEndDate", dateNow.toISOString().slice(0, 10), dispatchCustomComponent);
                      }
                      else { handleState("acqSwitchSettleEndDate", endDate.toISOString().slice(0, 10), dispatchCustomComponent); }
                    }
                  }}
                  type="date" className={inputStyle} disabled={stateCustomComponent.issSwitchSettleStartDate !== '' || stateCustomComponent.issSwitchSettleEndDate !== ''} />
              </div>
            </div>
            <div className="col-2"
              onClick={
                () => {
                  if (stateCustomComponent.issSwitchSettleStartDate !== "" || stateCustomComponent.issSwitchSettleEndDate !== "") {
                    dispatch({ type: "errorMessage", value: "You can only search Issuer switcher date. If you want to search with Acquirer Switcher date, please refresh the browser than select Acquirer Switcher date" });
                    <ErrorMessage errorMessage={state.errorMessage} />
                  }
                }
              } >
              <p className={labelStyle}>ACQ SWITCH SETTLE END DATE</p>
              <div className="d-flex justify-content-between">
                <input value={stateCustomComponent.acqSwitchSettleEndDate} onChange={(e) => handleState("acqSwitchSettleEndDate", e.target.value, dispatchCustomComponent)} type="date" className={inputStyle} disabled={stateCustomComponent.issSwitchSettleStartDate !== '' || stateCustomComponent.issSwitchSettleEndDate !== ''} />
              </div>
            </div>
            <div className="col-2"
              onClick={
                () => {
                  if (stateCustomComponent.acqSwitchSettleStartDate !== "" || stateCustomComponent.acqSwitchSettleEndDate !== "") {
                    dispatch({ type: "errorMessage", value: "You can only search Acquirer switcher date. If you want to search with Issuer Switcher date, please refresh the browser than select Issuer Switcher date" });
                    <ErrorMessage errorMessage={state.errorMessage} />
                  }
                }
              }>
              <p className={labelStyle}>ISS SWITCH SETTLE START DATE</p>
              <div className="d-flex justify-content-between">
                <input value={stateCustomComponent.issSwitchSettleStartDate} onChange={(e) => {
                  const startDate = e.target.value;
                  handleState("issSwitchSettleStartDate", startDate, dispatchCustomComponent);
                  if (startDate) {
                    let dateNow = new Date();
                    // let dateNow = new Date().toISOString().slice(0, 10);
                    const endDate = new Date(startDate);
                    endDate.setDate(endDate.getDate() + 6);
                    if (endDate >= dateNow) {
                      handleState("issSwitchSettleEndDate", dateNow.toISOString().slice(0, 10), dispatchCustomComponent);
                    }
                    else { handleState("issSwitchSettleEndDate", endDate.toISOString().slice(0, 10), dispatchCustomComponent); }
                  }
                }} type="date" className={inputStyle} disabled={stateCustomComponent.acqSwitchSettleStartDate !== '' || stateCustomComponent.acqSwitchSettleEndDate !== ''}
                />              </div>
            </div>
            <div className="col-2"
              onClick={
                () => {
                  if (stateCustomComponent.acqSwitchSettleStartDate !== "" || stateCustomComponent.acqSwitchSettleEndDate !== "") {
                    dispatch({ type: "errorMessage", value: "You can only search Acquirer switcher date. If you want to search with Issuer Switcher date, please refresh the browser than select Issuer Switcher date" });
                    <ErrorMessage errorMessage={state.errorMessage} />
                  }
                }
              } >
              <p className={labelStyle}>ISS SWITCH SETTLE END DATE</p>
              <div className="d-flex justify-content-between">
                <input value={stateCustomComponent.issSwitchSettleEndDate} onChange={(e) => handleState("issSwitchSettleEndDate", e.target.value, dispatchCustomComponent)} type="date" className={inputStyle} disabled={stateCustomComponent.acqSwitchSettleStartDate !== '' || stateCustomComponent.acqSwitchSettleEndDate !== ''} />
              </div>
            </div>
            <div className="col-10">
            </div>
            <div className="col-2">
              <div className="d-flex justify-content-end mb-2">
                <button className={'btn d-flex align-items-center bg-e8 h-30px'} style={{ opacity: isButtonDisabled ? 0.7 : 1, height: '25px' }} onClick={
                  (e) => {
                    if (stateCustomComponent.acqSwitchSettleStartDate == "" && stateCustomComponent.issSwitchSettleStartDate == "") {
                      dispatch({ type: "errorMessage", value: "Please select at least one start date" });
                    }
                    else {
                      initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsSettlementSummary, dispatchGlobal);
                      dispatch({ type: "errorMessage", value: null });
                    }
                  }
                }
                  disabled={isButtonDisabled}>
                  {icon_search_white}
                  <p className='s-1418 c-ff Lato-600 mx-2'>Search</p>
                </button>
              </div>
            </div>

          </div>
        </div>
        {/* <div className="row gx-4 gy-4">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
            <p className={labelStyle}>ACQ SWITCH</p>
            {(userLogin.memberCode === '410000' || userLogin.memberCode === '410001') ? (
              <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedAcquirerSwitcherInstitutionIDDetailCode)} onChange={(e) => handleState("selectedAcquirerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
            ) : (userLogin.memberRoleType?.includes('006')) ? (
              <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === state.userData.userInstitutionCode)} isDisabled={true} />
            ) : (
              <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail[0]} isDisabled={true} />
            )}
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
            <p className={labelStyle}>ISS SWITCH</p>
            {(userLogin.memberCode === '410000' || userLogin.memberCode === '410001') ? (
              <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateCustomComponent.selectedIssuerSwitcherInstitutionIDDetailCode)} onChange={(e) => handleState("selectedIssuerSwitcherInstitutionIDDetailCode", e.value, dispatchCustomComponent)} />
            ) : (userLogin.memberRoleType?.includes('005')) ? (
              <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value === state.userData.userInstitutionCode)} isDisabled={true} />
            ) : (
              <Select styles={inputStyleH25} options={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail.filter((option: any) => option.value2 !== '000')} value={stateOptions.optionsSwitcherInstitutionIDUseCCDNameAndCodeDetail[0]} isDisabled={true} />
            )}
          </div>
        </div>
        <div className="row gx-4 gy-2">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 pointer"
            onClick={
              () => {
                if (stateCustomComponent.issSwitchSettleStartDate !== "" || stateCustomComponent.issSwitchSettleEndDate !== "") {
                  dispatch({ type: "errorMessage", value: "You can only search Issuer switcher date. If you want to search with Acquirer Switcher date, please refresh the browser than select Acquirer Switcher date" });
                  <ErrorMessage errorMessage={state.errorMessage} />
                }
              }
            } >            <p className={labelStyle}>ACQ SWITCH SETTLE START DATE</p>
            <input value={stateCustomComponent.acqSwitchSettleStartDate}
              onChange={(e) => {
                const startDate = e.target.value;
                handleState("acqSwitchSettleStartDate", startDate, dispatchCustomComponent);
                if (startDate) {
                  let dateNow = new Date();
                  // let dateNow = new Date().toISOString().slice(0, 10);
                  const endDate = new Date(startDate);
                  endDate.setDate(endDate.getDate() + 6);
                  if (endDate >= dateNow) {
                    handleState("acqSwitchSettleEndDate", dateNow.toISOString().slice(0, 10), dispatchCustomComponent);
                  }
                  else { handleState("acqSwitchSettleEndDate", endDate.toISOString().slice(0, 10), dispatchCustomComponent); }
                }
              }}
              type="date" className={inputStyle} disabled={stateCustomComponent.issSwitchSettleStartDate !== '' || stateCustomComponent.issSwitchSettleEndDate !== ''} />
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 pointer"
            onClick={
              () => {
                if (stateCustomComponent.issSwitchSettleStartDate !== "" || stateCustomComponent.issSwitchSettleEndDate !== "") {
                  dispatch({ type: "errorMessage", value: "You can only search Issuer switcher date. If you want to search with Acquirer Switcher date, please refresh the browser than select Acquirer Switcher date" });
                  <ErrorMessage errorMessage={state.errorMessage} />
                }
              }
            } >            <p className={labelStyle}>ACQ SWITCH SETTLE END DATE</p>
            <input value={stateCustomComponent.acqSwitchSettleEndDate} onChange={(e) => handleState("acqSwitchSettleEndDate", e.target.value, dispatchCustomComponent)} type="date" className={inputStyle} disabled={stateCustomComponent.issSwitchSettleStartDate !== '' || stateCustomComponent.issSwitchSettleEndDate !== ''} />
          </div>
        </div>
        <div className="row gx-4 gy-2">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 pointer"
            onClick={
              () => {
                if (stateCustomComponent.acqSwitchSettleStartDate !== "" || stateCustomComponent.acqSwitchSettleEndDate !== "") {
                  dispatch({ type: "errorMessage", value: "You can only search Acquirer switcher date. If you want to search with Issuer Switcher date, please refresh the browser than select Issuer Switcher date" });
                  <ErrorMessage errorMessage={state.errorMessage} />
                }
              }
            } >
            <p className={labelStyle}>ISS SWITCH SETTLE START DATE</p>
            <input value={stateCustomComponent.issSwitchSettleStartDate} onChange={(e) => {
              const startDate = e.target.value;
              handleState("issSwitchSettleStartDate", startDate, dispatchCustomComponent);
              if (startDate) {
                let dateNow = new Date();
                // let dateNow = new Date().toISOString().slice(0, 10);
                const endDate = new Date(startDate);
                endDate.setDate(endDate.getDate() + 6);
                if (endDate >= dateNow) {
                  handleState("issSwitchSettleEndDate", dateNow.toISOString().slice(0, 10), dispatchCustomComponent);
                }
                else { handleState("issSwitchSettleEndDate", endDate.toISOString().slice(0, 10), dispatchCustomComponent); }
              }
            }} type="date" className={inputStyle} disabled={stateCustomComponent.acqSwitchSettleStartDate !== '' || stateCustomComponent.acqSwitchSettleEndDate !== ''}
            />
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 pointer"
            onClick={
              () => {
                if (stateCustomComponent.acqSwitchSettleStartDate !== "" || stateCustomComponent.acqSwitchSettleEndDate !== "") {
                  dispatch({ type: "errorMessage", value: "You can only search Acquirer switcher date. If you want to search with Issuer Switcher date, please refresh the browser than select Issuer Switcher date" });
                  <ErrorMessage errorMessage={state.errorMessage} />
                }
              }
            } >
            <p className={labelStyle}>ISS SWITCH SETTLE END DATE</p>
            <input value={stateCustomComponent.issSwitchSettleEndDate} onChange={(e) => handleState("issSwitchSettleEndDate", e.target.value, dispatchCustomComponent)} type="date" className={inputStyle} disabled={stateCustomComponent.acqSwitchSettleStartDate !== '' || stateCustomComponent.acqSwitchSettleEndDate !== ''} />
          </div>
        </div>
        {state.errorMessage ? <div className="row gx-4 mt-2">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12">
            <ErrorMessage errorMessage={state.errorMessage} />
          </div>
        </div> : <div />}
        <div className="row gx-4 mt-2">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center">
            <button className="d-flex align-items-center px-4 py-3 br-10 bd-d4 bg-e8 bd-29 text-white" style={{ opacity: isButtonDisabled ? 0.7 : 1 }} onClick={
              (e) => {
                if (stateCustomComponent.acqSwitchSettleStartDate == "" && stateCustomComponent.issSwitchSettleStartDate == "") {
                  dispatch({ type: "errorMessage", value: "Please select at least one start date" });
                }
                else {
                  initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsSettlementSummary, dispatchGlobal);
                  dispatch({ type: "errorMessage", value: null });
                }
              }
            }
              disabled={isButtonDisabled}>
              {icon_search_white}
              <p className="s-1418 c-ff Lato-600 mx-2">SEARCH</p>
            </button>
          </div>
        </div> */}
      </div>
    );
  };
  useEffect(() => {
    if (state.modalCondition === false) {
      dispatch({ type: "resetAllSelected" });
    }
  }, [state.modalCondition]);

  const tableComponentPage = () => {
    return (
      <TableSettlementSummary
        state={state}
        data2={state.data2}
        addDataComponent={addDataComponent}
        stateOptions={stateOptions}
        customButtonAction={customButtonAction}
        exportToCSVComponent={exportToCSVComponent}
        handleStateComponent={handleStateComponent}
        checkIndexComponent={checkIndexComponent}
        setModal={setModalComponent}
        modalAddDeleteComponentPage={modalAddDeleteComponentPage}
        modalUpdateComponentPage={modalUpdateComponentPage}
        pagination={pagination}
        setPagination={setPagination}
        isButtonDisabled={isButtonDisabled2}

        //   stateParent={state}
        //   //
        //   data2={state.data2}
        // //

        // totalTransCount={state.totalTransCount}
        // totalReconCount={state.totalReconCount}
        // totalTransAmount={state.totalTransAmount}
        // totalReconAmount={state.totalReconAmount}
        editData={(item: any, idSelected: string) => {
          if (idSelected === "reconStatus") {
            // console.log("test", item)
            dispatch({
              type: "setReconStatus",
              value: {
                selectedacqSwitchMemberCode: item.acqSwitchMemberCode,
                selectedacqSwitchSettDate: item.acqSwitchSettDate,
                selectedissSwitchMemberCode: item.issSwitchMemberCode,
                selectedissSwitchSettDate: item.issSwitchSettDate,
              }
              // 'acquirerId': item.split2,
              // 'issuerSwitchId': item.split2,
            })
          }

          dispatch({ type: "modalCondition", value: !state.modalCondition });
        }}
      />
    );
    // return (
    //   <TableSettlementSummary
    //     codeAccessPrivilegeDelete={state.privilegeAccess.delete}
    //     codeAccessPrivilegeEdit={state.privilegeAccess.edit}
    //     label={state.label}
    //     data={state.data}
    //     position={state.position}
    //     view={state.view}
    //     totalTransCount={state.totalTransCount}
    //     totalReconCount={state.totalReconCount}
    //     totalTransAmount={state.totalTransAmount}
    //     totalReconAmount={state.totalReconAmount}
    //     customButtonAction={customButtonAction}
    //     checkIndex={checkIndexComponent}
    //     widthTable={`wd-${state.widthTable}-responsive`}
    //     stateOptions={stateOptions}
    //     editData={(item: any, idSelected: string) => {
    //       let memberIdRole = item.memberId;
    //       let splitMemberId = memberIdRole
    //         .replace(/^[^(]+\(|\)$/g, "")
    //         .replace(/\)$/g, "")
    //         .trim();
    //       let split2 = splitMemberId.replace(")", "");
    //       let selectedRow;

    //       if (split2 === "Acquirer") {
    //         selectedRow = "acquirerId";
    //       } else if (split2 === "Issuer") {
    //         selectedRow = "issuerId";
    //       } else if (split2 === "Acquirer Switcher") {
    //         selectedRow = "acquirerSwitchId";
    //       } else if (split2 === "Issuer Switcher") {
    //         selectedRow = "issuerSwitchId";
    //       }

    //       if (idSelected === "reconStatus") {
    //         dispatch({
    //           type: "setReconStatus",
    //           value: {
    //             memberId: split2,
    //             // 'acquirerId': item.split2,
    //             // 'issuerSwitchId': item.split2,

    //             selectedRowName: selectedRow,
    //             issuerSettleDate: item.sttlDate,
    //             //
    //             acquirerId: item.acquirerId,
    //             issuerId: item.issuerId,
    //             issuerSwitchId: item.issuerSwitchId,
    //             acquirerSwitchId: item.acquirerSwitchId,
    //             acquirerSwitchSettleDate: item.sttlDate,
    //             //
    //             procDate: item.sttlDate,
    //             //
    //             titleTableLeft: split2,
    //             titleTableRight: "Acquirer Switcher",

    //             issuerMemberName: item.issuerMemberName,
    //             acquirerMemberName: item.acquirerMemberName,
    //             acquirerSwitchMemberName: item.acquirerSwitchMemberName,
    //             issuerSwitchMemberName: item.issuerSwitchMemberName,
    //           },
    //         });
    //       }
    //       dispatch({ type: "modalCondition", value: !state.modalCondition });
    //     }}
    //     //
    //     currentPage={state.currentPage}
    //   />
    // );
  };

  const modalAddDeleteComponentPage = () => {
    return (
      <ModalSettlementSummary
        pagination={pagination}
        setPagination={setPagination}
        modalCondition={state.modalCondition}
        setModal={setModalComponent}
        deleteItem={state.deleteItem}
        id={state.id}
        dataModal={state.dataModal}
        urlApi={state.urlApi}
        reloadData={() => reloadDataComponent(false)}
        stateOptions={stateOptions}
        //
        stateParent={state}
        dispatchParent={dispatch}
        handleStateComponent={handleStateComponent}
      />
    );
  };
  const modalUpdateComponentPage = () => {
    return <></>;
  };

  return (
    <>
      <h1 className="Lato-300 normal w-600 s-1822 py-2 mt-2">
        Settlement Summary
      </h1>
      {filterComponentPage()}
      {tableComponentPage()}
      {/* <TableWithLimitOffset
        isButtonDisabled={isButtonDisabled2}
        state={state}
        stateOptions={stateOptions}
        customButtonAction={customButtonAction}
        exportToCSVComponent={exportToCSVComponent}
        addDataComponent={addDataComponent}
        checkIndexComponent={checkIndexComponent}
        handleStateComponent={handleStateComponent}
        setToggleComponent={setToggleComponent}
        setModal={setModalComponent}
        // component
        tableComponentPage={tableComponentPage}
        filterComponentPage={filterComponentPage}
        modalAddDeleteComponentPage={modalAddDeleteComponentPage}
        modalUpdateComponentPage={modalUpdateComponentPage}
        //
        needComponentHeaderTable={false}
        needComponentFilterTable={true}
        needComponentTable={true}
        needComponentFooterTable={true}
        //
        needToggleFunction={false}
        needAddButton={false}
        needSearchFunction={false}
        needDownloadButton={true}
        needComponentHeaderTableCenter={true}
      /> */}
      {/* {state.totalData > 0 ? (
        <div className="d-flex my-2 pt-20px">
          <table className="table" style={{}}>
            <tbody>
              <tr>
                <td style={{ width: "150px" }}>
                  Transaction Count : {CheckUndefined(state.transCounts)}
                </td>
                <td style={{ width: "150px" }}>
                  Transaction Amout : {CheckUndefined(state.transAmounts)}
                </td>
                <td style={{ width: "150px" }}>
                  Recon Count : {CheckUndefined(state.reconCounts)}
                </td>
                <td style={{ width: "150px" }}>
                  Recon Amount : {CheckUndefined(state.reconAmounts)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null} */}
    </>
  );
};
const initialStateOptions = { ...stateOptions };
const initialStateCustomComponent = { ...stateSelected };
const initialState = {
  urlApi: "settlement/settlement-summary-v3",
  titlePage: "Settlement Summary",
  privilegeAccess: { view: "681", add: "", edit: "", delete: "", download: "" },
  label: [
    { field: "No.", width: "wd-50", val: "no" },
    { field: "Acquirer Switcher Settle Date", width: "wd-150", val: "acqSwitchSettDate" },
    { field: "Acquirer Switcher", width: "wd-100", val: "acqSwitchMemberId" },
    { field: "Issuer Switcher Settle Date", width: "wd-150", val: "issSwitchSettDate" },
    { field: "Issuer Switcher", width: "wd-100", val: "issSwitchMemberId" },
    { field: "Transaction Count", width: "wd-100", val: "trxCount" },
    { field: "Acquirer Switcher Amount", width: "wd-150", val: "acqSwitchAmount" },
    { field: "Issuer Switcher Amount", width: "wd-150", val: "issSwitchAmount" },
    { field: "Recon Status", width: "wd-100", val: "reconStatus" },
    { field: "Service Fee", width: "wd-100", val: "serviceFee" },
    { field: "Settlement Amount", width: "wd-150", val: "settAmount" },
  ],
  toggle: ["no", "acqSwitchSettDate", "acqSwitchMemberId", "issSwitchSettDate", "issSwitchMemberId", "trxCount", "acqSwitchAmount", "issSwitchAmount", "reconStatus", "serviceFee", "settAmount"],
  complete_toggle: ["no", "acqSwitchSettDate", "acqSwitchMemberId", "issSwitchSettDate", "issSwitchMemberId", "trxCount", "acqSwitchAmount", "issSwitchAmount", "reconStatus", "serviceFee", "settAmount"],
  list_column_name: ["No.", "Acquirer Switcher Settle Date", "Acquirer Switcher", "Issuer Switcher Settle Date", "Issuer Switcher", "Transaction Count", "Acquirer Switcher Amount", "Issuer Switcher Amount", "Recon Status", "Service Fee", "Settlement Amount"],
  //
  modalCondition: false,
  savedShowModal: [], // new
  //
  id: "",
  data: [],
  dataModal: null,
  search: "",
  view: 10,
  position: 10,
  list_view: [10, 20, 50],
  totalData: 0, // new
  totalPages: 0,
  currentPage: 0,
  insertPage: false,
  deleteItem: false,
  //
  widthTable: 2000,
  dataLength: 0, // zIndex
  //
  selectedAcq: "",
  selectedAcqSwitcher: "",
  selectedAcqDate: "",

  //
  selectedIssuerId: "",
  selectedIssuerSettleDate: "",

  selectedAcquirerId: "",
  selectedAcquirerSettleDate: "",

  selectedAcquirerSwitchId: "",
  selectedAcquirerSwitchSettleDate: "",

  selectedIssuerSwitchId: "",
  selectedIssuerSwitchSettleDate: "",
  //
  selectedProcDate: "",

  whatIsSelectedNow: "",

  titleTableLeft: "",
  titleTableRight: "",

  //
  selectedIssuerMemberName: "",
  selectedIssuerSwitchMemberName: "",
  selectedAcquirerMemberName: "",
  selectedAcquirerSwitchMemberName: "",

  // transCounts: 0,
  // transAmounts: 0,
  // reconCounts: 0,
  // reconAmounts: 0,
  userData: "",
  finalAPI: "",

  // totalTransCount: 0,
  // totalTransAmount: 0,
  // totalReconCount: 0,
  // totalReconAmount: 0,
  // total: 0,

  filterAcqId: "",
  filterIssId: "",
  filterAcqSwitchId: "",
  filterIssSwitchId: "",

  errorMessage: null
};

// sttlId ModelSettlementSummary
function reducer(state: any, action: any) {
  let showDataResult: any[] = [];
  let dataWhereChangeToIdGeneral: any[] = [];
  let newSavedShowModal: ShowModalInterface[] = [];
  switch (action.type) {
    case "data":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelSettlementSummary) => {
            element1.id = element1.sttlId;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case "searchData":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelSettlementSummary) => {
            element1.id = element1.sttlId;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        position: state.view,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case "showModal":
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            let conditionShowModalFind = false;
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value.idModal) {
                conditionShowModalFind = true;
              }
            });
            if (conditionShowModalFind === false) {
              newSavedShowModal.push({
                idModal: action.value.idModal,
                showModal: !action.value.showModal,
                itemModal: action.value.itemModal,
                index: state.dataLength + 99,
              });
              newSavedShowModal.push(...state.savedShowModal);
            } else {
              state.savedShowModal.forEach((element1: ShowModalInterface) => {
                if (element1.idModal === action.value.idModal) {
                  if (element1.showModal === false) {
                    // will to show
                    newSavedShowModal.push({
                      idModal: action.value.idModal,
                      showModal: !element1.showModal,
                      itemModal: action.value.itemModal,
                      index: state.dataLength + 99,
                    });
                  } else {
                    newSavedShowModal.push({
                      idModal: action.value.idModal,
                      showModal: !element1.showModal,
                      itemModal: action.value.itemModal,
                      index: 99,
                    });
                  }
                } else {
                  newSavedShowModal.push({
                    idModal: element1.idModal,
                    showModal: element1.showModal,
                    itemModal: element1.itemModal,
                    index: 99,
                  });
                }
              });
            }
          } else {
            newSavedShowModal.push({
              idModal: action.value.idModal,
              showModal: !action.value.showModal,
              itemModal: action.value.itemModal,
              index: 99,
            });
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case "changeIndex":
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value) {
                newSavedShowModal.push({
                  idModal: element1.idModal,
                  showModal: element1.showModal,
                  itemModal: element1.itemModal,
                  index: state.dataLength + 99,
                });
              } else {
                newSavedShowModal.push({
                  idModal: element1.idModal,
                  showModal: element1.showModal,
                  itemModal: element1.itemModal,
                  index: 99,
                });
              }
            });
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case "afterUpdate":
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelSettlementSummary) => {
            showDataResult.push({
              idModal: element1.sttlId,
              showModal: false,
              itemModal: element1,
              index: 1,
            });
            element1.id = element1.sttlId;
            dataWhereChangeToIdGeneral.push(element1);
          });
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        showModal: showDataResult,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    //
    case "setReconStatus":
      return {
        ...state,
        selectedacqSwitchMemberCode: action.value.selectedacqSwitchMemberCode,
        selectedacqSwitchSettDate: action.value.selectedacqSwitchSettDate,
        selectedissSwitchMemberCode: action.value.selectedissSwitchMemberCode,
        selectedissSwitchSettDate: action.value.selectedissSwitchSettDate,
      };
    case "resetAllSelected":
      return {
        ...state,
        selectedIssuerId: "",
        selectedIssuerSettleDate: "",

        selectedAcquirerId: "",
        selectedAcquirerSettleDate: "",

        selectedAcquirerSwitchId: "",
        selectedAcquirerSwitchSettleDate: "",

        selectedIssuerSwitchId: "",
        selectedIssuerSwitchSettleDate: "",

        selectedProcDate: "",

        whatIsSelectedNow: "",

        titleTableLeft: "",
        titleTableRight: "",

        filterAcqId: '',
        filterIssId: '',
        filterAcqSwitchId: '',
        filterIssSwitchId: '',
      };
    case "selectedAcq":
      return {
        ...state,
        selectedAcq: action.value,
      };
    case "selectedAcqSwitcher":
      return {
        ...state,
        selectedAcqSwitcher: action.value,
      };
    case "selectedAcqDate":
      return {
        ...state,
        selectedAcqDate: action.value,
      };

    case "selectedIssuerSettleDate":
      return {
        ...state,
        selectedIssuerSettleDate: action.value,
      };
    case "selectedAcquirerSettleDate":
      return {
        ...state,
        selectedAcquirerSettleDate: action.value,
      };
    case "selectedAcquirerSwitchSettleDate":
      return {
        ...state,
        selectedAcquirerSwitchSettleDate: action.value,
      };
    case "selectedIssuerSwitchSettleDate":
      return {
        ...state,
        selectedIssuerSwitchSettleDate: action.value,
      };
    case "selectedAcqDate":
      return {
        ...state,
        selectedAcqDate: action.value,
      };
    //
    case "selectedacqSwitchMemberCode":
      return {
        ...state,
        selectedacqSwitchMemberCode: action.value
      };
    case "selectedacqSwitchSettDate":
      return {
        ...state,
        selectedacqSwitchSettDate: action.value
      };
    case "selectedissSwitchMemberCode":
      return {
        ...state,
        selectedissSwitchMemberCode: action.value
      };
    case "selectedissSwitchSettDate":
      return {
        ...state,
        selectedissSwitchSettDate: action.value
      };

    //
    case "widthTable":
      return {
        ...state,
        widthTable: action.value,
      };
    case "modalCondition":
      return {
        ...state,
        modalCondition: action.value,
      };
    case "id":
      return {
        ...state,
        id: action.value,
      };
    case "label":
      return {
        ...state,
        label: action.value,
      };
    case "toggle":
      return {
        ...state,
        toggle: action.value,
      };
    case "complete_toggle":
      return {
        ...state,
        complete_toggle: action.value,
      };
    case "list_column_name":
      return {
        ...state,
        list_column_name: action.value,
      };
    case "privilegeAccess":
      return {
        ...state,
        privilegeAccess: action.value,
      };
    case "deleteItem":
      return {
        ...state,
        deleteItem: action.value,
      };
    case "list_view":
      return {
        ...state,
        list_view: action.value,
      };
    case "view":
      return {
        ...state,
        view: action.value,
      };
    case "position":
      return {
        ...state,
        position: action.value,
      };
    case "search":
      return {
        ...state,
        search: action.value,
      };
    case "dataModal":
      return {
        ...state,
        dataModal: action.value,
      };
    case "insertPage":
      return {
        ...state,
        insertPage: action.value,
      };
    case "addData":
      return {
        ...state,
        id: "",
        dataModal: null,
        modalCondition: !state.modalCondition,
      };
    case "editData":
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        modalCondition: true,
      };
    case "deleteData":
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        deleteItem: true,
      };
    // case "changeData":
    //   return {
    //     ...state,
    //     totalPages: action.value.totalPages,
    //     currentPage: action.value.currentPage,
    //     transCounts: action.value.transCounts,
    //     transAmounts: action.value.transAmounts,
    //     reconCounts: action.value.reconCounts,
    //     reconAmounts: action.value.reconAmounts,
    //   };
    //
    case "data2":
      return {
        ...state,
        data2: action.value,
        //
      };
    case "dataCsv":
      return {
        ...state,
        dataCsv: action.value,
        //
      };
    // new
    case "totalData":
      return {
        ...state,
        totalData: action.value,
      };
    case "totalPages":
      return {
        ...state,
        totalPages: action.value,
      };
    case "currentPage":
      return {
        ...state,
        currentPage: action.value,
      };

    //
    case "transCounts": {
      return {
        ...state,
        transCounts: action.value,
      };
    }
    case "transAmounts": {
      return {
        ...state,
        transAmounts: action.value,
      };
    }
    case "reconCounts": {
      return {
        ...state,
        reconCounts: action.value,
      };
    }
    case "reconAmounts": {
      return {
        ...state,
        reconAmounts: action.value,
      };
    }
    case "userData": {
      return {
        ...state,
        userData: action.value,
      };
    }
    case "finalAPI":
      return {
        ...state,
        finalAPI: action.value,
      };
    case "totalTransCount":
      return {
        ...state,
        totalTransCount: action.value,
      };
    case "totalTransAmount":
      return {
        ...state,
        totalTransAmount: action.value,
      };
    case "totalReconCount":
      return {
        ...state,
        totalReconCount: action.value,
      };
    case "totalReconAmount":
      return {
        ...state,
        totalReconAmount: action.value,
      };
    case "total":
      return {
        ...state,
        total: action.value,
      };
    //
    case "errorMessage":
      return {
        ...state,
        errorMessage: action.value,
      };
    default:
      throw new Error();
  }
}

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { CheckUndefined } from '../../Utils';
import { SelectView } from '../../Components';
import { Button, colors, Modal, Tooltip } from '@mui/material';
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';
import { saveAs } from "file-saver";
import CloseIcon from '@mui/icons-material/Close';
import { Divider } from 'antd';
import { iconDownloadCustom } from '../../Assets/Images/svg';
import { useNavigate } from 'react-router-dom';
import {decode as base64_decode, encode as base64_encode} from 'base-64';


const initialState = {
    // titleModal: "Log Details",
    // visible: false,
    // disabled: true,
    // bounds: false,
    // errorMessage: "",
    // errorMessageList: [],
    //
    data: null,
    // //
    // id: "",
    // //
    // modalDetail: false,
    width: 1200,
    height: 600,
};
const reducer = (state: any, action: any) => {
    switch (action.type) {
        case "setData":
            return {
                ...state,
                data: action.value,
                errorMessage: "",
            };
        case "data":
            return {
                ...state,
                data: action.value,
            };
        case "resize":
            return {
                ...state,
                width: action.value.width,
                height: action.value.height,
            };
        default:
            throw new Error();
    }
};



export const AlertHistoryTable = ({ ...props }) => {
    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom,
        handleStateParent,
        handleNavigation,
        setPagination,
        pagination,
    } = props;

    const [open, setOpen] = useState(false);
    const [logUrl, setLogUrl] = useState('');
    const [logName, setLogName] = useState('');
    const [logContent, setLogContent] = useState('');
    const [selectedMessage, setSelectedMessage] = useState('');
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const [modalPosition, setModalPosition] = useState({ x: 10, y: 10 });
    const [dragging, setDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const navigate = useNavigate();

    const exportFile = (fileName: any) => {
        // let element = (document.getElementById(`export-data${itemModal.logName}`) as HTMLFormElement).innerHTML.toString().replace(/<br\>/g, '\n');
        // let element = (document.getElementById(`export-data${itemModal.logName}`) as HTMLFormElement).innerHTML;
        let blob = new Blob([statePage.data], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${fileName}.txt`);
    };
    const handleMouseDown = (e: any) => {
        setDragging(true);
        setOffset({
            x: e.clientX - modalPosition.x,
            y: e.clientY - modalPosition.y,
        });
    };

    const handleMouseMove = (e: any) => {
        if (dragging) {
            setModalPosition({
                x: e.clientX - offset.x,
                y: e.clientY - offset.y,
            });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    // Add event listeners for mouse movement
    React.useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);
    const onResize = (event: any, propsSize: any) => {
        const { node, size, handle } = propsSize;
        if (size.width > 100 && size.height > 100) {
            dispatchStatePage({ type: "resize", value: { width: size.width, height: size.height } });
        }
    };


    const handleOpen = (url: any, urlLink:any) => {
        let encodeUrl = base64_encode(`${urlLink}`);
        // console.log("test", encodeUrl)
        setOpen(true);
        if (url.includes('.log')) {
            API.get({
                bodyCustom: null,
                pathCustom: `alert-history/log/file/${encodeUrl}`, // pathCustom: `dashboard/log/file/devgph230510212925298_20230510_212925_QRCPMPAYMENT_Req.log`,
                selectUrl: urlSelection.log, // use url log
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatchStatePage({ type: 'data', value: response.data.file });
                setLogName(url)
            })
        } else {
            setLogUrl(url);
        }
        // if(set)
    };

    const handleOpenLink = (url: any) => {
        console.log('url', url)
        //     setOpen(false);
        //   navigate(url);
        // if(set)
    };


    const handleClose = () => {
        setOpen(false);
        setLogUrl('');
        setLogContent('');
        dispatchStatePage({ type: 'setData', value: null })

    };
    const handleOpenMessage = (message: any) => {
        setSelectedMessage(message);
        setOpen(true);
    };

    const handleCloseMessage = () => {
        setOpen(false);
        setSelectedMessage('');
    };
    // useEffect(() => {
    //     if (open && logUrl) {
    //         // Fetch the log content when the modal opens
    //         const fetchLogContent = async () => {
    //             try {
    //                 const response = await fetch(logUrl);
    //                 if (!response.ok) {
    //                     throw new Error('Network response was not ok');
    //                 }
    //                 const text = await response.text();
    //                 setLogContent(text);
    //             } catch (error) {
    //                 setLogContent('Error fetching log content: ');
    //             }
    //         };

    //         fetchLogContent();
    //     }
    // }, [open, logUrl]);
    // interface EventType {
    //     label: string;
    //     value: string;
    // }
    // const eventArray = stateOptions.optionsCommonCode702;
    // const catArray = stateOptions.optionsCommonCode701;
    // const alertArray = stateOptions.optionsCommonCode703;

    const columns: any = useMemo(
        () => [
            {
                header: 'Date/Time',
                accessorKey: 'timestamp',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) => {
                    const timestamp = row.original.timestamp;
                    const date = new Date(timestamp);
                    return date.toLocaleString();
                },
            },
            {
                header: 'Server',
                accessorKey: 'svrId',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.svrId)}`,
            },
            {
                header: 'Category',
                accessorKey: 'alertCatName',
                size: 'auto',
                Cell: ({ row }: any) =>
                    //     {
                    //     const alertCatName = row.original.alertCatName;
                    //     const eventLabel = catArray.find((event: EventType) => event.value === alertCatName)?.label || 'Unknown Event';
                    //     return eventLabel;
                    // },
                    `${CheckUndefined(row.original.alertCatName)}`,
            },
            {
                header: 'Event',
                accessorKey: 'alertEventTypeName',
                size: 'auto',
                Cell: ({ row }: any) =>
                    //      {
                    //     const alertEventTypeName = row.original.alertEventTypeName;
                    //     const eventLabel = eventArray.find((event: EventType) => event.value === alertEventTypeName)?.label || 'Unknown Event';
                    //     return eventLabel;
                    // },
                    `${CheckUndefined(row.original.alertEventTypeName)}`,
            },
            {
                header: 'Severity',
                accessorKey: 'alertSeverityName',
                size: 'auto',
                // Cell: ({ row }: any) => {
                //     const alertSeverityName = row.original.alertSeverityName;
                //     const eventLabel = alertArray.find((event: EventType) => event.value === alertSeverityName)?.label || 'Unknown Event';
                //     return eventLabel;
                // },
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.alertSeverityName)}`,
            },
            {
                header: 'Threshold',
                accessorKey: 'alertThres',
                enableSorting: false,
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.alertThres)}`,
            },
            {
                header: 'Message',
                accessorKey: 'alertMsg',
                enableSorting: false,
                size: 'auto',
                Cell: ({ row }: any) => {
                    const alertMsg = row.original.alertMsg;
                    const alertMsgSubString = row.original.alertMsg.substring(0, 50)
                    return (
                        <a
                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'none' }}
                        onClick={() => handleOpenMessage(alertMsg)}
                    >
                        {alertMsgSubString}
                    </a>
                    );
                },
                // `${CheckUndefined(
                //     row.original.alertMsg
                // )}`,
                muiTableBodyCellProps: {
                    style: {
                         cursor: 'pointer'
                    },
                }
            },
            {
                header: 'Log',
                accessorKey: 'logFileUrl',
                enableSorting: false,
                size: 'auto',
                Cell: ({ row }: any) => {
                    // const logUrls = row.original.logFileUrl.split(', ');
                    // return <div style={{ whiteSpace: 'pre-wrap' }}>
                    return <div>
                        {row.original.logFiles.map((url: any) => (url.logFileName.includes('.log') ?
                            <div
                                key={url.logFileName}
                                style={{ color: 'blue', cursor: 'pointer' }}
                                onClick={() => handleOpen(url.logFileName, url.logFileUrl)}
                                className='p-1'
                            >
                                {url.logFileName}
                            </div> : <div className='py-1'>  <a href={url.logFileUrl} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
                                {url.logFileName}
                            </a></div>
                        ))}
                    </div>
                },
                //     {
                //     const logUrl = CheckUndefined(row.original.logFileUrl);
                //     return (
                //         <a href={logUrl} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
                //             {logUrl}
                //         </a>
                //     );
                // },

            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                // onClick: () => handleNavigation(row.original),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        onGlobalFilterChange: (filterValue) =>
            handleStateGlobal('search', filterValue),
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="justify-content-between pt-2">
                <div className="col-6 d-flex align-items-center">
                    <p className="Lato-400 s-1822 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
            </div>
        ),


    });
    let widthWindows_ = window.innerWidth;
    const isMobile = window.innerWidth <= 768;
    return (
        <>
            <div id="table" className="mt-2 mb-5 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />
                </div>
                {logUrl || statePage.data !== null ?
                    <Modal open={open} onClose={handleClose}
                        style={{
                            position: "fixed",
                            top: window.innerWidth < 768 ? "5%" : modalPosition.y, // old value = calc(45% - ${statePage.height / 2}px
                            left: window.innerWidth < 768 ? "5%" : modalPosition.x, // old value = calc(50% - ${statePage.width / 2}px
                            right: window.innerWidth < 768 ? "5%" : `2%`, // old value = calc(50% - ${statePage.width / 2}px
                            height: window.innerWidth < 768 ? "auto" : statePage.height,
                            zIndex: 1000,
                            margin: '0px'
                        }}
                    >
                        <div style={{ paddingRight: '0px', paddingLeft: '10px', background: 'white', borderRadius: '10px' }}>
                            <div style={{ paddingTop: '10px', cursor: 'move' }} onMouseDown={handleMouseDown} >
                                <h5>Log Details</h5>
                            </div>
                            <Divider className="m-0" />
                            <div className='d-flex justify-content-between py-2'>
                                <div>{logName}</div>
                                <div className='d-flex'>
                                    <Tooltip placement="bottom" title={"Download File"}>
                                        <div
                                            className="d-flex flex-column align-items-center justify-content-center  pointer"
                                            style={{
                                                width: window.innerWidth < 768 ? "75%" : "100%",
                                                height: window.innerWidth < 768 ? "75%" : "100%"
                                            }}
                                            onClick={() => exportFile(logName)}
                                        >
                                            {window.innerWidth < 768 ? iconDownloadCustom(13, 13) : iconDownloadCustom(15, 15)}
                                        </div>
                                    </Tooltip>
                                    <Button onClick={handleClose}>  <CloseIcon style={{ fontSize: '15px', borderRadius: 'rounded', color: 'gray', padding: '0px', margin: '0px' }} />
                                    </Button>
                                </div>
                            </div>
                            <Divider className="m-0" />
                            {/* {statePage.data !== "" ? <>{statePage.data}</> : <a href={logUrl} target="_blank" rel="noopener noreferrer">
                            {logUrl}
                        </a>} */}
                            <div style={{ paddingRight: '22px', paddingBottom: '10px' }}>
                                <pre
                                    style={{
                                        height: statePage.height - 60,
                                        overflow: "auto",
                                        fontSize: window.innerWidth < 768 ? "10px" : "12px",
                                    }}
                                >
                                    {`${statePage.data}`}
                                </pre></div>
                        </div>
                    </Modal> :selectedMessage? <Modal open={open} onClose={handleCloseMessage} style={{
                        position: "fixed",
                        top: window.innerWidth < 768 ? "5%" : modalPosition.y, // old value = calc(45% - ${statePage.height / 2}px
                        left: window.innerWidth < 768 ? "5%" : modalPosition.x, // old value = calc(50% - ${statePage.width / 2}px
                        right: window.innerWidth < 768 ? "5%" : `2%`, // old value = calc(50% - ${statePage.width / 2}px
                        height: window.innerWidth < 768 ? "auto" : statePage.height,
                        zIndex: 1000,
                        margin: '0px'
                    }}>
                                     <div style={{ paddingRight: '0px', paddingLeft: '10px', background: 'white', borderRadius: '10px' }}>
                            <Divider className="m-0" />
                            <div className='d-flex justify-content-between py-2'>
                            <div style={{ paddingTop: '10px', cursor: 'move' }} onMouseDown={handleMouseDown} >
                                <h5>Alert Message</h5>
                            </div>
                                    <Button onClick={handleCloseMessage}>  <CloseIcon style={{ fontSize: '15px', borderRadius: 'rounded', color: 'gray', padding: '0px', margin: '0px' }} />
                                    </Button>
                            </div>
                            <Divider className="m-0" />
                            <div style={{ paddingRight: '22px', paddingBottom: '10px' }}>
                                <pre
                                    style={{
                                        height: statePage.height - 60,
                                        overflow: "auto",
                                        fontSize: window.innerWidth < 768 ? "10px" : "12px",
                                    }}
                                >
                                    {selectedMessage}
                                </pre></div>
                        </div>
                    </Modal>:<></>}

            </div>
        </>
    );
};



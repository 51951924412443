import React, { useCallback, useEffect, useReducer } from 'react';
import { DatePicker, DatePickerProps, Divider, TimePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import moment from 'moment';
import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../../Components';
import { API } from '../../../Services';
import { logoutAction } from '../../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleReadOnly, labelStyle } from '../../../Styles';
import { CheckAllFields, CheckInputAZaz09Space } from '../../../Utils';
import { ModelBatchScheduler } from '../Models';
import { loadingAction } from '../../../Store/Loading';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { FooterModalUpdate2 } from '../../../Components/FooterModalUpdate2';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en'; // Ganti dengan lokal yang sesuai (e.g., 'en' for English)
import { Page53Title } from '../../../State/Menu/TitleMenu';
import { urlSelection } from '../../../Services/API';

dayjs.extend(utc);
dayjs.extend(timezone);

const Page3 = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const {
        optionsLogLevelDetailCode,
        optionsBatchProgramStatusDetailCode,
        optionsCommonStatusDetailCode,
        optionsBatchProgramTypeDetailCode,
        optionsBatchFromAPIBatch,
        optionsBatchRegistrationTypeDetailCode,
        optionsBatchSchedulerStatusDetailCode,
        optionsBatchExecutionTypeDetailCode,
        optionsBusinessGroupNameDetailCodeAndCcdName
    } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["batchProgId", "batchStatus", "batchName", "batchType", "batchProgName", "batchLogLevel", "batchDesc"]
        let limit = [15, 3, 15, 3, 50, 3, 200]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (nameState === 'batchProgId') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === 'batchName') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === "batchProgName") {
                let errorMessage = {
                    id: '1',
                    errorMessage: 'BATCH PROGRAM NAME must contain only letters (A-Z) or (a-z), space and numbers (0-9)'
                }
                if (CheckInputAZaz09Space.test(valueState.toString())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            }
            else if (valueState.split('').length <= limit[index]) {
                dispatchStatePage({ type: nameState, value: valueState })
            }

        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const {
            id,
            batchId,
            batchPreRequisite,
            batchStatus,
            batchRegType,
            batchExecutionType,
            batchRerunManuallyDttm,
            batchRunTime,
            batchParameterValue,
            batchGroupPath,
            batchPlannedDttm,
            batchInterval,
            batchBusinessGroup,
        } = statePage.data
        const data = {
            batchDetailId: id,
            batchId: batchId,
            batchPreRequisite: (batchPreRequisite !== null && batchPreRequisite.length > 0) ? batchPreRequisite.toString() : '',
            batchStatus: batchStatus,
            batchRegType: batchRegType,
            batchExecutionType: batchExecutionType,
            // batchRerunManuallyDttm: batchRerunManuallyDttm,
            batchRunTime: batchRunTime,
            batchParameterValue: batchParameterValue,
            batchGroupPath: batchGroupPath,
            batchPlannedDttm: batchPlannedDttm,
            batchInterval: batchInterval,
            batchBusinessGroup: batchBusinessGroup,
        }

        let checkFields: any[] = []
        let checkNameFields: any[] = []
        if (batchExecutionType === '005' || batchExecutionType === '006' || batchExecutionType === '007') {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
                batchInterval
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
                "BATCH INTERVAL",
            ]
        } else {
            checkFields = [
                batchId,
                batchStatus,
                batchRegType,
                batchBusinessGroup,
                batchExecutionType,
                batchRunTime,
            ]
            checkNameFields = [
                "BATCH ID",
                'BATCH STATUS',
                'BATCH REG TYPE',
                'BATCH BUSINESS GROUP',
                'BATCH EXECUTION TYPE',
                "BATCH RUN TIME",
            ]
        }


        const a = CheckAllFields(checkFields, checkNameFields)
        if (statePage.errorMessageList.length > 0) { }
        else {
            if (a !== "") {
                handleState('errorMessage', a);
            } else {
                handleState('errorMessage', ``);
                API.update({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    actionAfterUpdateItem(id)
                    actionShowModal()
                }).catch((err) => handleState('errorMessage', err))
            }
        }

    };

    useEffect(() => {
        let selectedBatchPreRequisite: any[] = []
        if (optionsBatchFromAPIBatch.length > 0) {
            optionsBatchFromAPIBatch.forEach((option: any) => {
                if (statePage.data.batchPreRequisite !== null) {
                    if (typeof statePage.data.batchPreRequisite === 'string') {
                        if (statePage.data.batchPreRequisite.length > 0) {
                            let splitBatchPreRequisite: any[] = []
                            splitBatchPreRequisite = [...statePage.data.batchPreRequisite.toString().split(',')]
                            if (splitBatchPreRequisite.length > 0) {
                                splitBatchPreRequisite.forEach((item: any) => {
                                    if (item === `${option.value}`) {
                                        selectedBatchPreRequisite.push(option)
                                    }
                                })

                            }
                        }
                    } else if (statePage.data.batchPreRequisite instanceof Array) {
                        if (statePage.data.batchPreRequisite.length > 0) {
                            if (statePage.data.batchPreRequisite.includes(option.value)) {
                                selectedBatchPreRequisite.push(option)
                            }
                        }
                    }

                }
            })
            handleState('selectedBatchPreRequisite', selectedBatchPreRequisite)
        }
    }, [statePage.data.batchPreRequisite, optionsBatchFromAPIBatch])



    const onOkBatchRunTime = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            // dispatchStatePage({ type: 'selectedDateBatchRunTime', value: localDate.toString().split(' ')[0] })
            let datNow = new Date();
            let hoursNow = datNow.getHours().toString().padStart(2, '0');
            let minutesNow = datNow.getMinutes().toString().padStart(2, '0');
            let secondsNow = datNow.getSeconds().toString().padStart(2, '0');
            let timeNowNow = `${hoursNow}:${minutesNow}:${secondsNow}`;
            if (timeNow > timeNowNow) {
                let dateStart = new Date();
                dateStart.setDate(dateStart.getDate() - 1)
                let dateStartUsed = dateStart.toISOString().slice(0, 10);
                dispatchStatePage({ type: 'batchRunTime', value: timeNow })
                dispatchStatePage({ type: 'batchPlannedDttm', value: `${dateStartUsed} ${timeNow}` })
            } else {
                let dateStart = new Date();
                let dateStartUsed = dateStart.toISOString().slice(0, 10);
                dispatchStatePage({ type: 'batchRunTime', value: timeNow })
                dispatchStatePage({ type: 'batchPlannedDttm', value: `${dateStartUsed} ${timeNowNow}` })
            }
        }
    };
    const onOkBatchPlannedDttm = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'batchPlannedDttm', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
        }
    };
    const onOkBatchRerunManuallyDttm = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        if (value !== null) {
            let date = new Date(value!.toString());
            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            let timeNow = `${hours}:${minutes}:${seconds}`;
            dispatchStatePage({ type: 'batchRerunManuallyDttm', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
        }
    };
    const ComponentBatchPlannedDttm = useCallback(({ ...props }) => {
        const { statePage } = props;
        return (
            <>
                {
                    dayjs(statePage.data.batchPlannedDttm, ['YYYY-MM-DD HH:mm:ss']).isValid() === true ?
                        <DatePicker
                            defaultValue={dayjs(`${statePage.data.batchPlannedDttm}`, ['YYYY-MM-DD HH:mm:ss'])}
                            className='flex-fill w-100 h-40px'
                            showTime
                            disabled={true}
                        />
                        :
                        <DatePicker
                            className='flex-fill w-100 h-40px'
                            showTime
                            disabled={true}
                        />
                }
            </>
        )
    }, [statePage.data.batchPlannedDttm])
    useEffect(() => {
        let labelSelectedBatchBusinessGroup: string = ''
        let labelSelectedBatchExecutionType: string = ''

        if (optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup).length > 0) {
            labelSelectedBatchBusinessGroup = optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup)[0].label
        }
        if (optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType).length > 0) {
            labelSelectedBatchExecutionType = optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType)[0].label
        }
        handleState('batchGroupPath', (`${labelSelectedBatchBusinessGroup}/${labelSelectedBatchExecutionType}`).toUpperCase())

    }, [statePage.data.batchBusinessGroup, statePage.data.batchExecutionType])

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='' style={{ overflow: 'auto', height: '580px' }}>
                <Divider className='mt-1 mb-1 ' />
                <div className='row m-0 p-0 g-1 mb-5'>
                    <div className='col-12'>
                        <p className={labelStyle}>BATCH PRE REQUISITE</p>
                        <Select
                            className='flex-fill'
                            closeMenuOnSelect={false}
                            isMulti
                            value={statePage.selectedBatchPreRequisite}
                            options={optionsBatchFromAPIBatch}
                            onChange={(items) => {
                                let dataSelected: any[] = [];
                                items.forEach((item: any) => {
                                    dataSelected.push(item.value);

                                })
                                handleState('batchPreRequisite', dataSelected)
                            }}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH ID <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} options={optionsBatchFromAPIBatch} value={optionsBatchFromAPIBatch.filter((option: any) => option.value === statePage.data.batchId)} onChange={(e) => handleState('batchId', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH STATUS <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} options={optionsBatchSchedulerStatusDetailCode} value={optionsBatchSchedulerStatusDetailCode.filter((option: any) => option.value === statePage.data.batchStatus)} onChange={(e) => handleState('batchStatus', e.value)} />
                    </div>


                    <div className='col-6'>
                        <p className={labelStyle}>BATCH BUSINESS GROUP <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} options={optionsBusinessGroupNameDetailCodeAndCcdName} value={optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === statePage.data.batchBusinessGroup)} onChange={(e) => handleState('batchBusinessGroup', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH EXECUTION TYPE  <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} options={optionsBatchExecutionTypeDetailCode} value={optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === statePage.data.batchExecutionType)} onChange={(e) => handleState('batchExecutionType', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH REG TYPE <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} options={optionsBatchRegistrationTypeDetailCode} value={optionsBatchRegistrationTypeDetailCode.filter((option: any) => option.value === statePage.data.batchRegType)} onChange={(e) => handleState('batchRegType', e.value)} />
                    </div>
                    {/* <div className='col-6'>
                        <p className={labelStyle}>BATCH RERUN MANUALLY DTTM</p>
                        {
                            dayjs(statePage.data.batchRerunManuallyDttm, ['YYYY-MM-DD HH:mm:ss']).isValid() === true ?
                                <DatePicker
                                    defaultValue={dayjs(`${statePage.data.batchRerunManuallyDttm}`, ['YYYY-MM-DD HH:mm:ss'])}
                                    className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRerunManuallyDttm}
                                    disabled
                                />
                                :
                                <DatePicker
                                    className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRerunManuallyDttm}
                                    disabled
                                />
                        }
                    </div> */}

                    {/* <div className='col-6'>
                        <p className={labelStyle}>BATCH RUN TIME <span className='text-danger'>*</span></p>
                        {
                            dayjs(statePage.data.batchRunTime, ['YYYY-MM-DD HH:mm:ss']).isValid() === true ?
                                <DatePicker
                                    defaultValue={dayjs(`${statePage.data.batchRunTime}`, ['YYYY-MM-DD HH:mm:ss'])}
                                    className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRunTime}
                                />
                                :
                                <DatePicker
                                    className='flex-fill w-100 h-40px' showTime onOk={onOkBatchRunTime}
                                />
                        }
                    </div> */}
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH RUN TIME <span className='text-danger'>*</span></p>
                        {
                            dayjs(statePage.data.batchRunTime, ['HH:mm:ss']).isValid() === true ?
                                <TimePicker className='h-40px'
                                    defaultValue={dayjs(`${statePage.data.batchRunTime}`, ['HH:mm:ss'])}
                                    onOk={onOkBatchRunTime} 
                                    onChange={onOkBatchRunTime}
                                    />
                                :
                                <TimePicker className='h-40px'
                                    onOk={onOkBatchRunTime} 
                                    onChange={onOkBatchRunTime}
                                    
                                    />

                        }

                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>BATCH PARAMETER VALUE </p>
                        <input value={statePage.data.batchParameterValue} onChange={(e) => handleState('batchParameterValue', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH GROUP PATH </p>
                        <input disabled={true} value={statePage.data.batchGroupPath} className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>BATCH PLANNED DTTM </p>
                        <ComponentBatchPlannedDttm statePage={statePage} />


                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>BATCH INTERVAL
                            {
                                statePage.data.batchExecutionType === '005' || statePage.data.batchExecutionType === '006' || statePage.data.batchExecutionType === '007' ?
                                    <span className='text-danger'>*</span>
                                    :
                                    <></>
                            }
                        </p>
                        <input type='number' value={statePage.data.batchInterval} onChange={(e) => handleState('batchInterval', e.target.value)} className={inputStyle} />
                    </div>
                </div>
                <FooterModalUpdate2 errorMessage={statePage.errorMessage} errorMessageList={statePage.errorMessageList} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page53Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelBatchScheduler({
        id: null,
        batchId: null,
        batchPreRequisite: null,
        batchStatus: null,
        batchLastExec: null,
        batchGroupPath: null,
        createProgId: null,
        updateProgId: null,
        createDttm: null,
        updateDttm: null,
        batchRegType: null,
        batchRerunManuallyDttm: null,
        batchPlannedDttm: null,
        batchRunTime: null,
        batchParameterValue: null,
        batchExecutionType: null,
        batchInterval: null,
        batchBusinessGroup: null,
        flag: null,
        batchDescription: null,
        batchProgId: null,
    }),
    //
    id: '',
    selectedBatchPreRequisite: [],
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelBatchScheduler) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        case 'errorMessageList':
            if (action.value.type === "removed") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                            errorMessageList: state.errorMessageList.filter((item: any) => item.id !== action.value.data.id)
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else if (action.value.type === "added") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                        }
                    } else {

                        let data: any[] = [...state.errorMessageList]
                        data.push(action.value.data)
                        return {
                            ...state,
                            errorMessageList: data
                        }
                    }
                } else {
                    let data: any[] = [...state.errorMessageList]
                    data.push(action.value.data)
                    return {
                        ...state,
                        errorMessageList: data
                    }
                }
            } else {
                return {
                    ...state,
                }
            }

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        //
        case 'selectedBatchPreRequisite':
            return {
                ...state,
                selectedBatchPreRequisite: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelBatchScheduler({
                    id: null,
                    batchId: null,
                    batchPreRequisite: null,
                    batchStatus: null,
                    batchLastExec: null,
                    batchGroupPath: null,
                    createProgId: null,
                    updateProgId: null,
                    createDttm: null,
                    updateDttm: null,
                    batchRegType: null,
                    batchRerunManuallyDttm: null,
                    batchPlannedDttm: null,
                    batchRunTime: null,
                    batchParameterValue: null,
                    batchExecutionType: null,
                    batchInterval: null,
                    batchBusinessGroup: null,
                    flag: null,
                    batchDescription: null,
                    batchProgId: null,
                }),
                errorMessage: "",
                selectedBatchPreRequisite: [],
            };
        case 'setData':
            return {
                ...state,
                data: new ModelBatchScheduler({
                    id: action.value.id,
                    batchId: action.value.batchId,
                    batchPreRequisite: action.value.batchPreRequisite,
                    batchStatus: action.value.batchStatus,
                    batchLastExec: action.value.batchLastExec,
                    batchGroupPath: action.value.batchGroupPath,
                    createProgId: action.value.createProgId,
                    updateProgId: action.value.updateProgId,
                    createDttm: action.value.createDttm,
                    updateDttm: action.value.updateDttm,
                    batchRegType: action.value.batchRegType,
                    batchRerunManuallyDttm: action.value.batchRerunManuallyDttm,
                    batchPlannedDttm: action.value.batchPlannedDttm,
                    batchRunTime: action.value.batchRunTime,
                    batchParameterValue: action.value.batchParameterValue,
                    batchExecutionType: action.value.batchExecutionType,
                    batchInterval: action.value.batchInterval,
                    batchBusinessGroup: action.value.batchBusinessGroup,
                    flag: action.value.flag,
                    batchDescription: action.value.batchDescription,
                    batchProgId: action.value.batchProgId,
                }),
                errorMessage: "",
                selectedBatchPreRequisite: [],
            };
        default:
            throw new Error();
    }
}


export default Page3;




import { API, urlSelection } from '../../../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../../../Components';
import { close, errNotificationIcon, errNotificationIconCustom } from '../../../../Assets/Images/svg';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer, useState } from 'react';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-modal';
import { ModelInterfaceFile } from '../../Utils/ModelInterfaceFile';
import { Page35Title } from '../../../../State/Menu/TitleMenu';
import Select from 'react-select';
import { loadingAction } from '../../../../Store/Loading';
import { logoutAction } from '../../../../Store/User';

export const ModalInterfaceFile = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props

    const {
        optionsInterfaceFileFormatDetailCode,
        optionsFileStringFormatDetailCode,
        optionsFileStringSeparatorDetailCode,
        optionsCommonStatusDetailCode,
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])


    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["interfaceFileId", "interfaceFileName", "interfaceFileFormat", "interfaceFileDesc", "filename_format"]
        let limit = [8, 32, 3, 64, 64]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'interfaceRecSize') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 1000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else if (nameState === 'interfaceRecItemNameLength') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 10000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    const clearForm = () => {
        dispatchStatePage({ type: 'clearData' });
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            interfaceFileId,
            interfaceFileName,
            interfaceFileFormat,
            interfaceStrFormat,
            interfaceRecSize,
            interfaceRecItemNameYn,
            interfaceRecItemNameLength,
            interfaceFileDesc,
            interfaceFileStatus,
            filename_format,
            interfaceFileCreateBy,
            interfaceFileCreateDate,
            interfaceFileUpdateBy,
            interfaceFileUpdateDate,
            interfaceFileDeleteBy,
            interfaceFileDeleteDate,
        } = statePage.data
        const data = {
            interfaceFileId: interfaceFileId,
            interfaceFileName: interfaceFileName,
            interfaceFileFormat: interfaceFileFormat,
            interfaceStrFormat: interfaceStrFormat,
            interfaceRecSize: interfaceRecSize,
            interfaceRecItemNameYn: interfaceRecItemNameYn,
            interfaceRecItemNameLength: interfaceRecItemNameLength,
            interfaceFileDesc: interfaceFileDesc,
            interfaceFileStatus: interfaceFileStatus,
            filename_format: filename_format,
        }
        let a = '';
        if (interfaceRecItemNameYn === false) {
            a = CheckAllFields(
                [
                    interfaceFileId,
                    interfaceFileName,
                    interfaceFileFormat,
                    interfaceStrFormat,
                    interfaceRecSize,
                    interfaceRecItemNameYn,
                    interfaceFileDesc,
                    interfaceFileStatus,
                    filename_format
                ],
                [
                    'INTERFACE FILE ID',
                    'FILE NAME',
                    'FILE FORMAT',
                    'FILE STRING FORMAT',
                    'RECORD SIZE',
                    'RECORD ITEM NAME',
                    'DESCRIPTION',
                    'FILE STATUS',
                    'FILE NAME FORMAT'
                ]
            )
        } else {
            a = CheckAllFields(
                [
                    interfaceFileId,
                    interfaceFileName,
                    interfaceFileFormat,
                    interfaceStrFormat,
                    interfaceRecSize,
                    interfaceRecItemNameYn,
                    interfaceRecItemNameLength,
                    interfaceFileDesc,
                    interfaceFileStatus,
                    filename_format
                ],
                [
                    'INTERFACE FILE ID',
                    'FILE NAME',
                    'FILE FORMAT',
                    'FILE STRING FORMAT',
                    'RECORD SIZE',
                    'RECORD ITEM NAME',
                    'RECORD NAME LENGTH',
                    'DESCRIPTION',
                    'FILE STATUS',
                    'FILE NAME FORMAT'
                ]

            )
        }


        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>Interface File ID</p>
                                <input value={statePage.data.interfaceFileId} onChange={(e) => handleState('interfaceFileId', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>file NAME</p>
                                <input value={statePage.data.interfaceFileName} onChange={(e) => handleState('interfaceFileName', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>file format</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceFileFormatDetailCode} value={optionsInterfaceFileFormatDetailCode.filter((option: any) => option.value === statePage.data.interfaceFileFormat)} onChange={(e) => handleState('interfaceFileFormat', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>file string format</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileStringFormatDetailCode} value={optionsFileStringFormatDetailCode.filter((option: any) => option.value === statePage.data.interfaceStrFormat)} onChange={(e) => handleState('interfaceStrFormat', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>record size</p>
                                <input type='number' min={1} value={statePage.data.interfaceRecSize} onChange={(e) => handleState('interfaceRecSize', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>record item name</p>
                                <BootstrapSwitchButton checked={statePage.data.interfaceRecItemNameYn} size="xs" onChange={(e) => handleState('interfaceRecItemNameYn', e)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>record name length</p>
                                <input type='number' min={1} value={statePage.data.interfaceRecItemNameLength} onChange={(e) => handleState('interfaceRecItemNameLength', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>file status</p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.interfaceFileStatus)} onChange={(e) => handleState('interfaceFileStatus', e.value)} />
                            </div>
                            <div className='col-6'></div>
                            <div className='col-6'>
                                <p className={labelStyle}>File Name Format</p>
                                <input type='text' value={statePage.data.filename_format} onChange={(e) => handleState('filename_format', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.data.interfaceFileDesc} onChange={(e) => handleState('interfaceFileDesc', e.target.value)} className={inputDescriptionStyle} />
                            </div>

                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>
            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page35Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInterfaceFile({
        id: null,
        interfaceFileId: null,
        interfaceFileName: null,
        interfaceFileFormat: null,
        interfaceStrFormat: null,
        interfaceRecSize: 1,
        interfaceRecItemNameYn: false,
        interfaceRecItemNameLength: null,
        interfaceFileDesc: null,
        interfaceFileStatus: null,
        interfaceFileCreateBy: null,
        interfaceFileCreateDate: null,
        interfaceFileUpdateBy: null,
        interfaceFileUpdateDate: null,
        interfaceFileDeleteBy: null,
        interfaceFileDeleteDate: null,
        filename_format: null
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: "",
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceFile) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceFile({
                    id: null,
                    interfaceFileId: null,
                    interfaceFileName: null,
                    interfaceFileFormat: null,
                    interfaceStrFormat: null,
                    interfaceRecSize: 1,
                    interfaceRecItemNameYn: false,
                    interfaceRecItemNameLength: null,
                    interfaceFileDesc: null,
                    interfaceFileStatus: null,
                    interfaceFileCreateBy: null,
                    interfaceFileCreateDate: null,
                    interfaceFileUpdateBy: null,
                    interfaceFileUpdateDate: null,
                    interfaceFileDeleteBy: null,
                    interfaceFileDeleteDate: null,
                    filename_format: null
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceFile({
                    id: action.value.interfaceFileId,
                    interfaceFileId: action.value.interfaceFileId,
                    interfaceFileName: action.value.interfaceFileName,
                    interfaceFileFormat: action.value.interfaceFileFormat,
                    interfaceStrFormat: action.value.interfaceStrFormat,
                    interfaceRecSize: action.value.interfaceRecSize,
                    interfaceRecItemNameYn: action.value.interfaceRecItemNameYn,
                    interfaceRecItemNameLength: action.value.interfaceRecItemNameLength,
                    interfaceFileDesc: action.value.interfaceFileDesc,
                    interfaceFileStatus: action.value.interfaceFileStatus,
                    interfaceFileCreateBy: action.value.interfaceFileCreateBy,
                    interfaceFileCreateDate: action.value.interfaceFileCreateDate,
                    interfaceFileUpdateBy: action.value.interfaceFileUpdateBy,
                    interfaceFileUpdateDate: action.value.interfaceFileUpdateDate,
                    interfaceFileDeleteBy: action.value.interfaceFileDeleteBy,
                    interfaceFileDeleteDate: action.value.interfaceFileDeleteDate,
                    filename_format: action.value.filename_format
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

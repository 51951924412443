import {
    CheckUndefined,
    ExportCSV
} from "../../../Utils";

const ExcelFee = (state: any, stateOptions: any, dataFromAPI : any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showFeeType = '';
        let showCommonStatus = '';
        let showMember = '';
        if (stateOptions.optionsFeeRateInputTypeDetailCode.length > 0) {
            stateOptions.optionsFeeRateInputTypeDetailCode.forEach((element2: any) => {
                if (element2.value === item.feeType) {
                    showFeeType = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.feeStatus) {
                    showCommonStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            feeId: CheckUndefined(item.feeId),
            feeType: CheckUndefined(showFeeType),
            feeStatus: CheckUndefined(showCommonStatus),
            feeDesc: CheckUndefined(item.feeDesc),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, 'Fee')
};
const ExcelMemberFee = (state: any, stateOptions: any, dataFromAPI : any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showCommonStatus = '';
        let showMember = '';
        let showFeeCalculationMethod = '';
        let showFee = '';
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.mfeeStatus) {
                    showCommonStatus = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsMember.length > 0) {
            stateOptions.optionsMember.forEach((element2: any) => {
                if (element2.value === item.memberId) {
                    showMember = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsFeeCalculationMethod.length > 0) {
            stateOptions.optionsFeeCalculationMethod.forEach((element2: any) => {
                if (element2.value === item.mfeeCalcType) {
                    showFeeCalculationMethod = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsFee.length > 0) {
            stateOptions.optionsFee.forEach((element2: any) => {
                if (element2.value === item.feeId) {
                    showFee = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            feeId: CheckUndefined(showFee),
            mfeeRate: CheckUndefined(item.mfeeRate),
            mfeeStartDate: CheckUndefined(item.mfeeStartDate),
            mfeeEndDate: CheckUndefined(item.mfeeEndDate),
            mfeeStatus: CheckUndefined(showCommonStatus),
            mfeeCalcType: CheckUndefined(showFeeCalculationMethod),
            memberId: CheckUndefined(showMember),
            mfeeDesc: CheckUndefined(item.mfeeDesc),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, 'Member Fee')
};

const ExcelExchangeRateSetting = (state: any, stateOptions: any, dataFromAPI : any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showCommonStatus = '';
        let showRateId = '';

        if (stateOptions.optionsRateSetting.length > 0) {
            stateOptions.optionsRateSetting.forEach((element2: any) => {
                if (element2.value === item.rateId) {
                    showRateId = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.rateStatus) {
                    showCommonStatus = `${element2.label}`;
                }
            });
        }

        const m = {
            no: i + 1,
            showRateId: CheckUndefined(showRateId),
            rateFromCurr: CheckUndefined(item.rateFromCurr),
            rateToCurr: CheckUndefined(item.rateToCurr),
            rateValue: CheckUndefined(item.rateValue),
            rateStatus: CheckUndefined(showCommonStatus),
            rateSrcMethod: CheckUndefined(item.rateSrcMethod),
            rateSrcName: CheckUndefined(item.rateSrcName),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, 'Exchange Rate Setting')
};
const ExcelExchangeRateMember = (state: any, stateOptions: any, dataFromAPI : any) => {
    let newList: any[] = []
    let listData: any[] = []
    dataFromAPI.map((item: any, i: any) => {
        let showRateId = '';
        let showMemberId = '';
        let showRatemStatus = '';

        if (stateOptions.optionsRateSetting.length > 0) {
            stateOptions.optionsRateSetting.forEach((element2: any) => {
                if (element2.value === item.rateId) {
                    showRateId = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsMember.length > 0) {
            stateOptions.optionsMember.forEach((element2: any) => {
                if (element2.value === item.memberId) {
                    showMemberId = `${element2.label}`;
                }
            });
        }
        if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
            stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                if (element2.value === item.ratemStatus) {
                    showRatemStatus = `${element2.label}`;
                }
            });
        }
        const m = {
            no: i + 1,
            showRateId: CheckUndefined(showRateId),
            memberId: CheckUndefined(showMemberId),
            ratemStatus: CheckUndefined(showRatemStatus),
            ratemStartDate: CheckUndefined(item.ratemStartDate),
            ratemEndDate: CheckUndefined(item.ratemEndDate),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, 'Exchange Rate Member')
};

export {
    ExcelExchangeRateMember,
    ExcelExchangeRateSetting,
    ExcelFee,
    ExcelMemberFee
}


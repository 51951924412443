import {
    CheckUndefined,
    CheckUndefinedStringEmpty,
    ExportCSV,
    NumberWithCommas
} from "../../Utils";
export const ExcelSettlementSummaryDetail = (state: any, type: string, stateOptions: any) => {
    let newList: any[] = []
    let listData: any[] = []
    state.data.map((item: any, i: any) => {

        let instName = '';
        if (type == 'issuerId') { instName = CheckUndefined(item.issName); }
        else if (type == 'acquirerId') { instName = CheckUndefined(item.acqName); }
        else if (type == 'acquirerSwitchId') { instName = CheckUndefined(item.acqSwitchName); }
        else if (type == 'issuerSwitchId') { instName = CheckUndefined(item.issSwitchName); }

        const m = {
            no: i + 1,
            settleDate: CheckUndefined(item.settleDate),
            authDate: CheckUndefined(item.authDate),
            authStan: CheckUndefined(item.authStan),
            authRrn: CheckUndefined(item.authRrn),
            authInvNo: CheckUndefined(item.authInvNo),
            authFeeAmount: `${CheckUndefinedStringEmpty(item.authCurrCode)} ${NumberWithCommas(CheckUndefined(item.authFeeAmount))}`,
            authAmount: `${CheckUndefinedStringEmpty(item.authCurrCode)} ${NumberWithCommas(CheckUndefined(item.authAmount))}`,
            dynamicColumn: instName,
            reconStatus: CheckUndefined(item.reconStatus),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        let instLabel = '';
        if (type == 'issuerId') { instLabel = 'Issuer'; }
        else if (type == 'acquirerId') { instLabel = 'Acquirer'; }
        else if (type == 'acquirerSwitchId') { instLabel = 'Acquirer Switcher'; }
        else if (type == 'issuerSwitchId') { instLabel = 'Issuer Switcher'; }

        if (state.toggle.includes(item)) {
            let columnLabel = state.list_column_name[state.complete_toggle.indexOf(item)];
            if (columnLabel == 'dynamicColumn') {
                newList.push(instLabel)
            } else {
                newList.push(columnLabel)

            }
        }
    })
    ExportCSV(newList, listData, 'Settlement Summary Detail')
};
import { StylesConfig } from 'react-select';

export const labelStyle = 'c-54 text-uppercase s-1214 w-100 my-1';
export const labelStyleW300PX = 'c-54 text-uppercase s-1214 w-300px my-2';
export const labelStyleW400PX = 'c-54 text-uppercase s-1214 w-400px my-2';
export const labelStyleW200PX =
    'c-54 text-uppercase s-1214 w-200px my-2 hg-40px d-flex align-items-center';
export const labelStyleW250PX =
    'c-54 text-uppercase s-1214 w-250px my-2 hg-40px d-flex align-items-center';
export const labelStyleW200PXWithoutUppercase =
    'c-54 s-1214 w-200px my-2 hg-40px d-flex align-items-center';
export const labelStyleWwithoutUppercase = 'c-54 s-1214 w-100 my-2';

export const inputStyle =
    'bd-d4 bg-ff br-5 h-25px px-2 w-100 s-1418 Lato-400 out-none';
export const inputStyle2 =
    'bd-d4 bg-ff br-5 h-50px px-2 w-100 s-1418 Lato-400 out-none';
export const inputStyleWithoutW100 =
    'bd-d4 bg-ff br-5 h-25px px-2 s-1418 Lato-400 out-none';
export const inputStyleWithoutW100ReadOnly =
    'bd-d4 bg-f4 br-5 h-25px px-2 s-1418 Lato-400 out-none';
    export const inputStyleReadOnlyH25 =
        'bd-d4 bg-f4 br-5 h-25px px-2 w-100 s-1418 Lato-400 out-none';
export const inputStyleReadOnly =
    'bd-d4 bg-f4 br-5 h-25px px-2 w-100 s-1418 Lato-400 out-none';
export const inputDescriptionStyle =
    'bd-d4 bg-ff br-5 w-100 s-1418 Lato-400 out-none px-2 py-2';
export const inputDescriptionStyleReadOnly =
    'bd-d4 bg-f4 br-5 w-100 s-1418 Lato-400 out-none px-2 py-2';

export const inputStyleH25: StylesConfig<any, false> = {
    control: (provided) => ({
        ...provided,
        minHeight: '25px',
        height: '25px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
    }),
    input: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    singleValue: (provided) => ({
        ...provided,
        paddingBottom: '2px'
    }),
    placeholder: (provided) => ({
        ...provided,
        paddingBottom: '2px'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        minHeight: '1px',
        height: '10px',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '12px',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        display: 'flex',
        alignItems: 'center'
    }),

    menu: (provided) => ({
        ...provided,
        zIndex: 100,
    }),
};

export const inputStyleH25Multi: StylesConfig<StylesConfig, true> = {
    control: (provided) => ({
        ...provided,
        minHeight: '25px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
    }),
    input: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        paddingTop: '0',
        paddingBottom: '0',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '25px',
        padding: '0 8px',
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '12px',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        display: 'flex',
        alignItems: 'center',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 100, 
    }),
};

export const inputStyleH20: StylesConfig<StylesConfig, false> = {
    control: (provided) => ({
        ...provided,
        height: '18px',
        minHeight: '18px',
        fontSize: '10px',
        whiteSpace: 'nowrap',
        paddingTop: '0',
        paddingBottom: '0',
    }),
    input: (provided) => ({
        ...provided,
        minHeight: '1px',
        padding: '0',
        margin: '0',
        alignItems: 'center', 
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        height: '18px',
        minHeight: '1px',
        paddingTop: '0',
        paddingBottom: '0',
        display: 'flex',
        alignItems: 'center',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        minHeight: '1px',
        height: '10px',
        alignItems: 'center',
        marginTop: '4px',
        marginLeft: '1px',
        marginRight: '1px'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        minHeight: '1px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '18px',
        paddingTop: '0',
        paddingBottom: '0',
        display: 'flex',
        alignItems: 'center', 
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '16px',
        alignItems: 'center',
    }),
    // option: (provided) => ({
    //     ...provided,
    //     fontSize: '12px',
    //     height: '30px',
    //     display: 'flex',
    //     alignItems: 'center',
    //     whiteSpace: 'nowrap',
    // }),
    option: (provided) => ({
        ...provided,
        fontSize: '10px',
        whiteSpace: 'normal',    
        wordWrap: 'break-word',
        display: 'flex',          
        alignItems: 'center'   
      }),
  
      menu: (provided) => ({
        ...provided,
        zIndex: 100,
      }),
};
import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { useEffect, useReducer } from "react";

import { API } from "../../Services";
import { Modal } from "antd";
import { initFunction, reducerOptions, stateOptions, stateSelected } from "../../State";
import { urlSelection } from "../../Services/API";
import { useDispatch } from "react-redux";
import { InterfaceOptionsDetailsMonitoringStatus } from "../Dashboard/InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { ModelDetailAdjustmentList } from "./ModelDetailAdjustmentList";

export const ModalAdjustmentListDetail = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
  const { stateParent, dispatchParent, modalConditionDetail, setModal } = props;

  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    if (stateParent.invNo !== '') {
      API.get({
        bodyCustom: null,
        pathCustom: `settlement/adjustment/d?invNo=${stateParent.invNo}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        handleState('data', response.data)
      })
    }

  }

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [modalConditionDetail])

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [])

  const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
  const data = state.allData
  if (state.data === null) return <></>
 
  const rightTable = () => {
    return (
      <>
        <div className="px-2 m-0 rounded py-2 w-auto">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column" style={{marginRight:'4px'}}>
              {data.slice(0, Math.ceil(data.length / 2)).map((item: any) => (Object.entries(item).map(([key, value]) => {
                // let count= data.length
                // console.log()
                return (
                  <div className="d-flex bg-f4  border mb-1" >
                    <div className=" w-200px d-flex align-items-center border-0  s-1214 p-2 bg-fff Lato-700 " style={{height:'45px'}}>{CheckUndefined(key)}</div>
                    <div className=" w-500px d-flex  align-items-center p-2 s-1214 ">{CheckUndefined(value)}</div>
                  </div>
                )
              })))}
            </div>
            <div className="d-flex flex-column">
            {data.slice(Math.floor(data.length / 2)).map((item: any) => (Object.entries(item).map(([key, value]) => {
              // let count= data.length
              // console.log()
              return(
                <div className="d-flex bg-f4  border mb-1" >
                  <div className=" w-200px  d-flex align-items-center border-0  s-1214  p-2 bg-fff Lato-700 " style={{height:'45px'}}>{CheckUndefined(key)}</div>
                  <div className=" w-500px d-flex  align-items-center p-2 s-1214 ">{CheckUndefined(value)}</div>
                </div>
              )
            })))}
          </div>
          </div>
        </div>
      </>
    );
  };
  let widthWindows_ = window.innerWidth;


  return (
    <>
      <Modal
        open={modalConditionDetail}
        onCancel={(e) => setModal("modalConditionDetail")}
        maskClosable={false}
        mask={true}
        maskStyle={{
          zIndex: 15,
        }}
        style={{
          position: "fixed",
          overflow: "hidden",
          // right: 'auto',
          // bottom: 'auto',
          // top: '50%',
          // left: '50%',
          // marginRight: '-30%',
          // transform: 'translate(-50%, -50%)',
          //
          right: "1%",
          bottom: "auto",
          top: "2%",
          left: "1%",
          minWidth: "min-content",
          zIndex: 90,
        }}
        bodyStyle={{
          maxHeight: '80vh',
          maxWidth: '100vw',
        }}
        footer={false}
        title={state.titleModal}
      >
        <div className="">
          <div style={{ paddingRight: "10px" }}>
            <div className="d-flex align-items-center m-2 ">
              <p className="w-700 Lato-700 c-29 s-1620">Source Detail</p>
            </div>
            <div className="d-flex ">
              <div className="flex-fill">
                <div className="d-flex flex-column wd-webkit">
                  <div className="d-flex wrap  ">
                    <div className="row  w-100 p-0">
                      {rightTable()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal>

      {/* <ModalDetailUnmatchedList detailModal={state.detailModal} setModal={() => dispatch({ type: "detailModal", value: !state.detailModal })} dispatchFirstParent={dispatchParent} stateFirstParent={stateParent} stateParent={state} stateOptions={stateOptions} /> */}
    </>
  );
};
interface test {
  data: ModelDetailAdjustmentList | null
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: test = {
  data: null
}

function reducer(state: any, action: any) {

  switch (action.type) {
    case 'data':
      return {
        ...state,
        allData: action.value,
        data: {
          invNo: action.value.invNo,
          adjType: action.value.adjType,
          sttlDate: action.value.sttlDate,
          replyDateTime: action.value.replyDateTime,
          transDateTime: action.value.transDateTime,
          transType: action.value.transType,
          direction: action.value.direction,
          srcInst: action.value.srcInst,
          dstInst: action.value.dstInst,
          curr: action.value.curr,
          amt: action.value.amt,
          frmAccNo: action.value.frmAccNo,
          toAccNo: action.value.toAccNo,
          originatorId: action.value.originatorId,
          receipientId: action.value.receipientId,
          sttlBank: action.value.sttlBank,
        }
      };

    default:
      throw new Error();
  }
}

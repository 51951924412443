import { useEffect, useMemo, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleState } from "../../State";
import { API } from "../../Services";
import { urlSelection } from "../../Services/API";
import { CheckUndefined } from "../../Utils";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { SelectView } from "../../Components";
import { FilterFileTransferMonitoring } from "./Components/FilterFileTransferMonitring";

export const FileTransferMonitoring = ({...props}) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: state.view,
    });
    const handleStateComponent = (nameAction: any, valueAction: any) =>
        handleState(nameAction, valueAction, dispatch);

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let procDate = state.procDate !== '' ? `procDate=${state.procDate}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${limit}${offset}${procDate}`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response?.data });
                dispatch({ type: 'totalPages', value: response?.detail.totalPage });
                dispatch({ type: 'totalData', value: response?.detail.total });
                /* Refresh Page */
                setPagination({pageSize: state.view, pageIndex: 0})
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response?.data });
                dispatch({ type: 'totalPages', value: response?.detail.totalPage });
                dispatch({ type: 'totalData', value: response?.detail.total });
            })
        }
    }

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess, state.privilegeAccess.view, navigate])

    useEffect(() => {
        if (state.view !== pagination.pageSize) {
            initFunctionCustom(true);
            setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
        } else {
            initFunctionCustom(false);
        }
    }, [pagination.pageIndex, state.view]);
    
    
    const columns : any= useMemo(
        () => [
            {
                header: '#',
                accessorKey: 'no',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.no)}`,
            },
            {
                header: 'Date',
                accessorKey: 'date',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.date)}`,
            },
            {
                header: 'Acquirer Switcher',
                accessorKey: 'acqSwitcher',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.acqSwitcher)}`,
            },
            {
                header: 'Issuer Switcher',
                accessorKey: 'issSwitcher',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.issSwitcher)}`,
            },
            {
                header: 'Job',
                accessorKey: 'job',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.job)}`,
            },
            {
                header: 'Job Description',
                accessorKey: 'jobDesc',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.jobDesc)}`,
            },
            {
                header: 'File Name',
                accessorKey: 'fileName',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.fileName)}`,
            },
            {
                header: 'Rcv/Snd',
                accessorKey: 'reveiceSendYn',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.reveiceSendYn)}`,
            },
            {
                header: 'Processed Time',
                accessorKey: 'procTime',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.procTime)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
                
            },
            {
                header: 'Fetch Count',
                accessorKey: 'fetchCount',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.fetchCount)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
            {
                header: 'Counter Part',
                accessorKey: 'counterPart',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.counterPart)}`,
            },
            {
                header: 'Cutoff',
                accessorKey: 'cutoff',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.cutoff)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                    },
                }
            },
            {
                header: 'Status',
                accessorKey: 'status',
                size: 'auto',
                Cell: ({ row }: any) => {
                    return (
                    <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            {CheckUndefined(row.original.status)}
                    </div>
                    )

                },
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '35px',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word', 
                    },
                }
            },
        ],
        []
    );
    
    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: false,
        manualPagination: true,
        enableSorting:false,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        paginationDisplayMode: 'pages',
        enableGlobalFilter: false,
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                hover: false,
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        renderTopToolbarCustomActions: ({ table }) => (
            <div className="justify-content-between pt-2">
                <div className="col-6 d-flex align-items-center">
                    <p className="Lato-400 s-1822 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
            </div>
        ),


    });

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                File Transfer Monitoring
            </h1>
            <div id="filter" className="mt-2">
                <FilterFileTransferMonitoring
                    state={state}
                    handleStateParent={handleStateComponent}
                    initFunctionCustom={initFunctionCustom}
                />
            </div>
            <div id="table" className="mt-2 mb-5 position-relative">
                <MaterialReactTable table={table}  />
                <div className='position-absolute' style={{bottom:'12px'}}>
                    <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />      
                </div>
            </div>
        </>
    )
}

const initialState = {
    urlApi: 'interface_file/file-transfer-list',
    titlePage: 'File Transfer Monitoring',
    privilegeAccess: { view: '1021', add: '', edit: '', delete: '', download: '' },
    id: '',
    data: [],
    search: '',
    view: 15,
    position: 15,
    list_view: [10, 15, 20, 30, 40, 50],
    totalData: 0, 
    totalPages: 0,
    currentPage: 0,
    dataLength: 0,
    procDate: new Date().toISOString().slice(0, 10),
};

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'procDate':
            return {
                ...state,
                procDate: action.value
            };
        default:
            throw new Error();
    }
}
import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../../Components';
import { API } from '../../../Services';
import { logoutAction } from '../../../Store/User';
import { inputDescriptionStyle, inputDescriptionStyleReadOnly, inputStyle, inputStyleReadOnly, labelStyle } from '../../../Styles';
import { CheckAllFields } from '../../../Utils';
import { ModelInstitutionCode } from '../Detail/Models/ModelInstitutionCode';
import { loadingAction } from '../../../Store/Loading';
import { urlSelection } from '../../../Services/API';

export const ModalDraggableInstitutionCode = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi, codeMember, getData } = props
    const { optionsGroupCode, optionsMember, optionsInstitutionCode200, optionsInstitutionCode201, optionsInstitutionCode202, optionsInstitutionCode203 } = props.stateOptions


    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    useEffect(() => {
        statePage.data.instCcdCodeName = ''
        statePage.data.instCcdCode = ''
        statePage.data.memberInstDesc = ''
        dispatchStatePage({
            type: "descSelected",
            value: '',
        })
        dispatchStatePage({
            type: "nameSelected",
            value: '',
        })
    }, [statePage.data.instCcgCode])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { instCcgCode, instCcdCode, memberInstDesc, errorMessage } = statePage.data
        const data = {
            memberId: codeMember,
            instCcgCode: instCcgCode,
            instCcdCode: instCcdCode,
            memberInstCode: statePage.data.memberInstCode,
            memberInstDesc: memberInstDesc,
            memberInstCodeId: id,
        }
        const a = CheckAllFields(
            [instCcgCode, instCcdCode, memberInstDesc],
            ['GROUP CODE', 'CODE NAME', 'DESCRIPTION']
        );

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    //new optionsGroupCode
    let groupList: any = [];
    if (optionsGroupCode.length > 0) {
        optionsGroupCode.forEach((data: any) => {
            if (["COM200", "COM201", "COM202", "COM203"].includes(data.value)) {
                groupList.push({
                    value: data.value,
                    label: data.label
                });
            }
        });
    }

    useEffect(() => {
        if (statePage.instCcgCode !== null) {
            let selectedCcgCode = statePage.data.instCcgCode === '' ? '' : `ccgCode=${statePage.data.instCcgCode}&`;
            let selectedMemberId = codeMember === '' ? '' : `memberId=${codeMember}&`;
            let finalParameter = `${selectedMemberId}${selectedCcgCode}`;
            API.get({
                bodyCustom: null,
                pathCustom: `member_inst_code/code_name?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                let listDataNameAPI: any = []
                if (response.data.length > 0) {
                    response.data.forEach((data: any) => {
                        listDataNameAPI.push({
                            label: `${data.ccdExt1Val} - ${data.ccdName}`,
                            value: data.ccdCode,
                            desc: data.ccdDesc,
                            name: data.ccdName,
                            ccgName: data.ccgName,
                        });
                    });
                }
                dispatchStatePage({ type: 'nameList', value: listDataNameAPI })
            }).catch((err) => handleState('errorMessage', err))
        }
    }, [statePage.data.instCcgCode, statePage.descSelected, statePage.nameSelected])

    useEffect(() => {
        if (statePage.data.memberInstCodeId !== null) {
            let dataTerpilih = [];

            const getSameData = getData.some((item: any) => item.memberInstCodeId === statePage.data.memberInstCodeId);
            if (getSameData) {
                dataTerpilih = getData.filter((item: any) => item.memberInstCodeId === statePage.data.memberInstCodeId);
            }
            dispatchStatePage({
                type: "dataInsCode",
                value: dataTerpilih,
            });
        }
    }, [statePage.data.memberInstCodeId]);

    useEffect(() => {
        if (statePage.dataInsCode && statePage.dataInsCode.length > 0) {
            const selectedInstCcdCodeName = {
                label: `${statePage.dataInsCode[0].memberBcapCode} - ${statePage.dataInsCode[0].instCcdCodeName}`,
                value: statePage.dataInsCode[0].instCcdCode,
                desc: statePage.dataInsCode[0].memberInstDesc,
                name: statePage.dataInsCode[0].instCcdCodeName,
                ccgName: statePage.dataInsCode[0].instCcgCode,
            }
            dispatchStatePage({
                type: "selectedInstCcdCodeName",
                value: selectedInstCcdCodeName,
            })
            dispatchStatePage({
                type: "descSelected",
                value: statePage.dataInsCode[0].memberInstDesc,
            })
            dispatchStatePage({
                type: "nameSelected",
                value: statePage.dataInsCode[0].instCcdCodeName,
            })
        }
    }, [statePage.dataInsCode]);


    useEffect(() => {
        if (statePage.dataInsCode && statePage.dataInsCode.length > 0) {
            const shouldAddName = statePage.data.instCcgCode === statePage.dataInsCode[0].instCcgCode
            if (shouldAddName) {
                statePage.nameList.unshift(statePage.selectedInstCcdCodeName);
            } else {
                if (statePage.data.instCcdCode[0] && statePage.data.instCcdCode[0].length > 0) {
                    const indexToRemove = statePage.nameList.find((item: any) => item.name === statePage.data.instCcdCode[0].instCcdCodeName);
                    if (indexToRemove) {
                        statePage.nameList.splice(indexToRemove, 1);
                    }
                }
            }
        }
    }, [statePage.nameList]);

    useEffect(() => {
        if (statePage.nameList.length > 0) {
            statePage.nameList.forEach((item: any) => {
                if (item && item.value === statePage.data.instCcdCode) {
                    dispatchStatePage({
                        type: "descSelected",
                        value: item.desc,
                    })
                    dispatchStatePage({
                        type: "nameSelected",
                        value: item.name,
                    })
                }
            });
        }
    }, [statePage.data.instCcdCode, statePage.nameList])

    //isi data
    statePage.data.memberInstDesc = statePage.descSelected


    let selectedOption: any = []
    if (statePage.nameList.length > 0) {
        statePage.nameList.forEach((item: any) => {
            if (item && item.value === statePage.data.instCcdCode) {
                selectedOption = {
                    value: item.value,
                    label: item.label
                }
            }
        });
    }

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>Edit Institution Code</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>GROUP CODE <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} options={groupList} value={groupList.find((option: any) => option.value === statePage.data.instCcgCode)} onChange={(e) => handleState('instCcgCode', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>CODE NAME <span className='text-danger'>*</span></p>
                        <Select
                            options={statePage.nameList}
                            value={selectedOption ? selectedOption : null}
                            onChange={(e) => handleState('instCcdCode', e.value)}
                        />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>MEMBER INSTITUTION CODE</p>
                        <input type='string' value={statePage.data.memberInstCode} onChange={(e) => handleState('memberInstCode', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Code Name</p>
                        <input disabled={true}
                            value={statePage.nameSelected} onChange={(e) => handleState('instCcdCodeName', e.target.value)} className={inputStyleReadOnly} />
                    </div>
                    <div className="col-12">
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} disabled={true} placeholder={statePage.data.memberInstDesc} value={statePage.descSelected} onChange={(e) => handleState("memberInstDesc", e.target.value)} className={inputDescriptionStyleReadOnly} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );
}

const initialState = {
    titleModal: `Edit`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelInstitutionCode({
        id: null,
        memberInstCodeId: null,
        memberId: null,
        instCcgCode: null,
        instCcdCode: null,
        instCcdCodeName: null,
        memberBcapCode: null,
        memberInstDesc: null,
        memberInstCode: null,

    }),
    //
    id: '',
    nameList: [],
    descSelected: '',
    nameSelected: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInstitutionCode) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        case 'nameList':
            return {
                ...state,
                nameList: action.value,
            }
        case 'descSelected':
            return {
                ...state,
                descSelected: action.value,
            }
        case 'nameSelected':
            return {
                ...state,
                nameSelected: action.value,
            }
        case 'selectedInstCcdCodeName':
            return {
                ...state,
                selectedInstCcdCodeName: action.value,
            }
        case 'dataInsCode':
            return {
                ...state,
                dataInsCode: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInstitutionCode({
                    id: null,
                    memberInstCodeId: null,
                    memberId: null,
                    instCcgCode: null,
                    instCcdCode: null,
                    instCcdCodeName: null,
                    memberBcapCode: null,
                    memberInstDesc: null,
                    memberInstCode: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInstitutionCode({
                    id: action.value.memberInstCodeId,
                    memberInstCodeId: action.value.memberInstCodeId,
                    memberId: action.value.memberId,
                    instCcgCode: action.value.instCcgCode,
                    instCcdCode: action.value.instCcdCode,
                    instCcdCodeName: action.value.instCcdCodeName,
                    memberBcapCode: action.value.memberBcapCode,
                    memberInstDesc: action.value.memberInstDesc,
                    memberInstCode: action.value.memberInstCode,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}
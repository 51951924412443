
import { Divider } from 'antd';
import { useState, useEffect, useReducer } from 'react'
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';


const initialState = {
    name: 'septian',
    age: '23',
    school: "SMA DIPONONOGRO",
    address: 'Malang'
}
const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'name':
            return {
                ...state,
                name: action.value
            };
        case 'age':
            return {
                ...state,
                age: action.value
            };
        case 'school':
            return {
                ...state,
                school: action.value
            };
        case 'address':
            return {
                ...state,
                address: action.value
            };
        default:
            throw new Error();

    }
}


const TestingComponent = ({...props}) => {
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const [data, setData] = useState([]);

    const initFunction = async () => {
        const response = await API.get({
            bodyCustom: null,
            pathCustom: `member`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        })
        const dataAPI = response.data;
        setData(dataAPI);
    }

    useEffect(() => {
        // console.log('dijalankan berulang kali')
    },)


    useEffect(() => {
        initFunction();
    }, [])


    useEffect(() => {
        // console.log('dijalankan sekali ada perubahan pada nilai nama')
    }, [statePage.name])

    if (statePage.name === '') {
        return (
            <>
                <button
                    className='btn btn-danger'
                    onClick={() => {
                        dispatchStatePage({ type: 'name', value: 'Andi' })
                    }} >
                    merubah nama
                </button>
            </>
        )
    }

    return (
        <>
            {
                data.length > 0 &&
                data.map((item: any) => {
                    return (
                        <>
                            <Divider />
                            <div>
                                {item.countryCode}
                                {item.countryName}
                            </div>
                            <Divider />

                        </>
                    )
                })
            }
            {statePage.name}
            <Divider />
            {statePage.age}
            <Divider />
            {statePage.school}
            <Divider />
            {statePage.address}
            <Divider />
            <button
                className='btn btn-danger'
                onClick={() => {
                    dispatchStatePage({ type: 'name', value: 'Andi' })
                    dispatchStatePage({ type: 'age', value: 'Andi' })
                    dispatchStatePage({ type: 'school', value: 'Andi' })
                    dispatchStatePage({ type: 'address', value: 'Andi' })
                }} >
                merubah nama
            </button>

        </>

    )
}

export default TestingComponent;


// SchedulerExecution

interface SchedulerExecution {
    id: null | number | string;
    batchExecTypeCode: number | null;
    batchExecType: string | null;
    batchGroupPath: string | null;
    batchJobDetailId: number | null;
    batchLastExec: string | null;
    batchMessagge: string | null;
    batchParameterValue: string | null;
    batchProgId: string | null;
    batchRegTypeCode: string | null;
    batchRegType: string | null;
    batchStatusCode: string | null;
    batchStatus: string | null;
    chngDttm: string | null;
    chngProgId: string | null;
    chngUserId: string | null;
    inpDttm: string | null;
    inpProgId: string | null;
    inpUserId: string | null;
    batchLogId: number | null;
    batchLogName: string | null;
    count: number | null;
    batchTxDate: number | null;
}
    

class ModelSchedulerExecution {
    id: null | number | string;
    batchExecTypeCode: number | null;
    batchExecType: string | null;
    batchGroupPath: string | null;
    batchJobDetailId: number | null;
    batchLastExec: string | null;
    batchMessagge: string | null;
    batchParameterValue: string | null;
    batchProgId: string | null;
    batchRegTypeCode: string | null;
    batchRegType: string | null;
    batchStatusCode: string | null;
    batchStatus: string | null;
    chngDttm: string | null;
    chngProgId: string | null;
    chngUserId: string | null;
    inpDttm: string | null;
    inpProgId: string | null;
    inpUserId: string | null;
    batchLogId: number | null;
    batchLogName: string | null;
    count: number | null;
    batchTxDate: number | null;

    constructor(value: SchedulerExecution | null) {
        this.id = value?.id ?? null;
        this.batchExecTypeCode = value?.batchExecTypeCode ?? null;
        this.batchExecType = value?.batchExecType ?? null;
        this.batchJobDetailId = value?.batchJobDetailId ?? null;
        this.batchMessagge= value?.batchMessagge ?? null;
        this.batchProgId= value?.batchProgId ?? null;
        this.batchStatus= value?.batchStatus ?? null;
        this.batchLastExec= value?.batchLastExec ?? null;
        this.batchGroupPath= value?.batchGroupPath ?? null;
        this.batchRegTypeCode= value?.batchRegTypeCode ?? null;
        this.batchStatusCode= value?.batchStatusCode ?? null;
        this.chngDttm= value?.chngDttm ?? null;
        this.chngProgId= value?.chngProgId ?? null;
        this.batchRegType= value?.batchRegType ?? null;
        this.chngUserId= value?.chngUserId ?? null;
        this.inpDttm= value?.inpDttm ?? null;
        this.inpProgId= value?.inpProgId ?? null;
        this.batchParameterValue= value?.batchParameterValue ?? null;
        this.inpUserId= value?.inpUserId ?? null;
        this.batchLogId= value?.batchLogId ?? null;
        this.batchLogName= value?.batchLogName ?? null;
        this.count= value?.count ?? null;
        this.batchTxDate= value?.batchTxDate ?? null;
    }
}


export default ModelSchedulerExecution
interface interfaceMemberConn {
    id: number | null;
    memberConnId: number | null;
    memberId: number | null;
    memberConnServer: string | null;
    memberConnPort: string | null;
    memberConnEndpoint: string | null;
    memberConnServerStatus: string | null;
    memberConnIntervalTime: number | null;
    memberConnStatus: string | null;
    memberConnLastEchoDt: null | string;
    memberConnLastTransDt: null | string;
    memberName: string | null;
    memberCode: string | null;
    cdtId: number | null;
    cdtStart: null | string;
    cdtStop: null | string;
}

export class ModelMemberConn  {
    id: number | null;
    memberConnId: number | null;
    memberId: number | null;
    memberConnServer: string | null;
    memberConnPort: string | null;
    memberConnEndpoint: string | null;
    memberConnServerStatus: string | null;
    memberConnIntervalTime: number | null;
    memberConnStatus: string | null;
    memberConnLastEchoDt: null | string;
    memberConnLastTransDt: null | string;
    memberName: string | null;
    memberCode: string | null;
    cdtId: number | null;
    cdtStart: null | string;
    cdtStop: null | string;

    constructor(data: interfaceMemberConn) {
        this.id = data.id;
        this.memberConnId = data.memberConnId;
        this.memberId = data.memberId;
        this.memberConnServer = data.memberConnServer;
        this.memberConnPort = data.memberConnPort;
        this.memberConnEndpoint = data.memberConnEndpoint;
        this.memberConnServerStatus = data.memberConnServerStatus;
        this.memberConnIntervalTime = data.memberConnIntervalTime;
        this.memberConnStatus = data.memberConnStatus;
        this.memberConnLastEchoDt = data.memberConnLastEchoDt;
        this.memberConnLastTransDt = data.memberConnLastTransDt;
        this.memberName = data.memberName;
        this.memberCode = data.memberCode;
        this.cdtId = data.cdtId;
        this.cdtStart = data.cdtStart;
        this.cdtStop = data.cdtStop;
    }
}
import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page14Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalProductFeatureManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsProduct, optionsCommonStatusDetailCode } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'prodFeatureInd') {
            if (valueState.split('').length < 9) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { prodFeatureId, productId, prodFeatureName, prodFeatureDesc, prodFeatureStatus, prodFeatureIsDisplay, prodFeatureInd, productName, errorMessage } = statePage
        const data = {
            productId: productId,
            prodFeatureName: prodFeatureName,
            prodFeatureDesc: prodFeatureDesc,
            prodFeatureStatus: prodFeatureStatus,
            prodFeatureInd: prodFeatureInd,
        }
        const a = CheckAllFields([productId, prodFeatureName, prodFeatureStatus, prodFeatureInd], ['Product Feature', 'Product Feature Name', 'Status', 'Product Indicator'])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Product Feature</p>
                                <Select styles={inputStyleH25} className='h-40px' options={optionsProduct} value={optionsProduct.filter((option: any) => option.value === statePage.productId)} onChange={(e) => handleState('productId', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>PRODUCT FEATURE NAME</p>
                                <input value={statePage.prodFeatureName} onChange={(e) => handleState('prodFeatureName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>PRODUCT INDICATOR</p>
                                <input value={statePage.prodFeatureInd} onChange={(e) => handleState('prodFeatureInd', e.target.value)} className={inputStyle} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Status</p>
                                <Select styles={inputStyleH25} className='h-40px' options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.prodFeatureStatus)} onChange={(e) => handleState('prodFeatureStatus', e.value)} />
                            </div>
                            <div className={'col-12'}>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.prodFeatureDesc} onChange={(e) => handleState('prodFeatureDesc', e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />

                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page14Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    prodFeatureId: '',
    productId: '',
    prodFeatureName: '',
    prodFeatureDesc: '',
    prodFeatureStatus: '001',
    prodFeatureIsDisplay: '',
    prodFeatureInd: '',
    productName: '',
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}


const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                prodFeatureId: '',
                productId: '',
                prodFeatureName: '',
                prodFeatureDesc: '',
                prodFeatureStatus: '001',
                prodFeatureIsDisplay: '',
                prodFeatureInd: '',
                productName: '',
            };
        case 'setData':
            return {
                ...state,
                prodFeatureId: action.value.prodFeatureId,
                productId: action.value.productId,
                prodFeatureName: action.value.prodFeatureName,
                prodFeatureDesc: action.value.prodFeatureDesc,
                prodFeatureStatus: action.value.prodFeatureStatus,
                prodFeatureIsDisplay: action.value.prodFeatureIsDisplay,
                prodFeatureInd: action.value.prodFeatureInd,
                productName: action.value.productName,
            };
        // 
        case 'prodFeatureId':
            return {
                ...state,
                prodFeatureId: action.value,
            };
        case 'productId':
            return {
                ...state,
                productId: action.value,
            };
        case 'prodFeatureName':
            return {
                ...state,
                prodFeatureName: action.value,
            };
        case 'prodFeatureDesc':
            return {
                ...state,
                prodFeatureDesc: action.value,
            };
        case 'prodFeatureStatus':
            return {
                ...state,
                prodFeatureStatus: action.value,
            };
        case 'prodFeatureIsDisplay':
            return {
                ...state,
                prodFeatureIsDisplay: action.value,
            };
        case 'prodFeatureInd':
            return {
                ...state,
                prodFeatureInd: action.value,
            };
        case 'productName':
            return {
                ...state,
                productName: action.value,
            };
        default:
            throw new Error();
    }
}

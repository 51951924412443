
// InterfaceRecord

interface InterfaceRecordInterface {
    id: null | number | string;
    recordId: number | null;
    recordName: string | null;
    recordSize: number | null;
    recordMappingCode: string | null;
    recordCreateBy: number | null;
    recordCreateDate: string | null;
    recordUpdateBy: number | null;
    recordUpdateDate: string | null;
    recordDeleteBy: string | null;
    recordDeleteDate: string | null;
    recordIsDelete: Boolean | null;
    recordSeparator: string | null;
    

}

export class ModelInterfaceRecord {
    id: null | number | string;
    recordId: number | null;
    recordName: string | null;
    recordSize: number | null;
    recordMappingCode: string | null;
    recordCreateBy: number | null;
    recordCreateDate: string | null;
    recordUpdateBy: number | null;
    recordUpdateDate: string | null;
    recordDeleteBy: string | null;
    recordDeleteDate: string | null;
    recordIsDelete: Boolean | null;
    recordSeparator: string | null;


    constructor(value: InterfaceRecordInterface | null) {
        this.id = value?.id ?? null;
        this.recordId = value?.recordId ?? null;
        this.recordName = value?.recordName ?? null;
        this.recordSize = value?.recordSize ?? null;
        this.recordMappingCode = value?.recordMappingCode ?? null;
        this.recordCreateBy = value?.recordCreateBy ?? null;
        this.recordCreateDate = value?.recordCreateDate ?? null;
        this.recordUpdateBy = value?.recordUpdateBy ?? null;
        this.recordUpdateDate = value?.recordUpdateDate ?? null;
        this.recordDeleteBy = value?.recordDeleteBy ?? null;
        this.recordDeleteDate = value?.recordDeleteDate ?? null;
        this.recordIsDelete = value?.recordIsDelete ?? null;
        this.recordSeparator = value?.recordSeparator ?? null;
    }
}

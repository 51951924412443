import { useState, useEffect } from "react";
import Modal from "react-modal";
import { ModalStyle } from "../Utils";

export const NavigationModalLimitOffset = ({ ...props }) => {
  const [getCurrentPage, setCurrentPage] = useState(0)
  const { position, view, totalPages, currentPage, data, insertPage, handleState, setModal, totalData } = props;

  useEffect(() => {
    setCurrentPage(currentPage)
  }, [currentPage])


  return (
    <Modal isOpen={insertPage} style={ModalStyle}>
      <div className="w-500px bg-ff px-2 py-2 d-flex flex-column justify-content-center br-20">
        <h2 className="s-24 Lato-600 text-center">Insert page number</h2>
        <hr className="py-0 my-0" />
        <div className="d-flex flex-column my-2">
          <input
            value={getCurrentPage}
            onChange={(e: any) => { setCurrentPage(e.target.value) }}
            type="number"
            className="bd-d4 bg-ff br-5 w-100 s-1418 Lato-400 w-500px out-none px-1 py-2"
          />
        </div>
        <div className="d-flex w-100 flex-end">
          <button className="btn_  bd-c1 bg-e8" onClick={(e) => {
            if (getCurrentPage >= 1 && getCurrentPage <= Math.ceil(totalData / view)) {
              handleState("position", (getCurrentPage * view)); //
              setModal("insertPage")
            } else if (getCurrentPage <= 1) {
              handleState("position", 1 * view); //
              setModal("insertPage")
            } else if (getCurrentPage >= Math.ceil(totalData / view)) {
              handleState("position", Math.ceil(totalData / view) * view); //
              setModal("insertPage")
            }
          }}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}

import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { ModelInterfaceHeader } from './ModelInterfaceHeader';
import { loadingAction } from '../../Store/Loading';
import { Page31Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableInterfaceHeader = ({ ...props }) => {
      const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsElementType, optionsFieldMappingDetailCode } = props.stateOptions


    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { headerFieldName, headerFieldDesc, headerFieldLength, headerFieldType, headerFieldMappingCode, errorMessage } = statePage.data
        const data = {
            headerFieldId: id,
            headerFieldName: headerFieldName,
            headerFieldDesc: headerFieldDesc,
            headerFieldLength: parseInt(headerFieldLength),
            headerFieldType: headerFieldType,
            headerFieldMappingCode: headerFieldMappingCode,
        }
        const a = CheckAllFields([headerFieldName, headerFieldDesc, headerFieldLength, headerFieldType, headerFieldMappingCode], ['Name', 'Description', 'Length', 'Type', 'Mapping Code'])


        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                 
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                   
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>NAME</p>
                        <input value={statePage.data.headerFieldName} onChange={(e) => handleState('headerFieldName', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Mapping Code</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsElementType} value={optionsElementType.filter((option: any) => option.value === statePage.data.headerFieldType)} onChange={(e) => handleState('headerFieldType', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>length</p>
                        <input type='number' min={0} value={statePage.data.headerFieldLength} onChange={(e) => handleState('headerFieldLength', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Mapping Code</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFieldMappingDetailCode} value={optionsFieldMappingDetailCode.filter((option: any) => option.value === statePage.data.headerFieldMappingCode)} onChange={(e) => handleState('headerFieldMappingCode', e.value)} />
                    </div>
                    <div className="col-12">
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.data.headerFieldDesc} onChange={(e) => handleState("headerFieldDesc", e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page31Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelInterfaceHeader({
        id: null,
        headerFieldId: null,
        headerFieldName: null,
        headerFieldDesc: null,
        headerFieldLength: null,
        headerFieldType: null,
        headerFieldMappingCode: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceHeader) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceHeader({
                    id: null,
                    headerFieldId: null,
                    headerFieldName: null,
                    headerFieldDesc: null,
                    headerFieldLength: null,
                    headerFieldType: null,
                    headerFieldMappingCode: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceHeader({
                    id: action.value.headerFieldId,
                    headerFieldId: action.value.headerFieldId,
                    headerFieldName: action.value.headerFieldName,
                    headerFieldDesc: action.value.headerFieldDesc,
                    headerFieldLength: action.value.headerFieldLength,
                    headerFieldType: action.value.headerFieldType,
                    headerFieldMappingCode: action.value.headerFieldMappingCode,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





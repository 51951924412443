import { useSelector } from "react-redux";

export const ButtonEdit = ({ ...props }) => {
    const { handleClick, codeAccessPrivilege } = props;
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    if (privilegeAccess === null) {
        return <></>
    } else if (privilegeAccess.includes(codeAccessPrivilege)) {
        return <p className="btn btn-dark bg-29 pointer p-614 s-1214 br-5 h-20px w-50px d-flex justify-content-center align-items-center " onClick={handleClick}>EDIT</p>
    } else return <></>;
};

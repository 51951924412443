import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page29Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalDataElementManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsElementFormatDetailCode, optionsCommonStatusDetailCode, optionsElementTypeDetailCode, optionsFieldMappingDetailCode } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { elementType, elementName, elementDesc, elementFormat, elementStatus, elementIsRoot, elementRootId, elementLength, elementMappingCode } = statePage
        const data = {
            elementType: `${elementType}`,
            elementRootId: elementRootId,
            elementFormat: `${elementFormat}`,
            elementName: `${elementName}`,
            elementDesc: `${elementDesc}`,
            elementStatus: `${elementStatus}`,
            elementIsRoot: elementIsRoot,
            elementLength: elementLength,
            elementMappingCode: `${elementMappingCode}`
        }
        //   const a = checkAllFields([elementName, elementFormat, elementType, elementLength, elementStatus, elementIsRoot, elementRootId, ],['Name','Format','Length','Is Root','Root Tag ID','Status'])
        const a = CheckAllFields([elementName, elementFormat, elementType, elementLength, elementStatus, elementMappingCode], ['Name', 'Format', 'Type', 'Length', 'Status', 'Mapping Code'])

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            {id !== '' &&
                                <div className='col-6'>
                                    <p className={labelStyle}>ELEMENT ID</p>
                                    <input value={statePage.id} className={inputStyle} />
                                </div>
                            }
                            <div className='col-6'>
                                <p className={labelStyle}>NAME</p>
                                <input value={statePage.elementName} onChange={(e) => handleState('elementName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <input value={statePage.elementDesc} onChange={(e) => handleState('elementDesc', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>FORMAT</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsElementFormatDetailCode}
                                    value={optionsElementFormatDetailCode.filter((option: any) => option.value === statePage.elementFormat)}
                                    onChange={(e) => handleState('elementFormat', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>TYPE</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsElementTypeDetailCode}
                                    value={optionsElementTypeDetailCode.filter((option: any) => option.value === statePage.elementType)}
                                    onChange={(e) => handleState('elementType', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>LENGTH</p>
                                <input type='number' min={0} value={statePage.elementLength} onChange={(e) => handleState('elementLength', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STATUS</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode}
                                    value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.elementStatus)}
                                    onChange={(e) => handleState('elementStatus', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>MAPPING CODE</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsFieldMappingDetailCode}
                                    value={optionsFieldMappingDetailCode.filter((option: any) => option.value === statePage.elementMappingCode)}
                                    onChange={(e) => handleState('elementMappingCode', e.value)}
                                />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page29Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    elementType: '',
    elementId: '',
    elementName: '',
    elementDesc: '',
    elementFormat: '',
    elementStatus: '001',
    elementIsRoot: true,
    elementRootId: null,
    elementLength: '',
    elementMappingCode: '',
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                elementType: '',
                elementId: '',
                elementName: '',
                elementDesc: '',
                elementFormat: '',
                elementStatus: '001',
                elementIsRoot: true,
                elementRootId: null,
                elementLength: '',
                elementMappingCode: '',
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                elementId: action.value.elementId,
                elementType: action.value.elementType,
                elementName: action.value.elementName,
                elementDesc: action.value.elementDesc,
                elementFormat: action.value.elementFormat,
                elementStatus: action.value.elementStatus,
                elementIsRoot: action.value.elementIsRoot,
                elementRootId: action.value.elementRootId,
                elementLength: action.value.elementLength,
                elementMappingCode: action.value.elementMappingCode,
                errorMessage: "",
            };
        // 
        case 'elementId':
            return {
                ...state,
                elementId: action.value,
            };
        case 'elementType':
            return {
                ...state,
                elementType: action.value,
            };
        case 'elementName':
            return {
                ...state,
                elementName: action.value,
            };
        case 'elementDesc':
            return {
                ...state,
                elementDesc: action.value,
            };
        case 'elementFormat':
            return {
                ...state,
                elementFormat: action.value,
            };
        case 'elementStatus':
            return {
                ...state,
                elementStatus: action.value,
            };
        case 'elementIsRoot':
            return {
                ...state,
                elementIsRoot: action.value,
            };
        case 'elementRootId':
            return {
                ...state,
                elementRootId: action.value,
            };
        case 'elementLength':
            return {
                ...state,
                elementLength: action.value,
            };
        case 'elementMappingCode':
            return {
                ...state,
                elementMappingCode: action.value,
            };
        default:
            throw new Error();
    }
}

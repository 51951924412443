import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete, DatePickerCalendarIconOnly } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import { format } from "date-fns";

import Modal from 'react-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ModelServiceRegistration } from './ModelServiceRegistration';
import { Page22Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalServiceRegistration = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const {
        optionsLogLevelDetailCode,
        optionsCommonStatusDetailCode
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["serviceCode", "serviceStat", "serviceName", "ServiceLogLevel", "serviceProgName", "serviceDesc"]
        let limit = [16, 3, 100, 3, 32, 255]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            serviceId,
            serviceCode,
            serviceStat,
            serviceName,
            serviceLogLevel,
            serviceProgName,
            serviceStartDateTime,
            serviceStartDate,
            serviceStartTime,
            serviceStopDateTime,
            serviceStopDate,
            serviceStopTime,
            serviceDesc,
        } = statePage.data
        let splitStartDate = serviceStartDateTime.toString().split('T');
        let splitEndDate = serviceStopDateTime.toString().split('T');

        const data = {
            serviceCode: serviceCode,
            serviceStat: serviceStat,
            serviceName: serviceName,
            serviceLogLevel: serviceLogLevel,
            serviceProgName: serviceProgName,
            serviceStartDateTime: `${splitStartDate[0]} ${splitStartDate[1]}`,
            serviceStopDateTime: `${splitEndDate[0]} ${splitEndDate[1]}`,
            serviceDesc: serviceDesc,
        }
        const a = CheckAllFields([
            serviceCode,
            serviceStat,
            serviceName,
            serviceLogLevel,
            serviceProgName,
            serviceStartDateTime,
            serviceStopDateTime,
        ], [
            'SERVICE CODE',
            'STATUS',
            'SERVICE NAME',
            'SERVICE LOG LEVEL',
            'SERVICE PROGRAM NAME',
            'START DATETIME',
            'STOP DATETIME',
        ])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>SERVICE CODE <span className='text-danger'>*</span></p>
                                <input value={statePage.data.serviceCode} onChange={(e) => handleState('serviceCode', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode}
                                    value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.serviceStat)}
                                    onChange={(e) => handleState('serviceStat', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>SERVICE NAME <span className='text-danger'>*</span></p>
                                <input value={statePage.data.serviceName} onChange={(e) => handleState('serviceName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>SERVICE LOG LEVEL <span className='text-danger'>*</span></p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsLogLevelDetailCode}
                                    value={optionsLogLevelDetailCode.filter((option: any) => option.value === statePage.data.serviceLogLevel)}
                                    onChange={(e) => handleState('serviceLogLevel', e.value)}
                                />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>START DATETIME <span className='text-danger'>*</span></p>
                                <div>
                                    <div className="d-flex">
                                        <DatePicker
                                            selected={new Date(statePage.data.serviceStartDateTime || '')}
                                            className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                            wrapperClassName="w-100 h-100"
                                            popperClassName="react-datepicker-popper-transform-up-right"
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            timeFormat="HH:mm"
                                            showYearDropdown
                                            showTimeSelect
                                            showPopperArrow={false}
                                            timeIntervals={5}
                                            onChange={(date: any) => { handleState('serviceStartDateTime', `${format(date, 'yyyy-MM-dd HH:mm:ss')}`) }}
                                        />
                                    </div>
                                    <DatePickerCalendarIconOnly />
                                </div>
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STOP DATETIME <span className='text-danger'>*</span></p>
                                <div>
                                    <div className="d-flex">
                                        <DatePicker
                                            selected={new Date(statePage.data.serviceStopDateTime || '')}
                                            className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                            wrapperClassName="w-100 h-100"
                                            popperClassName="react-datepicker-popper-transform-up-right"
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            timeFormat="HH:mm"
                                            showYearDropdown
                                            showTimeSelect
                                            showPopperArrow={false}
                                            timeIntervals={5}
                                            onChange={(date: any) => { handleState('serviceStopDateTime', `${format(date, 'yyyy-MM-dd HH:mm:ss')}`) }}
                                        />
                                    </div>
                                    <DatePickerCalendarIconOnly />
                                </div>
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>SERVICE PROGRAM NAME <span className='text-danger'>*</span></p>
                                <input value={statePage.data.serviceProgName} onChange={(e) => handleState('serviceProgName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.data.serviceDesc} onChange={(e) => handleState('serviceDesc', e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page22Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelServiceRegistration({
        id: null,
        serviceId: null,
        serviceCode: null,
        serviceStat: '001',
        serviceName: null,
        serviceLogLevel: '005',
        serviceProgName: null,
        serviceStartDateTime: null,
        serviceStartDate: null,
        serviceStartTime: null,
        serviceStopDateTime: null,
        serviceStopDate: null,
        serviceStopTime: null,
        serviceDesc: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    let now = new Date();
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    let timeNow = `${new Date().toISOString().slice(0, 10)}T${hours}:${minutes}:${seconds}`;
    for (const key in state.data as ModelServiceRegistration) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelServiceRegistration({
                    id: null,
                    serviceId: null,
                    serviceCode: null,
                    serviceStat: '001',
                    serviceName: null,
                    serviceLogLevel: '005',
                    serviceProgName: null,
                    serviceStartDateTime: timeNow.toString(),
                    serviceStartDate: null,
                    serviceStartTime: null,
                    serviceStopDateTime: timeNow.toString(),
                    serviceStopDate: null,
                    serviceStopTime: null,
                    serviceDesc: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelServiceRegistration({
                    id: action.value.serviceId,
                    serviceId: action.value.serviceId,
                    serviceCode: action.value.serviceCode,
                    serviceStat: action.value.serviceStat,
                    serviceName: action.value.serviceName,
                    serviceLogLevel: action.value.serviceLogLevel,
                    serviceProgName: action.value.serviceProgName,
                    serviceStartDateTime: action.value.serviceStartDateTime,
                    serviceStartDate: action.value.serviceStartDate,
                    serviceStartTime: action.value.serviceStartTime,
                    serviceStopDateTime: action.value.serviceStopDateTime,
                    serviceStopDate: action.value.serviceStopDate,
                    serviceStopTime: action.value.serviceStopTime,
                    serviceDesc: action.value.serviceDesc,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

import {
    NavigationModal,
    NavigationTable,
    DataTableFeatures,
    SelectView,
    DataTableFeaturesLimitOffset,
    NavigationModalLimitOffset,
    Sort,
} from ".";
import { stateOptions } from "../State";
import { NavigationTableLimitOffset } from "./NavigationTableLimitOffset";

export const TableWithLimitOffset = ({ ...props }) => {
    const {
        state,
        globalState,
        needComponentHeaderTable,
        needComponentHeaderTableCenter = false,
        addDataComponent,
        checkIndexComponent,
        exportToCSVComponent,
        handleStateComponent,
        setToggleComponent,
        needComponentFilterTable,
        filterComponentPage,
        needComponentTable,
        tableComponentPage,
        needComponentFooterTable,
        setModal,
        modalAddDeleteComponentPage,
        modalUpdateComponentPage,
        needToggleFunction = true,
        needAddButton = true,
        needSearchFunction = true,
        needDownloadButton = true,
        needComponentSort = false,
        needHeaderTableWhenDataIsZero = false,
        handleGlobalState,isButtonDisabled
    } = props;
    return (
        <>
            <div className='d-flex flex-column w-100 mt-3'>
                <h1 className='Lato-300 normal s-2024 mb-4'>{state.titlePage}</h1>
                <div className='d-flex flex-column bg-ff bd-ea br-10'>
                    {
                        needComponentHeaderTable === true ?
                            <DataTableFeaturesLimitOffset
                                stateParent={state}
                                data={state.data}
                                totalData={state.totalData} // new
                                label={state.label}
                                titleTable={'List'}
                                privilegeAccess={state.privilegeAccess}
                                addData={addDataComponent}
                                checkIndex={checkIndexComponent}
                                exportToCSV={exportToCSVComponent}
                                handleState={handleStateComponent}
                                setToggle={setToggleComponent}
                                needToggleFunction={needToggleFunction}
                                needAddButton={needAddButton}
                                needSearchFunction={needSearchFunction}
                                needDownloadButton={needDownloadButton}
                                isButtonDisabled={isButtonDisabled}
                            />
                            :
                            <></>
                    }
                    {
                        needComponentSort === true ?
                            <Sort state={state} stateOptions={stateOptions} globalState={globalState} handleGlobalState={handleGlobalState} />
                            :
                            <></>
                    }
                    {
                        needComponentFilterTable === true ?
                            filterComponentPage !== undefined ? filterComponentPage() : <></>
                            :
                            <></>
                    }
                    {
                        needComponentHeaderTableCenter === true ?
                            <DataTableFeaturesLimitOffset
                                stateParent={state}
                                data={state.data}
                                totalData={state.totalData} // new
                                label={state.label}
                                titleTable={'List'}
                                privilegeAccess={state.privilegeAccess}
                                addData={addDataComponent}
                                checkIndex={checkIndexComponent}
                                exportToCSV={exportToCSVComponent}
                                handleState={handleStateComponent}
                                setToggle={setToggleComponent}
                                needToggleFunction={needToggleFunction}
                                needAddButton={needAddButton}
                                needSearchFunction={needSearchFunction}
                                needDownloadButton={needDownloadButton}
                                isButtonDisabled={isButtonDisabled}
                            />
                            :
                            <></>
                    }

                    {
                        state.data.length > 0 ?
                            <>

                                {
                                    needComponentTable === true ?
                                        tableComponentPage !== undefined ?
                                            <div className="br-10">
                                                {tableComponentPage()}
                                            </div>
                                            :
                                            <></>
                                        :
                                        <></>
                                }
                                {
                                    needComponentFooterTable === true ?
                                        <div className='d-flex min-h-60px p-2 justify-content-between'>
                                            <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                                            <NavigationTableLimitOffset
                                                data={state.data}
                                                position={state.position}
                                                view={state.view}
                                                totalPages={state.totalPages}
                                                currentPage={state.currentPage}
                                                handleState={handleStateComponent}
                                                setModal={setModal}
                                            />

                                        </div>
                                        : <></>
                                }
                            </>
                            :
                            <div className="row">
                                {
                                    needHeaderTableWhenDataIsZero === true ?
                                        tableComponentPage() 
                                        :
                                        <></>
                                }

                                <div className="col-12 bg-f4 w-100 d-flex justify-content-center align-items-center h-40px">
                                    Data not found
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                state.data.length > 0 ?
                    <NavigationModalLimitOffset
                        data={state.data}
                        totalData={state.totalData} // new
                        position={state.position}
                        view={state.view}
                        totalPages={state.totalPages}
                        currentPage={state.currentPage}
                        insertPage={state.insertPage}
                        handleState={handleStateComponent}
                        setModal={setModal}
                    />
                    :
                    <></>
            }
            {modalAddDeleteComponentPage !== undefined ? modalAddDeleteComponentPage() : <></>}
            {modalUpdateComponentPage !== undefined ? modalUpdateComponentPage() : <></>}
        </>
    )
}
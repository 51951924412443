import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalPrivilegeCode = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
  const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
  const { optionsMember, optionsUserPrivilegeLevel } = props.stateOptions

  useEffect(() => {
    dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
    dispatchStatePage({ type: 'clearData', value: deleteItem })
    dispatchStatePage({ type: "ccdCode", value: null })
    dispatchStatePage({ type: "member", value: null })
  }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

  useEffect(() => {
    dispatchStatePage({ type: 'deleteItem', value: deleteItem })
  }, [deleteItem !== statePage.deleteItem])

  useEffect(() => {
    dispatchStatePage({ type: 'id', value: id })
    if (dataModal !== null) {
      handleState('setData', dataModal);
    }
    if (id !== '') { }
    else {
      dispatchStatePage({ type: 'clearData', value: deleteItem })
    }
  }, [id !== statePage.id])

  const handleState = (nameState: string | any, valueState: any) => {
    let names = ["privilegeName", "privilegeDesc"]
    let limit = [50, 255]
    let index = names.indexOf(nameState)
    if (index !== -1) {
      if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
    } else {
      dispatchStatePage({ type: nameState, value: valueState })
    }
  }

  const deleteItemAPI = () => {
    API.delete({
      bodyCustom: null,
      pathCustom: `${urlApi}/${id}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response) => {
      setModal("deleteItem")
      reloadData()
    }).catch((err) => {
      dispatchStatePage({ type: 'showModalError', value: true })
      dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
    }).finally(() => {
      dispatchStatePage({ type: 'clearData', value: deleteItem })
    })
  }

  const postDataAPI = () => {
    const { privilegeId, privilegeName, privilegeDesc, member, ccdCode } = statePage;
    const data = {
      privilegeName: privilegeName,
      privilegeDesc: privilegeDesc,
      memberId: member,
      ccdCode: ccdCode,
      privdStatus: '01',
    };
    const a = CheckAllFields([privilegeName, member, ccdCode], ['Privilege Name', 'Member', 'Privilege Level']); if (a !== "") {
      handleState('errorMessage', a);
    } else {
      API.post({
        bodyCustom: data,
        pathCustom: `${urlApi}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response) => {
        dispatchStatePage({ type: 'clearData' })
        setModal("visibleModalAddDelete")
        reloadData()
      }).catch((err) => handleState('errorMessage', err))
    }
  }

  return (
    <>
      <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
        <div className='d-flex flex-column w-600px'>
          <div className='d-flex min-h-40px justify-content-between align-items-center shd-ea'>
            <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
            <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='row py-2 gy-2 gx-3 mx-0'>
              <div className="col-6">
                <p className={labelStyle}>PRIVILEGE NAME <span className='text-danger'>*</span></p>
                <input value={statePage.privilegeName} onChange={(e) => handleState("privilegeName", e.target.value)} className={inputStyle} />
              </div>
              <div className="col-6" />
              <div className="col-6">
                <p className={labelStyle}>Member <span className='text-danger'>*</span></p>
                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.member)} onChange={(e) => dispatchStatePage({ type: 'member', value: e.value })} />
              </div>
              <div className="col-6">
                <p className={labelStyle}>Privilege CODE <span className='text-danger'>*</span></p>
                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsUserPrivilegeLevel} value={optionsUserPrivilegeLevel.filter((option: any) => option.value === statePage.ccdCode)} onChange={(e) => dispatchStatePage({ type: 'ccdCode', value: e.value })} />
              </div>
              <div className="col-12">
                <p className={labelStyle}>DESCRIPTION</p>
                <textarea rows={4} value={statePage.privilegeDesc} onChange={(e) => handleState("privilegeDesc", e.target.value)} className={inputDescriptionStyle} />
              </div>
            </div>
            <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />

          </div>
        </div>
      </Modal>

      <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
      <ModalCannotDelete statePage={statePage} handleState={handleState} />
    </>
  )
}


const initialState = {
  titleModal: 'NEW PRIVILEGE',
  visibleModalAddDelete: false,
  deleteItem: false,
  // 
  privilegeId: '',
  privilegeName: '',
  privilegeDesc: '',
  member: null,
  ccdCode: null,
  // 
  id: "",
  errorMessage: "",
  showModalError: false,
  errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'visibleModalAddDelete':
      return {
        ...state,
        visibleModalAddDelete: action.value,
      };
    case 'deleteItem':
      return {
        ...state,
        deleteItem: action.value,
      };
    case 'id':
      return {
        ...state,
        id: action.value,
      };
    case 'errorMessage':
      return {
        ...state,
        errorMessage: action.value,
      };
    case 'showModalError':
      return {
        ...state,
        showModalError: action.value,
      };
    case 'errorMessageModal':
      return {
        ...state,
        errorMessageModal: action.value,
      };
    // 
    case 'clearData':
      return {
        ...state,
        privilegeId: '',
        privilegeName: '',
        privilegeDesc: '',
        errorMessage: "",

      };
    case 'setData':
      return {
        ...state,
        privilegeId: action.value.privilegeId,
        privilegeName: action.value.privilegeName,
        privilegeDesc: action.value.privilegeDesc,
        errorMessage: "",
      };
    // 
    case 'privilegeId':
      return {
        ...state,
        privilegeId: action.value,
      };
    case 'privilegeName':
      return {
        ...state,
        privilegeName: action.value,
      };
    case 'privilegeDesc':
      return {
        ...state,
        privilegeDesc: action.value,
      };
    case 'member':
      return {
        ...state,
        member: action.value,
      };
    case 'ccdCode':
      return {
        ...state,
        ccdCode: action.value,
      };
    default:
      throw new Error();
  }
}

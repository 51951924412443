import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonDelete, ButtonEdit, InsideComponentPage } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { InterfaceOptionsBatchProgram } from './InterfaceOptionsBatchProgram';
import { ModalBatchProgram } from './ModalBatchProgram';
import { TableBatchProgram } from './TableBatchProgram';
import { Page35Title } from '../../State/Menu/TitleMenu';




export const BatchProgram = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])
    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let finalParameter = `${search}`;
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,

                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response: any) => dispatch({ type: 'searchData', value: response.data }))
        } else {
            API.get({
                bodyCustom: null,

                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response: any) => dispatch({ type: 'data', value: response.data }))
        }
    }

    const afterUpdate = (updateItemID: any) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let finalParameter = `${search}`;

        API.get({
            bodyCustom: null,

            pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,

        }).then((response: any) => dispatch({ type: 'afterUpdate', value: { data: response.data, updateItemID: updateItemID } }))
    }

    const afterUpdateItem = (updateItemID: any) => {
        initFunction(dispatchOptions, state, () => afterUpdate(updateItemID), null, InterfaceOptionsBatchProgram, dispatchGlobal)
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsBatchProgram, dispatchGlobal)
    }, [state.search, stateCustomComponent.selectedStartDate, stateCustomComponent.selectedEndDate, stateCustomComponent.selectedMember])

    useEffect(() => {
        let totalPages: number = Math.ceil(state.data.length / state.view);
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'changeData', value: { totalPages, currentPage } });
    }, [state.data.length, state.position, state.view])

    const exportToCSVComponent = () => { }
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: any) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsBatchProgram, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => afterUpdateItem(updateItemID)


    const customButtonAction = (item: any, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
                
                <ButtonEdit handleClick={() => actionShowModal(item.id)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
                
            </div>
        );
    };
    const filterComponentPage = () => {
        return (
            <></>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableBatchProgram
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <ModalBatchProgram
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {/* {
                    state.showModal.map((element1: any) => {
                        return (
                            <ModalDraggableQRStandardElement
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                } */}
            </>
        )
    }

    return (
        <>
            <InsideComponentPage
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={true}
                needSearchFunction={true}
                needDownloadButton={false}
            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'qrse',
    titlePage: Page35Title,
    privilegeAccess: { view: "191", add: "192", edit: "193", delete: "194", download: "195" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Program ID", width: "wd-200", val: "programID" }, //interfaceFieldId
        { field: "Batch Name", width: "wd-250", val: "batchName" }, //interfaceFieldId
        { field: "Batch Program Name", width: "wd-200", val: "batchProgramName" },
        { field: "Log Level", width: "wd-150", val: "logLevel" },
        { field: "Type", width: "wd-100", val: "type" },
        { field: "Status", width: "wd-100", val: "type" },
        { field: "Batch Reprocessing", width: "wd-200", val: "batchReprocessing" },
        { field: 'Parallel Execution', width: 'wd-150', val: 'parallelExecution' },
        { field: 'Action', width: 'wd-150', val: 'action' },
    ],
    complete_toggle: ["no", "programID", "batchName", "batchProgramName", "logLevel", "type", "status", "batchReprocessing", "parallelExecution", "action"],
    toggle: ["no", "programID", "batchName", "batchProgramName", "logLevel", "type", "status", "batchReprocessing", "parallelExecution", "action"],
    list_column_name: ["No.", "Program ID", "Batch Name", "Batch Program Name", "Log Level", "Type", "Status", "Batch Reprocessing", 'Parallel Execution'],
    //
    visibleModalAddDelete: false,
    showModal: [],
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1600,
};


// qrseId
function reducer(state: any, action: any) {
    let showDataResult: any[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let showModal = state.showModal;
    let newShowModal: any[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: any) => {
                        showDataResult.push(
                            { idModal: element1.qrseId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.qrseId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: any) => {
                        let i = 1;
                        showDataResult.push(
                            { idModal: element1.qrseId, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.qrseId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (showModal !== undefined) {
                if (showModal !== null) {
                    if (showModal.length > 0) {
                        showModal.forEach((element1: any | {}) => {
                            if (element1.idModal === action.value) {
                                if (element1.showModal === false) { // will to show
                                    newShowModal.push({ idModal: element1.idModal, showModal: !(element1.showModal), itemModal: element1.itemModal, index: state.dataLength + 99 })
                                } else {
                                    newShowModal.push({ idModal: element1.idModal, showModal: !(element1.showModal), itemModal: element1.itemModal, index: 99 })
                                }
                            } else {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                showModal: newShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (showModal !== undefined) {
                if (showModal !== null) {
                    if (showModal.length > 0) {
                        showModal.forEach((element1: any | {}) => {
                            if (element1.idModal === action.value) {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                showModal: newShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            let dataFilter: any[] = [];
            dataFilter = action.value.data.filter((element1: any) => element1.qrseId === action.value.updateItemID)
            let resultIdFilter = dataFilter[0].qrseId;
            dataFilter[0].id = resultIdFilter;
            return {
                ...state,
                showModal: state.showModal.map((element1: any | {}) => {
                    if (element1.idModal === action.value.updateItemID) {
                        return { idModal: resultIdFilter, showModal: !(element1.showModal), itemModal: dataFilter[0], index: 1 }
                    } else {
                        return element1;
                    }
                }),
                data: state.data.map((element1: any | {}) => {
                    if (element1.id === action.value.updateItemID) {
                        return dataFilter[0];
                    } else {
                        return element1;
                    }
                })
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        default:
            throw new Error();
    }
}



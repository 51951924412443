import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'universal-cookie';
import * as CryptoJS from 'crypto-js';
import { cookiesGlobal } from "../State/Cookies";

interface userInterface {
    user: any,
    token: string | null,
    idPrivilegeAccess: string | number | null,
    privilegeAccess: [] | null,
    userLevel: string | number | null,
    memberId: string | number | null,
    memberCode: string | number | null,
    memberIsRegisteredToPIC: Boolean | null,
    memberRoleType: string | number | null | any[],
}


const userInitialState = (): userInterface => {
    try {
        // let decryptedUserData = CryptoJS.AES.decrypt(cookiesGlobal.get('_userxxxx'), 'bcap-gapih.dashboard.com', { path: '/',  });
        let decryptedUserData = CryptoJS.AES.decrypt(localStorage.getItem("_userxxxx")!, 'bcap-gapih.dashboard.com');
        let jsonDecrypt = JSON.parse(decryptedUserData.toString(CryptoJS.enc.Utf8))
        return {
            user: jsonDecrypt,
            token: null,
            idPrivilegeAccess: null,
            privilegeAccess: null,
            userLevel: null,
            memberId: null,
            memberCode: null,
            memberIsRegisteredToPIC: null,
            memberRoleType: null,
        }
    } catch (error) {
        console.log(error)
        return {
            user: null,
            token: null,
            idPrivilegeAccess: null,
            privilegeAccess: null,
            userLevel: null,
            memberId: null,
            memberCode: null,
            memberIsRegisteredToPIC: null,
            memberRoleType: null,
        }
    }

}

export const userSlice = createSlice({
    name: "user",
    initialState: userInitialState(),
    reducers: {
        tokenAction: (state, action) => {
            state.token = action.payload.token;
        },
        loginAction: (state, action) => {
            state.idPrivilegeAccess = action.payload.privilegeId;
            state.userLevel = action.payload.userLevel;
            state.memberId = action.payload.memberId;
            let dataRes: any = [];
            action.payload.dataPrivilegeAccess.forEach((element1: any) => {
                dataRes.push(`${element1.privfId}`);
            });
            state.privilegeAccess = dataRes;
            state.user = action.payload.user;
        },
        logoutAction: (state, action) => {
            state.user = null;
            state.userLevel = null;
            state.idPrivilegeAccess = null;
            state.memberId = null;
            state.memberRoleType = null;
            state.memberCode = null;
            state.privilegeAccess = null;
        },
        setRoleByMemberIdAction: (state, action) => {
            if (action.payload.data.length > 0) {
                action.payload.data.forEach((element1: any) => {
                    if (element1.memberId === action.payload.memberId) {
                        if (element1.memberRoleDtos !== null) {
                            let setMemberRoleDtos: any[] = []
                            element1.memberRoleDtos.forEach((item2: any) => {
                                setMemberRoleDtos.push(item2.memberRoleType)
                            })
                            state.memberRoleType = setMemberRoleDtos

                        }
                        if (element1.memberRoleDtos !== null) {
                            state.memberCode = element1.memberCode

                        }
                    }
                })
            }
        },
        setMemberIsRegisteredToPIC: (state, action) => {
            state.memberIsRegisteredToPIC = action.payload;
        },


    },
});

export const { tokenAction, loginAction, logoutAction, setRoleByMemberIdAction, setMemberIsRegisteredToPIC } = userSlice.actions;
export const stateUser = (state: any) => state.user.value;
export default userSlice.reducer;

/* This component shows calendar icon in any DatePicker component */

// How to use
// Wrap DatePicker (react-datepicker) component in an empty <div> component
// Put this component below DatePicker component inside the same <div>

// Notes
// No need to set parent <div> position as relative
// This component DOES NOT include DatePicker component
// This component was made because DatePicker tends to break when
// parent position is relative and is using time picker

export const DatePickerCalendarIconOnly = () => {
    return (
        <div className='align-self-end position-relative'>
            <div
                className='bg-fff position-absolute rounded-end'
                style={{
                    position: "absolute",
                    height: "23px",
                    width: "30px",
                    right: "1px",
                    top: "50%",
                    transform: "translateY(-105%)",
                    pointerEvents: "none",
                    fontSize: "14px",
                }}>
            </div>
            <span
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-120%)",
                    pointerEvents: "none",
                    fontSize: "14px",
                }}
            >
                <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="calendar"
                    width="1em"
                    height="1em"
                    fill="#0000003f"
                    aria-hidden="true">
                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                    </path>
                </svg>
            </span>
        </div>
    )
}
import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { inputStyle, labelStyle } from "../../Styles";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { format } from "date-fns";

import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
import { close } from "../../Assets/Images/svg";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { initFunction, reducerOptions, stateOptions, stateSelected } from "../../State";
import { urlSelection } from "../../Services/API";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModelDetailTransactionStatus } from "../Dashboard/Models/ModelDetailTransactionStatus";
import { InterfaceOptionsDetailsMonitoringStatus } from "../Dashboard/InterfaceOptions/InterfaceOptionsDetailsMonitoringStatus";
import { resetAllAction } from "../../Store/TransactionJourney";
import { Page2DashboardRoute, Page2FrameworkRoute } from "../../Routes";
import { ModalUnmatchedList } from "./ModalUnmatchedList";
import { ResizableBox } from "react-resizable";
import { Box, Container, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


export const ModalUnmatchedList2 = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
  const [statePage, dispatchStatePage] = useReducer(reducerStatePage, initialStatePage);

  const { stateParent, setModal, dispatchParent, handleStateGlobal } = props;

  const [modalPosition, setModalPosition] = useState({ x: 0, y: 10 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: any) => {
    setDragging(true);
    setOffset({
      x: e.clientX - modalPosition.x,
      y: e.clientY - modalPosition.y,
    });
  };

  const handleMouseMove = (e: any) => {
    if (dragging) {
      setModalPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleNavigateByGlobalId = (globalId: string, startDate: string, endDate: string, startTime: string, endTime: string) => {
    const newWindow: any = window.open(`${Page2FrameworkRoute}`, '_blank');

    newWindow.opener.data = {
      globalId: globalId,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime
    };
  };
  const handleMouseUp = () => {
    setDragging(false);
  };
  React.useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);




  // const [state, dispatch] = useReducer(reducer, initialState);

  const { optionsTransactionTypeDetailCodeAndCcdDesc } = stateOptions;


  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    let transId = stateParent.selectedOriTransId;
    if (stateParent.selectedOriTransId !== '') {
      API.get({
        bodyCustom: null,
        pathCustom: `dashboard/transaction-detail?transId=${transId}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        handleState('data', response.data)
      })
    }
  }

  useEffect(() => {
    initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsDetailsMonitoringStatus, dispatchGlobal)
  }, [stateParent.selectedOriTransId])

  const handleClick = (codeTransType: any) => {
    dispatch({ type: "handleClick", value: codeTransType });
    // setModal("modalCondition")
  };
  useEffect(() => {
    if (state.modalDetail === false) {
      dispatch({ type: "transId", value: "" });
    }
  }, [state.modalDetail]);

  const onResize = (event: any, propsSize: any) => {
    const { node, size, handle } = propsSize;
    if (size.width > 100 && size.height > 100) {
      dispatchStatePage({ type: "resize", value: { width: size.width, height: size.height } });
    }
  };

  const handleState = (nameType: string, value: any) => dispatch({ type: nameType, value: value })
  const data = state.allData
  let merchant_post_code = ""
  let member_appr_code = ""

  if (state.data === null) return <></>

  if (CheckUndefined(data.merchantPosCode) == "-") {
    merchant_post_code = CheckUndefined(data.merchantPos)
  } else if (CheckUndefined(data.merchantPos) == "-") {
    merchant_post_code = CheckUndefined(data.merchantPosCode)
  } else {
    merchant_post_code = `${CheckUndefined(data.merchantPosCode)} - ${(CheckUndefined(data.merchantPos))}`
  }

  if (CheckUndefined(data.isApprCodeIntn) != "-") {
    if (data.isApprCodeIntn) member_appr_code = " (Generated by BCAP)"
  }

  const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
  const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)




  const transactionsDetailTable = () => {
    type Row = {
      id: number;
      header: string;
      content: string;
      onClick?: () => void;
    };
    const rows = [
      { id: 1, header: 'Transaction ID', content: `${CheckUndefined(data.transId)}` },
      {
        id: 2, header: 'Global ID', content: `${CheckUndefined(data.globalId)}`, onClick: () => {
          handleNavigateByGlobalId(
            data.globalId,
            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
            '00:00:01',
            '23:59:59'
          );
        }
      },
      { id: 3, header: 'Transaction Type', content: `${CheckUndefined(data.transType)}` },
      { id: 4, header: 'Transaction Date/Time', content: `${CheckUndefined(data.transactionDateTime)}` },
      { id: 5, header: 'Local Date Time', content: `${CheckUndefined(data.localDateTime)}` },
      { id: 6, header: 'Approval Code', content: `${CheckUndefined(data.apprCode)}${member_appr_code}` },
      { id: 7, header: 'Retrieval Reference Number', content: `${CheckUndefined(data.rrn)}` },
      { id: 8, header: 'Invoice Number', content: `${CheckUndefined(data.invNo)}` },
      { id: 9, header: 'STAN', content: `${CheckUndefined(data.stan)}` },
      { id: 10, header: `${CheckUndefined(data.srcLabel1)} Amount`, content: `${CheckUndefined(data.srcInstituteAmount1)}` },
      { id: 11, header: `${CheckUndefined(data.srcLabel2)} Amount`, content: `${CheckUndefined(data.srcInstituteAmount2)}` },
      { id: 12, header: `${CheckUndefined(data.dstLabel1)} Amount`, content: `${CheckUndefined(data.dstInstituteAmount1)}` },
      { id: 13, header: `${CheckUndefined(data.dstLabel2)} Amount`, content: `${CheckUndefined(data.dstInstituteAmount2)}` },
      { id: 14, header: 'Product Type', content: `${CheckUndefined(data.productTypeName)}` },
      { id: 15, header: 'Product Feature', content: `${CheckUndefined(data.productFeatureName)}` },
      { id: 16, header: 'MTI', content: `${CheckUndefined(data.mti)}` },
      { id: 17, header: 'Settlement Amount', content: `${CheckUndefined(data.stlAmt)}` },
      { id: 18, header: 'Fee', content: `${CheckUndefined(data.acqFeeAmount)}` },
      { id: 19, header: 'Settlement Date', content: `${CheckUndefined(data.stlDate)}` },
      { id: 20, header: 'Conversion Rate', content: `${CheckUndefined(data.convRate)}` },
      { id: 21, header: 'Conversion Date', content: `${CheckUndefined(data.convRate)}` },
      { id: 22, header: 'QR Ref No', content: `${CheckUndefined(data.qrAuthDetails?.qrRefNo)}` },
      { id: 23, header: 'Internal Response Code', content: `${CheckUndefined(data.intnRspnCode)}` },
      { id: 24, header: 'Destination Response Code', content: `${CheckUndefined(data.dstRspnCode)}` },
      { id: 25, header: 'Processing Code', content: `${CheckUndefined(data.procCode)}` },
      { id: 26, header: 'Response Code', content: `${CheckUndefined(data.rspnCode)}` },
      { id: 27, header: 'Transaction Status', content: `${CheckUndefined(data.status)}` },

    ];
    // const chunkArray = (arr: Row[], chunkSize: number) => {
    //   const result = [];
    //   for (let i = 0; i < arr.length; i += chunkSize) {
    //     result.push(arr.slice(i, i + chunkSize));
    //   }
    //   return result;
    // };

    // const getColumns = () => {
    //   const width = window.innerWidth;
    //   if (width < 768) return 3;
    //   if (width < 900) return 5;
    //   return 9;
    // };

    // const chunkedRows = chunkArray(rows, getColumns());
    const chunkArray = (arr: Row[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);

        while (chunk.length < chunkSize) {
          chunk.push({ id: -1, header: '', content: '' });
        }

        result.push(chunk);
      }
      return result;
    };

    const getColumns = () => {
      const width = window.innerWidth;
      if (width < 768) return 4;
      if (width < 900) return 4;
      return 8;
    };

    const chunkedRows = chunkArray(rows, getColumns());
    return (
      <>
        {data.rspnCode === '00 - Success' ? <>
          {/* <Container style={{ margin: '0px', padding: '0px', width:isMobile?'50%':'100%' }}>
            
                            <Box sx={{ overflowX: 'auto',borderLeft:'1px solid #ccc', borderTop:'1px solid #ccc', borderBottom:'1px solid #ccc',  }}>
                {chunkedRows.map((chunk, index) => (
                  <Table key={index} sx={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        {chunk.map((row) => (
                          <TableCell key={row.id} align="center" sx={{
                            borderRight: '1px solid #ccc',
                            padding: '6px',
                            fontFamily: 'system-ui, sans-serif',
                            width: isMobile ? '150px' : '100px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            fontSize: '12px',
                            backgroundColor: '#f5e9e9',
                            color: 'black',
                            borderTop: 'none',
                            borderBottom: 'none'

                          }}>{row.header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {chunk.map((row) => (
                          <TableCell key={row.id} align="center" sx={{
                            borderRight: '1px solid #ccc',
                            padding: '6px',
                            fontFamily: 'system-ui, sans-serif',
                            width: isMobile ? '150px' : '100px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            fontSize: '12px',
                            color: row.onClick ? 'blue' : row.header === 'Response Code' ? 'green' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                            fontWeight: row.header === 'Response Code' ? 'bold' : '',
                            borderTop: 'none',
                            borderBottom: 'none',
                            cursor: row.onClick ? 'pointer' : 'default'
                          }}
                            onClick={row.onClick}
                          >{row.content}</TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </Box>

          </Container> */}
          <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '56%' : '100%' }}>
            <div style={{ width: isMobile ? '' : '117%' }}>
              <Box sx={{ overflowX: isMobile ? 'auto' : 'unset', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
                {chunkedRows.map((chunk, index) => (
                  <Table key={index} sx={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        {chunk.map((row) => (
                          <TableCell key={row.id} align="center" sx={{
                            borderRight: '1px solid #ccc',
                            padding: '6px',
                            fontFamily: 'system-ui, sans-serif',
                            width: isMobile ? '150px' : '100px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            fontSize: '12px',
                            backgroundColor: '#f5e9e9',
                            color: 'black',
                            borderTop: 'none',
                            borderBottom: 'none'
                          }}>{row.header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {chunk.map((row) => (
                          <TableCell key={row.id} align="center" sx={{
                            borderRight: '1px solid #ccc',
                            padding: '6px',
                            fontFamily: 'system-ui, sans-serif',
                            width: isMobile ? '150px' : '100px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            fontSize: '12px',
                            color: row.onClick ? 'blue' : row.header === 'Response Code' || row.header === 'Transaction Status' ? 'green' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                            fontWeight: row.header === 'Response Code' || row.header === 'Transaction Status' ? 'bold' : '',
                            borderTop: 'none',
                            borderBottom: 'none',
                            cursor: row.onClick ? 'pointer' : 'default'
                          }}
                            onClick={row.onClick}
                          >{row.content || ''}</TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </Box>
            </div>
          </Container>
        </> :
          <>
            <>
              <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '56%' : '100%' }}>
                <div style={{ width: isMobile ? '' : '117%' }}>
                  <Box sx={{ overflowX: isMobile ? 'auto' : 'unset', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
                    {chunkedRows.map((chunk, index) => (
                      <Table key={index} sx={{ tableLayout: 'fixed' }}>
                        <TableHead>
                          <TableRow>
                            {chunk.map((row) => (
                              <TableCell key={row.id} align="center" sx={{
                                borderRight: '1px solid #ccc',
                                padding: '6px',
                                fontFamily: 'system-ui, sans-serif',
                                width: isMobile ? '100px' : '150px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'normal',
                                fontSize: '12px',
                                backgroundColor: '#f5e9e9',
                                color: 'black',
                                borderTop: 'none',
                                borderBottom: 'none'

                              }}>{row.header}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {chunk.map((row) => (
                              <TableCell key={row.id} align="center" sx={{
                                borderRight: '1px solid #ccc',
                                padding: '6px',
                                fontFamily: 'system-ui, sans-serif',
                                width: isMobile ? '100px' : '150px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'normal',
                                fontSize: '12px',
                                color: row.onClick ? 'blue' : row.header === 'Response Code' || row.header === 'Transaction Status' || row.header === 'Internal Response Code' || row.header === 'Destination Response Code' ? 'red' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                                fontWeight: row.header === 'Response Code' || row.header === 'Transaction Status' ? 'bold' : '',
                                borderTop: 'none',
                                borderBottom: 'none',
                                cursor: row.onClick ? 'pointer' : 'default'
                              }}
                                onClick={row.onClick}
                              >{row.content}</TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    ))}
                  </Box>
                </div>
              </Container>
            </>
          </>}
      </>
    );
  };

  const qrAuthDetailTable = () => {
    type Row = {
      id: number;
      header: string;
      content: string;
      onClick?: () => void;
    };
    const rows = [
      {
        id: 1,
        header: 'Transaction ID',
        content: CheckUndefined(data.qrAuthDetails?.qrTransId),
        onclick: undefined,
      },
      {
        id: 2,
        header: 'Global ID',
        content: (
          <div
            className="grid-item- bg-fff   row-data-pointer"
            style={{ color: "blue", textAlign: "center" }}
            onClick={() => {
              handleNavigateByGlobalId(
                data.globalId,
                format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                '00:00:01',
                '23:59:59'
              );
            }}
          >
            {CheckUndefined(data.qrAuthDetails?.qrGlobalId)}
          </div>
        ),
        onclick: undefined,
      },
      {
        id: 3,
        header: 'Product Feature',
        content: (
          <div style={{ color: 'red' }}>
            {CheckUndefined(data.qrAuthDetails?.qrProductFeatureName)}
          </div>
        ),
        onclick: undefined,
      },
      {
        id: 4,
        header: 'Request Date/Time',
        content: CheckUndefined(data.qrAuthDetails?.qrReqDate),
        onclick: undefined,
      },
      {
        id: 5,
        header: 'QR STAN',
        content: CheckUndefined(data.qrAuthDetails?.qrReqStan),
        onclick: undefined,
      },
      {
        id: 6,
        header: 'QR Ref No',
        content: (
          <div style={{ color: 'magenta' }}>
            {CheckUndefined(data.qrAuthDetails?.qrRefNo)}
          </div>
        ),
        onclick: undefined,
      },
      {
        id: 7,
        header: 'QR Inv No',
        content: CheckUndefined(data.qrAuthDetails?.qrInvNo),
        onclick: undefined,
      },
      {
        id: 8,
        header: 'QR Standard',
        content: CheckUndefined(data.qrAuthDetails?.qrStandard),
        onclick: undefined,
      },
      {
        id: 9,
        header: 'QR Mode',
        content: CheckUndefined(data.qrAuthDetails?.qrMode),
        onclick: undefined,
      },
      {
        id: 10,
        header: 'Member Name',
        content: CheckUndefined(data.qrAuthDetails?.qrMemberName),
        onclick: undefined,
      },
      {
        id: 11,
        header: 'Merchant Name',
        content: CheckUndefined(data.qrAuthDetails?.qrMerchantName),
        onclick: undefined,
      },
      {
        id: 12,
        header: 'QR Ref 1',
        content: CheckUndefined(data.qrAuthDetails?.ref1),
        onclick: undefined,
      },
      {
        id: 13,
        header: 'QR Ref 2',
        content: CheckUndefined(data.qrAuthDetails?.ref2),
        onclick: undefined,
      },
      {
        id: 14,
        header: 'QR Ref 3',
        content: CheckUndefined(data.qrAuthDetails?.ref3),
        onclick: undefined,
      },
      {
        id: 15,
        header: 'Response Code',
        content: (
          <div style={{ color: CheckUndefined(data.qrAuthDetails?.qrRspnCode) === '00 - Success' ? 'green' : 'red', fontWeight: 'bold' }}>
            {CheckUndefined(data.qrAuthDetails?.qrRspnCode)}
          </div>
        ),
        onclick: undefined,
      },
    ];

    const chunkArray = (arr: Row[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
      }
      return result;
    };

    const getColumns = () => {
      const width = window.innerWidth;
      if (width < 768) return 5;
      if (width < 900) return 5;
      return 5;
    };

    const chunkedRows = chunkArray(rows, getColumns());
    return (
      <>{data.productTypeName === 'QR' ?
        <> <div style={{ paddingLeft: '0px', marginLeft: '0px', marginTop: '0px', paddingTop: '0px' }}>
          <p className=" pb-2" style={{ fontSize: '13px' }}>Linked #1 : {CheckUndefined(data.qrAuthDetails?.qrProductFeatureInd)} {CheckUndefined(data.qrAuthDetails?.qrProductFeatureName)} </p>
        </div>
          <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '90%' : '100%' }}>

            <Box sx={{ overflowX: 'auto', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
              {chunkedRows.map((chunk, index) => (
                <Table key={index} sx={{ tableLayout: 'fixed', width: '100%' }}>
                  <TableHead>
                    <TableRow>
                      {chunk.map((row) => (
                        <TableCell key={row.id} align="center" sx={{
                          borderRight: '1px solid #ccc',
                          padding: '6px',
                          fontFamily: 'system-ui, sans-serif',
                          width: isMobile ? '150px' : '100px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                          fontSize: '12px',
                          backgroundColor: '#EA7878',
                          color: 'white',
                          borderTop: 'none',
                          borderBottom: 'none'

                        }}>{row.header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {chunk.map((row) => (
                        <TableCell key={row.id} align="center" sx={{
                          borderRight: '1px solid #ccc',
                          padding: '6px',
                          fontFamily: 'system-ui, sans-serif',
                          width: isMobile ? '150px' : '100px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                          fontSize: '12px',
                          color: row.onClick ? 'blue' : row.header === 'Response Code' ? 'green' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                          fontWeight: row.header === 'Response Code' ? 'bold' : '',
                          borderTop: 'none',
                          borderBottom: 'none',
                          cursor: row.onClick ? 'pointer' : 'default'
                        }}
                          onClick={row.onClick}
                        >{row.content}</TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </Box>

          </Container>
        </>

        :
        <></>
      }
      </>
    )

  };

  const settlementDetailsTable = () => {
    type Row = {
      id: number;
      header: string;
      content: string;
      onClick?: () => void;
    };
    const rows = [
      {
        id: 1,
        header: 'Wallet Id',
        content: `${CheckUndefined(data.walletId)}`, onclick: undefined
      },
      {
        id: 2,
        header: 'Customer PAN',
        content: (
          <div
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => {
              let newWindow : any = window.open(`${Page2DashboardRoute}`, '_blank');
              newWindow.opener.data = {
                walletUserPan: data.custPan,
                startDate: data.transactionDateTime.split(" ")[0],
                startTime: '00:00:00',
                navigateFrom: 'transaction-detail'
              };
            }}
          >
            {CheckUndefined(data.custPan)}
          </div>
        )
      },
      {
        id: 3,
        header: 'Customer Token',
        content: `${CheckUndefined(data.custToken)}`,
        onclick: undefined
      },
      {
        id: 4,
        header: 'Customer Username',
        content: `${CheckUndefined(data.custUsername)}`, onClick: undefined
      },
    ];

    return (
      <>
        <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '90%' : '100%' }} >

          <Box sx={{ overflowX: 'auto', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
            <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
              <TableHead>
                <TableRow>
                  {rows.map((row) => (
                    <TableCell key={row.id} align="center" sx={{
                      borderRight: '1px solid #ccc',
                      padding: '6px',
                      fontFamily: 'system-ui, sans-serif',
                      width: isMobile ? '150px' : '100px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                      fontSize: '12px',
                      backgroundColor: '#f5e9e9',
                      color: 'black',
                      borderTop: 'none',
                      borderBottom: 'none'

                    }}>{row.header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {rows.map((row) => (
                    <TableCell key={row.id} align="center" sx={{
                      borderRight: '1px solid #ccc',
                      fontSize: '12px',
                      padding: '6px',
                      size: '100px',
                      fontFamily: 'system-ui, sans-serif !important',
                      width: isMobile ? '150px' : '100px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                      color: 'black',
                      fontWeight: row.header === 'Response Code' ? 'bold' : '',
                      borderTop: 'none',
                      borderBottom: 'none',
                      cursor: row.onClick ? 'pointer' : 'default'
                    }}
                      onClick={row.onClick}
                    >{row.content}</TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Container>
      </>
    )

  };
  const sourceDetailTable = () => {
    type Row = {
      id: number;
      header: string;
      content: string;
      onClick?: () => void;
    };
    const rows = [
      {
        id: 1,
        header: 'Source Institute',
        content: CheckUndefined(data.srcInstitute),
        onclick: undefined,
      },
      {
        id: 2,
        header: data.srcLabel1,
        content: (
          <div
            className="grid-item-mc bg-fff   row-data-pointer"
            style={{ color: '#3b0607' }}
          >
            {CheckUndefined(data.srcInstituteName1)}
          </div>
        ),
        onclick: undefined,
      },
      {
        id: 3,
        header: data.srcLabel2,
        content: CheckUndefined(data.srcInstituteName2),
        onclick: undefined,
      },
      {
        id: 4,
        header: 'Source IP',
        content: CheckUndefined(data.srcIp),
        onclick: undefined,
      },
      {
        id: 5,
        header: 'Source Ref No',
        content: CheckUndefined(data.srcRefNo),
        onclick: undefined,
      },
      {
        id: 6,
        header: `${data.srcLabel1} Amount`,
        content: CheckUndefined(data.srcInstituteAmount1),
        onclick: undefined,
      },
      {
        id: 7,
        header: `${data.srcLabel2} Amount`,
        content: CheckUndefined(data.srcInstituteAmount2),
        onclick: undefined,
      },
    ];

    const chunkArray = (arr: Row[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);

        while (chunk.length < chunkSize) {
          chunk.push({ id: -1, header: '', content: '' });
        }

        result.push(chunk);
      }
      return result;
    };

    const getColumns = () => {
      const width = window.innerWidth;
      if (width < 768) return 4;
      if (width < 900) return 4;
      return 4;
    };

    const chunkedRows = chunkArray(rows, getColumns());
    return (
      <>
        <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '90%' : '100%' }}>

          <Box sx={{ overflowX: 'auto', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
            {chunkedRows.map((chunk, index) => (
              <Table key={index} sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    {chunk.map((row) => (
                      <TableCell key={row.id} align="center" sx={{
                        borderRight: '1px solid #ccc',
                        padding: '6px',
                        fontFamily: 'system-ui, sans-serif',
                        width: isMobile ? '150px' : '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        fontSize: '12px',
                        backgroundColor: '#f5e9e9',
                        color: 'black',
                        borderTop: 'none',
                        borderBottom: 'none'
                      }}>{row.header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {chunk.map((row) => (
                      <TableCell key={row.id} align="center" sx={{
                        borderRight: '1px solid #ccc',
                        padding: '6px',
                        fontFamily: 'system-ui, sans-serif',
                        width: isMobile ? '150px' : '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        fontSize: '12px',
                        color: row.onClick ? 'blue' : row.header === 'Response Code' ? 'green' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                        fontWeight: row.header === 'Response Code' ? 'bold' : '',
                        borderTop: 'none',
                        borderBottom: 'none',
                        cursor: row.onClick ? 'pointer' : 'default'
                      }}
                        onClick={row.onClick}
                      >{row.content || ''}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </Box>
        </Container>
      </>
    )


  };

  const destinationDetailTable = () => {
    type Row = {
      id: number;
      header: string;
      content: string;
      onClick?: () => void;
    };
    const rows = [
      {
        id: 1,
        header: 'Destination Institute',
        content: CheckUndefined(data.dstInstitute),
        onclick: undefined,
      },
      {
        id: 2,
        header: data.dstLabel1,
        content: (
          <div
            className="grid-item-mc bg-fff   row-data-pointer"
            style={{ color: '#3b0607', textAlign: 'center' }}
          >
            {CheckUndefined(data.dstInstituteName1)}
          </div>
        ),
        onclick: undefined,
      },
      {
        id: 3,
        header: data.dstLabel2,
        content: CheckUndefined(data.dstInstituteName2),
        onclick: undefined,
      },
      {
        id: 4,
        header: 'Destination Ref No',
        content: CheckUndefined(data.dstRefNo),
        onclick: undefined,
      },
      {
        id: 5,
        header: `${data.dstLabel1} Amount`,
        content: CheckUndefined(data.dstInstituteAmount1),
        onclick: undefined,
      },
      {
        id: 6,
        header: `${data.dstLabel2} Amount`,
        content: CheckUndefined(data.dstInstituteAmount2),
        onclick: undefined,
      },
      {
        id: 7,
        header: 'Conversion Rate',
        content: CheckUndefined(data.convRate),
        onclick: undefined,
      },
      {
        id: 8,
        header: 'Conversion Date',
        content: CheckUndefined(data.convDate),
        onclick: undefined,
      },
    ];
    const chunkArray = (arr: Row[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
      }
      return result;
    };

    const getColumns = () => {
      const width = window.innerWidth;
      if (width < 768) return 4;
      if (width < 900) return 4;
      return 4;
    };

    const chunkedRows = chunkArray(rows, getColumns());
    return (
      <>
        <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '90%' : '100%' }}>
          <Box sx={{ overflowX: 'auto', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
            {chunkedRows.map((chunk, index) => (
              <Table key={index} sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    {chunk.map((row) => (
                      <TableCell key={row.id} align="center" sx={{
                        borderRight: '1px solid #ccc',
                        padding: '6px',
                        fontFamily: 'system-ui, sans-serif',
                        width: isMobile ? '150px' : '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        fontSize: '12px',
                        backgroundColor: '#f5e9e9',
                        color: 'black',
                        borderTop: 'none',
                        borderBottom: 'none'

                      }}>{row.header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {chunk.map((row) => (
                      <TableCell key={row.id} align="center" sx={{
                        borderRight: '1px solid #ccc',
                        padding: '6px',
                        fontFamily: 'system-ui, sans-serif',
                        width: isMobile ? '150px' : '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        fontSize: '12px',
                        color: row.onClick ? 'blue' : row.header === 'Response Code' ? 'green' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                        fontWeight: row.header === 'Response Code' ? 'bold' : '',
                        borderTop: 'none',
                        borderBottom: 'none',
                        cursor: row.onClick ? 'pointer' : 'default'
                      }}
                        onClick={row.onClick}
                      >{row.content}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </Box>
        </Container>
      </>
    )
  };

  const subsequentLinkedTable = () => {
    type Row = {
      id: number;
      header: string;
      content: string;
      onClick?: () => void;
    };
    const rowsOri = [
      {
        id: 1,
        header: 'Transaction ID',
        content: CheckUndefined(data.oriTransId),
        onClick: () => handleClick(data.oriTransId),
        color: 'darkblue'
      },
      {
        id: 2,
        header: 'Global ID',
        content: CheckUndefined(data.oriGlobalId),
        onClick: () => {
          handleNavigateByGlobalId(
            data.globalId,
            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
            '00:00:01',
            '23:59:59'
          );
        },
        color: 'blue'
      },
      {
        id: 3,
        header: 'Product Feature',
        content: CheckUndefined(data.oriProductFeatureName),
        color: 'red'
      },
      {
        id: 4,
        header: 'Transaction Date/Time',
        content: CheckUndefined(data.oriTransactionDateTime)
      },
      {
        id: 5,
        header: 'STAN',
        content: CheckUndefined(data.oriStan)
      },
      {
        id: 6,
        header: 'QR Ref No',
        content: CheckUndefined(data.qrAuthDetails?.qrRefNo),
        color: 'magenta'
      },
      {
        id: 7,
        header: 'RRN',
        content: CheckUndefined(data.oriRrn)
      },
      {
        id: 8,
        header: 'Invoice Number',
        content: CheckUndefined(data.oriInvNo)
      },
      {
        id: 9,
        header: 'Transaction Amount',
        content: CheckUndefined(data.srcInstituteAmount)
      },
      {
        id: 10,
        header: 'Response Code',
        content: CheckUndefined(data.oriRspnCode),
        color: CheckUndefined(data.oriRspnCode) === '00 - Success' ? 'green' : 'red',
        fontWeight: 'bold'
      }
    ];
    const rowsCancelation = [
      { id: 1, header: 'Transaction ID', content: `${CheckUndefined(data.transId)}`, onClick: () => handleClick(data.transId) },
      {
        id: 2, header: 'Global ID', content: `${CheckUndefined(data.globalId)}`, onClick: () => {
          handleNavigateByGlobalId(
            data.globalId,
            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
            '00:00:01',
            '23:59:59'
          );
        }
      },
      { id: 3, header: 'Product Feature', content: `${CheckUndefined(data.productFeatureName)}` },
      { id: 4, header: 'Transaction Date/Time', content: `${CheckUndefined(data.transactionDateTime)}` },
      { id: 5, header: 'STAN', content: `${CheckUndefined(data.stan)}` },
      { id: 6, header: 'QR Ref No', content: `${CheckUndefined(data.qrAuthDetails?.qrRefNo)}`, style: { color: 'magenta' } },
      { id: 7, header: 'RRN', content: `${CheckUndefined(data.rrn)}` },
      { id: 8, header: 'Invoice Number', content: `${CheckUndefined(data.invNo)}` },
      { id: 9, header: 'Transaction Amount', content: `${CheckUndefined(data.srcInstituteAmount1)}` },
      {
        id: 10, header: 'Response Code', content: `${CheckUndefined(data.rspnCode)}`,
        style: { color: CheckUndefined(data.rspnCode) === '00 - Success' ? 'green' : 'red', fontWeight: 'bold' }
      },
    ];

    const chunkArray = (arr: Row[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
      }
      return result;
    };

    const getColumns = () => {
      const width = window.innerWidth;
      if (width < 768) return 5;
      if (width < 900) return 5;
      return 5;
    };

    const chunkedRows = chunkArray(rowsOri, getColumns());
    const chunkedRows2 = chunkArray(rowsCancelation, getColumns());

    return (
      (CheckUndefined(data.oriTransId) !== '-') ?
        <>
          <div style={{ paddingLeft: '0px', marginLeft: '0px' }}>
            <p className=" py-2" style={{ fontSize: '13px' }}>Linked #2 : {CheckUndefined(data.oriProductFeatureInd)} {CheckUndefined(data.oriProductFeatureName)} </p>
          </div>
          <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '45%' : '100%' }}>

            <Box sx={{ overflowX: 'auto', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
              {chunkedRows.map((chunk, index) => (
                <Table key={index} sx={{ tableLayout: 'fixed', width: '100%' }}>
                  <TableHead>
                    <TableRow>
                      {chunk.map((row) => (
                        <TableCell key={row.id} align="center" sx={{
                          borderRight: '1px solid #ccc',
                          padding: '6px',
                          fontFamily: 'system-ui, sans-serif',
                          width: isMobile ? '150px' : '100px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                          fontSize: '12px',
                          backgroundColor: '#DA4848',
                          color: 'white',
                          borderTop: 'none',
                          borderBottom: 'none'

                        }}>{row.header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {chunk.map((row) => (
                        <TableCell key={row.id} align="center" sx={{
                          borderRight: '1px solid #ccc',
                          padding: '6px',
                          fontFamily: 'system-ui, sans-serif',
                          width: isMobile ? '150px' : '100px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                          fontSize: '12px',
                          color: row.onClick ? 'blue' : row.header === 'Response Code' && row.content === '00 - Success' ? 'green' : row.header === 'Response Code' && row.content !== '00 - Success' ? 'red' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                          fontWeight: row.header === 'Response Code' ? 'bold' : '',
                          borderTop: 'none',
                          borderBottom: 'none',
                          cursor: row.onClick ? 'pointer' : 'default'
                        }}
                          onClick={row.onClick}
                        >{row.content}</TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </Box>

          </Container>
        </> : data.cancellationDetailList && data.cancellationDetailList.length > 0 ? (
          data.cancellationDetailList.map((item: any, index: number) => (
            <div className="p-0 m-0 rounded">
              <div style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                <p className=" pb-2" style={{ fontSize: '13px' }}>Linked #{index + 1} : {CheckUndefined(item.prodFeatureInd)} {CheckUndefined(item.prodFeatureName)} </p>
              </div>
              <div className="pb-2">
                <div className="grid-container-transdetails rounded border" style={{ overflow: isMobile ? 'auto' : 'unset', width: isMobile ? '90%' : '' }} >
                  <div className="grid-transdetail">
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  tr-id-140" style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>Transaction ID</div>
                      <div style={{ fontFamily: 'system-ui, sans-serif', fontSize: '12px' }}>
                        <div className="grid-item-mc bg-fff   tr-id-140 row-data-pointer" style={{ color: "darkblue", maxWidth: '120px', fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }}
                          onClick={() => {
                            handleClick(item.transId);
                          }}>{CheckUndefined(item.transId)}</div>
                      </div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  " style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }} >Global ID</div>
                      <div className="grid-item-mc bg-fff   row-data-pointer"
                        style={{ color: "blue", maxWidth: '120px', fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }} onClick={(e) => {
                          handleNavigateByGlobalId(
                            data.globalId,
                            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                            format(new Date(data.transactionDateTime.slice(0, 10)), 'yyyy-MM-dd'),
                            '00:00:01',
                            '23:59:59'
                          );
                        }}
                      >{CheckUndefined(item.globalId)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  tr-id-100" style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>Product Feature</div>
                      <div className="grid-item-mc bg-fff  " style={{ color: 'red', fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }} >{CheckUndefined(item.prodFeatureName)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  tr-id-170 " style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', minWidth: '170px', fontWeight: 'bold' }}>Transaction Date/Time</div>
                      <div className="grid-item-mc bg-fff   tr-id-170" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }}>{CheckUndefined(item.transDateTime)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  " style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>STAN</div>
                      <div className="grid-item-mc bg-fff  " style={{ fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }}>{CheckUndefined(item.stan)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  " style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>QR Ref No</div>
                      <div className="grid-item-mc bg-fff  " style={{ color: 'magenta', fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }}>{CheckUndefined(data.qrAuthDetails?.qrRefNo)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  " style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>RRN</div>
                      <div className="grid-item-mc bg-fff  " style={{ fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }}>{CheckUndefined(item.refNo)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  " style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>Invoice Number</div>
                      <div className="grid-item-mc bg-fff  " style={{ fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }}>{CheckUndefined(item.invNo)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  " style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>Transaction Amount</div>
                      <div className="grid-item-mc bg-fff  " style={{ fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center' }}>{CheckUndefined(item.srcInstituteAmount)}</div>
                    </div>
                    <div className="col bg-fff" style={{ borderRight: '1px solid #ccc' }}>
                      <div className="grid-item-mc  tr-id-140" style={{ backgroundColor: '#bf0000', fontFamily: 'system-ui, sans-serif', color: 'white', fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>Response Code</div>
                      <div className="grid-item-mc bg-fff  " style={{ fontFamily: 'system-ui, sans-serif', fontSize: '12px', textAlign: 'center', color: item.rspnCode === '00 - Success' ? 'green' : 'red', fontWeight: 'bold' }}>{CheckUndefined(item.rspnCode)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            // <div className="p-0 m-0 border rounded mb-2">
            //   <div>
            //     <div className="d-flex justify-content-between py-2  px-1">
            //       <div className="w-150px   d-flex align-items-center word-wrap border-0  ">Transaction ID</div>
            //       <div className="w-140px   d-flex align-items-center word-wrap border-0  ">Global ID</div>
            //       <div className="w-50px  d-flex align-items-center word-wrap border-0  ">Transaction Type</div>
            //       <div className="w-100px  d-flex align-items-center word-wrap border-0  ">Transaction Date Time</div>
            //     </div>
            //     <div className={`d-flex justify-content-between align-items-center py-2 px-1  bg-fff  `}>
            //       <div className="w-150px word-wrap d-flex justify-content-between align-items-center row-data-pointer" style={{ color: "teal" }}
            //         onClick={() => {
            //           // setModal("modalCondition");
            //           handleClick(item.transId);

            //         }}>
            //         {CheckUndefined(item.transId)}</div>
            //       <div className="w-140px word-wrap d-flex justify-content-between align-items-center row-data-pointer" style={{ color: "blue" }} onClick={(e) => {
            //         dispatchGlobal(resetAllAction(item.globalId))
            //         navigate(`${Page2FrameworkRoute}?globalId=${item.globalId}`)
            //       }}>
            //         {CheckUndefined(item.globalId)}</div>
            //       <div className="w-50px word-wrap d-flex justify-content-between align-items-center">{CheckUndefined(item.transType)}</div>
            //       <div className="w-100px word-wrap d-flex justify-content-between align-items-center">{CheckUndefined(item.transDateTime)}</div>
            //     </div>
            //   </div>
            //   <div>
            //     <div className="d-flex justify-content-between py-2 bg-b5  px-1">
            //       <div className="w-150px   d-flex align-items-center word-wrap border-0  ">Invoice Number</div>
            //       <div className="w-140px   d-flex align-items-center word-wrap border-0  ">RRN</div>
            //       <div className="w-50px  d-flex align-items-center word-wrap border-0  ">STAN</div>
            //       <div className="w-100px  d-flex align-items-center word-wrap border-0  ">Transaction Amount</div>
            //     </div>
            //     <div className={`d-flex justify-content-between align-items-center py-2 px-1  bg-bl5  `}>
            //       <div className="w-150px word-wrap d-flex justify-content-between align-items-center">
            //         {CheckUndefined(item.invNo)}</div>
            //       <div className="w-140px word-wrap d-flex justify-content-between align-items-center">{CheckUndefined(item.refNo)}</div>
            //       <div className="w-50px word-wrap d-flex justify-content-between align-items-center">{CheckUndefined(item.stan)}</div>
            //       <div className="w-100px word-wrap d-flex justify-content-between align-items-center">{CheckUndefined(item.srcInstituteAmount)}</div>
            //     </div>
            //   </div>

            // </div>
          ))) : (<></>)
    )
  };
  const merchantDetailTable = () => {
    type Row = {
      id: number;
      header: string;
      content: string;
      onClick?: () => void;
    };
    const rows = [
      { id: 1, header: 'Merchant Name', content: `${CheckUndefined(data.merchantName)}` },
      { id: 2, header: 'MID', content: `${CheckUndefined(data.mid)}` },
      { id: 3, header: 'Sub MID', content: `${CheckUndefined(data.subMid)}` },
      { id: 4, header: 'Member MID', content: `${CheckUndefined(data.memberMid)}` },
      { id: 5, header: 'Sub Merchant Name', content: `${CheckUndefined(data.subMerchantName)}` },
      { id: 6, header: 'Merchant Country', content: `${CheckUndefined(data.merchantCountry)}` },
      { id: 7, header: 'TID', content: `${CheckUndefined(data.tid)}` },
      { id: 8, header: 'MCC', content: `${CheckUndefined(data.mcc)}` },
      { id: 9, header: 'Merchant Post Entry Mode / Channel', content: `${CheckUndefined(data.merchant_post_code)}` },
    ];
    const chunkArray = (arr: Row[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);

        while (chunk.length < chunkSize) {
          chunk.push({ id: -1, header: '', content: '' });
        }

        result.push(chunk);
      }
      return result;
    };

    const getColumns = () => {
      const width = window.innerWidth;
      if (width < 768) return 4;
      if (width < 900) return 4;
      return 5;
    };

    const chunkedRows = chunkArray(rows, getColumns());
    return (
      <>
        <Container style={{ margin: '0px', padding: '0px', width: isMobile ? '90%' : '100%' }}>

          <Box sx={{ overflowX: 'auto', borderLeft: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', }}>
            {chunkedRows.map((chunk, index) => (
              <Table key={index} sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    {chunk.map((row) => (
                      <TableCell key={row.id} align="center" sx={{
                        borderRight: '1px solid #ccc',
                        padding: '6px',
                        fontFamily: 'system-ui, sans-serif',
                        width: isMobile ? '150px' : '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        fontSize: '12px',
                        backgroundColor: '#f5e9e9',
                        color: 'black',
                        borderTop: 'none',
                        borderBottom: 'none'
                      }}>{row.header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {chunk.map((row) => (
                      <TableCell key={row.id} align="center" sx={{
                        borderRight: '1px solid #ccc',
                        padding: '6px',
                        fontFamily: 'system-ui, sans-serif',
                        width: isMobile ? '150px' : '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        fontSize: '12px',
                        color: row.onClick ? 'blue' : row.header === 'Response Code' ? 'green' : row.header === 'Product Feature' ? 'red' : row.header === 'QR Ref No' ? 'magenta' : 'black',
                        fontWeight: row.header === 'Response Code' ? 'bold' : '',
                        borderTop: 'none',
                        borderBottom: 'none',
                        cursor: row.onClick ? 'pointer' : 'default'
                      }}
                        onClick={row.onClick}
                      >{row.content || ''}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </Box>
        </Container>
      </>
    )

  };




  let widthWindows_ = window.innerWidth;
  const isMobile = window.innerWidth <= 768;


  return (
    <><div style={{ position: 'relative' }}>{props.detailModal === true && <Modal
      open={props.detailModal}
      onCancel={(e) => setModal("modalCondition")}
      closable={false}
      maskClosable={false}
      mask={true}
      maskStyle={{ zIndex: 15 }}
      style={{
        position: 'fixed',
        top: modalPosition.y,
        left: modalPosition.x,
        right: "0%",
        minWidth: isMobile ? "" : "min-content",
        maxWidth: widthWindows_,
        zIndex: 90,
      }}
      bodyStyle={{
        maxHeight: '90vh',
        // overflowY: 'auto',
        marginLeft: isMobile ? "0px" : "2px",
        marginRight: "0px",
        marginBottom: "0px",
        marginTop: '5px'
      }}
      footer={false}
    > <div onMouseDown={handleMouseDown} style={{ cursor: 'move', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px', borderBottom: '1px solid #ccc' }}>
        <div className="modal-header c-acr1">
          <p style={{ margin: '0px', paddingBottom: '12px', fontSize: isMobile ? '13px' : '16px' }}>Transaction ID: {data.transId}</p>
        </div>
        <div style={{ paddingBottom: '12px' }}><IconButton aria-label="close" style={{ margin: '0px', padding: '0px' }} onClick={() => setModal("modalCondition")}>
          <CloseIcon style={{ fontSize: '20px', padding: '0px', margin: '0px' }} />
        </IconButton></div>
      </div>
      <div style={{ overflow: 'auto' }}>
        <ResizableBox height={statePage.height} width={statePage.width} onResize={onResize} minConstraints={[300, 300]}>
          <div className="">
            <div className="mx-2">
              <div className="d-flex " >
                <div className="flex-fill">
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap my-1 ">
                      <div className="row my-1 w-100 p-0">
                        <div className="p-0 h-30px">
                          <p className="w-700 c-acr1" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '13px', fontWeight: 'bold' }} >Transactions Details</p>
                        </div>
                        {transactionsDetailTable()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-lg-row " style={{ width: isMobile ? '' : `${widthWindows_ - 120}px` }}>
                <div className="flex-fill mb-3 mb-lg-0 " style={{ width: isMobile ? '' : `${widthWindows_ - 100}px` }}>
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap my-1">
                      <div className="row my-1 w-100 p-0">
                        <div className="p-0 h-30px">
                          <p className="w-700 c-acr1" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '13px', fontWeight: 'bold' }} >Merchant Details</p>
                        </div>
                        {merchantDetailTable()}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider type="vertical" className="m-1 d-none d-lg-block" />
                <div className="flex-fill" style={{ width: isMobile ? '' : `${widthWindows_ - 170}px` }}>
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap my-1">
                      <div className="row my-1 w-100 p-0">
                        <div className="p-0 h-30px">
                          <p className="w-700 c-acr1" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '13px', fontWeight: 'bold' }} >Wallet Info</p>
                        </div>
                        {settlementDetailsTable()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-lg-row " style={{ width: isMobile ? '' : `${widthWindows_ - 120}px` }}>
                <div className="flex-fill mb-3 mb-lg-0" style={{ width: isMobile ? '' : `${widthWindows_ - 100}px` }}>
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap my-1">
                      <div className="row my-1 w-100 p-0">
                        <div className="p-0 h-30px">
                          <p className="w-700 c-acr1" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '13px', fontWeight: 'bold' }} >Source Details</p>
                        </div>
                        {sourceDetailTable()}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider type="vertical" className="m-1 d-none d-lg-block" />
                <div className="flex-fill" style={{ width: isMobile ? '' : `${widthWindows_ - 170}px` }}>
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap my-1">
                      <div className="row my-1 w-100 p-0">
                        <div className="p-0 h-30px">
                          <p className="w-700 c-acr1" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '13px', fontWeight: 'bold' }} >Destination Details</p>
                        </div>
                        {destinationDetailTable()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-lg-row " style={{ width: isMobile ? '' : `${widthWindows_ - 120}px` }}>
                <div className="flex-fill mb-3 mb-lg-0" style={{ width: isMobile ? '' : `${widthWindows_ - 150}px` }}>
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap my-1">
                      <div className="row my-1 w-100 p-0">
                        <div className="p-0 h-30px">
                          <p className="w-700 c-acr1" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '13px', fontWeight: 'bold' }} >Previous Linked</p>
                        </div>
                        {qrAuthDetailTable()}
                      </div>
                    </div>
                  </div>
                </div>
                <Divider type="vertical" className="m-1 d-none d-lg-block" />
                <div className="flex-fill" style={{ width: isMobile ? '' : `${widthWindows_ - 200}px` }}>
                  <div className="d-flex flex-column wd-webkit">
                    <div className="d-flex wrap my-1">
                      {data.cancellationDetailList && data.cancellationDetailList.length > 0 ?
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                            <p className="w-700 c-acr1" style={{ fontFamily: 'system-ui, sans-serif', fontSize: '13px', fontWeight: 'bold' }} >Subsequent Linked</p>
                          </div>
                          {subsequentLinkedTable()}
                        </div> : <div></div>}
                    </div>
                  </div>
                </div>
              </div>
              {(CheckUndefined(data.oriTransId) !== '-') ?
                <div className="d-flex " style={{ width: isMobile ? '' : `${widthWindows_ - 120}px` }}>
                  <div className="flex-fill" style={{ width: isMobile ? '' : `${widthWindows_ - 140}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap ">
                        <div className="row  w-100 p-0">

                          {subsequentLinkedTable()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider type="vertical" className="m-1" />
                  <div className="flex-fill" style={{ width: isMobile ? '' : `${widthWindows_ - 200}px` }}>
                    <div className="d-flex flex-column wd-webkit">
                      <div className="d-flex wrap  ">
                        <div className="row my-1 w-100 p-0">
                          <div className="p-0 h-30px">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <></>}
            </div>
          </div>
        </ResizableBox>
      </div>
    </Modal>}


      <ModalUnmatchedList
        modalDetail={state.modalDetail}
        setModal={() => dispatch({ type: "modalDetail", value: !state.modalDetail })}
        dispatchFirstParent={dispatchParent}
        stateFirstParent={stateParent}
        stateParent={state}
        stateOptions={stateOptions} />
    </div></>
  );
};

interface test {
  data: ModelDetailTransactionStatus | null, modalDetail: false
}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: test = {
  data: null,
  modalDetail: false
};
function reducer(state: any, action: any) {

  switch (action.type) {
    case 'data':
      return {
        ...state,
        allData: action.value,
        data: {
          authId: action.value.authId,
          transAuthId: action.value.transId,
          transactionDateTime: action.value.transactionDateTime,
          issAmount: action.value.issAmount,
          productTypeName: action.value.productTypeName,
          localDateTime: action.value.localDateTime,
          iss: action.value.iss,
          issSwitcherAmount: action.value.issSwitcherAmount,
          productFeatureName: action.value.productFeatureName,
          acqSwitcherAmount: action.value.acqSwitcherAmount,
          mti: action.value.mti,
          issSwitcher: action.value.issSwitcher,
          acqAmount: action.value.acqAmount,
          stan: action.value.stan,
          acqSwitcher: action.value.acqSwitcher,
          convRate: action.value.convRate,
          procCode: action.value.procCode,
          acq: action.value.acq,
          convDate: action.value.convDate,
          rrn: action.value.rrn,
          mid: action.value.mid,
          issSettleDate: action.value.issSettleDate,
          invNo: action.value.invNo,
          tid: action.value.tid,
          issSwitcherSettleDate: action.value.issSwitcherSettleDate,
          apprCode: action.value.apprCode,
          merchantName: action.value.merchantName,
          acqSwitcherSettleDate: action.value.acqSwitcherSettleDate,
          merchantCountry: action.value.merchantCountry,
          acqSettleDate: action.value.acqSettleDate,
          globalId: action.value.globalId,
          mcc: action.value.mcc,
          intnRspnCode: action.value.intnRspnCode,
          isApprCodeIntn: action.value.isApprCodeIntn,

        }
      };
    case "modalDetail":
      return {
        ...state,
        modalDetail: action.value,
      };
    case "handleClick":
      return {
        ...state,
        transId: action.value,
        modalDetail: !state.modalDetail,
      };
    case "transId":
      return {
        ...state,
        transId: action.value,
      };
    default:
      throw new Error();
  }
}

const initialStatePage = {
  bounds: false,
  //
  data: null,
  //
  id: "",
  //
  width: window.innerWidth <= 768 ? window.innerWidth : 1400,
  height: 600,
};

const reducerStatePage = (state: any, action: any) => {
  switch (action.type) {
    case "resize":
      return {
        ...state,
        width: action.value.width,
        height: action.value.height,
      };

    default:
      throw new Error();
  }
};


// {isMobile ?
//   <>
//     <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>

//       {data.cancellationDetailList && data.cancellationDetailList.length > 0 ?
//         <>
//           <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 150}px` }}>
//             <div className="d-flex flex-column wd-webkit">
//               <div className="d-flex wrap my-1">
//                 <div className="row my-1 w-100 p-0">
//                   <div className="p-0 h-30px">
//                     <p className="w-700  s-1420 border-bottom pb-1">Cancelled Transaction Details</p>
//                   </div>
//                   {subsequentLinkedTableMobile()}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </> : (CheckUndefined(data.oriTransId) !== '-') ?
//           <>
//             <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
//               <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 150}px` }}>
//                 <div className="d-flex flex-column wd-webkit">
//                   <div className="d-flex wrap my-1">
//                     <div className="row my-1 w-100 p-0">
//                       <div className="p-0 h-30px">
//                         <p className="w-700  s-1420 border-bottom pb-1">Origin Transaction Details (Cacellation Only)</p>
//                       </div>
//                       {subsequentLinkedTableMobile()}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </> :
//           <></>}
//       <div className="flex-fill" style={{ width: `${widthWindows_ - 200}px` }}>
//         <div className="d-flex flex-column wd-webkit">
//           <div className="d-flex wrap my-1">
//             <div className="row my-1 w-100 p-0">
//               <div className="p-0 h-30px">
//                 <p className="w-700  s-1420 border-bottom pb-1">QR Auth Details</p>
//               </div>
//               {qrAuthDetailTableMobile()}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
//   : <>
//     <div className="d-flex flex-column flex-lg-row" style={{ width: `${widthWindows_ - 120}px` }}>
//       <div className="flex-fill mb-3 mb-lg-0" style={{ width: `${widthWindows_ - 150}px` }}>
//         <div className="d-flex flex-column wd-webkit">
//           <div className="d-flex wrap my-1">
//             <div className="row my-1 w-100 p-0">
//               <div className="p-0 h-30px">
//                 <p className="w-700  s-1420 border-bottom pb-1">Previous Linked</p>
//               </div>
//               {qrAuthDetailTable()}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Divider type="vertical" className="m-1 d-none d-lg-block" />
//       <div className="flex-fill" style={{ width: `${widthWindows_ - 200}px` }}>
//         <div className="d-flex flex-column wd-webkit">
//           <div className="d-flex wrap my-1">
//             {data.cancellationDetailList && data.cancellationDetailList.length > 0 ?
//               <div className="row my-1 w-100 p-0">
//                 <div className="p-0 h-30px">
//                   <p className="w-700  s-1420 border-bottom pb-1">Subsequent Linked</p>
//                 </div>
//                 {subsequentLinkedTable()}
//               </div> : <div></div>}
//           </div>
//         </div>
//       </div>
//     </div>
//     {(CheckUndefined(data.oriTransId) !== '-') ?
//       <div className="d-flex " style={{ width: `${widthWindows_ - 120}px` }}>
//         <div className="flex-fill" style={{ width: `${widthWindows_ - 120}px` }}>
//           <div className="d-flex flex-column wd-webkit">
//             <div className="d-flex wrap ">
//               <div className="row  w-100 p-0">

//                 {subsequentLinkedTable()}
//               </div>
//             </div>
//           </div>
//         </div>
//         <Divider type="vertical" className="m-1" />
//         <div className="flex-fill" style={{ width: `${widthWindows_ - 200}px` }}>
//           <div className="d-flex flex-column wd-webkit">
//             <div className="d-flex wrap  ">
//               <div className="row my-1 w-100 p-0">
//                 <div className="p-0 h-30px">
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       : <></>}</>}
import Modal from 'react-modal';
import { ModalStyle } from '../Utils';
import { closeButtonIcon } from '../Assets/Images/svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../Store/User';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { cookiesGlobal } from '../State/Cookies';


Modal.setAppElement('#root');

export const Warning = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    if (privilegeAccess !== null) {
        if (privilegeAccess.length === 0) {
            return (
                <Modal isOpen={true} style={ModalStyle} ariaHideApp={false}>
                    <div className='d-flex flex-column wd-300'>
                        <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                            <p className='w-700 Lato-700 text-danger text-center s-1822'>Warning</p>
                        </div>
                        <div className='d-flex p-20 min-h-60px justify-content-between align-items-center '>
                            <p className='s-1620 mb-30'>You do not have access to all page </p>
                        </div>
                        <button className='d-flex align-items-center justify-content-center br-20 bd-d4 bg-e8 pointer px-3 py-3 text-center' onClick={() => {
                            try {
                                // cookiesGlobal.remove('_userxxxx', { path: '/',  });
                                // cookiesGlobal.remove('_passxxxx', { path: '/',  });
                                localStorage.removeItem('_userxxxx')
                                localStorage.removeItem('_passxxxx')
                                dispatchGlobal(logoutAction(null))
                            } catch (error) {
                                // console.log(error)
                            }
                        }}>
                            <div className='s-1418 c-ff Lato-600 mx-2 text-uppercase  text-center'>Log Out</div>
                        </button>
                    </div>
                </Modal>
            )
        }
    }

    return (
        <Modal isOpen={true} style={ModalStyle}>
            <div className='d-flex flex-column wd-300'>
                <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                    <p className='w-700 Lato-700 text-danger text-center s-1822'>Warning</p>
                </div>
                <div className='d-flex p-20 min-h-60px justify-content-between align-items-center '>
                    <p className='s-1620 mb-30'>You do not have access to this page </p>
                </div>
                <button className='d-flex align-items-center justify-content-center br-20 bd-d4 bg-e8 pointer px-3 py-3 text-center' onClick={() => {
                    navigate('/login');
                }}>
                    <div className='s-1418 c-ff Lato-600 mx-2 text-uppercase  text-center'>Reload</div>
                </button>
            </div>
        </Modal>
    )
} 

// import { useSelector } from "react-redux";
// import { CheckUndefined } from "../../Utils";
// import { Divider } from "antd";

// const HeaderComponent = ({ ...props }) => {
//     const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
//     return (
//         <div className='d-flex justify-content-between px-4 '>
//             {
//                 props.label.map((item: any, i: any) => {
//                     if (item.val === 'action') {
//                         if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
//                             return (
//                                 props.checkIndex(`${item.val}`) && (
//                                     <p key={i} className={` s-1418 Lato-700 ${item.width} text-center `}>
//                                         {item.field}
//                                     </p>
//                                 )
//                             );
//                         } else {
//                             return <></>;
//                         }
//                     } else {
//                         return (
//                             props.checkIndex(`${item.val}`) && (
//                                 <p key={i} className={` s-1418 Lato-700 ${item.width} `}>
//                                     {item.field}
//                                 </p>
//                             )
//                         );
//                     }
//                 })
//             }
//         </div>
//     )
// }
// export const TableSchedulerExecution = ({ ...props }) => {
//     const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

//     return (
//         <div className='d-flex flex-column w-100 overflow mb-2'>
//             <div className={`d-flex flex-column ${props.widthTable}`}>
//                 <HeaderComponent
//                     codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
//                     codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
//                     label={props.label}
//                     data={props.data}
//                     position={props.position}
//                     view={props.view}
//                     customButtonAction={props.customButtonAction}
//                     checkIndex={props.checkIndex}
//                     widthTable={props.widthTable}
//                     stateOptions={props.stateOptions}
//                 />

//                 <Divider className="my-2"/>

//                 {props.data.map((item: any, index: any) => {
//                     let number = index + ((props.currentPage - 1) * props.view)
//                         return (
//                             <div key={number} className={`d-flex justify-content-between align-items-center min-h-50px px-4 table-data-pointer  ${number % 2 === 0 ? 'bg-f4' : ''} `} onClick={() => props.editData(item)} >
//                                 {props.label.map((element1: any) => {
//                                     if (element1.val === 'no') {
//                                         return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{number + 1}</p>;
//                                     } else if (element1.val === 'action') {
//                                         if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
//                                             return props.checkIndex(`${element1.val}`) && <div className={`d-flex  s-1418 Lato-400 word-wrap ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
//                                         } else {
//                                             return <></>;
//                                         }
//                                     }  
//                                     else if (element1.val === 'batchProgramId') {
//                                         let show = `${CheckUndefined(item.batchId)} ${CheckUndefined(item.batchProgramId)}`;
//                                         return props.checkIndex(`${element1.val}`) && <p className={`c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
//                                     } 
//                                     else {
//                                         return props.checkIndex(`${element1.val}`) && <p className={` s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
//                                     }
//                                 })}
//                             </div>
//                         );
//                 })}
//             </div>
//         </div>
//     );
// }

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { CheckUndefined } from '../../Utils';
import { ButtonAdd, SelectView } from '../../Components';
import { Divider } from 'antd';


export const TableSchedulerExecution = ({ ...props }) => {
    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom, reloadDataComponent,
        handleStateParent,
        setPagination,
        pagination,
    } = props;
    const columns: any = useMemo(
        () => [
            {
                header: 'Batch TX Date',
                accessorKey: 'batchTxDate',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchTxDate)}`,
            },
            {
                header: 'Batch Execution Type',
                accessorKey: 'batchExecType',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchExecType)}`,
            },
            {
                header: 'Batch Group Path',
                accessorKey: 'batchGroupPath',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchGroupPath)}`,
            },
            {
                header: 'Batch Job Detail ID',
                accessorKey: 'batchJobDetailId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchJobDetailId)}`,
            },
            {
                header: 'Batch Last Execution',
                accessorKey: 'batchLastExec',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchLastExec)}`,
            },
            {
                header: 'Batch Messagge',
                accessorKey: 'batchMessagge',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchMessagge)}`,
            },
            {
                header: 'Batch Program Id',
                accessorKey: 'batchProgId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchProgId)}`,
            },
            {
                header: 'Batch Status',
                accessorKey: 'batchStatus',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchStatus)}`,
            },
            {
                header: 'Change Dttm',
                accessorKey: 'chngDttm',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.chngDttm)}`,
            },
            {
                header: 'Change Program Id',
                accessorKey: 'chngProgId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.chngProgId)}`,
            },
            {
                header: 'Change User Id',
                accessorKey: 'chngUserId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.chngUserId)}`,
            },
            {
                header: 'Input Dttm',
                accessorKey: 'inpDttm',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.inpDttm)}`,
            },
            {
                header: 'Input Progrram Id',
                accessorKey: 'inpProgId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.inpProgId)}`,
            },
            {
                header: 'Input User Id',
                accessorKey: 'inpUserId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.inpUserId)}`,
            },
            {
                header: 'Batch Log Id',
                accessorKey: 'batchLogId',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchLogId)}`,
            },
            {
                header: 'Batch Log Name',
                accessorKey: 'batchLogName',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.batchLogName)}`,
            },
            {
                header: 'Count',
                accessorKey: 'count',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.count)}`,
            },


        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: {
            pagination,
            globalFilter: state.search || ''
        },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        // enableGlobalFilter: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                // onClick: () => props.handleShowModal(row.original),
                onClick: () =>props.editData(row.original),

                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        muiSearchTextFieldProps: {
            placeholder: `Search By ${state.SearchByPlaceHolder}`,
            size: 'small',
            title: `Search By ${state.SearchByPlaceHolder}`,
        },

        onGlobalFilterChange: (filterValue) => {
            if (filterValue === undefined) {
                handleStateParent('search', '')
            }
            handleStateParent('search', filterValue)
        },

        renderTopToolbarCustomActions: ({ table }) => (
            <div className="justify-content-between pt-2">
                <div className="col-6 d-flex align-items-center">
                    <p className="Lato-400 s-1822 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                    {/* <Divider type='vertical' />
                    <div className="h-20">
                        <ButtonAdd handleClick={props.addData} codeAccessPrivilege={state.privilegeAccess.add} />
                    </div> */}
                </div>
            </div>
        ),

    });
    return (
        <>
            <div id="table" className="mt-2 mb-5 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    );
};

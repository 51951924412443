
import "react-datepicker/dist/react-datepicker.css";
import { useCallback, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../../../Services';
import { initFunction, reducerOptions, reducerStateSelected, stateOptions, stateSelected } from '../../../State';
import { ModelMemberConn } from './Models/ModelMemberConn';
import { CheckAllFields, CheckUndefined } from '../../../Utils';
import { labelStyle } from '../../../Styles';
// import { DatePicker, DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { SelectOptionsInterface } from '../../../Interface';
import { useDispatch } from 'react-redux';
import { Page2SystemNetworkRoute } from '../../../Routes';
import { ModelModelConnectionDownTime } from './Models/ModelConnectionDownTime';
import { ErrorMessage } from '../../../Components/ErrorMessage';
import { urlSelection } from '../../../Services/API';
import DatePicker from "react-datepicker";

const IOPage: SelectOptionsInterface = {
    ccdOptions: true,
    spfOptions: false,
    memberOptions: false,
    ccgOptions: false,
    logLevel001Options: false,
    logLevel002Options: false,
    routingOptions: false,
    routeSequenceOptions: false,
    privilegeOptions: false,
    countryOptions: false,
    productOptions: false,
    productFeatureOptions: false,
    reasonCodeOptions: false,
    subProductOptions: false,
    exchangeRateOptions: false,
    elementOptions: false,
    qrStandardOptions: false,
    interfaceFieldOptions: false,
    headerFieldOptions: false,
    feeOptions: false,
    interfaceFileOptions: false,
    recordOptions: false,
    interfaceFileItemOptions: false,
    commonCodeOptions:false,resultOptions:false
}

const Detail3 = ({ ...props }) => {
    let { id } = useParams();
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const buttonBack = () => navigate(Page2SystemNetworkRoute);
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);


    const initFunctionCustom = () => {
        API.get({
            bodyCustom: null,
            pathCustom: `member_conn/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            dispatchStatePage({ type: 'setData', value: response.data })
            API.get({
                bodyCustom: null,
                pathCustom: `cdt/${response.data.cdtId}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response2: any) => {
                dispatchStatePage({ type: 'setDataConnectionDownTime', value: response2.data })
            })
        })
    }
    useEffect(() => {
        initFunction(dispatchOptions, statePage, () => { }, null, IOPage, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom()
    }, [])

    // const onOkCdtStart = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    //     if (value !== null) {
    //         let date = new Date(value!.toString());
    //         let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
    //         let hours = date.getHours().toString().padStart(2, '0');
    //         let minutes = date.getMinutes().toString().padStart(2, '0');
    //         let seconds = date.getSeconds().toString().padStart(2, '0');
    //         let timeNow = `${hours}:${minutes}:${seconds}`;
    //         // dispatchStatePage({ type: 'batchRunTime', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
    //         dispatchStatePage({ type: 'cdtStart', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
    //     }
    // };
    // const onOkCdtStop = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    //     if (value !== null) {
    //         let date = new Date(value!.toString());
    //         let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
    //         let hours = date.getHours().toString().padStart(2, '0');
    //         let minutes = date.getMinutes().toString().padStart(2, '0');
    //         let seconds = date.getSeconds().toString().padStart(2, '0');
    //         let timeNow = `${hours}:${minutes}:${seconds}`;
    //         // dispatchStatePage({ type: 'batchRunTime', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
    //         dispatchStatePage({ type: 'cdtStop', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
    //     }
    // };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const updateDataAPI = () => {
        const { memberConnId, memberId, memberConnServer, memberConnPort, memberConnEndpoint, memberConnServerStatus, memberConnIntervalTime, memberConnStatus, memberConnLastEchoDt, memberConnLastTransDt, memberName, memberCode, cdtStart, cdtStop } = statePage.data;
        let newMemberConnStatus = '';
        stateOptions.optionsConnectionStatusDetailCode.forEach((item1: any) => {
            if (item1.value === memberConnStatus) {
            } else {
                newMemberConnStatus = item1.value;
            }
        });
        const data = {
            memberConnId: memberConnId,
            memberId: memberId,
            memberConnServer: memberConnServer,
            memberConnPort: memberConnPort,
            memberConnEndpoint: memberConnEndpoint,
            memberConnServerStatus: memberConnServerStatus,
            memberConnIntervalTime: memberConnIntervalTime,
            memberConnStatus: newMemberConnStatus,
            memberConnLastEchoDt: memberConnLastEchoDt,
            memberConnLastTransDt: memberConnLastTransDt,
            memberName: memberName,
            memberCode: memberCode,
        };
        handleState('errorMessage', ``);
        API.update({
            bodyCustom: data,
            pathCustom: `member_conn`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            buttonBack();
        }).catch((err) => handleState('errorMessage', err))

    };

    const updateDataConnectionDowTimeAPI = () => {
        const { memberConnId, memberId, memberConnServer, memberConnPort, memberConnEndpoint, memberConnServerStatus, memberConnIntervalTime, memberConnStatus, memberConnLastEchoDt, memberConnLastTransDt, memberName, memberCode, cdtStart, cdtStop } = statePage.data;
        const {
            cdtId,
            cdtStatus,
        } = statePage.dataConnectionDownTime;
        let newMemberConnStatus = '';
        stateOptions.optionsConnectionStatusDetailCode.forEach((item1: any) => {
            if (item1.value === memberConnStatus) {
            } else {
                newMemberConnStatus = item1.value;
            }
        });
        const data = {
            memberConnId: memberConnId,
            memberId: memberId,
            memberConnServer: memberConnServer,
            memberConnPort: memberConnPort,
            memberConnEndpoint: memberConnEndpoint,
            memberConnServerStatus: memberConnServerStatus,
            memberConnIntervalTime: memberConnIntervalTime,
            memberConnStatus: newMemberConnStatus,
            memberConnLastEchoDt: memberConnLastEchoDt,
            memberConnLastTransDt: memberConnLastTransDt,
            memberName: memberName,
            memberCode: memberCode,
        };

        const dataConnectionDownTime = {
            cdtId: cdtId,
            memberConnId: memberConnId,
            cdtStart: cdtStart,
            cdtStop: cdtStop,
            cdtStatus: cdtStatus
        }
        const a = CheckAllFields([cdtStart, cdtStop], ['SCHEDULED START', 'SCHEDULED START']);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `member_conn`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
            dispatchStatePage({ type: 'setData', value: response.data })
            API.update({
                    bodyCustom: dataConnectionDownTime,
                    pathCustom: `cdt`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response) => {
                    buttonBack();
                }).catch((err) => handleState('errorMessage', err))
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    const ComponentScheduleStart = useCallback(({...props}) => {
        const {statePage} = props;
        return (
            <div className="w-100" style={{ position: "relative", display: "inline-block"}}>
                <> 
                    <DatePicker
                        className="bd-d4 bg-ff br-5 h-30px s-1418 Lato-700 out-none px-2 w-100"
                        wrapperClassName="w-100 h-100"
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                        timeFormat="HH:mm"
                        showTimeSelect
                        showYearDropdown
                        timeIntervals={5}
                        todayButton="Today"
                        placeholderText="Select date"

                        selected={statePage.data.cdtStart && statePage.data.cdtStart !== ''
                            ? new Date(`${statePage.data.cdtStart}` || '') 
                            : null
                        }
                        onChange={(value: any) => {
                            let date = new Date(value!.toString());
                            let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
                            let hours = date.getHours().toString().padStart(2, '0');
                            let minutes = date.getMinutes().toString().padStart(2, '0');
                            let seconds = date.getSeconds().toString().padStart(2, '0');
                            let timeNow = `${hours}:${minutes}:${seconds}`;
                            dispatchStatePage({ type: 'cdtStart', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
                        }}
                    />
                    <span
                    style={{
                        position: "absolute",
                        right: "10px",
                        top: "46%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        fontSize: "14px",
                    }}
                    >
                        <svg 
                            viewBox="64 64 896 896" 
                            focusable="false" 
                            data-icon="calendar" 
                            width="1em" 
                            height="1em" 
                            fill="#0000003f" 
                            aria-hidden="true">
                                <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                                </path>
                        </svg>
                    </span>
                </> 
            </div>
        )
    }, [statePage.data.cdtStart])
    const ComponentScheduleStop = useCallback(({...props}) => {
        const {statePage} = props;
        return (
            <div className="w-100" style={{ position: "relative", display: "inline-block"}}>
            <> 
                <DatePicker
                    className="bd-d4 bg-ff br-5 h-30px s-1418 Lato-700 out-none px-2 w-100"
                    wrapperClassName="w-100 h-100"
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeFormat="HH:mm"
                    showTimeSelect
                    showYearDropdown
                    timeIntervals={5}
                    todayButton="Today"
                    placeholderText="Select date"
                    selected={statePage.data.cdtStop && statePage.data.cdtStop !== ''
                        ? new Date(`${statePage.data.cdtStop}` || '') 
                        : null
                    }
                    onChange={(value: any) => {
                        let date = new Date(value!.toString());
                        let localDate = moment.utc(value!.toString()).local().format('YYYY-MM-DD hh:mm:ss');
                        let hours = date.getHours().toString().padStart(2, '0');
                        let minutes = date.getMinutes().toString().padStart(2, '0');
                        let seconds = date.getSeconds().toString().padStart(2, '0');
                        let timeNow = `${hours}:${minutes}:${seconds}`;
                        dispatchStatePage({ type: 'cdtStop', value: `${localDate.toString().split(' ')[0]} ${timeNow}` })
                    }}
                />
                <span
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "46%",
                    transform: "translateY(-50%)",
                    pointerEvents: "none",
                    fontSize: "14px",
                }}
                >
                    <svg 
                        viewBox="64 64 896 896" 
                        focusable="false" 
                        data-icon="calendar" 
                        width="1em" 
                        height="1em" 
                        fill="#0000003f" 
                        aria-hidden="true">
                            <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                            </path>
                    </svg>
                </span>
            </> 
        </div>
        )
    }, [statePage.data.cdtStart])
    let selectedConnectionStatus = stateOptions.optionsConnectionStatusDetailCode.filter((item1: any) => item1.value === statePage.data.memberConnStatus)
    let selectedMemberConnServerStatus = stateOptions.optionsServerStatusDetailCode.filter((item1: any) => item1.value === statePage.data.memberConnServerStatus)
    
    return (
        <div className='mb-2 p-0'>
            <h1 className='Lato-600 c-29 s-1822 mb-20 w-600 pointer  mt-3 mb-3' onClick={buttonBack}>
                〱 Details
            </h1>
            <div className=' w-100 '>
                <div className='bg-ff bd-ea br-10'>
                    <div className='gx-5 row container-all-select-custom m-0 mb-10 p-0 w-100' style={{ background: 'white', border: 'none' }}>
                        <div className='row m-0 mb-10 p-0'>
                            <div className='col-1 border pt-3 pb-3'>
                                <p className='c-54 mb6 s-1214 w-100'>Member</p>
                                <p className='c-29 s-1418 Lato-700'>
                                    {CheckUndefined(statePage.data.memberName)}
                                </p>
                            </div>
                            <div className='col-2 border pt-3 pb-3'>
                                <p className='c-54  mb6 s-1214 w-100'>IP Address</p>
                                <p className='c-29 s-1418 Lato-700'>
                                    {CheckUndefined(statePage.data.memberConnServer)}
                                </p>
                            </div>
                            <div className='col-1 border pt-3 pb-3'>
                                <p className='c-54  mb6 s-1214 w-100'>Port</p>
                                <p className='c-29 s-1418 Lato-700'>
                                    {CheckUndefined(statePage.data.memberConnPort)}
                                </p>
                            </div>
                            <div className='col-3 border pt-3 pb-3'>
                                <p className='c-54  mb6 s-1214 w-100'>Last Echo Time</p>
                                <p className='c-29 s-1418 Lato-700'>
                                    {CheckUndefined(statePage.data.memberConnLastEchoDt)}
                                </p>
                            </div>
                            <div className='col-3 border pt-3 pb-3'>
                                <p className='c-54  mb6 s-1214 w-100'>Last Transaction Time</p>
                                <p className='c-29 s-1418 Lato-700'>
                                    {CheckUndefined(statePage.data.memberConnLastTransDt)}
                                </p>
                            </div>
                            <div className='col-2  border pt-3 pb-3'>
                                <p className='c-54  mb6 s-1214 w-100'>Server Status</p>
                                <p className='c-29 s-1418 Lato-700'>
                                    {selectedMemberConnServerStatus.length > 0 ? selectedMemberConnServerStatus[0].label : ''}
                                </p>
                            </div>
                            {/* CHANGE LINE */}

                            <div className='col-12 border pt-2 pb-1 d-flex justify-content-between align-items-center'>
                                <p className='c-54 s-1214 w-100'>
                                    Connection Status : <div className='c-29 s-1418 Lato-700'>
                                        {selectedConnectionStatus.length > 0 ? selectedConnectionStatus[0].label : ''}
                                    </div>
                                </p>
                                <button
                                    className='btn d-flex align-items-center justify-content-center w-90px h-30px p-1014 br-10 bg-e8 text-white Lato-700'
                                    onClick={() => updateDataAPI()}>
                                    <div className='s-1418 c-ff Lato-600 '>
                                        {selectedMemberConnServerStatus.length > 0 ? selectedMemberConnServerStatus[0].value === '002' ? 'START' : 'STOP' : ''}
                                    </div>
                                </button>
                            </div>
                            {/* CHANGE LINE */}
                            <div className='col-12 border pt-2 pb-2 d-flex justify-content-between align-items-end'>
                                <div className='row d-flex align-items-center w-100' style={{marginLeft: '-10px'}}>
                                    <div className='col-xl-4 col-lg-6 col-sm-12 col-xs-12 '>
                                        <p className={labelStyle}>Scheduled Start: </p>
                                        <ComponentScheduleStart  statePage={statePage} />
                                    </div>
                                    <div className='col-xl-4 col-lg-6 col-sm-12 col-xs-12'>
                                        <p className={labelStyle}>Scheduled Stop: </p>
                                        <ComponentScheduleStop statePage={statePage} />
                                    </div>
                                </div>
                                <button
                                    className='btn d-flex align-items-center justify-content-center w-90px h-30px p-1014 br-10 bg-e8 text-white Lato-700'
                                    onClick={() => updateDataConnectionDowTimeAPI()}
                                >
                                    <div className='s-1418 c-ff Lato-600 '>
                                        {selectedMemberConnServerStatus.length > 0 ? selectedMemberConnServerStatus[0].value === '002' ? 'START' : 'STOP' : ''}
                                    </div>
                                </button>


                            </div>
                            <div className='col-12 border pt-2 pb-2 d-flex justify-content-between align-items-end'>
                                <ErrorMessage errorMessage={statePage.errorMessage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    data: new ModelMemberConn({
        id: null,
        memberConnId: null,
        memberId: null,
        memberConnServer: null,
        memberConnPort: null,
        memberConnEndpoint: null,
        memberConnServerStatus: null,
        memberConnIntervalTime: null,
        memberConnStatus: null,
        memberConnLastEchoDt: null,
        memberConnLastTransDt: null,
        memberName: null,
        memberCode: null,
        cdtId: null,
        cdtStart: null,
        cdtStop: null,
    }),
    dataConnectionDownTime: new ModelModelConnectionDownTime({
        id: null,
        cdtId: null,
        memberConnId: null,
        cdtStart: null,
        cdtStop: null,
        cdtStatus: null,
        memberConnServer: null,
        memberConnPort: null,
        memberConnEndpoint: null,
    }),
    errorMessage: "",
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelMemberConn) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }
    for (const key in state.dataConnectionDownTime as ModelModelConnectionDownTime) {
        if (key === action.type) {
            state.dataConnectionDownTime[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelMemberConn({
                    id: null,
                    memberConnId: null,
                    memberId: null,
                    memberConnServer: null,
                    memberConnPort: null,
                    memberConnEndpoint: null,
                    memberConnServerStatus: null,
                    memberConnIntervalTime: null,
                    memberConnStatus: null,
                    memberConnLastEchoDt: null,
                    memberConnLastTransDt: null,
                    memberName: null,
                    memberCode: null,
                    cdtId: null,
                    cdtStart: null,
                    cdtStop: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelMemberConn({
                    id: action.value.memberConnId,
                    memberConnId: action.value.memberConnId,
                    memberId: action.value.memberId,
                    memberConnServer: action.value.memberConnServer,
                    memberConnPort: action.value.memberConnPort,
                    memberConnEndpoint: action.value.memberConnEndpoint,
                    memberConnServerStatus: action.value.memberConnServerStatus,
                    memberConnIntervalTime: action.value.memberConnIntervalTime,
                    memberConnStatus: action.value.memberConnStatus,
                    memberConnLastEchoDt: action.value.memberConnLastEchoDt,
                    memberConnLastTransDt: action.value.memberConnLastTransDt,
                    memberName: action.value.memberName,
                    memberCode: action.value.memberCode,
                    cdtId: action.value.cdtId,
                    cdtStart: action.value.cdtStart,
                    cdtStop: action.value.cdtStop,
                }),
                errorMessage: "",
            };
        case 'setDataConnectionDownTime':
            return {
                ...state,
                dataConnectionDownTime: new ModelModelConnectionDownTime({
                    id: action.value.cdtId,
                    cdtId: action.value.cdtId,
                    memberConnId: action.value.memberConnId,
                    cdtStart: action.value.cdtStart,
                    cdtStop: action.value.cdtStop,
                    cdtStatus: action.value.cdtStatus,
                    memberConnServer: action.value.memberConnServer,
                    memberConnPort: action.value.memberConnPort,
                    memberConnEndpoint: action.value.memberConnEndpoint,
                }),
                errorMessage: "",

            };
        case 'clearDataConnectionDownTime':
            return {
                ...state,
                dataConnectionDownTime: new ModelModelConnectionDownTime({
                    id: null,
                    cdtId: null,
                    memberConnId: null,
                    cdtStart: null,
                    cdtStop: null,
                    cdtStatus: null,
                    memberConnServer: null,
                    memberConnPort: null,
                    memberConnEndpoint: null,
                }),
                errorMessage: "",
            };

        default:
            throw new Error();
    }
}


export default Detail3;


const Page1Code = '471';
const Page2Code = '481';
const Page3Code = '491';
const Page4Code = '501';
const PageQRAuthStatisticCode= '711';
const PageMainDashboardCode = '1001';

const Page5Code = '421';
const Page6Code = '431';
const Page7Code = '441';
const Page8Code = '451';
const Page9Code = '461';

const Page10Code = '61';
const Page11Code = '121';
const Page12Code = '281';

const Page13Code = '131';
const Page14Code = '141';
const Page15Code = '411';

const Page16Code = '321';
const Page17Code = '591';

const Page18Code = '311';

const Page19Code = 'aaaaaaa';
const Page20Code = '51';
const Page21Code = '101';
const Page22Code = '651';
const Page23Code = '661';


const Page24Code = '371';
const Page25Code = '381';
const Page26Code = '391';
const Page27Code = '291';
const Page28Code = '301';
const Page29Code = '171';

const Page30Code = '191';
const Page31Code = '201';
const Page32Code = '341';
const Page33Code = '351';
const Page34Code = '331';


const Page35Code = '601';
const Page36Code = '631';
const Page37Code = '621';
const Page38Code = '611';
const Page39Code = '641';

const Page40Code = '541';
const Page41Code = '551';
const Page42Code = '561';
const Page43Code = '571';

const Page44Code = '241';
const Page45Code = '251';
const Page46Code = '261';
const Page47Code = '271';

const Page48Code = '511';
const Page49Code = '91';
const Page50Code = '111';

const Page51Code = '81';
const Page52Code = '71';


const Page53Code = '671';
const Page54Code = '401';
const Page56Code = '681';

const Page57Code = '701';
const Page57ACode='721';
const Page58Code = '801';
const Page59Code = '901';

const PageUnmatchedListCode='981'
const PageAdjustmentListCode='991'

const PageAlertHistoryCode='1011'


const PageFileTransferMonitoringCode='1021'


export {
    Page1Code,
    Page2Code,
    Page3Code,
    Page4Code,
    Page5Code,
    Page6Code,
    Page7Code,
    Page8Code,
    Page9Code,
    Page10Code,
    Page11Code,
    Page12Code,
    Page13Code,
    Page14Code,
    Page15Code,
    Page16Code,
    Page17Code,
    Page18Code,
    Page19Code,
    Page20Code,
    Page21Code,
    Page22Code,
    Page23Code,
    Page24Code,
    Page25Code,
    Page26Code,
    Page27Code,
    Page28Code,
    Page29Code,
    Page30Code,
    Page31Code,
    Page32Code,
    Page33Code,
    Page34Code,
    Page35Code,
    Page36Code,
    Page37Code,
    Page38Code,
    Page39Code,
    Page40Code,
    Page41Code,
    Page42Code,
    Page43Code,
    Page44Code,
    Page45Code,
    Page46Code,
    Page47Code,
    Page48Code,
    Page49Code,
    Page50Code,
    Page51Code,
    Page52Code,
    Page53Code,
    Page54Code,
    Page56Code,
    Page57Code,
    Page58Code,
    Page57ACode,
    Page59Code,
    PageQRAuthStatisticCode,
    PageUnmatchedListCode,
    PageAdjustmentListCode,
    PageFileTransferMonitoringCode,
    PageMainDashboardCode,
    PageAlertHistoryCode
}
import Modal from 'react-modal';
import { ModalStyle } from '../Utils';
export const ModalDelete = ({ ...props }) => {
    const { deleteItem, setModal, deleteItemAPI, } = props;
    return (
        <Modal isOpen={deleteItem} style={ModalStyle}>
            <div className='bg-ff p-20 flex flex-column center br-20 w-400px'>
                <h2 className='s-24 Lato-600 text-center'>Confirmation</h2>
                <p className='s-1620 text-center'>Are you sure to delete this field?</p>
                <hr />
                <div className='d-flex w-100 justify-content-between'>
                    <button className='btn_ bg-c1 bd-c1' onClick={setModal}>Close</button>
                    <button className='btn_ mx-1 bg-f1 bd-f1' onClick={deleteItemAPI}>Delete</button>
                </div>
            </div>
        </Modal>
    )
}
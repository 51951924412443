import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { DatePickerRighIcon, FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import { format } from 'date-fns';

import Modal from 'react-modal';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalMemberFee = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMember, optionsCommonStatusDetailCode, optionsFeeCalculationMethod, optionsFee } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        const { mfeeCalcType } = statePage;
        if (nameState === 'mfeeRate') {
            if ('012' === mfeeCalcType) {
                if (parseInt(valueState) > 0 && parseInt(valueState) < 101) {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        } else if (nameState === 'mfeeCalcType') {
            if (mfeeCalcType === '012' || '012' === `${valueState}`) {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'mfeeRate', value: '' })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { mfeeId, feeId, memberId, mfeeRate, mfeeStartDate, mfeeEndDate, mfeeStatus, mfeeCalcType, mfeeDesc, memberCode, memberName, feeType, errorMessage } = statePage
        const data = {
            mfeeRate: mfeeRate,
            mfeeStartDate: mfeeStartDate,
            mfeeEndDate: mfeeEndDate,
            memberId: memberId,
            mfeeCalcType: mfeeCalcType,
            feeId: feeId,
            mfeeStatus: mfeeStatus,
            mfeeDesc: mfeeDesc
        }

        const a = CheckAllFields([feeId === null ? '' : feeId, mfeeRate, mfeeStartDate, mfeeEndDate, mfeeStatus, mfeeCalcType, memberId], ['Fee ID', 'Fee Rate', 'Start Date', 'End Date', 'Status', 'Calculation Type', 'Member'])

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-600px'>
                    <div className='d-flex min-h-40px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className={'col-6'}>
                                <p className={labelStyle}>FEE ID</p>
                                <Select styles={inputStyleH25} options={optionsFee} value={optionsFee.filter((option: any) => option.value === statePage.feeId)} onChange={(e) => handleState('feeId', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>RATE</p>
                                <input type='number' min={0} value={statePage.mfeeRate} onChange={(e) => handleState('mfeeRate', e.target.value)} className={inputStyle} />
                            </div>

                            <div className={'col-6'}>
                                <p className={labelStyle}>CALCULATION TYPE</p>
                                <Select styles={inputStyleH25} options={optionsFeeCalculationMethod} value={optionsFeeCalculationMethod.filter((option: any) => option.value === statePage.mfeeCalcType)} onChange={(e) => handleState('mfeeCalcType', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>STATUS</p>
                                <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.mfeeStatus)} onChange={(e) => handleState('mfeeStatus', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>START DATE</p>
                                <DatePickerRighIcon
                                    needTimeInput = {false}
                                    selected= {statePage.mfeeStartDate && statePage.mfeeStartDate !== ''
                                        ? statePage.mfeeStartDate
                                        : null
                                    }
                                    onChange={(date: any) => {
                                        handleState('mfeeStartDate', format(date, 'yyyy-MM-dd'))
                                    }}
                                />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>END DATE</p>
                                <DatePickerRighIcon
                                    needTimeInput = {false}
                                    selected= {statePage.mfeeEndDate && statePage.mfeeEndDate !== ''
                                        ? statePage.mfeeEndDate
                                        : null
                                    }
                                    onChange={(date: any) => {
                                        handleState('mfeeEndDate', format(date, 'yyyy-MM-dd'))
                                    }}
                                />
                            </div>

                            <div className={'col-6'}>
                                <p className={labelStyle}>MEMBER</p>
                                <Select styles={inputStyleH25} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                            </div>
                            <div className={'col-12'}>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={(statePage.mfeeDesc !== 'null') && (statePage.mfeeDesc !== null) ? statePage.mfeeDesc : ''} onChange={(e) => handleState('mfeeDesc', e.target.value)} className={inputDescriptionStyle}></textarea>
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: 'New Member Fee',
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    mfeeId: '',
    feeId: '',
    memberId: '',
    mfeeRate: '',
    mfeeStartDate: '',
    mfeeEndDate: '',
    mfeeStatus: '001',
    mfeeCalcType: '',
    mfeeDesc: '',
    memberCode: '',
    memberName: '',
    feeType: '',
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                mfeeId: '',
                feeId: '',
                memberId: '',
                mfeeRate: '',
                mfeeStartDate: '',
                mfeeEndDate: '',
                mfeeStatus: '001',
                mfeeCalcType: '',
                mfeeDesc: '',
                memberCode: '',
                memberName: '',
                feeType: '',
                errorMessage: "",
            };
        case 'setData':
            return {
                ...state,
                mfeeId: action.value.mfeeId,
                feeId: action.value.feeId,
                memberId: action.value.memberId,
                mfeeRate: action.value.mfeeRate,
                mfeeStartDate: action.value.mfeeStartDate,
                mfeeEndDate: action.value.mfeeEndDate,
                mfeeStatus: action.value.mfeeStatus,
                mfeeCalcType: action.value.mfeeCalcType,
                mfeeDesc: action.value.mfeeDesc,
                memberCode: action.value.memberCode,
                memberName: action.value.memberName,
                feeType: action.value.feeType,
                errorMessage: '',
            };
        // 
        case 'mfeeId':
            return {
                ...state,
                mfeeId: action.value,
            };
        case 'feeId':
            return {
                ...state,
                feeId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'mfeeRate':
            return {
                ...state,
                mfeeRate: action.value,
            };
        case 'mfeeStartDate':
            return {
                ...state,
                mfeeStartDate: action.value,
            };
        case 'mfeeEndDate':
            return {
                ...state,
                mfeeEndDate: action.value,
            };
        case 'mfeeStatus':
            return {
                ...state,
                mfeeStatus: action.value,
            };
        case 'mfeeCalcType':
            return {
                ...state,
                mfeeCalcType: action.value,
            };
        case 'mfeeDesc':
            return {
                ...state,
                mfeeDesc: action.value,
            };
        case 'memberCode':
            return {
                ...state,
                memberCode: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        case 'feeType':
            return {
                ...state,
                feeType: action.value,
            };
        // 
        default:
            throw new Error();
    }
}

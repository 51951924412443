import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page15Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { inputStyleH25, labelStyle } from '../../Styles';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalProductFeatureMember = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsCommonStatusDetailCode, optionsRouteSequence, optionsMember, optionsProductFeature } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { prodFmId, productFeatureId, memberId, pfmStatus, pfmDestMemberId, pfmRsId, productFeatureName, memberName, errorMessage } = statePage

        const data = {
            productFeatureId: productFeatureId,
            memberId: memberId,
            pfmStatus: pfmStatus,
            pfmDestMemberId: pfmDestMemberId,
            pfmRsId: pfmRsId,
        };

        let a = CheckAllFields([productFeatureId, memberId, pfmDestMemberId, pfmStatus], ["Product Feature", "Member", "Dest Member", "Status"]);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px' >
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Product Feature</p>
                                <Select styles={inputStyleH25} options={optionsProductFeature} value={optionsProductFeature.filter((option: any) => option.value === statePage.productFeatureId)} onChange={(e) => handleState('productFeatureId', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Member</p>
                                <Select styles={inputStyleH25} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Dest Member</p>
                                <Select styles={inputStyleH25} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.pfmDestMemberId)} onChange={(e) => handleState('pfmDestMemberId', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Route Sequence</p>
                                <Select styles={inputStyleH25} options={optionsRouteSequence} value={optionsRouteSequence.filter((option: any) => option.value === statePage.pfmRsId)} onChange={(e) => handleState('pfmRsId', e.value)} />
                            </div>
                            <div className={'col-6'}>
                                <p className={labelStyle}>Status</p>
                                <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.pfmStatus)} onChange={(e) => handleState('pfmStatus', `${e.value}`)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />

                    </div>
                </div>
            </Modal>


            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page15Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    prodFmId: '',
    productFeatureId: '',
    memberId: '',
    pfmStatus: '001',
    pfmDestMemberId: '',
    pfmRsId: '',
    productFeatureName: '',
    memberName: '',
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                prodFmId: '',
                productFeatureId: '',
                memberId: '',
                pfmStatus: '001',
                pfmDestMemberId: '',
                pfmRsId: '',
                productFeatureName: '',
                memberName: '',
            };
        case 'setData':
            return {
                ...state,
                prodFmId: action.value.prodFmId,
                productFeatureId: action.value.productFeatureId,
                memberId: action.value.memberId,
                pfmStatus: action.value.pfmStatus,
                pfmDestMemberId: action.value.pfmDestMemberId,
                pfmRsId: action.value.pfmRsId,
                productFeatureName: action.value.productFeatureName,
                memberName: action.value.memberName,
            };
        // 
        case 'prodFmId':
            return {
                ...state,
                prodFmId: action.value,
            };
        case 'productFeatureId':
            return {
                ...state,
                productFeatureId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'pfmStatus':
            return {
                ...state,
                pfmStatus: action.value,
            };
        case 'pfmDestMemberId':
            return {
                ...state,
                pfmDestMemberId: action.value,
            };
        case 'pfmRsId':
            return {
                ...state,
                pfmRsId: action.value,
            };
        case 'productFeatureName':
            return {
                ...state,
                productFeatureName: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        default:
            throw new Error();
    }
}

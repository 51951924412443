import { createSlice } from "@reduxjs/toolkit";

interface ReversalTransactionMonitoringInterface {
    dataFilter: any[],
    dataCopyFilter: any[],
    dataYesterday: any[],
    filterStartDate: string | null,
    filterEndDate: string | null,
    // ProductFeature
    selectedProductFeatureId: number | string,
    selectedProductFeatureLabel: string | null,
    selectedProductFeatureSendToApi: string | null,
    // Product
    selectedProductId: number | string,
    selectedProductLabel: string | null,
    selectedProductSendToApi: string | null,
    //
    search: string | null,
    optionsProductFeature: any[],
    optionsProduct: any[],
    listMember: any[], // for color static pie chart when click data color not change
    listReasonCode: any[], // for show status table transaction => 'APPROVED', 'DECLINED', 'TIMEOUT', 'PRE-DECLINED'
    rangeDate: number | string | null,

    sortBy: string | null,
    sortType: string | null,
}

function initialState(): ReversalTransactionMonitoringInterface | any {
    let dateStart = new Date();
    dateStart.setDate(dateStart.getDate() - 30);
    let dateStartUsed = dateStart.toISOString().slice(0, 10);
    return {
        dataFilter: [],
        dataCopyFilter: [],
        dataYesterday: [],
        filterStartDate: new Date().toISOString().slice(0, 10),//dateStartUsed,
        filterEndDate: new Date().toISOString().slice(0, 10),
        // ProductFeature
        selectedProductFeatureId: 1,
        selectedProductFeatureLabel: "",
        selectedProductFeatureSendToApi: "",
        // Product
        selectedProductId: 1,
        selectedProductLabel: "",
        selectedProductSendToApi: "",
        //
        search: "",
        optionsProductFeature: [],
        optionsProduct: [],
        listMember: [], // for color static pie chart when click data color not change
        listReasonCode: [], // for show status table transaction => 'APPROVED', 'DECLINED', 'TIMEOUT', 'PRE-DECLINED', 'FORMAT ERROR'
        rangeDate: 1,
        sortBy: '',
        sortType: '',
    }
}



export const reversalTransactionMonitoringSlice = createSlice({
    name: "reversalTransactionMonitoring",
    initialState: initialState(),
    reducers: {
        changeStateReversalTransactionMonitoring: (state, action) => {
            state.dataFilter = action.payload.dataFilter === undefined || null ? state.dataFilter : action.payload.dataFilter;
            state.dataCopyFilter = action.payload.dataFilter === undefined || null ? state.dataFilter : action.payload.dataFilter;
            state.dataYesterday = action.payload.dataYesterday === undefined || null ? state.dataYesterday : action.payload.dataYesterday;
            state.filterStartDate = action.payload.filterStartDate === undefined || null ? state.filterStartDate : action.payload.filterStartDate;
            state.filterEndDate = action.payload.filterEndDate === undefined || null ? state.filterEndDate : action.payload.filterEndDate;
            state.selectedProductFeatureId = action.payload.selectedProductFeatureId === undefined || null ? state.selectedProductFeatureId : action.payload.selectedProductFeatureId;
            state.selectedProductFeatureLabel = action.payload.selectedProductFeatureLabel === undefined || null ? state.selectedProductFeatureLabel : action.payload.selectedProductFeatureLabel;
            state.selectedProductFeatureSendToApi =
                action.payload.selectedProductFeatureSendToApi === undefined || null ? state.selectedProductFeatureSendToApi : action.payload.selectedProductFeatureSendToApi;
            state.selectedProductId = action.payload.selectedProductId === undefined || null ? state.selectedProductId : action.payload.selectedProductId;
            state.selectedProductLabel = action.payload.selectedProductLabel === undefined || null ? state.selectedProductLabel : action.payload.selectedProductLabel;
            state.selectedProductSendToApi = action.payload.selectedProductSendToApi === undefined || null ? state.selectedProductSendToApi : action.payload.selectedProductSendToApi;
            state.optionsProductFeature = action.payload.optionsProductFeature === undefined || null ? state.optionsProductFeature : action.payload.optionsProductFeature;
            state.optionsProduct = action.payload.optionsProduct === undefined || null ? state.optionsProduct : action.payload.optionsProduct;
            state.search = action.payload.search === undefined || null ? state.search : action.payload.search;
            state.listMember = action.payload.listMember === undefined || null ? state.listMember : action.payload.listMember;
            state.listReasonCode = action.payload.listReasonCode === undefined || null ? state.listReasonCode : action.payload.listReasonCode;
            state.rangeDate = action.payload.rangeDate === undefined || null ? state.rangeDate : action.payload.rangeDate;
            state.sortBy = action.payload.sortBy === undefined || null ? state.sortBy : action.payload.sortBy;
            state.sortType = action.payload.sortType === undefined || null ? state.sortType : action.payload.sortType;
        },
        changeStateDataCopyFilterReversalTransactionMonitoring: (state, action) => {
            // because use this dataCopyFilter
            state.dataCopyFilter = action.payload.dataCopyFilter === undefined || null ? state.dataCopyFilter : action.payload.dataCopyFilter;
            state.dataYesterday = action.payload.dataYesterday === undefined || null ? state.dataYesterday : action.payload.dataYesterday;
        },
        handleStateActionSAFStatistic: (state, action) => {
            for (const key in state as ReversalTransactionMonitoringInterface) {
                if (key === action.payload.name) {
                    let nameState: string = action.payload.name
                    state[nameState] = action.payload.value
                }
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { changeStateReversalTransactionMonitoring, changeStateDataCopyFilterReversalTransactionMonitoring, handleStateActionSAFStatistic } = reversalTransactionMonitoringSlice.actions;
export const stateGlobalReversalTransactionMonitoring = (state: any) => state.reversalTransactionMonitoring;
export default reversalTransactionMonitoringSlice.reducer;

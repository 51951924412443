import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
    let dateNow = new Date().toISOString().slice(0, 10);
    let now = new Date();
    let hours = now.getHours().toString().padStart(2, '0');
    let minutes = now.getMinutes().toString().padStart(2, '0');
    let seconds = now.getSeconds().toString().padStart(2, '0');
    let startTime = '00:00:00';
    let timeNow = `${hours}:${minutes}:${seconds}`;
    return {
        transId: "",
        transAuthIntResponseCode: "", // search for status => reason code
        statusSendToApi: "", // search for status => reason code
        transStatusName: "", // reason code name
        transCurrencyCode: "", // currency code
        acqSwitchCurrencyCode: "", // currency code
        issSwitchCurrencyCode: "", // currency code
        issuerId: "", // search for issuer
        acquirerId: "", // search for acquirer
        issuerSwitcherId: "", // search for issuerSwitcherId
        acquirerSwitcherId: "", // search for acquirerSwitcherId
        //
        issuerIdCode: "", // search for issuerCode
        acquirerIdCode: "", // search for acquirerCode
        issuerSwitcherIdCode: "", // search for issuerSwitcherIdCode
        acquirerSwitcherIdCode: "", // search for acquirerSwitcherIdCode
        // Product
        selectedProductId: "",
        selectedProductLabel: "",
        selectedProductSendToApi: "",
        // Product Feature
        selectedProductFeatureId: "",
        selectedProductFeatureLabel: "",
        selectedProductFeatureSendToApi: "",
        //
        filterStartDate: dateNow, // search for by start date
        filterEndDate: dateNow, // // search for by end date
        search: "",

        product: null,
        productFeature: null,

        // 05/05/2023
        approvalCode: "",
        RRN: "",
        InvoiceNumber: "",
        GlobalID: "",
        MID: "",
        TID: "",
        transactionAmount: "",

        // 06/09/2024
        STAN: "",

        // 06/10/2024
        walletUserPan: "",
        postStatus: "",
        startTime : startTime,
        endTime: timeNow,
        navigateFrom:"",
        walletId:""
    };
};

export const transactionMonitoringSlice = createSlice({
    name: "transactionMonitoringStatus",
    initialState: initialState(),
    reducers: {
        changeStateTransactionMonitoringStatus: (state, action) => {
            state.transAuthIntResponseCode =
                action.payload.transAuthIntResponseCode === undefined ||
                action.payload.transAuthIntResponseCode === null
                    ? state.transAuthIntResponseCode
                    : action.payload.transAuthIntResponseCode;
            state.statusSendToApi =
                action.payload.statusSendToApi === undefined ||
                action.payload.statusSendToApi === null
                    ? state.statusSendToApi
                    : action.payload.statusSendToApi;
            state.transStatusName =
                action.payload.transStatusName === undefined ||
                action.payload.transStatusName === null
                    ? state.transStatusName
                    : action.payload.transStatusName;
            state.transCurrencyCode =
                action.payload.transCurrencyCode === undefined ||
                action.payload.transCurrencyCode === null
                    ? state.transCurrencyCode
                    : action.payload.transCurrencyCode;
            state.issSwitchCurrencyCode =
                action.payload.issSwitchCurrencyCode === undefined ||
                action.payload.issSwitchCurrencyCode === null
                    ? state.issSwitchCurrencyCode
                    : action.payload.issSwitchCurrencyCode;
            state.acqSwitchCurrencyCode =
                action.payload.acqSwitchCurrencyCode === undefined ||
                action.payload.acqSwitchCurrencyCode === null
                    ? state.acqSwitchCurrencyCode
                    : action.payload.acqSwitchCurrencyCode;
            state.issuerId =
                action.payload.issuerId === undefined ||
                action.payload.issuerId === null
                    ? state.issuerId
                    : action.payload.issuerId;
            state.acquirerId =
                action.payload.acquirerId === undefined ||
                action.payload.acquirerId === null
                    ? state.acquirerId
                    : action.payload.acquirerId;
            state.issuerSwitcherId =
                action.payload.issuerSwitcherId === undefined ||
                action.payload.issuerSwitcherId === null
                    ? state.issuerSwitcherId
                    : action.payload.issuerSwitcherId;
            state.acquirerSwitcherId =
                action.payload.acquirerSwitcherId === undefined ||
                action.payload.acquirerSwitcherId === null
                    ? state.acquirerSwitcherId
                    : action.payload.acquirerSwitcherId;

            state.issuerIdCode =
                action.payload.issuerIdCode === undefined ||
                action.payload.issuerIdCode === null
                    ? state.issuerIdCode
                    : action.payload.issuerIdCode;
            state.acquirerIdCode =
                action.payload.acquirerIdCode === undefined ||
                action.payload.acquirerIdCode === null
                    ? state.acquirerIdCode
                    : action.payload.acquirerIdCode;
            state.issuerSwitcherIdCode =
                action.payload.issuerSwitcherIdCode === undefined ||
                action.payload.issuerSwitcherIdCode === null
                    ? state.issuerSwitcherIdCode
                    : action.payload.issuerSwitcherIdCode;
            state.acquirerSwitcherIdCode =
                action.payload.acquirerSwitcherIdCode === undefined ||
                action.payload.acquirerSwitcherIdCode === null
                    ? state.acquirerSwitcherIdCode
                    : action.payload.acquirerSwitcherIdCode;

            state.selectedProductId =
                action.payload.selectedProductId === undefined ||
                action.payload.selectedProductId === null
                    ? state.selectedProductId
                    : action.payload.selectedProductId;
            state.selectedProductLabel =
                action.payload.selectedProductLabel === undefined ||
                action.payload.selectedProductLabel === null
                    ? state.selectedProductLabel
                    : action.payload.selectedProductLabel;
            state.selectedProductSendToApi =
                action.payload.selectedProductSendToApi === undefined ||
                action.payload.selectedProductSendToApi === null
                    ? state.selectedProductSendToApi
                    : action.payload.selectedProductSendToApi;
            state.selectedProductFeatureId =
                action.payload.selectedProductFeatureId === undefined ||
                action.payload.selectedProductFeatureId === null
                    ? state.selectedProductFeatureId
                    : action.payload.selectedProductFeatureId;
            state.selectedProductFeatureLabel =
                action.payload.selectedProductFeatureLabel === undefined ||
                action.payload.selectedProductFeatureLabel === null
                    ? state.selectedProductFeatureLabel
                    : action.payload.selectedProductFeatureLabel;
            state.selectedProductFeatureSendToApi =
                action.payload.selectedProductFeatureSendToApi === undefined ||
                action.payload.selectedProductFeatureSendToApi === null
                    ? state.selectedProductFeatureSendToApi
                    : action.payload.selectedProductFeatureSendToApi;

            state.transId =
                action.payload.transId === undefined ||
                action.payload.transId === null
                    ? state.transId
                    : action.payload.transId;
            state.product =
                action.payload.product === undefined ||
                action.payload.product === null
                    ? state.product
                    : action.payload.product;
            state.productFeature =
                action.payload.productFeature === undefined ||
                action.payload.productFeature === null
                    ? state.productFeature
                    : action.payload.productFeature;
            state.filterStartDate =
                action.payload.filterStartDate === undefined ||
                action.payload.filterStartDate === null
                    ? state.filterStartDate
                    : action.payload.filterStartDate;
            state.filterEndDate =
                action.payload.filterEndDate === undefined ||
                action.payload.filterEndDate === null
                    ? state.filterEndDate
                    : action.payload.filterEndDate;
            state.search =
                action.payload.search === undefined ||
                action.payload.search === null
                    ? state.search
                    : action.payload.search;
            // 05/05/2023
            state.approvalCode =
                action.payload.approvalCode === undefined ||
                action.payload.approvalCode === null
                    ? state.approvalCode
                    : action.payload.approvalCode;
            state.RRN =
                action.payload.RRN === undefined || action.payload.RRN === null
                    ? state.RRN
                    : action.payload.RRN;
            state.InvoiceNumber =
                action.payload.InvoiceNumber === undefined ||
                action.payload.InvoiceNumber === null
                    ? state.InvoiceNumber
                    : action.payload.InvoiceNumber;
            state.GlobalID =
                action.payload.GlobalID === undefined ||
                action.payload.GlobalID === null
                    ? state.GlobalID
                    : action.payload.GlobalID;
            state.MID =
                action.payload.MID === undefined || action.payload.MID === null
                    ? state.MID
                    : action.payload.MID;
            state.TID =
                action.payload.TID === undefined || action.payload.TID === null
                    ? state.TID
                    : action.payload.TID;
            state.transactionAmount =
                action.payload.transactionAmount === undefined ||
                action.payload.transactionAmount === null
                    ? state.transactionAmount
                    : action.payload.transactionAmount;
            // 06/09/2024
            state.STAN =
                action.payload.STAN === undefined ||
                action.payload.STAN === null
                    ? state.STAN
                    : action.payload.STAN;

            // 06/10/2024
            state.walletUserPan =
                action.payload.walletUserPan === undefined ||
                action.payload.walletUserPan === null
                    ? state.walletUserPan
                    : action.payload.walletUserPan;
            state.postStatus =
                action.payload.postStatus === undefined ||
                action.payload.postStatus === null
                    ? state.postStatus
                    : action.payload.postStatus;
            state.startTime =
                action.payload.startTime === undefined ||
                action.payload.startTime === null
                    ? state.startTime
                    : action.payload.startTime;
            state.endTime =
                action.payload.endTime === undefined ||
                action.payload.endTime === null
                    ? state.endTime
                    : action.payload.endTime;
            state.navigateFrom =
                action.payload.navigateFrom === undefined ||
                action.payload.navigateFrom === null
                    ? state.navigateFrom
                    : action.payload.navigateFrom;
            state.walletId =
                action.payload.walletId === undefined ||
                action.payload.walletId === null
                    ? state.walletId
                    : action.payload.walletId;
        },
        resetAllActionPage2: (state, action) => {
            state.transAuthIntResponseCode = "";
            state.statusSendToApi = "";
            state.transStatusName = "";
            state.transCurrencyCode = "";
            state.acqSwitchCurrencyCode = "";
            state.issSwitchCurrencyCode = "";
            state.issuerId = "";
            state.acquirerId = "";
            state.issuerSwitcherId = "";
            state.acquirerSwitcherId = "";

            state.issuerIdCode = "";
            state.acquirerIdCode = "";
            state.issuerSwitcherIdCode = "";
            state.acquirerSwitcherIdCode = "";

            state.selectedProductId = "";
            state.selectedProductLabel = "";
            state.selectedProductSendToApi = "";
            state.selectedProductFeatureId = "";
            state.selectedProductFeatureLabel = "";
            state.selectedProductFeatureSendToApi = "";

            state.transId = action.payload;
            state.product = null;
            state.productFeature = null;
            state.filterStartDate = "";
            state.filterEndDate = "";
            state.search = "";
            // 05/05/2023
            state.approvalCode = "";
            state.RRN = "";
            state.InvoiceNumber = "";
            state.GlobalID = "";
            state.MID = "";
            state.TID = "";
            state.transactionAmount = "";
            // 06/09/2024
            state.STAN = "";
            // 06/09/2024
            state.walletUserPan = "";
            state.postStatus = "";
            state.startTime = "";
            state.endTime = "";
            state.navigateFrom = "";
            state.walletId = "";
        },
    },
});

// Action creators are generated for each case reducer function
export const { changeStateTransactionMonitoringStatus, resetAllActionPage2 } =
    transactionMonitoringSlice.actions;
export const stateGlobalTransactionMonitoringStatus = (state: any) =>
    state.transactionMonitoringStatus;
export default transactionMonitoringSlice.reducer;

import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../../Components';
import { API } from '../../../Services';
import { logoutAction } from '../../../Store/User';
import { inputDescriptionStyle, labelStyle } from '../../../Styles';
import { CheckAllFields } from '../../../Utils';
import { ModelLogLevelManagement } from '../Models/ModelLogLevelManagement';
import { loadingAction } from '../../../Store/Loading';
import { Page21Title } from '../../../State/Menu/TitleMenu';
import { urlSelection } from '../../../Services/API';


export const ModalDraggableLogLevelManagement = ({ ...props }) => {
      const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsLogLevelProgramClassificationDetailCode, optionsLogLevelDetailCode, optionsCommonStatusDetailCode, optionsService, optionsBatch } = props.stateOptions



    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { logLevelId, serviceName, logLevelName, logLevelCode, logLevelStatus, logLevelStatusCode, logLevelDate, logLevelTime, programId, programName, programClassCode, programClassName, errorMessage } = statePage.data
        const data = {
            logLevelId: id,
            programClassCode: programClassCode,
            programId: programId,
            logLevelCode: logLevelCode,
            logLevelStatusCode: logLevelStatusCode,
        };
        const a = CheckAllFields([programClassCode, programId, logLevelCode, logLevelStatusCode], ['PROGRAM CLASS', 'PROGRAM NAME', 'LOG LEVEL', 'STATUS']);
       
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                 
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                   
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>

                    <div className='col-6'>
                        <p className={labelStyle}>Program Class</p>
                        <Select placeholder={'(Search)'} options={optionsLogLevelProgramClassificationDetailCode} value={optionsLogLevelProgramClassificationDetailCode.filter((option: any) => option.value === statePage.data.programClassCode)} onChange={(e) => handleState('programClassCode', e.value)} />
                    </div>
                    {
                        statePage.data.programClassCode &&
                            statePage.data.programClassCode === '001' ?
                            <div className='col-6'>
                                <p className={labelStyle}>Program Name</p>
                                <Select placeholder={'(Search)'} options={optionsService} value={optionsService.filter((option: any) => option.value === statePage.data.programId)} onChange={(e) => handleState('programId', e.value)} />
                            </div>
                            :
                            statePage.data.programClassCode === '001' ?
                                <div className='col-6'>
                                    <p className={labelStyle}>Program Name</p>
                                    <Select placeholder={'(Search)'} options={optionsBatch} value={optionsBatch.filter((option: any) => option.value === statePage.data.programId)} onChange={(e) => handleState('programId', e.value)} />
                                </div>
                                :
                                <></>
                    }

                    <div className='col-6'>
                        <p className={labelStyle}>Log Level</p>
                        <Select placeholder={'(Search)'} options={optionsLogLevelDetailCode} value={optionsLogLevelDetailCode.filter((option: any) => option.value === statePage.data.logLevelCode)} onChange={(e) => handleState('logLevelCode', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Status</p>
                        <Select placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.data.logLevelStatusCode)} onChange={(e) => handleState('logLevelStatusCode', `${e.value}`)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page21Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelLogLevelManagement({
        id: null,
        logLevelId: null,
        serviceName: null,
        logLevelName: null,
        logLevelCode: null,
        logLevelStatus: '001',
        logLevelStatusCode: null,
        logLevelDate: null,
        logLevelTime: null,
        programId: null,
        programName: null,
        programClassCode: null,
        programClassName: null,
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelLogLevelManagement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelLogLevelManagement({
                    id: null,
                    logLevelId: null,
                    serviceName: null,
                    logLevelName: null,
                    logLevelCode: null,
                    logLevelStatus: '001',
                    logLevelStatusCode: null,
                    logLevelDate: null,
                    logLevelTime: null,
                    programId: null,
                    programName: null,
                    programClassCode: null,
                    programClassName: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelLogLevelManagement({
                    id: action.value.logLevelId,
                    logLevelId: action.value.logLevelId,
                    serviceName: action.value.serviceName,
                    logLevelName: action.value.logLevelName,
                    logLevelCode: action.value.logLevelCode,
                    logLevelStatus: action.value.logLevelStatus,
                    logLevelStatusCode: action.value.logLevelStatusCode,
                    logLevelDate: action.value.logLevelDate,
                    logLevelTime: action.value.logLevelTime,
                    programId: action.value.programId,
                    programName: action.value.programName,
                    programClassCode: action.value.programClassCode,
                    programClassName: action.value.programClassName,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { useMemo } from 'react';
import { CheckUndefined } from '../../../Utils';
import { SelectView } from '../../../Components';
import { FilterComponentQRAuthInquiry } from './FilterComponentQRAuthInquiry';

export const DesktopViewQRAuthInquiry = ({ ...props }) => {
    const {
        state,
        stateOptions,
        stateCustomComponent,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom,
        handleStateParent,
        handleNavigation,
        setPagination,
        pagination,
    } = props;
    const columns : any= useMemo(
        () => [
            {
                header: 'QR Date/Time',
                accessorKey: 'createDate',
                size: 'auto',
                padding: '20px',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.createDate)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px', 
                    },
                }
            },
            {
                header: 'Product Indicator',
                accessorKey: 'prodFeatureName',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.prodFeatureName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px', 
                    },
                }
            },
            {
                header: 'Member Id',
                accessorKey: 'qrReqMemberId',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.qrReqMemberId)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px', 
                    },
                }
            },
            {
                header: 'QR Ref No',
                accessorKey: 'qrRrn',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.qrRrn)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px', 
                    },
                }
            },
            {
                header: 'QR Stan',
                accessorKey: 'qrReqStan',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.qrReqStan)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px', 
                    },
                }
            },
            {
                header: 'QR Inv No',
                accessorKey: 'qrMpmInvNo',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.qrMpmInvNo)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px', 
                    },
                }
            },
            {
                header: 'QR Status',
                accessorKey: 'qrStatus',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(
                        row.original.qrStatus
                    )}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px', 
                    },
                }
            // },
            // {
            //     header: 'Status',
            //     accessorKey: 'transStatus',
            //     size: 'auto',
            //     Cell: ({ row }: any) =>
            //         `${CheckUndefined(row.original.transStatus)}`,
            //     muiTableBodyCellProps: {
            //         style: {
            //             paddingRight: '46px',
            //         },
            //     }
                
            // },
            // {
            //     header: 'Transaction ID',
            //     accessorKey: 'transAuthId',
            //     size: 'auto',
            //     Cell: ({ row }: any) =>
            //         `${CheckUndefined(row.original.transAuthId)}`,
            //     muiTableBodyCellProps: {
            //         style: {
            //             paddingRight: '46px',
            //         },
            //     }
            // },
            // {
            //     header: 'Invoice Number',
            //     accessorKey: 'authInvNo',
            //     size: 'auto',
            //     Cell: ({ row }: any) =>
            //         `${CheckUndefined(row.original.authInvNo)}`,
            //     muiTableBodyCellProps: {
            //         style: {
            //             paddingRight: '46px', 
            //         },
            //     }
            // },
            // {
            //     header: 'Merchant Name',
            //     accessorKey: 'merName',
            //     size: 'auto',
            //     Cell: ({ row }: any) =>
            //         `${CheckUndefined(row.original.merName)}`,
            //     muiTableBodyCellProps: {
            //         style: {
            //             paddingRight: '46px',
            //         },
            //     }
            // },
            // {
            //     header: 'Wallet User Pan',
            //     accessorKey: 'walletUserPan',
            //     size: 'auto',
            //     Cell: ({ row }: any) =>
            //         `${CheckUndefined(row.original.walletUserPan)}`,
            //     muiTableBodyCellProps: {
            //         style: {
            //             paddingRight: '46px',
            //         },
            //     }
            // },
            // {
            //     header: 'Posting Status',
            //     accessorKey: 'postStatus',
            //     size: 'auto',
            //     Cell: ({ row }: any) =>
            //         `${CheckUndefined(row.original.postStatus)}`,
            //                     muiTableBodyCellProps: {
            //         style: {
            //             paddingRight: '46px',
            //         },
            //     }
            },
        ],
        []
    );
    
    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                onClick: () => handleNavigation(row.original),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        onGlobalFilterChange: (filterValue) =>
            handleStateGlobal('search', filterValue),
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="justify-content-between pt-2">
                <div className="col-6 d-flex align-items-center">
                    <p className="Lato-400 s-1822 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
            </div>
        ),


    });
    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                QR Auth Inquiry
            </h1>
            <div id="filter" className="mt-2">
                <FilterComponentQRAuthInquiry
                    state={state}
                    stateOptions={stateOptions}
                    stateCustomComponent={stateCustomComponent}
                    handleStateParent={handleStateParent}
                    handleStateGlobal={handleStateGlobal}
                    resetFilterStatus={resetFilterStatus}
                    initFunctionCustom={initFunctionCustom}
                />
            </div>
            <div id="table" className="mt-2 mb-5 position-relative">
                <MaterialReactTable table={table}  />
                <div className='position-absolute' style={{bottom:'12px'}}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />      
                </div>
            </div>
        </>
    );
};

import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from '../../Services';
import {
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    stateOptions,
    stateSelected
} from '../../State';
import { changeStateReversalTransactionMonitoringStatus, stateGlobalReversalTransactionMonitoringStatus } from '../../Store/ReversalTransactionMonitoringStatusSlice';
import { CheckUndefinedStringEmpty } from '../../Utils';
import { IsDateRangeWithinDays } from '../../Utils/IsDateRangeWithinDays';
import { InterfaceOptionsTransactionMonitoringStatus } from '../TransactionInquiry/Utils/InterfaceOptionsTransactionMonitoringStatus';
import { FilterComponentRTSM } from './Components/FilterComponentRTSM';
import { urlSelection } from '../../Services/API';
import { ModalUnmatchedList } from '../UnmatchedList/ModalUnmatchedList';
import { ShowModalInterface } from '../../Interface';
import { TableRTSM } from './Components/TableRTSM';


export const ReversalMonitoringStatus = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const globalState = useSelector(stateGlobalReversalTransactionMonitoringStatus);

    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    const {
        safId,
        transId,
        transStatusName,
        statusSendToApi,
        filterStartDate,
        filterEndDate,
        search,
        transCurrencyCode,
        issuerIdCode,
        acquirerIdCode,
        issuerSwitcherIdCode,
        acquirerSwitcherIdCode,
        safStatus,
        oriTransId,
    } = globalState;

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])


    const initFunctionCustom = (needRefreshPage: Boolean | null) => {

        let apiUrl = 'dashboard/saf-transaction-list?';

        if (CheckUndefinedStringEmpty(search) !== '') {
            apiUrl = apiUrl + `search=${CheckUndefinedStringEmpty(search)}&`;
        }
        // statusName 'APPROVED' status = '0000'

        if (CheckUndefinedStringEmpty(safId) !== '') {
            apiUrl = apiUrl + `safId=${CheckUndefinedStringEmpty(safId)}&`;
        }
        if (CheckUndefinedStringEmpty(statusSendToApi) !== '') {
            apiUrl = apiUrl + `status=${CheckUndefinedStringEmpty(statusSendToApi)}&`;
        }
        if (CheckUndefinedStringEmpty(transStatusName) !== '') {
            apiUrl = apiUrl + `statusName=${CheckUndefinedStringEmpty(transStatusName)}&`;
        }
        if (CheckUndefinedStringEmpty(transCurrencyCode) !== '') {
            apiUrl = apiUrl + `currencyCode=${CheckUndefinedStringEmpty(transCurrencyCode)}&`;
        }

        if (CheckUndefinedStringEmpty(issuerIdCode) !== '') {
            apiUrl = apiUrl + `issuerId=${CheckUndefinedStringEmpty(issuerIdCode)}&`;
        }
        if (CheckUndefinedStringEmpty(issuerSwitcherIdCode) !== '') {
            apiUrl = apiUrl + `issuerSwitcherId=${CheckUndefinedStringEmpty(issuerSwitcherIdCode)}&`;
        }
        if (CheckUndefinedStringEmpty(acquirerSwitcherIdCode) !== '') {
            apiUrl = apiUrl + `acquirerSwitcherId=${CheckUndefinedStringEmpty(acquirerSwitcherIdCode)}&`;
        }
        // 
        if (CheckUndefinedStringEmpty(acquirerIdCode) !== '') {
            apiUrl = apiUrl + `acquirerId=${CheckUndefinedStringEmpty(acquirerIdCode)}&`;
        }
        if (CheckUndefinedStringEmpty(transId) !== '') {
            apiUrl = apiUrl + `transId=${CheckUndefinedStringEmpty(transId)}&`;
        }
        if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
            apiUrl = apiUrl + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
        } else {
            apiUrl = apiUrl + `startDate=${new Date().toISOString().slice(0, 10)}&`;
        }
        if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
            apiUrl = apiUrl + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
        } else {
            apiUrl = apiUrl + `endDate=${new Date().toISOString().slice(0, 10)}&`;
        }
        if (CheckUndefinedStringEmpty(safStatus) !== '') {
            apiUrl = apiUrl + `safStatus=${CheckUndefinedStringEmpty(safStatus)}&`;
        }

        if (CheckUndefinedStringEmpty(oriTransId) !== '') {
            apiUrl = apiUrl + `oriTransId=${CheckUndefinedStringEmpty(oriTransId)}&`;
        }
        apiUrl = apiUrl + `limit=${state.view}&`;
        apiUrl = apiUrl + `offset=${pagination.pageIndex * state.view}&`;

        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                setPagination({pageSize: state.view, pageIndex: 0})
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: apiUrl.slice(0, -1),
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    const handleStateGlobal = (nameState: any, valueState: any) => {
        dispatchGlobal(changeStateReversalTransactionMonitoringStatus({ [nameState]: valueState }))
    }

    useEffect(() => { initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsTransactionMonitoringStatus, dispatchGlobal) }, [search])
    useEffect(() => { initFunctionCustom(true) }, [search, state.view])
    useEffect(() => { initFunctionCustom(false) }, [pagination.pageIndex])

    useEffect(() => {
        handleStateGlobal('search', state.search);
    }, [state.search])

    useEffect(() => {
        setPagination({pageIndex: pagination.pageIndex, pageSize:state.view});
    }, [state.view]);

    useEffect(() => {
        if (filterStartDate !== '' && filterEndDate !== '') {
            let dateStart : any = new Date(filterStartDate);
            let dateEnd : any = new Date(filterEndDate);
    
            if (dateStart > dateEnd) {
                dispatch({
                    type: 'errorMessage',
                    value: 'Start date cannot be larger than end date',
                });
                return;
            }
    
            if (IsDateRangeWithinDays(filterStartDate, filterEndDate, 7)) {
                dispatch({
                    type: 'errorMessage',
                    value: 'Maximum range for start~end date is 7 days',
                });
            } else {
                let listAllDayChart = [];
                for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                    listAllDayChart.push(d.toISOString().slice(0, 10));
                }
    
                if (state.errorMessage !== '') {
                    dispatch({ type: 'errorMessage', value: '' });
                }
            }
        }
    }, [filterStartDate, filterEndDate])


    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsTransactionMonitoringStatus, dispatchGlobal)


    const resetFilterStatus = () => {
        handleStateGlobal('transStatusName', '');
        handleStateGlobal('transCurrencyCode', '');
    }

    const handleNavigation = (item: any) => {
        dispatch({
            type: 'transId',
            value: {
                transId: item.transAuthId,
            }
        });
        dispatch({ type: 'modalCondition', value: !(state.modalCondition) });
    }

    const modalAddDeleteComponentPage = () => {
        return (
            <ModalUnmatchedList
            modalCondition={state.modalCondition}
            setModal={setModalComponent}
            deleteItem={state.deleteItem}
            id={state.id}
            dataModal={state.dataModal}
            urlApi={state.urlApi}
            reloadData={() => reloadDataComponent(false)}
            stateOptions={stateOptions}
            handleStateGlobal={handleStateGlobal}
            dispatchGlobal={dispatchGlobal}
            //
            stateParent={state}
            dispatchParent={dispatch}
            />
        )
    }


    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                SAF Transaction Inquiry
            </h1>
            <FilterComponentRTSM state={state} stateOptions={stateOptions} stateCustomComponent={stateCustomComponent} handleStateGlobal={handleStateGlobal} resetFilterStatus={resetFilterStatus} initFunctionCustom={initFunctionCustom} />
            <TableRTSM
                state={state}
                handleStateGlobal={handleStateGlobal}
                handleStateParent={handleStateComponent}
                handleNavigation={handleNavigation}
                setPagination={setPagination}
                pagination={pagination}
            />
            {modalAddDeleteComponentPage()}
        </>
    )
}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    data: [],
    privilegeAccess: { view: '481', add: '', edit: '', delete: '', download: '' },
    deleteItem: false,
    dataModal: null,
    SearchByPlaceHolder: 'Currency Code, Transaction ID, Ori Transaction ID',

    id: '',
    search: '',
    view: 15,
    position: 15,
    list_view: [10, 15, 20, 30, 40, 50, 100],
    totalData: 0, 
    totalPages: 0,
    errorMessage: '',
    transId: '',
};

const reducer = (state: any, action: any) => {
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'data':
            return {
                ...state,
                data: action.value,
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'dataRouting':
            return {
                ...state,
                dataRouting: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };

        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
            };
        case 'editData':
            return {
                ...state,
            };
        case 'deleteData':
            return {
                ...state,
            };
        case 'searchData':
            return {
                ...state,
                data: action.value,
                position: 15,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value
            };
        // new
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'conditionHitFirst':
            return {
                ...state,
                conditionHitFirst: action.value
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'transId':
            return {
                ...state,
                transId: action.value.transId
            };
        case 'modalCondition':
            return {
                ...state,
                modalCondition: action.value
            };
        default:
            throw new Error();
    }
}
import { useDispatch, useSelector } from 'react-redux';
import {
    MaterialReactTable,
    MRT_ColumnDef,
    useMaterialReactTable,
} from 'material-react-table';
import { SelectView } from '../../../Components';
import { changeStateMainDashboard, stateGlobalMainDashboard, handleStateAction } from '../../../Store/MainDashboardSlice';

export const MainPageTransactionReactTable = ({ ...props }) => {

    const stateGlobal = useSelector(stateGlobalMainDashboard);

    const {
        data,
        columns,
        state,
        pagination,
        setPagination,
        initFunctionCustom,
        handleStateComponent,
        handleStateGlobal,
        handleRowClick,
        reloadDataComponent,
        dispatch
    } = props

    const table = useMaterialReactTable({
        // 1. Column Definitions
        columns,

        // 2. Data & State
        data,
        state: { pagination },
        initialState: {
            density: 'compact',
        },

        // 3. Editing & Row Actions
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        enableGlobalFilterRankedResults: false,

        // 4. Pagination & Row Numbers
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },

        // 5. Positioning & Layout
        enableFullScreenToggle: false,
        enableColumnFilterModes: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row, table }) => {

            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);

            return {
                onClick: () => handleRowClick(row?.original),
                onMouseEnter: () => handleStateComponent('isRowHovered', row.index),
                sx: {
                    cursor: 'pointer',
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: state.isRowHovered === row.index ? 'white' : 'inherit', // Change text color to white on hover
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableBodyProps: () => {
            return {
                onMouseLeave: () => handleStateComponent('isRowHovered', -1),
            }
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px', // Set font size for table cells
                fontFamily: 'system-ui, sans-serif', // Set font family for table cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiSearchTextFieldProps: {
            placeholder: 'Search all column',
            size: 'small',
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif'
            }
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            }
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        // 6. Event Handler
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="">
                <div className="row justify-content-between py-1">
                    <div className="col-6 d-flex align-items-center">
                        <p className="Lato-400 s-1822 c-29">List</p>
                        <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">{state.totalData}</p>
                    </div>
                </div>
                {/* <Button
                    disabled={state.totalData <= 0}
                    onClick={exportToCSVComponent}
                    startIcon={<FileDownloadIcon />}
                    className="h-30px hover-button-red"
                    sx={{
                        color: '#f15e5e'
                    }}
                >
                    Export to CSV
                </Button> */}
            </div>
        ),
    })

    return (
        <>
            <MaterialReactTable table={table} />
            <div className='align-self-start position-relative'>
                <div className='position-absolute' style={{ bottom: '14px' }}>
                    <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    )
}


// UserLogHistory

interface UserLogHistoryInterface {
    id: null | number | string;
    logId: number | null;
    traceId: number | null;
    logSourceIp: string | null;
    logCreateDt: string | null;
    logFileName: string | null;
    userId: number | null;
    username: string | null;
    before: string | null;
    after: string | null;
    screenName: string | null;
    crudType: string | null;
    
}

export class ModelUserLogHistory {
    id: null | number | string;
    logId: number | null;
    traceId: number | null;
    logSourceIp: string | null;
    logCreateDt: string | null;
    logFileName: string | null;
    userId: number | null;
    username: string | null;
    before: string | null;
    after: string | null;
    screenName: string | null;
    crudType: string | null;

    constructor(value: UserLogHistoryInterface) {
        this.id = value.id;
        this.logId= value.logId;
        this.traceId= value.traceId;
        this.logSourceIp= value.logSourceIp;
        this.logCreateDt= value.logCreateDt;
        this.logFileName= value.logFileName;
        this.userId= value.userId;
        this.username= value.username;
        this.before= value.before;
        this.after= value.after;
        this.screenName= value.screenName;
        this.crudType= value.crudType;
    }
}

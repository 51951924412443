import { alertHistoryIcon, interfaceIcon } from "../../../Assets/Images/MenuIcon";
import { Page1ProductionSimRoute, Page2ProductionSimRoute, PageAlertHistory, PageAlertParameter } from "../../../Routes";
import { Page57ACode, Page57Code, Page59Code, PageAlertHistoryCode } from "../CodeMenu";
import { Page57Title, Page57ATitle, PageAlertHistoryTitle, Page59Title } from "../TitleMenu";

export const Menu16 = {
    id: 'alert_history_sidebar',
    label: 'Alert',
    icon: alertHistoryIcon,
    privilegesId: [PageAlertHistoryCode],
    itemSidebar: [
        {
            titleList: PageAlertHistoryTitle,
            key: 'PageAlertHistoryOptions',
            privilegeId: PageAlertHistoryCode,
            url: PageAlertHistory,
        },
        {
            titleList: Page59Title,
            key: 'frameworkOptions6',
            privilegeId: Page59Code,
            url: PageAlertParameter,
        },
    ],
}
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonDelete, ButtonEdit, InsideComponentPage, TableWithLimitOffset } from '../../../../Components';
import { API, urlSelection } from '../../../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../../../State';
import { ModelInstitutionCode } from '../Models/ModelInstitutionCode';
import { TableInstitutionCode } from '../../Tables/TableInstitutionCode';
import { ShowModalInterface } from '../../../../Interface';
import { ModalInstitutionCode } from '../../Modals/ModalInstitutionCode';
import { ModalDraggableInstitutionCode } from '../../Modals/ModalDraggableInstitutionCode';
import { CheckUndefined, ExportCSV } from '../../../../Utils';

const ExcelInstitutionCode = (state: any, stateOptions: any, dataFromAPI: any) => {
    let newList: any[] = [];
    let listData: any[] = [];
    
    dataFromAPI.forEach((item: any, i: number) => {
        const m = {
            no: i + 1,
            instCcgCode: CheckUndefined(item.instCcgCode),
            instCcdCodeName: CheckUndefined(item.instCcdCodeName),
            memberBcapCode: CheckUndefined(item.memberBcapCode),
            memberInstDesc: CheckUndefined(item.memberInstDesc),
            memberInstCode: CheckUndefined(item.memberInstCode),
        };

        state.complete_toggle.forEach((toggleItem: any) => {
            if (toggleItem !== 'action' && state.toggle.indexOf(toggleItem) === -1) {
                delete m[toggleItem as keyof typeof m];
            }
        });

        listData.push(m);
    });

    state.complete_toggle.forEach((toggleItem: any) => {
        if (toggleItem !== 'action' && state.toggle.includes(toggleItem)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(toggleItem)]);
        }
    });

    ExportCSV(newList, listData, 'Member - Institution Code');
};

export const GetInstitutionCode = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const navigate = useNavigate();
    const { codeMember, stateOptions } = props;


    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let searchValue = state.search === '' ? '' : `search=${state.search}&`;
        let memberCode = codeMember === '' ? '' : `memberId=${codeMember}&`;
        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let finalParameter = `${limit}${offset}${searchValue}${memberCode}`;
        let finalParameter2 = `${searchValue}${memberCode}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })
        }
    }

    const filterComponentPage = () => {
        return (
            <></>
        )
    }
    useEffect(() => {
        initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        initFunctionCustom(true)
    }, [])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelInstitutionCode(state, stateOptions, response.data)
        })
    }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelInstitutionCode) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

    const customButtonAction = (item: ModelInstitutionCode, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>

                <ButtonEdit handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />

            </div>
        );
    };

    //
    const tableComponentPage = () => {
        return (
            <TableInstitutionCode
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                currentPage={state.currentPage}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <ModalInstitutionCode
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                codeMember={codeMember}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
            />
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableInstitutionCode
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                getData={state.data}
                                codeMember={codeMember}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={true}
                needAddButton={true}
                needSearchFunction={true}
                needDownloadButton={true}
            />
        </>
    );
}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'member_inst_code',
    titlePage: 'Institution Code Mapping',
    privilegeAccess: {  view: "71", add: "72", edit: "73", delete: "74", download: "75"  },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Group Code", width: "wd-100", val: "instCcgCode" },
        { field: "Code Name", width: 'wd-200', val: "instCcdCodeName" },
        { field: "BCAP Code", width: 'wd-100', val: "memberBcapCode" },
        { field: "Institution Name", width: 'wd-200', val: "memberInstDesc" },
        { field: "Member Institution Code", width: "wd-150", val: "memberInstCode" },
        { field: 'Action', width: 'wd-150', val: 'action' },
    ],
    toggle: ["no", "instCcgCode", "instCcdCodeName", "memberBcapCode", "memberInstDesc", "memberInstCode", "action"],
    complete_toggle: ["no", "instCcgCode", "instCcdCodeName", "memberBcapCode", "memberInstDesc", "memberInstCode", "action"],
    list_column_name: ["No.", "Group Code", "Code Name", "BCAP Code", "Institution Name", "Member Institution Code", " Action"],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Member Institution Code',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1500,
    finalAPI: '',
};

// headerFieldId ModelInterfaceHeader
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInstitutionCode) => {
                        element1.id = element1.memberInstCodeId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInstitutionCode) => {
                        element1.id = element1.memberInstCodeId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelInstitutionCode) => {
                        showDataResult.push(
                            { idModal: element1.memberInstCode!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.memberInstCode;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };

        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}
import {
    CheckUndefined,
    ExportCSV,
    NumberWithCommas
} from "../../Utils";
export const ExcelSalesInquiry = (state: any, stateOptions: any) => {
    let newList: any[] = []
    let listData: any[] = []
    state.data.map((item: any, i: any) => {

        const m = {
            no: i + 1,
            transDate: CheckUndefined(item.transDate),
            cpdDate: CheckUndefined(item.cpdDate),
            transType: CheckUndefined(item.transType),
            issuerAmount: `${CheckUndefined(item.issuerCurrency)} ${NumberWithCommas(CheckUndefined(item.issuerAmount))}`,
            issSwitcherAmount: `${CheckUndefined(item.issuerSwitcherCurrency)} ${NumberWithCommas(CheckUndefined(item.issSwitcherAmount))}`,
            acquirerSwitcherAmount: `${CheckUndefined(item.acquirerSwitcherCurrency)} ${NumberWithCommas(CheckUndefined(item.acquirerSwitcherAmount))}`,
            acquirerAmount: `${CheckUndefined(item.acquirerCurrency)} ${NumberWithCommas(CheckUndefined(item.acquirerAmount))}`,
            issuerMember: CheckUndefined(item.issuerMember),
            issuerSwitcherMember: CheckUndefined(item.issuerSwitcherMember),
            acquirerSwitcherMember: CheckUndefined(item.acquirerSwitcherMember),
            acquirerMember: CheckUndefined(item.acquirerMember),
            transId: CheckUndefined(item.transId),
            rrn: CheckUndefined(item.rrn),
            approvalCode: CheckUndefined(item.approvalCode),
            invoiceNumber: CheckUndefined(item.invoiceNumber),
            editCode: CheckUndefined(item.editCode),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, 'Sales Inquiry')
};
export const stateSelected = {
    selectedMember: '',
    selectedMemberName: '',
    selectedMemberIssuer: '',
    selectedMemberIssuerSwitcher: '',
    selectedMemberSwitcher: '',
    selectedMemberAcquirer: '',
    selectedMemberAcquirerSwitcher: '',
    selectedMemberSrcId: '',
    selectedMemberDstId: '',
    selectedMemberSrcName: '',
    selectedMemberDstName: '',
    selectedSourceMember: '',
    selectedReceivingMember: '',
    selectedSourceInst:'',
    // 
    selectedUserLevel: '',
    selectedUserCategory: '',
    selectedRateFrom: '',
    selectedRateTo: '',
    selectedServerStatus: '',
    selectedConnectionStatus: '',

    selectedReasonCode: '',
    // 

    selectedProductInd: '',
    selectedProcCode: '',
    selectedCpan: '',
    selectedMidMpan: '',
    selectedCurrencyCode: '',
    selectedAuthAmount: '',
    selectedAuthRrn: '',
    selectedAuthRspnCode: '',
    selectedServerId: '',
    selectedGlobalId: '',
    selectedAuthStan: '',
    selectedStartTime: '',
    selectedEndTime: '',
    // 
    selectedRouting: '',
    selectedSubProduct: '',
    // 
    selectedIssuerInstitutionIDDetailCode: '',
    selectedAcquirerInstitutionIDDetailCode: '',
    selectedIssuerSwitcherInstitutionIDDetailCode: '',
    selectedAcquirerSwitcherInstitutionIDDetailCode: '',
    selectedPaymentStatusDetailCode: '',
    selectedDisputeCaseStatusDetailCode: '',
    // date     
    selectedStartDate: '',
    selectedEndDate: '',
    selectedReceivedDate: '',
    selectedTransferDate: '',
    selectedStartDateISOString: '',
    selectedEndDateISOString: '',
    acqSwitchSettleStartDate: '',
    acqSwitchSettleEndDate: '',
    issSwitchSettleStartDate: '',
    issSwitchSettleEndDate: '',
    //
    selectedAcquirerDate: '',
    selectedIssuerDate: '',
    // 
    selectedCountryID: '',
    // 
    selectedQRStandard: [],
    selectedQRStandardID: '',
    selectedInterfaceRecordID: '',
    //
    inputProgramID: '',
    selectedBatchStatus: '',
    selectedBatchType: '',
    //
    inputServiceName: '',
    selectedServiceStatus: '',
    //
    selectedStatusBatch: '',
    selectedBatchProgramId: '',
    selectedBatchExecutionType: '',
    selectedBatchBusinessGroup: '',
    selectedBatchId: '',

}
import { useMemo } from 'react';
import { useSelector } from "react-redux";
import Select from "react-select";
import { useEffect, useState } from 'react';
import {
    MaterialReactTable,
    MRT_ColumnDef,
    useMaterialReactTable,
} from 'material-react-table';
import { Button, IconButton, Modal, Zoom } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { SelectView } from '../../Components';
import { errNotificationIcon, icon_search_white, undo_icon } from "../../Assets/Images/svg";
import { transactionJourneyGlobalState } from "../../Store/TransactionJourney";
import { inputStyleH25, labelStyle, labelStyleWwithoutUppercase } from "../../Styles";
import { CheckUndefined } from '../../Utils';
import ModalLogAnalyzer from './ModalLogAnalyzer';
import { ModelLogAnalyzer } from './ModelLogAnalyzer';

export const MobileViewTransactionJourney = ({ ...props }) => {

    const stateGlobal = useSelector(transactionJourneyGlobalState);

    // state for filter window
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const isMobile = () => window.innerWidth <= 768;

    const [activeFilter, setActiveFilter] = useState<
        'approved' | 'declined' | 'all' | ''
    >('all');

    const {
        state,
        stateOptions,
        optionsResponseCode,
        optionsServer,
        pagination,
        errorMessage,
        setPagination,
        setErrorMessage,
        initFunctionCustom,
        handleGlobalState,
        handleResetFilter,
        handleStateComponent,
        handleRowClick,
        exportToCSVComponent,
        reloadDataComponent,
        dispatch
    } = props;

    const handleFilterClick = (filter: 'approved' | 'declined' | 'all') => {
        setActiveFilter(filter);
        const status =
            filter === 'approved'
                ? '00'
                : filter === 'declined'
                    ? '01'
                    : '';
        handleGlobalState('selectedAuthRspnCode', status);
    };

    useEffect(() => {
        if (isMobile()) {
            initFunctionCustom(true, true);
        }
    }, [stateGlobal.selectedAuthRspnCode]);

    const columnsMobileView = useMemo<MRT_ColumnDef<ModelLogAnalyzer>[]>(
        () => [
            {
                accessorKey: 'logDateTime', // date time
                header: 'Date Time',
                maxSize: 100,
                Cell: ({ row }: any) => `${row.original.logDateTime}`
            },
            {
                accessorKey: 'reasonCode', // reason code
                header: 'R. Code',
                maxSize: 60,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.reasonCode}` == '' ? data = '-' : data = `${row.original.reasonCode}`
                    return data;
                }
            },
            {
                accessorKey: 'reasonMsg', // reason message
                header: 'R. Message',
                minSize: 100,
                maxSize: 160,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.reasonMsg}` == '' ? data = '-' : data = `${row.original.reasonMsg}`
                    return (
                        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {data}
                        </div>
                    );
                }
            },
            // {
            //     accessorKey: 'globalId', // global id
            //     header: 'Global ID',
            //     minSize: 200,
            //     maxSize: 200,
            //     Cell: ({ row }: any) => `${row.original.globalId}`
            // },
            {
                accessorKey: 'logChannel', // channel
                header: 'Channel',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.logChannel}` == '' ? data = '-' : data = `${row.original.logChannel}`
                    return data;
                }
            },
            {
                accessorKey: 'nodeSrc', // from
                header: 'From',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.nodeSrc}`
            },
            {
                accessorKey: 'nodeProc', // proc
                header: 'Proc',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.nodeProc}`
            },
            {
                accessorKey: 'nodeDst', // to
                header: 'To',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.nodeDst}`
            },
        ],
        [],
    );

    let previousGlobalId: string = '';  // for switching row color operation
    let currentColor: string = '';

    const table = useMaterialReactTable({
        // 1. Column Definitions
        columns: columnsMobileView,

        // 2. Data & State
        data: stateGlobal.groupedData,
        state: { pagination },
        initialState: {
            density: 'compact',
        },

        // 3. Editing & Row Actions
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        enableTopToolbar: false,
        enableGlobalFilterRankedResults: false,

        // 4. Pagination & Row Numbers
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginateExpandedRows: false,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showFirstButton: false,
            showLastButton: false,
            showRowsPerPage: false,
            size: 'small',
        },

        // 5. Positioning & Layout
        enableFullScreenToggle: false,
        enableColumnFilterModes: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row, table }) => {

            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);

            if (rowIndex > 0) {
                if (sortedRows[rowIndex].original.globalId !== previousGlobalId) {
                    currentColor = currentColor === '#f5e9e9' ? '#ffffff' : '#f5e9e9';
                }
                previousGlobalId = sortedRows[rowIndex].original.globalId;
            } else {
                currentColor = '#f5e9e9';
                previousGlobalId = sortedRows[rowIndex].original.globalId;
            }

            return {
                onClick: () => handleRowClick(row.original), // click function here
                sx: {
                    cursor: 'pointer',
                    backgroundColor: currentColor,
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white', // Change text color to white on hover
                        },
                    },
                    fontSize: '10px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '10px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '10px', // Set font size for table cells
                fontFamily: 'system-ui, sans-serif', // Set font family for table cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            }
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },
    });

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1418 py-2">Transaction Journey</h1>

            {/* header */}
            <div className='d-flex justify-content-between'>
                <div id="header-total-data" className='w-50 d-flex align-items-center my-2'>
                    <p className='Lato-400 s-1418 c-29'>List</p>
                    <p className='br-5 bd-ea bg-f4 px-1 c-29 Lato-400 s-1418 mx-1'>
                        {state.totalData}
                    </p>
                    <Button
                        disabled={state.totalData <= 0}
                        onClick={exportToCSVComponent}
                        startIcon={<FileDownloadIcon sx={{ width: '16px', height: '16px' }} />}
                        className="h-20px hover-button-red"
                        sx={{
                            color: '#f15e5e',
                            fontSize: '12px',
                        }}
                    >
                        Download
                    </Button>
                </div>
                <div id="header-filter-mobile" className='w-50 d-flex justify-content-end align-items-center'>
                    {/* response options (approved & declined) */}
                    <div className='d-flex h-20px gap-1'>
                        <Button
                            variant="contained"
                            onClick={() => handleFilterClick('approved')}
                            sx={{
                                borderRadius: '20px',
                                fontSize: '8px',
                                padding: '3px 4px',
                                backgroundColor:
                                    activeFilter === 'approved' ? 'green' : 'gray',
                                color: 'white',
                                textTransform: 'none',
                                marginY: '2px',
                            }}
                        >
                            Approved
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => handleFilterClick('declined')}
                            sx={{
                                borderRadius: '20px',
                                fontSize: '8px',
                                padding: '3px 4px',
                                backgroundColor:
                                    activeFilter === 'declined' ? 'red' : 'gray', // Change color based on filter state
                                color: 'white',
                                textTransform: 'none',
                                marginY: '2px',
                            }}
                        >
                            Declined
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => handleFilterClick('all')}
                            sx={{
                                borderRadius: '20px',
                                fontSize: '8px',
                                padding: '3px 4px',
                                backgroundColor:
                                    activeFilter === 'all' ? 'lightgray' : 'gray',
                                color: activeFilter === 'all' ? 'black' : 'white',
                                textTransform: 'none',
                                marginY: '2px',
                            }}
                        >
                            All
                        </Button>
                    </div>

                    {/* filter window */}
                    <div>
                        <IconButton onClick={handleOpen}>
                            <FilterListIcon />
                        </IconButton>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="filter-modal-title"
                            aria-describedby="filter-modal-description"
                            closeAfterTransition
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Zoom in={open}>
                                <div
                                    className="modal-content p-3 bg-white"
                                    style={modalStyle}
                                >
                                    <div className='filter-container'>
                                        <div id="filter-header" className='filter-header d-flex justify-content-between align-items-center border-bottom pb-2'>
                                            <div id="close-button">
                                                <button
                                                    className='btn d-flex h-30px mx-1 mr-100px'
                                                    onClick={handleClose}
                                                >
                                                    <svg
                                                        style={{
                                                            width: '18px',
                                                            height: '18px',
                                                        }}
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6.225 4.811a1 1 0 011.414 0L12 9.172l4.361-4.361a1 1 0 111.414 1.414L13.414 10.586l4.361 4.361a1 1 0 01-1.414 1.414L12 12l-4.361 4.361a1 1 0 01-1.414-1.414l4.361-4.361-4.361-4.361a1 1 0 010-1.414z"
                                                            fill="#292929"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>

                                            <h5 className='m-0'>Filters</h5>

                                            <div id='reset-filter-button'>
                                                <button
                                                    className='btn d-flex h-30px mx-1 mr-100px'
                                                    onClick={handleResetFilter}
                                                >
                                                    <svg
                                                        style={{
                                                            width: '18px',
                                                            height: '18px',
                                                        }}
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                                            fill="#292929"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>

                                        <div id='filter-section' className='filter-section mt-3'>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Global ID
                                                    </p>
                                                </div>
                                                <div className="col-8 justify-content-between">
                                                    <input
                                                        type="text"
                                                        className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                        value={stateGlobal.selectedGlobalId}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const maxLength = 24;

                                                            if (value.length > maxLength) {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "globalId": "Max 24 characters allowed!"
                                                                }))
                                                            } else {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "globalId": ""
                                                                }))
                                                            }
                                                            handleGlobalState("selectedGlobalId", e.target.value)
                                                        }}
                                                    />
                                                    {state.errorMessageFilter !== '' && (
                                                        <div className="">
                                                            <p className='text-danger pb-1 s-1418'>
                                                                {errorMessage.globalId}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        RRN
                                                    </p>
                                                </div>
                                                <div className="col-8 justify-content-between">
                                                    <input
                                                        type="number"
                                                        className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                        value={stateGlobal.selectedAuthRrn}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const maxLength = 12;

                                                            if (value.length > maxLength) {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "rrn": "Max 12 numbers allowed!"
                                                                }))
                                                            } else {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "rrn": ""
                                                                }))
                                                            }

                                                            handleGlobalState("selectedAuthRrn", e.target.value)
                                                        }}
                                                    />
                                                    {state.errorMessageFilter !== '' && (
                                                        <div className="">
                                                            <p className='text-danger pb-1 s-1418'>
                                                                {errorMessage.rrn}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        STAN
                                                    </p>
                                                </div>
                                                <div className="col-8 justify-content-between">
                                                    <input
                                                        type="number"
                                                        className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                        value={stateGlobal.selectedAuthStan}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const maxLength = 6;

                                                            if (value.length > maxLength) {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "stan": "Max 6 numbers allowed!"
                                                                }))
                                                            } else {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "stan": ""
                                                                }))
                                                            }

                                                            handleGlobalState("selectedAuthStan", e.target.value)
                                                        }}
                                                    />
                                                    {state.errorMessageFilter !== '' && (
                                                        <div className="">
                                                            <p className='text-danger pb-1 s-1418'>
                                                                {errorMessage.stan}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Invoice Number
                                                    </p>
                                                </div>
                                                <div className="col-8 justify-content-between">
                                                    <input
                                                        type="text"
                                                        className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                        value={stateGlobal.selectedInvNo}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const maxLength = 64;

                                                            if (value.length > maxLength) {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "invNo": "Max 64 characters allowed!"
                                                                }))
                                                            } else {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "invNo": ""
                                                                }))
                                                            }

                                                            handleGlobalState("selectedInvNo", e.target.value)
                                                        }}
                                                    />
                                                    {state.errorMessageFilter !== '' && (
                                                        <div className="">
                                                            <p className='text-danger pb-1 s-1418'>
                                                                {errorMessage.invNo}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Wallet User PAN
                                                    </p>
                                                </div>
                                                <div className="col-8 justify-content-between">
                                                    <input
                                                        type="number"
                                                        className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                        value={stateGlobal.selectedCpan}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const maxLength = 64;

                                                            if (value.length > maxLength) {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "cpan": "Max 64 numbers allowed!"
                                                                }))
                                                            } else {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "cpan": ""
                                                                }))
                                                            }

                                                            handleGlobalState("selectedCpan", e.target.value)
                                                        }}
                                                    />
                                                    {state.errorMessageFilter !== '' && (
                                                        <div className="">
                                                            <p className='text-danger pb-1 s-1418'>
                                                                {errorMessage.cpan}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        MID
                                                    </p>
                                                </div>
                                                <div className="col-8 justify-content-between">
                                                    <input
                                                        type="text"
                                                        className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                        value={stateGlobal.selectedMidMpan}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const maxLength = 64;

                                                            if (value.length > maxLength) {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "midMpan": "Max 64 characters allowed!"
                                                                }))
                                                            } else {
                                                                setErrorMessage((prevState: any) => ({
                                                                    ...prevState,
                                                                    "midMpan": ""
                                                                }))
                                                            }

                                                            handleGlobalState("selectedMidMpan", e.target.value)
                                                        }}
                                                    />
                                                    {state.errorMessageFilter !== '' && (
                                                        <div className="">
                                                            <p className='text-danger pb-1 s-1418'>
                                                                {errorMessage.midMpan}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Response Code
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <Select
                                                        placeholder={"(Search)"}
                                                        className="flex-fill"
                                                        styles={inputStyleH25}
                                                        options={optionsResponseCode}
                                                        value={optionsResponseCode.filter((option: any) => option.value === stateGlobal.selectedAuthRspnCode)}
                                                        onChange={(e) => {
                                                            handleGlobalState("selectedAuthRspnCode", e?.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Server
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <Select
                                                        placeholder={"(Search)"}
                                                        className="flex-fill"
                                                        styles={inputStyleH25}
                                                        options={optionsServer}
                                                        value={optionsServer.filter((option: any) => option.value === stateGlobal.selectedServerId)}
                                                        onChange={(e) => {
                                                            handleGlobalState("selectedServerId", e?.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Source Institution
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <Select
                                                        placeholder={"(Search)"}
                                                        className="flex-fill"
                                                        styles={inputStyleH25}
                                                        options={stateOptions.optionsMemberCode}
                                                        value={stateOptions.optionsMemberCode.filter((option: any) => option.value === stateGlobal.selectedMemberSrcCode || option.title === stateGlobal.selectedMemberSrcName)}
                                                        onChange={(e) => {
                                                            handleGlobalState("selectedMemberSrcName", e.title)
                                                            handleGlobalState("selectedMemberSrcCode", e.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Destination Institution
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <Select
                                                        placeholder={"(Search)"}
                                                        className="flex-fill"
                                                        styles={inputStyleH25}
                                                        options={stateOptions.optionsMemberCode}
                                                        value={stateOptions.optionsMemberCode.filter((option: any) => option.value === stateGlobal.selectedMemberDstCode || option.title === stateGlobal.selectedMemberDstName)}
                                                        onChange={(e) => {
                                                            handleGlobalState("selectedMemberDstName", e.title)
                                                            handleGlobalState("selectedMemberDstCode", e.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Product Indicator
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <Select
                                                        placeholder={"(Search)"}
                                                        className="flex-fill"
                                                        styles={inputStyleH25}
                                                        options={stateOptions.optionsProductFeatureWithAllOnlyProdFeatureInd}
                                                        value={stateOptions.optionsProductFeatureWithAllOnlyProdFeatureInd.filter((option: any) => option.value === stateGlobal.selectedProductInd)}
                                                        onChange={(e) => {
                                                            handleGlobalState("selectedProductInd", e.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        Start Date and Time
                                                    </p>
                                                </div>

                                                <div className='col-8 d-flex flex-column'>
                                                    <div className="d-flex justify-content-between">
                                                        <DatePicker
                                                            selected={new Date(`${stateGlobal.selectedStartDate} ${stateGlobal.selectedStartTime}` || '')}
                                                            className="form-control flex-fill h-25px w-100 s-1418"
                                                            wrapperClassName="w-100 h-100"
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            timeFormat="HH:mm"
                                                            showTimeSelect
                                                            showYearDropdown
                                                            timeIntervals={5}
                                                            maxDate={new Date()}
                                                            onChange={(date: any) => {
                                                                handleGlobalState('selectedStartDate', format(date, 'yyyy-MM-dd'))
                                                                handleGlobalState('selectedStartTime', format(date, 'HH:mm:ss'))
                                                            }}
                                                        />
                                                    </div>

                                                    <div className='align-self-end position-relative'>
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                right: "10px",
                                                                top: "50%",
                                                                transform: "translateY(-120%)",
                                                                pointerEvents: "none",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <svg
                                                                viewBox="64 64 896 896"
                                                                focusable="false"
                                                                data-icon="calendar"
                                                                width="1em"
                                                                height="1em"
                                                                fill="#0000003f"
                                                                aria-hidden="true">
                                                                <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className="col-4">
                                                    <p
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        End Date and Time
                                                    </p>
                                                </div>
                                                <div className='col-8 d-flex flex-column'>
                                                    <div className="d-flex justify-content-between">
                                                        <DatePicker
                                                            selected={new Date(`${stateGlobal.selectedEndDate} ${stateGlobal.selectedEndTime}`)}
                                                            className="form-control flex-fill w-100 h-25px s-1418"
                                                            wrapperClassName="w-100 h-100"
                                                            popperClassName="react-datepicker-w-custom-button"
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            timeFormat="HH:mm"
                                                            showTimeSelect
                                                            showYearDropdown
                                                            timeIntervals={5}
                                                            minDate={stateGlobal.selectedStartDate}
                                                            maxDate={new Date()}
                                                            onChange={(date: any) => {
                                                                handleGlobalState('selectedEndDate', format(date, 'yyyy-MM-dd'))
                                                                handleGlobalState('selectedEndTime', format(date, 'HH:mm:ss'))
                                                            }}
                                                        >
                                                            <div className='react-datepicker-custom-child-div'>
                                                                <button className='btn-hover border-0 px-2 py-1 bg-e8 c-ff text-uppercase br-5' style={{ fontSize: '12px', lineHeight: '12px' }}
                                                                    onClick={() => {
                                                                        handleGlobalState("selectedEndDate", format(new Date(), 'yyyy-MM-dd'));
                                                                        handleGlobalState("selectedEndTime", format(new Date(), 'HH:mm:ss'));
                                                                    }}
                                                                >
                                                                    Now
                                                                </button>
                                                            </div>
                                                        </DatePicker>
                                                    </div>

                                                    <div className='align-self-end position-relative'>
                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                right: "10px",
                                                                top: "50%",
                                                                transform: "translateY(-120%)",
                                                                pointerEvents: "none",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            <svg
                                                                viewBox="64 64 896 896"
                                                                focusable="false"
                                                                data-icon="calendar"
                                                                width="1em"
                                                                height="1em"
                                                                fill="#0000003f"
                                                                aria-hidden="true">
                                                                <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                    </div>

                                                    {state.errorMessageFilter !== '' && (
                                                        <div className="">
                                                            <p className='text-danger mt-1 s-1418'>
                                                                {errorMessage.date}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        id='fx-rate'
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        FX Rate
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <input
                                                        type="checkbox"
                                                        id="fx-rate"
                                                        checked={stateGlobal.selectedFxRate != ''}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                handleGlobalState("selectedFxRate", '01')
                                                            } else {
                                                                handleGlobalState("selectedFxRate", '')
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row d-flex'>
                                                <div className="col-4">
                                                    <p
                                                        id='sim'
                                                        className={
                                                            labelStyleWwithoutUppercase
                                                        }
                                                    >
                                                        SIM
                                                    </p>
                                                </div>
                                                <div className="col-8 d-flex justify-content-between">
                                                    <input
                                                        type="checkbox"
                                                        id="sim"
                                                        checked={stateGlobal.selectedSim != ''}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                handleGlobalState("selectedSim", '01')
                                                            } else {
                                                                handleGlobalState("selectedSim", '')
                                                            }
                                                        }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {state.errorMessageFilter !== "" ? (
                                            <div className="col-12 pl-0">
                                                <div className="d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit mt-2">
                                                    {errNotificationIcon}
                                                    <p className="px-2 s-1418 Lato-600 c-e87">{state.errorMessageFilter}</p>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                        <button
                                            className="btn btn-danger w-100 py-2 mt-3"
                                            disabled={state.errorMessageFilter !== ''}
                                            onClick={(e) => {
                                                handleClose();
                                                initFunctionCustom(true, true);
                                            }}
                                        >
                                            <p className="s-1620 tex-uppercase c-ff Lato-600 mx-2">
                                                APPLY
                                            </p>
                                        </button>

                                    </div>
                                </div>
                            </Zoom>
                        </Modal>
                    </div>
                </div>
            </div>

            <MaterialReactTable table={table} />

            {state.visibleModalAddDelete && (
                <ModalLogAnalyzer
                    visibleModalAddDelete={state.visibleModalAddDelete}
                    setModal={() =>
                        dispatch({
                            type: 'visibleModalAddDelete',
                            value: !state.visibleModalAddDelete,
                        })
                    }
                    deleteItem={state.deleteItem}
                    id={state.id}
                    dataModal={state.dataModal}
                    urlApi={state.urlApi}
                    reloadData={() => reloadDataComponent(false)}
                    stateOptions={stateOptions}
                />
            )}
        </>
    )
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    backgroundColor: 'white',
    boxShadow: '24',
    padding: '20px',
};
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonDelete, ButtonNavigation } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
  addData,
  deleteData,
  handleState,
  initFunction,
  reducerOptions,
  reducerStateSelected,
  setModal,
  stateOptions,
  stateSelected
} from '../../State';
import { InterfaceOptionsPrivilegeCode } from './InterfaceOptionsPrivilegeCode';
import { ModalPrivilegeCode } from './ModalPrivilegeCode';
import { ModelPrivilegeCode } from './ModelPrivilegeCode';
import { ShowModalInterface } from '../../Interface';
import { Page3UserRoute } from '../../Routes';
import { Page12Title } from '../../State/Menu/TitleMenu';
import { CheckUndefined, ExportCSV } from '../../Utils';
import { TablePrivilege } from './TablePrivilege';

const ExcelPrivilegeCode = (state: any, stateOptions: any, dataFromAPI: any) => {
  let newList: any[] = []
  let listData: any[] = []
  dataFromAPI.map((item: any, i: any) => {
      let showmember = '';
      let showccdCode = '';
      if (stateOptions.optionsMember.length > 0) {
          stateOptions.optionsMember.forEach((element2: any) => {
              if (element2.value === item.member) {
                  showmember = `${element2.label}`;
              }
          });
      }
      if (stateOptions.optionsUserPrivilegeLevel.length > 0) {
          stateOptions.optionsUserPrivilegeLevel.forEach((element2: any) => {
              if (element2.value === item.ccdCode) {
                  showccdCode = `${element2.label}`;
              }
          });
      }
      const m = {
          no: i + 1,
          privilegeId: CheckUndefined(item.privilegeId),
          privilegeName: CheckUndefined(item.privilegeName),
          privilegeDesc: CheckUndefined(item.privilegeDesc),
          member: CheckUndefined(showmember),
          ccdCode: CheckUndefined(showccdCode),
      }
      state.complete_toggle.map((item: any) => {
          if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
      })
      listData.push(m)
  })

  state.complete_toggle.map((item: any) => {
      if (state.toggle.includes(item)) {
          newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
      }
  })
  ExportCSV(newList, listData, state.titlePage)
};


export const PrivilegeCode = ({...props}) => {
  const dispatchGlobal = useDispatch();
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
  const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: state.view,
  });

  useEffect(() => {
    if (privilegeAccess !== null) {
      if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
        navigate('/warning', { replace: true })
      }
    }
  }, [privilegeAccess])

  const initFunctionCustom = (needRefreshPage: Boolean | null) => {
    let searchValue = state.search === '' ? '' : `search=${state.search}&`;
    let limit = `limit=${state.view}&`;
    let offset = `offset=${pagination.pageIndex * state.view}&`;
    let finalParameter = `${searchValue}${limit}${offset}`;
    let finalParameter2 = `${searchValue}`;
    handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)

    if (needRefreshPage === true) {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: 'searchData', value: response?.data });
        dispatch({ type: 'totalPages', value: response?.detail.totalPage });
        dispatch({ type: 'totalData', value: response?.detail.total });

        /* Refresh Page */
        setPagination({pageSize: state.view, pageIndex: 0})
      })
    } else {
      API.get({
        bodyCustom: null,
        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response: any) => {
        dispatch({ type: 'data', value: response?.data });
        dispatch({ type: 'totalPages', value: response?.detail.totalPage });
        dispatch({ type: 'totalData', value: response?.detail.total });
      })
    }
  }

  useEffect(() => {
    initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsPrivilegeCode, dispatchGlobal)
  }, [])

  useEffect(() => {
    initFunctionCustom(true)
  }, [state.search])

  useEffect(() => {
    if (state.view !== pagination.pageSize) {
        initFunctionCustom(true);
        setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
    } else {
        initFunctionCustom(false);
    }
}, [pagination.pageIndex, state.view]);

  const exportToCSVComponent = () => {
    API.get({
      bodyCustom: null,
      pathCustom: state.finalAPI,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response: any) => {
      ExcelPrivilegeCode(state, stateOptions, response.data)
    })
  }
  //
  const addDataComponent = () => addData(dispatch)
  const deleteDataComponent = (item: ModelPrivilegeCode) => deleteData(item.id, item, dispatch)
  const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
  const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
  const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)


  const customButtonAction = (item: ModelPrivilegeCode, width: string | any) => {
    return (
      <div className={`d-flex gap-2 align-items-center ${width}`}>
        
        <ButtonNavigation navTo={`${Page3UserRoute}/${item.id}`} codeAccessPrivilege={state.privilegeAccess.edit} />
        <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
        
      </div>
    );
  };

  const modalAddDeleteComponentPage = () => {
    return (
      <ModalPrivilegeCode
        visibleModalAddDelete={state.visibleModalAddDelete}
        setModal={setModalComponent}
        deleteItem={state.deleteItem}
        id={state.id}
        urlApi={state.urlApi}
        dataModal={state.dataModal}
        reloadData={() => reloadDataComponent(false)}
        stateOptions={stateOptions}
      />
    )
  }

  return (
      <>
        <h1 className="Lato-300 normal w-600 s-1822 py-2">
          Privilege
        </h1>
        <TablePrivilege
          state={state}
          stateOptions={stateOptions}
          customButtonAction={customButtonAction}
          exportToCSVComponent={exportToCSVComponent}
          addDataComponent={addDataComponent}
          handleStateComponent={handleStateComponent}
          modalAddDeleteComponentPage={modalAddDeleteComponentPage}
          pagination={pagination}
          setPagination={setPagination}
        />
      </>
  );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
  urlApi: 'privilege',
  titlePage: Page12Title,
  privilegeAccess: { view: '281', add: '282', edit: '283', delete: '284', download: '285' },
  toggle: ["no", "privilegeId", "member", "ccdCode", "privilegeName", "privilegeDesc", "action"],
  complete_toggle: ["no", "privilegeId", "member", "ccdCode", "privilegeName", "privilegeDesc", "action"],
  list_column_name: ["No.", "Privilege Id", "Member", "Privilege Level", "Privilege Name", "Description"],
  // 
  visibleModalAddDelete: false,
  savedShowModal: [], // new
  SearchByPlaceHolder: 'Privilege Name',
  // 
  id: '',
  data: [],
  dataModal: null,
  search: '',
  view: 10,
  position: 10,
  list_view: [10, 15, 20, 30, 40, 50],
  totalData: 0, // new
  totalPages: 0,
  currentPage: 0,
  insertPage: false,
  deleteItem: false,
  // 
  widthTable: 1300,
  dataLength: 0, // zIndex
  finalAPI: '',
};


// privilegeId ModelPrivilegeCode
function reducer(state: any, action: any) {
  let showDataResult: ShowModalInterface[] = [];
  let dataWhereChangeToIdGeneral: any[] = [];
  let newSavedShowModal: ShowModalInterface[] = [];
  switch (action.type) {
    case 'data':
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelPrivilegeCode) => {
            element1.id = element1.privilegeId;
            dataWhereChangeToIdGeneral.push(element1);
          })
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case 'searchData':
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelPrivilegeCode) => {
            element1.id = element1.privilegeId;
            dataWhereChangeToIdGeneral.push(element1);
          })
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        position: state.view,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    case 'showModal':
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            let conditionShowModalFind = false;
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value.idModal) {
                conditionShowModalFind = true;
              }
            })
            if (conditionShowModalFind === false) {
              newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
              newSavedShowModal.push(...state.savedShowModal);
            } else {
              state.savedShowModal.forEach((element1: ShowModalInterface) => {
                if (element1.idModal === action.value.idModal) {
                  if (element1.showModal === false) { // will to show
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                  } else {
                    newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                  }
                } else {
                  newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                }
              })
            }

          } else {
            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case 'changeIndex':
      if (state.savedShowModal !== undefined) {
        if (state.savedShowModal !== null) {
          if (state.savedShowModal.length > 0) {
            state.savedShowModal.forEach((element1: ShowModalInterface) => {
              if (element1.idModal === action.value) {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
              } else {
                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
              }
            })
          }
        }
      }
      return {
        ...state,
        savedShowModal: newSavedShowModal,
        dataLength: state.dataLength + 1,
      };
    case 'afterUpdate':
      if (action.value != null) {
        if (action.value.length > 0) {
          action.value.forEach((element1: ModelPrivilegeCode) => {
            showDataResult.push(
              { idModal: element1.privilegeId, showModal: false, itemModal: element1, index: 1 }
            )
            element1.id = element1.privilegeId;
            dataWhereChangeToIdGeneral.push(element1);
          })
        }
      }
      return {
        ...state,
        data: dataWhereChangeToIdGeneral,
        showModal: showDataResult,
        dataLength: dataWhereChangeToIdGeneral.length,
      };
    // 
    case 'widthTable':
      return {
        ...state,
        widthTable: action.value
      };
    case 'visibleModalAddDelete':
      return {
        ...state,
        visibleModalAddDelete: action.value
      };
    case 'id':
      return {
        ...state,
        id: action.value
      };
    case 'label':
      return {
        ...state,
        label: action.value
      };
    case 'toggle':
      return {
        ...state,
        toggle: action.value
      };
    case 'complete_toggle':
      return {
        ...state,
        complete_toggle: action.value
      };
    case 'list_column_name':
      return {
        ...state,
        list_column_name: action.value
      };
    case 'privilegeAccess':
      return {
        ...state,
        privilegeAccess: action.value
      };
    case 'deleteItem':
      return {
        ...state,
        deleteItem: action.value
      };
    case 'list_view':
      return {
        ...state,
        list_view: action.value
      };
    case 'view':
      return {
        ...state,
        view: action.value
      };
    case 'position':
      return {
        ...state,
        position: action.value
      };
    case 'search':
      return {
        ...state,
        search: action.value
      };
    case 'dataModal':
      return {
        ...state,
        dataModal: action.value
      };
    case 'insertPage':
      return {
        ...state,
        insertPage: action.value
      };
    case 'addData':
      return {
        ...state,
        id: '',
        dataModal: null,
        visibleModalAddDelete: !(state.visibleModalAddDelete),
      };
    case 'editData':
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        visibleModalAddDelete: true,
      };
    case 'deleteData':
      return {
        ...state,
        id: action.value.id,
        dataModal: action.value.dataModal,
        deleteItem: true,
      };
    // new
    case 'totalData':
      return {
        ...state,
        totalData: action.value
      };
    case 'totalPages':
      return {
        ...state,
        totalPages: action.value
      };
    case 'currentPage':
      return {
        ...state,
        currentPage: action.value
      };
    case 'finalAPI':
      return {
        ...state,
        finalAPI: action.value
      };
    default:
      throw new Error();
  }
}


import { API, urlSelection } from '../../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../../Utils';
import { FooterModalCreate, ModalDelete } from '../../../Components';
import { inputDescriptionStyle, inputStyle, labelStyle } from '../../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelLogLevelManagement } from '../Models/ModelLogLevelManagement';
import { Page21Title } from '../../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../../Assets/Images/svg';
import { loadingAction } from '../../../Store/Loading';
import { logoutAction } from '../../../Store/User';

export const ModalLogLevelManagement = ({ ...props }) => {
      const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsLogLevelProgramClassificationDetailCode, optionsLogLevelDetailCode, optionsCommonStatusDetailCode, optionsService, optionsBatch } = props.stateOptions
    
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
             
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
               
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { logLevelId, serviceName, logLevelName, logLevelCode, logLevelStatus, logLevelStatusCode, logLevelDate, logLevelTime, programId, programName, programClassCode, programClassName, errorMessage } = statePage.data
        const data = {
            // logLevelId: id,
            programClassCode: programClassCode,
            programId: programId,
            logLevelCode: logLevelCode,
            logLevelStatusCode: logLevelStatusCode,
        };
        const a = CheckAllFields([programClassCode, programId, logLevelCode, logLevelStatusCode], ['PROGRAM CLASS', 'PROGRAM NAME', 'LOG LEVEL', 'STATUS']);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                 
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                   
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>

                            <div className='col-6'>
                                <p className={labelStyle}>Program Class</p>
                                <Select placeholder={'(Search)'} options={optionsLogLevelProgramClassificationDetailCode} value={optionsLogLevelProgramClassificationDetailCode.filter((option: any) => option.value === statePage.data.programClassCode)} onChange={(e) => handleState('programClassCode', e.value)} />
                            </div>
                            {
                                statePage.data.programClassCode &&
                                    statePage.data.programClassCode === '001' ?
                                    <div className='col-6'>
                                        <p className={labelStyle}>Program Name</p>
                                        <Select placeholder={'(Search)'} options={optionsService} value={optionsService.filter((option: any) => option.value === statePage.data.programId)} onChange={(e) => handleState('programId', e.value)} />
                                    </div>
                                    :
                                    statePage.data.programClassCode === '001' ?
                                        <div className='col-6'>
                                            <p className={labelStyle}>Program Name</p>
                                            <Select placeholder={'(Search)'} options={optionsBatch} value={optionsBatch.filter((option: any) => option.value === statePage.data.programId)} onChange={(e) => handleState('programId', e.value)} />
                                        </div>
                                        :
                                        <></>
                            }

                            <div className='col-6'>
                                <p className={labelStyle}>Log Level</p>
                                <Select placeholder={'(Search)'} options={optionsLogLevelDetailCode} value={optionsLogLevelDetailCode.filter((option: any) => option.value === statePage.data.logLevelCode)} onChange={(e) => handleState('logLevelCode', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Status</p>
                                <Select placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.data.logLevelStatusCode)} onChange={(e) => handleState('logLevelStatusCode', `${e.value}`)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />


            <Modal isOpen={statePage.showModalError} style={ModalStyle}>
                <div className='d-flex flex-column wd-300'>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className=''></p>
                        <p className='w-700 Lato-700 text-danger s-1822'>Alert</p>
                        <div className='pointer' onClick={(e) => handleState('showModalError', false)}>{close} </div>
                    </div>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='s-1620 mb-30'>This Data Can't be delete </p>
                    </div>
                </div>
            </Modal>
        </>
    )
}


const initialState = {
    titleModal: `New ${Page21Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelLogLevelManagement({
        id: null,
        logLevelId: null,
        serviceName: null,
        logLevelName: null,
        logLevelCode: null,
        logLevelStatus: '001',
        logLevelStatusCode: null,
        logLevelDate: null,
        logLevelTime: null,
        programId: null,
        programName: null,
        programClassCode: null,
        programClassName: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelLogLevelManagement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }


    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelLogLevelManagement({
                    id: null,
                    logLevelId: null,
                    serviceName: null,
                    logLevelName: null,
                    logLevelCode: null,
                    logLevelStatus: '001',
                    logLevelStatusCode: null,
                    logLevelDate: null,
                    logLevelTime: null,
                    programId: null,
                    programName: null,
                    programClassCode: null,
                    programClassName: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelLogLevelManagement({
                    id: action.value.logLevelId,
                    logLevelId: action.value.logLevelId,
                    serviceName: action.value.serviceName,
                    logLevelName: action.value.logLevelName,
                    logLevelCode: action.value.logLevelCode,
                    logLevelStatus: action.value.logLevelStatus,
                    logLevelStatusCode: action.value.logLevelStatusCode,
                    logLevelDate: action.value.logLevelDate,
                    logLevelTime: action.value.logLevelTime,
                    programId: action.value.programId,
                    programName: action.value.programName,
                    programClassCode: action.value.programClassCode,
                    programClassName: action.value.programClassName,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}


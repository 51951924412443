import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { checklistButtonIcon, errNotificationIcon } from '../../../../Assets/Images/svg';
import { inputStyle, inputStyleH25, inputStyleWithoutW100, inputStyleWithoutW100ReadOnly, labelStyleW250PX } from '../../../../Styles';
import { useEffect, useReducer, useState } from 'react';

import { API } from '../../../../Services';
import { Buffer } from 'buffer';
import { CheckInput09 } from '../../../../Utils';
import { FormLabel } from 'react-bootstrap';
import { ModalInterfaceFile } from '../Models/ModelInterfaceFile';
import Select from 'react-select';
import { Tooltip } from 'antd';
import { UploadFileComponent } from '../../../../Components';
import { saveAs } from "file-saver";
import { urlSelection } from '../../../../Services/API';

export const GetInterfaceFile = ({ ...props }) => {

    const { codeMember, stateOptions } = props;
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => setShowPassword(!showPassword)

    //
    const [resetUpload, setResetUpload] = useState(false);
    //
    const handleUploadSuccess = () => {
        setResetUpload(false); //
    };
    //
    const handleUpdateData = () => {
        setResetUpload(true); // 
        updateData(); //
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["memberSftpServer", "memberSftpUsername", "memberSftpHost", "memberSftpPort", "memberSftpPassword"]
        let limit = [128, 125, 125, 5, 500]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (nameState === 'memberSftpPort') {
                let errorMessage = {
                    id: '1',
                    errorMessage: 'MEMBER SFTP PORT must contain only  numbers (0-9)'
                }
                if (CheckInput09.test(valueState.toString().toUpperCase())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            } else if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    useEffect(() => {
        API.get({
            bodyCustom: null,
            pathCustom: `member_parameter/interface/${codeMember}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            dispatchStatePage({ type: 'setData', value: response.data })
        })
    }, [])

    //

    const updateData = () => {
        const {
            memberParamsId,
            memberCode,
            memberReconFileRcvYn,
            memberReconFileRcvId,
            memberReconFileSndYn,
            memberReconFileSndId,
            memberSettleFileRcvYn,
            memberSettleFileRcvId,
            memberSettleFileSndYn,
            memberSettleFileSndId,
            memberMerchantFileRcvYn,
            memberMerchantFileRcvId,
            memberMerchantFileSndYn,
            memberMerchantFileSndId,
            memberPaymentFileRcvYn,
            memberPaymentFileRcvId,
            memberPaymentFileSndYn,
            memberPaymentFileSndId,
            memberBillingRcvYn,
            memberBillingRcvId,
            memberBillingSndYn,
            memberBillingSndId,
            memberReconResFileSndYn,
            memberReconResFileRcvId,
            memberMerchantResFileRcvYn,
            memberReconResFileSndId,
            memberReconResFileRcvYn,
            memberMerchantResFileRcvId,
            memberMerchantResFileSndYn,
            memberMerchantResFileSndId,
            memberUserSftpYn,
            memberSftpServer,
            memberSftpUsername,
            memberSftpHost,
            memberSftpPort,
            memberSftpPassword,
            memberSftpPublicKey,
        } = statePage.data

        let dataRelation: any = [
            {
                title: 'RECON FILE RECEIVE',
                condition: memberReconFileRcvYn,
                value: memberReconFileRcvId
            },
            {
                title: 'RECON FILE SEND',
                condition: memberReconFileSndYn,
                value: memberReconFileSndId
            },
            {
                title: 'SETTLEMENT FILE RECEIVE',
                condition: memberSettleFileRcvYn,
                value: memberSettleFileRcvId
            },
            {
                title: 'SETTLEMENT FILE SEND',
                condition: memberSettleFileSndYn,
                value: memberSettleFileSndId
            },
            {
                title: 'MERCHANT FILE RECEIVE',
                condition: memberMerchantFileRcvYn,
                value: memberMerchantFileRcvId
            },
            {
                title: 'MERCHANT FILE SEND',
                condition: memberMerchantFileSndYn,
                value: memberMerchantFileSndId
            },
            {
                title: 'PAYMENT FILE RECEIVE',
                condition: memberPaymentFileRcvYn,
                value: memberPaymentFileRcvId
            },
            {
                title: 'PAYMENT FILE SEND',
                condition: memberPaymentFileSndYn,
                value: memberPaymentFileSndId
            },
            {
                title: 'BILLING FILE RECEIVE',
                condition: memberBillingRcvYn,
                value: memberBillingRcvId
            },

            {
                title: 'BILLING FILE SEND',
                condition: memberBillingSndYn,
                value: memberBillingSndId
            },
            {
                title: 'RECON RESPONSE FILE RECEIVE',
                condition: memberReconResFileRcvYn,
                value: memberReconResFileRcvId
            },
            {
                title: 'RECON RESPONSE FILE SEND',
                condition: memberReconResFileSndYn,
                value: memberReconResFileSndId
            },

            {
                title: 'MERCHANT RESPONSE FILE RECEIVE',
                condition: memberMerchantResFileRcvYn,
                value: memberMerchantResFileRcvId
            },
            {
                title: 'MERCHANT RESPONSE FILE SEND',
                condition: memberMerchantResFileSndYn,
                value: memberMerchantResFileSndId
            },
            {
                title: 'MEMBER SFTP SERVER',
                condition: memberUserSftpYn,
                value: memberSftpServer
            },
            {
                title: 'MEMBER SFTP HOST',
                condition: memberUserSftpYn,
                value: memberSftpHost
            },
            {
                title: 'MEMBER SFTP USERNAME',
                condition: memberUserSftpYn,
                value: memberSftpUsername
            },
            {
                title: 'MEMBER SFTP PORT',
                condition: memberUserSftpYn,
                value: memberSftpPort
            },
            {
                title: 'MEMBER SFTP PASSWORD',
                condition: memberUserSftpYn,
                value: memberSftpPassword
            },
            {
                title: 'MEMBER SFTP PUBLIC KEY',
                condition: memberUserSftpYn,
                value: (statePage.selectFileArray.length === 0) && (memberSftpPublicKey === '') ? null : 'value is existed'
            },
        ]
        let errorMessage: any[] = [];
        dataRelation.forEach((item: any) => {
            if (item.condition) {
                if (item.value === null) errorMessage.push(item.title)
                else if (item.value === '') errorMessage.push(item.title)
            }
        });
        //
        if (errorMessage.length > 0) {
            if (errorMessage.length === 1) {
                handleState('errorMessage', `${errorMessage.join(', ').toString()} IS REQUIRED`)
            } else {
                handleState('errorMessage', `${errorMessage.join(', ').toString()} ARE REQUIRED`)
            }
        } else {
            if (statePage.errorMessageList.length > 0) { }
            else {
                let formData = new FormData()
                formData.append("memberCode", memberCode);
                formData.append("memberReconFileRcvYn", memberReconFileRcvYn);
                formData.append("memberReconFileRcvId", memberReconFileRcvYn === true ? memberReconFileRcvId : '');
                formData.append("memberReconFileSndYn", memberReconFileSndYn);
                formData.append("memberReconFileSndId", memberReconFileSndYn === true ? memberReconFileSndId : '');
                formData.append("memberSettleFileRcvYn", memberSettleFileRcvYn);
                formData.append("memberSettleFileRcvId", memberSettleFileRcvYn === true ? memberSettleFileRcvId : '');
                formData.append("memberSettleFileSndYn", memberSettleFileSndYn);
                formData.append("memberSettleFileSndId", memberSettleFileSndYn === true ? memberSettleFileSndId : '');
                formData.append("memberMerchantFileRcvYn", memberMerchantFileRcvYn);
                formData.append("memberMerchantFileRcvId", memberMerchantFileRcvYn === true ? memberMerchantFileRcvId : '');
                formData.append("memberMerchantFileSndYn", memberMerchantFileSndYn);
                formData.append("memberMerchantFileSndId", memberMerchantFileSndYn === true ? memberMerchantFileSndId : '');
                formData.append("memberPaymentFileRcvYn", memberPaymentFileRcvYn);
                formData.append("memberPaymentFileRcvId", memberPaymentFileRcvYn === true ? memberPaymentFileRcvId : '');
                formData.append("memberPaymentFileSndYn", memberPaymentFileSndYn);
                formData.append("memberPaymentFileSndId", memberPaymentFileSndYn === true ? memberPaymentFileSndId : '');
                formData.append("memberBillingRcvYn", memberBillingRcvYn);
                formData.append("memberBillingRcvId", memberBillingRcvYn === true ? memberBillingRcvId : '');
                formData.append("memberBillingSndYn", memberBillingSndYn);
                formData.append("memberBillingSndId", memberBillingSndYn === true ? memberBillingSndId : '');
                formData.append("memberReconResFileSndYn", memberReconResFileSndYn);
                formData.append("memberReconResFileRcvId", memberReconResFileSndYn === true ? memberReconResFileRcvId : '');
                formData.append("memberMerchantResFileRcvYn", memberMerchantResFileRcvYn);
                formData.append("memberReconResFileSndId", memberMerchantResFileRcvYn === true ? memberReconResFileSndId : '');
                formData.append("memberReconResFileRcvYn", memberReconResFileRcvYn);
                formData.append("memberMerchantResFileRcvId", memberReconResFileRcvYn === true ? memberMerchantResFileRcvId : '');
                formData.append("memberMerchantResFileSndYn", memberMerchantResFileSndYn);
                formData.append("memberMerchantResFileSndId", memberMerchantResFileSndYn === true ? memberMerchantResFileSndId : '');
                formData.append("memberUserSftpYn", memberUserSftpYn);
                formData.append("memberSftpServer", memberUserSftpYn === true ? memberSftpServer : '');
                formData.append("memberSftpUsername", memberUserSftpYn === true ? memberSftpUsername : '');
                formData.append("memberSftpHost", memberUserSftpYn === true ? memberSftpHost : '');
                formData.append("memberSftpPort", memberUserSftpYn === true ? memberSftpPort : '');
                formData.append("memberSftpPassword", memberUserSftpYn === true ? memberSftpPassword : '');
                if (memberUserSftpYn === true) {
                    if (statePage.selectFileArray.length === 0) {
                        API.putFile({
                            bodyCustom: formData,
                            pathCustom: `member_parameter/interface`,
                            selectUrl: urlSelection.dashboard,
                            useToken: true,
                            needLoading: true,
                        }).then((response) => {
                            if (response.data !== undefined) {
                                dispatchStatePage({ type: 'setData', value: response.data })
                            }
                            if (memberUserSftpYn === false) {
                                dispatchStatePage({ type: 'clearMemberUserSftpYn', })
                            }
                            handleState('errorMessage', ``)
                            //setResetUpload(true);
                            handleUploadSuccess()
                        }).catch((err) => {
                            handleState('errorMessage', err)
                        })
                     }
                    else if (statePage.selectFileArray.length > 0) {
                        let sizeMax = 0;
                        formData.append("memberSftpPublicKey", memberUserSftpYn === true ? statePage.selectFileArray[0] : '');
                        sizeMax = sizeMax + statePage.selectFileArray[0].size;
                        if (sizeMax < (5000 * 1000)) {
                            API.putFile({
                                bodyCustom: formData,
                                pathCustom: `member_parameter/interface`,
                                selectUrl: urlSelection.dashboard,
                                useToken: true,
                                needLoading: true,
                            }).then((response) => {
                                if (response.data !== undefined) {
                                    dispatchStatePage({ type: 'setData', value: response.data })
                                }
                                if (memberUserSftpYn === false) {
                                    dispatchStatePage({ type: 'clearMemberUserSftpYn', })
                                }
                                handleState('errorMessage', ``)
                                //setResetUpload(true);
                                handleUploadSuccess()
                            }).catch((err) => {
                                handleState('errorMessage', err)
                            })
                        } else if (sizeMax > (5000 * 1000)) {
                            handleState('errorMessage', 'Maximum upload size exceeded, maximum size is 5Mb');
                        }
                    }
                }
                else {
                    API.putFile({
                        bodyCustom: formData,
                        pathCustom: `member_parameter/interface`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    }).then((response) => {
                        if (response.data !== undefined) {
                            dispatchStatePage({ type: 'setData', value: response.data })
                        }
                        if (memberUserSftpYn === false) {
                            dispatchStatePage({ type: 'clearMemberUserSftpYn', })
                        }
                        handleState('errorMessage', ``)
                        //setResetUpload(true);
                        handleUploadSuccess()
                    }).catch((err) => {
                        handleState('errorMessage', err)
                    })
                }
            }

            // if (errorMessage.length > 0) {
            //     if (errorMessage.length === 1) {
            //         handleState('errorMessage', `${errorMessage.join(', ').toString()} IS REQUIRED`)
            //     } else {
            //         handleState('errorMessage', `${errorMessage.join(', ').toString()} ARE REQUIRED`)
            //     }
            // } else {
            //     if (statePage.errorMessageList.length > 0) { }
            //     else {
            //         const data = {
            // memberCode: memberCode,
            // memberReconFileRcvYn: memberReconFileRcvYn,
            // memberReconFileRcvId: memberReconFileRcvYn === true ? memberReconFileRcvId : null,

            // memberReconFileSndYn: memberReconFileSndYn,
            // memberReconFileSndId: memberReconFileSndYn === true ? memberReconFileSndId : null,

            // memberSettleFileRcvYn: memberSettleFileRcvYn,
            // memberSettleFileRcvId: memberSettleFileRcvYn === true ? memberSettleFileRcvId : null,

            // memberSettleFileSndYn: memberSettleFileSndYn,
            // memberSettleFileSndId: memberSettleFileSndYn === true ? memberSettleFileSndId : null,

            // memberMerchantFileRcvYn: memberMerchantFileRcvYn,
            // memberMerchantFileRcvId: memberMerchantFileRcvYn === true ? memberMerchantFileRcvId : null,

            // memberMerchantFileSndYn: memberMerchantFileSndYn,
            // memberMerchantFileSndId: memberMerchantFileSndYn === true ? memberMerchantFileSndId : null,

            // memberPaymentFileRcvYn: memberPaymentFileRcvYn,
            // memberPaymentFileRcvId: memberPaymentFileRcvYn === true ? memberPaymentFileRcvId : null,

            // memberPaymentFileSndYn: memberPaymentFileSndYn,
            // memberPaymentFileSndId: memberPaymentFileSndYn === true ? memberPaymentFileSndId : null,

            // memberBillingRcvYn: memberBillingRcvYn,
            // memberBillingRcvId: memberBillingRcvYn === true ? memberBillingRcvId : null,

            // memberBillingSndYn: memberBillingSndYn,
            // memberBillingSndId: memberBillingSndYn === true ? memberBillingSndId : null,

            // memberReconResFileSndYn: memberReconResFileSndYn,
            // memberReconResFileSndId: memberReconResFileSndYn === true ? memberReconResFileSndId : null,

            // memberReconResFileRcvYn: memberReconResFileRcvYn,
            // memberReconResFileRcvId: memberReconResFileRcvYn === true ? memberReconResFileRcvId : null,

            // memberMerchantResFileRcvYn: memberMerchantResFileRcvYn,
            // memberMerchantResFileRcvId: memberMerchantResFileRcvYn === true ? memberMerchantResFileRcvId : null,

            // memberMerchantResFileSndYn: memberMerchantResFileSndYn,
            // memberMerchantResFileSndId: memberMerchantResFileSndYn === true ? memberMerchantResFileSndId : null,



            // memberUserSftpYn: memberUserSftpYn,
            // memberSftpServer: memberUserSftpYn === true ? memberSftpServer : null,
            // memberSftpUsername: memberUserSftpYn === true ? memberSftpUsername : null,
            // memberSftpHost: memberUserSftpYn === true ? memberSftpHost : null,
            // memberSftpPort: memberUserSftpYn === true ? memberSftpPort : null,
            // memberSftpPassword: memberUserSftpYn === true ? memberSftpPassword : null,
            // //
            // memberSftpPublicKey: memberUserSftpYn === true ? memberSftpPublicKey : null,
            //         API.update({
            //             bodyCustom: formData,
            //             pathCustom: `member_parameter/interface`,
            //             selectUrl: urlSelection.dashboard,
            //             useToken: true,
            //         }).then((response: any) => {
            //             if (response.data !== undefined) {
            //                 dispatchStatePage({ type: 'setData', value: response.data })
            //             }
            //             if (memberUserSftpYn === false) {
            //                 dispatchStatePage({ type: 'clearMemberUserSftpYn', })
            //             }
            //         })
            //     }

        }



    }

    return (
        <div className='d-flex flex-column justify-content-between bd-ea br-10 px-2 pt-2 pb-5'>
            <div className='row p-0 m-0'>
                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                    <div className='row gy-2 gx-3 mx-0 my-3'>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>RECON FILE RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'
                                            onClick={() => handleState('memberReconFileRcvYn', true)}
                                            checked={statePage.data.memberReconFileRcvYn === true}
                                            name='memberReconFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberReconFileRcvYn', false)}
                                            checked={statePage.data.memberReconFileRcvYn === false}
                                            name='memberReconFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                {
                                    statePage.data.memberReconFileRcvYn === true ?
                                        <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberReconFileRcvId)} onChange={(e) => handleState('memberReconFileRcvId', e.value)} />
                                        :
                                        <Select styles={inputStyleH25} isDisabled={true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberReconFileRcvId)} onChange={(e) => handleState('memberReconFileRcvId', e.value)} />
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>RECON FILE SEND</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberReconFileSndYn', true)}
                                            checked={statePage.data.memberReconFileSndYn === true}
                                            name='memberReconFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberReconFileSndYn', false)}
                                            checked={statePage.data.memberReconFileSndYn === false}
                                            name='memberReconFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                {
                                    statePage.data.memberReconFileSndYn === true ?
                                        <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberReconFileSndId)} onChange={(e) => handleState('memberReconFileSndId', e.value)} />
                                        :
                                        <Select styles={inputStyleH25} isDisabled={true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberReconFileSndId)} onChange={(e) => handleState('memberReconFileSndId', e.value)} />
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>SETTLEMENT FILE RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberSettleFileRcvYn', true)}

                                            checked={statePage.data.memberSettleFileRcvYn === true}
                                            name='memberSettleFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberSettleFileRcvYn', false)}
                                            checked={statePage.data.memberSettleFileRcvYn === false}
                                            name='memberSettleFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                {
                                    statePage.data.memberSettleFileRcvYn === true ?
                                        <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberSettleFileRcvId)} onChange={(e) => handleState('memberSettleFileRcvId', e.value)} />
                                        :
                                        <Select styles={inputStyleH25} isDisabled={true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberSettleFileRcvId)} onChange={(e) => handleState('memberSettleFileRcvId', e.value)} />

                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>SETTLEMENT FILE SEND</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberSettleFileSndYn', true)}
                                            checked={statePage.data.memberSettleFileSndYn === true}
                                            name='memberSettleFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberSettleFileSndYn', false)}
                                            checked={statePage.data.memberSettleFileSndYn === false}
                                            name='memberSettleFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                {
                                    statePage.data.memberSettleFileSndYn === true ?
                                        <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberSettleFileSndId)} onChange={(e) => handleState('memberSettleFileSndId', e.value)} />
                                        :
                                        <Select styles={inputStyleH25} isDisabled={true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberSettleFileSndId)} onChange={(e) => handleState('memberSettleFileSndId', e.value)} />
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MERCHANT FILE RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantFileRcvYn', true)}

                                            checked={statePage.data.memberMerchantFileRcvYn === true}
                                            name='memberMerchantFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantFileRcvYn', false)}
                                            checked={statePage.data.memberMerchantFileRcvYn === false}

                                            name='memberMerchantFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                {
                                    statePage.data.memberMerchantFileRcvYn === true ?
                                        <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberMerchantFileRcvId)} onChange={(e) => handleState('memberMerchantFileRcvId', e.value)} />
                                        :
                                        <Select styles={inputStyleH25} isDisabled={true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberMerchantFileRcvId)} onChange={(e) => handleState('memberMerchantFileRcvId', e.value)} />
                                }

                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MERCHANT FILE SEND</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantFileSndYn', true)}
                                            checked={statePage.data.memberMerchantFileSndYn === true}
                                            name='memberMerchantFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantFileSndYn', false)}
                                            checked={statePage.data.memberMerchantFileSndYn === false}
                                            name='memberMerchantFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>

                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberMerchantFileSndYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberMerchantFileSndId)} onChange={(e) => handleState('memberMerchantFileSndId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>PAYMENT FILE RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberPaymentFileRcvYn', true)}

                                            checked={statePage.data.memberPaymentFileRcvYn === true}
                                            name='memberPaymentFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberPaymentFileRcvYn', false)}
                                            checked={statePage.data.memberPaymentFileRcvYn === false}
                                            name='memberPaymentFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberPaymentFileRcvYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberPaymentFileRcvId)} onChange={(e) => handleState('memberPaymentFileRcvId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>PAYMENT FILE SEND</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberPaymentFileSndYn', true)}

                                            checked={statePage.data.memberPaymentFileSndYn === true}
                                            name='memberPaymentFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberPaymentFileSndYn', false)}
                                            checked={statePage.data.memberPaymentFileSndYn === false}
                                            name='memberPaymentFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>

                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberPaymentFileSndYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberPaymentFileSndId)} onChange={(e) => handleState('memberPaymentFileSndId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>BILLING FILE RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberBillingRcvYn', true)}

                                            checked={statePage.data.memberBillingRcvYn === true}
                                            name='memberBillingRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberBillingRcvYn', false)}

                                            checked={statePage.data.memberBillingRcvYn === false}
                                            name='memberBillingRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>

                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberBillingRcvYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberBillingRcvId)} onChange={(e) => handleState('memberBillingRcvId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>BILLING FILE SEND</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberBillingSndYn', true)}

                                            checked={statePage.data.memberBillingSndYn === true}
                                            name='memberBillingSndYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberBillingSndYn', false)}
                                            checked={statePage.data.memberBillingSndYn === false}
                                            name='memberBillingSndYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberBillingSndYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberBillingSndId)} onChange={(e) => handleState('memberBillingSndId', e.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                    <div className='row gy-2 gx-3 mx-0 my-3'>

                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>RECON RESPONSE FILE RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberReconResFileRcvYn', true)}
                                            checked={statePage.data.memberReconResFileRcvYn === true}
                                            name='memberReconResFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberReconResFileRcvYn', false)}
                                            checked={statePage.data.memberReconResFileRcvYn === false}
                                            name='memberReconResFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberReconResFileRcvYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberReconResFileRcvId)} onChange={(e) => handleState('memberReconResFileRcvId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>RECON RESPONSE FILE SEND</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberReconResFileSndYn', true)}

                                            checked={statePage.data.memberReconResFileSndYn === true}
                                            name='memberReconResFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberReconResFileSndYn', false)}
                                            checked={statePage.data.memberReconResFileSndYn === false}

                                            name='memberReconResFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>

                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberReconResFileSndYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberReconResFileSndId)} onChange={(e) => handleState('memberReconResFileSndId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MERCHANT RESPONSE FILE RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantResFileRcvYn', true)}
                                            checked={statePage.data.memberMerchantResFileRcvYn === true}
                                            name='memberMerchantResFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantResFileRcvYn', false)}
                                            checked={statePage.data.memberMerchantResFileRcvYn === false}

                                            name='memberMerchantResFileRcvYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>

                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberMerchantResFileRcvYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberMerchantResFileRcvId)} onChange={(e) => handleState('memberMerchantResFileRcvId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MERCHANT RESPONSE FILE SEND</p>
                                <div className='d-flex justify-content-between align-items-center h-40px w-120px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantResFileSndYn', true)}
                                            checked={statePage.data.memberMerchantResFileSndYn === true}
                                            name='memberMerchantResFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberMerchantResFileSndYn', false)}
                                            checked={statePage.data.memberMerchantResFileSndYn === false}
                                            name='memberMerchantResFileSndYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>

                                <Select styles={inputStyleH25} isDisabled={statePage.data.memberMerchantResFileSndYn === true ? false : true} className='flex-fill' options={stateOptions.optionsInterfaceFile} value={stateOptions.optionsInterfaceFile.filter((option: any) => option.value === statePage.data.memberMerchantResFileSndId)} onChange={(e) => handleState('memberMerchantResFileSndId', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>USER GAPIH SFTP</p>
                                <div className='d-flex align-items-center h-40px w-120px justify-content-between' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberUserSftpYn', true)}

                                            checked={statePage.data.memberUserSftpYn === true}
                                            name='memberUserSftpYn'
                                        />
                                        <span className='mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'

                                            onClick={() => handleState('memberUserSftpYn', false)}
                                            checked={statePage.data.memberUserSftpYn === false}
                                            name='memberUserSftpYn'
                                        />
                                        <span className='mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                <div className='flex-fill'></div>

                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MEMBER SFTP SERVER</p>
                                {statePage.data.memberUserSftpYn === true ?
                                    <input value={statePage.data.memberSftpServer} onChange={(e) => handleState('memberSftpServer', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                    :
                                    <input value={statePage.data.memberSftpServer} disabled className={inputStyleWithoutW100ReadOnly + ' flex-fill'} />
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MEMBER SFTP HOST</p>
                                {statePage.data.memberUserSftpYn === true ?
                                    <input value={statePage.data.memberSftpHost} onChange={(e) => handleState('memberSftpHost', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                    :
                                    <input value={statePage.data.memberSftpHost} disabled className={inputStyleWithoutW100ReadOnly + ' flex-fill'} />
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MEMBER SFTP USERNAME</p>
                                {statePage.data.memberUserSftpYn === true ?
                                    <input value={statePage.data.memberSftpUsername} onChange={(e) => handleState('memberSftpUsername', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                    :
                                    <input value={statePage.data.memberSftpUsername} disabled className={inputStyleWithoutW100ReadOnly + ' flex-fill'} />
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MEMBER SFTP PORT</p>
                                {statePage.data.memberUserSftpYn === true ?
                                    <input value={statePage.data.memberSftpPort} onChange={(e) => handleState('memberSftpPort', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                    :
                                    <input value={statePage.data.memberSftpPort} disabled className={inputStyleWithoutW100ReadOnly + ' flex-fill'} />
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>MEMBER SFTP PASSWORD</p>
                                {statePage.data.memberUserSftpYn === true ?
                                    <FormLabel className="container-custom-input flex-fill h-25px" htmlFor="password-input">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            value={statePage.data.memberSftpPassword}
                                            className={inputStyle}
                                            style={{fontSize:'13px', height:'23px', lineHeight:'12px'}}
                                            placeholder="Password"
                                            onChange={(e) => handleState('memberSftpPassword', e.target.value)}
                                        />

                                        <button
                                            className="btn"
                                            type="button"
                                            onClick={handlePasswordToggle}
                                        >
                                            {
                                                showPassword ?
                                                    <FaEyeSlash />
                                                    :
                                                    <FaEye />
                                            }
                                        </button>
                                    </FormLabel>

                                    // <input value={statePage.data.memberSftpPassword} onChange={(e) => handleState('memberSftpPassword', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                    :
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        value={statePage.data.memberSftpPassword}
                                        className={inputStyleWithoutW100ReadOnly + ' flex-fill'}
                                        style={{fontSize:'13px', height:'23px', lineHeight:'12px'}}
                                        placeholder="Password"
                                        disabled
                                    />


                                }
                            </div>
                        </div>
                        {/* // */}
                        <div className='col-12'>
                            <div className={''}>
                                <p className={labelStyleW250PX}>MEMBER SFTP PUBLIC KEY</p>
                                {
                                    statePage.data.memberUserSftpYn === true && (
                                        statePage.data.memberSftpPublicKey === '' ?
                                            <UploadFileComponent handleStateParent={handleState} stateParent={statePage} useMultipleFile={false} useMaxCount={1} resetUpload={resetUpload} onUploadSuccess={handleUploadSuccess} key={resetUpload.toString()}/>
                                            :
                                            <div className=''>
                                                <UploadFileComponent handleStateParent={handleState} stateParent={statePage} useMultipleFile={false} useMaxCount={1} resetUpload={resetUpload} onUploadSuccess={handleUploadSuccess}  key={resetUpload.toString()}/>
                                                <div className={'d-flex justify-content-between align-items-center'}>
                                                    <p className={labelStyleW250PX}>Document</p>
                                                    <Tooltip placement="bottom" title={'Download File'}>
                                                        <p className={'my-2 pointer c-1e90ff'} onClick={() => downloadFile(statePage.data.memberCode, statePage.data.memberSftpPublicKey)} >
                                                            {statePage.data.memberSftpPublicKey}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column'>
                {
                    statePage.errorMessageList.length > 0 ?
                        <div className='col-12 pl-0'>
                            <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                {errNotificationIcon}
                                <p className='px-2 s-1418 Lato-600 c-e87'>{statePage.errorMessageList.map((item: any) => item.errorMessage).toString()}</p>
                            </div>
                        </div>
                        :
                        statePage.errorMessage !== '' && (
                            <div className='col-12 pl-0'>
                                <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                    {errNotificationIcon}
                                    <p className='px-2 s-1418 Lato-600 c-e87'>{statePage.errorMessage}</p>
                                </div>
                            </div>

                        )
                }
                <div className='d-flex'>
                    {/* <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-3 ' onClick={updateData}> */}
                    <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-2' onClick={handleUpdateData}>
                        {checklistButtonIcon}
                        <p className='s-1214 c-ff Lato-600 mx-2'>SAVE</p>
                    </button>
                </div>
            </div>
        </div>
    )
}
// const downloadFile = (endpoint: any, fileNameFromAPI: any) => {
//     API.getFile({
//         bodyCustom: null,
//         pathCustom: `member_parameter/interface/public_key/${endpoint}`,
//         selectUrl: urlSelection.dashboard,
//         useToken: true,
//         needLoading: true,
//     })
//         .then((response) => {
//             try {
//                 let data = `data:${response.headers["content-type"]};base64,${Buffer.from(response.data, "binary").toString("base64")}`;
//                 let fileArray = fileNameFromAPI.toString().split('.');
//                 let fileName = fileArray.slice(0, -1).join('.');
//                 let fileExtensionType = fileArray[fileArray.length - 1];
//                 for (let index = 0; index < fileArray.length - 1; index++) {
//                     fileName = fileName + fileArray[index];
//                 }
//                 saveAs(data, `${fileName}.${fileExtensionType}`);
//             } catch (error) {
//                 console.error(error);
//             }
//         })
//         .catch((err) => {
//             console.error(err);
//         });
// };
//
const downloadFile = (endpoint: any, fileNameFromAPI: any) => {
    API.getFile({
      bodyCustom: null,
      pathCustom: `member_parameter/interface/public_key/${endpoint}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    })
      .then((response) => {
        try {
          let data = `data:${response.headers["content-type"]};base64,${Buffer.from(response.data, "binary").toString("base64")}`;
          let fileArray = fileNameFromAPI.toString().split('.');
          let fileName = fileArray[0]; // Mengambil elemen pertama sebagai awal nama file
          let fileExtensionType = fileArray[fileArray.length - 1];
  
          for (let index = 1; index < fileArray.length - 1; index++) {
            fileName = fileName + '.' + fileArray[index]; // Menggabungkan elemen-elemen tengah
          }
  
          saveAs(data, `${fileName}.${fileExtensionType}`);
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

const initialState = {
    data: new ModalInterfaceFile({
        id: null,
        memberParamsId: null,
        memberCode: null,
        memberReconFileRcvYn: null,
        memberReconFileRcvId: null,
        memberReconFileSndYn: null,
        memberReconFileSndId: null,
        memberSettleFileRcvYn: null,
        memberSettleFileRcvId: null,
        memberSettleFileSndYn: null,
        memberSettleFileSndId: null,
        memberMerchantFileRcvYn: null,
        memberMerchantFileRcvId: null,
        memberMerchantFileSndYn: null,
        memberMerchantFileSndId: null,
        memberPaymentFileRcvYn: null,
        memberPaymentFileRcvId: null,
        memberPaymentFileSndYn: null,
        memberPaymentFileSndId: null,
        memberBillingRcvYn: null,
        memberBillingRcvId: null,
        memberBillingSndYn: null,
        memberBillingSndId: null,
        memberReconResFileSndYn: null,
        memberReconResFileRcvId: null,
        memberMerchantResFileRcvYn: null,
        memberReconResFileSndId: null,
        memberReconResFileRcvYn: null,
        memberMerchantResFileRcvId: null,
        memberMerchantResFileSndYn: null,
        memberMerchantResFileSndId: null,
        memberUserSftpYn: null,
        memberSftpServer: null,
        memberSftpUsername: null,
        memberSftpHost: null,
        memberSftpPort: null,
        memberSftpPassword: null,
        //
        memberSftpPublicKey: ''

    }),
    errorMessage: "",
    errorMessageList: [],
    selectFileArray: [''],
}
const reducer = (state: any, action: any) => {
    for (const key in state.data as ModalInterfaceFile) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {

        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            if (action.value.type === "removed") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                            errorMessageList: state.errorMessageList.filter((item: any) => item.id !== action.value.data.id)
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else if (action.value.type === "added") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                        }
                    } else {

                        let data: any[] = [...state.errorMessageList]
                        data.push(action.value.data)
                        return {
                            ...state,
                            errorMessageList: data
                        }
                    }
                } else {
                    let data: any[] = [...state.errorMessageList]
                    data.push(action.value.data)
                    return {
                        ...state,
                        errorMessageList: data
                    }
                }
            } else {
                return {
                    ...state,
                }
            }

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModalInterfaceFile({
                    id: null,
                    memberParamsId: null,
                    memberCode: null,
                    memberReconFileRcvYn: null,
                    memberReconFileRcvId: null,
                    memberReconFileSndYn: null,
                    memberReconFileSndId: null,
                    memberSettleFileRcvYn: null,
                    memberSettleFileRcvId: null,
                    memberSettleFileSndYn: null,
                    memberSettleFileSndId: null,
                    memberMerchantFileRcvYn: null,
                    memberMerchantFileRcvId: null,
                    memberMerchantFileSndYn: null,
                    memberMerchantFileSndId: null,
                    memberPaymentFileRcvYn: null,
                    memberPaymentFileRcvId: null,
                    memberPaymentFileSndYn: null,
                    memberPaymentFileSndId: null,
                    memberBillingRcvYn: null,
                    memberBillingRcvId: null,
                    memberBillingSndYn: null,
                    memberBillingSndId: null,
                    memberReconResFileSndYn: null,
                    memberReconResFileRcvId: null,
                    memberMerchantResFileRcvYn: null,
                    memberReconResFileSndId: null,
                    memberReconResFileRcvYn: null,
                    memberMerchantResFileRcvId: null,
                    memberMerchantResFileSndYn: null,
                    memberMerchantResFileSndId: null,
                    memberUserSftpYn: null,
                    memberSftpServer: null,
                    memberSftpUsername: null,
                    memberSftpHost: null,
                    memberSftpPort: null,
                    memberSftpPassword: null,
                    memberSftpPublicKey: null
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModalInterfaceFile({
                    id: action.value.memberParamsId,
                    memberParamsId: action.value.memberParamsId,
                    memberCode: action.value.memberCode,
                    memberReconFileRcvYn: action.value.memberReconFileRcvYn,
                    memberReconFileRcvId: action.value.memberReconFileRcvId,
                    memberReconFileSndYn: action.value.memberReconFileSndYn,
                    memberReconFileSndId: action.value.memberReconFileSndId,
                    memberSettleFileRcvYn: action.value.memberSettleFileRcvYn,
                    memberSettleFileRcvId: action.value.memberSettleFileRcvId,
                    memberSettleFileSndYn: action.value.memberSettleFileSndYn,
                    memberSettleFileSndId: action.value.memberSettleFileSndId,
                    memberMerchantFileRcvYn: action.value.memberMerchantFileRcvYn,
                    memberMerchantFileRcvId: action.value.memberMerchantFileRcvId,
                    memberMerchantFileSndYn: action.value.memberMerchantFileSndYn,
                    memberMerchantFileSndId: action.value.memberMerchantFileSndId,
                    memberPaymentFileRcvYn: action.value.memberPaymentFileRcvYn,
                    memberPaymentFileRcvId: action.value.memberPaymentFileRcvId,
                    memberPaymentFileSndYn: action.value.memberPaymentFileSndYn,
                    memberPaymentFileSndId: action.value.memberPaymentFileSndId,
                    memberBillingRcvYn: action.value.memberBillingRcvYn,
                    memberBillingRcvId: action.value.memberBillingRcvId,
                    memberBillingSndYn: action.value.memberBillingSndYn,
                    memberBillingSndId: action.value.memberBillingSndId,
                    memberReconResFileSndYn: action.value.memberReconResFileSndYn,
                    memberReconResFileRcvId: action.value.memberReconResFileRcvId,
                    memberMerchantResFileRcvYn: action.value.memberMerchantResFileRcvYn,
                    memberReconResFileSndId: action.value.memberReconResFileSndId,
                    memberReconResFileRcvYn: action.value.memberReconResFileRcvYn,
                    memberMerchantResFileRcvId: action.value.memberMerchantResFileRcvId,
                    memberMerchantResFileSndYn: action.value.memberMerchantResFileSndYn,
                    memberMerchantResFileSndId: action.value.memberMerchantResFileSndId,
                    memberUserSftpYn: action.value.memberUserSftpYn,
                    memberSftpServer: action.value.memberSftpServer,
                    memberSftpUsername: action.value.memberSftpUsername,
                    memberSftpHost: action.value.memberSftpHost,
                    memberSftpPort: action.value.memberSftpPort,
                    memberSftpPassword: action.value.memberSftpPassword,
                    //
                    memberSftpPublicKey: action.value.memberSftpPublicKey,
                }),
                errorMessage: "",
            };
        case 'clearMemberUserSftpYn':
            return {
                ...state,
                selectFileArray: [''],
                data: {
                    ...state.data,
                    memberSftpServer: "",
                    memberSftpUsername: "",
                    memberSftpHost: "",
                    memberSftpPort: "",
                    memberSftpPassword: "",
                    //
                    memberSftpPublicKey: "",
                }
            };
        case 'memberSftpPublicKey':
            return {
                ...state,
                memberSftpPublicKey: action.value,
            };
        case 'selectFileArray':
            return {
                ...state,
                selectFileArray: action.value,
            };
        default:
            throw new Error();
    }
}
import ReactDOM from 'react-dom/client';
import React from 'react'
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { store } from "./Store/Store";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>

);

reportWebVitals();

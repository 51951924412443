
// UserManagement

interface UserManagementInterface {
    id: null | number | string;
    userId: number | null;
    memberId: number | null;
    privilegeId: number | null;
    userEmail: string | null;
    userName: string | null;
    userCategoryCode: string | null;
    userLevel: string | null;
    userStatus: string | null;
    userStartDate: string | null;
    userEndDate: string | null;
    userBlockedReason: string | null;
    memberName: string | null;
    privilegeName: string | null;

}

export class ModelUserManagement {
    id: null | number | string;
    userId: number | null;
    memberId: number | null;
    privilegeId: number | null;
    userEmail: string | null;
    userName: string | null;
    userCategoryCode: string | null;
    userLevel: string | null;
    userStatus: string | null;
    userStartDate: string | null;
    userEndDate: string | null;
    userBlockedReason: string | null;
    memberName: string | null;
    privilegeName: string | null;

    constructor(value: UserManagementInterface) {
        this.id = value.id;
        this.userId = value?.userId ?? null;
        this.memberId = value?.memberId ?? null;
        this.privilegeId = value?.privilegeId ?? null;
        this.userEmail = value?.userEmail ?? null;
        this.userName = value?.userName ?? null;
        this.userCategoryCode = value?.userCategoryCode ?? null;
        this.userLevel = value?.userLevel ?? null;
        this.userStatus = value?.userStatus ?? null;
        this.userStartDate = value?.userStartDate ?? null;
        this.userEndDate = value?.userEndDate ?? null;
        this.userBlockedReason = value?.userBlockedReason ?? null;
        this.memberName = value?.memberName ?? null;
        this.privilegeName = value?.privilegeName ?? null;
    }
}

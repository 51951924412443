import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page15Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableProductFeatureMember = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsCommonStatusDetailCode, optionsRouteSequence, optionsMember, optionsProductFeature } = props.stateOptions

    const draggleRef = React.createRef<any>();


    useEffect(() => {

        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { prodFmId, productFeatureId, memberId, pfmStatus, pfmDestMemberId, pfmRsId, productFeatureName, memberName, errorMessage } = statePage;

        const data = {
            prodFmId: id,
            productFeatureId: productFeatureId,
            memberId: memberId,
            pfmStatus: pfmStatus,
            pfmDestMemberId: pfmDestMemberId,
            pfmRsId: pfmRsId,
        };

        let a = CheckAllFields([productFeatureId, memberId, pfmDestMemberId, pfmStatus], ["Product Feature", "Member", "Dest Member", "Status"]);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.update({
                bodyCustom: data,

                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className={'col-6'}>
                        <p className={labelStyle}>Product Feature</p>
                        <Select styles={inputStyleH25} options={optionsProductFeature} value={optionsProductFeature.filter((option: any) => option.value === statePage.productFeatureId)} onChange={(e) => handleState('productFeatureId', e.value)} />
                    </div>
                    <div className={'col-6'}>
                        <p className={labelStyle}>Member</p>
                        <Select styles={inputStyleH25} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                    </div>
                    <div className={'col-6'}>
                        <p className={labelStyle}>Dest Member</p>
                        <Select styles={inputStyleH25} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.pfmDestMemberId)} onChange={(e) => handleState('pfmDestMemberId', e.value)} />
                    </div>
                    <div className={'col-6'}>
                        <p className={labelStyle}>Route Sequence</p>
                        <Select styles={inputStyleH25} options={optionsRouteSequence} value={optionsRouteSequence.filter((option: any) => option.value === statePage.pfmRsId)} onChange={(e) => handleState('pfmRsId', e.value)} />
                    </div>

                    <div className={'col-6'}>
                        <p className={labelStyle}>Status</p>
                        <Select styles={inputStyleH25} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => `${option.value}` === statePage.pfmStatus)} onChange={(e) => handleState('pfmStatus', `${e.value}`)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />

            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page15Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    //
    prodFmId: '',
    productFeatureId: '',
    memberId: '',
    pfmStatus: '001',
    pfmDestMemberId: '',
    pfmRsId: '',
    productFeatureName: '',
    memberName: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                prodFmId: '',
                productFeatureId: '',
                memberId: '',
                pfmStatus: '001',
                pfmDestMemberId: '',
                pfmRsId: '',
                productFeatureName: '',
                memberName: '',
            };
        case 'setData':
            return {
                ...state,
                prodFmId: action.value.prodFmId,
                productFeatureId: action.value.productFeatureId,
                memberId: action.value.memberId,
                pfmStatus: action.value.pfmStatus,
                pfmDestMemberId: action.value.pfmDestMemberId,
                pfmRsId: action.value.pfmRsId,
                productFeatureName: action.value.productFeatureName,
                memberName: action.value.memberName,
            };
        // 
        case 'prodFmId':
            return {
                ...state,
                prodFmId: action.value,
            };
        case 'productFeatureId':
            return {
                ...state,
                productFeatureId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'pfmStatus':
            return {
                ...state,
                pfmStatus: action.value,
            };
        case 'pfmDestMemberId':
            return {
                ...state,
                pfmDestMemberId: action.value,
            };
        case 'pfmRsId':
            return {
                ...state,
                pfmRsId: action.value,
            };
        case 'productFeatureName':
            return {
                ...state,
                productFeatureName: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        // 
        default:
            throw new Error();
    }
}


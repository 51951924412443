
// ServiceRegistration

interface ServiceRegistrationInterface {
    id: null | number | string;
    serviceId: number | null;
    serviceCode: string | null;
    serviceStat: string | null;
    serviceName: string | null;
    serviceLogLevel: string | null;
    serviceProgName: string | null;
    serviceStartDateTime: string | null;
    serviceStartDate: string | null;
    serviceStartTime: string | null;
    serviceStopDateTime: string | null;
    serviceStopDate: string | null;
    serviceStopTime: string | null;
    serviceDesc: string | null;
}

export class ModelServiceRegistration {
    id: null | number | string;
    serviceId: number | null;
    serviceCode: string | null;
    serviceStat: string | null;
    serviceName: string | null;
    serviceLogLevel: string | null;
    serviceProgName: string | null;
    serviceStartDateTime: string | null;
    serviceStartDate: string | null;
    serviceStartTime: string | null;
    serviceStopDateTime: string | null;
    serviceStopDate: string | null;
    serviceStopTime: string | null;
    serviceDesc: string | null;


    constructor(value: ServiceRegistrationInterface | null) {
        this.id = value?.id ?? null;
        this.serviceId = value?.serviceId ?? null;
        this.serviceCode = value?.serviceCode ?? null;
        this.serviceStat = value?.serviceStat ?? null;
        this.serviceName = value?.serviceName ?? null;
        this.serviceLogLevel = value?.serviceLogLevel ?? null;
        this.serviceProgName = value?.serviceProgName ?? null;
        this.serviceStartDateTime = value?.serviceStartDateTime ?? null;
        this.serviceStartDate = value?.serviceStartDate ?? null;
        this.serviceStartTime = value?.serviceStartTime ?? null;
        this.serviceStopDateTime = value?.serviceStopDateTime ?? null;
        this.serviceStopDate = value?.serviceStopDate ?? null;
        this.serviceStopTime = value?.serviceStopTime ?? null;
        this.serviceDesc = value?.serviceDesc ?? null;
    }
}

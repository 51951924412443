import { API, urlSelection } from '../../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../../Components';
import { inputDescriptionStyle, inputDescriptionStyleReadOnly, inputStyle, inputStyleReadOnly, labelStyle } from '../../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelInstitutionCode } from '../Detail/Models/ModelInstitutionCode';
import Select from 'react-select';
import { close } from '../../../Assets/Images/svg';
import { loadingAction } from '../../../Store/Loading';
import { logoutAction } from '../../../Store/User';

export const ModalInstitutionCode = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal, codeMember } = props
    const { optionsGroupCode, optionsMember, optionsInstitutionCode200, optionsInstitutionCode201, optionsInstitutionCode202, optionsInstitutionCode203 } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { instCcgCode, instCcdCode, memberInstDesc, memberInstCode, errorMessage } = statePage.data
        const data = {
            memberId: codeMember,
            instCcgCode: instCcgCode,
            instCcdCode: instCcdCode,
            memberInstCode: memberInstCode,
            memberInstDesc: memberInstDesc
        }
        const a = CheckAllFields(
            [instCcgCode, instCcdCode, memberInstDesc],
            ['GROUP CODE', 'CODE NAME', 'DESCRIPTION']
        );

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                setModal("visibleModalAddDelete")
                dispatchStatePage({ type: 'clearData' })
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    //new optionsGroupCode
    let groupList: any = [];
    if (optionsGroupCode.length > 0) {
        optionsGroupCode.forEach((data: any) => {
            if (["COM200", "COM201", "COM202", "COM203"].includes(data.value)) {
                groupList.push({
                    value: data.value,
                    label: data.label
                });
            }
        });
    }

    useEffect(() => {
        let selectedCcgCode = statePage.data.instCcgCode === '' ? '' : `ccgCode=${statePage.data.instCcgCode}&`;
        let selectedMemberId = codeMember === '' ? '' : `memberId=${codeMember}&`;
        let finalParameter = `${selectedMemberId}${selectedCcgCode}`;
        API.get({
            bodyCustom: null,
            pathCustom: `member_inst_code/code_name?${finalParameter.slice(0, -1)}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            let listDataNameAPI: any = []
            if (response.data.length > 0) {
                response.data.forEach((data: any) => {
                    listDataNameAPI.push({
                        label: `${data.ccdExt1Val} - ${data.ccdName}`,
                        value: data.ccdCode,
                        desc: data.ccdDesc,
                        name: data.ccdName,
                    });
                });
            }
            dispatchStatePage({ type: 'nameList', value: listDataNameAPI })
        }).catch((err) => handleState('errorMessage', err))
    }, [statePage.data.instCcgCode])

    useEffect(() => {
        let descSelected: any = []
        let nameSelected: any = []
        if (statePage.nameList.length > 0) {
            statePage.nameList.forEach((data: any) => {
                if (statePage.data.instCcdCodeName === data.value) {
                    descSelected = data.desc
                }
                if (statePage.data.instCcdCodeName === data.value) {
                    nameSelected = data.name
                }
            });
        }
        dispatchStatePage({ type: 'descSelected', value: descSelected })
        dispatchStatePage({ type: 'nameSelected', value: nameSelected })
    }, [statePage.data.instCcdCodeName])

    //isi data
    statePage.data.instCcdCode = statePage.data.instCcdCodeName
    statePage.data.memberInstDesc = statePage.descSelected

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>Add Institution for Mapping</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>GROUP CODE <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} options={groupList} value={groupList.filter((option: any) => option.value === statePage.data.instCcgCode)} onChange={(e) => handleState('instCcgCode', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>CODE NAME <span className='text-danger'>*</span></p>
                                <Select isDisabled={!statePage.data.instCcgCode} placeholder={'(Search)'} options={statePage.nameList} value={statePage.nameList.filter((option: any) => option.value === statePage.data.instCcdCodeName)} onChange={(e) => handleState('instCcdCodeName', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>MEMBER INSTITUTION CODE</p>
                                <input disabled={!statePage.data.instCcgCode} className={statePage.data.instCcgCode ? inputStyle : inputStyleReadOnly} type='string' min={0} value={statePage.data.memberInstCode} onChange={(e) => handleState('memberInstCode', e.target.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Code Name</p>
                                <input disabled={true} value={statePage.nameSelected} onChange={(e) => handleState("instCcdCode", e.target.value)} className={inputStyleReadOnly} />
                            </div>
                            <div className="col-12">
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} disabled={true} value={statePage.descSelected} onChange={(e) => handleState("memberInstDesc", e.target.value)} className={inputDescriptionStyleReadOnly} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}

const initialState = {
    titleModal: `Adding institution for mapping`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelInstitutionCode({
        id: null,
        memberInstCodeId: null,
        memberId: null,
        instCcgCode: null,
        instCcdCode: null,
        instCcdCodeName: null,
        memberBcapCode: null,
        memberInstDesc: null,
        memberInstCode: null,

    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
    nameList: [],
    descSelected: '',
    nameSelected: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInstitutionCode) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        case 'nameList':
            return {
                ...state,
                nameList: action.value,
            }
        case 'descSelected':
            return {
                ...state,
                descSelected: action.value,
            }
        case 'nameSelected':
            return {
                ...state,
                nameSelected: action.value,
            }

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInstitutionCode({
                    id: null,
                    memberInstCodeId: null,
                    memberId: null,
                    instCcgCode: null,
                    instCcdCode: null,
                    instCcdCodeName: null,
                    memberBcapCode: null,
                    memberInstDesc: null,
                    memberInstCode: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInstitutionCode({
                    id: action.value.memberInstCodeId,
                    memberInstCodeId: action.value.memberInstCodeId,
                    memberId: action.value.memberId,
                    instCcgCode: action.value.instCcgCode,
                    instCcdCode: action.value.instCcdCode,
                    instCcdCodeName: action.value.instCcdCodeName,
                    memberBcapCode: action.value.memberBcapCode,
                    memberInstDesc: action.value.memberInstDesc,
                    memberInstCode: action.value.memberInstCode,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}
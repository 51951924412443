interface MemberRoleDtos {
    memberRolesId: number | null;
    memberId: number | null;
    memberRoleType: string | null;
}

interface InterfaceGeneralInformation {
    id: null | number | string;
    memberId: number | null;
    memberParamsId: number | null;
    memberCode: string | null;
    memberName: string | null;
    memberStatus: string | null;
    
    countryCode: string | null;
    memberInstitutionType: string | null;
    memberRoleDtos: MemberRoleDtos[] | any[] | null;
    memberLocalTimezone: string | null;
}

export class ModalGeneralInformation {
    id: null | number | string;
    memberId: number | null;
    memberParamsId: number | null;
    memberCode: string | null;
    memberName: string | null;
    memberStatus: string | null;
    countryCode: string | null;
    memberInstitutionType: string | null;
    memberRoleDtos: MemberRoleDtos[] | null;
    memberLocalTimezone: string | null;

    constructor(value: InterfaceGeneralInformation) {
        this.id = value.id;
        this.memberId = value?.memberId ?? null;
        this.memberParamsId = value?.memberParamsId ?? null;
        this.memberCode = value?.memberCode ?? null;
        this.memberName = value?.memberName ?? null;
        this.memberStatus = value?.memberStatus ?? null;
        this.countryCode = value?.countryCode ?? null;
        this.memberInstitutionType = value?.memberInstitutionType ?? null;
        this.memberRoleDtos = value?.memberRoleDtos ?? null;
        this.memberLocalTimezone = value?.memberLocalTimezone ?? null;
    }
}

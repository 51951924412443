import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { useNavigation } from '@react-navigation/native';

import { ButtonDelete, ButtonEdit, InsideComponentPage, TableWithLimitOffset } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import Select from 'react-select';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { InterfaceOptionsSchedulerManagement } from './InterfaceOptionsSchedulerManagement';
import { ModalSchedulerManagement } from './ModalSchedulerManagement';
import { TableSchedulerExecution } from '../JobExecutionHistory/TableSchedulerExecution';
import { Page41Title } from '../../State/Menu/TitleMenu';
import { ShowModalInterface } from '../../Interface';
import { inputStyleH25, labelStyle } from '../../Styles';
import { undo_icon } from '../../Assets/Images/svg';
import { ModelSchedulerExecution } from './ModelSchedulerManagement';
import { Page2JobSchedulerRoute } from '../../Routes';
import { ModalBatchScheduler } from '../Service/Modals';
import { TableSchedulerManagement } from './TableSchedulerManagement';
 



export const SchedulerManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const navigation = useNavigate();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])
    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        let selectedBatchExecutionType = stateCustomComponent.selectedBatchExecutionType !== '' ? `batchExecutionType=${stateCustomComponent.selectedBatchExecutionType}&` : '';
        let selectedBatchBusinessGroup = stateCustomComponent.selectedBatchBusinessGroup !== '' ? `batchBusinessGroup=${stateCustomComponent.selectedBatchBusinessGroup}&` : '';
        // let selectedBatchId = stateCustomComponent.selectedBatchId !== '' ? `batchId=${stateCustomComponent.selectedBatchId}&` : '';
        let finalParameter = `${search}${limit}${offset}${selectedBatchExecutionType}${selectedBatchBusinessGroup}`;
        let finalParameter2 = `${search}`;
        handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                setPagination({ pageSize: state.view, pageIndex: 0 })
                dispatch({ type: 'searchData', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })

        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => {
                dispatch({ type: 'data', value: response.data });
                dispatch({ type: 'totalPages', value: response.detail.totalPage });
                dispatch({ type: 'totalData', value: response.detail.total });
            })

        }
    }



    useEffect(() => {
        initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsSchedulerManagement, dispatchGlobal)
    }, [state.search, stateCustomComponent.selectedStartDate, stateCustomComponent.selectedEndDate, stateCustomComponent.selectedMember])

    useEffect(() => {
        initFunctionCustom(false)
    }, [state.position, state.view])
    useEffect(() => {
        initFunctionCustom(false);
    }, [pagination.pageIndex]);

    useEffect(() => {
        setPagination({ pageIndex: pagination.pageIndex, pageSize: state.view });
    }, [state.view]);

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search, stateCustomComponent.selectedBatchExecutionType, stateCustomComponent.selectedBatchBusinessGroup, stateCustomComponent.selectedBatchId])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])


    const exportToCSVComponent = () => { }
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: any) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsSchedulerManagement, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

    const customButtonAction = (item: any, width: string | any) => {
        return (
            <></>
        );
    };

    const filterComponentPage = () => {
        return (
            <>
                <div className="row container-all-select-custom pb-4 pt-1 px-0 mx-0">
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH BUSINESS GROUP</p>
                        <div className='d-flex justify-content-between'>
                            <Select className='flex-fill' options={stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName} value={stateOptions.optionsBusinessGroupNameDetailCodeAndCcdName.filter((option: any) => option.value === stateCustomComponent.selectedBatchBusinessGroup)} onChange={(e) => handleState("selectedBatchBusinessGroup", e.value, dispatchCustomComponent)} styles={inputStyleH25} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedBatchBusinessGroup", '', dispatchCustomComponent) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH EXECUTION TYPE</p>
                        <div className='d-flex justify-content-between'>
                            <Select className='flex-fill' options={stateOptions.optionsBatchExecutionTypeDetailCode} value={stateOptions.optionsBatchExecutionTypeDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedBatchExecutionType)} onChange={(e) => handleState("selectedBatchExecutionType", e.value, dispatchCustomComponent)} styles={inputStyleH25} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedBatchExecutionType", '', dispatchCustomComponent) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-4 col-sm-12 col-xs-12">
                        <p className={labelStyle}>BATCH ID</p>
                        <div className='d-flex justify-content-between'>
                            <Select className='flex-fill' options={stateOptions.optionsBatchFromAPIBatch} value={stateOptions.optionsBatchFromAPIBatch.filter((option: any) => option.value === stateCustomComponent.selectedBatchId)} onChange={(e) => handleState("selectedBatchId", e.value, dispatchCustomComponent)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedBatchId", '', dispatchCustomComponent) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div> */}
                </div>
            </>
        )
    }

    const tableComponentPage = () => {
        // return <></>
        return (
            <TableSchedulerManagement
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}

                //                
                dataModal={state.dataModal}
                currentPage={state.currentPage}
                handleShowModal={(item: any) => {
                    navigation(`${Page2JobSchedulerRoute}/details?batchExecutionType=${item.batchExecutionType === '' ? '' : item.batchExecutionType}&batchBusinessGroup=${item.batchBusinessGroup === '' ? '' : item.batchBusinessGroup}`)
                    // dispatch({ type: 'id', value: item.id })
                    // dispatch({ type: 'dataModal', value: item })
                    // dispatch({ type: 'visibleModalAddDelete', value: true })
                }}
            />
        )
    }

    const modalAddDeleteComponentPage = () => {
        return (
            <ModalBatchScheduler
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                dispatchParent={dispatch}
            />
        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
                {/* {
                    state.showModal.map((element1: any) => {
                        return (
                            <ModalDraggableQRStandardElement
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                } */}
            </>
        )
    }
    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">
                Job Schedule
            </h1>
            {filterComponentPage()}
            <TableSchedulerManagement
                state={state}
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                stateOptions={stateOptions}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                initFunctionCustom={initFunctionCustom}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                setPagination={setPagination}
                dataModal={state.dataModal}
                currentPage={state.currentPage}
                addData={addDataComponent}
                handleShowModal={(item: any) => {
                    const url = `${Page2JobSchedulerRoute}/details?batchExecutionType=${item.batchExecutionType || ''}&batchBusinessGroup=${item.batchBusinessGroup || ''}`;
                    window.open(url, '_blank');
                    // navigation(`${Page2JobSchedulerRoute}/details?batchExecutionType=${item.batchExecutionType === '' ? '' : item.batchExecutionType}&batchBusinessGroup=${item.batchBusinessGroup === '' ? '' : item.batchBusinessGroup}`)
                    // dispatch({ type: 'id', value: item.id })
                    // dispatch({ type: 'dataModal', value: item })
                    // dispatch({ type: 'visibleModalAddDelete', value: true })
                }}
            />
            <ModalBatchScheduler
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                dispatchParent={dispatch}
            />
            {/* <TableSchedulerManagement
             
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}

                //                
                dataModal={state.dataModal}
                currentPage={state.currentPage}
                handleShowModal={(item: any) => {
                    navigation(`${Page2JobSchedulerRoute}/details?batchExecutionType=${item.batchExecutionType === '' ? '' : item.batchExecutionType}&batchBusinessGroup=${item.batchBusinessGroup === '' ? '' : item.batchBusinessGroup}`)
                    // dispatch({ type: 'id', value: item.id })
                    // dispatch({ type: 'dataModal', value: item })
                    // dispatch({ type: 'visibleModalAddDelete', value: true })
                }}
            /> */}
            {/* <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={false}
                needAddButton={true}
                needSearchFunction={false}
                needDownloadButton={false}
            /> */}
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'batch/group-path',
    titlePage: Page41Title,
    privilegeAccess: { view: "551", add: "552", edit: "553", delete: "554", download: "555" },
    label: [
        { field: "No.", width: "wd-50", val: "no" },
        { field: "Batch Business Group", width: "wd-150", val: "batchBusinessGroup" },
        { field: "Batch Execution Type", width: "wd-150", val: "batchExecutionType" },
        { field: "Batch Group Path", width: "wd-200", val: "batchGroupPath" },

    ],
    toggle: ["no", "batchBusinessGroup", "batchExecutionType", "batchGroupPath"],
    complete_toggle: ["no", "batchBusinessGroup", "batchExecutionType", "batchGroupPath"],
    list_column_name: ["No.", "Batch Business Group", "Batch Execution Type", "Batch Group Path"],
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Batch ID, Batch Parameter Value',

    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 700,
    dataLength: 0, // zIndex
    finalAPI: '',
};


// batchId ModelSchedulerExecution
function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSchedulerExecution) => {
                        element1.id = element1.batchGroupPath!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSchedulerExecution) => {
                        element1.id = element1.batchGroupPath!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelSchedulerExecution) => {
                        showDataResult.push(
                            { idModal: element1.batchGroupPath!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.batchGroupPath!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };

        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}


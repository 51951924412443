import { useMemo } from 'react';
import { useSelector } from "react-redux";
import Select from "react-select";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    useMaterialReactTable,
} from 'material-react-table';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { SelectView } from '../../Components';
import { errNotificationIcon, icon_search_white, undo_icon } from "../../Assets/Images/svg";
import { transactionJourneyGlobalState } from "../../Store/TransactionJourney";
import { inputStyleH25, labelStyle } from "../../Styles";
import { CheckUndefined } from '../../Utils';
import ModalLogAnalyzer from './ModalLogAnalyzer';
import { ModelLogAnalyzer } from './ModelLogAnalyzer';

export const DesktopViewTransactionJourney = ({ ...props }) => {

    const stateGlobal = useSelector(transactionJourneyGlobalState);

    const {
        state,
        stateOptions,
        optionsResponseCode,
        optionsServer,
        pagination,
        errorMessage,
        setPagination,
        setErrorMessage,
        initFunctionCustom,
        handleGlobalState,
        handleResetFilter,
        handleStateComponent,
        handleRowClick,
        exportToCSVComponent,
        reloadDataComponent,
        dispatch
    } = props;

    const columns = useMemo<MRT_ColumnDef<ModelLogAnalyzer>[]>(
        () => [
            {
                accessorKey: 'logDateTime', // date time
                header: 'Date Time',
                maxSize: 100,
                Cell: ({ row }: any) => `${row.original.logDateTime}`
            },
            {
                accessorKey: 'productInd', // product indicator
                header: 'P. Indicator',
                maxSize: 150,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    if (row.original.productInd !== '') {
                        return `${row.original.productInd} - ${row.original.productFeatureName}`
                    } else {
                        return `-`
                    }
                }
            },
            {
                accessorKey: 'globalId', // global id
                header: 'Global ID',
                minSize: 200,
                maxSize: 200,
                Cell: ({ row }: any) => `${row.original.globalId}`
            },
            {
                accessorKey: 'authGwStatus', // api gateway status
                header: 'API G.W.',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.authGwStatus}` == '' ? data = '-' : data = `${row.original.authGwStatus}`
                    return data;
                }
            },
            {
                accessorKey: 'reasonCode', // reason code
                header: 'R. Code',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.reasonCode}` == '' ? data = '-' : data = `${row.original.reasonCode}`
                    return data;
                }
            },
            {
                accessorKey: 'reasonMsg', // reason message
                header: 'R. Message',
                minSize: 100,
                maxSize: 200,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.reasonMsg}` == '' ? data = '-' : data = `${row.original.reasonMsg}`
                    return (
                        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {data}
                        </div>
                    );
                }
            },
            {
                accessorKey: 'logChannel', // channel
                header: 'Channel',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.logChannel}` == '' ? data = '-' : data = `${row.original.logChannel}`
                    return data;
                }
            },
            {
                accessorKey: 'nodeSrc', // from
                header: 'From',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.nodeSrc}`
            },
            {
                accessorKey: 'nodeProc', // proc
                header: 'Proc',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.nodeProc}`
            },
            {
                accessorKey: 'nodeDst', // to
                header: 'To',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${row.original.nodeDst}`
            },
            {
                accessorKey: 'authAmount', // amount
                header: 'Amount',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    if ((row.original.currencyName !== "") && (row.original.authAmount !== "")) { // if currency name or auth amount is empty, return "-"
                        const amount = row.original.authAmount;
                        let formattedAmount = '';

                        if (amount.includes('.')) {
                            formattedAmount = parseFloat(amount).toFixed(2);
                        } else {
                            const length = amount.length;
                            const integerPart = amount.slice(0, length - 2);
                            const decimalPart = amount.slice(length - 2);
                            formattedAmount = `${parseInt(integerPart || '0')}.${decimalPart}`;
                        }

                        return `${row.original.currencyName} ${formattedAmount}`
                    } else {
                        return `-`
                    }
                }
            },
            {
                accessorKey: 'cpan', // wallet user pan
                header: 'Wallet User Pan',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.cpan}` == '' ? data = '-' : data = `${row.original.cpan}`
                    return data;
                }
            },
            {
                accessorKey: 'midMpan', // mid
                header: 'MID',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    `${row.original.midMpan}` == '' ? data = '-' : data = `${row.original.midMpan}`
                    return data;
                }
            },
        ],
        [],
    );

    let previousGlobalId: string = '';  // for switching row color operation
    let currentColor: string = '';

    const table = useMaterialReactTable({
        // 1. Column Definitions
        columns,

        // 2. Data & State
        data: stateGlobal.groupedData,
        state: { pagination },
        initialState: {
            density: 'compact',
        },

        // 3. Editing & Row Actions
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        enableGlobalFilterRankedResults: false,

        // 4. Pagination & Row Numbers
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },

        // 5. Positioning & Layout
        enableFullScreenToggle: false,
        enableColumnFilterModes: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row, table }) => {

            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);

            if (rowIndex > 0) {
                if (sortedRows[rowIndex].original.globalId !== previousGlobalId) {
                    currentColor = currentColor === '#f5e9e9' ? '#ffffff' : '#f5e9e9';
                }
                previousGlobalId = sortedRows[rowIndex].original.globalId;
            } else {
                currentColor = '#f5e9e9';
                previousGlobalId = sortedRows[rowIndex].original.globalId;
            }

            return {
                onClick: () => handleRowClick(row.original), // click function here
                sx: {
                    cursor: 'pointer',
                    backgroundColor: currentColor,
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white', // Change text color to white on hover
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px', // Set font size for table cells
                fontFamily: 'system-ui, sans-serif', // Set font family for table cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiSearchTextFieldProps: {
            placeholder: 'Search all column',
            size: 'small',
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif'
            }
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            }
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        // 6. Event Handler
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="flex flex-wrap align-items-center">
                <div className="row justify-content-between py-1">
                    <div className="col-6 d-flex align-items-center">
                        <p className="Lato-400 s-1822 c-29">List</p>
                        <p className="br-5 bd-ea bg-f4 px-3 py-2 c-29 Lato-400 s-1418 mx-2">{state.totalData}</p>
                    </div>
                </div>
                <Button
                    disabled={state.totalData <= 0}
                    onClick={exportToCSVComponent}
                    startIcon={<FileDownloadIcon />}
                    className="h-30px hover-button-red"
                    sx={{
                        color: '#f15e5e'
                    }}
                >
                    Download
                </Button>
            </div>
        ),
    });

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">Transaction Journey</h1>
            <div className='bg-ff bd-d4 br-10'>
                <div className="row gy-1 gx-2 d-flex align-items-start px-2 mb-3">
                    {/* first row */}
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Global ID</label>
                        <div className="">
                            <input
                                type="text"
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                value={stateGlobal.selectedGlobalId}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const maxLength = 24;
                                    // const lettersOnly = /^[a-zA-Z]*$/; // Regex for letters only
                                    // const numbersOnly = /^[0-9]*$/; // Regex for numbers only

                                    // if (!lettersOnly.test(value)) {
                                    //     setErrorMessage((prevState: any) => ({
                                    //         ...prevState,
                                    //         "globalId": "Only letters are allowed!"
                                    //     }))
                                    //     // setWarningMessage("Only letters are allowed!");
                                    // } 

                                    if (value.length > maxLength) {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "globalId": "Max 24 characters allowed"
                                        }))
                                    } else {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "globalId": ""
                                        }))
                                    }

                                    handleGlobalState("selectedGlobalId", value);
                                }}
                            />
                            {state.errorMessageFilter !== '' && (
                                <div className="">
                                    <p className='text-danger mt-1 s-1418'>
                                        {errorMessage.globalId}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>RRN</label>
                        <div className="">
                            <input
                                type="number"
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                value={stateGlobal.selectedAuthRrn}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const maxLength = 12;

                                    if (value.length > maxLength) {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "rrn": "Max 12 digits allowed"
                                        }))
                                    } else {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "rrn": ""
                                        }))
                                    }

                                    handleGlobalState("selectedAuthRrn", e.target.value)
                                }}
                            />
                            {state.errorMessageFilter !== '' && (
                                <div className="">
                                    <p className='text-danger mt-1 s-1418'>
                                        {errorMessage.rrn}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>STAN</label>
                        <div className="">
                            <input
                                type="number"
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                value={stateGlobal.selectedAuthStan}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const maxLength = 6;

                                    if (value.length > maxLength) {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "stan": "Max 6 digits allowed"
                                        }))
                                    } else {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "stan": ""
                                        }))
                                    }

                                    handleGlobalState("selectedAuthStan", e.target.value)
                                }}
                            />
                            {state.errorMessageFilter !== '' && (
                                <div className="">
                                    <p className='text-danger mt-1 s-1418'>
                                        {errorMessage.stan}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Invoice Number</label>
                        <div className="">
                            <input
                                type="text"
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                value={stateGlobal.selectedInvNo}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const maxLength = 64;

                                    if (value.length > maxLength) {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "invNo": "Max 64 characters allowed"
                                        }))
                                    } else {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "invNo": ""
                                        }))
                                    }

                                    handleGlobalState("selectedInvNo", e.target.value)
                                }}
                            />
                            {state.errorMessageFilter !== '' && (
                                <div className="">
                                    <p className='text-danger mt-1 s-1418'>
                                        {errorMessage.invNo}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Wallet User PAN</label>
                        <div className="">
                            <input
                                type="number"
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                value={stateGlobal.selectedCpan}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const maxLength = 64;

                                    if (value.length > maxLength) {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "cpan": "Max 64 digits allowed"
                                        }))
                                    } else {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "cpan": ""
                                        }))
                                    }

                                    handleGlobalState("selectedCpan", e.target.value)
                                }}
                            />
                            {state.errorMessageFilter !== '' && (
                                <div className="">
                                    <p className='text-danger mt-1 s-1418'>
                                        {errorMessage.cpan}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>MID</label>
                        <div className="">
                            <input
                                type="text"
                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                value={stateGlobal.selectedMidMpan}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const maxLength = 64;

                                    if (value.length > maxLength) {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "midMpan": "Max 64 characters allowed"
                                        }))
                                    } else {
                                        setErrorMessage((prevState: any) => ({
                                            ...prevState,
                                            "midMpan": ""
                                        }))
                                    }

                                    handleGlobalState("selectedMidMpan", e.target.value)
                                }}
                            />
                            {state.errorMessageFilter !== '' && (
                                <div className="">
                                    <p className='text-danger mt-1 s-1418'>
                                        {errorMessage.midMpan}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Response Code</label>
                        <div className="d-flex justify-content-between">
                            <Select
                                placeholder={"(Search)"}
                                className="flex-fill"
                                styles={inputStyleH25}
                                options={optionsResponseCode}
                                value={optionsResponseCode.filter((option: any) => option.value === stateGlobal.selectedAuthRspnCode)}
                                onChange={(e) => {
                                    handleGlobalState("selectedAuthRspnCode", e?.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Server</label>
                        <div className="">
                            <Select
                                placeholder={"(Search)"}
                                className="flex-fill"
                                styles={inputStyleH25}
                                options={optionsServer}
                                value={optionsServer.filter((option: any) => option.value === stateGlobal.selectedServerId)}
                                onChange={(e) => {
                                    handleGlobalState("selectedServerId", e?.value)
                                }}
                            />
                        </div>
                    </div>

                    {/* second row */}
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Source Institution</label>
                        <div className="">
                            <Select
                                placeholder={"(Search)"}
                                className="flex-fill"
                                styles={inputStyleH25}
                                options={stateOptions.optionsMemberCode}
                                value={stateOptions.optionsMemberCode.filter((option: any) => option.value === stateGlobal.selectedMemberSrcCode || option.title === stateGlobal.selectedMemberSrcName)}
                                onChange={(e) => {
                                    handleGlobalState("selectedMemberSrcName", e.title)
                                    handleGlobalState("selectedMemberSrcCode", e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Destination Institution</label>
                        <Select
                            placeholder={"(Search)"}
                            className="flex-fill"
                            styles={inputStyleH25}
                            options={stateOptions.optionsMemberCode}
                            value={stateOptions.optionsMemberCode.filter((option: any) => option.value === stateGlobal.selectedMemberDstCode || option.title === stateGlobal.selectedMemberDstName)}
                            onChange={(e) => {
                                handleGlobalState("selectedMemberDstName", e.title)
                                handleGlobalState("selectedMemberDstCode", e.value)
                            }}
                        />
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Product Indicator</label>
                        <div className="">
                            <Select
                                placeholder={"(Search)"}
                                className="flex-fill"
                                styles={inputStyleH25}
                                options={stateOptions.optionsProductFeatureWithAllOnlyProdFeatureInd}
                                value={stateOptions.optionsProductFeatureWithAllOnlyProdFeatureInd.filter((option: any) => option.value === stateGlobal.selectedProductInd)}
                                onChange={(e) => {
                                    handleGlobalState("selectedProductInd", e.value)
                                }}
                            />
                        </div>
                    </div>
                    <div id="log-checkbox" className="col-md-2 col-sm-6">
                        <div>
                            <label className={`${labelStyle}`}></label>
                        </div>
                        <div className='row d-flex justify-content-between'>
                            <div className='col-md-6 col-sm-12 d-flex align-items-center gap-1'>
                                <input
                                    type="checkbox"
                                    id="fx-rate"
                                    checked={stateGlobal.selectedFxRate != ''}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleGlobalState("selectedFxRate", '01')
                                        } else {
                                            handleGlobalState("selectedFxRate", '')
                                        }
                                    }}
                                />
                                <label className={labelStyle} htmlFor='fx-rate'>FX Rate</label>
                            </div>
                            <div className='col-md-6 col-sm-12 d-flex align-items-center gap-1'>
                                <input
                                    type="checkbox"
                                    id="sim"
                                    checked={stateGlobal.selectedSim != ''}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleGlobalState("selectedSim", '01')
                                        } else {
                                            handleGlobalState("selectedSim", '')
                                        }
                                    }
                                    }
                                />
                                <label className={labelStyle} htmlFor='sim'>SIM</label>
                            </div>
                        </div>
                    </div>

                    {/* third row */}
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>Start Date and Time</label>
                        <div className='d-flex flex-column'>
                            <div className="d-flex">
                                <DatePicker
                                    selected={new Date(`${stateGlobal.selectedStartDate} ${stateGlobal.selectedStartTime}` || '')}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    popperClassName="react-datepicker-popper-transform-right"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeFormat="HH:mm"
                                    showTimeSelect
                                    showYearDropdown
                                    timeIntervals={5}
                                    maxDate={new Date()}
                                    onChange={(date: any) => {
                                        handleGlobalState('selectedStartDate', format(date, 'yyyy-MM-dd'))
                                        handleGlobalState('selectedStartTime', format(date, 'HH:mm:ss'))
                                    }}
                                />
                            </div>

                            <div className='align-self-end position-relative'>
                                <div
                                    className='bg-fff position-absolute rounded-end'
                                    style={{
                                        position: "absolute",
                                        height: "23px",
                                        width: "30px",
                                        right: "1px",
                                        top: "50%",
                                        transform: "translateY(-105%)",
                                        pointerEvents: "none",
                                        fontSize: "14px",
                                    }}>
                                </div>
                                <span
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-120%)",
                                        pointerEvents: "none",
                                        fontSize: "14px",
                                    }}
                                >
                                    <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="calendar"
                                        width="1em"
                                        height="1em"
                                        fill="#0000003f"
                                        aria-hidden="true">
                                        <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                                        </path>
                                    </svg>
                                </span>
                            </div>

                            {state.errorMessageFilter !== '' && (
                                <div className="w-300px">
                                    <p className='text-danger mt-1 s-1418'>
                                        {errorMessage.date}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6">
                        <label className={labelStyle}>End Date and Time</label>
                        <div className='d-flex flex-column'>
                            <div className="d-flex">
                                <DatePicker
                                    selected={new Date(`${stateGlobal.selectedEndDate} ${stateGlobal.selectedEndTime}`)}
                                    className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                    wrapperClassName="w-100 h-100"
                                    popperClassName="react-datepicker-w-custom-button"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeFormat="HH:mm"
                                    showTimeSelect
                                    showYearDropdown
                                    timeIntervals={5}
                                    minDate={stateGlobal.selectedStartDate}
                                    maxDate={new Date()}
                                    onChange={(date: any) => {
                                        handleGlobalState('selectedEndDate', format(date, 'yyyy-MM-dd'))
                                        handleGlobalState('selectedEndTime', format(date, 'HH:mm:ss'))
                                    }}
                                >
                                    <div className='react-datepicker-custom-child-div'>
                                        <button className='btn-hover border-0 px-2 py-1 bg-e8 c-ff text-uppercase br-5' style={{ fontSize: '12px', lineHeight: '12px' }}
                                            onClick={() => {
                                                handleGlobalState("selectedEndDate", format(new Date(), 'yyyy-MM-dd'));
                                                handleGlobalState("selectedEndTime", format(new Date(), 'HH:mm:ss'));
                                            }}
                                        >
                                            Now
                                        </button>
                                    </div>
                                </DatePicker>
                            </div>

                            <div className='align-self-end position-relative bg-e8'>
                                <div
                                    className='bg-fff w-30px position-absolute rounded-end'
                                    style={{
                                        position: "absolute",
                                        height: "23px",
                                        right: "1px",
                                        top: "50%",
                                        transform: "translateY(-105%)",
                                        pointerEvents: "none",
                                        fontSize: "14px",
                                    }}>
                                </div>
                                <span
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-120%)",
                                        pointerEvents: "none",
                                        fontSize: "14px",
                                    }}
                                >
                                    <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="calendar"
                                        width="1em"
                                        height="1em"
                                        fill="#0000003f"
                                        aria-hidden="true">
                                        <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                {state.errorMessageFilter !== "" ? (
                    <div className="row">
                        <div className="col position-relative">
                            <div className="d-flex p-2 mb-20 gap-2 bd-e8 br-10 wd-fit align-items-center position-absolute" style={{top: '-5px'}}>
                                {errNotificationIcon}
                                <p className="ml-10 s-1420 Lato-600 c-e87">{state.errorMessageFilter}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <div className="row mb-2">
                    <div className="d-flex justify-content-end align-items-center gap-3">
                        {/* button reset */}
                        <button className='btn d-flex align-items-center bg-e8 h-30px border-0'
                            onClick={handleResetFilter}
                        >
                            {/* {reset_icon} */}
                            <svg style={{ width: "18px", height: "18px" }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                    fill="#FFFFFF" // Set the fill color to white
                                />
                            </svg>
                            <p className="s-1418 c-ff Lato-600 mx-2">Reset</p>
                        </button>

                        {/* button search */}
                        <button
                            className="btn d-flex align-items-center bg-e8 h-30px border-0"
                            disabled={state.errorMessageFilter !== ''}
                            onClick={() => initFunctionCustom(true, true)}
                        >
                            {/* {search_icon} */}
                            <svg style={{ width: "18px", height: "18px" }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.833008 8.75016C0.833008 4.37793 4.37744 0.833496 8.74967 0.833496C13.1219 0.833496 16.6663 4.37793 16.6663 8.75016C16.6663 10.6361 16.0069 12.368 14.9059 13.7279L17.9669 16.7889C18.2923 17.1143 18.2923 17.6419 17.9669 17.9674C17.6415 18.2928 17.1138 18.2928 16.7884 17.9674L13.7274 14.9064C12.3675 16.0074 10.6356 16.6668 8.74967 16.6668C4.37744 16.6668 0.833008 13.1224 0.833008 8.75016ZM8.74967 2.50016C5.29791 2.50016 2.49967 5.2984 2.49967 8.75016C2.49967 12.2019 5.29791 15.0002 8.74967 15.0002C12.2014 15.0002 14.9997 12.2019 14.9997 8.75016C14.9997 5.2984 12.2014 2.50016 8.74967 2.50016ZM5.8034 5.38718C6.55653 4.6341 7.59933 4.16683 8.74967 4.16683C9.90001 4.16683 10.9428 4.63409 11.6959 5.3872C12.0214 5.71264 12.0214 6.24027 11.6959 6.56571C11.3705 6.89115 10.8429 6.89115 10.5174 6.56571C10.0641 6.1124 9.44025 5.8335 8.74967 5.8335C8.0591 5.8335 7.43523 6.1124 6.98187 6.56573C6.65642 6.89116 6.12878 6.89114 5.80336 6.56569C5.47793 6.24024 5.47795 5.7126 5.8034 5.38718Z"
                                    fill="#FFFFFF"
                                />
                            </svg>
                            <p className="s-1418 c-ff Lato-600 mx-2">Search</p>
                        </button>
                    </div>
                </div>
            </div>

            <div className='position-relative mt-2 mb-5'>
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                </div>
            </div>

            {state.visibleModalAddDelete && (
                <ModalLogAnalyzer
                    visibleModalAddDelete={state.visibleModalAddDelete}
                    setModal={() =>
                        dispatch({
                            type: 'visibleModalAddDelete',
                            value: !state.visibleModalAddDelete,
                        })
                    }
                    deleteItem={state.deleteItem}
                    id={state.id}
                    dataModal={state.dataModal}
                    urlApi={state.urlApi}
                    reloadData={() => reloadDataComponent(false)}
                    stateOptions={stateOptions}
                />
            )}
        </>
    )
}
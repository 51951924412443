export const NumberWithCommas2 = (value: any) => {
    if (value === undefined || value === '' || value === null || value === '0') {
        return '0';
    }

    let number = parseFloat(value);

    if (isNaN(number)) {
        return '0'; 
    }

    let formattedNumberString = number.toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    
    return formattedNumberString;
};
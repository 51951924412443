import { SelectOptionsInterface } from "../../../Interface";

export const InterfaceOptionsQRAuthInquiry: SelectOptionsInterface = {
    ccdOptions: false,
    spfOptions: false,
    memberOptions: true,
    ccgOptions: false,
    logLevel001Options: false,
    logLevel002Options: false,
    routingOptions: false,
    routeSequenceOptions: false,
    privilegeOptions: false,
    countryOptions: false,
    productOptions: true,
    productFeatureOptions: true,
    reasonCodeOptions: false,
    // optionsCommonStatusDetailCode:true,
    subProductOptions: false,
    exchangeRateOptions: false,
    elementOptions: false,
    qrStandardOptions: true,
    interfaceFieldOptions: false,
    headerFieldOptions: false,
    feeOptions: false,
    interfaceFileOptions: false,
    recordOptions: false,
    interfaceFileItemOptions: false,
    commonCodeOptions:true,
    resultOptions:false
}

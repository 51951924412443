export const COLORS_NODE_JOB = [
    {
        code: '000',
        colorUsed: 'rgb(114, 114, 114)'
    },
    {
        code: '001',
        colorUsed: 'rgb(0, 230, 230)'
    },
    {
        code: '002',
        colorUsed: 'rgb(0, 115, 0)'
    },
    {
        code: '003',
        colorUsed: 'rgb(0, 230, 0)'
    },
    {
        code: '004',
        colorUsed: 'rgb(230, 57, 57)'
    },
    {
        code: '005',
        colorUsed: 'rgb(152, 9, 9)'
    }
]

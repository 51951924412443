import React, { useEffect, useReducer, useState } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../../../Components';
import { API } from '../../../../Services';
import { logoutAction } from '../../../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../../../Styles';
import { CheckAllFields } from '../../../../Utils';
import { ModelInterfaceFile } from '../../Utils/ModelInterfaceFile';
import { loadingAction } from '../../../../Store/Loading';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Page35Title } from '../../../../State/Menu/TitleMenu';
import { urlSelection } from '../../../../Services/API';

export const ModalDraggableInterfaceFile = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props

    const {
        optionsInterfaceFileFormatDetailCode,
        optionsFileStringFormatDetailCode,
        optionsFileStringSeparatorDetailCode,
        optionsCommonStatusDetailCode,
    } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["interfaceFileId", "interfaceFileName", "interfaceFileFormat", "interfaceFileDesc", "filename_format"]
        let limit = [8, 32, 3, 64, 64]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            if (nameState === 'interfaceRecSize') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 1000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else if (nameState === 'interfaceRecItemNameLength') {
                if (valueState === '') {
                    dispatchStatePage({ type: nameState, value: valueState })
                } else if (parseInt(valueState) > 0 && parseInt(valueState) < 10000) {
                    dispatchStatePage({ type: nameState, value: valueState })
                }
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }
    const updateDataAPI = () => {
        const {
            interfaceFileId,
            interfaceFileName,
            interfaceFileFormat,
            interfaceStrFormat,
            interfaceRecSize,
            interfaceRecItemNameYn,
            interfaceRecItemNameLength,
            interfaceFileDesc,
            interfaceFileStatus,
            filename_format,
            interfaceFileCreateBy,
            interfaceFileCreateDate,
            interfaceFileUpdateBy,
            interfaceFileUpdateDate,
            interfaceFileDeleteBy,
            interfaceFileDeleteDate,

        } = statePage.data
        const data = {
            interfaceFileId: id,
            interfaceFileName: interfaceFileName,
            interfaceFileFormat: interfaceFileFormat,
            interfaceStrFormat: interfaceStrFormat,
            interfaceRecSize: interfaceRecSize,
            interfaceRecItemNameYn: interfaceRecItemNameYn,
            interfaceRecItemNameLength: interfaceRecItemNameLength,
            interfaceFileDesc: interfaceFileDesc,
            interfaceFileStatus: interfaceFileStatus,
            filename_format: filename_format
        }
        let a = '';
        if (interfaceRecItemNameYn === false) {
            a = CheckAllFields(
                [
                    interfaceFileId,
                    interfaceFileName,
                    interfaceFileFormat,
                    interfaceStrFormat,
                    interfaceRecSize,
                    interfaceRecItemNameYn,
                    interfaceFileDesc,
                    interfaceFileStatus,
                    filename_format
                ],
                [
                    'INTERFACE FILE ID',
                    'FILE NAME',
                    'FILE FORMAT',
                    'FILE STRING FORMAT',
                    'RECORD SIZE',
                    'RECORD ITEM NAME',
                    'DESCRIPTION',
                    'FILE STATUS',
                    'FILE NAME FORMAT'
                ]
            )
        } else {
            a = CheckAllFields(
                [
                    interfaceFileId,
                    interfaceFileName,
                    interfaceFileFormat,
                    interfaceStrFormat,
                    interfaceRecSize,
                    interfaceRecItemNameYn,
                    interfaceRecItemNameLength,
                    interfaceFileDesc,
                    interfaceFileStatus,
                    filename_format
                ],
                [
                    'INTERFACE FILE ID',
                    'FILE NAME',
                    'FILE FORMAT',
                    'FILE STRING FORMAT',
                    'RECORD SIZE',
                    'RECORD ITEM NAME',
                    'RECORD NAME LENGTH',
                    'DESCRIPTION',
                    'FILE STATUS',
                    'FILE NAME FORMAT'
                ]

            )
        }
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
            // console.log("test kirim payload", data)
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>Interface File ID</p>
                        <input value={statePage.data.interfaceFileId} readOnly className={inputStyleReadOnly} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>file NAME</p>
                        <input value={statePage.data.interfaceFileName} onChange={(e) => handleState('interfaceFileName', e.target.value)} className={inputStyle} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>file format</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsInterfaceFileFormatDetailCode} value={optionsInterfaceFileFormatDetailCode.filter((option: any) => option.value === statePage.data.interfaceFileFormat)} onChange={(e) => handleState('interfaceFileFormat', e.value)} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>file string format</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsFileStringFormatDetailCode} value={optionsFileStringFormatDetailCode.filter((option: any) => option.value === statePage.data.interfaceStrFormat)} onChange={(e) => handleState('interfaceStrFormat', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>record size</p>
                        <input type='number' min={1} value={statePage.data.interfaceRecSize} onChange={(e) => handleState('interfaceRecSize', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>record item name</p>
                        <BootstrapSwitchButton checked={statePage.data.interfaceRecItemNameYn} size="xs" onChange={(e) => handleState('interfaceRecItemNameYn', e)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>record name length</p>
                        <input type='number' min={1} value={statePage.data.interfaceRecItemNameLength} onChange={(e) => handleState('interfaceRecItemNameLength', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>file status</p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.interfaceFileStatus)} onChange={(e) => handleState('interfaceFileStatus', e.value)} />
                    </div>
                    <div className='col-6'></div>
                    <div className='col-6'>
                        <p className={labelStyle}>File Name Format</p>
                        <input type='text' value={statePage.data.filename_format} onChange={(e) => handleState('filename_format', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.data.interfaceFileDesc} onChange={(e) => handleState('interfaceFileDesc', e.target.value)} className={inputDescriptionStyle} />
                    </div>

                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page35Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    data: new ModelInterfaceFile({
        id: null,
        interfaceFileId: null,
        interfaceFileName: null,
        interfaceFileFormat: null,
        interfaceStrFormat: null,
        interfaceRecSize: null,
        interfaceRecItemNameYn: false,
        interfaceRecItemNameLength: 1,
        interfaceFileDesc: null,
        interfaceFileStatus: null,
        interfaceFileCreateBy: null,
        interfaceFileCreateDate: null,
        interfaceFileUpdateBy: null,
        interfaceFileUpdateDate: null,
        interfaceFileDeleteBy: null,
        interfaceFileDeleteDate: null,
        filename_format: null
    }),
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelInterfaceFile) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelInterfaceFile({
                    id: null,
                    interfaceFileId: null,
                    interfaceFileName: null,
                    interfaceFileFormat: null,
                    interfaceStrFormat: null,
                    interfaceRecSize: null,
                    interfaceRecItemNameYn: false,
                    interfaceRecItemNameLength: 1,
                    interfaceFileDesc: null,
                    interfaceFileStatus: null,
                    interfaceFileCreateBy: null,
                    interfaceFileCreateDate: null,
                    interfaceFileUpdateBy: null,
                    interfaceFileUpdateDate: null,
                    interfaceFileDeleteBy: null,
                    interfaceFileDeleteDate: null,
                    filename_format: null
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelInterfaceFile({
                    id: action.value.interfaceFileId,
                    interfaceFileId: action.value.interfaceFileId,
                    interfaceFileName: action.value.interfaceFileName,
                    interfaceFileFormat: action.value.interfaceFileFormat,
                    interfaceStrFormat: action.value.interfaceStrFormat,
                    interfaceRecSize: action.value.interfaceRecSize,
                    interfaceRecItemNameYn: action.value.interfaceRecItemNameYn,
                    interfaceRecItemNameLength: action.value.interfaceRecItemNameLength,
                    interfaceFileDesc: action.value.interfaceFileDesc,
                    interfaceFileStatus: action.value.interfaceFileStatus,
                    interfaceFileCreateBy: action.value.interfaceFileCreateBy,
                    interfaceFileCreateDate: action.value.interfaceFileCreateDate,
                    interfaceFileUpdateBy: action.value.interfaceFileUpdateBy,
                    interfaceFileUpdateDate: action.value.interfaceFileUpdateDate,
                    interfaceFileDeleteBy: action.value.interfaceFileDeleteBy,
                    interfaceFileDeleteDate: action.value.interfaceFileDeleteDate,
                    filename_format: action.value.filename_format,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}





import ReactModal from "react-modal"
import { close } from "../../../Assets/Images/svg"
import { ModalStyle } from "../../../Utils"

export const ModalCustom = ({ ...props }) => {
    const { statePage, handleState } = props
    return (
        <ReactModal isOpen={statePage.showModal} style={ModalStyle}>
            <div className='d-flex flex-column'>
                <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                    <p className='w-700 Lato-700 c-29 s-1822'>Message</p>
                    <div className='pointer' onClick={() => handleState('showModal', false)}>
                        {close}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column my-2'>
                    {statePage.messageModal}
            </div>
        </ReactModal>
    )
}
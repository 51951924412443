import {
    CheckUndefined,
    ExportCSV,
    NumberWithCommas
} from "../../../Utils";
export const ExcelTransactionMonitoring = (state: any, stateOptions: any, stateRedux: any) => {
    let newList: any[] = []
    let listData: any[] = []
    stateRedux.dataCopyFilter.map((item: any, i: any) => {
        const m = {
            no: i + 1,
            productFeatureName:CheckUndefined(item.productFeatureName),
            issuerName: CheckUndefined(item.issuerName),
            issuerSwitcherName: CheckUndefined(item.issuerSwitcherName),
            acquirerSwitcherName: CheckUndefined(item.acquirerSwitcherName),
            acquirerName: CheckUndefined(item.acquirerName),
            productFeature: CheckUndefined(item.productFeature),
            transAmount: `${CheckUndefined(item.transCurrency)} ${NumberWithCommas(CheckUndefined(item.transAmount))}`,
            transCount: CheckUndefined(item.transCount),
            transStatus: CheckUndefined(item.transStatus),
            fullCreateDate: CheckUndefined(item.fullCreateDate),
        }
        state.complete_toggle.map((item: any) => {
            if (state.toggle.indexOf(item) === -1) delete m[item as keyof typeof m]
        })
        listData.push(m)
    })

    state.complete_toggle.map((item: any) => {
        if (state.toggle.includes(item)) {
            newList.push(state.list_column_name[state.complete_toggle.indexOf(item)])
        }
    })
    ExportCSV(newList, listData, 'Transaction Statistic')
};
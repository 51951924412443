import { icon_search, icon_search_white } from "../Assets/Images/svg";

export const ButtonSearch = ({ ...props }) => {
    const { initFunctionCustom, disabled = false } = props;
    return (
        <div className='d-flex w-100 flex-end my-2'>
            <button className={'btn d-flex align-items-center bg-e8 h-30px border-0'} onClick={ () => initFunctionCustom(true)} disabled={disabled}>
                {icon_search_white}
                <p className='s-1418 c-ff Lato-600 mx-2'>Search</p>
            </button>
        </div>
    )
}

export const ButtonSearch1 = ({ ...props }) => {
    const { initFunctionCustom, disabled = false } = props;
    return (
        <button className={'d-flex align-items-center br-20 pointer fill-ff bg-e8 h-30px'} onClick={() => initFunctionCustom(true)} disabled={disabled}>
            {icon_search}
            <p className='s-1418 c-ff Lato-600 mx-2'>Search</p>
        </button>
    )
}
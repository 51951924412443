import { createSlice } from "@reduxjs/toolkit";

export const logoSlice = createSlice({
    name: "logo",
    initialState: {
        condition: true // false where logo show without text (text hidden)
    },
    reducers: {
        conditionAction: (state, action) => {
            state.condition = !(state.condition);
        },
    }
});

export const { conditionAction } = logoSlice.actions;
export const stateUser = (state: any) => state.logo.value;
export default logoSlice.reducer;

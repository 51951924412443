import { labelStyle } from "../Styles";

export const InputReadOnly = ({ ...props }) => {
    const { titleInput, value } = props;
    return (
        <div className={'col-6 mb-2'}>
            <p className={labelStyle}>{titleInput}</p>
            <input value={value} disabled readOnly className='bd-d4 bg-ff br-5 h-25px w-100 s-1418 Lato-400 px-2 out-none bg-f4' />
        </div>
    )
}
import { checklistButtonIcon, closeButtonIcon } from "../Assets/Images/svg";
import { ErrorMessage } from "./ErrorMessage"
import { ErrorMessage2 } from "./ErrorMessage2";

export const FooterModalCreate2 = ({ ...props }) => {
    const { errorMessage, errorMessageList, setModal, handlePostAPI } = props;
    return (
        <>
            <ErrorMessage2 errorMessage={errorMessage} errorMessageList={errorMessageList} />
            <div className='d-flex justify-content-between'>
                <button className='d-flex align-items-center br-10 bd-d4 bg-ff pointer p-2' onClick={setModal}>
                    {closeButtonIcon}
                    <p className='s-1418 c-7f Lato-600 ms-2'>CANCEL</p>
                </button>
                <button className='d-flex align-items-center br-10 bd-d4 bg-e8 pointer p-2 ' onClick={handlePostAPI}>
                    {checklistButtonIcon}
                    <p className='s-1418 c-ff Lato-600 ms-2'>SAVE</p>
                </button>
            </div>
        </>
    )
}
import { useEffect, useReducer, useState } from 'react';
import Select from 'react-select';
import TimezoneSelect, {
    allTimezones,
    useTimezoneSelect
} from 'react-timezone-select';
import { checklistButtonIcon, errNotificationIcon } from '../../../../Assets/Images/svg';
import { API } from '../../../../Services';
import { inputStyleH25, inputStyleH25Multi, inputStyleWithoutW100, labelStyleW250PX } from '../../../../Styles';
import { ModalPayment } from '../Models/ModelPayment';
import { CheckInput09, CheckInputAZ09 } from '../../../../Utils';
import { urlSelection } from '../../../../Services/API';

const timezones = { ...allTimezones }
const labelStyle = 'original'

export const GetPayment = ({ ...props }) => {

    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })


    const { codeMember, stateOptions, getOptionsPIC } = props;
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["memberPaymentAccNo", "memberPaymentBankName"]
        let limit = [19, 32];
        let index = names.indexOf(nameState)
        if (index !== -1) {
            // if (nameState === "memberPaymentAccNo") {
            //     let errorMessage = {
            //         id: '1',
            //         errorMessage: 'PAYMENT ACCOUNT NUMBER must contain only uppercase letters (A-Z) and numbers (0-9)'
            //     }
            //     if (CheckInputAZ09.test(valueState.toString().toUpperCase())) {
            //         if (valueState.split('').length <= limit[index]) {
            //             dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
            //             dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } })
            //         }
            //     } else {
            //         if (valueState.split('').length <= limit[index]) {
            //             dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
            //             dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } })
            //         }
            //     }

            // }

            if (nameState === 'memberPaymentAccNo') {
                let errorMessage = {
                    id: '1',
                    errorMessage: 'PAYMENT ACCOUNT NUMBER must contain only  numbers (0-9)'
                }
                if (CheckInput09.test(valueState.toString().toUpperCase())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            } else if (nameState === "memberPaymentBankName") {
                let errorMessage = {
                    id: '2',
                    errorMessage: 'BANK NAME must contain only uppercase letters (A-Z) and numbers (0-9)'
                }
                if (CheckInputAZ09.test(valueState.toString().toUpperCase())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString().toUpperCase() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            } else {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateData = () => {
        const {
            memberParamsId,
            memberCode,
            memberPaymentSndYn,
            memberPaymentSndTime,
            memberPaymentSndTzone,
            memberPaymentRcvYn,
            memberPaymentRcvTime,
            memberPaymentRcvTZone,
            memberPaymentAccNo,
            memberPaymentBankName,
            memberPics,
            memberPaymentConfirm,
            memberPaymentEmailNotifYn,
        } = statePage.data
        const data = {
            memberCode: memberCode,
            memberPaymentSndYn: memberPaymentSndYn,
            memberPaymentSndTime: memberPaymentSndTime,
            memberPaymentSndTzone: memberPaymentSndTzone,
            memberPaymentRcvYn: memberPaymentRcvYn,
            memberPaymentRcvTime: memberPaymentRcvTime,
            memberPaymentRcvTZone: memberPaymentRcvTZone,
            memberPaymentAccNo: memberPaymentAccNo,
            memberPaymentBankName: memberPaymentBankName,
            userIds: memberPics,
            memberPaymentConfirm: memberPaymentConfirm,
            memberPaymentEmailNotifYn: memberPaymentEmailNotifYn
        }
        if (statePage.errorMessageList.length > 0) {}
        else {
            API.update({
                bodyCustom: data,
                pathCustom: `member_parameter/payment`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response: any) => {
                if (response.data !== undefined) {
                    dispatchStatePage({ type: 'setData', value: response.data })
                }
            })
        }

    }

    useEffect(() => {
        API.get({
            bodyCustom: null,
            pathCustom: `member_parameter/payment/${codeMember}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            dispatchStatePage({ type: 'setData', value: response.data })
        })
    }, [])

    useEffect(() => {
        let selectedPICMember: any[] = []
        if (getOptionsPIC.length > 0) {
            getOptionsPIC.forEach((option: any) => {
                if (statePage.data.memberPics !== null) {
                    if (statePage.data.memberPics.length > 0) {
                        if (statePage.data.memberPics.includes(option.value)) {
                            selectedPICMember.push(option)
                        }
                    }
                }
            })
            handleState('selectedPICMember', selectedPICMember)
        }
    }, [statePage.data.memberPics, getOptionsPIC.length])

    return (
        <div className='d-flex flex-column justify-content-between bd-ea br-10 px-2 pt-2 pb-5'>

            <div className='row p-0 m-0'>
                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                    <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>PAYMENT SEND</p>
                                <div className='d-flex justify-content-between align-items-center hg-40px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'
                                            onClick={() => handleState('memberPaymentSndYn', true)}
                                            checked={statePage.data.memberPaymentSndYn === true}
                                            name='PaymentSend' />
                                        <span className='mx-2 mx-2 s-1214'>YES</span>
                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'
                                            onClick={() => handleState('memberPaymentSndYn', false)}
                                            checked={statePage.data.memberPaymentSndYn === false}
                                            name='PaymentSend' />
                                        <span className='mx-2 mx-2 s-1214'>NO</span>
                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                <div className='flex-fill'></div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'row p-0'}>
                                <div className='col-12 p-0'>
                                    <div className={'d-flex  align-items-center my-2'}>
                                        <span className={labelStyleW250PX}>PAYMENT SEND SCHEDULE</span>
                                        <input value={statePage.data.memberPaymentSndTime} type='time' onChange={(e) => handleState('memberPaymentSndTime', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                    </div>
                                </div>
                                <div className='col-12 p-0'>
                                    <div className={'d-flex  align-items-center my-2'}>
                                        <span className={labelStyleW250PX}></span>
                                        <TimezoneSelect
                                            styles={inputStyleH25}
                                            options={options}
                                            value={statePage.data.memberPaymentSndTzone ?? selectedTimezone}
                                            className='flex-fill'
                                            onChange={(e) => handleState('memberPaymentSndTzone', e.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>PAYMENT RECEIVE</p>
                                <div className='d-flex justify-content-between align-items-center hg-40px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'
                                            onClick={() => handleState('memberPaymentRcvYn', true)}
                                            checked={statePage.data.memberPaymentRcvYn === true}
                                            name='memberPaymentRcvYn' />
                                        <span className='mx-2 mx-2 s-1214'>YES</span>

                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'
                                            onClick={() => handleState('memberPaymentRcvYn', false)}
                                            checked={statePage.data.memberPaymentRcvYn === false}
                                            name='memberPaymentRcvYn' />
                                        <span className='mx-2 mx-2 s-1214'>NO</span>

                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                <div className='flex-fill'></div>
                            </div>
                        </div>

                        <div className='col-12'>
                            <div className={'d-flex  align-items-center my-2'}>
                                <div className={'row p-0'}>
                                    <div className='col-12 p-0'>
                                        <div className={'d-flex  align-items-center justify-content-center my-2'}>
                                            <span className={labelStyleW250PX}>PAYMENT RECEIVE SCHEDULE</span>
                                            <input value={statePage.data.memberPaymentRcvTime} type='time' onChange={(e) => handleState('memberPaymentRcvTime', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                                        </div>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <div className={'d-flex  align-items-center my-2'}>
                                            <span className={labelStyleW250PX}></span>
                                            <TimezoneSelect
                                                styles={inputStyleH25}
                                                options={options}
                                                value={statePage.data.memberPaymentRcvTZone ?? selectedTimezone}
                                                className='flex-fill'
                                                onChange={(e) => handleState('memberPaymentRcvTZone', e.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-12'>
                            <div className={'d-flex  align-items-center justify-content-center my-2'}>
                                <span className={labelStyleW250PX}>PAYMENT ACCOUNT NUMBER</span>
                                <input value={statePage.data.memberPaymentAccNo} onChange={(e) => handleState('memberPaymentAccNo', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                            </div>
                        </div>
                        <div className='col-xl-12'>
                            <div className={'d-flex  align-items-center my-2'}>
                                <span className={labelStyleW250PX}>BANK NAME</span>
                                <input value={statePage.data.memberPaymentBankName} onChange={(e) => handleState('memberPaymentBankName', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                    <div className='row py-2 gy-1 gx-3 mx-0 mb-3'>
                        <div className='col-12'>
                            <div className={'d-flex  align-items-center my-2'}>
                                <span className={labelStyleW250PX}>PIC USERS</span>
                                <Select
                                    styles={inputStyleH25Multi}
                                    className='flex-fill'
                                    closeMenuOnSelect={false}
                                    value={statePage.selectedPICMember}
                                    isMulti
                                    options={getOptionsPIC} //memberPics
                                    onChange={(items) => {
                                        let dataSelected: any[] = [];
                                        items.forEach((item: any) => {
                                            dataSelected.push(item.value);

                                        })
                                        handleState('memberPics', dataSelected)
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex  align-items-center my-2'}>
                                <span className={labelStyleW250PX}>PAYMENT CONFIRMATION</span>
                                <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsPaymentConfirmationMethodDetailCode} value={stateOptions.optionsPaymentConfirmationMethodDetailCode.filter((option: any) => option.value === statePage.data.memberPaymentConfirm)} onChange={(e) => handleState('memberPaymentConfirm', e.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className={'d-flex justify-content-center align-items-center'}>
                                <p className={labelStyleW250PX}>PAYMENT EMAIL NOTIFICATION</p>
                                <div className='d-flex justify-content-between align-items-center hg-40px' >
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'
                                            onClick={() => handleState('memberPaymentEmailNotifYn', true)}
                                            checked={statePage.data.memberPaymentEmailNotifYn === true}
                                            name='memberPaymentEmailNotifYn' />
                                        <span className='mx-2 mx-2 s-1214'>YES</span>

                                    </span>
                                    <span className=''>
                                        <input
                                            type='radio'
                                            className='h-10px w-10px'
                                            onClick={() => handleState('memberPaymentEmailNotifYn', false)}
                                            checked={statePage.data.memberPaymentEmailNotifYn === false}
                                            name='memberPaymentEmailNotifYn' />
                                        <span className='mx-2 mx-2 s-1214'>NO</span>

                                    </span>
                                    <span></span>
                                </div>
                                <div className='w-40px'></div>
                                <div className='flex-fill'></div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className='d-flex flex-column'>
                {
                    statePage.errorMessageList.length > 0 ?
                        <div className='col-12 pl-0'>
                            <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                {errNotificationIcon}
                                <p className='px-2 s-1418 Lato-600 c-e87'>{statePage.errorMessageList.map((item: any) => item.errorMessage).toString()}</p>
                            </div>
                        </div>
                        :
                        statePage.errorMessage !== '' && (
                            <div className='col-12 pl-0'>
                                <div className='d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit my-2'>
                                    {errNotificationIcon}
                                    <p className='px-2 s-1418 Lato-600 c-e87'>{statePage.errorMessage}</p>
                                </div>
                            </div>

                        )
                }
                <div className='d-flex'>
                    <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-2' onClick={updateData}>
                        {checklistButtonIcon}
                        <p className='s-1214 c-ff Lato-600 mx-2'>SAVE</p>
                    </button>
                </div>
            </div>
        </div>
    )

}

const initialState = {
    data: new ModalPayment({
        id: null,
        memberParamsId: null,
        memberCode: null,
        memberPaymentSndYn: null,
        memberPaymentSndTime: null,
        memberPaymentSndTzone: null,
        memberPaymentRcvYn: null,
        memberPaymentRcvTime: null,
        memberPaymentRcvTZone: null,
        memberPaymentAccNo: null,
        memberPaymentBankName: null,
        memberPics: null,
        memberPaymentConfirm: null,
        memberPaymentEmailNotifYn: null,
    }),
    errorMessage: "",
    errorMessageList: [],

    selectedPICMember: [],
}
const reducer = (state: any, action: any) => {
    for (const key in state.data as ModalPayment) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'selectedPICMember':
            return {
                ...state,
                selectedPICMember: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            if (action.value.type === "removed") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                            errorMessageList: state.errorMessageList.filter((item: any) => item.id !== action.value.data.id)
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else if (action.value.type === "added") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                        }
                    } else {

                        let data: any[] = [...state.errorMessageList]
                        data.push(action.value.data)
                        return {
                            ...state,
                            errorMessageList: data
                        }
                    }
                } else {
                    let data: any[] = [...state.errorMessageList]
                    data.push(action.value.data)
                    return {
                        ...state,
                        errorMessageList: data
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModalPayment({
                    id: null,
                    memberParamsId: null,
                    memberCode: null,
                    memberPaymentSndYn: null,
                    memberPaymentSndTime: null,
                    memberPaymentSndTzone: null,
                    memberPaymentRcvYn: null,
                    memberPaymentRcvTime: null,
                    memberPaymentRcvTZone: null,
                    memberPaymentAccNo: null,
                    memberPaymentBankName: null,
                    memberPics: null,
                    memberPaymentConfirm: null,
                    memberPaymentEmailNotifYn: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            let memberPics: any[] = [];
            if (action.value.memberPics.length > 0) {
                action.value.memberPics.forEach((item: any) => {
                    memberPics.push(item.userId)
                })
            }
            return {
                ...state,
                data: new ModalPayment({
                    id: action.value.memberParamsId,
                    memberParamsId: action.value.memberParamsId,
                    memberCode: action.value.memberCode,
                    memberPaymentSndYn: action.value.memberPaymentSndYn,
                    memberPaymentSndTime: action.value.memberPaymentSndTime,
                    memberPaymentSndTzone: action.value.memberPaymentSndTzone,
                    memberPaymentRcvYn: action.value.memberPaymentRcvYn,
                    memberPaymentRcvTime: action.value.memberPaymentRcvTime,
                    memberPaymentRcvTZone: action.value.memberPaymentRcvTZone,
                    memberPaymentAccNo: action.value.memberPaymentAccNo,
                    memberPaymentBankName: action.value.memberPaymentBankName,
                    memberPics: memberPics,
                    memberPaymentConfirm: action.value.memberPaymentConfirm,
                    memberPaymentEmailNotifYn: action.value.memberPaymentEmailNotifYn,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}
import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page13Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


const ModalDraggableProductManagement = ({ ...props }) => {
      const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsCommonStatusDetailCode } = props.stateOptions
    const draggleRef = React.createRef<any>();


    useEffect(() => {
        
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }
    const updateDataAPI = () => {
        const { productId, productName, productDesc, productStatus } = statePage
        const data = {
            productId: id,
            productName: `${productName}`,
            productDesc: `${productDesc}`,
            productStatus: `${productStatus}`
        }
        const a = CheckAllFields([productName, productStatus], ["Product Name", "Status"])
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            API.update({
                bodyCustom: data,
                 
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                   
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 my-3'>
                    {
                        id !== "" &&
                        <div className='col-6'>
                            <p className={labelStyle}>PRODUCT ID</p>
                            <input value={id} disabled className='bd-d4 bg-f4 br-5 h-25px w-100 s-1418 Lato-400 px-2 out-none ' />
                        </div>
                    }
                    <div className={`col-6`}>
                        <p className={labelStyle}>PRODUCT NAME</p>
                        <input value={statePage.productName} onChange={(e) => handleState("productName", e.target.value)} className={inputStyle} />
                    </div>
                    <div className={`col-6`}>
                        <p className={labelStyle}>STATUS</p>
                        <Select styles={inputStyleH25} value={statePage.productStatus ? optionsCommonStatusDetailCode.find((item: any) => item.value === statePage.productStatus) : "01"} options={optionsCommonStatusDetailCode} onChange={(e) => handleState("productStatus", e.value)} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={(statePage.productDesc !== "null") && (statePage.productDesc !== null) ? statePage.productDesc : ""} onChange={(e) => handleState("productDesc", e.target.value)} className='bd-d4 bg-ff br-5 w-100 s-1418 Lato-400 px-2 out-none '></textarea>
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page13Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    //
    productId: "",
    productName: "",
    productDesc: "",
    productStatus: "001",
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'clearData':
            return {
                ...state,
                productId: '',
                productName: '',
                productDesc: '',
                productStatus: '001',
            };
        case 'setData':
            return {
                ...state,
                productId: action.value.productId,
                productName: action.value.productName,
                productDesc: action.value.productDesc,
                productStatus: action.value.productStatus,
            };
        //
        case 'productId':
            return {
                ...state,
                productId: action.value,
            };
        case 'productName':
            return {
                ...state,
                productName: action.value,
            };
        case 'productDesc':
            return {
                ...state,
                productDesc: action.value,
            };
        case 'productStatus':
            return {
                ...state,
                productStatus: action.value,
            };
        default:
            throw new Error();
    }
}




export default ModalDraggableProductManagement

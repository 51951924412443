import { Fragment, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { chartIcon, codeManagementIcon, feeManagementIcon, interfaceIcon, logIcon, productManagementIcon, qrManagementIcon, settlementIcon } from '../Assets/Images/MenuIcon';
import logo1 from '../Assets/Images/logo1.png';
import logo2 from '../Assets/Images/logo2.png';
import { arrow_sidebar, dbIcon, iconLogout, iconMenuHumbugger, iconPeople } from '../Assets/Images/svg';
import { history } from '../History';
import {
  Page1DashboardRoute,
  Page2DashboardRoute,
  Page3DashboardRoute,
  Page4DashboardRoute,
  Page5DashboardRoute,
  PageDashboardQRAuthStatisticRoute,
  PageMainDashboardRoute,
  Page1SystemNetworkRoute,

  Page1ProductRoute,
  Page2ProductRoute,
  Page3ProductRoute,

  Page1FeeRoute,
  Page1RateRoute,
  Page2RateRoute,

  Page1CodeRoute,
  Page4CodeRoute,
  Page3CodeRoute,
  Page2CodeRoute,
  Page3UserRoute,

  Page4QRRoute,
  Page5QRRoute,
  Page1QRRoute,
  Page2QRRoute,
  Page3QRRoute,
  Page6QRRoute,

  Page1AdminRoute,
  Page1UserRoute,
  Page1MemberRoute,
  Page2MemberRoute,
  Page2SystemNetworkRoute,
  Page3SystemNetworkRoute,
  Page2UserRoute,


  Page1InterfaceAPIRoute,
  Page2InterfaceAPIRoute,
  Page3InterfaceAPIRoute,
  Page4InterfaceAPIRoute,
  Page5InterfaceAPIRoute,
  Page6InterfaceAPIRoute,

  Page1InterfaceFileRoutes,
  Page2InterfaceFileRoutes,
  Page3InterfaceFileRoutes,
  Page4InterfaceFileRoutes,
  Page5InterfaceFileRoutes,

  Page1SettlementRoute,
  Page2SettlementRoute,
  Page3SettlementRoute,
  Page4SettlementRoute,
  Page5SettlementRoute,

  Page1FrameworkRoute,
  Page2FrameworkRoute,
  // Page6FrameworkRoute,
  PageAlertParameter,

  Page1JobSchedulerRoute,
  Page2JobSchedulerRoute,
  Page3JobSchedulerRoute,
  Page4JobSchedulerRoute,
  Page3FrameworkRoute,
  Page4FrameworkRoute,
  Page3ServicesRoute,

  Page1ProductionSimRoute,
  Page2ProductionSimRoute,

} from "../Routes";
import { conditionAction } from '../Store/Logo';
import { logoutAction } from '../Store/User';
import { Profile } from './Profile';
import { SecondsToHHMMSS } from '../Utils';
import { MenuSideBar, ShowMenuSideBar } from '../State/Menu/Menu';
import useIsMobile from '../Utils/useIsMobile';

const dataLogo1 = <img src={logo2} className='' alt='GAPIH' style={{ height: '60px' }} />
const dataLogo2 = <img src={logo1} className='' alt='GAPIH' style={{ height: '40px' }} />
const ContainerSidebar = ({ ...props }) => {
  const { stateComponent, changeMenuMain, dispatchParent, ref } = props;
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  const conditionLogo = useSelector((state: any) => state.logo.condition);
  return (
    <nav className='px-2'>
      {/* Main Menu */}
      <div className='nav nav-pills nav-sidebar' role='menu'>
        {stateComponent.menuSidebar &&
          stateComponent.menuSidebar.map((elementSidebar: any, index: any) => {
            if (privilegeAccess !== null) {
              if (elementSidebar.privilegesId.length === 0 || elementSidebar.privilegesId.some((element1: any) => privilegeAccess.includes(element1))) {
                // console.log(stateComponent.show[index].activeMenu, index)
                // console.log(stateComponent.show[index].displayDropdown, index)
                return (
                  <div key={`sideBarContainer${index}`} className={`pointer my-1 ${conditionLogo === true ? 'w-100' : 'w-100'}`} >
                    <div onClick={(e) => changeMenuMain(elementSidebar.id, null)} className={`nav-link py-1 px-2 d-flex ${stateComponent.show[index].activeMenu === true ? 'active menu_' : ''}`} >
                      <i className='nav-icon' style={conditionLogo === true ? { marginRight: '10px', } : { marginLeft:'3px'}}>{elementSidebar.icon}</i>
                      <div className='Lato-400 c-29 d-flex justify-content-between' style={conditionLogo === true ? { flex: '3' } : { flex: '3', display: 'none' }}>
                        {conditionLogo === true ? <><div className='mt-1' style={{ flex: '10', fontSize:'12px' }}>{elementSidebar.label}</div> <i className=''>{arrow_sidebar} </i>{' '}</> : <></>}
                      </div>
                    </div>
                    {/* Sub Menu */}
                    <div className='nav nav-treeview my-2' style={conditionLogo === false ? { display: 'none' } : stateComponent.show[index].displayDropdown === true ? { display: 'block' } : { display: 'none' }}>
                      {elementSidebar.itemSidebar.map((item: any, i: any) => {
                        if (item.privilegeId === '' || privilegeAccess.includes(item.privilegeId)) {
                          return (
                            <div key={`sideBarItem${i}${item.key}`} className={`nav-item pointer bd-ea br-30 my-1 mx-2`} onClick={() => {
                              // console.log('testing')
                              dispatchParent({ type: 'toggleSidebar', value: item.url })
                            }}>
                              <NavLink to={item.url} className={`nav-link item_`} state={{ id: elementSidebar.id }} >
                                <p className='Lato-400 c-29 ml-14' style={{fontSize:'12px'}}>{item.titleList} </p>
                              </NavLink>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              }
            }
          })}
      </div>
    </nav>
  )
}

const ContainerHeader = ({ ...props }) => {
  const dispatchGlobal = useDispatch()
  const user = useSelector((state: any) => state.user.user);
  return (
    <div className="d-flex justify-content-between bg-ff px-3  bd-ea-bottom" style={{ top: "0px", right: "0px", height: "60px", zIndex: 9 }}>
      <div className="d-flex justify-content-center align-items-center pointer" onClick={() => dispatchGlobal(conditionAction(null))}>{iconMenuHumbugger}</div>
        <Link to={PageMainDashboardRoute} 
          className={`d-flex d-sm-none align-items-center h-60px ms-3`}
        >
          {dataLogo1}
        </Link>
      <div className="d-flex px-2">
        <div className="d-none justify-content-center align-items-center px-3 h-60p d-sm-flex">
          <p className="Lato-400 px-2 c-7f font-weight-600">{user?.userName}</p>
        </div>
        <div className="pointer d-flex justify-content-center align-items-center " >
          <Profile />
        </div>
      </div>
    </div>

  );
}

export const Sidebar = ({ child }: { child: JSX.Element }): JSX.Element => {
  const isMobile = useIsMobile();
  const [stateComponent, dispatchStateComponent] = useReducer(reducer, initialState);
  const dispatchGlobal = useDispatch()
  const user = useSelector((state: any) => state.user.user);
  const conditionLogo = useSelector((state: any) => state.logo.condition);
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  const timeout = useSelector((state: any) => state.timeout);
  const location = useLocation();

  useEffect(() => {
    // console.log(location)
    dispatchStateComponent({ type: 'toggleSidebar', value: location?.pathname })
  }, [location?.pathname])

  const changeMenuMain = (idSidebar: any, condition: any) => {
    if (conditionLogo === false) {
      document.body.classList.remove('sidebar-collapse');
      dispatchGlobal(conditionAction(null));
    }
    if (idSidebar !== null) dispatchStateComponent({ type: 'clickMenu', value: { condition: condition, idSidebar: idSidebar, location: location } })
  };

  if (privilegeAccess !== null) {
    if (privilegeAccess.length === 0) {
      return <>{child}</>
    }
  }

  const widthSidebarFalse = isMobile ? "0px" : "60px";
  
  const styleContainerSidebarTrue: {} = {
    width: "220px",
    zIndex: 10,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    color: "black",
    background: "#cf5353 !important",
  };
  const styleContainerSidebarFalse: {} = {
    width: widthSidebarFalse,
    zIndex: 10,
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    color: "black",
    background: "#cf5353 !important",
  
  };
  return (
    <div className='d-flex' style={{ position: "relative", minHeight: '100%' }}>
      <div className=" bg-ff  shadow" style={conditionLogo === true ? styleContainerSidebarTrue : styleContainerSidebarFalse}>
          <Link to={PageMainDashboardRoute} // 2024-11-06 update home page to new main dashboard page
                    style={{
                      overflowY: 'scroll'
                    }} 
                    className={`d-none d-sm-flex align-items-center h-60px bd-ea-bottom scrollable-container  ${conditionLogo === true ? 'justify-content-center' : 'justify-content-center'}`}
              >
            {conditionLogo === true ? dataLogo1 : dataLogo2}
          </Link>
        <div className={`d-flex d-sm-none align-items-center h-40px bd-ea-bottom scrollable-container me-2  ${conditionLogo === true ? 'justify-content-end' : 'justify-content-end'}`}>
          <div 
            className="d-flex justify-content-center align-items-center pointer d-block d-sm-none" 
            onClick={() => dispatchGlobal(conditionAction(null))}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 16 16" fill="none">
              <path d="M9 13L9 10H16V6L9 6L9 3L8 3L3 8L8 13H9Z" fill="#7f7f7f"/>
              <path d="M2 14L2 2L0 2L5.24537e-07 14H2Z" fill="#7f7f7f"/>
            </svg>
          </div>
        </div>
        <div
          style={{ overflowY: 'scroll', paddingBottom: '150px' }}
          className='sidebar h-100 scrollable-container pt-2'
        >
          <ContainerSidebar stateComponent={stateComponent} changeMenuMain={changeMenuMain} dispatchParent={dispatchStateComponent} />
        </div>
      </div>
      <div className="flex-fill" style={{ position: "relative", marginLeft: conditionLogo === true ? (isMobile ? '0px' : '220px') : (isMobile ? '0px' : '60px') }}>
        <ContainerHeader />
        <div className='px-3 w-100' style={{ position: 'absolute', paddingTop: '0px', zIndex: 1 }}>{child}</div>
      </div>

      {/* <nav className="navbar fixed-bottom navbar-expand-lg navbar-light  mt-2">
        <div className="w-100 d-flex justify-content-end align-items-end">
          <div className="card">
            <div className="card-body">
              {SecondsToHHMMSS(timeout.remaining)}
            </div>
          </div>
        </div>
      </nav>  */}

    </div>

  );
}

const initialState: any = {
  menu: 'authorization_sidebar',
  menuSidebar: [...MenuSideBar],
  show: [...ShowMenuSideBar],
  submenu_id: 0,
  conditionLogo: false,
  privilegeAccess: [],
}

const reducer = (state: any, action: any) => {

  let show: any[] = [];
  switch (action.type) {
    case 'clickMenu':
      state.show.forEach((element: any) => {
        if (element.id === action.value.idSidebar) {
          element.activeMenu = true;
          element.displayDropdown = true;
        } else {
          element.activeMenu = false;
          element.displayDropdown = false;
        }
        show.push(element);
      });
      return {
        ...state,
        show: show,
      }
    case 'toggleSidebar':
      let pathNameNow = action.value === '' ? action.value.location.pathname.toString().split('/')[1] : action.value.toString().split('/')[1];
      let selectedMenuPathName = '';
      let selectedIdMenu = '';
      state.menuSidebar.forEach((item: any, index: number) => {
        if (item.itemSidebar[0].url.toString().split('/')[1] === pathNameNow) {
          selectedMenuPathName = action.value === '' ? action.value.location.pathname : action.value;
          selectedIdMenu = item.id;
        }
      })
      state.show.forEach((element: any) => {
        if (element.id === selectedIdMenu) {
          element.activeMenu = true;
          element.displayDropdown = true;
        } else {
          element.activeMenu = false;
          element.displayDropdown = false;
        }
        show.push(element);
      });
      return {
        ...state,
        show: show,
      }
    default:
      throw new Error();
  }
}




import { useParams } from "react-router-dom";
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected,
} from "../../State";
import { useEffect, useReducer } from "react";
import { API } from "../../Services";
import { logoutAction } from "../../Store/User";
import { useDispatch } from "react-redux";
import { CheckUndefined, NumberWithCommas } from "../../Utils";
import { InsideComponentPage, NavigationModal, TableWithLimitOffset } from "../../Components";
import { TableDisputeResolution } from "./TableDisputeResolution";
import { ModelDetailDisputeResolution } from "./ModelDetailDisputeResolution";
import { InterfaceOptionsDetailDisputeResolution } from "./InterfaceOptionsDetailDisputeResolution";
import { ModelDetailDisputeResolutionEvent } from "./ModelDetailDisputeResolutionEvent";
import { ModalDetailDisputeResolution } from "./ModalDetailDisputeResolution";
import { TableDetailDisputeResolution } from "./TableDetailDisputeResolution";
import { loadingAction } from "../../Store/Loading";
import { ShowModalInterface } from "../../Interface";
import { Divider } from "antd";
import { urlSelection } from "../../Services/API";


export const DetailDisputeResolution = ({...props}) => {
    let { id } = useParams();
    const dispatchGlobal = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        API.get({
            bodyCustom: null,
            pathCustom: `dispute/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            dispatch({ type: 'setData', value: response.data });
        })

        let limit = `limit=${state.view}&`;
        let offset = `offset=${parseInt((state.position - state.view).toString())}&`;
        let caseID = `caseId=${id}&`;
        let finalParameter = `${limit}${offset}${caseID}`;
        API.get({
            bodyCustom: null,
            pathCustom: `dispute/event?${finalParameter.slice(0, -1)}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            dispatch({ type: 'data', value: response.data });
            dispatch({ type: 'totalPages', value: response.detail.totalPage });
            dispatch({ type: 'totalData', value: response.detail.total });
        })
    }
    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsDetailDisputeResolution, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(false)
    }, [state.position, state.view])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search])

    useEffect(() => {
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'currentPage', value: currentPage });
    }, [state.totalData, state.position, state.view])

    const exportToCSVComponent = () => { }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelDetailDisputeResolution) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)

    const customButtonAction = (item: ModelDetailDisputeResolution, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
            </div>
        );
    };

    const filterComponentPage = () => {
        let classNameLeft = 's-1418 w-200px d-flex bg-f4 align-items-center p-2'
        let classNameRight = 'Lato-700 s-1418 w-100 bg-ff h-100 d-flex align-items-center p-2'
        return (
            <div className="px-2 ">
                <h2 className="Lato-700 c-29 s-1822 mt-4">Case Detail</h2>
                <Divider />
                <div className="row p-0 m-0" style={{ overflow: 'auto' }} >
                    <div className="col-xl-4 col-lg-6 col-sm-12 col-xs-12 p-0 m-0" >
                        <div className="">
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Case ID</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.caseId)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Case Date</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.caseDate)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Case Type</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.caseType)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Dispute Issuer Amount</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.disIssCurrency)} {NumberWithCommas(CheckUndefined(state.dataDispute.disIssAmount))}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Dispute Acquirer Amount</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.disAcqCurrency)} {NumberWithCommas(CheckUndefined(state.dataDispute.disAcqAmount))}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Issuer</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.iss)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Acquirer</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.acq)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Case Status</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.caseStatus)}</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-12 col-xs-12 p-0 m-0" >
                        <div className="">
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Merchant ID</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.merId)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Merchant Name</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.merName)}</div>
                            </div>
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Terminal ID</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.terId)}</div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-12 col-xs-12 p-0 m-0" >
                        <div className="">
                            <div className="d-flex bd-ea h-60px" >
                                <div className={`${classNameLeft}`}>Days to Act</div>
                                <div className={`${classNameRight}`}>{CheckUndefined(state.dataDispute.daysToAct)}</div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <div className="d-flex bd-ea h-60px" >
                                    <div className={`${classNameLeft}`}></div>
                                    <div className={`${classNameRight}`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const tableComponentPage = () => {
        return (
            <TableDetailDisputeResolution
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                editData={(item: any) => actionShowModal(item.eventId, item)}
                //
                currentPage={state.currentPage}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <></>
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDetailDisputeResolution
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }


    return (
        <>
            <TableWithLimitOffset
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={false}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={false}
                needAddButton={false}
                needSearchFunction={false}
                needDownloadButton={false}
                //
                needComponentHeaderTableCenter={true}
            />
        </>
    );
}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    titlePage: 'Dispute Resolution',
    detailModal: false,
    privilegeAccess: { view: '', add: '', edit: '', delete: '', download: '' },
    //
    dataDispute: new ModelDetailDisputeResolution({
        id: null,
        caseId: null,
        caseNo: null,
        caseDate: null,
        caseType: null,
        disIssAmount: null,
        disIssCurrency: null,
        disAcqAmount: null,
        disAcqCurrency: null,
        iss: null,
        acq: null,
        caseStatus: null,
        merId: null,
        merName: null,
        terId: null,
        daysToAct: null,
        dateDeadline: null,
    }),
    // 
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Date/Time', width: 'wd-200', val: 'dateTime' },
        { field: 'Event', width: 'wd-200', val: 'event' },
        { field: 'User/Member', width: 'wd-200', val: 'user' }, // member
        { field: 'Details', width: 'wd-200', val: 'detail' },
    ],
    toggle: ['no', 'dateTime', 'event', 'user', 'detail'],
    complete_toggle: ['no', 'dateTime', 'event', 'user', 'detail'],
    list_column_name: ['No.', 'Date/Time', 'Event', 'User/Member', 'Details'],
    //
    savedShowModal: [], // new
    // 
    id: '',
    data: [],
    dataModal: [],
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 900,
    dataLength: 0, // zIndex

};
// eventId ModelDetailDisputeResolutionEvent
function reducer(state: any, action: any) {
    for (const key in state.data as ModelDetailDisputeResolutionEvent) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'clearData':
            return {
                ...state,
                dataDispute: new ModelDetailDisputeResolution({
                    id: null,
                    caseId: null,
                    caseNo: null,
                    caseDate: null,
                    caseType: null,
                    disIssAmount: null,
                    disIssCurrency: null,
                    disAcqAmount: null,
                    disAcqCurrency: null,
                    iss: null,
                    acq: null,
                    caseStatus: null,
                    merId: null,
                    merName: null,
                    terId: null,
                    daysToAct: null,
                    dateDeadline: null,
                }),

            };
        case 'setData':
            return {
                ...state,
                dataDispute: new ModelDetailDisputeResolution({
                    id: action.value.caseId,
                    caseId: action.value.caseId,
                    caseNo: action.value.caseNo,
                    caseDate: action.value.caseDate,
                    caseType: action.value.caseType,
                    disIssAmount: action.value.disIssAmount,
                    disIssCurrency: action.value.disIssCurrency,
                    disAcqAmount: action.value.disAcqAmount,
                    disAcqCurrency: action.value.disAcqCurrency,
                    iss: action.value.iss,
                    acq: action.value.acq,
                    caseStatus: action.value.caseStatus,
                    merId: action.value.merId,
                    merName: action.value.merName,
                    terId: action.value.terId,
                    daysToAct: action.value.daysToAct,
                    dateDeadline: action.value.dateDeadline,
                }),
            };
        //

        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelDetailDisputeResolutionEvent) => {
                        element1.id = element1.eventId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelDetailDisputeResolutionEvent) => {
                        element1.id = element1.eventId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelDetailDisputeResolutionEvent) => {
                        showDataResult.push(
                            { idModal: element1.eventId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.eventId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'detailModal':
            return {
                ...state,
                detailModal: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };

        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: [],
                detailModal: !(state.detailModal),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                detailModal: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                deleteItem: true,
            };


        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        default:
            throw new Error();
    }
}


// InterfaceFile

interface InterfaceInterfaceFile {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberReconFileRcvYn: Boolean | null;
    memberReconFileRcvId: string | number | null;
    memberReconFileSndYn: Boolean | null;
    memberReconFileSndId: string | number | null;
    memberSettleFileRcvYn: Boolean | null;
    memberSettleFileRcvId: string | number | null;
    memberSettleFileSndYn: Boolean | null;
    memberSettleFileSndId: string | number | null;
    memberMerchantFileRcvYn: Boolean | null;
    memberMerchantFileRcvId: string | number | null;
    memberMerchantFileSndYn: Boolean | null;
    memberMerchantFileSndId: string | number | null;
    memberPaymentFileRcvYn: Boolean | null;
    memberPaymentFileRcvId: string | number | null;
    memberPaymentFileSndYn: Boolean | null;
    memberPaymentFileSndId: string | number | null;
    memberBillingRcvYn: Boolean | null;
    memberBillingRcvId: string | number | null;
    memberBillingSndYn: Boolean | null;
    memberBillingSndId: string | number | null;
    memberReconResFileSndYn: Boolean | null;
    memberReconResFileRcvId: string | number | null;
    memberMerchantResFileRcvYn: Boolean | null;
    memberReconResFileSndId: string | number | null;
    memberReconResFileRcvYn: Boolean | null;
    memberMerchantResFileRcvId: string | number | null;
    memberMerchantResFileSndYn: Boolean | null;
    memberMerchantResFileSndId: string | number | null;
    memberUserSftpYn: Boolean | null;
    memberSftpServer: string | number | null;
    memberSftpUsername: string | number | null;
    memberSftpHost: string | number | null;
    memberSftpPort: string | number | null;
    memberSftpPassword: string | number | null;
    //
    memberSftpPublicKey: string | number | null;
}

export class ModalInterfaceFile {
    id: null | number | string;
    memberParamsId: number | null;
    memberCode: string | null;
    memberReconFileRcvYn: Boolean | null;
    memberReconFileRcvId: string | number | null;
    memberReconFileSndYn: Boolean | null;
    memberReconFileSndId: string | number | null;
    memberSettleFileRcvYn: Boolean | null;
    memberSettleFileRcvId: string | number | null;
    memberSettleFileSndYn: Boolean | null;
    memberSettleFileSndId: string | number | null;
    memberMerchantFileRcvYn: Boolean | null;
    memberMerchantFileRcvId: string | number | null;
    memberMerchantFileSndYn: Boolean | null;
    memberMerchantFileSndId: string | number | null;
    memberPaymentFileRcvYn: Boolean | null;
    memberPaymentFileRcvId: string | number | null;
    memberPaymentFileSndYn: Boolean | null;
    memberPaymentFileSndId: string | number | null;
    memberBillingRcvYn: Boolean | null;
    memberBillingRcvId: string | number | null;
    memberBillingSndYn: Boolean | null;
    memberBillingSndId: string | number | null;
    memberReconResFileSndYn: Boolean | null;
    memberReconResFileRcvId: string | number | null;
    memberMerchantResFileRcvYn: Boolean | null;
    memberReconResFileSndId: string | number | null;
    memberReconResFileRcvYn: Boolean | null;
    memberMerchantResFileRcvId: string | number | null;
    memberMerchantResFileSndYn: Boolean | null;
    memberMerchantResFileSndId: string | number | null;
    memberUserSftpYn: Boolean | null;
    memberSftpServer: string | number | null;
    memberSftpUsername: string | number | null;
    memberSftpHost: string | number | null;
    memberSftpPort: string | number | null;
    memberSftpPassword: string | number | null;
    //
    memberSftpPublicKey: string | number | null;

    constructor(value: InterfaceInterfaceFile) {
        this.id = value.id;
        this.memberParamsId = value?.memberParamsId ?? null;
        this.memberCode = value?.memberCode ?? null;
        this.memberReconFileRcvYn = value?.memberReconFileRcvYn ?? null;
        this.memberReconFileRcvId = value?.memberReconFileRcvId ?? null;
        this.memberReconFileSndYn = value?.memberReconFileSndYn ?? null;
        this.memberReconFileSndId = value?.memberReconFileSndId ?? null;
        this.memberSettleFileRcvYn = value?.memberSettleFileRcvYn ?? null;
        this.memberSettleFileRcvId = value?.memberSettleFileRcvId ?? null;
        this.memberSettleFileSndYn = value?.memberSettleFileSndYn ?? null;
        this.memberSettleFileSndId = value?.memberSettleFileSndId ?? null;
        this.memberMerchantFileRcvYn = value?.memberMerchantFileRcvYn ?? null;
        this.memberMerchantFileRcvId = value?.memberMerchantFileRcvId ?? null;
        this.memberMerchantFileSndYn = value?.memberMerchantFileSndYn ?? null;
        this.memberMerchantFileSndId = value?.memberMerchantFileSndId ?? null;
        this.memberPaymentFileRcvYn = value?.memberPaymentFileRcvYn ?? null;
        this.memberPaymentFileRcvId = value?.memberPaymentFileRcvId ?? null;
        this.memberPaymentFileSndYn = value?.memberPaymentFileSndYn ?? null;
        this.memberPaymentFileSndId = value?.memberPaymentFileSndId ?? null;
        this.memberBillingRcvYn = value?.memberBillingRcvYn ?? null;
        this.memberBillingRcvId = value?.memberBillingRcvId ?? null;
        this.memberBillingSndYn = value?.memberBillingSndYn ?? null;
        this.memberBillingSndId = value?.memberBillingSndId ?? null;
        this.memberReconResFileSndYn = value?.memberReconResFileSndYn ?? null;
        this.memberReconResFileRcvId = value?.memberReconResFileRcvId ?? null;
        this.memberMerchantResFileRcvYn = value?.memberMerchantResFileRcvYn ?? null;
        this.memberReconResFileSndId = value?.memberReconResFileSndId ?? null;
        this.memberReconResFileRcvYn = value?.memberReconResFileRcvYn ?? null;
        this.memberMerchantResFileRcvId = value?.memberMerchantResFileRcvId ?? null;
        this.memberMerchantResFileSndYn = value?.memberMerchantResFileSndYn ?? null;
        this.memberMerchantResFileSndId = value?.memberMerchantResFileSndId ?? null;
        this.memberUserSftpYn = value?.memberUserSftpYn ?? null;
        this.memberSftpServer = value?.memberSftpServer ?? null;
        this.memberSftpUsername = value?.memberSftpUsername ?? null;
        this.memberSftpHost = value?.memberSftpHost ?? null;
        this.memberSftpPort = value?.memberSftpPort ?? null;
        this.memberSftpPassword = value?.memberSftpPassword ?? null;
        //
        this.memberSftpPublicKey = value?.memberSftpPublicKey ?? null;
    }
}

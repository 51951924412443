import { Dropdown } from "react-bootstrap";

export const SelectView = ({ ...props }) => {
  const { list_view, handleState, view } = props;
  return (
    <div className="dropdown mx-2 w-100px" >
      <Dropdown className="w-100px" drop="up">
        <Dropdown.Toggle id="dropdown-basic" variant="Secondary" className="bd-d4 br-20 px-2 h-30px Lato-400 s-1418 shd-none bg-ff w-70px">
          {view}{'  '}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-60px text-center Lato-400 s-1418" style={{minWidth:'5px'}}>
          {list_view.map((item: any) => (<Dropdown.Item key={item} onClick={() => {
            handleState("view", item)
            handleState("position", item)
          }}>{item}</Dropdown.Item>))}
        </Dropdown.Menu>
      </Dropdown>

    </div>
  );
};


import { feeManagementIcon } from "../../../Assets/Images/MenuIcon";
import { Page1RateRoute, Page2RateRoute } from "../../../Routes";
import { Page16Code, Page17Code } from "../CodeMenu";
import { Page16Title, Page17Title } from "../TitleMenu";

export const Menu6 = {
    id: 'rate_sidebar',
    label: 'Rate',
    icon: feeManagementIcon,
    privilegesId: [Page16Code, Page17Code],
    itemSidebar: [
        {
            titleList: Page16Title,
            key: 'rateOptions1',
            privilegeId: Page16Code,
            url: Page1RateRoute,
        },
        {
            titleList: Page17Title,
            key: 'rateOptions2',
            privilegeId: Page17Code,
            url: Page2RateRoute,
        },
    ],
}
import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalReasonCodeManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsRcGroup } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === "rcCode") {
            if (valueState.split("").length < 5) {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else if (nameState === "rcName") {
            if (valueState.split("").length > 32) {
                dispatchStatePage({ type: 'errorMessage', value: "Reason Name input maximum 32 characters" })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else if (nameState === "rcDesc") {
            if (valueState.split("").length > 64) {
                dispatchStatePage({ type: 'errorMessage', value: "Description input maximum 64 characters" })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { rcId, rcGroup, rcCode, rcName, rcDesc } = statePage;
        const data = {
            rcGroup: `${rcGroup}`,
            rcCode: `${rcCode}`,
            rcName: `${rcName}`,
            rcDesc: `${rcDesc}`,
        };
        API.post({
            bodyCustom: data,
            pathCustom: `${urlApi}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            dispatchStatePage({ type: 'clearData' })
            setModal("visibleModalAddDelete")
            reloadData()
        }).catch((err) => handleState('errorMessage', err))
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className="col-6">
                                <p className={labelStyle}>reason code</p>
                                <input value={statePage.rcCode} onChange={(e) => handleState("rcCode", e.target.value)} className={inputStyle} />
                            </div>
                            <div className="col-6">
                                <p className={labelStyle}>reason name</p>
                                <input value={statePage.rcName} onChange={(e) => handleState("rcName", e.target.value)} className={inputStyle} />
                            </div>
                            <div className="col-12">
                                <p className={labelStyle}>Reason Group Code</p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={"(Search Institution)"}
                                    options={optionsRcGroup}
                                    value={optionsRcGroup.filter((option: any) => option.value === `${statePage.rcGroup}`)}
                                    onChange={(e) => handleState("rcGroup", e.value)}
                                />
                            </div>
                            <div className="col-12">
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.rcDesc} onChange={(e) => handleState("rcDesc", e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />

                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />

        </>
    )
}


const initialState = {
    titleModal: 'New Reason Code',
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    rcId: "",
    rcGroup: "",
    rcCode: "",
    rcName: "",
    rcDesc: "",
    // 
    id: "",
    errorMessage: "",
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                rcId: "",
                rcGroup: "",
                rcCode: "",
                rcName: "",
                rcDesc: "",
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                rcId: action.value.rcId,
                rcGroup: action.value.rcGroup,
                rcCode: action.value.rcCode,
                rcName: action.value.rcName,
                rcDesc: action.value.rcDesc,
                errorMessage: "",
            };
        // 
        case 'rcId':
            return {
                ...state,
                rcId: action.value,
            };
        case 'rcGroup':
            return {
                ...state,
                rcGroup: action.value,
            };
        case 'rcCode':
            return {
                ...state,
                rcCode: action.value,
            };
        case 'rcName':
            return {
                ...state,
                rcName: action.value,
            };
        case 'rcDesc':
            return {
                ...state,
                rcDesc: action.value,
            };
        default:
            throw new Error();
    }
}

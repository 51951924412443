import { useEffect, useReducer, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { close, undo_icon } from '../../Assets/Images/svg';
import { ButtonDelete, ButtonEdit } from '../../Components';
import { ShowModalInterface } from '../../Interface';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { inputStyleH25, labelStyle } from '../../Styles';
import { ModalStyle } from '../../Utils';
import { InterfaceOptionsUserManagement } from './Utils/InterfaceOptionsUserManagement';
import { ModalDraggableUserManagement } from './Components/Modal/ModalDraggableUserManagement';
import { ModalUserManagement } from './Components/Modal/ModalUserManagement';
import { ModelUserManagement } from './Utils/ModelUserManagement';
import { Page10Title } from '../../State/Menu/TitleMenu';
import { ExcelUserManagement } from './Utils/Excel';
import { TableUserProfileManagement } from './Components/TableUserProfileManagement';


const Page7 = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    const userLogin = useSelector((state: any) => state.user);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])

    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let selectedUserCategory = stateCustomComponent.selectedUserCategory !== '' ? `categoryCode=${stateCustomComponent.selectedUserCategory}&` : '';
        let selectedUserLevel = stateCustomComponent.selectedUserLevel !== '' ? `level=${stateCustomComponent.selectedUserLevel}&` : '';
        let limit = `limit=${state.view}&`;
        let offset = `offset=${pagination.pageIndex * state.view}&`;
        if (needRefreshPage === true) {
            // searchData
            if (userLogin.memberId !== undefined && userLogin.memberId !== null) {
                if (`${userLogin.memberId}` === "1") {
                    let selectedMember = stateCustomComponent.selectedMember !== '' ? `memberId=${stateCustomComponent.selectedMember}&` : '';
                    let finalParameter = `${search}${limit}${offset}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    let finalParameter2 = `${search}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
                    API.get({
                        bodyCustom: null,
                        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    }).then((response: any) => {
                        dispatch({ type: 'searchData', value: response.data });
                        dispatch({ type: 'totalPages', value: response.detail.totalPage });
                        dispatch({ type: 'totalData', value: response.detail.total });

                        /* Refresh Page */
                        setPagination({pageSize: state.view, pageIndex: 0})
                    })
                } else {
                    let selectedMember = userLogin.memberId !== '' ? `memberId=${userLogin.memberId}&` : '';
                    let finalParameter = `${search}${limit}${offset}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    let finalParameter2 = `${search}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
                    API.get({
                        bodyCustom: null,
                        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    }).then((response: any) => {
                        setPagination({pageSize: state.view, pageIndex: 0})
                        dispatch({ type: 'searchData', value: response.data });
                        dispatch({ type: 'totalPages', value: response.detail.totalPage });
                        dispatch({ type: 'totalData', value: response.detail.total });

                        /* Refresh Page */
                        setPagination({pageSize: state.view, pageIndex: 0})
                    })
                }
            }
        } else {
            // data
            if (userLogin.memberId !== undefined && userLogin.memberId !== null) {
                if (`${userLogin.memberId}` === "1") {
                    let selectedMember = stateCustomComponent.selectedMember !== '' ? `memberId=${stateCustomComponent.selectedMember}&` : '';
                    let finalParameter = `${search}${limit}${offset}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    let finalParameter2 = `${search}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
                    API.get({
                        bodyCustom: null,
                        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    }).then((response: any) => {
                        dispatch({ type: 'data', value: response.data });
                        dispatch({ type: 'totalPages', value: response.detail.totalPage });
                        dispatch({ type: 'totalData', value: response.detail.total });
                    })
                } else {
                    let selectedMember = userLogin.memberId !== '' ? `memberId=${userLogin.memberId}&` : '';
                    let finalParameter = `${search}${limit}${offset}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    let finalParameter2 = `${search}${selectedUserCategory}${selectedUserLevel}${selectedMember}`;
                    handleStateComponent('finalAPI', `${state.urlApi}?${finalParameter2.slice(0, -1)}`)
                    API.get({
                        bodyCustom: null,
                        pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                        selectUrl: urlSelection.dashboard,
                        useToken: true,
                        needLoading: true,
                    }).then((response: any) => {
                        dispatch({ type: 'data', value: response.data });
                        dispatch({ type: 'totalPages', value: response.detail.totalPage });
                        dispatch({ type: 'totalData', value: response.detail.total });
                    })
                }
            }
        }
    }

    useEffect(() => {
        initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsUserManagement, dispatchGlobal)
    }, [])

    useEffect(() => {
        initFunctionCustom(true)
    }, [state.view, state.search, stateCustomComponent.selectedMember, stateCustomComponent.selectedUserCategory, stateCustomComponent.selectedUserLevel, userLogin.memberId])

useEffect(() => {
    initFunctionCustom(false);
}, [pagination.pageIndex]);

useEffect(() => {
    setPagination({pageIndex: pagination.pageIndex, pageSize:state.view});
}, [state.view]);

    const exportToCSVComponent = () => {
        API.get({
            bodyCustom: null,
            pathCustom: state.finalAPI,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => {
            ExcelUserManagement(state, stateOptions, response.data)
        })
    }
    //
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelUserManagement) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunctionCustom(needRefreshPage)
    const actionShowModal = (idModal: any, item: any) => dispatch({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => initFunctionCustom(false)


    const customButtonAction = (item: ModelUserManagement, width: string | any) => {
        return (
            
            <div className={`d-flex justify-content-around align-items-center gap-2 ${width}`}>
                <ButtonEdit handleClick={() => actionShowModal(item.id, item)} codeAccessPrivilege={state.privilegeAccess.edit} />
                <ButtonDelete handleClick={() => deleteDataComponent(item)} codeAccessPrivilege={state.privilegeAccess.delete} />
                
            </div>
        );

    };

    const filterComponentPage = () => {
        if (userLogin.memberId === undefined) {
            return <></>
        } else if (userLogin.memberId === null) {
            return <></>
        } else {
            return (
                <div className="g-5 row bg-ff mb-10 d-flex justify-content-center align-items-center p-0 m-0 py-3 mb-2 br-10 bd-d4">
                    <div className="row mb-10">
                        {`${userLogin.memberId}` === "1" ? (
                            <div className="col-xxl-4 col-xl-4 col-lg-6 col-sm-12 col-xs-12">
                                <p className={labelStyle}>member ID</p>
                                <div className='d-flex justify-content-between'>
                                    <Select className='flex-fill' styles={inputStyleH25} options={stateOptions.optionsMember} value={stateOptions.optionsMember.filter((option: any) => option.value === stateCustomComponent.selectedMember)} onChange={(e) => handleState("selectedMember", e.value, dispatchCustomComponent)} />
                                    <div className='d-flex justify-content-center align-items-center px-2 pointer width-5px' onClick={(e) => { handleState("selectedMember", '', dispatchCustomComponent) }}>
                                        {undo_icon}
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <></>
                        )}
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-sm-12 col-xs-12">
                            <p className={labelStyle}>By Privilege Level</p>
                            <div className='d-flex justify-content-between'>
                                <Select
                                    styles={inputStyleH25}
                                    className='flex-fill'
                                    options={stateOptions.optionsUserPrivilegeLevel.map((element: any) => {
                                        if (element.value !== "00") {
                                        }
                                        return element;
                                    })}
                                    value={stateOptions.optionsUserPrivilegeLevel.filter((option: any) => option.value === stateCustomComponent.selectedUserLevel)}
                                    onChange={(e) => handleState("selectedUserLevel", e.value, dispatchCustomComponent)}
                                />

                                <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedUserLevel", '', dispatchCustomComponent) }}>
                                    {undo_icon}
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-sm-12 col-xs-12">
                            <p className={labelStyle}>By Category code</p>
                            <div className='d-flex justify-content-between'>
                                <Select className='flex-fill' options={stateOptions.optionsUserCategoryDetailCode} styles={inputStyleH25} value={stateOptions.optionsUserCategoryDetailCode.filter((option: any) => option.value === stateCustomComponent.selectedUserCategory)} onChange={(e) => handleState("selectedUserCategory", e.value, dispatchCustomComponent)} />
                                <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedUserCategory", '', dispatchCustomComponent) }}>
                                    {undo_icon}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
        }

    }

    const modalAddDeleteComponentPage = () => {
        return (
            <ModalUserManagement
                visibleModalAddDelete={state.visibleModalAddDelete}
                setModal={setModalComponent}
                deleteItem={state.deleteItem}
                id={state.id}
                dataModal={state.dataModal}
                urlApi={state.urlApi}
                reloadData={() => reloadDataComponent(false)}
                stateOptions={stateOptions}
                handleStateComponent={handleStateComponent}
            />

        )
    }

    const modalUpdateComponentPage = () => {
        return (
            <>
                {
                    state.savedShowModal.map((element1: any) => {
                        return (
                            <ModalDraggableUserManagement
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={state}
                                urlApi={state.urlApi}
                                showModal={element1.showModal}
                                reloadData={() => reloadDataComponent(false)}
                                actionShowModal={() => actionShowModal(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModal(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItem}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                                handleStateComponent={handleStateComponent}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2 mt-2">
                User Profile
            </h1>
            <TableUserProfileManagement 
                state={state}
                addData={addData}
                stateOptions={stateOptions}
                addDataComponent={addDataComponent}
                stateCustomComponent={stateCustomComponent}
                handleStateParent={handleStateComponent}
                filterComponentPage={filterComponentPage}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                checkIndexComponent={checkIndexComponent}
                setModal={setModalComponent}
                setToggleComponent={setToggleComponent}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                pagination={pagination}
                modalUpdateComponentPage={modalUpdateComponentPage}
                setPagination={setPagination}
            />

            {modalAddDeleteComponentPage()}
            {modalUpdateComponentPage()}
            <Modal isOpen={state.showPassword} style={ModalStyle}>
                <div className='d-flex flex-column wd-300'>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className=''></p>
                        <p className='w-700 Lato-700 text-danger s-1822'>Your Password</p>
                        <div className='pointer' onClick={(e) => dispatch({ type: 'showPassword', value: false })}>{close} </div>
                    </div>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='s-1620 mb-30'>{state.currentPassword} </p>
                    </div>
                </div>
            </Modal>
        </>
    );
}

const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'userapp',
    titlePage: `${Page10Title}`,
    privilegeAccess: { view: "61", add: "62", edit: "63", delete: "64", download: "65" },
    toggle: ["no", "id", "userName", "userEmail", "userCategoryCode", "userLevel", "userStatus", "userStartDate", "userEndDate", "action"],
    complete_toggle: ["no", "id", "userName", "userEmail", "userCategoryCode", "userLevel", "userStatus", "userStartDate", "userEndDate", "action"],
    list_column_name: ["No.", "User ID", "Username", "User Email", "Category Code", "Privilege Level", "Status", "Valid Start Date", "Valid End Date"],

    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'User Email, Username, Valid Start Date, Valid End Date',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1600,
    dataLength: 0, // zIndex
    //
    currentPassword: '',
    showPassword: false,
    //
    finalAPI: '',
};

function reducer(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelUserManagement) => {
                        element1.id = element1.userId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelUserManagement) => {
                        element1.id = element1.userId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 99 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 99 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 99 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelUserManagement) => {
                        showDataResult.push(
                            { idModal: element1.userId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.userId;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'currentPassword':
            return {
                ...state,
                currentPassword: action.value
            };
        case 'showPassword':
            return {
                ...state,
                showPassword: action.value
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        default:
            throw new Error();
    }
}

export default Page7;
import { CheckUndefined, CheckUndefinedStringEmpty, ModalStyle, NumberWithCommas } from "../../Utils";
import { NavigationModal, NavigationTable, TableWithLimitOffset } from "../../Components";
import { addData, checkIndex, handleState, setModal, setToggle } from "../../State";
import { close, undo_icon } from "../../Assets/Images/svg";
import { inputStyle, labelStyle } from "../../Styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useReducer } from "react";

import { API } from "../../Services";
import { Divider } from "antd";
import { Modal } from "antd";
import { ModalSalesInquiry } from "../SalesInquiry/ModalSalesInquiry";
import { ModalSalesInquirySettlementSummaryIssuer } from "./ModalSalesInquiry";
import Select from 'react-select';
import { ShowModalInterface } from "../../Interface";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { urlSelection } from "../../Services/API";

interface TestingInterface {
    id: null | number | string;
    settleDate: string | null;
    authDate: string | null;
    authRrn: string | null;
    authInvNo: string | null;
    authTransId: string | null;
    authCurrCode: string | null;
    authAmount: number | null;
    prodFeatureName: string | null;
    editCode: string | null;
    acqName: string | null;
    issName: string | null;
    acqSwitchName: string | null;
    issSwitchName: string | null;
    transType: string | null;

}

export class ModelTesting {
    id: null | number | string;
    settleDate: string | null;
    authDate: string | null;
    authRrn: string | null;
    authInvNo: string | null;
    authTransId: string | null;
    authCurrCode: string | null;
    authAmount: number | null;
    prodFeatureName: string | null;
    editCode: string | null;
    acqName: string | null;
    issName: string | null;
    acqSwitchName: string | null;
    issSwitchName: string | null;
    transType: string | null;

    constructor(value: TestingInterface) {
        this.id = value.id;
        this.settleDate = value?.settleDate ?? null;
        this.authDate = value?.authDate ?? null;
        this.authRrn = value?.authRrn ?? null;
        this.authInvNo = value?.authInvNo ?? null;
        this.authTransId = value?.authTransId ?? null;
        this.authCurrCode = value?.authCurrCode ?? null;
        this.authAmount = value?.authAmount ?? null;
        this.prodFeatureName = value?.prodFeatureName ?? null;
        this.editCode = value?.editCode ?? null;
        this.acqName = value?.acqName ?? null;
        this.issName = value?.issName ?? null;
        this.acqSwitchName = value?.acqSwitchName ?? null;
        this.issSwitchName = value?.issSwitchName ?? null;
        this.transType = value?.transType ?? null;
    }
}

const HeaderTableLeft = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    return (
        <div className='d-flex justify-content-between px-4 py-3'>
            {
                props.label.map((item: any, i: any) => {
                    if (item.val === 'action') {
                        if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={i} className={`s-1418 Lato-700 ${item.width} text-center `}>
                                        {item.field}
                                    </p>
                                )
                            );
                        } else {
                            return <></>;
                        }
                    }
                    else if (item.val === 'dynamicColumn') {
                        let show = '';
                        if (props.stateFirstParent.whatIsSelectedNow === 'issuerId') {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={i} className={`s-1418 Lato-700 ${item.width} `}>
                                        Issuer
                                    </p>
                                )
                            );
                        }
                        else if (props.stateFirstParent.whatIsSelectedNow === 'acquirerId') {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={i} className={`s-1418 Lato-700 ${item.width} `}>
                                        Acquirer
                                    </p>
                                )
                            );
                        }
                        else if (props.stateFirstParent.whatIsSelectedNow === 'acquirerSwitchId') {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={i} className={`s-1418 Lato-700 ${item.width} `}>
                                        Acquirer Switcher
                                    </p>
                                )
                            );
                        }
                        else if (props.stateFirstParent.whatIsSelectedNow === 'issuerSwitchId') {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={i} className={`s-1418 Lato-700 ${item.width} `}>
                                        Issuer Switcher
                                    </p>
                                )
                            );
                        }
                        return (
                            props.checkIndex(`${item.val}`) && (
                                <p key={i} className={`s-1418 Lato-700 ${item.width} `}>

                                </p>
                            )
                        );
                    }
                    else {
                        return (
                            props.checkIndex(`${item.val}`) && (
                                <p key={i} className={`s-1418 Lato-700 ${item.width} `}>
                                    {item.field}
                                </p>
                            )
                        );
                    }
                })
            }
        </div>
    )
}
const TableCustomLeft = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    return (
        <div className='d-flex flex-column w-100 overflow mb-2'>
            <div className={`d-flex flex-column ${props.widthTable}`}>
                <HeaderTableLeft
                    codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
                    codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
                    label={props.label}
                    data={props.data}
                    position={props.position}
                    view={props.view}
                    customButtonAction={props.customButtonAction}
                    checkIndex={props.checkIndex}
                    widthTable={props.widthTable}
                    stateOptions={props.stateOptions}
                    stateFirstParent={props.stateFirstParent}
                />
                <Divider className="my-2" />

                {props.data.map((item: any, index: any) => {
                    let number = index + ((props.currentPage - 1) * props.view)

                    return (
                        <div key={number} className={`d-flex justify-content-between align-items-center min-h-50px px-4 table-data-pointer ${number % 2 === 0 ? 'bg-f4' : ''} `}
                        onClick={() => props.editData(item)}
                        >
                            {props.label.map((element1: any) => {
                                if (element1.val === 'no') {
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{number + 1}</p>;
                                }
                                // else if (element1.val === 'action') {
                                //     if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                                //         return props.checkIndex(`${element1.val}`) && <div className={`d-flex s-1418 Lato-400 word-wrap ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
                                //     } else {
                                //         return <></>;
                                //     }
                                // } 
                                else if (element1.val === 'authAmount') {
                                    let show = `${CheckUndefinedStringEmpty(item.authCurrCode)} ${NumberWithCommas(CheckUndefined(item.authAmount))}`;
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                }
                                else if (element1.val === 'authFeeAmount') {
                                    let show = `${CheckUndefinedStringEmpty(item.authCurrCode)} ${NumberWithCommas(CheckUndefined(item.authFeeAmount))}`;
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                }
                                else if (element1.val === 'dynamicColumn') {
                                    let show = '';
                                    if (props.stateFirstParent.whatIsSelectedNow === 'issuerId') {
                                        show = CheckUndefined(item[`issName`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    else if (props.stateFirstParent.whatIsSelectedNow === 'acquirerId') {
                                        show = CheckUndefined(item[`acqName`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    else if (props.stateFirstParent.whatIsSelectedNow === 'acquirerSwitchId') {
                                        show = CheckUndefined(item[`acqSwitchName`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    else if (props.stateFirstParent.whatIsSelectedNow === 'issuerSwitchId') {
                                        show = CheckUndefined(item[`issSwitchName`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}></p>;
                                }
                                else if (element1.val === 'reconStatus') {
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;

                                }
                                // else if (element1.val === 'transType') {
                                //     let dataReturn = '';
                                //     if (props.stateOptions.optionsTransactionTypeDetailCodeAndCcdName.length > 0) {
                                //         props.stateOptions.optionsTransactionTypeDetailCodeAndCcdName.forEach((element2: any) => {
                                //             if (element2.value === item[`${element1.val}`]) {
                                //                 dataReturn = `${element2.label}`;
                                //             }
                                //         });
                                //     }
                                //     return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                // }
                                else {
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                                }
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const HeaderTableRight = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    return (
        <div className='d-flex justify-content-between px-4 py-3'>
            {
                props.label.map((item: any, i: any) => {
                    if (item.val === 'action') {
                        if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={i} className={`s-1418 Lato-700 ${item.width} text-center `}>
                                        {item.field}
                                    </p>
                                )
                            );
                        } else {
                            return <></>;
                        }
                    }
                    else if (item.val === 'dynamicColumn') {
                        return (
                            props.checkIndex(`${item.val}`) && (
                                <p key={i} className={`s-1418 Lato-700 ${item.width} `}>
                                    Acquirer Switcher
                                </p>
                            )
                        );
                    }
                    else {
                        return (
                            props.checkIndex(`${item.val}`) && (
                                <p key={i} className={`s-1418 Lato-700 ${item.width} `}>
                                    {item.field}
                                </p>
                            )
                        );
                    }
                })
            }
        </div>
    )
}
const TableCustomRight = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    return (
        <div className='d-flex flex-column w-100 overflow mb-2'>
            <div className={`d-flex flex-column ${props.widthTable}`}>
                <HeaderTableRight
                    codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
                    codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
                    label={props.label}
                    data={props.data}
                    position={props.position}
                    view={props.view}
                    customButtonAction={props.customButtonAction}
                    checkIndex={props.checkIndex}
                    widthTable={props.widthTable}
                    stateOptions={props.stateOptions}
                    stateFirstParent={props.stateFirstParent}
                />
                <Divider className="my-2" />

                {props.data.map((item: any, index: any) => {
                    let number = index + ((props.currentPage - 1) * props.view)

                    return (
                        <div key={number} className={`d-flex justify-content-between align-items-center min-h-50px px-4 table-data-pointer   ${number % 2 === 0 ? 'bg-f4' : ''} `} onClick={() => props.editData(item)}>
                            {props.label.map((element1: any) => {
                                if (element1.val === 'no') {
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{number + 1}</p>;
                                }
                                // else if (element1.val === 'action') {
                                //     if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                                //         return props.checkIndex(`${element1.val}`) && <div className={`d-flex s-1418 Lato-400 word-wrap ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
                                //     } else {
                                //         return <></>;
                                //     }
                                // } 
                                else if (element1.val === 'authAmount') {
                                    let show = `${CheckUndefinedStringEmpty(item.authCurrCode)} ${NumberWithCommas(CheckUndefined(item.authAmount))}`;
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                }
                                else if (element1.val === 'authFeeAmount') {
                                    let show = `${CheckUndefinedStringEmpty(item.authCurrCode)} ${NumberWithCommas(CheckUndefined(item.authFeeAmount))}`;
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                }
                                else if (element1.val === 'dynamicColumn') {

                                    let show = CheckUndefined(item[`acqSwitchName`]);
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                }
                                else if (element1.val === 'reconStatus') {
                                    let show = '';
                                    if (props.stateFirstParent.whatIsSelectedNow === 'issuerId') {
                                        show = CheckUndefined(item[`issReconStatus`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    else if (props.stateFirstParent.whatIsSelectedNow === 'acquirerId') {
                                        show = CheckUndefined(item[`acqReconStatus`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    else if (props.stateFirstParent.whatIsSelectedNow === 'acquirerSwitchId') {
                                        show = CheckUndefined(item[`acqSwitchReconStatus`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    else if (props.stateFirstParent.whatIsSelectedNow === 'issuerSwitchId') {
                                        show = CheckUndefined(item[`issSwitchReconStatus`]);
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;
                                    }
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}></p>;
                                }
                                // else if (element1.val === 'transType') {
                                //     let dataReturn = '';
                                //     if (props.stateOptions.optionsTransactionTypeDetailCodeAndCcdName.length > 0) {
                                //         props.stateOptions.optionsTransactionTypeDetailCodeAndCcdName.forEach((element2: any) => {
                                //             if (element2.value === item[`${element1.val}`]) {
                                //                 dataReturn = `${element2.label}`;
                                //             }
                                //         });
                                //     }
                                //     return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(dataReturn)}</p>;
                                // }
                                else {
                                    return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                                }
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const ModalDetailSettlementSummaryIssuer = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    //
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateTableLeft, dispatchTableLeft] = useReducer(reducerTableLeft, initialStateTableLeft);
    const [stateTableRight, dispatchTableRight] = useReducer(reducerTableRight, initialStateTableRight);

    const { stateFirstParent, stateParent, stateOptions } = props;
    const { optionsTransactionTypeDetailCodeAndCcdName } = stateOptions

    const initFunctionCustomTableLeft = (needRefreshPage: Boolean | null) => {
        let finalParameterLeftAPI = stateParent.finalParameterLeftAPI !== '' ? `${stateParent.finalParameterLeftAPI}&` : '';
        let selectedTransType = stateParent.selectedTransType !== '' ? `transType=${stateParent.selectedTransType}&` : '';
        // -----------------
        let limitTableLeft = `limit=${stateTableLeft.view}&`;
        let offsetTableLeft = `offset=${parseInt((stateTableLeft.position - stateTableLeft.view).toString())}&`;
        // -----------------
        let search = stateTableLeft.search !== '' ? `search=${stateTableLeft.search}&` : '';
        let selectedReconStatus = stateTableLeft.selectedReconStatus !== '' ? `reconStatus=${stateTableLeft.selectedReconStatus}&` : '';

        let finalParameter1 = `${search}${selectedReconStatus}${finalParameterLeftAPI}${selectedTransType}${limitTableLeft}${offsetTableLeft}`;

        if (needRefreshPage === true) {
            if (finalParameter1 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `${stateFirstParent.whatIsSelectedNow === 'acquirerSwitchId' ? 'settlement/trans_post' :'settlement/member_recon'}?${finalParameter1.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableLeft({ type: 'searchData', value: response.data });
                        dispatchTableLeft({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableLeft({ type: 'totalData', value: response.detail.total });
                        dispatchTableLeft({ type: 'amountSummary', value: response.detail.amountSummary });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }
        } else {
            if (finalParameter1 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `${stateFirstParent.whatIsSelectedNow === 'acquirerSwitchId' ? 'settlement/trans_post' :'settlement/member_recon'}?${finalParameter1.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableLeft({ type: 'data', value: response.data });
                        dispatchTableLeft({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableLeft({ type: 'totalData', value: response.detail.total });
                        dispatchTableLeft({ type: 'amountSummary', value: response.detail.amountSummary });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }

        }
    }

    const initFunctionCustomTableRight = (needRefreshPage: Boolean | null) => {
        let finalParameterRightAPI = stateParent.finalParameterRightAPI !== '' ? `${stateParent.finalParameterRightAPI}&` : '';
        let selectedTransType = stateParent.selectedTransType !== '' ? `transType=${stateParent.selectedTransType}&` : '';
        let limitTableRight = `limit=${stateTableRight.view}&`;
        let offsetTableRight = `offset=${parseInt((stateTableRight.position - stateTableRight.view).toString())}&`;
        let search = stateTableRight.search !== '' ? `search=${stateTableRight.search}&` : '';
        let selectedReconStatus = '';
        if (stateFirstParent.whatIsSelectedNow === 'issuerId') {
            selectedReconStatus = stateTableRight.selectedReconStatus !== '' ? `statusName=iss:${stateTableRight.selectedReconStatus}&` : '';

        }
        else if (stateFirstParent.whatIsSelectedNow === 'acquirerId') {
            selectedReconStatus = stateTableRight.selectedReconStatus !== '' ? `statusName=acq:${stateTableRight.selectedReconStatus}&` : '';

        }
        else if (stateFirstParent.whatIsSelectedNow === 'acquirerSwitchId') {
            selectedReconStatus = stateTableRight.selectedReconStatus !== '' ? `statusName=acqSwitch:${stateTableRight.selectedReconStatus}&` : '';

        }
        else if (stateFirstParent.whatIsSelectedNow === 'issuerSwitchId') {
            selectedReconStatus = stateTableRight.selectedReconStatus !== '' ? `statusName=issSwitch:${stateTableRight.selectedReconStatus}&` : '';
        }
        // -----------------
        let finalParameter2 = `${search}${selectedReconStatus}${finalParameterRightAPI}${selectedTransType}${limitTableRight}${offsetTableRight}`;
        if (needRefreshPage === true) {
            if (finalParameter2 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `settlement/trans_post?${finalParameter2.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableRight({ type: 'searchData', value: response.data });
                        dispatchTableRight({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableRight({ type: 'totalData', value: response.detail.total });
                        dispatchTableRight({ type: 'amountSummary', value: response.detail.amountSummary });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }
        } else {
            if (finalParameter2 !== '' && stateParent.selectedTransType !== '') {
                API.get({
                    bodyCustom: null,
                    pathCustom: `settlement/trans_post?${finalParameter2.slice(0, -1)}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                }).then((response: any) => {
                    try {
                        dispatchTableRight({ type: 'data', value: response.data });
                        dispatchTableRight({ type: 'totalPages', value: response.detail.totalPage });
                        dispatchTableRight({ type: 'totalData', value: response.detail.total });
                        dispatchTableRight({ type: 'amountSummary', value: response.detail.amountSummary });
                    } catch (error) {
                        console.log(error)
                    }
                })
            }


        }
    }

    useEffect(() => {
        // reset page if modal show is false
        if (props.detailModal === false) {
            dispatchTableLeft({ type: 'resetPosition' })
            dispatchTableRight({ type: 'resetPosition' })
        }
    }, [props.detailModal])

    useEffect(() => {
        initFunctionCustomTableLeft(true)
    }, [stateTableLeft.search, stateTableLeft.selectedReconStatus])

    useEffect(() => {
        initFunctionCustomTableRight(true)
    }, [stateTableRight.search, stateTableRight.selectedReconStatus])

    useEffect(() => {
        initFunctionCustomTableLeft(false)
    }, [stateParent.finalParameterLeftAPI, stateParent.selectedTransType, stateTableLeft.position, stateTableLeft.view]);

    useEffect(() => {
        initFunctionCustomTableRight(false);
    }, [stateParent.finalParameterRightAPI, stateParent.selectedTransType, stateTableRight.position, stateTableRight.view]);

    useEffect(() => {
        let currentPage: number = parseInt((stateTableLeft.position / stateTableLeft.view).toString());
        dispatchTableLeft({ type: 'currentPage', value: currentPage });
    }, [stateTableLeft.totalData, stateTableLeft.position, stateTableLeft.view])

    useEffect(() => {
        let currentPage: number = parseInt((stateTableRight.position / stateTableRight.view).toString());
        dispatchTableRight({ type: 'currentPage', value: currentPage });
    }, [stateTableRight.totalData, stateTableRight.position, stateTableRight.view])

    //
    useEffect(() => {
        if (props.detailModal === false) {
            dispatchTableLeft({ type: 'hideAllModals' });
        }
    }, [props.detailModal]);

    //
    useEffect(() => {
        if (props.detailModal === false) {
            dispatchTableRight({ type: 'hideAllModals'});
        }
    }, [props.detailModal]);

    // TableLeft
    // TableRight
    const addDataComponentTableLeft = () => addData(dispatchTableLeft)
    const addDataComponentTableRight = () => addData(dispatchTableRight)
    const checkIndexComponentTableLeft = (nameAction: any) => checkIndex(nameAction, stateTableLeft)
    const checkIndexComponentTableRight = (nameAction: any) => checkIndex(nameAction, stateTableRight)
    const handleStateComponentTableLeft = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatchTableLeft)
    const handleStateComponentTableRight = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatchTableRight)
    const setToggleComponentTableLeft = (nameAction: any) => setToggle(nameAction, dispatchTableLeft, stateTableLeft)
    const setToggleComponentTableRight = (nameAction: any) => setToggle(nameAction, dispatchTableRight, stateTableRight)
    const setModalComponentTableLeft = (nameAction: any) => setModal(nameAction, dispatchTableLeft, stateTableLeft)
    const setModalComponentTableRight = (nameAction: any) => setModal(nameAction, dispatchTableRight, stateTableRight)

    const actionShowModalTableLeft = (idModal: any, item: any) => dispatchTableLeft({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionShowModalTableRight = (idModal: any, item: any) => dispatchTableRight({ type: 'showModal', value: { idModal, showModal: item.showModal, itemModal: item } })
    const actionIndexModalTableLeft = (idModal: any) => dispatchTableLeft({ type: 'changeIndex', value: idModal })
    const actionIndexModalTableRight = (idModal: any) => dispatchTableRight({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItemTableLeft = (updateItemID: any) => initFunctionCustomTableLeft(false)
    const actionAfterUpdateItemTableRight = (updateItemID: any) => initFunctionCustomTableRight(false)

    const tableComponentPageTableLeft = () => {
        return (
            <TableCustomLeft
                codeAccessPrivilegeDelete={''}
                codeAccessPrivilegeEdit={''}
                label={stateTableLeft.label}
                data={stateTableLeft.data}
                position={stateTableLeft.position}
                view={stateTableLeft.view}
                customButtonAction={() => <></>}
                checkIndex={checkIndexComponentTableLeft}
                widthTable={`wd-${stateTableLeft.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                currentPage={stateTableLeft.currentPage}
                //
                stateFirstParent={props.stateFirstParent}
                stateParent={props.stateParent}
                //
                editData={(item: ModelTesting) => actionShowModalTableLeft(item.id, item)}
            />
        )
    }
    const tableComponentPageTableRight = () => {
        return (
            <TableCustomRight
                codeAccessPrivilegeDelete={''}
                codeAccessPrivilegeEdit={''}
                label={stateTableRight.label}
                data={stateTableRight.data}
                position={stateTableRight.position}
                view={stateTableRight.view}
                customButtonAction={() => <></>}
                checkIndex={checkIndexComponentTableRight}
                widthTable={`wd-${stateTableRight.widthTable}-responsive`}
                stateOptions={stateOptions}
                //
                currentPage={stateTableRight.currentPage}
                //
                stateFirstParent={props.stateFirstParent}
                stateParent={props.stateParent}
                //
                editData={(item: ModelTesting) => actionShowModalTableLeft(item.id, item)}
            />
        )
    }
    const filterComponentPageTableLeft = () => {
        return (
            <div className="p-3 gx-5 row container-all-select-custom mb-10">
                <div className="row mb-10">
                    <div className="col-4">
                        <p className="c-54 text-uppercase s-1214 w-100 my-2">RECON STATUS</p>
                        <div className='d-flex justify-content-between'>
                            <Select className='flex-fill' options={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail} value={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateTableLeft.selectedReconStatus)} onChange={(e) => handleState("selectedReconStatus", e.value, dispatchTableLeft)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedReconStatus", '', dispatchTableLeft) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const filterComponentPageTableRight = () => {
        return (
            <div className="p-3 gx-5 row container-all-select-custom mb-10">
                <div className="row mb-10">
                    <div className="col-4">
                        <p className="c-54 text-uppercase s-1214 w-100 my-2">RECON STATUS</p>
                        <div className='d-flex justify-content-between'>
                            <Select className='flex-fill' options={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail} value={stateOptions.optionsReconciliationStatusUseCCDNameAndCodeDetail.filter((option: any) => option.value === stateTableRight.selectedReconStatus)} onChange={(e) => handleState("selectedReconStatus", e.value, dispatchTableRight)} />
                            <div className='d-flex justify-content-center align-items-center px-2 pointer width-10px' onClick={(e) => { handleState("selectedReconStatus", '', dispatchTableRight) }}>
                                {undo_icon}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const modalUpdateComponentPageTableLeft = () => {
        return (
            <>
                {
                    stateTableLeft.savedShowModal.map((element1: any) => {
                        return (
                            <ModalSalesInquirySettlementSummaryIssuer
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={stateTableLeft}
                                urlApi={stateTableLeft.urlApi}
                                showModal={element1.showModal}
                                actionShowModal={() => actionShowModalTableLeft(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModalTableLeft(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItemTableLeft}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }
    const modalUpdateComponentPageTableRight = () => {
        return (
            <>
                {
                    stateTableRight.savedShowModal.map((element1: any) => {
                        return (
                            <ModalSalesInquiry
                                key={element1.idModal}
                                id={element1.idModal}
                                index={element1.index}
                                idModal={element1.idModal}
                                state={stateTableRight}
                                urlApi={stateTableRight.urlApi}
                                showModal={element1.showModal}
                                actionShowModal={() => actionShowModalTableRight(element1.idModal, element1)}
                                actionIndexModal={() => actionIndexModalTableRight(element1.idModal)}
                                actionAfterUpdateItem={actionAfterUpdateItemTableRight}
                                itemModal={element1.itemModal}
                                stateOptions={stateOptions}
                            />
                        )
                    }
                    )
                }
            </>
        )
    }
    let widthWindows_ = window.innerWidth;
    let heightWindows_ = window.innerHeight;


    return (
        <>
            {
            props.detailModal === true &&
                <Modal
                    open={props.detailModal} onCancel={props.setModal}
                    mask={true}
                    maskStyle={{
                        zIndex: 15,
                    }}
                    style={{
                        position: 'fixed',
                        overflow: 'unset',
                        // right: 'auto',
                        // bottom: 'auto',
                        // top: '50%',
                        // left: '50%',
                        // marginRight: '-30%',
                        // transform: 'translate(-50%, -50%)',
                        //
                        right: '2%',
                        bottom: '3%',
                        top: '3%',
                        left: '2%',
                        minWidth: 'min-content',
                        zIndex: 100,
                    }}
                    footer={false}
                    title={state.titleModal}
                >
                    <div className='d-flex flex-column' style={{ overflow: "auto", height: `${heightWindows_ - 150}px ` }}>
                        <Divider />
                        <div className="h-100 d-flex " style={{ width: `${widthWindows_ - 200}px`, overflow: 'auto' }}>

                            <div style={{ overflow: 'auto', flex: '1' }}>
                                {/* <p className='w-700 Lato-700 s-1620 mb-2'>{CheckUndefined(stateParent.dataLeftAPI.memberCcdName)} ({stateFirstParent.titleTableLeft})</p> */}
                                {
                                    stateFirstParent.whatIsSelectedNow === 'issuerId' && (
                                        <p className='w-700 Lato-700 s-1620'>{CheckUndefined(stateFirstParent.selectedIssuerMemberName)} ({stateFirstParent.titleTableLeft})</p>
                                    )
                                }
                                {
                                    stateFirstParent.whatIsSelectedNow === 'issuerSwitchId' && (
                                        <p className='w-700 Lato-700 s-1620'>{CheckUndefined(stateFirstParent.selectedIssuerSwitchMemberName)} ({stateFirstParent.titleTableLeft})</p>
                                    )
                                }
                                {
                                    stateFirstParent.whatIsSelectedNow === 'acquirerId' && (
                                        <p className='w-700 Lato-700 s-1620'>{CheckUndefined(stateFirstParent.selectedAcquirerMemberName)} ({stateFirstParent.titleTableLeft})</p>
                                    )
                                }
                                {
                                    stateFirstParent.whatIsSelectedNow === 'acquirerSwitchId' && (
                                        <p className='w-700 Lato-700 s-1620'>{CheckUndefined(stateFirstParent.selectedAcquirerSwitchMemberName)} ({stateFirstParent.titleTableLeft})</p>
                                    )
                                }
                                <Divider />
                                <TableWithLimitOffset
                                    state={stateTableLeft}
                                    stateOptions={stateOptions}
                                    customButtonAction={() => <></>}
                                    exportToCSVComponent={() => { }}
                                    addDataComponent={addDataComponentTableLeft}
                                    checkIndexComponent={checkIndexComponentTableLeft}
                                    handleStateComponent={handleStateComponentTableLeft}
                                    setToggleComponent={setToggleComponentTableLeft}
                                    setModal={setModalComponentTableLeft}
                                    // component
                                    tableComponentPage={tableComponentPageTableLeft}
                                    filterComponentPage={filterComponentPageTableLeft}
                                    modalAddDeleteComponentPage={undefined}
                                    modalUpdateComponentPage={modalUpdateComponentPageTableLeft}
                                    // 
                                    needComponentHeaderTable={true}
                                    needComponentFilterTable={true}
                                    needComponentTable={true}
                                    needComponentFooterTable={true}
                                    //
                                    needToggleFunction={false}
                                    needAddButton={false}
                                    needSearchFunction={true}
                                    needDownloadButton={true}
                                />
                                <div className="d-flex justify-content-end mt-3" style={{ marginRight: '10px' }} >Amount : {CheckUndefined(stateTableLeft.amountSummary)}</div>
                            </div>
                            <Divider type="vertical" />

                            <div style={{ overflow: 'auto', flex: '1' }}>
                                <p className='w-700 Lato-700 s-1620'>{CheckUndefined(stateFirstParent.selectedAcquirerSwitchMemberName)} ({stateFirstParent.titleTableRight})</p>
                                <Divider />
                                <TableWithLimitOffset
                                    state={stateTableRight}
                                    stateOptions={stateOptions}
                                    customButtonAction={() => <></>}
                                    exportToCSVComponent={() => { }}
                                    addDataComponent={addDataComponentTableRight}
                                    checkIndexComponent={checkIndexComponentTableRight}
                                    handleStateComponent={handleStateComponentTableRight}
                                    setToggleComponent={setToggleComponentTableRight}
                                    setModal={setModalComponentTableRight}
                                    // component
                                    tableComponentPage={tableComponentPageTableRight}
                                    filterComponentPage={filterComponentPageTableRight}
                                    modalAddDeleteComponentPage={undefined}
                                    modalUpdateComponentPage={undefined}
                                    // 
                                    needComponentHeaderTable={true}
                                    needComponentFilterTable={true}
                                    needComponentTable={true}
                                    needComponentFooterTable={true}
                                    //
                                    needToggleFunction={false}
                                    needAddButton={false}
                                    needSearchFunction={true}
                                    needDownloadButton={true}
                                />
                                <div className="d-flex justify-content-end mt-3" style={{ marginRight: '10px' }} >Amount : {CheckUndefined(stateTableRight.amountSummary)}</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }


        </>
    );
}

const initialState = {
    titleModal: 'Transactions List',
}

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'selectedIss':
            return {
                ...state,
                selectedIss: action.value,
            };

        default:
            throw new Error();
    }
}


const initialStateTableLeft = {
    titlePage: ``,
    privilegeAccess: { view: "", add: "", edit: "", delete: "", download: "" },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Settle Date', width: 'wd-150', val: 'settleDate' },
        { field: 'Auth Date', width: 'wd-100', val: 'authDate' },
        { field: 'Auth STAN', width: 'wd-150', val: 'authStan' },
        { field: 'Invoice No', width: 'wd-200', val: 'authInvNo' },
        { field: 'Fee', width: 'wd-100', val: 'authFeeAmount' },
        { field: 'Amount', width: 'wd-150', val: 'authAmount' }, // acqSwitchCurrCode
        { field: 'dynamicColumn', width: 'wd-100', val: 'dynamicColumn' },
        { field: 'Recon Status', width: 'wd-100', val: 'reconStatus' },
    ],

    toggle: ["no", "settleDate", "authDate", "authStan", "authInvNo","authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    complete_toggle: ["no", "settleDate", "authDate", "authStan", "authInvNo","authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    list_column_name: ["No.", "Settle Date", "Auth Date", "Auth STAN", "Invoice No", "Fee", "Amount", "dynamicColumn", "Recon Status"],
    //
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Invoice No, Auth STAN',
    // memberData
    memberName: '',
    memberCurrencyCode: '',
    totalAmount: '',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 25, 50, 75, 100],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1200,
    dataLength: 0, // zIndex
    //

    selectedReconStatus: '',
    amountSummary: '',
};
// acquire recon
const initialStateTableRight = {
    titlePage: ``,
    privilegeAccess: { view: "", add: "", edit: "", delete: "", download: "" },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Settle Date', width: 'wd-150', val: 'settleDate' },
        { field: 'Auth Date', width: 'wd-100', val: 'authDate' },
        { field: 'Auth STAN', width: 'wd-150', val: 'authStan' },
        { field: 'Auth RRN', width: 'wd-150', val: 'authRrn' },
        { field: 'Invoice No', width: 'wd-200', val: 'authInvNo' },
        { field: 'Fee', width: 'wd-100', val: 'authFeeAmount' },
        { field: 'Amount', width: 'wd-150', val: 'authAmount' }, // acqSwitchCurrCode
        { field: 'dynamicColumn', width: 'wd-100', val: 'dynamicColumn' },
        { field: 'Recon Status', width: 'wd-100', val: 'reconStatus' },
    ],

    toggle: ["no", "settleDate", "authDate", "authRrn", "authStan", "authInvNo","authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    complete_toggle: ["no", "settleDate", "authDate", "authRrn", "authStan", "authInvNo","authFeeAmount", "authAmount", "dynamicColumn", "reconStatus"],
    list_column_name: ["No.", "Settle Date", "Auth Date", "Auth RRN", "Auth STAN", "Invoice No","Fee", "Amount", "dynamicColumn", "Recon Status"],
    //
    visibleModalAddDelete: false,
    savedShowModal: [], // new
    SearchByPlaceHolder: 'Invoice No, Auth RRN, Auth STAN',
    // memberData
    memberName: '',
    memberCurrencyCode: '',
    totalAmount: '',
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 25, 50, 75, 100],
    totalData: 0, // new
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 1300,
    dataLength: 0, // zIndex
    //

    selectedReconStatus: '',
    amountSummary: '',

};

function reducerTableLeft(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'amountSummary': {
            return {

                ...state,
                amountSummary: action.value,
            }
        }
        case 'resetPosition':
            return {
                ...state,
                view: 10,
                position: 10,
                totalData: 0, // new
                totalPages: 0,
                currentPage: 0,
                selectedReconStatus: '',
                search: '',
                amountSummary: '',
            };
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.authTransId!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.authTransId!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 120 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 120 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 120 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        showDataResult.push(
                            { idModal: element1.authTransId!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.authTransId!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        //
        case 'hideAllModals':
            return {
                ...state,
                savedShowModal: state.savedShowModal.map((element1: ShowModalInterface) => ({
                    ...element1,
                    showModal: false,
                })),
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        case 'selectedReconStatus':
            return {
                ...state,
                selectedReconStatus: action.value
            };
        default:
            throw new Error();
    }
}

function reducerTableRight(state: any, action: any) {
    let showDataResult: ShowModalInterface[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let newSavedShowModal: ShowModalInterface[] = [];
    switch (action.type) {
        case 'amountSummary': {
            return {
                ...state,
                amountSummary: action.value,
            }
        }
        case 'resetPosition':
            return {
                ...state,
                view: 10,
                position: 10,
                totalData: 0, // new
                totalPages: 0,
                currentPage: 0,
                selectedReconStatus: '',
                search: '',
                amountSummary: '',
            };
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.authInvNo!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        element1.id = element1.authInvNo!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        let conditionShowModalFind = false;
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value.idModal) {
                                conditionShowModalFind = true;
                            }
                        })
                        if (conditionShowModalFind === false) {
                            newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                            newSavedShowModal.push(...state.savedShowModal);
                        } else {
                            state.savedShowModal.forEach((element1: ShowModalInterface) => {
                                if (element1.idModal === action.value.idModal) {
                                    if (element1.showModal === false) { // will to show
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: state.dataLength + 120 })
                                    } else {
                                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(element1.showModal), itemModal: action.value.itemModal, index: 120 })
                                    }
                                } else {
                                    newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                                }
                            })
                        }

                    } else {
                        newSavedShowModal.push({ idModal: action.value.idModal, showModal: !(action.value.showModal), itemModal: action.value.itemModal, index: 120 })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (state.savedShowModal !== undefined) {
                if (state.savedShowModal !== null) {
                    if (state.savedShowModal.length > 0) {
                        state.savedShowModal.forEach((element1: ShowModalInterface) => {
                            if (element1.idModal === action.value) {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 120 })
                            } else {
                                newSavedShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 120 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                savedShowModal: newSavedShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelTesting) => {
                        showDataResult.push(
                            { idModal: element1.authInvNo!, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = element1.authInvNo!;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        // 
        case 'hideAllModals':
            return {
                ...state,
                savedShowModal: state.savedShowModal.map((element1: ShowModalInterface) => ({
                    ...element1,
                    showModal: false,
                })),
            };
        //
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        // new
        case 'totalData':
            return {
                ...state,
                totalData: action.value
            };
        case 'totalPages':
            return {
                ...state,
                totalPages: action.value
            };
        case 'currentPage':
            return {
                ...state,
                currentPage: action.value
            };
        case 'finalAPI':
            return {
                ...state,
                finalAPI: action.value
            };
        case 'selectedReconStatus':
            return {
                ...state,
                selectedReconStatus: action.value
            };
        default:
            throw new Error();
    }

}

export default ModalDetailSettlementSummaryIssuer;
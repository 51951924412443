

// RouteSequence

interface RouteSequenceInterface {
    id: null | number | string;
    rsId: number | null;
    rsReqId: number | null;
    rsResId: number | null;
    rsReqSeqVal: number | null;
    rsResSeqVal: number | null;
    rsName: string | null;
    rsDesc: string | null;
    rsStatus: string | null;
    rsNextRsId: number | null;
}

export class ModelRouteSequence {
    id: null | number | string;
    rsId: number | null;
    rsReqId: number | null;
    rsResId: number | null;
    rsReqSeqVal: number | null;
    rsResSeqVal: number | null;
    rsName: string | null;
    rsDesc: string | null;
    rsStatus: string | null;
    rsNextRsId: number | null;


    constructor(value: RouteSequenceInterface | null) {
        this.id = value?.id ?? null;
        this.rsId = value?.rsId ?? null;
        this.rsReqId = value?.rsReqId ?? null;
        this.rsResId = value?.rsResId ?? null;
        this.rsReqSeqVal = value?.rsReqSeqVal ?? null;
        this.rsResSeqVal = value?.rsResSeqVal ?? null;
        this.rsName = value?.rsName ?? null;
        this.rsDesc = value?.rsDesc ?? null;
        this.rsStatus = value?.rsStatus ?? null;
        this.rsNextRsId = value?.rsNextRsId ?? null;
    }
}
import React, { useEffect, useReducer } from 'react';

import {  Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../../../Components';
import { API } from '../../../../Services';

import {inputStyle, inputStyleH25, labelStyle } from '../../../../Styles';
import { CheckAllFields } from '../../../../Utils';
import { Page51Title } from '../../../../State/Menu/TitleMenu';
import { urlSelection } from '../../../../Services/API';


export const ModalDraggableHmacManagement = ({ ...props }) => {
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsMember, optionsCommonStatusDetailCode, optionsHashType, optionsEnvironmentType } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["hmacIncKeyVal", "hmacOutKeyValue"]
        let limit = [50, 50]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const { memberId, hmacIncKeyVal, hmacOutKeyValue, hmacHashType, hmacEnvType, hmacStatus, memberCode, memberName } = statePage;
        const data = {
            hmacId: id,
            memberId: memberId,
            hmacIncKeyVal: hmacIncKeyVal,
            hmacOutKeyValue: hmacOutKeyValue,
            hmacHashType: hmacHashType,
            hmacEnvType: hmacEnvType,
            hmacStatus: hmacStatus,
        };
        const a = CheckAllFields([memberId, hmacIncKeyVal, hmacOutKeyValue, hmacHashType, hmacEnvType, hmacStatus], ['Member Id', 'Incoming Key Value', 'Outgoing Key Value', 'Hash Type', 'Environment Type', 'Status']);

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={600}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                        marginBottom: '-0.1rem'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                    <hr className='mb-0'/>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row pb-2 gy-2 gx-3 mx-0 mb-3 mt-0'>
                    <div className='col-12'>
                        <p className={labelStyle}>member ID <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>INCOMING KEY VALUE <span className='text-danger'>*</span></p>
                        <input value={statePage.hmacIncKeyVal} onChange={(e) => handleState('hmacIncKeyVal', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>OUTGOING KEY VALUE <span className='text-danger'>*</span></p>
                        <input value={statePage.hmacOutKeyValue} onChange={(e) => handleState('hmacOutKeyValue', e.target.value)} className={inputStyle} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>HASH TYPE <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25}  placeholder={'(Search)'} options={optionsHashType} value={optionsHashType.filter((option: any) => option.value === statePage.hmacHashType)} onChange={(e) => handleState('hmacHashType', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>ENVIRONMENT TYPE <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25}  placeholder={'(Search)'} options={optionsEnvironmentType} value={optionsEnvironmentType.filter((option: any) => option.value === statePage.hmacEnvType)} onChange={(e) => handleState('hmacEnvType', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25}  placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.hmacStatus)} onChange={(e) => handleState('hmacStatus', e.value)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page51Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    hmacId: '',
    memberId: '',
    hmacIncKeyVal: '',
    hmacOutKeyValue: '',
    hmacHashType: '',
    hmacEnvType: '',
    hmacStatus: '001',
    memberCode: '',
    memberName: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                hmacId: '',
                memberId: '',
                hmacIncKeyVal: '',
                hmacOutKeyValue: '',
                hmacHashType: '',
                hmacEnvType: '',
                hmacStatus: '001',
                memberCode: '',
                memberName: '',
                //
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                hmacId: action.value.hmacId,
                memberId: action.value.memberId,
                hmacIncKeyVal: action.value.hmacIncKeyVal,
                hmacOutKeyValue: action.value.hmacOutKeyValue,
                hmacHashType: action.value.hmacHashType,
                hmacEnvType: action.value.hmacEnvType,
                hmacStatus: action.value.hmacStatus,
                memberCode: action.value.memberCode,
                memberName: action.value.memberName,
                //
                errorMessage: "",
            };
        // 
        case 'hmacId':
            return {
                ...state,
                hmacId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'hmacIncKeyVal':
            return {
                ...state,
                hmacIncKeyVal: action.value,
            };
        case 'hmacOutKeyValue':
            return {
                ...state,
                hmacOutKeyValue: action.value,
            };
        case 'hmacHashType':
            return {
                ...state,
                hmacHashType: action.value,
            };
        case 'hmacEnvType':
            return {
                ...state,
                hmacEnvType: action.value,
            };
        case 'hmacStatus':
            return {
                ...state,
                hmacStatus: action.value,
            };
        case 'memberCode':
            return {
                ...state,
                memberCode: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        default:
            throw new Error();
    }
}





import { useEffect, useReducer, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'react-bootstrap';
import { Chart } from 'react-chartjs-2'
import {
    Title,
    Legend,
    Tooltip,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Chart as ChartJS,
} from 'chart.js/auto'

import { hour } from "../../../Utils";
import { QRAuthMonitoringDispatch } from "../../../Store/Store";
import { changeStateQrAuthMonitoring, stateGlobalQrAuthMonitoring, changeStartDateAction } from "../../../Store/QRAuthMonitoringSlice";

ChartJS.register(
    Title,
    Legend,
    Tooltip,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
)

export const LineChartComponent = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const dispatchQRAuthMonitoring = QRAuthMonitoringDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { stateParent, handleStateParent, initFunctionCustom } = props


    const globalState = useSelector(stateGlobalQrAuthMonitoring);
    const { dataFilter, dataCopyFilter, dataCopyByDate, dataYesterday, dataYesterdayCopyByDate, filterStartDate, filterEndDate, selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedQRStandardId, selectedQRStandardName, selectedQRStandardSendToApi, search, optionsProductFeature, optionsQRStandard, listMember, listReasonCode, rangeDate } = globalState;


    useEffect(() => {
        console.log(dataYesterdayCopyByDate)

        const dateStart = new Date(filterStartDate);
        const dateEnd = new Date(filterEndDate);
        let listAllDayChart = [];
        for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
            listAllDayChart.push(new Date(d).toISOString().slice(0, 10));
        }
        if (listAllDayChart.length === 1) {
            let dateYesterday = new Date(filterStartDate);
            dateYesterday.setDate(dateYesterday.getDate() - 1);
            let dayYesterday = dateYesterday.toISOString().slice(0, 10);
            let resultDataToday = dataTodayChart(dataCopyByDate, filterStartDate);
            let resultDataYesterday = dataTodayChart(dataYesterdayCopyByDate, dayYesterday);
            dispatch({ type: 'setAllData', value: { resultDataToday: resultDataToday, resultDataYesterday: resultDataYesterday, resultRangeDate: 1 } });
        } else {
            let resultDataToday = dataOneWeek(dataCopyByDate, filterStartDate, filterEndDate);
            let dateStartBefore = new Date(filterStartDate);
            let dateEndBefore = new Date(filterEndDate);
            dateStartBefore.setDate(dateStartBefore.getDate() - listAllDayChart.length);
            dateEndBefore.setDate(dateEndBefore.getDate() - listAllDayChart.length);
            let resultDataYesterday = dataOneWeek(dataYesterdayCopyByDate, dateStartBefore, dateEndBefore);
            dispatch({ type: 'setAllData', value: { resultDataToday: resultDataToday, resultDataYesterday: resultDataYesterday, resultRangeDate: listAllDayChart.length } });
        }
    }, [dataFilter, dataCopyByDate, dataYesterdayCopyByDate, selectedQRStandardSendToApi, selectedProductFeatureSendToApi])

    // range data is 1
    const dataTodayChart = (data: any, filterStartDate: any) => {
        let dateStart = new Date(filterStartDate);
        let dayUsed = dateStart.toISOString().slice(0, 10);
        let dataByDateNow: any[] = [];
        let dataCopy = [...data];
        dataCopy.forEach((element1) => {
            let spliteDate = element1.reqDate.split(' '); // [2022-07-01 ][08:35:00]
            if (spliteDate[0] === dayUsed) {
                // 2022-07-01 === 2022-07-01 // today
                dataByDateNow.push(element1); // mengumpulkan semua data di tanggal yang di pilih
            }
        });
        let labelsCustomNew1: any[] = [];
        hour.forEach((element1: any) => {
            labelsCustomNew1.push({ id: element1, data: [] }); // membuat list data dengan jam kosong
        });
        let dataResultSplitTime = [];
        dataByDateNow.forEach((element1) => {
            let spliteDate = element1.reqDate.split(' '); //  [2022-07-01 ][08:35:00]
            let spliteTime = spliteDate[1].split(':'); // [08] [35] [00]
            let dataTemp = [...labelsCustomNew1];
            dataResultSplitTime = dataTemp.map((element3) => {
                if (spliteTime[0] === element3.id.split(':')[0]) {
                    // clock 08 === 08
                    element3.data.push(element1); // mengumpulkan data berdasarkan jamnya
                }
                return element3;
            });
        });
        if (dataResultSplitTime.length === 0) {
            dataResultSplitTime = labelsCustomNew1;
        }
        return dataResultSplitTime;
    };
    const dataOneWeek = (data: any, filterStartDate: any, filterEndDate: any) => {
        let dateStart = new Date(filterStartDate);
        let dateEnd = new Date(filterEndDate);
        let dataCopy = [...data];

        let listAllDayChart = [];
        for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
            listAllDayChart.push(new Date(d).toISOString().slice(0, 10));
        }
        let dataNew: any[] = [];
        let labelsCustomNew1: any[] = [];
        listAllDayChart.forEach((element1, index) => {
            dataCopy.forEach((element2) => {
                let spliteDate = element2.reqDate.split(' ');
                if (element1 === spliteDate[0]) {
                    // mengumpulkan semua data menjadi satu
                    dataNew.push(element2);
                }
            });
            labelsCustomNew1.push({ id: `${element1}`, data: [] });
        });

        let dataResultSplitDay = []; // mengelompokkan data berdasarkan tanggal
        dataNew.forEach((element1) => {
            let spliteDate = element1.reqDate.split(' '); //  [2022-07-01 ][08:35:00]
            let dataTemp = [...labelsCustomNew1];
            dataResultSplitDay = dataTemp.map((element3) => {
                if (spliteDate[0] === element3.id) {
                    // date 2022-07-01 === 2022-07-01
                    element3.data.push(element1);
                }
                return element3;
            });
        });

        if (dataResultSplitDay.length === 0) {
            dataResultSplitDay = labelsCustomNew1;
        }
        // this.setState({ rangeDate: listAllDayChart.length });
        return dataResultSplitDay;
    };

    const handleChangeDate = (dayUsed: string) => {
        return new Promise(async (resolve, reject) => {
            await dispatchQRAuthMonitoring(changeStartDateAction(dayUsed))
            resolve(dayUsed);
        })
    }

    const handleButtonChart = async (name: any) => {
        let dateEnd = new Date(filterEndDate);
        if (name === 'day') {
            let dayUsed = dateEnd.toISOString().slice(0, 10);
            handleStateParent('filterStartDate', dayUsed);
            await handleChangeDate(dayUsed)
        } else if (name === 'week') {
            dateEnd.setDate(dateEnd.getDate() - 6);
            let dayUsed = dateEnd.toISOString().slice(0, 10);
            handleStateParent('filterStartDate', dayUsed);
            await handleChangeDate(dayUsed);
        } else if (name === 'month') {
            dateEnd.setDate(dateEnd.getDate() - 29);
            let dayUsed = dateEnd.toISOString().slice(0, 10);
            handleStateParent('filterStartDate', dayUsed);
            await handleChangeDate(dayUsed);
        }

    };

    const handleState = (nameState: string | any, valueState: any) => {
        dispatch({ type: nameState, value: valueState })
    }

    useEffect(() => {
        const { dataToday, dataYesterday, rangeDate, timePeriodComparison } = state;
        let convertDataChartNow: any[] = []
        dataToday.forEach((element: any) => {
            let amount: number = 0;

            if (element?.data.length > 0) {
                element.data.forEach((element2: any) => {
                    amount = parseInt(amount.toString()) + parseInt(element2.reqCount);
                });
            }
            convertDataChartNow.push(amount)
        });
        let convertDataChartYesterday: any[] = []
        dataYesterday.forEach((element: any) => {
            let amount = 0;
            if (element?.data?.length > 0) {
                element.data.forEach((element2: any) => {
                    amount = parseInt(amount.toString()) + parseInt(element2.reqCount);
                });
            }
            convertDataChartYesterday.push(amount)
        });
        let startToday = '';
        let EndToday = '';
        let startYesterday = '';
        let EndYesterday = '';
        let displayToday = '';
        let displayYesterday = '';
        if (rangeDate === 1) {
            let dateToday = new Date();
            displayToday = `${dateToday.toISOString().slice(0, 10)}`;
            dateToday.setDate(dateToday.getDate() - 1);
            let dateYesterday = dateToday.toISOString().slice(0, 10);
            displayYesterday = `${dateYesterday}`;
        } else {
            startToday = dataToday.length > 0 ? (dataToday[0] !== undefined || dataToday[0] !== null ? `${dataToday[0].id}` : '') : '';
            EndToday = dataToday.length > 0 ? (dataToday[dataToday.length - 1] !== undefined || dataToday[dataToday.length - 1] !== null ? `${dataToday[dataToday.length - 1].id}` : '') : '';
            startYesterday = dataYesterday.length > 0 ? (dataYesterday[0] !== undefined || dataYesterday[0] !== null ? `${dataYesterday[0].id}` : '') : '';
            EndYesterday = dataYesterday.length > 0 ? (dataYesterday[dataYesterday.length - 1] !== undefined || dataYesterday[dataYesterday.length - 1] !== null ? `${dataYesterday[dataYesterday.length - 1].id}` : '') : '';
            displayToday = `${startToday} to ${EndToday}`;
            displayYesterday = `${startYesterday} to ${EndYesterday}`;
        }

        handleState("convertDataChartNow", convertDataChartNow)
        handleState("convertDataChartYesterday", convertDataChartYesterday)
        handleState("displayToday", displayToday)
        handleState("displayYesterday", displayYesterday)

    }, [state.dataToday, state.dataYesterday, state.rangeDate, dataFilter, dataCopyByDate, dataYesterdayCopyByDate, selectedQRStandardSendToApi, selectedProductFeatureSendToApi])

    useEffect(() => {
        let countedQRAuthTotal: number = 0;
        if (stateParent.qrAuthDivided.length > 0) {
            let dataQRAuthByAvailableMemberRequest: any[] = [];
            stateParent.qrAuthDivided.forEach((dataQRDivided: any) => {
                // countedQRAuthTotal = countedQRAuthTotal + parseInt(dataByDate.reqCount);
                if (dataQRDivided.dataListQRAuthDevided.length > 0) {
                    dataQRAuthByAvailableMemberRequest.push(dataQRDivided);
                }
            })

            if (dataQRAuthByAvailableMemberRequest.length > 0) {
                dataQRAuthByAvailableMemberRequest.forEach((dataQREachMember: any) => {
                    dataQREachMember.dataListQRAuthDevided.forEach((dataQRAuth: any) => {
                        countedQRAuthTotal = countedQRAuthTotal + parseInt(dataQRAuth.qrReqTotalCount);
                    })
                })
            }
        }

        handleState("countedQRAuthTotal", countedQRAuthTotal);
    }, [stateParent.qrAuthDivided, rangeDate])

    return (
        <div className='bg-ff bd-ea br-10 p-2 py-4 my-5'>
            <div className='row px-3'>
                <div className='col-4 d-flex align-items-center' style={{ height: '30px' }}>
                    <h1 className='Lato-300 normal width-600px s-2024 px-3' style={{ lineHeight: '30px' }}>
                        Chart
                    </h1>
                </div>
                <div className='col-4 text-center d-flex justify-content-center align-items-center' style={{ height: '30px' }}>
                    <button className={'btn d-flex align-items-center px-3 py-2 br-10 bd-d4 mx-2 ' + (state.rangeDate === 1 ? 'hover-button-red' : 'hover-button-white')} onClick={state.rangeDate === 1 ? () => { } : () => handleButtonChart('day')}>
                        <p className='s-1418 Lato-600'>Day</p>
                    </button>
                    <button className={'btn d-flex align-items-center px-3 py-2 br-10 bd-d4 mx-2 ' + (state.rangeDate >= 2 && state.rangeDate <= 8 ? 'hover-button-red' : 'hover-button-white')} onClick={state.rangeDate >= 2 && state.rangeDate <= 8 ? () => { } : () => handleButtonChart('week')}>
                        <span className='s-1418 Lato-600'>Week</span>
                    </button>
                    <button className={'btn d-flex align-items-center px-3 py-2 br-10 bd-d4 mx-2 ' + (state.rangeDate > 8 ? 'hover-button-red' : 'hover-button-white')} onClick={state.rangeDate > 8 ? () => { } : () => handleButtonChart('month')}>
                        <span className='s-1418 Lato-600'>Month</span>
                    </button>
                </div>
                <div className='col-4 d-flex align-items-center justify-content-end Lato-400 text-secondary'>
                    <div className='d-flex align-items-center justify-content-center' style={{ height: '30px' }}>
                        Time Period Comparison
                    </div>
                    <div className='d-flex align-items-center justify-content-center' style={{ height: '30px' }}>
                        {state.timePeriodComparison === true ? (
                            <Form className='mx-3 d-flex align-items-center'>
                                <Form.Check type='switch' id='custom-switch' checked value={state.timePeriodComparison} onChange={(e) => handleState('timePeriodComparison', e.target.checked)} />
                            </Form>
                        ) : (
                            <Form className='mx-3 d-flex align-items-center'>
                                <Form.Check type='switch' id='custom-switch' value={state.timePeriodComparison} onChange={(e) => handleState('timePeriodComparison', e.target.checked)} />
                            </Form>
                        )}
                    </div>
                </div>
            </div>
            <div className='row px-3'>
                <div className='col-4 d-flex' style={{ height: '30px' }}>
                    <p className='normal width-600px px-3' style={{ lineHeight: '30px' }}>
                        Total : {state.countedQRAuthTotal}
                    </p>
                </div>
            </div>
            <div className=''>
                {

                    <Chart
                        type='line'
                        options={{
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: true,
                                },
                                subtitle: {
                                    display: true,
                                },
                                decimation: {
                                    enabled: false,
                                },
                                tooltip: {
                                    // displayColors:false,
                                    callbacks: {
                                        title: function (this: any, tooltipItems: any): string | void | string[] {
                                            return '';
                                        },
                                        label: function (this: any, tooltipItem: any): string | void | string[] {

                                            if (this.labelColors[0].borderColor === '#FF5D5D') {
                                                // dataNow
                                                let selectedDate = this.dataPoints[0].label.split('|')[0] === null || this.dataPoints[0].label.split('|')[0] === undefined ? '' : this.dataPoints[0].label.split('|')[0];
                                                return `(${this.dataPoints[0].formattedValue}) ${selectedDate} `;
                                            } else if (this.labelColors[0].borderColor === '#1363DF') {
                                                // dataYesterday
                                                let selectedDate = this.dataPoints[0].label.split('|')[1] === null || this.dataPoints[0].label.split('|')[1] === undefined ? '' : this.dataPoints[0].label.split('|')[1];
                                                return `(${this.dataPoints[0].formattedValue}) ${selectedDate} `;
                                            }
                                            return this.formattedValue;
                                        },
                                        // afterLabel: function(tooltipItem, data) {
                                        // var dataset = data['datasets'][0];
                                        // var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset['_meta'][0]['total']) * 100)
                                        // return '(' + percent + '%)';
                                        // return tooltipItem;
                                        // }
                                    },
                                },
                            },
                            // scales: {
                            //     x: {
                            //         ticks: {
                            //             // Include a dollar sign in the ticks
                            //             callback: function (value, index, ticks) {
                            //                 let displayToday = dataToday[index];
                            //                 return `${displayToday === undefined || displayToday === null ? '' : displayToday.id}`;
                            //             },
                            //         },
                            //     },
                            // },
                        }}
                        data={{
                            labels:
                                state.dataToday.length === 0
                                    ?
                                    state.dataYesterday.length === 0
                                        ?
                                        hour.map((element1) => {
                                            return element1;
                                        })
                                        :
                                        state.dataYesterday.map((element: any) => {
                                            return element.id;
                                        })
                                    :
                                    state.dataToday.map((element: any, index: any) => {
                                        let today = '';
                                        let yesterday = '';
                                        if (state.rangeDate === 1) {
                                            today = 'Today';
                                            yesterday = 'Yesterday';
                                        } else if (state.rangeDate >= 2 && state.rangeDate <= 8) {
                                            today = 'This Week';
                                            yesterday = 'Last Week';
                                        } else if (state.rangeDate > 8) {
                                            today = 'This Month';
                                            yesterday = 'Last Month';
                                        }
                                        return `${state.dataToday[index].id}|${state.dataYesterday[index].id}`;
                                    }),
                            datasets:
                                state.timePeriodComparison === true ?
                                    [
                                        {
                                            label: 'Now',
                                            data: state.convertDataChartNow,
                                            fill: true,
                                            backgroundColor: 'rgba(255, 230, 230, 0.5)',
                                            borderColor: '#FF5D5D',
                                        },
                                        {
                                            label: 'Before',
                                            data: state.convertDataChartYesterday,
                                            fill: true,
                                            backgroundColor: 'rgb(205, 240, 234, 0.5)',
                                            borderColor: '#1363DF',
                                        },

                                    ]
                                    :
                                    [
                                        {
                                            // label: '',
                                            data: state.convertDataChartNow,
                                            fill: true,
                                            backgroundColor: 'rgba(255, 230, 230, 0.5)',
                                            borderColor: '#FF5D5D',
                                            // tension: 0.1,
                                        },
                                    ]
                            ,
                        }}
                        style={{ maxHeight: '350px', minHeight: '350px' }}
                    />

                }
            </div>

            {
                state.timePeriodComparison === false ?
                    (
                        <div className='d-flex justify-content-center align-items-center my-2'>
                            <div>
                                <div className='d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10 mx-2'>
                                    <div
                                        // key={element1.id}
                                        style={{
                                            // backgroundColor: element1.color,
                                            backgroundColor: '#FF5D5D',
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '7px',
                                            display: 'inline-block',
                                        }}
                                        className='mx-2'
                                    ></div>
                                    {state.displayToday}
                                </div>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div className='d-flex justify-content-center align-items-center my-2'>
                            <div>
                                <div className='d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10 mx-2'>
                                    <div
                                        // key={element1.id}
                                        style={{
                                            // backgroundColor: element1.color,
                                            backgroundColor: '#1363DF',
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '7px',
                                            display: 'inline-block',
                                        }}
                                        className='mx-2'
                                    ></div>
                                    <div>{state.displayYesterday}</div>
                                </div>
                            </div>
                            <div>
                                <div className='d-flex justify-content-center align-items-center bd-ea px-2 py-2 br-10 mx-2'>
                                    <div
                                        // key={element1.id}
                                        style={{
                                            // backgroundColor: element1.color,
                                            backgroundColor: '#FF5D5D',
                                            width: '15px',
                                            height: '15px',
                                            borderRadius: '7px',
                                            display: 'inline-block',
                                        }}
                                        className='mx-2'
                                    ></div>
                                    {state.displayToday}
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

const initialState = {
    dataToday: [],
    dataYesterday: [],
    rangeDate: 1, // for disable button
    timePeriodComparison: true,
    // new
    countedQRAuthTotal: 0,
    convertDataChartNow: [],
    convertDataChartYesterday: [],
    displayToday: '',
    displayYesterday: '',
};
function reducer(state: any, action: any) {
    switch (action.type) {
        case 'dataToday':
            return {
                ...state,
                dataToday: action.value
            };
        case 'dataYesterday':
            return {
                ...state,
                dataYesterday: action.value
            };
        case 'rangeDate':
            return {
                ...state,
                rangeDate: action.value
            };
        case 'timePeriodComparison':
            return {
                ...state,
                timePeriodComparison: action.value
            };
        case 'setAllData':
            return {
                ...state,
                dataToday: action.value.resultDataToday,
                dataYesterday: action.value.resultDataYesterday,
                rangeDate: action.value.resultRangeDate,
            };
        case 'countedQRAuthTotal':
            return {
                ...state,
                countedQRAuthTotal: action.value
            };

        case 'convertDataChartNow':
            return {
                ...state,
                convertDataChartNow: action.value
            };

        case 'convertDataChartYesterday':
            return {
                ...state,
                convertDataChartYesterday: action.value
            };

        case 'displayToday':
            return {
                ...state,
                displayToday: action.value
            };

        case 'displayYesterday':
            return {
                ...state,
                displayYesterday: action.value
            };

        default:
            throw new Error();
    }
}
import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAllFields, HasWhiteSpace } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page49Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableInstitutionMonitoringStatus = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsMember, optionsServerStatus, optionsConnectionStatus, optionsServerStatusDetailCode, optionsConnectionStatusDetailCode } = props.stateOptions

    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'memberConnIntervalTime') {
            if (valueState === '') dispatchStatePage({ type: nameState, value: valueState })
            else if (parseInt(valueState) > 0 && parseInt(valueState) < 100) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            let names = ["memberConnServer", "memberConnPort", "memberConnEndpoint"]
            let limit = [50, 10, 255]
            let index = names.indexOf(nameState)
            if (index !== -1) {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }
    
    const updateDataAPI = () => {
        const { memberId, memberConnServer, memberConnPort, memberConnServerStatus, memberConnStatus, memberConnEndpoint, memberConnIntervalTime } = statePage
        const data = {
            memberConnId: id,
            memberId: memberId,
            memberConnServer: memberConnServer,
            memberConnPort: memberConnPort,
            memberConnServerStatus: memberConnServerStatus,
            memberConnStatus: memberConnStatus,
            memberConnEndpoint: memberConnEndpoint,
            memberConnIntervalTime: memberConnIntervalTime,
        };

        const a = CheckAllFields([memberId, memberConnServer, memberConnPort, memberConnServerStatus, memberConnStatus, memberConnEndpoint, memberConnIntervalTime], ['MEMBER ID', 'IP ADDRESS/HOST', 'PORT', 'SERVER STATUS', 'CONNECTION STATUS', 'END POINT', 'INTERVAL TIME']);


        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,

                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={600}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                top: '8vw',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                    <hr className='c-7f7'/>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column'>
                <div className='row pb-2 mx-0 mb-3'>
                    <div className='col-6 mb-2'>
                        <p className={labelStyle}>member ID <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                    </div>
                    <div className='col-12 mb-2'>
                        <p className={labelStyle}>ip address/host <span className='text-danger'>*</span></p>
                        <input value={statePage.memberConnServer} onChange={(e) => handleState('memberConnServer', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-12 mb-2'>
                        <p className={labelStyle}>port <span className='text-danger'>*</span></p>
                        <input value={statePage.memberConnPort} onChange={(e) => handleState('memberConnPort', e.target.value)} className={inputStyle} />
                    </div>

                    <div className='col-12 mb-2'>
                        <p className={labelStyle}>End point <span className='text-danger'>*</span></p>
                        <input value={statePage.memberConnEndpoint} onChange={(e) => handleState('memberConnEndpoint', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6 mb-2'>
                        <p className={labelStyle}>Server STATUS <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsServerStatusDetailCode} value={optionsServerStatusDetailCode.filter((option: any) => option.value === statePage.memberConnServerStatus)} onChange={(e) => handleState('memberConnServerStatus', e.value)} />
                    </div>
                    <div className='col-6 mb-2'>
                        <p className={labelStyle}>Connection STATUS <span className='text-danger'>*</span></p>
                        <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsConnectionStatusDetailCode} value={optionsConnectionStatusDetailCode.filter((option: any) => option.value === statePage.memberConnStatus)} onChange={(e) => handleState('memberConnStatus', e.value)} />
                    </div>
                    <div className='col-12'>
                        <p className={labelStyle}>INTERVAL TIME <span className='text-danger'>*</span></p>
                        <input type='number' value={statePage.memberConnIntervalTime} onChange={(e) => handleState('memberConnIntervalTime', e.target.value)} className={inputStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: `Edit ${Page49Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    memberConnId: '',
    memberId: '',
    memberConnServer: '',
    memberConnPort: '',
    memberConnEndpoint: '',
    memberConnServerStatus: '000',
    memberConnIntervalTime: '',
    memberConnStatus: '001',
    memberConnLastEchoDt: '',
    memberConnLastTransDt: '',
    memberName: '',
    memberCode: '',
    cdtStart: '',
    cdtStop: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                memberConnId: '',
                memberId: '',
                memberConnServer: '',
                memberConnPort: '',
                memberConnEndpoint: '',
                memberConnServerStatus: '000',
                memberConnIntervalTime: '',
                memberConnStatus: '001',
                memberConnLastEchoDt: '',
                memberConnLastTransDt: '',
                memberName: '',
                memberCode: '',
                cdtStart: '',
                cdtStop: '',
                //
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                memberConnId: action.value.memberConnId,
                memberId: action.value.memberId,
                memberConnServer: action.value.memberConnServer,
                memberConnPort: action.value.memberConnPort,
                memberConnEndpoint: action.value.memberConnEndpoint,
                memberConnServerStatus: action.value.memberConnServerStatus,
                memberConnIntervalTime: action.value.memberConnIntervalTime,
                memberConnStatus: action.value.memberConnStatus,
                memberConnLastEchoDt: action.value.memberConnLastEchoDt,
                memberConnLastTransDt: action.value.memberConnLastTransDt,
                memberName: action.value.memberName,
                memberCode: action.value.memberCode,
                cdtStart: action.value.cdtStart,
                cdtStop: action.value.cdtStop,
                //
                errorMessage: "",
            };
        // 
        case 'memberConnId':
            return {
                ...state,
                memberConnId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'memberConnServer':
            return {
                ...state,
                memberConnServer: action.value,
            };
        case 'memberConnPort':
            return {
                ...state,
                memberConnPort: action.value,
            };
        case 'memberConnEndpoint':
            return {
                ...state,
                memberConnEndpoint: action.value,
            };
        case 'memberConnServerStatus':
            return {
                ...state,
                memberConnServerStatus: action.value,
            };
        case 'memberConnIntervalTime':
            return {
                ...state,
                memberConnIntervalTime: action.value,
            };
        case 'memberConnStatus':
            return {
                ...state,
                memberConnStatus: action.value,
            };
        case 'memberConnLastEchoDt':
            return {
                ...state,
                memberConnLastEchoDt: action.value,
            };
        case 'memberConnLastTransDt':
            return {
                ...state,
                memberConnLastTransDt: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        case 'memberCode':
            return {
                ...state,
                memberCode: action.value,
            };
        case 'cdtStart':
            return {
                ...state,
                cdtStart: action.value,
            };
        case 'cdtStop':
            return {
                ...state,
                cdtStop: action.value,
            };
        default:
            throw new Error();
    }
}





import { useCallback, useMemo, useState } from 'react';
import ReactFlow, {
    Background,
    BackgroundVariant,
    Controls,
    Handle,
    MarkerType,
    NodeToolbar,
    Position,
    addEdge,
    getBezierPath,
    getSmoothStepPath,
    useEdgesState,
    useNodesState
} from 'reactflow';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import 'reactflow/dist/style.css';
import './index.css';
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';
import { COLORS_NODE_JOB } from '../../Utils';
import { Tooltip } from 'antd';



const ModalStyle =
{
    content: {
        overflow: 'unset',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
}

const defaultNodeStyle = {
    borderRadius: 20,
    fontSize: 14,
    fontWeight: 'bold',
};

const foreignObjectSize = 40;
// const initialNodes = [
//     {
//         id: '1',
//         type: 'custom',
//         data: { label: 'Node 1', toolbarPosition: Position.Top },
//         position: { x: 200, y: 0 },
//         style: defaultNodeStyle,
//     },
//     {
//         id: '2',
//         type: 'custom',
//         data: { label: 'Node 2', toolbarPosition: Position.Top },
//         position: { x: -50, y: 100 },
//         style: defaultNodeStyle,
//     },
//     {
//         id: '3',
//         type: 'custom',
//         data: { label: 'Node 3', toolbarPosition: Position.Top },
//         position: { x: 0, y: 200 },
//         style: defaultNodeStyle,
//     },
//     {
//         id: '4',
//         type: 'custom',
//         data: { label: 'Node 4', toolbarPosition: Position.Top },
//         position: { x: 200, y: 300 },
//         style: defaultNodeStyle,
//     },
//     {
//         id: '5',
//         type: 'custom', // tooltip
//         data: { label: 'Node 5', toolbarPosition: Position.Top },
//         position: { x: 100, y: 400 },
//         style: defaultNodeStyle,
//     },
//     {
//         id: '6',
//         type: 'custom',
//         data: { label: 'Node 6', toolbarPosition: Position.Top, toolbarVisible: true },
//         position: { x: 0, y: -100 },
//         style: defaultNodeStyle,
//     },
// ];

// const initialEdges = [
// ];






const CustomNode = ({ ...props }) => {
    const { id, data, xPos, yPos, status } = props.value;
    const { removeNode, handleStateParent } = props;
    // console.log(props);
    return (
        <div onClick={() => {
            handleStateParent('selectedJobId', id)
        }}

        >
            <NodeToolbar isVisible={data.toolbarVisible} position={data.toolbarPosition} >
                <Tooltip title='remove node'>
                    <div className="edgebutton" onClick={(event) => removeNode(id)}>
                        x
                    </div>
                </Tooltip>
            </NodeToolbar>
            <div
                style={{ padding: '10px 20px', }}
                onDoubleClick={() => {
                    handleStateParent('modalUpdateBatch', true)
                    handleStateParent('selectedBatchJobIdUpdate', id)
                }}><span >{data.label}</span> </div>
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
        </div>
    );
};

const TooltipNode = ({ ...props }) => {
    const { id, data } = props.value;
    const { removeNode } = props;
    const [isVisible, setVisible] = useState(false);

    return (
        <div onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} >
            <NodeToolbar isVisible={isVisible} position={data.toolbarPosition}>
                <div>This is a tooltip</div>
            </NodeToolbar>
            <div style={{ padding: 20 }}>{data.label}</div>
            <Handle type="target" position={Position.Left} />
            <Handle type="source" position={Position.Right} />
        </div>
    );
};

function CustomEdge({ ...props }) {
    // console.log(props)
    const {
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        style = {},
        markerEnd,
    } = props.value;
    const { removeEdge, edges } = props;
    const [edgePath, labelX, labelY] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    let position: any = 0;


    if (edges.length > 0) {
        edges.forEach((element1: any, index: any) => {
            // console.log(element1);
            if (element1.id === id) {
                position = index
            }
        })
        return (
            <>
                <path
                    id={id}
                    style={style}
                    className="react-flow__edge-path"
                    d={edgePath}
                    markerEnd={markerEnd}
                />
                <foreignObject
                    width={foreignObjectSize}
                    height={foreignObjectSize}
                    x={labelX - foreignObjectSize / 2}
                    y={labelY - foreignObjectSize / 2}
                    className="edgebutton-foreignobject"
                    requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                    <div className='d-flex justify-content-center align-items-center w-100 p-0' style={{
                        height: '40px'
                    }}>
                        <Tooltip title='remove edge'>
                            <div className="btn edgebutton p-0" onClick={(event) => removeEdge(event, id)}>
                                x
                            </div>
                        </Tooltip>
                    </div>
                </foreignObject>
            </>
        );
    } else {
        return <></>
    }


}





const EdgeWithButtonFlow = forwardRef((props: any, ref: any) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const { handleStateParent, stateParent, setModal } = props;

    useEffect(() => {
        setNodes(stateParent.batchList);
        setEdges(stateParent.edgeList);
    }, []);

    useEffect(() => {
        handleStateParent('batchList', nodes)
        handleStateParent('edgeList', edges)
    }, [nodes, edges]);

    const resetNodesAndEdges = useCallback(() => {
        if (nodes.length > 0) {
            setNodes([]);
        }
        if (edges.length > 0) {
            setEdges([]);
        }
    }, [])


    const onConnect = useCallback(
        (params: any) => {
            // console.log('tess')
            // console.log('testing on connect')
            setEdges((eds) =>
                addEdge(
                    {
                        ...params,
                        type: 'buttonedge',
                        // type: 'step',
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            color: 'black',
                            height: 20,
                            width: 20,
                        },
                        animated: true,
                        style: { stroke: "black" },
                    },
                    eds
                )
            )
        },
        [setEdges]
    );

    const removeEdge = useCallback(
        (evt: any, id: any) => {
            // console.log('tess')
            evt.stopPropagation();
            setEdges((edges) => edges.filter((eds) => eds.id !== id));
            // alert(`remove ${id}`);

            // API.delete({
            //     bodyCustom: null,
            //     pathCustom: `batch/run-batch-v2/${id}`,
            //     selectUrl: urlSelection.dashboard,
            //     useToken: true,
            //     needLoading: true,
            // }).then((response) => {
            //     setModal("deleteItem")
            // }).catch((err) => {
            //     dispatchStatePage({ type: 'showModalError', value: true })
            //     dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
            // })
        }
        , []
    )

    const removeNode = useCallback(
        (id: any) => {
            handleStateParent('selectedBatchJobIdUpdate', id)
            setModal();
        }, []
    )

    const functionRemoveNode = (id: any) => {
        setNodes((nodes) => nodes.filter((nds) => nds.id !== id))
        setEdges((edges) => edges.filter((eds) => eds.source !== id));
        setEdges((edges) => edges.filter((eds) => eds.target !== id));
    }


    // console.log(nodes)

    // const onAdd = useCallback(
    //     (data: any) => {
    //         // console.log('tess')

    //         const newNode = {
    //             id: data.value,
    //             type: 'custom',
    //             data: { label: data.label, toolbarPosition: Position.Top, toolbarVisible: true },
    //             position: {
    //                 x: 50,
    //                 y: 0,
    //             },
    //             style: defaultNodeStyle,
    //         };
    //         setNodes((nds) => nds.concat(newNode));
    //         handleStateParent('modalAddBatch', false)
    //         handleStateParent('selectedBatch', null)
    //     },
    //     []
    // );
    const onAdd = useCallback(
        (data: any) => {
            let selectedBackgroundColor: any = ''
            let selectedTextColor: any = ''
            COLORS_NODE_JOB.forEach((itemColor: any) => {
                if (itemColor.code === `${data.status}`) {
                    selectedBackgroundColor = itemColor.colorUsed
                }
            })
            if (selectedBackgroundColor === 'rgb(114, 114, 114)' || selectedBackgroundColor === 'rgb(0, 115, 0)' || selectedBackgroundColor === 'rgb(230, 57, 57)' || selectedBackgroundColor === 'rgb(152, 9, 9)') {
                selectedTextColor = 'white'
            }
            const newNode = {
                id: `${data.jobId}`,
                type: 'custom',
                data: { label: `${data.jobId} - ${data.progId}`, status: `${data.status}`, toolbarPosition: Position.Top, toolbarVisible: true },
                position: {
                    x: data.position.x,
                    y: data.position.y,
                },
                style: {
                    ...defaultNodeStyle,
                    backgroundColor: selectedBackgroundColor,
                    border: `2px solid ${selectedBackgroundColor}`,
                    color: selectedTextColor
                },
            };
            setNodes((nds) => nds.concat(newNode));
            handleStateParent('modalAddBatch', false)
            handleStateParent('selectedBatch', null)
        },
        []
    );
    const updateNode = useCallback(
        (currentValue: any, changeValue: any) => {
            // remove terlebih dahulu
            // console.log('tess')
            setNodes((nodes) => nodes.map((nds) => {
                if (nds.id === changeValue.value) {
                    let selectedBackgroundColor: any = ''
                    let selectedTextColor: any = ''
                    COLORS_NODE_JOB.forEach((itemColor: any) => {
                        if (itemColor.code === `${currentValue.status}`) {
                            selectedBackgroundColor = itemColor.colorUsed
                        }
                    })

                    if (selectedBackgroundColor === 'rgb(114, 114, 114)' || selectedBackgroundColor === 'rgb(0, 115, 0)' || selectedBackgroundColor === 'rgb(230, 57, 57)' || selectedBackgroundColor === 'rgb(152, 9, 9)') {
                        selectedTextColor = 'white'
                    }
                    nds.id = currentValue.value;
                    nds.data.label = currentValue.label;
                    nds.style = {
                        ...defaultNodeStyle,
                        backgroundColor: selectedBackgroundColor,
                        border: `2px solid ${selectedBackgroundColor}`,
                        color: selectedTextColor

                    }
                }
                return nds;
            }))
            setEdges((edges) => edges.map((eds) => {
                if (eds.source === changeValue.value) {
                    eds.source = currentValue.value;
                }
                return eds;

            }));
        }, [])

    const edgeTypes = useMemo(
        () => ({
            buttonedge: (value: any) => CustomEdge({
                value,
                removeEdge,
                edges
            }),
        }),
        [edges]
    );
    const nodeTypes = useMemo(
        () => ({
            custom: (value: any) => CustomNode({ value, onAdd, removeNode, handleStateParent, }),
            tooltip: (value: any) => TooltipNode({ value, onAdd, removeNode, handleStateParent, edges }),
        }),
        []
    );


    const checkOrder = () => {
        // // cari id yang terbanyak
        // let nodesFirst[] = nodes.map((element1) => {
        //     return { id: element1.id, total: 0 }
        // })
        // let nodesLast[] = nodes.map((element1) => {
        //     return { id: element1.id, total: 0 }
        // })

        // nodesFirst.forEach((node) => {
        //     edges.forEach((eds) => {
        //         if (eds.source === node.id) {
        //             node.total = node.total + 1;
        //         }
        //     })
        // })
        // nodesLast.forEach((node) => {
        //     edges.forEach((eds) => {
        //         if (eds.target === node.id) {
        //             node.total = node.total + 1;
        //         }
        //     })
        // })
        // console.log(JSON.stringify(nodesFirst));
        // console.log(nodesLast);
        // let maxTotal = 0;

        // for (let i = 0; i < nodesFirst.length; i++) {
        //     if (nodesFirst[i].total > maxTotal) {
        //         maxTotal = nodesFirst[i].total;
        //     }
        // }

        // console.log(maxTotal);
        // console.log(JSON.stringify(edges));
        // console.log(nodes)

    }

    useImperativeHandle(ref, () => ({
        onAdd,
        removeNode,
        updateNode,
        onConnect,
        resetNodesAndEdges,
        functionRemoveNode,
    }));




    const [variant, setVariant] = useState('cross');
    return (
        <>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                snapToGrid={true}
                edgeTypes={edgeTypes}
                fitView
                attributionPosition="top-right"
                nodeTypes={nodeTypes}
                minZoom={0.2}
                maxZoom={4}
                className='touchdevice-flow'
                style={{
                    backgroundColor: '#f5e9e9'
                }}

            >
                {/* <MiniMap /> */}
                <Background gap={100} color="#ccc" variant={BackgroundVariant.Dots} />
                <Controls showInteractive={false}>
                </Controls>
            </ReactFlow>
        </>

    );
});

export default EdgeWithButtonFlow;

import { useMemo } from "react";
import { CheckUndefined, NumberWithCommas } from "../../../Utils";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { SelectView } from "../../../Components";

export const TableRTSM = ({ ...props }) => {
    const {
        state,
        handleStateGlobal,
        handleStateParent,
        handleNavigation,
        setPagination,
        pagination,
    } = props;

    const columns: any = useMemo(
        () => [
            {
                header: 'Transaction Datetime',
                accessorKey: 'fullCreateDate',
                size: 'auto',
                padding: '20px',
                enableSorting: true,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.fullCreateDate)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Product Type',
                accessorKey: 'productType',
                size: 'auto',
                padding: '20px',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.fullCreateDate)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Product Feature',
                accessorKey: 'productFeatureName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.productFeatureName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Issuer',
                accessorKey: 'issuerName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.issuerName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Issuer Switcher',
                accessorKey: 'issuerSwitcherName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.issuerSwitcherName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Acquier Switcher',
                accessorKey: 'acquirerSwitcherName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.acquirerSwitcherName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Acquier',
                accessorKey: 'acquirerName',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.acquirerName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Transaction Amount',
                accessorKey: 'transAmount',
                size: 'auto',
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${row.original.transCurrency} ${NumberWithCommas(CheckUndefined(
                        row.original.transAmount
                    ))}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Transaction ID',
                accessorKey: 'transAuthId',
                size: 'auto',
                enableSorting: true,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.transAuthId)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Ori Transaction ID',
                accessorKey: 'oriTransAuthId',
                size: 'auto',
                enableSorting: true,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.oriTransAuthId)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Destination Response Code',
                accessorKey: 'authMemberRspnCode',
                size: 200,
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.authMemberRspnCode)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Destination Institution',
                accessorKey: 'authDestMemberName',
                size: 200,
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.authDestMemberName)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
            {
                header: 'Internal Response Code',
                accessorKey: 'authIntnRspnCode',
                size: 200,
                enableSorting: false,
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.authIntnRspnCode)}`,
                muiTableBodyCellProps: {
                    style: {
                        paddingRight: '46px',
                    },
                }
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: false,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                onClick: () => handleNavigation(row.original),
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },

        muiSearchTextFieldProps: {
            placeholder: `Search by ${state.SearchByPlaceHolder}`,
            size: 'small',
            title:'Search by Currency Code, Transaction Id, Ori Transaction Id',
        },

        onGlobalFilterChange: (filterValue) => {
            if(filterValue === undefined) {
                handleStateGlobal('search', '')
            }
            handleStateGlobal('search', filterValue)
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <div className="justify-content-between pt-2">
                <div className="col-6 d-flex align-items-center">
                    <p className="Lato-400 s-1822 c-29">List</p>
                    <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                        {state.totalData}
                    </p>
                </div>
            </div>
        ),

    });

    return (
        <>
            <div id="table" className="mt-2 mb-5 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateParent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    )

}
import { API, urlSelection } from '../../../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page59Title } from '../../../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../../../Assets/Images/svg';
import { loadingAction } from '../../../../Store/Loading';
import { logoutAction } from '../../../../Store/User';
import { InputNumber } from 'antd';

export const ModalAlertParameters = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal,svcId, prodInd, reloadData2 } = props
    const { optionsMemberCode2, optionsCommonStatusDetailCode, optionsEnvironmentType, optionsTresholdPercent, optionsCommonCode700, optionsOnOff, optionsCommonCode703, optionsProductFeatureProdFeatureIndAsValue2 } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["alertThreshCount"]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState <= 99 && valueState >= 1 || valueState == '') dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }


    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const deleteItemAPI2 = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `alert-proc-time-params/${svcId}/${prodInd}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData2()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }


    // const deleteItemAPI2 = () => {
    //     API.delete({
    //         bodyCustom: null,
    //         pathCustom: `${urlApi}/${svcId}/${prodInd}`,
    //         selectUrl: urlSelection.dashboard,
    //         useToken: true,
    //         needLoading: true,
    //     }).then((response) => {
    //         setModal("deleteItem")
    //         reloadData()
    //     }).catch((err) => {
    //         dispatchStatePage({ type: 'showModalError', value: true })
    //         dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
    //     }).finally(() => {
    //         dispatchStatePage({ type: 'clearData', value: deleteItem })
    //     })
    // }

    const postDataAPI2 = () => {
        const { prodInd, svcId, procTimeThres, alertSeverity } = statePage;
        const data = {
            prodInd: prodInd,
            svcId: svcId,
            procTimeThres: procTimeThres,
            alertSeverity: alertSeverity,
        };
        const a = CheckAllFields([prodInd, svcId, procTimeThres, alertSeverity], ['Product Indicator', 'Service ID', 'Threshold', 'Alert Severity']);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: 'alert-proc-time-params',
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData2()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    const postDataAPI = () => {
        const { srcMemberCode, dstMemberCode, alertThreshCount, alertThreshPercent, alertType, alertOnOff ,alertSeverity} = statePage;
        const data = {
            srcMemberCode: srcMemberCode,
            dstMemberCode: dstMemberCode,
            alertThreshCount: alertThreshCount,
            alertThreshPercent: alertThreshPercent,
            alertType: alertType,
            alertOnOff: alertOnOff,
            alertSeverity:alertSeverity
        };
        const a = CheckAllFields([srcMemberCode, dstMemberCode, alertThreshCount, alertThreshPercent, alertType, alertOnOff], ['Source Member Code', 'Destination Member Code', 'Threshold Count', 'Threshold Percent', 'Alert Type', 'Alert on/off']);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                {props.isProcTime === 'alert-proc-time' ? <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>Prod. Ind <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={
                                    optionsProductFeatureProdFeatureIndAsValue2
                                }
                                    value={optionsProductFeatureProdFeatureIndAsValue2.filter(
                                        (option: any) =>
                                            option.value ===
                                            statePage.prodInd
                                    )} onChange={(e) => handleState('prodInd', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Service ID <span className='text-danger'>*</span></p>
                                <input type='text' value={statePage.svcId} onChange={(e) => handleState('svcId', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}> Threshold <span className='text-danger'>*</span></p>
                                <input type='number' value={statePage.procTimeThres} onChange={(e) => handleState('procTimeThres', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Severity<span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsCommonCode703} value={optionsCommonCode703.filter((option: any) => option.value === statePage.alertSeverity)} onChange={(e) => handleState('alertSeverity', e.value)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI2()} />
                    </div>
                </div> : <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>SOURCE MEMBER <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsMemberCode2} value={optionsMemberCode2.filter((option: any) => option.value === statePage.srcMemberCode)} onChange={(e) => handleState('srcMemberCode', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>DESTINATION MEMBER <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsMemberCode2} value={optionsMemberCode2.filter((option: any) => option.value === statePage.dstMemberCode)} onChange={(e) => handleState('dstMemberCode', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}> THRESHOLD COUNT <span className='text-danger'>*</span></p>
                                <input type='number' value={statePage.alertThreshCount} onChange={(e) => handleState('alertThreshCount', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>THRESHOLD PERCENT<span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsTresholdPercent} value={optionsTresholdPercent.filter((option: any) => option.value === statePage.alertThreshPercent)} onChange={(e) => handleState('alertThreshPercent', e.value)} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>ALERT TYPE <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsCommonCode700} value={optionsCommonCode700.filter((option: any) => option.value === statePage.alertType)} onChange={(e) => handleState('alertType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}> ON/OFF <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsOnOff} value={optionsOnOff.filter((option: any) => option.value === statePage.alertOnOff)} onChange={(e) => handleState('alertOnOff', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>Severity<span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsCommonCode703} value={optionsCommonCode703.filter((option: any) => option.value === statePage.alertSeverity)} onChange={(e) => handleState('alertSeverity', e.value)} />
                            </div>
                            {/* <div className='col-6'>
                                <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                                <Select placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.hmacStatus)} onChange={(e) => handleState('hmacStatus', e.value)} />
                            </div> */}
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>}
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={props.isProcTime === 'alert-proc-time' ?() => deleteItemAPI2():() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page59Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    alertTransParamsId: '',
    srcMemberCode: '',
    dstMemberCode: '',
    alertThreshCount: '',
    alertThreshPercent: '',
    alertType: '',
    alertOnOff: 'false',
    // 
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: '',
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'svcId':
            return {
                ...state,
                svcId: action.value,
            };
        case 'prodInd':
            return {
                ...state,
                prodInd: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                alertTransParamsId: '',
                srcMemberCode: '',
                dstMemberCode: '',
                alertThreshCount: '',
                alertThreshPercent: '',
                alertType: '',
                alertOnOff: 'false',
                //
                svcId: '',
                prodInd: '',
                procTimeThres: '',
                alertSeverity: '',
                //
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                alertTransParamsId: action.value.alertTransParamsId,
                srcMemberCode: action.value.srcMemberCode,
                dstMemberCode: action.value.dstMemberCode,
                alertThreshCount: action.value.alertThreshCount,
                alertThreshPercent: action.value.alertThreshPercent,
                alertType: action.value.alertType,
                alertOnOff: action.value.alertOnOff,
                //
                svcId: action.value.svcId,
                prodInd: action.value.prodInd,
                procTimeThres: action.value.procTimeThres,
                alertSeverity: action.value.alertSeverity,
                //
                errorMessage: "",
            };
        // 
        case 'alertTransParamsId':
            return {
                ...state,
                alertTransParamsId: action.value,
            };
        case 'srcMemberCode':
            return {
                ...state,
                srcMemberCode: action.value,
            };
        case 'dstMemberCode':
            return {
                ...state,
                dstMemberCode: action.value,
            };
        case 'alertThreshCount':
            return {
                ...state,
                alertThreshCount: action.value,
            };
        case 'alertThreshPercent':
            return {
                ...state,
                alertThreshPercent: action.value,
            };
        case 'alertType':
            return {
                ...state,
                alertType: action.value,
            };
        case 'alertOnOff':
            return {
                ...state,
                alertOnOff: action.value,
            };
        case 'procTimeThres':
            return {
                ...state,
                procTimeThres: action.value,
            };
        case 'alertSeverity':
            return {
                ...state,
                alertSeverity: action.value,
            };
        default:
            throw new Error();
    }
}

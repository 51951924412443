import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { CheckAllFields, HasWhiteSpace } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { Page29Title } from '../../State/Menu/TitleMenu';
import { urlSelection } from '../../Services/API';


export const ModalDraggableDataElementManagement = ({ ...props }) => {
  const dispatchGlobal = useDispatch();
  const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
  const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
  const { optionsElementFormatDetailCode, optionsCommonStatusDetailCode, optionsElementTypeDetailCode, optionsFieldMappingDetailCode } = props.stateOptions

  const draggleRef2 = React.createRef<any>();

  useEffect(() => {
    dispatchStatePage({ 'type': 'visible', value: showModal })
    dispatchStatePage({ 'type': 'setData', value: itemModal })
  }, [statePage.visible !== showModal])

  useEffect(() => {
    dispatchStatePage({ type: 'id', value: id })
  }, [id !== statePage.id])

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef2?.current?.getBoundingClientRect();
    dispatchStatePage({
      'type': 'bounds', value: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y)
      }
    })
  };

  const handleState = (nameState: string | any, valueState: any) => {
    dispatchStatePage({ type: nameState, value: valueState })
  }
  const updateDataAPI = () => {
    const { elementType, elementName, elementDesc, elementFormat, elementStatus, elementIsRoot, elementRootId, elementLength, elementMappingCode } = statePage
    const data = {
      elementId: id,
      elementType: `${elementType}`,
      elementRootId: elementRootId,
      elementFormat: `${elementFormat}`,
      elementName: `${elementName}`,
      elementDesc: `${elementDesc}`,
      elementStatus: `${elementStatus}`,
      elementIsRoot: elementIsRoot,
      elementLength: elementLength,
      elementMappingCode: `${elementMappingCode}`
    }
    //   const a = checkAllFields([elementName, elementFormat, elementType, elementLength, elementStatus, elementIsRoot, elementRootId, ],['Name','Format','Length','Is Root','Root Tag ID','Status'])
    const a = CheckAllFields([elementName, elementFormat, elementType, elementLength, elementStatus, elementMappingCode], ['Name', 'Format', 'Type', 'Length', 'Status', 'Mapping Code'])

    if (a !== "") {
      handleState('errorMessage', a);
    } else {
      handleState('errorMessage', ``);
      API.update({
        bodyCustom: data,
        pathCustom: `${urlApi}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needLoading: true,
      }).then((response) => {
        actionAfterUpdateItem(id)
        actionShowModal()
      }).catch((err) => handleState('errorMessage', err))
    }
  };

  return (
    <Modal
      mask={false}
      maskClosable={false}
      keyboard={false}
      wrapClassName="aaa"
      width={800}
      style={{
        position: 'fixed',
        left: (document.body.clientWidth - 500) / 2,
        height: '0px',
        width: '0px',
        zIndex: index,
      }}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move'
          }}
          onMouseOver={() => {
            if (statePage.disabled) {
              handleState('disabled', false)
            }
          }}
          onMouseOut={() => {
            handleState('disabled', true)
          }}
        >
          <div className="flex justify-between items-end">
            <div className="flex">
              <div className="flex flex-col justify-between ml-4">
                <div>{statePage.titleModal}</div>
              </div>
            </div>
          </div>
        </div>
      }

      footer={null}
      open={statePage.visible}
      onOk={() => actionShowModal()}
      onCancel={() => actionShowModal()}
      modalRender={(modal) => (
        <Draggable
          disabled={statePage.disabled}
          bounds={statePage.bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef2} onClick={() => actionIndexModal()}>
            {modal}
          </div>
        </Draggable>
      )}
    >

      <div className='d-flex flex-column min-h-60px'>
        <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
          {id !== '' &&
            <div className='col-6'>
              <p className={labelStyle}>ELEMENT ID</p>
              <input value={statePage.id} className={inputStyle} />
            </div>
          }
          <div className='col-6'>
            <p className={labelStyle}>NAME</p>
            <input value={statePage.elementName} onChange={(e) => handleState('elementName', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-6'>
            <p className={labelStyle}>DESCRIPTION</p>
            <input value={statePage.elementDesc} onChange={(e) => handleState('elementDesc', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-6'>
            <p className={labelStyle}>FORMAT</p>
            <Select
              styles={inputStyleH25}
              placeholder={'(Search)'}
              options={optionsElementFormatDetailCode}
              value={optionsElementFormatDetailCode.filter((option: any) => option.value === statePage.elementFormat)}
              onChange={(e) => handleState('elementFormat', e.value)}
            />
          </div>
          <div className='col-6'>
            <p className={labelStyle}>TYPE</p>
            <Select
              styles={inputStyleH25}
              placeholder={'(Search)'}
              options={optionsElementTypeDetailCode}
              value={optionsElementTypeDetailCode.filter((option: any) => option.value === statePage.elementType)}
              onChange={(e) => handleState('elementType', e.value)}
            />
          </div>
          <div className='col-6'>
            <p className={labelStyle}>LENGTH</p>
            <input type='number' min={0} value={statePage.elementLength} onChange={(e) => handleState('elementLength', e.target.value)} className={inputStyle} />
          </div>
          <div className='col-6'>
            <p className={labelStyle}>STATUS</p>
            <Select
              styles={inputStyleH25}
              placeholder={'(Search)'}
              options={optionsCommonStatusDetailCode}
              value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.elementStatus)}
              onChange={(e) => handleState('elementStatus', e.value)}
            />
          </div>
          <div className='col-6'>
            <p className={labelStyle}>MAPPING CODE</p>
            <Select
              styles={inputStyleH25}
              placeholder={'(Search)'}
              options={optionsFieldMappingDetailCode}
              value={optionsFieldMappingDetailCode.filter((option: any) => option.value === statePage.elementMappingCode)}
              onChange={(e) => handleState('elementMappingCode', e.value)}
            />
          </div>
        </div>
        <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
      </div>
    </Modal>
  );

}

const initialState = {
  titleModal: `Edit ${Page29Title}`,
  visible: false,
  disabled: true,
  bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  errorMessage: '',
  errorMessageList: [],
  //
  elementType: '',
  elementId: '',
  elementName: '',
  elementDesc: '',
  elementFormat: '',
  elementStatus: '001',
  elementIsRoot: true,
  elementRootId: null,
  elementLength: '',
  elementMappingCode: '',
  //
  id: ''
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'titleModal':
      return {
        ...state,
        titleModal: action.value,
      };
    case 'visible':
      return {
        ...state,
        visible: action.value,
      };
    case 'disabled':
      return {
        ...state,
        disabled: action.value,
      };
    case 'bounds':
      return {
        ...state,
        bounds: action.value,
      };
    case 'errorMessage':
      return {
        ...state,
        errorMessage: action.value,
      };
    case 'errorMessageList':
      return {
        ...state,
        errorMessageList: action.value,
      };

    case 'id':
      return {
        ...state,
        id: action.value,
      };
    // 
    case 'clearData':
      return {
        ...state,
        elementType: '',
        elementId: '',
        elementName: '',
        elementDesc: '',
        elementFormat: '',
        elementStatus: '001',
        elementIsRoot: true,
        elementRootId: null,
        elementLength: '',
        elementMappingCode: '',
        errorMessage: "",

      };
    case 'setData':
      return {
        ...state,
        elementId: action.value.elementId,
        elementType: action.value.elementType,
        elementName: action.value.elementName,
        elementDesc: action.value.elementDesc,
        elementFormat: action.value.elementFormat,
        elementStatus: action.value.elementStatus,
        elementIsRoot: action.value.elementIsRoot,
        elementRootId: action.value.elementRootId,
        elementLength: action.value.elementLength,
        elementMappingCode: action.value.elementMappingCode,
        errorMessage: "",
      };
    // 
    case 'elementId':
      return {
        ...state,
        elementId: action.value,
      };
    case 'elementType':
      return {
        ...state,
        elementType: action.value,
      };
    case 'elementName':
      return {
        ...state,
        elementName: action.value,
      };
    case 'elementDesc':
      return {
        ...state,
        elementDesc: action.value,
      };
    case 'elementFormat':
      return {
        ...state,
        elementFormat: action.value,
      };
    case 'elementStatus':
      return {
        ...state,
        elementStatus: action.value,
      };
    case 'elementIsRoot':
      return {
        ...state,
        elementIsRoot: action.value,
      };
    case 'elementRootId':
      return {
        ...state,
        elementRootId: action.value,
      };
    case 'elementLength':
      return {
        ...state,
        elementLength: action.value,
      };
    case 'elementMappingCode':
      return {
        ...state,
        elementMappingCode: action.value,
      };
    default:
      throw new Error();
  }
}





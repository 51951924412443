
import { useSelector } from "react-redux";
import { CheckUndefined, CheckUndefinedStringEmpty, NumberWithCommas } from "../../Utils";
import { Divider } from "antd";

const HeaderComponent = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    return (
        <div className='d-flex justify-content-between px-4 '>
            {
                props.label.map((item: any, i: any) => {
                    if (item.val === 'action') {
                        if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                            return (
                                props.checkIndex(`${item.val}`) && (
                                    <p key={i} className={`c-29 s-1418 Lato-700 ${item.width} text-center `}>
                                        {item.field}
                                    </p>
                                )
                            );
                        } else {
                            return <></>;
                        }
                    } else {
                        return (
                            props.checkIndex(`${item.val}`) && (
                                <p key={i} className={`c-29 s-1418 Lato-700 ${item.width} `}>
                                    {item.field}
                                </p>
                            )
                        );
                    }
                })
            }
        </div>
    )
}
export const TableSalesInquiry = ({ ...props }) => {
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    return (
        <div className='d-flex flex-column w-100 overflow mb-2'>
            <div className={`d-flex flex-column ${props.widthTable}`}>
                <HeaderComponent
                    codeAccessPrivilegeDelete={props.codeAccessPrivilegeDelete}
                    codeAccessPrivilegeEdit={props.codeAccessPrivilegeEdit}
                    label={props.label}
                    data={props.data}
                    position={props.position}
                    view={props.view}
                    customButtonAction={props.customButtonAction}
                    checkIndex={props.checkIndex}
                    widthTable={props.widthTable}
                    stateOptions={props.stateOptions}
                />
    <Divider className="my-2"/>

              {props.data.map((item: any, index: any) => {
                    let number = index + ((props.currentPage - 1) * props.view)
                        return (
                            <div key={number } className={`d-flex justify-content-between align-items-center min-h-50px px-4 table-data-pointer  ${number % 2 === 0 ? 'bg-f4' : ''} `} onClick={() => props.editData(item)}>
                                {props.label.map((element1: any) => {
                                    if (element1.val === 'no') {
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{number + 1}</p>;
                                    } else if (element1.val === 'action') {
                                        if (privilegeAccess.includes(props.codeAccessPrivilegeDelete) || privilegeAccess.includes(props.codeAccessPrivilegeEdit)) {
                                            return props.checkIndex(`${element1.val}`) && <div className={`d-flex c-29 s-1418 Lato-400 word-wrap ${element1.width}`}>{props.customButtonAction(item, element1.width)}</div>;
                                        } else {
                                            return <></>;
                                        }
                                    } else if (element1.val === 'issuerAmount') {
                                        let show = `${CheckUndefinedStringEmpty(item.issuerCurrency)} ${NumberWithCommas(CheckUndefined(item.issuerAmount))}`;
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;

                                    } else if (element1.val === 'issSwitcherAmount') {
                                        let show = `${CheckUndefinedStringEmpty(item.issuerSwitcherCurrency)} ${NumberWithCommas(CheckUndefined(item.issSwitcherAmount))}`;
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;

                                    } else if (element1.val === 'acquirerSwitcherAmount') {
                                        let show = `${CheckUndefinedStringEmpty(item.acquirerSwitcherCurrency)} ${NumberWithCommas(CheckUndefined(item.acquirerSwitcherAmount))}`;
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;

                                    }  else if (element1.val === 'acquirerAmount') {
                                        let show = `${CheckUndefinedStringEmpty(item.acquirerCurrency)} ${NumberWithCommas(CheckUndefined(item.acquirerAmount))}`;
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap ${element1.width}`}>{CheckUndefined(show)}</p>;

                                    }  
                                    else {
                                        return props.checkIndex(`${element1.val}`) && <p className={`s-1418 Lato-400 word-wrap py-1 ${element1.width}`}>{CheckUndefined(item[`${element1.val}`])}</p>;
                                    }
                                })}
                            </div>
                        );
                })}
            </div>
        </div>
    );
}

interface QRStandardElementInterface {
    id: null | number | string;
    qrseId: number | null;
    qrStandardId: number | null;
    qrStandardName: string | null;
    elementId: number | null;
    elementName: string | null;
    qrseMoc: string | null;
    qrseIsRoot: Boolean | null;
    rootElementId: number | null;
    qrseStatus: string | null;
    qrseDesc: string | null;
}

export class ModelQRStandardElement {
    id: null | number | string;
    qrseId: number | null;
    qrStandardId: number | null;
    qrStandardName: string | null;
    elementId: number | null;
    elementName: string | null;
    qrseMoc: string | null;
    qrseIsRoot: Boolean | null;
    rootElementId: number | null;
    qrseStatus: string | null;
    qrseDesc: string | null;

    constructor(value: QRStandardElementInterface | null)  {
        this.id = value?.id ?? null;
        this.qrseId=value?.qrseId ?? null;
        this.qrStandardId=value?.qrStandardId ?? null;
        this.qrStandardName=value?.qrStandardName ?? null;
        this.elementId=value?.elementId ?? null;
        this.elementName=value?.elementName ?? null;
        this.qrseMoc=value?.qrseMoc ?? null;
        this.qrseIsRoot=value?.qrseIsRoot ?? null;
        this.rootElementId=value?.rootElementId ?? null;
        this.qrseStatus=value?.qrseStatus ?? null;
        this.qrseDesc=value?.qrseDesc ?? null;
    }
}

import { settlementIcon } from "../../../Assets/Images/MenuIcon";
import { Page1SettlementRoute, Page2SettlementRoute, Page3SettlementRoute, Page4SettlementRoute, Page5SettlementRoute, Page6SettlementRoute, Page7SettlementRoute, Page8SettlementRoute } from "../../../Routes";
import { Page5Code, Page6Code, Page7Code, Page8Code, Page9Code, Page56Code, PageUnmatchedListCode, PageAdjustmentListCode } from "../CodeMenu";
import { Page5Title, Page6Title, Page7Title, Page8Title, Page9Title, Page56Title, PageUnmatchedList, PageAdjustmentList } from "../TitleMenu";

export const menu2 =
{
    id: 'settlement_sidebar',
    label: 'Settlement',
    icon: settlementIcon,
    privilegesId: [
        Page5Code,
        Page6Code,
        Page7Code,
        Page8Code,
        Page9Code,
        Page56Code
    ],
    itemSidebar: [
        {
            titleList: Page56Title,
            key: 'settlement6',
            privilegeId: Page56Code,
            url: Page6SettlementRoute
        },
        {
            titleList: Page5Title,
            key: 'settlementOptions1',
            privilegeId: Page5Code,
            url: Page1SettlementRoute
        },
        {
            titleList: Page6Title,
            key: 'settlementOptions2',
            privilegeId: Page6Code,
            url: Page2SettlementRoute
        },
        {
            titleList: Page7Title,
            key: 'settlementOptions3',
            privilegeId: Page7Code,
            url: Page3SettlementRoute
        },
        {
            titleList: Page8Title,
            key: 'settlementOptions4',
            privilegeId: Page8Code,
            url: Page4SettlementRoute
        },
        {
            titleList: Page9Title,
            key: 'settlement5',
            privilegeId: Page9Code,
            url: Page5SettlementRoute
        },
        {
            titleList: PageUnmatchedList,
            key: 'settlement7',
            privilegeId: PageUnmatchedListCode,
            url: Page7SettlementRoute
        },
        {
            titleList: PageAdjustmentList,
            key: 'settlement7',
            privilegeId: PageAdjustmentListCode,
            url: Page8SettlementRoute
        },
    ],
}
import { API, urlSelection } from '../../Services/API';
import { CapitalizeFirstLetter, CheckAllFields, CheckInputAZaz09Space, ModalStyle } from '../../Utils';
import { FooterModalCreate2, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../Styles';
import { useEffect, useReducer } from 'react';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-modal';
import { ModelBatchProgramRegistration } from './ModelBatchProgramRegistration';
import { Page23Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { useDispatch } from 'react-redux';

export const ModalBatchProgramRegistration = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const {
        optionsLogLevelDetailCode,
        optionsBatchProgramStatusDetailCode,
        optionsCommonStatusDetailCode,
        optionsBatchProgramTypeDetailCode
    } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["batchProgId", "batchStatus", "batchName", "batchType", "batchProgName", "batchLogLevel", "batchDesc", "batchParameterFormat"]
        let limit = [15, 3, 15, 3, 50, 3, 200, 254]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (nameState === 'batchProgId') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === 'batchName') {
                if (valueState.split('').length <= limit[index]) {
                    let changeValue = valueState.toString().toUpperCase();
                    let changeValueSpaceWithUnderScore = changeValue.toString().replaceAll(' ', '_');
                    dispatchStatePage({ type: nameState, value: changeValueSpaceWithUnderScore })
                }
            }
            else if (nameState === "batchProgName") {
                let errorMessage = {
                    id: '1',
                    errorMessage: 'BATCH PROGRAM NAME must contain only letters (A-Z) or (a-z), space and numbers (0-9)'
                }
                if (CheckInputAZaz09Space.test(valueState.toString())) {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "removed" } }) // removed
                    }
                } else {
                    if (valueState.split('').length <= limit[index]) {
                        dispatchStatePage({ type: nameState, value: valueState.toString() })
                        dispatchStatePage({ type: 'errorMessageList', value: { data: errorMessage, type: "added" } }) // added
                    }
                }
            }
            else if (valueState.split('').length <= limit[index]) {
                dispatchStatePage({ type: nameState, value: valueState })
            }

        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }


    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const {
            batchId,
            batchProgId,
            batchStat,
            batchStatus,
            batchName,
            batchType,
            batchProgName,
            batchReprocYn,
            logLevel,
            batchParallelExecYn,
            batchDesc,
            batchParameterFormat,
            batchConstName
        } = statePage.data
        const data = {
            // batchId: batchId,
            batchProgId: batchProgId,
            batchStatus: batchStatus,
            batchName: batchName,
            batchType: batchType,
            batchProgName: batchProgName,
            batchReprocYn: batchReprocYn,
            batchLogLevel: logLevel,
            batchParallelExecYn: batchParallelExecYn,
            batchDesc: batchDesc,
            batchParameterFormat: batchParameterFormat,
        }
        const a = CheckAllFields([
            batchProgId,
            batchStatus,
            batchName,
            batchType,
            batchProgName,
            batchReprocYn,
            logLevel,
            batchParallelExecYn,
        ], [
            "BATCH PROGRAM ID",
            "STATUS",
            'BATCH NAME',
            'TYPE',
            'BATCH PROGRAM NAME',
            "BATCH REPROCESSING",
            "BATCH LOG LEVEL",
            "BATCH EXECUTION",
        ])
        if (statePage.errorMessageList.length > 0) { }
        else {
            if (a !== "") {
                handleState('errorMessage', a);
            } else {
                handleState('errorMessage', ``);
                API.post({
                    bodyCustom: data,
                    pathCustom: `${urlApi}`,
                    selectUrl: urlSelection.dashboard,
                    useToken: true,
                    needLoading: true,
                    needNotification: true,
                }).then((response) => {
                    dispatchStatePage({ type: 'clearData' })
                    setModal("visibleModalAddDelete")
                    reloadData()
                }).catch((err) => handleState('errorMessage', err))
            }
        }

    }

    useEffect(() => {
        let resultConvertFormBatchName: String = '';
        if (statePage.data.batchProgId !== null) {
            let resultArray: string[] = statePage.data.batchProgId?.split('_')
            if (resultArray.length > 0) {
                resultArray.forEach((item: string) => {
                    resultConvertFormBatchName = resultConvertFormBatchName + CapitalizeFirstLetter(item);
                })
            }
            handleState('batchConstName', resultConvertFormBatchName)
        }

    }, [statePage.data.batchProgId])


    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH PROGRAM ID <span className='text-danger'>*</span></p>
                                <input value={statePage.data.batchProgId} onChange={(e) => handleState('batchProgId', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsCommonStatusDetailCode}
                                    value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.batchStatus)}
                                    onChange={(e) => handleState('batchStatus', e.value)}
                                />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>BATCH NAME <span className='text-danger'>*</span></p>
                                <input value={statePage.data.batchName} onChange={(e) => handleState('batchName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>TYPE <span className='text-danger'>*</span></p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsBatchProgramTypeDetailCode}
                                    value={optionsBatchProgramTypeDetailCode.filter((option: any) => option.value === statePage.data.batchType)}
                                    onChange={(e) => handleState('batchType', e.value)}
                                />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH PROGRAM NAME <span className='text-danger'>*</span></p>
                                <input value={statePage.data.batchProgName} onChange={(e) => handleState('batchProgName', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH REPROCESSING <span className='text-danger'>*</span></p>
                                <BootstrapSwitchButton
                                    checked={statePage.data.batchReprocYn}
                                    size="sm"
                                    onChange={(e) => handleState('batchReprocYn', e)}
                                />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH LOG LEVEL <span className='text-danger'>*</span></p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsLogLevelDetailCode}
                                    value={optionsLogLevelDetailCode.filter((option: any) => option.value === statePage.data.logLevel)}
                                    onChange={(e) => handleState('logLevel', e.value)}
                                />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>BATCH EXECUTION <span className='text-danger'>*</span></p>
                                <BootstrapSwitchButton
                                    checked={statePage.data.batchParallelExecYn}
                                    size="sm"
                                    onChange={(e) => handleState('batchParallelExecYn', e)}
                                />
                            </div>

                            <div className='col-6 my-2'>
                                <p className={labelStyle}>PARAMETER FORMAT</p>
                                <input value={statePage.data.batchParameterFormat} onChange={(e) => handleState('batchParameterFormat', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6 my-2'>
                                <p className={labelStyle}>BATCH PARAMETER</p>
                                <input value={statePage.data.batchConstName} disabled className={inputStyleReadOnly} />
                            </div>
                            <div className='col-12'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={statePage.data.batchDesc} onChange={(e) => handleState('batchDesc', e.target.value)} className={inputDescriptionStyle} />
                            </div>
                        </div>
                        <FooterModalCreate2 errorMessage={statePage.errorMessage} errorMessageList={statePage.errorMessageList} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page23Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelBatchProgramRegistration({
        id: null,
        batchId: null,
        batchProgId: null,
        batchStat: '',
        batchStatus: '001',
        batchName: null,
        batchType: '001',
        batchProgName: null,
        batchReprocYn: false,
        logLevel: '005',
        batchParallelExecYn: false,
        batchDesc: null,
        batchParameterFormat: null,
        batchConstName: null,
    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
    errorMessageModal: '',
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelBatchProgramRegistration) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        case 'errorMessageList':
            if (action.value.type === "removed") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                            errorMessageList: state.errorMessageList.filter((item: any) => item.id !== action.value.data.id)
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                } else {
                    return {
                        ...state,
                    }
                }
            } else if (action.value.type === "added") {
                let conditionSearchID = false;
                if (state.errorMessageList.length > 0) {
                    state.errorMessageList.forEach((item: any) => {
                        if (item.id === action.value.data.id) conditionSearchID = true;
                    })
                    if (conditionSearchID) {
                        return {
                            ...state,
                        }
                    } else {

                        let data: any[] = [...state.errorMessageList]
                        data.push(action.value.data)
                        return {
                            ...state,
                            errorMessageList: data
                        }
                    }
                } else {
                    let data: any[] = [...state.errorMessageList]
                    data.push(action.value.data)
                    return {
                        ...state,
                        errorMessageList: data
                    }
                }
            } else {
                return {
                    ...state,
                }
            }
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelBatchProgramRegistration({
                    id: null,
                    batchId: null,
                    batchProgId: null,
                    batchStat: '',
                    batchStatus: '001',
                    batchName: null,
                    batchType: '001',
                    batchProgName: null,
                    batchReprocYn: false,
                    logLevel: '005',
                    batchParallelExecYn: false,
                    batchDesc: null,
                    batchParameterFormat: null,
                    batchConstName: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelBatchProgramRegistration({
                    id: action.value.batchId,
                    batchId: action.value.batchId,
                    batchProgId: action.value.batchProgId,
                    batchStat: action.value.batchStat,
                    batchStatus: action.value.batchStatus,
                    batchName: action.value.batchName,
                    batchType: action.value.batchType,
                    batchProgName: action.value.batchProgName,
                    batchReprocYn: action.value.batchReprocYn,
                    logLevel: action.value.logLevel,
                    batchParallelExecYn: action.value.batchParallelExecYn,
                    batchDesc: action.value.batchDesc,
                    batchParameterFormat: action.value.batchParameterFormat,
                    batchConstName: action.value.batchConstName,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

import React, { useEffect, useReducer, useState } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../../../Components';
import { API } from '../../../../Services';
import { logoutAction } from '../../../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from '../../../../Styles';
import { CheckAllFields, HasWhiteSpace } from '../../../../Utils';
import { loadingAction } from '../../../../Store/Loading';
import { Page59Title } from '../../../../State/Menu/TitleMenu';
import { urlSelection } from '../../../../Services/API';

export const ModalDraggableAlertParameters = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem,actionAfterUpdateItem2, urlApi,reloadData2, reloadData} = props
    const { optionsMemberCode2, optionsCommonStatusDetailCode, optionsEnvironmentType, optionsTresholdPercent, optionsCommonCode700, optionsOnOff, optionsCommonCode703, optionsProductFeatureProdFeatureIndAsValue2 } = props.stateOptions
    const draggleRef2 = React.createRef<any>();

    useEffect(() => {
        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    // useEffect(() => {
    //     dispatchStatePage({ type: 'svcId', value: svcId })
    //     dispatchStatePage({ type: 'prodInd', value: prodInd })
    // }, [svcId !== statePage.svcId && prodInd !== statePage.prodInd])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef2?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["alertThreshCount"]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState <= 99 && valueState >= 1 || valueState == '') dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }

    const updateDataAPI = () => {
        const { alertTransParamsId, srcMemberCode, dstMemberCode, alertThreshCount, alertThreshPercent, alertType, alertOnOff } = statePage;
        const data = {
            atpId: alertTransParamsId,
            srcMemberCode: srcMemberCode,
            dstMemberCode: dstMemberCode,
            alertThreshCount: alertThreshCount,
            alertThreshPercent: alertThreshPercent,
            alertType: alertType,
            alertOnOff: alertOnOff,
        };
        const a = CheckAllFields([srcMemberCode, dstMemberCode, alertThreshCount, alertThreshPercent, alertType, alertOnOff], ['Source Member Code', 'Destination Member Code', 'Threshold Count', 'Threshold Percent', 'Alert Type', 'Alert on/off']);

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response) => {
                actionAfterUpdateItem(id)
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };
    const updateDataAPI2 = () => {
        const { prodInd, svcId, procTimeThres, alertSeverity } = statePage;
        const data = {
            prodInd: prodInd,
            svcId: svcId,
            procTimeThres: procTimeThres,
            alertSeverity: alertSeverity,
        };
        const a = CheckAllFields([prodInd, svcId, procTimeThres, alertSeverity], ['Product Indicator', 'Service ID', 'Threshold', 'Alert Severity']);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.update({
                bodyCustom: data,
                pathCustom: 'alert-proc-time-params',
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response) => {
                actionAfterUpdateItem2(id)
                // reloadData2()
                actionShowModal()
            }).catch((err) => handleState('errorMessage', err))
        }
    };
    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef2} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >
            {itemModal.svcId ? <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>Prod. Ind <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} isDisabled options={
                            optionsProductFeatureProdFeatureIndAsValue2
                        }
                            value={optionsProductFeatureProdFeatureIndAsValue2.filter(
                                (option: any) =>
                                    option.value ===
                                    statePage.prodInd
                            )} onChange={(e) => handleState('prodInd', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Service ID <span className='text-danger'>*</span></p>
                        <input disabled type='text' value={statePage.svcId} onChange={(e) => handleState('svcId', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}> Threshold <span className='text-danger'>*</span></p>
                        <input type='number' value={statePage.procTimeThres} onChange={(e) => handleState('procTimeThres', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Severity<span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsCommonCode703} value={optionsCommonCode703.filter((option: any) => option.value === statePage.alertSeverity)} onChange={(e) => handleState('alertSeverity', e.value)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI2()} />
            </div> : <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                    <div className='col-6'>
                        <p className={labelStyle}>SOURCE MEMBER <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsMemberCode2} value={optionsMemberCode2.filter((option: any) => option.value === statePage.srcMemberCode)} onChange={(e) => handleState('srcMemberCode', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>DESTINATION MEMBER <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsMemberCode2} value={optionsMemberCode2.filter((option: any) => option.value === statePage.dstMemberCode)} onChange={(e) => handleState('dstMemberCode', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}> THRESHOLD COUNT <span className='text-danger'>*</span></p>
                        <input type='number' value={statePage.alertThreshCount} onChange={(e) => handleState('alertThreshCount', e.target.value)} className={inputStyle} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>THRESHOLD PERCENT<span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsTresholdPercent} value={optionsTresholdPercent.filter((option: any) => option.value === statePage.alertThreshPercent)} onChange={(e) => handleState('alertThreshPercent', e.value)} />
                    </div>

                    <div className='col-6'>
                        <p className={labelStyle}>ALERT TYPE <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsCommonCode700} value={optionsCommonCode700.filter((option: any) => option.value === statePage.alertType)} onChange={(e) => handleState('alertType', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}> ON/OFF <span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsOnOff} value={optionsOnOff.filter((option: any) => option.value === statePage.alertOnOff)} onChange={(e) => handleState('alertOnOff', e.value)} />
                    </div>
                    <div className='col-6'>
                        <p className={labelStyle}>Severity<span className='text-danger'>*</span></p>
                        <Select placeholder={'(Search)'} styles={inputStyleH25} options={optionsCommonCode703} value={optionsCommonCode703.filter((option: any) => option.value === statePage.alertSeverity)} onChange={(e) => handleState('alertSeverity', e.value)} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>}
        </Modal>
    );


}

const initialState = {
    titleModal: `Update ${Page59Title}`,
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    errorMessageList: [],
    //
    alertTransParamsId: '',
    alertThreshCount: '',
    alertThreshPercent: '',
    alertOnOff: '',
    alertType: '',
    srcMemberCode: '',
    srcMemberName: '',
    dstMemberCode: '',
    dstMemberName: '',
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };

        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                alertTransParamsId: '',
                alertThreshCount: '',
                alertThreshPercent: '',
                alertOnOff: '',
                alertType: '',
                srcMemberCode: '',
                srcMemberName: '',
                dstMemberCode: '',
                dstMemberName: '',
                svcId: '',
                prodInd: '',
                procTimeThres: '',
                alertSeverity: '',
                //
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                alertTransParamsId: action.value.alertTransParamsId,
                alertThreshCount: action.value.alertThreshCount,
                alertThreshPercent: action.value.alertThreshPercent,
                alertOnOff: action.value.alertOnOff,
                alertType: action.value.alertType,
                srcMemberCode: action.value.srcMemberCode,
                srcMemberName: action.value.srcMemberName,
                dstMemberCode: action.value.dstMemberCode,
                dstMemberName: action.value.dstMemberName,
                svcId: action.value.svcId,
                prodInd: action.value.prodInd,
                procTimeThres: action.value.procTimeThres,
                alertSeverity: action.value.alertSeverity,

                //
                errorMessage: "",
            };
        // 
        case 'alertTransParamsId':
            return {
                ...state,
                alertTransParamsId: action.value,
            };
        case 'alertThreshCount':
            return {
                ...state,
                alertThreshCount: action.value,
            };
        case 'alertThreshPercent':
            return {
                ...state,
                alertThreshPercent: action.value,
            };
        case 'alertOnOff':
            return {
                ...state,
                alertOnOff: action.value,
            };
        case 'alertType':
            return {
                ...state,
                alertType: action.value,
            };
        case 'srcMemberCode':
            return {
                ...state,
                srcMemberCode: action.value,
            };
        case 'srcMemberName':
            return {
                ...state,
                srcMemberName: action.value,
            };
        case 'dstMemberCode':
            return {
                ...state,
                dstMemberCode: action.value,
            };
        case 'dstMemberName':
            return {
                ...state,
                dstMemberName: action.value,
            };
        case 'svcId':
            return {
                ...state,
                svcId: action.value,
            };
        case 'prodInd':
            return {
                ...state,
                prodInd: action.value,
            };
        // 
        case 'procTimeThres':
            return {
                ...state,
                procTimeThres: action.value,
            };
        case 'alertSeverity':
            return {
                ...state,
                alertSeverity: action.value,
            };
        default:
            throw new Error();
    }
}
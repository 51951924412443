import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { checklistButtonIcon } from "../../Assets/Images/svg";
import { ErrorMessage } from "../../Components/ErrorMessage";
import { API } from "../../Services";
import {
  initFunction, listPrivilegePage, listPrivilegePageSelected, reducerOptions,
  reducerStateSelected, stateOptions,
  stateSelected
} from "../../State";
import { loadingAction } from "../../Store/Loading";
import { logoutAction } from "../../Store/User";
import { inputDescriptionStyle, inputStyle, inputStyleH25, inputStyleReadOnly, labelStyle } from "../../Styles";
import { CheckAllFields } from "../../Utils";
import { InterfaceOptionsPrivilegeCodeDetail } from "./InterfaceOptionsPrivilegeCodeDetail";
import { Page3UserRoute } from "../../Routes";
import { store } from "../../Store/Store";
import { urlSelection } from "../../Services/API";
import { doubleAction } from "../../Store/Error";
import { successDoubleAction } from "../../Store/Sucesss";
import { Divider } from "antd";


export const PrivilegeCodeDetail = ({ ...props }) => {
  let { id } = useParams();
  const dispatchGlobal = useDispatch();
  const navigate = useNavigate()
  const [stateComponent, dispatchComponent] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
  const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);

  useEffect(() => {
    initFunction(dispatchOptions, null, () => { }, null, InterfaceOptionsPrivilegeCodeDetail, dispatchGlobal)
  }, [])


  useEffect(() => {
    API.get({
      bodyCustom: null,
      pathCustom: `privilege/${id}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response: any) => {
      dispatchComponent({ type: 'setData', value: response.data })
    })
    API.get({
      bodyCustom: null,
      pathCustom: `privilege_detail/${id}`,
      selectUrl: urlSelection.dashboard,
      useToken: true,
      needLoading: true,
    }).then((response: any) => {
      let dataListOption: any[] = [];
      let dataListOptionChecked: any[] = [];
      if (response.data.length > 0) {
        let copyListAllPrivilege = JSON.parse(listPrivilegePage);
        let listSelectedPrivilegeCopy = JSON.parse(listPrivilegePageSelected);
        copyListAllPrivilege.map((element1: any) => {
          element1.listPrivilege.forEach((element2: any) => {
            response.data.forEach((element3: any) => {
              if (element3.privfId.toString() === element2.id.toString()) {
                element1.checked = true;
              }
            });
          });
          return element1;
        });
        copyListAllPrivilege.forEach((element1: any) => {
          element1.listPrivilege.forEach((element2: any) => {
            response.data.forEach((element3: any) => {
              if (element3.privfId.toString() === element2.id.toString()) {
                // 50  === 50
                // dataListOptionChecked =
                listSelectedPrivilegeCopy.map((element4: any) => {
                  if (element1.val === element4.val) {
                    element4.selectedListPrivilege.push(element3.privfId.toString());
                  }
                  return element4;
                });
              }
            });
          });
        });
        dataListOption = copyListAllPrivilege;
        dataListOptionChecked = listSelectedPrivilegeCopy;
      }
      if (dataListOption.length > 0 || dataListOptionChecked.length > 0) {
        dispatchComponent({ type: 'listAllPrivilege', value: dataListOption })
        dispatchComponent({ type: 'listSelectedPrivilege', value: dataListOptionChecked })
        dispatchComponent({ type: 'errorMessage', value: '' })
      }
    })
  }, [])


  const handleState = (nameState: any, valueState: any) => {
    const { listSelectedPrivilege, listAllPrivilege } = stateComponent;
    listAllPrivilege.forEach((element: any) => {
      // if this is false then the option will not be able to be selected, for example: add, display
      if (element.valChecked === nameState) {
        let newListAllPrivilege: any[] = []
        listAllPrivilege.forEach((element2: any) => {
          if (element2.valChecked === nameState) { // nameState => optionCountryCodeManagement
            element2.checked = valueState.target.checked;
            if (valueState.target.checked === false) {
              let newListSelectedPrivilege: any[] = [];
              listSelectedPrivilege.forEach((itemSelected: any) => {
                if (itemSelected.val === element2.val) { // selectedCountryCodeManagement === selectedCountryCodeManagement
                  let itemDataCodeNew = itemSelected;
                  itemDataCodeNew.selectedListPrivilege = []
                  newListSelectedPrivilege.push(itemDataCodeNew)
                } else {
                  newListSelectedPrivilege.push(itemSelected)
                }

              })
              dispatchComponent({ type: 'listSelectedPrivilege', value: newListSelectedPrivilege })
            }
          }
          newListAllPrivilege.push(element2);
        })
        dispatchComponent({ type: 'listAllPrivilege', value: newListAllPrivilege })
      } else if (element.val === nameState) {
        // this is logic, to add access every option
        // for example user management, add access => add, view, download
        //-------------------------------------------------------------------------------
        var selectedPrivilege: any[] = [];
        listSelectedPrivilege.forEach((element2: any) => {
          if (element2.val === element.val) selectedPrivilege.push(element2);
        });
        dispatchComponent({ type: 'listSelectedPrivilege', value: selectedPrivilege })

        var newArray = [...selectedPrivilege[0].selectedListPrivilege]; //  51, 52, 53, 54, 55
        var listValue = [...element.listPrivilege]; // all, 51, 52, 53, 54, 55
        if (selectedPrivilege[0].selectedListPrivilege.length === 0) {
          if (valueState.target.value === "all") {
            newArray = [];
            listValue.forEach((element) => {
              if (element.id !== "all") {
                newArray.push(element.id);
                newArray.sort((a: any, b: any) => parseInt(a) - parseInt(b));
              }
            });
          } else if (valueState.target.value === "all" && valueState.target.checked === false) {
            newArray = [];// clear data array
          } else {
            newArray.push(valueState.target.value);
            newArray.sort((a: any, b: any) => parseInt(a) - parseInt(b));

          }
          let newListSelectedPrivilege: any[] = [];
          listSelectedPrivilege.forEach((element2: any) => {
            if (element2.val === nameState) {
              element2.selectedListPrivilege = newArray;
            }
            newListSelectedPrivilege.push(element2);
          });
          dispatchComponent({ type: 'listSelectedPrivilege', value: newListSelectedPrivilege })
        } else {
          if (selectedPrivilege[0].selectedListPrivilege.includes(`${valueState.target.value}`)) {//remove code
            let newListSelectedPrivilege: any[] = [];
            listSelectedPrivilege.forEach((element2: any) => {
              if (element2.val === nameState) {
                var newDataSelectedOption = element2.selectedListPrivilege.filter((elementDataSelected: any) => {
                  return elementDataSelected !== `${valueState.target.value}`;
                });
                element2.selectedListPrivilege = newDataSelectedOption;
              }
              newListSelectedPrivilege.push(element2);
            })
            dispatchComponent({ type: 'listSelectedPrivilege', value: newListSelectedPrivilege })
          } else {
            if (valueState.target.value === "all" && valueState.target.checked === true) {
              newArray = []; // clear data array
              listValue.forEach((element2: any) => {
                if (element2.id !== "all") {
                  newArray.push(element2.id);
                  newArray.sort((a: any, b: any) => parseInt(a) - parseInt(b));
                }
              });
            } else if (valueState.target.value === "all" && valueState.target.checked === false) {
              newArray = [];// clear data array
            } else {
              newArray.push(valueState.target.value);
              newArray.sort((a: any, b: any) => parseInt(a) - parseInt(b));
            }
            let newListSelectedPrivilege: any[] = [];
            listSelectedPrivilege.forEach((element2: any) => {
              if (element2.val === nameState) {
                element2.selectedListPrivilege = newArray;
              }
              newListSelectedPrivilege.push(element2);
            });
            dispatchComponent({ type: 'listSelectedPrivilege', value: newListSelectedPrivilege })
          }
        }
      }
    });
  };



  const postData = async () => {
    const { privilegeId, privilegeName, privilegeDesc, listSelectedPrivilege, member, ccdCode } = stateComponent;
    var dataListIdPrivilegeChecked: any[] = [];
    listSelectedPrivilege.forEach((element1: any) => {
      element1.selectedListPrivilege.forEach((element2: any) => {
        dataListIdPrivilegeChecked.push(element2); // 51 and other code
      });
    });
    let newDataListIdPrivilegeChecked: any[] = Array.from(new Set(dataListIdPrivilegeChecked)); // remove same id code
    const data = {
      privilegeId: privilegeId,
      privilegeName: privilegeName,
      privilegeDesc: privilegeDesc,
      memberId: member,
      ccdCode: ccdCode,
    };
    const data2 = {
      privilegeId: privilegeId,
      privfId: newDataListIdPrivilegeChecked,
      privdStatus: "01",
    };
    const a = CheckAllFields([privilegeName, member, ccdCode], ['Privilege Name', 'Member', 'Privilege Level']);
    if (a !== "") {
      dispatchComponent({ type: 'errorMessage', value: a })
    } else {
      dispatchComponent({ type: 'errorMessage', value: '' })
      store.dispatch(loadingAction(true));
      let conditionDataIsChanged = false;
      let messageError = ''
      try {
        await API.update({
          bodyCustom: data,
          pathCustom: `privilege`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needNotification: false,
        })
        conditionDataIsChanged = true;
        messageError = ``
      } catch (error) {
        conditionDataIsChanged = false;
        messageError = ` ${error !== undefined ? error : ''}`
      }
      try {
        await API.update({
          bodyCustom: data2,
          pathCustom: `privilege_detail`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needNotification: false,
        })
        if (conditionDataIsChanged === false) conditionDataIsChanged = true;
        messageError = ``
      } catch (error) {
        if (messageError !== '') messageError = ` ${error !== undefined ? error : ''}`
        if (conditionDataIsChanged === true) conditionDataIsChanged = true;
        else if (conditionDataIsChanged === false) conditionDataIsChanged = false;
      }
      if (conditionDataIsChanged === false) store.dispatch(doubleAction({ condition: true, message: messageError }));
      else store.dispatch(successDoubleAction({ condition: true, message: `` }));
      store.dispatch(loadingAction(false));
    };
  }
  return (
    <div className="d-flex flex-column bg-ff bd-ea br-10 my-3">
      <div className="d-flex flex-column hg-60px px-2 pt-2">
        <p className="w-700 Lato-700 c-29 s-1820 mb-1">EDIT PRIVILEGE</p>
        <Divider />
        <div className="d-flex justify-content-between align-items-center flex-wrap" style={{ gap: 20 }}>
          <div className="flex-grow-1">
            <p className={labelStyle}>PRIVILEGE id</p>
            <input disabled readOnly value={stateComponent.privilegeId} onChange={(e) => dispatchComponent({ type: 'privilegeId', value: e.target.value })} className={inputStyleReadOnly} />
            <p className={labelStyle}>PRIVILEGE NAME</p>
            <input value={stateComponent.privilegeName} onChange={(e) => dispatchComponent({ type: 'privilegeName', value: e.target.value })} className={inputStyle} />
            <div >
              <p className={labelStyle}>Member</p>
              <Select styles={inputStyleH25} placeholder={'(Search)'} options={stateOptions.optionsMember} value={stateOptions.optionsMember.filter((option: any) => option.value === stateComponent.member)} onChange={(e) => dispatchComponent({ type: 'member', value: e.value })} />
            </div>
            <div >
              <p className={labelStyle}>Privilege CODE</p>
              <Select styles={inputStyleH25} placeholder={'(Search)'} options={stateOptions.optionsUserPrivilegeLevel} value={stateOptions.optionsUserPrivilegeLevel.filter((option: any) => option.value === stateComponent.ccdCode)} onChange={(e) => dispatchComponent({ type: 'ccdCode', value: e.value })} />
            </div>
            <p className={labelStyle}>DESCRIPTION</p>
            <textarea rows={4} value={stateComponent.privilegeDesc} onChange={(e) => dispatchComponent({ type: 'privilegeDesc', value: e.target.value })} className={inputDescriptionStyle} />
          </div>
          <div className="flex-grow-1" style={{ maxHeight: "400px", overflowY: "scroll" }}>
            {stateComponent.listAllPrivilege &&
              stateComponent.listAllPrivilege.map((element: any) => {
                return (
                  <div className="my-1">
                    {
                      element.checked === true ?
                        <div><input type="checkbox" checked className="c-54 text-uppercase mb6 s-1214 mx-2" onChange={(e) => handleState(element.valChecked, e)} /> <span className="s-1620">{element.label}</span></div>
                        :
                        <div><input type="checkbox" className="c-54 text-uppercase mb6 s-1214 mx-2" onChange={(e) => handleState(element.valChecked, e)} /> <span className="s-1620">{element.label}</span></div>
                    }

                    <div className="px-5 py-2 ">
                      {
                        element.listPrivilege &&
                        element.listPrivilege.map((listPrivilege: any) => {

                          let selectedList = stateComponent.listSelectedPrivilege.filter((elementSelected: any) => elementSelected.val === element.val)[0];
                          let listNumberCodePrivilege: any[] = []
                          element.listPrivilege.forEach((itemPrivilege: any) => {
                            if (itemPrivilege.id === "all") {
                            } else {
                              listNumberCodePrivilege.push(itemPrivilege.id);
                            }
                          })
                          let checkedAll = listNumberCodePrivilege.every((itemPrivilegeCode: any, index: number) => itemPrivilegeCode === selectedList?.selectedListPrivilege[index])
                          // view add, edit delete, download => listPrivilege.label
                          // 51, 52, 53, 54, 55, selectedList.selectedListPrivilege
                          // all, 51, 52. 53, 54, 55 listNumberCodePrivilege

                          return element.checked === true ?
                            selectedList.selectedListPrivilege.includes(listPrivilege.id) ?
                              <span className="s-1620 mx-1">
                                <input value={listPrivilege.id} type="checkbox" className="mx-2" checked={true} onChange={(e) => handleState(element.val, e)} />
                                {listPrivilege.label}
                              </span>
                              :
                              listPrivilege.label === 'all' ?
                                checkedAll === true ?
                                  <span className="s-1620 mx-1">
                                    <input value={listPrivilege.id} type="checkbox" checked={true} className="mx-2" onChange={(e) => handleState(element.val, e)} />
                                    {listPrivilege.label}
                                  </span>
                                  :
                                  <span className="s-1620 mx-1">
                                    <input value={listPrivilege.id} type="checkbox" checked={false} className="mx-2" onChange={(e) => handleState(element.val, e)} />
                                    {listPrivilege.label}
                                  </span>
                                :
                                <span className="s-1620 mx-1">
                                  <input value={listPrivilege.id} type="checkbox" checked={false} className="mx-2" onChange={(e) => handleState(element.val, e)} />
                                  {listPrivilege.label}
                                </span>
                            :
                            listPrivilege.label === 'all' ?
                              <span className="s-1620 mx-1">
                                <input value={listPrivilege.id} checked={false} type="checkbox" disabled className="mx-2  " />
                                {listPrivilege.label}
                              </span>
                              :
                              <span className="s-1620 mx-1">
                                <input value={listPrivilege.id} checked={false} type="checkbox" disabled className="mx-2" />
                                {listPrivilege.label}
                              </span>
                            ;
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <ErrorMessage errorMessage={stateComponent.errorMessage} />
        <Divider />
        <div className="d-flex justify-content-between">
          <button className="d-flex align-items-center px-3 py-2 btn bg-e8 " onClick={() => postData()}>
            {checklistButtonIcon}
            <p className="s-1418 c-ff Lato-600 px-1">SAVE</p>
          </button>
        </div>
      </div>
    </div>
  );
};
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }
const initialState: any = {
  errorMessage: "",
  // 
  privilegeId: "",
  privilegeName: "",
  privilegeDesc: "",
  member: null,
  ccdCode: null,
  // 
  listAllPrivilege: [],
  listSelectedPrivilege: [],
}


const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        privilegeId: action.value.privilegeId,
        privilegeName: action.value.privilegeName,
        privilegeDesc: action.value.privilegeDesc,
        member: action.value.member,
        ccdCode: action.value.ccdCode,
        listAllPrivilege: JSON.parse(listPrivilegePage),
        listSelectedPrivilege: JSON.parse(listPrivilegePageSelected),
      };

    case 'errorMessage':
      return {
        ...state,
        errorMessage: action.value,
      };
    case 'privilegeId':
      return {
        ...state,
        privilegeId: action.value,
      };
    case 'privilegeName':
      return {
        ...state,
        privilegeName: action.value,
      };
    case 'member':
      return {
        ...state,
        member: action.value,
      };
    case 'ccdCode':
      return {
        ...state,
        ccdCode: action.value,
      };
    case 'privilegeDesc':
      return {
        ...state,
        privilegeDesc: action.value,
      };
    case 'listAllPrivilege':
      return {
        ...state,
        listAllPrivilege: action.value,
      };
    case 'listSelectedPrivilege':
      return {
        ...state,
        listSelectedPrivilege: action.value,
      };
    default:
      throw new Error();
  }
}




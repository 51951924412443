import { createSlice } from "@reduxjs/toolkit";

const initialState = () => {
    let dateNow = new Date().toISOString().slice(0, 10);
    return {
        qrRefNo: "",
        QRAuthInqResponseCode: "",
        QRStatusSendToApi: "",
        QRStatus: "",
        stan: "",
        invNo: "",
        globalId: "",
        memberId: "",
        qrCpmPan: "",
        qrMpmMerId: "",
        filterStartDate: dateNow,
        filterEndDate: dateNow,
        search: "",
        selectedQRStandardId: "",
        selectedQRStandardName: "",
        selectedQRStandardSendToApi: "",
        selectedProductIndId: "",
        selectedProductIndLabel: "",
        selectedProductIndSendToApi: "",
        productInd: null,
        qrsId: ""
    };
};

export const qrAuthInquirySlice = createSlice({
    name: "qrAuthInquiry",
    initialState: initialState(),
    reducers: {
        changeStateQRAuthInquiry: (state, action) => {
            state.QRAuthInqResponseCode = action.payload.QRAuthInqResponseCode === undefined || action.payload.QRAuthInqResponseCode === null ? state.QRAuthInqResponseCode : action.payload.QRAuthInqResponseCode;
            state.QRStatusSendToApi = action.payload.QRStatusSendToApi === undefined || action.payload.QRStatusSendToApi === null ? state.QRStatusSendToApi : action.payload.QRStatusSendToApi;
            state.QRStatus = action.payload.QRStatus === undefined || action.payload.QRStatus === null ? state.QRStatus : action.payload.QRStatus;
            state.stan = action.payload.stan === undefined || action.payload.stan === null ? state.stan : action.payload.stan;
            state.invNo = action.payload.invNo === undefined || action.payload.invNo === null ? state.invNo : action.payload.invNo;
            state.globalId = action.payload.globalId === undefined || action.payload.globalId === null ? state.globalId : action.payload.globalId;
            state.memberId = action.payload.memberId === undefined || action.payload.memberId === null ? state.memberId : action.payload.memberId;
            state.qrCpmPan = action.payload.qrCpmPan === undefined || action.payload.qrCpmPan === null ? state.qrCpmPan : action.payload.qrCpmPan;
            state.qrMpmMerId = action.payload.qrMpmMerId === undefined || action.payload.qrMpmMerId === null ? state.qrMpmMerId : action.payload.qrMpmMerId;

            state.selectedQRStandardId = action.payload.selectedQRStandardId === undefined || action.payload.selectedQRStandardId === null ? state.selectedQRStandardId : action.payload.selectedQRStandardId;
            state.selectedQRStandardName = action.payload.selectedQRStandardName === undefined || action.payload.selectedQRStandardName === null ? state.selectedQRStandardName : action.payload.selectedQRStandardName;
            state.selectedQRStandardSendToApi = action.payload.selectedQRStandardSendToApi === undefined || action.payload.selectedQRStandardSendToApi === null ? state.selectedQRStandardSendToApi : action.payload.selectedQRStandardSendToApi;

            state.selectedProductIndId = action.payload.selectedProductIndId === undefined || action.payload.selectedProductIndId === null ? state.selectedProductIndId : action.payload.selectedProductIndId;
            state.selectedProductIndLabel = action.payload.selectedProductIndLabel === undefined || action.payload.selectedProductIndLabel === null ? state.selectedProductIndLabel : action.payload.selectedProductIndLabel;
            state.selectedProductIndSendToApi = action.payload.selectedProductIndSendToApi === undefined || action.payload.selectedProductIndSendToApi === null ? state.selectedProductIndSendToApi : action.payload.selectedProductIndSendToApi;

            state.qrRefNo = action.payload.qrRefNo === undefined || action.payload.qrRefNo === null ? state.qrRefNo : action.payload.qrRefNo;
            state.qrsId = action.payload.qrsId === undefined || action.payload.qrsId === null ? state.qrsId : action.payload.qrsId;
            state.productInd = action.payload.productInd === undefined || action.payload.productInd === null ? state.productInd : action.payload.productInd;
            state.filterStartDate = action.payload.filterStartDate === undefined || action.payload.filterStartDate === null ? state.filterStartDate : action.payload.filterStartDate;
            state.filterEndDate = action.payload.filterEndDate === undefined || action.payload.filterEndDate === null ? state.filterEndDate : action.payload.filterEndDate;
            state.search = action.payload.search === undefined || action.payload.search === null ? state.search : action.payload.search;
        },
        resetAllActionPage5: (state, action) => {
            state.QRAuthInqResponseCode = '';
            state.QRStatusSendToApi = '';
            state.QRStatus = '';
            state.stan = '';
            state.invNo = '';
            state.globalId = '';
            state.memberId = '';
            state.qrCpmPan = '';
            state.qrMpmMerId = '';

            state.selectedQRStandardId = '';
            state.selectedQRStandardName = '';
            state.selectedProductIndSendToApi = '';

            state.selectedProductIndId = '';
            state.selectedProductIndLabel = '';
            state.selectedProductIndSendToApi = '';

            state.qrRefNo = '';
            state.qrsId = '';
            state.productInd = null;
            state.filterStartDate = '';
            state.filterEndDate = '';
            state.search = '';
        }
    }
});

export const { changeStateQRAuthInquiry, resetAllActionPage5 } = qrAuthInquirySlice.actions;
export const stateGlobalQRAuthInquiry = (state: any) => state.qrAuthInquiry;
export default qrAuthInquirySlice.reducer;

import React, { useEffect, useReducer } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FooterModalUpdate } from '../../Components';
import { API } from '../../Services';
import { logoutAction } from '../../Store/User';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { CheckAllFields } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { urlSelection } from '../../Services/API';


export const ModalDraggableReasonCodeManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { showModal, itemModal, id, idModal, index, actionShowModal, actionIndexModal, actionAfterUpdateItem, urlApi } = props
    const { optionsRcGroup } = props.stateOptions

    const draggleRef = React.createRef<any>();


    useEffect(() => {

        dispatchStatePage({ 'type': 'visible', value: showModal })
        dispatchStatePage({ 'type': 'setData', value: itemModal })
    }, [statePage.visible !== showModal])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
    }, [id !== statePage.id])

    const onStart = (event: any, uiData: any) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        dispatchStatePage({
            'type': 'bounds', value: {
                left: -targetRect?.left + uiData?.x,
                right: clientWidth - (targetRect?.right - uiData?.x),
                top: -targetRect?.top + uiData?.y,
                bottom: clientHeight - (targetRect?.bottom - uiData?.y)
            }
        })
    };

    const handleState = (nameState: string | any, valueState: any) => {
        const { rcmApplyDate, rcmEndDate } = statePage;

        if (nameState === "rcmApplyDate") {
            let dateStart = new Date(valueState);
            let dateEnd = new Date(rcmEndDate);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }

            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: "Start date cannot be larger than End Date" })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else if (nameState === "rcmEndDate") {
            let dateStart = new Date(rcmApplyDate);
            let dateEnd = new Date(valueState);
            let listAllDayChart = [];
            for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                listAllDayChart.push(d.toISOString().slice(0, 10));
            }
            if (listAllDayChart.length === 0) {
                dispatchStatePage({ type: 'errorMessage', value: "Start date cannot be larger than End Date" })

            } else {
                dispatchStatePage({ type: nameState, value: valueState })
                dispatchStatePage({ type: 'errorMessage', value: "" })
            }
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }
    const updateDataAPI = () => {
        const { rcId, rcGroup, rcCode, rcName, rcDesc } = statePage;
        const data = {
            rcId: `${id}`,
            rcGroup: `${rcGroup}`,
            rcCode: `${rcCode}`,
            rcName: `${rcName}`,
            rcDesc: `${rcDesc}`,
        };
        API.update({
            bodyCustom: data,

            pathCustom: `${urlApi}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,

        }).then((response) => {
            actionAfterUpdateItem(id)
            actionShowModal()
        }).catch((err) => handleState('errorMessage', err))
    };

    return (
        <Modal
            mask={false}
            maskClosable={false}
            keyboard={false}
            wrapClassName="aaa"
            width={800}
            style={{
                position: 'fixed',
                left: (document.body.clientWidth - 500) / 2,
                height: '0px',
                width: '0px',
                zIndex: index,
            }}
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move'
                    }}
                    onMouseOver={() => {
                        if (statePage.disabled) {
                            handleState('disabled', false)
                        }
                    }}
                    onMouseOut={() => {
                        handleState('disabled', true)
                    }}
                >
                    <div className="flex justify-between items-end">
                        <div className="flex">
                            <div className="flex flex-col justify-between ml-4">
                                <div>{statePage.titleModal}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            footer={null}
            open={statePage.visible}
            onOk={() => actionShowModal()}
            onCancel={() => actionShowModal()}
            modalRender={(modal) => (
                <Draggable
                    disabled={statePage.disabled}
                    bounds={statePage.bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef} onClick={() => actionIndexModal()}>
                        {modal}
                    </div>
                </Draggable>
            )}
        >

            <div className='d-flex flex-column min-h-60px'>
                <div className='row py-2 gy-2 gx-3 mx-0 mb-4'>
                    <div className="col-6">
                        <p className={labelStyle}>reason code</p>
                        <input value={statePage.rcCode} onChange={(e) => handleState("rcCode", e.target.value)} className={inputStyle} />
                    </div>
                    <div className="col-6">
                        <p className={labelStyle}>reason name</p>
                        <input value={statePage.rcName} onChange={(e) => handleState("rcName", e.target.value)} className={inputStyle} />
                    </div>
                    <div className="col-12">
                        <p className={labelStyle}>Reason Group Code</p>
                        <Select
                            styles={inputStyleH25}
                            placeholder={"(Search Institution)"}
                            options={optionsRcGroup}
                            value={optionsRcGroup.filter((option: any) => option.value === `${statePage.rcGroup}`)}
                            onChange={(e) => handleState("rcGroup", e.value)}
                        />
                    </div>
                    <div className="col-12">
                        <p className={labelStyle}>DESCRIPTION</p>
                        <textarea rows={4} value={statePage.rcDesc} onChange={(e) => handleState("rcDesc", e.target.value)} className={inputDescriptionStyle} />
                    </div>
                </div>
                <FooterModalUpdate errorMessage={statePage.errorMessage} actionShowModal={() => actionShowModal()} handleUpdateAPI={() => updateDataAPI()} />
            </div>
        </Modal>
    );

}

const initialState = {
    titleModal: 'Edit Reason Code',
    visible: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    errorMessage: '',
    //
    rcId: "",
    rcGroup: "",
    rcCode: "",
    rcName: "",
    rcDesc: "",
    //
    id: ''
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'titleModal':
            return {
                ...state,
                titleModal: action.value,
            };
        case 'visible':
            return {
                ...state,
                visible: action.value,
            };
        case 'disabled':
            return {
                ...state,
                disabled: action.value,
            };
        case 'bounds':
            return {
                ...state,
                bounds: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                rcId: "",
                rcGroup: "",
                rcCode: "",
                rcName: "",
                rcDesc: "",
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                rcId: action.value.rcId,
                rcGroup: action.value.rcGroup,
                rcCode: action.value.rcCode,
                rcName: action.value.rcName,
                rcDesc: action.value.rcDesc,
                errorMessage: "",
            };
        // 
        case 'rcId':
            return {
                ...state,
                rcId: action.value,
            };
        case 'rcGroup':
            return {
                ...state,
                rcGroup: action.value,
            };
        case 'rcCode':
            return {
                ...state,
                rcCode: action.value,
            };
        case 'rcName':
            return {
                ...state,
                rcName: action.value,
            };
        case 'rcDesc':
            return {
                ...state,
                rcDesc: action.value,
            };
        default:
            throw new Error();
    }
}





import { useEffect, useReducer } from "react";
import { applicationTwoIcon, checkedIconGreen, close_red, leavesIcon, timeIconYellow } from "../../../Assets/Images/svg";
import { CheckUndefined, NumberWithCommas } from "../../../Utils";

export const DetailStatusTransactionRTM = ({ ...props }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        let dataCard: any[] = [];
        let transactionCount = 0;

        if (props.stateOptions.optionsSAFStatusDetailCode.length > 0) {
            props.stateOptions.optionsSAFStatusDetailCode.forEach((element1: any) => {
                if (props.data.length > 0) {
                    if (element1.value !== '') {
                        let dataGroupTransaction: any = {
                            id: element1.value,
                            name: element1.label,
                            transactionCount: 0,
                            sortNumber: element1.sortNumber,
                            iconTransaction: null,
                            data: []
                        };
                        if (element1.value === '001' || element1.value === '002') dataGroupTransaction.iconTransaction = timeIconYellow
                        if (element1.value === '000') dataGroupTransaction.iconTransaction = checkedIconGreen
                        if (element1.value === '004' || element1.value === '005' || element1.value === '006') dataGroupTransaction.iconTransaction = close_red
                        props.data.forEach((element2: any) => {
                            if (element2.safStatusCode === element1.value) {
                                if (element2.transCount !== undefined)
                                    if (element2.transCount !== null)
                                        if (element2.transCount !== '') {
                                            transactionCount = transactionCount + element2.transCount
                                            dataGroupTransaction.transactionCount = dataGroupTransaction.transactionCount + element2.transCount
                                        }
                                dataGroupTransaction.data.push(element2)
                            }
                        })
                        // dataCard.push(dataGroupTransaction)
                        dataCard.push(dataGroupTransaction)
                    }
                }
            })
        }
        dispatch({ type: 'dataCard', value: dataCard.sort((a, b) => a.sortNumber - b.sortNumber) })
        dispatch({ type: 'transactionCount', value: transactionCount })
    }, [props.stateOptions.optionsSAFStatusDetailCode, props.data])


    if (props.stateOptions.optionsSAFStatusDetailCode.length > 0) {
        if (state.dataCard.length > 0) {
            return (
                <>
                    {state.dataCard && (
                        <div className="row gx-2 gy-1 my-2">
                            {state.dataCard.map((element1: any) => {
                                // let percentageTransaction = element1.transactionCount / state.transactionCount * 100
                                let percentageTransaction = element1.data.length / props.data.length * 100
                                // let showPercentage = isFinite(percentageTransaction) ? (percentageTransaction).toFixed(2) : 0.0;
                                let showPercentage = isFinite(percentageTransaction) ? (percentageTransaction).toFixed(2) : 0.0;
                                let groupingTransactionByCurrency: any[] = [];
                                props.stateOptions.optionsCurrency.forEach((element2: any) => {
                                    let dataGroupTransactionByCurr: any = {
                                        id: element2.value,
                                        name: element2.label,
                                        data: []
                                    };
                                    element1.data.forEach((element3: any) => {
                                        if (element3.transCurrency === '-') {
                                        } else if (element3.transCurrency === '') {
                                        } else if (element3.transCurrency === undefined) {
                                        } else if (element3.transCurrency === null) {
                                        } else if (element3.transCurrency === element2.label) {
                                            dataGroupTransactionByCurr.data.push(element3)
                                        }
                                    })
                                    groupingTransactionByCurrency.push(dataGroupTransactionByCurr)
                                })
                                return (
                                    <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12   " >
                                        <div className="d-flex justify-content-center " >
                                            <div className="px-2 pt-2 bd-ea br-20" style={{ width: '95%' , minHeight: '280px' }}>
                                                <div className="d-flex hg-80">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div className=" bd-ea p-2 br-20 d-flex justify-content-center align-items-center" style={{ height: "40px", width: "40px" }}>
                                                            {element1.iconTransaction === null ? '' : element1.iconTransaction}
                                                        </div>
                                                    </div>
                                                    <div className="mx-2"></div>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div>
                                                            <div className="Lato-400 text-secondary">{element1.name} Transaction</div>
                                                            <div>
                                                                <strong className="">{showPercentage}%</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />

                                                <div className="d-flex h-50px">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div className=" bd-ea p-2 br-20 d-flex justify-content-center align-items-center" style={{ height: "40px", width: "40px" }}>
                                                            {applicationTwoIcon}
                                                        </div>
                                                    </div>
                                                    <div className="mx-2"></div>
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div>
                                                            <div className="Lato-400 text-secondary">Total Transactions</div>
                                                            <div>
                                                                <strong className="">{element1.data.length}</strong>
                                                                {/* <strong className="">{element1.transactionCount}</strong> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex h-50px">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div className=" bd-ea p-2 br-20 d-flex justify-content-center align-items-center" style={{ height: "40px", width: "40px" }}>
                                                            {leavesIcon}
                                                        </div>
                                                    </div>
                                                    <div className="mx-2"></div>
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="w-100">
                                                            <div className="Lato-400 text-secondary">Total Amount</div>
                                                            <div>
                                                                {groupingTransactionByCurrency.map((element2: any) => {
                                                                    if (element2.data.length > 0) {
                                                                        let countAllTransactionSameCurr = 0;
                                                                        element2.data.forEach((element3: any) => {
                                                                            if (element3.transAmount !== null)
                                                                                if (element3.transAmount !== '')
                                                                                    if (element3.transAmount !== undefined)
                                                                                        // countAllTransactionSameCurr = countAllTransactionSameCurr + NumberWithCommas(CheckUndefined(element3.transAmount))
                                                                                        countAllTransactionSameCurr = countAllTransactionSameCurr + element3.transAmount
                                                                        })
                                                                        return (
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="Lato-400">{element2.name === "" ? "-" : element2.name}</div>
                                                                                <div className="text-end">
                                                                                    <strong className="text-end">{NumberWithCommas(CheckUndefined(countAllTransactionSameCurr))}</strong>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )
        } else {
            let newOptionsSAFStatusDetailCode: any = []
            props.stateOptions.optionsSAFStatusDetailCode.forEach((item: any) => {
                let dataGroupTransaction: any = {
                    id: item.value,
                    name: item.label,
                    transactionCount: 0,
                    sortNumber: item.sortNumber,
                    iconTransaction: null,
                };
                if (item.value === '001' || item.value === '002') dataGroupTransaction.iconTransaction = timeIconYellow
                if (item.value === '000') dataGroupTransaction.iconTransaction = checkedIconGreen
                if (item.value === '004' || item.value === '005' || item.value === '006') dataGroupTransaction.iconTransaction = close_red
                newOptionsSAFStatusDetailCode.push(dataGroupTransaction);
            })

            return (
                <div className="row gx-2 gy-1 my-2">

                    {
                        newOptionsSAFStatusDetailCode.map((item: any) => {
                            return (
                                <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-xs-12   " >
                                    <div className="d-flex justify-content-center " >
                                        <div className="px-2 pt-2 bd-ea br-20 " style={{ width: '95%' , minHeight: '280px' }}>
                                            <div className="d-flex hg-80">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className=" bd-ea p-2 br-20 d-flex justify-content-center align-items-center" style={{ height: "40px", width: "40px" }}>
                                                        {item.iconTransaction === null ? '' : item.iconTransaction}
                                                    </div>
                                                </div>
                                                <div className="mx-2"></div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div>
                                                        <div className="Lato-400 text-secondary">{item.name} Transaction</div>
                                                        <div>
                                                            <strong className="">{0}%</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="d-flex h-50px">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className=" bd-ea p-2 br-20 d-flex justify-content-center align-items-center" style={{ height: "40px", width: "40px" }}>
                                                        {applicationTwoIcon}
                                                    </div>
                                                </div>
                                                <div className="mx-2"></div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div>
                                                        <div className="Lato-400 text-secondary">Total Transactions</div>
                                                        <div>
                                                            <strong className="">{item.transactionCount}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="d-flex h-50px">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className=" bd-ea p-2 br-20 d-flex justify-content-center align-items-center" style={{ height: "40px", width: "40px" }}>
                                                        {leavesIcon}
                                                    </div>
                                                </div>
                                                <div className="mx-2"></div>
                                                <div className="d-flex align-items-center w-100">
                                                    <div className="w-100">
                                                        <div className="Lato-400 text-secondary">Total Amount</div>
                                                        <div>
                                                            0


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            )
        }
    } else {
        return <></>
    }

}

const initialState = {
    dataCard: [],
    transactionCount: 0,
};
const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'dataCard':
            return {
                ...state,
                dataCard: action.value
            };
        case 'transactionCount':
            return {
                ...state,
                transactionCount: action.value
            };
        default:
            throw new Error();
    }
}
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { errNotificationIcon, icon_search_white } from '../../Assets/Images/svg';
import { InsideComponentPage } from '../../Components';
import { API, urlSelection } from '../../Services/API';
import {
    addData,
    checkIndex,
    deleteData,
    handleState,
    initFunction,
    reducerOptions,
    reducerStateSelected,
    setModal,
    setToggle,
    stateOptions,
    stateSelected
} from '../../State';
import { labelStyle } from '../../Styles';
import { InterfaceOptionsMemberPayment } from './InterfaceOptionsMemberPayment';
import { ModelMemberPayment } from './ModelMemberPayment';
import { TableMemberPayment } from './TableMemberPayment';

export const MemberPayment = ({...props}) => {
    const dispatchGlobal = useDispatch();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [stateOptions, dispatchOptions] = useReducer(reducerOptions, initialStateOptions);
    const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, initialStateCustomComponent);
    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])
    const initFunctionCustom = (needRefreshPage: Boolean | null) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let selectedSourceMember = stateCustomComponent.selectedSourceMember !== '' ? `sourceMember=${stateCustomComponent.selectedSourceMember}&` : '';
        let selectedReceivingMember = stateCustomComponent.selectedReceivingMember !== '' ? `receivingMember=${stateCustomComponent.selectedReceivingMember}&` : '';
        let selectedReceivedDate = stateCustomComponent.selectedReceivedDate !== '' ? `receivedDate=${stateCustomComponent.selectedReceivedDate}&` : '';
        let selectedTransferDate = stateCustomComponent.selectedTransferDate !== '' ? `transferDate=${stateCustomComponent.selectedTransferDate}&` : '';
        let finalParameter = `${search}${selectedSourceMember}${selectedReceivingMember}${selectedReceivedDate}${selectedTransferDate}`;
        dispatch({ type: 'errorMessage', value: '' });
        if (needRefreshPage === true) {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => dispatch({ type: 'searchData', value: response.data }))
        } else {
            API.get({
                bodyCustom: null,
                pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response: any) => dispatch({ type: 'data', value: response.data }))
        }
    }

    const afterUpdate = (updateItemID: any) => {
        let search = state.search !== '' ? `search=${state.search}&` : '';
        let finalParameter = `${search}`;

        API.get({
            bodyCustom: null,
            pathCustom: `${state.urlApi}?${finalParameter.slice(0, -1)}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response: any) => dispatch({ type: 'afterUpdate', value: { data: response.data, updateItemID: updateItemID } }))
    }

    const afterUpdateItem = (updateItemID: any) => {
        initFunction(dispatchOptions, state, () => afterUpdate(updateItemID), null, InterfaceOptionsMemberPayment, dispatchGlobal)
    }
    useEffect(() => {
        initFunction(dispatchOptions, state, () => {}, null, InterfaceOptionsMemberPayment, dispatchGlobal)
    }, [])


    useEffect(() => {
        initFunctionCustom(true)
    }, [state.search, stateCustomComponent.selectedStartDate, stateCustomComponent.selectedEndDate, stateCustomComponent.selectedMember])

    useEffect(() => {
        let totalPages: number = Math.ceil(state.data.length / state.view);
        let currentPage: number = parseInt((state.position / state.view).toString());
        dispatch({ type: 'changeData', value: { totalPages, currentPage } });
    }, [state.data.length, state.position, state.view])

    const exportToCSVComponent = () => { }
    const addDataComponent = () => addData(dispatch)
    const deleteDataComponent = (item: ModelMemberPayment) => deleteData(item.id, item, dispatch)
    const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
    const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
    const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
    const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
    const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsMemberPayment, dispatchGlobal)
    const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
    const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
    const actionAfterUpdateItem = (updateItemID: any) => afterUpdateItem(updateItemID)

    const customButtonAction = (item: ModelMemberPayment, width: string | any) => {
        return (
            <div className={`d-flex justify-content-around align-items-center ${width}`}>
            </div>
        );
    };

    const handleButtonSearch = () => {
        if (stateCustomComponent.selectedSourceMember === '' && stateCustomComponent.selectedReceivingMember === '') initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsMemberPayment, dispatchGlobal)
        else if (stateCustomComponent.selectedSourceMember !== stateCustomComponent.selectedReceivingMember) initFunction(dispatchOptions, state, () => initFunctionCustom(true), null, InterfaceOptionsMemberPayment, dispatchGlobal)
        else dispatch({ type: 'errorMessage', value: 'SOURCE MEMBER AND RECEIVING MEMBER can\'t be the same!' });
    }



    const filterComponentPage = () => {
        return (
            <div className="gx-5 row my-2 py-3" style={{ background: "white", border: "none" }}>
                <div className="row my-10 p-0">
                    <div className="col-md-3 col-sm-12">
                        <p className={labelStyle}>RECEIVED DATE</p>
                        <input value={stateOptions.selectedReceivedDate} onChange={(e) => handleState('selectedReceivedDate', e.target.value, dispatchCustomComponent)} type='date' className='bd-d4 bg-ff br-5 h-40px w-100 s-1418 Lato-400 w-500 out-none px-2' />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <p className={labelStyle}>TRANSFER DATE</p>
                        <input value={stateOptions.selectedTransferDate} onChange={(e) => handleState('selectedTransferDate', e.target.value, dispatchCustomComponent)} type='date' className='bd-d4 bg-ff br-5 h-40px w-100 s-1418 Lato-400 w-500 out-none px-2' />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <p className={labelStyle}>SOURCE MEMBER</p>
                        <Select options={stateOptions.optionsMemberCode} value={stateOptions.optionsMemberCode.filter((option: any) => option.value === stateCustomComponent.selectedSourceMember)} onChange={(e) => handleState("selectedSourceMember", e.value, dispatchCustomComponent)} />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <p className={labelStyle}>RECEIVING MEMBER</p>
                        <Select options={stateOptions.optionsMemberCode} value={stateOptions.optionsMemberCode.filter((option: any) => option.value === stateCustomComponent.selectedReceivingMember)} onChange={(e) => handleState("selectedReceivingMember", e.value, dispatchCustomComponent)} />
                    </div>
                </div>
                {
                    state.errorMessage !== '' && (
                        <div className="row mby-2 p-0">
                            <div className="col-md-12 col-sm-12 d-flex align-items-center">
                                <div className='d-flex bd-e8 br-10 px-3 py-2 align-items-center wd-fit my-2'>
                                    {errNotificationIcon}
                                    <p className='ml-10 s-1418 Lato-600 c-e87'>{state.errorMessage}</p>
                                </div>

                            </div>
                        </div>
                    )
                }
                <div className="row my-4 p-0">
                    <div className="col-md-3 col-sm-12 d-flex align-items-center">
                        <button className="d-flex align-items-center py-3 px-4 br-20 bd-d4 bg-e8 bd-29 text-white" onClick={(e) => handleButtonSearch()}>
                            {icon_search_white}
                            <p className="s-1418 c-ff Lato-600 mx-2">SEARCH</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    const tableComponentPage = () => {
        return (
            <TableMemberPayment
                codeAccessPrivilegeDelete={state.privilegeAccess.delete}
                codeAccessPrivilegeEdit={state.privilegeAccess.edit}
                label={state.label}
                data={state.data}
                position={state.position}
                view={state.view}
                customButtonAction={customButtonAction}
                checkIndex={checkIndexComponent}
                widthTable={`wd-${state.widthTable}-responsive`}
                stateOptions={stateOptions}
            />
        )
    }
    const modalAddDeleteComponentPage = () => {
        return (
            <></>
        )
    }
    const modalUpdateComponentPage = () => {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <InsideComponentPage
                state={state}
                stateOptions={stateOptions}
                customButtonAction={customButtonAction}
                exportToCSVComponent={exportToCSVComponent}
                addDataComponent={addDataComponent}
                checkIndexComponent={checkIndexComponent}
                handleStateComponent={handleStateComponent}
                setToggleComponent={setToggleComponent}
                setModal={setModalComponent}
                // component
                tableComponentPage={tableComponentPage}
                filterComponentPage={filterComponentPage}
                modalAddDeleteComponentPage={modalAddDeleteComponentPage}
                modalUpdateComponentPage={modalUpdateComponentPage}
                // 
                needComponentHeaderTable={true}
                needComponentFilterTable={true}
                needComponentTable={true}
                needComponentFooterTable={true}
                //
                needToggleFunction={false}
                needAddButton={false}
                needSearchFunction={false}
                needDownloadButton={false}
            />
        </>
    );

}
const initialStateOptions = { ...stateOptions }
const initialStateCustomComponent = { ...stateSelected }

const initialState = {
    urlApi: 'payment',
    titlePage: 'Member Payment',
    privilegeAccess: { view: '461', add: '', edit: '', delete: '', download: '' },
    label: [
        { field: 'No.', width: 'wd-50', val: 'no' },
        { field: 'Payment Code', width: 'wd-100', val: 'paymentCode' },
        { field: 'Fund Type', width: 'wd-100', val: 'fundType' },
        { field: 'Settle Date', width: 'wd-100', val: 'settleDate' },
        { field: 'Payment Receive Date', width: 'wd-200', val: 'paymentReceiveDate' },
        { field: 'Receive Amount', width: 'wd-150', val: 'receiveAmount' },
        { field: 'Source Member', width: 'wd-100', val: 'sourceMemberName' },
        { field: 'From Account', width: 'wd-100', val: 'fromAccount' },
        { field: 'Payment Transfer Date', width: 'wd-200', val: 'paymentTrfDate' },
        { field: 'Transfer Amount', width: 'wd-150', val: 'transferAmount' },
        { field: 'Receiving Member', width: 'wd-150', val: 'receivingMemberName' },
        { field: 'To Account', width: 'wd-100', val: 'toAccount' },
        { field: 'Conv Rate', width: 'wd-100', val: 'convRate' },
        { field: 'Payment Status', width: 'wd-100', val: 'paymentStatus' },
        { field: 'Payment Info', width: 'wd-100', val: 'paymentInfo' },
    ],
    toggle: ['no', 'paymentCode', 'fundType', 'settleDate', 'paymentReceiveDate', 'receiveAmount', 'sourceMemberName', 'fromAccount', 'paymentTrfDate', 'transferAmount', 'receivingMemberName', 'toAccount', 'convRate', 'paymentStatus', 'paymentInfo'],
    complete_toggle: ['no', 'paymentCode', 'fundType', 'settleDate', 'paymentReceiveDate', 'receiveAmount', 'sourceMemberName', 'fromAccount', 'paymentTrfDate', 'transferAmount', 'receivingMemberName', 'toAccount', 'convRate', 'paymentStatus', 'paymentInfo'],
    list_column_name: ['No.', 'Payment Code', 'Fund Type', 'Settle Date', 'Payment Receive Date', 'Receive Amount', 'Source Member', 'Source Member', 'From Account', 'Payment Transfer Date', 'Transfer Amount', 'Receiving Member', 'To Account', 'Conv Rate', 'Payment Status', 'Payment Info'],


    visibleModalAddDelete: false,
    showModal: [],
    // 
    id: '',
    data: [],
    dataModal: null,
    search: '',
    view: 10,
    position: 10,
    list_view: [10, 15, 20],
    totalPages: 0,
    currentPage: 0,
    insertPage: false,
    deleteItem: false,
    // 
    widthTable: 2000,
    errorMessage: '',

};


function reducer(state: any, action: any) {
    let showDataResult: any[] = [];
    let dataWhereChangeToIdGeneral: any[] = [];
    let showModal = state.showModal;
    let newShowModal: any[] = [];
    switch (action.type) {
        case 'data':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelMemberPayment) => {
                        showDataResult.push(
                            { idModal: null, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = null;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'searchData':
            if (action.value != null) {
                if (action.value.length > 0) {
                    action.value.forEach((element1: ModelMemberPayment) => {
                        let i = 1;
                        showDataResult.push(
                            { idModal: null, showModal: false, itemModal: element1, index: 1 }
                        )
                        element1.id = null;
                        dataWhereChangeToIdGeneral.push(element1);
                    })
                }
            }
            return {
                ...state,
                data: dataWhereChangeToIdGeneral,
                position: state.view,
                showModal: showDataResult,
                dataLength: dataWhereChangeToIdGeneral.length,
            };
        case 'showModal':
            if (showModal !== undefined) {
                if (showModal !== null) {
                    if (showModal.length > 0) {
                        showModal.forEach((element1: any | {}) => {
                            if (element1.idModal === action.value) {
                                if (element1.showModal === false) { // will to show
                                    newShowModal.push({ idModal: element1.idModal, showModal: !(element1.showModal), itemModal: element1.itemModal, index: state.dataLength + 99 })
                                } else {
                                    newShowModal.push({ idModal: element1.idModal, showModal: !(element1.showModal), itemModal: element1.itemModal, index: 99 })
                                }
                            } else {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                showModal: newShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'changeIndex':
            if (showModal !== undefined) {
                if (showModal !== null) {
                    if (showModal.length > 0) {
                        showModal.forEach((element1: any | {}) => {
                            if (element1.idModal === action.value) {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: state.dataLength + 99 })
                            } else {
                                newShowModal.push({ idModal: element1.idModal, showModal: element1.showModal, itemModal: element1.itemModal, index: 99 })
                            }
                        })
                    }
                }
            }
            return {
                ...state,
                showModal: newShowModal,
                dataLength: state.dataLength + 1,
            };
        case 'afterUpdate':
            let dataFilter: any[] = [];
            dataFilter = action.value.data.filter((element1: ModelMemberPayment) => null === action.value.updateItemID)
            let resultIdFilter = dataFilter[0].elementId;
            dataFilter[0].id = resultIdFilter;
            return {
                ...state,
                showModal: state.showModal.map((element1: any | {}) => {
                    if (element1.idModal === action.value.updateItemID) {
                        return { idModal: resultIdFilter, showModal: !(element1.showModal), itemModal: dataFilter[0], index: 1 }
                    } else {
                        return element1;
                    }
                }),
                data: state.data.map((element1: any | {}) => {
                    if (element1.id === action.value.updateItemID) {
                        return dataFilter[0];
                    } else {
                        return element1;
                    }
                })
            };
        // 
        case 'widthTable':
            return {
                ...state,
                widthTable: action.value
            };
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value
            };
        case 'id':
            return {
                ...state,
                id: action.value
            };
        case 'label':
            return {
                ...state,
                label: action.value
            };
        case 'toggle':
            return {
                ...state,
                toggle: action.value
            };
        case 'complete_toggle':
            return {
                ...state,
                complete_toggle: action.value
            };
        case 'list_column_name':
            return {
                ...state,
                list_column_name: action.value
            };
        case 'privilegeAccess':
            return {
                ...state,
                privilegeAccess: action.value
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value
            };
        case 'list_view':
            return {
                ...state,
                list_view: action.value
            };
        case 'view':
            return {
                ...state,
                view: action.value
            };
        case 'position':
            return {
                ...state,
                position: action.value
            };
        case 'search':
            return {
                ...state,
                search: action.value
            };
        case 'dataModal':
            return {
                ...state,
                dataModal: action.value
            };
        case 'insertPage':
            return {
                ...state,
                insertPage: action.value
            };
        case 'addData':
            return {
                ...state,
                id: '',
                dataModal: null,
                visibleModalAddDelete: !(state.visibleModalAddDelete),
            };
        case 'editData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                visibleModalAddDelete: true,
            };
        case 'deleteData':
            return {
                ...state,
                id: action.value.id,
                dataModal: action.value.dataModal,
                deleteItem: true,
            };
        case 'changeData':
            return {
                ...state,
                totalPages: action.value.totalPages,
                currentPage: action.value.currentPage,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        default:
            throw new Error();
    }
}



// InterfaceFileItem

interface InterfaceFileItem {
    id: null | number | string;
    interfaceFileItemId: number | null;
    interfileItemName: string | null;
    interfileItemSize: number | null;
    interfileItemType: string | null;
    interfileItemPad: string | null;
    interfileItemPadOpt: string | null;
    interfileItemMappingCode: string | null;
    interfileItemCreateBy: number | null;
    interfileItemCreateDate: string | null;
    interfileItemUpdateBy: number | null;
    interfileItemUpdateDate: string | null;
    interfileItemDeleteBy: number | null;
    interfileItemDeleteDate: string | null;
    interfileItemIsDelete: Boolean | null;

}

export class ModelInterfaceFileItem {
    id: null | number | string;
    interfaceFileItemId: number | null;
    interfileItemName: string | null;
    interfileItemSize: number | null;
    interfileItemType: string | null;
    interfileItemPad: string | null;
    interfileItemPadOpt: string | null;
    interfileItemMappingCode: string | null;
    interfileItemCreateBy: number | null;
    interfileItemCreateDate: string | null;
    interfileItemUpdateBy: number | null;
    interfileItemUpdateDate: string | null;
    interfileItemDeleteBy: number | null;
    interfileItemDeleteDate: string | null;
    interfileItemIsDelete: Boolean | null;

    constructor(value: InterfaceFileItem | null) {
        this.id = value?.id ?? null;

        this.interfaceFileItemId = value?.interfaceFileItemId ?? null;
        this.interfileItemName = value?.interfileItemName ?? null;
        this.interfileItemSize = value?.interfileItemSize ?? null;
        this.interfileItemType = value?.interfileItemType ?? null;
        this.interfileItemPad = value?.interfileItemPad ?? null;
        this.interfileItemPadOpt = value?.interfileItemPadOpt ?? null;
        this.interfileItemMappingCode = value?.interfileItemMappingCode ?? null;
        this.interfileItemCreateBy = value?.interfileItemCreateBy ?? null;
        this.interfileItemCreateDate = value?.interfileItemCreateDate ?? null;
        this.interfileItemUpdateBy = value?.interfileItemUpdateBy ?? null;
        this.interfileItemUpdateDate = value?.interfileItemUpdateDate ?? null;
        this.interfileItemDeleteBy = value?.interfileItemDeleteBy ?? null;
        this.interfileItemDeleteDate = value?.interfileItemDeleteDate ?? null;
        this.interfileItemIsDelete = value?.interfileItemIsDelete ?? null;
    }
}

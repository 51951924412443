import { MaterialReactTable, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from "material-react-table";
import { ButtonAdd, SelectView } from "../../../Components";
import { useMemo } from "react";
import { CheckUndefined } from "../../../Utils";
import { HeaderDataTable } from "../../../Components/HeaderDataTable";

export const TableFee = ({ ...props }) => {
    const {
        state,
        addDataComponent,
        exportToCSVComponent,
        customButtonAction,
        handleStateComponent,
        checkIndexComponent,
        setPagination,
        pagination,
    } = props;
    const columns: any = useMemo(
        () => [
            {
                header: 'Fee ID',
                accessorKey: 'feeId',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.feeId)}`,
            },
            {
                header: 'Fee Type',
                accessorKey: 'feeType',
                size: 'auto',
                Cell: ({ row }: any) => {
                    let dataReturn = '';
                    if (props.stateOptions.optionsFeeRateInputTypeDetailCode.length > 0) {
                        props.stateOptions.optionsFeeRateInputTypeDetailCode.forEach((element2: any) => {
                            if (element2.value === row.original.feeType) {
                                dataReturn = `${element2.label}`;
                            }
                        });
                    }
                    return(
                        <>
                            {CheckUndefined(dataReturn)}
                        </>
                    )
                },
            },
            {
                header: 'Status',
                accessorKey: 'feeStatus',
                size: 'auto',
                Cell: ({ row }: any) => {
                    let dataReturn = '';
                    if (props.stateOptions.optionsCommonStatusDetailCode.length > 0) {
                        props.stateOptions.optionsCommonStatusDetailCode.forEach((element2: any) => {
                            if (element2.value === row.original.feeStatus) {
                                dataReturn = `${element2.label}`;
                            }
                        });
                    }
                    return(
                        <>
                            {CheckUndefined(dataReturn)}
                        </>
                    )
                },
            },
            {
                header: 'Description',
                accessorKey: 'feeDesc',
                size: 'auto',
                Cell: ({ row }: any) =>
                    `${CheckUndefined(row.original.feeDesc)}`,

            },
        ],
        [props.stateOptions]
    );

    const table = useMaterialReactTable({
        columns,
        data: state.data,
        state: { pagination, globalFilter: state.search || '' },
        initialState: {
            density: 'compact',
        },
        enableColumnActions: false,
        enableClickToCopy: false,
        enableEditing: false,
        enableHiding: false,
        enableRowActions: true,
        manualFiltering: true,
        manualPagination: true,
        rowCount: state.totalData,
        onPaginationChange: setPagination,
        enableRowNumbers: true,
        paginationDisplayMode: 'pages',
        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        positionActionsColumn: "last",

        muiTableBodyRowProps: ({ row }) => {
            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);
            return {
                hover: false,
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif',
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            },
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        muiTableFooterProps: {
            sx: {
                fontSize: '12px !important',
                fontFamily: 'system-ui, sans-serif',
            },
        },
        muiSearchTextFieldProps: {
            placeholder: `Search By ${state.SearchByPlaceHolder}`,
            title: `Search By ${state.SearchByPlaceHolder}`,
            
        },

        onGlobalFilterChange: (filterValue) => {
            if(filterValue === undefined) {
                handleStateComponent('search', '')
            } else {
                handleStateComponent('search', filterValue)
            }
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <>
                <div className="d-flex">
                    <HeaderDataTable
                        stateParent={state}
                        data={state.data}
                        totalData={state.totalData}
                        label={state.label}
                        titleTable={'List'}
                        privilegeAccess={state.privilegeAccess}
                        addData={addDataComponent}
                        checkIndex={checkIndexComponent}
                        exportToCSV={exportToCSVComponent}
                        handleState={handleStateComponent}
                        needToggleFunction={false}
                        needAddButton={true}
                        needSearchFunction={true}
                        needDownloadButton={true}
                        table={table}
                    />
                </div>
            </>
        ),

        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleGlobalFilterButton table={table} />
                <div className="h-30px w-30px">
                    <div className="h-100">
                        <ButtonAdd handleClick={addDataComponent} codeAccessPrivilege={state.privilegeAccess.add} />
                    </div>

                </div>
            </>
        ),

        renderRowActions: (table: any) => (
            customButtonAction(table.row.original)
        ),

    });

    return (
        <>
            <div id="table" className="mt-1 mb-5 position-relative">
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '12px' }}>
                    <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
        </>
    )
}
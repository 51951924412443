import { API, urlSelection } from '../../../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page51Title } from '../../../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../../../Assets/Images/svg';
import { loadingAction } from '../../../../Store/Loading';
import { logoutAction } from '../../../../Store/User';

export const ModalHmacManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMember, optionsCommonStatusDetailCode, optionsHashType, optionsEnvironmentType } = props.stateOptions


    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        let names = ["hmacIncKeyVal", "hmacOutKeyValue"]
        let limit = [50, 50]
        let index = names.indexOf(nameState)
        if (index !== -1) {
            if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
        } else {
            dispatchStatePage({ type: nameState, value: valueState })
        }
    }


    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { memberId, hmacIncKeyVal, hmacOutKeyValue, hmacHashType, hmacEnvType, hmacStatus, memberCode, memberName } = statePage;
        const data = {
            memberId: memberId,
            hmacIncKeyVal: hmacIncKeyVal,
            hmacOutKeyValue: hmacOutKeyValue,
            hmacHashType: hmacHashType,
            hmacEnvType: hmacEnvType,
            hmacStatus: hmacStatus,
        };
        const a = CheckAllFields([memberId, hmacIncKeyVal, hmacOutKeyValue, hmacHashType, hmacEnvType, hmacStatus], ['Member Id', 'Incoming Key Value', 'Outgoing Key Value', 'Hash Type', 'Environment Type', 'Status']);

        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
        needLoading: true,
    }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-600px'>
                    <div className='d-flex min-h-30px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1620'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                        <div className='col-12'>
                                <p className={labelStyle}>member ID <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsMember} value={optionsMember.filter((option: any) => option.value === statePage.memberId)} onChange={(e) => handleState('memberId', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>INCOMING KEY VALUE <span className='text-danger'>*</span></p>
                                <input value={statePage.hmacIncKeyVal} onChange={(e) => handleState('hmacIncKeyVal', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>OUTGOING KEY VALUE <span className='text-danger'>*</span></p>
                                <input value={statePage.hmacOutKeyValue} onChange={(e) => handleState('hmacOutKeyValue', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>HASH TYPE <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsHashType} value={optionsHashType.filter((option: any) => option.value === statePage.hmacHashType)} onChange={(e) => handleState('hmacHashType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>ENVIRONMENT TYPE <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsEnvironmentType} value={optionsEnvironmentType.filter((option: any) => option.value === statePage.hmacEnvType)} onChange={(e) => handleState('hmacEnvType', e.value)} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>STATUS <span className='text-danger'>*</span></p>
                                <Select styles={inputStyleH25} placeholder={'(Search)'} options={optionsCommonStatusDetailCode} value={optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.hmacStatus)} onChange={(e) => handleState('hmacStatus', e.value)} />
                            </div>
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page51Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    // 
    hmacId: '',
    memberId: '',
    hmacIncKeyVal: '',
    hmacOutKeyValue: '',
    hmacHashType: '',
    hmacEnvType: '',
    hmacStatus: '001',
    memberCode: '',
    memberName: '',
    // 
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: '',
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                hmacId: '',
                memberId: '',
                hmacIncKeyVal: '',
                hmacOutKeyValue: '',
                hmacHashType: '',
                hmacEnvType: '',
                hmacStatus: '01',
                memberCode: '',
                memberName: '',
                //
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                hmacId: action.value.hmacId,
                memberId: action.value.memberId,
                hmacIncKeyVal: action.value.hmacIncKeyVal,
                hmacOutKeyValue: action.value.hmacOutKeyValue,
                hmacHashType: action.value.hmacHashType,
                hmacEnvType: action.value.hmacEnvType,
                hmacStatus: action.value.hmacStatus,
                memberCode: action.value.memberCode,
                memberName: action.value.memberName,
                //
                errorMessage: "",
            };
        // 
        case 'hmacId':
            return {
                ...state,
                hmacId: action.value,
            };
        case 'memberId':
            return {
                ...state,
                memberId: action.value,
            };
        case 'hmacIncKeyVal':
            return {
                ...state,
                hmacIncKeyVal: action.value,
            };
        case 'hmacOutKeyValue':
            return {
                ...state,
                hmacOutKeyValue: action.value,
            };
        case 'hmacHashType':
            return {
                ...state,
                hmacHashType: action.value,
            };
        case 'hmacEnvType':
            return {
                ...state,
                hmacEnvType: action.value,
            };
        case 'hmacStatus':
            return {
                ...state,
                hmacStatus: action.value,
            };
        case 'memberCode':
            return {
                ...state,
                memberCode: action.value,
            };
        case 'memberName':
            return {
                ...state,
                memberName: action.value,
            };
        default:
            throw new Error();
    }
}


import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

export const CloudWatchMonitoring = ({...props}) => {
    const navigate = useNavigate();
    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
    useEffect(() => {
        if (privilegeAccess !== null) {
            if (privilegeAccess.includes('511') === false) {
                navigate('/warning', { replace: true })
            }
        }
    }, [privilegeAccess])
    return (
        <div>
            <iframe title='cloudWatch' src="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=bcap&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTg0NzkzNzc5MTc5NCIsIlUiOiJ1cy1lYXN0LTFfWnZ0ZnM5RlVoIiwiQyI6IjIwaWt0Nm9mNmU4c2s0OGU2NWM3M2MwOXY5IiwiSSI6InVzLWVhc3QtMToxOWU0ODUyNS0zM2I2LTQwYjktYmZiZS1hNDBlYjQ5NDFhMDgiLCJPIjoiYXJuOmF3czppYW06Ojg0NzkzNzc5MTc5NDpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1QdWJsaWNSZWFkT25seUFjY2Vzcy0wUjhWTUtQOSIsIk0iOiJQdWJsaWMifQ==" style={{ border: "0", width: "100%", height: "790px" }}></iframe>
        </div>
    )
}


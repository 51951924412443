import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import { fileURLToPath } from 'url';

import { ButtonNavigation, InsideComponentPage } from '../../Components';
import { CheckUndefinedStringEmpty, colors } from '../../Utils';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';
import { store } from '../../Store/Store';
import { API } from '../../Services';
import { urlSelection } from '../../Services/API';
import { history } from '../../History';
import { Page2DashboardRoute } from '../../Routes';
import {
  addData,
  checkIndex,
  deleteData,
  handleState,
  initFunction,
  stateSelected as initialStateCustomComponent,
  stateOptions as initialStateOptions,
  reducerOptions,
  reducerStateSelected,
  setModal,
  setToggle
} from '../../State';

import { changeStateTransactionMonitoring, stateGlobalTransactionMonitoring } from '../../Store/TransactionMonitoringSlice';
import { changeStateTransactionMonitoringStatus } from '../../Store/TransactionMonitoringStatusSlice';

import { FilterComponent } from './Components/FilterComponent';
import { InterfaceOptionsTransactionMonitoring } from './Utils/InterfaceOptionsTransactionMonitoring';
import { ModelTransactionMonitoring } from './Utils/ModelTransactionMonitoring';
import { TableTransactionMonitoring } from './Components/TableTransactionMonitoring';
import { ExcelTransactionMonitoring } from './Utils/ExcelTransactionMonitoring';
import { LineChartComponent } from './Components/LineChartComponent';
import { PieChartComponent } from './Components/PieChartComponent';

import { initialState } from './State/state';
import { reducer } from './State/reducer';

export const TransactionMonitoring = ({ ...props }) => {
  const navigate = useNavigate();
  const dispatchGlobal = useDispatch();
  const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);
  const globalState = useSelector(stateGlobalTransactionMonitoring);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateOptions, dispatchOptions] = useReducer(reducerOptions, { ...initialStateOptions });
  const [stateCustomComponent, dispatchCustomComponent] = useReducer(reducerStateSelected, { ...initialStateCustomComponent });
  const { dataFilter, dataCopyFilter, dataByDate, dataCopyByDate, dataYesterday, dataYesterdayByDate, dataYesterdayCopyByDate, filterStartDate, filterEndDate, selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedProductId, selectedProductLabel, selectedProductSendToApi, search, optionsProductFeature, optionsProduct, rangeDate } = globalState;

  const getProductFeature = () => {
    let newFirstElement = { value: '', label: 'All', title: 'All', sendToApi: '' };
    return new Promise((resolve, reject) => {
      let checkSelectedProductId = state.selectedProductId === '' ? '' : `productId=${state.selectedProductId}&`;
      let displayTrue = 'display=true&'
      let finalParameter = `${checkSelectedProductId}${displayTrue}`;
      API.get({
        bodyCustom: null,
        // pathCustom: `product_feature?productId=${selectedProductId}`,
        pathCustom: `product_feature?${finalParameter.slice(0, -1)}`,
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needNotification: false,
      }).then((response: any) => {
        let newOptionsProductFeature: any = [];
        if (response?.data.length > 0) {
          response.data.forEach((element1: any) => {
            // if (element1.prodFeatureIsDisplay === true) { // for show only debit, credit , refund, and reversal
            //   newOptionsProductFeature.push({
            //     value: element1.prodFeatureId,
            //     label: `${element1.prodFeatureName}`,
            //     title: `${element1.productId} - ${element1.productName}`,
            //     sendToApi: element1.prodFeatureInd,
            //   });
            // }
            newOptionsProductFeature.push({
              value: element1.prodFeatureId,
              label: `${element1.prodFeatureName}`,
              title: `${element1.productId} - ${element1.productName}`,
              sendToApi: element1.prodFeatureInd,
            });
          });
        }
        //
        let selectedProductFeature = { value: '', label: 'All', title: 'All', sendToApi: '' };
        newOptionsProductFeature.forEach((element1: any) => {
          if (element1.value === selectedProductFeatureId) selectedProductFeature = element1;
        });
        dispatchGlobal(
          changeStateTransactionMonitoring({
            selectedProductFeatureId: selectedProductFeature.value,
            selectedProductFeatureLabel: selectedProductFeature.label,
            selectedProductFeatureSendToApi: selectedProductFeature.sendToApi,
            optionsProductFeature: [newFirstElement].concat(newOptionsProductFeature),
          })
        );
        resolve(response?.data);
      }).catch((err: any) => reject(err));
    })

  }

  const preparationFilter = () => {
    let newFirstElement = { value: '', label: 'All', title: 'All', sendToApi: '' };
    const getProduct = () => {
      return new Promise((resolve, reject) => {
        API.get({
          bodyCustom: null,
          pathCustom: `product`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needNotification: false,
        }).then((response: any) => {
          let newOptionsProduct: any[] = [];
          if (response?.data !== undefined) {
            if (response.data.length > 0) {
              response.data.forEach((element1: any) => {
                newOptionsProduct.push({
                  value: element1.productId,
                  label: `${element1.productName}`,
                  title: `${element1.productId} - ${element1.productName}`,
                  sendToApi: element1.productName,
                });
              });
            }
          }
          let selectedProduct = { value: '', label: 'All', title: 'All', sendToApi: '' };
          newOptionsProduct.forEach((element1) => {
            if (element1.value === selectedProductId) {
              selectedProduct = element1;
            }
          });
          dispatchGlobal(
            changeStateTransactionMonitoring({
              selectedProductId: selectedProduct.value,
              selectedProductLabel: selectedProduct.label,
              selectedProductSendToApi: selectedProduct.sendToApi,
              optionsProduct: [newFirstElement].concat(newOptionsProduct),
            })
          );
          resolve(response?.data);
        }).catch((err: any) => reject(err));
      })
    }

    const getMembers = () => {
      return new Promise((resolve, reject) => {
        API.get({
          bodyCustom: null,
          pathCustom: `member`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needNotification: false,
        }).then((response: any) => {
          let newList = [];
          if (response?.data.length > 0) {
            response.data.forEach((element1: any, index: number) => {
              let randomColor = colors[index];
              newList.push({ memberId: element1.memberId, memberCode: element1.memberCode, memberName: element1.memberName, memberColor: randomColor, dataTransaction: [] })
            });
          }
          newList.push({ memberId: '', memberCode: '', memberName: 'Undefined', memberColor: 'rgb(0,0,0)', dataTransaction: [] })
          dispatchGlobal(changeStateTransactionMonitoring({ listMember: newList })); // automatically filter data also to the contents
          resolve(response?.data);
        }).catch((err: any) => reject(err));
      })
    }

    const getReasonCode = () => {
      return new Promise((resolve, reject) => {
        API.get({
          bodyCustom: null,
          pathCustom: `reason_code`,
          selectUrl: urlSelection.dashboard,
          useToken: true,
          needNotification: false,
        }).then((response: any) => {
          let newListDataStatusTransaction: any = [];
          if (response?.data.length > 0) {
            response.data.forEach((element1: any) => {
              newListDataStatusTransaction.push(element1.rcName);
            })
          }
          let removeSameValue: any[] = Array.from(new Set(newListDataStatusTransaction)); // remove same id

          let newList: any[] = [];
          removeSameValue.forEach((element1) => {
            newList.push({ status: element1, rcCodeList: [] });
          })
          newList.map((element1) => {
            if (response.data.length > 0) {
              response.data.forEach((element2: any) => {
                if (element1.status === element2.rcName) {
                  element1.rcCodeList.push(element2.rcCode);
                }
              })
            }
          })
          dispatchGlobal(changeStateTransactionMonitoring({ listReasonCode: newList })); // automatically filter data also to the contents
          dispatchOptions({ type: 'optionsReasonCode', value: response?.data });
          resolve(response?.data);
        }).catch((err: any) => reject(err));
      })
    }
    Promise.all([getProduct(), getProductFeature(), getMembers(), getReasonCode()]).then(() => {
    }).finally(() => {
      store.dispatch(loadingAction(false));
    })
  }

  const initFunctionCustom = async (needRefreshPage: Boolean | null) => {
    try {
      store.dispatch(loadingAction(true));

      let dateStart = new Date(filterStartDate); let dateEnd = new Date(filterEndDate); let listAllDayChart = [];
      for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) { listAllDayChart.push(d.toISOString().slice(0, 10)) }

      let dateStartBefore = new Date(filterStartDate); let dateEndBefore = new Date(filterEndDate);
      dateStartBefore.setDate(dateStartBefore.getDate() - listAllDayChart.length); dateEndBefore.setDate(dateEndBefore.getDate() - listAllDayChart.length);

      let dayUsedStartBefore = dateStartBefore.toISOString().slice(0, 10); let dayUsedEndBefore = dateEndBefore.toISOString().slice(0, 10);

      let urlReqNow = 'dashboard/transaction-monitoring-list?'; let urlReqBefore = 'dashboard/transaction-monitoring-list?';

      if (CheckUndefinedStringEmpty(selectedProductSendToApi) !== '') {
        urlReqNow = urlReqNow + `prodType=${CheckUndefinedStringEmpty(selectedProductSendToApi)}&`;
        urlReqBefore = urlReqBefore + `prodType=${CheckUndefinedStringEmpty(selectedProductSendToApi)}&`;
      }
      if (CheckUndefinedStringEmpty(selectedProductFeatureSendToApi) !== '') {
        urlReqNow = urlReqNow + `productFeature=${CheckUndefinedStringEmpty(selectedProductFeatureSendToApi)}&`;
        urlReqBefore = urlReqBefore + `productFeature=${CheckUndefinedStringEmpty(selectedProductFeatureSendToApi)}&`;
      }
      if (CheckUndefinedStringEmpty(search) !== '') {
        urlReqNow = urlReqNow + `search=${CheckUndefinedStringEmpty(search)}&`;
        urlReqBefore = urlReqBefore + `search=${CheckUndefinedStringEmpty(search)}&`;
      }
      if (CheckUndefinedStringEmpty(filterStartDate) !== '') {
        urlReqNow = urlReqNow + `startDate=${CheckUndefinedStringEmpty(filterStartDate)}&`;
        urlReqBefore = urlReqBefore + `startDate=${CheckUndefinedStringEmpty(dayUsedStartBefore)}&`;
      }
      if (CheckUndefinedStringEmpty(filterEndDate) !== '') {
        urlReqNow = urlReqNow + `endDate=${CheckUndefinedStringEmpty(filterEndDate)}&`;
        urlReqBefore = urlReqBefore + `endDate=${CheckUndefinedStringEmpty(dayUsedEndBefore)}&`;
      }

      const responseYesterday = await API.get({
        bodyCustom: null,
        pathCustom: urlReqBefore.slice(0, -1),
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needNotification: false,
      })

      const responseToday = await API.get({
        bodyCustom: null,
        pathCustom: urlReqNow.slice(0, -1),
        selectUrl: urlSelection.dashboard,
        useToken: true,
        needNotification: false,
      })

      // for percentage UI Top PIE CHART

      dispatchGlobal(changeStateTransactionMonitoring({ // automatically filter data also to the contents
        dataYesterday: responseYesterday?.data,
        dataYesterdayByDate: responseYesterday?.dataByDate,
        dataFilter: responseToday?.data,
        dataByDate: responseToday?.dataByDate,
        rangeDate: listAllDayChart.length
      }))

      store.dispatch(loadingAction(false));
    } catch (error) {
      console.error(error);
      store.dispatch(loadingAction(false));
    }
  }

  useEffect(() => {
    if (privilegeAccess !== null) {
      if (privilegeAccess.includes(state.privilegeAccess.view) === false) {
        navigate('/warning', { replace: true })
      }
    }
  }, [privilegeAccess])


  useEffect(() => {
    preparationFilter()
  }, [selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedProductId, selectedProductLabel, selectedProductSendToApi])

  useEffect(() => {
    initFunction(dispatchOptions, state, () => { }, null, InterfaceOptionsTransactionMonitoring, dispatchGlobal)
  }, [])

  useEffect(() => {
    if (filterStartDate !== '' && filterEndDate !== '') initFunctionCustom(true)
  }, [search, filterStartDate, filterEndDate, /*rangeDate,*/ selectedProductFeatureId, selectedProductFeatureLabel, selectedProductFeatureSendToApi, selectedProductId, selectedProductLabel, selectedProductSendToApi])

  useEffect(() => {
    handleStateComponent('filterStartDate', filterStartDate)
    handleStateComponent('filterEndDate', filterEndDate)

    handleStateComponent('selectedProductId', selectedProductId)
    handleStateComponent('selectedProductLabel', selectedProductLabel)
    handleStateComponent('selectedProductSendToApi', selectedProductSendToApi)

    handleStateComponent('selectedProductFeatureId', selectedProductFeatureId)
    handleStateComponent('selectedProductFeatureLabel', selectedProductFeatureLabel)
    handleStateComponent('selectedProductFeatureSendToApi', selectedProductFeatureSendToApi)
  }, [])

  useEffect(() => {
    getProductFeature()
  }, [state.selectedProductId])

  useEffect(() => {
    dispatchGlobal(changeStateTransactionMonitoring({ 'search': state.search }))
  }, [state.search])

  useEffect(() => {
    let totalPages: number = Math.ceil(dataCopyFilter.length / state.view);
    let currentPage: number = parseInt((state.position / state.view).toString());
    dispatch({ type: 'changeData', value: { totalPages, currentPage } });
    dispatch({ type: 'data', value: dataCopyFilter });
  }, [dataCopyFilter])

  const exportToCSVComponent = () => ExcelTransactionMonitoring(state, stateOptions, globalState)
  //
  const addDataComponent = () => addData(dispatch)
  const deleteDataComponent = (item: ModelTransactionMonitoring) => deleteData(item.id, item, dispatch)
  const checkIndexComponent = (nameAction: any) => checkIndex(nameAction, state)
  const handleStateComponent = (nameAction: any, valueAction: any) => handleState(nameAction, valueAction, dispatch)
  const setToggleComponent = (nameAction: any) => setToggle(nameAction, dispatch, state)
  const setModalComponent = (nameAction: any) => setModal(nameAction, dispatch, state)
  const reloadDataComponent = (needRefreshPage: any) => initFunction(dispatchOptions, state, () => initFunctionCustom(needRefreshPage), null, InterfaceOptionsTransactionMonitoring, dispatchGlobal)
  const actionShowModal = (idModal: any) => dispatch({ type: 'showModal', value: idModal })
  const actionIndexModal = (idModal: any) => dispatch({ type: 'changeIndex', value: idModal })
  const actionAfterUpdateItem = (updateItemID: any) => { }

  const handleNavigation = (item: any) => {
    if (item.fullCreateDate !== null) {
      let dateArray = item.fullCreateDate.toString().split(' ');
      if (dateArray.length > 1) {
        let date = dateArray[0];
        let changeTransStatusName = item.transStatus;
        if (changeTransStatusName === 'UNKNOWN') {
          changeTransStatusName = '-';
        }

        dispatchGlobal(
          changeStateTransactionMonitoringStatus({
            transId: '',
            transAuthIntResponseCode: '', // search for status => reason code
            statusSendToApi: '', // search for status => reason code
            transStatusName: changeTransStatusName === null || changeTransStatusName === undefined ? '' : changeTransStatusName, // search for status => reason code
            transCurrencyCode: item.acqTransCurrency === null || item.acqTransCurrency === undefined ? '' : item.acqTransCurrency, // search for status => reason code
            acqSwitchCurrencyCode: item.acqSwitchTansCurrency === null || item.acqSwitchTansCurrency === undefined ? '' : item.acqSwitchTansCurrency, // search for status => reason code
            issSwitchCurrencyCode: item.issSwitchTansCurrency === null || item.issSwitchTansCurrency === undefined ? '' : item.issSwitchTansCurrency, // search for status => reason code
            // Product
            selectedProductId: item.productType,
            selectedProductLabel: null,
            selectedProductSendToApi: item.productType,
            // Product Feature
            selectedProductFeatureId: item.productFeature,
            selectedProductFeatureLabel: null,
            selectedProductFeatureSendToApi: item.productFeature,
            // 
            search: '',
            filterStartDate: filterStartDate,
            filterEndDate: filterEndDate,
            // 
            issuerId: item.issuerId === null || item.issuerId === undefined ? '' : item.issuerId, // issuer
            acquirerId: item.acquirerId === null || item.acquirerId === undefined ? '' : item.acquirerId, // acquirer
            issuerSwitcherId: item.issuerSwitcherId === null || item.issuerSwitcherId === undefined ? '' : item.issuerSwitcherId, // issuerSwitcherId
            acquirerSwitcherId: item.acquirerSwitcherId === null || item.acquirerSwitcherId === undefined ? '' : item.acquirerSwitcherId, // acquirerSwitcherId
            // 
            issuerIdCode: item.issuerIdCode === null || item.issuerIdCode === undefined ? '' : item.issuerIdCode, // issuerIdCode
            acquirerIdCode: item.acquirerIdCode === null || item.acquirerIdCode === undefined ? '' : item.acquirerIdCode, // acquirerIdCode
            issuerSwitcherIdCode: item.issuerSwitcherIdCode === null || item.issuerSwitcherIdCode === undefined ? '' : item.issuerSwitcherIdCode, // issuerSwitcherIdCode
            acquirerSwitcherIdCode: item.acquirerSwitcherIdCode === null || item.acquirerSwitcherIdCode === undefined ? '' : item.acquirerSwitcherIdCode, // acquirerSwitcherIdCode
            navigateFrom: "transaction-statistic",
          })
        );
        navigate(`${Page2DashboardRoute}`)
      }
    }
  };

  const customButtonAction = (item: ModelTransactionMonitoring, width: string | any) => {
    return (
      <div className={`d-flex justify-content-around align-items-center ${width}`}>
        <ButtonNavigation handleClick={() => handleNavigation(item)} codeAccessPrivilege='' />
      </div>
    );
  };

  const filterComponentPage = () => {
    return (
      <></>
    )
  }

  const tableComponentPage = () => {
    return (
      <TableTransactionMonitoring
        codeAccessPrivilegeDelete={state.privilegeAccess.delete}
        codeAccessPrivilegeEdit={state.privilegeAccess.edit}
        label={state.label}
        data={state.data}
        position={state.position}
        view={state.view}
        customButtonAction={customButtonAction}
        checkIndex={checkIndexComponent}
        widthTable={`wd-${state.widthTable}-responsive`}
        stateOptions={stateOptions}
        //
        handleNavigation={handleNavigation}
      //
      />
    )
  }
  const modalAddDeleteComponentPage = () => {
    return (
      <></>
    )
  }

  const modalUpdateComponentPage = () => {
    return (
      <>
      </>
    )
  }

  return (
    <>
      <h1 className='Lato-300 normal w-600px s-2024 py-2'>{state.titlePage}</h1>
      <FilterComponent initFunctionCustom={initFunctionCustom} handleStateParent={handleStateComponent} stateParent={state} />
      <PieChartComponent handleStateComponent={handleStateComponent} stateOptions={stateOptions} />
      <LineChartComponent stateParent={state} initFunctionCustom={initFunctionCustom} handleStateParent={handleStateComponent} stateOptions={stateOptions} />
      <InsideComponentPage
        state={state}
        stateOptions={stateOptions}
        customButtonAction={customButtonAction}
        exportToCSVComponent={exportToCSVComponent}
        addDataComponent={addDataComponent}
        checkIndexComponent={checkIndexComponent}
        handleStateComponent={handleStateComponent}
        setToggleComponent={setToggleComponent}
        setModal={setModalComponent}
        // component
        tableComponentPage={tableComponentPage}
        filterComponentPage={filterComponentPage}
        modalAddDeleteComponentPage={modalAddDeleteComponentPage}
        modalUpdateComponentPage={modalUpdateComponentPage}
        // 
        needComponentHeaderTable={true}
        needComponentFilterTable={true}
        needComponentTable={true}
        needComponentFooterTable={true}
        //
        needToggleFunction={true}
        needAddButton={false}
        needSearchFunction={true}
        needDownloadButton={true}
      />
    </>
  )
}

// const initialStateOptions = { ...stateOptions }
// const initialStateCustomComponent = { ...stateSelected }



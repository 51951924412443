import { configureStore } from "@reduxjs/toolkit";
import type { Reducer } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import mainDashboardReducer from "./MainDashboardSlice";
import transactionMonitoringReducer from "./TransactionMonitoringSlice";
import transactionMonitoringStatusReducer from "./TransactionMonitoringStatusSlice";
import reversalTransactionMonitoringReducer from "./ReversalTransactionMonitoringSlice";
import reversalTransactionMonitoringStatusReducer from "./ReversalTransactionMonitoringStatusSlice";
import qrAuthInquiryReducer from "./QRAuthInquirySlice";
import qrAuthSimulatorInquiryReducer from "./QRAuthSimulatorInquirySlice";
import qrAuthMonitoringReducer from "./QRAuthMonitoringSlice";
import alertHistoryReducer from  "../Pages/Alert History Page/AlertHistorySlice"
import transactionJourneyReducer from "./TransactionJourney";
import memberProfileReducer from "./MemberProfileSlice";
//
import jobExecutionHistoryReducer from "./JobExecutionHistory";
import transactionTestInquiryReducer from "./TransactionTestInquirySlice";

import user from './User';
import logo from './Logo';
import loading from './Loading';
import error from './Error';
import success from './Sucesss';
import timeout from './Timeout';
import { alertHistorySlice } from "../Pages/Alert History Page/AlertHistorySlice";

declare const reducer: Reducer<{}>

export const store = configureStore({
    reducer: {
        jobExecutionHistory : jobExecutionHistoryReducer,
        //
        transactionJourney : transactionJourneyReducer,
        memberProfile : memberProfileReducer,
        //
        mainDashboard: mainDashboardReducer,
        transactionMonitoring: transactionMonitoringReducer,
        transactionMonitoringStatus: transactionMonitoringStatusReducer,
        reversalTransactionMonitoring: reversalTransactionMonitoringReducer,
        reversalTransactionMonitoringStatus: reversalTransactionMonitoringStatusReducer,
        transactionTestInquiry: transactionTestInquiryReducer,
        qrAuthInquiry: qrAuthInquiryReducer,
        qrAuthSimulatorInquiry:qrAuthSimulatorInquiryReducer,
        qrAuthMonitoring: qrAuthMonitoringReducer,
        alertHistory:alertHistoryReducer,


        //
        user: user,
        logo: logo,
        loading: loading,
        error : error,
        success : success,
        timeout: timeout
    }
})

export const MainDashboardDispatch = () => useDispatch<typeof store.dispatch>()
export const TransactionMonitoringDispatch = () => useDispatch<typeof store.dispatch>()
export const QRAuthMonitoringDispatch = () => useDispatch<typeof store.dispatch>()
import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { Page40Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalBatchProgram = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsElement, optionsQRStandard, optionsYorN, optionsMOC, optionsCommonStatus, optionsFieldMappingDetailCode } = props.stateOptions
    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { qrStandardId, qrStandardName, elementId, elementName, qrseMoc, qrseIsRoot, rootElementId, qrseStatus, qrseDesc } = statePage.data
        const data = {
            qrStandardId: qrStandardId,
            elementId: elementId,
            qrseStatus: qrseStatus,
            qrseDesc: qrseDesc,
            qrseMoc: qrseMoc,
            qrseIsRoot: qrseIsRoot,
            rootElementId: qrseIsRoot === false ? rootElementId : null
        };
        let a = '';
        if (qrseIsRoot === false) {
            a = CheckAllFields([qrStandardId, elementId, qrseStatus, qrseMoc, qrseIsRoot, rootElementId], ["QR Standard", "Element ID", "Status", "M/O/C", "Is Root", "Root Element"]);
        } else {
            a = CheckAllFields([qrStandardId, elementId, qrseStatus, qrseMoc, qrseIsRoot], ["QR Standard", "Element ID", "Status", "M/O/C", "Is Root"]);
            handleState('rootElementId', null);
        }
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,

                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,

            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }

    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 my-3'>
                            <div className='col-6 my-2'>
                                <p className={labelStyle}>Batch Program ID</p>
                                <input value={statePage.data.interfaceFieldName} onChange={(e) => handleState('interfaceFieldName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6 my-2'>
                                <p className={labelStyle}>Status</p>
                                <Select placeholder='Search' value={statePage.data.interfaceFieldMappingCode ? optionsFieldMappingDetailCode.find((item: any) => item.value === statePage.data.interfaceFieldMappingCode) : ''} options={optionsFieldMappingDetailCode} onChange={(e) => handleState('interfaceFieldMappingCode', e.value)} />
                            </div>
                            <div className='col-6 my-2'>
                                <p className={labelStyle}>Batch Name</p>
                                <input value={statePage.data.interfaceFieldName} onChange={(e) => handleState('interfaceFieldName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6 my-2'>
                                <p className={labelStyle}>Type</p>
                                <Select placeholder='Search' value={statePage.data.interfaceFieldMappingCode ? optionsFieldMappingDetailCode.find((item: any) => item.value === statePage.data.interfaceFieldMappingCode) : ''} options={optionsFieldMappingDetailCode} onChange={(e) => handleState('interfaceFieldMappingCode', e.value)} />
                            </div>
                            <div className='col-6 my-2'>
                                <p className={labelStyle}>Batch Program Name</p>
                                <input value={statePage.data.interfaceFieldName} onChange={(e) => handleState('interfaceFieldName', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <div className='col6 my-2'>
                                    <p className={labelStyle}>
                                        BATCH Reprocessing
                                    </p>
                                    <div className='d-flex justify-content-between align-items-center' style={{ height: '36px' }}>
                                        <span className='mx-2'>
                                            <input readOnly type='radio' name={`safControlSrStatus01`} className='mx-2' style={{ display: 'inline-block' }} disabled />
                                            {'YES'}
                                        </span>
                                        <span className='mx-2'>
                                            <input readOnly type='radio' name={`safControlSrStatus01`} className='mx-2' style={{ display: 'inline-block' }} disabled />
                                            NO
                                        </span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 my-2'>
                                <p className={labelStyle}>Batch Log Level</p>
                                <Select placeholder='Search' value={statePage.data.interfaceFieldMappingCode ? optionsFieldMappingDetailCode.find((item: any) => item.value === statePage.data.interfaceFieldMappingCode) : ''} options={optionsFieldMappingDetailCode} onChange={(e) => handleState('interfaceFieldMappingCode', e.value)} />
                            </div>

                            <div className='col-6'>
                                <div className='col6 my-2'>
                                    <p className={labelStyle}>
                                        BATCH Execution
                                    </p>
                                    <div className='d-flex justify-content-between align-items-center' style={{ height: '36px' }}>
                                        <span className='mx-2'>
                                            <input readOnly type='radio' name={`safControlSrStatus2`} className='mx-2' style={{ display: 'inline-block' }} disabled />
                                            YES
                                        </span>
                                        <span className='mx-2'>
                                            <input readOnly type='radio' name={`safControlSrStatus02`} className='mx-2' style={{ display: 'inline-block' }} disabled />
                                            NO
                                        </span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 my-2">
                                <p className={labelStyle}>Schedule Active</p>
                                <input
                                    type={'datetime-local'}
                                    onChange={(e) => {
                                        // let splitDate = e.target.value.toString().split('T');
                                        // let dateStart = new Date(splitDate[0]);
                                        // let dateEnd = new Date(stateCustomComponent.selectedEndDate);
                                        // let listAllDayChart = [];
                                        // for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                                        //     listAllDayChart.push(d.toISOString().slice(0, 10));
                                        // } if (listAllDayChart.length < 1) {
                                        //     handleState('errorMessageFilter', 'Invalid date spacing', dispatch);
                                        // } else if (listAllDayChart.length > 30) {
                                        //     handleState('errorMessageFilter', 'Maximum Date Range 30 Day', dispatch);
                                        // } else {
                                        //     handleState('errorMessageFilter', '', dispatch);
                                        //     handleState('selectedStartDate', splitDate[0], dispatchCustomComponent);
                                        //     handleState('selectedStartTime', splitDate[1], dispatchCustomComponent);
                                        // }
                                    }}
                                    // value={`${stateCustomComponent.selectedStartDate}T${stateCustomComponent.selectedStartTime}`}
                                    className='form-control text-secondary'
                                    style={{ borderRadius: '8px', marginTop: '0px' }}
                                />
                            </div>

                            <div className="col-6 my-2">
                                <p className={labelStyle}>Schedule Pause</p>

                                <input
                                    type={'datetime-local'}
                                    onChange={(e) => {
                                        // let splitDate = e.target.value.toString().split('T');
                                        // let dateStart = new Date(splitDate[0]);
                                        // let dateEnd = new Date(stateCustomComponent.selectedEndDate);
                                        // let listAllDayChart = [];
                                        // for (let d = dateStart; d <= dateEnd; d.setDate(d.getDate() + 1)) {
                                        //     listAllDayChart.push(d.toISOString().slice(0, 10));
                                        // } if (listAllDayChart.length < 1) {
                                        //     handleState('errorMessageFilter', 'Invalid date spacing', dispatch);
                                        // } else if (listAllDayChart.length > 30) {
                                        //     handleState('errorMessageFilter', 'Maximum Date Range 30 Day', dispatch);
                                        // } else {
                                        //     handleState('errorMessageFilter', '', dispatch);
                                        //     handleState('selectedStartDate', splitDate[0], dispatchCustomComponent);
                                        //     handleState('selectedStartTime', splitDate[1], dispatchCustomComponent);
                                        // }
                                    }}
                                    // value={`${stateCustomComponent.selectedStartDate}T${stateCustomComponent.selectedStartTime}`}
                                    className='form-control text-secondary'
                                    style={{ borderRadius: '8px', marginTop: '0px' }}
                                />
                            </div>
                            <div className='col-12 my-2'>
                                <p className={labelStyle}>DESCRIPTION</p>
                                <textarea rows={4} value={''} onChange={(e) => handleState("productDesc", e.target.value)} className={inputDescriptionStyle}></textarea>
                            </div>

                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />


            <Modal isOpen={statePage.showModalError} style={ModalStyle}>
                <div className='d-flex flex-column wd-300'>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className=''></p>
                        <p className='w-700 Lato-700 text-danger s-1822'>Alert</p>
                        <div className='pointer' onClick={(e) => handleState('showModalError', false)}>{close} </div>
                    </div>
                    <div className='d-flex p-20 min-h-60px justify-content-between align-items-center shd-ea'>
                        <p className='s-1620 mb-30'>This Data Can't be delete </p>
                    </div>
                </div>
            </Modal>
        </>
    )
}


const initialState = {
    titleModal: `New ${Page40Title}`,

    deleteItem: false,
    data: {},
    //
    // data: new any({
    //     id: null,
    //     qrseId: null,
    //     qrStandardId: null,
    //     qrStandardName: '',
    //     elementId: null,
    //     elementName: '',
    //     qrseMoc: 'M',
    //     qrseIsRoot: true,
    //     rootElementId: null,
    //     qrseStatus: '001',
    //     qrseDesc: '',
    // }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as any) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }


    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                // data: new any({
                //     id: null,
                //     qrseId: null,
                //     qrStandardId: null,
                //     qrStandardName: '',
                //     elementId: null,
                //     elementName: '',
                //     qrseMoc: 'M',
                //     qrseIsRoot: true,
                //     rootElementId: null,
                //     qrseStatus: '001',
                //     qrseDesc: '',
                // }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                // data: new any({
                //     id: action.value.qrseId,
                //     qrseId: action.value.qrseId,
                //     qrStandardId: action.value.qrStandardId,
                //     qrStandardName: action.value.qrStandardName,
                //     elementId: action.value.elementId,
                //     elementName: action.value.elementName,
                //     qrseMoc: action.value.qrseMoc,
                //     qrseIsRoot: action.value.qrseIsRoot,
                //     rootElementId: action.value.rootElementId,
                //     qrseStatus: action.value.qrseStatus,
                //     qrseDesc: action.value.qrseDesc,
                // }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}




// RoutingHeaderMapping

interface RoutingHeaderMappingInterface {
    id: null | number | string;
    rhfId: number | null;
    routeId: number | null;
    headerFieldId: number | null;
    rhfMoc: string | null;
    rhfStatus: string | null;
    rhfValue: string | null;
    description: string | null;
    headerFieldName: string | null;
    headerFieldValue: string | null;


}

export class ModelRoutingHeaderMapping {
    id: null | number | string;
    rhfId: number | null;
    routeId: number | null;
    headerFieldId: number | null;
    rhfMoc: string | null;
    rhfStatus: string | null;
    rhfValue: string | null;
    description: string | null;
    headerFieldName: string | null;
    headerFieldValue: string | null;


    constructor(value: RoutingHeaderMappingInterface | null) {
        this.id = value?.id ?? null;
        this.rhfId= value?.rhfId ?? null;
        this.routeId= value?.routeId ?? null;
        this.headerFieldId= value?.headerFieldId ?? null;
        this.rhfMoc= value?.rhfMoc ?? null;
        this.rhfStatus= value?.rhfStatus ?? null;
        this.rhfValue= value?.rhfValue ?? null;
        this.description= value?.description ?? null;
        this.headerFieldName= value?.headerFieldName ?? null;
        this.headerFieldValue= value?.headerFieldValue ?? null;
    }
}

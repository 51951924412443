import { ButtonAdd } from "./ButtonAdd";
import { ButtonDownload } from "./ButtonDownload";
import { SearchTable } from "./SearchTable";
import { ToggleTable } from "./ToggleTable";

export const DataTableFeaturesLimitOffset = ({ ...props }) => {
  const {
    stateParent,
    titleTable,
    data,
    label,
    privilegeAccess,
    checkIndex,
    setToggle,
    handleState,
    addData,
    exportToCSV,
    needAddButton = true,
    needDownloadButton = true,
    needSearchFunction = true,
    needToggleFunction = true,isButtonDisabled,
    totalData
  } = props;




  return (
    <div className="d-flex p-0 mt-1 mb-1 mx-2">
      <div className="d-flex justify-content-start align-items-center w-100">
      <div className="">
                    <div className="col-6 d-flex align-items-center">
                        <p className="Lato-400 s-1822 c-29">List</p>
                        <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">
                            {totalData}
                        </p>
                    </div>
                </div>

        {
          needDownloadButton ?
            <div className="h-30px w-30px" style={{opacity:isButtonDisabled?'0.7':'1'}}>
              <ButtonDownload isButtonDisabled={isButtonDisabled} handleClick={exportToCSV} codeAccessPrivilege={privilegeAccess.download} />
            </div>
            :
            <></>
        }

      </div>
      {/* 
      <div className="col-xl-8 col-lg-12 col-sm-12 col-xs-12 ">
        <div className="row justify-content-end g-3">

          <div className="col-xl-3 col-lg-12 col-sm-12 col-xs-12 h-50px">
            <ToggleTable needToggleFunction={needToggleFunction} label={label} checkIndex={checkIndex} setToggle={setToggle} />
          </div>
          <div className="col-xl-4 col-lg-12 col-sm-12 col-xs-12 h-50px">
            <SearchTable needSearchFunction={needSearchFunction} handleState={handleState} />
          </div>
          {
            needDownloadButton ?
              <div className="col-xl-3 col-lg-12 col-sm-12 col-xs-12 h-50px">
                <ButtonDownload handleClick={exportToCSV} codeAccessPrivilege={privilegeAccess.download} />
              </div>
              :
              <></>
          }
          {
            needAddButton ?
              <div className="col-xl-2 col-lg-12 col-sm-12 col-xs-12 h-50px">
                <div className="w-80px h-100">
                  <ButtonAdd handleClick={addData} codeAccessPrivilege={privilegeAccess.add} />
                </div>

              </div>
              :
              <></>
          }
        </div>


      </div> 
      */}
      <div className="col-xl-8 col-lg-8 col-sm-12 col-xs-12 ">
        <div className="d-flex justify-content-end align-items-center w-100">
          <div className="h-50px w-100px">
            <ToggleTable needToggleFunction={needToggleFunction} label={label} checkIndex={checkIndex} setToggle={setToggle} />
          </div>
          <div className="h-30px w-250px mx-2">
            <SearchTable needSearchFunction={needSearchFunction} handleState={handleState} stateParent={stateParent}/>
          </div>
          {
            needAddButton ?
              <div className="h-30px w-30px">
                <div className="h-100">
                  <ButtonAdd handleClick={addData} codeAccessPrivilege={privilegeAccess.add} />
                </div>

              </div>
              :
              <></>
          }
        </div>


      </div>
    </div>
  );
};


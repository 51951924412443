import { useEffect, useReducer, useState } from 'react';
import { API } from '../../../../Services';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import TimezoneSelect, {
    allTimezones,
    useTimezoneSelect
} from 'react-timezone-select';
import { checklistButtonIcon } from '../../../../Assets/Images/svg';
import { inputStyleH25, inputStyleH25Multi, inputStyleWithoutW100, inputStyleWithoutW100ReadOnly, labelStyleW250PX } from '../../../../Styles';
import { ModalGeneralInformation } from '../Models/ModelGeneralInformation';
import { urlSelection } from '../../../../Services/API';

const timezones = {
    ...allTimezones,
}
const labelStyle = 'original'

export const GetGeneralInformation = ({ ...props }) => {
    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })
    const animatedComponents = makeAnimated();


    const { codeMember, stateOptions } = props;
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }

    useEffect(() => {
        API.get({
            bodyCustom: null,
            pathCustom: `member_parameter/general_information/${codeMember}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            dispatchStatePage({ type: 'setData', value: response.data })
        })
    }, [])

    const updateData = () => {
        const {
            memberParamsId,
            memberCode,
            memberName,
            memberStatus,
            countryCode,
            memberInstitutionType,
            memberRoleDtos,
            memberLocalTimezone,
        } = statePage.data
        const data = {
            memberParamsId: memberParamsId,
            memberCode: memberCode,
            memberName: memberName,
            memberStatus: memberStatus,
            countryCode: countryCode,
            memberInstitutionType: memberInstitutionType,
            memberRoleTypes: memberRoleDtos,
            memberLocalTimezone: memberLocalTimezone
        }
        API.update({
            bodyCustom: data,
            pathCustom: `member_parameter/general_information`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            if (response.data !== undefined) {
                dispatchStatePage({ type: 'setData', value: response.data })
            }
        })
    }

    useEffect(() => {
        let selectedMemberRoles: any[] = []
        stateOptions.optionsRoleType.forEach((option: any) => {
            if (statePage.data.memberRoleDtos !== null) {
                if (statePage.data.memberRoleDtos.length > 0) {
                    if (statePage.data.memberRoleDtos.includes(option.value)) {
                        selectedMemberRoles.push(option)
                    }
                }
            }
        })
        handleState('selectedMemberRoles', selectedMemberRoles)
    }, [statePage.data.memberRoleDtos, stateOptions.optionsRoleType])

    return (
        <div className='d-flex flex-column justify-content-between bd-ea br-10 px-2 pt-2 pb-5'>
            <div className='row gy-2 gx-3 mx-0 my-3'>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>Member ID</span>
                    <input value={statePage.data.memberId} className={inputStyleWithoutW100ReadOnly + ' flex-fill'} />
                </div>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>MEMBER NAME</span>
                    <input value={statePage.data.memberName} onChange={(e) => handleState('memberName', e.target.value)} className={inputStyleWithoutW100 + ' flex-fill'} />
                </div>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>MEMBER CODE</span>
                    <input value={statePage.data.memberCode} className={inputStyleWithoutW100ReadOnly + ' flex-fill'} />
                </div>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>MEMBER COUNTRY</span>
                    <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsCountryCodeAndCurrencyCode} value={stateOptions.optionsCountryCodeAndCurrencyCode.filter((option: any) => option.value === statePage.data.countryCode)} onChange={(e) => handleState('countryCode', e.value)} />
                </div>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>MEMBER INSTITUTION TYPE</span>
                    <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsInstitutionType} value={stateOptions.optionsInstitutionType.filter((option: any) => option.value === statePage.data.memberInstitutionType)} onChange={(e) => handleState('memberInstitutionType', e.value)} />
                </div>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>MEMBER STATUS</span>
                    <Select styles={inputStyleH25} className='flex-fill' options={stateOptions.optionsCommonStatusDetailCode} value={stateOptions.optionsCommonStatusDetailCode.filter((option: any) => option.value === statePage.data.memberStatus)} 
                    onChange={(e) => handleState('memberStatus', e.value)} 
                    />
                </div>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>MEMBER ROLES TYPE</span>
                    <Select
                        styles={inputStyleH25Multi}
                        className='flex-fill'
                        closeMenuOnSelect={false}
                        value={statePage.selectedMemberRoles}
                        isMulti
                        options={stateOptions.optionsRoleType}
                        onChange={(items) => {
                            let dataSelected: any[] = [];
                            items.forEach((item: any) => {
                                dataSelected.push(item.value);

                            })
                            handleState('memberRoleDtos', dataSelected)
                        }}
                    />
                </div>
                <div className={'d-flex  align-items-center my-2'}>
                    <span className={labelStyleW250PX}>MEMBER LOCAL TIME ZONE</span>
                    <TimezoneSelect
                        styles={inputStyleH25}
                        options={options}
                        value={statePage.data.memberLocalTimezone ?? selectedTimezone}
                        className='flex-fill'
                        onChange={(e) => handleState('memberLocalTimezone', e.value)}
                    />
                </div>

            </div>
            <div className='d-flex'>
                <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-2' onClick={updateData}>
                    {checklistButtonIcon}
                    <p className='s-1214 c-ff Lato-600 mx-2'>SAVE</p>
                </button>
            </div>
        </div>
    )
}

const initialState = {
    data: new ModalGeneralInformation({
        id: null,
        memberId: null,
        memberParamsId: null,
        memberCode: null,
        memberName: null,
        memberStatus: null,
        countryCode: null,
        memberInstitutionType: null,
        memberRoleDtos: null,
        memberLocalTimezone: null,
    }),
    errorMessage: "",
    selectedMemberRoles: [],
}
const reducer = (state: any, action: any) => {
    for (const key in state.data as ModalGeneralInformation) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'selectedMemberRoles':
            return {
                ...state,
                selectedMemberRoles: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModalGeneralInformation({
                    id: null,
                    memberId: null,
                    memberParamsId: null,
                    memberCode: null,
                    memberName: null,
                    memberStatus: null,
                    countryCode: null,
                    memberInstitutionType: null,
                    memberRoleDtos: null,
                    memberLocalTimezone: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            let memberRoleDtos: any[] = [];
            if (action.value.memberRoleDtos.length > 0) {
                action.value.memberRoleDtos.forEach((item: any) => {
                    memberRoleDtos.push(item.memberRoleType)
                })
            }
            return {
                ...state,
                data: new ModalGeneralInformation({
                    id: action.value.memberParamsId,
                    memberId: action.value.memberId,
                    memberParamsId: action.value.memberParamsId,
                    memberCode: action.value.memberCode,
                    memberName: action.value.memberName,
                    memberStatus: action.value.memberStatus,
                    countryCode: action.value.countryCode,
                    memberInstitutionType: action.value.memberInstitutionType,
                    memberRoleDtos: memberRoleDtos,
                    memberLocalTimezone: action.value.memberLocalTimezone,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

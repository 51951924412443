import { useEffect, useState } from 'react';
import { Modal, Button, IconButton, Zoom } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Select from 'react-select';

import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import useIsMobile from '../../../Utils/useIsMobile';
import { stateGlobalQRAuthInquiry } from '../../../Store/QRAuthInquirySlice';
import { inputStyleH25, labelStyleWwithoutUppercase } from '../../../Styles';
import { DatePickerRighIcon } from '../../../Components/DatepickerRightIcon';
import { errNotificationIcon } from '../../../Assets/Images/svg';


export const FilterMobileQRAuthSimulatorInquiry = ({ ...props }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const globalState = useSelector(stateGlobalQRAuthInquiry);

    const isMobile = useIsMobile();
    const {
        state,
        stateOptions,
        handleStateGlobal,
        resetFilterStatus,
        initFunctionCustom,
    } = props;

    //  console.log(stateOptions.optionsCommonCode)
    const {
        testCat,
        testCaseId,
        isTestValid,
        qrRefNo,
        QRAuthInqResponseCode,
        QRStatusSendToApi,
        QRStatus,
        stan,
        invNo,
        globalId,
        memberId,
        qrCpmPan,
        qrMpmMerId,
        filterStartDate,
        filterEndDate,
        search,
        selectedQRStandardId,
        selectedQRStandardName,
        selectedQRStandardSendToApi,
        selectedProductIndId,
        selectedProductIndLabel,
        selectedProductIndSendToApi,
        productInd,
        qrsId,
    } = globalState;

    useEffect(() => {
        if (isMobile) {
            initFunctionCustom(true);
        }
    }, []);

    const inputValidationNumericMaxlength = (e: React.ChangeEvent<HTMLInputElement>, stateName: string, maxLength: number) => {
        const val = e.target.value;
        if (/^\d*$/.test(val) && val.length <= maxLength) {
            handleStateGlobal(stateName, val)
            resetFilterStatus();
        }
    }
    const userLogin = useSelector((state: any) => state.user)

    return (
        <>
            <div className="d-flex h-20px gap-1">
            </div>
            <div>
                <IconButton onClick={handleOpen}>
                    <FilterListIcon />
                </IconButton>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="filter-modal-title"
                    aria-describedby="filter-modal-description"
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Zoom in={open}>
                        <div
                            className="modal-content p-3 bg-white"
                            style={modalStyle}
                        >
                            <div className="filter-container">
                                <div className="filter-header d-flex justify-content-between align-items-center border-bottom pb-2">
                                    <div>
                                        <span
                                            className="close-button"
                                            onClick={handleClose}
                                        >
                                            <svg
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                }}
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M6.225 4.811a1 1 0 011.414 0L12 9.172l4.361-4.361a1 1 0 111.414 1.414L13.414 10.586l4.361 4.361a1 1 0 01-1.414 1.414L12 12l-4.361 4.361a1 1 0 01-1.414-1.414l4.361-4.361-4.361-4.361a1 1 0 010-1.414z"
                                                    fill="#292929"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    <h5 className="m-0">Filters</h5>
                                    <div>
                                        <button
                                            className="btn d-flex h-30px mx-1 mr-100px"
                                            onClick={(e) => {
                                                handleStateGlobal('testCat', '');
                                                handleStateGlobal('testCaseId', '');
                                                handleStateGlobal('isTestValid', '');
                                                handleStateGlobal('qrRefNo', '');
                                                handleStateGlobal('stan', '');
                                                handleStateGlobal('invNo', '');
                                                handleStateGlobal('globalId', '');
                                                handleStateGlobal('QRStatusSendToApi', '');
                                                handleStateGlobal('memberId', '');
                                                handleStateGlobal('selectedQRStandardId', '');
                                                handleStateGlobal('selectedQRStandardSendToApi', '');
                                                handleStateGlobal('qrCpmPan', '');
                                                handleStateGlobal('qrMpmMerId', '');
                                                handleStateGlobal('selectedProductIndId', '');
                                                handleStateGlobal('selectedProductIndSendToApi', '');
                                                let dateNow = new Date().toISOString().slice(0, 10);
                                                handleStateGlobal('filterStartDate', dateNow)
                                                handleStateGlobal('filterEndDate', dateNow)

                                            }}
                                        >
                                            <svg
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                }}
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M4.10746 4.10753C5.61455 2.6004 7.69904 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39772 18.3333 10.0001C18.3333 14.6024 14.6024 18.3334 10 18.3334C7.69904 18.3334 5.61456 17.3998 4.10745 15.8926C3.78202 15.5672 3.78203 15.0395 4.10747 14.7141C4.43291 14.3887 4.96055 14.3887 5.28598 14.7141C6.49329 15.9215 8.15881 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31819 13.6819 3.33341 10 3.33341C8.15881 3.33341 6.49329 4.0787 5.28598 5.28604L5.28596 5.28605C5.05321 5.5188 4.71671 5.87829 4.37525 6.25008H5.83334C6.29358 6.25008 6.66667 6.62318 6.66667 7.08342C6.66667 7.54365 6.29358 7.91675 5.83334 7.91675H2.50001C2.03977 7.91675 1.66667 7.54365 1.66667 7.08342V3.75008C1.66667 3.28984 2.03977 2.91675 2.50001 2.91675C2.96024 2.91675 3.33334 3.28984 3.33334 3.75008V4.92132C3.61958 4.61194 3.8973 4.31769 4.10746 4.10753Z"
                                                    fill="#292929"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                <div className="filter-section mt-3">
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Test Category
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={testCat}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'testCat', 64);
                                                }}
                                                maxLength={64}
                                            />
                                        </div>
                                    </div><div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Test Case ID
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={testCaseId}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'testCaseId', 12);
                                                }}
                                                maxLength={12}
                                            />
                                        </div>
                                    </div><div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Test Result
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={isTestValid}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'isTestValid', 12);
                                                }}
                                                maxLength={12}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                QR Ref No
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={qrRefNo}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'qrRefNo', 12);
                                                }}
                                                maxLength={12}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                QR Stan
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={stan}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'stan', 6);
                                                }}
                                                maxLength={6}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                QR Inv No
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={invNo}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'invNo', 64);
                                                }}
                                                maxLength={64}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Global Id
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={globalId}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'globalId', 24);
                                                }}
                                                maxLength={24}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                QR Standard
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <Select
                                                placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                isDisabled={false}
                                                options={
                                                    stateOptions.optionsQRStandard
                                                }
                                                value={stateOptions.optionsQRStandard.filter(
                                                    (option: any) =>
                                                        option.value === selectedQRStandardId
                                                )}
                                                onChange={(e) => {
                                                    handleStateGlobal('selectedQRStandardId', e.value);
                                                    // handleStateGlobal('selectedQRStandardSendToApi', e.sendToApi);
                                                    resetFilterStatus()
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Product Indicator
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <Select
                                                placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={
                                                    stateOptions.optionsProductInd
                                                }
                                                value={stateOptions.optionsQRStandard.filter(
                                                    (option: any) =>
                                                        option.value === selectedProductIndId
                                                )}
                                                onChange={(e) => {
                                                    handleStateGlobal('selectedProductIndId', e.value);
                                                    handleStateGlobal('selectedProductIndSendToApi', e.sendToApi);
                                                    resetFilterStatus()
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                QR Status
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <Select
                                                placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={
                                                    stateOptions.optionsCommonCode
                                                }
                                                value={stateOptions.optionsCommonCode.filter(
                                                    (option: any) =>
                                                        option.value === QRStatusSendToApi
                                                )}
                                                onChange={(e) => {
                                                    handleStateGlobal('QRStatusSendToApi', e.value);
                                                    handleStateGlobal('QRStatus', e.label);
                                                    resetFilterStatus()

                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Member Id
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <Select
                                                placeholder={''}
                                                className="flex-fill"
                                                styles={inputStyleH25}
                                                options={
                                                    stateOptions.optionsMemberCode
                                                }
                                                value={stateOptions.optionsMemberCode.filter(
                                                    (option: any) =>
                                                        option.value ===
                                                        memberId
                                                )}
                                                onChange={(e) => {
                                                    handleStateGlobal(
                                                        'acquirerIdCode',
                                                        e.value
                                                    );
                                                    resetFilterStatus(
                                                        'dst',
                                                        e.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                CPAN
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={qrCpmPan}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'qrCpmPan', 64);
                                                }}
                                                maxLength={64}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                MPAN
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <input
                                                className="bd-d4 bg-ff br-5 h-25px s-1418 Lato-400 out-none px-2 w-100"
                                                placeholder=""
                                                value={qrMpmMerId}
                                                onChange={(e) => {
                                                    inputValidationNumericMaxlength(e, 'qrMpmMerId', 128);
                                                }}
                                                maxLength={128}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Start transaction date
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <DatePickerRighIcon
                                                todayButton="today"
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={new Date()}
                                                selected={new Date(`${filterStartDate}` || '')}
                                                onChange={(date: any) => {
                                                    handleStateGlobal(
                                                        'filterStartDate',
                                                        format(date, 'yyyy-MM-dd')
                                                    );
                                                    if (filterEndDate === '') {
                                                        let dateNow = new Date()
                                                            .toISOString()
                                                            .slice(0, 10);
                                                        handleStateGlobal('filterEndDate', dateNow);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row d-flex">
                                        <div className="col-4">
                                            <p
                                                className={
                                                    labelStyleWwithoutUppercase
                                                }
                                            >
                                                Start transaction date
                                            </p>
                                        </div>
                                        <div className="col-8 d-flex justify-content-between">
                                            <DatePickerRighIcon
                                                todayButton="today"
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={new Date()}
                                                selected={new Date(`${filterEndDate}` || '')}
                                                onChange={(date: any) => {
                                                    handleStateGlobal(
                                                        'filterEndDate',
                                                        format(date, 'yyyy-MM-dd')
                                                    );
                                                    if (filterStartDate === '') {
                                                        let dateNow = new Date()
                                                            .toISOString()
                                                            .slice(0, 10);
                                                        handleStateGlobal('filterStartDate', dateNow);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {state.errorMessage !== '' && (
                                    <div className="col-12 pl-0">
                                        <div className="d-flex bd-e8 br-10 py-2 px-1 align-items-center wd-fit mt-2">
                                            {errNotificationIcon}
                                            <p className="px-2 s-1418 Lato-600 c-e87">
                                                {state.errorMessage}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <button
                                    className="btn btn-danger w-100 py-2 mt-3 bg-b4" style={{borderColor:'#5aade5'}}
                                    onClick={(e) => {
                                        handleClose();
                                        initFunctionCustom(true);
                                    }}
                                    disabled={state.errorMessage !== ''}
                                >
                                    <p className="s-1620 tex-uppercase c-ff Lato-600 mx-2">
                                        APPLY
                                    </p>
                                </button>
                            </div>
                        </div>
                    </Zoom>
                </Modal>
            </div>
        </>
    );
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    backgroundColor: 'white',
    boxShadow: '24',
    padding: '20px',
};

import { Dropdown } from "react-bootstrap";

export const ToggleTable = ({ ...props }): JSX.Element | null => {
    const { needToggleFunction, label, checkIndex, setToggle } = props;
    if (needToggleFunction === true) {
        return (
            <div className="dropdown h-100 ">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" variant="gray"  className="btn shd-ea bg-ff bd-ea br-30 Lato-400 s-1418 shd-none h-50px  w-100 text-overflow-ellipsis">
                        <span className="c-292">
                            Toggle{' '}
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                        {
                            label.map((item: any, i: any) => {
                                return (
                                    <label key={"toggleOne" + i} className="Lato-400 w-100 s-1418 d-flex px-2 my-2 px-1 py-1">
                                        <input className="mx-2" type="checkbox" checked={checkIndex(item.val)} value={item.val} onChange={(e) => setToggle(e.target.value)} />
                                        {item.field}
                                    </label>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>

            </div>
        )
    } else return null;
}
import { useEffect, useReducer } from 'react';
import Select from 'react-select';
import { checklistButtonIcon } from '../../../../Assets/Images/svg';
import { API } from '../../../../Services';
import { inputStyleH25Multi, labelStyleW250PX } from '../../../../Styles';
import { ModalDispute } from '../Models/ModelDispute';
import { urlSelection } from '../../../../Services/API';

export const GetDispute = ({ ...props }) => {

    const { codeMember, stateOptions, getOptionsPIC } = props;
    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);

    const handleState = (nameState: string | any, valueState: any) => {
        dispatchStatePage({ type: nameState, value: valueState })
    }


    useEffect(() => {
        API.get({
            bodyCustom: null,
            pathCustom: `member_parameter/dispute/${codeMember}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            dispatchStatePage({ type: 'setData', value: response.data })

        })
    }, [])

    const updateData = () => {
        const {
            memberParamsId,
            memberCode,
            memberDisplayFileYn,
            memberDisplayPortalYn,
            memberPicDtos,
            memberDisplayEmailNotifYn,
        } = statePage.data
        const data = {
            memberCode: memberCode,
            memberDisplayFileYn: memberDisplayFileYn,
            memberDisplayPortalYn: memberDisplayPortalYn,
            memberDisplayEmailNotifYn: memberDisplayEmailNotifYn,
            userIds: memberPicDtos
        }
        API.update({
            bodyCustom: data,
            pathCustom: `member_parameter/dispute`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
        }).then((response: any) => {
            if (response.data !== undefined) {
                dispatchStatePage({ type: 'setData', value: response.data })
            }
        })

    }
    useEffect(() => {
        let selectedPICMember: any[] = []
        if (getOptionsPIC.length > 0) {
            getOptionsPIC.forEach((option: any) => {
                if (statePage.data.memberPicDtos !== null) {
                    if (statePage.data.memberPicDtos.length > 0) {
                        if (statePage.data.memberPicDtos.includes(option.value)) {
                            selectedPICMember.push(option)
                        }
                    }
                }
            })
            handleState('selectedPICMember', selectedPICMember)
        }
    }, [statePage.data.memberPicDtos, getOptionsPIC.length])

    return (
        <div className='d-flex flex-column justify-content-between bd-ea br-10 px-2 pt-2 pb-5'>
            <div className='row py-2 gy-1 gx-3 mx-0 mb-3'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <p className={labelStyleW250PX}>DISPUTES VIA FILE</p>
                        <div className='d-flex justify-content-between align-items-center hg-40px' >
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    checked={statePage.data.memberDisplayFileYn === true}
                                    name='memberDisplayFileYn'
                                    onClick={() => handleState('memberDisplayFileYn', true)}
                                />
                                <span className='mx-2 s-1214'>YES</span>
                            </span>
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    checked={statePage.data.memberDisplayFileYn === false}
                                    name='memberDisplayFileYn'
                                    onClick={() => handleState('memberDisplayFileYn', false)}
                                />
                                <span className='mx-2 s-1214'>NO</span>
                            </span>
                            <span></span>
                        </div>
                        <div className='w-40px'></div>
                        <div className='flex-fill'></div>
                    </div>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <p className={labelStyleW250PX}>DISPUTE VIA DASHBOARD</p>
                        <div className='d-flex justify-content-between align-items-center hg-40px' >
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberDisplayPortalYn', true)}
                                    checked={statePage.data.memberDisplayPortalYn === true}
                                    name='memberDisplayPortalYn' />
                                <span className='mx-2 s-1214'>YES</span>
                            </span>
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberDisplayPortalYn', false)}
                                    checked={statePage.data.memberDisplayPortalYn === false}
                                    name='memberDisplayPortalYn' />
                                <span className='mx-2 s-1214'>NO</span>
                            </span>
                            <span></span>
                        </div>
                        <div className='w-40px'></div>
                        <div className='flex-fill'></div>
                    </div>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex  align-items-center justify-content-center my-2'}>
                        <span className={labelStyleW250PX}>DISPUTE PIC USERS</span>
                        <Select
                            styles={inputStyleH25Multi}
                            className="basic-multi-select flex-fill"
                            classNamePrefix="select"
                            value={statePage.selectedPICMember}
                            isMulti
                            options={getOptionsPIC} //memberPicDtos
                            onChange={(items) => {
                                let dataSelected: any[] = [];
                                items.forEach((item: any) => {
                                    dataSelected.push(item.value);

                                })
                                handleState('memberPicDtos', dataSelected)
                            }}
                        />
                    </div>
                </div>


                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <p className={labelStyleW250PX}>DISPUTE EMAIL NOTIFICATION</p>
                        <div className='d-flex justify-content-between align-items-center hg-40px' >
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberDisplayEmailNotifYn', true)}
                                    checked={statePage.data.memberDisplayEmailNotifYn === true}
                                    name='memberDisplayEmailNotifYn' />
                                <span className='mx-2 s-1214'>YES</span>
                            </span>
                            <span className=''>
                                <input
                                    type='radio'
                                    className='h-10px w-10px'
                                    onClick={() => handleState('memberDisplayEmailNotifYn', false)}
                                    checked={statePage.data.memberDisplayEmailNotifYn === false}
                                    name='memberDisplayEmailNotifYn' />
                                <span className='mx-2 s-1214'>NO</span>
                            </span>
                            <span></span>
                        </div>
                        <div className='w-40px'></div>
                        <div className='flex-fill'></div>
                    </div>
                </div>
            </div>
            <div className='d-flex'>
                <button className='d-flex align-items-center br-20 bd-d4 bg-e8 pointer px-3 py-2' onClick={updateData}>
                    {checklistButtonIcon}
                    <p className='s-1214 c-ff Lato-600 mx-2'>SAVE</p>
                </button>
            </div>
        </div>
    )
}

const initialState = {
    data: new ModalDispute({
        id: null,

        memberParamsId: null,
        memberCode: null,
        memberDisplayFileYn: null,
        memberDisplayPortalYn: null,
        memberPicDtos: null,
        memberDisplayEmailNotifYn: null,
    }),
    errorMessage: "",
    selectedPICMember: [],
}
const reducer = (state: any, action: any) => {
    for (const key in state.data as ModalDispute) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }

    switch (action.type) {
        case 'selectedPICMember':
            return {
                ...state,
                selectedPICMember: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };

        // 
        case 'clearData':
            return {
                ...state,
                data: new ModalDispute({
                    id: null,
                    memberParamsId: null,
                    memberCode: null,
                    memberDisplayFileYn: null,
                    memberDisplayPortalYn: null,
                    memberPicDtos: null,
                    memberDisplayEmailNotifYn: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            let memberPicDtos: any[] = [];
            if (action.value.memberPicDtos.length > 0) {
                action.value.memberPicDtos.forEach((item: any) => {
                    memberPicDtos.push(item.userId)
                })
            }
            return {
                ...state,
                data: new ModalDispute({
                    id: action.value.memberParamsId,
                    memberParamsId: action.value.memberParamsId,
                    memberCode: action.value.memberCode,
                    memberDisplayFileYn: action.value.memberDisplayFileYn,
                    memberDisplayPortalYn: action.value.memberDisplayPortalYn,
                    memberPicDtos: memberPicDtos,
                    memberDisplayEmailNotifYn: action.value.memberDisplayEmailNotifYn,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}
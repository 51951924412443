import { API, urlSelection } from '../../Services/API';
import { CheckAllFields, HasWhiteSpace, ModalStyle } from '../../Utils';
import { FooterModalCreate, ModalCannotDelete, ModalDelete } from '../../Components';
import { inputDescriptionStyle, inputStyle, inputStyleH25, labelStyle } from '../../Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';

import Modal from 'react-modal';
import { ModelSafControlManagement } from './ModelSafControlManagement';
import { Page20Title } from '../../State/Menu/TitleMenu';
import Select from 'react-select';
import { close } from '../../Assets/Images/svg';
import { loadingAction } from '../../Store/Loading';
import { logoutAction } from '../../Store/User';

export const ModalSafControlManagement = ({ ...props }) => {
    const dispatchGlobal = useDispatch();

    const [statePage, dispatchStatePage] = useReducer(reducer, initialState);
    const { visibleModalAddDelete, deleteItem, id, setModal, reloadData, urlApi, dataModal } = props
    const { optionsMember, optionsRouteSequence } = props.stateOptions

    useEffect(() => {
        dispatchStatePage({ type: 'visibleModalAddDelete', value: visibleModalAddDelete })
        dispatchStatePage({ type: 'clearData', value: deleteItem })
    }, [visibleModalAddDelete !== statePage.visibleModalAddDelete])

    useEffect(() => {
        dispatchStatePage({ type: 'deleteItem', value: deleteItem })
    }, [deleteItem !== statePage.deleteItem])

    useEffect(() => {
        dispatchStatePage({ type: 'id', value: id })
        if (dataModal !== null) {
            handleState('setData', dataModal);
        }
        if (id !== '') { }
        else {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        }
    }, [id !== statePage.id])

    const handleState = (nameState: string | any, valueState: any) => {
        if (nameState === 'safControlProcessCount' || nameState === 'safControlRetryCount' || nameState === 'safControlTimeout' || nameState === 'safControlWorkerNumber' || nameState === 'safSleepTime') {
            if (valueState === '') {
                dispatchStatePage({ type: nameState, value: valueState })
            } else if (parseInt(valueState) > 0 && parseInt(valueState) < 100) {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        } else {
            let names = ["safControlServer"]
            let limit = [50]
            let index = names.indexOf(nameState)
            if (index !== -1) {
                if (valueState.split('').length <= limit[index]) dispatchStatePage({ type: nameState, value: valueState })
            } else {
                dispatchStatePage({ type: nameState, value: valueState })
            }
        }
    }

    const deleteItemAPI = () => {
        API.delete({
            bodyCustom: null,
            pathCustom: `${urlApi}/${id}`,
            selectUrl: urlSelection.dashboard,
            useToken: true,
            needLoading: true,
        }).then((response) => {
            setModal("deleteItem")
            reloadData()
        }).catch((err) => {
            dispatchStatePage({ type: 'showModalError', value: true })
            dispatchStatePage({ type: 'errorMessageModal', value: `${err}` })
        }).finally(() => {
            dispatchStatePage({ type: 'clearData', value: deleteItem })
        })
    }

    const postDataAPI = () => {
        const { safControlId, memberId, safControlSrStatus, safControlProcessCount, safControlRetryCount, safControlWorkerNumber, safControlTimeout, safControlServer, safSleepTime, safControlRsId } = statePage.data
        const data = {
            // safControlId: id,
            memberId: memberId,
            safControlSrStatus: safControlSrStatus,
            safControlProcessCount: safControlProcessCount,
            safControlRetryCount: safControlRetryCount,
            safControlWorkerNumber: safControlWorkerNumber,
            safControlTimeout: safControlTimeout,
            safControlServer: safControlServer,
            safControlSleepTime: safSleepTime,
            safControlRsId: safControlRsId,
        };
        const a = CheckAllFields([memberId, safControlSrStatus, safControlProcessCount, safControlRetryCount, safControlWorkerNumber, safControlTimeout, safControlServer, safSleepTime,], ['Member ID', 'SAF Control Status', 'Process Count', 'Retry Transmit Count', 'Worker Number', 'Timeout', 'Server Name', 'Sleep Timeout',]);
        if (a !== "") {
            handleState('errorMessage', a);
        } else {
            handleState('errorMessage', ``);
            API.post({
                bodyCustom: data,
                pathCustom: `${urlApi}`,
                selectUrl: urlSelection.dashboard,
                useToken: true,
                needLoading: true,
            }).then((response) => {
                dispatchStatePage({ type: 'clearData' })
                setModal("visibleModalAddDelete")
                reloadData()
            }).catch((err) => handleState('errorMessage', err))
        }
    }
    return (
        <>
            <Modal isOpen={visibleModalAddDelete} style={ModalStyle}>
                <div className='d-flex flex-column w-800px'>
                    <div className='d-flex min-h-50px justify-content-between align-items-center shd-ea'>
                        <p className='w-700 Lato-700 c-29 s-1822'>{statePage.titleModal}</p>
                        <div className='pointer' onClick={(e) => props.setModal("visibleModalAddDelete")}>{close} </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='row py-2 gy-2 gx-3 mx-0 mb-3'>
                            <div className='col-12'>
                                <p className={labelStyle}>member <span className='text-danger'>*</span></p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsMember}
                                    value={optionsMember.filter((option: any) => option.value === statePage.data.memberId)}
                                    onChange={(e) => handleState('memberId', e.value)}
                                />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>send/receive <span className='text-danger'>*</span></p>
                                <div className='d-flex justify-content-between align-items-center hg-40px' >
                                    {
                                        statePage.data.safControlId ?
                                            <>
                                                <span className=''>
                                                    <input readOnly type='radio' value={'001'} checked={statePage.data.safControlSrStatus === '001'} name='safControlSrStatus' className='mx-2' style={{ display: 'inline-block' }} disabled />
                                                    {'YES'}
                                                </span>
                                                <span className=''>
                                                    <input readOnly type='radio' value={'000'} checked={statePage.data.safControlSrStatus === '000'} name='safControlSrStatus' className='mx-2' style={{ display: 'inline-block' }} disabled />
                                                    NO
                                                </span>
                                                <span></span>
                                            </>
                                            :
                                            <>
                                                <span className=''>
                                                    <input type='radio' value={'001'} checked={statePage.data.safControlSrStatus === '001'} onChange={(e) => handleState('safControlSrStatus', e.target.value)} className='mx-2' style={{ display: 'inline-block' }} />
                                                    {'YES'}
                                                </span>
                                                <span className=''>
                                                    <input type='radio' value={'000'} checked={statePage.data.safControlSrStatus === '000'} onChange={(e) => handleState('safControlSrStatus', e.target.value)} className='mx-2' style={{ display: 'inline-block' }} />
                                                    NO
                                                </span>
                                                <span></span>
                                            </>
                                    }
                                </div>
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>SERVER NAME <span className='text-danger'>*</span></p>
                                <input value={statePage.data.safControlServer} onChange={(e) => handleState('safControlServer', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}> PROCESS COUNT <span className='text-danger'>*</span></p>
                                <input type={'number'} min={0} value={statePage.data.safControlProcessCount} onChange={(e) => handleState('safControlProcessCount', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>RETRY COUNT <span className='text-danger'>*</span></p>
                                <input type={'number'} min={0} value={statePage.data.safControlRetryCount} onChange={(e) => handleState('safControlRetryCount', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>TIMEOUT <span className='text-danger'>*</span></p>
                                <input type={'number'} min={0} value={statePage.data.safControlTimeout} onChange={(e) => handleState('safControlTimeout', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>worker number <span className='text-danger'>*</span></p>
                                <input type={'number'} min={0} value={statePage.data.safControlWorkerNumber} onChange={(e) => handleState('safControlWorkerNumber', e.target.value)} className={inputStyle} />
                            </div>
                            <div className='col-6'>
                                <p className={labelStyle}>sleep time out <span className='text-danger'>*</span></p>
                                <input type={'number'} min={0} value={statePage.data.safSleepTime} onChange={(e) => handleState('safSleepTime', e.target.value)} className={inputStyle} />
                            </div>

                            <div className='col-6'>
                                <p className={labelStyle}>ROUTE SEQUENCE ID <span className='text-danger'>*</span></p>
                                <Select
                                    styles={inputStyleH25}
                                    placeholder={'(Search)'}
                                    options={optionsRouteSequence}
                                    value={optionsRouteSequence.filter((option: any) => option.value === statePage.data.safControlRsId)}
                                    onChange={(e) => handleState('safControlRsId', e.value)}
                                />
                            </div>

                            {/* <div className='col-12 mb-3'>
                                {
                                    statePage.data.safControlId ?
                                        <>
                                            <span className={'text-up mr-2 ' + (statePage.data.safControlSrStatus !== '000' ? 'button-select table-data-pointer' : 'button-unselect')} onClick={(e) => handleState('safControlSrStatus', '000')}>
                                                SAF STOP
                                            </span>
                                            <span className={'text-up mr-2 ' + (statePage.data.safControlSrStatus !== '001' ? 'button-select table-data-pointer' : 'button-unselect')} onClick={(e) => handleState('safControlSrStatus', '001')}>
                                                SAF START
                                            </span>
                                        </>

                                        :
                                        <>
                                            <span className={'text-up mr-2 ' + (statePage.data.safControlSrStatus !== '000' ? 'button-select table-data-pointer' : 'button-unselect')}>
                                                SAF STOP
                                            </span>
                                            <span className={'text-up mr-2 ' + (statePage.data.safControlSrStatus !== '001' ? 'button-select table-data-pointer' : 'button-unselect')}>
                                                SAF START
                                            </span>
                                        </>
                                }
                            </div> */}
                        </div>
                        <FooterModalCreate errorMessage={statePage.errorMessage} setModal={() => setModal("visibleModalAddDelete")} handlePostAPI={() => postDataAPI()} />
                    </div>
                </div>
            </Modal>

            <ModalDelete deleteItem={deleteItem} setModal={() => setModal("deleteItem")} deleteItemAPI={() => deleteItemAPI()} />
            <ModalCannotDelete statePage={statePage} handleState={handleState} />
        </>
    )
}


const initialState = {
    titleModal: `New ${Page20Title}`,
    visibleModalAddDelete: false,
    deleteItem: false,
    //
    data: new ModelSafControlManagement({
        id: null,
        safControlId: null,
        memberId: null,
        safControlSrStatus: '001',
        safControlProcessCount: null,
        safControlRetryCount: null,
        safControlWorkerNumber: null,
        safControlTimeout: null,
        memberName: null,
        memberCode: null,
        safSleepTime: null,
        safControlServer: null,
        safControlRsId: null,

    }),
    //
    id: "",
    errorMessage: "",
    errorMessageList: [],
    errorMessageModal: '',
    showModalError: false,
}

const reducer = (state: any, action: any) => {
    for (const key in state.data as ModelSafControlManagement) {
        if (key === action.type) {
            state.data[action.type] = action.value;
            return {
                ...state,
            };
        }
    }


    switch (action.type) {
        case 'visibleModalAddDelete':
            return {
                ...state,
                visibleModalAddDelete: action.value,
            };
        case 'deleteItem':
            return {
                ...state,
                deleteItem: action.value,
            };
        case 'id':
            return {
                ...state,
                id: action.value,
            };
        case 'errorMessage':
            return {
                ...state,
                errorMessage: action.value,
            };
        case 'errorMessageList':
            return {
                ...state,
                errorMessageList: action.value,
            };
        case 'showModalError':
            return {
                ...state,
                showModalError: action.value,
            };
        case 'errorMessageModal':
            return {
                ...state,
                errorMessageModal: action.value,
            };
        // 
        case 'clearData':
            return {
                ...state,
                data: new ModelSafControlManagement({
                    id: null,
                    safControlId: null,
                    memberId: null,
                    safControlSrStatus: '001',
                    safControlProcessCount: null,
                    safControlRetryCount: null,
                    safControlWorkerNumber: null,
                    safControlTimeout: null,
                    memberName: null,
                    memberCode: null,
                    safSleepTime: null,
                    safControlServer: null,
                    safControlRsId: null,
                }),
                errorMessage: "",

            };
        case 'setData':
            return {
                ...state,
                data: new ModelSafControlManagement({
                    id: action.value.safControlId,
                    safControlId: action.value.safControlId,
                    memberId: action.value.memberId,
                    safControlSrStatus: action.value.safControlSrStatus,
                    safControlProcessCount: action.value.safControlProcessCount,
                    safControlRetryCount: action.value.safControlRetryCount,
                    safControlWorkerNumber: action.value.safControlWorkerNumber,
                    safControlTimeout: action.value.safControlTimeout,
                    memberName: action.value.memberName,
                    memberCode: action.value.memberCode,
                    safSleepTime: action.value.safSleepTime,
                    safControlServer: action.value.safControlServer,
                    safControlRsId: action.value.safControlRsId,
                }),
                errorMessage: "",
            };
        default:
            throw new Error();
    }
}

import { useMemo, useCallback } from 'react';
import { useSelector } from "react-redux";
import Select from "react-select";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    MRT_ToggleGlobalFilterButton,
    MRT_ToggleDensePaddingButton,
    useMaterialReactTable,
} from 'material-react-table';

import { ModelQRWhitelistAndBlacklist } from '../ModelQRWhitelistAndBlacklist';
import { ButtonAdd, SelectView } from '../../../Components';
import { labelStyle, inputStyleH25 } from '../../../Styles';
import { CheckUndefined } from '../../../Utils';

export const DesktopViewQRWhitelistAndBlacklist = ({ ...props }) => {

    const privilegeAccess = useSelector((state: any) => state.user.privilegeAccess);

    const {
        state,
        stateOptions,
        codeAccessPrivilegeEdit,
        codeAccessPrivilegeDelete,
        debouncedSearch,
        stateCustomComponent,
        dispatchCustomComponent,
        customButtonAction,
        addDataComponent,
        handleState,
        handleStateComponent,
        modalAddDeleteComponentPage,
        modalUpdateComponentPage,
        dispatch
    } = props;

    const columns = useMemo<MRT_ColumnDef<ModelQRWhitelistAndBlacklist>[]>(
        () => [
            {
                accessorKey: 'qrListType', // type
                header: 'Type',
                maxSize: 40,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsWhitelistBlacklist.length > 0) {
                        stateOptions.optionsWhitelistBlacklist.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.qrListType) {
                                data = optionsItem.label;
                            }
                        });
                    }

                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'qrListCriteria', // criteria
                header: 'Criteria',
                maxSize: 40,
                enableSorting: false,
                Cell: ({ row }: any) => `${CheckUndefined(row.original.qrListCriteria)}`
            },
            {
                accessorKey: 'qrListCriteriaValue', // criteria value
                header: 'Criteria Value',
                maxSize: 70,
                enableSorting: false,
                Cell: ({ row }: any) => `${CheckUndefined(row.original.qrListCriteriaValue)}`
            },
            {
                accessorKey: 'qrListStartDate', // start date
                header: 'Start Date',
                maxSize: 70,
                enableSorting: false,
                Cell: ({ row }: any) => `${CheckUndefined(row.original.qrListStartDate)}`
            },
            {
                accessorKey: 'qrListEndDate', // end date
                header: 'End Date',
                maxSize: 70,
                enableSorting: false,
                Cell: ({ row }: any) => `${CheckUndefined(row.original.qrListEndDate)}`
            },
            {
                accessorKey: 'qrListStatus', // status
                header: 'Status',
                maxSize: 40,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsCommonStatusDetailCode.length > 0) {
                        stateOptions.optionsCommonStatusDetailCode.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.qrListStatus) {
                                data = optionsItem.label;
                            }
                        });
                    }

                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'qrListReason', // reason
                header: 'Reason',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => `${CheckUndefined(row.original.qrListReason)}`
            },
            {
                accessorKey: 'qrStandardId', // qr standard
                header: 'QR Standard',
                maxSize: 100,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    let data;
                    if (stateOptions.optionsQRStandard.length > 0) {
                        stateOptions.optionsQRStandard.forEach((optionsItem: any) => {
                            if (optionsItem.value === row.original?.qrStandardId) {
                                data = optionsItem.label;
                            }
                        });
                    }

                    return CheckUndefined(data);
                }
            },
            {
                accessorKey: 'qrListId', // action
                header: 'Action',
                maxSize: 80,
                enableSorting: false,
                Cell: ({ row }: any) => {
                    if (privilegeAccess.includes(codeAccessPrivilegeEdit) || privilegeAccess.includes(codeAccessPrivilegeDelete)) {
                        return (
                            <div className='d-flex c-29 s-1418 Lato-400 word-wrap'>
                                {customButtonAction(row.original, 'wd-150')}
                            </div>
                        )
                    }
                }
            },
        ],
        [stateOptions]
    );

    const table = useMaterialReactTable({
        // 1. Column Definitions
        columns,

        // 2. Data & State
        data: state.data,
        state: {
            pagination: {
                pageIndex: parseInt(((state.position / state.view) - 1).toString()),
                pageSize: state.view
            },
            globalFilter: state.search || ''
        },
        initialState: {
            density: 'compact',
        },

        // 3. Row & Column Utils
        enableRowActions: false,
        enableColumnActions: false,
        enableClickToCopy: false,

        // 4. Toolbar Utils
        manualFiltering: true,
        enableEditing: false,
        enableHiding: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,

        // 5. Pagination & Row Numbers
        enableRowNumbers: true,
        manualPagination: true,
        rowCount: state.totalData,
        paginationDisplayMode: 'pages',

        muiPaginationProps: {
            color: 'standard',
            showRowsPerPage: false,
            size: 'small',
        },

        onPaginationChange: (updater) => {
            const paginationState = typeof updater === 'function'
                ? updater(table.getState().pagination)
                : updater;

            dispatch({ type: 'view', value: paginationState.pageSize });
            dispatch({ type: 'position', value: (paginationState.pageIndex + 1) * paginationState.pageSize });
        },

        onGlobalFilterChange: (filterValue) => {
            const filterWithoutSymbol = filterValue ? filterValue.replace(/\[|\]/g, "") : "";
            debouncedSearch(filterWithoutSymbol);
        },

        muiTableBodyRowProps: ({ row, table }) => {

            const sortedRows: any = table.getRowModel().rows;
            const rowIndex = sortedRows.indexOf(row);

            return {
                onClick: () => { }, // click function here
                sx: {
                    backgroundColor: rowIndex % 2 === 0 ? '#f5e9e9' : '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 0, 0, 0.9)',
                        '& .MuiTableCell-root': {
                            color: 'white',
                        },
                    },
                    fontSize: '12px',
                    fontFamily: 'system-ui, sans-serif',
                    zIndex: 0,
                },
            };
        },

        muiTableHeadCellProps: {
            sx: {
                fontSize: '12px', // Set font size for header cells
                fontWeight: 'bold', // Optional: make header font bold
                fontFamily: 'system-ui, sans-serif', // Set font family for header cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                borderTop: '1px solid rgba(218, 217, 219, .7)',
            },
        },

        muiTableBodyCellProps: {
            sx: {
                fontSize: '12px', // Set font size for table cells
                fontFamily: 'system-ui, sans-serif', // Set font family for table cells
                borderRight: '1px solid rgba(218, 217, 219, .7)',
                zIndex: 0,
            },
        },

        muiSearchTextFieldProps: {
            placeholder: 'Search all column',
            size: 'small',
            sx: {
                fontSize: '12px',
                fontFamily: 'system-ui, sans-serif'
            }
        },

        muiTopToolbarProps: {
            sx: {
                zIndex: 0,
            }
        },

        muiBottomToolbarProps: {
            sx: {
                zIndex: 0,
                fontSize: '12px',
            },
        },

        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <div className="h-30px w-30px mx-2">
                    <div className="h-100">
                        <ButtonAdd handleClick={addDataComponent} codeAccessPrivilege={state.privilegeAccess.add} />
                    </div>

                </div>
            </>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <div className="d-flex flex-wrap align-items-center py-1">
                <div className="row justify-content-between">
                    <div className="col-6 d-flex align-items-center">
                        <p className="Lato-400 py-1 s-1822 c-29">List</p>
                        <p className="br-5 bd-ea bg-f4 px-2 py-1 c-29 Lato-400 s-1418 mx-2">{state.totalData}</p>
                    </div>
                </div>
            </div>
        ),
    });

    return (
        <>
            <h1 className="Lato-300 normal w-600 s-1822 py-2">{state.titlePage}</h1>
            <div className='row d-flex bd-d4 br-10 px-2 pt-2 pb-3'>
                <div className="col-4 px-0">
                    <p className={labelStyle}>QR STANDARD ID</p>
                    <Select
                        styles={inputStyleH25}
                        options={stateOptions.optionsQRStandard}
                        value={stateOptions.optionsQRStandard.filter((option: any) => option.value === stateCustomComponent.selectedQRStandard)}
                        onChange={(e) => handleState("selectedQRStandard", e.value, dispatchCustomComponent)}
                    />
                </div>
            </div>
            <div className='position-relative mt-2 mb-2'>
                <MaterialReactTable table={table} />
                <div className='position-absolute' style={{ bottom: '14px', left: '2px' }}>
                    <SelectView handleState={handleStateComponent} list_view={state.list_view} view={state.view} />
                </div>
            </div>
            {modalAddDeleteComponentPage !== undefined ? modalAddDeleteComponentPage() : <></>}
            {modalUpdateComponentPage !== undefined ? modalUpdateComponentPage() : <></>}
        </>
    )
}